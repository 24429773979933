import { useCallback, useMemo } from 'react'

import { useRouter } from 'next/router'

import { InjuryDummyBodyPartDataMap } from '@plco-pro/components/organisms/injury-dummy'
import {
  Gender,
  SportsBodyPartName,
  usePlayerInjuryQuery,
} from '@plco-pro/graphqls/react.generated'
import { useMoment } from '@plco-pro/hooks/moment'
import { useStore } from '@plco-pro/stores'

import { useNationality } from '.'

type Props = {
  skip?: boolean
  playerId: string
  from?: Date
  to?: Date
}
export const usePlayerInjuryData = ({ skip, playerId, from, to }: Props) => {
  const router = useRouter()
  const moment = useMoment()
  const { preference, players } = useStore()
  const { timezone } = preference.locale
  const nationality = useNationality()

  const { data, loading } = usePlayerInjuryQuery({
    skip: !playerId || skip,
    variables: {
      input: {
        nationality,
      },
      playerId,
      from: moment(from).startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
      to: moment(to).endOf('day').second(0).format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
      timezone,
    },
  })

  const user = data?.sports.user

  const getValue = useCallback(
    (name: string) =>
      user?.data?.find((item) => item.name === name)?.ranges?.[0].entries?.[0]?.value,
    [user?.data],
  )

  const injuryDummyGender: 'man' | 'woman' =
    user?.gender === Gender.MALE || user?.gender === Gender.OTHER ? 'man' : 'woman'

  const lastInjuredAt = getValue('INJURY')?.lastInjuredAt

  const injuryDummyData = useMemo(
    () =>
      ({
        SORENESS: getValue('SORENESS'),
        INJURY: getValue('INJURY'),
      }) as InjuryDummyBodyPartDataMap,
    [getValue],
  )

  const navigateInjuryHistoryModal = useCallback(
    (part: SportsBodyPartName) => {
      const routeMethod = typeof router.query.part === 'string' ? router.replace : router.push
      routeMethod(
        {
          query: {
            id: players.playerId,
            part,
          },
        },
        undefined,
        { scroll: false },
      )
    },
    [players.playerId, router],
  )

  return {
    loading,
    injuryDummyGender,
    lastInjuredAt,
    injuryDummyData,
    navigateInjuryHistoryModal,
  }
}
