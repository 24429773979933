import {
  FeedbackDataFragment,
  FeedbackType,
  FeedbackWorkloadMemoData,
} from '@plco-pro/graphqls/react.generated'
import { EXCLUDED_FEEDBACK_TYPE, ExcludedFeedbackType } from '@plco-pro/utils/libs'

export const getFeedbackAmplitudeType = (feedback: FeedbackDataFragment) => {
  const workloadMemoData = feedback.contentsData as FeedbackWorkloadMemoData
  const feedbackTypeText =
    feedback.type === FeedbackType.WORKLOAD_MEMO
      ? `${workloadMemoData.eventData?.type.toLowerCase() || 'workout'}_memo`
      : EXCLUDED_FEEDBACK_TYPE[feedback.type as ExcludedFeedbackType]

  return feedbackTypeText
}

export const FEEDBACK_COMMENT_MAX_LENGTH = 500
