import React from 'react'

import { IconButton, IconButtonProps } from '@plco-pro/components/molecules/icon-button'

export type IconButtonChartShiftProps = Pick<IconButtonProps, 'size' | 'interactionAreaProps'> & {
  orientation: 'left' | 'right'
  color: string
  disabled?: boolean
  onClick?: () => void
}

export const IconButtonChartShift: React.FunctionComponent<IconButtonChartShiftProps> = ({
  orientation,
  onClick = () => {},
  ...rest
}) => {
  return (
    <IconButton
      src={`/images/arrow-${orientation === 'left' ? 'left' : 'right'}.svg`}
      shape={'circle'}
      onClick={onClick}
      {...rest}
      sx={{
        boxShadow: 'circle',
        backgroundColor: 'white-500',
        border: 'inputButton',
      }}
    />
  )
}
