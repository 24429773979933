import React, { useMemo } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { useResponsive } from '@plco-pro/hooks/responsive'

export type ModalHeaderProps = BoxProps & {
  headerProps?: BoxProps
  isFullScreen?: boolean
  children?: React.ReactNode
}

const HeaderBox = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      flex: '0 1 auto',
      px: [2, 3],
      py: 2,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'grey-100',
    }}
  />
)

const HeaderFlex = (props: FlexProps) => (
  <Flex sx={{ height: '100%', alignItems: 'center' }} {...props} />
)

export const ModalHeader: React.FunctionComponent<ModalHeaderProps> = ({
  headerProps,
  isFullScreen = false,
  children,
  ...boxProps
}) => {
  const getResponsiveProp = useResponsive()

  const responsiveHeaderHeight = useMemo(
    () => (isFullScreen ? getResponsiveProp(['56px', '70px', null, null]) : '70px'),
    [isFullScreen, getResponsiveProp],
  )

  if (children) {
    return (
      <HeaderBox sx={{ height: responsiveHeaderHeight }} data-testid={'modal-header'} {...boxProps}>
        <HeaderFlex>{children}</HeaderFlex>
      </HeaderBox>
    )
  } else if (headerProps) {
    return (
      <HeaderBox sx={{ height: responsiveHeaderHeight }} data-testid={'modal-header'} {...boxProps}>
        <HeaderFlex>
          <Text variant={'h5'} sx={{ ...headerProps.sx }}>
            {headerProps.title}
          </Text>
        </HeaderFlex>
      </HeaderBox>
    )
  } else {
    return null
  }
}
