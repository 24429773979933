import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { Flex, Text, Textarea } from '@plco-pro/components/atoms'
import { FeedbackContents } from '@plco-pro/components/feedback'
import { PlayerSelectedType } from '@plco-pro/components/organisms/dialog-player-select'
import { Modal } from '@plco-pro/components/organisms/modal'
import {
  FeedbackConditionNotGoodData,
  FeedbackConditionNotGoodInput,
  FeedbackConditionPlummetData,
  FeedbackConditionPlummetInput,
  FeedbackSorenessSevereData,
  FeedbackSorenessSevereInput,
  FeedbackSuggestion,
  FeedbackType,
  FeedbackWorkloadScheduleData,
  FeedbackWorkloadMemoData,
  FeedbackWorkloadMemoInput,
  FeedbackWorkloadRiskAtInjuryData,
  FeedbackWorkloadRiskAtInjuryInput,
  FeedbackConnection,
  Maybe,
  useCreateFeedbackMutation,
  FeedbackDataFragmentDoc,
} from '@plco-pro/graphqls/react.generated'
import { useI18n, useToast, useViewer } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useFeedbackError } from '@plco-pro/hooks/feedback-error'
import { useMoment } from '@plco-pro/hooks/moment'
import { LANGUAGE_MAP } from '@plco-pro/hooks/multilingual'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { useStore } from '@plco-pro/stores'
import { AnalyticsEventType } from '@plco-pro/utils/libs'

import { getFeedbackAmplitudeType } from './helper'

interface Props {
  entry: 'squad' | 'fab' | 'newdashboard'
  isOpen: boolean
  player: PlayerSelectedType
  feedback: FeedbackSuggestion | null
  onCompleted?: () => void
  onCancel: () => void
  onClose: () => void
  onPrev: () => void
}

export const DialogRegistFeedback = ({
  entry,
  isOpen,
  player,
  feedback,
  onCompleted,
  onCancel,
  onClose,
  onPrev,
}: Props) => {
  const { formatMessage } = useI18n()
  const { preference } = useStore()
  const moment = useMoment()
  const { currentActiveTeam } = useViewer()
  const { showToast } = useToast()
  const { smAndDown, xlOnly } = useResponsiveContext()
  const { trackEvent } = useAnalytics()

  const showFeedbackError = useFeedbackError()

  const [memo, setMemo] = useState('')

  const [createFeedback, { loading }] = useCreateFeedbackMutation({
    onCompleted: ({ createFeedback }) => {
      const feedbackTypeText = getFeedbackAmplitudeType(createFeedback)

      trackEvent(AnalyticsEventType.REGISTER_FEEDBACK, {
        type: feedbackTypeText,
        conversion: entry === 'fab' ? entry.toUpperCase() : entry,
      })

      showToast(formatMessage({ id: 'toast.feedback-has-been-sent' }), { status: 'success' })

      onCompleted?.()
      onClose()
    },
    onError: ({ graphQLErrors }) => {
      showFeedbackError(graphQLErrors, false)
    },
    update: (cache, { data: feedback }) => {
      if (!feedback?.createFeedback.player) {
        return
      }

      const player = cache.identify(feedback.createFeedback.player)
      const newFeedbackNode = feedback.createFeedback

      cache.modify({
        id: player,
        fields: {
          feedbackConnection(prev: FeedbackConnection) {
            const prevFeedbackEdges = prev.edges

            const newFeedbackNodeRef = cache.writeFragment({
              data: newFeedbackNode,
              fragment: FeedbackDataFragmentDoc,
            })

            const newEdges = [newFeedbackNodeRef, ...prevFeedbackEdges]

            return { ...prev, edges: newEdges }
          },
        },
      })
    },
  })

  useEffect(() => {
    setMemo('')
  }, [feedback])

  const onChangeMemo = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 500) {
      const lastChar = e.target.value.charAt(e.target.value.length - 1)

      setMemo(`${memo.slice(0, -1)}${lastChar}`)

      return
    }

    setMemo(e.target.value)
  }

  const feedbackContentsData = useMemo(() => {
    if (!feedback?.contentsData) {
      return
    }

    const { __typename, ...contetsData } = feedback.contentsData

    return {
      workloadMemoData:
        feedback.type === FeedbackType.WORKLOAD_MEMO
          ? (contetsData as FeedbackWorkloadMemoData)
          : undefined,
      sorenessSevereData:
        feedback.type === FeedbackType.SORENESS_SEVERE
          ? (contetsData as FeedbackSorenessSevereData)
          : undefined,
      conditionNotGoodData:
        feedback.type === FeedbackType.CONDITION_NOT_GOOD
          ? (contetsData as FeedbackConditionNotGoodData)
          : undefined,
      conditionPlummetData:
        feedback.type === FeedbackType.CONDITION_PLUMMET
          ? (contetsData as FeedbackConditionPlummetData)
          : undefined,
      workloadRiskAtInjuryData:
        feedback.type === FeedbackType.WORKLOAD_RISK_AT_INJURY
          ? (contetsData as FeedbackWorkloadRiskAtInjuryData)
          : undefined,
    }
  }, [feedback])

  const feedbackSuggestionInput = useMemo(() => {
    if (!feedbackContentsData || !feedback) {
      return
    }

    const removeTypenameField = (scheduleData?: Maybe<FeedbackWorkloadScheduleData>) => {
      if (!scheduleData) {
        return null
      }

      const { __typename, ...rest } = scheduleData

      return rest
    }

    switch (feedback.type) {
      case FeedbackType.WORKLOAD_MEMO:
        return {
          workloadMemo: {
            ...{
              ...feedbackContentsData.workloadMemoData,
              scheduleData: removeTypenameField(
                feedbackContentsData?.workloadMemoData?.scheduleData,
              ),
            },
          } as FeedbackWorkloadMemoInput,
        }
      case FeedbackType.WORKLOAD_RISK_AT_INJURY:
        return {
          workloadRiskAtInjury: {
            ...feedbackContentsData?.workloadRiskAtInjuryData,
          } as FeedbackWorkloadRiskAtInjuryInput,
        }
      case FeedbackType.CONDITION_NOT_GOOD:
        return {
          conditionNotGood: {
            ...feedbackContentsData?.conditionNotGoodData,
          } as FeedbackConditionNotGoodInput,
        }

      case FeedbackType.CONDITION_PLUMMET:
        return {
          conditionPlummet: {
            ...feedbackContentsData?.conditionPlummetData,
          } as FeedbackConditionPlummetInput,
        }

      case FeedbackType.SORENESS_SEVERE:
        return {
          sorenessSevere: {
            ...feedbackContentsData?.sorenessSevereData,
            bodyPart: feedbackContentsData?.sorenessSevereData?.bodyPart.id,
          } as FeedbackSorenessSevereInput,
        }

      default:
        return
    }
  }, [feedback, feedbackContentsData])

  const onSave = () => {
    if (!currentActiveTeam?.id || !memo) {
      return
    }

    const language = preference.locale.language.split('-')[0] as keyof typeof LANGUAGE_MAP

    createFeedback({
      variables: {
        input: {
          date: feedback?.type ? feedback.date : moment().format('YYYY-MM-DD'),
          type: feedback?.type || FeedbackType.NORMAL,
          playerId: player.id,
          teamId: currentActiveTeam.id,
          message: memo,
          ...feedbackSuggestionInput,
        },
        multilingualTextInput: {
          nationality: `all_country_code__${LANGUAGE_MAP[language]}`,
        },
      },
    })
  }

  const modalMaxHeight = useMemo(() => {
    const viewportHeight = window.innerHeight

    if (smAndDown) {
      return '100%'
    }

    if (xlOnly) {
      return viewportHeight > 800 ? '800px' : `${viewportHeight - 40}px`
    }

    return viewportHeight > 590 ? '590px' : `${viewportHeight - 40}px`
  }, [smAndDown, xlOnly])

  return (
    <Modal
      isFullScreen={smAndDown}
      focusTrapped={false}
      open={isOpen}
      width={'560px'}
      maxHeight={modalMaxHeight}
      contentPaddingDisable={true}
      onClose={onCancel}
      headerProps={{
        title: formatMessage({ id: 'fab.send-feedback' }),
        sx: { fontSize: 's1' },
      }}
      footerProps={{
        leftButtonProps: {
          children: formatMessage({ id: 'feedback.back' }),
          onClick: onPrev,
        },
        rightButtonProps: {
          disabled: !memo.length,
          children: formatMessage({ id: 'feedback.save' }),
          onClick: onSave,
          isLoading: loading,
        },
      }}
    >
      <Flex sx={{ flexDirection: 'column', gap: 2, my: 3 }}>
        {feedback ? (
          <FeedbackContents player={player} date={moment(feedback.date)} feedback={feedback} />
        ) : (
          <Text sx={{ fontSize: ['h5', 'h3'], fontWeight: 700, mx: [2, 3] }}>
            {formatMessage({ id: 'feedback.general-feedback' })}
          </Text>
        )}
        <Flex
          sx={{
            position: 'relative',
            minHeight: '240px',
            pt: 2,
            pb: 3,
            mx: [2, 3],
            bg: 'grey-50',
            border: '1px solid',
            borderColor: 'grey-50',
            borderRadius: 10,
            ':focus-within': {
              bg: 'white-500',
              border: '1px solid',
              borderColor: 'primary-500',
            },
          }}
        >
          <Textarea
            value={memo}
            onChange={onChangeMemo}
            placeholder={formatMessage({ id: 'feedback.enter-your-feedback' })}
            sx={{
              p: 0,
              px: 2,
              border: 'none',
              resize: 'none',
              fontSize: ['p1', 's2'],
              fontWeight: 400,
            }}
          />
          <Text
            sx={{
              position: 'absolute',
              bottom: 1,
              right: 2,
              fontSize: '12px',
              color: 'grey-400',
              textAlign: 'right',
            }}
          >
            {`${memo.length} / 500`}
          </Text>
        </Flex>
      </Flex>
    </Modal>
  )
}
