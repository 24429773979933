import { Flex, Text } from '../../atoms'

interface ConditionTabProps {
  label: string
  isActive?: boolean
  onClick: () => void
}

export const ConditionTab = ({ label, isActive, onClick }: ConditionTabProps) => {
  return (
    <Flex
      sx={{
        flexShrink: 0,
        position: 'relative',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
        pb: '12px',
        borderRadius: '4px',
        ':hover': {
          backgroundColor: 'bg-hover',
        },
        ':after': {
          width: '100%',
          position: 'absolute',
          left: '0px',
          bottom: '0px',
          content: "''",
          height: '4px',
          backgroundColor: isActive ? 'primary-500' : 'transparent',
          borderRadius: '2px 2px 0px 0px',
        },
      }}
      onClick={onClick}
    >
      <Text
        variant="s2"
        sx={{ fontWeight: isActive ? 'bold' : 400, color: isActive ? 'text-primary' : 'grey-400' }}
      >
        {label}
      </Text>
    </Flex>
  )
}
