import { Box, FlexProps } from '../atoms'

interface CircleProps extends FlexProps {
  size: number
  color: string
}

export const Circle = ({ size = 6, color, ...props }: CircleProps) => {
  return (
    <Box
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        backgroundColor: color,
      }}
      {...props}
    />
  )
}
