import { ReactNode } from 'react'

import { Box, BoxProps, Text } from '../../atoms'
import { FeedbackContentsType } from '../helpers'

interface DescriptionBoxProps extends BoxProps {
  feedbackContentsType?: FeedbackContentsType
  children?: ReactNode
}

/**
 * 피드백 설명 박스입니다.
 * 김선수님은 체력 대비 피로도 높음 외 4개의 원인으로 인해 부상 위험 유의 상태입니다.
 */
export const DescriptionBox = ({
  feedbackContentsType = 'add',
  children,
  ...props
}: DescriptionBoxProps) => {
  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: '8px',
        ...{
          add: {
            backgroundColor: 'grey-50',
          },
          detail: {
            border: '1px solid',
            borderColor: 'grey-100',
            backgroundColor: 'white',
          },
        }[feedbackContentsType],
      }}
      {...props}
    >
      <Text variant="p1" sx={{ color: 'text-primary', fontWeight: 400 }}>
        {children}
      </Text>
    </Box>
  )
}
