import { ChangeEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { HyperLink, Button, Flex, Icon, Spinner, Text, Textarea } from '@plco-pro/components/atoms'
import { Avatar, IconButton } from '@plco-pro/components/molecules'
import { Feedback, useUpdateFeedbackMutation } from '@plco-pro/graphqls/react.generated'
import { useI18n, useNationality, useToast, useViewer } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useFeedbackError } from '@plco-pro/hooks/feedback-error'
import { useMomentFormat } from '@plco-pro/hooks/use-moment-format'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { WITHDRAWN_USER } from '@plco-pro/utils/constant'
import { AnalyticsEventType } from '@plco-pro/utils/libs'

import { getFeedbackAmplitudeType } from './helper'

interface Props {
  editId: string
  feedback: Feedback
  isEditing: boolean
  onEdit: (id?: string) => void
  onDelete: () => void
}

export const CardFeedbackDetail = ({ editId, feedback, isEditing, onEdit, onDelete }: Props) => {
  const { viewer } = useViewer()
  const { formatFeedbackCreateDate } = useMomentFormat()
  const { formatMessage } = useI18n()
  const { showToast } = useToast()
  const nationality = useNationality()
  const { smAndDown } = useResponsiveContext()
  const showFeedbackError = useFeedbackError()
  const { trackEvent } = useAnalytics()

  const { id, createUser, createdAt, message, isUpdated } = feedback

  const [editedMessage, setEditedMessage] = useState(message)
  const [textareaHeight, setTextareaHeight] = useState(0)

  const [updateFeedbackMutation, { loading }] = useUpdateFeedbackMutation({
    onCompleted: ({ updateFeedback }) => {
      const feedbackTypeText = getFeedbackAmplitudeType(updateFeedback)

      trackEvent(AnalyticsEventType.EDIT_FEEDBACK, {
        type: feedbackTypeText,
      })
      showToast(formatMessage({ id: 'feedback.feedback-has-edited' }), { status: 'success' })
      onEdit()
    },
    onError: ({ graphQLErrors }) => {
      showFeedbackError(graphQLErrors, true)
    },
  })

  const onCancel = () => {
    onEdit()
    setEditedMessage(message)
  }

  const onChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 500) {
      const lastChar = e.target.value.charAt(e.target.value.length - 1)

      setEditedMessage(`${editedMessage.slice(0, -1)}${lastChar}`)

      return
    }

    setEditedMessage(e.target.value)
  }

  const updateFeedback = () => {
    if (message === editedMessage) {
      const feedbackTypeText = getFeedbackAmplitudeType(feedback)

      trackEvent(AnalyticsEventType.EDIT_FEEDBACK, {
        type: feedbackTypeText,
      })
      showToast(formatMessage({ id: 'feedback.feedback-has-edited' }), { status: 'success' })
      onEdit()

      return
    }

    if (loading) {
      return
    }

    updateFeedbackMutation({
      variables: {
        input: {
          id,
          message: editedMessage,
        },
        multilingualTextInput: {
          nationality,
        },
      },
    })
  }

  const canEdit = viewer?.id === createUser.id && !isEditing
  const feedbackCreateDate = formatFeedbackCreateDate(createdAt)
  const isWithdrawnUser = createUser.id === WITHDRAWN_USER

  return (
    <Flex sx={{ gap: 1, mt: 1, mx: [2, 3] }}>
      <Icon src="/images/ic_keyboard_return.svg" />
      {isEditing && editId === feedback.id ? (
        <Flex sx={{ flex: 1, flexDirection: 'column', gap: 1 }}>
          <Flex
            sx={{
              position: 'relative',
              minHeight: '105px',
              pt: 2,
              pb: 3,
              border: '1px solid',
              borderColor: 'primary-500',
              borderRadius: 10,
              bg: 'white-500',
            }}
          >
            <Textarea
              ref={(node) => {
                if (!node) {
                  return
                }
                node.style.height = `${node.scrollHeight}px`
              }}
              value={editedMessage}
              onChange={onChangeMessage}
              placeholder={formatMessage({ id: 'feedback.enter-your-feedback' })}
              sx={{
                p: 0,
                px: 2,
                border: 'none',
                resize: 'none',
                fontSize: 's2',
                fontWeight: 400,
                maxHeight: '200px',
                height: textareaHeight,
              }}
            />
          </Flex>
          <Flex sx={{ justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={onCancel} variant={'secondary'} color={'grey'}>
              {formatMessage({ id: 'CANCEL' })}
            </Button>
            <Button onClick={updateFeedback} disabled={!editedMessage.length}>
              {loading ? (
                <Spinner size={'sm'} color={'alternative'} />
              ) : (
                formatMessage({ id: 'SAVE' })
              )}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex
          sx={{
            flex: 1,
            flexDirection: 'column',
            gap: '20px',
            p: 2,
            border: 'default',
            borderRadius: '10px',
            bg: 'white-500',
          }}
        >
          <Flex
            ref={(node) => {
              if (!node) {
                return
              }
              setTextareaHeight(node.scrollHeight)
            }}
            sx={{ alignItems: 'flex-start', justifyContent: 'space-between', gap: 1 }}
          >
            <HyperLink>
              <Flex
                sx={{
                  alignItems: 'center',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Text sx={{ fontSize: 's2', fontWeight: 400 }}>
                  {message}
                  {isUpdated && (
                    <Text
                      sx={{
                        display: 'inline-block',
                        fontWeight: 500,
                        color: 'grey-400',
                      }}
                    >
                      {formatMessage({ id: 'feedback.edited' })}
                    </Text>
                  )}
                </Text>
              </Flex>
            </HyperLink>

            {canEdit && (
              <Flex sx={{ flexShrink: 0 }}>
                <IconButton
                  onClick={() => onEdit(feedback.id)}
                  src={'/images/ic_edit.svg'}
                  color={'grey-400'}
                  size={smAndDown ? 'lg' : 'sm'}
                  iconSize={smAndDown && 24}
                />
                <IconButton
                  onClick={onDelete}
                  src={'/images/ic_delete_outline.svg'}
                  color={'grey-400'}
                  size={smAndDown ? 'lg' : 'sm'}
                  iconSize={smAndDown && 24}
                />
              </Flex>
            )}
            {isEditing && editId === feedback.id && (
              <Flex sx={{ alignItems: 'center' }}>
                <Text sx={{ fontSize: 'p2', fontWeight: 700, color: 'grey-300' }}>
                  {formatMessage({ id: 'feedback.editing' })}
                </Text>
                <Flex
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '32px',
                    height: '32px',
                  }}
                >
                  <Icon src={'/images/ic_edit.svg'} color={'grey-300'} />
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex sx={{ gap: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
            <Flex sx={{ gap: '4px', alignItems: 'center' }}>
              <Avatar
                src={createUser.pictureUrl}
                size={24}
                value={createUser.name}
                isWithdrawn={isWithdrawnUser}
              />
              <Text variant="p1" ellipsis>
                <FormattedMessage
                  id={'feedback.authors-feedback'}
                  values={{
                    author: (
                      <strong>
                        {isWithdrawnUser
                          ? formatMessage({ id: 'feedback.withdrew-user' })
                          : createUser.name}
                      </strong>
                    ),
                  }}
                />
              </Text>
            </Flex>
            <Text sx={{ fontSize: 'p1', fontWeight: 500, color: 'text-secondary', flexShrink: 0 }}>
              {feedbackCreateDate}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
