import React, { useCallback, useMemo } from 'react'

import styled from '@emotion/styled'
import { variant, VariantArgs } from 'styled-system'
import { ResponsiveStyleValue } from 'theme-ui'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { useResponsive } from '@plco-pro/hooks/responsive'

export type SegmentSize = 'sm' | 'md'

export type SegmentProps = Omit<FlexProps, 'onClick' | 'size'> & {
  size?: ResponsiveStyleValue<SegmentSize>
  checked: boolean
  onClick?: () => void
}

export type StyledFlexProps = Omit<FlexProps, 'size'> & {
  size?: SegmentSize
}

const size = variant({
  prop: 'size',
  variants: {
    sm: {
      height: '30px',
      fontSize: 'p1',
    },
    md: {
      height: '32px',
      fontSize: 's2',
    },
  },
} as VariantArgs)

const StyledFlex = styled(Flex)<StyledFlexProps>(size)

export const Segment: React.FunctionComponent<SegmentProps> = ({
  size = 'md',
  checked,
  ...rest
}) => {
  const getResponsiveProp = useResponsive()

  const responsiveSize = useMemo(() => getResponsiveProp(size), [size, getResponsiveProp])

  const interactiveSx = useCallback((checked: boolean): FlexProps['sx'] => {
    return checked
      ? {
          backgroundColor: 'white-500',
          boxShadow: '0 0 4px 1px rgba(16, 20, 38, 0.24)',
        }
      : {
          color: 'text-secondary',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'grey-300-40',
          },
          ':active': {
            backgroundColor: 'grey-300',
          },
        }
  }, [])

  return (
    <StyledFlex
      size={responsiveSize}
      {...rest}
      sx={{
        alignItems: 'center',
        fontWeight: 'medium',
        borderRadius: '16px',
        py: '4px',
        px: 2,
        zIndex: 1,
        ...interactiveSx(checked),
      }}
    />
  )
}
