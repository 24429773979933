/* eslint-disable max-len */
import { error } from './error'
import { I18nCommonMessagePack } from './i18n.en'
import { validationError } from './validation-error'

import 'moment/locale/ko'

export const messages: I18nCommonMessagePack = {
  ...error.ko,
  ...validationError.ko,
  // date format
  'date.format.month-minute': 'MMM Do HH:mm',
  'date.format.month-day-time': 'MMM Do LT',
  'date.format.year-month-day': 'YYYY. M. D',
  'date.format.year-month-day-suffix': 'YYYY년 MMM Do',
  'date.format.year-to-minutes': 'YYYY년 MMM Do · HH:mm',
  'date.format.year-to-minutes-a': 'YYYY. M. D A h시 mm분',
  'date.format.month-day': 'M. D',
  'date.format.period': 'YYYY. M. D',
  'date.format.year-month': 'YYYY년 MMM',
  'date.format.year-month-day-week': 'YYYY년 MMM Do (dd)',
  'date.format.year-month-day-week-suffix': 'YYYY년 MMM Do (dd) · HH:mm',
  'date.format.hour-minutes': '{hour}시간 {minute}분',

  // date (for scheduler)
  'date.year-month': '{YYYY}년 {MMM}',
  'date.year-full-month': '{YYYY}년 {MMMM}',
  'date.weekdays': '월_화_수_목_금_토_일',

  'date.left.day': '{days}일 남음',
  'date.duration.time': 'H시간 M분',

  'date-range.title-format': 'yyyy년 M월',
  'date-range.7-days': '지난 7일',
  'date-range.30-days': '지난 30일',

  NO: '아니요',
  YES: '예',
  CANCEL: '취소',
  CLOSE: '닫기',
  CONFIRM: '확인',
  DONE: '완료',
  RETRY: '다시 시도',
  LEARN_MORE: '더 알아보기',
  APPLY: '적용',
  SEND: '보내기',
  SELECT: '선택',
  SEARCH: '검색',
  HOUR: '{hours, plural, =0 {# 시간} other {# 시간}}',
  H: '시간',
  MINUTE: '{minutes, plural, =0 {# 분} other {# 분}}',
  M: '분',
  DAY: '일',
  MONTH: '월',
  YEAR: '년',
  COUNT: '개',
  POINT: '점',
  SCORE: '점',
  PEOPLE: '명',
  PEOPLE_COUNT: '{count}명',
  CM: 'cm',
  KG: 'kg',
  NAME: '이름',
  TODAY: '오늘',
  YESTERDAY: '어제',
  START: '시작하기',
  NEXT: '다음',
  BACK: '뒤로',
  NOT_NOW: '나중에',
  OKAY: '확인',
  LEAVE: '나가기',
  SAVE: '저장',
  AVG: '평균',
  SUM: '누적',
  USER: '유저',
  MAKE: '만들기',
  ADD: '추가',
  CHANGE: '변경',
  PART: '개',
  GOAL: '목표',

  ALL: '전체',
  FILTER: '필터',
  NO_SEARCH_RESULT: '검색 결과가 없습니다.',

  SELECT_ALL: '전체 선택',

  DELETE: '삭제',
  REFRESH: '새로고침',

  // auth federation
  'federation.kakao': '카카오톡',
  'federation.facebook': '페이스북',
  'federation.apple': '애플',
  'federation.google': '구글',

  // languages
  'ko-KR': '한국어',
  'en-KR': 'English',
  'ja-KR': '日本語',
  'vn-VN': 'Tiếng Việt',

  // data
  READINESS: '준비도',
  FATIGUE: '피로도',
  STRESS: '스트레스',
  MUSCLE: '근육',
  MOOD: '기분',
  SLEEP_DURATION: '수면시간',
  DURATION: '수면시간',
  SLEEP_QUALITY: '수면품질',
  QUALITY: '수면품질',
  WORKLOAD_ACXR: '부상 위험도 (ACWR)',
  WORKLOAD_AXD: '단조로움',
  WORKLOAD_AX: '급성부하',
  WORKLOAD_CX: '만성부하',
  WORKLOAD_AX_CX: '급성 & 만성부하',
  WORKLOAD_DURATION: '운동시간',
  WORKLOAD_INTENSITY: '운동강도',
  WORKLOAD_INTENSITY_RPE: '운동강도 (RPE)',
  WORKLOAD_SATISFACTION: '운동만족',
  SORENESS_COUNT: '통증 개수',
  SORENESS_1: '통증 1단계',
  SORENESS_2: '통증 2단계',
  SORENESS_3: '통증 3단계',
  SORENESS_4: '통증 4단계',
  SORENESS_5: '통증 5단계',
  CONDITION: '컨디션',
  WORKLOAD: '운동부하',
  HEIGHT: '키',
  WEIGHT: '체중',
  ACWR: '부상 위험도',
  DANGER_TRAINING: '위험',
  WARNING_TRAINING: '주의',
  BEST_TRAINING: '최적',
  CAUTION_TRAINING: '유의',
  RECENT_RECORDS: '최근 기록',

  INJURY_NUMBER_0: '통증없음',
  INJURY_NUMBER_1: '1단계',
  INJURY_NUMBER_2: '2단계',
  INJURY_NUMBER_3: '3단계',
  INJURY_NUMBER_4: '4단계',
  INJURY_NUMBER_5: '5단계',
  TYPE: '종류',

  SORENESS_TYPE: '유형',
  SORENESS_MEMO: '메모',
  SORENESS_SITUATION: '통증 발생 상황',

  CONTACT: '접촉',
  NON_CONTACT: '비접촉',

  // period
  '1-day': '일간',
  '1-week': '주간',
  '1-month': '월간',

  // scheduler type
  'event-type.TRAINING': '훈련',
  'event-type.MATCH': '경기',
  'event-type.PRACTICE': '연습경기',
  'event-type.REST': '휴식',
  'event-type.OTHER_EVENT': '기타',

  CHECK_RATE: '체크율',
  CHECK: '체크',
  UNCHECKED: '미체크',

  ATTEND: '참가',
  ABSENCE: '불참',

  // constants
  SEE_MORE: '더 보기',
  SORENESS_INTERPOLATED_STREAK:
    '{days, plural, =0 {없음} =1 {#일 지속} =28 {#일 이상 지속} other {#일 지속}}',
  SORENESS_DEGREE: '{degree} 단계',

  SORENESS_DEGREE_5: '{degree} 단계 (부상)',
  STREAK: '{days, plural, =0 {입력 없음} =9999 {연속 9999+일 째} other {연속 #일 째}}',
  STREAK_NEGATIVE:
    '{days, plural, =0 {입력 없음} =1 {입력 후 #일 경과} =9999 {입력 후 9999+일 경과} other {입력 후 #일 경과}}',
  'USER.NAME': '이름',
  'USER.NAME.placeholder': '실명을 입력하세요',
  'USER.NATIONALITY': '국적',
  'USER.GENDER': '성별',
  'USER.BIRTHDATE': '생일',
  'USER.USERNAME': '사용자 이름',
  'USER.USERNAME.placeholder': '사용자 이름을 입력하세요',
  'USER.PHONENUMBER': '휴대전화',
  'USER.PHONENUMBER.title': '휴대폰 번호 변경',
  'USER.INTRODUCE': '자기소개',
  'USER.INTRODUCE.placeholder': '자기를 소개해보세요',
  'USER.INTRODUCTION.empty': '아직 소개가 없습니다.',
  'GENDER.MALE': '남성',
  'GENDER.FEMALE': '여성',
  'GENDER.OTHER': '기타',
  PLAYER: '선수',
  COACH: '지도자',
  ROLE: '역할',
  'ROLE.PLAYER': '선수',
  'ROLE.COACH': '지도자',
  CATEGORY: '종목',
  POSITION: '포지션',
  TEAM: '팀',
  GROUP: '그룹',
  BIRTHDATE: '생년월일',

  MON: '월',
  TUE: '화',
  WED: '수',
  THU: '목',
  FRI: '금',
  SAT: '토',
  SUN: '일',

  // player positions
  'PLAYER_POSITION.EMPTY': ' ',
  'PLAYER_POSITION.SOCCER.FW': '공격수',
  'PLAYER_POSITION.SOCCER.MF': '미드필더',
  'PLAYER_POSITION.SOCCER.DF': '수비수',
  'PLAYER_POSITION.SOCCER.GK': '골키퍼',
  'PLAYER_POSITION.BASKETBALL.PG': '포인트가드',
  'PLAYER_POSITION.BASKETBALL.SG': '슛팅가드',
  'PLAYER_POSITION.BASKETBALL.SF': '스몰포워드',
  'PLAYER_POSITION.BASKETBALL.PF': '파워포워드',
  'PLAYER_POSITION.BASKETBALL.C': '센터',
  'PLAYER_POSITION.VOLLEYBALL.LI': '리베로',
  'PLAYER_POSITION.VOLLEYBALL.S': '세터',
  'PLAYER_POSITION.VOLLEYBALL.C': '센터',
  'PLAYER_POSITION.VOLLEYBALL.L': '레프트',
  'PLAYER_POSITION.VOLLEYBALL.R': '라이트',
  'PLAYER_POSITION.HANDBALL.GK': '골키퍼',
  'PLAYER_POSITION.HANDBALL.LW': '레프트 윙',
  'PLAYER_POSITION.HANDBALL.RW': '라이트 윙',
  'PLAYER_POSITION.HANDBALL.LB': '레프트 백',
  'PLAYER_POSITION.HANDBALL.RB': '라이트 백',
  'PLAYER_POSITION.HANDBALL.CB': '센터백',
  'PLAYER_POSITION.HANDBALL.PV': '피봇',
  'PLAYER_POSITION.BASEBALL.P': '투수',
  'PLAYER_POSITION.BASEBALL.C': '포수',
  'PLAYER_POSITION.BASEBALL._1B': '1루수',
  'PLAYER_POSITION.BASEBALL._2B': '2루수',
  'PLAYER_POSITION.BASEBALL._3B': '3루수',
  'PLAYER_POSITION.BASEBALL.SS': '유격수',
  'PLAYER_POSITION.BASEBALL.LF': '좌익수',
  'PLAYER_POSITION.BASEBALL.CF': '중견수',
  'PLAYER_POSITION.BASEBALL.RF': '우익수',
  'PLAYER_POSITION.BASEBALL.DH': '지명타자',
  'PLAYER_POSITION.FIELD_HOCKEY.FW': '공격수',
  'PLAYER_POSITION.FIELD_HOCKEY.MF': '미드필더',
  'PLAYER_POSITION.FIELD_HOCKEY.DF': '수비수',
  'PLAYER_POSITION.FIELD_HOCKEY.GK': '골키퍼',
  'PLAYER_POSITION.RUGBY.HK': '후커',
  'PLAYER_POSITION.RUGBY.PR': '프롭',
  'PLAYER_POSITION.RUGBY.SR': '록크',
  'PLAYER_POSITION.RUGBY.FL': '플랭커',
  'PLAYER_POSITION.RUGBY.NE': '넘버8',
  'PLAYER_POSITION.RUGBY.SH': '스크럼 하프',
  'PLAYER_POSITION.RUGBY.FH': '플라이 하프',
  'PLAYER_POSITION.RUGBY.CT': '센터',
  'PLAYER_POSITION.RUGBY.WG': '윙',
  'PLAYER_POSITION.RUGBY.FB': '풀백',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.C': '센터',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OG': '오펜시브 가드',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OT': '오펜시브 태클',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.QB': '쿼터백',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.RB': '런닝백',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.WR': '와이드 리시버',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.TE': '타이트 엔드',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DT': '디펜시브 태클',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DE': '디펜시브 엔드',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.MLB': '미들 라인베커',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OLB': '아웃사이드 라인베커',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.CB': '코너백',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.S': '세이프티',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.NB': '니클백',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DB': '다임백',
  'PLAYER_POSITION.BILLIARDS.S': '스누커',
  'PLAYER_POSITION.BILLIARDS.P': '풀',
  'PLAYER_POSITION.BILLIARDS.C': '캐롬',
  'PLAYER_POSITION.CRICKET.BO': '투수',
  'PLAYER_POSITION.CRICKET.BA': '타자',
  'PLAYER_POSITION.CRICKET.WI': '포수',
  'PLAYER_POSITION.SEPAK_TAKRAW.K': '킬러',
  'PLAYER_POSITION.SEPAK_TAKRAW.F': '피더',
  'PLAYER_POSITION.SEPAK_TAKRAW.T': '테콩',
  'PLAYER_POSITION.TEE_BALL.C': '본루수',
  'PLAYER_POSITION.TEE_BALL._1B': '1루수',
  'PLAYER_POSITION.TEE_BALL._2B': '2루수',
  'PLAYER_POSITION.TEE_BALL._3B': '3루수',
  'PLAYER_POSITION.TEE_BALL._1SS': '제1유격수',
  'PLAYER_POSITION.TEE_BALL._2SS': '제2유격수',
  'PLAYER_POSITION.TEE_BALL.LF': '좌익수',
  'PLAYER_POSITION.TEE_BALL._1CF': '제1중견수',
  'PLAYER_POSITION.TEE_BALL._2CF': '제2중견수',
  'PLAYER_POSITION.TEE_BALL.RF': '우익수',
  'PLAYER_POSITION.FOOT_VOLLEYBALL.A': '공격수',
  'PLAYER_POSITION.FOOT_VOLLEYBALL.S': '세타',
  'PLAYER_POSITION.FOOT_VOLLEYBALL.D': '수비수',
  'PLAYER_POSITION.FUTSAL.PIVO': '피보',
  'PLAYER_POSITION.FUTSAL.ALA': '아라',
  'PLAYER_POSITION.FUTSAL.FIXO': '픽소',
  'PLAYER_POSITION.FUTSAL.GOLEIRO': '골레이로',
  'PLAYER_POSITION.SWIMMING.FREESTYLE': '자유형',
  'PLAYER_POSITION.SWIMMING.BACKSTROKE': '배영',
  'PLAYER_POSITION.SWIMMING.BREASTSTROKE': '평영',
  'PLAYER_POSITION.SWIMMING.BUTTERFLY': '접영',
  'PLAYER_POSITION.SWIMMING.MEDLEY': '혼영',
  'PLAYER_POSITION.CANOE.SLALOM': '슬라럼',
  'PLAYER_POSITION.CANOE.SPRINT': '스프린트',
  'PLAYER_POSITION.KAYAK.SLALOM': '슬라럼',
  'PLAYER_POSITION.KAYAK.SPRINT': '스프린트',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.VAULT': '도마',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.FLOOR_EXERCISES': '마루',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.RINGS': '링',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.HORIZONTAL_BAR': '철봉',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.PARALLEL_BAR': '평행봉',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.POMMEL_HORSE': '안마',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.BALANCE_BEAM': '평균대',
  'PLAYER_POSITION.ICE_SKATING.SPEED_SKATING': '스피드 스케이팅',
  'PLAYER_POSITION.ICE_SKATING.FIGURE_SKATING': '피겨 스케이팅',
  'PLAYER_POSITION.ICE_SKATING.SHORT_TRACK': '쇼트트랙',
  'PLAYER_POSITION.ICE_HOCKEY.G': '골텐더',
  'PLAYER_POSITION.ICE_HOCKEY.RD': '라이트 디펜스',
  'PLAYER_POSITION.ICE_HOCKEY.LD': '레프트 디펜스',
  'PLAYER_POSITION.ICE_HOCKEY.RW': '라이트 윙',
  'PLAYER_POSITION.ICE_HOCKEY.CF': '센터 포워드',
  'PLAYER_POSITION.ICE_HOCKEY.LW': '레프트 윙',
  'PLAYER_POSITION.CURLING.LEAD': '리드',
  'PLAYER_POSITION.CURLING.SECOND': '세컨',
  'PLAYER_POSITION.CURLING.THIRD': '서드',
  'PLAYER_POSITION.CURLING.SKIP': '스킵',
  'PLAYER_POSITION.FENCING.FLEURET': '플뢰레',
  'PLAYER_POSITION.FENCING.SABRE': '사브레',
  'PLAYER_POSITION.FENCING.EPEE': '에페',

  // coach positions
  'COACH_POSITION.HEAD_COACH': '감독',
  'COACH_POSITION.FIRST_COACH': '수석 코치',
  'COACH_POSITION.COACH': '코치',
  'COACH_POSITION.PLAYING_COACH': '플레잉 코치',
  'COACH_POSITION.ATHLETIC_TRAINER': '의무 트레이너',
  'COACH_POSITION.GK_COACH': '골키퍼 코치',
  'COACH_POSITION.TACTICAL_COACH': '전술 코치',
  'COACH_POSITION.FITNESS_COACH': '피지컬 코치',
  'COACH_POSITION.ASSISTANT_COACH': '보조 코치',
  'COACH_POSITION.MANAGER': '감독',
  'COACH_POSITION.BENCH_COACH': '벤치 코치',
  'COACH_POSITION.HITTING_COACH': '타격 코치',
  'COACH_POSITION.PITCHING_COACH': '투수 코치',
  'COACH_POSITION.DEFENSIVE_COACH': '수비 코치',
  'COACH_POSITION.RUNNING_COACH': '주루 코치',
  'COACH_POSITION.SnC_COACH': 'S&C 코치',
  'COACH_POSITION.SWIMMING_COACH': '수영 코치',
  'COACH_POSITION.BIKE_COACH': '바이크 코치',

  // sports categories
  'SPORTS_CATEGORY_GROUP.ATHLETICS': '육상',
  'SPORTS_CATEGORY.SPRINT': '단거리 달리기',
  'SPORTS_CATEGORY.MIDDLE_DISTANCE_RUNNING': '중거리 달리기',
  'SPORTS_CATEGORY.LONG_DISTANCE_RUNNING': '장거리 달리기',
  'SPORTS_CATEGORY.HURDLE': '장애물 달리기',
  'SPORTS_CATEGORY.RACE_WALKING': '경보',
  'SPORTS_CATEGORY.MARATHON': '마라톤',
  'SPORTS_CATEGORY.LONG_JUMP': '멀리뛰기',
  'SPORTS_CATEGORY.TRIPLE_JUMP': '3단뛰기',
  'SPORTS_CATEGORY.HIGH_JUMP': '높이뛰기',
  'SPORTS_CATEGORY.POLE_VAULT': '장대높이뛰기',
  'SPORTS_CATEGORY.SHOT_PUT': '포환던지기',
  'SPORTS_CATEGORY.DISCUS_THROW': '원반던지기',
  'SPORTS_CATEGORY.JAVELIN_THROW': '창던지기',
  'SPORTS_CATEGORY.HAMMER_THROW': '해머던지기',
  'SPORTS_CATEGORY.MEN_DECATHLON': '남자 10종경기',
  'SPORTS_CATEGORY.WOMEN_HEPTATHLON': '여자 7종경기',
  'SPORTS_CATEGORY_GROUP.BALL': '구기',
  'SPORTS_CATEGORY.SOCCER': '축구',
  'SPORTS_CATEGORY.BASKETBALL': '농구',
  'SPORTS_CATEGORY.VOLLEYBALL': '배구',
  'SPORTS_CATEGORY.HANDBALL': '핸드볼',
  'SPORTS_CATEGORY.BASEBALL': '야구',
  'SPORTS_CATEGORY.FIELD_HOCKEY': '필드하키',
  'SPORTS_CATEGORY.RUGBY': '럭비',
  'SPORTS_CATEGORY.AMERICAN_FOOTBALL': '미식 축구',
  'SPORTS_CATEGORY.BILLIARDS': '당구',
  'SPORTS_CATEGORY.GOLF': '골프',
  'SPORTS_CATEGORY.CRICKET': '크리켓',
  'SPORTS_CATEGORY.BOCCIA': '보치아',
  'SPORTS_CATEGORY.SEPAK_TAKRAW': '세팍타크로',
  'SPORTS_CATEGORY.TEE_BALL': '티볼',
  'SPORTS_CATEGORY.FOOT_VOLLEYBALL': '족구',
  'SPORTS_CATEGORY.FUTSAL': '풋살',
  'SPORTS_CATEGORY.BOWLING': '볼링',
  'SPORTS_CATEGORY_GROUP.RACKET': '라켓',
  'SPORTS_CATEGORY.RACQUETBALL': '라켓볼',
  'SPORTS_CATEGORY.BADMINTON': '배드민턴',
  'SPORTS_CATEGORY.SQUASH': '스쿼시',
  'SPORTS_CATEGORY.SOFT_TENNIS': '정구',
  'SPORTS_CATEGORY.TABLE_TENNIS': '탁구',
  'SPORTS_CATEGORY.TENNIS': '테니스',
  'SPORTS_CATEGORY_GROUP.WATER': '수상',
  'SPORTS_CATEGORY.SWIMMING': '수영',
  'SPORTS_CATEGORY.DIVING': '다이빙',
  'SPORTS_CATEGORY.WATER_POLO': '수구',
  'SPORTS_CATEGORY.SYNCHRONIZE': '싱크로나이즈드',
  'SPORTS_CATEGORY.ROWING': '조정',
  'SPORTS_CATEGORY.CANOE': '카누',
  'SPORTS_CATEGORY.KAYAK': '카약',
  'SPORTS_CATEGORY.FINSWIMMING': '핀수영',
  'SPORTS_CATEGORY_GROUP.COMBAT': '격투',
  'SPORTS_CATEGORY.TAEKWONDO': '태권도',
  'SPORTS_CATEGORY.TAEKKYEON': '택견',
  'SPORTS_CATEGORY.KUNG_FU': '쿵푸',
  'SPORTS_CATEGORY.SSIREUM': '씨름',
  'SPORTS_CATEGORY.KARATE': '가라데',
  'SPORTS_CATEGORY.JUDO': '유도',
  'SPORTS_CATEGORY.BOXING': '복싱',
  'SPORTS_CATEGORY.KICKBOXING': '킥복싱',
  'SPORTS_CATEGORY.WRESTLING': '레슬링',
  'SPORTS_CATEGORY.ARM_WRESTLING': '팔씨름',
  'SPORTS_CATEGORY_GROUP.GYMNASTICS': '체조',
  'SPORTS_CATEGORY.ARTISTIC_GYMNASTICS': '기계체조',
  'SPORTS_CATEGORY.RHYTHMIC_GYMNASTICS': '리듬체조',
  'SPORTS_CATEGORY.TRAMPOLINE': '트램펄린',
  'SPORTS_CATEGORY.AEROBICS': '에어로빅',
  'SPORTS_CATEGORY_GROUP.WEIGHTLIFTING': '역도',
  'SPORTS_CATEGORY.WEIGHTLIFTING': '역도',
  'SPORTS_CATEGORY_GROUP.EQUESTRIAN': '승마',
  'SPORTS_CATEGORY.EQUESTRIAN': '승마',
  'SPORTS_CATEGORY_GROUP.CYCLING': '사이클',
  'SPORTS_CATEGORY.CYCLING': '사이클',
  'SPORTS_CATEGORY_GROUP.WINTER': '동계',
  'SPORTS_CATEGORY.ICE_SKATING': '아이스 스케이팅',
  'SPORTS_CATEGORY.ICE_HOCKEY': '아이스 하키',
  'SPORTS_CATEGORY.CURLING': '컬링',
  'SPORTS_CATEGORY.SKIING': '스키',
  'SPORTS_CATEGORY.ALPINE_SKIING': '알파인 스키',
  'SPORTS_CATEGORY.NORDIC_SKIING': '노르딕 스키',
  'SPORTS_CATEGORY.FREESTYLE_SKIING': '프리스타일 스키',
  'SPORTS_CATEGORY.CROSS_COUNTRY_SKIING': '크로스컨트리',
  'SPORTS_CATEGORY.SKI_JUMPING': '스키점프',
  'SPORTS_CATEGORY.BIATHLON': '바이애슬론',
  'SPORTS_CATEGORY.SNOWBOARDING': '스노우보드',
  'SPORTS_CATEGORY.BOBSLEIGH': '봅슬레이',
  'SPORTS_CATEGORY.LUGE': '루지',
  'SPORTS_CATEGORY.SKELETON': '스켈레톤',
  'SPORTS_CATEGORY_GROUP.ARCHERY': '양궁',
  'SPORTS_CATEGORY.ARCHERY': '양궁',
  'SPORTS_CATEGORY_GROUP.FENCING': '펜싱',
  'SPORTS_CATEGORY.FENCING': '펜싱',
  'SPORTS_CATEGORY_GROUP.SHOOTING': '사격',
  'SPORTS_CATEGORY.SHOOTING': '사격',
  'SPORTS_CATEGORY_GROUP.ROLLER': '롤러',
  'SPORTS_CATEGORY.ROLLER': '롤러',
  'SPORTS_CATEGORY_GROUP.MODERN_PENTATHLON': '근대 5종',
  'SPORTS_CATEGORY.MODERN_PENTATHLON': '근대 5종',
  'SPORTS_CATEGORY_GROUP.ETC': '기타',
  'SPORTS_CATEGORY.ETC': '기타',
  'SPORTS_CATEGORY_GROUP.TRIATHLON': '트라이애슬론',
  'SPORTS_CATEGORY.TRIATHLON': '트라이애슬론',

  // team authorities
  'TEAM_AUTHORITY.OWNER': '소유자',
  'TEAM_AUTHORITY.ADMIN': '관리자',
  'TEAM_AUTHORITY.MEMBER': '멤버',
  'TEAM_AUTHORITY.VIEWER': '뷰어',

  // country codes
  'COUNTRY_CODE.AD': '안도라',
  'COUNTRY_CODE.AE': '아랍에미리트',
  'COUNTRY_CODE.AF': '아프가니스탄',
  'COUNTRY_CODE.AG': '앤티가 바부다',
  'COUNTRY_CODE.AI': '앵귈라',
  'COUNTRY_CODE.AL': '알바니아',
  'COUNTRY_CODE.AM': '아르메니아',
  'COUNTRY_CODE.AO': '앙골라',
  'COUNTRY_CODE.AQ': '남극',
  'COUNTRY_CODE.AR': '아르헨티나',
  'COUNTRY_CODE.AS': '아메리칸사모아',
  'COUNTRY_CODE.AT': '오스트리아',
  'COUNTRY_CODE.AU': '오스트레일리아',
  'COUNTRY_CODE.AW': '아루바',
  'COUNTRY_CODE.AX': '올란드 제도',
  'COUNTRY_CODE.AZ': '아제르바이잔',
  'COUNTRY_CODE.BA': '보스니아 헤르체고비나',
  'COUNTRY_CODE.BB': '바베이도스',
  'COUNTRY_CODE.BD': '방글라데시',
  'COUNTRY_CODE.BE': '벨기에',
  'COUNTRY_CODE.BF': '부르키나파소',
  'COUNTRY_CODE.BG': '불가리아',
  'COUNTRY_CODE.BH': '바레인',
  'COUNTRY_CODE.BI': '부룬디',
  'COUNTRY_CODE.BJ': '베냉',
  'COUNTRY_CODE.BL': '생바르텔레미',
  'COUNTRY_CODE.BM': '버뮤다',
  'COUNTRY_CODE.BN': '브루나이',
  'COUNTRY_CODE.BO': '볼리비아',
  'COUNTRY_CODE.BQ': '카리브 네덜란드',
  'COUNTRY_CODE.BR': '브라질',
  'COUNTRY_CODE.BS': '바하마',
  'COUNTRY_CODE.BT': '부탄',
  'COUNTRY_CODE.BV': '부베 섬',
  'COUNTRY_CODE.BW': '보츠와나',
  'COUNTRY_CODE.BY': '벨라루스',
  'COUNTRY_CODE.BZ': '벨리즈',
  'COUNTRY_CODE.CA': '캐나다',
  'COUNTRY_CODE.CC': '코코스 제도',
  'COUNTRY_CODE.CD': '콩고 민주 공화국',
  'COUNTRY_CODE.CF': '중앙아프리카 공화국',
  'COUNTRY_CODE.CG': '콩고 공화국',
  'COUNTRY_CODE.CH': '스위스',
  'COUNTRY_CODE.CI': '코트디부아르',
  'COUNTRY_CODE.CK': '쿡 제도',
  'COUNTRY_CODE.CL': '칠레',
  'COUNTRY_CODE.CM': '카메룬',
  'COUNTRY_CODE.CN': '중화인민공화국',
  'COUNTRY_CODE.CO': '콜롬비아',
  'COUNTRY_CODE.CR': '코스타리카',
  'COUNTRY_CODE.CU': '쿠바',
  'COUNTRY_CODE.CV': '카보베르데',
  'COUNTRY_CODE.CW': '퀴라소',
  'COUNTRY_CODE.CX': '크리스마스 섬',
  'COUNTRY_CODE.CY': '키프로스',
  'COUNTRY_CODE.CZ': '체코',
  'COUNTRY_CODE.DE': '독일',
  'COUNTRY_CODE.DJ': '지부티',
  'COUNTRY_CODE.DK': '덴마크',
  'COUNTRY_CODE.DM': '도미니카 연방',
  'COUNTRY_CODE.DO': '도미니카 공화국',
  'COUNTRY_CODE.DZ': '알제리',
  'COUNTRY_CODE.EC': '에콰도르',
  'COUNTRY_CODE.EE': '에스토니아',
  'COUNTRY_CODE.EG': '이집트',
  'COUNTRY_CODE.EH': '서사하라',
  'COUNTRY_CODE.ER': '에리트레아',
  'COUNTRY_CODE.ES': '스페인',
  'COUNTRY_CODE.ET': '에티오피아',
  'COUNTRY_CODE.FI': '핀란드',
  'COUNTRY_CODE.FJ': '피지',
  'COUNTRY_CODE.FK': '포클랜드 제도',
  'COUNTRY_CODE.FM': '미크로네시아 연방',
  'COUNTRY_CODE.FO': '페로 제도',
  'COUNTRY_CODE.FR': '프랑스',
  'COUNTRY_CODE.GA': '가봉',
  'COUNTRY_CODE.GB': '영국',
  'COUNTRY_CODE.GD': '그레나다',
  'COUNTRY_CODE.GE': '조지아',
  'COUNTRY_CODE.GF': '프랑스령 기아나',
  'COUNTRY_CODE.GG': '건지 섬',
  'COUNTRY_CODE.GH': '가나',
  'COUNTRY_CODE.GI': '지브롤터',
  'COUNTRY_CODE.GL': '그린란드',
  'COUNTRY_CODE.GM': '감비아',
  'COUNTRY_CODE.GN': '기니',
  'COUNTRY_CODE.GP': '과들루프',
  'COUNTRY_CODE.GQ': '적도 기니',
  'COUNTRY_CODE.GR': '그리스',
  'COUNTRY_CODE.GS': '사우스조지아 사우스샌드위치 제도',
  'COUNTRY_CODE.GT': '과테말라',
  'COUNTRY_CODE.GU': '괌',
  'COUNTRY_CODE.GW': '기니비사우',
  'COUNTRY_CODE.GY': '가이아나',
  'COUNTRY_CODE.HK': '홍콩',
  'COUNTRY_CODE.HM': '허드 맥도널드 제도',
  'COUNTRY_CODE.HN': '온두라스',
  'COUNTRY_CODE.HR': '크로아티아',
  'COUNTRY_CODE.HT': '아이티',
  'COUNTRY_CODE.HU': '헝가리',
  'COUNTRY_CODE.ID': '인도네시아',
  'COUNTRY_CODE.IE': '아일랜드',
  'COUNTRY_CODE.IL': '이스라엘',
  'COUNTRY_CODE.IM': '맨 섬',
  'COUNTRY_CODE.IN': '인도',
  'COUNTRY_CODE.IO': '영국령 인도양 지역',
  'COUNTRY_CODE.IQ': '이라크',
  'COUNTRY_CODE.IR': '이란',
  'COUNTRY_CODE.IS': '아이슬란드',
  'COUNTRY_CODE.IT': '이탈리아',
  'COUNTRY_CODE.JE': '저지 섬',
  'COUNTRY_CODE.JM': '자메이카',
  'COUNTRY_CODE.JO': '요르단',
  'COUNTRY_CODE.JP': '일본',
  'COUNTRY_CODE.KE': '케냐',
  'COUNTRY_CODE.KG': '키르기스스탄',
  'COUNTRY_CODE.KH': '캄보디아',
  'COUNTRY_CODE.KI': '키리바시',
  'COUNTRY_CODE.KM': '코모로',
  'COUNTRY_CODE.KN': '세인트키츠 네비스',
  'COUNTRY_CODE.KP': '조선민주주의인민공화국',
  'COUNTRY_CODE.KR': '대한민국',
  'COUNTRY_CODE.KW': '쿠웨이트',
  'COUNTRY_CODE.KY': '케이맨 제도',
  'COUNTRY_CODE.KZ': '카자흐스탄',
  'COUNTRY_CODE.LA': '라오스',
  'COUNTRY_CODE.LB': '레바논',
  'COUNTRY_CODE.LC': '세인트루시아',
  'COUNTRY_CODE.LI': '리히텐슈타인',
  'COUNTRY_CODE.LK': '스리랑카',
  'COUNTRY_CODE.LR': '라이베리아',
  'COUNTRY_CODE.LS': '레소토',
  'COUNTRY_CODE.LT': '리투아니아',
  'COUNTRY_CODE.LU': '룩셈부르크',
  'COUNTRY_CODE.LV': '라트비아',
  'COUNTRY_CODE.LY': '리비아',
  'COUNTRY_CODE.MA': '모로코',
  'COUNTRY_CODE.MC': '모나코',
  'COUNTRY_CODE.MD': '몰도바',
  'COUNTRY_CODE.ME': '몬테네그로',
  'COUNTRY_CODE.MF': '생마르탱',
  'COUNTRY_CODE.MG': '마다가스카르',
  'COUNTRY_CODE.MH': '마셜 제도',
  'COUNTRY_CODE.MK': '마케도니아 공화국',
  'COUNTRY_CODE.ML': '말리',
  'COUNTRY_CODE.MM': '미얀마',
  'COUNTRY_CODE.MN': '몽골',
  'COUNTRY_CODE.MO': '마카오',
  'COUNTRY_CODE.MP': '북마리아나 제도',
  'COUNTRY_CODE.MQ': '마르티니크',
  'COUNTRY_CODE.MR': '모리타니',
  'COUNTRY_CODE.MS': '몬트세랫',
  'COUNTRY_CODE.MT': '몰타',
  'COUNTRY_CODE.MU': '모리셔스',
  'COUNTRY_CODE.MV': '몰디브',
  'COUNTRY_CODE.MW': '말라위',
  'COUNTRY_CODE.MX': '멕시코',
  'COUNTRY_CODE.MY': '말레이시아',
  'COUNTRY_CODE.MZ': '모잠비크',
  'COUNTRY_CODE.NA': '나미비아',
  'COUNTRY_CODE.NC': '누벨칼레도니',
  'COUNTRY_CODE.NE': '니제르',
  'COUNTRY_CODE.NF': '노퍽 섬',
  'COUNTRY_CODE.NG': '나이지리아',
  'COUNTRY_CODE.NI': '니카라과',
  'COUNTRY_CODE.NL': '네덜란드',
  'COUNTRY_CODE.NO': '노르웨이',
  'COUNTRY_CODE.NP': '네팔',
  'COUNTRY_CODE.NR': '나우루',
  'COUNTRY_CODE.NU': '니우에',
  'COUNTRY_CODE.NZ': '뉴질랜드',
  'COUNTRY_CODE.OM': '오만',
  'COUNTRY_CODE.PA': '파나마',
  'COUNTRY_CODE.PE': '페루',
  'COUNTRY_CODE.PF': '프랑스령 폴리네시아',
  'COUNTRY_CODE.PG': '파푸아 뉴기니',
  'COUNTRY_CODE.PH': '필리핀',
  'COUNTRY_CODE.PK': '파키스탄',
  'COUNTRY_CODE.PL': '폴란드',
  'COUNTRY_CODE.PM': '생피에르 미클롱',
  'COUNTRY_CODE.PN': '핏케언 제도',
  'COUNTRY_CODE.PR': '푸에르토리코',
  'COUNTRY_CODE.PS': '팔레스타인',
  'COUNTRY_CODE.PT': '포르투갈',
  'COUNTRY_CODE.PW': '팔라우',
  'COUNTRY_CODE.PY': '파라과이',
  'COUNTRY_CODE.QA': '카타르',
  'COUNTRY_CODE.RE': '레위니옹',
  'COUNTRY_CODE.RO': '루마니아',
  'COUNTRY_CODE.RS': '세르비아',
  'COUNTRY_CODE.RU': '러시아',
  'COUNTRY_CODE.RW': '르완다',
  'COUNTRY_CODE.SA': '사우디아라비아',
  'COUNTRY_CODE.SB': '솔로몬 제도',
  'COUNTRY_CODE.SC': '세이셸',
  'COUNTRY_CODE.SD': '수단',
  'COUNTRY_CODE.SE': '스웨덴',
  'COUNTRY_CODE.SG': '싱가포르',
  'COUNTRY_CODE.SH': '세인트헬레나',
  'COUNTRY_CODE.SI': '슬로베니아',
  'COUNTRY_CODE.SJ': '스발바르 얀마옌',
  'COUNTRY_CODE.SK': '슬로바키아',
  'COUNTRY_CODE.SL': '시에라리온',
  'COUNTRY_CODE.SM': '산마리노',
  'COUNTRY_CODE.SN': '세네갈',
  'COUNTRY_CODE.SO': '소말리아',
  'COUNTRY_CODE.SR': '수리남',
  'COUNTRY_CODE.SS': '남수단',
  'COUNTRY_CODE.ST': '상투메 프린시페',
  'COUNTRY_CODE.SV': '엘살바도르',
  'COUNTRY_CODE.SX': '신트마르턴',
  'COUNTRY_CODE.SY': '시리아',
  'COUNTRY_CODE.SZ': '스와질란드',
  'COUNTRY_CODE.TC': '터크스 케이커스 제도',
  'COUNTRY_CODE.TD': '차드',
  'COUNTRY_CODE.TF': '프랑스령 남부와 남극 지역',
  'COUNTRY_CODE.TG': '토고',
  'COUNTRY_CODE.TH': '타이',
  'COUNTRY_CODE.TJ': '타지키스탄',
  'COUNTRY_CODE.TK': '토켈라우',
  'COUNTRY_CODE.TL': '동티모르',
  'COUNTRY_CODE.TM': '투르크메니스탄',
  'COUNTRY_CODE.TN': '튀니지',
  'COUNTRY_CODE.TO': '통가',
  'COUNTRY_CODE.TR': '터키',
  'COUNTRY_CODE.TT': '트리니다드 토바고',
  'COUNTRY_CODE.TV': '투발루',
  'COUNTRY_CODE.TW': '중화민국',
  'COUNTRY_CODE.TZ': '탄자니아',
  'COUNTRY_CODE.UA': '우크라이나',
  'COUNTRY_CODE.UG': '우간다',
  'COUNTRY_CODE.UM': '미국령 군소 제도',
  'COUNTRY_CODE.US': '미국',
  'COUNTRY_CODE.UY': '우루과이',
  'COUNTRY_CODE.UZ': '우즈베키스탄',
  'COUNTRY_CODE.VA': '바티칸 시국',
  'COUNTRY_CODE.VC': '세인트빈센트 그레나딘',
  'COUNTRY_CODE.VE': '베네수엘라',
  'COUNTRY_CODE.VG': '영국령 버진아일랜드',
  'COUNTRY_CODE.VI': '미국령 버진아일랜드',
  'COUNTRY_CODE.VN': '베트남',
  'COUNTRY_CODE.VU': '바누아투',
  'COUNTRY_CODE.WF': '왈리스 퓌튀나',
  'COUNTRY_CODE.WS': '사모아',
  'COUNTRY_CODE.YE': '예멘',
  'COUNTRY_CODE.YT': '마요트',
  'COUNTRY_CODE.ZA': '남아프리카 공화국',
  'COUNTRY_CODE.ZM': '잠비아',
  'COUNTRY_CODE.ZW': '짐바브웨',

  // body parts
  'BODY_PART.HEAD': '머리 부분',
  'BODY_PART.TORSO': '몸 부분',
  'BODY_PART.RIGHT_ARM': '오른팔 부분',
  'BODY_PART.LEFT_ARM': '왼팔 부분',
  'BODY_PART.RIGHT_LEG': '오른다리 부분',
  'BODY_PART.LEFT_LEG': '왼다리 부분',
  'BODY_PART.FORE_HEAD': '이마',
  'BODY_PART.FACE': '얼굴',
  'BODY_PART.FRONT_NECK': '목',
  'BODY_PART.BACK_HEAD': '머리',
  'BODY_PART.BACK_NECK': '경추',
  'BODY_PART.RIGHT_CHEST': '오른쪽 가슴',
  'BODY_PART.LEFT_CHEST': '왼쪽 가슴',
  'BODY_PART.UPPER_ABDOMEN': '윗배',
  'BODY_PART.LOWER_ABDOMEN': '아랫배',
  'BODY_PART.RIGHT_RIB': '오른쪽 옆구리',
  'BODY_PART.LEFT_RIB': '왼쪽 옆구리',
  'BODY_PART.REPRODUCTIVE_ORGANS': '생식기',
  'BODY_PART.RIGHT_WING': '오른쪽 날개',
  'BODY_PART.LEFT_WING': '왼쪽 날개',
  'BODY_PART.UPPER_BACK': '위쪽 등',
  'BODY_PART.MIDDLE_BACK': '아래쪽 등',
  'BODY_PART.LOWER_BACK': '허리',
  'BODY_PART.TAILBONE': '꼬리뼈',
  'BODY_PART.RIGHT_SHOULDER': '오른쪽 어깨',
  'BODY_PART.RIGHT_UPPER_ARM': '오른쪽 위팔',
  'BODY_PART.RIGHT_FORE_ARM': '오른쪽 아래팔',
  'BODY_PART.RIGHT_WRIST': '오른쪽 손목',
  'BODY_PART.RIGHT_PALM': '오른쪽 손바닥',
  'BODY_PART.RIGHT_FINGER': '오른쪽 손가락',
  'BODY_PART.RIGHT_ELBOW': '오른쪽 팔꿈치',
  'BODY_PART.RIGHT_BACK_HAND': '오른쪽 손등',
  'BODY_PART.LEFT_SHOULDER': '왼쪽 어깨',
  'BODY_PART.LEFT_UPPER_ARM': '왼쪽 위팔',
  'BODY_PART.LEFT_FORE_ARM': '왼쪽 아래팔',
  'BODY_PART.LEFT_WRIST': '왼쪽 손목',
  'BODY_PART.LEFT_PALM': '왼쪽 손바닥',
  'BODY_PART.LEFT_FINGER': '왼쪽 손가락',
  'BODY_PART.LEFT_ELBOW': '왼쪽 팔꿈치',
  'BODY_PART.LEFT_BACK_HAND': '왼쪽 손등',
  'BODY_PART.RIGHT_HIP_JOINT': '오른쪽 고관절',
  'BODY_PART.RIGHT_GROIN': '오른쪽 사타구니',
  'BODY_PART.RIGHT_FRONT_THIGH': '오른쪽 허벅지',
  'BODY_PART.RIGHT_FRONT_KNEE': '오른쪽 무릎',
  'BODY_PART.RIGHT_FRONT_LEG': '오른쪽 정강이',
  'BODY_PART.RIGHT_ANKLE': '오른쪽 발목',
  'BODY_PART.RIGHT_INSTEP': '오른쪽 발등',
  'BODY_PART.RIGHT_TOE': '오른쪽 발가락',
  'BODY_PART.RIGHT_HIP': '오른쪽 엉덩이',
  'BODY_PART.RIGHT_BACK_THIGH': '오른쪽 뒷허벅지',
  'BODY_PART.RIGHT_BACK_KNEE': '오른쪽 오금',
  'BODY_PART.RIGHT_BACK_LEG': '오른쪽 종아리',
  'BODY_PART.RIGHT_ACHILLES_TENDON': '오른쪽 아킬레스건',
  'BODY_PART.RIGHT_SOLE': '오른쪽 발바닥',
  'BODY_PART.LEFT_HIP_JOINT': '왼쪽 고관절',
  'BODY_PART.LEFT_GROIN': '왼쪽 사타구니',
  'BODY_PART.LEFT_FRONT_THIGH': '왼쪽 허벅지',
  'BODY_PART.LEFT_FRONT_KNEE': '왼쪽 무릎',
  'BODY_PART.LEFT_FRONT_LEG': '왼쪽 정강이',
  'BODY_PART.LEFT_ANKLE': '왼쪽 발목',
  'BODY_PART.LEFT_INSTEP': '왼쪽 발등',
  'BODY_PART.LEFT_TOE': '왼쪽 발가락',
  'BODY_PART.LEFT_HIP': '왼쪽 엉덩이',
  'BODY_PART.LEFT_BACK_THIGH': '왼쪽 뒷허벅지',
  'BODY_PART.LEFT_BACK_KNEE': '왼쪽 오금',
  'BODY_PART.LEFT_BACK_LEG': '왼쪽 종아리',
  'BODY_PART.LEFT_ACHILLES_TENDON': '왼쪽 아킬레스건',
  'BODY_PART.LEFT_SOLE': '왼쪽 발바닥',

  // soreness level
  SORENESS_LEVEL_0: '통증없음',
  SORENESS_LEVEL_1: '1단계',
  SORENESS_LEVEL_2: '2단계',
  SORENESS_LEVEL_3: '3단계',
  SORENESS_LEVEL_4: '4단계',
  SORENESS_LEVEL_5: '5단계',

  // injury quality
  SHARP: '날카로운',
  BURNING: '타는듯한',
  CUTTING: '칼로 벤 것처럼 아픈',
  TINGLING: '쑤시는',
  COMES_AND_GOES: '아팠다 안 아팠다 하는',
  DULL: '둔한',
  PRESSING: '누르는듯한',
  NUMBING: '저린',
  CRAMPING: '죄는 듯한',
  RADIATING: '다른 부위로 퍼지듯',

  // injury timing
  SUSTAINING: '일정한',
  OCCASIONAL: '간헐적인',
  GRADUAL: '점진적인',
  SUDDEN: '갑작스러운',

  // custom data labels
  DATA: '데이터',
  'DATA.NONE': '없음',
  'DATA.READINESS': '준비도',
  'DATA.WORKLOAD': '운동부하',
  'DATA.DONUT.WORKLOAD_READINESS': '종합 운동부하',
  'DATA.WORKLOAD_READINESS': '종합 운동부하 준비도',
  'DATA.WORKLOAD_TOTAL': '총 운동부하',
  'DATA.WORKLOAD_RAW': '운동부하',
  'DATA.WORKLOAD_INTENSITY': '운동강도',
  'DATA.WORKLOAD_DURATION': '운동시간',
  'DATA.WORKLOAD_SATISFACTION': '운동만족',
  'DATA.WORKLOAD_AX': '급성부하',
  'DATA.WORKLOAD_AXI': '최근 1주 운동부하 수준',
  'DATA.WORKLOAD_AXD': '단조로움',
  'DATA.WORKLOAD_CX': '만성부하',
  'DATA.WORKLOAD_ACXR': '부상 위험도(ACWR)',
  'DATA.CONDITION': '컨디션',
  'DATA.DONUT.CONDITION_READINESS': '종합 컨디션',
  'DATA.CONDITION_READINESS': '종합 컨디션 준비도',
  'DATA.CONDITION_AX': '최근 1주 컨디션',
  'DATA.CONDITION_CX': '최근 4주 컨디션',
  'DATA.WELLNESS': '웰니스',
  'DATA.WELLNESS_MUSCLE': '근육',
  'DATA.WELLNESS_MOOD': '기분',
  'DATA.WELLNESS_FATIGUE': '피로도',
  'DATA.WELLNESS_STRESS': '스트레스',
  'DATA.WELLNESS_RECOVERY': '회복',
  'DATA.SLEEP': '수면',
  'DATA.SLEEP_DURATION': '수면 시간',
  'DATA.SLEEP_QUALITY': '수면 품질',
  'DATA.RECOVERY': '회복',
  'DATA.ACWR': '부상 위험도',
  'DATA.INJURY': '부상',
  'DATA.DONUT.INJURY_READINESS': '종합 부상',
  'DATA.INJURY_READINESS': '종합 부상 준비도',
  'DATA.SORENESS_AND_INJURY': '통증',
  'DATA.SORENESS': '통증',
  'DATA.SORENESS_COUNT': '통증 개수',
  'DATA.SORENESS_AX': '최근 1주 통증 정도',
  'DATA.SORENESS_AXI': '최근 1주 통증 수준',
  'DATA.SORENESS_AXD': '최근 1주 통증 리듬',
  'DATA.SORENESS_CX': '최근 3개월 통증 정도',
  'DATA.SORENESS_ACXR': '최근 3개월 통증 수준',
  'DATA.SORENESS_CXD': '최근 3개월 통증 리듬',
  'DATA.BODY': '신체',
  'DATA.BODY_HEIGHT': '키',
  'DATA.BODY_WEIGHT': '체중',
  'DATA.BODY_BMI': '체질량지수(BMI)',
  'DATA.BODY_RECOMMENDED_HYDRATION': '권장 수분 섭취량',
  'DATA.RAW_CONDITION': '컨디션',
  'DATA.RAW_WELLNESS': '웰니스',
  'DATA.CONDITION.BODY': '근육',
  'DATA.RAW_SLEEP': '수면',
  'DATA.CONDITION.QUALITY': '품질',
  'DATA.CONDITION.DURATION': '시간',
  'DATA.WORKOUT': '운동',
  'DATA.NORMAL.WORKOUT': '정상운동',
  'DATA.NORMAL': '정상',
  'DATA.REHAB': '재활',
  'DATA.REHAB.WORKOUT': '재활운동',
  'DATA.NO.WORKOUT': '운동 안 함',
  'DATA.INJURIES': '부상',
  'DATA.REST': '휴식',
  'DATA.RAW_WORKLOAD': '운동부하',
  'DATA.WORKLOAD.INTENSITY': '강도',
  'DATA.WORKLOAD.SATISFACTION': '만족',
  'DATA.WORKLOAD.DURATION': '운동시간',
  'DATA.RAW_INJURY': '부상',
  'DATA.INJURY.SORENESS': '통증',
  'DATA.INJURY.TIMING': '시기',
  'DATA.INJURY.QUALITY': '양상',
  'DATA.INJURY.MEMO': '메모',
  'DATA.INJURY.PLAYER.MEMO': '선수메모',
  'DATA.RAW_BODY': '신체',
  'DATA.BODY.WEIGHT': '무게',
  'DATA.BODY.HEIGHT': '키',
  'DATA.STRIDE_DAY': '일별',
  'DATA.STRIDE_WEEK': '주별',
  'DATA.STRIDE_MONTH': '월별',
  'DATA.RAW_BODY.HEIGHT': '키',
  'DATA.RAW_BODY.WEIGHT': '몸무게',
  'DATA.NO.INPUT': '입력 안 함',

  // data-interpreter generated labels and descriptions
  'DATA.READINESS.[0,50).label': '매우 나쁨',
  'DATA.READINESS.[50,60).label': '나쁨',
  'DATA.READINESS.[60,75).label': '보통',
  'DATA.READINESS.[75,85).label': '좋음',
  'DATA.READINESS.[85,100].label': '매우 좋음',
  'DATA.CONDITION.[1,2).label': '매우 낮음',
  'DATA.CONDITION.[2,4).label': '낮음',
  'DATA.CONDITION.[4,7).label': '보통',
  'DATA.CONDITION.[7,9).label': '높음',
  'DATA.CONDITION.[9,10].label': '매우 높음',
  'DATA.WELLNESS_MUSCLE.[1,3).label': '매우 나쁨',
  'DATA.WELLNESS_MUSCLE.[3,5).label': '나쁨',
  'DATA.WELLNESS_MUSCLE.[5,7).label': '보통',
  'DATA.WELLNESS_MUSCLE.[7,9).label': '좋음',
  'DATA.WELLNESS_MUSCLE.[9,10].label': '매우 좋음',
  'DATA.WELLNESS_MOOD.[1,3).label': '매우 나쁨',
  'DATA.WELLNESS_MOOD.[3,5).label': '나쁨',
  'DATA.WELLNESS_MOOD.[5,7).label': '보통',
  'DATA.WELLNESS_MOOD.[7,9).label': '좋음',
  'DATA.WELLNESS_MOOD.[9,10].label': '매우 좋음',
  'DATA.WELLNESS_FATIGUE.[1,3).label': '매우 나쁨',
  'DATA.WELLNESS_FATIGUE.[3,5).label': '나쁨',
  'DATA.WELLNESS_FATIGUE.[5,7).label': '보통',
  'DATA.WELLNESS_FATIGUE.[7,9).label': '좋음',
  'DATA.WELLNESS_FATIGUE.[9,10].label': '매우 좋음',
  'DATA.WELLNESS_STRESS.[1,3).label': '매우 나쁨',
  'DATA.WELLNESS_STRESS.[3,5).label': '나쁨',
  'DATA.WELLNESS_STRESS.[5,7).label': '보통',
  'DATA.WELLNESS_STRESS.[7,9).label': '좋음',
  'DATA.WELLNESS_STRESS.[9,10].label': '매우 좋음',
  'DATA.SLEEP_DURATION.[0,300).label': '매우 나쁨',
  'DATA.SLEEP_DURATION.[300,360).label': '나쁨',
  'DATA.SLEEP_DURATION.[360,420).label': '보통',
  'DATA.SLEEP_DURATION.[420,480).label': '좋음',
  'DATA.SLEEP_DURATION.[480,720].label': '매우좋음',
  'DATA.SLEEP_QUALITY.[1,3).label': '매우 나쁨',
  'DATA.SLEEP_QUALITY.[3,5).label': '나쁨',
  'DATA.SLEEP_QUALITY.[5,7).label': '보통',
  'DATA.SLEEP_QUALITY.[7,9).label': '좋음',
  'DATA.SLEEP_QUALITY.[9,10].label': '매우 좋음',
  'DATA.CONDITION_AX.[1,2).label': '매우 낮음',
  'DATA.CONDITION_AX.[2,4).label': '낮음',
  'DATA.CONDITION_AX.[4,7).label': '보통',
  'DATA.CONDITION_AX.[7,9).label': '높음',
  'DATA.CONDITION_AX.[9,10].label': '매우 높음',
  'DATA.CONDITION_CX.[1,2).label': '매우 낮음',
  'DATA.CONDITION_CX.[2,4).label': '낮음',
  'DATA.CONDITION_CX.[4,7).label': '보통',
  'DATA.CONDITION_CX.[7,9).label': '높음',
  'DATA.CONDITION_CX.[9,10].label': '매우 높음',
  'DATA.WORKLOAD.[0,201).label': '매우 낮음',
  'DATA.WORKLOAD.[201,401).label': '낮음',
  'DATA.WORKLOAD.[401,801).label': '보통',
  'DATA.WORKLOAD.[801,1001).label': '높음',
  'DATA.WORKLOAD.[1001,Infinity].label': '매우 높음',
  'DATA.WORKLOAD_INTENSITY.[1,2).label': '매우 가벼움',
  'DATA.WORKLOAD_INTENSITY.[2,4).label': '가벼움',
  'DATA.WORKLOAD_INTENSITY.[4,7).label': '보통',
  'DATA.WORKLOAD_INTENSITY.[7,8).label': '조금 강함',
  'DATA.WORKLOAD_INTENSITY.[8,10).label': '강함',
  'DATA.WORKLOAD_INTENSITY.[10,10].label': '최대',
  'DATA.WORKLOAD_DURATION.[0,31).label': '매우 낮음',
  'DATA.WORKLOAD_DURATION.[31,61).label': '낮음',
  'DATA.WORKLOAD_DURATION.[61,121).label': '보통',
  'DATA.WORKLOAD_DURATION.[121,241).label': '높음',
  'DATA.WORKLOAD_DURATION.[241,720].label': '매우 높음',
  'DATA.WORKLOAD_SATISFACTION.[1,3).label': '매우 낮음',
  'DATA.WORKLOAD_SATISFACTION.[3,5).label': '낮음',
  'DATA.WORKLOAD_SATISFACTION.[5,7).label': '보통',
  'DATA.WORKLOAD_SATISFACTION.[7,9).label': '높음',
  'DATA.WORKLOAD_SATISFACTION.[9,10].label': '매우 높음',
  'DATA.WORKLOAD_AX.[0,201).label': '매우 낮음',
  'DATA.WORKLOAD_AX.[201,401).label': '낮음',
  'DATA.WORKLOAD_AX.[401,801).label': '보통',
  'DATA.WORKLOAD_AX.[801,1001).label': '높음',
  'DATA.WORKLOAD_AX.[1001,7200].label': '매우 높음',
  'DATA.WORKLOAD_AXD.[2,Infinity].label': '나쁨',
  'DATA.WORKLOAD_AXD.[0,2).label': '좋음',
  'DATA.WORKLOAD_AXI.[0,0.8).label': '낮음',
  'DATA.WORKLOAD_AXI.[0.8,1.31).label': '보통',
  'DATA.WORKLOAD_AXI.[1.31,1.5).label': '높음',
  'DATA.WORKLOAD_AXI.[1.5,Infinity].label': '매우 높음',
  'DATA.WORKLOAD_CX.[0,201).label': '매우 낮음',
  'DATA.WORKLOAD_CX.[201,401).label': '낮음',
  'DATA.WORKLOAD_CX.[401,801).label': '보통',
  'DATA.WORKLOAD_CX.[801,1001).label': '높음',
  'DATA.WORKLOAD_CX.[1001,7200].label': '매우 높음',
  'DATA.WORKLOAD_ACXR.[0,0.8).label': '유의',
  'DATA.WORKLOAD_ACXR.[0.8,1.31).label': '최적',
  'DATA.WORKLOAD_ACXR.[1.31,1.5).label': '주의',
  'DATA.WORKLOAD_ACXR.[1.5,Infinity].label': '위험',
  'DATA.PARTIAL_INJURY_SORENESS.[0,1).label': '통증 없음',
  'DATA.PARTIAL_INJURY_SORENESS.[1,2).label': '조금 아픔',
  'DATA.PARTIAL_INJURY_SORENESS.[2,3).label': '아픔',
  'DATA.PARTIAL_INJURY_SORENESS.[3,4).label': '많이 아픔',
  'DATA.PARTIAL_INJURY_SORENESS.[4,5).label': '매우 아픔',
  'DATA.PARTIAL_INJURY_SORENESS.[5,5].label': '부상',
  'DATA.BODY_BMI.[0,18.5).label': '저체중',
  'DATA.BODY_BMI.[18.5,23).label': '정상 체중',
  'DATA.BODY_BMI.[23,25).label': '과체중',
  'DATA.BODY_BMI.[25,30).label': '경도 비만',
  'DATA.BODY_BMI.[30,40).label': '중정도 비만',
  'DATA.BODY_BMI.[40,Infinity].label': '고도 비만',

  'DATA.READINESS.[0,50).description':
    '준비도가 나쁩니다. 훈련이나 경기 참여를 위한 컨디션 관리가 부족합니다. 피로도, 수면 등 컨디션 항목별 점검이 필요합니다.',
  'DATA.READINESS.[50,60).description':
    '준비도가 나쁩니다. 훈련이나 경기 참여를 위한 컨디션 관리가 부족합니다. 피로도, 수면 등 컨디션 항목별 점검이 필요합니다.',
  'DATA.READINESS.[60,75).description': undefined,
  'DATA.READINESS.[75,85).description':
    '준비도가 좋습니다. 훈련이나 경기 참여를 위한 컨디션 관리가 적절합니다. 지속적으로 나의 준비 상태를 확인하세요.',
  'DATA.READINESS.[85,100].description':
    '준비도가 좋습니다. 훈련이나 경기 참여를 위한 컨디션 관리가 적절합니다. 지속적으로 나의 준비 상태를 확인하세요.',
  'DATA.CONDITION.[1,2).description':
    '컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.',
  'DATA.CONDITION.[2,4).description':
    '컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.',
  'DATA.CONDITION.[4,7).description': undefined,
  'DATA.CONDITION.[7,9).description':
    '컨디션이 좋습니다. 근육, 심리, 회복의 적절한 균형의 상태입니다.',
  'DATA.CONDITION.[9,10].description':
    '컨디션이 좋습니다. 근육, 심리, 회복의 적절한 균형의 상태입니다.',
  'DATA.WELLNESS_MUSCLE.[1,3).description':
    '근육 상태가 나쁩니다. 현재 상태로 운동을 수행하면 부상의 위험이 있습니다. 휴식 또는 가벼운 러닝으로 근육에 피로를 낮춰보세요.',
  'DATA.WELLNESS_MUSCLE.[3,5).description':
    '근육 상태가 나쁩니다. 현재 상태로 운동을 수행하면 부상의 위험이 있습니다. 휴식 또는 가벼운 러닝으로 근육에 피로를 낮춰보세요.',
  'DATA.WELLNESS_MUSCLE.[5,7).description': undefined,
  'DATA.WELLNESS_MUSCLE.[7,9).description': undefined,
  'DATA.WELLNESS_MUSCLE.[9,10].description': undefined,
  'DATA.WELLNESS_MOOD.[1,3).description':
    '기분 상태가 나쁩니다. 장시간 지속되지 않도록 해야 합니다. 기분이 좋지 못하면 근육이 평소보다 긴장되어 지친 느낌이 빠르게 나타납니다.',
  'DATA.WELLNESS_MOOD.[3,5).description':
    '기분 상태가 나쁩니다. 장시간 지속되지 않도록 해야 합니다. 기분이 좋지 못하면 근육이 평소보다 긴장되어 지친 느낌이 빠르게 나타납니다.',
  'DATA.WELLNESS_MOOD.[5,7).description': undefined,
  'DATA.WELLNESS_MOOD.[7,9).description': undefined,
  'DATA.WELLNESS_MOOD.[9,10].description': undefined,
  'DATA.WELLNESS_FATIGUE.[1,3).description':
    '장기적인 피로도는 경기력 저하에 영향을 줍니다. 피로도 관리가 필요합니다.',
  'DATA.WELLNESS_FATIGUE.[3,5).description':
    '장기적인 피로도는 경기력 저하에 영향을 줍니다. 피로도 관리가 필요합니다.',
  'DATA.WELLNESS_FATIGUE.[5,7).description': undefined,
  'DATA.WELLNESS_FATIGUE.[7,9).description': undefined,
  'DATA.WELLNESS_FATIGUE.[9,10].description': undefined,
  'DATA.WELLNESS_STRESS.[1,3).description':
    '스트레스는 근육에 긴장을 주며 부상의 위험성을 높이고 재활을 방해합니다. 원인과 대처 방법을 생각하세요.',
  'DATA.WELLNESS_STRESS.[3,5).description':
    '스트레스는 근육에 긴장을 주며 부상의 위험성을 높이고 재활을 방해합니다. 원인과 대처 방법을 생각하세요.',
  'DATA.WELLNESS_STRESS.[5,7).description': undefined,
  'DATA.WELLNESS_STRESS.[7,9).description': undefined,
  'DATA.WELLNESS_STRESS.[9,10].description': undefined,
  'DATA.SLEEP_DURATION.[0,300).description':
    '수면 시간이 적습니다. 수면시간이 적으면 근육 회복 속도가 느려지고 체력이 저하됩니다. 또 부상위험을 증가시키고 집중력이 저하될 수 있습니다.',
  'DATA.SLEEP_DURATION.[300,360).description':
    '수면 시간이 적습니다. 수면시간이 적으면 근육 회복 속도가 느려지고 체력이 저하됩니다. 또 부상위험을 증가시키고 집중력이 저하될 수 있습니다.',
  'DATA.SLEEP_DURATION.[360,420).description': undefined,
  'DATA.SLEEP_DURATION.[420,480).description': undefined,
  'DATA.SLEEP_DURATION.[480,720].description': undefined,
  'DATA.SLEEP_QUALITY.[1,3).description':
    '수면 품질 관리가 필요합니다. 충분히 긴 시간의 수면을 하더라도, 낮은 품질의 수면은 몸의 회복 속도를 늦춥니다. 또한, 스트레스와 기분에 악영향을 주기 때문에 수면 시간과 함께 수면 품질의 관리가 필요합니다.',
  'DATA.SLEEP_QUALITY.[3,5).description':
    '수면 품질 관리가 필요합니다. 충분히 긴 시간의 수면을 하더라도, 낮은 품질의 수면은 몸의 회복 속도를 늦춥니다. 또한, 스트레스와 기분에 악영향을 주기 때문에 수면 시간과 함께 수면 품질의 관리가 필요합니다.',
  'DATA.SLEEP_QUALITY.[5,7).description': undefined,
  'DATA.SLEEP_QUALITY.[7,9).description': undefined,
  'DATA.SLEEP_QUALITY.[9,10].description': undefined,
  'DATA.CONDITION_AX.[1,2).description':
    '최근 1주 컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.',
  'DATA.CONDITION_AX.[2,4).description':
    '최근 1주 컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.',
  'DATA.CONDITION_AX.[4,7).description': undefined,
  'DATA.CONDITION_AX.[7,9).description':
    '최근 1주 컨디션이 좋습니다. 좋은 컨디션은 경기력 향상에 도움이 됩니다. 현재의 컨디셔닝 루틴을 유지하세요',
  'DATA.CONDITION_AX.[9,10].description':
    '최근 1주 컨디션이 좋습니다. 좋은 컨디션은 경기력 향상에 도움이 됩니다. 현재의 컨디셔닝 루틴을 유지하세요',
  'DATA.CONDITION_CX.[1,2).description':
    '최근 4주 컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.',
  'DATA.CONDITION_CX.[2,4).description':
    '최근 4주 컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.',
  'DATA.CONDITION_CX.[4,7).description': undefined,
  'DATA.CONDITION_CX.[7,9).description':
    '최근 4주 컨디션이 좋습니다. 좋은 컨디션은 경기력 향상에 도움이 됩니다. 현재의 컨디셔닝 루틴을 유지하세요',
  'DATA.CONDITION_CX.[9,10].description':
    '최근 4주 컨디션이 좋습니다. 좋은 컨디션은 경기력 향상에 도움이 됩니다. 현재의 컨디셔닝 루틴을 유지하세요',
  'DATA.WORKLOAD.[0,201).description': undefined,
  'DATA.WORKLOAD.[201,401).description': undefined,
  'DATA.WORKLOAD.[401,801).description': undefined,
  'DATA.WORKLOAD.[801,1001).description':
    '운동부하가 높은 만큼 적절한 회복이 필요합니다. 운동 강도나 운동량을 조절하는 계획을 추천합니다.',
  'DATA.WORKLOAD.[1001,Infinity].description':
    '운동부하가 높은 만큼 적절한 회복이 필요합니다. 운동 강도나 운동량을 조절하는 계획을 추천합니다.',
  'DATA.WORKLOAD_INTENSITY.[1,2).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[2,4).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[4,7).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[7,8).description':
    '연속적인 높은 강도의 운동은 부상과 컨디션에 영향을 줍니다. ',
  'DATA.WORKLOAD_INTENSITY.[8,10).description':
    '연속적인 높은 강도의 운동은 부상과 컨디션에 영향을 줍니다. ',
  'DATA.WORKLOAD_INTENSITY.[10,10].description':
    '연속적인 높은 강도의 운동은 부상과 컨디션에 영향을 줍니다. ',
  'DATA.WORKLOAD_DURATION.[0,31).description': undefined,
  'DATA.WORKLOAD_DURATION.[31,61).description': undefined,
  'DATA.WORKLOAD_DURATION.[61,121).description': undefined,
  'DATA.WORKLOAD_DURATION.[121,241).description':
    '많은 양의 운동보다 목적에 맞게 운동의 질을 높이는 것이 중요합니다. ',
  'DATA.WORKLOAD_DURATION.[241,720].description':
    '많은 양의 운동보다 목적에 맞게 운동의 질을 높이는 것이 중요합니다. ',
  'DATA.WORKLOAD_SATISFACTION.[1,3).description':
    '운동에 대한 만족도가 낮습니다. 성취감을 위한 동기부여가 필요합니다. 크고 작은 목표설정을 추천합니다.',
  'DATA.WORKLOAD_SATISFACTION.[3,5).description':
    '운동에 대한 만족도가 낮습니다. 성취감을 위한 동기부여가 필요합니다. 크고 작은 목표설정을 추천합니다.',
  'DATA.WORKLOAD_SATISFACTION.[5,7).description': undefined,
  'DATA.WORKLOAD_SATISFACTION.[7,9).description': undefined,
  'DATA.WORKLOAD_SATISFACTION.[9,10].description': undefined,
  'DATA.WORKLOAD_AX.[0,201).description': undefined,
  'DATA.WORKLOAD_AX.[201,401).description': undefined,
  'DATA.WORKLOAD_AX.[401,801).description': undefined,
  'DATA.WORKLOAD_AX.[801,1001).description':
    '최근 1주 운동부하 평균이 높습니다. 본인의 체력 대비 급격한 운동량 증가는 부상을 초래합니다. 근육 성장과 경기력 향상을 위해 적절한 휴식이 필요합니다.',
  'DATA.WORKLOAD_AX.[1001,7200].description':
    '최근 1주 운동부하 평균이 높습니다. 본인의 체력 대비 급격한 운동량 증가는 부상을 초래합니다. 근육 성장과 경기력 향상을 위해 적절한 휴식이 필요합니다.',
  'DATA.WORKLOAD_AXD.[2,Infinity].description':
    '최근 1주 운동부하 리듬이 좋지 못합니다. 운동 강도와 운동 시간에 변화를 가져야 합니다. 단조로운 훈련은 선수에게 부정적인 영향을 줍니다.',
  'DATA.WORKLOAD_AXD.[0,2).description': undefined,
  'DATA.WORKLOAD_AXI.[0,0.8).description': undefined,
  'DATA.WORKLOAD_AXI.[0.8,1.31).description': undefined,
  'DATA.WORKLOAD_AXI.[1.31,1.5).description': undefined,
  'DATA.WORKLOAD_AXI.[1.5,Infinity].description':
    '최근 1주 평균보다 운동량이 급격히 증가했습니다. 부상을 방지하기 위해 적절한 스트레칭, 충분한 영양과 수분, 회복을 위한 충분한 수면 등을 권장합니다',
  'DATA.WORKLOAD_CX.[0,201).description': undefined,
  'DATA.WORKLOAD_CX.[201,401).description': undefined,
  'DATA.WORKLOAD_CX.[401,801).description': undefined,
  'DATA.WORKLOAD_CX.[801,1001).description':
    '최근 4주 운동부하 평균이 높습니다. 4주 운동부하 평균만큼 체력이 향상되며 높은 운동부하의 대한 적응력이 향상됩니다. 점진적인 운동량 증가를 권장합니다.',
  'DATA.WORKLOAD_CX.[1001,7200].description':
    '최근 4주 운동부하 평균이 높습니다. 4주 운동부하 평균만큼 체력이 향상되며 높은 운동부하의 대한 적응력이 향상됩니다. 점진적인 운동량 증가를 권장합니다.',
  'DATA.WORKLOAD_ACXR.[0,0.8).description': undefined,
  'DATA.WORKLOAD_ACXR.[0.8,1.31).description': undefined,
  'DATA.WORKLOAD_ACXR.[1.31,1.5).description':
    '부상 주의 구간입니다. 피로도를 줄이고 운동량을 조절할 필요가 있습니다.',
  'DATA.WORKLOAD_ACXR.[1.5,Infinity].description':
    '부상 위험 구간입니다. 본인의 체력 대비 피로도가 높습니다. 운동보단 휴식을 권장하며 적절한 휴식과 회복을 권장합니다. ',
  'DATA.PARTIAL_INJURY_SORENESS.[0,1).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[1,2).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[2,3).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[3,4).description':
    '통증 3단계가 발생했습니다. 단기적으로 통증 완화를 위한 노력이 필요합니다. 휴식, 마사지, 냉/온 찜질, 영양을 포함한 회복을 위한 행동이 필요합니다.',
  'DATA.PARTIAL_INJURY_SORENESS.[4,5).description':
    '통증 4단계가 발생했습니다. 전문가의 진단을 통해 정확한 원인과 처방이 필요합니다.',
  'DATA.PARTIAL_INJURY_SORENESS.[5,5].description':
    '부상 입니다. 모든 운동을 중단하고 신체적/심리적 회복에 집중하세요. 계획적인 회복 전략이 필요합니다.',
  'DATA.BODY_BMI.[0,18.5).description': undefined,
  'DATA.BODY_BMI.[18.5,23).description': undefined,
  'DATA.BODY_BMI.[23,25).description': undefined,
  'DATA.BODY_BMI.[25,30).description': undefined,
  'DATA.BODY_BMI.[30,40).description': undefined,
  'DATA.BODY_BMI.[40,Infinity].description': undefined,
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).label': '매우 가벼운 강도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).label': '가벼운 강도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).label': '보통 강도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).label': '조금 강한 강도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).label': '강한 강도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].label': '최대 강도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).description': '거의 노력이 필요 없는 정도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).description':
    '몇 시간 동안 버틸 수 있는 정도. 숨쉬기가 쉽고 대화를 수월하게 가능한 정도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).description':
    '숨을 가쁘게 쉴 정도. 짧은 대화가 가능함. 다소 편한 상태',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).description':
    '약간 불편한 정도. 숨이 찬 상태. 문장을 말할 수 있는 정도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).description':
    '운동강도를 유지하기 매우 어려움. 거의 숨쉬기 어려움. 오직 단어 몇 개만 말할 수 있는 정도',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].description':
    '지속하기 불가능할 정도. 완전 숨쉬기 어려움. 대화가 불가능한 정도',
}

export default messages
