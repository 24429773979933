import { SportsCategory, SportsCategoryGroup } from '../graphqls/react.generated'

type CategoryGroup = {
  id: SportsCategoryGroup
  categories: Category[]
}

type Category = {
  id: SportsCategory
  team: boolean
}

const CATEGORY_GROUP: (Omit<CategoryGroup, 'categories'> & {
  categories: Omit<Category, 'group'>[]
})[] = [
  // 육상 16
  {
    id: SportsCategoryGroup.ATHLETICS,
    categories: [
      {
        id: SportsCategory.SPRINT,
        team: false,
      },
      {
        id: SportsCategory.MIDDLE_DISTANCE_RUNNING,
        team: false,
      },
      {
        id: SportsCategory.LONG_DISTANCE_RUNNING,
        team: false,
      },
      {
        id: SportsCategory.HURDLE,
        team: false,
      },
      {
        id: SportsCategory.RACE_WALKING,
        team: false,
      },
      {
        id: SportsCategory.MARATHON,
        team: false,
      },
      {
        id: SportsCategory.LONG_JUMP,
        team: false,
      },
      {
        id: SportsCategory.TRIPLE_JUMP,
        team: false,
      },
      {
        id: SportsCategory.HIGH_JUMP,
        team: false,
      },
      {
        id: SportsCategory.POLE_VAULT,
        team: false,
      },
      {
        id: SportsCategory.SHOT_PUT,
        team: false,
      },
      {
        id: SportsCategory.DISCUS_THROW,
        team: false,
      },
      {
        id: SportsCategory.JAVELIN_THROW,
        team: false,
      },
      {
        id: SportsCategory.HAMMER_THROW,
        team: false,
      },
      {
        id: SportsCategory.MEN_DECATHLON,
        team: false,
      },
      {
        id: SportsCategory.WOMEN_HEPTATHLON,
        team: false,
      },
    ],
  },
  // 구기 15
  {
    id: SportsCategoryGroup.BALL,
    categories: [
      {
        id: SportsCategory.SOCCER,
        team: true,
      },
      {
        id: SportsCategory.BASKETBALL,
        team: true,
      },
      {
        id: SportsCategory.VOLLEYBALL,
        team: true,
      },
      {
        id: SportsCategory.HANDBALL,
        team: true,
      },
      {
        id: SportsCategory.BASEBALL,
        team: true,
      },
      {
        id: SportsCategory.FIELD_HOCKEY,
        team: true,
      },
      {
        id: SportsCategory.RUGBY,
        team: true,
      },
      {
        id: SportsCategory.AMERICAN_FOOTBALL,
        team: true,
      },
      {
        id: SportsCategory.BILLIARDS,
        team: false,
      },
      {
        id: SportsCategory.GOLF,
        team: false,
      },
      {
        id: SportsCategory.CRICKET,
        team: true,
      },
      {
        id: SportsCategory.BOCCIA,
        team: false,
      },
      {
        id: SportsCategory.SEPAK_TAKRAW,
        team: true,
      },
      {
        id: SportsCategory.TEE_BALL,
        team: true,
      },
      {
        id: SportsCategory.FOOT_VOLLEYBALL,
        team: true,
      },
      {
        id: SportsCategory.FUTSAL,
        team: true,
      },
      {
        id: SportsCategory.BOWLING,
        team: false,
      },
    ],
  },
  // 라켓 6
  {
    id: SportsCategoryGroup.RACKET,
    categories: [
      {
        id: SportsCategory.RACQUETBALL,
        team: false,
      },
      {
        id: SportsCategory.BADMINTON,
        team: false,
      },
      {
        id: SportsCategory.SQUASH,
        team: false,
      },
      {
        id: SportsCategory.SOFT_TENNIS,
        team: false,
      },
      {
        id: SportsCategory.TABLE_TENNIS,
        team: false,
      },
      {
        id: SportsCategory.TENNIS,
        team: false,
      },
    ],
  },
  // 수상 5
  {
    id: SportsCategoryGroup.WATER,
    categories: [
      {
        id: SportsCategory.SWIMMING,
        team: false,
      },
      {
        id: SportsCategory.DIVING,
        team: false,
      },
      {
        id: SportsCategory.WATER_POLO,
        team: true,
      },
      {
        id: SportsCategory.SYNCHRONIZE,
        team: false,
      },
      {
        id: SportsCategory.ROWING,
        team: false,
      },
      {
        id: SportsCategory.ROWING,
        team: false,
      },
      {
        id: SportsCategory.CANOE,
        team: false,
      },
      {
        id: SportsCategory.KAYAK,
        team: false,
      },
      {
        id: SportsCategory.FINSWIMMING,
        team: false,
      },
    ],
  },
  // 격투 10
  {
    id: SportsCategoryGroup.COMBAT,
    categories: [
      {
        id: SportsCategory.TAEKWONDO,
        team: false,
      },
      {
        id: SportsCategory.TAEKKYEON,
        team: false,
      },
      {
        id: SportsCategory.KUNG_FU,
        team: false,
      },
      {
        id: SportsCategory.SSIREUM,
        team: false,
      },
      {
        id: SportsCategory.KARATE,
        team: false,
      },
      {
        id: SportsCategory.JUDO,
        team: false,
      },
      {
        id: SportsCategory.BOXING,
        team: false,
      },
      {
        id: SportsCategory.KICKBOXING,
        team: false,
      },
      {
        id: SportsCategory.WRESTLING,
        team: false,
      },
      {
        id: SportsCategory.ARM_WRESTLING,
        team: false,
      },
    ],
  },
  // 체조 9
  {
    id: SportsCategoryGroup.GYMNASTICS,
    categories: [
      {
        id: SportsCategory.ARTISTIC_GYMNASTICS,
        team: false,
      },
      {
        id: SportsCategory.RHYTHMIC_GYMNASTICS,
        team: false,
      },
      {
        id: SportsCategory.TRAMPOLINE,
        team: false,
      },
      {
        id: SportsCategory.AEROBICS,
        team: false,
      },
    ],
  },
  // 역도 1
  {
    id: SportsCategoryGroup.WEIGHTLIFTING,
    categories: [
      {
        id: SportsCategory.WEIGHTLIFTING,
        team: false,
      },
    ],
  },
  // 승마 1
  {
    id: SportsCategoryGroup.EQUESTRIAN,
    categories: [
      {
        id: SportsCategory.EQUESTRIAN,
        team: false,
      },
    ],
  },
  // 사이클링 1
  {
    id: SportsCategoryGroup.CYCLING,
    categories: [
      {
        id: SportsCategory.CYCLING,
        team: false,
      },
    ],
  },
  // 동계 2
  {
    id: SportsCategoryGroup.WINTER,
    categories: [
      {
        id: SportsCategory.ICE_SKATING,
        team: false,
      },
      {
        id: SportsCategory.ICE_HOCKEY,
        team: true,
      },
      {
        id: SportsCategory.CURLING,
        team: true,
      },
      {
        id: SportsCategory.SKIING,
        team: false,
      },
      {
        id: SportsCategory.ALPINE_SKIING,
        team: false,
      },
      {
        id: SportsCategory.NORDIC_SKIING,
        team: false,
      },
      {
        id: SportsCategory.FREESTYLE_SKIING,
        team: false,
      },
      {
        id: SportsCategory.CROSS_COUNTRY_SKIING,
        team: false,
      },
      {
        id: SportsCategory.SKI_JUMPING,
        team: false,
      },
      {
        id: SportsCategory.BIATHLON,
        team: false,
      },
      {
        id: SportsCategory.SNOWBOARDING,
        team: false,
      },
      {
        id: SportsCategory.BOBSLEIGH,
        team: false,
      },
      {
        id: SportsCategory.LUGE,
        team: false,
      },
      {
        id: SportsCategory.SKELETON,
        team: false,
      },
    ],
  },
  // 양궁 1
  {
    id: SportsCategoryGroup.ARCHERY,
    categories: [
      {
        id: SportsCategory.ARCHERY,
        team: false,
      },
    ],
  },
  // 펜싱 1
  {
    id: SportsCategoryGroup.FENCING,
    categories: [
      {
        id: SportsCategory.FENCING,
        team: false,
      },
    ],
  },
  // 슈팅 1
  {
    id: SportsCategoryGroup.SHOOTING,
    categories: [
      {
        id: SportsCategory.SHOOTING,
        team: false,
      },
    ],
  },
  // 근대 5종
  {
    id: SportsCategoryGroup.MODERN_PENTATHLON,
    categories: [
      {
        id: SportsCategory.MODERN_PENTATHLON,
        team: false,
      },
    ],
  },
  {
    id: SportsCategoryGroup.ROLLER,
    categories: [
      {
        id: SportsCategory.ROLLER,
        team: false,
      },
    ],
  },
  // 기타 1
  {
    id: SportsCategoryGroup.ETC,
    categories: [
      {
        id: SportsCategory.ETC,
        team: false,
      },
    ],
  },
]

export const teamIndividual = (id: string): 'team' | 'individual' => {
  if (!id) return 'individual'
  return CATEGORY_GROUP.map((el) => el.categories)
    .flat()
    .find((el) => el.id === id)?.team
    ? 'team'
    : 'individual'
}
