import { types } from 'mobx-state-tree'

import {
  CountryCode,
  Gender,
  SportsCategory,
  SportsPosition,
  SportsCategoryGroup,
  TRegisterViewerFragment,
} from '@plco-pro/graphqls/react.generated'

export const RegisterStore = types
  .model('Register', {
    id: types.optional(types.maybeNull(types.string), null),
    name: types.optional(types.maybeNull(types.string), null),
    hasProfilePictureAlready: types.optional(types.boolean, false),
    profilePictureUrl: types.optional(types.maybeNull(types.string), null),
    gender: types.optional(types.maybeNull(types.enumeration(Object.values(Gender))), null),
    birthdate: types.optional(types.maybeNull(types.string), null),
    nationality: types.optional(
      types.maybeNull(types.enumeration(Object.values(CountryCode))),
      null,
    ),
    sportsCategoryGroup: types.optional(
      types.maybeNull(types.enumeration(Object.values(SportsCategoryGroup))),
      null,
    ),
    sportsCategory: types.optional(
      types.maybeNull(types.enumeration(Object.values(SportsCategory))),
      null,
    ),
    position: types.optional(
      types.maybeNull(types.enumeration(Object.values(SportsPosition))),
      null,
    ),
    phoneNumber: types.optional(types.maybeNull(types.string), null),
    teamCode: types.optional(types.maybeNull(types.string), null),
  })
  .volatile(() => ({
    profilePictureFile: null as File | null,
  }))
  .actions((self) => {
    return {
      viewerIdentity(viewer: TRegisterViewerFragment) {
        self.id = viewer?.id || null
        self.name = viewer?.name || null
        self.hasProfilePictureAlready = !!viewer?.pictureUrl
        self.profilePictureUrl = viewer?.pictureUrl || null
        self.gender = viewer?.gender || null
        self.birthdate = viewer?.birthdate || null
        self.nationality = viewer?.nationality || null
        self.phoneNumber = viewer?.phoneNumber?.replace(/\+82\s/, '0') || null
        self.sportsCategoryGroup = viewer?.priorSportsCategory?.group || null
        self.sportsCategory = viewer?.priorSportsCategory?.id || null
        self.position = viewer?.priorSportsPosition || null
      },
      setId(value: string | null) {
        self.id = value
      },
      setName(value: string | null) {
        self.name = value
      },
      setHasProfilePictureAlready(value: boolean) {
        self.hasProfilePictureAlready = value
      },
      setProfilePictureUrl(value: string | null) {
        self.profilePictureUrl = value
      },
      setProfilePictureFile(value: File | null) {
        self.profilePictureFile = value
      },
      setGender(value: Gender | null) {
        self.gender = value
      },
      setBirthdate(value: string | null) {
        self.birthdate = value
      },
      setNationality(value: CountryCode | null) {
        self.nationality = value
      },
      setSportsCategoryGroup(value: SportsCategoryGroup | null) {
        self.sportsCategoryGroup = value
      },
      setSportsCategory(value: SportsCategory | null) {
        self.sportsCategory = value
      },
      setPosition(value: SportsPosition | null) {
        self.position = value
      },
      setPhoneNumber(value: string | null) {
        self.phoneNumber = value
      },
      setTeamCode(value: string | null) {
        self.teamCode = value
      },
    }
  })
