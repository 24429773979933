import { Fragment, useMemo } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { GhostCard } from '@plco-pro/components/atoms/ghost-card'
import { Text, TextProps } from '@plco-pro/components/atoms/text'
import {
  InjuryDummy,
  InjuryDummyBodyPartDataMap,
} from '@plco-pro/components/organisms/injury-dummy'
import { SportsBodyPart } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMoment } from '@plco-pro/hooks/moment'
import { isMobile } from '@plco-pro/utils/isMobile'

const CardFlex = (props: FlexProps) => (
  <Flex sx={{ flexDirection: 'column', height: '100%' }} {...props} />
)

const TitleText = (props: TextProps) => (
  <Text variant={['s1', 'h4']} sx={{ fontWeight: 'bold', mx: 3, mt: 3 }} {...props} />
)

const SubtitleText = (props: TextProps) => (
  <Text variant={['p1', 's2']} appearance={'hint'} {...props} />
)

const ContentBox = (props: BoxProps) => <Box sx={{ mx: 3 }} {...props} />

interface Props {
  loading: boolean
  lastInjuredAt?: string
  gender?: 'man' | 'woman'
  injuryData: InjuryDummyBodyPartDataMap
  onClickPart?: (bodyPart: SportsBodyPart) => void
  showTitle?: boolean
}

export const CardPlayerSoreness = ({
  loading,
  lastInjuredAt,
  gender,
  injuryData,
  onClickPart,
  showTitle = true,
}: Props) => {
  const { formatMessage } = useI18n()
  const moment = useMoment()

  const injuryTimeText = useMemo(
    () =>
      lastInjuredAt
        ? moment(lastInjuredAt).format(formatMessage({ id: 'date.format.year-to-minutes-a' }))
        : '',
    [lastInjuredAt, moment, formatMessage],
  )

  const handleClickPart = (part: SportsBodyPart) => {
    if (isMobile()) {
      return
    }
    onClickPart?.(part)
  }

  if (loading) {
    return (
      <GhostCard sx={{ width: '100%', height: '100%' }}>
        <CardFlex>
          {showTitle && (
            <TitleText>{formatMessage({ id: 'players.card.soreness.title' })}</TitleText>
          )}
        </CardFlex>
      </GhostCard>
    )
  }

  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <CardFlex>
        {showTitle && (
          <Fragment>
            <TitleText>{formatMessage({ id: 'players.card.soreness.title' })}</TitleText>
            <SubtitleText sx={{ mx: 3, mt: '2px' }}>{injuryTimeText}</SubtitleText>
          </Fragment>
        )}

        <ContentBox sx={{ mt: 'auto', mb: 3 }}>
          <Flex sx={{ justifyContent: 'center', width: '100%' }}>
            <Box sx={{ width: '136px' }}>
              <InjuryDummy
                data={injuryData}
                gender={gender}
                orientation={'front'}
                onClick={handleClickPart}
              />
            </Box>

            <Box sx={{ width: '136px', ml: 2 }}>
              <InjuryDummy
                data={injuryData}
                gender={gender}
                orientation={'back'}
                onClick={handleClickPart}
              />
            </Box>
          </Flex>
        </ContentBox>

        <Flex sx={{ justifyContent: 'center', mb: '21px' }}>
          <Flex
            sx={{
              px: 3,
              py: '10px',
              bg: 'grey-50',
              borderRadius: '35px',
            }}
          >
            <Text variant={'p2'} color={'indigo-500'}>
              {formatMessage({ id: 'players.card.soreness.footer' })}
            </Text>
          </Flex>
        </Flex>
      </CardFlex>
    </Card>
  )
}
