import { Fragment, useCallback, useMemo } from 'react'

import styled from '@emotion/styled'
import { variant, VariantArgs } from 'styled-system'
import { ResponsiveStyleValue } from 'theme-ui'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Chip, ChipProps, ChipSize } from '@plco-pro/components/atoms/chip'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { useResponsive } from '@plco-pro/hooks/responsive'

export type ChipInputProps = Omit<ChipProps, 'onChange' | 'value' | 'type'> & {
  type?: 'radio' | 'checkbox'
  gap?: ResponsiveStyleValue<ChipInputGap>
  items: ChipInputItem[]
  onChange?: (value: any) => void
  value: any
  checkedProps?: Partial<ChipProps>
  containerProps?: FlexProps
}

export type ChipInputGap = 'xs' | 'sm' | 'md' | 'lg'

export type ChipInputItem = Omit<Partial<ChipProps>, 'onClick'> & {
  value?: any
  checkedProps?: Partial<ChipProps>
  group?: number
  onClick?: () => void
}

const containerGap = variant({
  prop: 'gap',
  variants: {
    xs: {
      marginTop: '-2px',
      marginBottom: '-2px',
      marginLeft: '-2px',
      marginRight: '-2px',
    },
    sm: {
      marginTop: '-4px',
      marginBottom: '-4px',
      marginLeft: '-4px',
      marginRight: '-4px',
    },
    lg: {
      marginTop: '-8px',
      marginBottom: '-8px',
      marginLeft: '-8px',
      marginRight: '-8px',
    },
    xl: {
      marginTop: '-12px',
      marginBottom: '-12px',
      marginLeft: '-12px',
      marginRight: '-12px',
    },
  },
} as VariantArgs)

const itemGap = variant({
  prop: 'gap',
  variants: {
    xs: {
      marginTop: '4px',
      marginBottom: '4px',
      marginLeft: '4px',
      marginRight: '4px',
    },
    sm: {
      marginTop: '4px',
      marginBottom: '4px',
      marginLeft: '4px',
      marginRight: '4px',
    },
    lg: {
      marginTop: '8px',
      marginBottom: '8px',
      marginLeft: '8px',
      marginRight: '8px',
    },
    xl: {
      marginTop: '4px',
      marginBottom: '4px',
      marginLeft: '4px',
      marginRight: '4px',
    },
  },
} as VariantArgs)

const dividerSize = variant({
  prop: 'size',
  variants: {
    sm: {
      height: '20px',
    },
    md: {
      height: '26px',
    },
    lg: {
      height: '32px',
    },
  },
} as VariantArgs)

const ContainerFlex = styled(Flex)<FlexProps & { gap: ChipInputGap | undefined }>(containerGap)

const ItemBox = styled(Box)<BoxProps & { gap: ChipInputGap | undefined }>(itemGap)

const DividerBox = styled(Box)<BoxProps & { size: ChipSize | undefined }>(dividerSize)

export const ChipInput: React.FunctionComponent<ChipInputProps> = ({
  type = 'radio',
  gap = null,
  size = 'lg',
  onChange = () => {},
  value,
  checkedProps,
  containerProps,
  disabled,
  ...rest
}) => {
  const getResponsiveProp = useResponsive()

  const responsiveSize = useMemo(() => getResponsiveProp(size), [size, getResponsiveProp])
  const responsiveGap = useMemo(
    () => getResponsiveProp(gap || size),
    [gap, size, getResponsiveProp],
  )

  const items = useMemo(() => {
    return rest.items.map((item) => ({
      ...item,
      checked:
        type === 'checkbox' && Array.isArray(value)
          ? value.includes(item.value)
          : item.value === value,
      disabled: item.value !== value && disabled,
    }))
  }, [rest.items, type, value, disabled])

  const handleClick = useCallback(
    (clickedItem: ChipInputItem) => {
      if (clickedItem.disabled) {
        return
      }

      if (type === 'checkbox') {
        const checkedValues = items.filter((item) => item.checked).map((item) => item.value)

        const newCheckedValues = checkedValues.includes(clickedItem.value)
          ? checkedValues.filter((valueItem) => valueItem !== clickedItem.value)
          : [...checkedValues, clickedItem.value]

        onChange(newCheckedValues)
      } else if (type === 'radio') {
        onChange(clickedItem.value)
      }

      if (clickedItem.onClick) {
        clickedItem.onClick()
      }
    },
    [items, onChange, type],
  )

  return (
    <Flex sx={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }} invisibleScroll={true}>
      <ContainerFlex
        gap={responsiveGap}
        sx={{ flexDirection: 'row', alignItems: 'center', flexShrink: 0 }}
        {...containerProps}
      >
        {items.map((item, index) => {
          const { group, checked, checkedProps: itemCheckedProps, ...itemRest } = item

          const commonProps: ChipProps = {
            variant: checked ? 'primary' : undefined,
            appearance: checked ? 'filled' : undefined,
            interactive: !(checked && type === 'radio'),
            ...rest,
            ...(checked ? checkedProps : {}),
          }

          const itemProps: ChipProps = {
            ...itemRest,
            ...(checked ? itemCheckedProps : {}),
          }

          return (
            <Fragment key={`chip-${index}`}>
              {index > 0 && group !== items[index - 1].group && (
                <ItemBox gap={responsiveGap}>
                  <DividerBox
                    size={responsiveSize}
                    sx={{ width: '2px', borderRadius: '1px', backgroundColor: 'grey-200' }}
                  />
                </ItemBox>
              )}
              <ItemBox gap={responsiveGap}>
                <Chip
                  interactive={true}
                  size={responsiveSize}
                  {...commonProps}
                  {...itemProps}
                  sx={{
                    ...commonProps.sx,
                    ...itemProps.sx,
                    borderColor: item.disabled && 'grey-200',
                    bg: item.disabled && 'grey-50',
                  }}
                  onClick={() => handleClick(item)}
                />
              </ItemBox>
            </Fragment>
          )
        })}
      </ContainerFlex>
    </Flex>
  )
}
