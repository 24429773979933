import React, { KeyboardEventHandler } from 'react'

import { ThemeUIStyleObject } from 'theme-ui'

import { Box } from '@plco-pro/components/atoms/box'
import { FlexProps } from '@plco-pro/components/atoms/flex'
import { DropdownMenu, DropdownMenuItem } from '@plco-pro/components/molecules/dropdown-menu'
import { DropdownToggle, DropdownToggleProps } from '@plco-pro/components/molecules/dropdown-toggle'
import {
  DropdownWrapper,
  DropdownWrapperProps,
} from '@plco-pro/components/molecules/dropdown-wrapper'

export type DropdownProps = Omit<DropdownWrapperProps, 'children'> & {
  toggle?: React.ReactNode
  toggleTitle?: string
  titleEllipsis?: boolean
  toggleWidth?: string | string[]
  toggleAppearance?: DropdownToggleProps['appearance']
  menuWidth?: string
  menuMaxHeight?: string
  menuItems: DropdownMenuItem[]
  hideDropdownArrow?: boolean
  toggleProps?: FlexProps
  menuContainerStyle?: ThemeUIStyleObject
  menuHeaderStyle?: ThemeUIStyleObject
  menuContentStyle?: ThemeUIStyleObject
  disabled?: boolean
  titleColor?: string
  zIndex?: number
}

export const Dropdown: React.FunctionComponent<DropdownProps> = ({
  show,
  onToggle,
  drop = 'down',
  alignEnd = false,
  toggle,
  toggleWidth,
  toggleTitle,
  titleEllipsis,
  toggleAppearance,
  menuWidth,
  menuMaxHeight,
  menuItems = [],
  hideDropdownArrow,
  toggleProps,
  menuContainerStyle,
  menuHeaderStyle,
  menuContentStyle,
  disabled = false,
  titleColor,
  zIndex,
}) => {
  const onToggleDropdown: DropdownProps['onToggle'] = (...props) => {
    if (disabled) {
      return
    }

    return onToggle(...props)
  }

  return (
    <DropdownWrapper show={show} onToggle={onToggleDropdown} drop={drop} alignEnd={alignEnd}>
      {({ props }) => (
        <Box onKeyDown={props.onKeyDown as unknown as KeyboardEventHandler<HTMLDivElement>}>
          <DropdownToggle
            width={toggleWidth}
            title={toggleTitle}
            titleEllipsis={titleEllipsis}
            appearance={toggleAppearance}
            hideDropdownArrow={hideDropdownArrow}
            containerProps={toggleProps}
            titleColor={titleColor}
          >
            {toggle}
          </DropdownToggle>
          {show && (
            <DropdownMenu
              width={menuWidth}
              items={menuItems}
              maxHeight={menuMaxHeight}
              containerStyle={menuContainerStyle}
              headerStyle={menuHeaderStyle}
              contentStyle={menuContentStyle}
              zIndex={zIndex}
            />
          )}
        </Box>
      )}
    </DropdownWrapper>
  )
}
