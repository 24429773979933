import { Flex, Text } from '@plco-pro/components/atoms'
import { Modal } from '@plco-pro/components/organisms/modal'
import { useI18n } from '@plco-pro/hooks/i18n'
import { getScreenSize } from '@plco-pro/providers/responsive'

interface Props {
  open: boolean
  onClose: () => void
  onDelete: () => void
}

export const DialogInjuryDelete = ({ open, onClose, onDelete }: Props) => {
  const { formatMessage } = useI18n()
  const { smAndDown } = getScreenSize(false)

  return (
    <Modal
      maxWidth={smAndDown ? 'calc(100% - 32px)' : '400px'}
      height={'modal.height.xs'}
      open={open}
      onClose={onClose}
      showCloseIcon={false}
      footerProps={{
        leftButtonProps: {
          children: formatMessage({ id: 'NO' }),
          onClick: onClose,
        },
        rightButtonProps: {
          children: formatMessage({ id: 'YES' }),
          onClick: onDelete,
          variant: 'danger',
        },
      }}
    >
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text sx={{ fontWeight: 700, textAlign: 'center' }} variant={'h5'}>
          {formatMessage({ id: 'player.modal-injury.delete.title' })}
        </Text>
      </Flex>
    </Modal>
  )
}
