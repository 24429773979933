/* eslint-disable max-len */
import { error } from './error'
import { I18nCommonMessagePack } from './i18n.en'
import { validationError } from './validation-error'

import 'moment/locale/ja'

export const messages: I18nCommonMessagePack = {
  ...error.jp,
  ...validationError.jp,
  // date format
  'date.format.month-minute': 'MMM Do HH:mm',
  'date.format.month-day-time': 'MMM Do LT',
  'date.format.year-month-day': 'YYYY. M. D',
  'date.format.year-month-day-suffix': 'YYYY年 M月 Do', // "YYYY년 MMM Do",
  'date.format.year-to-minutes': 'YYYY年 MMM Do · HH:mm', // "YYYY년 MMM Do · HH:mm",
  'date.format.year-to-minutes-a': 'YYYY. M. D A h時 mm分', // "YYYY. M. D A h시 mm분",
  'date.format.month-day': 'M. D',
  'date.format.period': 'YYYY. M. D',
  'date.format.year-month': 'YYYY年 MMM', // "YYYY년 MMM",
  'date.format.year-month-day-week': 'YYYY年 M月 D日 (dd)', // "YYYY년 M월 D일 (d)"
  'date.format.year-month-day-week-suffix': 'YYYY年 M月 D日 (dd) · HH:mm', // 'YYYY년 MMM Do (dd) · HH:mm',
  'date.format.hour-minutes': '{hour}H時間 {minute}分', // "{hour}시간 {minute}분",

  // date (for scheduler)
  'date.year-month': '{YYYY}年 {MMM}', // "{YYYY}년 {MMM}",
  'date.year-full-month': '{YYYY}年 {MMMM}', // "{YYYY}년 {MMMM}",
  'date.weekdays': '月_火_水_木_金_土_日', // "월_화_수_목_금_토_일",

  'date.left.day': '{days}日 残り', // "{days}일 남음",
  'date.duration.time': 'H時間 M分', // "H시간 M분",

  'date-range.title-format': 'YYYY年 MMMM',
  'date-range.7-days': '以前7日',
  'date-range.30-days': '以前30日',

  NO: 'いいえ', // "아니요",
  YES: 'はい', // "예",
  CANCEL: 'キャンセル', // "취소",
  CLOSE: '閉じる', // "닫기",
  CONFIRM: '確認', // "확인",
  DONE: '完了', // "완료",
  RETRY: 'やり直し', // "다시 시도",
  LEARN_MORE: 'もっと調べる', // "더 알아보기",
  APPLY: '適用', // "적용",
  SEND: '送り', // "보내기",
  SELECT: '選択', // "선택",
  SEARCH: '検索.', // "검색",
  HOUR: '{hours, plural, =0 {# 時間} other {# 時間}}', // "{hours, plural, =0 {시간} other {시간}}",
  H: '時間', // "시간",
  MINUTE: '{minutes, plural, =0 {# 分} other {# 分}}', // "{minutes, plural, =0 {분} other {분}}",
  M: '分', // "분",
  DAY: '日', // "일",
  MONTH: '月', // "월",
  YEAR: '年.', // "년",
  COUNT: '個', // "개",
  POINT: '点', // "점",
  SCORE: '点', // "점",
  PEOPLE: '名', // "명",
  PEOPLE_COUNT: '{count}名', // "{count}명",
  CM: 'cm', // "cm",
  KG: 'kg', // "kg",
  NAME: '名前', // "이름",
  TODAY: '今日', // "오늘",
  YESTERDAY: '昨日', // "어제",
  START: 'はじまり', // "시작하기",
  NEXT: '次', // "다음",
  BACK: '戻る', // "뒤로",
  NOT_NOW: '後で', // "나중에",
  OKAY: '確認', // "확인",
  LEAVE: '出る', // "나가기",
  SAVE: '保存', // "저장",
  AVG: '平均', // "평균",
  SUM: '累積', // "누적",
  USER: 'ユーザー', // "유저",
  MAKE: '作り', // "만들기",
  ADD: '追加', // "추가",
  CHANGE: '変更', // "변경",
  PART: '個', // 개
  GOAL: '目標', // "목표",

  ALL: '全体', // "전체",
  FILTER: 'フィルター', // "필터",
  NO_SEARCH_RESULT: '検索結果がありません.', // "검색 결과가 없습니다.",

  SELECT_ALL: '全体選択', // "전체 선택",

  DELETE: '削除', // "삭제",
  REFRESH: '最新の情報に更新', // "새로고침",

  // auth federation
  'federation.kakao': 'Kakao Talk', // "카카오톡",
  'federation.facebook': 'Facebook', // "페이스북",
  'federation.apple': 'Apple', // "애플",
  'federation.google': 'Google', // "구글",

  // languages
  'ko-KR': '한국어',
  'en-KR': 'English',
  'ja-KR': '日本語',
  'vn-VN': 'Tiếng Việt',

  // data
  READINESS: '準備度', // "준비도",
  FATIGUE: '疲労度', // "피로도",
  STRESS: 'ストレス', // "스트레스",
  MUSCLE: '筋肉', // "근육",
  MOOD: '気分', // "기분",
  SLEEP_DURATION: '睡眠時間', // "수면시간",
  DURATION: '睡眠時間', // "수면시간",
  SLEEP_QUALITY: '睡眠品質', // "수면품질",
  QUALITY: '睡眠品質', // "수면품질",
  WORKLOAD_ACXR: '負傷の危険度 (ACWR)', // "부상 위험도 (ACWR)",
  WORKLOAD_AXD: '単調さ', // "단조로움",
  WORKLOAD_AX: '急性負荷', // "급성부하",
  WORKLOAD_CX: '慢性負荷', // "만성부하",
  WORKLOAD_AX_CX: '急性 & 慢性負荷', // "급성 & 만성부하",
  WORKLOAD_DURATION: '運動時間', // "운동시간",
  WORKLOAD_INTENSITY: '運動強度', // "운동강",
  WORKLOAD_INTENSITY_RPE: '運動強度 (RPE)', // "운동강도 (RPE)",
  WORKLOAD_SATISFACTION: '運動満足', // "운동만족",
  SORENESS_COUNT: '痛みの本数', // "통증 개수",
  SORENESS_1: '痛み 1段階', // "통증 1단계",
  SORENESS_2: '痛み 2段階', // "통증 2단계",
  SORENESS_3: '痛み 3段階', // "통증 3단계",
  SORENESS_4: '痛み 4段階', // "통증 4단계",
  SORENESS_5: '痛み 5段階', // "통증 5단계",
  CONDITION: 'コンディション', // "컨디션",
  WORKLOAD: '運動負荷', // "운동부하",
  HEIGHT: '身長', // "키",
  WEIGHT: '体重.', // "체중",
  ACWR: '負傷の危険度', // "부상 위험도",
  DANGER_TRAINING: '危険', // 위험
  WARNING_TRAINING: '注意', // 주의
  BEST_TRAINING: '最適', // 최적
  CAUTION_TRAINING: '留意', // 유의
  RECENT_RECORDS: '最近の履歴', // "최근 기록"

  INJURY_NUMBER_0: '痛みなし', // "통증 없음"
  INJURY_NUMBER_1: '1段階', // "1단계"
  INJURY_NUMBER_2: '2段階', // "2단계"
  INJURY_NUMBER_3: '3段階', // "3단계"
  INJURY_NUMBER_4: '4段階', // "4단계"
  INJURY_NUMBER_5: '5段階', // "5단계"
  TYPE: '種類', // "종류"

  SORENESS_TYPE: 'タイプ', // '유형'
  SORENESS_MEMO: 'メモ', // '메모'
  SORENESS_SITUATION: '痛み発生の状況', // '통증 발생 상황'

  CONTACT: '接触', // '접촉'
  NON_CONTACT: '非接触', // '비접촉'

  // period
  '1-day': '一日間', // "일간",
  '1-week': '週間', // "주간",
  '1-month': '月間', // "월간",

  // scheduler type
  'event-type.TRAINING': '練習', // "훈련",
  'event-type.MATCH': '試合', // "경기",
  'event-type.PRACTICE': '練習試合', // "연습경기",
  'event-type.REST': '休み', // "휴식",
  'event-type.OTHER_EVENT': 'その外', // "기타",

  CHECK_RATE: 'チェック率', // '체크율',
  CHECK: 'チェック', // '체크',
  UNCHECKED: '未チェック', // '미체크',

  ATTEND: '参加', // "참가",
  ABSENCE: '不参', // "불참",

  // constants
  SEE_MORE: 'もっと見る', // "더 보기",
  SORENESS_INTERPOLATED_STREAK:
    '{days, plural, =0 {なし} =1 {#日 続く} =28 {#日 以上 続く} other {#日 続く}}', // "{days, plural, =0 {없음} =1 {#일 지속} =28 {#일 이상 지속} other {#일 지속}}",
  SORENESS_DEGREE: '{degree} 段階', // "{degree} 단계",
  SORENESS_DEGREE_5: '{degree} 段階 (負傷)', // "{degree} 단계 (부상)",
  STREAK: '{days, plural, =0 {入力 なし} =9999 {連続9999+日 目} other {連続 #日 目}}', // "{days, plural, =0 {입력 없음} =9999 {연속 9999+일 째} other {연속 #일 째}}",
  STREAK_NEGATIVE:
    '{days, plural, =0 {入力なし} =1 {入力 後 #日 経過} =9999 {入力 後 9999+日 経過} other {入力 後 #日 経過}}', // "{days, plural, =0 {입력 없음} =1 {입력 후 #일 경과} =9999 {입력 후 9999+일 경과} other {입력 후 #일 경과}}",
  'USER.NAME': '名前', // "이름",
  'USER.NAME.placeholder': '実名を入力してください', // "실명을 입력하세요",
  'USER.NATIONALITY': '国籍', // "국적",
  'USER.GENDER': '性別', // "성별",
  'USER.BIRTHDATE': '誕生日', // "생일",
  'USER.USERNAME': 'ユーザー 名前', // "사용자 이름",
  'USER.USERNAME.placeholder': 'ユーザー 名前を 入力してください。', // "사용자 이름을 입력하세요",
  'USER.PHONENUMBER': '携帯電話', // "휴대전화",
  'USER.PHONENUMBER.title': '携帯番号の 変更', // "휴대폰 번호 변경",
  'USER.INTRODUCE': '自己紹介.', // "자기소개",
  'USER.INTRODUCE.placeholder': '自己紹介してみてください', // "자기를 소개해보세요",
  'USER.INTRODUCTION.empty': 'まだ 紹介が ありません', // "아직 소개가 없습니다.",
  'GENDER.MALE': '男性', // "남성",
  'GENDER.FEMALE': '女性', // "여성",
  'GENDER.OTHER': 'その外', // "기타",
  PLAYER: '選手', // "선수",
  COACH: '指導者', // "지도자",
  ROLE: '役割', // "역할",
  'ROLE.PLAYER': '選手', // "선수",
  'ROLE.COACH': '指導者', // "지도자",
  CATEGORY: '種目', // "종목",
  POSITION: 'ポジション', // "포지션",
  TEAM: 'チーム', // "팀",
  GROUP: 'グループ', // "그룹",
  BIRTHDATE: '生年月日', // "생년월일",

  MON: '月', // 월
  TUE: '火', // 화
  WED: '水', // 수
  THU: '木', // 목
  FRI: '金', // 금
  SAT: '土', // 토
  SUN: '日', // 일

  // player positions
  'PLAYER_POSITION.EMPTY': ' ',
  'PLAYER_POSITION.SOCCER.FW': '攻撃手', // "공격수",
  'PLAYER_POSITION.SOCCER.MF': 'ミッドフィールド', // "미드필더",
  'PLAYER_POSITION.SOCCER.DF': 'ディフェンダー', // "수비수",
  'PLAYER_POSITION.SOCCER.GK': 'ゴールキーパー', // "골키퍼",
  'PLAYER_POSITION.BASKETBALL.PG': 'ポイントガード', // "포인트가드",
  'PLAYER_POSITION.BASKETBALL.SG': 'シュートガード', // "슛팅가드",
  'PLAYER_POSITION.BASKETBALL.SF': 'スモールフォワード', // "스몰포워드",
  'PLAYER_POSITION.BASKETBALL.PF': 'パワーフォワード', // "파워포워드",
  'PLAYER_POSITION.BASKETBALL.C': 'センター', // "센터",
  'PLAYER_POSITION.VOLLEYBALL.LI': 'リベロ', // "리베로",
  'PLAYER_POSITION.VOLLEYBALL.S': '세터', // "세터",
  'PLAYER_POSITION.VOLLEYBALL.C': 'センター', // "센터",
  'PLAYER_POSITION.VOLLEYBALL.L': 'レフト', // "레프트",
  'PLAYER_POSITION.VOLLEYBALL.R': 'ライト', // "라이트",
  'PLAYER_POSITION.HANDBALL.GK': 'ゴールキーパー', // "골키퍼",
  'PLAYER_POSITION.HANDBALL.LW': 'レフトウイング', // "레프트 윙",
  'PLAYER_POSITION.HANDBALL.RW': 'ライトウイング', // "라이트 윙",
  'PLAYER_POSITION.HANDBALL.LB': 'レフト·バック', // "레프트 백",
  'PLAYER_POSITION.HANDBALL.RB': 'ライト·バック', // "라이트 백",
  'PLAYER_POSITION.HANDBALL.CB': 'センターバック', // "센터백",
  'PLAYER_POSITION.HANDBALL.PV': 'ピボット', // "피봇",
  'PLAYER_POSITION.BASEBALL.P': '투수', // "투수",
  'PLAYER_POSITION.BASEBALL.C': 'キャッチャー', // "포수",
  'PLAYER_POSITION.BASEBALL._1B': '一塁手', // "1루수",
  'PLAYER_POSITION.BASEBALL._2B': '二塁手', // "2루수",
  'PLAYER_POSITION.BASEBALL._3B': '三塁手', // "3루수",
  'PLAYER_POSITION.BASEBALL.SS': '遊撃手', // "유격수",
  'PLAYER_POSITION.BASEBALL.LF': '左翼手', // "좌익수",
  'PLAYER_POSITION.BASEBALL.CF': '中堅手', // "중견수",
  'PLAYER_POSITION.BASEBALL.RF': '右翼手', // "우익수",
  'PLAYER_POSITION.BASEBALL.DH': '指名打者', // "지명타자",
  'PLAYER_POSITION.FIELD_HOCKEY.FW': '攻撃手', // "공격수",
  'PLAYER_POSITION.FIELD_HOCKEY.MF': 'ミッドフィールド', // "미드필더",
  'PLAYER_POSITION.FIELD_HOCKEY.DF': 'ディフェンダー', // "수비수",
  'PLAYER_POSITION.FIELD_HOCKEY.GK': 'ゴールキーパー', // "골키퍼",
  'PLAYER_POSITION.RUGBY.HK': 'フッカー', // "후커",
  'PLAYER_POSITION.RUGBY.PR': 'プロップ', // "프롭",
  'PLAYER_POSITION.RUGBY.SR': 'ロック', // "록크",
  'PLAYER_POSITION.RUGBY.FL': 'フランカー', // "플랭커",
  'PLAYER_POSITION.RUGBY.NE': 'ナンバー8', // "넘버8",
  'PLAYER_POSITION.RUGBY.SH': 'スクラムハーフ', // "스크럼 하프",
  'PLAYER_POSITION.RUGBY.FH': 'フライハーフ', // "플라이 하프",
  'PLAYER_POSITION.RUGBY.CT': 'センター', // "센터",
  'PLAYER_POSITION.RUGBY.WG': 'ウイング', // "윙",
  'PLAYER_POSITION.RUGBY.FB': 'フルバック', // "풀백",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.C': 'センター', // "센터",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OG': 'オッフェンシブ·ガード', // "오펜시브 가드",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OT': 'オッフェンシブタックル', // "오펜시브 태클",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.QB': 'クォーターバック', // "쿼터백",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.RB': 'ランニングバック', // "런닝백",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.WR': 'ワイドレシーバー', // "와이드 리시버",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.TE': 'タイトエンド', // "타이트 엔드",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DT': 'ディフェンシブタックル', // "디펜시브 태클",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DE': 'ディフェンシブエンド', // "디펜시브 엔드",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.MLB': 'ミドルラインベッカー', // "미들 라인베커",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OLB': 'アウトサイド·ラインベッカー', // "아웃사이드 라인베커",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.CB': 'コーナーバック', // "코너백",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.S': 'セーフティー', // "세이프티",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.NB': 'ニクルバック', // "니클백",
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DB': 'ダイムバッグ', // "다임백",
  'PLAYER_POSITION.BILLIARDS.S': 'スヌーカー', // "스누커",
  'PLAYER_POSITION.BILLIARDS.P': 'プルー', // "풀",
  'PLAYER_POSITION.BILLIARDS.C': 'キャローム', // "캐롬",
  'PLAYER_POSITION.CRICKET.BO': 'ピッチャー', // "투수",
  'PLAYER_POSITION.CRICKET.BA': 'バッター', // "타자",
  'PLAYER_POSITION.CRICKET.WI': 'キャッチャー', // "포수",
  'PLAYER_POSITION.SEPAK_TAKRAW.K': 'キラー', // "킬러",
  'PLAYER_POSITION.SEPAK_TAKRAW.F': 'フィーダー', // "피더",
  'PLAYER_POSITION.SEPAK_TAKRAW.T': 'テコン', // "테콩",
  'PLAYER_POSITION.TEE_BALL.C': '本塁手', // "본루수",
  'PLAYER_POSITION.TEE_BALL._1B': '一塁手', // "1루수",
  'PLAYER_POSITION.TEE_BALL._2B': '二塁手', // "2루수",
  'PLAYER_POSITION.TEE_BALL._3B': '三塁手', // "3루수",
  'PLAYER_POSITION.TEE_BALL._1SS': '第1遊撃手', // "제1유격수",
  'PLAYER_POSITION.TEE_BALL._2SS': '第2遊撃手', // "제2유격수",
  'PLAYER_POSITION.TEE_BALL.LF': '左翼手', // "좌익수",
  'PLAYER_POSITION.TEE_BALL._1CF': '第1中堅手', // "제1중견수",
  'PLAYER_POSITION.TEE_BALL._2CF': '第2中堅手', // "제2중견수",
  'PLAYER_POSITION.TEE_BALL.RF': '右翼手,', // "우익수",
  'PLAYER_POSITION.FOOT_VOLLEYBALL.A': '攻撃手', // "공격수",
  'PLAYER_POSITION.FOOT_VOLLEYBALL.S': 'セタ', // "세타",
  'PLAYER_POSITION.FOOT_VOLLEYBALL.D': 'ディフェンダー', // "수비수",
  'PLAYER_POSITION.FUTSAL.PIVO': 'ピーボ', // "피보",
  'PLAYER_POSITION.FUTSAL.ALA': 'アラ', // "아라",
  'PLAYER_POSITION.FUTSAL.FIXO': 'ピクソ', // "픽소",
  'PLAYER_POSITION.FUTSAL.GOLEIRO': 'ゴレイロ', // "골레이로",
  'PLAYER_POSITION.SWIMMING.FREESTYLE': 'フリースタイル.', // "자유형",
  'PLAYER_POSITION.SWIMMING.BACKSTROKE': 'バックストローク', // "배영",
  'PLAYER_POSITION.SWIMMING.BREASTSTROKE': 'ブレスト', // "평영",
  'PLAYER_POSITION.SWIMMING.BUTTERFLY': 'バタフライ.', // "접영",
  'PLAYER_POSITION.SWIMMING.MEDLEY': '水泳メドレー', // "혼영",
  'PLAYER_POSITION.CANOE.SLALOM': 'スラローム', // "슬라럼",
  'PLAYER_POSITION.CANOE.SPRINT': 'スプリント.', // "스프린트",
  'PLAYER_POSITION.KAYAK.SLALOM': 'スラローム', // "슬라럼",
  'PLAYER_POSITION.KAYAK.SPRINT': 'スプリント', // "스프린트",
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.VAULT': 'VAULT', // "도마",
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.FLOOR_EXERCISES': 'EXERCISES', // "마루",
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.RINGS': 'リング', // "링",
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.HORIZONTAL_BAR': '鉄棒.', // "철봉",
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.PARALLEL_BAR': '平行棒.', // "평행봉",
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.POMMEL_HORSE': 'ポンメルホース', // "안마",
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.BALANCE_BEAM': '平均台.', // "평균대",
  'PLAYER_POSITION.ICE_SKATING.SPEED_SKATING': 'スピードスケート', // "스피드 스케이팅",
  'PLAYER_POSITION.ICE_SKATING.FIGURE_SKATING': 'フィギュアスケート', // "피겨 스케이팅",
  'PLAYER_POSITION.ICE_SKATING.SHORT_TRACK': 'ショートトラック.', // "쇼트트랙",
  'PLAYER_POSITION.ICE_HOCKEY.G': 'ゴールテンダー', // "골텐더",
  'PLAYER_POSITION.ICE_HOCKEY.RD': 'ライトディフェンス', // "라이트 디펜스",
  'PLAYER_POSITION.ICE_HOCKEY.LD': 'レフトディフェンス', // "레프트 디펜스",
  'PLAYER_POSITION.ICE_HOCKEY.RW': 'ライトウイング', // "라이트 윙",
  'PLAYER_POSITION.ICE_HOCKEY.CF': 'センターフォワード', // "센터 포워드",
  'PLAYER_POSITION.ICE_HOCKEY.LW': 'レフトウイング', // "레프트 윙",
  'PLAYER_POSITION.CURLING.LEAD': 'リード', // "리드",
  'PLAYER_POSITION.CURLING.SECOND': 'セカンド', // "세컨",
  'PLAYER_POSITION.CURLING.THIRD': 'サード', // "서드",
  'PLAYER_POSITION.CURLING.SKIP': 'スキップ', // "스킵",
  'PLAYER_POSITION.FENCING.FLEURET': 'フルーレ.', // "플뢰레",
  'PLAYER_POSITION.FENCING.SABRE': 'サブレ', // "사브레",
  'PLAYER_POSITION.FENCING.EPEE': ' エペ.', // "에페",

  // coach positions
  'COACH_POSITION.HEAD_COACH': '監督', // "감독",
  'COACH_POSITION.FIRST_COACH': 'チーフコーチ', // "수석 코치",
  'COACH_POSITION.COACH': 'コーチ', // "코치",
  'COACH_POSITION.PLAYING_COACH': 'プレイング·コーチ', // "플레잉 코치",
  'COACH_POSITION.ATHLETIC_TRAINER': '医務トレーナー', // "의무 트레이너",
  'COACH_POSITION.GK_COACH': 'ゴールキーパー・コーチ', // "골키퍼 코치",
  'COACH_POSITION.TACTICAL_COACH': '戦術コーチ', // "전술 코치",
  'COACH_POSITION.FITNESS_COACH': 'フィジカルコーチ', // "피지컬 코치",
  'COACH_POSITION.ASSISTANT_COACH': 'アシスタントコーチ', // "보조 코치",
  'COACH_POSITION.MANAGER': 'マネージャー', // "감독",
  'COACH_POSITION.BENCH_COACH': 'ベンチコーチ', // "벤치 코치",
  'COACH_POSITION.HITTING_COACH': '打撃コーチ', // "타격 코치",
  'COACH_POSITION.PITCHING_COACH': '投手コーチ', // "투수 코치",
  'COACH_POSITION.DEFENSIVE_COACH': '守備コーチ', // "수비 코치",
  'COACH_POSITION.RUNNING_COACH': '走塁コーチ', // "주루 코치",
  'COACH_POSITION.SnC_COACH': 'S&Cコーチ', // "S&C 코치",
  'COACH_POSITION.SWIMMING_COACH': '水泳コーチ', // "수영 코치"
  'COACH_POSITION.BIKE_COACH': '自転車コーチ', // "바이크 코치"

  // sports categories
  'SPORTS_CATEGORY_GROUP.ATHLETICS': '陸上', // "육상",
  'SPORTS_CATEGORY.SPRINT': '短距離走', // "단거리 달리기",
  'SPORTS_CATEGORY.MIDDLE_DISTANCE_RUNNING': '中距離走', // "중거리 달리기",
  'SPORTS_CATEGORY.LONG_DISTANCE_RUNNING': '長距離走', // "장거리 달리기",
  'SPORTS_CATEGORY.HURDLE': '障害物競走', // "장애물 달리기",
  'SPORTS_CATEGORY.RACE_WALKING': '競歩', // "경보",
  'SPORTS_CATEGORY.MARATHON': 'マラソン', // "마라톤",
  'SPORTS_CATEGORY.LONG_JUMP': '幅跳び', // "멀리뛰기",
  'SPORTS_CATEGORY.TRIPLE_JUMP': '三段跳', // "3단뛰기",
  'SPORTS_CATEGORY.HIGH_JUMP': '高跳び', // "높이뛰기",
  'SPORTS_CATEGORY.POLE_VAULT': '棒高跳び', // "장대높이뛰기",
  'SPORTS_CATEGORY.SHOT_PUT': '砲丸投げ', // "포환던지기",
  'SPORTS_CATEGORY.DISCUS_THROW': '円盤投げ', // "원반던지기",
  'SPORTS_CATEGORY.JAVELIN_THROW': '槍投げ', // "창던지기",
  'SPORTS_CATEGORY.HAMMER_THROW': 'ハンマー投げ', // "해머던지기",
  'SPORTS_CATEGORY.MEN_DECATHLON': '男子十種競技', // "남자 10종경기",
  'SPORTS_CATEGORY.WOMEN_HEPTATHLON': '女子七種競技', // "여자 7종경기",
  'SPORTS_CATEGORY_GROUP.BALL': '球技', // "구기",
  'SPORTS_CATEGORY.SOCCER': 'サッカー', // "축구",
  'SPORTS_CATEGORY.BASKETBALL': 'バスケットボール', // "농구",
  'SPORTS_CATEGORY.VOLLEYBALL': 'バレーボール', // "배구",
  'SPORTS_CATEGORY.HANDBALL': 'ハンドボール', // "핸드볼",
  'SPORTS_CATEGORY.BASEBALL': 'ベースボール', // "야구",
  'SPORTS_CATEGORY.FIELD_HOCKEY': 'フィールドホッケー', // "필드하키",
  'SPORTS_CATEGORY.RUGBY': 'ラグビー', // "럭비",
  'SPORTS_CATEGORY.AMERICAN_FOOTBALL': 'アメリカンフットボール', // "미식 축구",
  'SPORTS_CATEGORY.BILLIARDS': 'ビリヤード', // "당구",
  'SPORTS_CATEGORY.GOLF': 'ゴルフ', // "골프",
  'SPORTS_CATEGORY.CRICKET': 'クリケット', // "크리켓",
  'SPORTS_CATEGORY.BOCCIA': 'ボッチャ', // "보치아",
  'SPORTS_CATEGORY.SEPAK_TAKRAW': 'セパタクロー', // "세팍타크로",
  'SPORTS_CATEGORY.TEE_BALL': 'ティーボール', // "티볼",
  'SPORTS_CATEGORY.FOOT_VOLLEYBALL': '足球', // "족구",
  'SPORTS_CATEGORY.FUTSAL': 'フットサル', // "풋살",
  'SPORTS_CATEGORY.BOWLING': 'ボウリング', // "볼링",
  'SPORTS_CATEGORY_GROUP.RACKET': 'ラケット', // "라켓",
  'SPORTS_CATEGORY.RACQUETBALL': 'ラケットボール', // "라켓볼",
  'SPORTS_CATEGORY.BADMINTON': 'バドミントン', // "배드민턴",
  'SPORTS_CATEGORY.SQUASH': 'スカッシュ', // "스쿼시",
  'SPORTS_CATEGORY.SOFT_TENNIS': '庭球', // "정구",
  'SPORTS_CATEGORY.TABLE_TENNIS': 'ピンポン', // "탁구",
  'SPORTS_CATEGORY.TENNIS': 'テニス', // "테니스",
  'SPORTS_CATEGORY_GROUP.WATER': '水上', // "수상",
  'SPORTS_CATEGORY.SWIMMING': '水泳', // "수영",
  'SPORTS_CATEGORY.DIVING': 'ダイビング', // "다이빙",
  'SPORTS_CATEGORY.WATER_POLO': '水球', // "수구",
  'SPORTS_CATEGORY.SYNCHRONIZE': 'シンクロナイズド', // "싱크로나이즈드",
  'SPORTS_CATEGORY.ROWING': '漕艇', // "조정",
  'SPORTS_CATEGORY.CANOE': 'カヌー', // "카누",
  'SPORTS_CATEGORY.KAYAK': 'カヤック', // "카약",
  'SPORTS_CATEGORY.FINSWIMMING': 'ピン水泳', // "핀수영",
  'SPORTS_CATEGORY_GROUP.COMBAT': '格闘', // "격투",
  'SPORTS_CATEGORY.TAEKWONDO': 'テコンドー', // "태권도",
  'SPORTS_CATEGORY.TAEKKYEON': 'テッキョン', // "택견",
  'SPORTS_CATEGORY.KUNG_FU': 'カンフー', // "쿵푸",
  'SPORTS_CATEGORY.SSIREUM': '相撲', // "씨름",
  'SPORTS_CATEGORY.KARATE': '空手', // "가라데",
  'SPORTS_CATEGORY.JUDO': '柔道', // "유도",
  'SPORTS_CATEGORY.BOXING': 'ボクシング', // "복싱",
  'SPORTS_CATEGORY.KICKBOXING': 'キックボクシング', // "킥복싱",
  'SPORTS_CATEGORY.WRESTLING': 'レスリング', // "레슬링",
  'SPORTS_CATEGORY.ARM_WRESTLING': '腕相撲', // "팔씨름",
  'SPORTS_CATEGORY_GROUP.GYMNASTICS': '体操', // "체조",
  'SPORTS_CATEGORY.ARTISTIC_GYMNASTICS': '器械体操', // "기계체조",
  'SPORTS_CATEGORY.RHYTHMIC_GYMNASTICS': 'リズム体操', // "리듬체조",
  'SPORTS_CATEGORY.TRAMPOLINE': 'トランポリン', // "트램펄린",
  'SPORTS_CATEGORY.AEROBICS': 'エアロビクス', // "에어로빅",
  'SPORTS_CATEGORY_GROUP.WEIGHTLIFTING': '力道', // "역도",
  'SPORTS_CATEGORY.WEIGHTLIFTING': '力道', // "역도",
  'SPORTS_CATEGORY_GROUP.EQUESTRIAN': '乗馬', // "승마",
  'SPORTS_CATEGORY.EQUESTRIAN': '乗馬', // "승마",
  'SPORTS_CATEGORY_GROUP.CYCLING': 'サイクル', // "사이클",
  'SPORTS_CATEGORY.CYCLING': 'サイクル', // "사이클",
  'SPORTS_CATEGORY_GROUP.WINTER': '冬季', // "동계",
  'SPORTS_CATEGORY.ICE_SKATING': 'アイススケート', // "아이스 스케이팅",
  'SPORTS_CATEGORY.ICE_HOCKEY': 'アイスホッケー', // "아이스 하키",
  'SPORTS_CATEGORY.CURLING': 'カーリング', // "컬링",
  'SPORTS_CATEGORY.SKIING': 'スキー', // "스키",
  'SPORTS_CATEGORY.ALPINE_SKIING': 'アルペンスキー', // "알파인 스키",
  'SPORTS_CATEGORY.NORDIC_SKIING': 'ノルディックスキー', // "노르딕 스키",
  'SPORTS_CATEGORY.FREESTYLE_SKIING': 'フリースタイルスキー', // "프리스타일 스키",
  'SPORTS_CATEGORY.CROSS_COUNTRY_SKIING': 'クロスカントリー', // "크로스컨트리",
  'SPORTS_CATEGORY.SKI_JUMPING': 'スキージャンプ', // "스키점프",
  'SPORTS_CATEGORY.BIATHLON': 'バイアスロン', // "바이애슬론",
  'SPORTS_CATEGORY.SNOWBOARDING': 'スノーボード', // "스노우보드",
  'SPORTS_CATEGORY.BOBSLEIGH': 'ボッブスレー', // "봅슬레이",
  'SPORTS_CATEGORY.LUGE': 'リュージュ', // "루지",
  'SPORTS_CATEGORY.SKELETON': 'スケルトン', // "스켈레톤",
  'SPORTS_CATEGORY_GROUP.ARCHERY': 'アーチェリー', // "양궁",
  'SPORTS_CATEGORY.ARCHERY': 'アーチェリー', // "양궁",
  'SPORTS_CATEGORY_GROUP.FENCING': 'フェンシング', // "펜싱",
  'SPORTS_CATEGORY.FENCING': 'フェンシング', // "펜싱",
  'SPORTS_CATEGORY_GROUP.SHOOTING': '射撃', // "사격",
  'SPORTS_CATEGORY.SHOOTING': '射撃', // "사격",
  'SPORTS_CATEGORY_GROUP.ROLLER': 'ローラー', // "롤러",
  'SPORTS_CATEGORY.ROLLER': 'ローラー', // "롤러",
  'SPORTS_CATEGORY_GROUP.MODERN_PENTATHLON': '近代五種', // "근대 5종",
  'SPORTS_CATEGORY.MODERN_PENTATHLON': '近代五種', // "근대 5종",
  'SPORTS_CATEGORY_GROUP.ETC': 'その外', // "기타",
  'SPORTS_CATEGORY.ETC': 'その外', // "기타",
  'SPORTS_CATEGORY_GROUP.TRIATHLON': 'トライアスロン', // "트라이애슬론"
  'SPORTS_CATEGORY.TRIATHLON': 'トライアスロン', // "트라이애슬론"

  // team authorities
  'TEAM_AUTHORITY.OWNER': '所有者', // "소유자",
  'TEAM_AUTHORITY.ADMIN': '管理者', // "관리자",
  'TEAM_AUTHORITY.MEMBER': 'メンバー', // "멤버",
  'TEAM_AUTHORITY.VIEWER': 'ビューアー', // "뷰어",

  // country codes
  'COUNTRY_CODE.AD': 'アンドラ', // "안도라",
  'COUNTRY_CODE.AE': 'アラブ首長国連邦', // "아랍에미리트",
  'COUNTRY_CODE.AF': 'アフガニスタン', // "아프가니스탄",
  'COUNTRY_CODE.AG': 'アンチグア·バーブダ', // "앤티가 바부다",
  'COUNTRY_CODE.AI': 'アンギュラ', // "앵귈라",
  'COUNTRY_CODE.AL': 'アルバニア', // "알바니아",
  'COUNTRY_CODE.AM': 'アルメニア', // "아르메니아",
  'COUNTRY_CODE.AO': 'アンゴラ', // "앙골라",
  'COUNTRY_CODE.AQ': '南極', // "남극",
  'COUNTRY_CODE.AR': 'アルゼンチン', // "아르헨티나",
  'COUNTRY_CODE.AS': 'アメリカンサモア', // "아메리칸사모아",
  'COUNTRY_CODE.AT': 'オーストリア', // "오스트리아",
  'COUNTRY_CODE.AU': 'オーストラリア', // "오스트레일리아",
  'COUNTRY_CODE.AW': 'アルバ', // "아루바",
  'COUNTRY_CODE.AX': 'オーランド諸島', // "올란드 제도",
  'COUNTRY_CODE.AZ': 'アゼルバイジャン', // "아제르바이잔",
  'COUNTRY_CODE.BA': 'ボスニア·ヘルツェゴビナ', // "보스니아 헤르체고비나",
  'COUNTRY_CODE.BB': 'バルバドス', // "바베이도스",
  'COUNTRY_CODE.BD': 'バングラデシュ', // "방글라데시",
  'COUNTRY_CODE.BE': 'ベルギー', // "벨기에",
  'COUNTRY_CODE.BF': 'ブルキナファソ', // "부르키나파소",
  'COUNTRY_CODE.BG': 'ブルガリア', // "불가리아",
  'COUNTRY_CODE.BH': 'バーレーン', // "바레인",
  'COUNTRY_CODE.BI': 'ブルンジ', // "부룬디",
  'COUNTRY_CODE.BJ': 'ベナン', // "베냉",
  'COUNTRY_CODE.BL': 'サン·バルテレミ', // "생바르텔레미",
  'COUNTRY_CODE.BM': 'バミューダ', // "버뮤다",
  'COUNTRY_CODE.BN': 'ブルネイ', // "브루나이",
  'COUNTRY_CODE.BO': 'ボリビア', // "볼리비아",
  'COUNTRY_CODE.BQ': 'カリブ·オランダ', // "카리브 네덜란드",
  'COUNTRY_CODE.BR': 'ブラジル', // "브라질",
  'COUNTRY_CODE.BS': 'バハマ', // "바하마",
  'COUNTRY_CODE.BT': 'ブータン', // "부탄",
  'COUNTRY_CODE.BV': 'ブーベ島', // "부베 섬",
  'COUNTRY_CODE.BW': 'ボツワナ', // "보츠와나",
  'COUNTRY_CODE.BY': 'ベラルーシ', // "벨라루스
  'COUNTRY_CODE.BZ': 'ベリーズ', // "벨리즈",
  'COUNTRY_CODE.CA': 'カナダ', // "캐나다",
  'COUNTRY_CODE.CC': 'ココス諸島', // "코코스 제도",
  'COUNTRY_CODE.CD': 'コンゴ民主共和国', // "콩고 민주 공화국",
  'COUNTRY_CODE.CF': '中央アフリカ共和国', // "중앙아프리카 공화국",
  'COUNTRY_CODE.CG': 'コンゴ共和国', // "콩고 공화국",
  'COUNTRY_CODE.CH': 'スイス', // "스위스",
  'COUNTRY_CODE.CI': 'コートジボワール', // "코트디부아르",
  'COUNTRY_CODE.CK': 'クック諸島', // "쿡 제도",
  'COUNTRY_CODE.CL': 'チリ', // "칠레",
  'COUNTRY_CODE.CM': 'カメルーン', // "카메룬",
  'COUNTRY_CODE.CN': '中華人民共和国', // "중화인민공화국",
  'COUNTRY_CODE.CO': 'コロンビア', // "콜롬비아",
  'COUNTRY_CODE.CR': 'コスタリカ', // "코스타리카",
  'COUNTRY_CODE.CU': 'キューバ', // "쿠바",
  'COUNTRY_CODE.CV': 'カーボベルデ', // "카보베르데",
  'COUNTRY_CODE.CW': 'キュラソー', // "퀴라소",
  'COUNTRY_CODE.CX': 'クリスマス島', // "크리스마스 섬",
  'COUNTRY_CODE.CY': 'キプロス', // "키프로스",
  'COUNTRY_CODE.CZ': 'チェコ', // "체코",
  'COUNTRY_CODE.DE': 'ドイツ', // "독일",
  'COUNTRY_CODE.DJ': 'ジブチ', // "지부티",
  'COUNTRY_CODE.DK': 'デンマーク', // "덴마크",
  'COUNTRY_CODE.DM': 'ドミニカ連邦', // "도미니카 연방",
  'COUNTRY_CODE.DO': 'ドミニカ共和国', // "도미니카 공화국",
  'COUNTRY_CODE.DZ': 'アルジェリア', // "アルジェリア",
  'COUNTRY_CODE.EC': 'エクアドル', // "에콰도르",
  'COUNTRY_CODE.EE': 'エストニア', // "에스토니아",
  'COUNTRY_CODE.EG': 'エジプト', // "이집트",
  'COUNTRY_CODE.EH': '西サハラ', // "서사하라",
  'COUNTRY_CODE.ER': 'エリトリア', // "에리트레아",
  'COUNTRY_CODE.ES': 'スペイン', // "스페인",
  'COUNTRY_CODE.ET': 'エチオピア', // "에티오피아",
  'COUNTRY_CODE.FI': 'フィンランド', // "핀란드",
  'COUNTRY_CODE.FJ': 'フィジー', // "피지",
  'COUNTRY_CODE.FK': 'フォークランド諸島', // "포클랜드 제도",
  'COUNTRY_CODE.FM': 'ミクロネシア連邦', // "미크로네시아 연방",
  'COUNTRY_CODE.FO': 'フェロー諸島', // "페로 제도",
  'COUNTRY_CODE.FR': 'フランス', // "프랑스",
  'COUNTRY_CODE.GA': 'ガボン', // "가봉",
  'COUNTRY_CODE.GB': 'イギリス', // "영국",
  'COUNTRY_CODE.GD': 'グレナダ', // "그레나다",
  'COUNTRY_CODE.GE': 'ジョージア', // "조지아",
  'COUNTRY_CODE.GF': 'フランス領ギアナ', // "프랑스령 기아나",
  'COUNTRY_CODE.GG': 'ガーンジー島', // "건지 섬",
  'COUNTRY_CODE.GH': 'ガーナ', // "가나",
  'COUNTRY_CODE.GI': 'ジブラルタル.', // "지브롤터",
  'COUNTRY_CODE.GL': 'グリーンランド', // "그린란드",
  'COUNTRY_CODE.GM': 'ガンビア', // "감비아",
  'COUNTRY_CODE.GN': 'ギニア', // "기니",
  'COUNTRY_CODE.GP': 'グアドループ', // "과들루프",
  'COUNTRY_CODE.GQ': '赤道ギニア', // "적도 기니",
  'COUNTRY_CODE.GR': 'グリース', // "그리스",
  'COUNTRY_CODE.GS': 'サウスジョージア州サウスサンドウィッチ諸島', // "사우스조지아 사우스샌드위치 제도",
  'COUNTRY_CODE.GT': 'グアテマラ', // "과테말라",
  'COUNTRY_CODE.GU': 'グアム', // "괌",
  'COUNTRY_CODE.GW': 'ギニアビサウ', // "기니비사우",
  'COUNTRY_CODE.GY': 'ガイアナ', // "가이아나",
  'COUNTRY_CODE.HK': 'ホンコン', // "홍콩",
  'COUNTRY_CODE.HM': 'ハド·マクドナルド諸島', // "허드 맥도널드 제도",
  'COUNTRY_CODE.HN': 'ホンジュラス', // "온두라스",
  'COUNTRY_CODE.HR': 'クロアチア', // "크로아티아",
  'COUNTRY_CODE.HT': 'アイティー,', // "아이티",
  'COUNTRY_CODE.HU': 'ハンガリー', // "헝가리",
  'COUNTRY_CODE.ID': 'インドネシア', // "인도네시아",
  'COUNTRY_CODE.IE': 'アイルランド', // "아일랜드",
  'COUNTRY_CODE.IL': 'イスラエル', // "이스라엘",
  'COUNTRY_CODE.IM': 'マン島', // "맨 섬",
  'COUNTRY_CODE.IN': 'インド', // "인도",
  'COUNTRY_CODE.IO': 'イギリス領インド洋地域', // "영국령 인도양 지역",
  'COUNTRY_CODE.IQ': 'イラク', // "이라크",
  'COUNTRY_CODE.IR': 'イラン', // "이란",
  'COUNTRY_CODE.IS': 'アイスランド', // "아이슬란드",
  'COUNTRY_CODE.IT': 'イタリア', // "이탈리아",
  'COUNTRY_CODE.JE': 'ジャージー島', // "저지 섬",
  'COUNTRY_CODE.JM': 'ジャマイカ', // "자메이카",
  'COUNTRY_CODE.JO': 'ヨルダン', // "요르단",
  'COUNTRY_CODE.JP': '日本', // "일본",
  'COUNTRY_CODE.KE': 'ケニア', // "케냐",
  'COUNTRY_CODE.KG': 'キルギスタン', // "키르기스스탄",
  'COUNTRY_CODE.KH': 'カンボジア', // "캄보디아",
  'COUNTRY_CODE.KI': 'キリバス', // "키리바시",
  'COUNTRY_CODE.KM': 'コモロ', // "코모로",
  'COUNTRY_CODE.KN': 'セント·キッツ·ネビス', // "세인트키츠 네비스",
  'COUNTRY_CODE.KP': '朝鮮民主主義人民共和国', // "조선민주주의인민공화국",
  'COUNTRY_CODE.KR': '大韓民国', // "대한민국",
  'COUNTRY_CODE.KW': 'クウェート', // "쿠웨이트",
  'COUNTRY_CODE.KY': 'ケイマン諸島', // "케이맨 제도",
  'COUNTRY_CODE.KZ': 'カザフスタン', // "카자흐스탄",
  'COUNTRY_CODE.LA': 'ラオス', // "라오스",
  'COUNTRY_CODE.LB': 'レバノン', // "레바논",
  'COUNTRY_CODE.LC': 'セントルシア', // "세인트루시아",
  'COUNTRY_CODE.LI': 'リヒテンシュタイン', // "리히텐슈타인",
  'COUNTRY_CODE.LK': 'スリランカ', // "스리랑카",
  'COUNTRY_CODE.LR': 'リベリア', // "라이베리아",
  'COUNTRY_CODE.LS': 'レソト', // "레소토",
  'COUNTRY_CODE.LT': 'リトアニア', // "리투아니아",
  'COUNTRY_CODE.LU': 'ルクセンブルク', // "룩셈부르크",
  'COUNTRY_CODE.LV': 'ラトビア', // "라트비아",
  'COUNTRY_CODE.LY': 'リビア', // "리비아",
  'COUNTRY_CODE.MA': 'モロッコ', // "모로코",
  'COUNTRY_CODE.MC': 'モナコ', // "모나코",
  'COUNTRY_CODE.MD': 'モルドバ', // "몰도바",
  'COUNTRY_CODE.ME': 'モンテネグロ', // "몬테네그로",
  'COUNTRY_CODE.MF': 'サン·マルタン', // "생마르탱",
  'COUNTRY_CODE.MG': 'マダガスカル', // "마다가스카르",
  'COUNTRY_CODE.MH': 'マーシャル諸島', // "마셜 제도",
  'COUNTRY_CODE.MK': 'マケドニア共和国', // "마케도니아 공화국",
  'COUNTRY_CODE.ML': 'マリ', // "말리",
  'COUNTRY_CODE.MM': 'ミャンマー', // "미얀마",
  'COUNTRY_CODE.MN': 'モンゴル', // "몽골",
  'COUNTRY_CODE.MO': 'マカオ', // "마카오",
  'COUNTRY_CODE.MP': '北マリアナ諸島', // "북마리아나 제도",
  'COUNTRY_CODE.MQ': 'マルティニーク', // "마르티니크",
  'COUNTRY_CODE.MR': 'モーリタニア', // "모리타니",
  'COUNTRY_CODE.MS': 'モントセラット', // "몬트세랫",
  'COUNTRY_CODE.MT': 'マルタ', // "몰타",
  'COUNTRY_CODE.MU': 'モーリシャス', // "모리셔스",
  'COUNTRY_CODE.MV': 'モルディブ', // "몰디브",
  'COUNTRY_CODE.MW': 'マラウイ', // "말라위",
  'COUNTRY_CODE.MX': 'メキシコ', // "멕시코",
  'COUNTRY_CODE.MY': 'マレーシア', // "말레이시아",
  'COUNTRY_CODE.MZ': 'モザンビーク', // "모잠비크",
  'COUNTRY_CODE.NA': 'ナミビア', // "나미비아",
  'COUNTRY_CODE.NC': 'ヌーベルカレドニ', // "누벨칼레도니",
  'COUNTRY_CODE.NE': 'ニジェール', // "니제르",
  'COUNTRY_CODE.NF': 'ノーフォーク島', // "노퍽 섬",
  'COUNTRY_CODE.NG': 'ナイジェリア', // "나이지리아",
  'COUNTRY_CODE.NI': 'ニカラグア', // "니카라과",
  'COUNTRY_CODE.NL': 'オランダ', // "네덜란드",
  'COUNTRY_CODE.NO': 'ノルウェー', // "노르웨이",
  'COUNTRY_CODE.NP': 'ネパール', // "네팔",
  'COUNTRY_CODE.NR': 'ナウル', // "나우루",
  'COUNTRY_CODE.NU': 'ニウエ', // "니우에",
  'COUNTRY_CODE.NZ': 'ニュージーランド', // "뉴질랜드",
  'COUNTRY_CODE.OM': 'オマーン', // "오만",
  'COUNTRY_CODE.PA': 'パナマ', // "파나마",
  'COUNTRY_CODE.PE': 'ペルー', // "페루",
  'COUNTRY_CODE.PF': 'フランス領ポリネシア', // "프랑스령 폴리네시아",
  'COUNTRY_CODE.PG': 'パプアニューギニア', // "파푸아 뉴기니",
  'COUNTRY_CODE.PH': 'フィリピン', // "필리핀",
  'COUNTRY_CODE.PK': 'パキスタン', // "파키스탄",
  'COUNTRY_CODE.PL': 'ポーランド', // "폴란드",
  'COUNTRY_CODE.PM': 'サンピエール·ミクロン', // "생피에르 미클롱",
  'COUNTRY_CODE.PN': 'ピッケアン諸島', // "핏케언 제도",
  'COUNTRY_CODE.PR': 'プエルトリコ.', // "푸에르토리코",
  'COUNTRY_CODE.PS': 'パレスチナ', // "팔레스타인",
  'COUNTRY_CODE.PT': 'ポルトガル', // "포르투갈",
  'COUNTRY_CODE.PW': 'パラオ', // "팔라우",
  'COUNTRY_CODE.PY': 'パラグアイ', // "파라과이",
  'COUNTRY_CODE.QA': 'カタール', // "카타르",
  'COUNTRY_CODE.RE': 'レウィニオン', // "레위니옹",
  'COUNTRY_CODE.RO': 'ルーマニア', // "루마니아",
  'COUNTRY_CODE.RS': 'セルビア', // "세르비아",
  'COUNTRY_CODE.RU': 'ロシア', // "러시아",
  'COUNTRY_CODE.RW': 'ルワンダ', // "르완다",
  'COUNTRY_CODE.SA': 'サウジアラビア', // "사우디아라비아",
  'COUNTRY_CODE.SB': 'ソロモン諸島', // "솔로몬 제도",
  'COUNTRY_CODE.SC': 'セーシェル', // "세이셸",
  'COUNTRY_CODE.SD': 'スーダン', // "수단",
  'COUNTRY_CODE.SE': 'スウェーデン', // "스웨덴",
  'COUNTRY_CODE.SG': 'シンガポール', // "싱가포르",
  'COUNTRY_CODE.SH': 'セントヘレナ', // "세인트헬레나",
  'COUNTRY_CODE.SI': 'スロベニア', // "슬로베니아",
  'COUNTRY_CODE.SJ': 'スバルバルヤンマイエン', // "스발바르 얀마옌",
  'COUNTRY_CODE.SK': 'スロバキア', // "슬로바키아",
  'COUNTRY_CODE.SL': 'シエラレオネ', // "시에라리온",
  'COUNTRY_CODE.SM': 'サンマリノ', // "산마리노",
  'COUNTRY_CODE.SN': 'セネガル', // "세네갈",
  'COUNTRY_CODE.SO': 'ソマリア', // "소말리아",
  'COUNTRY_CODE.SR': 'スリナム', // "수리남",
  'COUNTRY_CODE.SS': '南スーダン', // "남수단",
  'COUNTRY_CODE.ST': 'サントメ·プリンシペ', // "상투메 프린시페",
  'COUNTRY_CODE.SV': 'エルサルバドル', // "엘살바도르",
  'COUNTRY_CODE.SX': '栄養マルトン', // "신트마르턴",
  'COUNTRY_CODE.SY': 'シリア', // "시리아",
  'COUNTRY_CODE.SZ': 'スワジランド', // "스와질란드",
  'COUNTRY_CODE.TC': 'トクスケイコス制度', // "터크스 케이커스 제도",
  'COUNTRY_CODE.TD': 'チャド', // "차드",
  'COUNTRY_CODE.TF': 'フランス領南部と南極地域', // "프랑스령 남부와 남극 지역",
  'COUNTRY_CODE.TG': 'トーゴ', // "토고",
  'COUNTRY_CODE.TH': 'タイ', // "타이",
  'COUNTRY_CODE.TJ': 'タジキスタン', // "타지키스탄",
  'COUNTRY_CODE.TK': 'トケラウ', // "토켈라우",
  'COUNTRY_CODE.TL': '東ティモール', // "동티모르",
  'COUNTRY_CODE.TM': 'トルクメニスタン', // "투르크메니스탄",
  'COUNTRY_CODE.TN': 'チュニジア', // "튀니지",
  'COUNTRY_CODE.TO': 'トンガ', // "통가",
  'COUNTRY_CODE.TR': 'トルコ', // "터키",
  'COUNTRY_CODE.TT': 'トリニダード·トバゴ', // "트리니다드 토바고",
  'COUNTRY_CODE.TV': 'ツバル', // "투발루",
  'COUNTRY_CODE.TW': '中華民国,', // "중화민국",
  'COUNTRY_CODE.TZ': 'タンザニア', // "탄자니아",
  'COUNTRY_CODE.UA': 'ウクライナ', // "우크라이나",
  'COUNTRY_CODE.UG': 'ウガンダ', // "우간다",
  'COUNTRY_CODE.UM': 'アメリカ領軍曹制度', // "미국령 군소 제도",
  'COUNTRY_CODE.US': 'アメリカ', // "미국",
  'COUNTRY_CODE.UY': 'ウルグアイ', // "우루과이",
  'COUNTRY_CODE.UZ': 'ウズベキスタン', // "우즈베키스탄",
  'COUNTRY_CODE.VA': 'バチカン市国', // "바티칸 시국",
  'COUNTRY_CODE.VC': 'セントビンセント·グレナジン', // "세인트빈센트 그레나딘",
  'COUNTRY_CODE.VE': 'ベネズエラ', // "베네수엘라",
  'COUNTRY_CODE.VG': '英国領のバージンアイルランド', // "영국령 버진아일랜드",
  'COUNTRY_CODE.VI': 'アメリカ領バージンアイランド', // "미국령 버진아일랜드",
  'COUNTRY_CODE.VN': 'ベトナム', // "베트남",
  'COUNTRY_CODE.VU': 'バヌアツ', // "바누아투",
  'COUNTRY_CODE.WF': 'ワリス·ピュチュナ', // "왈리스 퓌튀나",
  'COUNTRY_CODE.WS': 'サモア', // "사모아",
  'COUNTRY_CODE.YE': 'イエメン', // "예멘",
  'COUNTRY_CODE.YT': 'マヨット', // "마요트",
  'COUNTRY_CODE.ZA': '南アフリカ共和国', // "남아프리카 공화국",
  'COUNTRY_CODE.ZM': 'ザンビア', // "잠비아",
  'COUNTRY_CODE.ZW': 'ジンバブエ', // "짐바브웨",

  // body parts
  'BODY_PART.HEAD': '頭の 部分', // "머리 부분",
  'BODY_PART.TORSO': '体の　部分', // "몸 부분",
  'BODY_PART.RIGHT_ARM': '右腕 部分', // "오른팔 부분",
  'BODY_PART.LEFT_ARM': '左腕 部分', // "왼팔 부분",
  'BODY_PART.RIGHT_LEG': '右足 部分', // "오른다리 부분",
  'BODY_PART.LEFT_LEG': '左足の　部分', // "왼다리 부분",
  'BODY_PART.FORE_HEAD': '額', // "이마",
  'BODY_PART.FACE': '顔', // "얼굴",
  'BODY_PART.FRONT_NECK': '首', // "목",
  'BODY_PART.BACK_HEAD': '頭', // "머리",
  'BODY_PART.BACK_NECK': '頸椎', // "경추",
  'BODY_PART.RIGHT_CHEST': '右胸', // "오른쪽 가슴",
  'BODY_PART.LEFT_CHEST': '左胸', // "왼쪽 가슴",
  'BODY_PART.UPPER_ABDOMEN': 'へその上', // "윗배",
  'BODY_PART.LOWER_ABDOMEN': '下腹', // "아랫배",
  'BODY_PART.RIGHT_RIB': '右脇腹', // "오른쪽 옆구리",
  'BODY_PART.LEFT_RIB': '左脇腹', // "왼쪽 옆구리",
  'BODY_PART.REPRODUCTIVE_ORGANS': '生殖器', // "생식기",
  'BODY_PART.RIGHT_WING': '右翼', // "오른쪽 날개",
  'BODY_PART.LEFT_WING': '左翼', // "왼쪽 날개",
  'BODY_PART.UPPER_BACK': '上の背中', // "위쪽 등",
  'BODY_PART.MIDDLE_BACK': '下側の背中', // "아래쪽 등",
  'BODY_PART.LOWER_BACK': '腰', // "허리",
  'BODY_PART.TAILBONE': '尾骨', // "꼬리뼈",
  'BODY_PART.RIGHT_SHOULDER': '右肩', // "오른쪽 어깨",
  'BODY_PART.RIGHT_UPPER_ARM': '右上の腕', // "오른쪽 위팔",
  'BODY_PART.RIGHT_FORE_ARM': '右下腕', // "오른쪽 아래팔",
  'BODY_PART.RIGHT_WRIST': '右手首', // "오른쪽 손목",
  'BODY_PART.RIGHT_PALM': '右手のひら', // "오른쪽 손바닥",
  'BODY_PART.RIGHT_FINGER': '右指', // "오른쪽 손가락",
  'BODY_PART.RIGHT_ELBOW': '右肘', // "오른쪽 팔꿈치",
  'BODY_PART.RIGHT_BACK_HAND': '右手の甲', // "오른쪽 손등",
  'BODY_PART.LEFT_SHOULDER': '左肩', // "왼쪽 어깨",
  'BODY_PART.LEFT_UPPER_ARM': '左上腕', // "왼쪽 위팔",
  'BODY_PART.LEFT_FORE_ARM': '左下腕', // "왼쪽 아래팔",
  'BODY_PART.LEFT_WRIST': '左手首', // "왼쪽 손목",
  'BODY_PART.LEFT_PALM': '左手のひら', // "왼쪽 손바닥",
  'BODY_PART.LEFT_FINGER': '左指', // "왼쪽 손가락",
  'BODY_PART.LEFT_ELBOW': '左肘', // "왼쪽 팔꿈치",
  'BODY_PART.LEFT_BACK_HAND': '左手の甲', // "왼쪽 손등",
  'BODY_PART.RIGHT_HIP_JOINT': '右股関節', // "오른쪽 고관절",
  'BODY_PART.RIGHT_GROIN': '右股間', // "오른쪽 사타구니",
  'BODY_PART.RIGHT_FRONT_THIGH': '右太もも', // "오른쪽 허벅지",
  'BODY_PART.RIGHT_FRONT_KNEE': '右ひざ', // "오른쪽 무릎",
  'BODY_PART.RIGHT_FRONT_LEG': '右すね', // "오른쪽 정강이",
  'BODY_PART.RIGHT_ANKLE': '右足首', // "오른쪽 발목",
  'BODY_PART.RIGHT_INSTEP': '右足の甲', // "오른쪽 발등",
  'BODY_PART.RIGHT_TOE': '右足の指', // "오른쪽 발가락",
  'BODY_PART.RIGHT_HIP': '右の尻', // "오른쪽 엉덩이",
  'BODY_PART.RIGHT_BACK_THIGH': '右後太もも', // "오른쪽 뒷허벅지",
  'BODY_PART.RIGHT_BACK_KNEE': '右ひざのうら', // "오른쪽 오금",
  'BODY_PART.RIGHT_BACK_LEG': '右ふくらはぎ', // "오른쪽 종아리",
  'BODY_PART.RIGHT_ACHILLES_TENDON': '右アキレス腱', // "오른쪽 아킬레스건",
  'BODY_PART.RIGHT_SOLE': '右足の裏', // "오른쪽 발바닥",
  'BODY_PART.LEFT_HIP_JOINT': '左股関節', // "왼쪽 고관절",
  'BODY_PART.LEFT_GROIN': '左股間', // "왼쪽 사타구니",
  'BODY_PART.LEFT_FRONT_THIGH': '左太もも', // "왼쪽 허벅지",
  'BODY_PART.LEFT_FRONT_KNEE': '左ひざ', // "왼쪽 무릎",
  'BODY_PART.LEFT_FRONT_LEG': '左すね', // "왼쪽 정강이",
  'BODY_PART.LEFT_ANKLE': '左足首', // "왼쪽 발목",
  'BODY_PART.LEFT_INSTEP': '左足の甲', // "왼쪽 발등",
  'BODY_PART.LEFT_TOE': '左の足の指', // "왼쪽 발가락",
  'BODY_PART.LEFT_HIP': '左尻', // "왼쪽 엉덩이",
  'BODY_PART.LEFT_BACK_THIGH': '左後ろ太もも', // "왼쪽 뒷허벅지",
  'BODY_PART.LEFT_BACK_KNEE': '左ひざのうら', // "왼쪽 오금",
  'BODY_PART.LEFT_BACK_LEG': '左ふくらはぎ', // "왼쪽 종아리",
  'BODY_PART.LEFT_ACHILLES_TENDON': '左アキレス腱', // "왼쪽 아킬레스건",
  'BODY_PART.LEFT_SOLE': '左足の裏', // "왼쪽 발바닥",

  // soreness level
  SORENESS_LEVEL_0: '痛みなし', // '통증없음',
  SORENESS_LEVEL_1: '1段階', // '1단계',
  SORENESS_LEVEL_2: '2段階', // '2단계',
  SORENESS_LEVEL_3: '3段階', //'3단계',
  SORENESS_LEVEL_4: '4段階', //'4단계',
  SORENESS_LEVEL_5: '5段階', // '5단계',

  // injury quality
  SHARP: '鋭い', // "날카로운",
  BURNING: '燃えるような', // "타는듯한",
  CUTTING: 'ナイフで切ったように痛い', // "칼로 벤 것처럼 아픈",
  TINGLING: 'うずしは', // "쑤시는",
  COMES_AND_GOES: '痛かったり痛くなかったりする', // "아팠다 안 아팠다 하는",
  DULL: '鈍い', // "둔한",
  PRESSING: '押すような', // "누르는듯한",
  NUMBING: 'しびれ', // "저린",
  CRAMPING: '締め付けるような', // "죄는 듯한",
  RADIATING: '他の部位に広がるように', // "다른 부위로 퍼지듯",

  // injury timing
  SUSTAINING: '一定の', // "일정한",
  OCCASIONAL: '間欠的な', // "간헐적인",
  GRADUAL: '漸進的な', // "점진적인",
  SUDDEN: '突然の', // "갑작스러운",

  // custom data labels
  DATA: 'データ', // "데이터",
  'DATA.NONE': 'なし', // "없음",
  'DATA.READINESS': '準備度', // "준비도",
  'DATA.WORKLOAD': '運動負荷', // "운동부하",
  'DATA.DONUT.WORKLOAD_READINESS': '総合運動負荷', // "종합 운동부하",
  'DATA.WORKLOAD_READINESS': '総合運動負荷準備度', // "종합 운동부하 준비도",
  'DATA.WORKLOAD_TOTAL': '総運動負荷', // "총 운동부하",
  'DATA.WORKLOAD_RAW': '運動負荷', // "운동부하",
  'DATA.WORKLOAD_INTENSITY': '運動強度', // "운동강도",
  'DATA.WORKLOAD_DURATION': '運動時間', // "운동시간",
  'DATA.WORKLOAD_SATISFACTION': '運動満足', // "운동만족",
  'DATA.WORKLOAD_AX': '急性負荷', // "급성부하",
  'DATA.WORKLOAD_AXI': '直近1週間の運動負荷レベル', // "최근 1주 운동부하 수준",
  'DATA.WORKLOAD_AXD': '単調さ', // "단조로움",
  'DATA.WORKLOAD_CX': '慢性負荷', // "만성부하",
  'DATA.WORKLOAD_ACXR': '負傷危険度 (ACWR)', // "부상 위험도(ACWR)",
  'DATA.CONDITION': 'コンディション', // "컨디션",
  'DATA.DONUT.CONDITION_READINESS': '総合コンディション', // "종합 컨디션",
  'DATA.CONDITION_READINESS': '総合コンディションの準備度', // "종합 컨디션 준비도",
  'DATA.CONDITION_AX': '最近1週間のコンディション', // "최근 1주 컨디션",
  'DATA.CONDITION_CX': '最近4週間のコンディション', // "최근 4주 컨디션",
  'DATA.WELLNESS': 'ウェルネス', // "웰니스",
  'DATA.WELLNESS_MUSCLE': '筋肉', // "근육",
  'DATA.WELLNESS_MOOD': '気分', // "기분",
  'DATA.WELLNESS_FATIGUE': '疲労度', // "피로도",
  'DATA.WELLNESS_STRESS': 'ストレス', // "스트레스",
  'DATA.WELLNESS_RECOVERY': '回復', // "회복",
  'DATA.SLEEP': '睡眠', // "수면",
  'DATA.SLEEP_DURATION': '睡眠時間', // "수면 시간",
  'DATA.SLEEP_QUALITY': '睡眠品質', // "수면 품질",
  'DATA.RECOVERY': '回復', // "회복",
  'DATA.ACWR': '負傷の危険度', // "부상 위험도",
  'DATA.INJURY': '負傷', // "부상",
  'DATA.DONUT.INJURY_READINESS': '総合負傷', // "종합 부상",
  'DATA.INJURY_READINESS': '総合負傷準備度', // "종합 부상 준비도",
  'DATA.SORENESS_AND_INJURY': '痛み及び負傷', // "통증 및 부상",
  'DATA.SORENESS': '痛み', // "통증",
  'DATA.SORENESS_COUNT': '痛みの本数', // "통증 개수",
  'DATA.SORENESS_AX': '最近1週間の痛み程度', // "최근 1주 통증 정도",
  'DATA.SORENESS_AXI': '最近1週間の痛みのレベル', // "최근 1주 통증 수준",
  'DATA.SORENESS_AXD': '最近1週間の痛みのリズム', // "최근 1주 통증 리듬",
  'DATA.SORENESS_CX': '最近3ヶ月間の痛み程度', // "최근 3개월 통증 정도",
  'DATA.SORENESS_ACXR': '最近3ヵ月間の痛みのレベル', // "최근 3개월 통증 수준",
  'DATA.SORENESS_CXD': '最近3ヵ月間の痛みのリズム', // "최근 3개월 통증 리듬",
  'DATA.BODY': '身体', // "신체",
  'DATA.BODY_HEIGHT': '身長', // "키",
  'DATA.BODY_WEIGHT': '体重', // "체중",
  'DATA.BODY_BMI': '体質量指数(BMI)', // "체질량지수(BMI)",
  'DATA.BODY_RECOMMENDED_HYDRATION': '推奨水分摂取量', // "권장 수분 섭취량",
  'DATA.RAW_CONDITION': 'コンディション', // "컨디션",
  'DATA.RAW_WELLNESS': 'ウェルネス', // "웰니스",
  'DATA.CONDITION.BODY': '筋肉', // "근육",
  'DATA.RAW_SLEEP': '睡眠', // "수면",
  'DATA.CONDITION.QUALITY': '品質', // "품질",
  'DATA.CONDITION.DURATION': '時間', // "시간",
  'DATA.WORKOUT': '運動', // "운동",
  'DATA.NORMAL': '正常', // "정상",
  'DATA.NORMAL.WORKOUT': '正常運動', // "정상운동",
  'DATA.REHAB': '復活', // "재활",
  'DATA.REHAB.WORKOUT': '復活運動', // "재활운동",
  'DATA.NO.WORKOUT': '運動しなかった', // "운동 안 함",
  'DATA.INJURIES': '負傷', // "부상",
  'DATA.REST': '休憩', // "휴식",
  'DATA.RAW_WORKLOAD': '運動負荷', // "운동부하",
  'DATA.WORKLOAD.INTENSITY': '強度', // "강도",
  'DATA.WORKLOAD.SATISFACTION': '満足', // "만족",
  'DATA.WORKLOAD.DURATION': '運動時間', // "운동시간",
  'DATA.RAW_INJURY': '負傷', // "부상",
  'DATA.INJURY.SORENESS': '痛み', // "통증",
  'DATA.INJURY.TIMING': '時機', // "시기",
  'DATA.INJURY.QUALITY': '様相', // "양상",
  'DATA.INJURY.MEMO': 'メモ', // "메모",
  'DATA.INJURY.PLAYER.MEMO': '選手メモ', // "선수메모"
  'DATA.RAW_BODY': '身体', // "신체",
  'DATA.BODY.WEIGHT': '重さ', // "무게",
  'DATA.BODY.HEIGHT': '身長', // "키",
  'DATA.STRIDE_DAY': '日別', // "일별",
  'DATA.STRIDE_WEEK': '週別', // "주별",
  'DATA.STRIDE_MONTH': '月別', // "월별",
  'DATA.RAW_BODY.HEIGHT': '身長', // "키",
  'DATA.RAW_BODY.WEIGHT': '体重', // "몸무게",
  'DATA.NO.INPUT': '入力なし', // "입력안함"

  // data-interpreter generated labels and descriptions
  'DATA.READINESS.[0,50).label': '非常に悪い', // "매우 나쁨",
  'DATA.READINESS.[50,60).label': '悪い', // "나쁨",
  'DATA.READINESS.[60,75).label': '普通', // "보통",
  'DATA.READINESS.[75,85).label': '良い', // "좋음",
  'DATA.READINESS.[85,100].label': '上々の良い', // "매우 좋음",
  'DATA.CONDITION.[1,2).label': '非常に低い', // "매우 낮음",
  'DATA.CONDITION.[2,4).label': '低い', // "낮음",
  'DATA.CONDITION.[4,7).label': '普通', // "보통",
  'DATA.CONDITION.[7,9).label': '高い', // "높음",
  'DATA.CONDITION.[9,10].label': '非常に高い', // "매우 높음",
  'DATA.WELLNESS_MUSCLE.[1,3).label': '非常に悪い', // "매우 나쁨",
  'DATA.WELLNESS_MUSCLE.[3,5).label': '悪い', // "나쁨",
  'DATA.WELLNESS_MUSCLE.[5,7).label': '普通', // "보통",
  'DATA.WELLNESS_MUSCLE.[7,9).label': '良い', // "좋음",
  'DATA.WELLNESS_MUSCLE.[9,10].label': '上々の良い', // "매우 좋음",
  'DATA.WELLNESS_MOOD.[1,3).label': '非常に悪い', // "매우 나쁨",
  'DATA.WELLNESS_MOOD.[3,5).label': '悪い', // "나쁨",
  'DATA.WELLNESS_MOOD.[5,7).label': '普通', // "보통",
  'DATA.WELLNESS_MOOD.[7,9).label': '良い', // "좋음",
  'DATA.WELLNESS_MOOD.[9,10].label': '上々の良い', // "매우 좋음",
  'DATA.WELLNESS_FATIGUE.[1,3).label': '非常に悪い', // "매우 나쁨",
  'DATA.WELLNESS_FATIGUE.[3,5).label': '悪い', // "나쁨",
  'DATA.WELLNESS_FATIGUE.[5,7).label': '普通', // "보통",
  'DATA.WELLNESS_FATIGUE.[7,9).label': '良い', // "좋음",
  'DATA.WELLNESS_FATIGUE.[9,10].label': '上々の良い', // "매우 좋음",
  'DATA.WELLNESS_STRESS.[1,3).label': '非常に悪い', // "매우 나쁨",
  'DATA.WELLNESS_STRESS.[3,5).label': '悪い', // "나쁨",
  'DATA.WELLNESS_STRESS.[5,7).label': '普通', // "보통",
  'DATA.WELLNESS_STRESS.[7,9).label': '良い', // "좋음",
  'DATA.WELLNESS_STRESS.[9,10].label': '上々の良い', // "매우 좋음",
  'DATA.SLEEP_DURATION.[0,300).label': '非常に悪い', // "매우 나쁨",
  'DATA.SLEEP_DURATION.[300,360).label': '悪い', // "나쁨",
  'DATA.SLEEP_DURATION.[360,420).label': '普通', // "보통",
  'DATA.SLEEP_DURATION.[420,480).label': '良い', // "좋음",
  'DATA.SLEEP_DURATION.[480,720].label': '上々良い', // "매우좋음",
  'DATA.SLEEP_QUALITY.[1,3).label': '非常に悪い', // "매우 나쁨",
  'DATA.SLEEP_QUALITY.[3,5).label': '悪い', // "나쁨",
  'DATA.SLEEP_QUALITY.[5,7).label': '普通', // "보통",
  'DATA.SLEEP_QUALITY.[7,9).label': '良い', // "좋음",
  'DATA.SLEEP_QUALITY.[9,10].label': '上々良い', // "매우 좋음",
  'DATA.CONDITION_AX.[1,2).label': '非常に低い', // "매우 낮음",
  'DATA.CONDITION_AX.[2,4).label': '低い', // "낮음",
  'DATA.CONDITION_AX.[4,7).label': '普通', // "보통",
  'DATA.CONDITION_AX.[7,9).label': '高い', // "높음",
  'DATA.CONDITION_AX.[9,10].label': '非常に高い', // "매우 높음",
  'DATA.CONDITION_CX.[1,2).label': '非常に低い', // "매우 낮음",
  'DATA.CONDITION_CX.[2,4).label': '低い', // "낮음",
  'DATA.CONDITION_CX.[4,7).label': '普通', // "보통",
  'DATA.CONDITION_CX.[7,9).label': '高い', // "높음",
  'DATA.CONDITION_CX.[9,10].label': '非常に高い', // "매우 높음",
  'DATA.WORKLOAD.[0,201).label': '非常に低い', // "매우 낮음",
  'DATA.WORKLOAD.[201,401).label': '低い', // "낮음",
  'DATA.WORKLOAD.[401,801).label': '普通', // "보통",
  'DATA.WORKLOAD.[801,1001).label': '高い', // "높음",
  'DATA.WORKLOAD.[1001,Infinity].label': '非常に高い', // "매우 높음",
  'DATA.WORKLOAD_INTENSITY.[1,2).label': '非常に軽い', // "매우 가벼움",
  'DATA.WORKLOAD_INTENSITY.[2,4).label': '軽い', // "가벼움",
  'DATA.WORKLOAD_INTENSITY.[4,7).label': '普通', // "보통",
  'DATA.WORKLOAD_INTENSITY.[7,8).label': '少し強い', // "조금 강함",
  'DATA.WORKLOAD_INTENSITY.[8,10).label': '強い', // "강함",
  'DATA.WORKLOAD_INTENSITY.[10,10].label': '最大', // "최대",
  'DATA.WORKLOAD_DURATION.[0,31).label': '非常に低い', // "매우 낮음",
  'DATA.WORKLOAD_DURATION.[31,61).label': '低い', // "낮음",
  'DATA.WORKLOAD_DURATION.[61,121).label': '普通', // "보통",
  'DATA.WORKLOAD_DURATION.[121,241).label': '高い', // "높음",
  'DATA.WORKLOAD_DURATION.[241,720].label': '非常に高い', // "매우 높음",
  'DATA.WORKLOAD_SATISFACTION.[1,3).label': '非常に低い', // "매우 낮음",
  'DATA.WORKLOAD_SATISFACTION.[3,5).label': '低い', // "낮음",
  'DATA.WORKLOAD_SATISFACTION.[5,7).label': '普通', // "보통",
  'DATA.WORKLOAD_SATISFACTION.[7,9).label': '高い', // "높음",
  'DATA.WORKLOAD_SATISFACTION.[9,10].label': '非常に高い', // "매우 높음",
  'DATA.WORKLOAD_AX.[0,201).label': '非常に低い', // "매우 낮음",
  'DATA.WORKLOAD_AX.[201,401).label': '低い', // "낮음",
  'DATA.WORKLOAD_AX.[401,801).label': '普通', // "보통",
  'DATA.WORKLOAD_AX.[801,1001).label': '高い', // "높음",
  'DATA.WORKLOAD_AX.[1001,7200].label': '非常に高い', // "매우 높음",
  'DATA.WORKLOAD_AXD.[2,Infinity].label': '悪い', // "나쁨",
  'DATA.WORKLOAD_AXD.[0,2).label': '良い', // "좋음",
  'DATA.WORKLOAD_AXI.[0,0.8).label': '低い', // "낮음",
  'DATA.WORKLOAD_AXI.[0.8,1.31).label': '普通', // "보통",
  'DATA.WORKLOAD_AXI.[1.31,1.5).label': '高い', // "높음",
  'DATA.WORKLOAD_AXI.[1.5,Infinity].label': '非常に高い', // "매우 높음",
  'DATA.WORKLOAD_CX.[0,201).label': '非常に低い', // "매우 낮음",
  'DATA.WORKLOAD_CX.[201,401).label': '低い', // "낮음",
  'DATA.WORKLOAD_CX.[401,801).label': '普通', // "보통",
  'DATA.WORKLOAD_CX.[801,1001).label': '高い', // "높음",
  'DATA.WORKLOAD_CX.[1001,7200].label': '非常に高い', // "매우 높음",
  'DATA.WORKLOAD_ACXR.[0,0.8).label': '留意', // "유의",
  'DATA.WORKLOAD_ACXR.[0.8,1.31).label': '最適', // "최적",
  'DATA.WORKLOAD_ACXR.[1.31,1.5).label': '注意', // "주의",
  'DATA.WORKLOAD_ACXR.[1.5,Infinity].label': '危険', // "위험",
  'DATA.PARTIAL_INJURY_SORENESS.[0,1).label': '痛みなし', // "통증 없음",
  'DATA.PARTIAL_INJURY_SORENESS.[1,2).label': '少し痛い', // "조금 아픔",
  'DATA.PARTIAL_INJURY_SORENESS.[2,3).label': '痛い', // "아픔",
  'DATA.PARTIAL_INJURY_SORENESS.[3,4).label': 'ひどく痛い', // "많이 아픔",
  'DATA.PARTIAL_INJURY_SORENESS.[4,5).label': 'とても痛い', // "매우 아픔",
  'DATA.PARTIAL_INJURY_SORENESS.[5,5].label': '負傷', // "부상",
  'DATA.BODY_BMI.[0,18.5).label': '低体重', // "저체중",
  'DATA.BODY_BMI.[18.5,23).label': '正常体重', // "정상 체중",
  'DATA.BODY_BMI.[23,25).label': '過体重', // "과체중",
  'DATA.BODY_BMI.[25,30).label': '軽度肥満', // "경도 비만",
  'DATA.BODY_BMI.[30,40).label': '中程度肥満', // "중정도 비만",
  'DATA.BODY_BMI.[40,Infinity].label': '高度肥満', // "고도 비만",

  'DATA.READINESS.[0,50).description':
    '準備度が悪いです。トレーニングや試合参加のためのコンディション管理が不足しています。疲労度、睡眠など体調項目別の点検が必要です。', // "준비도가 나쁩니다. 훈련이나 경기 참여를 위한 컨디션 관리가 부족합니다. 피로도, 수면 등 컨디션 항목별 점검이 필요합니다."
  'DATA.READINESS.[50,60).description':
    '準備度が悪いです。トレーニングや試合参加のためのコンディション管理が不足しています。疲労度、睡眠など体調項目別の点検が必要です。', // "준비도가 나쁩니다. 훈련이나 경기 참여를 위한 컨디션 관리가 부족합니다. 피로도, 수면 등 컨디션 항목별 점검이 필요합니다."
  'DATA.READINESS.[60,75).description': undefined,
  'DATA.READINESS.[75,85).description':
    '準備度がいいです。あなたの状態はトレーニングまたは試合に適しています。自分の準備状況をチェックし続けてください。', // "준비도가 좋습니다. 훈련이나 경기 참여를 위한 컨디션 관리가 적절합니다. 지속적으로 나의 준비 상태를 확인하세요.",
  'DATA.READINESS.[85,100].description':
    '準備度がいいです。あなたの状態はトレーニングまたは試合に適しています。自分の準備状況をチェックし続けてください。', // "준비도가 좋습니다. 훈련이나 경기 참여를 위한 컨디션 관리가 적절합니다. 지속적으로 나의 준비 상태를 확인하세요.",
  'DATA.CONDITION.[1,2).description':
    'コンディションが低いです。休息を推奨し、筋肉/心理的回復を優先順位に考えてください。', // "컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.",
  'DATA.CONDITION.[2,4).description':
    'コンディションが低いです。休息を推奨し、筋肉/心理的回復を優先順位に考えてください。', // "컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.",
  'DATA.CONDITION.[4,7).description': undefined,
  'DATA.CONDITION.[7,9).description':
    'コンディションがいいです。筋肉、心理、回復の適切なバランスの状態です。', // "컨디션이 좋습니다. 근육, 심리, 회복의 적절한 균형의 상태입니다.",
  'DATA.CONDITION.[9,10].description':
    'ンコンディションがいいです。筋肉、心理、回復の適切なバランスの状態です。', // "컨디션이 좋습니다. 근육, 심리, 회복의 적절한 균형의 상태입니다.",
  'DATA.WELLNESS_MUSCLE.[1,3).description':
    '筋肉の状態が悪いです。 現在の状態で運動を行うと怪我の危険があります。 休息または軽いランニングで筋肉の疲労を軽減してください。', // "근육 상태가 나쁩니다. 현재 상태로 운동을 수행하면 부상의 위험이 있습니다. 휴식 또는 가벼운 러닝으로 근육에 피로를 낮춰보세요.",
  'DATA.WELLNESS_MUSCLE.[3,5).description':
    '筋肉の状態が悪いです。 現在の状態で運動を行うと怪我の危険があります。 休息または軽いランニングで筋肉の疲労を軽減してください。', // "근육 상태가 나쁩니다. 현재 상태로 운동을 수행하면 부상의 위험이 있습니다. 휴식 또는 가벼운 러닝으로 근육에 피로를 낮춰보세요.",
  'DATA.WELLNESS_MUSCLE.[5,7).description': undefined,
  'DATA.WELLNESS_MUSCLE.[7,9).description': undefined,
  'DATA.WELLNESS_MUSCLE.[9,10].description': undefined,
  'DATA.WELLNESS_MOOD.[1,3).description':
    '気分が悪いです。 長時間続かないようにしましょうしなければなりません。気分が良くないと筋肉がいつもより緊張して疲れた感じが早く現れます。', // "기분 상태가 나쁩니다. 장시간 지속되지 않도록 해야 합니다. 기분이 좋지 못하면 근육이 평소보다 긴장되어 지친 느낌이 빠르게 나타납니다.",
  'DATA.WELLNESS_MOOD.[3,5).description':
    '気分が悪いです。 長時間続かないようにしましょうしなければなりません。気分が良くないと筋肉がいつもより緊張して疲れた感じが早く現れます。', // "기분 상태가 나쁩니다. 장시간 지속되지 않도록 해야 합니다. 기분이 좋지 못하면 근육이 평소보다 긴장되어 지친 느낌이 빠르게 나타납니다.",
  'DATA.WELLNESS_MOOD.[5,7).description': undefined,
  'DATA.WELLNESS_MOOD.[7,9).description': undefined,
  'DATA.WELLNESS_MOOD.[9,10].description': undefined,
  'DATA.WELLNESS_FATIGUE.[1,3).description':
    '長期的な疲労度は景気力の低下に影響します。 疲労度管理が必要です。', // "장기적인 피로도는 경기력 저하에 영향을 줍니다. 피로도 관리가 필요합니다.",
  'DATA.WELLNESS_FATIGUE.[3,5).description':
    '長期的な疲労度は景気力の低下に影響します。 疲労度管理が必要です。', // "장기적인 피로도는 경기력 저하에 영향을 줍니다. 피로도 관리가 필요합니다.",
  'DATA.WELLNESS_FATIGUE.[5,7).description': undefined,
  'DATA.WELLNESS_FATIGUE.[7,9).description': undefined,
  'DATA.WELLNESS_FATIGUE.[9,10].description': undefined,
  'DATA.WELLNESS_STRESS.[1,3).description':
    'ストレスは筋肉に緊張を与え 怪我の危険性を高めリハビリを妨げます。 原因と対処方法を考えてください。', // "스트레스는 근육에 긴장을 주며 부상의 위험성을 높이고 재활을 방해합니다. 원인과 대처 방법을 생각하세요.",
  'DATA.WELLNESS_STRESS.[3,5).description':
    'ストレスは筋肉に緊張を与え 怪我の危険性を高めリハビリを妨げます。 原因と対処方法を考えてください。', // "스트레스는 근육에 긴장을 주며 부상의 위험성을 높이고 재활을 방해합니다. 원인과 대처 방법을 생각하세요.",
  'DATA.WELLNESS_STRESS.[5,7).description': undefined,
  'DATA.WELLNESS_STRESS.[7,9).description': undefined,
  'DATA.WELLNESS_STRESS.[9,10].description': undefined,
  'DATA.SLEEP_DURATION.[0,300).description':
    '睡眠時間が少ないです。 睡眠時間が少ないと筋肉回復の速度が遅くなり、体力が低下します。 また、負傷のリスクを増加させ、集中力が低下する可能性があります。', // "수면 시간이 적습니다. 수면시간이 적으면 근육 회복 속도가 느려지고 체력이 저하됩니다. 또 부상위험을 증가시키고 집중력이 저하될 수 있습니다.",
  'DATA.SLEEP_DURATION.[300,360).description':
    '睡眠時間が少ないです。 睡眠時間が少ないと筋肉回復の速度が遅くなり、体力が低下します。 また、負傷のリスクを増加させ、集中力が低下する可能性があります。', // "수면 시간이 적습니다. 수면시간이 적으면 근육 회복 속도가 느려지고 체력이 저하됩니다. 또 부상위험을 증가시키고 집중력이 저하될 수 있습니다.",
  'DATA.SLEEP_DURATION.[360,420).description': undefined,
  'DATA.SLEEP_DURATION.[420,480).description': undefined,
  'DATA.SLEEP_DURATION.[480,720].description': undefined,
  'DATA.SLEEP_QUALITY.[1,3).description':
    '睡眠品質管理が必要です。 十分に長い睡眠をとっても、低品質の睡眠は体の回復速度を遅らせます。 また、ストレスや気分に悪影響を与えるため、睡眠時間とともに睡眠品質の管理が必要です。', // "수면 품질 관리가 필요합니다. 충분히 긴 시간의 수면을 하더라도, 낮은 품질의 수면은 몸의 회복 속도를 늦춥니다. 또한, 스트레스와 기분에 악영향을 주기 때문에 수면 시간과 함께 수면 품질의 관리가 필요합니다.",
  'DATA.SLEEP_QUALITY.[3,5).description':
    '睡眠品質管理が必要です。 十分に長い睡眠をとっても、低品質の睡眠は体の回復速度を遅らせます。 また、ストレスや気分に悪影響を与えるため、睡眠時間とともに睡眠品質の管理が必要です。', // "수면 품질 관리가 필요합니다. 충분히 긴 시간의 수면을 하더라도, 낮은 품질의 수면은 몸의 회복 속도를 늦춥니다. 또한, 스트레스와 기분에 악영향을 주기 때문에 수면 시간과 함께 수면 품질의 관리가 필요합니다.",
  'DATA.SLEEP_QUALITY.[5,7).description': undefined,
  'DATA.SLEEP_QUALITY.[7,9).description': undefined,
  'DATA.SLEEP_QUALITY.[9,10].description': undefined,
  'DATA.CONDITION_AX.[1,2).description':
    '最近1週間の体調が低いです. 休息を推奨し、筋肉/心理的回復を優先順位に考えてください.', // "최근 1주 컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.",
  'DATA.CONDITION_AX.[2,4).description':
    '最近1週間の体調が低いです. 休息を推奨し、筋肉/心理的回復を優先順位に考えてください.', // "최근 1주 컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.",
  'DATA.CONDITION_AX.[4,7).description': undefined,
  'DATA.CONDITION_AX.[7,9).description':
    '最近1週間調子がいいです. 良いコンディションは競技力向上に役立ちます. 現在のコンディショニングルーチンを維持してください.', // "최근 1주 컨디션이 좋습니다. 좋은 컨디션은 경기력 향상에 도움이 됩니다. 현재의 컨디셔닝 루틴을 유지하세요",
  'DATA.CONDITION_AX.[9,10].description':
    '最近1週間調子がいいです. 良いコンディションは競技力向上に役立ちます. 現在のコンディショニングルーチンを維持してください.', // "최근 1주 컨디션이 좋습니다. 좋은 컨디션은 경기력 향상에 도움이 됩니다. 현재의 컨디셔닝 루틴을 유지하세요",
  'DATA.CONDITION_CX.[1,2).description':
    '最近4週間のコンディションが低いです. 休息を推奨し、筋肉/心理的回復を優先順位に考えてください.', // "최근 4주 컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.",
  'DATA.CONDITION_CX.[2,4).description':
    '最近4週間のコンディションが低いです. 休息を推奨し、筋肉/心理的回復を優先順位に考えてください.', // "최근 4주 컨디션이 낮습니다. 휴식을 권장하며 근육/심리적 회복을 우선순위로 생각하세요.",
  'DATA.CONDITION_CX.[4,7).description': undefined,
  'DATA.CONDITION_CX.[7,9).description':
    '最近4週間コンディションがいいです。 良いコンディションは競技力向上に役立ちます. 現在のコンディショニングルーチンを維持してください.', // "최근 4주 컨디션이 좋습니다. 좋은 컨디션은 경기력 향상에 도움이 됩니다. 현재의 컨디셔닝 루틴을 유지하세요",
  'DATA.CONDITION_CX.[9,10].description':
    '最近4週間コンディションがいいです。 良いコンディションは競技力向上に役立ちます. 現在のコンディショニングルーチンを維持してください.', // "최근 4주 컨디션이 좋습니다. 좋은 컨디션은 경기력 향상에 도움이 됩니다. 현재의 컨디셔닝 루틴을 유지하세요",
  'DATA.WORKLOAD.[0,201).description': undefined,
  'DATA.WORKLOAD.[201,401).description': undefined,
  'DATA.WORKLOAD.[401,801).description': undefined,
  'DATA.WORKLOAD.[801,1001).description':
    '運動負荷が高い分、適切な回復が必要です。 運動強度や運動量を調節する計画をお勧めします。', // "운동부하가 높은 만큼 적절한 회복이 필요합니다. 운동 강도나 운동량을 조절하는 계획을 추천합니다.",
  'DATA.WORKLOAD.[1001,Infinity].description':
    '運動負荷が高い分、適切な回復が必要です。 運動強度や運動量を調節する計画をお勧めします。', // "운동부하가 높은 만큼 적절한 회복이 필요합니다. 운동 강도나 운동량을 조절하는 계획을 추천합니다.",
  'DATA.WORKLOAD_INTENSITY.[1,2).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[2,4).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[4,7).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[7,8).description':
    '連続的な高強度の運動は、怪我と体調に影響を与えます。', // "연속적인 높은 강도의 운동은 부상과 컨디션에 영향을 줍니다. ",
  'DATA.WORKLOAD_INTENSITY.[8,10).description':
    '連続的な高強度の運動は、怪我と体調に影響を与えます。', // "연속적인 높은 강도의 운동은 부상과 컨디션에 영향을 줍니다. ",
  'DATA.WORKLOAD_INTENSITY.[10,10].description':
    '連続的な高強度の運動は、怪我と体調に影響を与えます。', // "연속적인 높은 강도의 운동은 부상과 컨디션에 영향을 줍니다. ",
  'DATA.WORKLOAD_DURATION.[0,31).description': undefined,
  'DATA.WORKLOAD_DURATION.[31,61).description': undefined,
  'DATA.WORKLOAD_DURATION.[61,121).description': undefined,
  'DATA.WORKLOAD_DURATION.[121,241).description':
    '大量の運動より目的に合わせて運動の質を高めることが重要です。', // "많은 양의 운동보다 목적에 맞게 운동의 질을 높이는 것이 중요합니다. ",
  'DATA.WORKLOAD_DURATION.[241,720].description':
    '大量の運動より目的に合わせて運動の質を高めることが重要です。', // "많은 양의 운동보다 목적에 맞게 운동의 질을 높이는 것이 중요합니다. ",
  'DATA.WORKLOAD_SATISFACTION.[1,3).description':
    '運動に対する満足度が低いです。 達成感のためのモチベーションが必要です。 大小の目標設定をお勧めします。', // "운동에 대한 만족도가 낮습니다. 성취감을 위한 동기부여가 필요합니다. 크고 작은 목표설정을 추천합니다.",
  'DATA.WORKLOAD_SATISFACTION.[3,5).description':
    '運動に対する満足度が低いです。 達成感のためのモチベーションが必要です。 大小の目標設定をお勧めします。', // "운동에 대한 만족도가 낮습니다. 성취감을 위한 동기부여가 필요합니다. 크고 작은 목표설정을 추천합니다.",
  'DATA.WORKLOAD_SATISFACTION.[5,7).description': undefined,
  'DATA.WORKLOAD_SATISFACTION.[7,9).description': undefined,
  'DATA.WORKLOAD_SATISFACTION.[9,10].description': undefined,
  'DATA.WORKLOAD_AX.[0,201).description': undefined,
  'DATA.WORKLOAD_AX.[201,401).description': undefined,
  'DATA.WORKLOAD_AX.[401,801).description': undefined,
  'DATA.WORKLOAD_AX.[801,1001).description':
    '最近1週間の運動負荷の平均が高いです. 本人の体力に比べて急激な運動量の増加は負傷をもたらします. 筋肉成長と競技力向上のために適切な休息が必要です.', // "최근 1주 운동부하 평균이 높습니다. 본인의 체력 대비 급격한 운동량 증가는 부상을 초래합니다. 근육 성장과 경기력 향상을 위해 적절한 휴식이 필요합니다.",
  'DATA.WORKLOAD_AX.[1001,7200].description':
    '最近1週間の運動負荷の平均が高いです. 本人の体力に比べて急激な運動量の増加は負傷をもたらします. 筋肉成長と競技力向上のために適切な休息が必要です.', // "최근 1주 운동부하 평균이 높습니다. 본인의 체력 대비 급격한 운동량 증가는 부상을 초래합니다. 근육 성장과 경기력 향상을 위해 적절한 휴식이 필요합니다.",
  'DATA.WORKLOAD_AXD.[2,Infinity].description':
    '最近1週間の運動負荷リズムが良くありません. 運動強度と運動時間に変化を持たなければなりません. 単調なトレーニングは選手に否定的な影響を与えます.', // "최근 1주 운동부하 리듬이 좋지 못합니다. 운동 강도와 운동 시간에 변화를 가져야 합니다. 단조로운 훈련은 선수에게 부정적인 영향을 줍니다.",
  'DATA.WORKLOAD_AXD.[0,2).description': undefined,
  'DATA.WORKLOAD_AXI.[0,0.8).description': undefined,
  'DATA.WORKLOAD_AXI.[0.8,1.31).description': undefined,
  'DATA.WORKLOAD_AXI.[1.31,1.5).description': undefined,
  'DATA.WORKLOAD_AXI.[1.5,Infinity].description':
    '最近1週間平均より運動量が急激に増加しました. 怪我を防ぐために適切なストレッチ、十分な栄養と水分、回復のための十分な睡眠などをお勧めします.', // "최근 1주 평균보다 운동량이 급격히 증가했습니다. 부상을 방지하기 위해 적절한 스트레칭, 충분한 영양과 수분, 회복을 위한 충분한 수면 등을 권장합니다",
  'DATA.WORKLOAD_CX.[0,201).description': undefined,
  'DATA.WORKLOAD_CX.[201,401).description': undefined,
  'DATA.WORKLOAD_CX.[401,801).description': undefined,
  'DATA.WORKLOAD_CX.[801,1001).description':
    '最近4週間の運動負荷の平均が高いです. 4週間の運動負荷平均分だけ体力が向上し、高い運動負荷への適応力が向上します. 漸進的な運動量の増加をお勧めします.', // "최근 4주 운동부하 평균이 높습니다. 4주 운동부하 평균만큼 체력이 향상되며 높은 운동부하의 대한 적응력이 향상됩니다. 점진적인 운동량 증가를 권장합니다.",
  'DATA.WORKLOAD_CX.[1001,7200].description':
    '最近4週間の運動負荷の平均が高いです. 4週間の運動負荷平均分だけ体力が向上し、高い運動負荷への適応力が向上します. 漸進的な運動量の増加をお勧めします.', // "최근 4주 운동부하 평균이 높습니다. 4주 운동부하 평균만큼 체력이 향상되며 높은 운동부하의 대한 적응력이 향상됩니다. 점진적인 운동량 증가를 권장합니다.",
  'DATA.WORKLOAD_ACXR.[0,0.8).description': undefined,
  'DATA.WORKLOAD_ACXR.[0.8,1.31).description': undefined,
  'DATA.WORKLOAD_ACXR.[1.31,1.5).description':
    '負傷注意区間です。 疲労度を減らし、運動量を調節する必要があります。', // "부상 주의 구간입니다. 피로도를 줄이고 운동량을 조절할 필요가 있습니다.",
  'DATA.WORKLOAD_ACXR.[1.5,Infinity].description':
    '負傷危険区間です。本人の体力に比べて疲労度が高いです。 運動よりは休息を推奨し、適切な休息と回復を推奨します。', // "부상 위험 구간입니다. 본인의 체력 대비 피로도가 높습니다. 운동보단 휴식을 권장하며 적절한 휴식과 회복을 권장합니다. ",
  'DATA.PARTIAL_INJURY_SORENESS.[0,1).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[1,2).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[2,3).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[3,4).description':
    '痛み3段階が発生しました。 短期的には痛みを和らげるための努力が必要です。 休息、マッサージ、冷/温湿布、栄養を含む回復のための行動が必要です。', // "통증 3단계가 발생했습니다. 단기적으로 통증 완화를 위한 노력이 필요합니다. 휴식, 마사지, 냉/온 찜질, 영양을 포함한 회복을 위한 행동이 필요합니다.",
  'DATA.PARTIAL_INJURY_SORENESS.[4,5).description':
    '痛み4段階が発生しました。 専門家の診断により、正確な原因と処方が必要です。', // "통증 4단계가 발생했습니다. 전문가의 진단을 통해 정확한 원인과 처방이 필요합니다.",
  'DATA.PARTIAL_INJURY_SORENESS.[5,5].description':
    '怪我です。 すべての運動を中断して身体的/心理的回復に集中してください。 計画的な回復戦略が必要です。', // "부상 입니다. 모든 운동을 중단하고 신체적/심리적 회복에 집중하세요. 계획적인 회복 전략이 필요합니다.",
  'DATA.BODY_BMI.[0,18.5).description': undefined,
  'DATA.BODY_BMI.[18.5,23).description': undefined,
  'DATA.BODY_BMI.[23,25).description': undefined,
  'DATA.BODY_BMI.[25,30).description': undefined,
  'DATA.BODY_BMI.[30,40).description': undefined,
  'DATA.BODY_BMI.[40,Infinity].description': undefined,
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).label': '非常に軽い強度', // "매우 가벼운 강도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).label': '軽い強度', // "가벼운 강도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).label': '普通強度', // "보통 강도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).label': '少し強い強度', // "조금 강한 강도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).label': '強い強度', // "강한 강도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].label': '最大強度', // "최대 강도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).description': 'ほとんど努力のいらない程度', // "거의 노력이 필요 없는 정도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).description':
    '数時間耐えられる程度。 呼吸しやすく会話が容易な程度', // "몇 시간 동안 버틸 수 있는 정도. 숨쉬기가 쉽고 대화를 수월하게 가능한 정도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).description':
    '息を切らすくらい。 短い会話が可能。 多少楽な状態', // "숨을 가쁘게 쉴 정도. 짧은 대화가 가능함. 다소 편한 상태",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).description':
    '少し不便な程度。息切れ状態。 文章が話せる程度', // "약간 불편한 정도. 숨이 찬 상태. 문장을 말할 수 있는 정도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).description':
    '運動強度を維持することが非常に困難。 ほとんど呼吸困難。 単語をいくつか話せる程度', // "운동강도를 유지하기 매우 어려움. 거의 숨쉬기 어려움. 오직 단어 몇 개만 말할 수 있는 정도",
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].description':
    '持続不可能なほど。 完全に呼吸が難しい。 対話が不可能な程度', // "지속하기 불가능할 정도. 완전 숨쉬기 어려움. 대화가 불가능한 정도",
}

export default messages
