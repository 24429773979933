import { useRouter } from 'next/router'

import { Box, Button, Flex, Image, Text } from '@plco-pro/components/atoms/index'
import { useI18n } from '@plco-pro/hooks/i18n'

const IMAGE_URL =
  'https://kr.freepik.com/free-psd/gold-medal-icon-isolated-3d-render-illustration_30112525.htm#query=victory%203d&position=8&from_view=search&track=ais'

export const HyperOnboarding = () => {
  const { formatMessage } = useI18n()
  const route = useRouter()

  const moveToPricingPage = () => {
    route.push('/plan/pay/pricing')
  }

  const openImageUrl = () => {
    window.open(IMAGE_URL)
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 6,
      }}
    >
      <Flex sx={{ flexDirection: 'column', gap: 4, alignItems: 'center' }}>
        <Box>
          <Text
            sx={{ whiteSpace: 'pre-line', textAlign: 'center', fontWeight: 'bold' }}
            variant={['h3', 'h1']}
          >
            {formatMessage({ id: 'team-chart.onboarding.hyper.title' })}
          </Text>
        </Box>
        <Box>
          <Image src={'/images/medal.svg'} />
        </Box>
        <Box>
          <Text
            sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}
            variant={'s2'}
            appearance={'hint'}
          >
            {formatMessage({ id: 'team-chart.onboarding.hyper.description' })}
          </Text>
        </Box>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'flex-end',
          gap: 3,
          mb: [3, 0],
        }}
      >
        <Button
          sx={{ minWidth: '295px', px: 2, py: '12px' }}
          size={'lg'}
          onClick={moveToPricingPage}
        >
          {formatMessage({ id: 'team-chart.onboarding.hyper.payment.button' })}
        </Button>
        <Box sx={{ cursor: 'pointer' }} onClick={openImageUrl}>
          <Text variant={'p2'} appearance={'disabled'}>
            {'Image by xvector on Freepik'}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}
