import React from 'react'

import { Chip, ChipProps } from '@plco-pro/components/atoms/chip'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { useI18n } from '@plco-pro/hooks/i18n'

export type ChipMonitoringKgProps = ChipProps & {
  value: any
}

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-start',
    }}
    {...props}
  />
)

export const ChipMonitoringKg: React.FunctionComponent<ChipMonitoringKgProps> = ({
  value,
  ...rest
}) => {
  const { formatMessage: f } = useI18n()

  if (rest.backgroundColor) {
    return (
      <Chip size={'xs'} {...rest}>
        <Text
          variant={'p1'}
          appearance={'alternative'}
          sx={{ fontWeight: 'medium', marginTop: '-1px' }}
        >
          {value}
          {f({ id: 'KG' })}
        </Text>
      </Chip>
    )
  } else {
    return (
      <ContainerFlex>
        <Text variant={'p1'}>
          {value}
          {f({ id: 'KG' })}
        </Text>
      </ContainerFlex>
    )
  }
}
