import { types } from 'mobx-state-tree'

import { TeamMapListEntriesFragment } from '@plco-pro/graphqls/react.generated'

export const TeamsStore = types
  .model('Teams', {
    teamsDataMap: types.optional(types.frozen<TeamMapListEntriesFragment[]>(), []),
  })
  .actions((self) => {
    return {
      setTeamsDataMap(value: TeamMapListEntriesFragment[]) {
        self.teamsDataMap = value
      },
      addTeamsDataMap(value: TeamMapListEntriesFragment[]) {
        self.teamsDataMap = self.teamsDataMap.concat(value)
      },
      deleteTeamDataMap(value: string) {
        self.teamsDataMap = self.teamsDataMap.filter((data) => data.team.id !== value)
      },
    }
  })
