import React, { Fragment, useCallback, useMemo } from 'react'

import styled from '@emotion/styled'
import { useDropdownMenu } from 'react-overlays/DropdownMenu'
import { ThemeUIStyleObject } from 'theme-ui'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Divider } from '@plco-pro/components/atoms/divider'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Spinner } from '@plco-pro/components/atoms/spinner'
import {
  DropdownMenuAvatarItem,
  DropdownMenuAvatarItemProps,
} from '@plco-pro/components/molecules/dropdown-menu-avatar-item'
import {
  DropdownMenuCheckboxItem,
  DropdownMenuCheckboxItemProps,
} from '@plco-pro/components/molecules/dropdown-menu-checkbox-item'
import {
  DropdownMenuDatasetItem,
  DropdownMenuDatasetItemProps,
} from '@plco-pro/components/molecules/dropdown-menu-dataset-item'
import {
  DropdownMenuIconItem,
  DropdownMenuIconItemProps,
} from '@plco-pro/components/molecules/dropdown-menu-icon-item'
import {
  DropdownMenuTextItem,
  DropdownMenuTextItemProps,
} from '@plco-pro/components/molecules/dropdown-menu-text-item'
import {
  DropdownMenuTitleItem,
  DropdownMenuTitleItemProps,
} from '@plco-pro/components/molecules/dropdown-menu-title-item'

export type DropdownMenuProps = {
  items: DropdownMenuItem[]
  width?: string
  maxHeight?: string
  containerStyle?: ThemeUIStyleObject
  headerStyle?: ThemeUIStyleObject
  contentStyle?: ThemeUIStyleObject
  isShowChip?: boolean
  zIndex?: number
}

export type DropdownMenuItem =
  | DropdownMenuTitleItem
  | DropdownMenuIconItem
  | DropdownMenuCheckboxItem
  | DropdownMenuTextItem
  | DropdownMenuAvatarItem
  | DropdownMenuDatasetItem
  | DropdownMenuLoadingItem

export type DropdownMenuTitleItem = DropdownMenuTitleItemProps & {
  type?: 'title'
  group?: number
  ref?: BoxProps['ref']
}

export type DropdownMenuIconItem = DropdownMenuIconItemProps & {
  type?: 'icon'
  group?: number
  ref?: BoxProps['ref']
}

export type DropdownMenuTextItem = DropdownMenuTextItemProps & {
  type: 'text'
  group?: number
  ref?: BoxProps['ref']
}

export type DropdownMenuAvatarItem = DropdownMenuAvatarItemProps & {
  type: 'avatar'
  group?: number
  ref?: BoxProps['ref']
}

export type DropdownMenuCheckboxItem = DropdownMenuCheckboxItemProps & {
  type: 'checkbox'
  group?: number
  ref?: BoxProps['ref']
}

export type DropdownMenuDatasetItem = DropdownMenuDatasetItemProps & {
  type?: 'dataset'
  group?: number
  ref?: BoxProps['ref']
}

export type DropdownMenuLoadingItem = {
  type?: 'loading'
  group?: number
  ref?: BoxProps['ref']
}

export type DropdownMenuContainerDivProps = {
  show: boolean
  width?: string
  ref?: BoxProps['ref']
}

const ContainerDiv = styled('div')`
  display: ${(props: DropdownMenuContainerDivProps) => (props.show ? 'flex' : 'none')};
  z-index: 2;
  width: ${(props: DropdownMenuContainerDivProps) => props.width};
`

const ContainerFlex = (props: FlexProps) => (
  <Flex
    {...props}
    sx={{
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'white-500',
      border: 'card',
      boxShadow: 'card',
      borderRadius: 'default',
      ...props.sx,
    }}
  />
)

export const DropdownMenu = ({
  width,
  items,
  maxHeight,
  containerStyle,
  headerStyle,
  contentStyle,
  isShowChip,
  zIndex,
}: DropdownMenuProps) => {
  const { show, props } = useDropdownMenu({
    usePopper: true,
    flip: true,
    offset: [0, 8],
  })

  const isMultiDropdown = useMemo(() => items.every((item) => item.group), [items])

  const getTypeCategory = useCallback((type: string | undefined) => {
    if (type === 'title') {
      return 'header'
    } else if (
      type === 'icon' ||
      type === 'text' ||
      type === 'avatar' ||
      type === 'checkbox' ||
      type === 'dataset'
    ) {
      return 'content'
    } else {
      return 'content'
    }
  }, [])

  const headerItems = useMemo(() => {
    return (
      <Box sx={{ ...headerStyle }}>
        {items
          .filter((item) => getTypeCategory(item.type) === 'header')
          .map(({ ref, ...item }, index) => {
            const isNotLastItem = index !== items.length - 1
            const isNextItemDifferentGroup = item.group !== items[index + 1]?.group
            const isNextItemDifferentTypeCategory =
              getTypeCategory(item.type) !== getTypeCategory(items[index + 1]?.type)

            return (
              <Fragment key={`menu-header-item${index}`}>
                {item.type === 'title' && (
                  <Box ref={ref}>
                    <DropdownMenuTitleItem {...item} />
                  </Box>
                )}
                {isNotLastItem && (isNextItemDifferentGroup || isNextItemDifferentTypeCategory) && (
                  <Divider />
                )}
              </Fragment>
            )
          })}
      </Box>
    )
  }, [getTypeCategory, headerStyle, items])

  const contentItems = useMemo(() => {
    return (
      <Box sx={{ ...contentStyle }}>
        {items
          .filter((item) => getTypeCategory(item.type) === 'content')
          .map(({ ref, ...item }, index) => {
            const isNotLastItem = index !== items.length - 1
            const isNextItemDifferentGroup = item.group !== items[index + 1]?.group
            const isNextItemDifferentTypeCategory =
              getTypeCategory(item.type) !== getTypeCategory(items[index + 1]?.type)

            return (
              <Fragment key={`menu-content-item${index}`}>
                {(item.type === 'icon' || !item.type) && (
                  <Box ref={ref}>
                    <DropdownMenuIconItem
                      data-step={index === 0 ? 'step-4' : ''}
                      isMultiDropdown={isMultiDropdown}
                      isLast={index === items.length - 1}
                      {...item}
                    />
                  </Box>
                )}
                {item.type === 'text' && (
                  <Box ref={ref}>
                    <DropdownMenuTextItem {...item} />
                  </Box>
                )}
                {item.type === 'avatar' && (
                  <Box ref={ref}>
                    <DropdownMenuAvatarItem {...item} />
                  </Box>
                )}
                {item.type === 'checkbox' && (
                  <Box ref={ref}>
                    <DropdownMenuCheckboxItem {...item} />
                  </Box>
                )}
                {item.type === 'dataset' && (
                  <Box ref={ref}>
                    <DropdownMenuDatasetItem isShowChip={!!item.label} {...item} />
                  </Box>
                )}
                {item.type === 'loading' && (
                  <Flex
                    sx={{
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spinner size={'md'} />
                  </Flex>
                )}
                {isNotLastItem && (isNextItemDifferentGroup || isNextItemDifferentTypeCategory) && (
                  <Divider />
                )}
              </Fragment>
            )
          })}
      </Box>
    )
  }, [contentStyle, getTypeCategory, isMultiDropdown, items])

  return (
    <ContainerDiv role={'menu'} show={show} sx={{ width: width, zIndex }} {...props}>
      <ContainerFlex
        sx={{
          width: width,
          maxHeight: maxHeight,
          py: isMultiDropdown ? 1 : 0,
          overflowX: maxHeight ? 'scroll' : undefined,
          ...containerStyle,
        }}
      >
        {headerItems}
        {contentItems}
      </ContainerFlex>
    </ContainerDiv>
  )
}
