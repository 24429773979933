import React, { ReactNode, forwardRef } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { observer, useStore } from '@plco-pro/stores'

const CardContainerBox = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <Box
    ref={ref}
    sx={{
      width: ['col12.12', 'col12.12', 'col12.6', 'col12.4'],
      height: '480px',
      px: [1, 2, 2, 2],
      mt: [2, 4, 4, 4],
    }}
    {...props}
  />
))

type Props = {
  info: ReactNode
  infoRef?: (element: HTMLDivElement | null) => void
  readiness: ReactNode
  soreness: ReactNode
  injury: ReactNode
  feedback: ReactNode
}

export const LayoutPlayerSummary = observer(
  ({ info, infoRef, readiness, soreness, injury, feedback }: Props) => {
    const {
      players: { isContentXlAndUp },
    } = useStore()

    return (
      <Flex
        sx={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          mx: [-1, -2, null, null],
          mt: [-2, -4, null, null],
        }}
      >
        <CardContainerBox
          ref={infoRef}
          sx={{
            width: ['col12.12', 'col12.12', 'col12.4', 'col12.4'],
            minWidth: isContentXlAndUp ? undefined : [null, null, null, '33.3%'],
          }}
        >
          {info}
        </CardContainerBox>

        <CardContainerBox
          sx={{
            width: ['col12.12', 'col12.12', 'col12.4', 'col12.4'],
            minWidth: isContentXlAndUp ? undefined : [null, null, null, '33.3%'],
          }}
        >
          {readiness}
        </CardContainerBox>

        <CardContainerBox
          sx={{
            width: ['col12.12', 'col12.12', 'col12.4', 'col12.4'],
            minWidth: isContentXlAndUp ? undefined : [null, null, null, '33.3%'],
          }}
        >
          {feedback}
        </CardContainerBox>

        <CardContainerBox
          sx={{
            width: ['col12.12', 'col12.12', 'col12.6', 'col12.6'],
            minWidth: isContentXlAndUp ? undefined : [null, null, null, '50%'],
          }}
        >
          {soreness}
        </CardContainerBox>

        <CardContainerBox
          sx={{
            height: ['100%', '100%', '480px'],
            width: ['col12.12', 'col12.12', 'col12.6', 'col12.6'],
            minWidth: isContentXlAndUp ? undefined : [null, null, null, '50%'],
          }}
        >
          {injury}
        </CardContainerBox>
      </Flex>
    )
  },
)
