export const validationError = {
  en: {
    VALIDATION_ERROR: 'Validation Error',
    'VALIDATION_ERROR.required': '{field} is required',
    'VALIDATION_ERROR.string': '{field} must be string',
    'VALIDATION_ERROR.stringEmpty': '{field} field must not be empty.',
    'VALIDATION_ERROR.stringMin':
      '{field} field length must be greater than or equal to {expected} characters long',
    'VALIDATION_ERROR.stringMax':
      'The {field} field length must be less than or equal to {expected} characters long',
    'VALIDATION_ERROR.stringLength': '{field} must be {expected} characters long',
    'VALIDATION_ERROR.stringPattern': '{field} field fails to match the required pattern',
    'VALIDATION_ERROR.stringContains': '{field} field must contain the {expected} text',
    'VALIDATION_ERROR.stringEnum': '{field} field does not match any of the allowed values',
    'VALIDATION_ERROR.stringNumeric': '{field} field must be a numeric string.',
    'VALIDATION_ERROR.stringAlpha': '{field} must be an alphabetic string',
    'VALIDATION_ERROR.stringAlphanum': '{field} field must be an alphanumeric string.',
    'VALIDATION_ERROR.stringAlphadash': '{field} field must be an alphadash string',
    'VALIDATION_ERROR.stringHex': '{field} field must be a hex string',
    'VALIDATION_ERROR.stringSingleLine': '{field} field must be a single line string',
    'VALIDATION_ERROR.number': '{field} field must be a number.',
    'VALIDATION_ERROR.numberMin': '{field} field must be greater than or equal to {expected}',
    'VALIDATION_ERROR.numberMax': '{field} field must be less than or equal to {expected}',
    'VALIDATION_ERROR.numberEqual': '{field} field must be equal to {expected}',
    'VALIDATION_ERROR.numberNotEqual': '{field} field cant be equal to {expected}',
    'VALIDATION_ERROR.numberInteger': '{field} field must be an integer',
    'VALIDATION_ERROR.numberPositive': '{field} field must be a positive number',
    'VALIDATION_ERROR.numberNegative': '{field} field must be a negative number',
    'VALIDATION_ERROR.array': '{field} field must be an array.',
    'VALIDATION_ERROR.arrayEmpty': '{field} field must not be an empty array.',
    'VALIDATION_ERROR.arrayMin': '{field} field must contain at least {expected} items',
    'VALIDATION_ERROR.arrayMax':
      '{field} field must contain less than or equal to {expected} items',
    'VALIDATION_ERROR.arrayLength': '{field} field must contain {expected} items',
    'VALIDATION_ERROR.arrayContains': '{field} field must contain the {expected} item',
    'VALIDATION_ERROR.arrayUnique':
      '{actual} value in {field} field does not unique the {expected} values',
    'VALIDATION_ERROR.arrayEnum':
      '{actual} value in {field} field does not match any of the {expected} values',
    'VALIDATION_ERROR.tuple': '{field} field must be an array',
    'VALIDATION_ERROR.tupleEmpty': '{field} field must not be an empty array',
    'VALIDATION_ERROR.tupleLength': '{field} field must contain {expected} items',
    'VALIDATION_ERROR.boolean': '{field} field must be a boolean',
    'VALIDATION_ERROR.function': '{field} field must be a function',
    'VALIDATION_ERROR.date': '{field} field must be a Date',
    'VALIDATION_ERROR.dateMin': '{field} field must be greater than or equal to {expected}',
    'VALIDATION_ERROR.dateMax': '{field} field must be less than or equal to {expected}',
    'VALIDATION_ERROR.forbidden': '{field} field is forbidden',
    'VALIDATION_ERROR.email': '{field} field must be a valid e-mail',
    'VALIDATION_ERROR.url': '{field} field must be a valid URL',
    'VALIDATION_ERROR.enumValue': '{field} field value does not match any of the allowed values',
    'VALIDATION_ERROR.equalValue': '{field} field value must be equal to {expected}',
    'VALIDATION_ERROR.equalField': '{field} field value must be equal to {expected} field value',
    'VALIDATION_ERROR.object': '{field} must be an Object',
    'VALIDATION_ERROR.objectStrict': 'object {field} contains forbidden keys: {actual}',
    'VALIDATION_ERROR.objectMinProps': 'object {field} must contain at least {expected} properties',
    'VALIDATION_ERROR.objectMaxProps': 'object {field} must contain {expected} properties at most',
    'VALIDATION_ERROR.uuid': '{field} field must be a valid UUID',
    'VALIDATION_ERROR.uuidVersion': '{field} field must be a valid UUID version provided',
    'VALIDATION_ERROR.mac': '{field} field must be a valid MAC address',
    'VALIDATION_ERROR.luhn': '{field} field must be a valid checksum luhn',
    'VALIDATION_ERROR.duplicate': '{field} is in use',

    'VALIDATION_ERROR.sports.createTeam.username':
      'usernamse should be combination of alphabet, number and dash',
    'VALIDATION_ERROR.sports.createTeam.username.duplicate': 'This URL is already in use.',
    'VALIDATION_ERROR.sports.createTeam.name.stringPattern':
      'special characters or emojis are not allowed',
    'VALIDATION_ERROR.sports.joinTeam.code':
      'usernamse should be combination of alphabet, number and dash',
    'VALIDATION_ERROR.sports.updateUser.name.pattern': 'Use only letters, numbers and hyphens(-).',
    'VALIDATION_ERROR.sports.updateUser.birthdate.common':
      'Brithdate is not valid. Please try again.',
    'VALIDATION_ERROR.sports.updateUser.phone-number.common':
      'The phone number is not vaild. Please try again.',
    'VALIDATION_ERROR.sports.createEvent.title.required':
      'Please enter a scheduler title (up to 20 characters).',
    'VALIDATION_ERROR.sports.createEvent.startAt.required':
      'Please enter the correct date and time.',
    'VALIDATION_ERROR.sports.createEvent.endAt.required': 'Please enter the correct date and time.',
    'VALIDATION_ERROR.sports.createEvent.expectedIntensity.required':
      'Please select expected intensity.',
    'VALIDATION_ERROR.sports.createEvent.participants.required': 'Please select participants.',
    'VALIDATION_ERROR.sports.updateEvent.title.required':
      'Please enter a event title (up to 20 characters).',
    'VALIDATION_ERROR.sports.updateEvent.startAt.required':
      'Please enter the correct date and time.',
    'VALIDATION_ERROR.sports.updateEvent.endAt.required': 'Please enter the correct date and time.',
    'VALIDATION_ERROR.sports.updateEvent.expectedIntensity.required':
      'Please select expected intensity.',
    'VALIDATION_ERROR.sports.updateEvent.participants.required': 'Please select participants.',
    'VALIDATION_ERROR.sports.updateTeam.name.pattern':
      'Please use only letters, numbers, and hyphens (-).',
    'VALIDATION_ERROR.sports.TeamGroup.name': 'Please enter within 1 to 10 characters.',
    'VALIDATION_ERROR.sports.TeamGroup.userIds': 'Please select at least one member.',

    'VALIDATION_ERROR.plan.buyer.name': 'Please enter 1 ~ 50 characters.',
    'VALIDATION_ERROR.plan.buyer.email':
      'You have entered an invalid email address. Please try again.',
    'VALIDATION_ERROR.plan.buyer.phone': 'The phone number is not valid. Please try again.',
    'VALIDATION_ERROR.username.duplicate': "The 'username' is already used by other user or team.",
    'VALIDATION_ERROR.username.stringMin':
      "The 'username' field length must be greater than or equal to 4 characters long.",
    'VALIDATION_ERROR.username.stringAlphadash':
      "The 'username' field must be an alphadash string.",

    'VALIDATION_ERROR.sports.match.opponent.required': 'Please enter the opponent.',
    'VALIDATION_ERROR.sports.match.competition.required':
      'Please enter the competition information.',
    'VALIDATION_ERROR.sports.match.location.required': 'Please enter the match location.',
    'VALIDATION_ERROR.sports.match.date.required': 'Please enter the correct date and time.',
    'VALIDATION_ERROR.sports.match.notice-target.required': 'Please select the notice target.',

    // FIELD VALIDATION ERROR MESSAGE
    'ERROR.FIELD.name': 'Name',
    'ERROR.FIELD.team.name': 'Team name',
    'ERROR.FIELD.team.url': 'URL',
    'ERROR.FIELD.sportsCategory': 'Sports Category',
    'ERROR.FIELD.region': 'Region',
    'ERROR.FIELD.username': 'User Name',
    'ERROR.FIELD.sports.createTeam.username': 'Team Url',
    'ERROR.FIELD.code': 'Team Code',
    'ERROR.FIELD.role': 'Role',
    'ERROR.FIELD.position': 'Position',
    'ERROR.FIELD.sports_category': 'Sports',
    'ERROR.FIELD.nationality': 'Nationality',
    'ERROR.FIELD.gender': 'Gender',
    'ERROR.FIELD.birthdate': 'Birthdate',
    'ERROR.FIELD.phoneNumber': 'Phone Number',

    // EMPTY ERROR MESSAGE
    'ERROR.EMPTY.select': 'Please select a {field}',
    'ERROR.EMPTY.input': 'Please enter your {field}',
  },
  ko: {
    VALIDATION_ERROR: '검증 에러',
    'VALIDATION_ERROR.required': '{field} (은)는 필수입니다',
    'VALIDATION_ERROR.string': '{field} (은)는 텍스트여야 합니다',
    'VALIDATION_ERROR.stringEmpty': '{field} (은)는 빈 값일 수 없습니다.',
    'VALIDATION_ERROR.stringMin': '{field} (은)는 최소 {expected} 자 이상이어야 합니다',
    'VALIDATION_ERROR.stringMax': '{field} (은)는 최대 {expected} 자 이하이어야 합니다',
    'VALIDATION_ERROR.stringLength': '{field} (은)는 {expected} 자 이어야합니다',
    'VALIDATION_ERROR.stringPattern': '{field} (은)는 형식과 맞지 않습니다',
    'VALIDATION_ERROR.stringContains': '{field} (은)는 {expected} 글자를 포함하여야 합니다',
    'VALIDATION_ERROR.stringEnum': '{field} (은)는 허용되(은)는 값들중에 존재하지 않습니다',
    'VALIDATION_ERROR.stringNumeric': '{field} (은)는 숫자 조합이어야 합니다',
    'VALIDATION_ERROR.stringAlpha': '{field} (은)는 영문 조합이어야 합니다',
    'VALIDATION_ERROR.stringAlphanum': '{field} (은)는 영문, 숫자 조합이어야 합니다',
    'VALIDATION_ERROR.stringAlphadash': "{field} (은)는 영문, 특수문자: '-'  조합이어야 합니다",
    'VALIDATION_ERROR.stringHex': '{field} (은)는 hex(16진수) 형태여야 합니다',
    'VALIDATION_ERROR.stringSingleLine': '{field} (은)는 한줄이어야 합니다',
    'VALIDATION_ERROR.number': '{field} field (은)는 숫자이어야 합니다',
    'VALIDATION_ERROR.numberMin': '{field} (은)는 {expected} 이상이어야 합니다',
    'VALIDATION_ERROR.numberMax': '{field} (은)는 {expected} 이하이어야 합니다',
    'VALIDATION_ERROR.numberEqual': '{field} (은)는 {expected} 이어야 합니다',
    'VALIDATION_ERROR.numberNotEqual': '{field} (은)는 {expected} 일 수 없습니다',
    'VALIDATION_ERROR.numberInteger': '{field} (은)는 정수 값이어야 합니다',
    'VALIDATION_ERROR.numberPositive': '{field} (은)는 양수여야 합니다',
    'VALIDATION_ERROR.numberNegative': '{field} (은)는 음수어야 합니다',
    'VALIDATION_ERROR.array': '{field} (은)는 배열 형태여야 합니다',
    'VALIDATION_ERROR.arrayEmpty': '{field} (은)는 빈 배열일 수 없습니다',
    'VALIDATION_ERROR.arrayMin': '{field} 의 배열은 {expected} 개 이상의 값을 가져야 합니다',
    'VALIDATION_ERROR.arrayMax': '{field} 의 배열은 {expected} 개 이하의 값을 가져야 합니다',
    'VALIDATION_ERROR.arrayLength': '{field} 의 배열은 {expected} 개의 값을 가져야 합니다',
    'VALIDATION_ERROR.arrayContains': '{field} 의 배열에(은)는 {expected} 을 포함되어야 합니다',
    'VALIDATION_ERROR.arrayUnique': '{field}의 {actual}(은)는 유일한 {expected} 이어야 합니다',
    'VALIDATION_ERROR.arrayEnum': '{field}의 {actual}(은)는 {expected} 중 하나여야 합니다',
    'VALIDATION_ERROR.tuple': '{field} (은)는 배열 형태여야 합니다',
    'VALIDATION_ERROR.tupleEmpty': '{field} (은)는 빈 배열일 수 없습니다',
    'VALIDATION_ERROR.tupleLength': '{field} 의 배열은 {expected} 개의 값을 가져야 합니다',
    'VALIDATION_ERROR.boolean': '{field} (은)는 true 또(은)는 false 값을 가져야 합니다',
    'VALIDATION_ERROR.function': '{field} (은)는 함수여야 합니다',
    'VALIDATION_ERROR.date': '{field} (은)는 날짜 형식어야 합니다',
    'VALIDATION_ERROR.dateMin': '{field} (은)는 {expected} 와 같거나 이후이어야 합니다',
    'VALIDATION_ERROR.dateMax': '{field} (은)는 {expected} 와 같거나 이전이어야 합니다',
    'VALIDATION_ERROR.forbidden': '{field} (은)는 허용되지 않습니다',
    'VALIDATION_ERROR.email': '{field} (은)는 유효한 email 형식이 아닙니다',
    'VALIDATION_ERROR.url': '{field} (은)는 유효한 url 형식이 아닙니다',
    'VALIDATION_ERROR.enumValue': '{field} (은)는 하나를 선택해야 됩니다',
    'VALIDATION_ERROR.equalValue': '{field} (은)는 {expected} 이어야 합니다',
    'VALIDATION_ERROR.equalField': '{field} (은)는 {expected} 의 값과 같아야 합니다',
    'VALIDATION_ERROR.object': '{field} (은)는 객체 형태이어야 합니다',
    'VALIDATION_ERROR.objectStrict': '{field} 의 {actual} (은)는 허용되지 않습니다',
    'VALIDATION_ERROR.objectMinProps': '{field} (은)는 최소한 {expected} 속성들을 가져야 합니다',
    'VALIDATION_ERROR.objectMaxProps': 'object (은)는 {expected} 속성들을 가질 수 있습니다',
    'VALIDATION_ERROR.uuid': '{field} (은)는 유효한 UUID 형식이 아닙니다',
    'VALIDATION_ERROR.uuidVersion': '{field} (은)는 유요한 UUID 버전이어야 합니다',
    'VALIDATION_ERROR.mac': '{field} (은)는 유효한 Mac 주소가 아닙니다',
    'VALIDATION_ERROR.luhn': '{field} (은)는 유효한 Luhn 값이 아닙니다',
    'VALIDATION_ERROR.duplicate': '{field} (은)는 이미 사용 중입니다',

    'VALIDATION_ERROR.sports.createTeam.username':
      '영문, 숫자, 대쉬(-) 조합 4자 이상 형식으로 입력해주세요',
    'VALIDATION_ERROR.sports.createTeam.username.duplicate': '이미 사용중인 URL 입니다.',
    'VALIDATION_ERROR.sports.createTeam.name.stringPattern':
      '특수 문자나 이모지(emoji)는 사용이 불가합니다',
    'VALIDATION_ERROR.sports.joinTeam.code':
      '영문, 숫자, 대쉬(-) 조합 4자 이상 형식으로 입력해주세요',
    'VALIDATION_ERROR.sports.updateUser.name.pattern': '문자, 숫자, 하이픈(-)만 사용하세요.',
    'VALIDATION_ERROR.sports.updateUser.birthdate.common':
      '생년월일이 올바르지 않습니다. 다시 시도해 주세요.',
    'VALIDATION_ERROR.sports.updateUser.phone-number.common':
      '휴대폰 번호가 올바르지 않습니다. 다시 시도해 주세요.',
    'VALIDATION_ERROR.sports.createEvent.title.required': '일정 이름을 입력해주세요.',
    'VALIDATION_ERROR.sports.createEvent.startAt.required': '날짜와 시간을 올바르게 입력해주세요.',
    'VALIDATION_ERROR.sports.createEvent.endAt.required': '날짜와 시간을 올바르게 입력해주세요.',
    'VALIDATION_ERROR.sports.createEvent.expectedIntensity.required':
      '목표 운동 강도를 선택해 주세요.',
    'VALIDATION_ERROR.sports.createEvent.participants.required': '참가 대상을 선택해주세요.',
    'VALIDATION_ERROR.sports.updateEvent.title.required': '일정 이름을 입력해주세요.',
    'VALIDATION_ERROR.sports.updateEvent.startAt.required': '날짜와 시간을 올바르게 입력해주세요.',
    'VALIDATION_ERROR.sports.updateEvent.endAt.required': '날짜와 시간을 올바르게 입력해주세요.',
    'VALIDATION_ERROR.sports.updateEvent.expectedIntensity.required':
      '목표 운동 강도를 선택해 주세요.',
    'VALIDATION_ERROR.sports.updateEvent.participants.required': '참가 대상을 선택해주세요.',
    'VALIDATION_ERROR.sports.updateTeam.name.pattern': '문자, 숫자, 하이픈(-)만 사용하세요.',
    'VALIDATION_ERROR.sports.TeamGroup.name': '1~10자이내로 입력해주세요.',
    'VALIDATION_ERROR.sports.TeamGroup.userIds': '멤버를 최소 1명 이상 선택해주세요.',

    'VALIDATION_ERROR.plan.buyer.name': '1자 이상 ~ 50자 이하로 입력하세요.',
    'VALIDATION_ERROR.plan.buyer.email': '이메일이 올바르지 않습니다. 다시 시도해 주세요.',
    'VALIDATION_ERROR.plan.buyer.phone': '휴대폰 번호가 올바르지 않습니다. 다시 시도해 주세요.',
    'VALIDATION_ERROR.username.duplicate': '다른 사용자나 팀에서 이미 사용중인 이름입니다.',
    'VALIDATION_ERROR.username.stringMin': '사용자 이름은 최소 4 글자 이상이여야합니다.',
    'VALIDATION_ERROR.username.stringAlphadash':
      '사용자 이름은 알파벳과 대시(-)로 구성되어야합니다.',

    'VALIDATION_ERROR.sports.match.opponent.required': '경기 상대를 입력해주세요.',
    'VALIDATION_ERROR.sports.match.competition.required': '대회 정보를 입력해주세요.',
    'VALIDATION_ERROR.sports.match.location.required': '경기 장소를 입력해주세요.',
    'VALIDATION_ERROR.sports.match.date.required': '날짜와 시간을 올바르게 입력해주세요.',
    'VALIDATION_ERROR.sports.match.notice-target.required': '공지 대상을 선택해주세요.',

    // FIELD VALIDATION ERROR MESSAGE
    'ERROR.FIELD.name': '이름',
    'ERROR.FIELD.team.name': '팀이름',
    'ERROR.FIELD.team.url': 'URL',
    'ERROR.FIELD.sportsCategory': '종목',
    'ERROR.FIELD.region': '국가',
    'ERROR.FIELD.username': '유저네임',
    'ERROR.FIELD.sports.createTeam.username': '팀 Url',
    'ERROR.FIELD.code': '팀 코드',
    'ERROR.FIELD.role': '역할',
    'ERROR.FIELD.position': '직책',
    'ERROR.FIELD.sports_category': '종목',
    'ERROR.FIELD.nationality': '국적',
    'ERROR.FIELD.gender': '성별',
    'ERROR.FIELD.birthdate': '생년월일',
    'ERROR.FIELD.phoneNumber': '휴대폰 번호',

    // EMPTY ERROR MESSAGE
    'ERROR.EMPTY.select': '{field}을 선택해 주세요',
    'ERROR.EMPTY.input': '{field}을 입력해 주세요',
  },
  jp: {
    VALIDATION_ERROR: '検証エラー', // "검증 에러",
    'VALIDATION_ERROR.required': '{field}(は)は必須です.', // "{field} (은)는 필수입니다",
    'VALIDATION_ERROR.string': '{field}(は)はテキストである必要があります.', // "{field} (은)는 텍스트여야 합니다",
    'VALIDATION_ERROR.stringEmpty': '{field}(は)は空の値であることはできません.', // "{field} (은)는 빈 값일 수 없습니다.",
    'VALIDATION_ERROR.stringMin': '{field}(は)は最小{expected}字以上でなければなりません.', // "{field} (은)는 최소 {expected} 자 이상이어야 합니다",
    'VALIDATION_ERROR.stringMax': 'The{field}(は)は最大{expected}字以下でなければなりません.', // "The {field} (은)는 최대 {expected} 자 이하이어야 합니다",
    'VALIDATION_ERROR.stringLength': '{field}(は)は{expected}字である必要があります.', // "{field} (은)는 {expected} 자 이어야합니다",
    'VALIDATION_ERROR.stringPattern': '{field}(は)は形式と合いません.', // "{field} (은)는 형식과 맞지 않습니다",
    'VALIDATION_ERROR.stringContains': '{field}は)は、{expected}文字を含めなければなりません', // "{field} (은)는 {expected} 글자를 포함하여야 합니다",
    'VALIDATION_ERROR.stringEnum': '{field}(は)は許容される(は)値の中に存在しません.', // "{field} (은)는 허용되(은)는 값들중에 존재하지 않습니다",
    'VALIDATION_ERROR.stringNumeric': '{field}はは数字でなければなりません', // "{field} (은)는 숫자 조합이어야 합니다",
    'VALIDATION_ERROR.stringAlpha': '{field}はは英文組合でなければなりません', // "{field} (은)는 영문 조합이어야 합니다",
    'VALIDATION_ERROR.stringAlphanum': '{field}(は)は英文、数字の組み合わせでなければなりません.', // "{field} (은)는 영문, 숫자 조합이어야 합니다",
    'VALIDATION_ERROR.stringAlphadash':
      "{field}(は)は英語、特殊文字: '-'の組み合わせでなければなりません.", // "{field} (은)는 영문, 특수문자: '-'  조합이어야 합니다",
    'VALIDATION_ERROR.stringHex': '{field}(は)はhex(16進数)形態でなければなりません.', // "{field} (은)는 hex(16진수) 형태여야 합니다",
    'VALIDATION_ERROR.stringSingleLine': '{field}(は)は一行でなければなりません.', // "{field} (은)는 한줄이어야 합니다",
    'VALIDATION_ERROR.number': '{field}field(銀は、数字でなければなりません.', // "{field} field (은)는 숫자이어야 합니다",
    'VALIDATION_ERROR.numberMin': '{field}は)は、{expected}以上でなければなりません.', // "{field} (은)는 {expected} 이상이어야 합니다",
    'VALIDATION_ERROR.numberMax': '{field}(は)は{expected}以下でなければなりません.', // "{field} (은)는 {expected} 이하이어야 합니다",
    'VALIDATION_ERROR.numberEqual': '{field}(は)は{expected}である必要があります.', // "{field} (은)는 {expected} 이어야 합니다",
    'VALIDATION_ERROR.numberNotEqual': '{field}は)は、{expected}の可能性がありません.', // "{field} (은)는 {expected} 일 수 없습니다",
    'VALIDATION_ERROR.numberInteger': '{field}は)は、定数の値でなければならないです.', // "{field} (은)는 정수 값이어야 합니다",
    'VALIDATION_ERROR.numberPositive': '{field}(は)は正である必要があります.', // "{field} (은)는 양수여야 합니다",
    'VALIDATION_ERROR.numberNegative': '{field}(は)は負の数である必要があります.', // "{field} (은)는 음수어야 합니다",
    'VALIDATION_ERROR.array': '{field}(は)は配列形態である必要があります.', // "{field} (은)는 배열 형태여야 합니다",
    'VALIDATION_ERROR.arrayEmpty': '{field}(は)は空の配列であることはできません.', // "{field} (은)는 빈 배열일 수 없습니다",
    'VALIDATION_ERROR.arrayMin': '{field}の配列は{expected}犬以上の値を持つ必要があります.', // "{field} 의 배열은 {expected} 개 이상의 값을 가져야 합니다",
    'VALIDATION_ERROR.arrayMax': '{field}の配列は{expected}犬以下の値を持つ必要があります.', // "{field} 의 배열은 {expected} 개 이하의 값을 가져야 합니다",
    'VALIDATION_ERROR.arrayLength': '{field}の配列は{expected}の値を持つ必要があります.', // "{field} 의 배열은 {expected} 개의 값을 가져야 합니다",
    'VALIDATION_ERROR.arrayContains': '{field}の配列には{expected}を含める必要があります.', // "{field} 의 배열에(은)는 {expected} 을 포함되어야 합니다",
    'VALIDATION_ERROR.arrayUnique': '{field}の{actual}(は)は唯一の{expected}である必要があります.', // "{field}의 {actual}(은)는 유일한 {expected} 이어야 합니다",
    'VALIDATION_ERROR.arrayEnum':
      '{field}の{actual}(は)は{expected}のいずれかでなければなりません.', // "{field}의 {actual}(은)는 {expected} 중 하나여야 합니다",
    'VALIDATION_ERROR.tuple': '{field}(は)は配列形態である必要があります.', // "{field} (은)는 배열 형태여야 합니다",
    'VALIDATION_ERROR.tupleEmpty': '{field}(は)は空の配列であることはできません.', // "{field} (은)는 빈 배열일 수 없습니다",
    'VALIDATION_ERROR.tupleLength': '{field}の配列は{expected}個の値を持つ必要があります.', // "{field} 의 배열은 {expected} 개의 값을 가져야 합니다",
    'VALIDATION_ERROR.boolean': '{field}ははtrueまたははfalse値を持つ必要があります.', // "{field} (은)는 true 또(은)는 false 값을 가져야 합니다",
    'VALIDATION_ERROR.function': '{field}はは関数でなければなりません.', // "{field} (은)는 함수여야 합니다",
    'VALIDATION_ERROR.date': '{field}(は)は日付形式でなければなりません.', // "{field} (은)는 날짜 형식어야 합니다",
    'VALIDATION_ERROR.dateMin': '{field}(は)は{expected}と同じか、それ以降でなければなりません.', // "{field} (은)는 {expected} 와 같거나 이후이어야 합니다",
    'VALIDATION_ERROR.dateMax': '{field}(は)は{expected}と同じか以前でなければなりません.', // "{field} (은)는 {expected} 와 같거나 이전이어야 합니다",
    'VALIDATION_ERROR.forbidden': '{field}(は)は許可されません.', // "{field} (은)는 허용되지 않습니다",
    'VALIDATION_ERROR.email': 'field}は有効なemail形式ではありません.', // "{field} (은)는 유효한 email 형식이 아닙니다",
    'VALIDATION_ERROR.url': '{field}(は)は有効なurl形式ではありません.', // "{field} (은)는 유효한 url 형식이 아닙니다",
    'VALIDATION_ERROR.enumValue': '{field}(は)は一つを選択しなければなりません.', // "{field} (은)는 하나를 선택해야 됩니다",
    'VALIDATION_ERROR.equalValue': '{field}は)は、{expected}受け継がねばならないです.', // "{field} (은)는 {expected} 이어야 합니다",
    'VALIDATION_ERROR.equalField': '{field}(は)は{expected}の値と等しくなければなりません.', // "{field} (은)는 {expected} 의 값과 같아야 합니다",
    'VALIDATION_ERROR.object': '{field}(は)はオブジェクト形態である必要があります.', // "{field} (은)는 객체 형태이어야 합니다",
    'VALIDATION_ERROR.objectStrict': '{field}の{actual}は)は許容されません.', // "{field} 의 {actual} (은)는 허용되지 않습니다",
    'VALIDATION_ERROR.objectMinProps':
      '{field}(は)は、少なくとも{expected}プロパティを持つ必要があります.', // "{field} (은)는 최소한 {expected} 속성들을 가져야 합니다",
    'VALIDATION_ERROR.objectMaxProps': 'object(は)は{expected}プロパティを持つことができます.', // "object (은)는 {expected} 속성들을 가질 수 있습니다",
    'VALIDATION_ERROR.uuid': '{field}(は)は有効なUUID形式ではありません.', // "{field} (은)는 유효한 UUID 형식이 아닙니다",
    'VALIDATION_ERROR.uuidVersion': '{field}(は)は有限UIDバージョンである必要があります.', // "{field} (은)는 유요한 UUID 버전이어야 합니다",
    'VALIDATION_ERROR.mac': '{field}はは有効なMacアドレスがありません.', // "{field} (은)는 유효한 Mac 주소가 아닙니다",
    'VALIDATION_ERROR.luhn': '{field}はは有効なLuhn値がありません.', // "{field} (은)는 유효한 Luhn 값이 아닙니다",
    'VALIDATION_ERROR.duplicate': '{field}(は)は既に使用中です', // "{field} (은)는 이미 사용 중입니다",

    'VALIDATION_ERROR.sports.createTeam.username':
      '英語、数字、ダッシュ(-)組み合わせ 4文字以上の形式で入力してください', // "영문, 숫자, 대쉬(-) 조합 4자 이상 형식으로 입력해주세요",
    'VALIDATION_ERROR.sports.createTeam.username.duplicate': '既に使用中のURLです.', // "이미 사용중인 URL 입니다.",
    'VALIDATION_ERROR.sports.createTeam.name.stringPattern':
      '特殊文字や絵文字(emoji)は使用できません。', // "특수 문자나 이모지(emoji)는 사용이 불가합니다",
    'VALIDATION_ERROR.sports.joinTeam.code':
      '英語、数字、ダッシュ(-)組み合わせ 4文字以上の形式で入力してください', // "영문, 숫자, 대쉬(-) 조합 4자 이상 형식으로 입력해주세요",
    'VALIDATION_ERROR.sports.updateUser.name.pattern':
      '文字、数字、ハイフン(-)のみ使用してください', // "문자, 숫자, 하이픈(-)만 사용하세요.",
    'VALIDATION_ERROR.sports.updateUser.birthdate.common':
      '生年月日が正しくありません. もう一度お試しください.', // "생년월일이 올바르지 않습니다. 다시 시도해 주세요.",
    'VALIDATION_ERROR.sports.updateUser.phone-number.common':
      '携帯電話番号が正しくありません. もう一度お試しください.', // "휴대폰 번호가 올바르지 않습니다. 다시 시도해 주세요.",
    'VALIDATION_ERROR.sports.createEvent.title.required': 'スケジュール名を入力してください.', // "일정 이름을 입력해주세요.",
    'VALIDATION_ERROR.sports.createEvent.startAt.required': '日付と時間を正しく入力してください.', // "날짜와 시간을 올바르게 입력해주세요.",
    'VALIDATION_ERROR.sports.createEvent.endAt.required': '日付と時間を正しく入力してください.', // "날짜와 시간을 올바르게 입력해주세요.",
    'VALIDATION_ERROR.sports.createEvent.expectedIntensity.required':
      '目標運動強度を選択してください.', // "목표 운동 강도를 선택해 주세요.",
    'VALIDATION_ERROR.sports.createEvent.participants.required': '参加対象を選択してください.', // "참가 대상을 선택해주세요.",
    'VALIDATION_ERROR.sports.updateEvent.title.required': 'スケジュール名を入力してください.', // "일정 이름을 입력해주세요.",
    'VALIDATION_ERROR.sports.updateEvent.startAt.required': '日付と時間を正しく入力してください.', // "날짜와 시간을 올바르게 입력해주세요.",
    'VALIDATION_ERROR.sports.updateEvent.endAt.required': '日付と時間を正しく入力してください.', // "날짜와 시간을 올바르게 입력해주세요.",
    'VALIDATION_ERROR.sports.updateEvent.expectedIntensity.required':
      '目標運動強度を選択してください.', // "목표 운동 강도를 선택해 주세요.",
    'VALIDATION_ERROR.sports.updateEvent.participants.required': '参加対象を選択してください.', // "참가 대상을 선택해주세요.",
    'VALIDATION_ERROR.sports.updateTeam.name.pattern':
      '文字、数字、ハイフン(-)のみ使用してください.', // "문자, 숫자, 하이픈(-)만 사용하세요.",
    'VALIDATION_ERROR.sports.TeamGroup.name': '1~10文字以内に入力してください.', // "1~10자이내로 입력해주세요.",
    'VALIDATION_ERROR.sports.TeamGroup.userIds': 'メンバーを少なくとも1人以上選択してください.', // "멤버를 최소 1명 이상 선택해주세요.",

    'VALIDATION_ERROR.plan.buyer.name': '1文字以上~50文字以下で入力してください.', // "1자 이상 ~ 50자 이하로 입력하세요.",
    'VALIDATION_ERROR.plan.buyer.email': 'メールが正しくありません. もう一度お試しください.', // "이메일이 올바르지 않습니다. 다시 시도해 주세요.",
    'VALIDATION_ERROR.plan.buyer.phone': '携帯電話番号が正しくありません. もう一度お試しください.', // "휴대폰 번호가 올바르지 않습니다. 다시 시도해 주세요.",
    'VALIDATION_ERROR.username.duplicate': '他のユーザーまたはチームですでに使用中の名前です.', // "다른 사용자나 팀에서 이미 사용중인 이름입니다.",
    'VALIDATION_ERROR.username.stringMin': 'ユーザー名は最低 4 文字以上である必要があります.', // "사용자 이름은 최소 4 글자 이상이여야합니다.",
    'VALIDATION_ERROR.username.stringAlphadash':
      'ユーザー名はアルファベットとダッシュ(-)で構成する必要があります.', // "사용자 이름은 알파벳과 대시(-)로 구성되어야합니다.",

    'VALIDATION_ERROR.sports.match.opponent.required': '試合相手を入力してください。', // '경기 상대를 입력해주세요.',
    'VALIDATION_ERROR.sports.match.competition.required': '大会情報を入力してください。', // '대회 정보를 입력해주세요.',
    'VALIDATION_ERROR.sports.match.location.required': '試合場所を入力してください。', // '경기 장소를 입력해주세요.',
    'VALIDATION_ERROR.sports.match.date.required': '日付と時間を正しく入力してください。', // '날짜와 시간을 올바르게 입력해주세요.',
    'VALIDATION_ERROR.sports.match.notice-target.required': 'お知らせ対象を選択してください。', // '공지 대상을 선택해주세요.',

    // FIELD VALIDATION ERROR MESSAGE
    'ERROR.FIELD.name': '名前', // "이름",
    'ERROR.FIELD.team.name': 'チーム名前', // "팀이름",
    'ERROR.FIELD.team.url': 'URL', // "URL",
    'ERROR.FIELD.sportsCategory': '種目', // "종목",
    'ERROR.FIELD.region': '国家', // "국가",
    'ERROR.FIELD.username': 'ユーザーネーム', // "유저네임",
    'ERROR.FIELD.sports.createTeam.username': 'チームUrl', // "팀 Url",
    'ERROR.FIELD.code': 'チームコード', // "팀 코드",
    'ERROR.FIELD.role': '役割', // "역할",
    'ERROR.FIELD.position': '職責,', // "직책",
    'ERROR.FIELD.sports_category': '種目', // "종목",
    'ERROR.FIELD.nationality': '国籍', // "국적",
    'ERROR.FIELD.gender': '性別', // "성별",
    'ERROR.FIELD.birthdate': '生年月日', // "생년월일",
    'ERROR.FIELD.phoneNumber': '携帯番号', // "휴대폰 번호",

    // EMPTY ERROR MESSAGE
    'ERROR.EMPTY.select': '{field}を選択してください', // "{field}을 선택해 주세요",
    'ERROR.EMPTY.input': '{field}を入力してください。', // "{field}을 입력해 주세요",
  },
}
