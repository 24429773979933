import { moment } from '@common/utils/libs'
import { toJS } from 'mobx'
import { cast, getRoot, types } from 'mobx-state-tree'

import {
  SimpleOrder,
  UserDataOrderByInput,
  UserDataStride,
  UserDataType,
} from '@plco-pro/graphqls/react.generated'
import {
  BadgeInputChartMonitoringMap,
  chartMonitoringMap,
  ChartMonitoringPeriod,
  MonitoringDataType,
  SingleDataType,
} from '@plco-pro/maps/chart-monitoring'
import { ChartSystemMapKey } from '@plco-pro/maps/chart-system/chart-system'
import { ChartDataMap, TableDataMap } from '@plco-pro/pages/monitoring'

export const DEFAULT_CHART_SORT_FIELD = UserDataType.createdAt
export const DEFAULT_CHART_SORT_ORDER = SimpleOrder.ASC
export const DEFAULT_CHART_PAGE = 0
export const DEFAULT_CHART_PAGE_SIZE = 100
export const DEFAULT_CHART_HAS_PREV = false
export const DEFAULT_CHART_HAS_NEXT = false
export const DEFAULT_TABLE_SORT_FIELD = UserDataType.name
export const DEFAULT_TABLE_SORT_ORDER = SimpleOrder.DESC
export const DEFAULT_TABLE_PAGE = 0
export const DEFAULT_TABLE_PAGE_COUNT = 1
export const DEFAULT_TABLE_PAGE_SIZE = 20
export const DEFAULT_TABLE_HAS_PREV = false
export const DEFAULT_TABLE_HAS_NEXT = true

export const MonitoringStore = types
  .model('Monitoring', {
    // SHARED
    isBottomSheetOpen: types.optional(types.boolean, true),
    // invite card
    isInviteCardClosed: types.optional(types.boolean, false),
    // query
    reloadedAt: types.optional(types.Date, new Date()),
    // filtering
    groupId: types.optional(types.maybeNull(types.string), null),
    date: types.optional(types.string, new Date(new Date().setHours(23, 59, 0, 0)).toISOString()),
    playerIds: types.optional(types.maybeNull(types.array(types.string)), null),

    // CHART
    // query
    chartLoading: types.optional(types.boolean, false),
    chartReload: types.optional(types.boolean, false),
    // filtering
    chartDataType: types.optional(
      types.enumeration(Object.values(MonitoringDataType)),
      MonitoringDataType.MUSCLE,
    ),
    // sorting
    chartSortField: types.optional(
      types.enumeration(Object.values(UserDataType)),
      DEFAULT_CHART_SORT_FIELD,
    ),
    chartSortOrder: types.optional(
      types.enumeration(Object.values(SimpleOrder)),
      DEFAULT_CHART_SORT_ORDER,
    ),
    // pagination
    chartPage: types.optional(types.number, DEFAULT_CHART_PAGE),
    chartPageSize: types.optional(types.number, DEFAULT_CHART_PAGE_SIZE),
    chartHasPrev: types.optional(types.boolean, DEFAULT_CHART_HAS_PREV),
    chartHasNext: types.optional(types.boolean, DEFAULT_CHART_HAS_NEXT),
    // data
    chartDataMap: types.optional(types.frozen<ChartDataMap>(), []),
    // period
    chartPeriod: types.optional(
      types.enumeration(Object.values(ChartMonitoringPeriod)),
      ChartMonitoringPeriod.DAY,
    ),
    // TABLE
    // query
    tableLoading: types.optional(types.boolean, false),
    tableReload: types.optional(types.boolean, false),
    // sorting
    tableSortField: types.optional(
      types.enumeration(Object.values(UserDataType)),
      DEFAULT_TABLE_SORT_FIELD,
    ),
    tableSortOrder: types.optional(
      types.enumeration(Object.values(SimpleOrder)),
      DEFAULT_TABLE_SORT_ORDER,
    ),
    // pagination
    tablePage: types.optional(types.number, DEFAULT_TABLE_PAGE),
    tablePageCount: types.optional(types.number, DEFAULT_TABLE_PAGE_COUNT),
    tablePageSize: types.optional(types.number, DEFAULT_TABLE_PAGE_SIZE),
    tableHasPrev: types.optional(types.boolean, DEFAULT_TABLE_HAS_PREV),
    tableHasNext: types.optional(types.boolean, DEFAULT_TABLE_HAS_NEXT),
    // data
    tableDataMap: types.optional(types.frozen<TableDataMap>(), []),
  })
  .actions((self) => {
    return {
      // SHARED
      setBottomSheetVisible(value: boolean) {
        self.isBottomSheetOpen = value
      },
      // invite card
      setIsInviteCardClosed(value: boolean) {
        self.isInviteCardClosed = value
      },
      // query
      setReloadedAt(value: Date) {
        self.reloadedAt = value
      },
      // filtering
      setGroupId(value: string | null) {
        self.groupId = value
      },
      setDate(value: Date) {
        self.chartPage = 0
        self.tablePage = 0
        self.date = moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
      },
      setPlayerIds(value: string[] | null) {
        self.playerIds = cast(value)
      },

      // CHART
      // query
      setChartLoading(value: boolean) {
        self.chartLoading = value
      },
      setChartReload(value: boolean) {
        self.chartReload = value
      },
      // filtering
      setChartDataType(value: MonitoringDataType) {
        self.chartDataType = value
      },
      // sorting
      setChartSortField(value: UserDataType) {
        self.chartSortField = value
      },
      setChartSortOrder(value: SimpleOrder) {
        self.chartSortOrder = value
      },
      resetChartSorting() {
        self.chartSortField = DEFAULT_CHART_SORT_FIELD
        self.chartSortOrder = DEFAULT_CHART_SORT_ORDER
      },
      // pagination
      setChartePage(value: number) {
        self.chartPage = value
      },
      setChartePageSize(value: number) {
        self.chartPageSize = value
      },
      setCharteHasPrev(value: boolean) {
        self.chartHasPrev = value
      },
      setCharteHasNext(value: boolean) {
        self.chartHasNext = value
      },
      resetChartPagination() {
        self.chartPage = DEFAULT_CHART_PAGE
        self.chartPageSize = DEFAULT_CHART_PAGE_SIZE
      },
      // period
      setChartPeriod(value: ChartMonitoringPeriod) {
        self.chartPeriod = value
      },
      // data
      setChartDataMap(value: ChartDataMap) {
        self.chartDataMap = value
      },

      // TABLE
      // query
      setTableLoading(value: boolean) {
        self.tableLoading = value
      },
      setTableReload(value: boolean) {
        self.tableReload = value
      },
      // sorting
      setTableSortField(value: UserDataType) {
        self.tableSortField = value
      },
      setTableSortOrder(value: SimpleOrder) {
        self.tableSortOrder = value
      },
      resetTableSorting() {
        self.tableSortField = DEFAULT_TABLE_SORT_FIELD
        self.tableSortOrder = DEFAULT_TABLE_SORT_ORDER
      },
      // pagination
      setTablePage(value: number) {
        self.tablePage = value
      },
      setTablePageCount(value: number) {
        self.tablePageCount = value
      },
      setTablePageSize(value: number) {
        self.tablePageSize = value
      },
      setTableHasPrev(value: boolean) {
        self.tableHasPrev = value
      },
      setTableHasNext(value: boolean) {
        self.tableHasNext = value
      },
      resetTablePagination() {
        self.tablePage = DEFAULT_TABLE_PAGE
        self.tablePageSize = DEFAULT_TABLE_PAGE_SIZE
      },
      // data
      setTableDataMap(value: TableDataMap) {
        self.tableDataMap = value
      },
    }
  })
  .views((self) => {
    return {
      // SHARED
      get cursor() {
        return moment(self.date).startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
      },
      get deserializedPlayerIds() {
        return toJS(self.playerIds)
      },

      // CHART
      get chartSorting() {
        const root: any = getRoot(self)
        const { timezone } = root?.preference?.locale

        return {
          order: {
            field: self.chartSortField,
            sort: self.chartSortOrder,
            timezone,
            cursor: moment(self.date).startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
            stride: UserDataStride.DAY,
          },
        }
      },
      get chartPagination() {
        const offset = self.chartPage * self.chartPageSize
        const limit = self.chartPageSize
        return {
          offset,
          limit,
        }
      },

      // TABLE
      get tableSorting(): { order: UserDataOrderByInput } {
        const root: any = getRoot(self)
        const { timezone } = root?.preference?.locale

        return {
          order: {
            field: self.tableSortField,
            sort: self.tableSortOrder,
            stride: UserDataStride.DAY,
            cursor: moment(self.date).startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
            timezone,
          },
        }
      },
      get tablePagination() {
        const offset = self.tablePage * self.tablePageSize
        const limit = (self.tablePage + 1) * self.tablePageSize

        return {
          offset,
          limit,
        }
      },

      // CHART CONFIG KEY
      getChartConfigKey() {
        const { size } = chartMonitoringMap
        const monitoringChartMapValues = chartMonitoringMap.values()

        if (SingleDataType.includes(self.chartDataType)) {
          self.setChartPeriod(ChartMonitoringPeriod.DAY)
        }

        for (let i = 0; i < size; i += 1) {
          const dataTypeChartMap = monitoringChartMapValues.next()
            .value as BadgeInputChartMonitoringMap

          const chartConfigByPeriod = dataTypeChartMap.get(self.chartDataType)

          if (chartConfigByPeriod) {
            return chartConfigByPeriod[self.chartPeriod]
          }
        }
        return
      },
      get hasChartConfigKey() {
        return !!this.getChartConfigKey()
      },

      get chartConfigKey() {
        const configKey = this.getChartConfigKey()
        const defaultConfigKey: ChartSystemMapKey = 'TEAM:CONDITION:1_DAYS:USER_AVATAR:BAR'

        if (configKey) {
          return configKey
        }

        console.info(
          "chart config key doesn't exist in get chart config key inside monitoring store views",
        )

        return defaultConfigKey
      },
    }
  })
