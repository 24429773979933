import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'

import { BlockProps } from 'victory-core'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Flex } from '@plco-pro/components/atoms/flex'
import { GhostCard } from '@plco-pro/components/atoms/ghost-card'
import { Text } from '@plco-pro/components/atoms/text'
import { Chart } from '@plco-pro/components/organisms/chart'
import { useChartSystem } from '@plco-pro/hooks/chart-system'
import { useMoment } from '@plco-pro/hooks/moment'
import { ChartSystemMapKey } from '@plco-pro/maps/chart-system/chart-system'
import { observer, useStore } from '@plco-pro/stores'
import { DRAWER_ANIMATION_DURATION } from '@plco-pro/utils/constant'

export type CardChartPlayersProps = {
  configKey: ChartSystemMapKey
  title: string
  padding?: BlockProps
  chartContainerProps?: BoxProps
  isDateRange?: boolean
}

export const CardChartPlayers: React.FunctionComponent<CardChartPlayersProps> = observer(
  forwardRef(({ configKey, title, padding, chartContainerProps, isDateRange }, ref) => {
    const moment = useMoment()
    const store = useStore()

    const { date, playerId, startDate, endDate } = store.players
    const { isOpenDrawer, isOpenPlayersDrawer } = store.navigation

    const chartBoxRef = useRef<HTMLDivElement>(null)

    const [chartWidth, setChartWidth] = useState<number | 'auto'>()

    const {
      initialized,
      store: chartStore,
      chartSystemProps,
      loading,
    } = useChartSystem(configKey, isDateRange)

    useEffect(() => {
      if (initialized) {
        chartStore?.setDate(date)
        chartStore?.setDateRange([startDate, endDate])
        chartStore?.setVariables({
          id: playerId,
        })
      }
    }, [chartStore, date, endDate, initialized, moment, playerId, startDate])

    const chartPadding = useMemo(() => {
      if (padding) {
        return padding
      }

      return {
        top: 64,
        bottom: 64,
        left: 64,
        right: 64,
      }
    }, [padding])

    useEffect(() => {
      setTimeout(() => {
        setChartWidth(chartBoxRef.current?.getBoundingClientRect().width || 'auto')
      }, DRAWER_ANIMATION_DURATION + 100)
    }, [isOpenPlayersDrawer, isOpenDrawer])

    if (loading) {
      return (
        <GhostCard sx={{ height: '505px' }}>
          <Flex sx={{ pb: 5, flexDirection: 'column', alignItems: 'center' }}>
            <Flex
              sx={{
                px: [2, 3, null, null],
                pt: [2, 3, null, null],
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Text variant={['s1', 'h4']} sx={{ fontWeight: 'bold' }}>
                {title}
              </Text>
            </Flex>
          </Flex>
        </GhostCard>
      )
    }

    return (
      <Card sx={{ height: '100%' }}>
        <Flex sx={{ pb: 5, flexDirection: 'column', alignItems: 'center' }}>
          <Flex
            sx={{
              px: [2, 3, null, null],
              pt: [2, 3, null, null],
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Text variant={['s1', 'h4']} sx={{ fontWeight: 700 }}>
              {title}
            </Text>
          </Flex>

          <Box ref={chartBoxRef} sx={{ width: '100%', mt: 8 }} {...chartContainerProps}>
            <Chart
              ref={ref}
              name={configKey}
              width={chartWidth}
              height={320}
              padding={chartPadding}
              isDateRange={isDateRange}
              {...chartSystemProps}
            />
          </Box>
        </Flex>
      </Card>
    )
  }),
)
