import {
  MonitoringChartDataQueryVariables,
  QuerySystemTeamAverageDataQueryVariables,
  QuerySystemTeamUserMapsDataQueryVariables,
  QuerySystemUserDataQueryVariables,
  QuerySystemUserWorkloadDataQueryVariables,
  useMonitoringChartDataLazyQuery,
  useQuerySystemTeamAverageDataLazyQuery,
  useQuerySystemTeamUserMapsDataLazyQuery,
  useQuerySystemUserDataLazyQuery,
  useQuerySystemUserWorkloadDataLazyQuery,
} from '@plco-pro/graphqls/react.generated'

export type QuerySystemMap = typeof querySystemMap

export const querySystemMap = {
  USER_DATA: () => useQuerySystemUserDataLazyQuery,
  USER_WORKLOAD_DATA: () => useQuerySystemUserWorkloadDataLazyQuery,
  TEAM_USER_MAPS_DATA: () => useQuerySystemTeamUserMapsDataLazyQuery,
  TEAM_AVG_DATA: () => useQuerySystemTeamAverageDataLazyQuery,
  TEAM_MONITORING: () => useMonitoringChartDataLazyQuery,
}

export type QuerySystemVariablesTypeMap = {
  USER_DATA: QuerySystemUserDataQueryVariables
  USER_WORKLOAD_DATA: QuerySystemUserWorkloadDataQueryVariables
  TEAM_USER_MAPS_DATA: QuerySystemTeamUserMapsDataQueryVariables
  TEAM_AVG_DATA: QuerySystemTeamAverageDataQueryVariables
  TEAM_MONITORING: MonitoringChartDataQueryVariables
}
