export const formatCurrency = (value: number | string): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatPhone = (value: number | string): string => {
  return value.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-')
}

export const formatUserPosition = (value?: string | null) => {
  return value ? value.replace(/_/g, ' ').trimStart() : 'None'
}
