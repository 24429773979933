import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'
import _throttle from 'lodash/throttle'
import { useRouter } from 'next/router'
import {
  createContainer,
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryClipContainer,
  VictoryGroup,
  VictoryLine,
  VictoryPolarAxis,
  VictoryPortal,
  VictoryScatter,
  VictoryStack,
  VictoryVoronoiContainerProps,
  VictoryZoomContainerProps,
} from 'victory'
import {
  AnimatePropTypeInterface,
  BlockProps,
  DomainPaddingPropType,
  DomainTuple,
  EventPropTypeInterface,
  LabelOrientationType,
  OrientationTypes,
  VictoryStyleInterface,
} from 'victory-core'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { BottomSheetWorkloadHistory } from '@plco-pro/components/molecules/bottom-sheet-workload-history'
import { ChartCustomBar } from '@plco-pro/components/molecules/chart-custom-bar'
import { ChartCustomScatter } from '@plco-pro/components/molecules/chart-custom-scatter'
import { ChartCustomStack } from '@plco-pro/components/molecules/chart-custom-stack'
import { ChartTooltipPeriod } from '@plco-pro/components/molecules/chart-label-tooltip-multi-team-data'
import { chartTheme } from '@plco-pro/components/molecules/chart-theme'
import {
  ChartTooltipWorkloadHistory,
  DEFAULT_TOOLTIP_WIDTH,
} from '@plco-pro/components/molecules/chart-tooltip-workload-history'
import { ChartLegend } from '@plco-pro/components/organisms/chart-legend'
import { SportsRawWorkload } from '@plco-pro/graphqls/react.generated'
import { useMoment } from '@plco-pro/hooks/moment'
import { ChartParsedDataItem } from '@plco-pro/hooks/store-system'
import { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import { Color } from '@plco-pro/maps/chart-system/chart-system.types'
import { CHART_TYPE } from '@plco-pro/maps/chart-system/props'
import { getScreenSize } from '@plco-pro/providers/responsive'
import theme from '@plco-pro/themes/main'

export const DEFAULT_CHART_WIDTH = 600
export const DEFAULT_CHART_HEIGHT = 300
export const DOMAIN_DEFAULT_SIZE = 10
export const DOMAIN_DEFAULT_SHIFT = 10
export const DOMAIN_DEFAULT_X_START = 1
export const DOMAIN_DEFAULT_X_END = 2
export const DOMAIN_DEFAULT_Y_START = 0
export const DOMAIN_DEFAULT_Y_END = 1
export const DOMAIN_DEFAULT_X_PADDING_RATIO = 0.25 // by how much of a domain to add on left and right as padding

const VictoryZoomVoronoiContainer = createContainer<
  VictoryZoomContainerProps,
  VictoryVoronoiContainerProps
>('zoom', 'voronoi')

// domain
export type ChartPropsDomainX = {
  start?: number
  end?: number
}
export type ChartPropsDomainY = {
  start?: number
  end?: number
}
export type ChartPropsDomain = {
  size?: number
  shift?: number
  xPaddingRatio?: number
  x?: ChartPropsDomainX
  y?: ChartPropsDomainY
}

// axis
export type ChartPropsAxisX = {
  orientation?: OrientationTypes // orientation
  tickCount?: number // how many tick on y-axis
  tickValues?: any[] // explicitly specifies tick values of y-axis
  tickFormat?: any[] | { (tick: any, index: number, ticks: any[]): string | number } // how to format tick on y-axis
  tickLabelComponent?: React.ReactElement // custom component
}
export type ChartPropsAxisY = {
  orientation?: OrientationTypes // orientation
  style?: VictoryStyleInterface
  tickCount?: number // how many tick on y-axis
  tickValues?: any[] // explicitly specifies tick values of y-axis
  tickFormat?: any[] | { (tick: any, index: number, ticks: any[]): string | number } // how to format tick on y-axis
  tickLabelComponent?: React.ReactElement // custom component
}
export type ChartPropsAxis = {
  x?: ChartPropsAxisX | ChartPropsAxisX[]
  y?: ChartPropsAxisY | ChartPropsAxisY[]
}

// polar axis
export type ChartPropsPolarAxisX = {
  labelPlacement?: LabelOrientationType // orientation
  style?: VictoryStyleInterface
  tickCount?: number // how many tick on y-axis
  tickValues?: any[] // explicitly specifies tick values of y-axis
  tickFormat?: any[] | { (tick: any, index: number, ticks: any[]): string | number } // how to format tick on y-axis
  tickLabelComponent?: React.ReactElement // custom component
}
export type ChartPropsPolarAxisY = {
  labelPlacement?: LabelOrientationType // orientation
  dataName?: string
  style?: VictoryStyleInterface
  tickCount?: number // how many tick on y-axis
  tickValues?: any[] // explicitly specifies tick values of y-axis
  tickFormat?: any[] | { (tick: any, index: number, ticks: any[]): string | number } // how to format tick on y-axis
  tickLabelComponent?: React.ReactElement // custom component
}
export type ChartPropsPolarAxis = {
  x?: ChartPropsPolarAxisX | ChartPropsPolarAxisX[]
  y?: ChartPropsPolarAxisY | ChartPropsPolarAxisY[]
}

// label
export type ChartPropsParams = {
  [key: string]: any // any props
}
export type ChartPropsLabel = {
  labelComponent?: React.ReactElement // custom component
  config?: ChartPropsParams // label component config
}

// legend
export type ChartPropsLegend = {
  label?: string
  chartType?: CHART_TYPE
  chartColor?: keyof typeof theme.colors
  toggle?: boolean
  visible?: boolean
  group?: number
}

// dataset
export type ChartPropsDatasetItemYFormat = (data: any) => number
export type ChartPropsDatasetItemPolarYFormat = {
  [key: string]: ChartPropsDatasetItemYFormat
}
export type ChartPropsDatasetItemLabel = {
  labelComponent?: React.ReactElement
}

export type ChartPropsDatasetItem = {
  data?: ChartParsedDataItem[]
  type?: CHART_TYPE
  style?: VictoryStyleInterface
  events?: EventPropTypeInterface<'data', any>[]
  animate?: boolean | AnimatePropTypeInterface
  noneDataColor?: keyof typeof theme.colors
  colorHover?: keyof typeof theme.colors
  noneDataColorHover?: keyof typeof theme.colors
  colorScale?: (keyof typeof theme.colors)[]
  colorScaleHover?: (keyof typeof theme.colors)[]
  axis?: ChartPropsAxis
  polarAxis?: ChartPropsPolarAxis
  yFormat?: ChartPropsDatasetItemYFormat
  polarYFormat?: ChartPropsDatasetItemPolarYFormat
  legend?: ChartPropsLegend[]
  label?: ChartPropsDatasetItemLabel
  offset?: number
  cornerRadius?: number
  drawPrevision?: boolean
  scatterWidth?: number
  hoverScatterWidth?: number
  period?: ChartTooltipPeriod
  chipType?: 'number' | 'ten' | 'time'
  dataType?: MonitoringDataType
  chartType?: CHART_TYPE
  colorList?: Color[]
  nullDataKey?: string
}
export type ChartPropsDataset = ChartPropsDatasetItem[]

export type ChartProps = {
  ref?: any // ref used by parent components
  name: string // name of the chart, used for list rendering
  width?: number | 'auto' // chart width, use auto for responsive width
  height?: number | 'auto' // chart height, use auto for responsive height
  padding?: BlockProps // chart padding
  legendPadding?: BlockProps // legend padding
  containerProps?: BoxProps // props for chart container
  onChangeCanShiftLeft?: (value: boolean) => void // callback on left shift availability change
  onChangeCanShiftRight?: (value: boolean) => void // callback on right shift availability change
  dataset: ChartPropsDataset // dataset props
  domain: ChartPropsDomain // domain props
  label?: ChartPropsLabel // label props
  hasHistoryLine?: boolean
  isDateRange?: boolean
}

export type ChartDomain = {
  x: DomainTuple
  y: DomainTuple
}

export type ChartRef = {
  onShiftLeft: () => void
  onShiftRight: () => void
}

export type HoverUserData = {
  index: number
  datum: { [key in string]?: any }
  scale: { x?: any; y?: any }
}

export const Chart: React.FunctionComponent<ChartProps> = React.memo(
  forwardRef((props, ref) => {
    const moment = useMoment()
    const { smAndDown } = getScreenSize(false)
    const { query } = useRouter()

    const {
      width,
      height,
      padding,
      legendPadding,
      containerProps,
      onChangeCanShiftLeft = () => {},
      onChangeCanShiftRight = () => {},
      dataset,
      label,
      hasHistoryLine,
      isDateRange,
    } = props

    // props parsing
    const name = useMemo(() => props.name, [props.name])
    // TODO: instead of first item, make some order rule of combine data or use dataIndex in dataset
    const data = useMemo(() => dataset?.[0]?.data || [], [dataset])

    // type conditionals
    const isPolar = dataset?.filter((item) => item.type === 'POLAR').length > 0
    const isStack = dataset?.filter((item) => item.type === 'STACK').length > 0

    // clicked user
    const [clickeIndex, setClickIndex] = useState<number>()
    const [isActive, setIsActive] = useState(false)

    const [isChartActive, setIsChartActive] = useState(false)

    // hoverd user
    const [hoverIndex, setHoverIndex] = useState<number>()
    const [tooltipDatum, setTooltipDatum] = useState<{ [key in string]?: any }>()
    const [tooltipScale, setTooltipScale] = useState<{ x?: any; y?: any }>()
    const [isTooltipActive, setisTooltipActive] = useState(false)

    // custom tooltip location
    const [xy, setXY] = useState({ x: 0, y: 0 })

    const onClickUserData = (index: number) => {
      setClickIndex(index)
      setIsActive(true)
    }

    const onHoverUserData = ({ index, datum, scale }: HoverUserData) => {
      setHoverIndex(index)
      setTooltipDatum(datum)
      setTooltipScale(scale)

      setIsChartActive(true)
      setIsActive(true)
    }

    const onCloseTooltip = () => {
      setIsChartActive(false)
      setIsActive(false)
      setisTooltipActive(false)
    }

    const onCloseChart = () => {
      setIsChartActive(false)
    }

    // domain state
    const [domainPadding, setDomainPadding] = useState<DomainPaddingPropType>({
      x: [0, 0],
      y: [0, 0],
    })
    const [domainSize, setDomainSize] = useState(DOMAIN_DEFAULT_SIZE)
    const [domainShift, setDomainShift] = useState(DOMAIN_DEFAULT_SHIFT)
    const [domainXStart, setDomainXStart] = useState(DOMAIN_DEFAULT_X_START)
    const [domainXEnd, setDomainXEnd] = useState(DOMAIN_DEFAULT_X_END)
    const [domainXPaddingRatio, setDomainXPaddingRatio] = useState(DOMAIN_DEFAULT_X_PADDING_RATIO)
    const [domainXStartMin, setDomainXStartMin] = useState(
      DOMAIN_DEFAULT_X_START - DOMAIN_DEFAULT_X_PADDING_RATIO,
    )
    const [domainXStartMax, setDomainXStartMax] = useState(
      DOMAIN_DEFAULT_X_START + DOMAIN_DEFAULT_X_PADDING_RATIO,
    )
    const [domainYStart, setDomainYStart] = useState(DOMAIN_DEFAULT_Y_START)
    const [domainYEnd, setDomainYEnd] = useState(DOMAIN_DEFAULT_Y_END)
    const [domain, setDomain] = useState<ChartDomain>({
      x: [domainXStart, domainXEnd],
      y: [domainYStart, domainYEnd],
    })

    // other state
    const [dataHash, setDataHash] = useState<string>()
    const [domainHash, setDomainHash] = useState<string>()

    // refs
    const domainRef = useRef(domain)
    useEffect(() => {
      domainRef.current = domain
    })

    const domainShiftRef = useRef(domainShift)
    useEffect(() => {
      domainShiftRef.current = domainShift
    })

    const domainXStartMinRef = useRef(domainXStartMin)
    useEffect(() => {
      domainXStartMinRef.current = domainXStartMin
    })

    const domainXStartMaxRef = useRef(domainXStartMax)
    useEffect(() => {
      domainXStartMaxRef.current = domainXStartMax
    })

    // update domain on data change
    useEffect(() => {
      // use ref.current value to avoid stale state
      const domain = domainRef.current
      const propsDomain = props.domain

      const newDomain: ChartDomain = {
        x: [DOMAIN_DEFAULT_X_START, DOMAIN_DEFAULT_X_END],
        y: [DOMAIN_DEFAULT_Y_START, DOMAIN_DEFAULT_Y_END],
      }

      // generate a hash string from data's x values
      const newDataHash = data.reduce((acc, item) => `${acc}${item.x}`, '')
      // generate a hash string from domain's properties
      const newDomainHash = `${propsDomain.x?.start || '-'}${propsDomain.x?.end || '-'}${
        propsDomain.xPaddingRatio || '-'
      }${propsDomain.y?.start || '-'}${propsDomain.y?.start || '-'}${propsDomain.shift || '-'}${
        propsDomain.shift || '-'
      }`

      // domain x
      if (newDataHash === dataHash && newDomainHash === domainHash) {
        // skip domain x calculation (save domain x position)
        newDomain.x = domain.x
      } else {
        setDataHash(newDataHash)
        setDomainHash(newDomainHash)

        const newDomainSize =
          (isDateRange ? props.dataset[0]?.data?.length : props.domain?.size) || DOMAIN_DEFAULT_SIZE
        const newDomainShift = props.domain?.shift || DOMAIN_DEFAULT_SHIFT
        const newDomainXPaddingRatio =
          props.domain?.xPaddingRatio != undefined
            ? props.domain?.xPaddingRatio
            : DOMAIN_DEFAULT_X_PADDING_RATIO
        const newDomainXStartMin = DOMAIN_DEFAULT_X_START
        const newDomainXStartMax = DOMAIN_DEFAULT_X_START + (data.length - newDomainSize)
        const newDomainXStart = DOMAIN_DEFAULT_X_START
        const newDomainXEnd = DOMAIN_DEFAULT_X_START + (newDomainSize - DOMAIN_DEFAULT_X_START)
        setDomainSize(newDomainSize)
        setDomainShift(newDomainShift)
        setDomainXPaddingRatio(newDomainXPaddingRatio)
        setDomainXStartMin(newDomainXStartMin)
        setDomainXStartMax(newDomainXStartMax)
        setDomainXStart(newDomainXStart)
        setDomainXEnd(newDomainXEnd)
        newDomain.x = [
          newDomainXStart - newDomainXPaddingRatio,
          newDomainXEnd + newDomainXPaddingRatio,
        ]
      }

      // domain y
      const newDomainYStart =
        props.domain?.y?.start != undefined
          ? props.domain?.y?.start
          : Math.min(...data?.map?.((item) => item.y), DOMAIN_DEFAULT_Y_START)
      const newDomainYEnd =
        props.domain?.y?.end != undefined
          ? props.domain?.y?.end
          : Math.max(...data?.map?.((item) => item.y), DOMAIN_DEFAULT_Y_END)
      setDomainYStart(newDomainYStart)
      setDomainYEnd(newDomainYEnd)
      newDomain.y = [newDomainYStart, newDomainYEnd]

      setDomain(newDomain)
    }, [data, dataHash, domainHash, isDateRange, props.dataset, props.domain])

    const getCanShiftLeft = useCallback(() => {
      // use ref.current value to avoid stale state
      const domain = domainRef.current
      const domainCurrXStart = (domain.x[0] as number) + domainXPaddingRatio
      const domainXStartMin = domainXStartMinRef.current
      const domainShift = domainShiftRef.current

      let shift = 0
      if (domainCurrXStart === domainXStartMin) {
        shift = 0
      } else if (domainCurrXStart - domainShift >= domainXStartMin) {
        shift = domainShift
      } else if (domainCurrXStart - domainShift < domainXStartMin) {
        shift = domainCurrXStart - domainXStartMin
      }

      return shift > 0
    }, [domainXPaddingRatio])

    // domain left shifting
    const handleShiftLeft = useCallback(() => {
      // use ref.current value to avoid stale state
      const domain = domainRef.current
      const domainCurrXStart = (domain.x[0] as number) + domainXPaddingRatio
      const domainXStartMin = domainXStartMinRef.current
      const domainShift = domainShiftRef.current

      let shift = 0
      if (domainCurrXStart === domainXStartMin) {
        shift = 0
      } else if (domainCurrXStart - domainShift >= domainXStartMin) {
        shift = domainShift
      } else if (domainCurrXStart - domainShift < domainXStartMin) {
        shift = domainCurrXStart - domainXStartMin
      }
      const newDomainXStart = Math.round(domainCurrXStart - shift)
      const newDomainXEnd = Math.round(domainCurrXStart - shift + domainSize - domainXPaddingRatio)

      // update domain
      const newDomain: ChartDomain = {
        x: [newDomainXStart - domainXPaddingRatio, newDomainXEnd + domainXPaddingRatio],
        y: [...domain.y],
      }
      setDomain(newDomain)
    }, [domainSize, domainXPaddingRatio])

    const debouncedOnChangeCanShiftLeft = useMemo(
      () =>
        _debounce(
          () => onChangeCanShiftLeft(getCanShiftLeft()),
          500,
          {
            leading: true,
            trailing: true,
          },
          // eslint-disable-next-line
  ), [domainXPaddingRatio, onChangeCanShiftLeft]);

    const getCanShiftRight = useCallback(() => {
      // use ref.current value to avoid stale state
      const domain = domainRef.current
      const domainCurrXStart = (domain.x[0] as number) + domainXPaddingRatio
      const domainXStartMax = domainXStartMaxRef.current
      const domainShift = domainShiftRef.current

      let shift = 0
      if (domainCurrXStart === domainXStartMax) {
        shift = 0
      } else if (domainCurrXStart + domainShift <= domainXStartMax) {
        shift = domainShift
      } else if (domainCurrXStart + domainShift >= domainXStartMax) {
        shift = domainXStartMax - domainCurrXStart
      }

      return shift > 0
    }, [domainXPaddingRatio])

    // domain right shifting
    const handleShiftRight = useCallback(() => {
      // use ref.current value to avoid stale state
      const domain = domainRef.current
      const domainCurrXStart = (domain.x[0] as number) + domainXPaddingRatio
      const domainXStartMax = domainXStartMaxRef.current
      const domainShift = domainShiftRef.current

      let shift = 0
      if (domainCurrXStart === domainXStartMax) {
        shift = 0
      } else if (domainCurrXStart + domainShift <= domainXStartMax) {
        shift = domainShift
      } else if (domainCurrXStart + domainShift >= domainXStartMax) {
        shift = domainXStartMax - domainCurrXStart
      }
      const newDomainXStart = Math.round(domainCurrXStart + shift)
      const newDomainXEnd = Math.round(domainCurrXStart + shift + domainSize - domainXPaddingRatio)

      // update domain
      const newDomain: ChartDomain = {
        x: [newDomainXStart - domainXPaddingRatio, newDomainXEnd + domainXPaddingRatio],
        y: [...domain.y],
      }
      setDomain(newDomain)
    }, [domainSize, domainXPaddingRatio])

    const debouncedOnChangeCanShiftRight = useMemo(
      () =>
        _debounce(
          () => onChangeCanShiftRight(getCanShiftRight()),
          500,
          {
            leading: true,
            trailing: true,
          },
          // eslint-disable-next-line
  ), [domainXPaddingRatio, onChangeCanShiftRight]);

    // update shift availability on domain change
    useEffect(() => {
      debouncedOnChangeCanShiftLeft()
      debouncedOnChangeCanShiftRight()
    }, [debouncedOnChangeCanShiftLeft, debouncedOnChangeCanShiftRight, domain])

    // extend ref
    useImperativeHandle(
      ref,
      () => ({
        onShiftLeft: handleShiftLeft,
        onShiftRight: handleShiftRight,
      }),
      [handleShiftLeft, handleShiftRight],
    )

    // chart sizing
    const layoutRef = useRef<HTMLDivElement>(null)
    const [chartWidth, setChartWidth] = useState(
      width && width !== 'auto' ? width : DEFAULT_CHART_WIDTH,
    )
    const [chartHeight, setChartHeight] = useState(
      height && height !== 'auto' ? height : DEFAULT_CHART_HEIGHT,
    )

    // compute chart dimension on first render (only on first render for now)
    useLayoutEffect(() => {
      const resize = () => {
        if (layoutRef?.current) {
          if (width === 'auto') {
            setChartWidth(layoutRef?.current?.offsetWidth)
          } else if (width) {
            setChartWidth(width)
          }
          if (height === 'auto') {
            setChartHeight(layoutRef?.current?.offsetHeight)
          } else if (height) {
            setChartHeight(height)
          }
        }
      }
      const throttledResize = _throttle(resize, 500, { leading: true, trailing: true })

      // call on init
      throttledResize()

      // call on window resize
      window.addEventListener('resize', throttledResize)

      return () => window.removeEventListener('resize', resize)
    }, [height, width])

    const getInitialDomainPaddingX = useCallback(() => {
      return (
        ((chartWidth - (padding?.left || 0) - (padding?.right || 0)) / domainSize) *
        domainXPaddingRatio
      )
    }, [chartWidth, domainSize, domainXPaddingRatio, padding?.left, padding?.right])

    // compute domain padding on first render (only on first render for now)
    useEffect(() => {
      const newDomainPaddingX = getInitialDomainPaddingX()

      setDomainPadding({
        x: [newDomainPaddingX, newDomainPaddingX],
        y: [0, 0],
      })
    }, [getInitialDomainPaddingX])

    // sync domain on panning
    const handleZoom = (value: ChartDomain) => {
      // only apply domain.x on zoom to prevent bug where domain.y value is wrong (= previous data's value) on data change and zoom
      const newDomain: ChartDomain = {
        x: [...value.x],
        y: [...domain.y],
      }
      setDomain(newDomain)
    }

    const clipContainer = useMemo(() => {
      return (
        <VictoryClipContainer
          clipPadding={{
            top: 12,
            bottom: 12,
            left: 12,
            right: 12,
          }}
        />
      )
    }, [])

    const renderAllAxisX = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) {
          return null
        }

        // get all x axis in dataset
        const items = dataset.reduce((acc, item) => {
          const x = item?.axis?.x
          if (!x) return acc

          return acc.concat(Array.isArray(x) ? x : [x])
        }, [] as ChartPropsAxisX[])

        return items.map((item, index) => {
          return (
            <VictoryAxis
              key={`${name}-axis-x-${index}`}
              crossAxis={false}
              orientation={item?.orientation}
              tickCount={item?.tickCount}
              tickValues={item?.tickValues}
              tickFormat={item?.tickFormat}
              tickLabelComponent={data.length > 0 ? item?.tickLabelComponent : <></>}
              style={{ axis: { stroke: 'none' } }}
            />
          )
        })
      },
      [data.length, name],
    )

    const renderAllAxisY = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) {
          return null
        }

        // get all y axis in dataset
        const items = dataset.reduce((acc, item) => {
          const y = item?.axis?.y
          if (!y) {
            return acc
          }

          return acc.concat(Array.isArray(y) ? y : [y])
        }, [] as ChartPropsAxisY[])

        return items.map((item, index) => {
          return (
            <VictoryAxis
              key={`${name}-axis-y-${index}`}
              dependentAxis
              crossAxis={false}
              orientation={item?.orientation}
              style={item?.style}
              tickCount={item?.tickCount}
              tickValues={item?.tickValues}
              tickFormat={item?.tickFormat}
              tickLabelComponent={data.length > 0 ? item?.tickLabelComponent : <></>}
            />
          )
        })
      },
      [data.length, name],
    )

    const renderAllPolarAxisX = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null

        // get all x axis polar in dataset
        const items = dataset.reduce((acc, item) => {
          const x = item?.polarAxis?.x
          if (!x) return acc

          return acc.concat(Array.isArray(x) ? x : [x])
        }, [] as ChartPropsPolarAxisX[])

        return items.map((item, index) => {
          return (
            <VictoryPolarAxis
              key={`${name}-polar-axis-x-${index}`}
              invertAxis
              style={item?.style}
              labelPlacement={item?.labelPlacement}
              tickFormat={item?.tickFormat}
            />
          )
        })
      },
      [name],
    )

    const renderAllPolarAxisY = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null

        // get all y axis polar in dataset
        const items = dataset.reduce((acc, item) => {
          const y = item?.polarAxis?.y
          if (!y) return acc

          return acc.concat(Array.isArray(y) ? y : [y])
        }, [] as ChartPropsPolarAxisY[])

        return items.map((item, index) => {
          return (
            <VictoryPolarAxis
              key={`${name}-polar-axis-y-${index}`}
              dependentAxis
              style={item?.style}
              tickValues={item?.tickValues}
              tickFormat={item?.tickFormat}
            />
          )
        })
      },
      [name],
    )

    const renderAllBar = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) {
          return null
        }

        const barDataset = dataset.filter(
          (item) => item.type === 'BAR' || item.type === 'WORKLOAD_BAR',
        )
        if (!barDataset.length) return null

        const offset = barDataset.map((item) => item?.offset)[0]

        return (
          <VictoryGroup offset={offset}>
            {barDataset.map((item, index) => {
              return (
                <VictoryBar
                  name={`${name}-bar-${index}`}
                  key={`${name}-bar-${index}`}
                  data={item.data}
                  style={item.style}
                  events={item.events}
                  animate={item.animate}
                  y={item.yFormat}
                  cornerRadius={item.cornerRadius}
                  dataComponent={
                    <ChartCustomBar
                      onClick={onClickUserData}
                      onHover={smAndDown ? undefined : onHoverUserData}
                      onClose={smAndDown ? undefined : onCloseTooltip}
                      index={index}
                      type={item.type}
                      noneDataColorHover={item.noneDataColorHover}
                      colorHover={item.colorHover}
                      drawPrevision={item.drawPrevision}
                      noneDataColor={item.noneDataColor}
                    />
                  }
                />
              )
            })}
          </VictoryGroup>
        )
      },
      [name, smAndDown],
    )

    const renderAllLine = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null

        return dataset
          .filter((item) => item.type === 'LINE' || item.type === 'WORKLOAD_LINE')
          .map((item, index) => {
            // change null values to 0 to ensure line is not drawn continuously

            const sanitizedData = (item?.data || []).map((item) => {
              const sanitizedItem = { ...item }

              if (!sanitizedItem.y) {
                sanitizedItem.y = sanitizedItem.reasonForNoWorkout ? 0 : null
              }
              return sanitizedItem
            })

            // parse line props into scatter props
            const style = {
              data: {
                fill: item?.style?.data?.stroke,
                opacity: ({ datum }: any) => datum.opacity,
              },
            }
            const styleSize =
              (item?.style?.data?.strokeWidth as number) || chartTheme.line.style.data.strokeWidth
            const size = ({ active }: any) =>
              active
                ? item?.hoverScatterWidth ?? (styleSize + 10) / 2
                : item?.scatterWidth ?? (styleSize + 10) / 4
            const dotSize = dataset[0]?.period === 'WEEKLY' && smAndDown ? 8 : 3

            return (
              <VictoryGroup key={`${name}-line-group-${index}`} animate={item.animate}>
                <VictoryPortal>
                  {item.type === 'WORKLOAD_LINE' ? (
                    <VictoryScatter
                      name={`${name}-line-scatter-${index}`}
                      data={sanitizedData}
                      style={style}
                      y={item.yFormat}
                      dataComponent={
                        <ChartCustomScatter
                          onClick={onClickUserData}
                          onHover={smAndDown ? undefined : onHoverUserData}
                          onClose={smAndDown ? undefined : onCloseTooltip}
                          dotSize={dotSize}
                        />
                      }
                    />
                  ) : (
                    <VictoryScatter
                      name={`${name}-line-scatter-${index}`}
                      data={sanitizedData}
                      style={style}
                      size={size}
                      y={item.yFormat}
                    />
                  )}
                </VictoryPortal>

                <VictoryLine
                  name={`${name}-line-${index}`}
                  data={sanitizedData}
                  style={item.style}
                  events={item.events}
                  y={item.yFormat}
                  groupComponent={clipContainer}
                />
              </VictoryGroup>
            )
          })
      },
      [name, clipContainer, smAndDown],
    )

    const parseToHistoryData = (
      index: number,
      prevIndex: number,
      prevValue: any,
      nextIndex: number,
      nextValue: any,
    ) => {
      const parsedValue =
        prevValue + ((nextValue - prevValue) / (nextIndex - prevIndex)) * (index - prevIndex)

      return parsedValue
    }

    const renderHistoryLine = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset || !hasHistoryLine) return null

        return dataset
          .filter((item) => item.type === 'LINE' || item.type === 'WORKLOAD_LINE')
          .map((item, index) => {
            const isSingleItem =
              (item?.data || []).filter((item) => item.y != undefined).length === 1

            if (isSingleItem) return null

            const indexMap = [-1, -1]

            item?.data?.forEach((value, index) => {
              if (typeof value.y !== 'number') return

              if (indexMap[0] === -1) {
                indexMap[0] = index
              }

              if (indexMap[0] < index) {
                indexMap[1] = index
              }
            })

            const firstIndex = Math.min(...indexMap)
            const lastIndex = Math.max(...indexMap)

            const sanitizedData = (item?.data || []).map((item) => {
              const sanitizedItem = { ...item }

              if (sanitizedItem.y === null) sanitizedItem.y = null

              return sanitizedItem
            })

            const historyData: ChartParsedDataItem[] = (item?.data || [])
              .map((value, index) => {
                const lineData = item?.data || []

                const prevData = index !== firstIndex ? lineData[index - 1] : { y: 0 }
                const nextData = index !== lastIndex ? lineData[index + 1] : { y: 0 }

                if (prevData?.y === null || nextData?.y === null) return value

                return {
                  ...value,
                  y: null,
                }
              })
              .map((value, index) => {
                const originLineData = item?.data || []

                if (index < firstIndex || index > lastIndex) return value

                const historyItem = { ...value }

                historyItem.y = historyItem.y ?? null

                if (originLineData[index].y !== null && historyItem.y === null) return value

                if (historyItem.y === null && item.data) {
                  const nextIndex = item.data.findIndex(
                    (value, idx) => index < idx && typeof value.y === 'number',
                  )
                  const nextValue = nextIndex !== -1 ? item.data[nextIndex].y : null

                  const prevIndex = [-1]

                  for (let i = 0; i < item.data.length; i++) {
                    if (index <= i) break

                    if (typeof item.data[i].y === 'number') {
                      prevIndex[0] = i
                    }
                  }

                  const prevValue = prevIndex[0] !== -1 ? item.data[prevIndex[0]].y : null

                  historyItem.y =
                    nextValue || prevValue
                      ? parseToHistoryData(index, prevIndex[0], prevValue, nextIndex, nextValue)
                      : null
                }

                return historyItem
              })
              .reduce<ChartParsedDataItem[]>((acc, cur, index, historyData) => {
                acc.push(cur)

                const originLineData = item?.data || []
                const nextItem = historyData[index + 1]

                if (
                  cur.y === null ||
                  originLineData[index + 1]?.y === null ||
                  originLineData[index].y === null ||
                  index === 0 ||
                  !nextItem ||
                  nextItem.y === null
                )
                  return acc

                if (!moment(nextItem?.x).isValid()) return acc
                acc.push({
                  ...cur,
                  x: moment(nextItem.x).format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
                  y: null,
                })

                return acc
              }, [])

            // parse line props into scatter props
            const style = {
              data: {
                fill: item?.style?.data?.stroke,
                opacity: ({ datum }: any) => datum.opacity,
              },
            }
            const styleSize =
              (item?.style?.data?.strokeWidth as number) || chartTheme.line.style.data.strokeWidth
            const size = ({ active }: any) =>
              active
                ? item?.hoverScatterWidth ?? (styleSize + 10) / 2
                : item?.scatterWidth ?? (styleSize + 10) / 4

            return (
              <VictoryGroup key={`${name}-line-group-${index}`} animate={item.animate}>
                <VictoryPortal>
                  <VictoryScatter
                    name={`${name}-line-scatter-${index}`}
                    data={sanitizedData}
                    style={style}
                    size={size}
                    y={item.yFormat}
                  />
                </VictoryPortal>
                <VictoryLine
                  interpolation={'linear'}
                  name={`${name}-history-line-${index}`}
                  data={historyData}
                  style={{
                    data: {
                      opacity: 0.8,
                      stroke: '#ffa066',
                      strokeDasharray: '4,4',
                    },
                  }}
                  events={item.events}
                  y={item.yFormat}
                  groupComponent={clipContainer}
                />
              </VictoryGroup>
            )
          })
      },
      [hasHistoryLine, name, clipContainer, moment],
    )

    const renderAllArea = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null

        return dataset
          .filter((item) => item.type === 'AREA')
          .map((item, index) => {
            // parse area props into scatter props
            const style = {
              data: {
                fill: item?.style?.data?.stroke,
              },
            }
            const styleSize =
              (item?.style?.data?.strokeWidth as number) || chartTheme.line.style.data.strokeWidth
            const size = ({ active }: any) => (active ? (styleSize + 10) / 2 : 0)

            return (
              <VictoryGroup key={`${name}-area-group-${index}`}>
                <VictoryPortal>
                  <VictoryScatter
                    name={`${name}-area-scatter-${index}`}
                    data={item.data}
                    style={style}
                    size={size}
                    y={item.yFormat}
                  />
                </VictoryPortal>
                <VictoryArea
                  name={`${name}-area-${index}`}
                  data={item.data}
                  style={item.style}
                  events={item.events}
                  animate={item.animate}
                  groupComponent={clipContainer}
                />
              </VictoryGroup>
            )
          })
      },
      [clipContainer, name],
    )

    const renderAllPastPrevisionLine = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null
        if (hasHistoryLine) return null

        return dataset
          .filter((item) => item.type === 'LINE' || item.type === 'WORKLOAD_LINE')
          .map((item, index) => {
            if (!item.drawPrevision) return null

            const todayOrBeforeData = (item.data || []).filter((item) =>
              moment(item?.x).isSameOrBefore(moment(), 'day'),
            )

            // parse line props into scatter props
            const style = {
              data: {
                fill: item?.style?.data?.stroke,
                opacity: ({ datum }: any) => datum.opacity,
              },
            }
            const styleSize =
              (item?.style?.data?.strokeWidth as number) || chartTheme.line.style.data.strokeWidth
            const size = ({ active }: any) => (active ? (styleSize + 10) / 2 : 0)
            const dotSize = dataset[0]?.period === 'WEEKLY' && smAndDown ? 8 : 3

            return (
              <VictoryGroup
                key={`${name}-past-prevision-line-group-${index}`}
                animate={item.animate}
              >
                <VictoryPortal>
                  {item.type === 'WORKLOAD_LINE' ? (
                    <VictoryScatter
                      name={`${name}-past-prevision-line-scatter-${index}`}
                      data={todayOrBeforeData}
                      style={style}
                      // size={size}
                      y={item.yFormat}
                      dataComponent={
                        <ChartCustomScatter
                          onClick={onClickUserData}
                          onHover={smAndDown ? undefined : onHoverUserData}
                          onClose={smAndDown ? undefined : onCloseTooltip}
                          dotSize={dotSize}
                        />
                      }
                    />
                  ) : (
                    <VictoryScatter
                      name={`${name}-past-prevision-line-scatter-${index}`}
                      data={todayOrBeforeData}
                      style={style}
                      size={size}
                      y={item.yFormat}
                    />
                  )}
                </VictoryPortal>
                <VictoryLine
                  name={`${name}-past-prevision-line-${index}`}
                  data={todayOrBeforeData}
                  style={{
                    data: {
                      stroke: item?.style?.data?.stroke,
                      opacity: 0.8,
                      strokeDasharray: '4,4',
                    },
                  }}
                  y={item.yFormat}
                />
              </VictoryGroup>
            )
          })
      },
      [hasHistoryLine, moment, name, smAndDown],
    )

    const renderAllFuturePrevisionLine = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) {
          return null
        }

        if (hasHistoryLine) {
          return null
        }

        return dataset
          .filter((item) => item.type === 'LINE' || item.type === 'WORKLOAD_LINE')
          .map((item, index) => {
            if (!item.drawPrevision) return null

            const todayOrAfterData = (item.data || []).filter((item) =>
              moment(item?.x).isSameOrAfter(moment(), 'day'),
            )

            return (
              <VictoryLine
                key={`${name}-future-prevision-line-${index}`}
                name={`${name}-future-prevision-line--${index}`}
                data={todayOrAfterData}
                style={{
                  data: {
                    stroke: theme.colors['grey-200'],
                    strokeDasharray: '4,4',
                  },
                }}
                animate={item.animate}
                y={item.yFormat}
              />
            )
          })
      },
      [hasHistoryLine, moment, name],
    )

    const renderAllDottedLine = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null

        const chartDomainX = getInitialDomainPaddingX()
        const lineDomainPadding: DomainPaddingPropType = {
          x: [-chartDomainX, -chartDomainX],
        }

        const dottedLineDataset = dataset.filter(
          (item) => item.type === 'DOTTED_LINE' && item.data?.find((data) => data.y),
        )

        if (dottedLineDataset.length === 0) {
          return null
        }

        return dottedLineDataset.map((item, index) => {
          const data = [...(item.data || [])]
          for (let i = item.data?.length || 0; i <= domainSize; i += 1) {
            data.push({ x: i, y: data[i - 1]?.y, y0: data[i - 1]?.y0 })
          }

          return (
            <VictoryLine
              key={`${name}-dotted-line-${index}`}
              data={data}
              style={item.style}
              events={item.events}
              animate={item.animate}
              labelComponent={item.label?.labelComponent}
              y={item.yFormat}
              domainPadding={lineDomainPadding}
              maxDomain={{ y: 2.5 }}
            />
          )
        })
      },
      [domainSize, getInitialDomainPaddingX, name],
    )

    const renderAllLineScatter = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset || hasHistoryLine) return null

        return dataset
          .filter((item) => item.type === 'LINE' || item.type === 'WORKLOAD_LINE')
          .map((item, index) => {
            const isSingleItem =
              (item?.data || []).filter((item) => item.y != undefined).length === 1

            if (isSingleItem) {
              // parse line props into scatter props
              const style = {
                data: {
                  fill: item?.style?.data?.stroke,
                },
              }
              const styleSize =
                (item?.style?.data?.strokeWidth as number) || chartTheme.line.style.data.strokeWidth
              const size = (styleSize + 10) / 2
              const dotSize = dataset[0]?.period === 'WEEKLY' && smAndDown ? 8 : 3

              // if data contains excatly 1 valid data item, returns a scatter (point)

              if (item.type === 'WORKLOAD_LINE') {
                return (
                  <VictoryScatter
                    key={`${name}-line-scatter-${index}`}
                    data={item.data}
                    style={style}
                    size={size}
                    events={item.events}
                    animate={item.animate}
                    y={item.yFormat}
                    dataComponent={
                      <ChartCustomScatter
                        onClick={onClickUserData}
                        onHover={smAndDown ? undefined : onHoverUserData}
                        onClose={smAndDown ? undefined : onCloseTooltip}
                        dotSize={dotSize}
                      />
                    }
                  />
                )
              } else {
                return (
                  <VictoryScatter
                    key={`${name}-line-scatter-${index}`}
                    data={item.data}
                    style={style}
                    size={size}
                    events={item.events}
                    animate={item.animate}
                    y={item.yFormat}
                  />
                )
              }
            } else {
              // if data contains no or many valid data items, returns nothing
              return null
            }
          })
      },
      [name, hasHistoryLine, smAndDown],
    )

    const renderAllPolar = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null

        const polarDataset = dataset
          .filter((item) => item.type === 'POLAR')
          .map((item) => {
            const { polarYFormat } = item

            const data = item.data?.map((data) => {
              return Object.keys(data).map((key) => {
                const keyYFormat = polarYFormat?.[key]

                return {
                  x: key,
                  y: keyYFormat ? keyYFormat({ y: data[key] }) : data[key],
                }
              })
            })

            return {
              data,
              style: item.style,
              polarYFormat: item.polarYFormat,
              events: item.events,
              animate: item.animate,
            }
          })

        return polarDataset.map((datasetItem, datasetIndex) => {
          return datasetItem.data?.map((dataItem, dataIndex) => {
            return (
              <VictoryArea
                key={`${name}-polar-${datasetIndex}-${dataIndex}`}
                data={dataItem}
                style={datasetItem.style}
                events={datasetItem.events}
                animate={datasetItem.animate}
              />
            )
          })
        })
      },
      [name],
    )

    const renderAllStack = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null

        const isWorkloadChart =
          name === 'CHART_MONITORING' || name.includes('WORKLOAD') || name.includes('DURATION')

        const stackDataset = dataset
          .filter((item) => item.type === 'STACK' || item.type === 'WORKLOAD_STACK')
          .map((item) => {
            const data: any[] = []

            const injuryStackChartDataParsing = () => {
              const EMPTY_INJURY_NUMBER = 5

              return Object.values(item.data?.[0]?.y).length || EMPTY_INJURY_NUMBER
            }

            const workloadStackChartDataParsing = () => {
              const { data } = dataset[0]
              const workloadDataMaxLength =
                data?.map((dataValue) => Object.keys(dataValue.y).length) || []

              return Math.max(...workloadDataMaxLength)
            }

            const stackLength = isWorkloadChart
              ? workloadStackChartDataParsing()
              : injuryStackChartDataParsing()

            for (let i = 0; i < stackLength; i++) {
              data.push(
                item.data?.map((data) => {
                  return { x: data.x, y: Object.values(data.y || {})[i] || 0 }
                }),
              )
            }

            return {
              item,
              data,
              type: item.type,
              colorScale: item.colorScale,
              colorScaleHover: item.colorScaleHover,
              style: item.style,
              animate: item.animate,
              drawPrevision: item.drawPrevision,
            }
          })

        return stackDataset.map((datasetItem, datasetIndex) => {
          return (
            <VictoryStack
              key={`stack-${datasetIndex}`}
              colorScale={datasetItem.colorScale}
              animate={datasetItem.animate}
            >
              {datasetItem.data.map((item, itemIndex) => {
                return (
                  <VictoryBar
                    key={`${name}-stack-${datasetIndex}-${itemIndex}`}
                    name={`color-hover-${itemIndex + 1}`}
                    data={item}
                    style={datasetItem.style}
                    cornerRadius={datasetItem.item.cornerRadius}
                    dataComponent={
                      <ChartCustomStack
                        onClick={onClickUserData}
                        onHover={smAndDown ? undefined : onHoverUserData}
                        onCloseChart={onCloseChart}
                        index={itemIndex}
                        type={datasetItem.type}
                        isWorkloadChart={isWorkloadChart}
                        color={datasetItem?.colorScale?.[itemIndex]}
                        colorHover={
                          datasetItem?.colorScaleHover?.[
                            isWorkloadChart ? itemIndex % 4 : itemIndex
                          ]
                        }
                        drawPrevision={datasetItem?.drawPrevision}
                        data={datasetItem.item.data}
                        noneDataColorHover={datasetItem.item.noneDataColorHover}
                        noneDataColor={datasetItem.item.noneDataColorHover}
                      />
                    }
                  />
                )
              })}
            </VictoryStack>
          )
        })
      },
      [name, smAndDown],
    )

    const renderAllBackgroundArea = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset) return null

        const areaDataset = dataset.filter((item) => item.type === 'BACKGROUND_AREA')

        const chartDomainX = getInitialDomainPaddingX()
        const areaDomainPadding: DomainPaddingPropType = {
          x: [-chartDomainX, 0],
        }

        if (!areaDataset.length) return null

        const xEnd = typeof domain.x[1] === 'number' ? domain.x[1] : domainXEnd

        return (
          // VictoryArea domain padding not working, so add group wrapper
          <VictoryGroup domainPadding={areaDomainPadding}>
            {areaDataset.map((datasetItem, datasetIndex) => {
              const data = [...(datasetItem.data || [])]
              for (
                let i = datasetItem.data?.length || 0;
                i <= Math.ceil(Math.max(domainSize, xEnd));
                i += 1
              ) {
                data.push({ y: data[i - 1]?.y, y0: data[i - 1]?.y0 })
              }

              return (
                <VictoryArea
                  key={`area-${datasetIndex}`}
                  data={data}
                  style={datasetItem.style}
                  animate={datasetItem.animate}
                  y={datasetItem.yFormat}
                  labelComponent={datasetItem?.label?.labelComponent}
                />
              )
            })}
          </VictoryGroup>
        )
      },
      [domain.x, domainSize, domainXEnd, getInitialDomainPaddingX],
    )

    const renderLabels = useCallback(() => {
      if (!label) return undefined

      return _isEmpty(label) ? undefined : ({ datum }: any) => datum.y
    }, [label])

    const renderLabelComponent = useCallback(() => {
      if (!label) return undefined

      return label?.labelComponent || undefined
    }, [label])

    const renderLegend = useCallback(
      (dataset?: ChartPropsDatasetItem[]) => {
        if (!dataset || !dataset.find((item) => item.legend)) return

        return <ChartLegend padding={legendPadding} dataset={dataset} />
      },
      [legendPadding],
    )

    // disable pan if data is smaller than domain
    const isPanAllowed = data.length > domainSize

    // 바텀 시트 운동부하 선수 데이터
    const bottomSheetDataset = useMemo(() => {
      if (clickeIndex === undefined || !dataset[0]?.data || !dataset[0].period) return

      return {
        currentUser: dataset[0].data[clickeIndex],
        workloadList: dataset[0].data[clickeIndex].workloadList as SportsRawWorkload[],
        period: dataset[0].period,
        chipType: dataset[0].chipType,
        dataType: dataset[0].dataType,
        chartType: dataset[0].type,
        colorList: dataset[0].colorScale,
        nullDataKey: dataset[0].nullDataKey,
      }
    }, [dataset, clickeIndex])

    const showBottomSheet = smAndDown && bottomSheetDataset && isActive

    const onCloseBottomSheet = () => {
      setTimeout(() => {
        setIsActive(false)
      }, 500)
    }

    // 툴팁 운동부하 선수 데이터
    const tooltipDataset = useMemo(() => {
      if (
        hoverIndex === undefined ||
        !dataset[0]?.data ||
        !dataset[0].period ||
        !tooltipDatum ||
        !tooltipScale
      )
        return

      return {
        datum: tooltipDatum,
        scale: tooltipScale,
        period: dataset[0].period,
        items: [
          {
            chartType: dataset[0].type,
            chipType: dataset[0].chipType,
            colorList: dataset[0].colorScale,
            dataType: dataset[0].dataType,
            nullDataKey: dataset[0].nullDataKey,
            data: dataset[0].data[hoverIndex] as ChartParsedDataItem,
          },
        ],
      }
    }, [dataset, hoverIndex, tooltipDatum, tooltipScale])

    const showTooltip = tooltipDataset && (isChartActive || isTooltipActive)

    const chartMouseHoverEvent = (event: any) => {
      if (!isChartActive) return

      const x = query?.playerId ? event.pageX - window.innerWidth / 2 : event.pageX
      const xAdjusted =
        window.innerWidth - event.pageX <= DEFAULT_TOOLTIP_WIDTH ? x - DEFAULT_TOOLTIP_WIDTH : x
      const y = query?.playerId || query.reportId ? event.clientY : event.pageY

      setXY({ x: xAdjusted, y })
    }

    const onToggleTooltipActive = (isActive: boolean) => {
      setisTooltipActive(isActive)
    }

    useEffect(() => {
      if (!isTooltipActive && !isChartActive) {
        onCloseTooltip()
      }
    }, [isTooltipActive, isChartActive])

    const chartThemeDeleteAreaStroke: any = { ...chartTheme }

    const areaChartStrokeData = useCallback(() => {
      if (query?.dataType === '') {
        chartThemeDeleteAreaStroke.area.style.data.stroke = 'none'
      } else {
        delete chartThemeDeleteAreaStroke.area.style.data.stroke
      }
    }, [chartThemeDeleteAreaStroke.area.style.data, query?.dataType])

    useEffect(() => {
      areaChartStrokeData()
    }, [query, areaChartStrokeData])

    return (
      <Box ref={layoutRef} {...containerProps} onMouseMove={(e) => chartMouseHoverEvent(e)}>
        <VictoryChart
          polar={isPolar}
          width={chartWidth}
          height={chartHeight}
          padding={padding}
          theme={chartThemeDeleteAreaStroke}
          domain={isDateRange ? undefined : !isPolar ? domain : undefined}
          domainPadding={isPolar ? undefined : domainPadding}
          {...(isPolar ? { startAngle: 90, endAngle: 450 } : {})}
          containerComponent={
            <VictoryZoomVoronoiContainer
              // zoom props
              responsive={false}
              allowPan={isPanAllowed}
              allowZoom={false}
              zoomDimension={'x'}
              zoomDomain={!(isPolar || isStack) ? domain : undefined}
              onZoomDomainChange={handleZoom}
              clipContainerComponent={clipContainer}
              // voronoi props
              voronoiDimension={'x'}
              labels={renderLabels()}
              labelComponent={renderLabelComponent()}
            />
          }
        >
          {renderAllAxisX(dataset)}
          {renderAllAxisY(dataset)}
          {renderAllPolarAxisX(dataset)}
          {renderAllPolarAxisY(dataset)}
          {renderAllBackgroundArea(dataset)}
          {renderAllBar(dataset)}
          {renderAllLine(dataset)}
          {renderHistoryLine(dataset)}
          {renderAllArea(dataset)}
          {renderAllPastPrevisionLine(dataset)}
          {renderAllFuturePrevisionLine(dataset)}
          {renderAllDottedLine(dataset)}
          {renderAllLineScatter(dataset)}
          {renderAllPolar(dataset)}
          {renderAllStack(dataset)}
        </VictoryChart>
        {renderLegend(dataset)}

        {showBottomSheet && (
          <BottomSheetWorkloadHistory
            userDataset={bottomSheetDataset}
            onClose={onCloseBottomSheet}
          />
        )}

        {showTooltip && (
          <ChartTooltipWorkloadHistory
            {...tooltipDataset}
            onToggleTooltipActive={onToggleTooltipActive}
            x={xy.x}
            y={xy.y}
          />
        )}
      </Box>
    )
  }),
)
