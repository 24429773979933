import { Card, Divider, Flex, Text, TextProps } from '@plco-pro/components/atoms'
import { InjuryHistoryCardTitle } from '@plco-pro/components/molecules/injury-history-card-title'
import { Contact, SportsUserSorenessRecentDataValue } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { getScreenSize } from '@plco-pro/providers/responsive'

const TableDivider = () => <Divider sx={{ my: '12px' }} />

interface HeaderTextProps extends TextProps {
  mdAndDown?: boolean
}

const TableHeaderText = (props: HeaderTextProps) => {
  const { mdAndDown } = props

  return (
    <Text
      variant={'s2'}
      sx={{
        width: mdAndDown ? '64px' : '98px',
        minWidth: mdAndDown ? '64px' : '98px',
        marginRight: '20px',
        alignSelf: 'flex-start',
        fontWeight: 700,
      }}
      {...props}
    />
  )
}

const TableContentsText = (props: TextProps) => {
  return <Text sx={{ fontWeight: 500 }} variant={'s2'} {...props} />
}

type Props = {
  injuryData: SportsUserSorenessRecentDataValue
}

export const CardPlayerInjuryHistory = ({ injuryData }: Props) => {
  const { formatMessage } = useI18n()
  const { mdAndDown } = getScreenSize(false)

  const getContactTypeText = (isContact?: Contact) => {
    if (isContact === Contact.CONTACT) {
      return formatMessage({ id: 'CONTACT' })
    }

    if (isContact === Contact.NON_CONTACT) {
      return formatMessage({ id: 'NON_CONTACT' })
    }

    return '-'
  }

  return (
    <Card
      sx={{
        width: '100%',
        padding: '20px',
        mb: '16px',
        mx: 'auto',
      }}
    >
      <InjuryHistoryCardTitle soreness={injuryData.level} time={injuryData.time} />

      <Flex sx={{ alignItems: 'center', width: '100%', mt: '24px' }}>
        <TableHeaderText mdAndDown={mdAndDown}>
          {formatMessage({ id: 'SORENESS_TYPE' })}
        </TableHeaderText>

        <TableContentsText>{getContactTypeText(injuryData.isContact as Contact)}</TableContentsText>
      </Flex>

      <TableDivider />

      <Flex sx={{ width: '100%' }}>
        <TableHeaderText mdAndDown={mdAndDown}>
          {formatMessage({ id: 'SORENESS_SITUATION' })}
        </TableHeaderText>

        <TableContentsText sx={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
          {injuryData.memo}
        </TableContentsText>
      </Flex>
    </Card>
  )
}
