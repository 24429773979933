import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { useRouter } from 'next/router'

import { Box, Button, Card, Flex, GhostCard, Spinner, Text } from '@plco-pro/components/atoms'
import { Feedback, useFeedbackListQuery } from '@plco-pro/graphqls/react.generated'
import { useI18n, useNationality, useSetRef, useViewer } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useNavigation } from '@plco-pro/hooks/navigation'
import { AnalyticsEventType } from '@plco-pro/utils/libs'

import { DialogFeedback } from '../dialog-feedback/dialog-feedback'
import { DialogFeedbackDetail } from '../dialog-feedback/dialog-feedback-detail'
import { PlayerSelectedType } from '../dialog-player-select'
import { FeedbackCard } from './components'

interface CardPlayerFeedbackProps {
  player?: PlayerSelectedType
}

// 선수단 > 피드백
export const CardPlayerFeedback = ({ player }: CardPlayerFeedbackProps) => {
  const { query } = useRouter()
  const { formatMessage: f } = useI18n()
  const { isObserver } = useNavigation()
  const { currentActiveTeam } = useViewer()
  const nationality = useNationality()
  const { trackEvent } = useAnalytics()

  const [isOpenFeedbackDialog, setFeedbackDialog] = useState(false)
  const [isOpenFeedbackDetail, setIsOpenFeedbackDetail] = useState(false)
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback>()
  const { ref, inView } = useInView()
  const [containerRef, setContainerRef] = useSetRef()

  const { data, loading, fetchMore } = useFeedbackListQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !currentActiveTeam?.id || !player?.id,
    variables: {
      teamId: currentActiveTeam?.id || '',
      first: 20,
      userId: player?.id || '',
      multilingualTextInput: {
        nationality,
      },
    },
  })

  const hasNextPage = data?.sports.user?.feedbackConnection.pageInfo.hasNextPage

  const fetchFeedbackList = useCallback(() => {
    const cursor = data?.sports.user?.feedbackConnection.pageInfo.endCursor

    fetchMore({
      variables: {
        after: cursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.sports.user) {
          return prev
        }

        const endCursor = prev.sports.user?.feedbackConnection.pageInfo.endCursor
        const nextCursor = fetchMoreResult.sports.user?.feedbackConnection.pageInfo.endCursor

        if (endCursor === nextCursor) {
          return prev
        }

        const prevEdges = prev.sports.user?.feedbackConnection.edges
        const nextEdges = fetchMoreResult.sports.user?.feedbackConnection.edges

        fetchMoreResult.sports.user.feedbackConnection.edges = [...(prevEdges || []), ...nextEdges]

        return { ...fetchMoreResult }
      },
    })
  }, [data, fetchMore])

  /**
   * 피드백 등록 후 컨테이너의 스크롤을 최상단으로 올린다.
   */
  const onScrollReset = () => {
    if (containerRef) {
      containerRef.scrollTop = 0
    }
  }

  const feedbackList = useMemo(() => {
    return data?.sports.user?.feedbackConnection.edges || []
  }, [data])

  const hasFeedbackData = useMemo(() => {
    return !!data?.sports.user?.feedbackConnection.edges.length
  }, [data])

  // `OBSERVER` 권한일 경우
  const observerView = useMemo(() => {
    return (
      <Flex
        sx={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Text variant="h1">📨</Text>

          {/* 아직 피드백이 없어요 */}
          <Text
            variant="s2"
            sx={{ color: 'text-secondary', whiteSpace: 'pre-line', textAlign: 'center' }}
          >
            {f({ id: 'feedback.org-license-description' })}
          </Text>
        </Flex>
      </Flex>
    )
  }, [f])

  const onFeedbackDialogToggle = useCallback(() => {
    if (!isOpenFeedbackDialog) {
      trackEvent(AnalyticsEventType.REGISTER_FEEDBACK_CLICKED, { conversion: 'squad' })
    }

    setFeedbackDialog(!isOpenFeedbackDialog)
  }, [isOpenFeedbackDialog, trackEvent])

  const noFeedbackView = useMemo(() => {
    return (
      <Flex
        sx={{
          flex: 1,
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          position: 'absolute',
          width: '100%',
          height: ['100%'],
        }}
      >
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Text variant="h1">📨</Text>

          {/* 아직 피드백이 없어요 */}
          <Text variant="s2" sx={{ color: 'text-secondary' }}>
            {f({ id: 'feedback.no-feedback-yet' })}
          </Text>
        </Flex>

        {/* 피드백 등록 */}
        <Button variant="secondary" color="grey" onClick={onFeedbackDialogToggle}>
          {f({ id: 'feedback.send-feedback' })}
        </Button>
      </Flex>
    )
  }, [f, onFeedbackDialogToggle])

  const feedbackListView = useMemo(() => {
    const onClickFeedback = (feedback: Feedback) => {
      setSelectedFeedback(feedback)
      setIsOpenFeedbackDetail(true)
    }

    return (
      <Box ref={setContainerRef} sx={{ overflow: 'auto' }}>
        {feedbackList.map((feedback) => (
          <FeedbackCard
            key={feedback.node.id}
            feedbackData={feedback.node as Feedback}
            onClick={() => onClickFeedback(feedback.node as Feedback)}
          />
        ))}

        {loading && (
          <Flex
            sx={{
              my: 4,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner size={'md'} />
          </Flex>
        )}

        <Box ref={ref} />
      </Box>
    )
  }, [setContainerRef, feedbackList, loading, ref])

  const contents = useMemo(() => {
    if (isObserver) {
      return observerView
    }

    if (hasFeedbackData) {
      return feedbackListView
    }

    return noFeedbackView
  }, [feedbackListView, hasFeedbackData, isObserver, noFeedbackView, observerView])

  useEffect(() => {
    if (!inView || !hasNextPage) {
      return
    }

    fetchFeedbackList()
  }, [fetchFeedbackList, hasNextPage, inView])

  /**
   * [문제]
   * 선수가 변경해도 피드백 카드 컴포넌트의 내부의 스크롤이 유지되는 현상이 발생
   *
   * [해결]
   * 쿼리 파라미터(선수 ID)가 변경될 때 컨테이너의 Ref를 이용하여 스크롤을 최상단으로 이동
   */
  useEffect(() => {
    if (containerRef) {
      containerRef.scrollTop = 0
    }
  }, [containerRef, query.id])

  // 로딩 중일 때 고스트 UI
  if (!data) {
    return (
      <GhostCard sx={{ width: '100%', height: '100%' }}>
        <Flex sx={{ flexDirection: 'column', height: '100%' }}>
          <Text variant={['s1', 'h4']} sx={{ fontWeight: 700, mx: 3, mt: 3 }}>
            {f({ id: 'feedback' })}
          </Text>
        </Flex>
      </GhostCard>
    )
  }

  return (
    <Card sx={{ width: '100%', height: ['480px', '100%'], position: 'relative' }}>
      <Flex sx={{ flexDirection: 'column', height: '100%' }}>
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mx: 3, my: 3, gap: 1 }}>
          <Text ellipsis={true} variant={['s1', 'h4']} sx={{ fontWeight: 700, flex: 1 }}>
            {f({ id: 'feedback' })}
          </Text>

          {hasFeedbackData && (
            <Button
              variant="secondary"
              color="grey"
              onClick={() => {
                onFeedbackDialogToggle()
              }}
            >
              {f({ id: 'feedback.send-feedback' })}
            </Button>
          )}
        </Flex>

        {contents}

        {isOpenFeedbackDialog && (
          <DialogFeedback
            isOpen={isOpenFeedbackDialog}
            onClose={onFeedbackDialogToggle}
            entry={'squad'}
            player={player}
            onCompleted={onScrollReset}
          />
        )}

        {selectedFeedback && isOpenFeedbackDetail && (
          <DialogFeedbackDetail
            entry={'squad'}
            isOpen={isOpenFeedbackDetail}
            onClose={() => setIsOpenFeedbackDetail(false)}
            feedbackId={selectedFeedback.id}
          />
        )}
      </Flex>
    </Card>
  )
}
