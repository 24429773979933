import { SportsTeamDataName } from '@plco-pro/graphqls/react.generated'
import { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import { ChartSystemMapValue, ChipType } from '@plco-pro/maps/chart-system/chart-system.types'
import { DATASET_LABEL_INJURY } from '@plco-pro/maps/chart-system/dataset-label'
import { DATASET_LEGENDS_INJURY } from '@plco-pro/maps/chart-system/dataset-legend'
import theme from '@plco-pro/themes/main'

export const CHART_SYSTEM_TEAM_CHART_MAP_KEY = [
  // teamChart/condition
  'TEAM_AVG:CONDITION:1_WEEKS:DAILY:AREA',
  'TEAM_AVG:CONDITION:LINE',

  // teamChart/workload
  'TEAM_AVG:WORKLOAD:1_WEEKS:DAILY:BAR',
  'TEAM_AVG:WORKLOAD_INTENSITY:BAR_LINE',

  // teamChart/acwr
  'TEAM_AVG:WORKLOAD_ACXR:1_WEEKS:DAILY:AREA',

  // teamChart/injury
  'TEAM_AVG:INJURY:STACK',
] as const

export const chartSystemTeamChartMap: Record<
  (typeof CHART_SYSTEM_TEAM_CHART_MAP_KEY)[number],
  ChartSystemMapValue
> = {
  'TEAM_AVG:CONDITION:1_WEEKS:DAILY:AREA': {
    query: {
      name: 'TEAM_AVG_DATA',
      variables: {
        names: [SportsTeamDataName.TEAM_CONDITION_AVG],
        period: 'LAST_7_DAYS',
      },
      context: {
        omitBatch: true,
      },
    },
    parser: 'TEAM_AVG_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'AREA',
        color: 'deep-purple-500',
        colorHover: 'deep-purple-500',
        animate: 'FAST_CUBIC_IN_OUT',
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'condition',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 7,
              tickFormat: 'NULL_STRING',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              style: 'AXIS_STYLE_INVISIBLE',
              tickFormat: 'NULL_STRING',
            },
          ],
        },
      },
    ],
    domain: {
      size: 7,
      y: {
        start: 0,
        end: 10,
      },
      xPaddingRatio: 0,
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'AREA',
            dataType: MonitoringDataType.CONDITION,
            color: theme.colors['deep-purple-500'],
            chipType: ChipType.TEN,
            interpretPath: 'CONDITION',
          },
        ],
      },
    },
  },
  'TEAM_AVG:CONDITION:LINE': {
    query: {
      name: 'TEAM_AVG_DATA',
      variables: {
        names: [SportsTeamDataName.TEAM_CONDITION_AVG],
        period: 'LAST_28_DAYS',
      },
      context: {
        omitBatch: true,
      },
    },
    parser: 'TEAM_AVG_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'LINE',
        width: 3,
        color: 'deep-purple-500',
        colorHover: 'deep-purple-500',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'condition',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_10_TICK_VALUES',
            },
          ],
        },
      },
    ],
    domain: {
      size: 28,
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.CONDITION,
            color: theme.colors['deep-purple-500'],
            chipType: ChipType.TEN,
            interpretPath: 'CONDITION',
          },
        ],
      },
    },
  },
  // teamChart/workload
  'TEAM_AVG:WORKLOAD:1_WEEKS:DAILY:BAR': {
    query: {
      name: 'TEAM_AVG_DATA',
      variables: {
        names: [SportsTeamDataName.TEAM_WORKLOAD_AVG],
        period: 'LAST_7_DAYS',
      },
    },
    parser: 'TEAM_AVG_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        color: 'teal-300',
        colorHover: 'teal-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'workload',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 7,
              tickFormat: 'NULL_STRING',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              style: 'AXIS_STYLE_INVISIBLE',
              tickFormat: 'NULL_STRING',
            },
          ],
        },
      },
    ],
    domain: {
      size: 7,
      y: {
        start: 0,
        end: 'Y_MAX_1600_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.WORKLOAD,
            nullDataKey: 'chart.data.none',
            color: theme.colors['teal-300'],
            interpretPath: 'WORKLOAD',
          },
        ],
      },
    },
  },
  'TEAM_AVG:WORKLOAD_INTENSITY:BAR_LINE': {
    query: {
      name: 'TEAM_AVG_DATA',
      variables: {
        names: [SportsTeamDataName.TEAM_WORKLOAD_AVG],
        period: 'LAST_28_DAYS',
      },
    },
    parser: 'TEAM_AVG_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        color: 'teal-300',
        colorHover: 'teal-500',
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'workload',
          },
        },
        axis: {
          y: [
            {
              orientation: 'LEFT',
              tickCount: 5,
              tickValues: 'Y_1_TICK_VALUES_DIVIDE_6',
              tickFormat: '1_RE-SCALE_1500_MAX',
            },
          ],
        },
        yFormat: '1500_MAX_RE-SCALE_1',
        legend: [
          {
            label: 'WORKLOAD',
            chartType: 'BAR',
            chartColor: theme.colors['teal-300'],
            toggle: false,
            visible: true,
          },
        ],
      },
      {
        type: 'LINE',
        width: 3,
        color: 'amber-600',
        colorHover: 'amber-600',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'intensity',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'RIGHT',
              tickValues: 'Y_1_TICK_VALUES_DIVIDE_6',
              tickFormat: '1_RE-SCALE_10',
            },
          ],
        },
        yFormat: '10_RE-SCALE_1',
        legend: [
          {
            label: 'WORKLOAD_INTENSITY_RPE',
            chartType: 'LINE',
            chartColor: theme.colors['amber-600'],
            toggle: false,
            visible: true,
          },
        ],
      },
    ],
    domain: {
      size: 28,
      y: {
        start: 0,
        end: 1,
      },
      dataIndex: [0, 1],
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.WORKLOAD,
            nullDataKey: 'chart.data.none',
            color: theme.colors['teal-300'],
            interpretPath: 'WORKLOAD',
          },
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.WORKLOAD_INTENSITY,
            nullDataKey: 'chart.data.none',
            color: theme.colors['amber-600'],
            interpretPath: 'WORKLOAD_INTENSITY',
          },
        ],
      },
    },
  },
  // teamChart/acwr
  'TEAM_AVG:WORKLOAD_ACXR:1_WEEKS:DAILY:AREA': {
    query: {
      name: 'TEAM_AVG_DATA',
      variables: {
        names: [SportsTeamDataName.TEAM_WORKLOAD_ACXR_AVG],
        period: 'LAST_7_DAYS',
      },
    },
    parser: 'TEAM_AVG_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'AREA',
        color: 'pink-500',
        colorHover: 'pink-500',
        animate: 'FAST_CUBIC_IN_OUT',
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'team_workload_acxr_avg',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 7,
              tickFormat: 'NULL_STRING',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              style: 'AXIS_STYLE_INVISIBLE',
              tickFormat: 'NULL_STRING',
            },
          ],
        },
      },
    ],
    domain: {
      size: 7,
      y: {
        start: 0,
        end: 'Y_MAX_2_ROUNDED_UP',
      },
      xPaddingRatio: 0,
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'AREA',
            dataType: MonitoringDataType.WORKLOAD_ACXR,
            color: theme.colors['pink-500'],
            chipType: ChipType.NUMBER,
            interpretPath: 'WORKLOAD_ACXR',
          },
        ],
      },
    },
  },
  // teamChart/injury
  'TEAM_AVG:INJURY:STACK': {
    query: {
      name: 'TEAM_AVG_DATA',
      variables: {
        names: [SportsTeamDataName.TEAM_INJURY_BY_SORENESS],
        period: 'LAST_28_DAYS',
      },
      context: {
        omitBatch: true,
      },
    },
    parser: 'TEAM_AVG_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'STACK',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        colorScale: [
          'injury-level-stack-1',
          'injury-level-stack-2',
          'injury-level-stack-3',
          'injury-level-stack-4',
          'injury-level-stack-5',
        ],
        colorScaleHover: [
          'injury-level-1-hover',
          'injury-level-2-hover',
          'injury-level-3-hover',
          'injury-level-4-hover',
          'injury-level-5-hover',
        ],
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'countByLevel',
          },
          domainY: {
            field: 'count',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: {
            orientation: 'LEFT',
            tickValues: 'DOMAIN_Y_4_ROUNDED_TICK_VALUES',
          },
        },
        legend: DATASET_LEGENDS_INJURY,
      },
    ],
    domain: {
      size: 28,
      y: {
        start: 0,
        end: 'DOMAIN_Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: DATASET_LABEL_INJURY,
  },
}
