import { useCallback, useEffect, useState } from 'react'

import { IamportTypes } from '@plco-pro/utils/libs/iamport.types'

export const useIamport = () => {
  const [iamport, setIamport] = useState<IamportTypes | undefined>()

  useEffect(() => {
    // dynamically import package on client side once component is mounted
    const dynamicallyImportPackage = () => {
      const Iamport = require('@plco-pro/utils/libs/iamport.js').default
      setIamport(new Iamport())
    }

    dynamicallyImportPackage()
  }, [])

  const requestPay = useCallback(
    (data, callback) => {
      iamport?.requestPay(data, callback)
    },
    [iamport],
  )

  return {
    requestPay,
  }
}
