import { css } from '@emotion/react'

import resetStyle from '@plco-pro/styles/reset'

export const rootEm = 16

const globalStyle = css`
  ${resetStyle}
  @font-face {
    font-family: 'BMHANNAPro';
    src: url('/fonts/BMHANNAPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('/fonts/Pretendard-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans KR';
    src: url('/fonts/NotoSansKR-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html,
  body {
    font-size: ${rootEm}px; // base value used for em/rem calculation
    font-family:
      -apple-system,
      Noto Sans KR,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      Fira Sans,
      Droid Sans,
      Helvetica Neue,
      BMHANNAPro,
      Pretendard,
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body,
  #__next {
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline;
  }

  abbr {
    text-decoration: none;
  }

  img {
    pointer-events: none;
    user-select: none;
  }

  button {
    cursor: pointer;
  }
  button:disabled {
    cursor: default;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export default globalStyle
