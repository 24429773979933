import { Moment } from 'moment'

import { useI18n } from '@plco-pro/hooks'

import { Flex, Icon, Text } from '../../atoms'
import { FeedbackContentsType } from '../helpers'

interface DateChipProps {
  feedbackContentsType?: FeedbackContentsType
  date: Moment
}

// yyyy년 MM월 D일 데이터
export const DateChip = ({ feedbackContentsType, date }: DateChipProps) => {
  const { formatMessage: f } = useI18n()

  return (
    <Flex
      sx={{
        height: '26px',
        alignItems: 'center',
        px: 1,
        borderRadius: '4px',
        backgroundColor: feedbackContentsType === 'add' ? 'grey-50' : 'white',
        gap: '4px',
      }}
    >
      <Icon src={'/images/ic_graphic_eq.svg'} />

      <Text variant="p2" sx={{ fontWeight: 'bold', color: 'primary-400' }}>
        {date.format(f({ id: 'feedback.data-for-date' }))}
      </Text>
    </Flex>
  )
}
