import React, { Fragment, ReactNode, useCallback, useMemo } from 'react'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Segment, SegmentProps } from '@plco-pro/components/atoms/segment'

export type SegmentInputProps = {
  size?: SegmentProps['size']
  items: SegmentInputItem[]
  onChange?: (value: any) => void
  value: any
}

export type SegmentInputItem = Partial<SegmentProps> & {
  value?: any
  children?: ReactNode
  onClick?: () => void
}

export const SegmentInput: React.FunctionComponent<SegmentInputProps> = ({
  size,
  value,
  onChange = () => {},
  ...rest
}) => {
  const items = useMemo(() => {
    return rest.items.map((item) => ({
      ...item,
      checked: item.value === value,
    }))
  }, [rest.items, value])

  const handleClick = useCallback(
    (item: SegmentInputItem) => {
      onChange(item.value)

      if (item.onClick) {
        item.onClick()
      }
    },
    [onChange],
  )

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        alignItems: 'stretch',
        p: '2px',
        backgroundColor: 'grey-200',
        borderRadius: '18px',
        boxShadow: 'inset 0 0 1px 0 rgba(16, 20, 38, 0.24)',
      }}
    >
      {items.map((item, index) => {
        return (
          <Fragment key={`chip-${index}`}>
            {index > 0 && !item.checked && !items[index - 1].checked && (
              <Flex sx={{ flexDirection: 'column', alignItems: 'center', py: '6px' }}>
                <Box sx={{ width: '1px', height: '100%', backgroundColor: 'grey-300' }} />
              </Flex>
            )}

            <Segment size={size} onClick={() => handleClick(item)} {...item}>
              {item.children}
            </Segment>
          </Fragment>
        )
      })}
    </Flex>
  )
}
