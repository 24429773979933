import { Fragment, ReactNode, useEffect } from 'react'

import { useRouter } from 'next/router'

import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { useStore } from '@plco-pro/stores'
import { ROUTE_PATHS } from '@plco-pro/utils/constant'

export const DrawerProvider = ({ children }: { children?: ReactNode }) => {
  const router = useRouter()
  const { navigation } = useStore()
  const { lgAndDown } = useResponsiveContext()

  const { setIsOpenPlayersDrawer, setIsOpenDrawer } = navigation

  const { pathname } = router

  useEffect(() => {
    if (lgAndDown) {
      const isPlayerPage = pathname.includes(ROUTE_PATHS.PLAYERS)

      setIsOpenDrawer(false)
      setIsOpenPlayersDrawer(isPlayerPage)
    }
  }, [lgAndDown, pathname, setIsOpenDrawer, setIsOpenPlayersDrawer])

  return <Fragment>{children}</Fragment>
}
