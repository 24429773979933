import React, { createContext, FunctionComponent, useContext, useEffect } from 'react'

import { useRouter } from 'next/router'

import { Language } from '@plco-pro/graphqls/react.generated'
import { useViewer } from '@plco-pro/hooks/viewer/viewer'
import { useStore } from '@plco-pro/stores'

export type AuthorizationContext = {
  authorized: boolean | undefined
}

export const AuthorizationContext = createContext<AuthorizationContext>({
  authorized: false,
})

export const useAuthorizationContext = () => {
  return useContext(AuthorizationContext)
}

const AuthorizationProvider: FunctionComponent = (props) => {
  const store = useStore()
  const router = useRouter()
  const { loading } = useViewer()

  // New 코치에서 넘어온 teamId
  const teamIdWithNewCoach = router.query.teamId

  // New 코치에서 넘어온 language
  const languageWithNewCoach = router.query.language

  useEffect(() => {
    if (languageWithNewCoach) {
      const languageFormatted =
        languageWithNewCoach === 'vi' ? 'vn-VN' : `${languageWithNewCoach}-KR`

      store.preference.config.locale.setLanguage(languageFormatted as Language)
    }

    if (teamIdWithNewCoach && typeof teamIdWithNewCoach === 'string') {
      store.navigation.setActiveTeamId(teamIdWithNewCoach)

      const pathWithoutQuery = router.asPath.split('?')[0]
      router.replace(pathWithoutQuery, undefined, { shallow: true })
    }
  }, [languageWithNewCoach, router, store, teamIdWithNewCoach])

  const value = {
    authorized: true,
  }

  return (
    <AuthorizationContext.Provider value={value}>
      {loading ? null : props.children}
    </AuthorizationContext.Provider>
  )
}

export default AuthorizationProvider
