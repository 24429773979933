import React, { forwardRef } from 'react'

export type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ capture, ...rest }, ref) => (
  <input type={'checkbox'} ref={ref} {...rest} />
))
