import { ChartSystemPropsDatasetItem } from '@plco-pro/maps/chart-system/chart-system.types'

type PostProcess = {
  postProcessFieldMap: { [fieldName: string]: string[] }
  postProcessData: { [key: string]: number[] }
}

export const getPostProcess = (dataset: ChartSystemPropsDatasetItem[]) => {
  const initialPostProcess = { postProcessFieldMap: {}, postProcessData: {} }

  return dataset.reduce<PostProcess>((acc, { parser }) => {
    Object.entries(parser)
      .filter(
        ([_, datasetParserItem]) => datasetParserItem?.postOperation && datasetParserItem?.field,
      )
      .forEach(([datasetParserKey, datasetParserItem]) => {
        if (!datasetParserItem?.field) {
          return
        }

        const { field } = datasetParserItem

        acc.postProcessFieldMap[field] = [
          ...(acc.postProcessFieldMap[field] || []),
          datasetParserKey,
        ]

        acc.postProcessData[datasetParserKey] = []
      })

    return acc
  }, initialPostProcess)
}
