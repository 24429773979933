import { useKeycloak } from '@react-keycloak/ssr'

import { viewerTeam } from '@plco-pro/domain/viewer-team'
import { useViewerQuery } from '@plco-pro/graphqls/react.generated'
import { useStore } from '@plco-pro/stores'

export const useViewer = () => {
  const { navigation } = useStore()
  const { initialized } = useKeycloak()
  const { activeTeamId } = navigation

  const { data, loading, refetch } = useViewerQuery({
    skip: !initialized,
  })

  const teamUserMap = data?.sports.viewer?.teamMaps

  const teamMaps = viewerTeam.teamMaps(teamUserMap)

  const currentActiveTeam = viewerTeam.currentActiveTeam(activeTeamId, teamUserMap)

  const teamAuthority = viewerTeam.teamAuthority(activeTeamId, teamUserMap)

  const isInvitationPending = viewerTeam.isInvitationPending(activeTeamId, teamUserMap)

  return {
    viewer: data?.sports.viewer,
    teamMaps,
    currentActiveTeam,
    teamAuthority,
    isInvitationPending,
    loading,
    refetch,
  }
}
