import React, { forwardRef } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { CardChartPlayers } from '@plco-pro/components/organisms/card-chart-players'
import { useI18n } from '@plco-pro/hooks/i18n'
import { observer, useStore } from '@plco-pro/stores'

const CardContainerBox = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <Box ref={ref} sx={{ px: [1, 2, 2, 2], mt: [2, 4, 4, 4] }} {...props} />
))

export const TabContentItemPlayersCondition = observer(() => {
  const { formatMessage: f } = useI18n()
  const {
    players: { isContentXlAndUp },
  } = useStore()

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        mx: [-1, -2, null, null],
        mt: [-2, -4, null, null],
      }}
    >
      <CardContainerBox sx={{ width: ['col12.12', null, 'col12.6', null] }}>
        <CardChartPlayers
          configKey="USER:FATIGUE_STRESS_BODY_MOOD_DURATION_QUALITY:1_DAYS:POLAR"
          title={f({ id: 'players.condition.chart-card.condition-by-items.title' })}
          padding={{
            top: 48,
            bottom: 48,
            left: 48,
            right: 48,
          }}
        />
      </CardContainerBox>

      <CardContainerBox sx={{ width: ['col12.12', null, 'col12.6', null] }}>
        <CardChartPlayers
          configKey="USER:CONDITION:LINE"
          title={f({ id: 'players.condition.chart-card.condition.title' })}
          isDateRange={true}
        />
      </CardContainerBox>

      <CardContainerBox
        sx={{
          width: ['col12.12', null, 'col12.6', 'col12.4'],
          minWidth: isContentXlAndUp ? undefined : [null, null, null, '50%'],
        }}
      >
        <CardChartPlayers
          configKey="USER:FATIGUE:BAR"
          title={f({ id: 'players.condition.chart-card.fatigue.title' })}
          isDateRange={true}
        />
      </CardContainerBox>

      <CardContainerBox
        sx={{
          width: ['col12.12', null, 'col12.6', 'col12.4'],
          minWidth: isContentXlAndUp ? undefined : [null, null, null, '50%'],
        }}
      >
        <CardChartPlayers
          configKey="USER:STRESS:BAR"
          title={f({ id: 'players.condition.chart-card.stress.title' })}
          isDateRange={true}
        />
      </CardContainerBox>

      <CardContainerBox
        sx={{
          width: ['col12.12', null, 'col12.6', 'col12.4'],
          minWidth: isContentXlAndUp ? undefined : [null, null, null, '50%'],
        }}
      >
        <CardChartPlayers
          configKey="USER:MUSCLE:BAR"
          title={f({ id: 'players.condition.chart-card.muscle.title' })}
          isDateRange={true}
        />
      </CardContainerBox>

      <CardContainerBox
        sx={{
          width: ['col12.12', null, 'col12.6', 'col12.4'],
          minWidth: isContentXlAndUp ? undefined : [null, null, null, '50%'],
        }}
      >
        <CardChartPlayers
          configKey="USER:MOOD:BAR"
          title={f({ id: 'players.condition.chart-card.mood.title' })}
          isDateRange={true}
        />
      </CardContainerBox>

      <CardContainerBox
        sx={{
          width: ['col12.12', null, 'col12.12', 'col12.4'],
          minWidth: isContentXlAndUp ? undefined : [null, null, null, '100%'],
        }}
      >
        <CardChartPlayers
          configKey="USER:DURATION_QUALITY:BAR_LINE"
          title={f({ id: 'players.condition.chart-card.sleep.title' })}
          isDateRange={true}
        />
      </CardContainerBox>
    </Flex>
  )
})
