import { useCallback, useMemo } from 'react'

import { DurationInputArg2 } from 'moment'

import { useI18n } from '@plco-pro/hooks/i18n'
import { useMoment } from '@plco-pro/hooks/moment'

export type DatesFormat = (
  date: Date | string,
  dateInput: Date | string | { amount: number | string; unit?: DurationInputArg2 },
) => string

export const useMomentUiFormat = () => {
  const moment = useMoment()
  const { formatMessage: f } = useI18n()

  const yearMonthDayFormat = useMemo(() => f({ id: 'date.format.year-month-day' }), [f])
  const sameYearsFormat = useMemo(() => f({ id: 'date.format.month-day' }), [f])

  const formatDate = useCallback(
    (date: Date | string) => {
      return moment(date).format(yearMonthDayFormat)
    },
    [moment, yearMonthDayFormat],
  )

  const formatDateWithInput: DatesFormat = useCallback(
    (date: Date | string, dateInput) => {
      const dateFormat = moment(date)
      const addedDateFormat =
        typeof dateInput === 'object' && 'amount' in dateInput
          ? moment(date).add(dateInput.amount, dateInput.unit)
          : moment(dateInput)

      const isAmountPositive = addedDateFormat.isSameOrAfter(dateFormat)
      const isSameYears = dateFormat.isSame(addedDateFormat, 'year')

      const startedDate = isAmountPositive ? dateFormat : addedDateFormat
      const endedDate = isAmountPositive ? addedDateFormat : dateFormat

      return `${startedDate.format(yearMonthDayFormat)} ~ ${endedDate.format(
        isSameYears ? sameYearsFormat : yearMonthDayFormat,
      )}`
    },
    [moment, sameYearsFormat, yearMonthDayFormat],
  )

  const formatDateWithRange = useCallback(
    (date: Date | string, key: 'WEEKLY' | 'MONTHLY') => {
      if (key === 'WEEKLY') {
        return `${moment(date).startOf('isoWeek').format(yearMonthDayFormat)} ~ ${moment(date)
          .endOf('isoWeek')
          .format(yearMonthDayFormat)}`
      } else if (key === 'MONTHLY') {
        return `${moment(date).startOf('month').format(yearMonthDayFormat)} ~ ${moment(date)
          .endOf('month')
          .format(yearMonthDayFormat)}`
      }
    },
    [moment, yearMonthDayFormat],
  )

  return {
    formatDate,
    formatDateWithInput,
    formatDateWithRange,
  }
}
