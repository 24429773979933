import React from 'react'

import { Chip, ChipProps } from '@plco-pro/components/atoms/chip'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { useMappingByDataType } from '@plco-pro/hooks/mapping-by-data-type'

export type ChipMonitoringTimeProps = ChipProps & {
  value: any
  label?: string
  isUpdatedByCoach?: boolean
}

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-start',
    }}
    {...props}
  />
)

export const ChipMonitoringTime = ({
  value,
  label,
  disabled,
  isUpdatedByCoach,
  ...rest
}: ChipMonitoringTimeProps) => {
  const { timeDurationMap: timeTranslate } = useMappingByDataType()

  if (label) {
    return (
      <Flex sx={{ alignItems: 'center', gap: '6px', flex: '1 0 0' }}>
        <Text sx={{ fontWeight: 700 }} variant={'s1'} appearance={disabled && 'disabled'}>
          {value ? timeTranslate(value) : label}
        </Text>
      </Flex>
    )
  }

  if (rest.backgroundColor) {
    return (
      <Chip size={'xs'} {...rest}>
        <Text
          variant={'p1'}
          appearance={'alternative'}
          sx={{ fontWeight: 'medium', marginTop: '-1px' }}
        >
          {timeTranslate(value)}
        </Text>
      </Chip>
    )
  }

  return (
    <ContainerFlex>
      <Text variant={'p1'} sx={{ color: isUpdatedByCoach ? 'indigo-500' : 'text-primary' }}>
        {timeTranslate(value)}
      </Text>
    </ContainerFlex>
  )
}
