import { BoxProps } from 'victory'

import { Box, Card, Flex, Text } from '@plco-pro/components/atoms'
import { DialogModalChangeCard } from '@plco-pro/components/organisms/dialog-modal-change-card'
import {
  useNavigationByPlanQuery,
  usePlanBillingBillingQuery,
} from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useModal } from '@plco-pro/hooks/modal'
import { useMoment } from '@plco-pro/hooks/moment'
import { usePlan } from '@plco-pro/hooks/plan'
import { useViewer } from '@plco-pro/hooks/viewer/viewer'

type InfoProps = BoxProps & {
  label: string
  value?: string
}

type Props = {
  isHyper?: boolean
}

const InfoItem = ({ label, value }: InfoProps) => {
  return (
    <Box>
      <Text variant={'p1'} appearance={'hint'} sx={{ mb: 1 }}>
        {label}
      </Text>
      <Text variant={['s2', 'h5']}>{value}</Text>
    </Box>
  )
}

export const CardHyperPlanInfo = ({ isHyper }: Props) => {
  const { formatMessage } = useI18n()
  const moment = useMoment()
  const { formatCardInfo } = usePlan()
  const {
    open: isOpenChangeCardModal,
    onOpen: onOpenChangeCardModal,
    onClose: onCloseChangeCardModal,
  } = useModal()

  const { currentActiveTeam } = useViewer()
  const teamId = currentActiveTeam?.id

  const { data: hyperData } = useNavigationByPlanQuery({
    variables: { teamId: teamId || '', includeSubscription: true },
  })

  const { data: billingData } = usePlanBillingBillingQuery({
    variables: {
      identityToken: teamId || '',
    },
    fetchPolicy: 'cache-and-network',
  })

  const planName = hyperData?.teamSubscriptionItem?.plan.planOptionInfo.planOptionName
  const planType = hyperData?.teamSubscriptionItem?.plan.planType
  const startDate = moment(hyperData?.teamSubscriptionItem?.startAt).format(
    formatMessage({ id: 'date.format.year-month-day-suffix' }),
  )
  const endDate = moment(hyperData?.teamSubscriptionItem?.endAt).format(
    formatMessage({ id: 'date.format.year-month-day-suffix' }),
  )
  const nextPaymentDate = moment(hyperData?.teamSubscriptionItem?.endAt).format(
    formatMessage({ id: 'date.format.year-month-day-suffix' }),
  )

  return (
    <>
      <Flex sx={{ justifyContent: 'center' }}>
        <Card
          sx={{
            px: [2, 4],
            pt: [2, 4],
            pb: [3, 6],
            width: ['100%', '532px'],
          }}
        >
          <Flex sx={{ flexDirection: 'column', gap: 3 }}>
            <Box>
              <Text variant={['s1', 'h4']} sx={{ fontWeight: 'bold' }}>
                {formatMessage({ id: 'plan.billing.plan-details' })}
              </Text>
            </Box>
            <InfoItem label={formatMessage({ id: 'plan.plan' })} value={planName} />
            <InfoItem
              label={formatMessage({ id: 'hyper.billing.period' })}
              value={`${startDate} ~ ${endDate}`}
            />
            <InfoItem
              label={formatMessage({ id: 'hyper.next.payment.date' })}
              value={nextPaymentDate}
            />
            <Flex sx={{ gap: 1, alignItems: 'flex-end' }}>
              <InfoItem
                label={formatMessage({ id: 'plan.payment.method' })}
                value={formatCardInfo(
                  billingData?.billing?.cardName,
                  billingData?.billing?.cardNumber,
                )}
              />
              <Text
                variant={'p1'}
                appearance={'primary'}
                onClick={onOpenChangeCardModal}
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                {formatMessage({ id: 'plan.change.card' })}
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <DialogModalChangeCard
        isHyper={isHyper}
        open={isOpenChangeCardModal}
        onClose={onCloseChangeCardModal}
        planType={planType}
      />
    </>
  )
}
