import React from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { IconButton } from '@plco-pro/components/molecules/icon-button'

export type ModalHeaderProps = BoxProps & {
  headerProps?: BoxProps
  children?: React.ReactNode
  onClose: () => void
  handleClickBackIcon?: () => void
  showBackIcon?: boolean
}

export const ModalPageHeader: React.FunctionComponent<ModalHeaderProps> = ({
  headerProps,
  children,
  onClose,
  handleClickBackIcon,
  showBackIcon = false,
  ...boxProps
}) => {
  return (
    <HeaderBox data-testid={'modal-header'} {...boxProps}>
      <HeaderFlex sx={{ flex: 1, mr: [0, 2] }}>
        {showBackIcon ? (
          <IconButton
            src={'/images/arrow-back.svg'}
            onClick={handleClickBackIcon || onClose}
            sx={{ mr: 1 }}
          />
        ) : null}
        {typeof children === 'string' ? <Text variant={'h5'}>{children}</Text> : children}
      </HeaderFlex>
      <HeaderFlex>
        <Box>
          <IconButton src={'/images/ic-close.svg'} onClick={onClose} />
        </Box>
      </HeaderFlex>
    </HeaderBox>
  )
}

const HeaderBox = (props: BoxProps) => (
  <Flex
    {...props}
    sx={{
      flex: '0 1 auto',
      px: 3,
      py: 2,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'grey-100',
      height: ['56px', '70px'],
    }}
  />
)

const HeaderFlex = (props: FlexProps) => (
  <Flex sx={{ height: '100%', alignItems: 'center' }} {...props} />
)
