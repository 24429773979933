import { Fragment, useMemo, useState } from 'react'

import { colord } from 'colord'
import { VictoryAxis, VictoryChart, VictoryLine, VictoryScatter } from 'victory'

import {
  FeedbackConditionPlummetData,
  UserConditionDataType,
} from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { palette } from '@plco-pro/themes/main'

import { Box, Divider, Flex } from '../../atoms'
import { DescriptionBox } from '../components'
import { FeedbackContentsType } from '../helpers'
import { ConditionTab } from './condition-tab'

interface PlummedConditionProps {
  name: string
  feedbackContentsType?: FeedbackContentsType
  conditionPlummetData: FeedbackConditionPlummetData
}

interface PlummedConditionProps {
  feedbackContentsType?: FeedbackContentsType
  conditionPlummetData: FeedbackConditionPlummetData
}

export const PlummedCondition = ({
  name: player,
  feedbackContentsType = 'add',
  conditionPlummetData,
}: PlummedConditionProps) => {
  const { formatMessage: f } = useI18n()
  const { smAndDown } = useResponsiveContext()

  const [conditionTypeTab, setConditionTypeTab] = useState<UserConditionDataType>(
    () => conditionPlummetData.dataList[0],
  )

  const {
    mood28dayList,
    sleepDuration28dayList,
    sleepQuality28dayList,
    fatigue28dayList,
    muscle28dayList,
    stress28dayList,
  } = conditionPlummetData

  const circleBackgroundColor = useMemo(() => {
    return colord(palette['carmine-400']).alpha(0.5).toRgbString()
  }, [])

  const conditionList = useMemo(() => {
    return conditionPlummetData.dataList
      .map((data) => f({ id: `feedback.condition-type.${data}` }))
      .join(', ')
  }, [conditionPlummetData.dataList, f])

  const data = useMemo(() => {
    if (conditionTypeTab === UserConditionDataType.MOOD) {
      return mood28dayList.map((mood, index) => ({
        x: index + 1,
        y: mood,
      }))
    }

    if (conditionTypeTab === UserConditionDataType.SLEEP_DURATION) {
      return sleepDuration28dayList.map((sleepDuration, index) => ({
        x: index + 1,
        y: sleepDuration,
      }))
    }

    if (conditionTypeTab === UserConditionDataType.FATIGUE) {
      return fatigue28dayList.map((fatigue, index) => ({
        x: index + 1,
        y: fatigue,
      }))
    }

    if (conditionTypeTab === UserConditionDataType.MUSCLE) {
      return muscle28dayList.map((muscle, index) => ({
        x: index + 1,
        y: muscle,
      }))
    }

    if (conditionTypeTab === UserConditionDataType.STRESS) {
      return stress28dayList.map((stress, index) => ({
        x: index + 1,
        y: stress,
      }))
    }

    return sleepQuality28dayList.map((sleepQuality, index) => ({
      x: index + 1,
      y: sleepQuality,
    }))
  }, [
    conditionTypeTab,
    sleepQuality28dayList,
    mood28dayList,
    sleepDuration28dayList,
    fatigue28dayList,
    muscle28dayList,
    stress28dayList,
  ])

  // 모바일, 피드백 등록 시에만 디바이더 노출
  const showDivider = feedbackContentsType === 'add' && smAndDown

  return (
    <Fragment>
      <Box sx={{ mx: [2, 3] }}>
        <DescriptionBox
          sx={{
            mb: 2,
            ...{
              add: {},
              detail: {
                border: '1px solid',
                borderColor: 'grey-100',
                borderRadius: '12px',
                bg: 'white-500',
              },
            }[feedbackContentsType],
          }}
        >
          {f({ id: 'feedback.decline-condition' }, { player, conditionList })}
        </DescriptionBox>

        <Flex
          sx={{
            flexDirection: 'column',
            p: 2,
            ...{
              add: {},
              detail: {
                border: '1px solid',
                borderColor: 'grey-100',
                borderRadius: '12px',
                bg: 'white-500',
              },
            }[feedbackContentsType],
          }}
        >
          <Flex
            sx={{
              borderBottom: '1px solid',
              borderBottomColor: 'grey-100',
              overflowX: 'scroll',
            }}
          >
            {conditionPlummetData.dataList.map((data) => (
              <ConditionTab
                key={data}
                label={f({ id: `feedback.condition-type.${data}` })}
                isActive={conditionTypeTab === data}
                onClick={() => setConditionTypeTab(data)}
              />
            ))}
          </Flex>

          <Box>
            {/* VictoryLine에 stroke에 gradient를 적용하기 위해서 추가 */}
            {/* display:none 하면 stroke가 나타나지 않음 */}
            <svg style={{ height: 0 }}>
              <defs>
                <linearGradient id="myGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#FF2E00ED" />

                  <stop offset="100%" stopColor="#FFB9A9" />
                </linearGradient>
              </defs>
            </svg>

            <VictoryChart
              height={160}
              minDomain={{ y: 0 }}
              domainPadding={{ x: 4, y: 24 }}
              padding={0}
              animate={{ duration: 200 }}
            >
              <VictoryAxis
                dependentAxis={true}
                style={{
                  grid: { stroke: palette['grey-50'] },
                  axis: { stroke: 'transparent' },
                  ticks: { stroke: 'transparent' },
                  tickLabels: { fill: 'transparent' },
                }}
              />

              <VictoryLine
                interpolation={'natural'}
                style={{
                  data: { stroke: 'url(#myGradient)' },
                }}
                data={data}
              />

              {/* 라인 차트 마지막에 원 그리기 위해서 추가 */}
              <VictoryScatter
                data={[data[data.length - 1]]}
                size={4}
                style={{ data: { fill: circleBackgroundColor } }}
              />

              {/* 라인 차트 마지막에 원 그리기 위해서 추가 */}
              <VictoryScatter
                data={[data[data.length - 1]]}
                size={2}
                style={{ data: { fill: palette['deep-orange-500'] } }}
              />
            </VictoryChart>
          </Box>
        </Flex>
      </Box>
      {showDivider && <Divider sx={{ height: '16px', mt: 3, mb: 1 }} />}
    </Fragment>
  )
}
