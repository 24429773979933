export const get30DaysAgo = (date?: Date) => {
  // 현재 날짜 객체 생성
  const currentDate = date || new Date()

  // 30일을 빼기
  const thirtyOneDaysAgo = new Date()
  thirtyOneDaysAgo.setDate(currentDate.getDate() - 30)

  const year = thirtyOneDaysAgo.getFullYear()
  const month = thirtyOneDaysAgo.getMonth()
  const day = thirtyOneDaysAgo.getDate()

  return new Date(year, month, day, 0, 0, 0)
}
