import { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { BlockProps } from 'victory-core'

import { Box } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { ChartPeriodNavigation } from '@plco-pro/components/molecules/chart-period-navigation'
import { SegmentInput } from '@plco-pro/components/molecules/segment-input'
import { Chart } from '@plco-pro/components/organisms/chart'
import {
  SportsBodyPartName,
  useMonthlyInjuryHistoryLazyQuery,
  useWeeklyInjuryHistoryLazyQuery,
} from '@plco-pro/graphqls/react.generated'
import { useNationality } from '@plco-pro/hooks'
import { useChartSystem } from '@plco-pro/hooks/chart-system'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMoment } from '@plco-pro/hooks/moment'
import { ChartSystemMapKey } from '@plco-pro/maps/chart-system/chart-system'

export type Props = {
  periodConfigKeyMap: InjuryHistoryChartConfigKeyMap
  playerId: string
  injuryPart: SportsBodyPartName
  padding?: BlockProps
}

export type InjuryHistoryChartConfigKeyMap = Map<InjuryHistoryChartPeriod, ChartSystemMapKey>

export enum InjuryHistoryChartPeriod {
  WEEK = '1-week',
  MONTH = '1-month',
}

export const ChartInjuryHistory = observer(
  ({ periodConfigKeyMap, playerId, injuryPart, padding }: Props) => {
    const { formatMessage: f } = useI18n()
    const moment = useMoment()
    const nationality = useNationality()

    const [period, setPeriod] = useState<InjuryHistoryChartPeriod>(
      Array.from(periodConfigKeyMap.keys()).reverse()[0],
    )

    const parsedPeriod = (period: InjuryHistoryChartPeriod) => {
      if (period === InjuryHistoryChartPeriod.WEEK) {
        return 'WEEK'
      }

      return 'MONTH'
    }

    const configKey: ChartSystemMapKey =
      periodConfigKeyMap.get(period) || periodConfigKeyMap.values().next().value

    const { store, chartSystemProps } = useChartSystem(configKey)

    const date = store?.variables.date || store?.date

    const [weeklyInjuryHistoryQuery, { data: weeklyData }] = useWeeklyInjuryHistoryLazyQuery()
    const [monthlyInjuryHistoryQuery, { data: monthlyData }] = useMonthlyInjuryHistoryLazyQuery()

    useEffect(() => {
      if (!injuryPart) {
        return
      }

      if (period === InjuryHistoryChartPeriod.WEEK) {
        weeklyInjuryHistoryQuery({
          variables: {
            input: {
              nationality,
            },
            id: playerId,
            date: moment(date).format('YYYY-MM-DD'),
            bodyPart: injuryPart,
          },
        })
      } else if (period === InjuryHistoryChartPeriod.MONTH) {
        monthlyInjuryHistoryQuery({
          variables: {
            input: {
              nationality,
            },
            id: playerId,
            date: moment(date).format('YYYY-MM-DD'),
            bodyPart: injuryPart,
          },
        })
      }
    }, [
      period,
      weeklyInjuryHistoryQuery,
      monthlyInjuryHistoryQuery,
      date,
      injuryPart,
      playerId,
      moment,
      nationality,
    ])

    const periodInputItems = Array.from(periodConfigKeyMap.keys()).map((item) => ({
      children: f({ id: item }),
      value: item,
    }))

    const chartPadding = padding || {
      top: 64,
      bottom: 64,
      left: 64,
      right: 64,
    }

    useEffect(() => {
      if (!store) {
        return
      }

      const chartData =
        period === InjuryHistoryChartPeriod.WEEK
          ? weeklyData?.sports.user?.sorenessHistory.weeklyData.curr.map((el) => ({
              x: el.time,
              y: el.level,
            }))
          : monthlyData?.sports.user?.sorenessHistory.monthlyData.curr.map((el) => ({
              x: el.time,
              y: el.level,
            }))

      if (chartData) {
        store.setParsedData([chartData])
      }
    }, [store, weeklyData, monthlyData, period])

    return (
      <Card sx={{ height: '100%' }}>
        <Flex sx={{ pb: 5, flexDirection: 'column', alignItems: 'center' }}>
          <Flex
            sx={{
              px: [2, 3, null, null],
              pt: [2, 3, null, null],
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Text variant={['s1', 'h4']} sx={{ fontWeight: 'bold' }}>
              {f({ id: 'players.injury.chart-card.soreness-injury.title' })}
            </Text>

            <Flex sx={{ alignItems: 'center' }}>
              {periodInputItems.length > 1 && (
                <SegmentInput
                  items={periodInputItems}
                  onChange={setPeriod}
                  value={period}
                  size={['sm', 'md', null, null]}
                />
              )}
            </Flex>
          </Flex>

          <Box sx={{ mt: 4, width: 'auto' }}>
            <ChartPeriodNavigation
              configKey={configKey}
              defaultPeriod={parsedPeriod(period)}
              store={store}
            />
          </Box>

          <Box sx={{ width: '100%' }}>
            <Chart
              hasHistoryLine={true}
              name={configKey}
              width={'auto'}
              height={320}
              padding={chartPadding}
              {...chartSystemProps}
            />
          </Box>
        </Flex>
      </Card>
    )
  },
)
