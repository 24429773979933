import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'

export type IntersectionObserverOptions = IntersectionObserverInit & {
  onVisible?: () => void
}

export type IntersectionObserverResult = {
  entry: IntersectionObserverEntry | undefined
  visible: boolean
  onceVisible: boolean
  element: HTMLDivElement | null
  setElement: Dispatch<SetStateAction<HTMLDivElement | null>>
  ref: (element: HTMLDivElement | null) => void
}

export const useIntersectionObserver = (
  options?: IntersectionObserverOptions,
): IntersectionObserverResult => {
  const [element, setElement] = useState<HTMLDivElement | null>(null)
  const [entry, setEntry] = useState<IntersectionObserverEntry>()
  const [visible, setVisible] = useState(false)
  const [onceVisible, setOnceVisible] = useState(false)
  const [memoizedThreshold, setMemoizedThreshold] = useState(options?.threshold)

  const onVisibleRef = useRef(options?.onVisible)

  const ref: IntersectionObserverResult['ref'] = useCallback((element) => {
    if (element) {
      setElement(element)
    }
  }, [])

  useEffect(() => {
    if (!isEqual(options?.threshold, memoizedThreshold)) {
      setMemoizedThreshold(options?.threshold)
    }
  }, [memoizedThreshold, options?.threshold])

  const memoizedInitOptions = useMemo(() => {
    return {
      threshold: memoizedThreshold,
      root: options?.root,
      rootMargin: options?.rootMargin,
    }
  }, [options?.root, options?.rootMargin, memoizedThreshold])

  useEffect(() => {
    onVisibleRef.current = options?.onVisible
  }, [options?.onVisible])

  const intersectionObserverCallback = useCallback(([entry]: IntersectionObserverEntry[]) => {
    setEntry(entry)
    if (entry.isIntersecting) {
      setVisible(true)
      setOnceVisible(true)
      onVisibleRef.current?.()
    } else {
      setVisible(false)
    }
  }, [])

  useEffect(() => {
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || !element) return

    const observer = new IntersectionObserver(intersectionObserverCallback, memoizedInitOptions)

    observer.observe(element)

    return () => {
      observer.disconnect()
      setOnceVisible(false)
    }
  }, [element, intersectionObserverCallback, memoizedInitOptions])

  return {
    entry,
    visible,
    onceVisible,
    element,
    setElement,
    ref,
  }
}
