import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'

import _debounce from 'lodash/debounce'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { ScalableSvg } from '@plco-pro/components/atoms/scalable-svg'
import { MemoizedInjuryDummyBodyPart } from '@plco-pro/components/molecules/injury-dummy-body-part'
import { PartsData } from '@plco-pro/components/molecules/injury-tooltip/injury-tooltip'
import {
  SportsBodyPart,
  SportsBodyPartName,
  useBodyPartsQuery,
} from '@plco-pro/graphqls/react.generated'
import { useNationality } from '@plco-pro/hooks'
import {
  injuryDummyBackgroundMap,
  InjuryDummyGender,
  InjuryDummyOrientation,
} from '@plco-pro/maps/injury-dummy-background'
import {
  INJURY_DUMMY_BACKGROUND_HEIGHT,
  INJURY_DUMMY_BACKGROUND_WIDTH,
  InjuryDummyBodyPartName,
} from '@plco-pro/maps/injury-dummy-body-part'

export type InjuryDummyBodyPartDataMap = {
  SORENESS?: { [part in InjuryDummyBodyPartName]?: number }
  SORENESS_STREAK?: {
    [part in InjuryDummyBodyPartName]?: number
  }
  INJURY?: {
    count: number
    parts: {
      [part in InjuryDummyBodyPartName]?: PartsData
    }
  }
}

export type InjuryDummyBodyPartDataEntry = {
  SORENESS?: number
  SORENESS_STREAK?: number
}

export type InjuryDummyProps = {
  gender?: InjuryDummyGender
  orientation?: InjuryDummyOrientation
  data?: InjuryDummyBodyPartDataMap
  selectedPartName?: SportsBodyPartName
  disabledTooltip?: boolean
  onClick?: (bodyPart: SportsBodyPart) => void
}

export const InjuryDummy = ({
  gender = 'man',
  orientation = 'front',
  data = {},
  selectedPartName,
  disabledTooltip,
  onClick,
}: InjuryDummyProps) => {
  const nationality = useNationality()

  const { data: partsData } = useBodyPartsQuery({
    variables: {
      input: {
        nationality,
      },
    },
  })

  const injuryDummyOrientation: SportsBodyPart[] | undefined = useMemo(() => {
    return partsData?.sports.bodyParts.filter(
      (bodyPart) => bodyPart.front === (orientation === 'front'),
    ) as SportsBodyPart[]
  }, [orientation, partsData?.sports.bodyParts])

  // calculate parent layout once for all parts
  const [parentLayout, setParentLayout] = useState({
    width: 0,
    height: 0,
    offsetLeft: 0,
    parentWidth: 0,
  })

  // update parentLayout on layout change
  const layoutRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const debounceSetParentLayout = _debounce(setParentLayout, 100, {
      leading: true,
      trailing: true,
      maxWait: 500,
    })

    const width = layoutRef?.current?.clientWidth || 0
    const height = layoutRef?.current?.clientHeight || 0
    const offsetLeft = layoutRef?.current?.offsetLeft || 0
    const parentWidth = layoutRef?.current?.offsetParent?.clientWidth || 0

    debounceSetParentLayout({
      width,
      height,
      offsetLeft,
      parentWidth,
    })
  }, [])

  const backgroundLayout = useMemo(() => {
    if (!parentLayout.width) {
      return { opacity: 0 }
    }

    return {
      width: parentLayout.width,
      height: (parentLayout.width / INJURY_DUMMY_BACKGROUND_WIDTH) * INJURY_DUMMY_BACKGROUND_HEIGHT,
      offsetLeft: parentLayout.offsetLeft,
      offsetParentWidth: parentLayout.parentWidth,
    }
  }, [parentLayout])

  return (
    <Flex
      ref={layoutRef}
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          ...backgroundLayout,
          width: '100%',
        }}
      >
        <ScalableSvg src={injuryDummyBackgroundMap[gender][orientation]} {...backgroundLayout} />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          ...backgroundLayout,
        }}
      >
        {injuryDummyOrientation?.map((part) => {
          return (
            parentLayout.width && (
              <MemoizedInjuryDummyBodyPart
                key={part.id}
                bodyPart={part}
                gender={gender}
                onClick={onClick}
                parentLayout={backgroundLayout}
                partsData={data.INJURY?.parts[part.id]}
                selected={selectedPartName === part.id}
                disabledTooltip={disabledTooltip}
                data={{
                  SORENESS: (data.SORENESS && data.SORENESS[part.id]) || 0,
                  SORENESS_STREAK: (data.SORENESS_STREAK && data.SORENESS_STREAK[part.id]) || 0,
                }}
              />
            )
          )
        })}
      </Box>
    </Flex>
  )
}
