import { Fragment, useMemo } from 'react'

import { UserConditionDataType } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'

import { Flex, Text } from '../../atoms'

interface BadConditionItemProps {
  conditionType: UserConditionDataType
  score: number
  sleepDuration?: number
}

export const BadConditionItem = ({
  conditionType,
  score,
  sleepDuration,
}: BadConditionItemProps) => {
  const { formatMessage: f } = useI18n()

  // 4점 미만이면 좋지 않음
  const isBad = useMemo(() => {
    return score < 4
  }, [score])

  const time = useMemo(() => {
    if (sleepDuration) {
      const hour = Math.floor(sleepDuration / 60)
      const minute = sleepDuration % 60

      if (hour && !minute) {
        return f({ id: 'HOUR' }, { hours: hour })
      }

      if (minute && !hour) {
        return f({ id: 'MINUTE' }, { minutes: minute })
      }

      return f({ id: 'date.format.hour-minutes' }, { hour, minute })
    }
  }, [f, sleepDuration])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        border: '1px solid',
        borderColor: isBad ? 'carmine-200' : 'grey-200',
        gap: '4px',
        height: '84px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        bg: 'white-500',
      }}
    >
      <Flex sx={{ alignItems: 'center', gap: '4px' }}>
        {sleepDuration ? (
          <Text variant="s2" sx={{ fontWeight: 700, color: 'grey-500-80' }}>
            {time}
          </Text>
        ) : (
          <Fragment>
            <Text variant="h5" sx={{ fontWeight: 700, color: 'grey-500-80' }}>
              {score}
            </Text>

            <Text variant="h5" sx={{ fontWeight: 700, color: 'grey-300' }}>
              /10
            </Text>
          </Fragment>
        )}
      </Flex>

      <Text variant="p2" sx={{ color: isBad ? 'carmine-400' : 'grey-400', fontWeight: 700 }}>
        {f({ id: `feedback.condition-type.${conditionType}` })}
      </Text>
    </Flex>
  )
}
