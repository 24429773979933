import React, { useCallback, useEffect } from 'react'

import { DialogModal } from '@plco-pro/components/organisms/dialog-modal'
import {
  PlanBillingBillingDocument,
  useUpdateBillingKeyMutation,
} from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { usePlan } from '@plco-pro/hooks/plan'
import { useViewer } from '@plco-pro/hooks/viewer/viewer'
import { useResponsiveContext } from '@plco-pro/providers/responsive'

export type DialogModalChangeCardProps = {
  open: boolean
  isHyper?: boolean
  onClose?: () => void
  planType?: string
}

export const DialogModalChangeCard: React.FunctionComponent<DialogModalChangeCardProps> = ({
  open,
  isHyper,
  onClose = () => {},
  planType,
}) => {
  const { formatMessage: f } = useI18n()
  const { smOnly } = useResponsiveContext()
  const { requestBillingToken, billingToken } = usePlan()

  const { currentActiveTeam } = useViewer()
  const teamId = currentActiveTeam?.id

  const [updateBillingKeyMutation, { loading }] = useUpdateBillingKeyMutation({
    refetchQueries: [{ query: PlanBillingBillingDocument, variables: { identityToken: teamId } }],
  })

  const onClickChangeCard = useCallback(() => {
    if (!teamId || !planType) return

    onClose()
    requestBillingToken({ teamId })
  }, [onClose, planType, requestBillingToken, teamId])

  useEffect(() => {
    if (!billingToken?.success || !billingToken?.imp_uid || !teamId) return

    updateBillingKeyMutation({
      variables: {
        input: {
          identityToken: teamId,
          impUid: billingToken.imp_uid,
        },
      },
    })
  }, [billingToken, teamId, updateBillingKeyMutation])

  return (
    <DialogModal
      isHyper={isHyper}
      width={'dialogModal.width.sm'}
      open={open}
      onClose={onClose}
      title={f({ id: 'plan.change.card.title' })}
      subtitle={f({
        id: smOnly ? 'plan.change.card.subtitle.smOnly' : 'plan.change.card.subtitle.mdAndUp',
      })}
      footerProps={{
        leftButtonProps: {
          children: f({ id: 'CANCEL' }),
          onClick: onClose,
          disabled: loading,
        },
        rightButtonProps: {
          children: f({ id: 'plan.change.card.button' }),
          onClick: onClickChangeCard,
          disabled: loading,
        },
      }}
    />
  )
}
