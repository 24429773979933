import { Fragment } from 'react'

import { Flex, Text } from '@plco-pro/components/atoms'
import { InjuryStatus } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'

interface Props {
  status: InjuryStatus
}

export const CardPlayerInjuryEmpty = ({ status }: Props) => {
  const { formatMessage } = useI18n()

  return (
    <Flex
      sx={{
        height: ['240px', '240px', '322px'],
        textAlign: 'center',
        gap: 1,
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {status === InjuryStatus.END ? (
        <Fragment>
          <Text sx={{ fontWeight: 700 }} variant={'h1'}>
            {'📑'}
          </Text>
          <Text sx={{ fontWeight: 500 }} variant={'s2'} color="grey-400">
            {formatMessage({ id: 'players.card.past-injury-empty.text' })}
          </Text>
          <Text sx={{ fontWeight: 400, whiteSpace: 'pre-line' }} variant={'p2'} color="grey-400">
            {formatMessage({ id: 'players.card.past-injury-empty.subtext' })}
          </Text>
        </Fragment>
      ) : (
        <Fragment>
          <Text sx={{ fontWeight: 700 }} variant={'h1'}>
            {'💪'}
          </Text>
          <Text sx={{ fontWeight: 500 }} variant={'s2'} color="grey-400">
            {formatMessage({ id: 'players.card.current-injury-empty.text' })}
          </Text>
        </Fragment>
      )}
    </Flex>
  )
}
