import React from 'react'

import { VictoryLabelProps, VictoryPortal } from 'victory'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { LABEL_CONFIG_PREFIX } from '@plco-pro/maps/chart-system/props'

export const WIDTH = 64
export const HEIGHT = 26
export const LEFT_BLEED = 40

export type ChartLabelDottedLineProps = VictoryLabelProps & {
  format?: (value: number | string) => string | number
  prefix?: string
  defaultPrefix?: LABEL_CONFIG_PREFIX
  isAcwrLabel?: boolean
}

export const ChartLabelDottedLine: React.FunctionComponent<ChartLabelDottedLineProps> = (props) => {
  const { isAcwrLabel } = props

  // parsed props
  const parsedValue = props.data?.find((item: any) => item.x === (props.datum as any)?.x)?.y
  const parsedText =
    props.format && (parsedValue ?? false) !== false ? props.format(parsedValue) : ''
  const acwrText = props.data?.find((item: any) => item.x === (props.datum as any)?.x)?.label

  return (
    <VictoryPortal>
      <foreignObject x={-LEFT_BLEED} y={(props.y || 0) - HEIGHT / 2} width={'100%'} height={HEIGHT}>
        <Flex
          sx={{
            ml: LEFT_BLEED,
            width: '100%',
            justifyContent: 'flex-start',
            opacity: 0.8,
          }}
        >
          <Flex
            sx={{
              height: HEIGHT,
              p: '4px',
              alignItems: 'center',
              backgroundColor: props.defaultPrefix === 'GOAL' ? 'primary-500' : 'teal-500',
              borderRadius: 4,
            }}
          >
            <Text variant={'p2'} sx={{ color: 'white-500', fontWeight: 'medium' }}>
              {props.prefix || ''}
              {isAcwrLabel ? acwrText : parsedText}
            </Text>
          </Flex>
        </Flex>
      </foreignObject>
    </VictoryPortal>
  )
}
