export const CUSTOM_TIMEOUT_MESSAGE = 'timeout'

export const getOperation = (body: string) => {
  const operationKeyValue = body
    .slice(1, -1)
    .split(',')
    .find((el) => el.includes('operationName'))

  if (operationKeyValue) {
    return operationKeyValue.replaceAll('"', '').split(':')[1]
  }

  return
}

export const CUSTOM_TIMEOUT: Record<string, number> = {
  NavigationViewer: 10000,
}

export const isCustomTimeoutOperation = (operationName: string) => {
  return operationName in CUSTOM_TIMEOUT
}

export const customTimeout = (options: RequestInit, cb: () => void) => {
  if (typeof options?.body === 'string') {
    const operationName = getOperation(options.body)
    if (operationName && isCustomTimeoutOperation(operationName)) {
      setTimeout(() => {
        cb()
      }, CUSTOM_TIMEOUT[operationName])
    }
  }
}
