import get from 'lodash/get'
import has from 'lodash/has'

import {
  QuerySystemTeamAverageDataQuery,
  QuerySystemUserDataQuery,
} from '@plco-pro/graphqls/react.generated'
import { getPostProcess } from '@plco-pro/hooks/parser-system/utils/get-post-process'
import { ChartSystemMapValue } from '@plco-pro/maps/chart-system/chart-system.types'

type ParserDataType =
  | NonNullable<QuerySystemTeamAverageDataQuery['sports']['team']>['data']
  | NonNullable<QuerySystemUserDataQuery['sports']['user']>['data']

export const parserUserData = (
  data: ParserDataType,
  dataset: ChartSystemMapValue['dataset'],
  restProperty?: Record<string, string | number | null>,
): { parser: Record<string, any>[][]; postProcessData: Record<string, any[]> } => {
  const { postProcessFieldMap, postProcessData } = getPostProcess(dataset)

  const parseByDataName = data.map(({ name, ranges }) => {
    const lowerCaseName = name.toLocaleLowerCase()

    return ranges.map((rangeData) => {
      const time = rangeData.from

      return rangeData.entries.length === 0
        ? [{ ...restProperty, time }]
        : rangeData.entries.map(({ value, time: insertTime }) => {
            const entriesData = typeof value === 'object' ? value : { [lowerCaseName]: value }

            Object.entries(postProcessFieldMap).forEach(([dataFieldName, keys]) => {
              if (has(entriesData, dataFieldName)) {
                const nDepthValue = get(entriesData, dataFieldName || '')
                if (nDepthValue) {
                  keys.forEach((key) => {
                    postProcessData[key].push(nDepthValue)
                  })
                }
              }
            })
            return {
              ...entriesData,
              ...restProperty,
              time,
              insertTime,
            }
          })
    })
  }, [])

  if (parseByDataName.length > 1) {
    const mergeParseByDate: Record<string, any>[][] = []
    for (let i = 0; i < parseByDataName.length; i += 1) {
      const dataByDateEachDataName = parseByDataName[i]
      for (let j = 0; j < dataByDateEachDataName.length; j += 1) {
        const dataByDate = dataByDateEachDataName[j]
        const mergedParserEachDate = mergeParseByDate[j] || []
        mergeParseByDate[j] = [...mergedParserEachDate, ...dataByDate]
      }
    }
    return { parser: mergeParseByDate, postProcessData }
  } else {
    return { parser: parseByDataName[0], postProcessData }
  }
}
