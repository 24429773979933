import { Flex, Text } from '@plco-pro/components/atoms'
import {
  ButtonSlideToggle,
  SlideToggleButtonProps,
} from '@plco-pro/components/molecules/button-slide-toggle'
import { useI18n } from '@plco-pro/hooks'

export const SectionInjuryStatus = ({ active, onToggle, disabled }: SlideToggleButtonProps) => {
  const { formatMessage } = useI18n()

  return (
    <Flex sx={{ alignItems: 'center', gap: 1 }}>
      <Flex sx={{ flexDirection: 'column', gap: '4px', flex: 1 }}>
        <Text sx={{ fontWeight: 500 }} variant={'p1'}>
          {formatMessage({ id: 'player.modal-injury.detail-selection.injury-ended.title' })}
        </Text>
        <Text variant={'p2'} color={'text-secondary'}>
          {formatMessage({ id: 'player.modal-injury.detail-selection.injury-ended.subtitle' })}
        </Text>
      </Flex>
      <ButtonSlideToggle active={active} onToggle={onToggle} disabled={disabled} />
    </Flex>
  )
}
