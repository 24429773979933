import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { Flex } from '@plco-pro/components/atoms/flex'
import { InjuryHistoryModalHeader } from '@plco-pro/components/molecules/injury-history-modal-header'
import { CardPlayerSoreness } from '@plco-pro/components/organisms/card-player-soreness/card-player-soreness'
import { ModalPage } from '@plco-pro/components/organisms/modal-page'
import { PlayerInjuryHistoryContainer } from '@plco-pro/containers/players/player-injury-histories/player-injury-history-container'
import { PlayerInjuryHistoryChartContainer } from '@plco-pro/containers/players/player-injury-history-chart/player-injury-history-chart-container'
import {
  SportsBodyPart,
  SportsBodyPartName,
  usePlayerModalLatestRawBodyQuery,
  usePlayerModalUserQuery,
} from '@plco-pro/graphqls/react.generated'
import { useIntersectionObserver } from '@plco-pro/hooks/intersection-observer'
import { useModal } from '@plco-pro/hooks/modal'
import { usePlayerInjuryData } from '@plco-pro/hooks/player-injury-data'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { useStore } from '@plco-pro/stores'

const BLACK_LIST = 'blog'

export const ModalInjuryHistory = () => {
  const router = useRouter()
  const { ref, visible } = useIntersectionObserver()
  const { mobile } = useResponsiveContext()
  const store = useStore()

  const { setPlayerId } = store.players

  const playerId = (router.query.playerId || router.query.id) as string
  const isSecondSideModal = !!router.query.playerId
  const part = router.query.part as SportsBodyPartName

  const skip = !playerId || router.pathname.includes(BLACK_LIST)

  const { open, onOpen, onClose } = useModal()

  const {
    preference: {
      locale: { timezone },
    },
  } = useStore()

  const { data } = usePlayerModalUserQuery({
    skip,
    variables: {
      playerId,
    },
  })

  const { data: latestRawBodyData } = usePlayerModalLatestRawBodyQuery({
    skip,
    variables: {
      playerId,
      timezone,
    },
  })

  const {
    injuryDummyGender,
    injuryDummyData,
    loading: injuryLoading,
    navigateInjuryHistoryModal,
  } = usePlayerInjuryData({
    skip,
    playerId,
  })

  const user = data?.sports.user
  const latestRawBody = latestRawBodyData?.sports.user?.latestRawBody

  const onClickInjuryPart = (part: SportsBodyPart) => {
    setPlayerId(playerId)
    navigateInjuryHistoryModal(part.id)
  }

  const onClickBackIcon = () => {
    const { part, ...restQuery } = router.query
    router.replace(
      {
        query: {
          ...restQuery,
        },
      },
      undefined,
      { scroll: false },
    )
  }

  const onCloseModal = () => {
    const { part, playerId, ...restQuery } = router.query
    router.replace({
      query: {
        ...restQuery,
      },
    })
  }

  useEffect(() => {
    if (typeof playerId === 'string' && typeof part === 'string') {
      onOpen()
    } else {
      onClose()
    }
  }, [onClose, onOpen, part, playerId])

  return (
    <ModalPage
      open={open}
      onClose={onCloseModal}
      showHeaderBackIcon={isSecondSideModal}
      handleClickBackIcon={onClickBackIcon}
      animationDuration={isSecondSideModal ? 0 : 300}
      header={
        <InjuryHistoryModalHeader
          showInjuryPart={mobile || !visible}
          user={user}
          latestRawBody={latestRawBody}
          part={part}
        />
      }
    >
      <Flex p={2} sx={{ width: '100%', minHeight: '100vh', flexDirection: 'column' }}>
        <Flex
          ref={ref}
          sx={{
            width: 'col12.12',
            height: '420px',
            mt: [2, 4, 4, 4],
            mb: 5,
          }}
        >
          <CardPlayerSoreness
            loading={injuryLoading}
            injuryData={injuryDummyData}
            gender={injuryDummyGender}
            onClickPart={onClickInjuryPart}
            showTitle={false}
          />
        </Flex>

        <Flex>
          <PlayerInjuryHistoryChartContainer playerId={playerId} injuryPart={part} />
        </Flex>

        <PlayerInjuryHistoryContainer playerId={playerId} injuryPart={part} />
      </Flex>
    </ModalPage>
  )
}
