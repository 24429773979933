// exceptions reference : https://www.notion.so/qmit1201/exceptions-from-backend-7171f58449dc4e759d12cd5c7775ce7e?
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ApolloError, NextLink, Operation } from '@apollo/client'
import { ServerParseError } from '@apollo/client/link/http'
import { fromPromise, ServerError } from '@apollo/client/link/utils'
import { getStatusCodeNum, getStatusCodeText, Nullable } from '@common/utils'
import { GraphQLError } from 'graphql'
import { getReasonPhrase } from 'http-status-codes'
import _capitalize from 'lodash/capitalize'

import { useI18n } from '@plco-pro/hooks/i18n'
import { useToast } from '@plco-pro/hooks/toast'
import { getKeycloakInstanceWithConfig } from '@plco-pro/providers/keycloak'

export type ParsedGraphQLError = {
  unexpectedError?: {
    message: string
    name: string
    code: number
    errorCode?: string
    stack: Nullable<string>
  }
  validationError?: {
    input: {
      [key: string]: any
    }
    message: string
    name: string
    code: number
  }
  getMessageForServerError?: (code: number, path: string, errorCode?: string) => string | undefined
}

// helper hook to parse validation error and internal errors
const emptyError = {}
export const useGraphQLError = (
  error?: ApolloError,
  options?: {
    inputFields?: string[] // input fields which are aware of, if given, other fields except this would make unexpectedError rather validationError
  },
): ParsedGraphQLError => {
  const { formatMessage: f } = useI18n()
  return useMemo(() => {
    if (!error) {
      return emptyError
    }
    const errorPath = error.graphQLErrors[0]?.path
    const graphqlErrorCode = error.graphQLErrors[0]?.extensions?.code
    const parsedCode =
      error.graphQLErrors[0]?.extensions?.exception?.status ||
      error.graphQLErrors[0]?.extensions?.exception?.type?.code ||
      error.graphQLErrors[0]?.extensions?.exception?.code ||
      getStatusCodeNum(graphqlErrorCode) ||
      (error?.networkError as any)?.statusCode ||
      500
    const result: ParsedGraphQLError = {
      unexpectedError: {
        message:
          f({
            id: `ERROR.${getStatusCodeText(parsedCode)}`,
            defaultMessage: getReasonPhrase(parsedCode),
          }) || error.message,
        name:
          getStatusCodeText(parsedCode) || error.graphQLErrors[0]?.extensions?.code || error.name,
        code: (error.networkError && (error.networkError as any).statusCode) || parsedCode || 500,
        stack: error.stack,
      },
      getMessageForServerError: (
        code: number,
        path: string,
        errorCode?: string,
      ): string | undefined => {
        const id = errorCode
          ? `ERROR.${getStatusCodeText(code)}.${path}.${errorCode}`
          : `ERROR.${getStatusCodeText(code)}.${path}`
        return f({
          id,
          defaultMessage: f({
            id: `ERROR.${getStatusCodeText(code)}`,
            defaultMessage: 'Something went wrong',
          }),
        })
      },
    }

    const exception: {
      status: number
      type: string
      data?: {
        type: string
        message: string
        field: string
        actual?: string
        expected?: string
      }[]
      response?: {
        errorCode: string
      }
    } = error.graphQLErrors[0]?.extensions?.exception
    if (exception) {
      if (exception?.response?.errorCode) {
        result.unexpectedError!.code = exception.status
        result.unexpectedError!.errorCode = exception.response.errorCode
        result.unexpectedError!.message = f({
          id: `ERROR.${exception.type}`,
          defaultMessage: exception.type,
        })
      }

      if (!isNaN(exception.status) && exception.status !== 500) {
        result.unexpectedError!.code = exception.status
        result.unexpectedError!.message = f({
          id: `ERROR.${exception.type}`,
          defaultMessage: exception.type,
        })
      }
      if (exception.status === 422) {
        result.validationError = result.unexpectedError as any
        delete result.unexpectedError
        result.validationError!.input = exception.data!.reduce(
          (messages, entry) => {
            const values = {
              field: f({
                id: `ERROR.FIELD.${errorPath && `${errorPath.join('.')}.`}${entry.field}${
                  entry.type
                }`,
                defaultMessage: f({
                  id: `ERROR.FIELD.${entry.field}`,
                  defaultMessage: _capitalize(entry.field),
                }),
              }),
              type: entry.type,
              actual: entry.actual || null,
              expected: entry.expected || null,
            }
            const parsedErrorMessage = f(
              {
                id: `ERROR.${exception.type}.${errorPath && `${errorPath.join('.')}.`}${
                  entry.field
                }.${entry.type}`,
                defaultMessage: f(
                  {
                    id: `ERROR.${exception.type}.${errorPath && `${errorPath.join('.')}.`}${
                      entry.field
                    }`,
                    defaultMessage: f(
                      {
                        id: `ERROR.${exception.type}.${entry.type}`,
                        defaultMessage: entry.message,
                      },
                      values,
                    ),
                  },
                  values,
                ),
              },
              values,
            )
            messages[entry.field] = { ...values, message: parsedErrorMessage }
            return messages
          },
          {} as { [field: string]: any },
        )

        // make it as unexpected error if ..
        if (
          options?.inputFields &&
          Object.keys(result.validationError!.input).some(
            (field) => !options.inputFields!.includes(field),
          )
        ) {
          result.unexpectedError = result.validationError as any
        }
        // if(Array.isArray(exception.data)) {
        // }
      }
    }

    return result
  }, [error, f, options?.inputFields])
}

interface useErrorHandlerHookParams {
  path: string
  error?: ApolloError
  onServerError?: (errorCode: number, errorMessage: string) => void
}

export const useErrorHandler = (opts: useErrorHandlerHookParams) => {
  const { path, error, onServerError } = opts
  const [serverErrorMessage, setServerErrorMessage] = useState<string>('')
  const { unexpectedError, getMessageForServerError, validationError } = useGraphQLError(error)

  const code = useMemo(() => unexpectedError?.code, [unexpectedError])
  const errorCode = useMemo(() => unexpectedError?.errorCode, [unexpectedError])
  const errorMessage = useMemo(
    () => (getMessageForServerError && getMessageForServerError!(code!, path, errorCode!)) || '',
    [getMessageForServerError, code, errorCode, path],
  )

  useEffect(() => {
    if (unexpectedError) {
      if (onServerError && errorMessage) {
        onServerError(code!, errorMessage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unexpectedError])

  useEffect(() => {
    if (errorMessage) {
      setServerErrorMessage(errorMessage)
    }
    return () => {
      setServerErrorMessage('')
    }
  }, [errorMessage])

  return {
    serverErrorMessage,
    validationError,
    unexpectedError,
    setServerErrorMessage,
    getMessageForServerError,
  }
}

export const useLinkError = () => {
  const { showToast } = useToast()
  const { formatMessage: f } = useI18n()

  const isRefreshingTokenRef = useRef(false)
  const pendingRequestsRef = useRef<any[]>([])

  const showingToastCountsRef = useRef({
    400: 0,
    401: 0,
    403: 0,
    404: 0,
    409: 0,
    422: 0,
    500: 0,
    501: 0,
    network: 0,
  })

  const logGraphQLError = useCallback((error: GraphQLError) => {
    console.error('[GraphQL error]: ', JSON.stringify(error, null, 2))
  }, [])

  const logNetworkError = useCallback((error: Error | ServerError | ServerParseError) => {
    console.error('[Network error]: ', JSON.stringify(error, null, 2))
  }, [])

  const showGraphQLErrorToast = useCallback(
    (error: GraphQLError, maxShowingToastCount?: number) => {
      const code = error?.extensions?.exception?.code
      if (!code) return

      const text =
        f({ id: `ERROR.${getStatusCodeText(code)}`, defaultMessage: getReasonPhrase(code) }) ||
        error.message
      if (!text) return

      // skip if maximum count is reached
      const showingToastCount =
        showingToastCountsRef.current[code as keyof typeof showingToastCountsRef.current]
      if (maxShowingToastCount && showingToastCount >= maxShowingToastCount) return

      showToast(text, {
        status: 'error',
        onOpen: () => {
          const showingToastCount =
            showingToastCountsRef.current[code as keyof typeof showingToastCountsRef.current]
          showingToastCountsRef.current = {
            ...showingToastCountsRef.current,
            [code]: showingToastCount + 1,
          }
        },
        onClose: () => {
          const showingToastCount =
            showingToastCountsRef.current[code as keyof typeof showingToastCountsRef.current]
          showingToastCountsRef.current = {
            ...showingToastCountsRef.current,
            [code]: showingToastCount - 1,
          }
        },
      })
    },
    [f, showToast],
  )

  const showNetworkErrorToast = useCallback(
    (error: Error | ServerError | ServerParseError, maxShowingToastCount?: number) => {
      const text = f({ id: 'ERROR.NETWORK_ERROR' }) || error.message
      if (!text) return

      // skip if maximum count is reached
      const showingToastCount = showingToastCountsRef.current['network']
      if (maxShowingToastCount && showingToastCount >= maxShowingToastCount) return

      showToast(text, {
        status: 'error',
        onOpen: () => {
          const showingToastCount = showingToastCountsRef.current['network']
          showingToastCountsRef.current = {
            ...showingToastCountsRef.current,
            ['network']: showingToastCount + 1,
          }
        },
        onClose: () => {
          const showingToastCount = showingToastCountsRef.current['network']
          showingToastCountsRef.current = {
            ...showingToastCountsRef.current,
            ['network']: showingToastCount - 1,
          }
        },
      })
    },
    [f, showToast],
  )

  const resolvePendingRequests = useCallback(() => {
    pendingRequestsRef.current.map((callback) => callback())
    pendingRequestsRef.current = []
  }, [])

  const handleGraphQLParserFailed = useCallback(
    (error: GraphQLError, operation: Operation) => {
      logGraphQLError(error)
    },
    [logGraphQLError],
  )

  const handleGraphQLValidationFailed = useCallback(
    (error: GraphQLError, operation: Operation) => {
      logGraphQLError(error)
    },
    [logGraphQLError],
  )

  const handleBadUserInput = useCallback(
    (error: GraphQLError, operation: Operation) => {
      logGraphQLError(error)
    },
    [logGraphQLError],
  )

  // ref: https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
  const handleUnauthenticated = useCallback(
    (error: GraphQLError, operation: Operation, forward: NextLink) => {
      logGraphQLError(error)

      const keycloakInstance = getKeycloakInstanceWithConfig()

      if (!keycloakInstance.updateToken) return forward(operation)

      let observable
      if (!isRefreshingTokenRef.current) {
        isRefreshingTokenRef.current = true
        observable = fromPromise(
          (keycloakInstance.updateToken?.(Infinity) || new Promise((resolve) => resolve(true)))
            .then(() => {
              const newToken = keycloakInstance.token

              const oldHeaders = operation.getContext().headers
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  Authorization: `Bearer ${newToken}`,
                },
              })

              resolvePendingRequests()
              return newToken
            })
            .catch((error) => {
              console.error(error)
              pendingRequestsRef.current = []
              return
            })
            .finally(() => {
              isRefreshingTokenRef.current = false
            }),
        ).filter((value) => {
          return Boolean(value)
        })
      } else {
        observable = fromPromise(
          new Promise((resolve) => {
            pendingRequestsRef.current = [...pendingRequestsRef.current, () => resolve(true)]
          }),
        )
      }
      return observable.flatMap(() => forward(operation))
    },
    [logGraphQLError, resolvePendingRequests],
  )

  const handleForbidden = useCallback(
    (error: GraphQLError, operation: Operation) => {
      logGraphQLError(error)
    },
    [logGraphQLError],
  )

  const handlePersistedQueryNotFound = useCallback(
    (error: GraphQLError, operation: Operation) => {
      logGraphQLError(error)
    },
    [logGraphQLError],
  )

  const handlePersistedQueryNotSupported = useCallback(
    (error: GraphQLError, operation: Operation) => {
      logGraphQLError(error)
    },
    [logGraphQLError],
  )

  const handleInternalServerError = useCallback(
    (error: GraphQLError, operation: Operation) => {
      logGraphQLError(error)

      const { skipGlobalHandling } = operation.getContext()
      if (skipGlobalHandling) {
        return
      }

      const code = error?.extensions?.exception?.code
      if (!code) return

      switch (code) {
        case 400:
          // do nothing
          break
        case 401:
          // do nothing
          break
        case 403:
          showGraphQLErrorToast(error, 1)
          break
        case 404:
          // do nothing
          break
        case 408:
          // do nothing
          break
        case 409:
          // do nothing
          break
        case 422:
          // do nothing
          break
        case 500:
          showGraphQLErrorToast(error)
          break
        case 501:
          showGraphQLErrorToast(error)
          break
        default:
          break
      }
    },
    [logGraphQLError, showGraphQLErrorToast],
  )

  const handleNetworkError = useCallback(
    (error: Error | ServerError | ServerParseError, operation: Operation) => {
      logNetworkError(error)
      // do nothing
    },
    [logNetworkError],
  )

  return {
    handleGraphQLParserFailed,
    handleGraphQLValidationFailed,
    handleBadUserInput,
    handleUnauthenticated,
    handleForbidden,
    handlePersistedQueryNotFound,
    handlePersistedQueryNotSupported,
    handleInternalServerError,
    handleNetworkError,
  }
}
