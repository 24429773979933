import { moment } from '@common/utils/libs'
import { types } from 'mobx-state-tree'

import { SEND_CHECK_REMINDER_LOCK_SECONDS } from './team-chart-store'

export const NotificationStore = types
  .model('Dashboard', {
    conditionUncheckedReminderSentAt: types.optional(types.maybeNull(types.Date), null),
    workoutUncheckedReminderSentAt: types.optional(types.maybeNull(types.Date), null),
    trainingParticipationReminderSentAt: types.optional(types.maybeNull(types.Date), null),
    trainingWorkloadReminderSentAt: types.optional(types.maybeNull(types.Date), null),
    matchWorkloadReminderSentAt: types.optional(types.maybeNull(types.Date), null),
    insightConditionReminderSentAt: types.optional(types.maybeNull(types.Date), null),
    weeklyCalendarParticipationReminderSentAt: types.optional(types.maybeNull(types.Date), null),
    eventCalendarParticipationReminderSentAt: types.optional(types.maybeNull(types.Date), null),
  })
  .actions((self) => {
    return {
      setConditionUncheckedReminderSentAt(value: Date) {
        self.conditionUncheckedReminderSentAt = value
      },
      setWorkoutUncheckedReminderSentAt(value: Date) {
        self.workoutUncheckedReminderSentAt = value
      },
      setTrainingParticipationReminderSentAt(value: Date) {
        self.trainingParticipationReminderSentAt = value
      },
      setTrainingWorkloadReminderSentAt(value: Date) {
        self.trainingWorkloadReminderSentAt = value
      },
      setMatchWorkloadReminderSentAt(value: Date) {
        self.matchWorkloadReminderSentAt = value
      },
      setInsightConditionReminderSentAt(value: Date) {
        self.insightConditionReminderSentAt = value
      },
      setWeeklyCalendarParticipationReminderSentAt(value: Date) {
        self.weeklyCalendarParticipationReminderSentAt = value
      },
      setEventCalendarParticipationReminderSentAt(value: Date) {
        self.eventCalendarParticipationReminderSentAt = value
      },
    }
  })
  .views((self) => {
    return {
      canSendConditionUncheckedReminder() {
        if (!self.conditionUncheckedReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.conditionUncheckedReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
      canSendWorkoutUncheckedReminder() {
        if (!self.workoutUncheckedReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.workoutUncheckedReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },

      canSendTrainingParticipationReminder() {
        if (!self.trainingParticipationReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.trainingParticipationReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
      canSendTrainingWorkloadReminder() {
        if (!self.trainingWorkloadReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.trainingWorkloadReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
      canSendMatchWorkloadReminder() {
        if (!self.matchWorkloadReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.matchWorkloadReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
      canInsightConditionReminderSentAt() {
        if (!self.insightConditionReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.insightConditionReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
      canWeeklyCalendarParticipationReminderSentAt() {
        if (!self.weeklyCalendarParticipationReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.weeklyCalendarParticipationReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
      canEventCalendarParticipationReminderSentAt() {
        if (!self.eventCalendarParticipationReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.eventCalendarParticipationReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
    }
  })
