import { blog } from '@plco-pro/i18ns/blog'
import { dashboard } from '@plco-pro/i18ns/dashboard'
import { error } from '@plco-pro/i18ns/error'
import { insight } from '@plco-pro/i18ns/insight'
import { notable } from '@plco-pro/i18ns/notable'
import { plan } from '@plco-pro/i18ns/plan'
import { players } from '@plco-pro/i18ns/players'
import { profile } from '@plco-pro/i18ns/profile'
import { register } from '@plco-pro/i18ns/register'
import { teamChart } from '@plco-pro/i18ns/team-chart'
import { teamCode } from '@plco-pro/i18ns/team-code'
import { teams } from '@plco-pro/i18ns/teams'
import { toast } from '@plco-pro/i18ns/toast'

import { I18nMessagePack } from './i18n.en'

export const messages: I18nMessagePack = {
  language: '日本語', // @주빈님 확인

  ...register.ja,
  ...teamChart.ja,
  ...error.ja,
  ...dashboard.ja,
  ...insight.ja,
  ...plan.ja,
  ...players.ja,
  ...profile.ja,
  ...teamCode.ja,
  ...teams.ja,
  ...toast.ja,
  ...blog.ja,
  ...notable.ja,

  'common.today': '今日',

  // widget data type
  UNCHECKED: '未チェック', // "미체크",
  HIGH: '高い', // "높은",
  BAD: '悪い', // "나쁜",

  // index page
  'index.title':
    'プルコ for Coachは選手たちのコンディション、運動負荷、痛み及び負傷データをモニタリングして管理するサービスです.', // "플코 for Coach는 선수들의 컨디션, 운동 부하, 통증 및 부상 데이터를 모니터링 하고 관리하는 서비스 입니다.",

  // 403 page
  '403.title': 'おっと、アクセスできないページです.', // "이런, 접근할 수 없는 페이지 입니다.",
  '403.subtitle.first': '申し訳ありませんが、このページにアクセスする権限がありません.', // "죄송합니다. 이 페이지에 접근할 수 있는 권한이 없습니다.",
  '403.subtitle.second': "'前のページに戻る'ボタンを押してください.", // "‘이전 페이지로 돌아가기’ 버튼을 누르세요.",
  '403.button': '前ページに戻る', // "이전 페이지로 돌아가기",

  // 404 page
  '404.title': 'ページが存在しません.', // "페이지가 존재하지 않습니다.",
  '404.subtitle': "再起動するには、'ホームに戻る'ボタンを押してください.", // "다시 시작하려면 ‘홈으로 돌아가기’버튼을 눌러주세요.",
  '404.button': 'ホームに戻る', // "홈으로 돌아가기",

  // internal server error page
  'internal-server-error.title': 'サービスがしばらく遅れています.', // "서비스가 잠시 지연되고 있습니다.",
  'internal-server-error.subtitle':
    '問題を解決するために一生懸命努力しています.\nブログでスポーツ科学情報を見て回るか、3~5分後にボタンを押してもう一度試してみてください.', // "문제를 해결하기 위해 열심히 노력하고 있습니다.\n블로그를 통해 스포츠 과학 정보를 둘러보거나 3-5분 뒤에 버튼을 눌러 다시 시도해 보세요.",
  'internal-server-error.button': 'もう一度試してみる', // "다시 시도해 보기",

  // team-new page
  'team-new.title': 'ようこそ。{name}様！', // "환영합니다. {name}님!",
  'team-new.subtitle':
    'プルコ for Coachは選手たちのコンディション、運動負荷、痛み及び負傷データをモニタリングして管理するサービスです.', // "플코 for Coach는 선수들의 컨디션, 운동 부하, 통증 및 부상 데이터를 모니터링하고 관리하는 서비스입니다.",
  'team-new.create.title': 'プルコ for Coachでチームを作ってメンバーを招待してみてください!', // "플코 for Coach에서 팀을 만들고 멤버들을 초대해 보세요!",
  'team-new.create.button': 'チーム作り', // "팀 만들기",
  'team-new.join.title': '招待されたチームはありますか？ 私が受け取ったコードを入力する.', // "초대받은 팀이 있나요? 내가 받은 코드 입력하기.",
  'team-new.join.button': 'コード入力', // "코드 입력",

  // modal-team-create
  'modal-team-create.header.title': 'チーム作り', // "팀 만들기",
  'modal-team-create.footer.left.button': 'キャンセル', // "취소",
  'modal-team-create.footer.right.button': 'チーム作り', // "팀 만들기",
  'modal-team-create.name.label': 'チーム名(必須)', // "팀 이름(필수)",
  'modal-team-create.name.placeholder': 'チーム名を入力してください.(ex:プルコFC)', // "팀 이름을 입력해주세요. (예: 플코 FC)",
  'modal-team-create.category.label': '種目(必須)', // "종목(필수)", @주빈님
  'modal-team-create.nationality.label': '国籍(必須)', // "국적(필수)", @주빈님
  'modal-team-create.region.label': '国(必須)', // "국가(필수)", @주빈님
  'modal-team-create.url.label': 'チームURL', // "팀 URL", @주빈님
  'modal-team-create.url.placeholder': '英文、数字可能', // "영문, 숫자 가능", @주빈님

  // modal-team-create-loading
  'modal-team-create-loading.loading.title': '素敵なチームを作っています...', // "멋진 팀을 만들고 있습니다...",
  'modal-team-create-loading.loading.subtitle': '少々お待ちください.', // "잠시만 기다려 주세요.",
  'modal-team-create-loading.success.title': 'チーム作り成功！', // "팀만들기 성공!",
  'modal-team-create-loading.error.title': 'チーム作り失敗', // "팀만들기 실패",
  'modal-team-create-loading.error.subtitle': 'もう一度お試しください.', // "다시 시도해 주세요.",

  // modal-team-join
  'modal-team-join.header.title': 'チームのコード入力', // "팀 코드 입력",
  'modal-team-join.footer.left.button': 'キャンセル', // "취소",
  'modal-team-join.footer.right.button': '確認', // "확인",
  'modal-team-join.title':
    '英文、数字の6桁の組み合わせのチームコードを入力し、確認をクリックしてください。', // "영문, 숫자 6자리 조합으로 된 팀 코드를 입력한 후 확인을 클릭하세요.",
  'modal-team-join.code.placeholder': 'チームコード入力', // "팀 코드 입력",

  // modal-team-join-success
  'modal-team-join-success.footer.right.button': '確認', // "확인",
  'modal-team-join-success.title': '{name}接続成功', // "{name} 연결 성공",
  'modal-team-join-success.subtitle': '指導者が受諾すればチームの連結が完了します.', // "지도자가 수락하면 팀연결이 완료돼요.",

  // modal-logout
  'modal-logout.title': '本当にログアウトしますか？', // "정말로 로그아웃 하시겠습니까?",

  // modal-leave
  'modal-leave.title': '本当にチームから出ますか?', // "정말로 팀에서 나가시겠습니까?",

  // modal-kickout
  'modal-kickout.title': '{name}様をチームからエクスポートしますか？', // "{name}님을 팀에서 내보내시겠습니까?",

  // modal-transfer
  'modal-transfer.title': '{userName}様に{teamName}の所有権を変更しますか？', // "{userName}님에게 {teamName}에 대한 소유권 변경을 하시겠습니까?",

  // modal-check-condition-reminder
  'modal-check-condition-reminder.title.smOnly':
    '{count}名の選手にコンディションチェックアラームを\n送信しますか？', // "{count}명 선수들에게 컨디션 체크 알림을 \n 보내시겠습니까?",
  'modal-check-condition-reminder.title.mdAndUp':
    '{count}名の選手に\nコンディションチェックアラームをお送りしますか？', // "{count}명 선수들에게 \n 컨디션 체크 알림을 보내시겠습니까?",

  // modal-check-workload-reminder
  'modal-check-workload-reminder.title.smOnly':
    '{count}名の選手に運動負荷チェックアラームを\n送信しますか？', // "{count}명 선수들에게 운동 체크 알림을 \n 보내시겠습니까?",
  'modal-check-workload-reminder.title.mdAndUp':
    '{count}名の選手に\n運動負荷チェックアラームをお送りしますか？', // "{count}명 선수들에게 \n 운동 체크 알림을 보내시겠습니까?",

  // navbar-user-dropdown
  'navbar-user-dropdown.edit-profile': 'プロフィール修正', // "프로필 수정",
  'navbar-user-dropdown.logout': 'ログアウト', // "로그아웃",

  // navbar-team-dropdown
  'navbar-team-dropdown.team': '全メンバー{membersCount}名·選手{playersCount}名', // "전체 멤버 {membersCount}명 · 선수 {playersCount}명",
  'navbar-team-dropdown.edit-team-profile': 'チームプロフィール修正', // "팀 프로필 수정",
  'navbar-team-dropdown.team-management': 'メンバーおよび権限', // "멤버 및 권한",
  'navbar-team-dropdown.team-invite': 'チームへ招待', // "팀으로 초대",
  'navbar-team-dropdown.team-list': '全体チーム ({count})', // "전체 팀 ({count})",
  'navbar-team-dropdown.create-team': 'チーム作り', // "팀 만들기",
  'navbar-team-dropdown.payment': 'プラン & 決済', // "플랜 & 결제",
  'navbar-team-dropdown.team-code.title': '招待されたチームはありますか？', // "초대 받은 팀이 있으신가요?",
  'navbar-team-dropdown.enter-team-code': 'チームコード入力', // "팀 코드 입력",

  // navbar-players
  'navbar-players.player-data': '選手データ', // "선수 데이터",
  'navbar-players.back': '戻る', // "돌아가기",

  // main-drawer
  'main-drawer.item.dashboard': 'ダッシュボード', // "대시보드",
  'main-drawer.item.team-chart': 'チームチャート', // '팀 차트',
  'main-drawer.item.insight': 'インサイト', // "인사이트",
  'main-drawer.item.monitoring': 'チームモニタリング', // "팀 모니터링",
  'main-drawer.item.player': '選手団', // "선수단",
  'main-drawer.item.scheduler': '日程', // "일정",
  'main-drawer.item.blog': 'ブログ', // "블로그",
  'main-drawer.item.blog.chip': '無料体験D-{leftDay}', // "무료체험 D-{leftDay}",

  // sub-drawer
  'sub-drawer.item.overview': '概要', // '개요',

  // drawer-language
  'drawer-language.language': '言語', // "언어",

  // drawer-footer
  'drawer-footer.copyright': '© plco for Coach',

  // drawer-players
  'drawer-players.search.placeholder': '選手検索', // "선수 검색",
  'drawer-players.starred-players': 'お気に入りの選手 ({playersCount})名', // "즐겨 찾는 선수 ({playersCount}명)",
  'drawer-players.players': '選手 ({playersCount}名)', // "선수 ({playersCount}명)",

  // team-member
  'team-member.back': '戻る', // "돌아가기",
  'team-member.access.title': 'メンバーおよび権限', // "멤버 및 권한",

  // dropdown-menu-team-member
  'dropdown-menu-team-member.leave': 'チームを出る', // "팀 나가기",
  'dropdown-menu-team-member.kickout': 'チームから出す', // "팀 내보내기",
  'dropdown-menu-team-member.transfer': '所有権変更', // "소유권 변경",

  // monitoring
  'monitoring.invite.pending.title':
    '管理者の承認を待っています.承認が終わったら、もう一度ログインしてみてください.', // "관리자의 승인을 기다리는 중입니다. 승인을 마치면 다시 로그인 해보세요.",
  'monitoring-header.title': 'チームモニタリング', // "팀 모니터링",
  'monitoring-header.update': 'アップデート', // "업데이트",
  'monitoring.modal.title': 'チームへ招待', // "팀으로 초대",
  'monitoring.modal.team.code': 'チームコード', // "팀 코드",
  'monitoring.modal.description.top': '誰に招待メッセージを送りたいですか？', // "누구에게 초대 메시지를 보내고 싶나요?",
  'monitoring.modal.description.bottom': 'リンクをコピーして渡してみてください.', // "링크를 복사해서 전달해 보세요.",
  'monitoring.modal.coach': '指導者', // "지도자",
  'monitoring.modal.player': '選手', // "선수",
  'monitoring.modal.clipboard.toasts.success': 'チーム招待メッセージがコピーされました.', // "팀 초대 메시지가 복사되었습니다.",
  'monitoring.modal.clipboard-team-code.toasts.success':
    'チームコードがクリップボードにコピーされました.', // "팀코드가 클립보드에 복사 되었습니다.",
  'monitoring.chart.title': 'チームチャート', // "팀 차트",
  'monitoring.table.title': 'チームデータ', // "팀 데이터",
  'monitoring.table.workout.memo-button': '運動 メモ',
  'monitoring.modal-workout-memo.title': '運動 メモ',
  'monitoring.modal-workout-memo.empty': '入力された運動メモがありません。',
  'monitoring.table.noData': 'まだメンバーがいないですね...', // "아직 멤버가 없네요...",
  'monitoring.table.soreness.degree': '痛み{degree, selectordinal, other{#段階}}', // "통증 {degree, selectordinal, other {#단계}}",
  'monitoring.table.data-sorting': 'データ整列', // "데이터 정렬",
  'monitoring.table.clear-sorting': '整列初期化', // "정렬 초기화",
  'monitoring.table.data': 'データㅣ', // "데이터",
  'monitoring.table.sorting': '整列', // "정렬",
  'monitoring.table.asc': '上がる次順', // "오름차순",
  'monitoring.table.desc': '下りの次順', // "내림차순",
  'monitoring.period-button.1_DAY': '一日', // "1일",
  'monitoring.period-button.7_DAYS': '以前7日', // "지난 7일",
  'monitoring.period-button.28_DAYS': '以前28日', // "지난 28일",
  'monitoring.chart.tooltip.close': '閉じる',
  'monitoring.chart.tooltip.another-team.title': '運動/練習', // 운동/훈련
  'monitoring.chart.workload-count': '合計 {count}', // 총 {count}회

  // unauthorized
  'unauthorized.title': 'おっと、まだコンテンツを見ることができません.', // "이런, 아직 컨텐츠를 볼 수 없습니다.",
  'unauthorized.subtitle.first': '管理者が承認するまでしばらくお待ちください.', // "관리자가 승인할 때까지 잠시만 기다려주세요.",
  'unauthorized.subtitle.second': '承認が完了したら、最新の情報報告書(F5)してみてください.', // "승인이 완료 되면 새로고침(F5) 해보세요.",

  // calendar
  'calendar.navigationLabel.decade': '年度選択', // "년도 선택",

  // group
  'group-monitoring.dropdal-player-select.subtitle': '{playersCount}名 選択された', // "{playersCount}명 선택됨",
  'group-monitoring.dropdal-player-select.placeholder.groups-and-players': 'グループ/選手 検索', // "그룹/선수 검색",
  'group-monitoring.dropdal-player-select.placeholder.players': '選手 検索', // "선수 검색",
  'group-monitoring.dropdal-player-select.select-all': '全体 選択({playersCount}名)', // "전체 선택 ({playersCount}명)",

  'group-monitoring.group-button.create': 'グループ追加 ({count}/5)', // "그룹 추가",
  'group-monitoring.modal-group.create.cancel': 'グループの追加をキャンセルしますか？', // "그룹 추가를 취소하겠습니까?",
  'group-monitoring.modal-group.create.header': 'グループ追加', // "그룹 추가",

  'group-monitoring.group-button.setting': 'グループの設定', // '그룹 설정',
  'group-monitoring.modal-group.setting.header': 'グループの設定', // '그룹 설정',

  'group-monitoring.modal-group.guide':
    '保存されたグループ情報は、すべてのコーチに均一に反映されます。', // '저장한 그룹 정보는 모든 지도자에게 동일하게 반영됩니다.',

  'group-monitoring.modal-group.modify.header': 'グループ修正', // "그룹 수정",
  'group-monitoring.modal-group.setting.cancel': 'グループの修正をキャンセルしますか？', // "그룹 수정을 취소하시겠습니까?",
  'group-monitoring.modal-group.setting.delete-button': 'このグループを削除', // "이 그룹 삭제하기",

  'group-monitoring.modal-group.input.label': 'グループ名', // "그룹 이름",
  'group-monitoring.modal-group.input.placeholder': 'グループ名を作成してください.(最大10文字)', // "그룹 이름을 만들어 주세요.(최대 10자)",

  'group-monitoring.modal-group.group-list.header.title': 'グループを選択', // '그룹 선택',

  'group-monitoring.modal-group.member-list.header.title': 'メンバー選択', // "멤버 선택",
  'group-monitoring.modal-group.member-list.header.subtitle':
    'グループに入るメンバーを選択してください。', // "그룹에 들어갈 멤버를 선택해 주세요",
  'group-monitoring.modal-group.member-list.placeholder': '選手 検索', // "선수 검색",
  'group-monitoring.modal-group.member-list.select-all': '全体選択({playersCount}名)', // "전체 선택 ({playersCount}명)",

  'group-monitoring.modal-group-delete.text': '本当にグループを削除しますか？', // "정말로 그룹을 삭제하시겠습니까?",

  // scheduler
  'scheduler.header.title': '日程', // "일정",
  'scheduler.header.button.create-event': '日程追加', // "일정 추가",
  'scheduler.days.date.event.more': '個 もっと見る···', // "개 더보기...",
  'scheduler.days.date.event.count': '個', // "개",
  'scheduler.modal-event.create.header': '日程追加', // "일정 추가",
  'scheduler.modal-event.duplicate.header': '日程複写', // "일정 복제",
  'scheduler.modal-event.edit.header': '日程修正', // "일정 수정",
  'scheduler.modal-event.event-name.field-input.label': '日程名', // "일정 이름",
  'scheduler.modal-event.event-name.field-input.placeholder':
    '日程の名前をつけてください. （50文字以内）', // "일정 이름을 지어주세요. (50자 이내)",
  'scheduler.modal-event.event-location.field-input.label': '場所', // "위치",
  'scheduler.modal-event.event-location.field-input.placeholder': '場所を入力してください.', // "위치를 입력하세요.",
  'scheduler.modal-event.event-date.field-input-date.start.label': '始まり', // "시작",
  'scheduler.modal-event.event-date.field-input-date.end.label': '終わり', // "종료",
  'scheduler.modal-event.expected-intensity.label': '目標強度', // "목표 강도",
  'scheduler.modal-event.expected-intensity.1': '非常に軽い程度', // "아주 가벼운 정도",
  'scheduler.modal-event.expected-intensity.2': '対話が可能な程度の低強度', // "대화가 가능한 정도의 저강도",
  'scheduler.modal-event.expected-intensity.3': '対話が可能な程度の低強度', // "대화가 가능한 정도의 저강도",
  'scheduler.modal-event.expected-intensity.4': '呼吸が適度に冷たい中強さ', // "호흡이 적당히 차는 중강도",
  'scheduler.modal-event.expected-intensity.5': '呼吸が適度に冷たい中強さ', // "호흡이 적당히 차는 중강도",
  'scheduler.modal-event.expected-intensity.6': '呼吸が適度に冷たい中強さ', // "호흡이 적당히 차는 중강도",
  'scheduler.modal-event.expected-intensity.7': '呼吸が比較的難しい高強度', // "호흡이 비교적 어려운 고강도",
  'scheduler.modal-event.expected-intensity.8': '呼吸が比較的難しい高強度', // "호흡이 비교적 어려운 고강도",
  'scheduler.modal-event.expected-intensity.9': '呼吸が比較的難しい高強度', // "호흡이 비교적 어려운 고강도",
  'scheduler.modal-event.expected-intensity.10': '対話不能状態の最大強度', // "대화가 불가능한 상태의 최대 강도",
  'scheduler.modal-event.participant.label': '参加対象', // "참가 대상",
  'scheduler.modal-event.participant.count': '{playersCount}名選択された', // "{playersCount}명 선택됨",
  'scheduler.modal-event.training.textarea.label': '練習のお知らせ', // "훈련 공지"
  'scheduler.modal-event.training.textarea.placeholder':
    'トレーニングプログラムや選手に知らせたい内容を書いてください。\n選手にコンテンツが共有されます\n例) 今日のトレーニングプ：ウォームアップ - ロンド - 1:1パス',
  // "일정을 자세하게 입력하거나 선수들에게 공지하고 싶은 내용을 적어보세요.\n선수들에게 내용이 공유됩니다.\n예시) 오늘의 훈련 프로그램: 웜업 - 5:2 - 슛팅 - 1:1 패스",
  'scheduler.modal-event.textarea.placeholder': 'スケジュールを詳しく入力してください.', // "일정을 자세하게 입력하세요."

  'scheduler.modal-event.caution.expected-intensity':
    '目標強度、参加対象、メモは管理者のみが閲覧できます.', // "목표 강도, 참가 대상, 메모는 관리자만 볼 수 있습니다.",
  'scheduler.modal-event.caution': '参加対象、メモは管理者のみ閲覧できます.', // "참가 대상, 메모는 관리자만 볼 수 있습니다.",

  'scheduler.modal-event.more-items.duplicate': '日程複写', // "일정 복제",
  'scheduler.modal-event.more-items.modify': '日程修正', // "일정 수정",
  'scheduler.modal-event.more-items.delete': '日程削除', // "일정 삭제",

  'scheduler.modal-event.create.cancel.title': '本当にスケジュール作りをキャンセルしますか？', // "정말로 일정 만들기를 취소하시겠습니까?",
  'scheduler.modal-event.duplicate.cancel.title': '本当にスケジュールの複製をキャンセルしますか？', // "정말로 일정 복제를 취소하시겠습니까?""
  'scheduler.modal-event.modify.cancel.title': '本当に日程の修正を取り消しますか。', // "정말로 일정 수정을 취소하시겠습니까?",
  'scheduler.modal-event.delete.cancel.title': '本当に日程を削除しますか？', // "정말로 일정을 삭제하시겠습니까?",

  'scheduler.overlay.participants': '参加対象 ({participants}名)', // "참가대상 ({participants}명)",
  'scheduler.overlay.participants.more': '+ もっと見る…', // "+ 더보기...",
  'scheduler.overlay.notice-target': '告知対象 ({participants})', // '공지 대상 ({participants})',

  'scheduler.overlay.participants.total': '全人数 ({total})', // "전체인원 ({total})",
  'scheduler.overlay.participants.notification': '参加未チェック通知送信', // "참가여부 미체크 알림 보내기"
  'scheduler.overlay.all': '全体', // "전체",
  'scheduler.overlay.attend': '参加', // "참가",
  'scheduler.overlay.absent': '不参', // "불참",
  'scheduler.overlay.injured': 'けが', // "부상",
  'scheduler.overlay.etc': '他に理由', // "기타",
  'scheduler.overlay.unchecked': '未チェック', // "미체크",
  'scheduler.button.training-report': '練習レポートを見る', // "훈련 리포트 보기",
  'scheduler.modal-participant-notification.title':
    '{participants}名の選手に\n練習参加可否通知を送りますか？', // "{participatns}명의 선수에게 훈련 참가여부 알림을 보낼까요?",
  'scheduler.modal-event.memo.guide':
    '目標強度/参加対象は選手には見られませんが、練習のお知らせは選手に共有されます。', // "목표강도/참가대상은 선수들이 볼 수 없지만, 메모는 선수들에게 공유됩니다."

  'scheduler.modal-event.match-opponent.field-input.label': '試合相手', // '경기 상대',
  'scheduler.modal-event.match-opponent.field-input.placeholder':
    '相手チームまたは選手を入力してください。', // '상대팀 또는 선수를 입력하세요.',
  'scheduler.modal-event.match-competition.field-input.label': '大会', // '대회',
  'scheduler.modal-event.match-competition.field-input.placeholder':
    'リーグ/大会名などを入力してください。', // '리그/대회명 등을 입력하세요.',
  'scheduler.modal-event.match-location.field-input.label': '場所', // '장소',
  'scheduler.modal-event.match-location.field-input.placeholder': '試合場所を入力してください。', // '경기 장소를 입력하세요.',
  'scheduler.modal-event.match-event.field-input.label': '日程', // '일정',
  'scheduler.modal-event.match-venue.etc.button': 'その他', // '기타',
  'scheduler.modal-event.match-notice-target.field-input.label': '告知対象', // '공지 대상',
  'scheduler.modal-event.match-notice-target.field-input.guide':
    'お知らせ対象に含まれている選手を出場選手リストに登録することができます。', // '공지 대상에 포함된 선수를 출전 선수 명단에 등록할 수 있습니다.',
  'scheduler.modal-event.match-notice.field-input.label': 'お知らせ', // '공지'',
  'scheduler.modal-event.match-notice.textarea.label': '試合告知', // '경기 공지',
  'scheduler.modal-event.match-notice.textarea.placeholder':
    '試合関連選手たちに告知が必要な内容を書いてみてください。\n選手たちに内容が共有されます。', // '경기와 관련하여 선수들에게 공지가 필요한 내용을 적어보세요.\n선수들에게 내용이 공유됩니다.',
  'scheduler.modal-event.match-notice.textarea.guide':
    '2023年10月10日以前に保存した試合日程メモは公開されませんので、ご心配なく。', // '2023년 10월 10일 이전에 저장한 경기 일정 메모는 공개되지 않으니 걱정마세요.',
  'scheduler.button.match-report': '試合レポートを見る', // '경기 리포트 보기',
  'scheduler.button.practice-match-report': '練習試合レポートを見る', // '연습경기 리포트 보기',

  // match report
  'match-report.notice': 'お知らせ', // '공지',
  'match-report.result': '試合結果', // '경기 결과',
  'match-report.result-status.win': '勝', // '승',
  'match-report.result-status.draw': '引分', // '무',
  'match-report.result-status.lose': '敗', // '패',
  'match-report.result-textarea.placeholder':
    '試合の主要記録、コメント、戦術などを記録してみてください。', // '경기 주요 기록, 코멘트, 전술 등을 자유롭게 기록해보세요.',
  'match-report.lineup-and-record-card.title': '出場選手リストと記録', //  '출전 선수 명단 및 기록',
  'match-report.lineup-and-record-card-empty.title': '登録された出場選手なし', //  '등록된 출전 선수 없음',
  'match-report.lineup-and-record-card-empty.subtitle':
    '「出場選手登録」ボタンを押してリストを登録してください', // '출전 선수 등록 버튼을 눌러 명단을 등록해주세요',
  'match-report.lineup-and-record-card.table-label.part': '出戦', // '출전',
  'match-report.lineup-and-record-card.table-label.playing-time': '出場時間', // '출전 시간',
  'match-report.lineup-and-record-card.table-label.record-and-comment': '選手記録および評価です', // '선수 기록 및 평가',
  'match-report.lineup-and-record-card.modify-button.help':
    'ここをクリックして出場選手リストを修正できます。', //  '여기를 눌러 출전 선수 명단을 수정할 수 있어요',
  'match-report.lineup-register-modal.title': '出場選手登録', // '출전 선수 등록',
  'match-report.lineup-register-modal.search-input.placeholder': '選手検索', // '선수 검색',
  'match-report.lineup-register-modal.guide':
    'お知らせ対象として選択した選手がリストに表示されます。', // '공지 대상으로 선택한 선수가 리스트에 표시됩니다.',
  'match-report.lineup-register-modal.player-status.starting': '先発', // '선발',
  'match-report.lineup-register-modal.player-status.sub': '交代', // '교체',
  'match-report.lineup-register-modal.player-status.no-play': '出場なし', // '미출전',
  'match-report.lineup-register-modal.empty':
    '表示する選手がいません。\n日程の修正でお知らせ対象を先に選択してください。', // '표시할 선수가 없습니다.\n일정 수정에서 공지 대상을 먼저 선택해주세요.',
  'match-report.lineup-register-modal.no-result': '検索結果がありません。', // '검색 결과가 없습니다.',
  'match-report.lineup-data-card.title': '出場選手データ', // '출전 선수 데이터',
  'match-report.edit': '編集中', // '편집 중',
  'match-report.event-list.title': '告知対象 ({count}名)', // '공지 대상 ({count}명)',
  'match-report.all.player-list.empty.title': '選択された選手なし', //  '선택된 선수 없음',
  'match-report.all.player-list.empty.subTitle': 'お知らせ対象に選ばれた選手がいません。', // '공지 대상으로 선택된 선수들이 없어요.',

  // training report
  'training-report.location': '場所', // "위치",
  'training-report.date': '日付', // "날짜",
  'training-report.time': '時間', // "시간",
  'training-report.notice': 'お知らせ', // "공지",

  'training-report.attendance.percent': '参加率', // "참가율",

  'training-report.player-list.help': '番号を選択して名簿を検証してください', // "숫자를 누르고 각 명단을 확인해보세요",

  'training-report.attendance.workload.data.title': '参加選手運動負荷データ', // "참가선수 운동부하 데이터",
  'training-report.attendance.workload.data.list': 'リスト', // "리스트",
  'training-report.attendance.workload.data.chart': 'チャート', // "차트",

  'training-report.all': '全体', // "전체",
  'training-report.attend': '参加', // "참가",
  'training-report.injured': 'けが', // "부상",
  'training-report.etc': '他に理由', // "기타",
  'training-report.unchecked': '未チェック', // "미체크",

  'training-report.all.player-list.empty.title': '選択した選手なし', //  "선택된 선수 없음",
  'training-report.all.player-list.empty.subTitle': '参加対象に選ばれた選手がいません。', // "참가 대상으로 선택된 선수들이 없어요.",

  'training-report.attend.player-list.empty.title': '参加選手なし', // "참가 선수 없음",
  'training-report.attend.player-list.empty.subTitle': 'まだ「参加」にチェックした選手がいません。', // "아직 ‘참가'에 체크한 선수들이 없어요.",

  'training-report.injured.player-list.empty.title': 'けが（不参）選手なし', // "부상(불참) 선수 없음",
  'training-report.injured.player-list.empty.subTitle': 'けがで参加しない選手はいません👍', // "부상으로 불참하는 선수가 없어요 👍",

  'training-report.etc.player-list.empty.title': '他に理由(不参)選手なし', // "기타(불참) 선수 없음",
  'training-report.etc.player-list.empty.subTitle': '個人事情で参加しない選手はいません👍', // "개인사정으로 불참하는 선수가 없어요 👍",

  'training-report.unchecked.player-list.empty.title': '未チェック選手なし', // "미체크 선수 없음",
  'training-report.unchecked.player-list.empty.subTitle':
    'すべての選手が参加/不参をチェックしました👍', // "모든 선수들이 참가/불참을 체크했어요 👍",

  'training-report.attendance.workload.data.empty': 'まだ参加をチェックした選手がいませんね…', // "아직 참가를 체크한 선수가 없네요...",

  // tutorial @주빈님 확인
  'tutorial.end': '알겠어요',
  'tutorial.step.0.title': '멤버들을 초대하세요. 👋',
  'tutorial.step.0.content':
    '플코 for Coach는 데이터 모니터링을 통해 멤버들의 상태를 파악하고 올바른 코칭을 할 수 있는 서비스입니다. ‘팀 코드로 초대’를 눌러 멤버들을 초대해 보세요.',
  'tutorial.step.0.searchTerm': '‘팀 코드로 초대’',
  'tutorial.step.1.title': '팀코드와 초대 메시지를 복사하세요. 👍',
  'tutorial.step.1.content':
    '팀코드와 초대 메시지를 복사하고 초대할 멤버들에게 메신저나 문자 메시지를 통해 팀 코드를 전달하세요.',
  'tutorial.step.1.searchTerm': '팀코드와 초대 메시지를 복사',
  'tutorial.step.2.title': ' ',
  'tutorial.step.2.content': ' ',
  'tutorial.step.2.searchTerm': ' ',
  'tutorial.step.3.title': '초대한 멤버들을 멤버 및 권한에서 확인하세요. 🙋🏻‍♂️',
  'tutorial.step.3.content': '‘멤버 및 권한’를 눌러서 어떤 멤버들이 있는지 확인해 보세요.',
  'tutorial.step.3.searchTerm': '‘멤버 및 권한’',
  'tutorial.step.4.title': '초대한 멤버들을 멤버 및 권한에서 확인하세요. 🙋🏻‍♂️',
  'tutorial.step.4.content': '‘멤버 및 권한’를 눌러서 어떤 멤버들이 있는지 확인해 보세요.',
  'tutorial.step.4.searchTerm': '‘멤버 및 권한’',
  'tutorial.step.5.title': '선수 요청을 수락해서 팀에 합류시켜 보세요. ✅',
  'tutorial.step.5.content':
    '지금은 아무도 없기 때문에 가상의 선수를 미리 추가해놨어요. 간혹 내가 초대하지 않은 멤버가 들어올 수 있어요. 그럴 때는 거절을 누르세요. 그러면 해당 선수는 팀에 합류할 수 없어요.',
  'tutorial.step.5.searchTerm': '가상의 선수를 미리 추가',
  'tutorial.step.6.title': '권한을 설정하세요. 🤝',
  'tutorial.step.6.content':
    "요청을 수락하게 되면 처음은 모두 멤버(선수) 권한을 가지게 됩니다. 요청한 사람이 코치 또는 감독이라면 꼭 '관리자'로 변경하셔야 합니다. 권한을 올바르게 선택해 보세요.",
  'tutorial.step.6.searchTerm': '처음은 모두 멤버(선수) 권한',
  'tutorial.step.6.subContent':
    '소유자: 팀을 만든 사람입니다. 멤버들의 그룹을 설정할 수 있습니다. 일정을 생성하고, 수정, 삭제 할 수 있습니다. 필요하면 팀을 삭제 할 수 있습니다.\n\n관리자(코치 또는 감독): 멤버의 데이터를 모니터링 할 수 있습니다. 일정을 생성하고 수정, 삭제할 수 있습니다.\n\n멤버(선수): 팀에 속하는 선수입니다.',
  'tutorial.step.7.title': '멤버를 팀에서 내보내 보세요. ➡️',
  'tutorial.step.7.content':
    '이제 가상의 선수는 할 일을 다했습니다. ‘팀 내보내기’를 눌러 팀에서 내보내 보세요.',
  'tutorial.step.7.searchTerm': '‘팀 내보내기’',
  'tutorial.step.8.title': ' ',
  'tutorial.step.8.content': ' ',
  'tutorial.step.8.searchTerm': ' ',
  'tutorial.step.9.title': '멤버 초대 안내 완료 🙌',
  'tutorial.step.9.content':
    '잘하셨습니다. 이제 실제 멤버들을 초대하세요. 요청을 수락하고 권한을 변경하세요. 그리고 데이터를 모니터링하세요.',
  'tutorial.step.9.searchTerm': '이제 실제 멤버들을 초대하세요.',
  'tutorial.restart.title': '팀 멤버 초대 방법을 알고 싶나요? 🙌',
  'tutorial.restart.subTitle': '튜토리얼을 통해 멤버 초대 방법에 대해서 알아보세요.',
  'tutorial.virtualMember.name': '(가상) 김선수',
  'tutorial.virtualMember.sportName': '축구',
  'tutorial.virtualMember.sportPosition': '미드필더',
  'tutorial.virtualMember.sportRole': '선수',

  // banner-subscribe
  'banner-subscribe.title.first': '無料体験後も引き続き', // "플랜 구독하고",
  'banner-subscribe.title.second': 'plcoをお試しください', // "모니터링을 계속해 보세요",
  'banner-subscribe.action': 'プラン購読', // "플랜 구독하기",

  // banner-update
  'banner-update.title': 'もし変わった点に気づきましたか？', // '혹시 달라진 점을 눈치채셨나요?',
  'banner-update.subtext': '今すぐ「アップデートのニュース」\nを確認してください!', // '지금 바로 ‘업데이트 소식’을\n확인하세요!',

  // card
  'card-teams-invite.title': 'チームへ招待', // "팀으로 초대",
  'card-teams-invite.content': 'チームメンバーを招待してください.', // "팀 멤버를 초대하세요",
  'card-teams-invite.instruction': '上段左側の{place}でいつでもメンバーを招待できます.', // "상단 왼쪽에 {place}에서 언제든 멤버를 초대할 수 있어요.",
  'card-teams-invite.instruction.place': 'チームメニュー>チームに招待', // "팀 메뉴>'팀으로 초대'",

  // info tooltip
  'info-tooltip.player-detail': 'これで選手の個人データを確認できます！', // "이제 선수의 개인 데이터를 확인할 수 있어요!",
  'info-tooltip.transfer-ownership': 'チームの所有者に権限が変更されました！', // "팀에 소유자로 권한이 변경 됐습니다!",

  // field-input
  'field-input.label.name': '名前', // "이름",
  'field-input.label.nationality': '国籍', // "국적",
  'field-input.label.gender': '性別', // "성별",
  'field-input.label.birthdate': '生年月日', // "생년월일",
  'field-input.label.phone-number': '携帯番号', // "휴대폰 번호",
  'field-input.label.role': '役割', // "역할",
  'field-input.label.position': '職責', // "직책",
  'field-input.label.sportsCategory': '種目', // "종목",
  'field-input.name.placeholder': '名前入力', // "이름 입력",
  'field-input.birthdate.placeholder': '生年月日入力(ex:1994-05-01)', // "생년월일 입력(예:1994-05-01)",
  'field-input.phone-number.placeholder': '휴대폰 번호 입력 (ex: 010-1234-5678)', // "휴대폰 번호 입력 (예: 010-1234-5678)",
  'field-input.username.placeholder': 'IDを入力', // "ID를 입력",

  // chart data
  'chart.data.not.checked': 'チェック無し', // "체크안함",
  'chart.data.none': 'なし', // "없음",

  // report
  'report.exception.mobile': '練習レポートはパソコンまたはタブレットでご確認ください。', // "리포트는\nPC 또는 태블릿에서 확인해주세요.",
  'report.exception.authority.title': 'この 練習レポートを見ることはできません', // "이 리포트를 볼 수 없어요",
  'report.exception.authority.subtext':
    '私たちのチームのスケジュールは正しいですか？\n他のチームの予定であるか、すでに削除された予定ではないことを確認してください。', // "우리 팀의 일정이 맞나요?\n다른 팀의 일정이거나, 이미 삭제된 일정은 아닌지 확인해보세요.",

  // match report
  'match-report.modal.header': '試合レポート', // '경기 리포트',

  // practice-match report
  'practice-match-report.modal.header': '練習試合レポート', // '연습경기 리포트',

  // training report
  'training-report.modal.header': '練習レポート', // "훈련 리포트",
  'training-report.workload-intensity-compare.title': '運動強度運動強度', // "운동강도 비교",
  'training-report.workload-intensity-checked':
    '参加選手{totalCount}名中{checkedCount}名運動負荷チェック完了', // "참가선수 {totalCount}명 중 {checkedCount}명 운동부하 체크 완료",
  'training-report.aim-intesity-ballon': '目標強度', // "목표강도",
  'training-report.aim-intensity.title': '目標強度', // "목표강도",
  'training-report.real-intensity.title': '実強度', // "실제강도",
  'training-report.real-intensity.label': 'チーム平均*', // "팀 평균*",
  'training-report.real-intensity.empty': '運動負荷チェック待機中', // "운동부하 체크 대기 중",
  'training-report.training-point.title': '練習ポイント', // "훈련 포인트",
  'training-report.training-point.placeholder':
    '練習の目標と目的、実際の練習内容と結果などを記録してください。\n\n例）試合前の練習だから強度が低い', // "훈련의 목표와 목적, 실제 훈련 내용과 결과 등을 기록해보세요.\n\n예시) 경기 전 훈련이기에 강도 낮게 진행함",
  'training-report.special-notes.title': '特記事項', // "특이사항",
  'training-report.special-notes.placeholder':
    '練習中にあった特異事項、感じた点などを記録してみてください。\n\n例）前日、高強度練習のせいか選手たちが疲れて追いにくい傾向が見られる', // "훈련 중 있던 특이사항, 느낀점 등을 기록해보세요.\n\n예시) 전날 고강도 훈련 때문인지 선수들이 쳐지는 경향을 보임",

  // export report
  'report.export.help': 'レポートをPDFまたは画像として保存できます', // '리포트를 PDF/이미지로 저장할 수 있어요',
  'report.export-modal.title': 'レポートをエクスポート', // '리포트 내보내기',
  'report.export-modal.save-pdf.button': 'PDF で保存', // 'PDF로 저장',
  'report.export-modal.save-img.button': 'イメージで保存', // '이미지로 저장',
  'report.export-modal.save.loading.text': '保存中', // '저장 중',
  'report.export-modal.save.success.text': '保存成功', // '저장 성공',
  'report.export-modal.save.failed.text': '保存失敗', // '저장 실패',

  // player injury modal
  'player.modal-injury.register.header': '負傷登録', // '부상 등록',
  'player.modal-injury.edit.header': '負傷修正', // '부상 수정',
  'player.modal-injury.detail.header': '負傷の詳細を表示', // '부상 상세보기',
  'player.modal-injury.injury-dummy.front': '前', // '앞',
  'player.modal-injury.injury-dummy.back': '後', //'뒤',
  'player.modal-injury.selection.part.text': '負傷部位選択', // '부상 부위 선택',
  'player.modal-injury.selection.part.none.subtext': '選択された部位なし', // '선택된 부위 없음',
  'player.modal-injury.selection.part.guide': 'マネキンを押して負傷部位を選択してください', // '마네킹을 눌러 부상 부위를 선택해주세요',
  'player.modal-injury.detail-selection.header': '詳細情報入力', // '상세 정보 입력',
  'player.modal-injury.detail-selection.date.title': '負傷日', // '부상 날짜',
  'player.modal-injury.detail-selection.relapse.text': '再発', // '재발',
  'player.modal-injury.detail-selection.cause.title': '原因及び症状', // '원인 및 증상',
  'player.modal-injury.detail-selection.cause.placeholder':
    '負傷が発生した背景、状況、症状を入力してください。', // '부상이 발생한 배경, 상황, 증상 등을 입력하세요.',
  'player.modal-injury.detail-selection.diagnosis.title': '診断名', // '진단명',
  'player.modal-injury.detail-selection.diagnosis.placeholder': '診断名を入力してください。', // '진단명을 입력하세요.',
  'player.modal-injury.detail-selection.medical-record.title': '診療記録', // '진료 기록',
  'player.modal-injury.detail-selection.medical-record.placeholder':
    '診療日、病院、診断方法などを入力してください。', // '진료일, 병원, 진단 방법 등을 입력하세요.',
  'player.modal-injury.detail-selection.return-date.title': '復帰日', // '복귀 날짜',
  'player.modal-injury.detail-selection.return-date.undecide': '未定', // '미정',
  'player.modal-injury.detail-selection.return-info.title': '復帰情報', // '복귀 정보',
  'player.modal-injury.detail-selection.return-info.placeholder':
    'リハビリ期間、復帰予定日などを入力してください。', // '재활 기간, 복귀 예상 시기 등을 입력하세요.',
  'player.modal-injury.detail-selection.injury-ended.title': '負傷終了', // '부상 종료',
  'player.modal-injury.detail-selection.injury-ended.subtitle':
    'この負傷を過去のけがとして保存します。', // '이 부상을 과거 부상으로 저장합니다',
  'player.modal-injury.detail-selection.file.button': 'ファイルを添付', // '파일 첨부',
  'player.modal-injury.detail-selection.file.text':
    '関連する写真や文書を添付できます\n*10MB以内のファイルを最大3つ添付できます', // '관련 사진이나 문서를 첨부할 수 있어요\n*10MB 이내의 파일 최대 3개',
  'player.modal-injury.register-cancel.title': '本当に負傷登録を取り消しますか？', // '정말로 부상 등록을 취소하시겠습니까?',
  'player.modal-injury.update-cancel.title': '本当に負傷の修正を取り消しますか？', // '정말로 부상 수정을 취소하시겠습니까?',
  'player.modal-injury.delete.title': '本当に負傷を削除しますか？', // '정말로 부상을 삭제하시겠습니까?',

  'menu.chat-support': 'チャットの問い合わせ',
  'menu.lanuage': 'チャットの問い合わせ',
  'menu.notification': 'お知らせ',
  'menu.my-profile': '私のプロフィール',

  'fab.send-feedback': 'フィードバック登録',
  'fab.select-feedback-player': 'フィードバック選手を選択してください',
  'fab.search-players': '検索',
  'fab.select': '選択',
  'fab.no-results-found': '検索結果がありません。',
  'fab.cancel': 'キャンセル',
  'fab.next': '次',

  'notification.updates': 'アップデート',
  'notification.notable-players': '主要選手',
  'notification.make-all-as-read': '全て既読にする',
  'notification.COMMENT_CREATED':
    '{user}さんが{player}さんの{title}フィードバックにコメントしました',
  'notification.COMMENT_CREATED_NO_FEEDBACK': '{user}さんが{player}さんの{title}にコメントしました',
  'notification.FEEDBACK_CREATED':
    '{author}さんが{player}さんに{title}のフィードバックを送りました',
  'notification.FEEDBACK_CREATED_NO_FEEDBACK': '{author}さんが{player}さんに{title}のを送りました',
  'notification.FEEDBACK_REQUESTED': '{player}さんから新しいフィードバックリクエストが届きました',

  'notification.feedback-deleted': 'このフィードバックは削除されました',
  'notification.feedback-request-deleted': 'このフィードバックは削除されました',
  'notification.comment-deleted': 'このコメントは削除されました',
  'notification.no-update-notifications': 'アップデートのお知らせはありません',
  'notification.notify-request-or-comments':
    '新しいフィードバックリクエストや\nコメントなどがあればお知らせします',
  'notification.general': '一般',
  'notification.format.a-week-ago': '一週間前',
  'notification.format.today': 'HH:mm',

  feedback: 'フィードバック',
  'feedback.no-feedback-yet': 'まだフィードバックがありません',
  'feedback.send-feedback': 'フィードバックを登録',
  'feedback.choose-feedback': '{player}さんに\nどのようなフィードバックを送りますか？',
  'feedback.suggestion-description':
    '自由に書くか、PLCOが提供する提案から選んでください。\n提案は常に最新情報です。',
  'feedback.general-feedback': '一般フィードバック',
  'feedback.emoji-general-feedback': '🙂 一般フィードバック',
  'feedback.write-freely': '自由に書く',
  'feedback.plco-suggestions': '✨PLCO 提案',
  'feedback.tag.NORMAL': '一般フィードバック',
  'feedback.tag.REQUEST': 'フィードバックリクエスト', // TODO: 맞는건지 확인 필요
  'feedback.tag.TRAINING': 'トレーニング メモ',
  'feedback.tag.MATCH': '試合 メモ',
  'feedback.tag.PRACTICE': '練習試合 メモ',
  'feedback.tag.WORKLOAD_MEMO': '運動 メモ',
  'feedback.tag.WORKLOAD_RISK_AT_INJURY': '運動負荷',
  'feedback.tag.CONDITION_NOT_GOOD': 'コンディション ',
  'feedback.tag.CONDITION_PLUMMET': 'コンディション ',
  'feedback.tag.SORENESS_SEVERE': '痛み',
  'feedback.type.normal-workout': '一般運動',
  'feedback.type.RISK_INCREASED': '負傷リスクの増加',
  'feedback.type.RISK_CAUTION': '負傷リスクの注意',
  'feedback.type.RISK_DETECTED': '負傷リスクの検出',
  'feedback.type.severe-soreness': '深刻な痛み',
  'feedback.type.bad-condition': '悪いコンディション',
  'feedback.type.plummeted-condition': '急落したコンディション',
  'feedback.back': '戻る',
  'feedback.next': '次へ',
  'feedback.save': '保存',
  'feedback.no-specific-suggestions': '特記事項はありません',
  'feedback.enter-your-feedback': 'フィードバックを入力してください',
  'feedback.data-for-date': 'YYYY年M月DD日のデータ',
  'feedback.injury.reason.HIGH_ACWR': '高いACWR',
  'feedback.injury.reason.LOW_ACWR': '低いACWR',
  'feedback.injury.reason.WEEKLY_WORKLOAD_SURGE': '先週に比べて週間の運動負荷が急増',
  'feedback.injury.reason.STRAIN': 'ストレイン',
  'feedback.injury.reason.HIGH_WEEKLY_WORKLOAD': '高い週間ワークロード',
  'feedback.injury.reason.MONOTONY': '単調さ',
  'feedback.injury.reason.NON_ELASTIC_INTENSITY': '弾力性のない強度',
  'feedback.player-status-description':
    '{player}さんは{cause}と他{count}の要因により{status}の状態です。',
  'feedback.plco-suggestion': 'PLCOの提案',
  'feedback.condition-type.FATIGUE': '疲労度',
  'feedback.condition-type.STRESS': 'ストレス',
  'feedback.condition-type.MUSCLE': '筋肉',
  'feedback.condition-type.MOOD': '気分',
  'feedback.condition-type.SLEEP_DURATION': '睡眠時間',
  'feedback.condition-type.SLEEP_QUALITY': '睡眠品質',
  'feedback.bad-condition-description': '{player}さんの{conditionList}項目は4点未満で悪いです。',
  'feedback.decline-condition':
    '{player}さんの{conditionList}項目は直近28日の傾向と比較して急激に低下しました。',
  'feedback.soreness-bodypart':
    '{player}さんは{body_part}に{level}段階の{type, select, CONTACT {接触性} NON_CONTACT {非接触性} other {}}の痛みが発生しました。',
  'feedback.level': '{level}段階',
  'feedback.sure-cancel-sending-feedback': '本当にフィードバックの登録をキャンセルしますか？',
  'feedback.no': 'いいえ',
  'feedback.yes': 'はい',
  'feedback.view-feedback-details': 'フィードバックの詳細を表示',
  'feedback.comments': 'コメント',
  'feedback.add-comment-this-feedback': 'このフィードバックにコメントを追加する',
  'feedback.submit': '登録',
  'feedback.editing': '編集中',
  'feedback.sure-delete-feedback': '本当にフィードバックを削除しますか？',
  'feedback.player-no-longer-view': '選手もこれ以上見ることができません',
  'feedback.want-delete-comment': 'コメントを削除しますか？',
  'feedback.edited': '(修正済み)',
  'feedback.authors-feedback': '{author}さんのフィードバック',
  'feedback.no-comments-yet': 'まだコメントがありません',
  'feedback.request-from-player': '{player}さんからのリクエストです',
  'feedback.feedback-request': 'フィードバックリクエスト',
  'feedback.feedback-has-deleted': 'フィードバックを削除しました',
  'feedback.feedback-has-edited': 'フィードバックを修正しました',
  'feedback.org-license-description': 'ORG ライセンスの管理者は\nフィードバックを使用できません',
  'feedback.withdrew-user': '退会したユーザー', // '탈퇴한 유저',

  'workout-duration.edit': '運動時間の編集',
  'workout-duration.helper-text': 'コーチが編集した運動時間データは青で表示されます',
  'workout-duration.edit-description':
    '一定日基準で7日間編集でき、選手に通知が送信されます。\nデータの元が編集されるため、特に注意してください。',
  'workout-duration.select-players': '選手を選択*',
  'workout-duration.starts-ends': '始まり~終わり*',
  'workout-duration.time-format-hours-min': '⏱️ HH:mm ~ HH:mm ({hours}時間 {min}分)',
  'workout-duration.time-format-hours': '⏱️ HH:mm ~ HH:mm ({hours}時間)',
  'workout-duration.time-format-min': '⏱️ HH:mm ~ HH:mm ({min}分)',
  'workout-duration.invalid-duration': '時間を正確に入力してください',
  'workout-duration.designated-time': '指定された時間',
  'workout-duration.cancel': 'キャンセル',
  'workout-duration.save': '保存',
  'workout-duration.select': '選択',
  'workout-duration.select-all': '全て選択 ({count}人)',
  'workout-duration.hh': 'HH時',
  'workout-duration.mm': 'mm分',
  'workout-duration.edited': '運動時間が編集されました',
}

export default messages
