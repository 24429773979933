export const register = {
  en: {
    'register.welcome.title': '🎉 welcome!',
    'register.welcome.instruction':
      'Plco is a service to help player to make best performace by recording and tracing data.',
    'register.welcome.hint': 'Please answer a few questions for seamless service!',
    'register.name.title': 'Enter your name',
    'register.name.instruction':
      'Plco recommends entering your name as your real name so that others can recognize you. You can change it anytime.',
    'register.profile-picture.title': 'Upload profile image',
    'register.profile-picture.instruction':
      'Please add a profile picture so that others can recognize you.',
    'register.profile-picture.upload': 'upload profile image',
    'register.profile-picture.reset': 'change to default image',
    'register.gender.title': 'Please select your gender',
    'register.gender.man': 'men',
    'register.gender.woman': 'women',
    'register.gender.others': 'etc',
    'register.birthdate.title': 'Please enter your birthdate',
    'register.nationality.title': 'Please select your nationality',
    'register.sports-category.title': 'Please select your Sports',
    'register.position.title': 'Please select your position',
    'register.phone-number.title': 'Please enter your mobile phone number',
    'register.phone-number.instruction':
      'Please enter your mobile phone number to receive important notices.',
    'register.team-code.title.first': 'Do you have a team?',
    'register.team-code.title.second': 'Please enter the team code.',
    'register.team-code.instruction':
      "If your team already uses 'plco', ask the coach who created a team to give you the team code.",
    'register.team-code.team-code.placeholder': 'Enter the team code 6 digit',
    'register.team-code.connect': 'Connect',
  },
  ko: {
    'register.welcome.title': '🎉 환영합니다!',
    'register.welcome.instruction':
      '플코는 데이터를 기록하고 모니터링하면서 최고의 퍼포먼스를 낼 수 있도록 도움을 주는 서비스 입니다.',
    'register.welcome.hint': '원할한 서비스 사용을 위해 몇가지 질문에 대답해 주세요!',
    'register.name.title': '이름을 입력해 주세요',
    'register.name.instruction':
      '다른 사람이 나를 잘 알 수 있도록 이름을 본명으로 입력하는 것을 권장합니다. 나중에 얼마든지 변경 가능합니다.',
    'register.profile-picture.title': '프로필 사진을 등록해 주세요',
    'register.profile-picture.instruction':
      '다른 사람이 나를 잘 알아볼 수 있도록 프로필 사진을 추가해 주세요.',
    'register.profile-picture.upload': '프로필 사진 업로드',
    'register.profile-picture.reset': '기본 이미지로 변경',
    'register.gender.title': '성별을 선택해 주세요',
    'register.gender.man': '남자',
    'register.gender.woman': '여자',
    'register.gender.others': '기타',
    'register.birthdate.title': '생년월일을 입력해 주세요',
    'register.nationality.title': '국적을 선택해 주세요',
    'register.sports-category.title': '종목을 선택해 주세요',
    'register.position.title': '직책을 선택해 주세요',
    'register.phone-number.title': '휴대폰 번호를 입력해 주세요',
    'register.phone-number.instruction':
      '중요한 공지나 알림사항을 안내받기 위한 휴대폰 번호를 입력해 주세요.',
    'register.team-code.title.first': '소속팀이 있으신가요?',
    'register.team-code.title.second': '팀코드를 입력하세요',
    'register.team-code.instruction':
      '팀에서 이미 플코를 사용한다면 팀을 만든 지도자에게 팀코드를 알려달라고 요청하세요.',
    'register.team-code.team-code.placeholder': '팀코드 6자리 입력',
    'register.team-code.connect': '연결',
  },
  ja: {
    'register.welcome.title': '🎉 歓迎します！', // "🎉 환영합니다!",
    'register.welcome.instruction':
      'プルコはデータを記録してモニタリングしながら最高のパフォーマンスを出せるようにサポートするサービスです.', // "플코는 데이터를 기록하고 모니터링하면서 최고의 퍼포먼스를 낼 수 있도록 도움을 주는 서비스 입니다.",
    'register.welcome.hint': 'スムーズなサービスを利用するために、いくつかの質問に答えてください!', // "원할한 서비스 사용을 위해 몇가지 질문에 대답해 주세요!",
    'register.name.title': '名前を入力してください。', // "이름을 입력해 주세요",
    'register.name.instruction':
      '他の人が私をよく知るために、名前を本名で入力することをお勧めします. 後でいくらでも変更できます.', // "다른 사람이 나를 잘 알 수 있도록 이름을 본명으로 입력하는 것을 권장합니다. 나중에 얼마든지 변경 가능합니다.",
    'register.profile-picture.title': 'プロフィール写真を登録してください。', // "프로필 사진을 등록해 주세요",
    'register.profile-picture.instruction':
      '他の人が私によく気付くようにプロフィール写真を追加してください.', // "다른 사람이 나를 잘 알아볼 수 있도록 프로필 사진을 추가해 주세요.",
    'register.profile-picture.upload': 'プロフィール写真のアップロード', // "프로필 사진 업로드",
    'register.profile-picture.reset': '基本イメージに変更', // "기본 이미지로 변경",
    'register.gender.title': '性別を選択してください', // "성별을 선택해 주세요",
    'register.gender.man': '男子', // "남자",
    'register.gender.woman': '女子', // "여자",
    'register.gender.others': 'その外', // "기타",
    'register.birthdate.title': '生年月日を入力してください', // "생년월일을 입력해 주세요",
    'register.nationality.title': '国籍を選択してください', // "국적을 선택해 주세요",
    'register.sports-category.title': '種目を選択してください。', // "종목을 선택해 주세요",
    'register.position.title': '職責を選択してください', // "직책을 선택해 주세요",
    'register.phone-number.title': '携帯番号を入力してください', // "휴대폰 번호를 입력해 주세요",
    'register.phone-number.instruction':
      '重要なお知らせやお知らせを受け取るための携帯電話番号を入力してください.', // "중요한 공지나 알림사항을 안내받기 위한 휴대폰 번호를 입력해 주세요.",
    'register.team-code.title.first': '所属チームはありますか？', // "소속팀이 있으신가요?",
    'register.team-code.title.second': 'チームコードを入力してください', // "팀코드를 입력하세요",
    'register.team-code.instruction':
      'チームですでにプルコを使用する場合は、チームを作った指導者にチームコードを教えてほしいと要請してください.', // "팀에서 이미 플코를 사용한다면 팀을 만든 지도자에게 팀코드를 알려달라고 요청하세요.",
    'register.team-code.team-code.placeholder': 'チームコード6桁入力', // "팀코드 6자리 입력",
    'register.team-code.connect': '連結', // "연결",
  },
}
