import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { makeVar } from '@apollo/client'
import throttle from 'lodash/throttle'
import { get } from 'styled-system'

import { theme } from '@plco-pro/themes/main'
import { remToPx } from '@plco-pro/utils/rem-to-px'

export const LAYOUT_MAIN_MAX_WIDTH = 1586
export const LAYOUT_NAVBAR_ONLY_MAX_WIDTH = 1100

const themeBreakpoints = get(theme, 'breakpoints') as string[]
const breakpoints = themeBreakpoints?.reduce(
  (acc, cur) => {
    acc.push(remToPx(cur))

    return acc
  },
  [0],
)

export const getScreenSize = (isPageModal: boolean) => {
  const width = isPageModal
    ? document.querySelector('.react-responsive-modal-modal')?.clientWidth ?? 0
    : window.innerWidth
  const height = window.innerHeight

  const sm = width < breakpoints[1]
  const md = width < breakpoints[2] && !sm
  const lg = width < breakpoints[3] && !(sm || md)
  const xl = width >= breakpoints[3]

  return {
    width,
    height,
    breakpoints,
    smOnly: sm,
    mdOnly: md,
    lgOnly: lg,
    xlOnly: xl,
    smAndUp: sm || md || lg || xl,
    mdAndUp: !sm && (md || lg || xl),
    lgAndUp: !(sm || md) && (lg || xl),
    xlAndUp: !(sm || md || lg) && xl,
    smAndDown: sm && !(md || lg || xl),
    mdAndDown: (sm || md) && !(lg || xl),
    lgAndDown: (sm || md || lg) && !xl,
    xlAndDown: sm || md || lg || xl,
    mobile: sm && !(md || lg || xl),
    tablet: md && !(sm || lg || xl),
    desktop: (lg || xl) && !(sm || md),
  }
}

export type ResponsiveContext = {
  width?: number
  height?: number
  breakpoints?: number[]
  smOnly?: boolean
  mdOnly?: boolean
  lgOnly?: boolean
  xlOnly?: boolean
  smAndUp?: boolean
  mdAndUp?: boolean
  lgAndUp?: boolean
  xlAndUp?: boolean
  smAndDown?: boolean
  mdAndDown?: boolean
  lgAndDown?: boolean
  xlAndDown?: boolean
  mobile?: boolean
  tablet?: boolean
  desktop?: boolean
  handleResize?: () => void
}

export const ResponsiveContext = createContext<ResponsiveContext>({})
export const responsiveVar = makeVar<ResponsiveContext>({})

export const useResponsiveContext = () => {
  return useContext(ResponsiveContext)
}

type Props = {
  isPageModal?: boolean
}

const ResponsiveContextProvider: FunctionComponent<Props> = ({ children, isPageModal = false }) => {
  const [responsiveContext, setResponsiveContext] = useState<
    Omit<ResponsiveContext, 'handleResize'>
  >({})

  const hasWindow = typeof window !== 'undefined'

  const handleResize = useCallback(() => {
    setResponsiveContext(() => getScreenSize(isPageModal))
  }, [isPageModal])

  useEffect(() => {
    if (!hasWindow) return

    const throttledHandleResize = throttle(handleResize, 500)

    handleResize()

    window.addEventListener('resize', throttledHandleResize)
    return () => window.removeEventListener('resize', throttledHandleResize)
  }, [handleResize, hasWindow, isPageModal])

  const value = useMemo(
    () => ({
      ...responsiveContext,
      handleResize,
    }),
    [handleResize, responsiveContext],
  )
  responsiveVar(value)

  return <ResponsiveContext.Provider value={value}>{children}</ResponsiveContext.Provider>
}

export default ResponsiveContextProvider
