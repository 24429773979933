import { __ENV__, __SERVER_DEV__ } from '@plco-pro/env'

export const config = {
  store: {
    apiClientHeaders: {},
  },
  i18n: {
    messagePacks: {
      // inline require ref: https://reactnative.dev/docs/ram-bundles-inline-requires#inline-requires
      get en() {
        return require('@plco-pro/i18ns/i18n.en').default
      },
      get ko() {
        return require('@plco-pro/i18ns/i18n.ko').default
      },
      get ja() {
        return require('@plco-pro/i18ns/i18n.ja').default
      },
      get vn() {
        return require('@plco-pro/i18ns/i18n.vn').default
      },
    },
  },
  env: __ENV__,
  isDev: __SERVER_DEV__,
}
