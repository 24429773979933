import { Flex, Text } from '@plco-pro/components/atoms'
import { useI18n } from '@plco-pro/hooks'

import { Modal } from '../modal'

interface Props {
  loading: boolean
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

export const ModalDeleteFeedback = ({ loading, isOpen, onClose, onDelete }: Props) => {
  const { formatMessage } = useI18n()
  return (
    <Modal
      width={'modal.width.sm'}
      height={'modal.height.xs'}
      open={isOpen}
      showCloseIcon={false}
      onClose={onClose}
      footerProps={{
        leftButtonProps: {
          children: formatMessage({ id: 'NO' }),
          onClick: onClose,
        },
        rightButtonProps: {
          children: formatMessage({ id: 'YES' }),
          onClick: onDelete,
          variant: 'danger',
          isLoading: loading,
        },
      }}
    >
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text sx={{ fontSize: 'h5', fontWeight: 'bold', textAlign: 'center' }}>
          {formatMessage({ id: 'feedback.sure-delete-feedback' })}
        </Text>
        <Text sx={{ fontSize: 's2', fontWeight: 400, textAlign: 'center' }}>
          {formatMessage({ id: 'feedback.player-no-longer-view' })}
        </Text>
      </Flex>
    </Modal>
  )
}
