import { TypePolicies } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      sports: {
        // short for options.mergeObjects(existing, incoming)
        merge: true,
      },
      eventForCoach: {
        merge: true,
      },
    },
  },
  SportsTeam: {
    fields: {
      coachAlarmConnection: relayStylePagination(),
    },
  },
  MultilingualCategoryGroup: {
    keyFields: ['id', 'value'],
  },
  MultilingualCountry: {
    keyFields: ['id', 'value'],
  },
  MultilingualCategory: {
    keyFields: ['id', 'value'],
  },
  MultilingualCoachPosition: {
    keyFields: ['id', 'value'],
  },
  MultilingualPlayerPosition: {
    keyFields: ['id', 'value'],
  },
  TeamGroup: {
    fields: {
      groupUsers: {
        merge: false,
      },
    },
  },
  TeamSportsData: {
    merge: true,
  },
  SportsData: {
    keyFields: ['id', 'date'],
  },

  Mutation: {
    fields: {
      sports: {
        // short for options.mergeObjects(existing, incoming)
        merge: true,
      },
    },
  },
}
