import { ChartSystemPropsDatasetLegend } from '@plco-pro/maps/chart-system/chart-system.types'
import theme from '@plco-pro/themes/main'

export const DATASET_LEGENDS_INJURY: ChartSystemPropsDatasetLegend[] = [
  {
    label: 'SORENESS_1',
    chartType: 'BAR',
    chartColor: theme.colors['injury-level-1'],
    toggle: true,
    visible: true,
    group: 0,
  },
  {
    label: 'SORENESS_2',
    chartType: 'BAR',
    chartColor: theme.colors['injury-level-2'],
    toggle: true,
    visible: true,
    group: 0,
  },
  {
    label: 'SORENESS_3',
    chartType: 'BAR',
    chartColor: theme.colors['injury-level-3'],
    toggle: true,
    visible: true,
    group: 0,
  },
  {
    label: 'SORENESS_4',
    chartType: 'BAR',
    chartColor: theme.colors['injury-level-4'],
    toggle: true,
    visible: true,
    group: 1,
  },
  {
    label: 'SORENESS_5',
    chartType: 'BAR',
    chartColor: theme.colors['injury-level-5'],
    toggle: true,
    visible: true,
    group: 1,
  },
]
