import { Fragment } from 'react'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'

export const SM_TOP_NAV_HEIGHT = 64
export const MD_AND_UP_TOP_NAV_HEIGHT = 80

const ContainerFlex = (props: FlexProps) => (
  <Flex
    {...props}
    sx={{
      alignItems: 'center',
      height: '64px',
      bg: 'white-500',
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      zIndex: 102,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'grey-100',
      ...props.sx,
    }}
  />
)

const PlaceholderFlex = (props: FlexProps) => <Flex sx={{ height: '64px' }} {...props} />

export const Navbar = (props: FlexProps) => {
  return (
    <Fragment>
      <PlaceholderFlex />
      <ContainerFlex {...props} />
    </Fragment>
  )
}
