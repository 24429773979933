import React, { useMemo } from 'react'

import { Image, ImageProps } from '@plco-pro/components/atoms/image'
import { AvatarDefault, AvatarDefaultProps } from '@plco-pro/components/molecules/avatar-default'

export type AvatarProps = Omit<ImageProps, 'src' | 'value'> &
  Omit<AvatarDefaultProps, 'src' | 'value' | 'size'> & {
    src?: string | null
    value?: string | null
    size?: number | number[]
    alt?: string
    isWithdrawn?: boolean
  }

export const Avatar: React.FunctionComponent<AvatarProps> = ({
  src,
  value,
  size = 32,
  alt = 'avatar',
  isWithdrawn = false,
  ...rest
}) => {
  const sx = useMemo(
    () => ({
      borderRadius: size,
      border: 'avatar',
    }),
    [size],
  )

  // 탈퇴 유저 기본 아바타
  if (isWithdrawn) {
    return (
      <Image
        src={'/images/avatar.svg'}
        alt={alt}
        sx={{
          width: size,
          minWidth: size,
          height: size,
          minHeight: size,
          ...sx,
        }}
        {...rest}
      />
    )
  }

  return (
    <>
      {src ? (
        <Image
          src={src}
          alt={alt}
          sx={{
            width: size,
            minWidth: size,
            height: size,
            minHeight: size,
            ...sx,
          }}
          {...rest}
        />
      ) : (
        <AvatarDefault value={value || ''} size={size} {...rest} />
      )}
    </>
  )
}
