import * as snippet from '@segment/snippet'

import { __ENV__ } from '@plco-pro/env'
import { storage } from '@plco-pro/utils/libs/storage'

import {
  AnalyticsEventParams,
  AnalyticsEventType,
  AnalyticsPattern,
  PageEventName,
} from './analytics.types'

const SESSION_EXPIRE_TIME = 30 * 60 * 1000 // 30mins

const SESSION_ID_KEY = 'analytics-session-id'
const SESSION_EXTENDED_AT_KEY = 'analytics-session-extended-at'

type GetPropertyFunctions = ({
  pageEvent,
  routeName,
}: {
  pageEvent: PageEventName
  routeName: string
}) => Record<string, string>

const getPropertyFunctions: GetPropertyFunctions = ({ pageEvent, routeName }) => {
  const propertyFunctions = {
    [PageEventName.BLOG_CONTENT]: (routeName: string) => ({
      contents_id: routeName.split('/').pop(),
    }),
  }
  const defaultFunctions = () => ({})

  return (
    pageEvent in propertyFunctions
      ? propertyFunctions[pageEvent as keyof typeof propertyFunctions]
      : defaultFunctions
  )(routeName)
}

export default class Analytics {
  private static pageViewEnabled = false
  // this should be enabled only for testing
  private static forceEnable = false
  private static isDev = __ENV__ === 'development'
  private static isIdentified = false
  private static prevPageEvent = ''
  private static patternList: AnalyticsPattern[] = [
    { pattern: '/dashboard', event: PageEventName.NEWDASHBOARD },
    { pattern: '/teamchart', event: PageEventName.DASHBOARD as const },
    { pattern: '/monitoring', event: PageEventName.MONITORING as const },
    { pattern: '/teams/(.*)/members', event: PageEventName.TEAM_MANAGE as const },
    { pattern: '/event', event: PageEventName.SCHEDULER },
    { pattern: '/insight', event: PageEventName.INSIGHT },
    { pattern: '/players/(.*)', event: PageEventName.PLAYERS },
    { pattern: '/blog', event: PageEventName.BLOG },
    { pattern: '/blog/(.*)', event: PageEventName.BLOG_CONTENT },
    { pattern: '/blog/search', event: PageEventName.BLOG_SEARCH, track: false },
    { pattern: '/blog/onboarding/(.*)', event: PageEventName.BLOG_ONBOARDING, track: false },
    { pattern: '/plan/pay([#.*])?$', event: PageEventName.PLAN_SELECT },
    { pattern: '/plan/pay\\?plan([^?&]+)$', event: PageEventName.PERSONS_SELECT },
    { pattern: '/plan/billing', event: PageEventName.PAYMENT_MANAGEMENT },
  ]

  static generateSnippet() {
    switch (__ENV__) {
      case 'production':
        return snippet.min({
          apiKey: require('@plco-pro-config/production.analytics.json').segment.writeKey,
          // note: the page option only covers SSR tracking.
          // Page.js is used to track other events using `window.analytics.page()`
          page: Analytics.pageViewEnabled,
        })
      case 'stage':
        return snippet.max({
          apiKey: require('@plco-pro-config/stage.analytics.json').segment.writeKey,
          // note: the page option only covers SSR tracking.
          // Page.js is used to track other events using `window.analytics.page()`
          page: Analytics.pageViewEnabled,
        })
      default:
        if (Analytics.forceEnable) {
          return snippet.max({
            apiKey: require('@plco-pro-config/development.analytics.json').segment.writeKey,
            // note: the page option only covers SSR tracking.
            // Page.js is used to track other events using `window.analytics.page()`
            page: Analytics.pageViewEnabled,
          })
        }
        return ''
    }
  }

  public static getSessionId() {
    return storage.get(SESSION_ID_KEY)
  }

  public static getSessionExtendedAt() {
    const rawExtendedAt = storage.get(SESSION_EXTENDED_AT_KEY)

    return rawExtendedAt ? new Date(rawExtendedAt) : null
  }

  public static getIsIdentified() {
    return Analytics.isIdentified
  }

  private static extendSession() {
    const newExtendedAt = new Date().toISOString()
    storage.set(SESSION_EXTENDED_AT_KEY, newExtendedAt)
  }

  public static restartSession() {
    const newSessionId = Math.floor(new Date().getTime() / 1000).toString()
    storage.set(SESSION_ID_KEY, newSessionId)

    Analytics.extendSession()
  }

  private static validateSession() {
    const sessionId = Analytics.getSessionId()
    const extendedAt = Analytics.getSessionExtendedAt()

    if (
      !sessionId ||
      !extendedAt ||
      new Date().getTime() - extendedAt.getTime() >= SESSION_EXPIRE_TIME
    ) {
      Analytics.restartSession()
    } else {
      Analytics.extendSession()
    }
  }

  public static identifyUser(userId: string, traits: Record<string, any> | undefined) {
    Analytics.isIdentified = true

    if (Analytics.isDev && !Analytics.forceEnable) return

    Analytics.validateSession()

    analytics.identify(userId, traits, {
      integrations: {
        Amplitude: {
          session_id: Analytics.getSessionId(),
        },
      },
    })
  }

  public static trackEvent<T extends AnalyticsEventType>(
    event: T,
    properties: T extends keyof AnalyticsEventParams ? AnalyticsEventParams[T] : {},
  ) {
    if (Analytics.isDev && !Analytics.forceEnable) return

    Analytics.validateSession()

    analytics.track(
      event,
      { ...properties },
      {
        integrations: {
          Amplitude: {
            session_id: Analytics.getSessionId(),
          },
        },
      },
    )
  }

  public static getPageEvent(routeName: string): AnalyticsPattern | undefined {
    let pageEvent

    for (const routeEvent of Analytics.patternList) {
      const regex = new RegExp(routeEvent.pattern)
      if (regex.test(routeName)) {
        pageEvent = routeEvent
      }
    }

    return pageEvent
  }

  public static pageTrack(routeName: string, properties?: Record<string, any>) {
    if (Analytics.isDev && !Analytics.forceEnable) return

    Analytics.validateSession()

    const pageEvent = Analytics.getPageEvent(routeName)

    if (pageEvent && pageEvent.track !== false) {
      if (this.prevPageEvent === pageEvent.event) {
        return
      }

      const pageProperty = getPropertyFunctions({ pageEvent: pageEvent.event, routeName })

      this.prevPageEvent = pageEvent.event
      analytics.track(
        pageEvent.event,
        { ...pageProperty, ...properties, timestamp: new Date().toISOString() },
        {
          integrations: {
            Amplitude: {
              session_id: Analytics.getSessionId(),
            },
          },
        },
      )
    }
  }

  public static reset = () => {
    if (!window.analytics) return

    return analytics.reset()
  }
}
