import React, { forwardRef, ReactNode, useCallback, useRef } from 'react'

import { Checkbox } from '@plco-pro/components/atoms/checkbox'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'

export type CheckboxItemProps = {
  checked?: boolean
  onChange?: (checked: boolean) => void
  containerProps?: FlexProps
  children?: ReactNode
}

const ContainerFlex = (props: FlexProps) => {
  const sx = {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'bg-hover',
    },
    '&:active': {
      backgroundColor: 'bg-pressed',
    },
  }
  return <Flex sx={sx} {...props} />
}

export const CheckboxItem = forwardRef<HTMLDivElement, CheckboxItemProps>(
  ({ checked = false, onChange = (checked: boolean) => {}, children, containerProps }, ref) => {
    const checkboxRef = useRef<HTMLInputElement>(null)

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        event.stopPropagation()
        onChange(event.currentTarget.checked)
      },
      [onChange],
    )

    const handleContainerClick = useCallback(() => {
      onChange(!(checkboxRef.current?.checked || false))
    }, [onChange])

    return (
      <ContainerFlex
        ref={ref}
        sx={{
          px: 2,
          py: 1,
          alignItems: 'center',
          width: '100%',
        }}
        onClick={handleContainerClick}
        data-testid={'checkbox-item'}
        {...containerProps}
      >
        <Flex sx={{ flex: 1 }}>{children}</Flex>
        <Flex sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
          <Checkbox
            ref={checkboxRef}
            sx={{ width: '16px', height: '16px' }}
            checked={checked}
            onChange={handleChange}
          />
        </Flex>
      </ContainerFlex>
    )
  },
)
