import React, { forwardRef, useCallback, useMemo } from 'react'
import { ReactSVG } from 'react-svg'

import { ResponsiveStyleValue } from 'theme-ui'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { useResponsive } from '@plco-pro/hooks/responsive'

export type IconProps = Omit<FlexProps, 'size'> & {
  src: string
  size?: ResponsiveStyleValue<number | string>
  disabled?: boolean
  disabledColor?: FlexProps['color']
  flex?: boolean
}

export const Icon: React.FunctionComponent<IconProps> = forwardRef(
  (
    {
      src,
      size = null,
      color = 'icon',
      disabledColor = 'icon-disabled',
      disabled,
      flex = false,
      ...rest
    },
    ref,
  ) => {
    const getResponsiveProp = useResponsive()

    const responsiveSize = useMemo(() => getResponsiveProp(size), [size, getResponsiveProp])

    const handleBeforeInjection = useCallback(
      (svg) => {
        if (!svg || !responsiveSize) {
          return
        }

        // update svg style
        svg.setAttribute('width', responsiveSize)
        svg.setAttribute('height', responsiveSize)

        if (flex) {
          svg.setAttribute('display', 'flex')
        }
      },
      [responsiveSize, flex],
    )

    const reactSvgStyle = useMemo(
      () => ({
        color: 'inherit',
        fontSize: 0,
      }),
      [],
    )

    return (
      <Flex color={disabled ? disabledColor : color} ref={ref} {...rest}>
        <ReactSVG src={src} beforeInjection={handleBeforeInjection} style={reactSvgStyle} />
      </Flex>
    )
  },
)
