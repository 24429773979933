import React from 'react'

import { VictoryLabel, VictoryLabelProps, VictoryPortal } from 'victory'
import { D3Scale } from 'victory-core'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { HEIGHT } from '@plco-pro/components/molecules/chart-label-dotted-line'
import { baseLabelStyle } from '@plco-pro/components/molecules/chart-theme'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { ThemeColor } from '@plco-pro/themes/main'
import { getColor } from '@plco-pro/utils'

export type ChartLabelAreaCenterProps = VictoryLabelProps & {
  scale?: {
    x: D3Scale
    y: D3Scale
  }
  index?: string | number
  datum?: Record<string, any>
  color?: ThemeColor | string
}

export const ChartLabelAreaCenter: React.FunctionComponent<ChartLabelAreaCenterProps> = ({
  color,
  ...rest
}) => {
  const { datum, scale, index } = rest
  const { smOnly } = useResponsiveContext()

  if (smOnly || (index && index > 0)) {
    return <></>
  }

  if (!scale || !datum || !('_y' in datum) || !('_y0' in datum)) {
    return <VictoryLabel renderInPortal {...rest} />
  }

  // y - ((y - y0) / 2) = (y + y0) / 2
  const centerPosition = scale.y((datum._y + datum._y0) / 2) - HEIGHT / 2
  const xPosition = (rest?.x || scale.x(0.25)) + 8

  const style = {
    color: getColor(color, baseLabelStyle.fill),
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 2,
  }

  return (
    <VictoryPortal>
      <foreignObject x={xPosition} y={centerPosition} width={120} height={HEIGHT}>
        <Flex>
          <Text sx={{ ...style }}>{datum.label}</Text>
        </Flex>
      </foreignObject>
    </VictoryPortal>
  )
}
