import { useCallback, useEffect, useMemo, useState } from 'react'

import { dataInterpreter } from '@common/utils/libs'
import styled from '@emotion/styled'
import _sum from 'lodash/sum'

import { Box } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Icon } from '@plco-pro/components/atoms/icon'
import { Text } from '@plco-pro/components/atoms/text'
import { ChipWorkoutStatus } from '@plco-pro/components/molecules'
import { ChartTooltipPeriod } from '@plco-pro/components/molecules/chart-label-tooltip-multi-team-data'
import {
  PERIOD_WHITE_LIST,
  WorkloadKey,
} from '@plco-pro/components/molecules/chart-label-workload-history'
import { ChipMonitoringNumber } from '@plco-pro/components/molecules/chip-monitoring-number'
import { ChipMonitoringTen } from '@plco-pro/components/molecules/chip-monitoring-ten'
import { ChipMonitoringTime } from '@plco-pro/components/molecules/chip-monitoring-time'
import { WorkloadHistoryEventItem } from '@plco-pro/components/molecules/workload-history-event-item'
import { WorkloadHistoryUserInfo } from '@plco-pro/components/molecules/workload-history-user-info'
import { SportsRawWorkload } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMomentUiFormat } from '@plco-pro/hooks/moment-ui-format'
import { ChartParsedDataItem } from '@plco-pro/hooks/store-system'
import { useWorkloadParser } from '@plco-pro/hooks/workload-parser'
import { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import { Color } from '@plco-pro/maps/chart-system/chart-system.types'
import { CHART_TYPE } from '@plco-pro/maps/chart-system/props'
import { observer, useStore } from '@plco-pro/stores'
import theme from '@plco-pro/themes/main'

const TOOLTIP_HEADER_HEIGHT = 41

export type userDataset = {
  currentUser: ChartParsedDataItem
  period: ChartTooltipPeriod
  chipType?: 'number' | 'ten' | 'time'
  dataType?: MonitoringDataType
  chartType?: CHART_TYPE
  colorList?: Color[]
  workloadList: SportsRawWorkload[]
  nullDataKey?: string
}

export type BottomSheetWorkloadHistoryProps = {
  userDataset: userDataset
  onClose: () => void
}

export const BottomSheetOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(21, 26, 48, 0.3);
  z-index: 999;
`

export const BottomSheetWorkloadHistory = observer(
  ({ userDataset, onClose }: BottomSheetWorkloadHistoryProps) => {
    const CONTENT_HEIGHT = Math.round(window.innerHeight * 0.6 - TOOLTIP_HEADER_HEIGHT)

    const { currentUser, period, chipType, dataType, chartType, colorList, nullDataKey } =
      userDataset

    const { monitoring } = useStore()
    const { formatMessage } = useI18n()
    const { formatDate, formatDateWithInput } = useMomentUiFormat()
    const { getWorkloadLabel } = useWorkloadParser()

    const { isBottomSheetOpen: open, date: monitoringDate } = monitoring
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
      setTimeout(() => setIsOpen(open), 10)
    }, [isOpen, open])

    const closeBottomSheet = () => {
      monitoring.setBottomSheetVisible(false)
      onClose()
    }

    const parsedData = useMemo(() => {
      const parsedValue =
        chartType === 'WORKLOAD_STACK' ? _sum(Object.values(currentUser.y)) : currentUser.y
      const parsedDataType = dataType ? dataType.toLocaleUpperCase() : ''

      const interpretedValue = dataInterpreter.get(parsedDataType, parsedValue)

      const getStackWorkloadLabel = () => {
        if (interpretedValue.value) {
          return {
            text: formatMessage({ id: `DATA.${interpretedValue.interpretation?.label}` }),
            disabled: false,
          }
        } else if (nullDataKey) {
          return {
            text: formatMessage({ id: `${nullDataKey}` }),
            disabled: true,
          }
        } else if (currentUser.reasonForNoWorkout) {
          return {
            text: formatMessage({ id: 'DATA.NO.WORKOUT' }),
            disabled: false,
          }
        }

        return {
          text: formatMessage({ id: 'DATA.NO.INPUT' }),
          disabled: true,
        }
      }

      const { text, disabled } = getStackWorkloadLabel()

      const chipComponent = (() => {
        if (chipType === 'ten') {
          return (
            <>
              <ChipMonitoringTen value={parsedValue} label={text} disabled={disabled} />
              {currentUser.reasonForNoWorkout ? (
                <ChipWorkoutStatus type={currentUser.reasonForNoWorkout} />
              ) : null}
            </>
          )
        } else if (chipType === 'time') {
          return (
            <>
              <ChipMonitoringTime value={parsedValue} label={text} disabled={disabled} />
              {currentUser.reasonForNoWorkout ? (
                <ChipWorkoutStatus type={currentUser.reasonForNoWorkout} />
              ) : null}
            </>
          )
        } else if (chipType === 'number') {
          return (
            <>
              <ChipMonitoringNumber value={parsedValue} label={text} disabled={disabled} />
              {currentUser.reasonForNoWorkout ? (
                <ChipWorkoutStatus type={currentUser.reasonForNoWorkout} />
              ) : null}
            </>
          )
        }
      })()

      return {
        dataType: parsedDataType as WorkloadKey,
        chipComponent,
      }
    }, [
      chipType,
      dataType,
      chartType,
      currentUser.y,
      formatMessage,
      currentUser.reasonForNoWorkout,
      nullDataKey,
    ])

    const getAmount = useCallback((period: ChartTooltipPeriod) => {
      if (period === '7_DAYS') {
        return -6
      } else if (period === '28_DAYS') {
        return -27
      }

      return 0
    }, [])

    const date = useMemo(() => {
      if (!period) return

      const currentDate = currentUser.x

      if (period === 'WEEKLY' || period === 'MONTHLY') {
        return formatDate(currentDate)
      } else if (period === '1_DAY') {
        return formatDate(monitoringDate)
      } else {
        const amount = getAmount(period)
        const unit = 'day'

        return formatDateWithInput(monitoringDate, { amount, unit })
      }
    }, [period, currentUser.x, monitoringDate, formatDate, getAmount, formatDateWithInput])

    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = 'hidden'
        document.body.style.touchAction = 'none'
      } else {
        document.body.style.overflow = 'auto'
        document.body.style.touchAction = 'auto'
      }
    }, [isOpen])

    const name = currentUser.name as string
    const pictureUrl = currentUser.pictureUrl as string

    const showWorkloadList = PERIOD_WHITE_LIST.includes(period) && !!userDataset.workloadList.length

    return (
      <>
        {isOpen && <BottomSheetOverlay onClick={closeBottomSheet} />}
        <Card
          sx={{
            borderRadius: '15px 15px 0 0',
            overflow: 'hidden',
            zIndex: 1000,
            position: 'fixed',
            bottom: open ? '0' : '-100%',
            left: 0,
            right: 0,
            transform: `translateY(${isOpen ? '0' : '100%'})`,
            transition: 'transform 0.5s ease-in-out, bottom 0.5s ease-in-out',
          }}
        >
          <Flex sx={{ flexDirection: 'column', zIndex: 1000 }}>
            {/* header */}
            <Flex
              sx={{
                bg: 'grey-50',
                width: '100%',
                height: TOOLTIP_HEADER_HEIGHT,
                justifyContent: 'space-between',
              }}
            >
              <Text sx={{ fontWeight: 'bold', p: '10px 16px' }} variant={'p1'} appearance={'hint'}>
                {date}
              </Text>
              <Flex
                sx={{
                  p: '10px 16px',
                  gap: '4px',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={closeBottomSheet}
              >
                <Text sx={{ fontWeight: 'bold' }} color={theme.colors['primary-400']}>
                  {formatMessage({ id: 'monitoring.chart.tooltip.close' })}
                </Text>
                <Icon
                  sx={{ fontWeight: 'bold' }}
                  src={'/images/ic-close.svg'}
                  size={20}
                  color={'primary-400'}
                />
              </Flex>
            </Flex>
            <Box
              sx={{
                height: CONTENT_HEIGHT,
                overflow: 'scroll',
              }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                  p: '16px 24px 24px 24px',
                }}
              >
                {/* user info */}
                <WorkloadHistoryUserInfo name={name} pictureUrl={pictureUrl} />
                {!PERIOD_WHITE_LIST.includes(period) && (
                  <Text sx={{ fontWeight: 'bold', pb: 1 }} variant={'p2'} appearance={'primary'}>
                    {userDataset.workloadList.length
                      ? formatMessage(
                          { id: 'monitoring.chart.workload-count' },
                          { count: userDataset.workloadList.length },
                        )
                      : ''}
                  </Text>
                )}
                {/* workload info */}
                <Flex sx={{ alignItems: 'center', gap: '6px', pb: 2 }}>
                  {parsedData?.chipComponent}
                </Flex>
                {showWorkloadList && (
                  <Flex sx={{ flexDirection: 'column', gap: 1 }}>
                    {/* event list" */}
                    <Text
                      sx={{ fontWeight: 'bold' }}
                      variant={'p2'}
                      color={theme.colors['primary-400']}
                    >
                      {formatMessage(
                        { id: 'monitoring.chart.workload-count' },
                        { count: userDataset.workloadList.length },
                      )}
                    </Text>
                    <Flex sx={{ flexDirection: 'column', gap: '12px' }}>
                      {/* event item  */}
                      {userDataset.workloadList.map((workloadItem, index) => {
                        if (!parsedData) return null

                        const result = getWorkloadLabel(parsedData.dataType, workloadItem)

                        if (!result) return null

                        return (
                          <WorkloadHistoryEventItem
                            key={workloadItem.id}
                            color={colorList?.[index % 4]}
                            title={result.title}
                            prevLabel={result.prevLabel}
                            afterLabel={result.afterLabel}
                            type={workloadItem.type}
                          />
                        )
                      })}
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Flex>
        </Card>
      </>
    )
  },
)
