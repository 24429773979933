import { useMemo } from 'react'

import { Flex, Text } from '@plco-pro/components/atoms'
import { ButtonRadio } from '@plco-pro/components/molecules/button-radio'
import { ChipFeedbackType } from '@plco-pro/components/organisms/dialog-feedback/chip-feedback-type'
import { PlayerSelectedType } from '@plco-pro/components/organisms/dialog-player-select'
import {
  FeedbackConditionNotGoodData,
  FeedbackConditionPlummetData,
  FeedbackSorenessSevereData,
  FeedbackSuggestion,
  FeedbackType,
  FeedbackWorkloadMemoData,
  FeedbackWorkloadRiskAtInjuryData,
} from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'
import { useMoment } from '@plco-pro/hooks/moment'

interface Props {
  player: PlayerSelectedType
  feedbackSuggestion: FeedbackSuggestion
  isSelected: boolean
  onChange: () => void
}

export const CardSuggestionFeedback = ({
  player,
  feedbackSuggestion,
  isSelected,
  onChange,
}: Props) => {
  const moment = useMoment()
  const { formatMessage } = useI18n()

  // 이름
  const name = player.name || ''

  const feedbackContentsData = useMemo(() => {
    switch (feedbackSuggestion.type) {
      case FeedbackType.WORKLOAD_RISK_AT_INJURY: {
        const contentsData = feedbackSuggestion.contentsData as FeedbackWorkloadRiskAtInjuryData
        const status = formatMessage({
          id: `feedback.type.${contentsData.label}`,
        })
        const cause = formatMessage({
          id: `feedback.injury.reason.${contentsData.reasonList[0]}`,
        })

        return {
          title: status,
          description: formatMessage(
            { id: 'feedback.player-status-description' },
            {
              player: name,
              cause,
              count: contentsData.reasonList.length - 1,
              status,
            },
          ),
        }
      }
      case FeedbackType.SORENESS_SEVERE: {
        const contentsData = feedbackSuggestion.contentsData as FeedbackSorenessSevereData
        const bodyPart = contentsData.bodyPart.value

        return {
          title: `${bodyPart} ${formatMessage({ id: 'feedback.type.severe-soreness' })}`,
          description: formatMessage(
            { id: 'feedback.soreness-bodypart' },
            {
              player: name,
              body_part: bodyPart,
              level: contentsData.level,
              type: contentsData.isContact,
            },
          ),
        }
      }
      case FeedbackType.CONDITION_NOT_GOOD: {
        const contentsData = feedbackSuggestion.contentsData as FeedbackConditionNotGoodData
        const conditionList = contentsData.dataList
          .map((data) => formatMessage({ id: `feedback.condition-type.${data}` }))
          .join(', ')

        return {
          title: formatMessage({ id: 'feedback.type.bad-condition' }),
          description: formatMessage(
            { id: 'feedback.bad-condition-description' },
            { player: name, conditionList },
          ),
        }
      }
      case FeedbackType.CONDITION_PLUMMET: {
        const contentsData = feedbackSuggestion.contentsData as FeedbackConditionPlummetData

        const conditionList = contentsData.dataList
          .map((data) => formatMessage({ id: `feedback.condition-type.${data}` }))
          .join(', ')

        return {
          title: formatMessage({ id: 'feedback.type.plummeted-condition' }),
          description: formatMessage(
            { id: 'feedback.decline-condition' },
            {
              player: name,
              conditionList,
            },
          ),
        }
      }
      default: {
        const contentsData = feedbackSuggestion.contentsData as FeedbackWorkloadMemoData

        const isScheduleData = !!contentsData.scheduleData

        const scheduleDataTitle = contentsData.scheduleData?.title || ''

        return {
          title: isScheduleData
            ? scheduleDataTitle
            : formatMessage({ id: 'feedback.type.normal-workout' }),
          description: contentsData.memo,
          scheduleType: contentsData.scheduleData?.type,
        }
      }
    }
  }, [name, feedbackSuggestion, formatMessage])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        gap: '12px',
        px: 2,
        py: '20px',
        cursor: 'pointer',
        border: '1px solid',
        borderRadius: '6px',
        borderColor: isSelected ? 'primary-500' : 'grey-200',
        ':hover': {
          bg: 'grey-100',
        },
        ':active': {
          bg: 'grey-200',
        },
      }}
      onClick={onChange}
    >
      <ButtonRadio isChecked={isSelected} size={20} sx={{ width: '20px', height: '20px', p: 0 }} />

      <Flex sx={{ width: '100%', flexDirection: 'column', gap: 1 }}>
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <ChipFeedbackType
            feedbackType={feedbackSuggestion.type}
            scheduleType={feedbackContentsData.scheduleType}
          />

          <Text sx={{ fontSize: 'p2', color: 'grey-400' }}>
            {moment(feedbackSuggestion.date).format('YYYY.MM.DD')}
          </Text>
        </Flex>

        <Flex sx={{ flexDirection: 'column' }}>
          <Text sx={{ fontSize: 's2', fontWeight: 'bold' }}>{feedbackContentsData.title}</Text>

          <Text sx={{ fontWeight: 500, color: 'grey-400' }} ellipsis={true}>
            {feedbackContentsData.description}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
