import { Tooltip as ReactTooltip } from 'react-tooltip'

import { palette } from '@plco-pro/themes/main'

import { Text } from '../atoms'

type PlacesType =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'

interface TooltipProps {
  id: string
  content: string
  place?: PlacesType
}

export const Tooltip = ({ id, content, place = 'bottom' }: TooltipProps) => {
  return (
    <ReactTooltip
      id={id}
      place={place}
      noArrow={true}
      offset={4}
      style={{
        paddingInline: '8px',
        paddingBlock: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette['grey-900'],
        height: '30px',
        zIndex: 801,
      }}
    >
      <Text variant="p2" sx={{ color: 'white' }}>
        {content}
      </Text>
    </ReactTooltip>
  )
}
