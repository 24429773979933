import { SportsDataName } from '@plco-pro/graphqls/react.generated'
import { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import { ChartSystemMapValue, ChipType } from '@plco-pro/maps/chart-system/chart-system.types'
import { DATASET_LABEL_INJURY } from '@plco-pro/maps/chart-system/dataset-label'
import { DATASET_LEGENDS_INJURY } from '@plco-pro/maps/chart-system/dataset-legend'
import theme from '@plco-pro/themes/main'

import {
  getBestTrainingAreaDataset,
  getCautionTrainingAreaDataset,
  getDangerTrainingAreaDataset,
  getWarningTraningAreaDataset,
} from './datasets'

export const CHART_SYSTEM_PLAYER_MAP_KEY = [
  // players/summary/readiness
  'USER:READINESS:1_WEEKS:DAILY:BAR',

  // players/tab-condition
  'USER:FATIGUE_STRESS_BODY_MOOD_DURATION_QUALITY:1_DAYS:POLAR',
  'USER:CONDITION:LINE',
  'USER:FATIGUE:BAR',
  'USER:STRESS:BAR',
  'USER:MUSCLE:BAR',
  'USER:MOOD:BAR',
  'USER:DURATION_QUALITY:BAR_LINE',

  // players/tab-workload
  'USER:WORKLOAD:BAR',
  'USER:INTENSITY:LINE',
  'USER:DURATION:BAR',
  'USER:SATISFACTION:LINE',

  // players/tab-acwr
  'USER:ACWR:BAR_LINE',

  // players/tab-injury
  'USER:INJURY:STACK',

  // players/tab-body
  'USER:WEIGHT:LINE',
  'USER:HEIGHT:LINE',

  // players/injury-history
  'USER:INJURY_HISTORY:1_WEEKS:DAILY_TEXT:LINE',
  'USER:INJURY_HISTORY:1_MONTHS:WEEKLY_DAY:LINE',
] as const

export const chartSystemPlayersMap: Record<
  (typeof CHART_SYSTEM_PLAYER_MAP_KEY)[number],
  ChartSystemMapValue
> = {
  'USER:READINESS:1_WEEKS:DAILY:BAR': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.READINESS],
        period: 'LAST_7_DAYS',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        color: '#6758F3',
        colorHover: '#B3ABF9',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'readiness',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 7,
              tickFormat: 'NULL_STRING',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              style: 'AXIS_STYLE_INVISIBLE',
              tickFormat: 'NULL_STRING',
            },
          ],
        },
      },
    ],
    domain: {
      size: 7,
      y: {
        start: 0,
        end: 'Y_MAX_100',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.READINESS,
            color: '#6758F3',
            interpretPath: 'READINESS',
          },
        ],
      },
    },
  },
  // players/tab-condition
  'USER:FATIGUE_STRESS_BODY_MOOD_DURATION_QUALITY:1_DAYS:POLAR': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.CONDITION],
        period: 'DAY',
      },
    },
    parser: 'USER_DATA_WITHOUT_USER',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'POLAR',
        color: 'deep-purple-500',
        colorHover: 'deep-purple-600',
        animate: 'FAST_CUBIC_IN_OUT',
        parser: {
          fatigue: {
            field: 'fatigue',
          },
          stress: {
            field: 'stress',
          },
          muscle: {
            field: 'muscle',
          },
          mood: {
            field: 'mood',
          },
          duration: {
            field: 'sleepDuration',
          },
          quality: {
            field: 'sleepQuality',
          },
        },
        polarAxis: {
          x: {
            labelPlacement: 'VERTICAL',
            style: 'AXIS_TEXT_ANCHOR_CONDITION',
            tickFormat: 'TRANSLATE_UPPERCASE',
          },
          y: [
            {
              style: 'AXIS_TEXT_ANCHOR_MIDDLE',
              tickValues: 'Y_10_TICK_VALUES',
              tickFormat: 'NULL_STRING',
            },
          ],
        },
        polarYFormat: {
          duration: '72_RE-SCALE_1',
        },
      },
    ],
    domain: {},
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'XBIG_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'POLAR',
            dataType: MonitoringDataType.FATIGUE,
            field: 'fatigue',
            color: theme.colors['deep-purple-500'],
            interpretPath: 'WELLNESS_FATIGUE',
          },
          {
            chartType: 'POLAR',
            dataType: MonitoringDataType.STRESS,
            field: 'stress',
            color: theme.colors['deep-purple-500'],
            interpretPath: 'WELLNESS_STRESS',
          },
          {
            chartType: 'POLAR',
            dataType: MonitoringDataType.MUSCLE,
            field: 'muscle',
            color: theme.colors['deep-purple-500'],
            interpretPath: 'WELLNESS_MUSCLE',
          },
          {
            chartType: 'POLAR',
            dataType: MonitoringDataType.MOOD,
            field: 'mood',
            color: theme.colors['deep-purple-500'],
            interpretPath: 'WELLNESS_MOOD',
          },
          {
            chartType: 'POLAR',
            dataType: MonitoringDataType.SLEEP_DURATION,
            field: 'duration',
            color: theme.colors['deep-purple-500'],
            format: 'HOUR_MINUTE_FORMAT',
            interpretPath: 'SLEEP_DURATION',
          },
          {
            chartType: 'POLAR',
            dataType: MonitoringDataType.SLEEP_QUALITY,
            field: 'quality',
            color: theme.colors['deep-purple-500'],
            interpretPath: 'SLEEP_QUALITY',
          },
        ],
      },
    },
  },
  // 'USER:CONDITION:1_WEEKS:DAILY_TEXT:LINE': {
  //   query: {
  //     name: 'USER_DATA',
  //     variables: {
  //       names: [SportsDataName.CONDITION],
  //       period: 'WEEK',
  //     },
  //   },
  //   parser: 'PLAYER_DAILY_CONDITION',
  //   store: 'QUERY_CHART',
  //   dataset: [
  //     {
  //       type: 'LINE',
  //       color: 'deep-purple-500',
  //       colorHover: 'deep-purple-500',
  //       animate: 'FAST_CUBIC_IN_OUT_OPACITY',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'score',
  //         },
  //       },
  //       axis: {
  //         x: [
  //           {
  //             orientation: 'BOTTOM',
  //             tickCount: 7,
  //             tickFormat: 'DAY_OF_WEEK',
  //           },
  //         ],
  //         y: [
  //           {
  //             orientation: 'LEFT',
  //             tickValues: 'Y_10_TICK_VALUES',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  //   domain: {
  //     size: 7,
  //     y: {
  //       start: 0,
  //       end: 10,
  //     },
  //     dataIndex: 0,
  //   },
  //   label: {
  //     labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
  //     config: {
  //       width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
  //       items: [
  //         {
  //           chartType: 'LINE',
  //           dataType: MonitoringDataType.CONDITION,
  //           color: theme.colors['deep-purple-500'],
  //           interpretPath: 'condition',
  //         },
  //       ],
  //     },
  //   },
  // },
  'USER:CONDITION:LINE': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.CONDITION],
        period: 'MONTH',
      },
    },
    parser: 'PLAYER_DAILY_CONDITION',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'LINE',
        color: 'deep-purple-500',
        colorHover: 'deep-purple-500',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'score',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_10_TICK_VALUES',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.CONDITION,
            color: theme.colors['deep-purple-500'],
            interpretPath: 'CONDITION',
          },
        ],
      },
    },
  },
  // 'USER:FATIGUE:1_WEEKS:DAILY_TEXT:BAR': {
  //   query: {
  //     name: 'USER_DATA',
  //     variables: {
  //       names: [SportsDataName.CONDITION],
  //       period: 'WEEK',
  //     },
  //   },
  //   parser: 'USER_DATA',
  //   store: 'QUERY_CHART',
  //   dataset: [
  //     {
  //       type: 'BAR',
  //       color: 'pink-300',
  //       colorHover: 'pink-500',
  //       cornerRadius: 'MEDIUM_CORNER_RADIUS',
  //       animate: 'FAST_CUBIC_IN_OUT',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'fatigue',
  //         },
  //       },
  //       axis: {
  //         x: [
  //           {
  //             orientation: 'BOTTOM',
  //             tickCount: 7,
  //             tickFormat: 'DAY_OF_WEEK',
  //           },
  //         ],
  //         y: [
  //           {
  //             orientation: 'LEFT',
  //             tickValues: 'Y_10_TICK_VALUES',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  //   domain: {
  //     size: 7,
  //     y: {
  //       start: 0,
  //       end: 10,
  //     },
  //     dataIndex: 0,
  //   },
  //   label: {
  //     labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
  //     config: {
  //       width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
  //       items: [
  //         {
  //           chartType: 'BAR',
  //           dataType: MonitoringDataType.FATIGUE,
  //           color: theme.colors['pink-300'],
  //           interpretPath: 'WELLNESS_FATIGUE',
  //         },
  //       ],
  //     },
  //   },
  // },
  'USER:FATIGUE:BAR': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.CONDITION],
        period: 'MONTH',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        color: 'pink-300',
        colorHover: 'pink-500',
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'fatigue',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_10_TICK_VALUES',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.FATIGUE,
            color: theme.colors['pink-300'],
            interpretPath: 'WELLNESS_FATIGUE',
          },
        ],
      },
    },
  },
  // 'USER:STRESS:1_WEEKS:DAILY_TEXT:BAR': {
  //   query: {
  //     name: 'USER_DATA',
  //     variables: {
  //       names: [SportsDataName.CONDITION],
  //       period: 'WEEK',
  //     },
  //   },
  //   parser: 'USER_DATA',
  //   store: 'QUERY_CHART',
  //   dataset: [
  //     {
  //       type: 'BAR',
  //       color: 'violet-300',
  //       colorHover: 'violet-500',
  //       cornerRadius: 'MEDIUM_CORNER_RADIUS',
  //       animate: 'FAST_CUBIC_IN_OUT',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'stress',
  //         },
  //       },
  //       axis: {
  //         x: [
  //           {
  //             orientation: 'BOTTOM',
  //             tickCount: 7,
  //             tickFormat: 'DAY_OF_WEEK',
  //           },
  //         ],
  //         y: [
  //           {
  //             orientation: 'LEFT',
  //             tickValues: 'Y_10_TICK_VALUES',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  //   domain: {
  //     size: 7,
  //     y: {
  //       start: 0,
  //       end: 10,
  //     },
  //     dataIndex: 0,
  //   },
  //   label: {
  //     labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
  //     config: {
  //       width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
  //       items: [
  //         {
  //           chartType: 'BAR',
  //           dataType: MonitoringDataType.STRESS,
  //           color: theme.colors['violet-300'],
  //           interpretPath: 'WELLNESS_STRESS',
  //         },
  //       ],
  //     },
  //   },
  // },
  'USER:STRESS:BAR': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.CONDITION],
        period: 'MONTH',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        color: 'violet-300',
        colorHover: 'violet-500',
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'stress',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_10_TICK_VALUES',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.STRESS,
            color: theme.colors['violet-300'],
            interpretPath: 'WELLNESS_STRESS',
          },
        ],
      },
    },
  },
  // 'USER:MUSCLE:1_WEEKS:DAILY_TEXT:BAR': {
  //   query: {
  //     name: 'USER_DATA',
  //     variables: {
  //       names: [SportsDataName.CONDITION],
  //       period: 'WEEK',
  //     },
  //   },
  //   parser: 'USER_DATA',
  //   store: 'QUERY_CHART',
  //   dataset: [
  //     {
  //       type: 'BAR',
  //       color: 'teal-300',
  //       colorHover: 'teal-500',
  //       cornerRadius: 'MEDIUM_CORNER_RADIUS',
  //       animate: 'FAST_CUBIC_IN_OUT',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'muscle',
  //         },
  //       },
  //       axis: {
  //         x: [
  //           {
  //             orientation: 'BOTTOM',
  //             tickCount: 7,
  //             tickFormat: 'DAY_OF_WEEK',
  //           },
  //         ],
  //         y: [
  //           {
  //             orientation: 'LEFT',
  //             tickValues: 'Y_10_TICK_VALUES',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  //   domain: {
  //     size: 7,
  //     y: {
  //       start: 0,
  //       end: 10,
  //     },
  //     dataIndex: 0,
  //   },
  //   label: {
  //     labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
  //     config: {
  //       width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
  //       items: [
  //         {
  //           chartType: 'BAR',
  //           dataType: MonitoringDataType.MUSCLE,
  //           color: theme.colors['teal-300'],
  //           interpretPath: 'WELLNESS_MUSCLE',
  //         },
  //       ],
  //     },
  //   },
  // },
  'USER:MUSCLE:BAR': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.CONDITION],
        period: 'MONTH',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        color: 'teal-300',
        colorHover: 'teal-500',
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'muscle',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_10_TICK_VALUES',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MUSCLE,
            color: theme.colors['teal-300'],
            interpretPath: 'WELLNESS_MUSCLE',
          },
        ],
      },
    },
  },
  // 'USER:MOOD:1_WEEKS:DAILY_TEXT:BAR': {
  //   query: {
  //     name: 'USER_DATA',
  //     variables: {
  //       names: [SportsDataName.CONDITION],
  //       period: 'WEEK',
  //     },
  //   },
  //   parser: 'USER_DATA',
  //   store: 'QUERY_CHART',
  //   dataset: [
  //     {
  //       type: 'BAR',
  //       color: 'amber-300',
  //       colorHover: 'amber-600',
  //       cornerRadius: 'MEDIUM_CORNER_RADIUS',
  //       animate: 'FAST_CUBIC_IN_OUT',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'mood',
  //         },
  //       },
  //       axis: {
  //         x: [
  //           {
  //             orientation: 'BOTTOM',
  //             tickCount: 7,
  //             tickFormat: 'DAY_OF_WEEK',
  //           },
  //         ],
  //         y: [
  //           {
  //             orientation: 'LEFT',
  //             tickValues: 'Y_10_TICK_VALUES',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  //   domain: {
  //     size: 7,
  //     y: {
  //       start: 0,
  //       end: 10,
  //     },
  //     dataIndex: 0,
  //   },
  //   label: {
  //     labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
  //     config: {
  //       width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
  //       items: [
  //         {
  //           chartType: 'BAR',
  //           dataType: MonitoringDataType.MOOD,
  //           color: theme.colors['amber-300'],
  //           interpretPath: 'WELLNESS_MOOD',
  //         },
  //       ],
  //     },
  //   },
  // },
  'USER:MOOD:BAR': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.CONDITION],
        period: 'MONTH',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        color: 'amber-300',
        colorHover: 'amber-600',
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'mood',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_10_TICK_VALUES',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'MEDIUM_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MOOD,
            color: theme.colors['amber-300'],
            interpretPath: 'WELLNESS_MOOD',
          },
        ],
      },
    },
  },
  // 'USER:DURATION_QUALITY:BAR_LINE': {
  //   query: {
  //     name: 'USER_DATA',
  //     variables: {
  //       names: [SportsDataName.CONDITION],
  //       period: 'WEEK',
  //     },
  //   },
  //   parser: 'USER_DATA',
  //   store: 'QUERY_CHART',
  //   dataset: [
  //     {
  //       type: 'BAR',
  //       color: 'indigo-300',
  //       colorHover: 'indigo-500',
  //       cornerRadius: 'MEDIUM_CORNER_RADIUS',
  //       animate: 'FAST_CUBIC_IN_OUT',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'sleepDuration',
  //         },
  //       },
  //       legend: [
  //         {
  //           label: 'SLEEP_DURATION',
  //           chartType: 'BAR',
  //           chartColor: theme.colors['indigo-300'],
  //           toggle: true,
  //           visible: true,
  //         },
  //       ],
  //       axis: {
  //         y: {
  //           orientation: 'LEFT',
  //           tickCount: 4,
  //           tickValues: 'Y_1_TICK_VALUES_DIVIDE_5',
  //           tickFormat: '1_RE-SCALE_720_HOUR_FORMAT',
  //         },
  //       },
  //       yFormat: '720_RE-SCALE_1',
  //     },
  //     {
  //       type: 'LINE',
  //       color: 'yellow-600',
  //       colorHover: 'yellow-600',
  //       animate: 'FAST_CUBIC_IN_OUT_OPACITY',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'sleepQuality',
  //           interpretPath: 'RAW_SLEEP.quality',
  //         },
  //       },
  //       axis: {
  //         x: [
  //           {
  //             orientation: 'BOTTOM',
  //             tickCount: 7,
  //             tickFormat: 'DAY_OF_WEEK',
  //           },
  //         ],
  //         y: [
  //           {
  //             orientation: 'RIGHT',
  //             tickCount: 2,
  //             tickValues: 'Y_1_TICK_VALUES_DIVIDE_5',
  //             tickFormat: '1_RE-SCALE_10',
  //           },
  //         ],
  //       },
  //       yFormat: '10_RE-SCALE_1',
  //       legend: [
  //         {
  //           label: 'SLEEP_QUALITY',
  //           chartType: 'LINE',
  //           chartColor: theme.colors['yellow-600'],
  //           toggle: true,
  //           visible: true,
  //         },
  //       ],
  //     },
  //   ],
  //   domain: {
  //     size: 7,
  //     shift: 10,
  //     y: {
  //       start: 0,
  //       end: 1,
  //     },
  //     dataIndex: 0,
  //   },
  //   label: {
  //     labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
  //     config: {
  //       width: 'XBIG_LABEL_TOOLTIP_WIDTH',
  //       items: [
  //         {
  //           chartType: 'BAR',
  //           dataType: MonitoringDataType.SLEEP_DURATION,
  //           color: theme.colors['indigo-300'],
  //           format: 'HOUR_MINUTE_FORMAT',
  //           interpretPath: 'SLEEP_DURATION',
  //         },
  //         {
  //           chartType: 'LINE',
  //           dataType: MonitoringDataType.SLEEP_QUALITY,
  //           color: theme.colors['yellow-600'],
  //           interpretPath: 'SLEEP_QUALITY',
  //         },
  //       ],
  //     },
  //   },
  // },
  'USER:DURATION_QUALITY:BAR_LINE': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.CONDITION],
        period: 'MONTH',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        color: 'indigo-300',
        colorHover: 'indigo-500',
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'sleepDuration',
          },
        },
        legend: [
          {
            label: 'SLEEP_DURATION',
            chartType: 'BAR',
            chartColor: theme.colors['indigo-300'],
            toggle: true,
            visible: true,
          },
        ],
        axis: {
          y: {
            orientation: 'LEFT',
            tickCount: 4,
            tickValues: 'Y_1_TICK_VALUES_DIVIDE_5',
            tickFormat: '1_RE-SCALE_720_HOUR_FORMAT',
          },
        },
        yFormat: '720_RE-SCALE_1',
      },
      {
        type: 'LINE',
        color: 'yellow-600',
        colorHover: 'yellow-600',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'sleepQuality',
          },
        },
        legend: [
          {
            label: 'SLEEP_QUALITY',
            chartType: 'LINE',
            chartColor: theme.colors['yellow-600'],
            animate: 'FAST_CUBIC_IN_OUT',
            toggle: true,
            visible: true,
          },
        ],
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'RIGHT',
              tickCount: 2,
              tickValues: 'Y_1_TICK_VALUES_DIVIDE_5',
              tickFormat: '1_RE-SCALE_10',
            },
          ],
        },
        yFormat: '10_RE-SCALE_1',
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      shift: 10,
      y: {
        start: 0,
        end: 1,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'XBIG_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_DURATION,
            color: theme.colors['indigo-300'],
            format: 'HOUR_MINUTE_FORMAT',
            interpretPath: 'SLEEP_DURATION',
          },
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.SLEEP_QUALITY,
            color: theme.colors['yellow-600'],
            interpretPath: 'SLEEP_QUALITY',
          },
        ],
      },
    },
  },
  'USER:WORKLOAD:BAR': {
    query: {
      name: 'USER_WORKLOAD_DATA',
      variables: {},
    },
    parser: 'PLAYER_WORKLOAD_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_STACK',
        noneDataColorHover: '#A9CEC4',
        noneDataColor: 'teal-100',
        colorScale: ['teal-300', 'teal-600', 'teal-800', 'teal-900'],
        colorScaleHover: ['#62CAB1', '#18A4A6', '#10657A', '#0E4F69'],
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        period: 'MONTHLY',
        drawPrevision: true,
        chipType: ChipType.NUMBER,
        dataType: MonitoringDataType.WORKLOAD,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'workload',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          domainY: {
            field: 'workloadTotal',
          },
          workloadList: {
            field: 'workloadList',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickCount: 4,
            tickFormat: 'DAY_OF_MONTH',
          },
          y: {
            orientation: 'LEFT',
          },
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 'DOMAIN_Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
  },
  'USER:INTENSITY:LINE': {
    query: {
      name: 'USER_WORKLOAD_DATA',
      variables: {},
    },
    parser: 'USER_WORKLOAD',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_LINE',
        color: 'amber-700',
        colorHover: 'amber-700',
        colorScale: ['amber-200', 'amber-500', 'amber-700', 'amber-900'],
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        period: 'MONTHLY',
        drawPrevision: true,
        chipType: ChipType.TEN,
        dataType: MonitoringDataType.WORKLOAD_INTENSITY,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'parsedWorkload.intensity.avg',
          },
          workloadList: {
            field: 'workloadList',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_10_TICK_VALUES',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  'USER:DURATION:BAR': {
    query: {
      name: 'USER_WORKLOAD_DATA',
      variables: {},
    },
    parser: 'PLAYER_WORKLOAD_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_STACK',
        noneDataColorHover: '#CCAAB0',
        noneDataColor: 'pink-100',
        colorScale: ['pink-300', 'pink-600', 'pink-800', 'pink-900'],
        colorScaleHover: ['#CA5A74', '#AB0E5E', '#990E51', '#7E0E4E'],
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        period: 'MONTHLY',
        drawPrevision: true,
        chipType: ChipType.TIME,
        dataType: MonitoringDataType.WORKLOAD_DURATION,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'duration',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          domainY: {
            field: 'workloadTotal',
          },
          workloadList: {
            field: 'workloadList',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickFormat: 'HOUR_MINUTE_FORMAT',
              tickValues: 'Y_TICK_VALUES_180_UP_DOWN',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 'DOMAIN_Y_180_UP_DOWN',
      },
      dataIndex: 0,
    },
  },
  'USER:SATISFACTION:LINE': {
    query: {
      name: 'USER_WORKLOAD_DATA',
      variables: {},
    },
    parser: 'USER_WORKLOAD',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_LINE',
        color: 'purple-700',
        colorHover: 'purple-700',
        colorScale: ['purple-300', 'purple-600', 'purple-700', 'purple-900'],
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        period: 'MONTHLY',
        drawPrevision: true,
        chipType: ChipType.TEN,
        dataType: MonitoringDataType.WORKLOAD_SATISFACTION,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'parsedWorkload.satisfaction.avg',
          },
          workloadList: {
            field: 'workloadList',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_10_TICK_VALUES',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  // //   // players/tab-acwr
  // 'USER:ACWR:1_WEEKS:DAILY_TEXT:BAR_LINE': {
  //   query: {
  //     name: 'USER_DATA',
  //     variables: {
  //       names: [
  //         SportsDataName.WORKLOAD_ACXR,
  //         SportsDataName.WORKLOAD_AX,
  //         SportsDataName.WORKLOAD_CX,
  //         SportsDataName.WORKLOAD,
  //       ],
  //       period: 'WEEK',
  //     },
  //   },
  //   parser: 'USER_DATA',
  //   store: 'QUERY_CHART',
  //   dataset: [
  //     {
  //       type: 'LINE',
  //       color: 'pink-500',
  //       colorHover: 'pink-500',
  //       animate: 'FAST_CUBIC_IN_OUT_OPACITY',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'workload_acxr',
  //         },
  //       },
  //       legend: [
  //         {
  //           label: 'DATA.WORKLOAD_ACXR',
  //           chartType: 'LINE',
  //           chartColor: theme.colors['pink-500'],
  //           toggle: true,
  //           visible: true,
  //         },
  //       ],
  //       axis: {
  //         x: [
  //           {
  //             orientation: 'BOTTOM',
  //             tickCount: 7,
  //             tickFormat: 'DAY_OF_WEEK',
  //           },
  //         ],
  //         y: [
  //           {
  //             orientation: 'RIGHT',
  //             tickCount: 5,
  //             tickValues: 'Y_1_TICK_VALUES_DIVIDE_6',
  //             tickFormat: '1_RE-SCALE_2.5+',
  //           },
  //         ],
  //       },
  //       yFormat: '2.5_RE-SCALE_MAX_1',
  //     },
  //     {
  //       type: 'LINE',
  //       color: 'orange-500',
  //       colorHover: 'orange-500',
  //       animate: 'FAST_CUBIC_IN_OUT_OPACITY',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'workload_ax',
  //           format: 'FLOOR',
  //         },
  //       },
  //       legend: [
  //         {
  //           label: 'WORKLOAD_AX',
  //           chartType: 'LINE',
  //           chartColor: theme.colors['orange-500'],
  //           toggle: true,
  //           visible: true,
  //         },
  //       ],
  //       yFormat: '1500_MAX_RE-SCALE_1',
  //     },
  //     {
  //       type: 'LINE',
  //       color: 'indigo-500',
  //       colorHover: 'indigo-500',
  //       animate: 'FAST_CUBIC_IN_OUT_OPACITY',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'workload_cx',
  //           format: 'FLOOR',
  //         },
  //       },
  //       legend: [
  //         {
  //           label: 'WORKLOAD_CX',
  //           chartType: 'LINE',
  //           chartColor: theme.colors['indigo-500'],
  //           toggle: true,
  //           visible: true,
  //         },
  //       ],
  //       axis: {
  //         y: [
  //           {
  //             orientation: 'LEFT',
  //             tickCount: 5,
  //             tickValues: 'Y_1_TICK_VALUES_DIVIDE_6',
  //             tickFormat: '1_RE-SCALE_1500_MAX',
  //           },
  //         ],
  //       },
  //       yFormat: '1500_MAX_RE-SCALE_1',
  //     },
  //     {
  //       type: 'BAR',
  //       color: 'teal-300',
  //       colorHover: 'teal-500',
  //       cornerRadius: 'MEDIUM_CORNER_RADIUS',
  //       animate: 'FAST_CUBIC_IN_OUT',
  //       drawPrevision: true,
  //       parser: {
  //         x: {
  //           field: 'time',
  //         },
  //         y: {
  //           field: 'workload.sum',
  //         },
  //         reasonForNoWorkout: {
  //           field: 'reasonForNoWorkout',
  //         },
  //       },
  //       legend: [
  //         {
  //           label: 'DATA.WORKLOAD',
  //           chartType: 'BAR',
  //           chartColor: theme.colors['teal-300'],
  //           toggle: true,
  //           visible: true,
  //         },
  //       ],
  //       yFormat: '1500_MAX_RE-SCALE_1',
  //     },
  //     getCautionTrainingAreaDataset('time', (value) => value / 2.5),
  //     getBestTrainingAreaDataset('time', (value) => value / 2.5),
  //     getWarningTraningAreaDataset('time', (value) => value / 2.5),
  //     getDangerTrainingAreaDataset('time', (value) => value / 2.5),
  //   ],
  //   domain: {
  //     size: 7,
  //     shift: 10,
  //     xPaddingRatio: 0.9,
  //     y: {
  //       start: 0,
  //       end: 1,
  //     },
  //     dataIndex: 0,
  //   },
  //   label: {
  //     labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
  //     config: {
  //       width: 'XBIG_LABEL_TOOLTIP_WIDTH',
  //       items: [
  //         {
  //           chartType: 'LINE',
  //           dataType: MonitoringDataType.WORKLOAD_ACXR,
  //           color: theme.colors['pink-500'],
  //           interpretPath: 'WORKLOAD_ACXR',
  //           nullDataKey: 'chart.data.none',
  //         },
  //         {
  //           chartType: 'LINE',
  //           dataType: MonitoringDataType.WORKLOAD_AX,
  //           color: theme.colors['orange-500'],
  //           interpretPath: 'WORKLOAD_AX',
  //           nullDataKey: 'chart.data.none',
  //         },
  //         {
  //           chartType: 'LINE',
  //           dataType: MonitoringDataType.WORKLOAD_CX,
  //           color: theme.colors['indigo-500'],
  //           interpretPath: 'WORKLOAD_CX',
  //           nullDataKey: 'chart.data.none',
  //         },
  //         {
  //           chartType: 'BAR',
  //           dataType: MonitoringDataType.WORKLOAD,
  //           color: theme.colors['teal-300'],
  //           interpretPath: 'WORKLOAD',
  //         },
  //       ],
  //     },
  //   },
  // },
  'USER:ACWR:BAR_LINE': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [
          SportsDataName.WORKLOAD_ACXR,
          SportsDataName.WORKLOAD_AX,
          SportsDataName.WORKLOAD_CX,
          SportsDataName.WORKLOAD,
        ],
        period: 'MONTH',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'LINE',
        color: 'pink-500',
        colorHover: 'pink-500',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'workload_acxr',
          },
        },
        legend: [
          {
            label: 'DATA.WORKLOAD_ACXR',
            chartType: 'LINE',
            chartColor: theme.colors['pink-500'],
            toggle: true,
            visible: true,
          },
        ],
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'RIGHT',
              tickCount: 5,
              tickValues: 'Y_1_TICK_VALUES_DIVIDE_6',
              tickFormat: '1_RE-SCALE_2.5+',
            },
          ],
        },
        yFormat: '2.5_RE-SCALE_MAX_1',
      },
      {
        type: 'LINE',
        color: 'orange-500',
        colorHover: 'orange-500',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'workload_ax',
            format: 'FLOOR',
          },
        },
        legend: [
          {
            label: 'WORKLOAD_AX',
            chartType: 'LINE',
            chartColor: theme.colors['orange-500'],
            toggle: true,
            visible: true,
          },
        ],
        yFormat: '1500_MAX_RE-SCALE_1',
      },
      {
        type: 'LINE',
        color: 'indigo-500',
        colorHover: 'indigo-500',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'workload_cx',
            format: 'FLOOR',
          },
        },
        legend: [
          {
            label: 'WORKLOAD_CX',
            chartType: 'LINE',
            chartColor: theme.colors['indigo-500'],
            toggle: true,
            visible: true,
          },
        ],
        axis: {
          y: [
            {
              orientation: 'LEFT',
              tickCount: 5,
              tickValues: 'Y_1_TICK_VALUES_DIVIDE_6',
              tickFormat: '1_RE-SCALE_1500_MAX',
            },
          ],
        },
        yFormat: '1500_MAX_RE-SCALE_1',
      },
      {
        type: 'BAR',
        color: 'teal-300',
        colorHover: 'teal-500',
        cornerRadius: 'SMALL_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'workload.sum',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        legend: [
          {
            label: 'DATA.WORKLOAD',
            chartType: 'BAR',
            chartColor: theme.colors['teal-300'],
            toggle: true,
            visible: true,
          },
        ],
        yFormat: '1500_MAX_RE-SCALE_1',
      },
      getCautionTrainingAreaDataset('time', (value) => value / 2.5),
      getBestTrainingAreaDataset('time', (value) => value / 2.5),
      getWarningTraningAreaDataset('time', (value) => value / 2.5),
      getDangerTrainingAreaDataset('time', (value) => value / 2.5),
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      xPaddingRatio: 0.9,
      y: {
        start: 0,
        end: 1,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'XBIG_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.WORKLOAD_ACXR,
            color: theme.colors['pink-500'],
            interpretPath: 'WORKLOAD_ACXR',
            nullDataKey: 'chart.data.none',
          },
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.WORKLOAD_AX,
            color: theme.colors['orange-500'],
            interpretPath: 'WORKLOAD_AX',
            nullDataKey: 'chart.data.none',
          },
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.WORKLOAD_CX,
            color: theme.colors['indigo-500'],
            interpretPath: 'WORKLOAD_CX',
            nullDataKey: 'chart.data.none',
          },
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.WORKLOAD,
            color: theme.colors['teal-300'],
            interpretPath: 'WORKLOAD',
          },
        ],
      },
    },
  },
  'USER:INJURY:STACK': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.INJURY],
        period: 'MONTH',
      },
    },
    store: 'QUERY_CHART',
    parser: 'USER_DATA_WITHOUT_USER',
    dataset: [
      {
        type: 'STACK',
        animate: 'FAST_CUBIC_IN_OUT',
        colorScale: [
          'injury-level-stack-1',
          'injury-level-stack-2',
          'injury-level-stack-3',
          'injury-level-stack-4',
          'injury-level-stack-5',
        ],
        colorScaleHover: [
          'injury-level-1-hover',
          'injury-level-2-hover',
          'injury-level-3-hover',
          'injury-level-4-hover',
          'injury-level-5-hover',
        ],
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'countByLevel',
          },
          domainY: {
            field: 'count',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: {
            orientation: 'LEFT',
            tickValues: 'DOMAIN_Y_4_ROUNDED_TICK_VALUES',
          },
        },
        legend: DATASET_LEGENDS_INJURY,
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 'DOMAIN_Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: DATASET_LABEL_INJURY,
  },
  // players/tab-body
  'USER:WEIGHT:LINE': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.BODY],
        period: 'MONTH',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'LINE',
        color: 'green-500',
        colorHover: 'green-500',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'weight',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_MAX_PLUS_5_OR_100_TICK_VALUES_6',
              tickFormat: 'KG_FORMAT',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 'Y_MIN_MINUS_5_ROUNDED_DOWN',
        end: 'Y_MAX_PLUS_5_DEFAULT_100_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'SMALL_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.WEIGHT,
            color: theme.colors['green-500'],
            format: 'KG_FORMAT',
            interpretPath: 'BODY_WEIGHT',
          },
        ],
      },
    },
  },
  'USER:HEIGHT:LINE': {
    query: {
      name: 'USER_DATA',
      variables: {
        names: [SportsDataName.BODY],
        period: 'MONTH',
      },
    },
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'LINE',
        color: 'violet-500',
        colorHover: 'violet-500',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'height',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_MAX_PLUS_5_OR_200_TICK_VALUES_6',
              tickFormat: 'CM_FORMAT',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 'Y_MIN_MINUS_5_ROUNDED_DOWN',
        end: 'Y_MAX_PLUS_5_DEFAULT_200_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
      config: {
        width: 'SMALL_LABEL_TOOLTIP_WIDTH',
        items: [
          {
            chartType: 'LINE',
            dataType: MonitoringDataType.HEIGHT,
            color: theme.colors['violet-500'],
            format: 'CM_FORMAT',
            interpretPath: 'BODY_HEIGHT',
          },
        ],
      },
    },
  },
  'USER:INJURY_HISTORY:1_WEEKS:DAILY_TEXT:LINE': {
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'LINE',
        color: 'deep-orange-300',
        colorHover: 'deep-orange-300',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'level',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 7,
              tickFormat: 'DAY_OF_WEEK',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_INJURY_LEVEL',
              tickFormat: 'TRANSLATE_INJURY',
            },
          ],
        },
      },
    ],
    domain: {
      size: 7,
      y: {
        start: 0,
        end: 6,
      },
      dataIndex: 0,
    },
  },
  'USER:INJURY_HISTORY:1_MONTHS:WEEKLY_DAY:LINE': {
    parser: 'USER_DATA',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'LINE',
        color: 'deep-orange-300',
        colorHover: 'deep-orange-300',
        animate: 'FAST_CUBIC_IN_OUT_OPACITY',
        drawPrevision: true,
        parser: {
          x: {
            field: 'time',
          },
          y: {
            field: 'level',
          },
        },
        axis: {
          x: [
            {
              orientation: 'BOTTOM',
              tickCount: 4,
              tickFormat: 'DAY_OF_MONTH',
            },
          ],
          y: [
            {
              orientation: 'LEFT',
              tickValues: 'Y_INJURY_LEVEL',
              tickFormat: 'TRANSLATE_INJURY',
            },
          ],
        },
      },
    ],
    domain: {
      size: 'DAYS_IN_MONTH',
      y: {
        start: 0,
        end: 6,
      },
      dataIndex: 0,
    },
  },
}
