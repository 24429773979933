import { IStorage } from './storage.types'

// match report help
export const PLAYER_LIST_EDIT_HELP_STORAGE_KEY = 'player-list-edit-guide-disable'
export const REPORT_EXPORT_HELP_STORAGE_KEY = 'report-export-help'

// player data injury help
export const PLAYER_INJURY_REGISTER_HELP_STORAGE_KEY = 'player-injury-register-help'

export const storage: IStorage = {
  get: (key: string) => localStorage.getItem(key),
  set: (key: string, value: string) => {
    try {
      localStorage.setItem(key, value)
      return true
    } catch (error) {
      return false
    }
  },
  remove: (key: string) => localStorage.removeItem(key),
}
