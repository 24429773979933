import isFinite from 'lodash/isFinite'
import isNaN from 'lodash/isNaN'

export const sum = (values: number[]): number => {
  return values.reduce((a, b) => a + b, 0)
}

export const average = (values: number[]): number => {
  return values.reduce((a, b) => a + b, 0) / values.length
}

export const isNumeric = (value: unknown): boolean => {
  if (typeof value === 'number') {
    return isFinite(value)
  }

  if (typeof value === 'string') {
    return !isNaN(parseFloat(value))
  }

  return false
}

// 소수점 첫째자리에서 버리는 percentage를 구함
export const getPercentage = (value: number, total: number) => {
  if (!value || !total) return 0

  return Math.floor((value / total) * 100)
}
