import React, { useCallback, useMemo } from 'react'

import { inOperator } from '@common/utils'
import get from 'lodash/get'
import { VictoryPortal, VictoryTooltipProps } from 'victory'

import { Box } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Icon } from '@plco-pro/components/atoms/icon'
import { Text } from '@plco-pro/components/atoms/text'
import { ChartLabelTooltipBackgroundLine } from '@plco-pro/components/molecules/chart-label-tooltip-backgroud-line'
import { ChipMonitoringCm } from '@plco-pro/components/molecules/chip-monitoring-cm'
import { ChipMonitoringKg } from '@plco-pro/components/molecules/chip-monitoring-kg'
import { ChipMonitoringNumber } from '@plco-pro/components/molecules/chip-monitoring-number'
import { ChipMonitoringTen } from '@plco-pro/components/molecules/chip-monitoring-ten'
import { ChipMonitoringTime } from '@plco-pro/components/molecules/chip-monitoring-time'
import { TranslatedData } from '@plco-pro/hooks/data-interpreter'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMomentUiFormat } from '@plco-pro/hooks/moment-ui-format'
import { ChartParsedDataItem } from '@plco-pro/hooks/store-system'
import { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import { ChartSystemPropsLabelConfigItem } from '@plco-pro/maps/chart-system/chart-system.types'
import theme from '@plco-pro/themes/main'
import { getDegreeColor, getDegreeVariant } from '@plco-pro/utils'

const DEFAULT_TOOLTIP_WIDTH = 200
const HEADER_HEIGHT = 48

export type ChartTooltipPeriod = '1_DAY' | '7_DAYS' | '28_DAYS' | 'WEEKLY' | 'MONTHLY'

type Item = ChartSystemPropsLabelConfigItem & {
  data: ChartParsedDataItem
  format: (value: number | string) => number | string
  interpret: ((value: number | string) => TranslatedData<number>) | (() => void)
}

export type ChartLabelTooltipMultiTeamDataProps = VictoryTooltipProps & {
  width?: number
  date?: Date
  items?: Item[]
  period?: ChartTooltipPeriod
  datum?: { [key in string]?: any }
  scale?: { x?: any; y?: any }
  prefix?: 'AVG' | 'SUM'
}

export const ChartLabelTooltipMultiTeamData: React.FunctionComponent<
  ChartLabelTooltipMultiTeamDataProps
> = (props) => {
  const { formatMessage: f } = useI18n()
  const { formatDate, formatDateWithInput } = useMomentUiFormat()

  // items props
  const formatRawData = useCallback(
    (rawData: MonitoringDataType) => {
      return f({ id: rawData.toUpperCase() }).replace(/\(.*?\)/g, '')
    },
    [f],
  )

  const items = useMemo(() => {
    return (props.items || []).map((item) => {
      const { data, chartType, dataType, color, interpret, chipType, prefix } = item

      // type conditionals
      const isPolar = chartType === 'POLAR'
      const isStack = chartType === 'STACK'

      const isInjuryData =
        !!dataType &&
        [
          MonitoringDataType.SORENESS_1,
          MonitoringDataType.SORENESS_2,
          MonitoringDataType.SORENESS_3,
          MonitoringDataType.SORENESS_4,
          MonitoringDataType.SORENESS_5,
        ].includes(dataType)

      const isAcwrData = !!dataType && [
        MonitoringDataType.WORKLOAD_AX,
        MonitoringDataType.WORKLOAD_CX,
        MonitoringDataType.WORKLOAD_ACXR,
      ]

      const currentDatum = data?.find((item: unknown) => {
        if (!!item && typeof item === 'object' && inOperator(item, 'x')) {
          return get(item, 'x') === props.datum?.x
        }

        return false
      })
      if (!currentDatum) return null

      // parsed props
      const parsedValue = currentDatum.y
      const parsedChartType =
        chartType && !isPolar && !isStack ? chartType?.toLocaleLowerCase() : 'bar'
      const parsedDataType = dataType ? formatRawData(dataType) : ''
      const parsedColor = color || theme.colors['deep-purple-500']

      // chip props
      const { degree } = interpret?.(parsedValue) || {}
      const isExtendedWhitelist =
        dataType === MonitoringDataType.WORKLOAD ||
        dataType === MonitoringDataType.WORKLOAD_INTENSITY
      const degreeColor = getDegreeColor(
        degree,
        isExtendedWhitelist ? 'EXTENDED_DEGREE_COLOR_WHITELIST' : 'DEFAULT_DEGREE_COLOR_WHITELIST',
      )
      const degreeVariant = getDegreeVariant(
        degree,
        isExtendedWhitelist ? 'EXTENDED_DEGREE_COLOR_WHITELIST' : 'DEFAULT_DEGREE_COLOR_WHITELIST',
      )
      const valuePrefix = prefix ? f({ id: prefix }) : null

      let chipComponent = <Text>{isInjuryData ? '-' : f({ id: 'chart.data.not.checked' })}</Text>

      if (parsedValue || isAcwrData) {
        if (parsedValue === null) {
          chipComponent = <Text>{f({ id: 'chart.data.not.checked' })}</Text>
        } else if (chipType === 'ten') {
          chipComponent = <ChipMonitoringTen value={parsedValue} variant={degreeVariant} />
        } else if (chipType === 'time') {
          chipComponent = <ChipMonitoringTime value={parsedValue} variant={degreeVariant} />
        } else if (chipType === 'number') {
          chipComponent = <ChipMonitoringNumber value={parsedValue} variant={degreeVariant} />
        } else if (chipType === 'cm') {
          chipComponent = <ChipMonitoringCm value={parsedValue} variant={degreeVariant} />
        } else if (chipType === 'kg') {
          chipComponent = <ChipMonitoringKg value={parsedValue} variant={degreeVariant} />
        } else {
          chipComponent = (
            <Text variant={'p1'} color={degreeColor}>
              {parsedValue}
            </Text>
          )
        }
      }

      // 단조로움이 10보다 큰 경우 10+ 텍스트 노출
      if (dataType === 'workload_axd') {
        if (parsedValue > 10) {
          chipComponent = <Text variant={'p1'}>{'10+'}</Text>
        }
      }

      return {
        chartType: parsedChartType,
        dataType: parsedDataType,
        color: parsedColor,
        degreeColor,
        chipComponent,
        valuePrefix,
      }
    })
  }, [f, formatRawData, props.datum?.x, props.items])

  // parsed props
  const getAmount = useCallback((period: ChartTooltipPeriod) => {
    if (period === '7_DAYS') {
      return -6
    } else if (period === '28_DAYS') {
      return -27
    }

    return 0
  }, [])

  const date = useMemo(() => {
    if (!props.date || !props.period) return

    if (props.period === '1_DAY') {
      return formatDate(props.date)
    } else {
      const amount = getAmount(props.period)
      const unit = 'day'
      return formatDateWithInput(props.date, { amount, unit })
    }
  }, [props.period, props.date, formatDate, getAmount, formatDateWithInput])

  const name = props?.datum?.name || ''

  // size props
  const width = useMemo(() => {
    return props.width || DEFAULT_TOOLTIP_WIDTH
  }, [props.width])
  const height = useMemo(() => {
    return HEADER_HEIGHT + items.length * 24 + 24
  }, [items.length])

  const x = useMemo(() => {
    const centerX = Math.round(props?.scale?.x.domain()[1] / 2)

    if (centerX === props?.datum?._x) {
      return (props?.x || 0) - width / 2
    } else if (centerX > props?.datum?._x) {
      return props?.x || 0
    } else {
      return (props?.x || 0) - 2 * (width / 2)
    }
  }, [props?.datum?._x, props?.scale?.x, props?.x, width])

  const y = useMemo(() => (props?.y || 0) - height - 8, [props?.y, height])

  // return null if one item is null
  if (items.every((item) => !item)) return null

  // backgroud line props
  const hasBackgroudLine =
    items?.findIndex((item: any) => item.chartType === 'line' || item.chartType === 'area') !== -1

  return (
    <>
      <VictoryPortal>
        <foreignObject x={x} y={y} style={{ width: width, height: height }}>
          <Card sx={{ height: '100%', p: 2 }}>
            <Flex sx={{ height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box sx={{ width: '100%' }}>
                <Text variant={'p2'} appearance={'hint'}>
                  {date}
                </Text>
              </Box>
              <Box sx={{ width: '100%', mb: '4px' }}>
                <Text variant={'p1'} ellipsis>
                  {name}
                </Text>
              </Box>
              {items.map((item, index) => {
                if (!item) return null

                return (
                  <Box key={index} sx={{ width: '100%', mb: '4px' }}>
                    <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Flex sx={{ flex: '0 0 auto', flexDirection: 'row', alignItems: 'center' }}>
                        <Icon
                          color={item.color}
                          src={`/images/chart-tooltip-${item.chartType}.svg`}
                        />
                      </Flex>
                      <Flex sx={{ flex: '1 1 auto', flexDirection: 'row', alignItems: 'center' }}>
                        <Text variant={'p1'} ellipsis sx={{ ml: '4px' }}>
                          {item.dataType}
                        </Text>
                      </Flex>
                      <Flex sx={{ flex: '0 0 auto', alignItems: 'center' }}>
                        <Text variant={'p2'} appearance={'hint'} sx={{ mr: '4px' }}>
                          {item.valuePrefix}
                        </Text>
                        {item.chipComponent}
                      </Flex>
                    </Flex>
                  </Box>
                )
              })}
            </Flex>
          </Card>
        </foreignObject>
      </VictoryPortal>
      {hasBackgroudLine && <ChartLabelTooltipBackgroundLine {...props} />}
    </>
  )
}
