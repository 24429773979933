import { Moment } from 'moment'

import { useI18n } from './i18n'
import { useMoment } from './moment'

export const useMomentFormat = () => {
  const { formatMessage: f } = useI18n()
  const moment = useMoment()

  const formatFeedbackDate = (value: string) => {
    const date = moment(value)

    const today = moment()
    const isYearSame = today.isSame(date, 'years')

    if (isYearSame) {
      return date.format('MM.DD')
    }

    return date.format('YYYY.MM.DD')
  }

  const formatMatchDate = (startAt: Moment, endAt: Moment) => {
    return `${startAt.format(f({ id: 'date.format.year-to-minutes' }))} ~ ${endAt.format('HH:mm')}`
  }

  const formatFeedbackCreateDate = (value: string) => {
    const date = moment(value)
    const today = moment()

    const isYearSame = today.isSame(date, 'years')

    if (isYearSame) {
      return date.format('MM.DD HH:mm')
    }

    return date.format('YYYY.MM.DD HH:mm')
  }

  const fotmatFeedbackCommentsDate = (value: string) => {
    const date = moment(value)

    const today = moment()
    const isYearSame = today.isSame(date, 'years')

    if (isYearSame) {
      return date.format('MM.DD HH:mm')
    }

    return date.format('YYYY.MM.DD HH:mm')
  }

  return {
    formatFeedbackDate,
    formatMatchDate,
    formatFeedbackCreateDate,
    fotmatFeedbackCommentsDate,
  }
}
