export enum DataValueDegree {
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  GOOD = 'GOOD',
  NEUTRAL = 'NEUTRAL',
  NONE = 'NONE',
  INJURY1 = 'INJURY1',
  INJURY2 = 'INJURY2',
  INJURY3 = 'INJURY3',
  INJURY4 = 'INJURY4',
  INJURY5 = 'INJURY5',
}

export interface DataInterpretationItem {
  degree: keyof typeof DataValueDegree
  priority: number | undefined
  label: string | undefined // need translation with i18n
  description: string | undefined // need translation with i18n
}

export type DataInterpretationRanges = {
  [key: string]: Readonly<DataInterpretationItem>
}

export interface DataInterpretation {
  paths: ReadonlyArray<string>
  ranges: Readonly<DataInterpretationRanges>
}
