import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useRouter } from 'next/router'
import queryString from 'query-string'

export type QueryParamsContext = {
  queryParams: QueryParams
  setQueryParam?: (name: string, value: string) => void
  deleteQueryParam?: (name: string) => void
  getQueryParam?: (name: string) => string
  getQueryParams?: (name: string) => string[]
}

export type QueryParams = Record<string, string | string[] | undefined>

const QueryParamsContext = createContext<QueryParamsContext>({
  queryParams: {},
})

export const useQueryParams = () => {
  return useContext(QueryParamsContext)
}

const QueryParamsProvider: React.FunctionComponent = ({ children }) => {
  const router = useRouter()

  const [queryParams, setQueryParams] = useState<QueryParams>({})

  const setQueryParam = useCallback(
    (name: string, value: string) => {
      const newQueryParams = { ...queryParams }
      newQueryParams[name] = value
      const newQueryString = queryString.stringify(newQueryParams)

      if (history.pushState) {
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${newQueryString}`
        history.pushState({ path: newUrl }, '', newUrl)
      } else {
        window.location.search = newQueryString
      }
      setQueryParams(newQueryParams)
    },
    [queryParams],
  )

  const deleteQueryParam = useCallback(
    (name: string) => {
      const newQueryParams = queryParams
      delete newQueryParams[name]
      const newQueryString = queryString.stringify(newQueryParams)

      if (history.pushState) {
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${newQueryString}`
        history.pushState({ path: newUrl }, '', newUrl)
      } else {
        window.location.search = newQueryString
      }

      setQueryParams(newQueryParams)
    },
    [queryParams],
  )

  useEffect(() => {
    const queryParamsFromRouter: QueryParams = router.query
    setQueryParams(queryParamsFromRouter)
  }, [router])

  const getQueryParam = useCallback(
    (name: string): string => {
      const value = queryParams[name]

      if (typeof value === 'string') {
        return value
      } else if (Array.isArray(value)) {
        return value[0]
      } else {
        return ''
      }
    },
    [queryParams],
  )

  const getQueryParams = useCallback(
    (name: string): string[] => {
      const value = queryParams[name]

      if (typeof value === 'string') {
        return [value]
      } else if (Array.isArray(value)) {
        return value
      } else {
        return []
      }
    },
    [queryParams],
  )

  const value: QueryParamsContext = useMemo(
    () => ({
      queryParams,
      setQueryParam,
      deleteQueryParam,
      getQueryParam,
      getQueryParams,
    }),
    [queryParams, setQueryParam, deleteQueryParam, getQueryParam, getQueryParams],
  )

  return <QueryParamsContext.Provider value={value}>{children}</QueryParamsContext.Provider>
}

export default QueryParamsProvider
