import React from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Icon } from '@plco-pro/components/atoms/icon'
import { Text, TextProps } from '@plco-pro/components/atoms/text'

export type DropdownMenuIconItemProps = TextProps & {
  isMultiDropdown?: boolean
  isLast?: boolean
  src?: string
  size?: number
  title?: string
  onClick?: BoxProps['onClick']
  badge?: React.ReactNode
  containerProps?: BoxProps
  value?: string
  isDisabled?: boolean
  disabledOnClick?: BoxProps['onClick']
}

const ContainerBox = (
  props: BoxProps & {
    isMultiDropdown?: boolean
    isLast?: boolean
    isDisabled?: boolean
  },
) => (
  <Box
    {...props}
    sx={{
      px: 2,
      py: 1,
      textAlign: 'left',
      borderBottomWidth: props.isLast || props.isMultiDropdown ? 0 : 1,
      borderBottomStyle: props.isLast ? 'none' : 'solid',
      borderBottomColor: props.isLast ? 'transparent' : 'grey-100',
      cursor: props.isDisabled ? 'default' : 'pointer',
      '&:hover': {
        backgroundColor: props.isDisabled ? 'transparent' : 'bg-hover',
      },
      '&:active': {
        backgroundColor: props.isDisabled ? 'transparent' : 'bg-pressed',
      },
      ...props.sx,
    }}
  />
)

export const DropdownMenuIconItem: React.FunctionComponent<DropdownMenuIconItemProps> = ({
  children,
  isMultiDropdown = false,
  isLast = false,
  src,
  size,
  title,
  onClick = () => {},
  badge,
  containerProps,
  isDisabled,
  disabledOnClick,
  ...rest
}) => {
  return (
    <ContainerBox
      isMultiDropdown={isMultiDropdown}
      isLast={isLast}
      onClick={isDisabled ? disabledOnClick : onClick}
      isDisabled={isDisabled}
      {...containerProps}
    >
      <Flex sx={{ height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <Flex sx={{ width: 'col12.12' }}>
          {src && <Icon src={src} size={size || 20} disabled={isDisabled} sx={{ mr: 1 }} />}
          {title ? (
            <Text
              variant={'p1'}
              appearance={isDisabled ? 'disabled' : 'default'}
              {...rest}
              ellipsis={rest.ellipsis}
            >
              {title}
            </Text>
          ) : (
            children
          )}
        </Flex>
        {badge && <Flex>{badge}</Flex>}
      </Flex>
    </ContainerBox>
  )
}
