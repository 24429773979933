import { Fragment, useMemo } from 'react'

import { Contact, FeedbackSorenessSevereData, Gender } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'
import { useResponsiveContext } from '@plco-pro/providers/responsive'

import { Box, Divider, Flex, Text } from '../../atoms'
import { Circle } from '../../circle'
import { InjuryDummy } from '../../organisms/injury-dummy'
import { DescriptionBox } from '../components'
import { FeedbackContentsType } from '../helpers'

interface SorenessProps {
  name: string
  gender: Gender
  feedbackContentsType?: FeedbackContentsType
  sorenessSevereData: FeedbackSorenessSevereData
}

export const Soreness = ({
  name: player,
  gender,
  feedbackContentsType,
  sorenessSevereData,
}: SorenessProps) => {
  const { formatMessage: f } = useI18n()
  const { smAndDown } = useResponsiveContext()

  const { bodyPart, level, memo, isContact } = sorenessSevereData

  const color = useMemo(() => {
    if (level) {
      return `injury-level-${level}-normal`
    }

    return 'injury-level-0-normal'
  }, [level])

  const injuryBodyPart = useMemo(() => {
    return sorenessSevereData.bodyPart.value
  }, [sorenessSevereData])

  // 기타 성별의 경우에도 남성 마네킹 노출
  const injuryDummyGender = gender === Gender.FEMALE ? 'woman' : 'man'

  // 모바일, 피드백 등록 시에만 디바이더 노출
  const showDivider = feedbackContentsType === 'add' && smAndDown

  const hasContactType = isContact === Contact.CONTACT || isContact === Contact.NON_CONTACT

  return (
    <Fragment>
      <Box sx={{ mx: [2, 3] }}>
        <DescriptionBox feedbackContentsType={feedbackContentsType} sx={{ mb: 2 }}>
          {f(
            { id: 'feedback.soreness-bodypart' },
            { player, body_part: injuryBodyPart, level, type: isContact },
          )}
        </DescriptionBox>

        <Flex sx={{ alignItems: 'center', justifyContent: 'center', gap: 1, mb: 2 }}>
          <Box sx={{ width: '153px' }}>
            <InjuryDummy
              gender={injuryDummyGender}
              orientation={'front'}
              data={{
                SORENESS: {
                  [bodyPart.id]: level,
                },
              }}
              disabledTooltip={true}
            />
          </Box>

          <Box sx={{ width: '153px' }}>
            <InjuryDummy
              gender={injuryDummyGender}
              orientation={'back'}
              data={{
                SORENESS: {
                  [bodyPart.id]: level,
                },
              }}
              disabledTooltip={true}
            />
          </Box>
        </Flex>

        <Flex
          sx={{
            border: '1px solid',
            borderColor: 'grey-100',
            backgroundColor: 'white',
            px: '20px',
            py: 2,
            gap: 2,
            borderRadius: '8px',
          }}
        >
          <Box sx={{ pt: '6px' }}>
            <Circle size={16} color={color} />
          </Box>

          <Box>
            {/* 텍스트 예시 */}
            {/* 5단계 · 접촉 */}
            <Text variant="s2" sx={{ color: 'black', fontWeight: 700 }}>
              {`${f({ id: 'feedback.level' }, { level })}  ${
                hasContactType ? `· ${f({ id: isContact })}` : ''
              }`}
            </Text>

            {/* 텍스트 예시 */}
            {/* 스프린트 하다 넘어짐 */}
            <Text variant="p1" sx={{ color: 'grey-400' }}>
              {memo}
            </Text>
          </Box>
        </Flex>
      </Box>
      {showDivider && <Divider sx={{ height: '16px', mt: 3, mb: 1 }} />}
    </Fragment>
  )
}
