import React, { useMemo } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { CardChartPlayers } from '@plco-pro/components/organisms/card-chart-players'
import { useI18n } from '@plco-pro/hooks/i18n'
import { observer } from '@plco-pro/stores'

const CardContainerBox = (props: BoxProps) => (
  <Box sx={{ px: [1, 2, 2, 2], mt: [2, 4, 4, 4] }} {...props} />
)

export const TabContentItemPlayersWorkload = observer(() => {
  const { formatMessage: f } = useI18n()

  const durationPadding = useMemo(
    () => ({
      top: 64,
      bottom: 64,
      left: 80,
      right: 64,
    }),
    [],
  )

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        mx: [-1, -2, null, null],
        mt: [-2, -4, null, null],
      }}
    >
      <Box sx={{ width: ['col12.12', null, 'col12.12', null] }}>
        <CardContainerBox>
          <CardChartPlayers
            configKey="USER:WORKLOAD:BAR"
            title={f({ id: 'players.workload.chart-card.workload.title' })}
            isDateRange={true}
          />
        </CardContainerBox>
      </Box>

      <Box sx={{ width: ['col12.12', null, 'col12.6', null] }}>
        <CardContainerBox>
          <CardChartPlayers
            configKey="USER:INTENSITY:LINE"
            title={f({ id: 'players.workload.chart-card.intensity.title' })}
            isDateRange={true}
          />
        </CardContainerBox>
      </Box>

      <Box sx={{ width: ['col12.12', null, 'col12.6', null] }}>
        <CardContainerBox>
          <CardChartPlayers
            configKey="USER:DURATION:BAR"
            title={f({ id: 'players.workload.chart-card.duration.title' })}
            isDateRange={true}
            padding={durationPadding}
          />
        </CardContainerBox>
      </Box>

      <Box sx={{ width: ['col12.12', null, 'col12.6', null] }}>
        <CardContainerBox>
          <CardChartPlayers
            configKey="USER:SATISFACTION:LINE"
            title={f({ id: 'players.workload.chart-card.satisfaction.title' })}
            isDateRange={true}
          />
        </CardContainerBox>
      </Box>
    </Flex>
  )
})
