import { useEffect, useMemo, useState } from 'react'

import { useChartSystemProps } from '@plco-pro/hooks/chart-system-props/chart-system-props'
import { useParserSystem } from '@plco-pro/hooks/parser-system/parser-system'
import { useQuerySystem } from '@plco-pro/hooks/query-system'
import { useStoreSystem } from '@plco-pro/hooks/store-system'
import { chartSystemMap, ChartSystemMapKey } from '@plco-pro/maps/chart-system/chart-system'

export const useChartSystem = (configKey: ChartSystemMapKey, isDateRange?: boolean) => {
  const [initialized, setInitialized] = useState<boolean>(false)

  const config = useMemo(() => chartSystemMap[configKey], [configKey])

  if (!config) {
    throw new Error('chart-system: invalid config')
  }

  // store system
  const { initialized: storeInitialized, store } = useStoreSystem(config.store)

  // query system
  // TODO: handle non-initialized store
  const { data, loading, refetch, variables } = useQuerySystem(config.query, store, isDateRange)

  // parser system
  const { parser } = useParserSystem(config)

  // chart props system
  const { initialized: chartSystemPropsInitialized, chartSystemProps } = useChartSystemProps(
    config,
    store,
  )

  // set configKey when initialized
  useEffect(() => {
    if (storeInitialized) {
      store?.setConfigKey(configKey)
    }
  }, [configKey, store, storeInitialized])

  useEffect(() => {
    setInitialized(storeInitialized && chartSystemPropsInitialized)
  }, [chartSystemPropsInitialized, data, parser, store, storeInitialized])

  // TODO: move into query-system?
  useEffect(() => {
    if (!storeInitialized || !store) {
      return
    }

    store.setLoading(loading)

    if (refetch) {
      store.setRefetch(refetch)
    }
  }, [data, loading, parser, refetch, store, storeInitialized])

  // TODO: move into query-system?
  useEffect(() => {
    if (!storeInitialized || !store || !data) {
      return
    }

    store.setData(data)
    store.setParsedData(parser(data))
  }, [data, loading, parser, refetch, store, storeInitialized])

  return useMemo(
    () => ({
      initialized,
      data,
      variables,
      loading,
      refetch,
      store,
      chartSystemProps,
      parser,
      isCustomQuery: !config.query?.name,
    }),
    [chartSystemProps, data, initialized, loading, parser, refetch, store, variables, config],
  )
}
