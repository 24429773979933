import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { PartsData } from '@plco-pro/components/molecules/injury-tooltip/injury-tooltip'
import { useI18n } from '@plco-pro/hooks/i18n'
import { injuryColors } from '@plco-pro/themes/main'

interface Props {
  name: string
  partsData?: PartsData
}

export const InjuryTooltipTitle = ({ name, partsData }: Props) => {
  const { formatMessage } = useI18n()

  const getSorenessColor = (partsData?: PartsData) => {
    if (!partsData) {
      return injuryColors['injury-level-0']
    }

    const colorKey = `injury-level-${partsData.soreness}`

    if (colorKey in injuryColors) {
      return injuryColors[colorKey as keyof typeof injuryColors]
    }
  }

  const sorenessColor = getSorenessColor(partsData)

  return (
    <Flex
      sx={{
        gap: '24px',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
      }}
    >
      <Flex sx={{ gap: '4px', alignItems: 'center' }}>
        <Box
          sx={{
            width: '16px',
            height: '16px',
            borderRadius: '36px',
            bg: sorenessColor,
            flex: 'none',
          }}
        />
        <Text sx={{ color: 'white' }} variant={'s2'}>
          {name}
        </Text>
      </Flex>

      <Text sx={{ color: sorenessColor, textAlign: 'right' }} variant={'s2'}>
        {partsData?.soreness ? formatMessage({ id: `SORENESS_LEVEL_${partsData.soreness}` }) : ''}
      </Text>
    </Flex>
  )
}
