import { ChangeEvent, useState } from 'react'

import { Button, Flex, Spinner, Text, Textarea } from '@plco-pro/components/atoms'
import { useCreateFeedbackCommentsMutation } from '@plco-pro/graphqls/react.generated'
import { useI18n, useNationality, useViewer } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useFeedbackError } from '@plco-pro/hooks/feedback-error'
import { AnalyticsEventType } from '@plco-pro/utils/libs'

import { FEEDBACK_COMMENT_MAX_LENGTH, getFeedbackAmplitudeType } from './helper'

interface Props {
  isEditing: boolean
  feedbackId: string
}

export const TextAreaComments = ({ isEditing, feedbackId }: Props) => {
  const { formatMessage } = useI18n()
  const { viewer } = useViewer()
  const nationality = useNationality()
  const showFeedbackError = useFeedbackError()
  const { trackEvent } = useAnalytics()

  const [comments, setComments] = useState('')

  const [createFeedbackCommentsMutation, { loading }] = useCreateFeedbackCommentsMutation({
    onCompleted: ({ createFeedbackComment }) => {
      const feedbackTypeText = getFeedbackAmplitudeType(createFeedbackComment)

      trackEvent(AnalyticsEventType.SAVE_FEEDBACK_COMMENT, {
        type: feedbackTypeText,
      })
      setComments('')
    },
    onError: ({ graphQLErrors }) => {
      showFeedbackError(graphQLErrors, true)
    },
  })

  const onChangeComments = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > FEEDBACK_COMMENT_MAX_LENGTH) {
      const lastChar = e.target.value.charAt(e.target.value.length - 1)

      setComments(`${comments.slice(0, -1)}${lastChar}`)

      return
    }

    setComments(e.target.value)
  }

  const onCreateComments = () => {
    if (loading) {
      return
    }

    createFeedbackCommentsMutation({
      variables: {
        input: {
          feedbackId,
          comment: comments,
        },
        multilingualTextInput: {
          nationality,
        },
      },
    })
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        p: 2,
        mx: [2, 3],
        minHeight: '137px',
        boxShadow: 'inset 0 0 0 2px #E4E9F2',
        borderRadius: '10px',
        transition: '0.2s box-shadow',
        ':hover': {
          boxShadow: !isEditing && 'inset 0 0 0 2px #7E92F9',
        },
        ':focus-within': {
          boxShadow: 'inset 0 0 0 1px #2A44EC',
        },
      }}
    >
      <Text sx={{ fontWeight: 'bold' }}>{viewer?.name}</Text>
      <Textarea
        disabled={isEditing}
        value={comments}
        onChange={onChangeComments}
        placeholder={formatMessage({ id: 'feedback.add-comment-this-feedback' })}
        sx={{
          p: 0,
          mt: '4px',
          resize: 'none',
          border: 'none',
          fontSize: 's2',
          '::placeholder': {
            color: 'grey-400',
            fontSize: 's2',
          },
        }}
      />
      <Flex sx={{ justifyContent: 'flex-end', mt: 3 }}>
        <Button onClick={onCreateComments} disabled={!comments.length} size={'sm'}>
          {loading ? (
            <Spinner size={'sm'} color={'alternative'} />
          ) : (
            formatMessage({ id: 'feedback.submit' })
          )}
        </Button>
      </Flex>
    </Flex>
  )
}
