import { UserWorkloadRiskAtInjuryReasonType } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'

import { Flex, Icon, Text } from '../../atoms'

const InjuryRiskIconSet: Record<UserWorkloadRiskAtInjuryReasonType, string> = {
  [UserWorkloadRiskAtInjuryReasonType.HIGH_ACWR]: '/images/injury-risk-reason/high-acwr.svg',
  [UserWorkloadRiskAtInjuryReasonType.HIGH_WEEKLY_WORKLOAD]:
    '/images/injury-risk-reason/high-weekly-workload.svg',
  [UserWorkloadRiskAtInjuryReasonType.LOW_ACWR]: '/images/injury-risk-reason/low-acwr.svg',
  [UserWorkloadRiskAtInjuryReasonType.MONOTONY]: '/images/injury-risk-reason/monotony.svg',
  [UserWorkloadRiskAtInjuryReasonType.NON_ELASTIC_INTENSITY]:
    '/images/injury-risk-reason/non-elastic-intensity.svg',
  [UserWorkloadRiskAtInjuryReasonType.STRAIN]: '/images/injury-risk-reason/strain.svg',
  [UserWorkloadRiskAtInjuryReasonType.WEEKLY_WORKLOAD_SURGE]:
    '/images/injury-risk-reason/weekly-workload-surge.svg',
}

interface WorkloadReasonChipProps {
  type: UserWorkloadRiskAtInjuryReasonType
}

export const WorkloadReasonChip = ({ type }: WorkloadReasonChipProps) => {
  const { formatMessage: f } = useI18n()

  return (
    <Flex
      sx={{
        height: '36px',
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'grey-100',
        borderRadius: '6px',
        px: 1,
        mr: '12px',
        gap: '4px',
        backgroundColor: 'white',
      }}
    >
      <Icon src={InjuryRiskIconSet[type]} />

      <Text variant="p1" sx={{ fontWeight: 500, color: 'grey-500-72' }}>
        {f({ id: `feedback.injury.reason.${type}` })}
      </Text>
    </Flex>
  )
}
