import React from 'react'

import styled from '@emotion/styled'
import { useDropdownToggle } from 'react-overlays/DropdownToggle'
import { variant, VariantArgs } from 'styled-system'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Image } from '@plco-pro/components/atoms/image'
import { Text } from '@plco-pro/components/atoms/text'

export type DropdownToggleProps = {
  title?: string
  titleEllipsis?: boolean
  width?: string | string[]
  appearance?: 'button' | 'error' | 'card'
  hideDropdownArrow?: boolean
  containerProps?: FlexProps
  disabled?: boolean
  titleColor?: string
}

export type DropdownToggleContentProps = {
  title?: string
}

export type DropdownToggleDivProps = {
  width?: string | number
}

const appearance = variant({
  prop: 'appearance',
  variants: {
    card: {
      padding: 1,
      boxSizing: 'border-box',
      border: 'card',
      borderRadius: 'default',
    },
    button: {
      padding: 1,
      border: 'inputButton',
      boxShadow: 'inputButton',
      borderRadius: 'default',
      boxSizing: 'border-box',
    },
    error: {
      padding: 1,
      border: 'inputButton',
      boxShadow: 'inputButton',
      borderRadius: 'default',
      boxSizing: 'border-box',
      borderColor: 'red-500',
    },
  },
} as VariantArgs)

const ContainerDiv = styled('div')<DropdownToggleDivProps>`
  width: ${(props) => props.width};
  cursor: pointer;
`

const StyledContainerDiv = styled(ContainerDiv)<DropdownToggleProps>(appearance)

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
    {...props}
  />
)

export const DropdownToggle: React.FunctionComponent<DropdownToggleProps> = ({
  title,
  titleEllipsis,
  width,
  appearance,
  hideDropdownArrow,
  children,
  containerProps,
  disabled,
  titleColor,
}) => {
  const [props, { show, toggle }] = useDropdownToggle()

  const handleClick = () => {
    if (disabled) {
      return
    }

    toggle(show)
  }

  return (
    <StyledContainerDiv
      disabled={disabled}
      onClick={handleClick}
      appearance={appearance}
      sx={{ width: width }}
      {...props}
    >
      <ContainerFlex {...containerProps}>
        {children && children}

        {!children && title && (
          <Text sx={{ color: titleColor }} variant={'p1'} ellipsis={titleEllipsis}>
            {title}
          </Text>
        )}

        {!hideDropdownArrow && (
          <Image
            src={'/images/dropdown-arrow.svg'}
            alt={'dropdown arrow'}
            sx={{
              ml: 1,
              width: '24px',
              minWidth: '24px',
              height: '24px',
              minHeight: '24px',
            }}
          />
        )}
      </ContainerFlex>
    </StyledContainerDiv>
  )
}
