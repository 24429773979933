import { Flex, Icon, Text } from '@plco-pro/components/atoms'
import { useI18n } from '@plco-pro/hooks'

export const EmptyFeedbackComments = () => {
  const { formatMessage } = useI18n()
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: 1,
        justifyContent: 'center',
        alignItems: 'center',
        my: 7,
      }}
    >
      <Icon src={'/images/empty-comments.svg'} />
      <Text color={'grey-400'}>{formatMessage({ id: 'feedback.no-comments-yet' })}</Text>
    </Flex>
  )
}
