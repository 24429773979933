import { Flex, Text } from '@plco-pro/components/atoms'
import { Avatar } from '@plco-pro/components/molecules'

interface MemoCardProps {
  pictureUrl?: string
  name: string
  contents: string
}

export const MemoCard = ({ pictureUrl, name, contents }: MemoCardProps) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        border: '1px solid',
        borderColor: 'grey-100',
        p: '20px',
        borderRadius: '8px',
        bg: 'white-500',
      }}
    >
      <Flex sx={{ alignItems: 'center', gap: 1, mb: 1 }}>
        <Avatar src={pictureUrl} value={name} size={24} />

        <Text ellipsis={true} sx={{ fontSize: 's2', fontWeight: 'bold' }}>
          {name}
        </Text>
      </Flex>

      <Text variant="s2" sx={{ fontWeight: 400, color: 'grey-500' }}>
        {contents}
      </Text>
    </Flex>
  )
}
