import React, { useEffect, useMemo, useState } from 'react'

import { SportsUser, useDialogTeamPlayerListQuery } from '@plco-pro/graphqls/react.generated'
import { useViewer } from '@plco-pro/hooks'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useResponsiveContext } from '@plco-pro/providers/responsive'

import { Box, Flex, Text } from '../atoms'
import { ButtonRadio } from '../molecules/button-radio'
import { InputSearch } from '../molecules/input-search'
import { Dropdown } from './dropdown'
import { Modal } from './modal'

export type PlayerSelectedType = {
  __typename: 'SportsUser'
} & Pick<SportsUser, 'id' | 'name' | 'pictureUrl' | 'gender'>

interface Props {
  open: boolean
  title: string
  onClose: () => void
  onNext: () => void
  player?: PlayerSelectedType
  onChangePlayer: (player: PlayerSelectedType) => void
}

export const DialogPlayerSelect = ({
  open,
  title,
  onNext,
  onClose,
  player,
  onChangePlayer,
}: Props) => {
  const { formatMessage } = useI18n()
  const { currentActiveTeam } = useViewer()
  const { smAndDown } = useResponsiveContext()

  const teamId = currentActiveTeam?.id

  const { data } = useDialogTeamPlayerListQuery({
    skip: !teamId || !open,
    variables: {
      teamId: teamId as string,
    },
  })

  const userList = data?.sports.team?.userMapsByPage.entries

  const selectedUser = userList?.find(({ user }) => user.id === player?.id)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const playerListByFiltering = useMemo(() => {
    return userList?.filter(({ user }) => user.name?.includes(searchValue))
  }, [searchValue, userList])

  const resetSearchValue = () => {
    setSearchValue('')
  }

  useEffect(() => {
    resetSearchValue()
  }, [open])

  return (
    <Modal
      isFullScreen={smAndDown}
      width={'400px'}
      height={'643px'}
      open={open}
      onClose={onClose}
      contentPaddingDisable={true}
      headerProps={
        smAndDown
          ? {
              title: formatMessage({ id: 'feedback.send-feedback' }),
              sx: { fontSize: 's1', fontWeight: 500 },
            }
          : undefined
      }
      footerProps={{
        leftButtonProps: {
          children: formatMessage({ id: 'CANCEL' }),
          onClick: onClose,
        },
        rightButtonProps: {
          disabled: !player,
          children: formatMessage({ id: 'NEXT' }),
          onClick: () => {
            onNext()
            resetSearchValue()
          },
        },
      }}
    >
      <Flex
        sx={{
          mt: [0, 7],
          p: '32px 24px 16px 24px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Text variant={'s1'} sx={{ fontWeight: '700' }}>
          {title}
        </Text>
        <Box sx={{ mt: 3, width: '100%' }}>
          <Dropdown
            toggleTitle={selectedUser?.user.name || formatMessage({ id: 'SELECT' })}
            titleEllipsis
            show={dropdownOpen}
            onToggle={() => setDropdownOpen((prev) => !prev)}
            toggleAppearance={'button'}
            menuWidth={`calc(100% - 24px)`}
            menuItems={[
              {
                containerProps: { sx: { px: 0 } },
                isDisabled: true,
                children: (
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      width: '100%',
                      pt: 1,
                    }}
                  >
                    <Flex sx={{ mx: 2 }}>
                      <InputSearch
                        size={'sm'}
                        placeholder={formatMessage({
                          id: 'dashboard.popup.player-select.search.player',
                        })}
                        value={searchValue}
                        onChange={(value) => setSearchValue(value)}
                      />
                    </Flex>
                    <Flex sx={{ height: '16px' }} />
                    <Flex
                      sx={{
                        flexDirection: 'column',
                        height: '272px',
                        overflowY: 'auto',
                      }}
                    >
                      {!playerListByFiltering?.length && userList?.length ? (
                        <Text sx={{ px: 2 }} color={'text-secondary'}>
                          {formatMessage({ id: 'dashboard.popup.player-select.search.none' })}
                        </Text>
                      ) : (
                        playerListByFiltering?.map(({ user }, index) => (
                          <Flex
                            key={index}
                            sx={{
                              px: 2,
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              cursor: 'pointer',
                              ':hover': {
                                bg: 'grey-100',
                              },
                            }}
                            onClick={() => {
                              onChangePlayer(user)
                            }}
                          >
                            <Text ellipsis>{user.name}</Text>
                            <ButtonRadio isChecked={player?.id === user.id} size={24} />
                          </Flex>
                        ))
                      )}
                    </Flex>
                  </Flex>
                ),
              },
            ]}
          />
        </Box>
      </Flex>
    </Modal>
  )
}
