import { inOperator } from '@common/utils'

import { ScheduleType } from '@plco-pro/graphqls/react.generated'
import theme, { ThemeDegreeColor } from '@plco-pro/themes/main'

export type DegreeColorWhitelistName =
  | 'DEFAULT_DEGREE_COLOR_WHITELIST'
  | 'EXTENDED_DEGREE_COLOR_WHITELIST'
  | 'MULTI_DATA_DEGREE_COLOR_WHITELIST'

const DEFAULT_DEGREE_COLOR_WHITELIST = ['DANGER', 'WARNING', 'GOOD']

const EXTENDED_DEGREE_COLOR_WHITELIST = ['DANGER', 'WARNING', 'GOOD']

const MULTI_DATA_DEGREE_COLOR_WHITELIST = [
  'DANGER',
  'WARNING',
  'GOOD',
  'NONE',
  'NEUTRAL',
  'INJURY1',
  'INJURY2',
  'INJURY3',
  'INJURY4',
  'INJURY5',
]

const DEGREE_VARIANT = {
  DANGER: 'red',
  WARNING: 'orange',
  GOOD: 'green',
  NONE: undefined,
  NEUTRAL: undefined,
  INJURY1: undefined,
  INJURY2: undefined,
  INJURY3: undefined,
  INJURY4: undefined,
  INJURY5: undefined,
} as const

export const getDegreeColor = (
  degree?: ThemeDegreeColor,
  whitelistName?: DegreeColorWhitelistName,
) => {
  if (!degree) {
    return undefined
  }

  const whitelist = getWhitelist(whitelistName)

  if (degree && whitelist.includes(degree)) {
    return theme.colors[degree]
  }
  return undefined
}

export const getChipBgColorByDegree = (degree?: ThemeDegreeColor) => {
  if (!degree) {
    return undefined
  }

  const bgColor = {
    GOOD: 'lime-600',
    WARNING: 'orange-400',
    DANGER: 'soft-red-400',
  }

  if (degree in bgColor) {
    return bgColor[degree as keyof typeof bgColor]
  }

  return undefined
}

export const getDegreeVariant = (
  degree?: ThemeDegreeColor,
  whitelistName?: DegreeColorWhitelistName,
) => {
  if (!degree) {
    return undefined
  }

  const whitelist = getWhitelist(whitelistName)

  if (degree && whitelist.includes(degree)) {
    return DEGREE_VARIANT[degree]
  }
  return undefined
}

export const getEventTypeColor = (scheduleType: keyof typeof ScheduleType | undefined | null) => {
  switch (scheduleType) {
    case 'TRAINING':
      return 'violet-500'
    case 'MATCH':
      return 'red-500'
    default:
      return 'grey-500'
  }
}

export const getEventTypeVariant = (scheduleType: keyof typeof ScheduleType | undefined | null) => {
  switch (scheduleType) {
    case 'TRAINING':
      return 'violet'
    case 'MATCH':
      return 'red'
    default:
      return 'grey'
  }
}

export const getColor = (color?: string, defaultColor?: string) => {
  if (!color) {
    return defaultColor
  }

  if (inOperator(theme.colors, color)) {
    return theme.colors[color]
  }

  return color
}

const getWhitelist = (whitelistName?: DegreeColorWhitelistName) => {
  if (whitelistName === 'EXTENDED_DEGREE_COLOR_WHITELIST') {
    return EXTENDED_DEGREE_COLOR_WHITELIST
  } else if (whitelistName === 'MULTI_DATA_DEGREE_COLOR_WHITELIST') {
    return MULTI_DATA_DEGREE_COLOR_WHITELIST
  }

  return DEFAULT_DEGREE_COLOR_WHITELIST
}
