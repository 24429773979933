import React from 'react'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { Avatar } from '@plco-pro/components/molecules/avatar'
import { CheckboxItem, CheckboxItemProps } from '@plco-pro/components/molecules/checkbox-item'

export type DropdownMenuCheckboxItemProps = CheckboxItemProps & {
  title: string
  value?: string | null
  src?: string | null
}

export const DropdownMenuCheckboxItem: React.FunctionComponent<DropdownMenuCheckboxItemProps> = ({
  src,
  value,
  title,
  containerProps,
  ...rest
}) => {
  return (
    <CheckboxItem {...rest} containerProps={{ py: '6px', ...containerProps }}>
      <Flex sx={{ alignItems: 'center' }}>
        {src !== null && <Avatar size={28} sx={{ mr: 1 }} src={src} value={value} />}
        <Flex>
          <Text ellipsis>{title}</Text>
        </Flex>
      </Flex>
    </CheckboxItem>
  )
}
