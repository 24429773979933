import React, { MouseEvent } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'

export type DropdownMenuTextItemProps = {
  title?: string
  subTitle?: string
  containerProps?: BoxProps
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

const ContainerBox = (props: BoxProps) => (
  <Box
    sx={
      props.onClick
        ? {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'bg-hover',
            },
            '&:active': {
              backgroundColor: 'bg-pressed',
            },
          }
        : {}
    }
    {...props}
  />
)

export const DropdownMenuTextItem: React.FunctionComponent<DropdownMenuTextItemProps> = ({
  title,
  subTitle,
  containerProps,
  onClick,
}) => {
  return (
    <ContainerBox onClick={onClick} {...containerProps}>
      <Flex
        sx={{
          height: '100%',
          flexDirection: 'column-reverse',
          justifyContent: 'center',
          px: 2,
        }}
      >
        <Text variant={'p1'} ellipsis>
          {title}
        </Text>
        <Text sx={{ color: 'grey-400', mb: '4px' }} variant={'p1'} ellipsis>
          {' '}
          {subTitle}{' '}
        </Text>
      </Flex>
    </ContainerBox>
  )
}
