import { ChangeEvent, useState } from 'react'

import { Button, Flex, HyperLink, Icon, Spinner, Text, Textarea } from '@plco-pro/components/atoms'
import { Avatar, IconButton } from '@plco-pro/components/molecules'
import {
  FeedbackComment,
  FeedbackDataFragment,
  useUpdateFeedbackCommentsMutation,
} from '@plco-pro/graphqls/react.generated'
import { useI18n, useNationality, useViewer } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useFeedbackError } from '@plco-pro/hooks/feedback-error'
import { useMomentFormat } from '@plco-pro/hooks/use-moment-format'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { WITHDRAWN_USER } from '@plco-pro/utils/constant'
import { AnalyticsEventType } from '@plco-pro/utils/libs'

import { FEEDBACK_COMMENT_MAX_LENGTH, getFeedbackAmplitudeType } from './helper'

interface Props {
  feedback: FeedbackDataFragment
  feedbackId: string
  editId: string
  comment: FeedbackComment
  isEditing: boolean
  onEdit: (id?: string) => void
  onDelete: (commentId: string) => void
}

export const FeedbackCommentsItem = ({
  feedback,
  feedbackId,
  editId,
  comment,
  isEditing,
  onEdit,
  onDelete,
}: Props) => {
  const { fotmatFeedbackCommentsDate } = useMomentFormat()
  const { smAndDown } = useResponsiveContext()
  const { formatMessage } = useI18n()
  const { viewer } = useViewer()
  const nationality = useNationality()
  const showFeedbackError = useFeedbackError()
  const { trackEvent } = useAnalytics()

  const [editedComment, setEditedComment] = useState(comment.comment)
  const [textareaHeight, setTextareaHeight] = useState(0)

  const [updateCommentMutation, { loading }] = useUpdateFeedbackCommentsMutation({
    onCompleted: ({ updateFeedbackComment }) => {
      const feedbackTypeText = getFeedbackAmplitudeType(updateFeedbackComment)

      trackEvent(AnalyticsEventType.SAVE_FEEDBACK_COMMENT, {
        type: feedbackTypeText,
      })
      onEdit()
    },
    onError: ({ graphQLErrors }) => {
      showFeedbackError(graphQLErrors, true)
    },
  })

  const canEdit = viewer?.id === comment.user.id && !isEditing
  const isWithdrawnUser = comment.user.id === WITHDRAWN_USER

  const onChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > FEEDBACK_COMMENT_MAX_LENGTH) {
      const lastChar = e.target.value.charAt(e.target.value.length - 1)

      setEditedComment(`${editedComment.slice(0, -1)}${lastChar}`)

      return
    }

    setEditedComment(e.target.value)
  }

  const onCancel = () => {
    onEdit()
    setEditedComment(comment.comment)
  }

  const updateComment = () => {
    if (comment.comment === editedComment) {
      const feedbackTypeText = getFeedbackAmplitudeType(feedback)

      trackEvent(AnalyticsEventType.SAVE_FEEDBACK_COMMENT, {
        type: feedbackTypeText,
      })
      onEdit()

      return
    }

    if (loading) {
      return
    }

    updateCommentMutation({
      variables: {
        input: {
          feedbackId,
          commentId: comment.id,
          comment: editedComment,
        },
        multilingualTextInput: {
          nationality,
        },
      },
    })
  }

  return (
    <Flex
      sx={{
        gap: '12px',
        alignItems: 'flex-start',
        px: [2, 3],
      }}
    >
      <Avatar
        src={comment.user.pictureUrl}
        value={comment.user.name}
        isWithdrawn={isWithdrawnUser}
      />
      <Flex sx={{ flex: 1, flexDirection: 'column', gap: 1 }}>
        <Flex sx={{ gap: 2, justifyContent: 'space-between' }}>
          <Flex sx={{ flex: 1, flexDirection: 'column', gap: '2px' }}>
            <Text sx={{ fontWeight: 700 }}>
              {isWithdrawnUser
                ? formatMessage({ id: 'feedback.withdrew-user' })
                : comment.user.name}
            </Text>
            <Text sx={{ fontSize: 'p2', color: 'grey-400' }}>
              {fotmatFeedbackCommentsDate(comment.createdAt)}
            </Text>
          </Flex>
          {canEdit && (
            <Flex sx={{ flexShrink: 0 }}>
              <IconButton
                onClick={() => onEdit(comment.id)}
                src={'/images/ic_edit.svg'}
                color={'grey-400'}
                size={smAndDown ? 'lg' : 'sm'}
                iconSize={smAndDown && 24}
              />
              <IconButton
                onClick={() => onDelete(comment.id)}
                src={'/images/ic_delete_outline.svg'}
                color={'grey-400'}
                size={smAndDown ? 'lg' : 'sm'}
                iconSize={smAndDown && 24}
              />
            </Flex>
          )}
          {isEditing && editId === comment.id && (
            <Flex sx={{ alignItems: 'center' }}>
              <Text sx={{ fontSize: 'p2', fontWeight: 700, color: 'grey-300' }}>
                {formatMessage({ id: 'feedback.editing' })}
              </Text>
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '32px',
                  height: '32px',
                }}
              >
                <Icon src={'/images/ic_edit.svg'} color={'grey-300'} />
              </Flex>
            </Flex>
          )}
        </Flex>
        {isEditing && editId === comment.id ? (
          <Flex sx={{ flexDirection: 'column', gap: '12px' }}>
            <Flex
              sx={{
                flexDirection: 'column',
                gap: 3,
                position: 'relative',
                minHeight: '112px',
                p: 2,
                bg: 'white-500',
                border: '1px solid',
                borderColor: 'primary-500',
                borderRadius: 10,
              }}
            >
              <Textarea
                ref={(node) => {
                  if (!node) {
                    return
                  }
                  node.style.height = `${node.scrollHeight}px`
                }}
                value={editedComment}
                onChange={onChangeComment}
                placeholder={formatMessage({ id: 'feedback.add-comment-this-feedback' })}
                sx={{
                  p: 0,
                  height: textareaHeight,
                  maxHeight: '160px',
                  border: 'none',
                  resize: 'none',
                  fontSize: 's2',
                  fontWeight: 400,
                }}
              />
              <Flex sx={{ justifyContent: 'flex-end', gap: 1 }}>
                <Button onClick={onCancel} variant={'secondary'} color={'grey'} size={'sm'}>
                  {formatMessage({ id: 'CANCEL' })}
                </Button>
                <Button onClick={updateComment} disabled={!editedComment.length} size={'sm'}>
                  {loading ? (
                    <Spinner size={'sm'} color={'alternative'} />
                  ) : (
                    formatMessage({ id: 'SAVE' })
                  )}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <HyperLink>
            <Text
              ref={(node) => {
                if (!node) {
                  return
                }
                setTextareaHeight(node.scrollHeight)
              }}
              sx={{
                fontSize: 's2',
                fontWeight: 400,
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
            >
              {comment.comment}
              {comment.isUpdated && (
                <Text sx={{ display: 'inline-block', fontWeight: 500, color: 'grey-400' }}>
                  {formatMessage({ id: 'feedback.edited' })}
                </Text>
              )}
            </Text>
          </HyperLink>
        )}
      </Flex>
    </Flex>
  )
}
