import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import { FlexProps, ResponsiveStyleValue } from 'theme-ui'

import { useResponsive } from '@plco-pro/hooks/responsive'
import { Keyframes } from '@plco-pro/styles'

export type SpinnerProps = FlexProps & {
  size?: ResponsiveStyleValue<'sm' | 'md' | 'lg'>
  color?: 'alternative' | 'primary'
}

const ANIMATION_DURATION = 0.8

const SpinnerDiv = styled('div')<{ size: any; color: 'alternative' | 'primary' }>`
  box-sizing: border-box;
  border-radius: 50%;
  ${(props) => props.size};
  border-top-color: ${(props) => props.theme.colors[props.color]};
  border-bottom-color: ${(props) => props.theme.colors[props.color]};
  border-left-color: ${(props) => props.theme.colors[props.color]};
  animation: ${Keyframes.rotation} ${ANIMATION_DURATION}s ease infinite;
`

export const Spinner = ({ size = 'lg', color = 'primary', ...rest }: SpinnerProps) => {
  const getResponsiveProp = useResponsive()

  const responsiveSize = useMemo(() => getResponsiveProp(size), [size, getResponsiveProp])

  const spinnerSize = useMemo(() => {
    if (responsiveSize === 'sm') {
      return {
        width: '16px',
        height: '16px',
        border: '2px solid transparent',
      }
    } else if (responsiveSize === 'md') {
      return {
        width: '24px',
        height: '24px',
        border: '3px solid transparent',
      }
    } else if (responsiveSize === 'lg') {
      return {
        width: '36px',
        height: '36px',
        border: '5px solid transparent',
      }
    }
  }, [responsiveSize])

  return <SpinnerDiv size={spinnerSize} color={color} data-testid={'spinner'} {...rest} />
}
