import React from 'react'

import { Arc, ArcProps } from '@plco-pro/components/atoms/arc'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { useDataInterpreter } from '@plco-pro/hooks/data-interpreter'
import { useI18n } from '@plco-pro/hooks/i18n'
import theme from '@plco-pro/themes/main'

export type DonutDataProps = ArcProps & {
  value?: number // 0 - 100
  path: string
}

const ArcContainer: React.FunctionComponent<FlexProps & { width: number; height: number }> = (
  props,
) => (
  <Flex
    sx={{
      justifyContent: 'center',
      position: 'relative',
      bottom: props.width || 0,
    }}
    {...props}
  />
)

const GRADIENT_COLORS = {
  GOOD: [theme.colors.GOOD_START, theme.colors.GOOD_END], // 80-100
  WARNING: [theme.colors.WARNING_START, theme.colors.WARNING_END], // 70-80
  DANGER: [theme.colors.DANGER_START, theme.colors.DANGER_END], // 1-70
  NONE: [theme.colors.NONE_START, theme.colors.NONE_END], // 0
}

export const DonutData: React.FunctionComponent<DonutDataProps> = ({
  width = 100,
  value,
  path,
  ...props
}) => {
  const { formatMessage: f } = useI18n()
  const interpret = useDataInterpreter()

  const interpretation = interpret(path, value)
  const label = value ? interpretation.label : f({ id: 'DATA.NONE' })
  const colors = value
    ? GRADIENT_COLORS[interpretation.degree as keyof typeof GRADIENT_COLORS]
    : GRADIENT_COLORS['NONE']
  const [startColor, endColor] = colors
  const labelColor = theme.colors[interpretation.degree as keyof typeof GRADIENT_COLORS]

  return (
    <Flex sx={{ flexDirection: 'column', marginBottom: -width }}>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: width,
          height: width,
        }}
      >
        <Text variant={'h1'} sx={{ fontWeight: 'bold' }}>
          {value}
        </Text>
        <Text variant={'p2'} sx={{ fontWeight: 'bold', color: labelColor }}>
          {label}
        </Text>
      </Flex>
      <ArcContainer width={width} height={width}>
        <Arc percent={value} width={width} startColor={startColor} endColor={endColor} {...props} />
      </ArcContainer>
    </Flex>
  )
}
