export const dashboard = {
  en: {
    'dashboard.update.label': '{date} last updated',
    'dashboard.dday.chip': '🔥 Free trial D-{leftDay}',

    'dashboard.calendar.today': 'Today',
    'dashboard.calendar.setting.title': 'Setting',
    'dashboard.calendar.setting.general.subtitle': 'General',
    'dashboard.calendar.setting.general.condition-check': 'Cond. check',
    'dashboard.calendar.setting.check.subtitle': 'Check',
    'dashboard.calendar.data.show-more': 'more···',

    'dashboard.condition-banner.title': '😎 Condition check',
    'dashboard.workout-banner.title': '💪 Workout check',
    'dashboard.banner-checked.players': 'Check',
    'dashboard.banner-unchecked.players': 'Unchecked',
    'dashboard.banner-unchecked.notification': 'Notify unchecked players',

    'dashboard.data.weekly-workload.title': 'Team workload total',
    'dashboard.data.weekly-workload.toggle-button': 'Team event only',
    'dashboard.data.weekly-workload.heavy': '4000\nHeavy workload',
    'dashboard.data.last-week-day.workload.sum': 'Last week on {day}\n{sum}',
    'dashboard.data.last-week.label': 'Last week',
    'dashboard.data.this-week.label': 'This week',

    'dashboard.data.latest-feedback.title': 'Latest feedback',
    'dashboard.data.latest-feedback.guide':
      'You can view up to 20 feedback.\nCheck out notification or squad for all.',

    'dashboard.data.condition.title': 'Condition check in need',
    'dashboard.data.condition.chip.in-bad': 'In bad',
    'dashboard.data.condition.chip-guide.in-bad':
      'It displays players with a poor condition when checked on today\n- Fatigue, stress, muscle, mood, sleep quality: Less than 4 points\n- Sleep time: Less than 6 hours',
    'dashboard.data.condition.chip.plummeted': 'Plummeted',
    'dashboard.data.condition.chip-guide.plummeted':
      'It displays players whose condition has suddenly dropped compared to recent trends.',
    'dashboard.data.condition.empty': 'Excellent condition management',

    'dashboard.data.risk-of-injury.title': 'Risk of injury detected',
    'dashboard.data.risk-of-injury.empty': 'No risk of injury',

    'dashboard.data.risk-of-injury.increased': 'Increased',
    'dashboard.data.risk-of-injury.caution': 'Caution',
    'dashboard.data.risk-of-injury.detected': 'Detected',

    'dashboard.data.risk-of-injury.reason.high-acwr': 'High ACWR',
    'dashboard.data.risk-of-injury.reason.low-acwr': 'Low ACWR',
    'dashboard.data.risk-of-injury.reason.weekly-workload-surge': 'Weekly workload surge',
    'dashboard.data.risk-of-injury.reason.strain': 'Strain',
    'dashboard.data.risk-of-injury.reason.high-weekly-workload': 'High weekly workload',
    'dashboard.data.risk-of-injury.reason.monotony': 'Monotony',
    'dashboard.data.risk-of-injury.reason.non-elastic-intensity': 'Non-elastic intensity',

    'dashboard.data.risk-of-injury.suggestion.A':
      'By mixing high-intensity and low-intensity workouts to reduce training volume, you can lower the risk of injuries due to fatigue and alleviate the training burden!',
    'dashboard.data.risk-of-injury.suggestion.B':
      'By adjusting the training intensity and duration to reduce training violume, you can reduce the risk of injuries due to fatigue and alleviate the training burden!',
    'dashboard.data.risk-of-injury.suggestion.C':
      'By mixing high-intensity and low-intensity workouts to reduce or gradually increase training volume, you can reduce the risk of injuries due to fatigue and alleviate the training burden!',
    'dashboard.data.risk-of-injury.suggestion.D':
      'By adjusting the training intensity and duration to reduce or gradually increase training volume, you can reduce the risk of injuries due to fatigue and alleviate the training burden!',
    'dashboard.data.risk-of-injury.suggestion.E':
      'By mixing high-intensity and low-intensity workouts and gradually adjusting training volume, you can reduce the risk of injuries due to fatigue!',
    'dashboard.data.risk-of-injury.suggestion.F':
      'By adjusting the training intensity and duration to reduce or gradually increase training volume, you can reduce the risk of injuries due to fatigue!',
    'dashboard.data.risk-of-injury.suggestion.G':
      'By mixing high-intensity and low-intensity workouts to reduce training volume, you can reduce the risk of injuries due to fatigue!',
    'dashboard.data.risk-of-injury.suggestion.H':
      'By adjusting the training intensity and duration to reduce training volume, you can reduce the risk of injuries due to fatigue!',
    'dashboard.data.risk-of-injury.suggestion.I':
      'By mixing high-intensity and low-intensity workouts to increase training volume, you can prevent injuries due to fitness level decline and reduce the training burden!',
    'dashboard.data.risk-of-injury.suggestion.J':
      'By adjusting the training intensity and duration to increase training volume, you can prevent injuries due to fitness level decline and reduce the training burden!',
    'dashboard.data.risk-of-injury.suggestion.K':
      'By mixing high-intensity and low-intensity workouts to increase training volume, you can prevent injuries due to fitness level decline!',
    'dashboard.data.risk-of-injury.suggestion.L':
      'By adjusting the training intensity and duration to increase training volume, you can prevent injuries due to fitness level decline!',
    'dashboard.data.risk-of-injury.suggestion.M':
      'By mixing high-intensity and low-intensity workouts to increase training volume, you can reduce the training burden!',
    'dashboard.data.risk-of-injury.suggestion.N':
      'By adjusting the training intensity and duration to reduce training volume, you can reduce the training burden!',
    'dashboard.data.risk-of-injury.suggestion.O':
      'By mixing high-intensity and low-intensity workouts to gradually adjust training volume, you can reduce the training burden!',
    'dashboard.data.risk-of-injury.suggestion.P':
      'By adjusting the training intensity and duration to reduce or gradually increse training volume, you can reduce the training volume!',
    'dashboard.data.risk-of-injury.suggestion.Q':
      'Mix high-intensity and low-intensity workouts to gradually adjust training volume!',
    'dashboard.data.risk-of-injury.suggestion.R':
      'Adjust the training intensity and duration to reduce or gradually increse training volume!',
    'dashboard.data.risk-of-injury.suggestion.S':
      'By planning training that can give a flexible workload by mixing high-intensity and low-intensity workouts, you can reduce the training burden!',
    'dashboard.data.risk-of-injury.suggestion.T':
      'By planning training that can give a flexible workload by adjusting training intensity and time can reduce the training burden!',
    'dashboard.data.risk-of-injury.suggestion.U':
      'Plan training that can give a flexible workload by mixing high-intensity and low-intensity workouts!',
    'dashboard.data.risk-of-injury.suggestion.V':
      'Plan training that can give a flexible workload by adjusting training intensity and time!',
    'dashboard.data.risk-of-injury.suggestion.W':
      'Plan training that combines high-intensity and low-intensity workouts!',
    'dashboard.data.risk-of-injury.suggestion.X': '-',

    'dashboard.data.current-injury.title': 'Current injuries',
    'dashboard.data.current-injury.empty': 'There are currently no injured players',

    'dashboard.data.soreness.title': 'Players in soreness',
    'dashboard.data.soreness.chip.severe': 'Severe',
    'dashboard.data.soreness.chip-guide.severe':
      'It displays players with at least one soreness level 3 or higher.',
    'dashboard.data.soreness.severe.empty': 'There are no players with severe soreness',
    'dashboard.data.soreness.chip.multiple': 'Multiple',
    'dashboard.data.soreness.chip-guide.multiple':
      'It displays players with four or more soreness.',
    'dashboard.data.soreness.multiple.empty': 'There are no players with multiple soreness',
    'dashboard.data.soreness.list': 'Soreness list',

    'dashboard.data.today-event.title': "Today's event",
    'dashboard.data.today-event.empty-player': 'There are no players to display',
    'dashboard.data.today-event-training.none':
      'There are no participants.\nPlease add participants.',
    'dashboard.data.today-event-match.none':
      'There are no players on the lineup.\nTry registering for the lineup.',
    'dashboard.data.today-event.match-player.none': 'There is no workout schedule for today.',

    'dashboard.popup.reminder-condition-unchecked.title':
      'Would you like to send a Condition\ncheck notification to {count} players?',
    'dashboard.popup.reminder-workout-unchecked.title':
      'Would you like to send a Workout check\nnotification to {count} players?',
    'dashboard.popup.reminder-participant-unchecked.title':
      'Would you like to send a Participation\ncheck notification to {count} players?',
    'dashboard.popup.reminder-workload-unchecked.title':
      'Would you like to send a Workload\ncheck notification to {count} players?',
    'dashboard.popup.reminder.subtitle': 'Only unchecked players are included.',

    'dashboard.popup.player-select.all': 'Select all',
    'dashboard.popup.player-select.all-selected': 'All selected ({count})',
    'dashboard.popup.player-select.none-selected': 'None',
    'dashboard.popup.player-select.search.player': 'Search players',
    'dashboard.popup.player-select.search.no-input': 'None',
    'dashboard.popup.player-select.search.none': 'No results found.',

    'dashboard.create-injury.player-select.title': 'Select players',

    'dashboard.button.training-report': 'Training report',
    'dashboard.button.match-report': 'Match report',
    'dashboard.button.practice-report': 'Practice match report',
    'dashboard.button.send-participant-notification': 'Send participation',
    'dashboard.button.send-workload-notification': 'Send workload',
    'dashboard.button.injury-registration': 'Register injury',
    'dashboard.button.create-event': 'Create event',
    'dashboard.button.register-lineup': 'Register lineup',

    'dashboard.data-etc.name': 'Name',
    'dashboard.data-etc.type': 'Type',
    'dashboard.data-etc.part': 'Part',
    'dashboard.data-etc.level': 'Level',
    'dashboard.data-etc.lasting-days': 'Lasting days',
    'dashboard.data-etc.description': 'Description',
    'dashboard.data-etc.position': 'Position',
    'dashboard.data-etc.criteria': '{count} criteria',

    'dashboard.event.general': 'General',
    'dashboard.event.participant': 'Participation',
    'dashboard.event.lineup': 'Lineup',
    'dashboard.event.all': 'All',
    'dashboard.event.training-attend': 'Attend',
    'dashboard.event.training-absent': 'Absent',
    'dashboard.event.unchecked': 'Unchecked',
    'dashboard.event.match-starting': 'Starting',
    'dashboard.event.match-subs': 'Subs',
    'dashboard-event.match-vs': 'vs',
    'dashboard-event.match-result': 'Result',

    'dashboard.workout.normal': 'Normal workout',
    'dashboard.workout.rehab': 'Rehabilitation',
    'dashboard.workout.absence-injury': 'Absence due to injuries',
    'dashboard.workout.absence-other-reason': 'Absence due to other reasons',

    'dashboard.soreness.description.level-1': 'Mild',
    'dashboard.soreness.description.level-2': 'Moderate',
    'dashboard.soreness.description.level-3': 'Severe',
    'dashboard.soreness.description.level-4': 'Very severe',
    'dashboard.soreness.description.level-5': 'Unbearable',

    'dashboard.card-team-invite.title': 'Invite to team',
    'dashboard.card-team-invite.subtitle': 'Invite members\nwith a team code',

    'dashboard.filter.schedule.subtitle': 'Scheduler',
    'dashboard.filter.intensity-check.subtitle': 'Workload check',
  },
  ko: {
    'dashboard.update.label': '{date} 업데이트',
    'dashboard.dday.chip': '🔥 무료체험 D-{leftDay}',
    'dashboard.calendar.today': '오늘',
    'dashboard.calendar.setting.title': '설정',
    'dashboard.calendar.setting.general.subtitle': '일반',
    'dashboard.calendar.setting.general.condition-check': '컨디션 체크',
    'dashboard.calendar.setting.check.subtitle': '체크',
    'dashboard.calendar.data.show-more': '더보기···',

    'dashboard.condition-banner.title': '😎 컨디션 체크',
    'dashboard.workout-banner.title': '💪 운동 체크',
    'dashboard.banner-checked.players': '체크',
    'dashboard.banner-unchecked.players': '미체크',
    'dashboard.banner-unchecked.notification': '미체크 알림 보내기',

    'dashboard.data.weekly-workload.title': '팀 주간 운동부하 합계',
    'dashboard.data.weekly-workload.toggle-button': '팀 일정만 보기',
    'dashboard.data.weekly-workload.heavy': '4000\n많은 운동량',
    'dashboard.data.last-week-day.workload.sum': '지난주 {day}요일\n누적 {sum}',
    'dashboard.data.last-week.label': '지난주',
    'dashboard.data.this-week.label': '이번주',

    'dashboard.data.latest-feedback.title': '최신 피드백',
    'dashboard.data.latest-feedback.guide':
      '최신 등록 기준 20개까지 볼 수 있습니다.\n전체 피드백은 알림 또는 선수단에서 확인하세요.',

    'dashboard.data.condition.title': '컨디션 확인이 필요한 선수',
    'dashboard.data.condition.chip.in-bad': '안 좋은 선수',
    'dashboard.data.condition.chip-guide.in-bad':
      '당일 컨디션을 확인했을 때 안 좋은 선수를 나타냅니다.\n- 피로도, 스트레스, 근육, 기분, 수면품질: 4점 미만\n- 수면 시간: 6시간 미만',
    'dashboard.data.condition.chip.plummeted': '급락한 선수',
    'dashboard.data.condition.chip-guide.plummeted':
      '최근의 추세에 비해 컨디션 항목이 갑자기 떨어진 선수를 나타냅니다.',
    'dashboard.data.condition.empty': '컨디션 관리가 탁월합니다',

    'dashboard.data.risk-of-injury.title': '부상 위험이 감지되는 선수',
    'dashboard.data.risk-of-injury.empty': '부상 위험이 없습니다',

    'dashboard.data.risk-of-injury.increased': '부상 위험 증가',
    'dashboard.data.risk-of-injury.caution': '부상 위험 유의',
    'dashboard.data.risk-of-injury.detected': '부상 위험 감지',

    'dashboard.data.risk-of-injury.reason.high-acwr': '체력 대비 피로도 높음',
    'dashboard.data.risk-of-injury.reason.low-acwr': '체력 대비 훈련량 부족',
    'dashboard.data.risk-of-injury.reason.weekly-workload-surge': '전주 대비 주간 운동부하 급증',
    'dashboard.data.risk-of-injury.reason.strain': '신체적, 심리적인 훈련 부담감',
    'dashboard.data.risk-of-injury.reason.high-weekly-workload': '높은 주간 운동부하',
    'dashboard.data.risk-of-injury.reason.monotony': '단조로운 운동',
    'dashboard.data.risk-of-injury.reason.non-elastic-intensity': '비탄력적 운동강도',

    'dashboard.data.risk-of-injury.suggestion.A':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추면, 피로도로 인한 부상 위험과 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.B':
      '훈련 강도와 시간을 조절하여 훈련량을 낮추면, 피로도로 인한 부상 위험과 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.C':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추거나 점진적으로 끌어올리면, 피로도로 인한 부상 위험과 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.D':
      '훈련 강도와 시간을 조절하여 훈련량을 낮추거나 점진적으로 끌어올리면, 피로도로 인한 부상 위험과 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.E':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추거나 점진적으로 끌어올리면, 피로도로 인한 부상 위험을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.F':
      '훈련 강도와 시간을 조절하여 훈련량을 낮추거나 점진적으로 끌어올리면, 피로도로 인한 부상 위험을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.G':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추면, 피로도로 인한 부상 위험을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.H':
      '훈련 강도와 시간을 조절하여 훈련량을 낮추면, 피로도로 인한 부상 위험을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.I':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 끌어올리면, 체력 저하로 인한 부상 노출을 막고 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.J':
      '훈련 강도와 시간을 조절하여 훈련량을 끌어올리면, 체력 저하로 인한 부상 노출을 막고 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.K':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 끌어올리면, 체력 저하로 인한 부상 노출을 막을 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.L':
      '훈련 강도와 시간을 조절하여 훈련량을 끌어올리면, 체력 저하로 인한 부상 노출을 막을 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.M':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.N':
      '훈련 강도와 시간을 조절하여 훈련량을 낮추면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.O':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추거나 점진적으로 끌어올리면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.P':
      '훈련 강도와 시간을 조절하여 훈련량을 낮추거나 점진적으로 끌어올리면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.Q':
      '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추거나 점진적으로 끌어올리세요!',
    'dashboard.data.risk-of-injury.suggestion.R':
      '훈련 강도와 시간을 조절하여 훈련량을 낮추거나 점진적으로 끌어올리세요!',
    'dashboard.data.risk-of-injury.suggestion.S':
      '고강도 운동과 저강도 운동을 혼합하여 탄력적인 운동부하를 줄 수 있는 훈련을 계획하면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.T':
      '훈련 강도와 시간을 조절하여 탄력적인 운동부하를 줄 수 있는 훈련을 계획하면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.U':
      '고강도 운동과 저강도 운동을 혼합하여 탄력적인 운동부하를 줄 수 있는 훈련을 계획하세요!',
    'dashboard.data.risk-of-injury.suggestion.V':
      '훈련 강도와 시간을 조절하여 탄력적인 운동부하를 줄 수 있는 훈련을 계획하세요!',
    'dashboard.data.risk-of-injury.suggestion.W':
      '고강도 운동과 저강도 운동이 혼합된 훈련을 계획하세요!',
    'dashboard.data.risk-of-injury.suggestion.X': '-',

    'dashboard.data.current-injury.title': '현재 부상 선수',
    'dashboard.data.current-injury.empty': '현재 부상 선수가 없습니다',

    'dashboard.data.soreness.title': '현재 통증을 겪고 있는 선수',
    'dashboard.data.soreness.chip.severe': '심각한 통증',
    'dashboard.data.soreness.chip-guide.severe':
      '3단계 이상의 통증이 1개 이상인 선수를 표시합니다.',
    'dashboard.data.soreness.severe.empty': '심각한 통증이 있는 선수는 없습니다',
    'dashboard.data.soreness.chip.multiple': '여러 통증',
    'dashboard.data.soreness.chip-guide.multiple': '전체 통증이 4개 이상인 선수를 표시합니다.',
    'dashboard.data.soreness.multiple.empty': '여러 통증을 가진 선수는 없습니다',
    'dashboard.data.soreness.list': '통증 리스트',

    'dashboard.data.today-event.title': '오늘의 일정',
    'dashboard.data.today-event.empty-player': '표시할 선수가 없습니다',
    'dashboard.data.today-event-training.none': '참가 대상이 없습니다.\n참가 대상을 추가해 보세요.',
    'dashboard.data.today-event-match.none': '출전 선수가 없습니다.\n출전 선수를 등록해 보세요.',
    'dashboard.data.today-event.match-player.none': '오늘은 운동 일정이 없습니다.',

    'dashboard.popup.reminder-condition-unchecked.title':
      '{count}명의 선수에게\n컨디션 알림을 보낼까요?',
    'dashboard.popup.reminder-workout-unchecked.title':
      '{count}명의 선수에게\n운동 알림을 보낼까요?',
    'dashboard.popup.reminder-participant-unchecked.title':
      '{count}명의 선수에게\n훈련 참가여부 알림을 보낼까요?',
    'dashboard.popup.reminder-workload-unchecked.title':
      '{count}명의 선수에게\n운동부하 알림을 보낼까요?',
    'dashboard.popup.reminder.subtitle': '미체크 선수만 포함됩니다.',

    'dashboard.popup.player-select.all': '전체 선택',
    'dashboard.popup.player-select.all-selected': '전체 선택됨 ({count}명)',
    'dashboard.popup.player-select.none-selected': '선택 없음',
    'dashboard.popup.player-select.search.player': '선수 검색',
    'dashboard.popup.player-select.search.no-input': '입력 없음',
    'dashboard.popup.player-select.search.none': '검색 결과가 없습니다.',

    'dashboard.create-injury.player-select.title': '선수를 선택하세요',

    'dashboard.button.training-report': '훈련 리포트 보기',
    'dashboard.button.match-report': '경기 리포트 보기',
    'dashboard.button.practice-report': '연습경기 리포트 보기',
    'dashboard.button.send-participant-notification': '참가여부 알림 보내기',
    'dashboard.button.send-workload-notification': '운동부하 알림 보내기',
    'dashboard.button.injury-registration': '부상 등록',
    'dashboard.button.create-event': '일정 추가',
    'dashboard.button.register-lineup': '출전 선수 등록하기',

    'dashboard.data-etc.name': '이름',
    'dashboard.data-etc.type': '유형',
    'dashboard.data-etc.part': '부위',
    'dashboard.data-etc.level': '단계',
    'dashboard.data-etc.lasting-days': '지속일',
    'dashboard.data-etc.description': '설명',
    'dashboard.data-etc.position': '포지션',
    'dashboard.data-etc.criteria': '{count}개 항목',

    'dashboard.event.general': '일반 정보',
    'dashboard.event.participant': '참가여부',
    'dashboard.event.lineup': '출전 선수',
    'dashboard.event.all': '전체',
    'dashboard.event.training-attend': '참가',
    'dashboard.event.training-absent': '불참',
    'dashboard.event.unchecked': '미체크',
    'dashboard.event.match-starting': '선발',
    'dashboard.event.match-subs': '교체',
    'dashboard-event.match-vs': 'vs',
    'dashboard-event.match-result': '경기 결과',

    'dashboard.workout.normal': '정상운동',
    'dashboard.workout.rehab': '재활운동',
    'dashboard.workout.absence-injury': '부상으로 인한 불참',
    'dashboard.workout.absence-other-reason': '기타 사유로 인한 불참',

    'dashboard.soreness.description.level-1': '가벼운 통증',
    'dashboard.soreness.description.level-2': '중간 정도 통증',
    'dashboard.soreness.description.level-3': '심각한 통증',
    'dashboard.soreness.description.level-4': '매우 심각한 통증',
    'dashboard.soreness.description.level-5': '참을 수 없는 통증',

    'dashboard.card-team-invite.title': '팀으로 초대하기',
    'dashboard.card-team-invite.subtitle': '팀 코드로\n멤버를 초대하세요',

    'dashboard.filter.schedule.subtitle': '일정',
    'dashboard.filter.intensity-check.subtitle': '운동부하 체크',
  },
  ja: {
    'dashboard.update.label': '{date} アップデート', // '{date} 업데이트',
    'dashboard.dday.chip': '🔥 無料体験D-{leftDay}',

    'dashboard.calendar.today': '今日', // '오늘',
    'dashboard.calendar.setting.title': '設定', // '설정',
    'dashboard.calendar.setting.general.subtitle': '一般', // '일반',
    'dashboard.calendar.setting.general.condition-check': 'コンディション', // '컨디션 체크',
    'dashboard.calendar.setting.check.subtitle': 'チェック', // '체크',
    'dashboard.calendar.data.show-more': 'もっと見る···', // '{count}개 더보기...',

    'dashboard.condition-banner.title': '😎 コンディションチェック', // '😎 컨디션 체크',
    'dashboard.workout-banner.title': '💪 運動チェック', // '💪 운동 체크',
    'dashboard.banner-checked.players': 'チェック', // '체크',
    'dashboard.banner-unchecked.players': '未チェック', // '미체크',
    'dashboard.banner-unchecked.notification': '未チェック通知を送る', // '미체크 알림 보내기',

    'dashboard.data.weekly-workload.title': 'チーム運動負荷合計', // '팀 주간 운동부하 합계',
    'dashboard.data.weekly-workload.toggle-button': 'チームの日程だけ', // '팀 일정만 보기',
    'dashboard.data.weekly-workload.heavy': '4000\n多くの運動量', // '4000\n많은 운동량',
    'dashboard.data.last-week-day.workload.sum': '先週 {day}曜日\n累積 {sum}', // '지난주 {day}요일\n누적 {sum}',
    'dashboard.data.last-week.label': '先週', // '지난주',
    'dashboard.data.this-week.label': '今週', // '이번주',

    'dashboard.data.latest-feedback.title': '最新フィードバック', // '최신 피드백',
    'dashboard.data.latest-feedback.guide':
      '最大 20 個のフィードバックを表示できます。\nすべての通知またはチームをチェックしてください。',
    // '최신 등록 기준 20개까지 볼 수 있습니다.\n전체 피드백은 알림 또는 선수단에서 확인하세요.',

    'dashboard.data.condition.title': 'コンディション確認必要選手', // '컨디션 확인이 필요한 선수',
    'dashboard.data.condition.chip.in-bad': '悪い', // '안 좋은 선수',
    'dashboard.data.condition.chip-guide.in-bad':
      '今日のチェック時に、体調が悪い選手を示します。\n- 疲労度、ストレス、筋肉、気分、睡眠品質: 4点未満\n- 睡眠時間: 6時間未満', // '당일 컨디션을 확인했을 때 안 좋은 선수를 나타냅니다.\n- 피로도, 스트레스, 근육, 기분, 수면품질: 4점 미만\n- 수면 시간: 6시간 미만',
    'dashboard.data.condition.chip.plummeted': '急落', // '급락한 선수',
    'dashboard.data.condition.chip-guide.plummeted':
      'これは、最近の傾向と比較して急激にコンディションが低下した選手を表しています。', // '최근의 추세에 비해 컨디션 항목이 갑자기 떨어진 선수를 나타냅니다.',
    'dashboard.data.condition.empty': 'コンディション管理が優れています', // '컨디션 관리가 탁월합니다',

    'dashboard.data.risk-of-injury.title': '怪我危険検出選手', // '부상 위험이 감지되는 선수',
    'dashboard.data.risk-of-injury.empty': '怪我の危険はありません', // '부상 위험이 없습니다'

    'dashboard.data.risk-of-injury.increased': '増加', // '부상 위험 증가',
    'dashboard.data.risk-of-injury.caution': '注意', // '부상 위험 유의',
    'dashboard.data.risk-of-injury.detected': '検出', // '부상 위험 감지',

    'dashboard.data.risk-of-injury.reason.high-acwr': '高いACWR', // '체력 대비 피로도 높음',
    'dashboard.data.risk-of-injury.reason.low-acwr': '低いACWR', // '체력 대비 훈련량 부족',
    'dashboard.data.risk-of-injury.reason.weekly-workload-surge':
      '先週に比べて週間の運動負荷が急増', // '전주 대비 주간 운동부하 급증',
    'dashboard.data.risk-of-injury.reason.strain': 'ストレイン', // '신체적, 심리적인 훈련 부담감',
    'dashboard.data.risk-of-injury.reason.high-weekly-workload': '高い週間ワークロード', // '높은 주간 운동부하',
    'dashboard.data.risk-of-injury.reason.monotony': '単調さ', // '단조로운 운동',
    'dashboard.data.risk-of-injury.reason.non-elastic-intensity': '弾力性のない強度', // '비탄력적 운동강도',

    'dashboard.data.risk-of-injury.suggestion.A':
      '高強度運動と低強度運動を組み合わせてトレーニング量を減らすことで、疲労による怪我のリスクを減少させ、トレーニングへの負担を軽減することができます!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추면, 피로도로 인한 부상 위험과 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.B':
      'トレーニングの強度と時間を調節してトレーニング量を下げると、疲労度による負傷の危険性とトレーニングに対する負担を軽減できます！', // '훈련 강도와 시간을 조절하여 훈련량을 낮추면, 피로도로 인한 부상 위험과 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.C':
      '高強度と低強度の運動を組み合わせ、トレーニングボリュームを減少させるか徐々に増加させることで、疲労からくるけがのリスクとトレーニングの負担感を最小限に抑えることができます!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추거나 점진적으로 끌어올리면, 피로도로 인한 부상 위험과 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.D':
      'トレーニングの強度と時間を調節してトレーニング量を下げたり、徐々に引き上げたりすると、疲労度による負傷の危険性とトレーニングに対する負担を軽減できます！', // '훈련 강도와 시간을 조절하여 훈련량을 낮추거나 점진적으로 끌어올리면, 피로도로 인한 부상 위험과 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.E':
      '高強度運動と低強度運動を組み合わせ、トレーニング量を徐々に調整することで、疲労による怪我のリスクを減少させることができます!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추거나 점진적으로 끌어올리면, 피로도로 인한 부상 위험을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.F':
      'トレーニングの強度と時間を調整することで、トレーニング量を減らすか徐々に増やし、疲労による怪我のリスクを低減できます!', // '훈련 강도와 시간을 조절하여 훈련량을 낮추거나 점진적으로 끌어올리면, 피로도로 인한 부상 위험을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.G':
      '高強度運動と低強度運動を組み合わせてトレーニング量を減らすことで、疲労による怪我のリスクを低減できます!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추면, 피로도로 인한 부상 위험을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.H':
      'トレーニングの強度と時間を調整してトレーニング量を減らすことで、疲労による怪我のリスクを低減できます!', // '훈련 강도와 시간을 조절하여 훈련량을 낮추면, 피로도로 인한 부상 위험을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.I':
      '高強度の運動と低強度の運動を混ぜてトレーニングボリュームを増加させることで、体力の低下からくるけがのリスクを防ぎ、トレーニングの負担感を減少させることができます!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 끌어올리면, 체력 저하로 인한 부상 노출을 막고 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.J':
      'トレーニングの強度と時間を調整してトレーニング量を増やすことで、体力低下による怪我のリスクを減少させ、トレーニングの負担を軽減できます!', // '훈련 강도와 시간을 조절하여 훈련량을 끌어올리면, 체력 저하로 인한 부상 노출을 막고 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.K':
      '高強度の運動と低強度の運動を混ぜてトレーニングボリュームを増加させることで、体力低下による怪我の露出を防ぐことができます!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 끌어올리면, 체력 저하로 인한 부상 노출을 막을 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.L':
      'トレーニングの強度と時間を調整して、ボリュームを増加させ、体力の衰えからくる潜在的なけがを防ぐことができます!', // '훈련 강도와 시간을 조절하여 훈련량을 끌어올리면, 체력 저하로 인한 부상 노출을 막을 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.M':
      '高強度の運動と低強度の運動を混ぜてトレーニングボリュームを増加させることで、トレーニングの負担を軽減できます!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.N':
      'トレーニングの強度と時間を調整してトレーニング量を減らすことで、トレーニングの負担を軽減できます!', // '훈련 강도와 시간을 조절하여 훈련량을 낮추면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.O':
      '高強度運動と低強度運動を組み合わせ、トレーニング量を徐々に調整することで、トレーニングの負担を軽減できます!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추거나 점진적으로 끌어올리면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.P':
      'トレーニング強度と時間を調整してトレーニング量を減少させたり、徐々に増加させたりすることで、トレーニングの負担を軽減できます!', // '훈련 강도와 시간을 조절하여 훈련량을 낮추거나 점진적으로 끌어올리면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.Q':
      '高強度と低強度の運動を組み合わせて、トレーニング量を減らすか、徐々に増やしてください!', // '고강도 운동과 저강도 운동을 혼합하여 훈련량을 낮추거나 점진적으로 끌어올리세요!',
    'dashboard.data.risk-of-injury.suggestion.R':
      'トレーニングの強度と時間を調整して、トレーニング量を減らすか、徐々に増やしてください!', // '훈련 강도와 시간을 조절하여 훈련량을 낮추거나 점진적으로 끌어올리세요!',
    'dashboard.data.risk-of-injury.suggestion.S':
      '高強度運動と低強度運動を混合して弾力的な運動負荷を減らせる練習を計画すれば、練習に対する負担感を減らすことができます！', // '고강도 운동과 저강도 운동을 혼합하여 탄력적인 운동부하를 줄 수 있는 훈련을 계획하면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.T':
      '練習強度と時間を調節して弾力的な運動負荷を与えることができる練習を計画すれば、練習に対する負担感を減らすことができます！', // '훈련 강도와 시간을 조절하여 탄력적인 운동부하를 줄 수 있는 훈련을 계획하면, 훈련에 대한 부담감을 낮출 수 있어요!',
    'dashboard.data.risk-of-injury.suggestion.U':
      '高強度運動と低強度運動を混合して弾力的な運動負荷を与える練習を計画してください！', // '고강도 운동과 저강도 운동을 혼합하여 탄력적인 운동부하를 줄 수 있는 훈련을 계획하세요!',
    'dashboard.data.risk-of-injury.suggestion.V':
      'トレーニングの強度と時間を調節して弾力的な運動負荷を与えることができるトレーニングを計画してください！', // '훈련 강도와 시간을 조절하여 탄력적인 운동부하를 줄 수 있는 훈련을 계획하세요!',
    'dashboard.data.risk-of-injury.suggestion.W':
      '高強度運動と低強度運動が混合されたトレーニングを計画してください！', // '고강도 운동과 저강도 운동이 혼합된 훈련을 계획하세요!',
    'dashboard.data.risk-of-injury.suggestion.X': '-', // '-',

    'dashboard.data.current-injury.title': '現在のけが選手', // '현재 부상 선수',
    'dashboard.data.current-injury.empty': '現在、けがをした選手はいません。', // '현재 부상 선수가 없습니다',

    'dashboard.data.soreness.title': '現在の痛み選手', // '현재 통증을 겪고 있는 선수',
    'dashboard.data.soreness.chip.severe': '深刻', // '심각한 통증',
    'dashboard.data.soreness.chip-guide.severe': '3段階以上の痛みが1つ以上の選手を表示します。', // '3단계 이상의 통증이 1개 이상인 선수를 표시합니다.',
    'dashboard.data.soreness.severe.empty': '重大な痛みを感じる選手はいません。', // '심각한 통증이 있는 선수는 없습니다',
    'dashboard.data.soreness.chip.multiple': '複数', // '여러 통증',
    'dashboard.data.soreness.chip-guide.multiple': '全体の痛みが4つ以上の選手を表示します。', // '전체 통증이 4개 이상인 선수를 표시합니다.',
    'dashboard.data.soreness.multiple.empty': '複数の痛みを感じる選手はいません。', // '여러 통증을 가진 선수는 없습니다',
    'dashboard.data.soreness.list': '痛みのリスト', // '통증 리스트',

    'dashboard.data.today-event.title': '今日のイベント', // '오늘의 일정',
    'dashboard.data.today-event.empty-player': '表示する選手がいません', // '표시할 선수가 없습니다',
    'dashboard.data.today-event-training.none':
      '参加対象がありません。\n参加対象を追加してみてください。', // '참가 대상이 없습니다.\n참가 대상을 추가해 보세요.',
    'dashboard.data.today-event-match.none': '出場選手はいません\n出場選手を登録してみてください。', // '출전 선수가 없습니다.\n출전 선수를 등록해 보세요.',
    'dashboard.data.today-event.match-player.none': '今日はスケジュールがありません。', // '오늘은 운동 일정이 없습니다.',

    'dashboard.popup.reminder-condition-unchecked.title':
      '{count}人の選手にコンディション\n通知を送りましょうか?', // '{count}명의 선수에게\n컨디션 알림을 보낼까요?',
    'dashboard.popup.reminder-workout-unchecked.title':
      '{count}人の選手に運動\n通知を送りましょうか?', // '{count}명의 선수에게\n운동 알림을 보낼까요?',
    'dashboard.popup.reminder-participant-unchecked.title':
      '{count}人の選手に参加するかどうか\n通知を送りましょうか?', // '{count}명의 선수에게\n훈련 참가여부 알림을 보낼까요?',
    'dashboard.popup.reminder-workload-unchecked.title':
      '{count}人の選手に運動負荷\n通知を送りましょうか?', // '{count}명의 선수에게\n운동부하 알림을 보낼까요?',
    'dashboard.popup.reminder.subtitle': '未チェックプレイヤーのみ。', // '미체크 선수만 포함됩니다.',

    'dashboard.popup.player-select.all': '全体選択', // '전체 선택',
    'dashboard.popup.player-select.all-selected': '全体選択({count}人)', // '전체 선택됨 ({count}명)',
    'dashboard.popup.player-select.none-selected': 'なし', // '선택 없음',
    'dashboard.popup.player-select.search.player': '検索', // '선수 검색',
    'dashboard.popup.player-select.search.no-input': 'なし', // '입력 없음',
    'dashboard.popup.player-select.search.none': '検索結果がありません。', // '검색 결과가 없습니다.',

    'dashboard.create-injury.player-select.title': '選手を選択してください', // '선수를 선택하세요.',

    'dashboard.button.training-report': '練習レポートを見る', // '훈련 리포트 보기',
    'dashboard.button.match-report': '試合レポートを見る', // '경기 리포트 보기',
    'dashboard.button.practice-report': '練習試合レポートを見る', // '연습경기 리포트 보기',
    'dashboard.button.send-participant-notification': '参加通知の送信', // '참가여부 알림 보내기',
    'dashboard.button.send-workload-notification': 'トレーニング負荷通知', // '운동부하 알림 보내기',
    'dashboard.button.injury-registration': '負傷登録', // '부상 등록',
    'dashboard.button.create-event': '日程追加', // '일정 추가',
    'dashboard.button.register-lineup': '出場選手登録', // '출전 선수 등록하기',

    'dashboard.data-etc.name': '名前', // '이름',
    'dashboard.data-etc.type': 'タイプ', // '유형',
    'dashboard.data-etc.part': '部位', // '부위',
    'dashboard.data-etc.level': '段階', // '단계',
    'dashboard.data-etc.lasting-days': '持続日', // '지속일',
    'dashboard.data-etc.description': '説明', // '설명',
    'dashboard.data-etc.position': 'ポジション', // '포지션',
    'dashboard.data-etc.criteria': '{count}個のアイテム', // '{count}개 항목',

    'dashboard.event.general': '一般', // '일반 정보',
    'dashboard.event.participant': '参加', // '참가여부',
    'dashboard.event.lineup': '出場', // '출전 선수',
    'dashboard.event.all': '全体', // '전체',
    'dashboard.event.training-attend': '参加', // '참가',
    'dashboard.event.training-absent': '不参', // '불참',
    'dashboard.event.unchecked': '未チェック', // '미체크',
    'dashboard.event.match-starting': '先発', // '선발',
    'dashboard.event.match-subs': '交代', // '교체',
    'dashboard-event.match-vs': 'vs', // 'vs',
    'dashboard-event.match-result': '試合結果', // '경기 결과',

    'dashboard.workout.normal': '正常運動', // '정상운동',
    'dashboard.workout.rehab': '復活運動', // '재활운동',
    'dashboard.workout.absence-injury': '負傷による不参加です', // '부상으로 인한 불참',
    'dashboard.workout.absence-other-reason': 'その他の事由による不参加', // '기타 사유로 인한 불참',

    'dashboard.soreness.description.level-1': '少し痛い', // '가벼운 통증',
    'dashboard.soreness.description.level-2': '痛い', // '중간 정도 통증',
    'dashboard.soreness.description.level-3': 'ひどく痛い', // '심각한 통증',
    'dashboard.soreness.description.level-4': '非常痛い', // '매우 심각한 통증',
    'dashboard.soreness.description.level-5': '耐え難い', // '참을 수 없는 통증',

    'dashboard.card-team-invite.title': 'チームに招待します', // '팀으로 초대하기',
    'dashboard.card-team-invite.subtitle': 'チームコードで\nメンバーを招待する', // '팀 코드로\n멤버를 초대하세요',

    'dashboard.filter.schedule.subtitle': '日程', // '일정'
    'dashboard.filter.intensity-check.subtitle': '運動負荷チェック', // '운동부하 체크'
  },
}
