import { ReactNode } from 'react'
import Linkify from 'react-linkify'

import Link from 'next/link'

import { palette } from '@plco-pro/themes/main'

interface Props {
  children: ReactNode
}

export const HyperLink = ({ children }: Props) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <Link href={decoratedHref} key={key}>
          <a
            style={{
              color: palette['indigo-500'],
              textDecoration: 'underline',
              textDecorationColor: palette['indigo-500'],
            }}
            href={decoratedHref}
            key={key}
            target="_blank"
            rel="noreferrer"
          >
            {decoratedText}
          </a>
        </Link>
      )}
    >
      {children}
    </Linkify>
  )
}
