import React, { PropsWithChildren, useMemo } from 'react'

import { ThemeProvider as ThemeUIThemeProvider } from 'theme-ui'

import theme, { breakpoints, breakpointsModalPage } from '@plco-pro/themes/main'

type Props = {
  isPageModal?: boolean
}
function ThemeProvider({ children, isPageModal = false }: PropsWithChildren<Props>) {
  const providerTheme = useMemo(() => {
    const themeBreakpoints = isPageModal ? breakpointsModalPage : breakpoints

    return {
      ...theme,
      breakpoints: themeBreakpoints,
    }
  }, [isPageModal])

  return <ThemeUIThemeProvider theme={providerTheme}>{children}</ThemeUIThemeProvider>
}

export default ThemeProvider
