import React, { useCallback, useMemo } from 'react'

import { Bar } from 'victory'

import { HoverUserData } from '@plco-pro/components/organisms/chart'
import { useMoment } from '@plco-pro/hooks/moment'
import { ChartParsedDataItem } from '@plco-pro/hooks/store-system'
import { Color } from '@plco-pro/maps/chart-system/chart-system.types'
import { CHART_TYPE } from '@plco-pro/maps/chart-system/props'
import { useStore } from '@plco-pro/stores'
import theme from '@plco-pro/themes/main'
import { getColor } from '@plco-pro/utils'

export type ChartCustomBarProps = {
  active?: boolean
  y?: number
  y0?: number
  style?: any
  noneDataColor?: Color
  colorHover?: Color
  noneDataColorHover?: Color
  drawPrevision?: boolean
  datum?: { [key in string]?: any }
  scale?: { x?: any; y?: any }
  data?: ChartParsedDataItem
  index: number
  type?: CHART_TYPE
  onClick?: (index: number) => void
  onHover?: ({ index, datum, scale }: HoverUserData) => void
  onClose?: () => void
}

export const ChartCustomBar: React.FunctionComponent<ChartCustomBarProps> = (props) => {
  const {
    active,
    y,
    y0,
    style,
    colorHover,
    drawPrevision,
    data,
    datum,
    scale,
    index,
    noneDataColor,
    noneDataColorHover,
    type,
    onClick = () => {},
    onHover = () => {},
    onClose = () => {},
  } = props

  const moment = useMoment()
  const { monitoring } = useStore()

  const openBottomSheet = useCallback(() => {
    onClick(index)
    monitoring.setBottomSheetVisible(true)
  }, [monitoring, index, onClick])

  const openTooltip = useCallback(() => {
    if (!datum || !scale) return

    onHover({
      index,
      datum,
      scale,
    })
  }, [index, datum, scale, onHover])

  const closeTooltip = useCallback(() => {
    onClose()
  }, [onClose])

  const getIsAfterData = useCallback(
    (datum) => {
      return moment(datum?.x, 'YYYY-MM-DDTHH:mm:ssZ').isAfter(moment())
    },
    [moment],
  )

  const isAxisYMinData = useMemo(() => y && y === y0, [y, y0])
  const hasDataY = useMemo(() => index && data?.[index].y !== 0, [data, index])
  const minDataHeight = useMemo(() => y && y - 3, [y])

  const previsionStyle = {
    ...style,
    fill: ({ datum }: any) =>
      getIsAfterData(datum) ? theme.colors['grey-300'] : getColor(noneDataColor) || style?.fill,
    opacity: noneDataColor ? 1 : 0.4,
  }

  const previsionHoverStyle = {
    ...style,
    fill: ({ datum }: any) =>
      getIsAfterData(datum) ? theme.colors['grey-300'] : getColor(noneDataColorHover || colorHover),
    opacity: ({ datum }: any) => (getIsAfterData(datum) && !noneDataColorHover ? 0.4 : 1),
  }

  const hoverStyle = {
    ...style,
    fill:
      !hasDataY && noneDataColorHover
        ? getColor(noneDataColorHover)
        : getColor(colorHover) || theme.colors['deep-purple-500'],
  }

  if (drawPrevision && isAxisYMinData && !hasDataY) {
    return (
      <Bar
        {...props}
        events={{
          onClick: type === 'WORKLOAD_BAR' ? openBottomSheet : null,
          onMouseEnter: type === 'WORKLOAD_BAR' ? openTooltip : null,
          onMouseLeave: type === 'WORKLOAD_BAR' ? closeTooltip : null,
        }}
        y={minDataHeight}
        y0={y}
        style={active ? previsionHoverStyle : previsionStyle}
      />
    )
  } else if (drawPrevision && isAxisYMinData) {
    return (
      <Bar
        {...props}
        events={{
          onClick: type === 'WORKLOAD_BAR' ? openBottomSheet : null,
          onMouseEnter: type === 'WORKLOAD_BAR' ? openTooltip : null,
          onMouseLeave: type === 'WORKLOAD_BAR' ? closeTooltip : null,
        }}
        y={minDataHeight}
        y0={y}
        style={active ? hoverStyle : style}
      />
    )
  } else {
    return (
      <Bar
        {...props}
        events={{
          onClick: type === 'WORKLOAD_BAR' ? openBottomSheet : null,
          onMouseEnter: type === 'WORKLOAD_BAR' ? openTooltip : null,
          onMouseLeave: type === 'WORKLOAD_BAR' ? closeTooltip : null,
        }}
        style={active ? hoverStyle : style}
      />
    )
  }
}
