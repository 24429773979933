import React, { useMemo } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text, TextProps } from '@plco-pro/components/atoms/text'
import { useResponsive } from '@plco-pro/hooks/responsive'

export type AvatarDefaultProps = BoxProps & {
  value: string
  size?: number | number[]
}

const ContainerBox = (props: BoxProps & { avatarSize: number | number[] }) => (
  <Box
    sx={{
      width: props.avatarSize,
      minWidth: props.avatarSize,
      height: props.avatarSize,
      minHeight: props.avatarSize,
      bg: 'grey-100',
      borderRadius: props.avatarSize,
    }}
    {...props}
  />
)

const AvatarText = (props: TextProps & { avatarSize: number }) => (
  <Text
    sx={{
      fontSize:
        props.avatarSize <= 24
          ? '0.75rem'
          : props.avatarSize <= 32
          ? '1rem'
          : props.avatarSize <= 64
          ? '1.25rem'
          : '1.5rem',
      fontWeight: 'bold',
      lineHeight: props.avatarSize <= 32 ? '1rem' : '1.25rem',
      color: 'grey-400',
      marginTop: props.avatarSize <= 32 ? '-2px' : '-2px',
    }}
    {...props}
  />
)

export const AvatarDefault: React.FunctionComponent<AvatarDefaultProps> = ({
  value,
  size = 32,
  ...rest
}) => {
  const text = useMemo(() => value?.[0] || '', [value])
  const getResponsiveProps = useResponsive()

  return (
    <ContainerBox avatarSize={size} {...rest}>
      <Flex
        sx={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AvatarText avatarSize={getResponsiveProps(size) as number}>{text}</AvatarText>
      </Flex>
    </ContainerBox>
  )
}
