import type { ChartSystemMapKey } from '@plco-pro/maps/chart-system/chart-system'

export enum MonitoringTab {
  CONDITION = 'CONDITION',
  WORKLOAD = 'WORKLOAD',
  ACWR = 'WORKLOAD_ACXR',
  BODY = 'BODY',
  SORENESS = 'SORENESS',
}

export enum MonitoringDataType {
  READINESS = 'readiness',
  CONDITION = 'condition',
  FATIGUE = 'fatigue',
  STRESS = 'stress',
  MUSCLE = 'muscle',
  MOOD = 'mood',
  SLEEP_DURATION = 'sleep_duration',
  SLEEP_QUALITY = 'sleep_quality',
  WORKLOAD = 'workload',
  WORKLOAD_DURATION = 'workload_duration',
  WORKLOAD_INTENSITY = 'workload_intensity',
  WORKLOAD_SATISFACTION = 'workload_satisfaction',
  WORKLOAD_ACXR = 'workload_acxr',
  WORKLOAD_AXD = 'workload_axd',
  WORKLOAD_AX = 'workload_ax',
  WORKLOAD_CX = 'workload_cx',
  WORKLOAD_AX_CX = 'workload_ax_cx',
  SORENESS_1 = 'soreness_1',
  SORENESS_2 = 'soreness_2',
  SORENESS_3 = 'soreness_3',
  SORENESS_4 = 'soreness_4',
  SORENESS_5 = 'soreness_5',
  HEIGHT = 'height',
  WEIGHT = 'weight',
}

/**
 * 1일 데이터만 표시하는 항목들
 */
export const SingleDataType: readonly MonitoringDataType[] = [
  MonitoringDataType.WORKLOAD_ACXR,
  MonitoringDataType.WORKLOAD_AXD,
  MonitoringDataType.WORKLOAD_AX,
  MonitoringDataType.WORKLOAD_CX,
  MonitoringDataType.WORKLOAD_AX_CX,
  MonitoringDataType.SORENESS_1,
  MonitoringDataType.SORENESS_2,
  MonitoringDataType.SORENESS_3,
  MonitoringDataType.SORENESS_4,
  MonitoringDataType.SORENESS_5,
  MonitoringDataType.HEIGHT,
  MonitoringDataType.WEIGHT,
]

export enum ChartMonitoringPeriod {
  DAY = '1_DAY',
  LATEST_7_DAYS = '7_DAYS',
  LATEST_28_DAYS = '28_DAYS',
}

export type ChartMonitoringConfigKeyByPeriod = Record<
  ChartMonitoringPeriod,
  ChartSystemMapKey | undefined
>

export type BadgeInputChartMonitoringMap = Map<MonitoringDataType, ChartMonitoringConfigKeyByPeriod>

const conditionMap: BadgeInputChartMonitoringMap = new Map([
  [
    MonitoringDataType.CONDITION,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:CONDITION:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:CONDITION:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:CONDITION:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.FATIGUE,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:FATIGUE:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:FATIGUE:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:FATIGUE:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.STRESS,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:STRESS:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:STRESS:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:STRESS:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.MUSCLE,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:MUSCLE:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:MUSCLE:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:MUSCLE:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.MOOD,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:MOOD:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:MOOD:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:MOOD:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.SLEEP_DURATION,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:SLEEP_DURATION:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:SLEEP_DURATION:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:SLEEP_DURATION:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.SLEEP_QUALITY,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:SLEEP_QUALITY:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:SLEEP_QUALITY:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:SLEEP_QUALITY:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
])

const workloadMap: BadgeInputChartMonitoringMap = new Map([
  [
    MonitoringDataType.WORKLOAD,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:WORKLOAD:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:WORKLOAD:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:WORKLOAD:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.WORKLOAD_INTENSITY,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:WORKLOAD_INTENSITY:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:WORKLOAD_INTENSITY:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:WORKLOAD_INTENSITY:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.WORKLOAD_DURATION,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:WORKLOAD_DURATION:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:WORKLOAD_DURATION:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:WORKLOAD_DURATION:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
  [
    MonitoringDataType.WORKLOAD_SATISFACTION,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:WORKLOAD_SATISFACTION:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: 'TEAM:WORKLOAD_SATISFACTION:1_WEEKS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_28_DAYS]: 'TEAM:WORKLOAD_SATISFACTION:4_WEEKS:USER_AVATAR:BAR',
    },
  ],
])

const acwrMap: BadgeInputChartMonitoringMap = new Map([
  [
    MonitoringDataType.WORKLOAD_ACXR,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:WORKLOAD_ACXR:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
  [
    MonitoringDataType.WORKLOAD_AX_CX,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:WORKLOAD_AX_CX:1_DAYS:USER_AVATAR:BAR_BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
  [
    MonitoringDataType.WORKLOAD_AXD,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:WORKLOAD_AXD:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
])

const sorenessMap: BadgeInputChartMonitoringMap = new Map([
  [
    MonitoringDataType.SORENESS_1,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:INJURY_1:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
  [
    MonitoringDataType.SORENESS_2,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:INJURY_2:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
  [
    MonitoringDataType.SORENESS_3,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:INJURY_3:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
  [
    MonitoringDataType.SORENESS_4,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:INJURY_4:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
  [
    MonitoringDataType.SORENESS_5,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:INJURY_5:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
])

const bodyMap: BadgeInputChartMonitoringMap = new Map([
  [
    MonitoringDataType.HEIGHT,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:HEIGHT:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
  [
    MonitoringDataType.WEIGHT,
    {
      [ChartMonitoringPeriod.DAY]: 'TEAM:WEIGHT:1_DAYS:USER_AVATAR:BAR',
      [ChartMonitoringPeriod.LATEST_7_DAYS]: undefined,
      [ChartMonitoringPeriod.LATEST_28_DAYS]: undefined,
    },
  ],
])

export const chartMonitoringMap: Map<MonitoringTab, BadgeInputChartMonitoringMap> = new Map([
  [MonitoringTab.CONDITION, conditionMap],
  [MonitoringTab.WORKLOAD, workloadMap],
  [MonitoringTab.ACWR, acwrMap],
  [MonitoringTab.SORENESS, sorenessMap],
  [MonitoringTab.BODY, bodyMap],
])
