import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useRouter } from 'next/router'
import { ThemeUIStyleObject } from 'theme-ui'

import { Box } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { DateRangePicker } from '@plco-pro/components/date-range-picker'
import { Anchor } from '@plco-pro/components/molecules/anchor'
import { TabContentItemPlayersAcwr } from '@plco-pro/components/organisms/tab-content-item-players-acwr'
import { TabContentItemPlayersBody } from '@plco-pro/components/organisms/tab-content-item-players-body'
import { TabContentItemPlayersCondition } from '@plco-pro/components/organisms/tab-content-item-players-condition'
import { TabContentItemPlayersInjury } from '@plco-pro/components/organisms/tab-content-item-players-injury'
import { TabContentItemPlayersWorkload } from '@plco-pro/components/organisms/tab-content-item-players-workload'
import { LAYOUT_PLAYER_CONTENT_PADDING } from '@plco-pro/components/templates/layout-player-modal'
import { PlayerSummaryContainer } from '@plco-pro/containers/players/player-summary/player-summary-container'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMoment } from '@plco-pro/hooks/moment'
import { useResponsive } from '@plco-pro/hooks/responsive'
import { useViewer } from '@plco-pro/hooks/viewer/viewer'
import { useRouterContext } from '@plco-pro/providers/router'
import { observer, useStore } from '@plco-pro/stores'
import { PersonalDataTabId } from '@plco-pro/stores/players'
import { get30DaysAgo } from '@plco-pro/utils/date-utils'
import { AnalyticsEventType } from '@plco-pro/utils/libs'
import { getDataType } from '@plco-pro/utils/player-data-type'

const TAB_MENU_CARD_BORDER_RADIUS = 4

const MainFlex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
  <Flex ref={ref} sx={{ flex: 1, mx: 'auto', flexDirection: 'column' }} {...props} />
))

type PlayerPageProps = {
  playerId: string
  summaryUserRef?: (element: HTMLDivElement | null) => void
}

export const PlayerPage = observer(({ playerId, summaryUserRef }: PlayerPageProps) => {
  const router = useRouter()
  const moment = useMoment()
  const { formatMessage: f } = useI18n()
  const { trackEvent } = useAnalytics()
  const getResponsiveProps = useResponsive()
  const { hash, loading: routerLoading } = useRouterContext()
  const { currentActiveTeam } = useViewer()

  // store
  const { players } = useStore()
  const { tabId, setTabId, setPlayerId, setIsPlayerStarred, setDate, dateRange, setDateRange } =
    players

  const teamId = currentActiveTeam?.id

  // state
  const [tabMenuFixedViewportTop, setTabMenuFixedViewportTop] = useState(0)
  const [isTabMenuFixed, setIsTabMenuFixed] = useState(false)
  const [tabMenuDistance, setTabMenuDistance] = useState(0)

  const mainFlexRef = useRef<HTMLDivElement>(null)
  const tabMenuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    return () => {
      setDate(moment().toDate())
      setDateRange([get30DaysAgo(), moment().toDate()])
    }
  }, [moment, setDate, setDateRange])

  // check tabId (from hash #... )
  useEffect(() => {
    if (routerLoading) {
      return
    }

    if (hash === PersonalDataTabId.CONDITION) {
      setTabId(PersonalDataTabId.CONDITION)
      trackEvent(AnalyticsEventType.CONDITION_CLICKED, {})
    } else if (hash === PersonalDataTabId.WORKLOAD) {
      setTabId(PersonalDataTabId.WORKLOAD)
      trackEvent(AnalyticsEventType.WORKLOAD_CLICKED, {})
    } else if (hash === PersonalDataTabId.ACWR) {
      setTabId(PersonalDataTabId.ACWR)
      trackEvent(AnalyticsEventType.ACWR_CLICKED, {})
    } else if (hash === PersonalDataTabId.BODY) {
      setTabId(PersonalDataTabId.BODY)
      trackEvent(AnalyticsEventType.BODY_CLICKED, {})
    } else if (hash === PersonalDataTabId.SORENESS) {
      setTabId(PersonalDataTabId.SORENESS)
      trackEvent(AnalyticsEventType.INJURY_CLICKED, {})
    }
  }, [hash, router, routerLoading, setTabId, trackEvent])

  useEffect(() => {
    return () => {
      setPlayerId(null)
      setTabId(null)
      setIsPlayerStarred(null)
    }
  }, [setIsPlayerStarred, setPlayerId, setTabId])

  const onClickAnchor = useCallback(
    async (newTabId: PersonalDataTabId) => {
      const dataType = getDataType(newTabId)

      await router.push(
        {
          query: {
            ...router.query,
            dataType,
          },
          hash: newTabId,
        },
        undefined,
        {
          scroll: false,
        },
      )

      window.scrollTo({ top: tabMenuDistance - tabMenuFixedViewportTop, behavior: 'smooth' })
    },
    [router, tabMenuDistance, tabMenuFixedViewportTop],
  )

  const updateTabMenuDistance = () => {
    if (!tabMenuRef.current) {
      return
    }

    const tabMenuDistance = tabMenuRef.current.getBoundingClientRect().top + window.scrollY
    setTabMenuDistance(tabMenuDistance)
  }

  useEffect(() => {
    setPlayerId(playerId)
  }, [playerId, setPlayerId])

  // get absolute tab menu distance when first render
  useEffect(() => {
    updateTabMenuDistance()
  }, [])

  // update top (for sticky position) of tabMenu
  const updateTabMenuViewportTop = useCallback(
    (mainFlexNode: React.RefObject<HTMLDivElement>) =>
      requestAnimationFrame(() => {
        if (mainFlexNode && mainFlexNode.current) {
          const base = mainFlexNode.current.offsetTop
          const currentLayoutContentPadding = getResponsiveProps(LAYOUT_PLAYER_CONTENT_PADDING) || 0
          const isPlayerPage = router.pathname.includes('players')

          const top = isPlayerPage ? base - currentLayoutContentPadding * 8 : 0 // modal page header height

          setTabMenuFixedViewportTop(top)
        }
      }),
    [getResponsiveProps, router],
  )

  const onResize = useCallback(() => {
    updateTabMenuViewportTop(mainFlexRef)
  }, [updateTabMenuViewportTop])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])

  // change sx when tabMenu(sticky) becomes fix <-> normal
  const tabMenuContainerSx: ThemeUIStyleObject = useMemo(
    () =>
      isTabMenuFixed
        ? {
            mx: LAYOUT_PLAYER_CONTENT_PADDING.map((item) => -item),
            width: LAYOUT_PLAYER_CONTENT_PADDING.map((item) => `calc(100% + ${item * 8 * 2}px)`),
          }
        : {
            mx: 'unset',
          },
    [isTabMenuFixed],
  )

  const tabMenuCardSx: ThemeUIStyleObject = useMemo(
    () =>
      isTabMenuFixed
        ? {
            borderRadius: 0,
            borderStyle: 'none',
            borderBottomStyle: 'solid',
          }
        : {
            borderRadius: TAB_MENU_CARD_BORDER_RADIUS,
            borderStyle: 'solid',
          },
    [isTabMenuFixed],
  )

  const tabMenuFlexSx: ThemeUIStyleObject = useMemo(
    () =>
      isTabMenuFixed
        ? {
            mx: [0, null, null, 'auto'],
            px: [3, 7, 8, null],
          }
        : {
            px: [3, null, 4, null],
          },
    [isTabMenuFixed],
  )

  useEffect(() => {
    const updateIsTabMenuFixed = () =>
      requestAnimationFrame(() => {
        const newIsTabMenuFixed =
          tabMenuRef &&
          tabMenuRef.current &&
          tabMenuRef.current.getBoundingClientRect().top === tabMenuFixedViewportTop
        setIsTabMenuFixed(newIsTabMenuFixed || false)
      })

    updateIsTabMenuFixed()
    window.addEventListener('scroll', updateIsTabMenuFixed)

    return () => window.removeEventListener('scroll', updateIsTabMenuFixed)
  }, [tabMenuFixedViewportTop])

  const tabContentItem = useMemo(() => {
    if (tabId === PersonalDataTabId.CONDITION) {
      return <TabContentItemPlayersCondition />
    } else if (tabId === PersonalDataTabId.WORKLOAD) {
      return <TabContentItemPlayersWorkload />
    } else if (tabId === PersonalDataTabId.ACWR) {
      return <TabContentItemPlayersAcwr />
    } else if (tabId === PersonalDataTabId.SORENESS) {
      return <TabContentItemPlayersInjury />
    } else if (tabId === PersonalDataTabId.BODY) {
      return <TabContentItemPlayersBody />
    } else {
      return null
    }
  }, [tabId])

  return (
    <MainFlex ref={mainFlexRef}>
      <Box sx={{ width: '100%', mx: 'auto' }}>
        {teamId && playerId && (
          <PlayerSummaryContainer
            teamId={teamId}
            playerId={playerId}
            summaryUserRef={summaryUserRef}
          />
        )}
      </Box>

      <Box sx={{ width: '100%', my: [2, 4, null, null] }}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Box
            ref={tabMenuRef}
            sx={{
              position: 'sticky',
              top: tabMenuFixedViewportTop,
              zIndex: 100,
              ...tabMenuContainerSx,
            }}
          >
            <Card
              sx={{
                position: 'relative',
                ...tabMenuCardSx,
              }}
            >
              <Flex sx={{ overflowX: 'auto', ...tabMenuFlexSx }} invisibleScroll={true}>
                {Object.entries(PersonalDataTabId).map(([key, PersonalDataTab]) => (
                  <Box key={key} sx={{ pt: '4px', flexShrink: 0 }}>
                    <Anchor
                      name={f({ id: `players.tab-menu-item.${PersonalDataTab}.title` })}
                      onClick={() => onClickAnchor(PersonalDataTab)}
                      isActive={tabId === PersonalDataTab}
                    />
                  </Box>
                ))}
              </Flex>

              <Box
                sx={{
                  width: '48px',
                  height: '100%',
                  pointerEvents: 'none',
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  borderTopRightRadius: TAB_MENU_CARD_BORDER_RADIUS,
                  borderBottomRightRadius: TAB_MENU_CARD_BORDER_RADIUS,
                  backgroundImage: 'linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0))',
                }}
              />
            </Card>
          </Box>

          <Flex sx={{ mt: [2, 2, 3, 3], justifyContent: ['flex-start', 'flex-start', 'flex-end'] }}>
            <DateRangePicker
              value={dateRange as [Date, Date]}
              onChange={(value) => {
                const [_, endDate] = value
                setDate(endDate)
                setDateRange(value)
              }}
              onOpen={() => {
                const element = document.getElementById('modal-page-content-box')

                if (element) {
                  element.style.overflowY = 'hidden'
                }
              }}
              onClose={() => {
                const element = document.getElementById('modal-page-content-box')

                if (element) {
                  element.style.overflowY = 'auto'
                }
              }}
            />
          </Flex>
          <Flex sx={{ width: '100%', mx: 'auto', mt: [2, 3, null, null] }}>
            <Box sx={{ width: '100%' }}>{tabContentItem}</Box>
          </Flex>
        </Flex>
      </Box>
    </MainFlex>
  )
})
