export const downloadFile = async (url: string, fileName: string) => {
  const file = await fetch(url)
  const blob = await file.blob()

  const fileDownloadUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')

  link.style.display = 'none'
  link.download = fileName
  link.href = fileDownloadUrl

  document.body.appendChild(link)
  link.click()

  window.URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
}
