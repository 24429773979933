export const players = {
  en: {
    'players.header.title.no-player': 'Wait a minute! \n There are no players...',
    'players.header.subtitle.no-player':
      'Invite players to the team and monitor their personal data!',
    'players.header.button.no-player': 'Invite members',

    'players.readiness.previous-day': 'Previous day',
    'players.readiness.previous-day.no-change': 'No change',

    // SUMMARY
    'players.card.player-info.title': 'Player info',
    'players.card.player-info.birthdate': 'Date of birth',
    'players.card.player-info.height': 'Height',
    'players.card.player-info.weight': 'Weight',
    'players.card.player-info.none': 'None',

    'players.card.readiness.title': 'Readiness',
    'players.card.readiness.past-7-days': 'past 7days',

    'players.card.soreness.title': 'Soreness',
    'players.card.soreness.footer': 'Touch on the area! You can see records in detail',

    'players.card.injury.title': 'Injury',
    'players.card.current-injury.tab': 'Current injury',
    'players.card.past-injury.tab': 'Past injury',
    'players.card.current-injury-empty.text': 'Not injured',
    'players.card.past-injury-empty.text': "There's no history",
    'players.card.past-injury-empty.subtext':
      "If you turn on 'Injury ended' when registering/modifying,\n it will be saved as a past injury",
    'players.card.injury-register.button': 'Register injury',
    'players.card.injury-list.return.label': 'Return',
    'players.card.injury-list.return-unknown.label': 'Undecided',

    'players.card.injury-register.help':
      'Injury is data managed by the coaches and is not visible to the players.',

    // PERSONAL DATA
    'players.tab-menu-item.CONDITION.title': 'Condition',
    'players.tab-menu-item.WORKLOAD.title': 'Workload',
    'players.tab-menu-item.WORKLOAD_ACXR.title': 'Risk of injury',
    'players.tab-menu-item.SORENESS.title': 'Soreness',
    'players.tab-menu-item.BODY.title': 'Body',

    'players.condition.chart-card.condition-by-items.title': 'Condition by items',
    'players.condition.chart-card.condition.title': 'Condition',
    'players.condition.chart-card.fatigue.title': 'Fatigue',
    'players.condition.chart-card.stress.title': 'Stress',
    'players.condition.chart-card.muscle.title': 'Muscle',
    'players.condition.chart-card.mood.title': 'Mood',
    'players.condition.chart-card.sleep.title': 'Sleep',

    'players.workload.chart-card.workload.title': 'Workload',
    'players.workload.chart-card.intensity.title': 'Intensity (RPE)',
    'players.workload.chart-card.duration.title': 'Duration',
    'players.workload.chart-card.satisfaction.title': 'Satisfaction',

    'players.acwr.chart-card.acwr.title': 'Risk of injury (ACWR)',
    'players.injury.chart-card.soreness-injury.title': 'Soreness',
    'players.body.chart-card.weight.title': 'Weight',
    'players.body.chart-card.height.title': 'Height',

    'players.injury.tooltip.button': 'View details',
  },
  ko: {
    'players.header.title.no-player': '잠시만요! \n 선수가 한명도 없어요...',
    'players.header.subtitle.no-player':
      '선수를 팀에 초대하고, 선수 개인 데이터를 모니터링 해보세요!',
    'players.header.button.no-player': '멤버 초대',

    'players.readiness.previous-day': '전일 대비',
    'players.readiness.previous-day.no-change': '변화없음',

    // SUMMARY
    'players.card.player-info.title': '선수 정보',
    'players.card.player-info.birthdate': '생년월일',
    'players.card.player-info.height': '키',
    'players.card.player-info.weight': '체중',
    'players.card.player-info.none': '입력 안함',

    'players.card.readiness.title': '준비도',
    'players.card.readiness.past-7-days': '지난 7일',

    'players.card.soreness.title': '통증',
    'players.card.soreness.footer': '부위를 눌러보세요! 추이와 기록을 자세히 볼 수 있어요',

    'players.card.injury.title': '부상',
    'players.card.current-injury.tab': '현재 부상',
    'players.card.past-injury.tab': '과거 부상',
    'players.card.current-injury-empty.text': '부상이 아닙니다',
    'players.card.past-injury-empty.text': '과거 기록이 없습니다',
    'players.card.past-injury-empty.subtext':
      "등록/수정 시 ‘부상 종료'를 켜면,\n과거 부상으로 저장됩니다",
    'players.card.injury-register.button': '부상 등록',
    'players.card.injury-list.return.label': '복귀',
    'players.card.injury-list.return-unknown.label': '복귀일 미정',
    'players.card.injury-register.help':
      '부상은 지도자가 관리하는 데이터로\n선수에게는 표시되지 않습니다.',

    // PERSONAL DATA
    'players.tab-menu-item.CONDITION.title': '컨디션',
    'players.tab-menu-item.WORKLOAD.title': '운동부하',
    'players.tab-menu-item.WORKLOAD_ACXR.title': '부상 위험도',
    'players.tab-menu-item.SORENESS.title': '통증',
    'players.tab-menu-item.BODY.title': '신체',

    'players.condition.chart-card.condition-by-items.title': '항목별 컨디션',
    'players.condition.chart-card.condition.title': '컨디션',
    'players.condition.chart-card.fatigue.title': '피로도',
    'players.condition.chart-card.stress.title': '스트레스',
    'players.condition.chart-card.muscle.title': '근육',
    'players.condition.chart-card.mood.title': '기분',
    'players.condition.chart-card.sleep.title': '수면',

    'players.workload.chart-card.workload.title': '운동부하',
    'players.workload.chart-card.intensity.title': '운동강도 (RPE)',
    'players.workload.chart-card.duration.title': '운동시간',
    'players.workload.chart-card.satisfaction.title': '운동만족',

    'players.acwr.chart-card.acwr.title': '부상 위험도 (ACWR)',
    'players.injury.chart-card.soreness-injury.title': '통증',
    'players.body.chart-card.weight.title': '체중',
    'players.body.chart-card.height.title': '키',

    'players.injury.tooltip.button': '자세히보기',
  },
  ja: {
    'players.header.title.no-player': 'ちょっと待ってください！\n選手が一人もいません...', // "잠시만요! \n 선수가 한명도 없어요...",
    'players.header.subtitle.no-player':
      '選手をチームに招待して、選手の個人データをモニタリングしてみてください！', // "선수를 팀에 초대하고, 선수 개인 데이터를 모니터링 해보세요!",
    'players.header.button.no-player': 'メンバー招待', // "멤버 초대",

    'players.readiness.previous-day': '前日比', // "전일 대비",
    'players.readiness.previous-day.no-change': '変化なし', // "변화없음",

    // SUMMARY
    'players.card.player-info.title': '選手情報', // "선수 정보",
    'players.card.player-info.birthdate': '生年月日.', // "생년월일",
    'players.card.player-info.height': '身長', // "키",
    'players.card.player-info.weight': '体重', // "체중"
    'players.card.player-info.none': '入力なし', // "입력 안함",

    'players.card.readiness.title': '準備度', // "준비도",
    'players.card.readiness.past-7-days': '以前7日', // "지난 7일",

    'players.card.soreness.title': '痛み', // "통증",
    'players.card.soreness.footer': 'エリアをタッチ！記録を詳しく見ることができます', // 부위를 눌러보세요! 추이와 기록을 자세히 볼 수 있어요

    'players.card.injury.title': '負傷', // '부상',
    'players.card.current-injury.tab': '現在の負傷', // '현재 부상',
    'players.card.past-injury.tab': '過去の負傷', // '과거 부상',
    'players.card.current-injury-empty.text': 'けがではありません', // '부상이 아닙니다',
    'players.card.past-injury-empty.text': '過去の記録がありません', // '과거 기록이 없습니다',
    'players.card.past-injury-empty.subtext':
      '登録/修正時に「負傷終了」をオンにすると、\n過去の負傷として保存されます', // "등록/수정 시 ‘부상 종료'를 켜면,\n과거 부상으로 저장됩니다",
    'players.card.injury-register.button': '負傷登録', // '부상 등록',
    'players.card.injury-list.return.label': '復帰', // '복귀',
    'players.card.injury-list.return-unknown.label': '復帰日未定', // '복귀일 미정',

    'players.card.injury-register.help':
      '負傷は指導者が管理するデータであり、選手には表示されません。', // '부상은 지도자가 관리하는 데이터로\n선수에게는 표시되지 않습니다.',

    // PERSONAL DATA
    'players.tab-menu-item.CONDITION.title': 'コンディション',
    'players.tab-menu-item.WORKLOAD.title': '運動負荷', // "운동부하",
    'players.tab-menu-item.WORKLOAD_ACXR.title': '負傷の危険度', // "부상 위험도",
    'players.tab-menu-item.SORENESS.title': '痛み', // "통증",
    'players.tab-menu-item.BODY.title': '身体', // "신체",

    'players.condition.chart-card.condition-by-items.title': '項目別コンディション', // "항목별 컨디션",
    'players.condition.chart-card.condition.title': 'コンディション', // "컨디션",
    'players.condition.chart-card.fatigue.title': '疲労度', // "피로도",
    'players.condition.chart-card.stress.title': 'ストレス', // "스트레스",
    'players.condition.chart-card.muscle.title': '筋肉', // "근육",
    'players.condition.chart-card.mood.title': '気分', // "기분",
    'players.condition.chart-card.sleep.title': '睡眠', // "수면",

    'players.workload.chart-card.workload.title': '運動負荷', // "운동부하",
    'players.workload.chart-card.intensity.title': '運動強度 (RPE)', // "운동강도 (RPE)",
    'players.workload.chart-card.duration.title': '運動時間', // "운동시간",
    'players.workload.chart-card.satisfaction.title': '運動満足', // "운동만족",

    'players.acwr.chart-card.acwr.title': '負傷危険度 (ACWR)', // "부상 위험도 (ACWR)",
    'players.injury.chart-card.soreness-injury.title': '痛み', // "통증",
    'players.body.chart-card.weight.title': '体重', // "체중",
    'players.body.chart-card.height.title': '身長', // "키",

    'players.injury.tooltip.button': '詳細を見る',
  },
}
