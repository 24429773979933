import React from 'react'

import { VictoryTooltipProps } from 'victory'

import theme from '@plco-pro/themes/main'

export type ChartLabelTooltipBackgroundLineProps = VictoryTooltipProps & {
  scale?: { x?: any; y?: any }
}

export const ChartLabelTooltipBackgroundLine: React.FunctionComponent<
  ChartLabelTooltipBackgroundLineProps
> = (props) => {
  const range = props?.scale?.y.range()
  const realX = props?.x
  const strokeDasharray = '3, 3'

  return (
    <line
      style={{
        stroke: theme.colors['grey-300'],
        strokeWidth: 1,
        strokeDasharray,
      }}
      x1={realX}
      x2={realX}
      y1={Math.max(...range)}
      y2={Math.min(...range)}
    />
  )
}
