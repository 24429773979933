import _get from 'lodash/get'

import { dataInterpretationMap } from './data-interpreter-mapping'
import { DataInterpretationItem } from './data-interpreter.types'

class DataInterpreter {
  private static map = dataInterpretationMap

  public readonly get = (
    path: string,
    value: any,
  ): {
    value: null | number
    name: null | string
    interpretation: null | Readonly<DataInterpretationItem>
  } => {
    // find property accessor in deep data (ex: RAW_WELLNESS.muscle.avg => muscle.avg)
    const [first, ...rest] = path.split('.')
    const accessors = rest.join('.')

    // find value by accessors
    let number: number | null = value
    if (accessors) {
      number = _get(value, accessors) as number
      if (typeof number === 'undefined') {
        number = null
      }
    }

    // find interpretation
    const interpretation = DataInterpreter.map.get(path)
    if (number === null || isNaN(number) || !interpretation) {
      if (typeof value === 'object') {
        return {
          value: null,
          name: interpretation?.name || null,
          interpretation: null,
        }
      }
      return {
        value,
        name: interpretation?.name || null,
        interpretation: null,
      }
    }

    // find matching range
    const { name, ranges } = interpretation
    const rangeKey = Object.keys(ranges).find((key: string) => {
      const regex = /([[(])([0-9.]+|-Infinity),([0-9.]+|Infinity)([\])])/
      const match = key.match(regex)
      if (match === null) {
        console.warn('error while parsing range: malformed range: ', { name, key })
        return false
      }

      // parse range
      const [fullMatch, minInclusiveChar, minChar, maxChar, maxInclusiveChar] = match
      const isMinInclusive = minInclusiveChar === '['
      const isMaxInclusive = maxInclusiveChar === ']'
      const min = minChar === '-Infinity' ? -Infinity : Number(minChar)
      const max = maxChar === 'Infinity' ? Infinity : Number(maxChar)

      // check for value fit in range
      if (isMinInclusive && isMaxInclusive) {
        // ex: "[0,10]"
        return number! >= min && number! <= max
      } else if (isMinInclusive && !isMaxInclusive) {
        // ex: "[0,10)"
        return number! >= min && number! < max
      } else if (!isMinInclusive && isMaxInclusive) {
        // ex: "(0,10]"
        return number! > min && number! <= max
      } else if (!isMinInclusive && !isMaxInclusive) {
        // ex: "(0,10)"
        return number! > min && number! < max
      }

      return false
    })

    return {
      value: number,
      name,
      interpretation: (rangeKey && ranges[rangeKey]) || null,
    }
  }
}

export const dataInterpreter = new DataInterpreter()
