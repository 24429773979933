import { types } from 'mobx-state-tree'

import {
  NavigationTeamLicenseFragment,
  NavigationTeamSubscriptionFragment,
  SubscriptionStatusEnum,
} from '@plco-pro/graphqls/react.generated'

export const NavigationStore = types
  .model('Navigation', {
    // common
    initialized: types.optional(types.boolean, false),
    // team
    activeTeamId: types.optional(types.maybeNull(types.string), null),
    // totals
    totalTeamCount: types.optional(types.maybeNull(types.number), null),
    // subscription
    teamSubscriptionId: types.optional(types.maybeNull(types.string), null),
    teamSubscriptionIsFreeTrial: types.optional(types.maybeNull(types.boolean), null),
    isSubscripting: types.optional(types.maybeNull(types.boolean), null),
    teamSubscriptionStatus: types.optional(
      types.maybeNull(types.enumeration(Object.values(SubscriptionStatusEnum))),
      null,
    ),
    teamSubscriptionMaxPlayerCount: types.optional(types.maybeNull(types.number), null),
    teamSubscriptionMaxTeamCount: types.optional(types.maybeNull(types.number), null),
    teamSubscriptionPlanId: types.optional(types.maybeNull(types.string), null),
    teamSubscriptionPlanOptionId: types.optional(types.maybeNull(types.string), null),
    teamSubscriptionEndAt: types.optional(types.maybeNull(types.string), null),
    // hyper
    isHyper: types.optional(types.boolean, false),
    // license
    teamLicenseId: types.optional(types.maybeNull(types.string), null),
    teamLicenseIsActivate: types.optional(types.maybeNull(types.boolean), null),
    // drawer
    isOpenDrawer: types.optional(types.boolean, false),
    isOpenDrawerSorting: types.optional(types.boolean, false),
    isOpenPlayersDrawer: types.optional(types.boolean, true),
    // analytics
    pageViewProperty: types.optional(types.frozen<Record<string, any>>(), {}),
  })
  .actions((self) => {
    return {
      // common
      setInitialized(value: boolean) {
        self.initialized = value
      },
      // team
      setActiveTeamId(value: string | null) {
        self.activeTeamId = value
      },
      // totals
      setTotalTeamCount(value: number | null) {
        self.totalTeamCount = value
      },
      // subscription
      setTeamSubscriptionId(value: string | null) {
        self.teamSubscriptionId = value
      },
      setTeamSubscriptionIsFreeTrial(value: boolean) {
        self.teamSubscriptionIsFreeTrial = value
      },
      setTeamSubscriptionStatus(value: SubscriptionStatusEnum) {
        self.teamSubscriptionStatus = value
      },
      setTeamSubscriptionMaxPlayerCount(value: number) {
        self.teamSubscriptionMaxPlayerCount = value
      },
      setTeamSubscriptionMaxTeamCount(value: number) {
        self.teamSubscriptionMaxTeamCount = value
      },
      setTeamSubscriptionPlanId(value: string) {
        self.teamSubscriptionPlanId = value
      },
      setTeamSubscriptionPlanOption(value: string) {
        self.teamSubscriptionPlanOptionId = value
      },
      changeSubscription(value?: NavigationTeamSubscriptionFragment | null) {
        self.teamSubscriptionId = value ? value.id : null
        self.teamSubscriptionIsFreeTrial = value ? value.isFreeTrial : null
        self.teamSubscriptionStatus = value ? value.status : null
        self.teamSubscriptionMaxPlayerCount = value
          ? value.plan.planOptionInfo.maxPlayerCount
          : null
        self.teamSubscriptionMaxTeamCount = value ? value.plan.planOptionInfo.maxTeamCount : null
        self.teamSubscriptionPlanId = value ? value.plan.id : null
        self.teamSubscriptionPlanOptionId = value ? value.plan.planOptionInfo.id : null
        self.teamSubscriptionEndAt = value ? value.endAt : null
        self.isSubscripting = value ? value.isSubscripting : null
      },
      // hyper
      changeHyperStatus(value: boolean) {
        self.isHyper = value
      },
      // license
      setTeamLicenseId(value: string | null) {
        self.teamLicenseId = value
      },
      setTeamLicenseIsActivate(value: boolean | null) {
        self.teamLicenseIsActivate = value
      },
      changeLicense(value?: NavigationTeamLicenseFragment | null) {
        self.teamLicenseId = value ? value.id : null
        self.teamLicenseIsActivate = value ? value.isActivate : null
      },
      // drawer,
      setIsOpenDrawer(value: boolean) {
        self.isOpenDrawer = value
      },
      toggleDrawer() {
        self.isOpenDrawer = !self.isOpenDrawer
      },
      setIsOpenDrawerSorting(value: boolean) {
        self.isOpenDrawerSorting = value
      },
      setIsOpenPlayersDrawer(value: boolean) {
        self.isOpenPlayersDrawer = value
      },
      togglePlayersDrawer() {
        self.isOpenPlayersDrawer = !self.isOpenPlayersDrawer
      },
      updateDrawerWithScreenSize(isLgAndDown: boolean) {
        if (isLgAndDown && self.isOpenPlayersDrawer) {
          self.isOpenDrawer = false
          return
        }

        if (!isLgAndDown) {
          self.isOpenPlayersDrawer = true
          return
        }
      },
      // analytics
      setPageViewProperty(value: Record<string, any>) {
        self.pageViewProperty = value
      },
      clearPageViewProperty() {
        self.pageViewProperty = {}
      },
    }
  })
