import React, { useCallback, useMemo } from 'react'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { IconButton } from '@plco-pro/components/molecules/icon-button'
import { useChartSystemHelper } from '@plco-pro/hooks/chart-system-helper'
import { useMoment } from '@plco-pro/hooks/moment'
import { useMomentUiFormat } from '@plco-pro/hooks/moment-ui-format'
import { QueryChartPaginationStore, QueryChartStore } from '@plco-pro/hooks/store-system'
import { ChartSystemMapKey } from '@plco-pro/maps/chart-system/chart-system'

export type ChartPeriodNavigationProps = {
  configKey: ChartSystemMapKey
  defaultPeriod?: string
  store?: QueryChartPaginationStore | QueryChartStore
}

export const ChartPeriodNavigation: React.FunctionComponent<ChartPeriodNavigationProps> = ({
  configKey,
  defaultPeriod,
  store,
}) => {
  const moment = useMoment()
  const { formatDateWithInput, formatDate } = useMomentUiFormat()
  const { getQueryVariables } = useChartSystemHelper()

  const { date, setDate, loading } = store || {}
  const period = defaultPeriod || getQueryVariables(configKey)?.period

  const getShiftAmount = useCallback((direction: 'LEFT' | 'RIGHT') => {
    return direction === 'LEFT' ? -1 : 1
  }, [])

  const getShiftUnitOfTime = useCallback((period) => {
    switch (period) {
      case 'DAY':
        return 'days'
      case 'WEEK':
        return 'weeks'
      case 'MONTH':
        return 'months'
      default:
        return 'days'
    }
  }, [])

  const getUnitOfTime = useCallback(
    (period) => {
      switch (period) {
        case 'WEEK':
          return 'isoWeek'
        default:
          return getShiftUnitOfTime(period)
      }
    },
    [getShiftUnitOfTime],
  )

  const getShiftedPeriod = useCallback(
    (date: Date, direction: 'LEFT' | 'RIGHT') => {
      return moment(date).clone().add(getShiftAmount(direction), getShiftUnitOfTime(period))
    },
    [getShiftUnitOfTime, getShiftAmount, moment, period],
  )

  const shiftPeriod = useCallback(
    (date: Date | undefined, direction: 'LEFT' | 'RIGHT') => {
      if (!date) {
        return
      }
      const newDate = getShiftedPeriod(date, direction).toDate()

      setDate?.(newDate)
    },
    [getShiftedPeriod, setDate],
  )

  const dateString = useMemo(() => {
    const unitOfTime = getUnitOfTime(period)
    const from = moment(date).startOf(unitOfTime).toDate()
    const to = moment(from).endOf(unitOfTime).toDate()

    if (moment(from).isSame(to, 'day')) {
      return formatDate(from)
    }

    return formatDateWithInput(from, to)
  }, [date, formatDate, formatDateWithInput, getUnitOfTime, moment, period])

  const isDisabledButtonLeft = useMemo(() => {
    return loading
  }, [loading])

  const isDisabledButtonRight = useMemo(() => {
    const today = moment()
    const unitOfTime = getShiftUnitOfTime(period)
    const from = moment(date).startOf(unitOfTime)
    const to = moment(from).endOf(unitOfTime)

    const isTodayInPeriod = today.isBetween(from, to)

    return loading || isTodayInPeriod
  }, [date, getShiftUnitOfTime, loading, moment, period])

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Box>
        <IconButton
          src={'/images/arrow-left.svg'}
          disabled={isDisabledButtonLeft}
          sx={{ color: 'grey-500' }}
          disabledColor={'grey-100'}
          size={'xs'}
          shape={'square'}
          onClick={() => shiftPeriod(date, 'LEFT')}
        />
      </Box>

      <Box sx={{ mx: 1 }}>
        <Text variant={'s2'} appearance={'hint'} sx={{ mt: '-2px' }}>
          {dateString}
        </Text>
      </Box>

      <Box>
        <IconButton
          src={'/images/arrow-right.svg'}
          disabled={isDisabledButtonRight}
          sx={{ color: 'grey-500' }}
          disabledColor={'grey-100'}
          size={'xs'}
          shape={'square'}
          onClick={() => shiftPeriod(date, 'RIGHT')}
        />
      </Box>
    </Flex>
  )
}
