import React from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Button, ButtonProps } from '@plco-pro/components/atoms/button'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { useI18n } from '@plco-pro/hooks/i18n'

export type DialogModalFooterProps = {
  leftButtonProps?: ButtonProps
  rightButtonProps?: ButtonProps
  children?: React.ReactNode
  isHyper?: boolean
}

const FooterBox = (props: BoxProps) => (
  <Box
    sx={{
      height: '88px',
      flex: '0 1 auto',
      px: 3,
      py: 3,
    }}
    {...props}
  />
)

const FooterFlex = (props: FlexProps) => (
  <Flex
    sx={{
      height: '100%',
      alignItems: 'center',
    }}
    {...props}
  />
)

const FooterButton = (props: ButtonProps) => (
  <Button
    sx={{
      width: ['100%', 'auto'],
      minWidth: '124px',
      px: [0, '12px'],
      height: '100%',
      fontWeight: 'bold',
    }}
    {...props}
  />
)

export const DialogModalFooter: React.FunctionComponent<DialogModalFooterProps> = ({
  leftButtonProps,
  rightButtonProps,
  children,
  isHyper,
}) => {
  const { formatMessage: f } = useI18n()

  if (children) {
    return (
      <FooterBox>
        <FooterFlex>{children}</FooterFlex>
      </FooterBox>
    )
  } else if (isHyper) {
    return (
      <FooterBox sx={{ py: 2, borderTop: '1px solid', borderColor: 'grey-100' }}>
        <FooterFlex sx={{ justifyContent: 'space-between' }}>
          <Button
            variant={'secondary'}
            color={'grey'}
            size={'sm'}
            sx={{ px: 2, py: 1, height: '40px' }}
            {...leftButtonProps}
          >
            {leftButtonProps?.title || f({ id: 'CANCEL' })}
          </Button>
          <Button
            variant={'primary'}
            disabled={rightButtonProps?.disabled}
            size={'sm'}
            sx={{ px: 2, py: 1, height: '40px' }}
            {...rightButtonProps}
          >
            {rightButtonProps?.title || f({ id: 'CONFIRM' })}
          </Button>
        </FooterFlex>
      </FooterBox>
    )
  } else if (leftButtonProps && rightButtonProps) {
    return (
      <FooterBox>
        <FooterFlex sx={{ justifyContent: ['space-between', 'flex-end'] }}>
          <FooterButton
            variant={leftButtonProps.variant || 'secondary'}
            color={leftButtonProps.color || 'grey'}
            {...leftButtonProps}
          >
            {leftButtonProps.title || f({ id: 'CANCEL' })}
          </FooterButton>
          <FooterButton
            variant={rightButtonProps.variant || 'primary'}
            disabled={rightButtonProps.disabled}
            sx={{ ml: [1, 2] }}
            {...rightButtonProps}
          >
            {rightButtonProps.title || f({ id: 'CONFIRM' })}
          </FooterButton>
        </FooterFlex>
      </FooterBox>
    )
  } else if (rightButtonProps && !leftButtonProps) {
    return (
      <FooterBox>
        <FooterFlex sx={{ justifyContent: 'center' }}>
          <FooterButton variant={rightButtonProps.variant || 'primary'} {...rightButtonProps}>
            {rightButtonProps.title || f({ id: 'CONFIRM' })}
          </FooterButton>
        </FooterFlex>
      </FooterBox>
    )
  } else if (!rightButtonProps && leftButtonProps) {
    return (
      <FooterBox>
        <FooterFlex sx={{ justifyContent: 'center' }}>
          <FooterButton variant={leftButtonProps.variant || 'primary'} {...leftButtonProps}>
            {leftButtonProps.title || f({ id: 'CANCEL' })}
          </FooterButton>
        </FooterFlex>
      </FooterBox>
    )
  } else {
    return null
  }
}
