import React, { useMemo } from 'react'
import { IntlConfig, IntlProvider, ReactIntlError } from 'react-intl'

import _debounce from 'lodash/debounce'
import { observer } from 'mobx-react'

import { useStore } from '@plco-pro/stores'

export type I18nMessagePacks = { [language: string]: IntlConfig['messages'] }

export const commonMessagePacks: I18nMessagePacks = {
  // inline require ref: https://reactnative.dev/docs/ram-bundles-inline-requires#inline-requires
  get en() {
    return require('@common/i18ns/i18n.en').default
  },
  get ko() {
    return require('@common/i18ns/i18n.ko').default
  },
  get ja() {
    return require('@common/i18ns/i18n.ja').default
  },
}

const logQueue: ReactIntlError[] = []

const logWarn = _debounce(() => {
  const groups: any = {}
  const errs = logQueue.splice(0, logQueue.length)
  for (const err of errs) {
    const group = groups[err.code] || (groups[err.code] = {})
    const key = (err as any).descriptor?.id || err.message
    if (!group[key]) {
      group[key] = err
    }
  }
  console.warn(groups)
}, 1000)

const onError = (err: ReactIntlError) => {
  logQueue.push(err)
  logWarn()
}

const I18nProvider: React.FunctionComponent<{
  messagePacks?: I18nMessagePacks
}> = observer(({ messagePacks = {}, children }) => {
  const store = useStore()

  const { language, timezone } = store.preference.locale

  const { messagePack } = useMemo(() => {
    const appMessagePack =
      messagePacks[language] || messagePacks[language.split('-')[0]] || messagePacks.en || {}
    const commonMessagePack =
      commonMessagePacks[language] ||
      commonMessagePacks[language.split('-')[0]] ||
      commonMessagePacks.en ||
      {}
    // eslint-disable-next-line no-shadow
    const messagePack = {
      ...commonMessagePack,
      ...appMessagePack,
    } as IntlConfig['messages']

    return { messagePack, language }
  }, [language, messagePacks])

  return (
    <IntlProvider
      onError={onError as any}
      locale={language}
      timeZone={timezone}
      messages={messagePack}
    >
      {children}
    </IntlProvider>
  )
})

export default I18nProvider
