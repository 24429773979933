import React, { ReactNode, useContext, useEffect, useMemo } from 'react'

import { observer, useObserver } from 'mobx-react'
import { IAnyModelType } from 'mobx-state-tree'

import { storage } from '@plco-pro/utils/libs/storage'

import { __CLIENT_DEBUG__ } from '../env'
import { RootStore, RootStoreEnv, RootStoreModel, RootStoreType } from './root'

export { observer, useObserver }
export type { RootStoreEnv, RootStoreModel, RootStoreType }

export const RootStoreContext = React.createContext<RootStoreType>({} as any)

export function useStore(): RootStoreType {
  return useContext(RootStoreContext)
}

export type StoreProviderProps = {
  apiClientHeaders?: { [key: string]: string }
  extend?: (store: RootStoreModel) => IAnyModelType
  storageKey?: string
  extraEnv?: { [key: string]: any }
  children?: ReactNode
}

export const StoreProvider = ({
  children,
  apiClientHeaders,
  extend,
  extraEnv = {},
}: StoreProviderProps) => {
  // create store
  const storeEnv: RootStoreEnv = {
    apiClientHeaders,
    storage,
  }

  const duplicateEnv = Object.keys(extraEnv).filter(
    (k) => typeof (storeEnv as any)[k] !== 'undefined',
  )

  if (duplicateEnv.length > 0) {
    throw new Error(`cannot extend store env with preserved keys: ${duplicateEnv}`)
  }

  const store: RootStoreType = useMemo(() => {
    const rootStore = (extend ? extend(RootStore) : RootStore).create(undefined, {
      ...extraEnv,
      ...storeEnv,
    })

    return rootStore
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (__CLIENT_DEBUG__ && typeof window !== 'undefined') {
      // unprotect (store)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.store = store
    }
  }, [store])

  return (
    <RootStoreContext.Provider value={store}>
      <StoreLoadingWrapper>{children}</StoreLoadingWrapper>
    </RootStoreContext.Provider>
  )
}

export const SharedStoreProvider: React.FunctionComponent<{
  instance: RootStoreType
}> = ({ children, instance }) => {
  return (
    <RootStoreContext.Provider value={instance}>
      <StoreLoadingWrapper>{children}</StoreLoadingWrapper>
    </RootStoreContext.Provider>
  )
}

const StoreLoadingWrapper = observer(({ children }: { children?: ReactNode }) => {
  const store = useStore()

  if (!store.initialized) {
    return null
  }

  return children as any
})

export default StoreProvider
