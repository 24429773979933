import React, { FunctionComponent, useCallback, useMemo } from 'react'
import ReactCalendar, { CalendarProps } from 'react-calendar'
import { ReactSVG } from 'react-svg'

import { moment } from '@common/utils/libs'
import styled from '@emotion/styled'

import { useI18n } from '@plco-pro/hooks/i18n'
import { useStore } from '@plco-pro/stores'

export type { CalendarProps }

export const MIN_DATE = new Date(2020, 0, 1)

const CalendarStyle = styled((props: CalendarProps) => (
  <ReactCalendar data-testid={'calendar'} {...props} />
))`
  width: 284px;
  color: ${(props) => props.theme.colors['text-primary']};
  background: white;
  font-size: 0.875rem;
  border: ${(props) => `1px solid ${props.theme.colors['grey-100']}`};
  border-radius: 4px;
  font-family: 'Noto Sans KR', sans-serif;
  line-height: 1.125em;
  padding: 0px 8px 0px;

  button {
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors['text-primary']};
    padding: 0px;
    margin: 0px;
    border-width: 0px;
    font-family: 'Noto Sans KR', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button:enabled:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors['bg-hover']};
  }
  button:enabled:active {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors['bg-pressed']};
  }
  button:disabled {
    color: ${(props) => props.theme.colors['text-tertiary']};
    background: none;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // navigation bar
  .react-calendar__navigation {
    display: flex;
    border-bottom-width: 1px;
    padding: 8px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) => props.theme.colors['grey-100']};
    justify-content: space-between;
    align-items: center;
  }
  .react-calendar__navigation button {
    border-radius: 4px;
    background: none;
  }
  .react-calendar__navigation__label {
    color: ${(props) => props.theme.colors['text-primary']};
    font-weight: 500;
    width: auto;
    padding: 4px 16px 4px;
    flex-grow: 0 !important;
  }
  .react-calendar__navigation__label:disabled {
    color: ${(props) => props.theme.colors['text-primary']};
  }
  button.react-calendar__navigation__arrow {
    padding: 12px 4px 12px;
    font-size: 0;
  }
  button.react-calendar__navigation__arrow {
    color: ${(props) => props.theme.colors['text-secondary']};
  }
  button.react-calendar__navigation__arrow:disabled {
    color: ${(props) => props.theme.colors['text-tertiary']};
  }

  // select (year, month, day)
  .react-calendar__tile {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    text-align: center;
    background: none;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: ${(props) => props.theme.colors['bg-hover']};
  }
  .react-calendar__tile:enabled:active {
    background-color: ${(props) => props.theme.colors['bg-pressed']};
  }
  .react-calendar__tile:disabled {
    color: ${(props) => props.theme.colors['text-tertiary']};
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: ${(props) => props.theme.colors['bg-hover']};
  }

  // select year, month
  .react-calendar__decade-view,
  .react-calendar__year-view {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .react-calendar__decade-view__years .react-calendar__tile,
  .react-calendar__year-view__months .react-calendar__tile {
    flex-basis: auto !important;
    width: calc((100% / 3) - 4px);
    padding: 11px 0px 13px;
    margin: 2px;
    border-radius: 4px;
    font-weight: 500;
  }
  .react-calendar__decade-view__years .react-calendar__tile--hasActive,
  .react-calendar__year-view__months .react-calendar__tile--hasActive {
    color: ${(props) => props.theme.colors['alternative']};
    background-color: ${(props) => props.theme.colors['primary-500']};
  }
  .react-calendar__decade-view__years .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__decade-view__years .react-calendar__tile--hasActive:enabled:focus,
  .react-calendar__year-view__months .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__year-view__months .react-calendar__tile--hasActive:enabled:focus {
    background-color: ${(props) => props.theme.colors['primary-600']};
  }
  .react-calendar__year-view__years .react-calendar__tile--hasActive:enabled:active,
  .react-calendar__year-view__months .react-calendar__tile--hasActive:enabled:active {
    background-color: ${(props) => props.theme.colors['primary-700']};
  }

  // select day
  .react-calendar__month-view {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .react-calendar__month-view > div > div {
    width: auto !important;
    flex-grow: 0 !important;
    flex-basis: auto !important;
  }
  // week
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.75rem;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: ${(props) => props.theme.colors['text-secondary']};
    font-weight: normal;
    padding: 0.5em;
  }
  // days
  .react-calendar__month-view__days__day {
    height: 34px;
    border-radius: 1000px;
  }
  .react-calendar__month-view__days__day:enabled:hover {
    background-color: ${(props) => props.theme.colors['bg-hover']};
  }
  .react-calendar__month-view__days__day:enabled:active {
    background-color: ${(props) => props.theme.colors['bg-pressed']};
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${(props) => props.theme.colors['text-secondary']};
  }
  .react-calendar__tile--active {
    color: ${(props) => props.theme.colors['alternative']} !important;
    background-color: ${(props) => props.theme.colors['primary-500']};
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${(props) => props.theme.colors['primary-600']} !important;
  }
  .react-calendar__tile--active:enabled:active {
    background: ${(props) => props.theme.colors['primary-700']} !important;
  }
`

export const Calendar: FunctionComponent<CalendarProps> = ({ locale: localeProp, ...props }) => {
  const { formatMessage: f } = useI18n()
  const store = useStore()

  const formatMonth = useCallback((locale: string, date: Date) => {
    return moment(date).format('MMM')
  }, [])

  const formatDay = useCallback((_, date) => {
    return moment(date).format('D')
  }, [])

  const locale = useMemo(() => {
    if (!localeProp) {
      const code = store.preference?.locale.code
      if (code === 'vn-KR') {
        return 'vi'
      }

      return code
    }

    return localeProp
  }, [localeProp, store.preference?.locale.code])

  return (
    <CalendarStyle
      minDate={MIN_DATE}
      maxDate={new Date()}
      locale={locale}
      minDetail={'decade'}
      next2Label={null}
      prev2Label={null}
      prevLabel={<ReactSVG src={'/images/calendar-label-right.svg'} data-testid={'prev-label'} />}
      nextLabel={<ReactSVG src={'/images/calendar-label-left.svg'} data-testid={'next-label'} />}
      formatMonth={formatMonth}
      formatDay={formatDay}
      navigationLabel={({ date, label, view }) => {
        const momentDate = moment(date)
        if (view === 'month') {
          return `${momentDate.format('YYYY.MM')}`
        } else if (view === 'year') {
          return momentDate.format('YYYY')
        } else if (view === 'decade') {
          return f({ id: 'calendar.navigationLabel.decade' })
        } else {
          return label
        }
      }}
      {...props}
    />
  )
}
