import { useCallback, useMemo } from 'react'

import { useAnalyticsContext } from '@plco-pro/providers/analytics'
import { useStore } from '@plco-pro/stores'
import Analytics from '@plco-pro/utils/libs/analytics'

import { useViewer } from './viewer/viewer'

export const useAnalytics = () => {
  const { navigation } = useStore()
  const { currentActiveTeam } = useViewer()

  const teamId = currentActiveTeam?.id
  const teamName = currentActiveTeam?.name
  const teamSlug = currentActiveTeam?.slug

  const { setSessionId, setIsIdentified } = useAnalyticsContext()

  const teamIdTeamName = useMemo(() => {
    return teamId && teamName ? `${teamId}_${teamName}` : undefined
  }, [teamId, teamName])

  const trackEvent: typeof Analytics.trackEvent = useCallback(
    (event, property, ...rest) => {
      if (navigation.initialized) {
        Analytics.trackEvent(
          event,
          {
            teamId_name: teamIdTeamName,
            teamslug_name: `${teamSlug}_${teamName}`,
            teamslug: teamSlug,
            ...property,
          },
          ...rest,
        )

        setSessionId(Analytics.getSessionId())
      }
    },
    [navigation.initialized, setSessionId, teamIdTeamName, teamName, teamSlug],
  )

  const pageTrack: typeof Analytics.pageTrack = useCallback(
    (event, property, ...rest) => {
      Analytics.pageTrack(
        event,
        {
          teamId_name: teamIdTeamName,
          teamslug_name: `${teamSlug}_${teamName}`,
          teamslug: teamSlug,
          ...property,
        },
        ...rest,
      )

      setSessionId(Analytics.getSessionId())
    },
    [setSessionId, teamIdTeamName],
  )

  const identifyUser: typeof Analytics.identifyUser = useCallback(
    (...rest) => {
      Analytics.identifyUser(...rest)

      setSessionId(Analytics.getSessionId())
      setIsIdentified(Analytics.getIsIdentified())
    },
    [setIsIdentified, setSessionId],
  )

  const restartSession: typeof Analytics.restartSession = useCallback(() => {
    Analytics.restartSession()

    setSessionId(Analytics.getSessionId())
    setIsIdentified(Analytics.getIsIdentified())
  }, [setIsIdentified, setSessionId])

  const result = useMemo(
    () => ({
      identifyUser,
      trackEvent,
      pageTrack,
      restartSession,
    }),
    [identifyUser, pageTrack, restartSession, trackEvent],
  )

  return result
}
