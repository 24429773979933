import React, { MouseEvent } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { Avatar } from '@plco-pro/components/molecules/avatar'

export type DropdownMenuAvatarItemProps = {
  src?: string | null
  value?: string | null
  title?: string
  subTitle?: string
  containerProps?: BoxProps
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
  children?: React.ReactNode
}

const ContainerBox = (props: BoxProps) => (
  <Box
    sx={
      props.onClick
        ? {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'bg-hover',
            },
            '&:active': {
              backgroundColor: 'bg-pressed',
            },
          }
        : {}
    }
    {...props}
  />
)

export const DropdownMenuAvatarItem: React.FunctionComponent<DropdownMenuAvatarItemProps> = ({
  src,
  value,
  title,
  subTitle,
  containerProps,
  onClick,
  children,
}) => {
  return (
    <ContainerBox onClick={onClick} {...containerProps}>
      <Flex
        sx={{
          height: '100%',
          alignItems: 'center',
          px: 2,
        }}
      >
        <Avatar size={48} src={src} value={value} sx={{ mr: 1 }} />
        <Flex sx={{ flexDirection: 'column' }}>
          {children ? (
            children
          ) : (
            <>
              <Text variant={'s2'} sx={{ fontWeight: 'bold' }} ellipsis>
                {title}
              </Text>
              <Text variant={'p2'} ellipsis>
                {' '}
                {subTitle}{' '}
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </ContainerBox>
  )
}
