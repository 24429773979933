import { QualificationType } from '@common/graphqls/react.generated'

import {
  SportsTeamAuthority,
  SubscriptionStatusEnum,
  useNavigationByPlanLazyQuery,
  useNavigationQualificationQuery,
} from '@plco-pro/graphqls/react.generated'
import { useStore } from '@plco-pro/stores'

import { useViewer } from './viewer/viewer'

export const useNavigation = () => {
  const { navigation } = useStore()
  const { teamAuthority } = useViewer()

  const activeTeamId = navigation.activeTeamId || ''

  const [navigationByPlanQuery, { data: planData, loading: planLoading }] =
    useNavigationByPlanLazyQuery({
      fetchPolicy: 'no-cache',
    })

  const subscriptionData = planData?.teamSubscriptionItem

  const licenseData = planData?.licenseByTeam

  const isActivateSubscription = subscriptionData?.status !== SubscriptionStatusEnum.INACTIVATE

  const isActivateLicense = !!licenseData?.isActivate

  const isActivate = isActivateSubscription || isActivateLicense

  const isFreeTrial = subscriptionData?.isFreeTrial

  const isHyper = subscriptionData?.plan.id === 'HYPER'

  const { loading: qualificationLoading } = useNavigationQualificationQuery({
    fetchPolicy: 'no-cache',
    skip: !activeTeamId,
    variables: {
      teamId: activeTeamId || '',
    },
    onCompleted: (data) => {
      if (data?.qualification.qualificationType === QualificationType.LICENSE) {
        navigationByPlanQuery({
          variables: { teamId: activeTeamId || '', includeLicense: true },
        })
      } else {
        navigationByPlanQuery({
          variables: { teamId: activeTeamId || '', includeSubscription: true },
        })
      }
    },
  })

  const isAdmin = teamAuthority === SportsTeamAuthority.ADMIN

  const isObserver = teamAuthority === SportsTeamAuthority.OBSERVER

  const isOwner = teamAuthority === SportsTeamAuthority.OWNER

  const isLoading = planLoading || qualificationLoading

  return {
    isLoading,
    isAdmin,
    isObserver,
    isOwner,
    subscriptionData,
    licenseData,
    isActivate,
    isFreeTrial,
    isHyper,
    isActivateSubscription,
    isActivateLicense,
  }
}
