import { useCallback } from 'react'

import { StoreSystemObjectType, StoreSystemTypeName } from '@plco-pro/hooks/store-system'
import { chartSystemMap, ChartSystemMapKey } from '@plco-pro/maps/chart-system/chart-system'
import { ChartSystemMapValue } from '@plco-pro/maps/chart-system/chart-system.types'
import { Y_FORMAT } from '@plco-pro/maps/chart-system/props'

export const useChartSystemHelper = () => {
  const getQueryVariables = useCallback((configKey: ChartSystemMapKey) => {
    return chartSystemMap[configKey]?.query?.variables
  }, [])

  const getDataIndex = useCallback((configKey?: ChartSystemMapKey) => {
    if (!configKey) {
      return
    }

    return chartSystemMap[configKey]?.domain.dataIndex
  }, [])

  const getDataEachYFormat = useCallback(
    (
      yFormat: Y_FORMAT,
      config: ChartSystemMapValue,
      store?: StoreSystemObjectType<StoreSystemTypeName>,
    ) => {
      return config.dataset.reduce((items, item, index) => {
        if (item.yFormat === yFormat) {
          items.push(
            ...(store?.parsedData[index]?.map((parsedData) => {
              return parsedData.y
            }) || []),
          )
        }

        return items
      }, [] as number[])
    },
    [],
  )

  const getBarWidth = useCallback((props: any) => {
    const { scale, data, defaultBarWidth } = props

    const range = scale.x.range()
    const extent = Math.abs(range[1] - range[0])
    const bars = data.length + 2
    const barRatio = props.barRatio || 0.5
    const defaultWidth = barRatio * (data.length < 2 ? defaultBarWidth : extent / bars)

    return Math.max(1, defaultWidth)
  }, [])

  return {
    getQueryVariables,
    getDataIndex,
    getDataEachYFormat,
    getBarWidth,
  }
}
