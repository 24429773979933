import React from 'react'

import { ThemeUIStyleObject } from 'theme-ui'

import { Box, Flex, Text } from '@plco-pro/components/atoms'

interface TabAnchorProps {
  name: string
  onClick: () => void
  isActive?: boolean
  activeColor?: string
  textStyle?: ThemeUIStyleObject
}

export const AnchorSmIcon = React.memo(
  ({ name, onClick, isActive, activeColor = 'primary', textStyle, ...rest }: TabAnchorProps) => {
    return (
      <Flex
        onClick={onClick}
        sx={{
          flexDirection: 'column',
          alignItems: 'stretch',
          flexShrink: '0',
          '&:hover': { cursor: 'pointer' },
        }}
        {...rest}
      >
        <Text
          sx={{
            mt: '12px',
            fontSize: '18px',
            fontWeight: isActive ? 700 : 400,
            p: 1,
            borderRadius: '4px',
            ...textStyle,
          }}
          appearance={isActive ? undefined : 'hint'}
        >
          {name}
        </Text>
        <Box
          sx={{
            mt: '4px',
            height: '4px',
            borderRadius: '10px 10px 0 0',
            bg: isActive ? activeColor : undefined,
          }}
        />
      </Flex>
    )
  },
)
