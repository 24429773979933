/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  DateTimeString: any;
  JSON: { [key: string]: any };
  StringOrFloat: any;
  Time: string;
  Upload: never;
};

export type AccessAuthorization = {
  __typename: 'AccessAuthorization';
  hasAccess: Scalars['Boolean'];
};

export type ActionList = {
  __typename: 'ActionList';
  clickTime: Scalars['Date'];
};

export type AddBannerLogInput = {
  bannerId: Scalars['ID'];
  timezone: Scalars['String'];
};

export type AddLicenseObserverList = {
  licenseId: Scalars['ID'];
  userIdList: Array<Scalars['ID']>;
};

export type AddLicenseTeamListInput = {
  licenseId: Scalars['ID'];
  teamIdList: Array<Scalars['ID']>;
};

export type Alarm = {
  createUser: SportsUser;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  player?: Maybe<SportsUser>;
  team?: Maybe<SportsTeam>;
  type: AlarmType;
};

export type AlarmConnection = {
  __typename: 'AlarmConnection';
  edges: Array<AlarmEdge>;
  pageInfo: PageInfo;
};

export type AlarmEdge = {
  __typename: 'AlarmEdge';
  cursor: Scalars['String'];
  node: Alarm;
};

export enum AlarmType {
  COMMENT_CREATED = 'COMMENT_CREATED',
  FEEDBACK_CREATED = 'FEEDBACK_CREATED',
  FEEDBACK_REQUESTED = 'FEEDBACK_REQUESTED',
  FEEDBACK_UPDATED = 'FEEDBACK_UPDATED',
  SCHEDULE_BULK_CREATED = 'SCHEDULE_BULK_CREATED',
  SCHEDULE_CREATED = 'SCHEDULE_CREATED',
  SCHEDULE_DELETE = 'SCHEDULE_DELETE',
  SCHEDULE_UPDATE = 'SCHEDULE_UPDATE'
}

export type AppendSorenessListInput = {
  sorenessList: Array<SorenessInput>;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type ApplyCouponInput = {
  couponId: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type AttendeeChartAvg = ChartCondition | ChartWorkload;

export type AttendeeChartAvgSchedule = {
  __typename: 'AttendeeChartAvgSchedule';
  duration: Scalars['Float'];
  intensity: Scalars['Float'];
  satisfaction: Scalars['Float'];
  workload: Scalars['Float'];
};

export type AttendeeChartData = {
  __typename: 'AttendeeChartData';
  avg: AttendeeChartAvg;
  userList: Array<AttendeeChartUser>;
};

export type AttendeeChartDataSchedule = {
  __typename: 'AttendeeChartDataSchedule';
  avg: AttendeeChartAvgSchedule;
  userList: Array<AttendeeChartUserSchedule>;
};

export enum AttendeeChartDataType {
  CONDITION = 'CONDITION',
  WORKLOAD = 'WORKLOAD'
}

export type AttendeeChartUser = {
  __typename: 'AttendeeChartUser';
  user: SportsUser;
  value: AttendeeChartValue;
};

export type AttendeeChartUserSchedule = {
  __typename: 'AttendeeChartUserSchedule';
  user: SportsUser;
  value: ChartUserWorkload;
};

export type AttendeeChartValue = ChartUserCondition | ChartUserWorkload;

export type AvgExcludeRehabAndGk = {
  __typename: 'AvgExcludeRehabAndGK';
  intensity: Scalars['Float'];
  load: Scalars['Float'];
};

export type Banner = {
  __typename: 'Banner';
  clickUserList: BannerLogResponse;
  createdAt: Scalars['Date'];
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  landingUrl?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  targetOptionList?: Maybe<Array<Maybe<TargetOption>>>;
  title: Scalars['String'];
};


export type BannerClickUserListArgs = {
  date: Scalars['Date'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type BannerLogResponse = {
  __typename: 'BannerLogResponse';
  clickUserList: Array<Maybe<ClickUser>>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export enum BannerOption {
  AGE = 'AGE',
  GENDER = 'GENDER',
  LANGUAGE = 'LANGUAGE',
  TEAM = 'TEAM'
}

export enum BannerOptionOperator {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  NE = 'NE'
}

export type BasicEvent = Event & {
  __typename: 'BasicEvent';
  endAt: Scalars['Date'];
  eventInvitees: Array<EventInvitee>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: ScheduleType;
};

export type BasicEventInvitee = EventInvitee & {
  __typename: 'BasicEventInvitee';
  event: Event;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  status?: Maybe<EventInviteeStatus>;
  /** the player who is invited to this event */
  user?: Maybe<SportsUser>;
  workoutType?: Maybe<WorkoutType>;
};

export type BasicSchedule = Schedule & {
  __typename: 'BasicSchedule';
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['Time']>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  fileList?: Maybe<Array<ScheduleFile>>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isPlayerNotice: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  scheduleDate: Scalars['String'];
  scheduleInviteeList: Array<ScheduleInvitee>;
  startTime?: Maybe<Scalars['Time']>;
  team?: Maybe<SportsTeam>;
  title?: Maybe<Scalars['String']>;
  trainingPoint?: Maybe<Scalars['String']>;
  type: ScheduleType;
};

export type BasicScheduleInvitee = ScheduleInvitee & {
  __typename: 'BasicScheduleInvitee';
  absenceReason?: Maybe<Scalars['String']>;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  schedule?: Maybe<Schedule>;
  status?: Maybe<ScheduleInviteeStatus>;
  team?: Maybe<SportsTeam>;
  /** the player who is invited to this schedule */
  user?: Maybe<SportsUser>;
  workoutType: WorkoutType;
};


export type BasicScheduleInviteeScheduleArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export type Billing = {
  __typename: 'Billing';
  buyerEmail?: Maybe<Scalars['String']>;
  buyerName?: Maybe<Scalars['String']>;
  buyerTel?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  customerUid: Scalars['String'];
  id: Scalars['ID'];
  identityToken: Scalars['String'];
};

export type BillingToken = {
  __typename: 'BillingToken';
  customerUid: Scalars['String'];
  paymentToken: Scalars['String'];
};

export type BodyData = {
  __typename: 'BodyData';
  BMI?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<HeightUnit>;
  id?: Maybe<Scalars['ID']>;
  recommendedHydration?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
};

export type Calendar = {
  __typename: 'Calendar';
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Date']>;
  teamId: Scalars['ID'];
  type?: Maybe<CalendarType>;
};

export enum CalendarType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

export type CancelSubscriptionInput = {
  subscriptionId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type ChangeCouponInput = {
  couponId: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type ChangeSubscriptionDateInput = {
  endAt: Scalars['DateTime'];
  startAt?: Maybe<Scalars['DateTime']>;
  subscriptionId: Scalars['ID'];
};

export type ChartAvgData = ChartBody | ChartCondition | ChartSoreness | ChartWorkload | ChartWorkloadAcxrAxd;

export type ChartBody = {
  __typename: 'ChartBody';
  height: Scalars['Float'];
  weight: Scalars['Float'];
};

export type ChartCondition = {
  __typename: 'ChartCondition';
  condition: Scalars['Float'];
  duration: Scalars['Float'];
  fatigue: Scalars['Float'];
  mood: Scalars['Float'];
  muscle: Scalars['Float'];
  quality: Scalars['Float'];
  stress: Scalars['Float'];
};

export type ChartData = {
  __typename: 'ChartData';
  avg: ChartAvgData;
  userList: Array<ChartUser>;
};

export enum ChartDataName {
  BODY = 'BODY',
  CONDITION = 'CONDITION',
  CONDITION_7DAY = 'CONDITION_7DAY',
  CONDITION_28DAY = 'CONDITION_28DAY',
  SORENESS = 'SORENESS',
  WORKLOAD = 'WORKLOAD',
  WORKLOAD_7DAY = 'WORKLOAD_7DAY',
  WORKLOAD_28DAY = 'WORKLOAD_28DAY',
  WORKLOAD_ACXR = 'WORKLOAD_ACXR'
}

export type ChartSoreness = {
  __typename: 'ChartSoreness';
  level1: SorenessLevelData;
  level2: SorenessLevelData;
  level3: SorenessLevelData;
  level4: SorenessLevelData;
  level5: SorenessLevelData;
};

export type ChartUser = {
  __typename: 'ChartUser';
  user: SportsUser;
  value: ChartUserData;
};

export type ChartUserCondition = {
  __typename: 'ChartUserCondition';
  condition?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fatigue?: Maybe<Scalars['Float']>;
  mood?: Maybe<Scalars['Float']>;
  muscle?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
  stress?: Maybe<Scalars['Float']>;
};

export type ChartUserData = ChartBody | ChartSoreness | ChartUserCondition | ChartUserWorkload | ChartWorkloadAcxrAxd;

export type ChartUserWorkload = {
  __typename: 'ChartUserWorkload';
  /** 당일 운동시간의 합 */
  duration?: Maybe<Scalars['Float']>;
  /** 첫번째 운동부하의 아이디 */
  id?: Maybe<Scalars['ID']>;
  /** 당일 운동강도의 평균 */
  intensity?: Maybe<Scalars['Float']>;
  reasonForNoWorkout?: Maybe<NoWorkoutType>;
  /** 당일 운동만족도의 평균 */
  satisfaction?: Maybe<Scalars['Float']>;
  /** 당일 운동부하의 합 */
  workload?: Maybe<Scalars['Float']>;
  workloadList: Array<Maybe<Workload>>;
};

export type ChartWorkload = {
  __typename: 'ChartWorkload';
  duration: Scalars['Float'];
  intensity: Scalars['Float'];
  satisfaction: Scalars['Float'];
  workload: Scalars['Float'];
};

export type ChartWorkloadAcxrAxd = {
  __typename: 'ChartWorkloadAcxrAxd';
  acxr: Scalars['Float'];
  ax: Scalars['Float'];
  /** 값이 Infinity 일 때 null 로 응답 */
  axd?: Maybe<Scalars['Float']>;
  cx: Scalars['Float'];
};

export type CheckIsGivenTeamsInput = {
  teamIdList: Array<Scalars['ID']>;
};

export type CheckReminderPushInput = {
  coachName: Scalars['String'];
  teamId: Scalars['String'];
  timezone: Scalars['String'];
  userIdList?: Maybe<Array<Scalars['ID']>>;
};

export type CheckScheduleInviteReminderPushInput = {
  coachName: Scalars['String'];
  scheduleId: Scalars['ID'];
  userIdList?: Maybe<Array<Scalars['ID']>>;
};

export type CheckedData = {
  __typename: 'CheckedData';
  condition: CheckedOrNotData;
  date: Scalars['String'];
  workload: CheckedOrNotData;
};

export type CheckedDetailData = {
  __typename: 'CheckedDetailData';
  playerList: Array<SportsUser>;
  total: Scalars['Float'];
};

export type CheckedOrNotData = {
  __typename: 'CheckedOrNotData';
  checked: CheckedDetailData;
  unChecked: CheckedDetailData;
};

export type ClickUser = {
  __typename: 'ClickUser';
  actionList: Array<ActionList>;
  user: SportsUser;
};

export type Condition = {
  __typename: 'Condition';
  ac: Scalars['Float'];
  cc: Scalars['Float'];
  fatigue: Scalars['Float'];
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  mood: Scalars['Float'];
  muscle: Scalars['Float'];
  readiness: Scalars['Float'];
  score: Scalars['Float'];
  sleepDuration: Scalars['Float'];
  sleepQuality: Scalars['Float'];
  sleepTime: Scalars['DateTimeString'];
  stress: Scalars['Float'];
};

export type ConditionCareUser = {
  __typename: 'ConditionCareUser';
  dataList: Array<UserConditionDataType>;
  type: UserConditionLabelType;
  user: SportsUser;
};

export type ConditionReportFatigueData = {
  __typename: 'ConditionReportFatigueData';
  fatigue: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportMoodData = {
  __typename: 'ConditionReportMoodData';
  mood: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportMuscleData = {
  __typename: 'ConditionReportMuscleData';
  muscle: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportSleepDurationData = {
  __typename: 'ConditionReportSleepDurationData';
  sleepDuration: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportSleepQualityData = {
  __typename: 'ConditionReportSleepQualityData';
  sleepQuality: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportStressData = {
  __typename: 'ConditionReportStressData';
  streak?: Maybe<Scalars['Float']>;
  stress: Scalars['Float'];
};

export type ConditionSolution = {
  __typename: 'ConditionSolution';
  guide: Scalars['String'];
  type: ConditionSolutionType;
};

export enum ConditionSolutionType {
  CB = 'CB',
  CC1 = 'CC1',
  CC2 = 'CC2',
  CD1 = 'CD1',
  CD2 = 'CD2',
  CE1 = 'CE1',
  CE2 = 'CE2',
  CE3 = 'CE3'
}

export type ConditionStreakData = ConditionReportFatigueData | ConditionReportMoodData | ConditionReportMuscleData | ConditionReportSleepDurationData | ConditionReportSleepQualityData | ConditionReportStressData;

export type ConditionTeamSolution = {
  __typename: 'ConditionTeamSolution';
  guide: Scalars['String'];
  type: ConditionTeamSolutionType;
};

export enum ConditionTeamSolutionType {
  CAA1 = 'CAA1',
  CAA2 = 'CAA2',
  CAB1 = 'CAB1',
  CAB2 = 'CAB2'
}

export type ConditionUser = {
  __typename: 'ConditionUser';
  conditionList: Array<ConditionStreakData>;
  user: SportsUser;
};

export enum Contact {
  CONTACT = 'CONTACT',
  NON_CONTACT = 'NON_CONTACT',
  NO_SORENESS = 'NO_SORENESS',
  UNKNOWN = 'UNKNOWN'
}

export type CoordinatingConditionReportData = {
  __typename: 'CoordinatingConditionReportData';
  newSorenessUserList: Array<SorenessUser>;
  recoverSorenessUserList: Array<SorenessUser>;
  severeConditionUserList: Array<ConditionUser>;
  severeSorenessUserList: Array<SorenessUser>;
  solution: CoordinatingConditionReportSolution;
};

export type CoordinatingConditionReportSolution = {
  __typename: 'CoordinatingConditionReportSolution';
  conditionTeamSolution?: Maybe<ConditionTeamSolution>;
  solutionUserList: Array<CoordinatingConditionReportSolutionUser>;
};

export type CoordinatingConditionReportSolutionUser = {
  __typename: 'CoordinatingConditionReportSolutionUser';
  conditionSolutionList: Array<ConditionSolution>;
  sorenessSolutionList: Array<SorenessSolution>;
  user: SportsUser;
};

export type CoordinatingMatchReportData = {
  __typename: 'CoordinatingMatchReportData';
  satisfactionUserIdList: Array<SatisfactionUserId>;
  schedule: MatchScheduleForCoordinatingReport;
  solution?: Maybe<MatchReportSolution>;
};

export type CoordinatingTrainingReportData = {
  __typename: 'CoordinatingTrainingReportData';
  avgExcludeRehabAndGK: AvgExcludeRehabAndGk;
  highDiffWithTeamIntensityAvgUserList: Array<IntensityUser>;
  isTeamIncludeGKPlayer: Scalars['Boolean'];
  lowSatisfactionUserList: Array<SatisfactionUser>;
  schedule: TrainingScheduleForCoordinatingReport;
  solution?: Maybe<TrainingReportSolution>;
};

export type Country = {
  __typename: 'Country';
  defaultLanguage?: Maybe<Scalars['String']>;
  defaultUTCOffset?: Maybe<Scalars['Int']>;
  id?: Maybe<CountryCode>;
};

export enum CountryCode {
  all_country_code__ad = 'all_country_code__ad',
  all_country_code__ae = 'all_country_code__ae',
  all_country_code__af = 'all_country_code__af',
  all_country_code__ag = 'all_country_code__ag',
  all_country_code__ai = 'all_country_code__ai',
  all_country_code__al = 'all_country_code__al',
  all_country_code__am = 'all_country_code__am',
  all_country_code__ao = 'all_country_code__ao',
  all_country_code__aq = 'all_country_code__aq',
  all_country_code__ar = 'all_country_code__ar',
  all_country_code__as = 'all_country_code__as',
  all_country_code__at = 'all_country_code__at',
  all_country_code__au = 'all_country_code__au',
  all_country_code__aw = 'all_country_code__aw',
  all_country_code__ax = 'all_country_code__ax',
  all_country_code__az = 'all_country_code__az',
  all_country_code__ba = 'all_country_code__ba',
  all_country_code__bb = 'all_country_code__bb',
  all_country_code__bd = 'all_country_code__bd',
  all_country_code__be = 'all_country_code__be',
  all_country_code__bf = 'all_country_code__bf',
  all_country_code__bg = 'all_country_code__bg',
  all_country_code__bh = 'all_country_code__bh',
  all_country_code__bi = 'all_country_code__bi',
  all_country_code__bj = 'all_country_code__bj',
  all_country_code__bl = 'all_country_code__bl',
  all_country_code__bm = 'all_country_code__bm',
  all_country_code__bn = 'all_country_code__bn',
  all_country_code__bo = 'all_country_code__bo',
  all_country_code__bq = 'all_country_code__bq',
  all_country_code__br = 'all_country_code__br',
  all_country_code__bs = 'all_country_code__bs',
  all_country_code__bt = 'all_country_code__bt',
  all_country_code__bv = 'all_country_code__bv',
  all_country_code__bw = 'all_country_code__bw',
  all_country_code__by = 'all_country_code__by',
  all_country_code__bz = 'all_country_code__bz',
  all_country_code__ca = 'all_country_code__ca',
  all_country_code__cc = 'all_country_code__cc',
  all_country_code__cd = 'all_country_code__cd',
  all_country_code__cf = 'all_country_code__cf',
  all_country_code__cg = 'all_country_code__cg',
  all_country_code__ch = 'all_country_code__ch',
  all_country_code__ci = 'all_country_code__ci',
  all_country_code__ck = 'all_country_code__ck',
  all_country_code__cl = 'all_country_code__cl',
  all_country_code__cm = 'all_country_code__cm',
  all_country_code__cn = 'all_country_code__cn',
  all_country_code__co = 'all_country_code__co',
  all_country_code__cr = 'all_country_code__cr',
  all_country_code__cu = 'all_country_code__cu',
  all_country_code__cv = 'all_country_code__cv',
  all_country_code__cw = 'all_country_code__cw',
  all_country_code__cx = 'all_country_code__cx',
  all_country_code__cy = 'all_country_code__cy',
  all_country_code__cz = 'all_country_code__cz',
  all_country_code__de = 'all_country_code__de',
  all_country_code__dj = 'all_country_code__dj',
  all_country_code__dk = 'all_country_code__dk',
  all_country_code__dm = 'all_country_code__dm',
  all_country_code__do = 'all_country_code__do',
  all_country_code__dz = 'all_country_code__dz',
  all_country_code__ec = 'all_country_code__ec',
  all_country_code__ee = 'all_country_code__ee',
  all_country_code__eg = 'all_country_code__eg',
  all_country_code__eh = 'all_country_code__eh',
  all_country_code__er = 'all_country_code__er',
  all_country_code__es = 'all_country_code__es',
  all_country_code__et = 'all_country_code__et',
  all_country_code__fi = 'all_country_code__fi',
  all_country_code__fj = 'all_country_code__fj',
  all_country_code__fk = 'all_country_code__fk',
  all_country_code__fm = 'all_country_code__fm',
  all_country_code__fo = 'all_country_code__fo',
  all_country_code__fr = 'all_country_code__fr',
  all_country_code__ga = 'all_country_code__ga',
  all_country_code__gb = 'all_country_code__gb',
  all_country_code__gd = 'all_country_code__gd',
  all_country_code__ge = 'all_country_code__ge',
  all_country_code__gf = 'all_country_code__gf',
  all_country_code__gg = 'all_country_code__gg',
  all_country_code__gh = 'all_country_code__gh',
  all_country_code__gi = 'all_country_code__gi',
  all_country_code__gl = 'all_country_code__gl',
  all_country_code__gm = 'all_country_code__gm',
  all_country_code__gn = 'all_country_code__gn',
  all_country_code__gp = 'all_country_code__gp',
  all_country_code__gq = 'all_country_code__gq',
  all_country_code__gr = 'all_country_code__gr',
  all_country_code__gs = 'all_country_code__gs',
  all_country_code__gt = 'all_country_code__gt',
  all_country_code__gu = 'all_country_code__gu',
  all_country_code__gw = 'all_country_code__gw',
  all_country_code__gy = 'all_country_code__gy',
  all_country_code__hk = 'all_country_code__hk',
  all_country_code__hm = 'all_country_code__hm',
  all_country_code__hn = 'all_country_code__hn',
  all_country_code__hr = 'all_country_code__hr',
  all_country_code__ht = 'all_country_code__ht',
  all_country_code__hu = 'all_country_code__hu',
  all_country_code__id = 'all_country_code__id',
  all_country_code__ie = 'all_country_code__ie',
  all_country_code__il = 'all_country_code__il',
  all_country_code__im = 'all_country_code__im',
  all_country_code__in = 'all_country_code__in',
  all_country_code__io = 'all_country_code__io',
  all_country_code__iq = 'all_country_code__iq',
  all_country_code__ir = 'all_country_code__ir',
  all_country_code__is = 'all_country_code__is',
  all_country_code__it = 'all_country_code__it',
  all_country_code__je = 'all_country_code__je',
  all_country_code__jm = 'all_country_code__jm',
  all_country_code__jo = 'all_country_code__jo',
  all_country_code__jp = 'all_country_code__jp',
  all_country_code__ke = 'all_country_code__ke',
  all_country_code__kg = 'all_country_code__kg',
  all_country_code__kh = 'all_country_code__kh',
  all_country_code__ki = 'all_country_code__ki',
  all_country_code__km = 'all_country_code__km',
  all_country_code__kn = 'all_country_code__kn',
  all_country_code__kp = 'all_country_code__kp',
  all_country_code__kr = 'all_country_code__kr',
  all_country_code__kw = 'all_country_code__kw',
  all_country_code__ky = 'all_country_code__ky',
  all_country_code__kz = 'all_country_code__kz',
  all_country_code__la = 'all_country_code__la',
  all_country_code__lb = 'all_country_code__lb',
  all_country_code__lc = 'all_country_code__lc',
  all_country_code__li = 'all_country_code__li',
  all_country_code__lk = 'all_country_code__lk',
  all_country_code__lr = 'all_country_code__lr',
  all_country_code__ls = 'all_country_code__ls',
  all_country_code__lt = 'all_country_code__lt',
  all_country_code__lu = 'all_country_code__lu',
  all_country_code__lv = 'all_country_code__lv',
  all_country_code__ly = 'all_country_code__ly',
  all_country_code__ma = 'all_country_code__ma',
  all_country_code__mc = 'all_country_code__mc',
  all_country_code__md = 'all_country_code__md',
  all_country_code__me = 'all_country_code__me',
  all_country_code__mf = 'all_country_code__mf',
  all_country_code__mg = 'all_country_code__mg',
  all_country_code__mh = 'all_country_code__mh',
  all_country_code__mk = 'all_country_code__mk',
  all_country_code__ml = 'all_country_code__ml',
  all_country_code__mm = 'all_country_code__mm',
  all_country_code__mn = 'all_country_code__mn',
  all_country_code__mo = 'all_country_code__mo',
  all_country_code__mp = 'all_country_code__mp',
  all_country_code__mq = 'all_country_code__mq',
  all_country_code__mr = 'all_country_code__mr',
  all_country_code__ms = 'all_country_code__ms',
  all_country_code__mt = 'all_country_code__mt',
  all_country_code__mu = 'all_country_code__mu',
  all_country_code__mv = 'all_country_code__mv',
  all_country_code__mw = 'all_country_code__mw',
  all_country_code__mx = 'all_country_code__mx',
  all_country_code__my = 'all_country_code__my',
  all_country_code__mz = 'all_country_code__mz',
  all_country_code__na = 'all_country_code__na',
  all_country_code__nc = 'all_country_code__nc',
  all_country_code__ne = 'all_country_code__ne',
  all_country_code__nf = 'all_country_code__nf',
  all_country_code__ng = 'all_country_code__ng',
  all_country_code__ni = 'all_country_code__ni',
  all_country_code__nl = 'all_country_code__nl',
  all_country_code__no = 'all_country_code__no',
  all_country_code__np = 'all_country_code__np',
  all_country_code__nr = 'all_country_code__nr',
  all_country_code__nu = 'all_country_code__nu',
  all_country_code__nz = 'all_country_code__nz',
  all_country_code__om = 'all_country_code__om',
  all_country_code__pa = 'all_country_code__pa',
  all_country_code__pe = 'all_country_code__pe',
  all_country_code__pf = 'all_country_code__pf',
  all_country_code__pg = 'all_country_code__pg',
  all_country_code__ph = 'all_country_code__ph',
  all_country_code__pk = 'all_country_code__pk',
  all_country_code__pl = 'all_country_code__pl',
  all_country_code__pm = 'all_country_code__pm',
  all_country_code__pn = 'all_country_code__pn',
  all_country_code__pr = 'all_country_code__pr',
  all_country_code__ps = 'all_country_code__ps',
  all_country_code__pt = 'all_country_code__pt',
  all_country_code__pw = 'all_country_code__pw',
  all_country_code__py = 'all_country_code__py',
  all_country_code__qa = 'all_country_code__qa',
  all_country_code__re = 'all_country_code__re',
  all_country_code__ro = 'all_country_code__ro',
  all_country_code__rs = 'all_country_code__rs',
  all_country_code__ru = 'all_country_code__ru',
  all_country_code__rw = 'all_country_code__rw',
  all_country_code__sa = 'all_country_code__sa',
  all_country_code__sb = 'all_country_code__sb',
  all_country_code__sc = 'all_country_code__sc',
  all_country_code__sd = 'all_country_code__sd',
  all_country_code__se = 'all_country_code__se',
  all_country_code__sg = 'all_country_code__sg',
  all_country_code__sh = 'all_country_code__sh',
  all_country_code__si = 'all_country_code__si',
  all_country_code__sj = 'all_country_code__sj',
  all_country_code__sk = 'all_country_code__sk',
  all_country_code__sl = 'all_country_code__sl',
  all_country_code__sm = 'all_country_code__sm',
  all_country_code__sn = 'all_country_code__sn',
  all_country_code__so = 'all_country_code__so',
  all_country_code__sr = 'all_country_code__sr',
  all_country_code__ss = 'all_country_code__ss',
  all_country_code__st = 'all_country_code__st',
  all_country_code__sv = 'all_country_code__sv',
  all_country_code__sx = 'all_country_code__sx',
  all_country_code__sy = 'all_country_code__sy',
  all_country_code__sz = 'all_country_code__sz',
  all_country_code__tc = 'all_country_code__tc',
  all_country_code__td = 'all_country_code__td',
  all_country_code__tf = 'all_country_code__tf',
  all_country_code__tg = 'all_country_code__tg',
  all_country_code__th = 'all_country_code__th',
  all_country_code__tj = 'all_country_code__tj',
  all_country_code__tk = 'all_country_code__tk',
  all_country_code__tl = 'all_country_code__tl',
  all_country_code__tm = 'all_country_code__tm',
  all_country_code__tn = 'all_country_code__tn',
  all_country_code__to = 'all_country_code__to',
  all_country_code__tr = 'all_country_code__tr',
  all_country_code__tt = 'all_country_code__tt',
  all_country_code__tv = 'all_country_code__tv',
  all_country_code__tw = 'all_country_code__tw',
  all_country_code__tz = 'all_country_code__tz',
  all_country_code__ua = 'all_country_code__ua',
  all_country_code__ug = 'all_country_code__ug',
  all_country_code__um = 'all_country_code__um',
  all_country_code__us = 'all_country_code__us',
  all_country_code__uy = 'all_country_code__uy',
  all_country_code__uz = 'all_country_code__uz',
  all_country_code__va = 'all_country_code__va',
  all_country_code__vc = 'all_country_code__vc',
  all_country_code__ve = 'all_country_code__ve',
  all_country_code__vg = 'all_country_code__vg',
  all_country_code__vi = 'all_country_code__vi',
  all_country_code__vn = 'all_country_code__vn',
  all_country_code__vu = 'all_country_code__vu',
  all_country_code__wf = 'all_country_code__wf',
  all_country_code__ws = 'all_country_code__ws',
  all_country_code__ye = 'all_country_code__ye',
  all_country_code__yt = 'all_country_code__yt',
  all_country_code__za = 'all_country_code__za',
  all_country_code__zm = 'all_country_code__zm',
  all_country_code__zw = 'all_country_code__zw'
}

export type CouponApplied = {
  __typename: 'CouponApplied';
  deductedPrice?: Maybe<Scalars['Int']>;
  discountInfo: DiscountInfo;
  discountedPrice?: Maybe<Scalars['Int']>;
  originalPrice: Scalars['Int'];
};

export type CouponDetail = {
  __typename: 'CouponDetail';
  couponId: Scalars['ID'];
  couponItemListByPage: CouponItemListByPage;
  discountInfo: DiscountInfo;
  endAt: Scalars['Date'];
  limitCount: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  remainDays: Scalars['Int'];
  startAt: Scalars['Date'];
  totalTeamCnt: Scalars['Int'];
  useTeamCnt: Scalars['Int'];
};


export type CouponDetailCouponItemListByPageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum CouponDiscountType {
  FIXED_RATE_DISCOUNT = 'FIXED_RATE_DISCOUNT',
  FLAT_DISCOUNT = 'FLAT_DISCOUNT'
}

export type CouponIsGiven = {
  __typename: 'CouponIsGiven';
  isGiven: Scalars['Boolean'];
  ownerToken: Scalars['String'];
};

export type CouponIsGivenList = {
  __typename: 'CouponIsGivenList';
  content?: Maybe<Array<CouponIsGiven>>;
};

export type CouponItem = {
  __typename: 'CouponItem';
  code: Scalars['String'];
  endAt?: Maybe<Scalars['Date']>;
  ownerToken: Scalars['String'];
  startAt?: Maybe<Scalars['Date']>;
  state: CouponItemStateType;
  team: SportsTeam;
  terminatedAt?: Maybe<Scalars['Date']>;
  useCount: Scalars['Int'];
};

export type CouponItemListByPage = Page & {
  __typename: 'CouponItemListByPage';
  content?: Maybe<Array<CouponItem>>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum CouponItemStateType {
  TERMINATED = 'TERMINATED',
  UNUSED = 'UNUSED',
  USE_COMPLETE = 'USE_COMPLETE',
  USING = 'USING'
}

export type CouponListByPage = Page & {
  __typename: 'CouponListByPage';
  content?: Maybe<Array<CouponSimple>>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type CouponListByTeam = {
  __typename: 'CouponListByTeam';
  content?: Maybe<Array<CouponSimple>>;
};

export type CouponSimple = {
  __typename: 'CouponSimple';
  couponId: Scalars['ID'];
  endAt: Scalars['Date'];
  name: Scalars['String'];
  remainDays: Scalars['Int'];
  startAt: Scalars['Date'];
};

export type CreateAttendanceStatusInput = {
  absenceReason?: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
  status: EventInviteeStatus;
};

export type CreateBodyInput = {
  height: Scalars['Float'];
  heightUnit?: Maybe<HeightUnit>;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
  weight: Scalars['Float'];
  weightUnit?: Maybe<WeightUnit>;
};

export type CreateCheckReminderPushSchedule = {
  pushMessageType: ReminderPushMessageType;
  scheduleTime: Scalars['String'];
  teamIdList: Array<Scalars['String']>;
  timezone: Scalars['String'];
};

export type CreateConditionInput = {
  fatigue: Scalars['Int'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  sleepDuration: Scalars['Int'];
  sleepQuality: Scalars['Int'];
  sleepTime: Scalars['DateTime'];
  stress: Scalars['Int'];
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type CreateCouponInput = {
  discountType: CouponDiscountType;
  discountValue: Scalars['Int'];
  endAt: Scalars['DateTime'];
  limitCount: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type CreateEventInput = {
  competition?: Maybe<Scalars['String']>;
  endAt: Scalars['DateTime'];
  eventInviteIdentityList: Array<EventInviteIdentity>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: ScheduleType;
  venue?: Maybe<Venue>;
};

export type CreateFeedbackCommentInput = {
  comment: Scalars['String'];
  feedbackId: Scalars['ID'];
};

export type CreateFeedbackInput = {
  conditionNotGood?: Maybe<FeedbackConditionNotGoodInput>;
  conditionPlummet?: Maybe<FeedbackConditionPlummetInput>;
  /** YYYY-MM-DD 형식 */
  date: Scalars['String'];
  message: Scalars['String'];
  playerId: Scalars['String'];
  sorenessSevere?: Maybe<FeedbackSorenessSevereInput>;
  teamId: Scalars['String'];
  type: FeedbackType;
  workloadMemo?: Maybe<FeedbackWorkloadMemoInput>;
  workloadRiskAtInjury?: Maybe<FeedbackWorkloadRiskAtInjuryInput>;
};

export type CreateHyperClientInput = {
  birthdate?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isExistsUser: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CreateInjuryInput = {
  bodyPart?: Maybe<SportsBodyPartName>;
  /** 최대 100자 */
  diagnosis?: Maybe<Scalars['String']>;
  historyStatus: InjuryHistoryStatus;
  /** 최대 100자 */
  medicalRecord?: Maybe<Scalars['String']>;
  occurDate: Scalars['DateTime'];
  /** 최대 100자 */
  reason?: Maybe<Scalars['String']>;
  return: ReturnInput;
  status: InjuryStatus;
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateInput = {
  schedule?: Maybe<ScheduleInput>;
  title: Scalars['String'];
};

export type CreateLicenseInput = {
  endAt: Scalars['DateTime'];
  licenseName: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  teamVolume: Scalars['Int'];
};

export type CreateNoWorkoutInput = {
  reasonForNoWorkout: NoWorkoutType;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type CreateScheduleAttendanceStatusInput = {
  absenceReason?: Maybe<Scalars['String']>;
  scheduleId: Scalars['ID'];
  status: ScheduleInviteeStatus;
};

export type CreateSpecialNoteInput = {
  eventId: Scalars['ID'];
  specialNote: Scalars['String'];
};

export type CreateTeamGroupInput = {
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  teamId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type CreateTeamInput = {
  name: Scalars['String'];
};

export type CreateTrainingPointInput = {
  eventId: Scalars['ID'];
  trainingPoint: Scalars['String'];
};

export type CreateWorkloadInput = {
  duration: Scalars['Int'];
  intensity: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  satisfaction: Scalars['Int'];
  scheduleId?: Maybe<Scalars['ID']>;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
  type: WorkoutType;
};

export type CsvExportInput = {
  from: Scalars['DateTimeString'];
  group: Array<SportsDataGroup>;
  language: Language;
  to: Scalars['DateTimeString'];
  userIdList: Array<Scalars['ID']>;
};

export type CurrentSorenessUser = {
  __typename: 'CurrentSorenessUser';
  sorenessList: Array<Soreness>;
  type: UserSorenessLabelType;
  user: SportsUser;
};

export type DailyAcwrData = DailyData & {
  __typename: 'DailyAcwrData';
  range: Array<SportsTeamDataRange>;
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailyConditionData = DailyData & {
  __typename: 'DailyConditionData';
  range: Array<SportsTeamDataRange>;
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailyData = {
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailyReadinessData = DailyData & {
  __typename: 'DailyReadinessData';
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailyTeamData = {
  __typename: 'DailyTeamData';
  acwr: DailyAcwrData;
  condition: DailyConditionData;
  readiness: DailyReadinessData;
  workload: DailyWorkloadData;
};

export type DailyWorkloadData = DailyData & {
  __typename: 'DailyWorkloadData';
  range: Array<SportsTeamDataRange>;
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};




export type DeleteBannerInput = {
  id: Scalars['ID'];
};

export type DeleteFeedbackCommentInput = {
  commentId: Scalars['ID'];
  feedbackId: Scalars['ID'];
};

export type DeleteFileInput = {
  id: Scalars['ID'];
};

export type DeleteInjuryInput = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type DeleteInput = {
  routineId: Scalars['ID'];
};

export type DeleteLicenseInput = {
  licenseId: Scalars['ID'];
};

export type DeleteNoWorkoutInput = {
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type DeleteSportsDataInput = {
  date: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type DeleteSportsUserInput = {
  identityId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeleteTeamGroupInput = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type DiscountInfo = {
  __typename: 'DiscountInfo';
  type?: Maybe<CouponDiscountType>;
  value?: Maybe<Scalars['Int']>;
};

export type Event = {
  endAt: Scalars['Date'];
  eventInvitees: Array<EventInvitee>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: ScheduleType;
};

export type EventInviteIdentity = {
  id: Scalars['ID'];
};

export type EventInvitee = {
  event: Event;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  status?: Maybe<EventInviteeStatus>;
  /** the player who is invited to this event */
  user?: Maybe<SportsUser>;
  workoutType?: Maybe<WorkoutType>;
};

export enum EventInviteeStatus {
  ABSENCE_ETC = 'ABSENCE_ETC',
  ABSENCE_INJURY = 'ABSENCE_INJURY',
  NOT_CHECK = 'NOT_CHECK',
  NO_PLAY = 'NO_PLAY',
  PARTICIPATION = 'PARTICIPATION',
  STARTER = 'STARTER',
  SUBSTITUTE = 'SUBSTITUTE'
}

export type Eventable = {
  event?: Maybe<EventInvitee>;
};

export type ExportReport = {
  eventId: Scalars['String'];
  fileType: ReportFileType;
  language: Language;
  timezone: Scalars['String'];
};

export type ExportReportSchedule = {
  fileType: ReportFileType;
  nationality: CountryCode;
  scheduleId: Scalars['String'];
  timezone: Scalars['String'];
};

export type Feedback = {
  __typename: 'Feedback';
  commentList: Array<FeedbackComment>;
  contentsData?: Maybe<FeedbackContentsData>;
  createUser: SportsUser;
  createdAt: Scalars['Date'];
  date: Scalars['String'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isUpdated: Scalars['Boolean'];
  message: Scalars['String'];
  player: SportsUser;
  team?: Maybe<SportsTeam>;
  teamId: Scalars['ID'];
  type: FeedbackType;
};

export type FeedbackAlarm = Alarm & {
  __typename: 'FeedbackAlarm';
  commentId?: Maybe<Scalars['ID']>;
  createUser: SportsUser;
  createdAt: Scalars['DateTime'];
  feedback: Feedback;
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  player?: Maybe<SportsUser>;
  team?: Maybe<SportsTeam>;
  type: AlarmType;
};

export type FeedbackComment = {
  __typename: 'FeedbackComment';
  comment: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  isUpdated: Scalars['Boolean'];
  user: SportsUser;
};

export type FeedbackConditionNotGoodData = {
  __typename: 'FeedbackConditionNotGoodData';
  dataList: Array<UserConditionDataType>;
  fatigue: Scalars['Int'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  sleepDuration: Scalars['Int'];
  sleepDurationScore: Scalars['Int'];
  sleepQuality: Scalars['Int'];
  stress: Scalars['Int'];
};

export type FeedbackConditionNotGoodInput = {
  dataList: Array<UserConditionDataType>;
  fatigue: Scalars['Int'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  sleepDuration: Scalars['Int'];
  sleepDurationScore: Scalars['Int'];
  sleepQuality: Scalars['Int'];
  stress: Scalars['Int'];
};

export type FeedbackConditionPlummetData = {
  __typename: 'FeedbackConditionPlummetData';
  dataList: Array<UserConditionDataType>;
  fatigue28dayList: Array<Scalars['Int']>;
  mood28dayList: Array<Scalars['Int']>;
  muscle28dayList: Array<Scalars['Int']>;
  sleepDuration28dayList: Array<Scalars['Int']>;
  sleepQuality28dayList: Array<Scalars['Int']>;
  stress28dayList: Array<Scalars['Int']>;
};

export type FeedbackConditionPlummetInput = {
  dataList: Array<UserConditionDataType>;
  fatigue28dayList: Array<Scalars['Int']>;
  mood28dayList: Array<Scalars['Int']>;
  muscle28dayList: Array<Scalars['Int']>;
  sleepDuration28dayList: Array<Scalars['Int']>;
  sleepQuality28dayList: Array<Scalars['Int']>;
  stress28dayList: Array<Scalars['Int']>;
};

export type FeedbackConnection = {
  __typename: 'FeedbackConnection';
  edges: Array<FeedbackEdge>;
  pageInfo: PageInfo;
};

export type FeedbackContentsData = FeedbackConditionNotGoodData | FeedbackConditionPlummetData | FeedbackSorenessSevereData | FeedbackWorkloadMemoData | FeedbackWorkloadRiskAtInjuryData;

export type FeedbackEdge = {
  __typename: 'FeedbackEdge';
  cursor: Scalars['String'];
  node: Feedback;
};

export type FeedbackSorenessSevereData = {
  __typename: 'FeedbackSorenessSevereData';
  bodyPart: SportsBodyPart;
  isContact?: Maybe<Contact>;
  level: Scalars['Int'];
  memo: Scalars['String'];
};


export type FeedbackSorenessSevereDataBodyPartArgs = {
  input: MultilingualTextInput;
};

export type FeedbackSorenessSevereInput = {
  bodyPart: SportsBodyPartName;
  isContact?: Maybe<Contact>;
  level: Scalars['Int'];
  memo: Scalars['String'];
};

export type FeedbackSuggestion = {
  __typename: 'FeedbackSuggestion';
  contentsData?: Maybe<FeedbackContentsData>;
  date: Scalars['String'];
  type: FeedbackType;
};

export enum FeedbackType {
  CONDITION_NOT_GOOD = 'CONDITION_NOT_GOOD',
  CONDITION_PLUMMET = 'CONDITION_PLUMMET',
  NORMAL = 'NORMAL',
  REQUEST = 'REQUEST',
  SORENESS_SEVERE = 'SORENESS_SEVERE',
  WORKLOAD_MEMO = 'WORKLOAD_MEMO',
  WORKLOAD_RISK_AT_INJURY = 'WORKLOAD_RISK_AT_INJURY'
}

export type FeedbackWorkloadMemoData = {
  __typename: 'FeedbackWorkloadMemoData';
  duration: Scalars['Int'];
  memo: Scalars['String'];
  scheduleData?: Maybe<FeedbackWorkloadScheduleData>;
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
};

export type FeedbackWorkloadMemoInput = {
  duration: Scalars['Int'];
  memo: Scalars['String'];
  scheduleData?: Maybe<FeedbackWorkloadScheduleInput>;
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
};

export type FeedbackWorkloadRiskAtInjuryData = {
  __typename: 'FeedbackWorkloadRiskAtInjuryData';
  analysisType: UserWorkloadSuggestionDataType;
  label: UserWorkloadRiskAtInjuryLabelType;
  reasonList: Array<UserWorkloadRiskAtInjuryReasonType>;
};

export type FeedbackWorkloadRiskAtInjuryInput = {
  analysisType: UserWorkloadSuggestionDataType;
  label: UserWorkloadRiskAtInjuryLabelType;
  reasonList: Array<UserWorkloadRiskAtInjuryReasonType>;
};

export type FeedbackWorkloadScheduleData = {
  __typename: 'FeedbackWorkloadScheduleData';
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  scheduleDate: Scalars['String'];
  startTime?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: ScheduleType;
};

export type FeedbackWorkloadScheduleInput = {
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  scheduleDate: Scalars['String'];
  startTime?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: ScheduleType;
};

export type FileEntity = {
  __typename: 'FileEntity';
  byteSize: Scalars['Float'];
  contentType: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  private: Scalars['Boolean'];
  tags: Scalars['JSON'];
  updatedAt: Scalars['Date'];
  url: Scalars['String'];
};

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER'
}

export type GetCoachEventInput = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};

export type GetCoachScheduleInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type GetCouponAppliedPriceInput = {
  couponId?: Maybe<Scalars['String']>;
  originalPrice: Scalars['Int'];
};

export type GiveCouponToTeamsInput = {
  teamIdList: Array<Scalars['ID']>;
};

export type GuideReopenStatus = {
  __typename: 'GuideReopenStatus';
  alarm: GuideReopenStatusUnit;
  readiness: GuideReopenStatusUnit;
};

export type GuideReopenStatusUnit = {
  __typename: 'GuideReopenStatusUnit';
  app: ReopenStatus;
  web: ReopenStatus;
};

export enum HeightUnit {
  cm = 'cm',
  ft = 'ft',
  in = 'in'
}

export type Injury = {
  __typename: 'Injury';
  bodyPart?: Maybe<SportsBodyPart>;
  diagnosis?: Maybe<Scalars['String']>;
  fileList: Array<InjuryFile>;
  historyStatus: InjuryHistoryStatus;
  id: Scalars['ID'];
  medicalRecord?: Maybe<Scalars['String']>;
  occurDate: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  return: Return;
  status: InjuryStatus;
  user: SportsUser;
};


export type InjuryBodyPartArgs = {
  input: MultilingualTextInput;
};

export type InjuryFile = {
  __typename: 'InjuryFile';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export enum InjuryHistoryStatus {
  FIRST = 'FIRST',
  RELAPSE = 'RELAPSE'
}

export enum InjuryReturnStatus {
  DECIDED = 'DECIDED',
  UNDECIDED = 'UNDECIDED'
}

export enum InjuryStatus {
  END = 'END',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type InjuryUser = {
  __typename: 'InjuryUser';
  injuryList: Array<Injury>;
  user: SportsUser;
};

export type InjuryUserList = {
  __typename: 'InjuryUserList';
  total: Scalars['Float'];
  userList: Array<InjuryUser>;
};

export type IntensityUser = {
  __typename: 'IntensityUser';
  intensity: Scalars['Float'];
  user: SportsUser;
};

export type InvitationRequest = {
  id: Scalars['ID'];
  teamAuthority: SportsTeamAuthority;
  userId: Scalars['ID'];
};

export type Invoice = {
  billingUserEmail: Scalars['String'];
  billingUserName: Scalars['String'];
  billingUserPhone: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  id: Scalars['ID'];
  paymentName: Scalars['String'];
  paymentStatus: PaymentStatus;
  receiptUrl?: Maybe<Scalars['String']>;
};

export type InvoiceDetail = {
  __typename: 'InvoiceDetail';
  amount: Scalars['Float'];
  billingUserEmail: Scalars['String'];
  billingUserName: Scalars['String'];
  billingUserPhone: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  id: Scalars['ID'];
  maxPlayerCount: Scalars['Float'];
  maxTeamCount: Scalars['Float'];
  paidAt: Scalars['String'];
  planName: Scalars['String'];
  planOptionName: Scalars['String'];
  receiptUrl?: Maybe<Scalars['String']>;
};

export type InvoiceList = {
  __typename: 'InvoiceList';
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  invoiceList?: Maybe<Array<Maybe<Invoice>>>;
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  total: Scalars['Float'];
};


export enum Language {
  EN = 'EN',
  JA = 'JA',
  KO = 'KO',
  VN = 'VN'
}

export type License = {
  __typename: 'License';
  createdAt: Scalars['String'];
  endAt: Scalars['String'];
  id: Scalars['ID'];
  isActivate: Scalars['Boolean'];
  licenseName: Scalars['String'];
  licensedTeamList: Array<SportsTeam>;
  memo: Scalars['String'];
  observerList: Array<SportsUser>;
  startAt: Scalars['String'];
  teamIdList: Array<Scalars['String']>;
  teamVolume: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type LicenseListByPage = {
  __typename: 'LicenseListByPage';
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  licenseList?: Maybe<Array<License>>;
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  total: Scalars['Float'];
};

export type LineupInvitee = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineupPosition?: Maybe<Scalars['String']>;
  playTime?: Maybe<Scalars['String']>;
  status: EventInviteeStatus;
};

export type LineupInviteeSchedule = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineupPosition?: Maybe<SportsPosition>;
  matchStatus: MatchInviteeStatus;
  playTime?: Maybe<Scalars['String']>;
};

export type MakeDoNotReopenAlarmGuideStatusInput = {
  platform: Platform;
  userId: Scalars['ID'];
};

export type MakeDoNotReopenReadinessGuideStatusInput = {
  platform: Platform;
  untilToday: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type Match = Event & {
  __typename: 'Match';
  attendeeChartData: AttendeeChartData;
  competition: Scalars['String'];
  endAt: Scalars['Date'];
  eventInvitees: Array<MatchInvitee>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  opponent: Scalars['String'];
  result: MatchResult;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: ScheduleType;
  venue: Venue;
  workloadCheckedUserCount: Scalars['Float'];
};


export type MatchAttendeeChartDataArgs = {
  dataType?: Maybe<AttendeeChartDataType>;
};

export type MatchInvitee = EventInvitee & {
  __typename: 'MatchInvitee';
  comment?: Maybe<Scalars['String']>;
  event: Event;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  lineupPosition?: Maybe<Scalars['String']>;
  playTime?: Maybe<Scalars['String']>;
  status?: Maybe<EventInviteeStatus>;
  /** the player who is invited to this event */
  user?: Maybe<SportsUser>;
  workoutType?: Maybe<WorkoutType>;
};

export enum MatchInviteeStatus {
  NO_PLAY = 'NO_PLAY',
  STARTER = 'STARTER',
  SUBSTITUTE = 'SUBSTITUTE'
}

export type MatchReportSolution = {
  __typename: 'MatchReportSolution';
  matchWorkloadTeamSolution: MatchWorkloadTeamSolution;
  solutionUserList: Array<MatchReportSolutionUser>;
};

export type MatchReportSolutionUser = {
  __typename: 'MatchReportSolutionUser';
  user: SportsUser;
  workloadSolution: MatchWorkloadSolution;
};

export type MatchResult = {
  __typename: 'MatchResult';
  memo?: Maybe<Scalars['String']>;
  opponentScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  status?: Maybe<MatchResultStatus>;
};

export type MatchResultForCoordinatingReport = {
  __typename: 'MatchResultForCoordinatingReport';
  memo?: Maybe<Scalars['String']>;
  opponentScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

export type MatchResultSchedule = {
  __typename: 'MatchResultSchedule';
  opponentScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

export enum MatchResultStatus {
  DRAW = 'DRAW',
  LOSE = 'LOSE',
  WIN = 'WIN'
}

export type MatchSchedule = Schedule & {
  __typename: 'MatchSchedule';
  attendeeChartData: AttendeeChartDataSchedule;
  competition?: Maybe<Scalars['String']>;
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['Time']>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  fileList?: Maybe<Array<ScheduleFile>>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isPlayerNotice: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  matchPoint?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  participatedScheduleInviteeList: Array<MatchScheduleInvitee>;
  result: MatchResultSchedule;
  scheduleDate: Scalars['String'];
  scheduleInviteeList: Array<MatchScheduleInvitee>;
  startTime?: Maybe<Scalars['Time']>;
  team?: Maybe<SportsTeam>;
  title?: Maybe<Scalars['String']>;
  trainingPoint?: Maybe<Scalars['String']>;
  type: ScheduleType;
  venue?: Maybe<Venue>;
  workloadCheckedUserCount: Scalars['Float'];
};


export type MatchScheduleAttendeeChartDataArgs = {
  dataType?: Maybe<AttendeeChartDataType>;
};

export type MatchScheduleForCoordinatingReport = {
  __typename: 'MatchScheduleForCoordinatingReport';
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['Time']>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isPlayerNotice: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  result: MatchResultForCoordinatingReport;
  scheduleDate: Scalars['String'];
  scheduleInviteeList: Array<MatchScheduleInvitee>;
  startTime?: Maybe<Scalars['Time']>;
  title?: Maybe<Scalars['String']>;
  type: ScheduleType;
  venue?: Maybe<Venue>;
};

export type MatchScheduleInvitee = ScheduleInvitee & {
  __typename: 'MatchScheduleInvitee';
  absenceReason?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  lineupPosition?: Maybe<Scalars['String']>;
  matchStatus?: Maybe<MatchInviteeStatus>;
  playTime?: Maybe<Scalars['String']>;
  schedule?: Maybe<Schedule>;
  status?: Maybe<ScheduleInviteeStatus>;
  team?: Maybe<SportsTeam>;
  /** the player who is invited to this schedule */
  user?: Maybe<SportsUser>;
  workoutType: WorkoutType;
};


export type MatchScheduleInviteeScheduleArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export type MatchWorkloadSolution = {
  __typename: 'MatchWorkloadSolution';
  guide: Scalars['String'];
  type: MatchWorkloadSolutionGuideType;
};

export enum MatchWorkloadSolutionGuideType {
  MD1 = 'MD1',
  MD2 = 'MD2',
  MD3 = 'MD3',
  ME1 = 'ME1',
  ME2 = 'ME2',
  ME3 = 'ME3'
}

export type MatchWorkloadTeamSolution = {
  __typename: 'MatchWorkloadTeamSolution';
  guide: Scalars['String'];
  type: MatchWorkloadTeamSolutionGuideType;
};

export enum MatchWorkloadTeamSolutionGuideType {
  MA = 'MA',
  MB1 = 'MB1',
  MB2 = 'MB2',
  MB3 = 'MB3',
  MBD1 = 'MBD1',
  MBD2 = 'MBD2',
  MBD3 = 'MBD3',
  MBL1 = 'MBL1',
  MBL2 = 'MBL2',
  MBL3 = 'MBL3',
  MBW1 = 'MBW1',
  MBW2 = 'MBW2',
  MBW3 = 'MBW3',
  MC1 = 'MC1',
  MC2 = 'MC2',
  MC3 = 'MC3',
  MCD1 = 'MCD1',
  MCD2 = 'MCD2',
  MCD3 = 'MCD3',
  MCL1 = 'MCL1',
  MCL2 = 'MCL2',
  MCL3 = 'MCL3',
  MCW1 = 'MCW1',
  MCW2 = 'MCW2',
  MCW3 = 'MCW3'
}

export type MultilingualBodyPart = {
  __typename: 'MultilingualBodyPart';
  back: Scalars['Boolean'];
  front: Scalars['Boolean'];
  group: MultilingualBodyPartGroup;
  id: SportsBodyPartName;
  left: Scalars['Boolean'];
  right: Scalars['Boolean'];
  value: Scalars['String'];
};

export type MultilingualBodyPartGroup = {
  __typename: 'MultilingualBodyPartGroup';
  id: SportsBodyPartGroupName;
  value: Scalars['String'];
};

export type MultilingualCategory = {
  __typename: 'MultilingualCategory';
  coachPositionList: Array<MultilingualCoachPosition>;
  id: SportsCategory;
  playerPositionList: Array<MultilingualPlayerPosition>;
  value: Scalars['String'];
};

export type MultilingualCategoryGroup = {
  __typename: 'MultilingualCategoryGroup';
  categoryList: Array<MultilingualCategory>;
  id: SportsCategoryGroup;
  value: Scalars['String'];
};

export type MultilingualCoachPosition = {
  __typename: 'MultilingualCoachPosition';
  id: SportsPosition;
  value: Scalars['String'];
};

export type MultilingualCountry = {
  __typename: 'MultilingualCountry';
  id: CountryCode;
  value: Scalars['String'];
};

export type MultilingualPlayerPosition = {
  __typename: 'MultilingualPlayerPosition';
  id: SportsPosition;
  value: Scalars['String'];
};

export type MultilingualTextInput = {
  nationality?: Maybe<Scalars['String']>;
  osType?: Maybe<OsType>;
};

export type MultilingualTextQuery = {
  __typename: 'MultilingualTextQuery';
  bodyPartList: Array<MultilingualBodyPart>;
  categoryGroupList: Array<MultilingualCategoryGroup>;
  countryList: Array<MultilingualCountry>;
  timezoneList: Array<Timezone>;
};


export type MultilingualTextQueryBodyPartListArgs = {
  key?: Maybe<Scalars['String']>;
};


export type MultilingualTextQueryCategoryGroupListArgs = {
  key?: Maybe<Scalars['String']>;
};


export type MultilingualTextQueryCountryListArgs = {
  key?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename: 'Mutation';
  acceptInvitationList: Array<SportsTeamInvitationRequest>;
  addBannerLog: Scalars['Boolean'];
  addLicenseObserverList: License;
  addLicenseTeamList: License;
  appendSorenessList: SportsData;
  applyCoupon: SubscriptionItem;
  cancelSubscription: SubscriptionItem;
  changeCoupon: SubscriptionItem;
  changeSubscriptionDate: SubscriptionItem;
  clearDeviceTokens: Scalars['Boolean'];
  createAttendanceStatus: EventInvitee;
  createBody: SportsData;
  createCareer: SportsCareer;
  createCheckReminderPushSchedule: Array<TeamCheckReminderSchedule>;
  createCondition: SportsData;
  createCoupon: CouponDetail;
  createEvent: Event;
  createFeedback: Feedback;
  createFeedbackComment: Feedback;
  createHyperClient: Scalars['Boolean'];
  createInjury: Injury;
  createLicense: License;
  createNoWorkout: SportsData;
  createRoutine: Routine;
  createSchedule: Array<Schedule>;
  createScheduleAttendanceStatus: ScheduleInvitee;
  createSpecialNote: Event;
  createTeam: SportsTeam;
  createTeamGroup: TeamGroup;
  createTrainingPoint: Event;
  createWorkload: SportsData;
  deactivateAppLocalPush: Scalars['Boolean'];
  deleteBanner: Banner;
  deleteBody: SportsData;
  deleteCareer: Scalars['Boolean'];
  deleteCheckReminderPushSchedule: Scalars['Boolean'];
  deleteCondition: SportsData;
  deleteCoupon: CouponDetail;
  deleteEvent: Scalars['Boolean'];
  deleteFeedback: Scalars['Boolean'];
  deleteFeedbackComment: Feedback;
  /** @deprecated 기능별로 필요한 파일 삭제 기능을 개발할 계획으로 추후 삭제 예정 */
  deleteFile: Scalars['Boolean'];
  deleteInjury: Scalars['Boolean'];
  deleteLicense: License;
  deleteNoWorkout: SportsData;
  deleteRoutine: Routine;
  deleteSchedule: Scalars['Boolean'];
  deleteTeam: SportsTeam;
  deleteTeamGroup: TeamGroup;
  deleteUser: Scalars['Boolean'];
  deleteWorkload: SportsData;
  deregisterDeviceToken: Scalars['Boolean'];
  exportCsvFile: Scalars['String'];
  exportMatchReport: Scalars['String'];
  exportMatchReportSchedule: Scalars['String'];
  exportTrainingReport: Scalars['String'];
  exportTrainingReportSchedule: Scalars['String'];
  giveCoupons: CouponDetail;
  kickoutTeamUserList: Scalars['Boolean'];
  leaveTeam: Scalars['Boolean'];
  makeAlarmGuideDoNotReopenStatus: GuideReopenStatusUnit;
  makeCoachAlarmListChecked: Array<Alarm>;
  makePlayerAlarmListChecked: Array<Alarm>;
  makeReadinessGuideDoNotReopenStatus: GuideReopenStatusUnit;
  refund: RefundResponse;
  refundSubscription: SubscriptionResponse;
  registerDeviceToken: Scalars['Boolean'];
  rejectTeamInvitationList: Array<SportsTeamInvitationRequest>;
  removeCoupon: CouponDetail;
  removeLicenseObserver: License;
  removeLicenseTeam: License;
  removeUnusedCoupons: CouponDetail;
  requestBillingToken: BillingToken;
  requestTeamInvitation: SportsTeamInvitationRequest;
  restartSubscription: SubscriptionResponse;
  resumeSubscription: SubscriptionItem;
  retryPayment: SubscriptionResponse;
  sendCheckConditionReminderPush: Scalars['Boolean'];
  sendCheckScheduleInviteeReminderPush: Scalars['Boolean'];
  sendCheckWorkloadReminderPush: Scalars['Boolean'];
  sendEventWorkloadReminderPush: Scalars['Boolean'];
  startSubscription: SubscriptionResponse;
  toggleRoutine: RoutineToggle;
  toggleTeamUserStarStatus: StarredToggleResult;
  transferTeamOwnership: TransferTeamOwnership;
  updateAttendanceStatus: EventInvitee;
  updateBanner: Banner;
  updateBillingBuyerEmail: Billing;
  updateBillingBuyerName: Billing;
  updateBillingBuyerNumber: Billing;
  updateBillingKey: Billing;
  updateBody: SportsData;
  updateCareer: SportsCareer;
  updateCondition: SportsData;
  updateCoupon: CouponDetail;
  updateEvent: Event;
  updateEventWorkloadDuration: Event;
  updateFeedback: Feedback;
  updateFeedbackComment: Feedback;
  updateInjury: Injury;
  updateLicenseDate: License;
  updateLineup: Match;
  updateLineupSchedule: MatchSchedule;
  updateMatchPoint: MatchSchedule;
  updateMatchResult: Match;
  updateMatchResultSchedule: MatchSchedule;
  updateNoWorkout: SportsData;
  updateRoutine: Routine;
  updateSchedule: Schedule;
  updateScheduleAttendanceStatus: ScheduleInvitee;
  updateSpecialNote: Event;
  updateTeam: SportsTeam;
  updateTeamGroup: TeamGroup;
  updateTrainingPoint: Event;
  updateTrainingPointSchedule: Schedule;
  updateUser: SportsUser;
  updateWorkload: SportsData;
  uploadBanner: Banner;
  uploadCalendar: Calendar;
  uploadCoordinatingReport: Scalars['Boolean'];
  uploadProfile: UploadProfileResponse;
  uploadScheduleReport: Scalars['Boolean'];
  upsertScheduleAttendanceStatus: ScheduleInvitee;
};


export type MutationAcceptInvitationListArgs = {
  input: SportsTeamInvitationAcceptListInput;
};


export type MutationAddBannerLogArgs = {
  input: AddBannerLogInput;
};


export type MutationAddLicenseObserverListArgs = {
  input: AddLicenseObserverList;
};


export type MutationAddLicenseTeamListArgs = {
  input: AddLicenseTeamListInput;
};


export type MutationAppendSorenessListArgs = {
  input: AppendSorenessListInput;
};


export type MutationApplyCouponArgs = {
  input: ApplyCouponInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationChangeCouponArgs = {
  input: ChangeCouponInput;
};


export type MutationChangeSubscriptionDateArgs = {
  input: ChangeSubscriptionDateInput;
};


export type MutationCreateAttendanceStatusArgs = {
  input: CreateAttendanceStatusInput;
};


export type MutationCreateBodyArgs = {
  input: CreateBodyInput;
};


export type MutationCreateCareerArgs = {
  input: SportsCareerCreateInput;
};


export type MutationCreateCheckReminderPushScheduleArgs = {
  input: CreateCheckReminderPushSchedule;
};


export type MutationCreateConditionArgs = {
  input: CreateConditionInput;
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
  teamId: Scalars['ID'];
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


export type MutationCreateFeedbackCommentArgs = {
  input: CreateFeedbackCommentInput;
};


export type MutationCreateHyperClientArgs = {
  input: CreateHyperClientInput;
};


export type MutationCreateInjuryArgs = {
  files: Array<Scalars['Upload']>;
  input: CreateInjuryInput;
};


export type MutationCreateLicenseArgs = {
  input: CreateLicenseInput;
};


export type MutationCreateNoWorkoutArgs = {
  input: CreateNoWorkoutInput;
};


export type MutationCreateRoutineArgs = {
  input: CreateInput;
};


export type MutationCreateScheduleArgs = {
  fileList?: Maybe<Array<Scalars['Upload']>>;
  input: ScheduleCreateInput;
  teamId: Scalars['ID'];
};


export type MutationCreateScheduleAttendanceStatusArgs = {
  input: CreateScheduleAttendanceStatusInput;
};


export type MutationCreateSpecialNoteArgs = {
  input: CreateSpecialNoteInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTeamGroupArgs = {
  input: CreateTeamGroupInput;
};


export type MutationCreateTrainingPointArgs = {
  input: CreateTrainingPointInput;
};


export type MutationCreateWorkloadArgs = {
  input: CreateWorkloadInput;
};


export type MutationDeleteBannerArgs = {
  input: DeleteBannerInput;
};


export type MutationDeleteBodyArgs = {
  input: DeleteSportsDataInput;
};


export type MutationDeleteCareerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCheckReminderPushScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteConditionArgs = {
  input: DeleteSportsDataInput;
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationDeleteFeedbackArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFeedbackCommentArgs = {
  input: DeleteFeedbackCommentInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationDeleteInjuryArgs = {
  input: DeleteInjuryInput;
};


export type MutationDeleteLicenseArgs = {
  input: DeleteLicenseInput;
};


export type MutationDeleteNoWorkoutArgs = {
  input: DeleteNoWorkoutInput;
};


export type MutationDeleteRoutineArgs = {
  input: DeleteInput;
};


export type MutationDeleteScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['ID'];
};


export type MutationDeleteTeamGroupArgs = {
  input: DeleteTeamGroupInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteSportsUserInput;
};


export type MutationDeleteWorkloadArgs = {
  input: DeleteSportsDataInput;
};


export type MutationDeregisterDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationExportCsvFileArgs = {
  input: CsvExportInput;
};


export type MutationExportMatchReportArgs = {
  input: ExportReport;
};


export type MutationExportMatchReportScheduleArgs = {
  input: ExportReportSchedule;
};


export type MutationExportTrainingReportArgs = {
  input: ExportReport;
};


export type MutationExportTrainingReportScheduleArgs = {
  input: ExportReportSchedule;
};


export type MutationGiveCouponsArgs = {
  id: Scalars['ID'];
  input: GiveCouponToTeamsInput;
};


export type MutationKickoutTeamUserListArgs = {
  input: TeamUserListInput;
};


export type MutationLeaveTeamArgs = {
  input: TeamUserInput;
};


export type MutationMakeAlarmGuideDoNotReopenStatusArgs = {
  input: MakeDoNotReopenAlarmGuideStatusInput;
};


export type MutationMakeCoachAlarmListCheckedArgs = {
  alarmId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
  teamId: Scalars['ID'];
};


export type MutationMakePlayerAlarmListCheckedArgs = {
  alarmId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
};


export type MutationMakeReadinessGuideDoNotReopenStatusArgs = {
  input: MakeDoNotReopenReadinessGuideStatusInput;
};


export type MutationRefundArgs = {
  input: Refund;
};


export type MutationRefundSubscriptionArgs = {
  input: RefundSubscriptionInput;
};


export type MutationRegisterDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationRejectTeamInvitationListArgs = {
  input: SportsTeamInvitationRejectListInput;
};


export type MutationRemoveCouponArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationRemoveLicenseObserverArgs = {
  input: RemoveLicenseObserver;
};


export type MutationRemoveLicenseTeamArgs = {
  input: RemoveLicenseTeamInput;
};


export type MutationRemoveUnusedCouponsArgs = {
  id: Scalars['ID'];
};


export type MutationRequestBillingTokenArgs = {
  input: RequestBillingTokenInput;
};


export type MutationRequestTeamInvitationArgs = {
  input: SportsTeamInvitationRequestInput;
};


export type MutationRestartSubscriptionArgs = {
  input: RestartSubscriptionInput;
};


export type MutationResumeSubscriptionArgs = {
  input: ResumeSubscriptionInput;
};


export type MutationRetryPaymentArgs = {
  input: RetryPayment;
};


export type MutationSendCheckConditionReminderPushArgs = {
  input: CheckReminderPushInput;
};


export type MutationSendCheckScheduleInviteeReminderPushArgs = {
  input: CheckScheduleInviteReminderPushInput;
};


export type MutationSendCheckWorkloadReminderPushArgs = {
  input: CheckReminderPushInput;
};


export type MutationSendEventWorkloadReminderPushArgs = {
  input: CheckScheduleInviteReminderPushInput;
};


export type MutationStartSubscriptionArgs = {
  input: StartSubscriptionInput;
};


export type MutationToggleRoutineArgs = {
  input: ToggleInput;
};


export type MutationToggleTeamUserStarStatusArgs = {
  input: ToggleTeamUserStarredInput;
};


export type MutationTransferTeamOwnershipArgs = {
  input: TeamUserInput;
};


export type MutationUpdateAttendanceStatusArgs = {
  input: UpdateAttendanceStatusInput;
};


export type MutationUpdateBannerArgs = {
  file?: Maybe<Scalars['Upload']>;
  input: UpdateBannerInput;
};


export type MutationUpdateBillingBuyerEmailArgs = {
  input: UpdateBillingBuyerEmailInput;
};


export type MutationUpdateBillingBuyerNameArgs = {
  input: UpdateBillingBuyerNameInput;
};


export type MutationUpdateBillingBuyerNumberArgs = {
  input: UpdateBillingBuyerNumberInput;
};


export type MutationUpdateBillingKeyArgs = {
  input: UpdateBillingTokenInput;
};


export type MutationUpdateBodyArgs = {
  input: UpdateBodyInput;
};


export type MutationUpdateCareerArgs = {
  input: SportsCareerUpdateInput;
};


export type MutationUpdateConditionArgs = {
  input: UpdateConditionInput;
};


export type MutationUpdateCouponArgs = {
  id: Scalars['ID'];
  input: UpdateCouponInput;
};


export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  input: UpdateEventInput;
  teamId: Scalars['ID'];
};


export type MutationUpdateEventWorkloadDurationArgs = {
  input: UpdateEventWorkloadDurationInput;
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};


export type MutationUpdateFeedbackCommentArgs = {
  input: UpdateFeedbackCommentInput;
};


export type MutationUpdateInjuryArgs = {
  files?: Maybe<Array<Scalars['Upload']>>;
  input: UpdateInjuryInput;
};


export type MutationUpdateLicenseDateArgs = {
  input: UpdateLicenseInput;
};


export type MutationUpdateLineupArgs = {
  input: UpdateLineup;
};


export type MutationUpdateLineupScheduleArgs = {
  input: UpdateLineupSchedule;
};


export type MutationUpdateMatchPointArgs = {
  input: UpdateScheduleMatchPointInput;
};


export type MutationUpdateMatchResultArgs = {
  input: UpdateMatchResultInput;
};


export type MutationUpdateMatchResultScheduleArgs = {
  input: UpdateMatchResultScheduleInput;
};


export type MutationUpdateNoWorkoutArgs = {
  input: UpdateNoWorkoutInput;
};


export type MutationUpdateRoutineArgs = {
  input: UpdateInput;
};


export type MutationUpdateScheduleArgs = {
  fileList?: Maybe<Array<Scalars['Upload']>>;
  id: Scalars['ID'];
  input: ScheduleUpdateInput;
  teamId: Scalars['ID'];
};


export type MutationUpdateScheduleAttendanceStatusArgs = {
  input: UpdateScheduleAttendanceStatusInput;
};


export type MutationUpdateSpecialNoteArgs = {
  input: UpdateSpecialNoteInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationUpdateTeamGroupArgs = {
  input: UpdateTeamGroupInput;
};


export type MutationUpdateTrainingPointArgs = {
  input: UpdateTrainingPointInput;
};


export type MutationUpdateTrainingPointScheduleArgs = {
  input: UpdateTrainingPointScheduleInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateSportsUserInput;
};


export type MutationUpdateWorkloadArgs = {
  input: UpdateWorkloadInput;
};


export type MutationUploadBannerArgs = {
  file: Scalars['Upload'];
  input: UploadBannerInput;
};


export type MutationUploadCalendarArgs = {
  file: Scalars['Upload'];
  input: UploadCalendarInput;
};


export type MutationUploadCoordinatingReportArgs = {
  file: Scalars['Upload'];
  input: UploadCoordinatingReportInput;
};


export type MutationUploadProfileArgs = {
  file: Scalars['Upload'];
  teamId?: Maybe<Scalars['ID']>;
};


export type MutationUploadScheduleReportArgs = {
  file: Scalars['Upload'];
  input: UploadScheduleReportInput;
};


export type MutationUpsertScheduleAttendanceStatusArgs = {
  input: UpsertScheduleAttendanceStatusInput;
};

export enum NoWorkoutType {
  INJURY = 'INJURY',
  REST = 'REST'
}

export enum OsType {
  android = 'android',
  ios = 'ios',
  web = 'web'
}

export type Page = {
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PayInvoice = Invoice & {
  __typename: 'PayInvoice';
  amount: Scalars['Float'];
  billingUserEmail: Scalars['String'];
  billingUserName: Scalars['String'];
  billingUserPhone: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  id: Scalars['ID'];
  paidAt?: Maybe<Scalars['String']>;
  paymentName: Scalars['String'];
  paymentStatus: PaymentStatus;
  receiptUrl?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  FAIL = 'FAIL',
  PAY = 'PAY',
  REFUND = 'REFUND',
  SCHEDULED_CANCEL = 'SCHEDULED_CANCEL',
  SCHEDULED_PAY = 'SCHEDULED_PAY'
}

export type Plan = {
  __typename: 'Plan';
  discountEndAt?: Maybe<Scalars['Date']>;
  discountStartAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  limitCount?: Maybe<Scalars['Int']>;
  originalPriceStartAt?: Maybe<Scalars['Date']>;
  planName: Scalars['String'];
  planOptionInfoList?: Maybe<Array<Maybe<PlanOptionWithCoupon>>>;
  planType: Scalars['String'];
};

export type PlanOption = {
  __typename: 'PlanOption';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  id: Scalars['ID'];
  maxPlayerCount: Scalars['Float'];
  maxTeamCount: Scalars['Float'];
  nextPaymentDay: Scalars['Date'];
  period: Scalars['String'];
  planOptionName: Scalars['String'];
};

export type PlanOptionWithCoupon = {
  __typename: 'PlanOptionWithCoupon';
  currency: Scalars['String'];
  description: Scalars['String'];
  discountedPrice?: Maybe<Scalars['Int']>;
  duration: Scalars['Float'];
  id: Scalars['ID'];
  maxPlayerCount: Scalars['Float'];
  maxTeamCount: Scalars['Float'];
  nextPaymentDay: Scalars['Date'];
  originalPrice: Scalars['Int'];
  period: Scalars['String'];
  planOptionName: Scalars['String'];
};

export type PlanWithOption = {
  __typename: 'PlanWithOption';
  id: Scalars['ID'];
  planName: Scalars['String'];
  planOptionInfo: PlanOption;
  planType: Scalars['String'];
};

export enum Platform {
  APP = 'APP',
  WEB = 'WEB'
}

export type PlayerSchedule = {
  __typename: 'PlayerSchedule';
  scheduleList: Array<ScheduleInvitee>;
  upcomingScheduleList: Array<ScheduleInvitee>;
  user: SportsUser;
};


export type PlayerScheduleScheduleListArgs = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type PlayerScheduleUpcomingScheduleListArgs = {
  from: Scalars['DateTime'];
};

export type Qualification = {
  __typename: 'Qualification';
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  qualificationType: QualificationType;
};

export enum QualificationType {
  LICENSE = 'LICENSE',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export type Query = {
  __typename: 'Query';
  banner: Banner;
  bannerList: Array<Banner>;
  billing?: Maybe<Billing>;
  checkServerInspection: AccessAuthorization;
  coupon: CouponDetail;
  couponAppliedPrice: CouponApplied;
  couponIsGivenList: CouponIsGivenList;
  couponList: CouponListByPage;
  couponListByTeam: CouponListByTeam;
  eventForCoach: Event;
  feedback: Feedback;
  findFile: FileEntity;
  highestBenefitCouponByTeam?: Maybe<CouponSimple>;
  invoice: InvoiceDetail;
  invoicesByPage: InvoiceList;
  licenseById: License;
  licenseByTeam?: Maybe<License>;
  licenseListByPage: LicenseListByPage;
  multilingualText: MultilingualTextQuery;
  qualification: Qualification;
  routine?: Maybe<Routine>;
  routineList?: Maybe<Array<Routine>>;
  scheduleForCoach: Schedule;
  scheduleForPlayer: ScheduleInvitee;
  scheduleListForCoach: Array<Schedule>;
  serviceMeta: ServiceMeta;
  sports: SportsQuery;
  teamCheckReminderScheduleByPage: TeamCheckReminderScheduleByPage;
  teamPlanList: Array<Plan>;
  teamSubscriptionItem?: Maybe<SubscriptionItem>;
  totalBannerList: Array<Banner>;
};


export type QueryBannerArgs = {
  id: Scalars['ID'];
};


export type QueryBannerListArgs = {
  language: Language;
  nowDate: Scalars['Date'];
};


export type QueryBillingArgs = {
  identityToken: Scalars['ID'];
};


export type QueryCouponArgs = {
  id: Scalars['ID'];
};


export type QueryCouponAppliedPriceArgs = {
  input: GetCouponAppliedPriceInput;
};


export type QueryCouponIsGivenListArgs = {
  id: Scalars['ID'];
  input: CheckIsGivenTeamsInput;
};


export type QueryCouponListArgs = {
  isActivated: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
};


export type QueryCouponListByTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryEventForCoachArgs = {
  id: Scalars['ID'];
};


export type QueryFeedbackArgs = {
  id: Scalars['ID'];
};


export type QueryFindFileArgs = {
  id: Scalars['ID'];
};


export type QueryHighestBenefitCouponByTeamArgs = {
  originalPrice: Scalars['Float'];
  teamId: Scalars['ID'];
};


export type QueryInvoiceArgs = {
  paymentId: Scalars['ID'];
  subscriptionId: Scalars['ID'];
};


export type QueryInvoicesByPageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  subscriptionId: Scalars['ID'];
};


export type QueryLicenseByIdArgs = {
  licenseId: Scalars['ID'];
};


export type QueryLicenseByTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryLicenseListByPageArgs = {
  isActivate?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryMultilingualTextArgs = {
  input: MultilingualTextInput;
};


export type QueryQualificationArgs = {
  teamId: Scalars['ID'];
};


export type QueryRoutineArgs = {
  routineId: Scalars['ID'];
};


export type QueryRoutineListArgs = {
  cursor?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
};


export type QueryScheduleForCoachArgs = {
  id: Scalars['ID'];
};


export type QueryScheduleForPlayerArgs = {
  id: Scalars['ID'];
};


export type QueryScheduleListForCoachArgs = {
  input: GetCoachScheduleInput;
  teamId: Scalars['ID'];
};


export type QueryServiceMetaArgs = {
  appId: Scalars['String'];
  os: OsType;
  since: Scalars['String'];
};


export type QueryTeamCheckReminderScheduleByPageArgs = {
  filter?: Maybe<TeamCheckReminderScheduleFilter>;
  limit?: Scalars['Float'];
  offset?: Scalars['Float'];
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryTeamPlanListArgs = {
  couponId?: Maybe<Scalars['String']>;
};


export type QueryTeamSubscriptionItemArgs = {
  teamId: Scalars['ID'];
};


export type QueryTotalBannerListArgs = {
  isExpired?: Maybe<Scalars['Boolean']>;
  nowDate: Scalars['Date'];
};

export enum QueryOperation {
  eq = 'eq',
  ilike = 'ilike',
  in = 'in'
}

export type RawDataByCursor = {
  __typename: 'RawDataByCursor';
  cursor: Scalars['Date'];
  entries: Array<RawDataEntry>;
  names: Array<SportsRawDataName>;
  nextCursor: Scalars['Date'];
  stride: SportsDataStride;
  timezone: Scalars['String'];
};

export type RawDataEntry = SportsRawBody | SportsRawCondition | SportsRawInjury | SportsRawSleep | SportsRawWellness | SportsRawWorkload;

export type Refund = {
  amount?: Maybe<Scalars['Float']>;
  impUid: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
};

export type RefundInvoice = Invoice & {
  __typename: 'RefundInvoice';
  billingUserEmail: Scalars['String'];
  billingUserName: Scalars['String'];
  billingUserPhone: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  id: Scalars['ID'];
  paymentName: Scalars['String'];
  paymentStatus: PaymentStatus;
  receiptUrl?: Maybe<Scalars['String']>;
  refundAmount: Scalars['Float'];
  refundAt: Scalars['String'];
};

export type RefundResponse = {
  __typename: 'RefundResponse';
  reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RefundSubscriptionInput = {
  subscriptionId: Scalars['ID'];
};

export type Region = {
  __typename: 'Region';
  defaultLanguage?: Maybe<Scalars['String']>;
  defaultUTCOffset?: Maybe<Scalars['Int']>;
  id?: Maybe<CountryCode>;
};

export enum ReminderPushMessageType {
  CONDITION = 'CONDITION',
  EVENT_WORKLOAD = 'EVENT_WORKLOAD',
  SCHEDULE_WORKLOAD = 'SCHEDULE_WORKLOAD',
  WORKLOAD = 'WORKLOAD'
}

export type RemoveLicenseObserver = {
  licenseId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RemoveLicenseTeamInput = {
  licenseId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export enum ReopenStatus {
  DO_NOT_REOPEN = 'DO_NOT_REOPEN',
  REOPEN = 'REOPEN'
}

export enum ReportFileType {
  IMAGE = 'IMAGE',
  PDF = 'PDF'
}

export enum ReportType {
  COORDINATING_DAILY = 'COORDINATING_DAILY',
  COORDINATING_WEEKLY = 'COORDINATING_WEEKLY',
  EVENT_MATCH = 'EVENT_MATCH',
  EVENT_TRAINING = 'EVENT_TRAINING'
}

export type RequestBillingTokenInput = {
  identityToken: Scalars['ID'];
};

export type RestartSubscriptionInput = {
  buyerEmail: Scalars['String'];
  buyerName: Scalars['String'];
  buyerTel: Scalars['String'];
  couponId?: Maybe<Scalars['ID']>;
  planId: Scalars['ID'];
  planOptionId: Scalars['ID'];
  subscriptionId?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type ResumeSubscriptionInput = {
  subscriptionId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type RetryPayment = {
  subscriptionId: Scalars['ID'];
};

export type Return = {
  __typename: 'Return';
  date: Scalars['String'];
  info?: Maybe<Scalars['String']>;
  status: InjuryReturnStatus;
};

export type ReturnInput = {
  date: Scalars['DateTime'];
  /** 최대 100자 */
  info?: Maybe<Scalars['String']>;
  status: InjuryReturnStatus;
};

export type RiskAtInjuryUser = {
  __typename: 'RiskAtInjuryUser';
  analysisType: UserWorkloadSuggestionDataType;
  label: UserWorkloadRiskAtInjuryLabelType;
  reasonList: Array<UserWorkloadRiskAtInjuryReasonType>;
  user: SportsUser;
};

export type Routine = {
  __typename: 'Routine';
  activity?: Maybe<RoutineActivity>;
  id: Scalars['ID'];
  schedule?: Maybe<RoutineSchedule>;
  title: Scalars['String'];
};

export type RoutineActivity = {
  __typename: 'RoutineActivity';
  hasCompleted: Scalars['Boolean'];
  lastCompletedAt?: Maybe<Scalars['Date']>;
};

export enum RoutineActivityStatus {
  DONE = 'DONE',
  UNDONE = 'UNDONE'
}

export type RoutineSchedule = {
  __typename: 'RoutineSchedule';
  alertAt: Scalars['String'];
  dayNums: Array<Scalars['Int']>;
  id: Scalars['ID'];
  isAlertEnabled: Scalars['Boolean'];
};

export type RoutineToggle = {
  __typename: 'RoutineToggle';
  res: Scalars['Boolean'];
};

export type SatisfactionUser = {
  __typename: 'SatisfactionUser';
  satisfaction: Scalars['Float'];
  user: SportsUser;
};

export type SatisfactionUserId = {
  __typename: 'SatisfactionUserId';
  satisfaction: Scalars['Float'];
  userId: Scalars['ID'];
};

export type Schedule = {
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['Time']>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  fileList?: Maybe<Array<ScheduleFile>>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isPlayerNotice: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  scheduleDate: Scalars['String'];
  scheduleInviteeList: Array<ScheduleInvitee>;
  startTime?: Maybe<Scalars['Time']>;
  team?: Maybe<SportsTeam>;
  title?: Maybe<Scalars['String']>;
  trainingPoint?: Maybe<Scalars['String']>;
  type: ScheduleType;
};

export type ScheduleAlarm = Alarm & {
  __typename: 'ScheduleAlarm';
  createUser: SportsUser;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  player?: Maybe<SportsUser>;
  schedule: Schedule;
  scheduleCount?: Maybe<Scalars['Float']>;
  team?: Maybe<SportsTeam>;
  type: AlarmType;
};

export type ScheduleCreateInput = {
  competition?: Maybe<Scalars['String']>;
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['Time']>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  isPlayerNotice: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  scheduleDateList: Array<Scalars['DateTime']>;
  scheduleInviteIdentityList: Array<ScheduleInviteIdentityInput>;
  startTime?: Maybe<Scalars['Time']>;
  timezone: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: ScheduleType;
  venue?: Maybe<Venue>;
};

export enum ScheduleDetailType {
  COACH_SCHEDULE = 'COACH_SCHEDULE',
  COMPETITION_MATCH = 'COMPETITION_MATCH',
  ETC = 'ETC',
  FRIENDLY_MATCH = 'FRIENDLY_MATCH',
  GROUP_TRAINING = 'GROUP_TRAINING',
  INDIV_TRAINING = 'INDIV_TRAINING',
  LEAGUE_MATCH = 'LEAGUE_MATCH',
  PRACTICE_MATCH = 'PRACTICE_MATCH',
  REST = 'REST',
  TEAM_TRAINING = 'TEAM_TRAINING'
}

export type ScheduleFile = {
  __typename: 'ScheduleFile';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ScheduleInput = {
  alertAt: Scalars['String'];
  dayNums: Array<Scalars['Int']>;
  isAlertEnabled: Scalars['Boolean'];
};

export type ScheduleInviteIdentityInput = {
  id: Scalars['ID'];
};

export type ScheduleInvitee = {
  absenceReason?: Maybe<Scalars['String']>;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  schedule?: Maybe<Schedule>;
  status?: Maybe<ScheduleInviteeStatus>;
  team?: Maybe<SportsTeam>;
  /** the player who is invited to this schedule */
  user?: Maybe<SportsUser>;
  workoutType: WorkoutType;
};


export type ScheduleInviteeScheduleArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export enum ScheduleInviteeStatus {
  ABSENCE_ETC = 'ABSENCE_ETC',
  ABSENCE_INJURY = 'ABSENCE_INJURY',
  NOT_CHECK = 'NOT_CHECK',
  PARTICIPATION = 'PARTICIPATION'
}

export enum ScheduleReportType {
  MATCH = 'MATCH',
  TRAINING = 'TRAINING'
}

export enum ScheduleType {
  ETC = 'ETC',
  MATCH = 'MATCH',
  TRAINING = 'TRAINING'
}

export type ScheduleUpdateInput = {
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['Time']>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  isPlayerNotice: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  scheduleInviteIdentityList: Array<ScheduleInviteIdentityInput>;
  startTime?: Maybe<Scalars['Time']>;
  timezone: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: ScheduleType;
  venue?: Maybe<Venue>;
};

export type ServiceMeta = {
  __typename: 'ServiceMeta';
  forceUpdate: Scalars['Boolean'];
  higherVersionList: Array<VersionRevision>;
  latestVersion: Scalars['String'];
  versionRevisions: Array<VersionRevision>;
};


export type ServiceMetaVersionRevisionsArgs = {
  appId: Scalars['String'];
  os: OsType;
  since: Scalars['String'];
};

export enum SimpleOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Soreness = {
  __typename: 'Soreness';
  bodyPart: SportsBodyPart;
  isContact: Contact;
  level: Scalars['Float'];
  memo: Scalars['String'];
  streak: Scalars['Float'];
};


export type SorenessBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SorenessData = {
  __typename: 'SorenessData';
  isChecked: Scalars['Boolean'];
  sorenessList: Array<Soreness>;
};

export type SorenessInput = {
  bodyPart: SportsBodyPartName;
  isContact: Contact;
  level: Scalars['Float'];
  /** 최대 50자 */
  memo: Scalars['String'];
};

export type SorenessLevelData = {
  __typename: 'SorenessLevelData';
  count: Scalars['Float'];
  parts: Array<SorenessParts>;
};

export type SorenessParts = {
  __typename: 'SorenessParts';
  bodyPart: SportsBodyPart;
  bodyPartGroup: SportsBodyPartGroupName;
};


export type SorenessPartsBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SorenessSolution = {
  __typename: 'SorenessSolution';
  guide: Scalars['String'];
  type: SorenessSolutionType;
};

export enum SorenessSolutionType {
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE'
}

export type SorenessUser = {
  __typename: 'SorenessUser';
  sorenessList: Array<Soreness>;
  user: SportsUser;
};

export type SportsBodyPart = {
  __typename: 'SportsBodyPart';
  back: Scalars['Boolean'];
  front: Scalars['Boolean'];
  group: SportsBodyPartGroup;
  id: SportsBodyPartName;
  left: Scalars['Boolean'];
  right: Scalars['Boolean'];
  value: Scalars['String'];
};

export type SportsBodyPartGroup = {
  __typename: 'SportsBodyPartGroup';
  id: SportsBodyPartGroupName;
  parts: Array<SportsBodyPart>;
};


export type SportsBodyPartGroupPartsArgs = {
  input: MultilingualTextInput;
};

export enum SportsBodyPartGroupName {
  all_body_part_group__arm = 'all_body_part_group__arm',
  all_body_part_group__head = 'all_body_part_group__head',
  all_body_part_group__leg = 'all_body_part_group__leg',
  all_body_part_group__torso = 'all_body_part_group__torso'
}

export enum SportsBodyPartName {
  all_body_part__back_head = 'all_body_part__back_head',
  all_body_part__back_neck = 'all_body_part__back_neck',
  all_body_part__face = 'all_body_part__face',
  all_body_part__forehead = 'all_body_part__forehead',
  all_body_part__front_neck = 'all_body_part__front_neck',
  all_body_part__left_achilles_tendon = 'all_body_part__left_achilles_tendon',
  all_body_part__left_ankle = 'all_body_part__left_ankle',
  all_body_part__left_back_leg = 'all_body_part__left_back_leg',
  all_body_part__left_back_of_hand = 'all_body_part__left_back_of_hand',
  all_body_part__left_back_of_knee = 'all_body_part__left_back_of_knee',
  all_body_part__left_back_thigh = 'all_body_part__left_back_thigh',
  all_body_part__left_chest = 'all_body_part__left_chest',
  all_body_part__left_elbow = 'all_body_part__left_elbow',
  all_body_part__left_finger = 'all_body_part__left_finger',
  all_body_part__left_forearm = 'all_body_part__left_forearm',
  all_body_part__left_front_knee = 'all_body_part__left_front_knee',
  all_body_part__left_front_thigh = 'all_body_part__left_front_thigh',
  all_body_part__left_groin = 'all_body_part__left_groin',
  all_body_part__left_hip = 'all_body_part__left_hip',
  all_body_part__left_hip_joint = 'all_body_part__left_hip_joint',
  all_body_part__left_instep = 'all_body_part__left_instep',
  all_body_part__left_palm = 'all_body_part__left_palm',
  all_body_part__left_rib = 'all_body_part__left_rib',
  all_body_part__left_shin = 'all_body_part__left_shin',
  all_body_part__left_shoulder = 'all_body_part__left_shoulder',
  all_body_part__left_sole = 'all_body_part__left_sole',
  all_body_part__left_toe = 'all_body_part__left_toe',
  all_body_part__left_upper_arm = 'all_body_part__left_upper_arm',
  all_body_part__left_wing = 'all_body_part__left_wing',
  all_body_part__left_wrist = 'all_body_part__left_wrist',
  all_body_part__lower_abdomen = 'all_body_part__lower_abdomen',
  all_body_part__lower_back = 'all_body_part__lower_back',
  all_body_part__middle_back = 'all_body_part__middle_back',
  all_body_part__reproductive_organs = 'all_body_part__reproductive_organs',
  all_body_part__right_achilles_tendon = 'all_body_part__right_achilles_tendon',
  all_body_part__right_ankle = 'all_body_part__right_ankle',
  all_body_part__right_back_leg = 'all_body_part__right_back_leg',
  all_body_part__right_back_of_hand = 'all_body_part__right_back_of_hand',
  all_body_part__right_back_of_knee = 'all_body_part__right_back_of_knee',
  all_body_part__right_back_thigh = 'all_body_part__right_back_thigh',
  all_body_part__right_chest = 'all_body_part__right_chest',
  all_body_part__right_elbow = 'all_body_part__right_elbow',
  all_body_part__right_finger = 'all_body_part__right_finger',
  all_body_part__right_forearm = 'all_body_part__right_forearm',
  all_body_part__right_front_knee = 'all_body_part__right_front_knee',
  all_body_part__right_front_thigh = 'all_body_part__right_front_thigh',
  all_body_part__right_groin = 'all_body_part__right_groin',
  all_body_part__right_hip = 'all_body_part__right_hip',
  all_body_part__right_hip_joint = 'all_body_part__right_hip_joint',
  all_body_part__right_instep = 'all_body_part__right_instep',
  all_body_part__right_palm = 'all_body_part__right_palm',
  all_body_part__right_rib = 'all_body_part__right_rib',
  all_body_part__right_shin = 'all_body_part__right_shin',
  all_body_part__right_shoulder = 'all_body_part__right_shoulder',
  all_body_part__right_sole = 'all_body_part__right_sole',
  all_body_part__right_toe = 'all_body_part__right_toe',
  all_body_part__right_upper_arm = 'all_body_part__right_upper_arm',
  all_body_part__right_wing = 'all_body_part__right_wing',
  all_body_part__right_wrist = 'all_body_part__right_wrist',
  all_body_part__tailbone = 'all_body_part__tailbone',
  all_body_part__upper_abdomen = 'all_body_part__upper_abdomen',
  all_body_part__upper_back = 'all_body_part__upper_back'
}

export type SportsCareer = {
  __typename: 'SportsCareer';
  abstract?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  sportsCategory: SportsCategoryCollection;
  sportsPosition?: Maybe<SportsPosition>;
  sportsRole: SportsRole;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  teamName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type SportsCareerCreateInput = {
  abstract?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  sportsCategory: SportsCategory;
  sportsPosition?: Maybe<SportsPosition>;
  sportsRole: SportsRole;
  startAt: Scalars['DateTime'];
  teamId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type SportsCareerUpdateInput = {
  abstract?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  sportsCategory: SportsCategory;
  sportsPosition?: Maybe<SportsPosition>;
  sportsRole: SportsRole;
  startAt: Scalars['DateTime'];
  teamId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export enum SportsCategory {
  sports_category__3x3_basketball = 'sports_category__3x3_basketball',
  sports_category__aerobics = 'sports_category__aerobics',
  sports_category__alpine_skiing = 'sports_category__alpine_skiing',
  sports_category__american_football = 'sports_category__american_football',
  sports_category__archery = 'sports_category__archery',
  sports_category__arm_wrestling = 'sports_category__arm_wrestling',
  sports_category__artistic_gymnastics = 'sports_category__artistic_gymnastics',
  sports_category__artistic_swimming = 'sports_category__artistic_swimming',
  sports_category__badminton = 'sports_category__badminton',
  sports_category__baseball = 'sports_category__baseball',
  sports_category__basketball = 'sports_category__basketball',
  sports_category__biathlon = 'sports_category__biathlon',
  sports_category__billiards = 'sports_category__billiards',
  sports_category__bmx_freestyle = 'sports_category__bmx_freestyle',
  sports_category__bmx_racing = 'sports_category__bmx_racing',
  sports_category__bobsleigh = 'sports_category__bobsleigh',
  sports_category__boccia = 'sports_category__boccia',
  sports_category__bowling = 'sports_category__bowling',
  sports_category__boxing = 'sports_category__boxing',
  sports_category__breaking = 'sports_category__breaking',
  sports_category__canoe = 'sports_category__canoe',
  sports_category__cricket = 'sports_category__cricket',
  sports_category__cross_country_skiing = 'sports_category__cross_country_skiing',
  sports_category__curling = 'sports_category__curling',
  sports_category__cycling_mtb = 'sports_category__cycling_mtb',
  sports_category__cycling_road = 'sports_category__cycling_road',
  sports_category__cycling_track = 'sports_category__cycling_track',
  sports_category__decathlon = 'sports_category__decathlon',
  sports_category__discus_throw = 'sports_category__discus_throw',
  sports_category__diving = 'sports_category__diving',
  sports_category__dressage = 'sports_category__dressage',
  sports_category__etc = 'sports_category__etc',
  sports_category__eventing = 'sports_category__eventing',
  sports_category__fencing = 'sports_category__fencing',
  sports_category__field_hockey = 'sports_category__field_hockey',
  sports_category__fin_swimming = 'sports_category__fin_swimming',
  sports_category__foot_volleyball = 'sports_category__foot_volleyball',
  sports_category__freestyle_skiing = 'sports_category__freestyle_skiing',
  sports_category__futsal = 'sports_category__futsal',
  sports_category__golf = 'sports_category__golf',
  sports_category__hammer_throw = 'sports_category__hammer_throw',
  sports_category__handball = 'sports_category__handball',
  sports_category__heptathlon = 'sports_category__heptathlon',
  sports_category__high_jump = 'sports_category__high_jump',
  sports_category__hurdle = 'sports_category__hurdle',
  sports_category__ice_hockey = 'sports_category__ice_hockey',
  sports_category__ice_skating = 'sports_category__ice_skating',
  sports_category__javelin_throw = 'sports_category__javelin_throw',
  sports_category__judo = 'sports_category__judo',
  sports_category__karate = 'sports_category__karate',
  sports_category__kayak = 'sports_category__kayak',
  sports_category__kickboxing = 'sports_category__kickboxing',
  sports_category__kung_fu = 'sports_category__kung_fu',
  sports_category__lacrosse = 'sports_category__lacrosse',
  sports_category__long_distance_running = 'sports_category__long_distance_running',
  sports_category__long_jump = 'sports_category__long_jump',
  sports_category__luge = 'sports_category__luge',
  sports_category__marathon = 'sports_category__marathon',
  sports_category__marathon_swimming = 'sports_category__marathon_swimming',
  sports_category__middle_distance_running = 'sports_category__middle_distance_running',
  sports_category__modern_pentathlon = 'sports_category__modern_pentathlon',
  sports_category__nordic_skiing = 'sports_category__nordic_skiing',
  sports_category__pole_vault = 'sports_category__pole_vault',
  sports_category__race_walking = 'sports_category__race_walking',
  sports_category__racquetball = 'sports_category__racquetball',
  sports_category__rhythmic_gymnastics = 'sports_category__rhythmic_gymnastics',
  sports_category__roller = 'sports_category__roller',
  sports_category__rowing = 'sports_category__rowing',
  sports_category__rugby = 'sports_category__rugby',
  sports_category__rugby_sevens = 'sports_category__rugby_sevens',
  sports_category__sepak_takraw = 'sports_category__sepak_takraw',
  sports_category__shooting = 'sports_category__shooting',
  sports_category__shot_put = 'sports_category__shot_put',
  sports_category__show_jumping = 'sports_category__show_jumping',
  sports_category__skateboard = 'sports_category__skateboard',
  sports_category__skeleton = 'sports_category__skeleton',
  sports_category__ski_jumping = 'sports_category__ski_jumping',
  sports_category__ski_mountaineering = 'sports_category__ski_mountaineering',
  sports_category__snowboarding = 'sports_category__snowboarding',
  sports_category__soccer = 'sports_category__soccer',
  sports_category__soft_tennis = 'sports_category__soft_tennis',
  sports_category__softball = 'sports_category__softball',
  sports_category__sports_climbing = 'sports_category__sports_climbing',
  sports_category__sprint = 'sports_category__sprint',
  sports_category__squash = 'sports_category__squash',
  sports_category__ssireum = 'sports_category__ssireum',
  sports_category__surfing = 'sports_category__surfing',
  sports_category__swimming = 'sports_category__swimming',
  sports_category__table_tennis = 'sports_category__table_tennis',
  sports_category__taekwondo = 'sports_category__taekwondo',
  sports_category__takkyeon = 'sports_category__takkyeon',
  sports_category__tee_ball = 'sports_category__tee_ball',
  sports_category__tennis = 'sports_category__tennis',
  sports_category__trampoline = 'sports_category__trampoline',
  sports_category__triathlon = 'sports_category__triathlon',
  sports_category__triple_jump = 'sports_category__triple_jump',
  sports_category__volleyball = 'sports_category__volleyball',
  sports_category__water_polo = 'sports_category__water_polo',
  sports_category__water_skiing = 'sports_category__water_skiing',
  sports_category__weightlifting = 'sports_category__weightlifting',
  sports_category__wrestling = 'sports_category__wrestling',
  sports_category__yacht = 'sports_category__yacht'
}

export type SportsCategoryCollection = {
  __typename: 'SportsCategoryCollection';
  coachPositions: Array<SportsPosition>;
  group?: Maybe<SportsCategoryGroup>;
  id?: Maybe<SportsCategory>;
  playerPositions: Array<SportsPosition>;
};

export enum SportsCategoryGroup {
  sports_category_group__archery = 'sports_category_group__archery',
  sports_category_group__athletics = 'sports_category_group__athletics',
  sports_category_group__ball = 'sports_category_group__ball',
  sports_category_group__combat = 'sports_category_group__combat',
  sports_category_group__cycling = 'sports_category_group__cycling',
  sports_category_group__equestrian = 'sports_category_group__equestrian',
  sports_category_group__etc = 'sports_category_group__etc',
  sports_category_group__fencing = 'sports_category_group__fencing',
  sports_category_group__gymnastics = 'sports_category_group__gymnastics',
  sports_category_group__modern_pentathlon = 'sports_category_group__modern_pentathlon',
  sports_category_group__racket = 'sports_category_group__racket',
  sports_category_group__roller = 'sports_category_group__roller',
  sports_category_group__shooting = 'sports_category_group__shooting',
  sports_category_group__triathlon = 'sports_category_group__triathlon',
  sports_category_group__water = 'sports_category_group__water',
  sports_category_group__weightlifting = 'sports_category_group__weightlifting',
  sports_category_group__winter = 'sports_category_group__winter'
}

export type SportsCategoryGroupCollection = {
  __typename: 'SportsCategoryGroupCollection';
  categories: Array<SportsCategoryCollection>;
  id: SportsCategoryGroup;
};

export type SportsData = {
  __typename: 'SportsData';
  body?: Maybe<BodyData>;
  condition?: Maybe<Condition>;
  date: Scalars['DateTimeString'];
  id?: Maybe<Scalars['ID']>;
  soreness: SorenessData;
  user: SportsUser;
  workload?: Maybe<WorkloadData>;
};


export type SportsDataBodyArgs = {
  heightUnit?: HeightUnit;
  weightUnit?: WeightUnit;
};

export type SportsDataEntry = {
  __typename: 'SportsDataEntry';
  time: Scalars['DateTimeString'];
  value?: Maybe<Scalars['JSON']>;
};

export enum SportsDataGroup {
  BODY = 'BODY',
  CONDITION = 'CONDITION',
  INJURY = 'INJURY',
  READINESS = 'READINESS',
  WORKLOAD = 'WORKLOAD'
}

export enum SportsDataName {
  BODY = 'BODY',
  BODY_MONTHLY_STAT = 'BODY_MONTHLY_STAT',
  BODY_WEEKLY_STAT = 'BODY_WEEKLY_STAT',
  CONDITION = 'CONDITION',
  CONDITION_AX = 'CONDITION_AX',
  CONDITION_CX = 'CONDITION_CX',
  CONDITION_MONTHLY_AVG = 'CONDITION_MONTHLY_AVG',
  CONDITION_ROLLING_AVG_7DAY = 'CONDITION_ROLLING_AVG_7DAY',
  CONDITION_ROLLING_AVG_28DAY = 'CONDITION_ROLLING_AVG_28DAY',
  CONDITION_WEEKLY_AVG = 'CONDITION_WEEKLY_AVG',
  INJURY = 'INJURY',
  RAW_BODY = 'RAW_BODY',
  RAW_INJURY = 'RAW_INJURY',
  RAW_WORKLOAD = 'RAW_WORKLOAD',
  READINESS = 'READINESS',
  SLEEP_MONTHLY_STAT = 'SLEEP_MONTHLY_STAT',
  SLEEP_ROLLING_AVG_7DAY = 'SLEEP_ROLLING_AVG_7DAY',
  SLEEP_ROLLING_AVG_28DAY = 'SLEEP_ROLLING_AVG_28DAY',
  SLEEP_WEEKLY_STAT = 'SLEEP_WEEKLY_STAT',
  SORENESS = 'SORENESS',
  WELLNESS_MONTHLY_STAT = 'WELLNESS_MONTHLY_STAT',
  WELLNESS_ROLLING_AVG_7DAY = 'WELLNESS_ROLLING_AVG_7DAY',
  WELLNESS_ROLLING_AVG_28DAY = 'WELLNESS_ROLLING_AVG_28DAY',
  WELLNESS_WEEKLY_STAT = 'WELLNESS_WEEKLY_STAT',
  WORKLOAD = 'WORKLOAD',
  WORKLOAD_ACXR = 'WORKLOAD_ACXR',
  WORKLOAD_ACXRAXD_ROLLING_AVG_7DAY = 'WORKLOAD_ACXRAXD_ROLLING_AVG_7DAY',
  WORKLOAD_ACXRAXD_ROLLING_AVG_28DAY = 'WORKLOAD_ACXRAXD_ROLLING_AVG_28DAY',
  WORKLOAD_AX = 'WORKLOAD_AX',
  WORKLOAD_AXD = 'WORKLOAD_AXD',
  WORKLOAD_AXI = 'WORKLOAD_AXI',
  WORKLOAD_CX = 'WORKLOAD_CX',
  WORKLOAD_MONTHLY_STAT = 'WORKLOAD_MONTHLY_STAT',
  WORKLOAD_ROLLING_AVG_7DAY = 'WORKLOAD_ROLLING_AVG_7DAY',
  WORKLOAD_ROLLING_AVG_28DAY = 'WORKLOAD_ROLLING_AVG_28DAY',
  WORKLOAD_WEEKLY_STAT = 'WORKLOAD_WEEKLY_STAT',
  WORKLOAD_X = 'WORKLOAD_X'
}

export type SportsDataRange = {
  __typename: 'SportsDataRange';
  entries: Array<SportsDataEntry>;
  from: Scalars['DateTimeString'];
  to: Scalars['DateTimeString'];
};

export enum SportsDataStride {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}

export enum SportsInjuryQuality {
  BURNING = 'BURNING',
  COMES_AND_GOES = 'COMES_AND_GOES',
  CRAMPING = 'CRAMPING',
  CUTTING = 'CUTTING',
  DULL = 'DULL',
  NUMBING = 'NUMBING',
  PRESSING = 'PRESSING',
  RADIATING = 'RADIATING',
  SHARP = 'SHARP',
  TINGLING = 'TINGLING'
}

export enum SportsInjuryTiming {
  GRADUAL = 'GRADUAL',
  OCCASIONAL = 'OCCASIONAL',
  SUDDEN = 'SUDDEN',
  SUSTAINING = 'SUSTAINING'
}

export enum SportsPosition {
  coach_position__3x3_basketball__assistant_coach = 'coach_position__3x3_basketball__assistant_coach',
  coach_position__3x3_basketball__athletic_trainer = 'coach_position__3x3_basketball__athletic_trainer',
  coach_position__3x3_basketball__coach = 'coach_position__3x3_basketball__coach',
  coach_position__3x3_basketball__head_coach = 'coach_position__3x3_basketball__head_coach',
  coach_position__3x3_basketball__playing_coach = 'coach_position__3x3_basketball__playing_coach',
  coach_position__aerobics__athletic_trainer = 'coach_position__aerobics__athletic_trainer',
  coach_position__aerobics__coach = 'coach_position__aerobics__coach',
  coach_position__aerobics__first_coach = 'coach_position__aerobics__first_coach',
  coach_position__aerobics__head_coach = 'coach_position__aerobics__head_coach',
  coach_position__aerobics__playing_coach = 'coach_position__aerobics__playing_coach',
  coach_position__alpine_skiing__athletic_trainer = 'coach_position__alpine_skiing__athletic_trainer',
  coach_position__alpine_skiing__coach = 'coach_position__alpine_skiing__coach',
  coach_position__alpine_skiing__first_coach = 'coach_position__alpine_skiing__first_coach',
  coach_position__alpine_skiing__head_coach = 'coach_position__alpine_skiing__head_coach',
  coach_position__alpine_skiing__playing_coach = 'coach_position__alpine_skiing__playing_coach',
  coach_position__american_football__assistant_coach = 'coach_position__american_football__assistant_coach',
  coach_position__american_football__athletic_trainer = 'coach_position__american_football__athletic_trainer',
  coach_position__american_football__coach = 'coach_position__american_football__coach',
  coach_position__american_football__defensive_coach = 'coach_position__american_football__defensive_coach',
  coach_position__american_football__fitness_coach = 'coach_position__american_football__fitness_coach',
  coach_position__american_football__head_coach = 'coach_position__american_football__head_coach',
  coach_position__american_football__offensive_coach = 'coach_position__american_football__offensive_coach',
  coach_position__american_football__playing_coach = 'coach_position__american_football__playing_coach',
  coach_position__american_football__scrum_coach = 'coach_position__american_football__scrum_coach',
  coach_position__american_football__skill_coach = 'coach_position__american_football__skill_coach',
  coach_position__american_football__strength_and_conditioning_coach = 'coach_position__american_football__strength_and_conditioning_coach',
  coach_position__archery__assistant_coach = 'coach_position__archery__assistant_coach',
  coach_position__archery__athletic_trainer = 'coach_position__archery__athletic_trainer',
  coach_position__archery__coach = 'coach_position__archery__coach',
  coach_position__archery__head_coach = 'coach_position__archery__head_coach',
  coach_position__archery__playing_coach = 'coach_position__archery__playing_coach',
  coach_position__arm_wrestling__athletic_trainer = 'coach_position__arm_wrestling__athletic_trainer',
  coach_position__arm_wrestling__coach = 'coach_position__arm_wrestling__coach',
  coach_position__arm_wrestling__first_coach = 'coach_position__arm_wrestling__first_coach',
  coach_position__arm_wrestling__head_coach = 'coach_position__arm_wrestling__head_coach',
  coach_position__arm_wrestling__playing_coach = 'coach_position__arm_wrestling__playing_coach',
  coach_position__artistic_gymnastics__athletic_trainer = 'coach_position__artistic_gymnastics__athletic_trainer',
  coach_position__artistic_gymnastics__coach = 'coach_position__artistic_gymnastics__coach',
  coach_position__artistic_gymnastics__first_coach = 'coach_position__artistic_gymnastics__first_coach',
  coach_position__artistic_gymnastics__head_coach = 'coach_position__artistic_gymnastics__head_coach',
  coach_position__artistic_gymnastics__playing_coach = 'coach_position__artistic_gymnastics__playing_coach',
  coach_position__artistic_swimming__athletic_trainer = 'coach_position__artistic_swimming__athletic_trainer',
  coach_position__artistic_swimming__coach = 'coach_position__artistic_swimming__coach',
  coach_position__artistic_swimming__first_coach = 'coach_position__artistic_swimming__first_coach',
  coach_position__artistic_swimming__head_coach = 'coach_position__artistic_swimming__head_coach',
  coach_position__badminton__assistant_coach = 'coach_position__badminton__assistant_coach',
  coach_position__badminton__athletic_trainer = 'coach_position__badminton__athletic_trainer',
  coach_position__badminton__coach = 'coach_position__badminton__coach',
  coach_position__badminton__head_coach = 'coach_position__badminton__head_coach',
  coach_position__baseball__athletic_trainer = 'coach_position__baseball__athletic_trainer',
  coach_position__baseball__base_coach = 'coach_position__baseball__base_coach',
  coach_position__baseball__bench_coach = 'coach_position__baseball__bench_coach',
  coach_position__baseball__coach = 'coach_position__baseball__coach',
  coach_position__baseball__defensive_coach = 'coach_position__baseball__defensive_coach',
  coach_position__baseball__head_coach = 'coach_position__baseball__head_coach',
  coach_position__baseball__hitting_coach = 'coach_position__baseball__hitting_coach',
  coach_position__baseball__pitching_coach = 'coach_position__baseball__pitching_coach',
  coach_position__baseball__playing_coach = 'coach_position__baseball__playing_coach',
  coach_position__basketball__assistant_coach = 'coach_position__basketball__assistant_coach',
  coach_position__basketball__athletic_trainer = 'coach_position__basketball__athletic_trainer',
  coach_position__basketball__coach = 'coach_position__basketball__coach',
  coach_position__basketball__first_coach = 'coach_position__basketball__first_coach',
  coach_position__basketball__head_coach = 'coach_position__basketball__head_coach',
  coach_position__basketball__playing_coach = 'coach_position__basketball__playing_coach',
  coach_position__biathlon__athletic_trainer = 'coach_position__biathlon__athletic_trainer',
  coach_position__biathlon__coach = 'coach_position__biathlon__coach',
  coach_position__biathlon__first_coach = 'coach_position__biathlon__first_coach',
  coach_position__biathlon__head_coach = 'coach_position__biathlon__head_coach',
  coach_position__biathlon__playing_coach = 'coach_position__biathlon__playing_coach',
  coach_position__billiards__athletic_trainer = 'coach_position__billiards__athletic_trainer',
  coach_position__billiards__coach = 'coach_position__billiards__coach',
  coach_position__bmx_freestyle__assistant_coach = 'coach_position__bmx_freestyle__assistant_coach',
  coach_position__bmx_freestyle__athletic_trainer = 'coach_position__bmx_freestyle__athletic_trainer',
  coach_position__bmx_freestyle__coach = 'coach_position__bmx_freestyle__coach',
  coach_position__bmx_freestyle__head_coach = 'coach_position__bmx_freestyle__head_coach',
  coach_position__bmx_freestyle__playing_coach = 'coach_position__bmx_freestyle__playing_coach',
  coach_position__bmx_racing__assistant_coach = 'coach_position__bmx_racing__assistant_coach',
  coach_position__bmx_racing__athletic_trainer = 'coach_position__bmx_racing__athletic_trainer',
  coach_position__bmx_racing__coach = 'coach_position__bmx_racing__coach',
  coach_position__bmx_racing__head_coach = 'coach_position__bmx_racing__head_coach',
  coach_position__bmx_racing__playing_coach = 'coach_position__bmx_racing__playing_coach',
  coach_position__bobsleigh__athletic_trainer = 'coach_position__bobsleigh__athletic_trainer',
  coach_position__bobsleigh__coach = 'coach_position__bobsleigh__coach',
  coach_position__bobsleigh__first_coach = 'coach_position__bobsleigh__first_coach',
  coach_position__bobsleigh__head_coach = 'coach_position__bobsleigh__head_coach',
  coach_position__bobsleigh__playing_coach = 'coach_position__bobsleigh__playing_coach',
  coach_position__boccia__athletic_trainer = 'coach_position__boccia__athletic_trainer',
  coach_position__boccia__coach = 'coach_position__boccia__coach',
  coach_position__boccia__first_coach = 'coach_position__boccia__first_coach',
  coach_position__boccia__head_coach = 'coach_position__boccia__head_coach',
  coach_position__boccia__playing_coach = 'coach_position__boccia__playing_coach',
  coach_position__bowling__athletic_trainer = 'coach_position__bowling__athletic_trainer',
  coach_position__bowling__coach = 'coach_position__bowling__coach',
  coach_position__bowling__first_coach = 'coach_position__bowling__first_coach',
  coach_position__bowling__head_coach = 'coach_position__bowling__head_coach',
  coach_position__bowling__playing_coach = 'coach_position__bowling__playing_coach',
  coach_position__boxing__athletic_trainer = 'coach_position__boxing__athletic_trainer',
  coach_position__boxing__coach = 'coach_position__boxing__coach',
  coach_position__boxing__first_coach = 'coach_position__boxing__first_coach',
  coach_position__boxing__head_coach = 'coach_position__boxing__head_coach',
  coach_position__boxing__playing_coach = 'coach_position__boxing__playing_coach',
  coach_position__breaking__athletic_trainer = 'coach_position__breaking__athletic_trainer',
  coach_position__breaking__coach = 'coach_position__breaking__coach',
  coach_position__breaking__head_coach = 'coach_position__breaking__head_coach',
  coach_position__breaking__playing_coach = 'coach_position__breaking__playing_coach',
  coach_position__canoe__athletic_trainer = 'coach_position__canoe__athletic_trainer',
  coach_position__canoe__coach = 'coach_position__canoe__coach',
  coach_position__canoe__first_coach = 'coach_position__canoe__first_coach',
  coach_position__canoe__head_coach = 'coach_position__canoe__head_coach',
  coach_position__cricket__athletic_trainer = 'coach_position__cricket__athletic_trainer',
  coach_position__cricket__coach = 'coach_position__cricket__coach',
  coach_position__cricket__first_coach = 'coach_position__cricket__first_coach',
  coach_position__cricket__head_coach = 'coach_position__cricket__head_coach',
  coach_position__cricket__playing_coach = 'coach_position__cricket__playing_coach',
  coach_position__cross_country_skiing__athletic_trainer = 'coach_position__cross_country_skiing__athletic_trainer',
  coach_position__cross_country_skiing__coach = 'coach_position__cross_country_skiing__coach',
  coach_position__cross_country_skiing__first_coach = 'coach_position__cross_country_skiing__first_coach',
  coach_position__cross_country_skiing__head_coach = 'coach_position__cross_country_skiing__head_coach',
  coach_position__cross_country_skiing__playing_coach = 'coach_position__cross_country_skiing__playing_coach',
  coach_position__curling__athletic_trainer = 'coach_position__curling__athletic_trainer',
  coach_position__curling__coach = 'coach_position__curling__coach',
  coach_position__curling__first_coach = 'coach_position__curling__first_coach',
  coach_position__curling__head_coach = 'coach_position__curling__head_coach',
  coach_position__curling__playing_coach = 'coach_position__curling__playing_coach',
  coach_position__cycling_mtb__assistant_coach = 'coach_position__cycling_mtb__assistant_coach',
  coach_position__cycling_mtb__athletic_trainer = 'coach_position__cycling_mtb__athletic_trainer',
  coach_position__cycling_mtb__coach = 'coach_position__cycling_mtb__coach',
  coach_position__cycling_mtb__head_coach = 'coach_position__cycling_mtb__head_coach',
  coach_position__cycling_mtb__playing_coach = 'coach_position__cycling_mtb__playing_coach',
  coach_position__cycling_road__assistant_coach = 'coach_position__cycling_road__assistant_coach',
  coach_position__cycling_road__athletic_trainer = 'coach_position__cycling_road__athletic_trainer',
  coach_position__cycling_road__coach = 'coach_position__cycling_road__coach',
  coach_position__cycling_road__head_coach = 'coach_position__cycling_road__head_coach',
  coach_position__cycling_road__playing_coach = 'coach_position__cycling_road__playing_coach',
  coach_position__cycling_track__assistant_coach = 'coach_position__cycling_track__assistant_coach',
  coach_position__cycling_track__athletic_trainer = 'coach_position__cycling_track__athletic_trainer',
  coach_position__cycling_track__coach = 'coach_position__cycling_track__coach',
  coach_position__cycling_track__head_coach = 'coach_position__cycling_track__head_coach',
  coach_position__cycling_track__playing_coach = 'coach_position__cycling_track__playing_coach',
  coach_position__discus_throw__athletic_trainer = 'coach_position__discus_throw__athletic_trainer',
  coach_position__discus_throw__coach = 'coach_position__discus_throw__coach',
  coach_position__discus_throw__first_coach = 'coach_position__discus_throw__first_coach',
  coach_position__discus_throw__head_coach = 'coach_position__discus_throw__head_coach',
  coach_position__discus_throw__playing_coach = 'coach_position__discus_throw__playing_coach',
  coach_position__diving__athletic_trainer = 'coach_position__diving__athletic_trainer',
  coach_position__diving__coach = 'coach_position__diving__coach',
  coach_position__diving__first_coach = 'coach_position__diving__first_coach',
  coach_position__diving__head_coach = 'coach_position__diving__head_coach',
  coach_position__dressage__assistant_coach = 'coach_position__dressage__assistant_coach',
  coach_position__dressage__athletic_trainer = 'coach_position__dressage__athletic_trainer',
  coach_position__dressage__coach = 'coach_position__dressage__coach',
  coach_position__dressage__head_coach = 'coach_position__dressage__head_coach',
  coach_position__dressage__playing_coach = 'coach_position__dressage__playing_coach',
  coach_position__etc__athletic_trainer = 'coach_position__etc__athletic_trainer',
  coach_position__etc__coach = 'coach_position__etc__coach',
  coach_position__etc__head_coach = 'coach_position__etc__head_coach',
  coach_position__etc__playing_coach = 'coach_position__etc__playing_coach',
  coach_position__eventing__assistant_coach = 'coach_position__eventing__assistant_coach',
  coach_position__eventing__athletic_trainer = 'coach_position__eventing__athletic_trainer',
  coach_position__eventing__coach = 'coach_position__eventing__coach',
  coach_position__eventing__head_coach = 'coach_position__eventing__head_coach',
  coach_position__eventing__playing_coach = 'coach_position__eventing__playing_coach',
  coach_position__fencing__athletic_trainer = 'coach_position__fencing__athletic_trainer',
  coach_position__fencing__coach = 'coach_position__fencing__coach',
  coach_position__fencing__first_coach = 'coach_position__fencing__first_coach',
  coach_position__fencing__head_coach = 'coach_position__fencing__head_coach',
  coach_position__fencing__playing_coach = 'coach_position__fencing__playing_coach',
  coach_position__field_hockey__assistant_coach = 'coach_position__field_hockey__assistant_coach',
  coach_position__field_hockey__athletic_trainer = 'coach_position__field_hockey__athletic_trainer',
  coach_position__field_hockey__coach = 'coach_position__field_hockey__coach',
  coach_position__field_hockey__gk_coach = 'coach_position__field_hockey__gk_coach',
  coach_position__field_hockey__head_coach = 'coach_position__field_hockey__head_coach',
  coach_position__field_hockey__playing_coach = 'coach_position__field_hockey__playing_coach',
  coach_position__fin_swimming__athletic_trainer = 'coach_position__fin_swimming__athletic_trainer',
  coach_position__fin_swimming__coach = 'coach_position__fin_swimming__coach',
  coach_position__fin_swimming__first_coach = 'coach_position__fin_swimming__first_coach',
  coach_position__fin_swimming__head_coach = 'coach_position__fin_swimming__head_coach',
  coach_position__foot_volleyball__athletic_trainer = 'coach_position__foot_volleyball__athletic_trainer',
  coach_position__foot_volleyball__coach = 'coach_position__foot_volleyball__coach',
  coach_position__foot_volleyball__first_coach = 'coach_position__foot_volleyball__first_coach',
  coach_position__foot_volleyball__head_coach = 'coach_position__foot_volleyball__head_coach',
  coach_position__foot_volleyball__playing_coach = 'coach_position__foot_volleyball__playing_coach',
  coach_position__freestyle_skiing__athletic_trainer = 'coach_position__freestyle_skiing__athletic_trainer',
  coach_position__freestyle_skiing__coach = 'coach_position__freestyle_skiing__coach',
  coach_position__freestyle_skiing__first_coach = 'coach_position__freestyle_skiing__first_coach',
  coach_position__freestyle_skiing__head_coach = 'coach_position__freestyle_skiing__head_coach',
  coach_position__freestyle_skiing__playing_coach = 'coach_position__freestyle_skiing__playing_coach',
  coach_position__futsal__athletic_trainer = 'coach_position__futsal__athletic_trainer',
  coach_position__futsal__coach = 'coach_position__futsal__coach',
  coach_position__futsal__first_coach = 'coach_position__futsal__first_coach',
  coach_position__futsal__head_coach = 'coach_position__futsal__head_coach',
  coach_position__futsal__playing_coach = 'coach_position__futsal__playing_coach',
  coach_position__golf__athletic_trainer = 'coach_position__golf__athletic_trainer',
  coach_position__golf__coach = 'coach_position__golf__coach',
  coach_position__hammer_throw__athletic_trainer = 'coach_position__hammer_throw__athletic_trainer',
  coach_position__hammer_throw__coach = 'coach_position__hammer_throw__coach',
  coach_position__hammer_throw__first_coach = 'coach_position__hammer_throw__first_coach',
  coach_position__hammer_throw__head_coach = 'coach_position__hammer_throw__head_coach',
  coach_position__hammer_throw__playing_coach = 'coach_position__hammer_throw__playing_coach',
  coach_position__handball__assistant_coach = 'coach_position__handball__assistant_coach',
  coach_position__handball__athletic_trainer = 'coach_position__handball__athletic_trainer',
  coach_position__handball__coach = 'coach_position__handball__coach',
  coach_position__handball__first_coach = 'coach_position__handball__first_coach',
  coach_position__handball__head_coach = 'coach_position__handball__head_coach',
  coach_position__handball__playing_coach = 'coach_position__handball__playing_coach',
  coach_position__high_jump__athletic_trainer = 'coach_position__high_jump__athletic_trainer',
  coach_position__high_jump__coach = 'coach_position__high_jump__coach',
  coach_position__high_jump__first_coach = 'coach_position__high_jump__first_coach',
  coach_position__high_jump__head_coach = 'coach_position__high_jump__head_coach',
  coach_position__high_jump__playing_coach = 'coach_position__high_jump__playing_coach',
  coach_position__hurdle__athletic_trainer = 'coach_position__hurdle__athletic_trainer',
  coach_position__hurdle__coach = 'coach_position__hurdle__coach',
  coach_position__hurdle__first_coach = 'coach_position__hurdle__first_coach',
  coach_position__hurdle__head_coach = 'coach_position__hurdle__head_coach',
  coach_position__hurdle__playing_coach = 'coach_position__hurdle__playing_coach',
  coach_position__ice_hockey__assistant_coach = 'coach_position__ice_hockey__assistant_coach',
  coach_position__ice_hockey__athletic_trainer = 'coach_position__ice_hockey__athletic_trainer',
  coach_position__ice_hockey__coach = 'coach_position__ice_hockey__coach',
  coach_position__ice_hockey__gk_coach = 'coach_position__ice_hockey__gk_coach',
  coach_position__ice_hockey__head_coach = 'coach_position__ice_hockey__head_coach',
  coach_position__ice_hockey__playing_coach = 'coach_position__ice_hockey__playing_coach',
  coach_position__ice_skating__athletic_trainer = 'coach_position__ice_skating__athletic_trainer',
  coach_position__ice_skating__coach = 'coach_position__ice_skating__coach',
  coach_position__ice_skating__first_coach = 'coach_position__ice_skating__first_coach',
  coach_position__ice_skating__head_coach = 'coach_position__ice_skating__head_coach',
  coach_position__ice_skating__playing_coach = 'coach_position__ice_skating__playing_coach',
  coach_position__javelin_throw__athletic_trainer = 'coach_position__javelin_throw__athletic_trainer',
  coach_position__javelin_throw__coach = 'coach_position__javelin_throw__coach',
  coach_position__javelin_throw__first_coach = 'coach_position__javelin_throw__first_coach',
  coach_position__javelin_throw__head_coach = 'coach_position__javelin_throw__head_coach',
  coach_position__javelin_throw__playing_coach = 'coach_position__javelin_throw__playing_coach',
  coach_position__judo__athletic_trainer = 'coach_position__judo__athletic_trainer',
  coach_position__judo__coach = 'coach_position__judo__coach',
  coach_position__judo__first_coach = 'coach_position__judo__first_coach',
  coach_position__judo__head_coach = 'coach_position__judo__head_coach',
  coach_position__judo__playing_coach = 'coach_position__judo__playing_coach',
  coach_position__karate__athletic_trainer = 'coach_position__karate__athletic_trainer',
  coach_position__karate__coach = 'coach_position__karate__coach',
  coach_position__karate__first_coach = 'coach_position__karate__first_coach',
  coach_position__karate__head_coach = 'coach_position__karate__head_coach',
  coach_position__karate__playing_coach = 'coach_position__karate__playing_coach',
  coach_position__kayak__athletic_trainer = 'coach_position__kayak__athletic_trainer',
  coach_position__kayak__coach = 'coach_position__kayak__coach',
  coach_position__kayak__first_coach = 'coach_position__kayak__first_coach',
  coach_position__kayak__head_coach = 'coach_position__kayak__head_coach',
  coach_position__kickboxing__athletic_trainer = 'coach_position__kickboxing__athletic_trainer',
  coach_position__kickboxing__coach = 'coach_position__kickboxing__coach',
  coach_position__kickboxing__first_coach = 'coach_position__kickboxing__first_coach',
  coach_position__kickboxing__head_coach = 'coach_position__kickboxing__head_coach',
  coach_position__kickboxing__playing_coach = 'coach_position__kickboxing__playing_coach',
  coach_position__kung_fu__athletic_trainer = 'coach_position__kung_fu__athletic_trainer',
  coach_position__kung_fu__coach = 'coach_position__kung_fu__coach',
  coach_position__kung_fu__first_coach = 'coach_position__kung_fu__first_coach',
  coach_position__kung_fu__head_coach = 'coach_position__kung_fu__head_coach',
  coach_position__kung_fu__playing_coach = 'coach_position__kung_fu__playing_coach',
  coach_position__lacrosse__assistant_coach = 'coach_position__lacrosse__assistant_coach',
  coach_position__lacrosse__athletic_trainer = 'coach_position__lacrosse__athletic_trainer',
  coach_position__lacrosse__coach = 'coach_position__lacrosse__coach',
  coach_position__lacrosse__gk_coach = 'coach_position__lacrosse__gk_coach',
  coach_position__lacrosse__head_coach = 'coach_position__lacrosse__head_coach',
  coach_position__lacrosse__playing_coach = 'coach_position__lacrosse__playing_coach',
  coach_position__long_distance_running__athletic_trainer = 'coach_position__long_distance_running__athletic_trainer',
  coach_position__long_distance_running__coach = 'coach_position__long_distance_running__coach',
  coach_position__long_distance_running__first_coach = 'coach_position__long_distance_running__first_coach',
  coach_position__long_distance_running__head_coach = 'coach_position__long_distance_running__head_coach',
  coach_position__long_distance_running__playing_coach = 'coach_position__long_distance_running__playing_coach',
  coach_position__long_jump__athletic_trainer = 'coach_position__long_jump__athletic_trainer',
  coach_position__long_jump__coach = 'coach_position__long_jump__coach',
  coach_position__long_jump__first_coach = 'coach_position__long_jump__first_coach',
  coach_position__long_jump__head_coach = 'coach_position__long_jump__head_coach',
  coach_position__long_jump__playing_coach = 'coach_position__long_jump__playing_coach',
  coach_position__luge__athletic_trainer = 'coach_position__luge__athletic_trainer',
  coach_position__luge__coach = 'coach_position__luge__coach',
  coach_position__luge__first_coach = 'coach_position__luge__first_coach',
  coach_position__luge__head_coach = 'coach_position__luge__head_coach',
  coach_position__luge__playing_coach = 'coach_position__luge__playing_coach',
  coach_position__marathon__athletic_trainer = 'coach_position__marathon__athletic_trainer',
  coach_position__marathon__coach = 'coach_position__marathon__coach',
  coach_position__marathon__first_coach = 'coach_position__marathon__first_coach',
  coach_position__marathon__head_coach = 'coach_position__marathon__head_coach',
  coach_position__marathon__playing_coach = 'coach_position__marathon__playing_coach',
  coach_position__marathon_swimming__athletic_trainer = 'coach_position__marathon_swimming__athletic_trainer',
  coach_position__marathon_swimming__coach = 'coach_position__marathon_swimming__coach',
  coach_position__marathon_swimming__first_coach = 'coach_position__marathon_swimming__first_coach',
  coach_position__marathon_swimming__head_coach = 'coach_position__marathon_swimming__head_coach',
  coach_position__men_decathlon__athletic_trainer = 'coach_position__men_decathlon__athletic_trainer',
  coach_position__men_decathlon__coach = 'coach_position__men_decathlon__coach',
  coach_position__men_decathlon__first_coach = 'coach_position__men_decathlon__first_coach',
  coach_position__men_decathlon__head_coach = 'coach_position__men_decathlon__head_coach',
  coach_position__men_decathlon__playing_coach = 'coach_position__men_decathlon__playing_coach',
  coach_position__middle_distance_running__athletic_trainer = 'coach_position__middle_distance_running__athletic_trainer',
  coach_position__middle_distance_running__coach = 'coach_position__middle_distance_running__coach',
  coach_position__middle_distance_running__first_coach = 'coach_position__middle_distance_running__first_coach',
  coach_position__middle_distance_running__head_coach = 'coach_position__middle_distance_running__head_coach',
  coach_position__middle_distance_running__playing_coach = 'coach_position__middle_distance_running__playing_coach',
  coach_position__modern_pentathlon__athletic_trainer = 'coach_position__modern_pentathlon__athletic_trainer',
  coach_position__modern_pentathlon__coach = 'coach_position__modern_pentathlon__coach',
  coach_position__modern_pentathlon__first_coach = 'coach_position__modern_pentathlon__first_coach',
  coach_position__modern_pentathlon__head_coach = 'coach_position__modern_pentathlon__head_coach',
  coach_position__modern_pentathlon__playing_coach = 'coach_position__modern_pentathlon__playing_coach',
  coach_position__nordic_skiing__athletic_trainer = 'coach_position__nordic_skiing__athletic_trainer',
  coach_position__nordic_skiing__coach = 'coach_position__nordic_skiing__coach',
  coach_position__nordic_skiing__first_coach = 'coach_position__nordic_skiing__first_coach',
  coach_position__nordic_skiing__head_coach = 'coach_position__nordic_skiing__head_coach',
  coach_position__nordic_skiing__playing_coach = 'coach_position__nordic_skiing__playing_coach',
  coach_position__pole_vault__athletic_trainer = 'coach_position__pole_vault__athletic_trainer',
  coach_position__pole_vault__coach = 'coach_position__pole_vault__coach',
  coach_position__pole_vault__first_coach = 'coach_position__pole_vault__first_coach',
  coach_position__pole_vault__head_coach = 'coach_position__pole_vault__head_coach',
  coach_position__pole_vault__playing_coach = 'coach_position__pole_vault__playing_coach',
  coach_position__race_walking__athletic_trainer = 'coach_position__race_walking__athletic_trainer',
  coach_position__race_walking__coach = 'coach_position__race_walking__coach',
  coach_position__race_walking__first_coach = 'coach_position__race_walking__first_coach',
  coach_position__race_walking__head_coach = 'coach_position__race_walking__head_coach',
  coach_position__race_walking__playing_coach = 'coach_position__race_walking__playing_coach',
  coach_position__racquetball__assistant_coach = 'coach_position__racquetball__assistant_coach',
  coach_position__racquetball__athletic_trainer = 'coach_position__racquetball__athletic_trainer',
  coach_position__racquetball__coach = 'coach_position__racquetball__coach',
  coach_position__racquetball__head_coach = 'coach_position__racquetball__head_coach',
  coach_position__rhythmic_gymnastics__athletic_trainer = 'coach_position__rhythmic_gymnastics__athletic_trainer',
  coach_position__rhythmic_gymnastics__coach = 'coach_position__rhythmic_gymnastics__coach',
  coach_position__rhythmic_gymnastics__first_coach = 'coach_position__rhythmic_gymnastics__first_coach',
  coach_position__rhythmic_gymnastics__head_coach = 'coach_position__rhythmic_gymnastics__head_coach',
  coach_position__rhythmic_gymnastics__playing_coach = 'coach_position__rhythmic_gymnastics__playing_coach',
  coach_position__roller__athletic_trainer = 'coach_position__roller__athletic_trainer',
  coach_position__roller__coach = 'coach_position__roller__coach',
  coach_position__roller__first_coach = 'coach_position__roller__first_coach',
  coach_position__roller__head_coach = 'coach_position__roller__head_coach',
  coach_position__roller__playing_coach = 'coach_position__roller__playing_coach',
  coach_position__rowing__athletic_trainer = 'coach_position__rowing__athletic_trainer',
  coach_position__rowing__coach = 'coach_position__rowing__coach',
  coach_position__rowing__first_coach = 'coach_position__rowing__first_coach',
  coach_position__rowing__head_coach = 'coach_position__rowing__head_coach',
  coach_position__rugby__assistant_coach = 'coach_position__rugby__assistant_coach',
  coach_position__rugby__athletic_trainer = 'coach_position__rugby__athletic_trainer',
  coach_position__rugby__coach = 'coach_position__rugby__coach',
  coach_position__rugby__defensive_coach = 'coach_position__rugby__defensive_coach',
  coach_position__rugby__fitness_coach = 'coach_position__rugby__fitness_coach',
  coach_position__rugby__head_coach = 'coach_position__rugby__head_coach',
  coach_position__rugby__offensive_coach = 'coach_position__rugby__offensive_coach',
  coach_position__rugby__playing_coach = 'coach_position__rugby__playing_coach',
  coach_position__rugby__scrum_coach = 'coach_position__rugby__scrum_coach',
  coach_position__rugby__skill_coach = 'coach_position__rugby__skill_coach',
  coach_position__rugby__strength_and_conditioning_coach = 'coach_position__rugby__strength_and_conditioning_coach',
  coach_position__rugby_sevens__assistant_coach = 'coach_position__rugby_sevens__assistant_coach',
  coach_position__rugby_sevens__athletic_trainer = 'coach_position__rugby_sevens__athletic_trainer',
  coach_position__rugby_sevens__coach = 'coach_position__rugby_sevens__coach',
  coach_position__rugby_sevens__defensive_coach = 'coach_position__rugby_sevens__defensive_coach',
  coach_position__rugby_sevens__fitness_coach = 'coach_position__rugby_sevens__fitness_coach',
  coach_position__rugby_sevens__head_coach = 'coach_position__rugby_sevens__head_coach',
  coach_position__rugby_sevens__offensive_coach = 'coach_position__rugby_sevens__offensive_coach',
  coach_position__rugby_sevens__playing_coach = 'coach_position__rugby_sevens__playing_coach',
  coach_position__rugby_sevens__scrum_coach = 'coach_position__rugby_sevens__scrum_coach',
  coach_position__rugby_sevens__skill_coach = 'coach_position__rugby_sevens__skill_coach',
  coach_position__rugby_sevens__strength_and_conditioning_coach = 'coach_position__rugby_sevens__strength_and_conditioning_coach',
  coach_position__sepak_takraw__athletic_trainer = 'coach_position__sepak_takraw__athletic_trainer',
  coach_position__sepak_takraw__coach = 'coach_position__sepak_takraw__coach',
  coach_position__sepak_takraw__first_coach = 'coach_position__sepak_takraw__first_coach',
  coach_position__sepak_takraw__head_coach = 'coach_position__sepak_takraw__head_coach',
  coach_position__sepak_takraw__playing_coach = 'coach_position__sepak_takraw__playing_coach',
  coach_position__shooting__athletic_trainer = 'coach_position__shooting__athletic_trainer',
  coach_position__shooting__coach = 'coach_position__shooting__coach',
  coach_position__shooting__first_coach = 'coach_position__shooting__first_coach',
  coach_position__shooting__head_coach = 'coach_position__shooting__head_coach',
  coach_position__shot_put__athletic_trainer = 'coach_position__shot_put__athletic_trainer',
  coach_position__shot_put__coach = 'coach_position__shot_put__coach',
  coach_position__shot_put__first_coach = 'coach_position__shot_put__first_coach',
  coach_position__shot_put__head_coach = 'coach_position__shot_put__head_coach',
  coach_position__shot_put__playing_coach = 'coach_position__shot_put__playing_coach',
  coach_position__show_jumping__assistant_coach = 'coach_position__show_jumping__assistant_coach',
  coach_position__show_jumping__athletic_trainer = 'coach_position__show_jumping__athletic_trainer',
  coach_position__show_jumping__coach = 'coach_position__show_jumping__coach',
  coach_position__show_jumping__head_coach = 'coach_position__show_jumping__head_coach',
  coach_position__show_jumping__playing_coach = 'coach_position__show_jumping__playing_coach',
  coach_position__skateboard__athletic_trainer = 'coach_position__skateboard__athletic_trainer',
  coach_position__skateboard__coach = 'coach_position__skateboard__coach',
  coach_position__skateboard__head_coach = 'coach_position__skateboard__head_coach',
  coach_position__skateboard__playing_coach = 'coach_position__skateboard__playing_coach',
  coach_position__skeleton__athletic_trainer = 'coach_position__skeleton__athletic_trainer',
  coach_position__skeleton__coach = 'coach_position__skeleton__coach',
  coach_position__skeleton__first_coach = 'coach_position__skeleton__first_coach',
  coach_position__skeleton__head_coach = 'coach_position__skeleton__head_coach',
  coach_position__skeleton__playing_coach = 'coach_position__skeleton__playing_coach',
  coach_position__ski_jumping__athletic_trainer = 'coach_position__ski_jumping__athletic_trainer',
  coach_position__ski_jumping__coach = 'coach_position__ski_jumping__coach',
  coach_position__ski_jumping__first_coach = 'coach_position__ski_jumping__first_coach',
  coach_position__ski_jumping__head_coach = 'coach_position__ski_jumping__head_coach',
  coach_position__ski_jumping__playing_coach = 'coach_position__ski_jumping__playing_coach',
  coach_position__ski_mountaineering__athletic_trainer = 'coach_position__ski_mountaineering__athletic_trainer',
  coach_position__ski_mountaineering__coach = 'coach_position__ski_mountaineering__coach',
  coach_position__ski_mountaineering__first_coach = 'coach_position__ski_mountaineering__first_coach',
  coach_position__ski_mountaineering__head_coach = 'coach_position__ski_mountaineering__head_coach',
  coach_position__ski_mountaineering__playing_coach = 'coach_position__ski_mountaineering__playing_coach',
  coach_position__snowboarding__athletic_trainer = 'coach_position__snowboarding__athletic_trainer',
  coach_position__snowboarding__coach = 'coach_position__snowboarding__coach',
  coach_position__snowboarding__first_coach = 'coach_position__snowboarding__first_coach',
  coach_position__snowboarding__head_coach = 'coach_position__snowboarding__head_coach',
  coach_position__snowboarding__playing_coach = 'coach_position__snowboarding__playing_coach',
  coach_position__soccer__athletic_trainer = 'coach_position__soccer__athletic_trainer',
  coach_position__soccer__coach = 'coach_position__soccer__coach',
  coach_position__soccer__first_coach = 'coach_position__soccer__first_coach',
  coach_position__soccer__fitness_coach = 'coach_position__soccer__fitness_coach',
  coach_position__soccer__gk_coach = 'coach_position__soccer__gk_coach',
  coach_position__soccer__head_coach = 'coach_position__soccer__head_coach',
  coach_position__soccer__playing_coach = 'coach_position__soccer__playing_coach',
  coach_position__soccer__tactical_coach = 'coach_position__soccer__tactical_coach',
  coach_position__soft_tennis__assistant_coach = 'coach_position__soft_tennis__assistant_coach',
  coach_position__soft_tennis__athletic_trainer = 'coach_position__soft_tennis__athletic_trainer',
  coach_position__soft_tennis__coach = 'coach_position__soft_tennis__coach',
  coach_position__soft_tennis__head_coach = 'coach_position__soft_tennis__head_coach',
  coach_position__softball__athletic_trainer = 'coach_position__softball__athletic_trainer',
  coach_position__softball__base_coach = 'coach_position__softball__base_coach',
  coach_position__softball__bench_coach = 'coach_position__softball__bench_coach',
  coach_position__softball__coach = 'coach_position__softball__coach',
  coach_position__softball__defensive_coach = 'coach_position__softball__defensive_coach',
  coach_position__softball__head_coach = 'coach_position__softball__head_coach',
  coach_position__softball__hitting_coach = 'coach_position__softball__hitting_coach',
  coach_position__softball__pitching_coach = 'coach_position__softball__pitching_coach',
  coach_position__softball__playing_coach = 'coach_position__softball__playing_coach',
  coach_position__sports_climbing__athletic_trainer = 'coach_position__sports_climbing__athletic_trainer',
  coach_position__sports_climbing__coach = 'coach_position__sports_climbing__coach',
  coach_position__sports_climbing__head_coach = 'coach_position__sports_climbing__head_coach',
  coach_position__sports_climbing__playing_coach = 'coach_position__sports_climbing__playing_coach',
  coach_position__sprint__athletic_trainer = 'coach_position__sprint__athletic_trainer',
  coach_position__sprint__coach = 'coach_position__sprint__coach',
  coach_position__sprint__first_coach = 'coach_position__sprint__first_coach',
  coach_position__sprint__head_coach = 'coach_position__sprint__head_coach',
  coach_position__sprint__playing_coach = 'coach_position__sprint__playing_coach',
  coach_position__squash__assistant_coach = 'coach_position__squash__assistant_coach',
  coach_position__squash__athletic_trainer = 'coach_position__squash__athletic_trainer',
  coach_position__squash__coach = 'coach_position__squash__coach',
  coach_position__squash__head_coach = 'coach_position__squash__head_coach',
  coach_position__ssireum__athletic_trainer = 'coach_position__ssireum__athletic_trainer',
  coach_position__ssireum__coach = 'coach_position__ssireum__coach',
  coach_position__ssireum__first_coach = 'coach_position__ssireum__first_coach',
  coach_position__ssireum__head_coach = 'coach_position__ssireum__head_coach',
  coach_position__ssireum__playing_coach = 'coach_position__ssireum__playing_coach',
  coach_position__surfing__athletic_trainer = 'coach_position__surfing__athletic_trainer',
  coach_position__surfing__coach = 'coach_position__surfing__coach',
  coach_position__surfing__first_coach = 'coach_position__surfing__first_coach',
  coach_position__surfing__head_coach = 'coach_position__surfing__head_coach',
  coach_position__swimming__athletic_trainer = 'coach_position__swimming__athletic_trainer',
  coach_position__swimming__coach = 'coach_position__swimming__coach',
  coach_position__swimming__first_coach = 'coach_position__swimming__first_coach',
  coach_position__swimming__head_coach = 'coach_position__swimming__head_coach',
  coach_position__table_tennis__assistant_coach = 'coach_position__table_tennis__assistant_coach',
  coach_position__table_tennis__athletic_trainer = 'coach_position__table_tennis__athletic_trainer',
  coach_position__table_tennis__coach = 'coach_position__table_tennis__coach',
  coach_position__table_tennis__head_coach = 'coach_position__table_tennis__head_coach',
  coach_position__taekwondo__athletic_trainer = 'coach_position__taekwondo__athletic_trainer',
  coach_position__taekwondo__coach = 'coach_position__taekwondo__coach',
  coach_position__taekwondo__first_coach = 'coach_position__taekwondo__first_coach',
  coach_position__taekwondo__head_coach = 'coach_position__taekwondo__head_coach',
  coach_position__taekwondo__playing_coach = 'coach_position__taekwondo__playing_coach',
  coach_position__takkyeon__athletic_trainer = 'coach_position__takkyeon__athletic_trainer',
  coach_position__takkyeon__coach = 'coach_position__takkyeon__coach',
  coach_position__takkyeon__first_coach = 'coach_position__takkyeon__first_coach',
  coach_position__takkyeon__head_coach = 'coach_position__takkyeon__head_coach',
  coach_position__takkyeon__playing_coach = 'coach_position__takkyeon__playing_coach',
  coach_position__tee_ball__athletic_trainer = 'coach_position__tee_ball__athletic_trainer',
  coach_position__tee_ball__coach = 'coach_position__tee_ball__coach',
  coach_position__tee_ball__first_coach = 'coach_position__tee_ball__first_coach',
  coach_position__tee_ball__head_coach = 'coach_position__tee_ball__head_coach',
  coach_position__tee_ball__playing_coach = 'coach_position__tee_ball__playing_coach',
  coach_position__tennis__assistant_coach = 'coach_position__tennis__assistant_coach',
  coach_position__tennis__athletic_trainer = 'coach_position__tennis__athletic_trainer',
  coach_position__tennis__coach = 'coach_position__tennis__coach',
  coach_position__tennis__head_coach = 'coach_position__tennis__head_coach',
  coach_position__trampoline__athletic_trainer = 'coach_position__trampoline__athletic_trainer',
  coach_position__trampoline__coach = 'coach_position__trampoline__coach',
  coach_position__trampoline__first_coach = 'coach_position__trampoline__first_coach',
  coach_position__trampoline__head_coach = 'coach_position__trampoline__head_coach',
  coach_position__trampoline__playing_coach = 'coach_position__trampoline__playing_coach',
  coach_position__triathlon__athletic_trainer = 'coach_position__triathlon__athletic_trainer',
  coach_position__triathlon__bike_coach = 'coach_position__triathlon__bike_coach',
  coach_position__triathlon__coach = 'coach_position__triathlon__coach',
  coach_position__triathlon__first_coach = 'coach_position__triathlon__first_coach',
  coach_position__triathlon__head_coach = 'coach_position__triathlon__head_coach',
  coach_position__triathlon__playing_coach = 'coach_position__triathlon__playing_coach',
  coach_position__triathlon__running_coach = 'coach_position__triathlon__running_coach',
  coach_position__triathlon__swimming_coach = 'coach_position__triathlon__swimming_coach',
  coach_position__triple_jump__athletic_trainer = 'coach_position__triple_jump__athletic_trainer',
  coach_position__triple_jump__coach = 'coach_position__triple_jump__coach',
  coach_position__triple_jump__first_coach = 'coach_position__triple_jump__first_coach',
  coach_position__triple_jump__head_coach = 'coach_position__triple_jump__head_coach',
  coach_position__triple_jump__playing_coach = 'coach_position__triple_jump__playing_coach',
  coach_position__volleyball__assistant_coach = 'coach_position__volleyball__assistant_coach',
  coach_position__volleyball__athletic_trainer = 'coach_position__volleyball__athletic_trainer',
  coach_position__volleyball__coach = 'coach_position__volleyball__coach',
  coach_position__volleyball__first_coach = 'coach_position__volleyball__first_coach',
  coach_position__volleyball__head_coach = 'coach_position__volleyball__head_coach',
  coach_position__volleyball__playing_coach = 'coach_position__volleyball__playing_coach',
  coach_position__water_polo__athletic_trainer = 'coach_position__water_polo__athletic_trainer',
  coach_position__water_polo__coach = 'coach_position__water_polo__coach',
  coach_position__water_polo__first_coach = 'coach_position__water_polo__first_coach',
  coach_position__water_polo__head_coach = 'coach_position__water_polo__head_coach',
  coach_position__water_skiing__athletic_trainer = 'coach_position__water_skiing__athletic_trainer',
  coach_position__water_skiing__coach = 'coach_position__water_skiing__coach',
  coach_position__water_skiing__first_coach = 'coach_position__water_skiing__first_coach',
  coach_position__water_skiing__head_coach = 'coach_position__water_skiing__head_coach',
  coach_position__weightlifting__assistant_coach = 'coach_position__weightlifting__assistant_coach',
  coach_position__weightlifting__athletic_trainer = 'coach_position__weightlifting__athletic_trainer',
  coach_position__weightlifting__coach = 'coach_position__weightlifting__coach',
  coach_position__weightlifting__head_coach = 'coach_position__weightlifting__head_coach',
  coach_position__weightlifting__playing_coach = 'coach_position__weightlifting__playing_coach',
  coach_position__women_heptathlon__athletic_trainer = 'coach_position__women_heptathlon__athletic_trainer',
  coach_position__women_heptathlon__coach = 'coach_position__women_heptathlon__coach',
  coach_position__women_heptathlon__first_coach = 'coach_position__women_heptathlon__first_coach',
  coach_position__women_heptathlon__head_coach = 'coach_position__women_heptathlon__head_coach',
  coach_position__women_heptathlon__playing_coach = 'coach_position__women_heptathlon__playing_coach',
  coach_position__wrestling__athletic_trainer = 'coach_position__wrestling__athletic_trainer',
  coach_position__wrestling__coach = 'coach_position__wrestling__coach',
  coach_position__wrestling__first_coach = 'coach_position__wrestling__first_coach',
  coach_position__wrestling__head_coach = 'coach_position__wrestling__head_coach',
  coach_position__wrestling__playing_coach = 'coach_position__wrestling__playing_coach',
  coach_position__yacht__athletic_trainer = 'coach_position__yacht__athletic_trainer',
  coach_position__yacht__coach = 'coach_position__yacht__coach',
  coach_position__yacht__first_coach = 'coach_position__yacht__first_coach',
  coach_position__yacht__head_coach = 'coach_position__yacht__head_coach',
  player_position__american_football__center = 'player_position__american_football__center',
  player_position__american_football__conner_back = 'player_position__american_football__conner_back',
  player_position__american_football__defensive_end = 'player_position__american_football__defensive_end',
  player_position__american_football__defensive_tackle = 'player_position__american_football__defensive_tackle',
  player_position__american_football__dime_back = 'player_position__american_football__dime_back',
  player_position__american_football__free_safety = 'player_position__american_football__free_safety',
  player_position__american_football__full_back = 'player_position__american_football__full_back',
  player_position__american_football__half_back = 'player_position__american_football__half_back',
  player_position__american_football__middle_linebacker = 'player_position__american_football__middle_linebacker',
  player_position__american_football__nickle_back = 'player_position__american_football__nickle_back',
  player_position__american_football__offensive_guard = 'player_position__american_football__offensive_guard',
  player_position__american_football__offensive_tackle = 'player_position__american_football__offensive_tackle',
  player_position__american_football__outside_linebacker = 'player_position__american_football__outside_linebacker',
  player_position__american_football__quarter_back = 'player_position__american_football__quarter_back',
  player_position__american_football__running_back = 'player_position__american_football__running_back',
  player_position__american_football__strong_safety = 'player_position__american_football__strong_safety',
  player_position__american_football__tight_end = 'player_position__american_football__tight_end',
  player_position__american_football__wide_receiver = 'player_position__american_football__wide_receiver',
  player_position__baseball__catcher = 'player_position__baseball__catcher',
  player_position__baseball__center_fielder = 'player_position__baseball__center_fielder',
  player_position__baseball__designated_hitter = 'player_position__baseball__designated_hitter',
  player_position__baseball__first_baseman = 'player_position__baseball__first_baseman',
  player_position__baseball__left_fielder = 'player_position__baseball__left_fielder',
  player_position__baseball__pitcher = 'player_position__baseball__pitcher',
  player_position__baseball__right_fielder = 'player_position__baseball__right_fielder',
  player_position__baseball__second_baseman = 'player_position__baseball__second_baseman',
  player_position__baseball__short_stop = 'player_position__baseball__short_stop',
  player_position__baseball__third_baseman = 'player_position__baseball__third_baseman',
  player_position__basketball__center = 'player_position__basketball__center',
  player_position__basketball__point_guard = 'player_position__basketball__point_guard',
  player_position__basketball__power_forward = 'player_position__basketball__power_forward',
  player_position__basketball__shooting_guard = 'player_position__basketball__shooting_guard',
  player_position__basketball__small_forward = 'player_position__basketball__small_forward',
  player_position__cricket__batsman = 'player_position__cricket__batsman',
  player_position__cricket__bowler = 'player_position__cricket__bowler',
  player_position__cricket__wicket = 'player_position__cricket__wicket',
  player_position__curling__lead = 'player_position__curling__lead',
  player_position__curling__second = 'player_position__curling__second',
  player_position__curling__skip = 'player_position__curling__skip',
  player_position__curling__vice = 'player_position__curling__vice',
  player_position__empty = 'player_position__empty',
  player_position__field_hockey__defense = 'player_position__field_hockey__defense',
  player_position__field_hockey__forward = 'player_position__field_hockey__forward',
  player_position__field_hockey__goalkeeper = 'player_position__field_hockey__goalkeeper',
  player_position__field_hockey__midfielder = 'player_position__field_hockey__midfielder',
  player_position__foot_volleyball__attacker = 'player_position__foot_volleyball__attacker',
  player_position__foot_volleyball__defender = 'player_position__foot_volleyball__defender',
  player_position__foot_volleyball__setter = 'player_position__foot_volleyball__setter',
  player_position__futsal__ala = 'player_position__futsal__ala',
  player_position__futsal__fixo = 'player_position__futsal__fixo',
  player_position__futsal__goleiro = 'player_position__futsal__goleiro',
  player_position__futsal__pivo = 'player_position__futsal__pivo',
  player_position__handball__center_back = 'player_position__handball__center_back',
  player_position__handball__goalkeeper = 'player_position__handball__goalkeeper',
  player_position__handball__left_back = 'player_position__handball__left_back',
  player_position__handball__left_wing = 'player_position__handball__left_wing',
  player_position__handball__pivot = 'player_position__handball__pivot',
  player_position__handball__right_back = 'player_position__handball__right_back',
  player_position__handball__right_wing = 'player_position__handball__right_wing',
  player_position__ice_hockey__center = 'player_position__ice_hockey__center',
  player_position__ice_hockey__goaltender = 'player_position__ice_hockey__goaltender',
  player_position__ice_hockey__left_defense = 'player_position__ice_hockey__left_defense',
  player_position__ice_hockey__left_wing = 'player_position__ice_hockey__left_wing',
  player_position__ice_hockey__right_defense = 'player_position__ice_hockey__right_defense',
  player_position__ice_hockey__right_wing = 'player_position__ice_hockey__right_wing',
  player_position__rugby__centre = 'player_position__rugby__centre',
  player_position__rugby__flanker = 'player_position__rugby__flanker',
  player_position__rugby__full_back = 'player_position__rugby__full_back',
  player_position__rugby__hooker = 'player_position__rugby__hooker',
  player_position__rugby__lock = 'player_position__rugby__lock',
  player_position__rugby__number_eight = 'player_position__rugby__number_eight',
  player_position__rugby__prop = 'player_position__rugby__prop',
  player_position__rugby__scrum_half = 'player_position__rugby__scrum_half',
  player_position__rugby__stand_off = 'player_position__rugby__stand_off',
  player_position__rugby__wing = 'player_position__rugby__wing',
  player_position__rugby_sevens__center = 'player_position__rugby_sevens__center',
  player_position__rugby_sevens__hooker = 'player_position__rugby_sevens__hooker',
  player_position__rugby_sevens__prop = 'player_position__rugby_sevens__prop',
  player_position__rugby_sevens__scrum_half = 'player_position__rugby_sevens__scrum_half',
  player_position__rugby_sevens__stand_off = 'player_position__rugby_sevens__stand_off',
  player_position__rugby_sevens__wing = 'player_position__rugby_sevens__wing',
  player_position__sepak_takraw__feeder = 'player_position__sepak_takraw__feeder',
  player_position__sepak_takraw__killer = 'player_position__sepak_takraw__killer',
  player_position__sepak_takraw__tekong = 'player_position__sepak_takraw__tekong',
  player_position__soccer__defense = 'player_position__soccer__defense',
  player_position__soccer__forward = 'player_position__soccer__forward',
  player_position__soccer__goalkeeper = 'player_position__soccer__goalkeeper',
  player_position__soccer__midfielder = 'player_position__soccer__midfielder',
  player_position__tee_ball__catcher = 'player_position__tee_ball__catcher',
  player_position__tee_ball__first_baseman = 'player_position__tee_ball__first_baseman',
  player_position__tee_ball__first_center_fielder = 'player_position__tee_ball__first_center_fielder',
  player_position__tee_ball__first_short_stop = 'player_position__tee_ball__first_short_stop',
  player_position__tee_ball__left_fielder = 'player_position__tee_ball__left_fielder',
  player_position__tee_ball__right_fielder = 'player_position__tee_ball__right_fielder',
  player_position__tee_ball__second_baseman = 'player_position__tee_ball__second_baseman',
  player_position__tee_ball__second_center_fielder = 'player_position__tee_ball__second_center_fielder',
  player_position__tee_ball__second_short_stop = 'player_position__tee_ball__second_short_stop',
  player_position__tee_ball__third_baseman = 'player_position__tee_ball__third_baseman',
  player_position__volleyball__libero = 'player_position__volleyball__libero',
  player_position__volleyball__middle_blocker = 'player_position__volleyball__middle_blocker',
  player_position__volleyball__opposite_spiker = 'player_position__volleyball__opposite_spiker',
  player_position__volleyball__outside_hitter = 'player_position__volleyball__outside_hitter',
  player_position__volleyball__setter = 'player_position__volleyball__setter'
}

export type SportsQuery = {
  __typename: 'SportsQuery';
  bodyPartGroups: Array<SportsBodyPartGroup>;
  bodyParts: Array<SportsBodyPart>;
  categories: Array<SportsCategoryCollection>;
  category: SportsCategoryCollection;
  categoryGroups: Array<SportsCategoryGroupCollection>;
  countryList: Array<Country>;
  regions: Array<Region>;
  team?: Maybe<SportsTeam>;
  teamsByPage: SportsTeamByPage;
  user?: Maybe<SportsUser>;
  usersByPage: SportsUserByPage;
  viewer?: Maybe<SportsUser>;
};


export type SportsQueryBodyPartsArgs = {
  groups?: Maybe<Array<SportsBodyPartGroupName>>;
  input: MultilingualTextInput;
};


export type SportsQueryCategoriesArgs = {
  groups?: Maybe<Array<Scalars['String']>>;
};


export type SportsQueryCategoryArgs = {
  id: Scalars['String'];
};


export type SportsQueryTeamArgs = {
  id: Scalars['ID'];
};


export type SportsQueryTeamsByPageArgs = {
  activeOnly?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TeamFilterInput>;
  filterBy?: Maybe<TeamFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SportsQueryUserArgs = {
  id: Scalars['ID'];
};


export type SportsQueryUsersByPageArgs = {
  activeOnly?: Maybe<Scalars['Boolean']>;
  filterBy?: Maybe<UserFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type SportsRawBody = SportsRawData & TimeSerializable & {
  __typename: 'SportsRawBody';
  height: Scalars['Float'];
  heightUnit?: Maybe<HeightUnit>;
  id: Scalars['ID'];
  name: SportsRawDataName;
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  user: SportsUser;
  weight: Scalars['Float'];
  weightUnit?: Maybe<WeightUnit>;
};

export type SportsRawCondition = SportsRawData & TimeSerializable & {
  __typename: 'SportsRawCondition';
  id: Scalars['ID'];
  name: SportsRawDataName;
  rawSleep: SportsRawSleep;
  rawWellness: SportsRawWellness;
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  user: SportsUser;
};

export type SportsRawData = {
  id: Scalars['ID'];
  name: SportsRawDataName;
  user: SportsUser;
};

export enum SportsRawDataName {
  RAW_BODY = 'RAW_BODY',
  RAW_CONDITION = 'RAW_CONDITION',
  RAW_INJURY = 'RAW_INJURY',
  RAW_SLEEP = 'RAW_SLEEP',
  RAW_WELLNESS = 'RAW_WELLNESS',
  RAW_WORKLOAD = 'RAW_WORKLOAD'
}

export type SportsRawInjury = Eventable & SportsRawData & TimeSerializable & {
  __typename: 'SportsRawInjury';
  bodyPart: SportsBodyPart;
  bodyPartGroup: SportsBodyPartGroup;
  comment?: Maybe<Scalars['String']>;
  event?: Maybe<EventInvitee>;
  id: Scalars['ID'];
  name: SportsRawDataName;
  quality: Array<SportsInjuryQuality>;
  schedule?: Maybe<ScheduleInvitee>;
  soreness: Scalars['Int'];
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  timing: Array<SportsInjuryTiming>;
  user: SportsUser;
};


export type SportsRawInjuryScheduleArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export type SportsRawSleep = SportsRawData & TimeSerializable & {
  __typename: 'SportsRawSleep';
  duration: Scalars['Int'];
  id: Scalars['ID'];
  name: SportsRawDataName;
  quality: Scalars['Int'];
  time: Scalars['DateTimeString'];
  timeHourlyOffset: Scalars['Float'];
  timezone: Scalars['String'];
  user: SportsUser;
};

export type SportsRawWellness = SportsRawData & TimeSerializable & {
  __typename: 'SportsRawWellness';
  fatigue: Scalars['Int'];
  id: Scalars['ID'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  name: SportsRawDataName;
  stress: Scalars['Int'];
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  user: SportsUser;
};

export type SportsRawWorkload = Eventable & SportsRawData & TimeSerializable & {
  __typename: 'SportsRawWorkload';
  duration: Scalars['Int'];
  event?: Maybe<EventInvitee>;
  id: Scalars['ID'];
  intensity: Scalars['Int'];
  load: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  name: SportsRawDataName;
  satisfaction: Scalars['Int'];
  schedule?: Maybe<ScheduleInvitee>;
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  type: WorkoutType;
  user: SportsUser;
};


export type SportsRawWorkloadScheduleArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export enum SportsRole {
  COACH = 'COACH',
  PLAYER = 'PLAYER'
}

export type SportsTeam = {
  __typename: 'SportsTeam';
  adminUserList: SportsUserList;
  adminWithOwnerUserList: SportsUserList;
  alarmConnection: AlarmConnection;
  chartData: ChartData;
  code: Scalars['String'];
  createdAt: Scalars['Date'];
  dailyData: DailyTeamData;
  data: Array<SportsTimeSeriesTeamData>;
  eventList: Array<Event>;
  group: TeamGroup;
  groups: Array<TeamGroup>;
  id: Scalars['ID'];
  injuryList: Array<Injury>;
  introduction?: Maybe<Scalars['String']>;
  isGivenWelcomeCoupon: Scalars['Boolean'];
  license?: Maybe<License>;
  memberUserList: SportsUserList;
  name: Scalars['String'];
  notCheckedAlarm: Scalars['Int'];
  ownedBy: SportsUser;
  pendingInvitationRequestList: Array<TeamInvitationRequest>;
  pictureFileId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  region: Region;
  slug?: Maybe<Scalars['String']>;
  sportsCategory: SportsCategoryCollection;
  sportsData: TeamSportsData;
  starredUserMaps: Array<SportsTeamUserStarred>;
  subscriptionItem?: Maybe<SubscriptionItem>;
  totalUserList: SportsUserList;
  type: TeamType;
  updatedAt: Scalars['Date'];
  userMapsByPage: SportsTeamUserMapByPage;
  viewerAuthority?: Maybe<SportsTeamAuthority>;
  viewerInvitationRequestStatus: SportsTeamInvitationRequestStatus;
};


export type SportsTeamAlarmConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  timezone: Scalars['String'];
};


export type SportsTeamChartDataArgs = {
  cursor: Scalars['DateTime'];
  dataName: ChartDataName;
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  timezone: Scalars['String'];
};


export type SportsTeamDailyDataArgs = {
  cursor: Scalars['DateTime'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  timezone: Scalars['String'];
};


export type SportsTeamDataArgs = {
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  from: Scalars['DateTime'];
  names: Array<SportsTeamDataName>;
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type SportsTeamEventListArgs = {
  input: GetCoachEventInput;
};


export type SportsTeamGroupArgs = {
  id: Scalars['ID'];
};


export type SportsTeamNotCheckedAlarmArgs = {
  timezone: Scalars['String'];
};


export type SportsTeamStarredUserMapsArgs = {
  order?: Maybe<UserDataOrderByInput>;
  starerId: Scalars['ID'];
};


export type SportsTeamUserMapsByPageArgs = {
  filter?: Maybe<TeamUserMapFilterInput>;
  filterBy?: Maybe<TeamUserFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<UserDataOrderByInput>;
};

export enum SportsTeamAuthority {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OBSERVER = 'OBSERVER',
  OWNER = 'OWNER',
  PENDING_MEMBER = 'PENDING_MEMBER',
  VIEWER = 'VIEWER'
}

export type SportsTeamByPage = {
  __typename: 'SportsTeamByPage';
  entries: Array<SportsTeam>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type SportsTeamDataEntry = {
  __typename: 'SportsTeamDataEntry';
  meta?: Maybe<Scalars['JSON']>;
  time: Scalars['DateTimeString'];
  value?: Maybe<Scalars['JSON']>;
};

export enum SportsTeamDataName {
  TEAM_CONDITION_AVG = 'TEAM_CONDITION_AVG',
  TEAM_INJURY_BY_SORENESS = 'TEAM_INJURY_BY_SORENESS',
  TEAM_READINESS_AVG = 'TEAM_READINESS_AVG',
  TEAM_WORKLOAD_ACXR_AVG = 'TEAM_WORKLOAD_ACXR_AVG',
  TEAM_WORKLOAD_AVG = 'TEAM_WORKLOAD_AVG'
}

export type SportsTeamDataRange = {
  __typename: 'SportsTeamDataRange';
  entries: Array<SportsTeamDataEntry>;
  from: Scalars['DateTimeString'];
  to: Scalars['DateTimeString'];
};

export type SportsTeamInvitationAcceptListInput = {
  invitationRequestList: Array<InvitationRequest>;
  team: SportsTeamInvitationRejectTeam;
};

export type SportsTeamInvitationRejectListInput = {
  requestIdList: Array<Scalars['ID']>;
  team: SportsTeamInvitationRejectTeam;
};

export type SportsTeamInvitationRejectTeam = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SportsTeamInvitationRequest = {
  __typename: 'SportsTeamInvitationRequest';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  position?: Maybe<SportsPosition>;
  role?: Maybe<SportsRole>;
  status: SportsTeamInvitationRequestStatus;
  team: SportsTeam;
  teamAuthority?: Maybe<SportsTeamAuthority>;
  updatedAt: Scalars['Date'];
  user: SportsUser;
};

export type SportsTeamInvitationRequestInput = {
  code: Scalars['String'];
  position?: Maybe<SportsPosition>;
  role?: Maybe<SportsRole>;
  teamAuthority?: Maybe<SportsTeamAuthority>;
  userId: Scalars['ID'];
};

export enum SportsTeamInvitationRequestStatus {
  CONFIRMING = 'CONFIRMING',
  LEFT = 'LEFT',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type SportsTeamUserMap = {
  __typename: 'SportsTeamUserMap';
  comment?: Maybe<Scalars['String']>;
  groups: Array<TeamGroup>;
  id: Scalars['ID'];
  isStarredByViewer: Scalars['Boolean'];
  position?: Maybe<SportsPosition>;
  role?: Maybe<SportsRole>;
  team: SportsTeam;
  teamAuthority: SportsTeamAuthority;
  user: SportsUser;
};

export type SportsTeamUserMapByPage = {
  __typename: 'SportsTeamUserMapByPage';
  entries: Array<SportsTeamUserMap>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type SportsTeamUserStarred = {
  __typename: 'SportsTeamUserStarred';
  isStarredByViewer: Scalars['Boolean'];
  starer: SportsUser;
  team: SportsTeam;
  user: SportsUser;
};

export type SportsTimeSeriesData = {
  __typename: 'SportsTimeSeriesData';
  group: SportsDataGroup;
  name: SportsDataName;
  ranges: Array<SportsDataRange>;
  stride: SportsDataStride;
};


export type SportsTimeSeriesDataRangesArgs = {
  input: MultilingualTextInput;
};

export type SportsTimeSeriesTeamData = {
  __typename: 'SportsTimeSeriesTeamData';
  name: SportsTeamDataName;
  ranges: Array<SportsTeamDataRange>;
  stride: SportsDataStride;
};

export type SportsUser = {
  __typename: 'SportsUser';
  alarmConnection: AlarmConnection;
  birthdate?: Maybe<Scalars['String']>;
  body: UserBodyData;
  careers?: Maybe<Array<SportsCareer>>;
  createdAt: Scalars['Date'];
  currentInjuryList: Array<Injury>;
  data: Array<SportsTimeSeriesData>;
  deviceTokens?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  federation?: Maybe<Array<Scalars['String']>>;
  feedbackConnection: FeedbackConnection;
  feedbackSuggestionList: Array<FeedbackSuggestion>;
  gender?: Maybe<Gender>;
  guideReopenStatus: GuideReopenStatus;
  id: Scalars['ID'];
  identityId: Scalars['String'];
  injury?: Maybe<Injury>;
  introduction?: Maybe<Scalars['String']>;
  isStarredBy: Scalars['Boolean'];
  latestRawBody?: Maybe<SportsRawBody>;
  name?: Maybe<Scalars['String']>;
  nationality?: Maybe<CountryCode>;
  notCheckedAlarm: Scalars['Float'];
  pastInjuryList: Array<Injury>;
  phoneNumber?: Maybe<Scalars['String']>;
  pictureFileId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  priorCareer?: Maybe<SportsCareer>;
  priorSportsCategory?: Maybe<SportsCategoryCollection>;
  priorSportsPosition?: Maybe<SportsPosition>;
  priorSportsRole?: Maybe<SportsRole>;
  pushConditionCheckReminderSchedule?: Maybe<Scalars['String']>;
  pushConditionReminderAllowed: Scalars['Boolean'];
  pushMarketingAllowed: Scalars['Boolean'];
  pushReminderAllowed: Scalars['Boolean'];
  pushWorkloadCheckReminderSchedule?: Maybe<Scalars['String']>;
  pushWorkloadReminderAllowed: Scalars['Boolean'];
  rawBody: SportsRawBody;
  rawCondition: SportsRawCondition;
  rawDataByCursor: RawDataByCursor;
  rawInjury: SportsRawInjury;
  rawSleep: SportsRawSleep;
  rawWellness: SportsRawWellness;
  rawWorkload: SportsRawWorkload;
  routineList: Array<Routine>;
  schedule: PlayerSchedule;
  slug?: Maybe<Scalars['String']>;
  sorenessHistory: SportsUserSorenessHistory;
  sportsData?: Maybe<SportsData>;
  sportsDataList: Array<SportsData>;
  teamList: Array<SportsTeam>;
  teamListForCoach: Array<SportsTeam>;
  teamMaps: Array<SportsTeamUserMap>;
  teamMapsByPage: SportsTeamUserMapByPage;
  teamsByPage: SportsTeamByPage;
  totalFeedbackCount: Scalars['Int'];
  translatedNationality?: Maybe<Scalars['String']>;
  translatedSportsCategory?: Maybe<Scalars['String']>;
  translatedSportsPosition?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  workload: Array<UserWorkloadData>;
};


export type SportsUserAlarmConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  timezone: Scalars['String'];
};


export type SportsUserBodyArgs = {
  date: Scalars['DateTime'];
  heightUnit?: HeightUnit;
  weightUnit?: WeightUnit;
};


export type SportsUserCurrentInjuryListArgs = {
  teamId: Scalars['ID'];
};


export type SportsUserDataArgs = {
  from?: Maybe<Scalars['DateTime']>;
  groups?: Maybe<Array<SportsDataGroup>>;
  names?: Maybe<Array<SportsDataName>>;
  raw?: Maybe<Scalars['Boolean']>;
  stride?: Maybe<SportsDataStride>;
  timezone: Scalars['String'];
  to?: Maybe<Scalars['DateTime']>;
};


export type SportsUserFeedbackConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  teamId?: Maybe<Scalars['ID']>;
};


export type SportsUserFeedbackSuggestionListArgs = {
  teamId: Scalars['ID'];
  timezone: Scalars['String'];
};


export type SportsUserIsStarredByArgs = {
  starerId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type SportsUserLatestRawBodyArgs = {
  cursor?: Maybe<Scalars['DateTime']>;
  heightUnit?: HeightUnit;
  timezone: Scalars['String'];
  weightUnit?: WeightUnit;
};


export type SportsUserNotCheckedAlarmArgs = {
  timezone: Scalars['String'];
};


export type SportsUserPastInjuryListArgs = {
  teamId: Scalars['ID'];
};


export type SportsUserRawBodyArgs = {
  heightUnit?: HeightUnit;
  id: Scalars['ID'];
  weightUnit?: WeightUnit;
};


export type SportsUserRawConditionArgs = {
  id: Scalars['ID'];
};


export type SportsUserRawSleepArgs = {
  id: Scalars['ID'];
};


export type SportsUserRawWellnessArgs = {
  id: Scalars['ID'];
};


export type SportsUserRawWorkloadArgs = {
  id: Scalars['ID'];
};


export type SportsUserRoutineListArgs = {
  cursor?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
};


export type SportsUserSportsDataArgs = {
  date: Scalars['String'];
  timezone: Scalars['String'];
};


export type SportsUserSportsDataListArgs = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type SportsUserTeamMapsByPageArgs = {
  filter?: Maybe<TeamUserMapFilterInput>;
  filterBy?: Maybe<TeamUserFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<TeamMapOrderByInput>;
};


export type SportsUserTeamsByPageArgs = {
  activeOnly?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TeamFilterInput>;
  filterBy?: Maybe<TeamFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SportsUserTranslatedNationalityArgs = {
  input: MultilingualTextInput;
};


export type SportsUserTranslatedSportsCategoryArgs = {
  input: MultilingualTextInput;
};


export type SportsUserTranslatedSportsPositionArgs = {
  input: MultilingualTextInput;
};


export type SportsUserWorkloadArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type SportsUserByPage = {
  __typename: 'SportsUserByPage';
  entries: Array<SportsUser>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type SportsUserList = {
  __typename: 'SportsUserList';
  count: Scalars['Int'];
  userList: Array<SportsUser>;
};

export type SportsUserSorenessDataValue = {
  __typename: 'SportsUserSorenessDataValue';
  level?: Maybe<Scalars['Int']>;
  time: Scalars['DateTimeString'];
};

export type SportsUserSorenessHistory = {
  __typename: 'SportsUserSorenessHistory';
  monthlyData: SportsUserSorenessHistoryMonthly;
  recentData: SportsUserSorenessRecentData;
  weeklyData: SportsUserSorenessHistoryWeekly;
};


export type SportsUserSorenessHistoryMonthlyDataArgs = {
  bodyPart: SportsBodyPartName;
  date: Scalars['DateTime'];
};


export type SportsUserSorenessHistoryRecentDataArgs = {
  bodyPart: SportsBodyPartName;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SportsUserSorenessHistoryWeeklyDataArgs = {
  bodyPart: SportsBodyPartName;
  date: Scalars['DateTime'];
};

export type SportsUserSorenessHistoryMonthly = {
  __typename: 'SportsUserSorenessHistoryMonthly';
  bodyPart: SportsBodyPart;
  curr: Array<SportsUserSorenessDataValue>;
  from: Scalars['DateTimeString'];
  next: SportsUserSorenessDataValue;
  prev: SportsUserSorenessDataValue;
  to: Scalars['DateTimeString'];
};


export type SportsUserSorenessHistoryMonthlyBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SportsUserSorenessHistoryWeekly = {
  __typename: 'SportsUserSorenessHistoryWeekly';
  bodyPart: SportsBodyPart;
  curr: Array<SportsUserSorenessDataValue>;
  from: Scalars['DateTimeString'];
  next: SportsUserSorenessDataValue;
  prev: SportsUserSorenessDataValue;
  to: Scalars['DateTimeString'];
};


export type SportsUserSorenessHistoryWeeklyBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SportsUserSorenessRecentData = {
  __typename: 'SportsUserSorenessRecentData';
  bodyPart: SportsBodyPart;
  hasNext: Scalars['Boolean'];
  total: Scalars['Int'];
  values: Array<SportsUserSorenessRecentDataValue>;
};


export type SportsUserSorenessRecentDataBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SportsUserSorenessRecentDataValue = {
  __typename: 'SportsUserSorenessRecentDataValue';
  isContact?: Maybe<Contact>;
  level: Scalars['Int'];
  memo: Scalars['String'];
  time: Scalars['DateTimeString'];
};

export type StarredToggleResult = {
  __typename: 'StarredToggleResult';
  status: Scalars['Boolean'];
};

export type StartSubscriptionInput = {
  buyerEmail: Scalars['String'];
  buyerName: Scalars['String'];
  buyerTel: Scalars['String'];
  couponId?: Maybe<Scalars['ID']>;
  planId: Scalars['ID'];
  planOptionId: Scalars['ID'];
  subscriptionId?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type Statistic = {
  __typename: 'Statistic';
  avg: Scalars['Float'];
  max: Scalars['Float'];
  min: Scalars['Float'];
  sum: Scalars['Float'];
};


export type SubscriptionItem = {
  __typename: 'SubscriptionItem';
  coupon: UseCoupon;
  cycleAnchor?: Maybe<Scalars['String']>;
  endAt: Scalars['Date'];
  id: Scalars['ID'];
  invoicesByPage: InvoiceList;
  isActivate: Scalars['Boolean'];
  isFreeTrial: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  isSubscripting: Scalars['Boolean'];
  plan: PlanWithOption;
  planId: Scalars['String'];
  planOptionId: Scalars['String'];
  startAt: Scalars['Date'];
  status: SubscriptionStatusEnum;
  teamId: Scalars['String'];
};


export type SubscriptionItemInvoicesByPageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type SubscriptionResponse = {
  __typename: 'SubscriptionResponse';
  paymentList: Array<Invoice>;
  subscription: SubscriptionItem;
};

export enum SubscriptionStatusEnum {
  ACTIVATE = 'ACTIVATE',
  GRACE_PERIOD = 'GRACE_PERIOD',
  INACTIVATE = 'INACTIVATE'
}

export type TargetOption = {
  __typename: 'TargetOption';
  operator: BannerOptionOperator;
  option: BannerOption;
  value: Scalars['StringOrFloat'];
};

export type TargetOptionInput = {
  operator: BannerOptionOperator;
  option: BannerOption;
  value: Scalars['StringOrFloat'];
};

export type TeamCheckReminderSchedule = {
  __typename: 'TeamCheckReminderSchedule';
  id: Scalars['ID'];
  pushMessageType: ReminderPushMessageType;
  scheduleTime: Scalars['String'];
  team: SportsTeam;
};

export type TeamCheckReminderScheduleByPage = {
  __typename: 'TeamCheckReminderScheduleByPage';
  teamCheckReminderScheduleList: Array<TeamCheckReminderSchedule>;
  total: Scalars['Float'];
};

export type TeamCheckReminderScheduleFilter = {
  pushMessageTypeList?: Maybe<Array<ReminderPushMessageType>>;
  scheduleTimeList?: Maybe<Array<Scalars['String']>>;
};

export enum TeamField {
  name = 'name',
  region = 'region',
  slug = 'slug',
  sportsCategory = 'sportsCategory'
}

export type TeamFilter = {
  searchTerm?: Maybe<Scalars['String']>;
};

export type TeamFilterExpressionInput = {
  field: TeamField;
  op: QueryOperation;
  value: Scalars['String'];
};

export type TeamFilterInput = {
  and?: Maybe<Array<TeamFilterInput>>;
  expression?: Maybe<TeamFilterExpressionInput>;
  not?: Maybe<TeamFilterInput>;
  or?: Maybe<Array<TeamFilterInput>>;
};

export type TeamGroup = {
  __typename: 'TeamGroup';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy: SportsUser;
  groupMembers: Array<TeamGroupUser>;
  id: Scalars['ID'];
  name: Scalars['String'];
  orderRanking?: Maybe<Scalars['String']>;
  team: SportsTeam;
  teamId: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  userId: Scalars['String'];
};

export type TeamGroupUser = {
  __typename: 'TeamGroupUser';
  groups: Array<TeamGroup>;
  id: Scalars['ID'];
  team: SportsTeam;
  user: SportsUser;
};

export type TeamInvitationRequest = {
  __typename: 'TeamInvitationRequest';
  id: Scalars['ID'];
  prevTeamAuthority?: Maybe<SportsTeamAuthority>;
  role?: Maybe<SportsRole>;
  status: SportsTeamInvitationRequestStatus;
  teamAuthority?: Maybe<SportsTeamAuthority>;
  user: SportsUser;
};

export enum TeamMapField {
  createdAt = 'createdAt'
}

export type TeamMapOrderByInput = {
  field: TeamMapField;
  sort: SimpleOrder;
};

export type TeamSportsData = {
  __typename: 'TeamSportsData';
  chartData: ChartData;
  checkedList: Array<CheckedData>;
  conditionCareUserList: Array<ConditionCareUser>;
  coordinatingConditionReportData: CoordinatingConditionReportData;
  coordinatingMatchReportDataList: Array<CoordinatingMatchReportData>;
  coordinatingTrainingReportDataList: Array<CoordinatingTrainingReportData>;
  currentInjuryUserList: InjuryUserList;
  currentSorenessUserList: Array<CurrentSorenessUser>;
  dailyData: DailyTeamData;
  injuryList: Array<Injury>;
  latestFeedbackList: Array<Feedback>;
  riskAtInjuryUserList: Array<RiskAtInjuryUser>;
  sorenessLevel5UserList: Array<SorenessUser>;
  weeklyWorkloadSum: WeeklyWorkloadSum;
};


export type TeamSportsDataChartDataArgs = {
  cursor: Scalars['DateTime'];
  dataName: ChartDataName;
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  timezone: Scalars['String'];
};


export type TeamSportsDataCheckedListArgs = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type TeamSportsDataConditionCareUserListArgs = {
  timezone: Scalars['String'];
};


export type TeamSportsDataCoordinatingConditionReportDataArgs = {
  date: Scalars['DateTime'];
  timezone: Scalars['String'];
};


export type TeamSportsDataCoordinatingMatchReportDataListArgs = {
  date: Scalars['DateTime'];
  timezone: Scalars['String'];
};


export type TeamSportsDataCoordinatingTrainingReportDataListArgs = {
  date: Scalars['DateTime'];
  timezone: Scalars['String'];
};


export type TeamSportsDataCurrentSorenessUserListArgs = {
  timezone: Scalars['String'];
};


export type TeamSportsDataDailyDataArgs = {
  cursor: Scalars['DateTime'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  timezone: Scalars['String'];
};


export type TeamSportsDataRiskAtInjuryUserListArgs = {
  timezone: Scalars['String'];
};


export type TeamSportsDataSorenessLevel5UserListArgs = {
  date: Scalars['DateTime'];
};


export type TeamSportsDataWeeklyWorkloadSumArgs = {
  groupId?: Maybe<Scalars['String']>;
  scheduleOnly: Scalars['Boolean'];
  timezone: Scalars['String'];
};

export enum TeamType {
  ORG = 'ORG',
  TEAM = 'TEAM'
}

export type TeamUserFilter = {
  teamAuthority?: Maybe<SportsTeamAuthority>;
};

export type TeamUserInput = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type TeamUserListInput = {
  teamId: Scalars['ID'];
  userIdList?: Maybe<Array<Scalars['ID']>>;
};

export enum TeamUserMapField {
  groupId = 'groupId',
  position = 'position',
  role = 'role',
  teamAuthority = 'teamAuthority',
  userId = 'userId',
  userName = 'userName'
}

export type TeamUserMapFilterExpressionInput = {
  field: TeamUserMapField;
  op: QueryOperation;
  value: Scalars['String'];
};

export type TeamUserMapFilterInput = {
  and?: Maybe<Array<TeamUserMapFilterInput>>;
  expression?: Maybe<TeamUserMapFilterExpressionInput>;
  not?: Maybe<TeamUserMapFilterInput>;
  or?: Maybe<Array<TeamUserMapFilterInput>>;
};

export enum TeamUserMapForTeamDataField {
  groupId = 'groupId',
  position = 'position',
  role = 'role',
  teamAuthority = 'teamAuthority',
  userId = 'userId'
}

export type TeamUserMapForTeamDataFilterExpressionInput = {
  field: TeamUserMapForTeamDataField;
  op: QueryOperation;
  value: Scalars['String'];
};

export type TeamUserMapForTeamDataFilterInput = {
  and?: Maybe<Array<TeamUserMapForTeamDataFilterInput>>;
  expression?: Maybe<TeamUserMapForTeamDataFilterExpressionInput>;
  not?: Maybe<TeamUserMapForTeamDataFilterInput>;
  or?: Maybe<Array<TeamUserMapForTeamDataFilterInput>>;
};


export type TimeSerializable = {
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
};

export type Timezone = {
  __typename: 'Timezone';
  value: Scalars['String'];
};

export type ToggleInput = {
  routineId: Scalars['ID'];
  status: RoutineActivityStatus;
  timezone: Scalars['String'];
  toggledAt: Scalars['Date'];
};

export type ToggleTeamUserStarredInput = {
  starerId: Scalars['ID'];
  status: Scalars['Boolean'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Training = Event & {
  __typename: 'Training';
  attendeeChartData: AttendeeChartData;
  endAt: Scalars['Date'];
  eventInvitees: Array<TrainingInvitee>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  specialNote?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  trainingPoint?: Maybe<Scalars['String']>;
  type: ScheduleType;
  workloadCheckedUserCount: Scalars['Float'];
};

export type TrainingInvitee = EventInvitee & {
  __typename: 'TrainingInvitee';
  absenceReason?: Maybe<Scalars['String']>;
  event: Event;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  status?: Maybe<EventInviteeStatus>;
  /** the player who is invited to this event */
  user?: Maybe<SportsUser>;
  workoutType?: Maybe<WorkoutType>;
};

export type TrainingInviteeSchedule = ScheduleInvitee & {
  __typename: 'TrainingInviteeSchedule';
  absenceReason?: Maybe<Scalars['String']>;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  schedule?: Maybe<Schedule>;
  status?: Maybe<ScheduleInviteeStatus>;
  team?: Maybe<SportsTeam>;
  /** the player who is invited to this schedule */
  user?: Maybe<SportsUser>;
  workoutType: WorkoutType;
};


export type TrainingInviteeScheduleScheduleArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export type TrainingReportSolution = {
  __typename: 'TrainingReportSolution';
  solutionUserList: Array<TrainingReportSolutionUser>;
  trainingWorkloadTeamSolution: TrainingWorkloadTeamSolution;
};

export type TrainingReportSolutionUser = {
  __typename: 'TrainingReportSolutionUser';
  user: SportsUser;
  workloadSolutionList: Array<TrainingWorkloadSolution>;
};

export type TrainingSchedule = Schedule & {
  __typename: 'TrainingSchedule';
  attendeeChartData: AttendeeChartDataSchedule;
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['Time']>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  fileList?: Maybe<Array<ScheduleFile>>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isPlayerNotice: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  scheduleDate: Scalars['String'];
  scheduleInviteeList: Array<TrainingInviteeSchedule>;
  startTime?: Maybe<Scalars['Time']>;
  team?: Maybe<SportsTeam>;
  title?: Maybe<Scalars['String']>;
  trainingPoint?: Maybe<Scalars['String']>;
  type: ScheduleType;
  workloadCheckedUserCount: Scalars['Float'];
};

export type TrainingScheduleForCoordinatingReport = {
  __typename: 'TrainingScheduleForCoordinatingReport';
  detailType: ScheduleDetailType;
  endTime?: Maybe<Scalars['Time']>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isPlayerNotice: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  scheduleDate: Scalars['String'];
  scheduleInviteeList: Array<TrainingInviteeSchedule>;
  startTime?: Maybe<Scalars['Time']>;
  title?: Maybe<Scalars['String']>;
  type: ScheduleType;
};

export type TrainingWorkloadSolution = {
  __typename: 'TrainingWorkloadSolution';
  guide: Scalars['String'];
  type: TrainingWorkloadSolutionGuideType;
};

export enum TrainingWorkloadSolutionGuideType {
  TE = 'TE',
  TF = 'TF',
  TG1 = 'TG1',
  TG2 = 'TG2',
  TG3 = 'TG3'
}

export type TrainingWorkloadTeamSolution = {
  __typename: 'TrainingWorkloadTeamSolution';
  guide: Scalars['String'];
  type: TrainingWorkloadTeamSolutionGuideType;
};

export enum TrainingWorkloadTeamSolutionGuideType {
  TA = 'TA',
  TB1 = 'TB1',
  TB2 = 'TB2',
  TB3 = 'TB3',
  TB4 = 'TB4',
  TB5 = 'TB5',
  TC1 = 'TC1',
  TC2 = 'TC2',
  TC3 = 'TC3',
  TC4 = 'TC4',
  TC5 = 'TC5',
  TD1 = 'TD1',
  TD2 = 'TD2',
  TD3 = 'TD3',
  TD4 = 'TD4',
  TD5 = 'TD5'
}

export type TransferTeamOwnership = {
  __typename: 'TransferTeamOwnership';
  adminTeamUserMap: SportsTeamUserMap;
  ownerTeamUserMap: SportsTeamUserMap;
};

export type UpdateAttendanceStatusInput = {
  absenceReason?: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
  status: EventInviteeStatus;
};

export type UpdateBannerInput = {
  createdAt: Scalars['Date'];
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  landingUrl?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  targetOptionList?: Maybe<Array<Maybe<TargetOptionInput>>>;
  title: Scalars['String'];
};

export type UpdateBillingBuyerEmailInput = {
  billingId?: Maybe<Scalars['ID']>;
  buyerEmail: Scalars['String'];
  identityToken: Scalars['ID'];
};

export type UpdateBillingBuyerNameInput = {
  billingId?: Maybe<Scalars['ID']>;
  buyerName: Scalars['String'];
  identityToken: Scalars['ID'];
};

export type UpdateBillingBuyerNumberInput = {
  billingId?: Maybe<Scalars['ID']>;
  buyerTel: Scalars['String'];
  identityToken: Scalars['ID'];
};

export type UpdateBillingTokenInput = {
  identityToken: Scalars['ID'];
  impUid: Scalars['String'];
};

export type UpdateBodyInput = {
  height: Scalars['Float'];
  heightUnit?: Maybe<HeightUnit>;
  id: Scalars['ID'];
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
  weight: Scalars['Float'];
  weightUnit?: Maybe<WeightUnit>;
};

export type UpdateConditionInput = {
  fatigue: Scalars['Int'];
  id: Scalars['ID'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  sleepDuration: Scalars['Int'];
  sleepQuality: Scalars['Int'];
  sleepTime: Scalars['DateTime'];
  stress: Scalars['Int'];
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type UpdateCouponInput = {
  discountType: CouponDiscountType;
  discountValue: Scalars['Int'];
  limitCount: Scalars['Int'];
};

export type UpdateEventInput = {
  competition?: Maybe<Scalars['String']>;
  endAt: Scalars['DateTime'];
  eventInviteIdentityList: Array<EventInviteIdentity>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: ScheduleType;
  venue?: Maybe<Venue>;
};

export type UpdateEventWorkloadDurationInput = {
  endDate: Scalars['DateTime'];
  eventId: Scalars['String'];
  startDate: Scalars['DateTime'];
  timezone: Scalars['String'];
  userIdList: Array<Scalars['ID']>;
};

export type UpdateFeedbackCommentInput = {
  comment: Scalars['String'];
  commentId: Scalars['ID'];
  feedbackId: Scalars['ID'];
};

export type UpdateFeedbackInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type UpdateInjuryInput = {
  bodyPart?: Maybe<SportsBodyPartName>;
  /** 최대 100자 */
  diagnosis?: Maybe<Scalars['String']>;
  historyStatus: InjuryHistoryStatus;
  id: Scalars['ID'];
  maintainFileIdList: Array<Scalars['ID']>;
  /** 최대 100자 */
  medicalRecord?: Maybe<Scalars['String']>;
  occurDate: Scalars['DateTime'];
  /** 최대 100자 */
  reason?: Maybe<Scalars['String']>;
  return: ReturnInput;
  status: InjuryStatus;
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UpdateInput = {
  routineId: Scalars['ID'];
  schedule?: Maybe<ScheduleInput>;
  title: Scalars['String'];
};

export type UpdateLicenseInput = {
  endAt: Scalars['DateTime'];
  licenseId: Scalars['ID'];
  startAt: Scalars['DateTime'];
};

export type UpdateLineup = {
  eventId: Scalars['String'];
  eventInviteeList: Array<LineupInvitee>;
};

export type UpdateLineupSchedule = {
  scheduleId: Scalars['String'];
  scheduleInviteeList: Array<LineupInviteeSchedule>;
};

export type UpdateMatchResultInput = {
  eventId: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  result?: Maybe<MatchResultStatus>;
  score?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UpdateMatchResultScheduleInput = {
  scheduleId: Scalars['String'];
  score?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UpdateNoWorkoutInput = {
  reasonForNoWorkout: NoWorkoutType;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type UpdateScheduleAttendanceStatusInput = {
  absenceReason?: Maybe<Scalars['String']>;
  scheduleId: Scalars['ID'];
  status: ScheduleInviteeStatus;
};

export type UpdateScheduleMatchPointInput = {
  matchPoint: Scalars['String'];
  scheduleId: Scalars['ID'];
};

export type UpdateSpecialNoteInput = {
  eventId: Scalars['ID'];
  specialNote: Scalars['String'];
};

export type UpdateSportsUserInput = {
  birthdate?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['ID']>;
  introduction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nationality?: Maybe<CountryCode>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberVerified?: Maybe<Scalars['Boolean']>;
  pictureFileId?: Maybe<Scalars['String']>;
  priorSportsCategory?: Maybe<SportsCategory>;
  priorSportsPosition?: Maybe<SportsPosition>;
  priorSportsRole?: Maybe<SportsRole>;
  pushConditionCheckReminderSchedule?: Maybe<Scalars['String']>;
  pushConditionReminderAllowed?: Maybe<Scalars['Boolean']>;
  pushMarketingAllowed?: Maybe<Scalars['Boolean']>;
  pushReminderAllowed?: Maybe<Scalars['Boolean']>;
  pushWorkloadCheckReminderSchedule?: Maybe<Scalars['String']>;
  pushWorkloadReminderAllowed?: Maybe<Scalars['Boolean']>;
};

export type UpdateTeamGroupInput = {
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  teamId: Scalars['ID'];
  userIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateTeamInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  pictureFileId?: Maybe<Scalars['String']>;
};

export type UpdateTrainingPointInput = {
  eventId: Scalars['ID'];
  trainingPoint: Scalars['String'];
};

export type UpdateTrainingPointScheduleInput = {
  scheduleId: Scalars['ID'];
  trainingPoint: Scalars['String'];
};

export type UpdateWorkloadInput = {
  duration: Scalars['Int'];
  id: Scalars['ID'];
  intensity: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  satisfaction: Scalars['Int'];
  scheduleId?: Maybe<Scalars['ID']>;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
  type: WorkoutType;
};


export type UploadBannerInput = {
  createdAt: Scalars['Date'];
  endAt?: Maybe<Scalars['Date']>;
  landingUrl?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  targetOptionList?: Maybe<Array<Maybe<TargetOptionInput>>>;
  title: Scalars['String'];
};

export type UploadCalendarInput = {
  endAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  scheduleDetailType?: Maybe<Array<ScheduleDetailType>>;
  startAt?: Maybe<Scalars['Date']>;
  teamId: Scalars['ID'];
  type?: Maybe<CalendarType>;
};

export type UploadCoordinatingReportInput = {
  endAt?: Maybe<Scalars['Date']>;
  reportType: ReportType;
  startAt: Scalars['Date'];
  teamIdList: Array<Scalars['ID']>;
};

export type UploadProfileResponse = {
  __typename: 'UploadProfileResponse';
  team?: Maybe<SportsTeam>;
  user?: Maybe<SportsUser>;
};

export type UploadScheduleReportInput = {
  scheduleDate: Scalars['Date'];
  scheduleId: Scalars['ID'];
  teamId: Scalars['ID'];
  type: ScheduleReportType;
};

export type UpsertScheduleAttendanceStatusInput = {
  absenceReason?: Maybe<Scalars['String']>;
  scheduleId: Scalars['ID'];
  status: ScheduleInviteeStatus;
};

export type UseCoupon = {
  __typename: 'UseCoupon';
  id?: Maybe<Scalars['ID']>;
  isUsed: Scalars['Boolean'];
};

export type UserBodyData = {
  __typename: 'UserBodyData';
  BMI?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<HeightUnit>;
  id?: Maybe<Scalars['ID']>;
  recommendedHydration?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
};

export enum UserConditionDataType {
  FATIGUE = 'FATIGUE',
  MOOD = 'MOOD',
  MUSCLE = 'MUSCLE',
  SLEEP_DURATION = 'SLEEP_DURATION',
  SLEEP_QUALITY = 'SLEEP_QUALITY',
  STRESS = 'STRESS'
}

export enum UserConditionLabelType {
  NOT_GOOD = 'NOT_GOOD',
  PLUMMET = 'PLUMMET'
}

export type UserDataOrderByInput = {
  cursor?: Maybe<Scalars['DateTime']>;
  field: UserDataType;
  sort: SimpleOrder;
  stride: UserDataStride;
  timezone: Scalars['String'];
};

export enum UserDataStride {
  DAY = 'DAY'
}

export enum UserDataType {
  condition = 'condition',
  createdAt = 'createdAt',
  fatigue = 'fatigue',
  height = 'height',
  mood = 'mood',
  muscle = 'muscle',
  name = 'name',
  sleepDuration = 'sleepDuration',
  sleepQuality = 'sleepQuality',
  stress = 'stress',
  weight = 'weight',
  worklaodACWR = 'worklaodACWR',
  worklaodAX = 'worklaodAX',
  worklaodCX = 'worklaodCX',
  workload = 'workload',
  workloadAXD = 'workloadAXD',
  workloadDuration = 'workloadDuration',
  workloadIntensity = 'workloadIntensity',
  workloadSatisfaction = 'workloadSatisfaction'
}

export type UserFilter = {
  searchTerm?: Maybe<Scalars['String']>;
};

export enum UserSorenessLabelType {
  MULTIPLE = 'MULTIPLE',
  SEVERE = 'SEVERE'
}

export type UserWorkloadData = {
  __typename: 'UserWorkloadData';
  acwr?: Maybe<Scalars['Float']>;
  aw?: Maybe<Scalars['Int']>;
  awd?: Maybe<Scalars['Float']>;
  awi?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  cw?: Maybe<Scalars['Int']>;
  duration: Statistic;
  id?: Maybe<Scalars['ID']>;
  intensity: Statistic;
  reasonForNoWorkout?: Maybe<NoWorkoutType>;
  satisfaction: Statistic;
  time: Scalars['Date'];
  workload: Statistic;
  workloadList: Array<SportsRawWorkload>;
};

export enum UserWorkloadRiskAtInjuryLabelType {
  RISK_CAUTION = 'RISK_CAUTION',
  RISK_DETECTED = 'RISK_DETECTED',
  RISK_INCREASED = 'RISK_INCREASED'
}

export enum UserWorkloadRiskAtInjuryReasonType {
  HIGH_ACWR = 'HIGH_ACWR',
  HIGH_WEEKLY_WORKLOAD = 'HIGH_WEEKLY_WORKLOAD',
  LOW_ACWR = 'LOW_ACWR',
  MONOTONY = 'MONOTONY',
  NON_ELASTIC_INTENSITY = 'NON_ELASTIC_INTENSITY',
  STRAIN = 'STRAIN',
  WEEKLY_WORKLOAD_SURGE = 'WEEKLY_WORKLOAD_SURGE'
}

export enum UserWorkloadSuggestionDataType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X'
}

export enum Venue {
  AWAY = 'AWAY',
  ETC = 'ETC',
  HOME = 'HOME'
}

export type VersionRevision = {
  __typename: 'VersionRevision';
  appId: Scalars['String'];
  enableUserDelete: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  forceUpdate: Scalars['Boolean'];
  id: Scalars['ID'];
  optionalUpdate: Scalars['Boolean'];
  os?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type WeeklyWorkloadSum = {
  __typename: 'WeeklyWorkloadSum';
  lastWeek: Array<Scalars['Int']>;
  thisWeek: Array<Scalars['Int']>;
};

export enum WeightUnit {
  kg = 'kg',
  lb = 'lb'
}

export type Workload = {
  __typename: 'Workload';
  duration: Scalars['Int'];
  event?: Maybe<EventInvitee>;
  id: Scalars['ID'];
  intensity: Scalars['Int'];
  isUpdatedByCoach: Scalars['Boolean'];
  load: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  satisfaction: Scalars['Int'];
  schedule?: Maybe<ScheduleInvitee>;
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  type: WorkoutType;
};

export type WorkloadData = {
  __typename: 'WorkloadData';
  acwr?: Maybe<Scalars['Float']>;
  aw?: Maybe<Scalars['Int']>;
  awd?: Maybe<Scalars['Float']>;
  awi?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  cw?: Maybe<Scalars['Int']>;
  duration: Statistic;
  id?: Maybe<Scalars['ID']>;
  intensity: Statistic;
  isChecked: Scalars['Boolean'];
  reasonForNoWorkout?: Maybe<NoWorkoutType>;
  satisfaction: Statistic;
  workload: Statistic;
  workloadList: Array<Workload>;
};

export enum WorkoutType {
  NORMAL = 'NORMAL',
  REHAB = 'REHAB'
}

export const UserInjuryDataFragmentDoc = gql`
    fragment UserInjuryData on Injury {
  id
  bodyPart(input: $input) {
    id
    value
  }
  occurDate
  historyStatus
  reason
  diagnosis
  medicalRecord
  return {
    date
    info
    status
  }
  fileList {
    id
    name
    url
  }
  status
}
    `;
export const FeedbackDataFragmentDoc = gql`
    fragment FeedbackData on Feedback {
  id
  date
  type
  message
  contentsData {
    ... on FeedbackWorkloadMemoData {
      duration
      time
      timezone
      memo
      scheduleData {
        type
        detailType
        title
        location
        scheduleDate
        startTime
        endTime
      }
    }
    ... on FeedbackSorenessSevereData {
      bodyPart(input: $multilingualTextInput) {
        id
        value
        front
        back
        left
        right
      }
      level
      memo
      isContact
    }
    ... on FeedbackConditionNotGoodData {
      dataList
      mood
      fatigue
      stress
      muscle
      sleepDuration
      sleepDurationScore
      sleepQuality
    }
    ... on FeedbackConditionPlummetData {
      dataList
      mood28dayList
      fatigue28dayList
      stress28dayList
      muscle28dayList
      sleepDuration28dayList
      sleepQuality28dayList
    }
    ... on FeedbackWorkloadRiskAtInjuryData {
      label
      analysisType
      reasonList
    }
  }
  commentList {
    id
    comment
    isUpdated
    createdAt
    user {
      id
      pictureUrl
      name
    }
  }
  isUpdated
  createUser {
    id
    name
    pictureUrl
    gender
  }
  player {
    id
    name
    pictureUrl
  }
  createdAt
  teamId
  team {
    id
  }
}
    `;
export const DrawerPlayersUserMapFragmentDoc = gql`
    fragment DrawerPlayersUserMap on SportsTeamUserMap {
  id
  user {
    id
    name
    pictureUrl
    priorSportsCategory {
      id
      group
    }
    priorSportsPosition
    priorSportsRole
  }
  isStarredByViewer
}
    `;
export const ModalTeamCreateTeamFragmentDoc = gql`
    fragment ModalTeamCreateTeam on SportsTeam {
  id
  name
  pictureUrl
  sportsCategory {
    id
    group
  }
  region {
    id
  }
  createdAt
  allUsers: userMapsByPage(
    filter: {expression: {field: teamAuthority, op: in, value: "[\\"PLCO:OWNER\\", \\"PLCO:ADMIN\\", \\"PLCO:MEMBER\\"]"}}
  ) {
    total
  }
  players: userMapsByPage(
    filter: {expression: {field: teamAuthority, op: eq, value: "PLCO:MEMBER"}}
  ) {
    total
  }
}
    `;
export const NavbarTeamsTeamFragmentDoc = gql`
    fragment NavbarTeamsTeam on SportsTeam {
  id
  name
  pictureUrl
  sportsCategory {
    id
    group
  }
  region {
    id
  }
  createdAt
  allUsers: userMapsByPage(
    filter: {expression: {field: teamAuthority, op: in, value: "[\\"PLCO:OWNER\\", \\"PLCO:ADMIN\\", \\"PLCO:MEMBER\\"]"}}
  ) {
    total
  }
  players: userMapsByPage(
    filter: {expression: {field: teamAuthority, op: eq, value: "PLCO:MEMBER"}}
  ) {
    total
  }
}
    `;
export const SectionPaymentPlanOptionsSubscriptionFragmentDoc = gql`
    fragment SectionPaymentPlanOptionsSubscription on SubscriptionItem {
  id
  plan {
    id
    planOptionInfo {
      id
    }
  }
}
    `;
export const WidgetSorenessUserListFragmentDoc = gql`
    fragment WidgetSorenessUserList on SorenessUser {
  user {
    id
    name
    pictureUrl
  }
  sorenessList {
    bodyPart(input: $input) {
      value
    }
  }
}
    `;
export const PlayerSummaryIdentityFragmentDoc = gql`
    fragment PlayerSummaryIdentity on SportsUser {
  pictureUrl
  name
  gender
  birthdate
  priorSportsCategory {
    id
    group
  }
  priorSportsPosition
  priorSportsRole
}
    `;
export const FSportsTeamFragmentDoc = gql`
    fragment FSportsTeam on SportsTeam {
  id
  type
  region {
    id
  }
  sportsCategory {
    id
  }
  slug
  name
  code
  viewerAuthority
  pictureUrl
  allUsers: userMapsByPage {
    total
  }
  players: userMapsByPage(filterBy: {teamAuthority: MEMBER}) {
    total
  }
}
    `;
export const FSportsTeamInvitationRequestFragmentDoc = gql`
    fragment FSportsTeamInvitationRequest on SportsTeamInvitationRequest {
  id
  status
  teamAuthority
  user {
    id
  }
  team {
    ...FSportsTeam
  }
}
    ${FSportsTeamFragmentDoc}`;
export const FSportsTeamUserMapFragmentDoc = gql`
    fragment FSportsTeamUserMap on SportsTeamUserMap {
  id
  teamAuthority
  team {
    subscriptionItem {
      id
      planId
    }
    ...FSportsTeam
  }
}
    ${FSportsTeamFragmentDoc}`;
export const FSportsUserFragmentDoc = gql`
    fragment FSportsUser on SportsUser {
  id
  slug
  name
  introduction
  pictureUrl
  priorSportsCategory {
    id
    group
  }
  priorSportsPosition
  priorSportsRole
  createdAt
  birthdate
  email
  federation
  gender
  name
  nationality
  phoneNumber
}
    `;
export const FTeamGroupFragmentDoc = gql`
    fragment FTeamGroup on TeamGroup {
  id
  name
  orderRanking
  teamId
  userId
  createdAt
  updatedAt
}
    `;
export const PaymentSubscrptionFragmentDoc = gql`
    fragment PaymentSubscrption on SubscriptionItem {
  id
  status
  cycleAnchor
  startAt
  endAt
  teamId
  planId
  planOptionId
  plan {
    id
    planName
    planType
    planOptionInfo {
      id
    }
  }
  isSubscripting
  isRefundable
  isFreeTrial
  isActivate
  coupon {
    id
    isUsed
  }
}
    `;
export const AnalyticsTeamFragmentDoc = gql`
    fragment AnalyticsTeam on SportsTeam {
  id
  name
  pictureUrl
  code
  allUsers: userMapsByPage {
    total
  }
  players: userMapsByPage(filterBy: {teamAuthority: MEMBER}) {
    total
  }
}
    `;
export const AnalyticsViewerFragmentDoc = gql`
    fragment AnalyticsViewer on SportsUser {
  id
  name
  pictureUrl
  priorSportsRole
  priorSportsCategory {
    id
    group
  }
  email
  birthdate
  phoneNumber
  gender
  nationality
  createdAt
  teamMaps {
    id
    teamAuthority
    team {
      ...AnalyticsTeam
    }
  }
}
    ${AnalyticsTeamFragmentDoc}`;
export const InvoiceBillingItemFragmentDoc = gql`
    fragment InvoiceBillingItem on Billing {
  id
  identityToken
  customerUid
  cardName
  cardNumber
  buyerName
  buyerTel
  buyerEmail
}
    `;
export const PlanOptionInfoFragmentDoc = gql`
    fragment PlanOptionInfo on PlanOptionWithCoupon {
  id
  currency
  description
  originalPrice
  discountedPrice
  duration
  maxPlayerCount
  maxTeamCount
  nextPaymentDay
  originalPrice
  period
  planOptionName
}
    `;
export const TGroupMemberMapFragmentDoc = gql`
    fragment TGroupMemberMap on TeamGroup {
  id
  name
  orderRanking
  groupMembers {
    user {
      id
      name
      pictureUrl
      nationality
    }
  }
}
    `;
export const PlanBillingPayInvoiceInfoFragmentDoc = gql`
    fragment PlanBillingPayInvoiceInfo on PayInvoice {
  id
  paidAt
  paymentName
  paymentStatus
  amount
  cardName
  cardNumber
  receiptUrl
}
    `;
export const PlanBillingTeamSubscriptionFragmentDoc = gql`
    fragment PlanBillingTeamSubscription on SubscriptionItem {
  invoicesByPage(limit: $limit, offset: $offset) {
    offset
    limit
    total
    hasNext
    hasPrev
    invoiceList {
      ...PlanBillingPayInvoiceInfo
      ... on RefundInvoice {
        id
        refundAt
        paymentName
        paymentStatus
        refundAmount
        cardName
        cardNumber
        receiptUrl
      }
    }
  }
  id
  status
  cycleAnchor
  startAt
  endAt
  teamId
  isSubscripting
  isRefundable
  isFreeTrial
  coupon {
    id
    isUsed
  }
  plan {
    id
    planName
    planType
    planOptionInfo {
      id
      planOptionName
      description
      amount
      currency
      period
      duration
      maxPlayerCount
      maxTeamCount
    }
  }
}
    ${PlanBillingPayInvoiceInfoFragmentDoc}`;
export const PlanPayPricingSubscriptionFragmentDoc = gql`
    fragment PlanPayPricingSubscription on SubscriptionItem {
  id
  status
  cycleAnchor
  startAt
  endAt
  teamId
  isSubscripting
  isRefundable
  isFreeTrial
  plan {
    id
    planName
    planType
    planOptionInfo {
      id
      planOptionName
      description
      currency
      period
      duration
      maxPlayerCount
      maxTeamCount
      amount
    }
  }
}
    `;
export const PlanPayPricingBillingItemFragmentDoc = gql`
    fragment PlanPayPricingBillingItem on Billing {
  id
  identityToken
  customerUid
  cardName
  cardNumber
  buyerName
  buyerTel
  buyerEmail
}
    `;
export const TRegisterViewerFragmentDoc = gql`
    fragment TRegisterViewer on SportsUser {
  id
  priorSportsCategory {
    id
    group
  }
  priorSportsPosition
  pictureUrl
  name
  gender
  birthdate
  nationality
  phoneNumber
}
    `;
export const NavigationViewerFragmentDoc = gql`
    fragment NavigationViewer on SportsUser {
  id
  name
  slug
  pictureUrl
  priorSportsRole
  priorSportsCategory {
    id
    group
  }
  priorSportsPosition
  createdAt
}
    `;
export const NavigationTeamSubscriptionFragmentDoc = gql`
    fragment NavigationTeamSubscription on SubscriptionItem {
  id
  status
  teamId
  isFreeTrial
  isSubscripting
  startAt
  endAt
  planId
  planOptionId
  plan {
    id
    planType
    planOptionInfo {
      id
      maxPlayerCount
      maxTeamCount
      amount
      currency
      period
      description
      planOptionName
      nextPaymentDay
    }
  }
}
    `;
export const NavigationTeamLicenseFragmentDoc = gql`
    fragment NavigationTeamLicense on License {
  id
  isActivate
}
    `;
export const CardDiscountDetailsCouponContentsDocument = gql`
    query CardDiscountDetailsCouponContents($teamId: ID!) {
  couponListByTeam(teamId: $teamId) {
    content {
      couponId
      name
    }
  }
}
    `;

/**
 * __useCardDiscountDetailsCouponContentsQuery__
 *
 * To run a query within a React component, call `useCardDiscountDetailsCouponContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardDiscountDetailsCouponContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardDiscountDetailsCouponContentsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useCardDiscountDetailsCouponContentsQuery(baseOptions: Apollo.QueryHookOptions<CardDiscountDetailsCouponContentsQuery, CardDiscountDetailsCouponContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardDiscountDetailsCouponContentsQuery, CardDiscountDetailsCouponContentsQueryVariables>(CardDiscountDetailsCouponContentsDocument, options);
      }
export function useCardDiscountDetailsCouponContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardDiscountDetailsCouponContentsQuery, CardDiscountDetailsCouponContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardDiscountDetailsCouponContentsQuery, CardDiscountDetailsCouponContentsQueryVariables>(CardDiscountDetailsCouponContentsDocument, options);
        }
export type CardDiscountDetailsCouponContentsQueryHookResult = ReturnType<typeof useCardDiscountDetailsCouponContentsQuery>;
export type CardDiscountDetailsCouponContentsLazyQueryHookResult = ReturnType<typeof useCardDiscountDetailsCouponContentsLazyQuery>;
export type CardDiscountDetailsCouponContentsQueryResult = Apollo.QueryResult<CardDiscountDetailsCouponContentsQuery, CardDiscountDetailsCouponContentsQueryVariables>;
export function refetchCardDiscountDetailsCouponContentsQuery(variables?: CardDiscountDetailsCouponContentsQueryVariables) {
      return { query: CardDiscountDetailsCouponContentsDocument, variables: variables }
    }
export const InvitationRequestBadgeRequestsDocument = gql`
    query InvitationRequestBadgeRequests {
  sports {
    viewer {
      id
      teamMaps {
        id
        team {
          id
          pendingInvitationRequestList {
            id
            status
          }
        }
      }
    }
  }
}
    `;

/**
 * __useInvitationRequestBadgeRequestsQuery__
 *
 * To run a query within a React component, call `useInvitationRequestBadgeRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationRequestBadgeRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationRequestBadgeRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvitationRequestBadgeRequestsQuery(baseOptions?: Apollo.QueryHookOptions<InvitationRequestBadgeRequestsQuery, InvitationRequestBadgeRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationRequestBadgeRequestsQuery, InvitationRequestBadgeRequestsQueryVariables>(InvitationRequestBadgeRequestsDocument, options);
      }
export function useInvitationRequestBadgeRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationRequestBadgeRequestsQuery, InvitationRequestBadgeRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationRequestBadgeRequestsQuery, InvitationRequestBadgeRequestsQueryVariables>(InvitationRequestBadgeRequestsDocument, options);
        }
export type InvitationRequestBadgeRequestsQueryHookResult = ReturnType<typeof useInvitationRequestBadgeRequestsQuery>;
export type InvitationRequestBadgeRequestsLazyQueryHookResult = ReturnType<typeof useInvitationRequestBadgeRequestsLazyQuery>;
export type InvitationRequestBadgeRequestsQueryResult = Apollo.QueryResult<InvitationRequestBadgeRequestsQuery, InvitationRequestBadgeRequestsQueryVariables>;
export function refetchInvitationRequestBadgeRequestsQuery(variables?: InvitationRequestBadgeRequestsQueryVariables) {
      return { query: InvitationRequestBadgeRequestsDocument, variables: variables }
    }
export const CardGroupSummaryProgressBarDataDocument = gql`
    query CardGroupSummaryProgressBarData($teamId: ID!, $cursor: DateTime!, $timezone: String!, $filter: TeamUserMapForTeamDataFilterInput) {
  sports {
    team(id: $teamId) {
      id
      dailyData(cursor: $cursor, timezone: $timezone, filter: $filter) {
        readiness {
          value
          varianceFromYesterday
        }
      }
    }
  }
}
    `;

/**
 * __useCardGroupSummaryProgressBarDataQuery__
 *
 * To run a query within a React component, call `useCardGroupSummaryProgressBarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardGroupSummaryProgressBarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardGroupSummaryProgressBarDataQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      cursor: // value for 'cursor'
 *      timezone: // value for 'timezone'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCardGroupSummaryProgressBarDataQuery(baseOptions: Apollo.QueryHookOptions<CardGroupSummaryProgressBarDataQuery, CardGroupSummaryProgressBarDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardGroupSummaryProgressBarDataQuery, CardGroupSummaryProgressBarDataQueryVariables>(CardGroupSummaryProgressBarDataDocument, options);
      }
export function useCardGroupSummaryProgressBarDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardGroupSummaryProgressBarDataQuery, CardGroupSummaryProgressBarDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardGroupSummaryProgressBarDataQuery, CardGroupSummaryProgressBarDataQueryVariables>(CardGroupSummaryProgressBarDataDocument, options);
        }
export type CardGroupSummaryProgressBarDataQueryHookResult = ReturnType<typeof useCardGroupSummaryProgressBarDataQuery>;
export type CardGroupSummaryProgressBarDataLazyQueryHookResult = ReturnType<typeof useCardGroupSummaryProgressBarDataLazyQuery>;
export type CardGroupSummaryProgressBarDataQueryResult = Apollo.QueryResult<CardGroupSummaryProgressBarDataQuery, CardGroupSummaryProgressBarDataQueryVariables>;
export function refetchCardGroupSummaryProgressBarDataQuery(variables?: CardGroupSummaryProgressBarDataQueryVariables) {
      return { query: CardGroupSummaryProgressBarDataDocument, variables: variables }
    }
export const FeedbackListDocument = gql`
    query FeedbackList($userId: ID!, $teamId: ID!, $multilingualTextInput: MultilingualTextInput!, $after: String, $before: String, $last: Float, $first: Float) {
  sports {
    user(id: $userId) {
      id
      feedbackConnection(
        teamId: $teamId
        after: $after
        before: $before
        last: $last
        first: $first
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...FeedbackData
          }
          cursor
        }
      }
    }
  }
}
    ${FeedbackDataFragmentDoc}`;

/**
 * __useFeedbackListQuery__
 *
 * To run a query within a React component, call `useFeedbackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFeedbackListQuery(baseOptions: Apollo.QueryHookOptions<FeedbackListQuery, FeedbackListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedbackListQuery, FeedbackListQueryVariables>(FeedbackListDocument, options);
      }
export function useFeedbackListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedbackListQuery, FeedbackListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedbackListQuery, FeedbackListQueryVariables>(FeedbackListDocument, options);
        }
export type FeedbackListQueryHookResult = ReturnType<typeof useFeedbackListQuery>;
export type FeedbackListLazyQueryHookResult = ReturnType<typeof useFeedbackListLazyQuery>;
export type FeedbackListQueryResult = Apollo.QueryResult<FeedbackListQuery, FeedbackListQueryVariables>;
export function refetchFeedbackListQuery(variables?: FeedbackListQueryVariables) {
      return { query: FeedbackListDocument, variables: variables }
    }
export const UserCurrentInjuryListDocument = gql`
    query UserCurrentInjuryList($teamId: ID!, $userId: ID!, $input: MultilingualTextInput!) {
  sports {
    user(id: $userId) {
      id
      currentInjuryList(teamId: $teamId) {
        ...UserInjuryData
      }
    }
  }
}
    ${UserInjuryDataFragmentDoc}`;

/**
 * __useUserCurrentInjuryListQuery__
 *
 * To run a query within a React component, call `useUserCurrentInjuryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCurrentInjuryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCurrentInjuryListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCurrentInjuryListQuery(baseOptions: Apollo.QueryHookOptions<UserCurrentInjuryListQuery, UserCurrentInjuryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCurrentInjuryListQuery, UserCurrentInjuryListQueryVariables>(UserCurrentInjuryListDocument, options);
      }
export function useUserCurrentInjuryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCurrentInjuryListQuery, UserCurrentInjuryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCurrentInjuryListQuery, UserCurrentInjuryListQueryVariables>(UserCurrentInjuryListDocument, options);
        }
export type UserCurrentInjuryListQueryHookResult = ReturnType<typeof useUserCurrentInjuryListQuery>;
export type UserCurrentInjuryListLazyQueryHookResult = ReturnType<typeof useUserCurrentInjuryListLazyQuery>;
export type UserCurrentInjuryListQueryResult = Apollo.QueryResult<UserCurrentInjuryListQuery, UserCurrentInjuryListQueryVariables>;
export function refetchUserCurrentInjuryListQuery(variables?: UserCurrentInjuryListQueryVariables) {
      return { query: UserCurrentInjuryListDocument, variables: variables }
    }
export const UserPastInjuryListDocument = gql`
    query UserPastInjuryList($teamId: ID!, $userId: ID!, $input: MultilingualTextInput!) {
  sports {
    user(id: $userId) {
      id
      pastInjuryList(teamId: $teamId) {
        ...UserInjuryData
      }
    }
  }
}
    ${UserInjuryDataFragmentDoc}`;

/**
 * __useUserPastInjuryListQuery__
 *
 * To run a query within a React component, call `useUserPastInjuryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPastInjuryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPastInjuryListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserPastInjuryListQuery(baseOptions: Apollo.QueryHookOptions<UserPastInjuryListQuery, UserPastInjuryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPastInjuryListQuery, UserPastInjuryListQueryVariables>(UserPastInjuryListDocument, options);
      }
export function useUserPastInjuryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPastInjuryListQuery, UserPastInjuryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPastInjuryListQuery, UserPastInjuryListQueryVariables>(UserPastInjuryListDocument, options);
        }
export type UserPastInjuryListQueryHookResult = ReturnType<typeof useUserPastInjuryListQuery>;
export type UserPastInjuryListLazyQueryHookResult = ReturnType<typeof useUserPastInjuryListLazyQuery>;
export type UserPastInjuryListQueryResult = Apollo.QueryResult<UserPastInjuryListQuery, UserPastInjuryListQueryVariables>;
export function refetchUserPastInjuryListQuery(variables?: UserPastInjuryListQueryVariables) {
      return { query: UserPastInjuryListDocument, variables: variables }
    }
export const FeedbackDetailDocument = gql`
    query FeedbackDetail($id: ID!, $multilingualTextInput: MultilingualTextInput!) {
  feedback(id: $id) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;

/**
 * __useFeedbackDetailQuery__
 *
 * To run a query within a React component, call `useFeedbackDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *   },
 * });
 */
export function useFeedbackDetailQuery(baseOptions: Apollo.QueryHookOptions<FeedbackDetailQuery, FeedbackDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedbackDetailQuery, FeedbackDetailQueryVariables>(FeedbackDetailDocument, options);
      }
export function useFeedbackDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedbackDetailQuery, FeedbackDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedbackDetailQuery, FeedbackDetailQueryVariables>(FeedbackDetailDocument, options);
        }
export type FeedbackDetailQueryHookResult = ReturnType<typeof useFeedbackDetailQuery>;
export type FeedbackDetailLazyQueryHookResult = ReturnType<typeof useFeedbackDetailLazyQuery>;
export type FeedbackDetailQueryResult = Apollo.QueryResult<FeedbackDetailQuery, FeedbackDetailQueryVariables>;
export function refetchFeedbackDetailQuery(variables?: FeedbackDetailQueryVariables) {
      return { query: FeedbackDetailDocument, variables: variables }
    }
export const FeedbackSuggestionListDocument = gql`
    query FeedbackSuggestionList($userId: ID!, $teamId: ID!, $timezone: String!, $input: MultilingualTextInput!) {
  sports {
    user(id: $userId) {
      id
      name
      pictureUrl
      feedbackSuggestionList(teamId: $teamId, timezone: $timezone) {
        date
        type
        contentsData {
          ... on FeedbackWorkloadMemoData {
            memo
            duration
            time
            timezone
            scheduleData {
              type
              detailType
              title
              location
              scheduleDate
              startTime
              endTime
            }
          }
          ... on FeedbackSorenessSevereData {
            bodyPart(input: $input) {
              id
              value
              group {
                id
              }
              front
              back
              left
              right
            }
            level
            memo
            isContact
          }
          ... on FeedbackConditionNotGoodData {
            dataList
            mood
            fatigue
            stress
            muscle
            sleepDuration
            sleepDurationScore
            sleepQuality
          }
          ... on FeedbackConditionPlummetData {
            dataList
            mood28dayList
            fatigue28dayList
            stress28dayList
            muscle28dayList
            sleepDuration28dayList
            sleepQuality28dayList
          }
          ... on FeedbackWorkloadRiskAtInjuryData {
            label
            analysisType
            reasonList
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFeedbackSuggestionListQuery__
 *
 * To run a query within a React component, call `useFeedbackSuggestionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackSuggestionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackSuggestionListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *      timezone: // value for 'timezone'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFeedbackSuggestionListQuery(baseOptions: Apollo.QueryHookOptions<FeedbackSuggestionListQuery, FeedbackSuggestionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedbackSuggestionListQuery, FeedbackSuggestionListQueryVariables>(FeedbackSuggestionListDocument, options);
      }
export function useFeedbackSuggestionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedbackSuggestionListQuery, FeedbackSuggestionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedbackSuggestionListQuery, FeedbackSuggestionListQueryVariables>(FeedbackSuggestionListDocument, options);
        }
export type FeedbackSuggestionListQueryHookResult = ReturnType<typeof useFeedbackSuggestionListQuery>;
export type FeedbackSuggestionListLazyQueryHookResult = ReturnType<typeof useFeedbackSuggestionListLazyQuery>;
export type FeedbackSuggestionListQueryResult = Apollo.QueryResult<FeedbackSuggestionListQuery, FeedbackSuggestionListQueryVariables>;
export function refetchFeedbackSuggestionListQuery(variables?: FeedbackSuggestionListQueryVariables) {
      return { query: FeedbackSuggestionListDocument, variables: variables }
    }
export const DialogTeamPlayerListDocument = gql`
    query DialogTeamPlayerList($teamId: ID!) {
  sports {
    team(id: $teamId) {
      id
      userMapsByPage(limit: 6535, filterBy: {teamAuthority: MEMBER}) {
        entries {
          user {
            id
            name
            pictureUrl
            gender
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDialogTeamPlayerListQuery__
 *
 * To run a query within a React component, call `useDialogTeamPlayerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDialogTeamPlayerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDialogTeamPlayerListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDialogTeamPlayerListQuery(baseOptions: Apollo.QueryHookOptions<DialogTeamPlayerListQuery, DialogTeamPlayerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DialogTeamPlayerListQuery, DialogTeamPlayerListQueryVariables>(DialogTeamPlayerListDocument, options);
      }
export function useDialogTeamPlayerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DialogTeamPlayerListQuery, DialogTeamPlayerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DialogTeamPlayerListQuery, DialogTeamPlayerListQueryVariables>(DialogTeamPlayerListDocument, options);
        }
export type DialogTeamPlayerListQueryHookResult = ReturnType<typeof useDialogTeamPlayerListQuery>;
export type DialogTeamPlayerListLazyQueryHookResult = ReturnType<typeof useDialogTeamPlayerListLazyQuery>;
export type DialogTeamPlayerListQueryResult = Apollo.QueryResult<DialogTeamPlayerListQuery, DialogTeamPlayerListQueryVariables>;
export function refetchDialogTeamPlayerListQuery(variables?: DialogTeamPlayerListQueryVariables) {
      return { query: DialogTeamPlayerListDocument, variables: variables }
    }
export const DrawerPlayersUserMapsDocument = gql`
    query DrawerPlayersUserMaps($teamId: ID!, $offset: Int!, $limit: Int!, $timezone: String!, $filter: TeamUserMapFilterInput) {
  sports {
    team(id: $teamId) {
      id
      userMapsByPage(
        offset: $offset
        limit: $limit
        filter: $filter
        order: {field: name, sort: ASC, timezone: $timezone, stride: DAY}
      ) {
        offset
        limit
        total
        hasNext
        hasPrev
        entries {
          ...DrawerPlayersUserMap
        }
      }
    }
  }
}
    ${DrawerPlayersUserMapFragmentDoc}`;

/**
 * __useDrawerPlayersUserMapsQuery__
 *
 * To run a query within a React component, call `useDrawerPlayersUserMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawerPlayersUserMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawerPlayersUserMapsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      timezone: // value for 'timezone'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDrawerPlayersUserMapsQuery(baseOptions: Apollo.QueryHookOptions<DrawerPlayersUserMapsQuery, DrawerPlayersUserMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DrawerPlayersUserMapsQuery, DrawerPlayersUserMapsQueryVariables>(DrawerPlayersUserMapsDocument, options);
      }
export function useDrawerPlayersUserMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrawerPlayersUserMapsQuery, DrawerPlayersUserMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DrawerPlayersUserMapsQuery, DrawerPlayersUserMapsQueryVariables>(DrawerPlayersUserMapsDocument, options);
        }
export type DrawerPlayersUserMapsQueryHookResult = ReturnType<typeof useDrawerPlayersUserMapsQuery>;
export type DrawerPlayersUserMapsLazyQueryHookResult = ReturnType<typeof useDrawerPlayersUserMapsLazyQuery>;
export type DrawerPlayersUserMapsQueryResult = Apollo.QueryResult<DrawerPlayersUserMapsQuery, DrawerPlayersUserMapsQueryVariables>;
export function refetchDrawerPlayersUserMapsQuery(variables?: DrawerPlayersUserMapsQueryVariables) {
      return { query: DrawerPlayersUserMapsDocument, variables: variables }
    }
export const BodyPartsDocument = gql`
    query BodyParts($input: MultilingualTextInput!) {
  sports {
    bodyParts(input: $input) {
      back
      front
      group {
        id
      }
      id
      left
      right
      value
    }
  }
}
    `;

/**
 * __useBodyPartsQuery__
 *
 * To run a query within a React component, call `useBodyPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBodyPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBodyPartsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBodyPartsQuery(baseOptions: Apollo.QueryHookOptions<BodyPartsQuery, BodyPartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BodyPartsQuery, BodyPartsQueryVariables>(BodyPartsDocument, options);
      }
export function useBodyPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BodyPartsQuery, BodyPartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BodyPartsQuery, BodyPartsQueryVariables>(BodyPartsDocument, options);
        }
export type BodyPartsQueryHookResult = ReturnType<typeof useBodyPartsQuery>;
export type BodyPartsLazyQueryHookResult = ReturnType<typeof useBodyPartsLazyQuery>;
export type BodyPartsQueryResult = Apollo.QueryResult<BodyPartsQuery, BodyPartsQueryVariables>;
export function refetchBodyPartsQuery(variables?: BodyPartsQueryVariables) {
      return { query: BodyPartsDocument, variables: variables }
    }
export const BodyPartMultilingualTextDocument = gql`
    query BodyPartMultilingualText($input: MultilingualTextInput!, $key: String) {
  multilingualText(input: $input) {
    bodyPartList(key: $key) {
      id
      value
    }
  }
}
    `;

/**
 * __useBodyPartMultilingualTextQuery__
 *
 * To run a query within a React component, call `useBodyPartMultilingualTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useBodyPartMultilingualTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBodyPartMultilingualTextQuery({
 *   variables: {
 *      input: // value for 'input'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useBodyPartMultilingualTextQuery(baseOptions: Apollo.QueryHookOptions<BodyPartMultilingualTextQuery, BodyPartMultilingualTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BodyPartMultilingualTextQuery, BodyPartMultilingualTextQueryVariables>(BodyPartMultilingualTextDocument, options);
      }
export function useBodyPartMultilingualTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BodyPartMultilingualTextQuery, BodyPartMultilingualTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BodyPartMultilingualTextQuery, BodyPartMultilingualTextQueryVariables>(BodyPartMultilingualTextDocument, options);
        }
export type BodyPartMultilingualTextQueryHookResult = ReturnType<typeof useBodyPartMultilingualTextQuery>;
export type BodyPartMultilingualTextLazyQueryHookResult = ReturnType<typeof useBodyPartMultilingualTextLazyQuery>;
export type BodyPartMultilingualTextQueryResult = Apollo.QueryResult<BodyPartMultilingualTextQuery, BodyPartMultilingualTextQueryVariables>;
export function refetchBodyPartMultilingualTextQuery(variables?: BodyPartMultilingualTextQueryVariables) {
      return { query: BodyPartMultilingualTextDocument, variables: variables }
    }
export const ModalGroupGroupsDocument = gql`
    query ModalGroupGroups($id: ID!) {
  sports {
    team(id: $id) {
      id
      groups {
        id
        name
        orderRanking
        groupMembers {
          id
          user {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useModalGroupGroupsQuery__
 *
 * To run a query within a React component, call `useModalGroupGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalGroupGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalGroupGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModalGroupGroupsQuery(baseOptions: Apollo.QueryHookOptions<ModalGroupGroupsQuery, ModalGroupGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalGroupGroupsQuery, ModalGroupGroupsQueryVariables>(ModalGroupGroupsDocument, options);
      }
export function useModalGroupGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalGroupGroupsQuery, ModalGroupGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalGroupGroupsQuery, ModalGroupGroupsQueryVariables>(ModalGroupGroupsDocument, options);
        }
export type ModalGroupGroupsQueryHookResult = ReturnType<typeof useModalGroupGroupsQuery>;
export type ModalGroupGroupsLazyQueryHookResult = ReturnType<typeof useModalGroupGroupsLazyQuery>;
export type ModalGroupGroupsQueryResult = Apollo.QueryResult<ModalGroupGroupsQuery, ModalGroupGroupsQueryVariables>;
export function refetchModalGroupGroupsQuery(variables?: ModalGroupGroupsQueryVariables) {
      return { query: ModalGroupGroupsDocument, variables: variables }
    }
export const ModalTeamCreateViewerDocument = gql`
    query ModalTeamCreateViewer {
  sports {
    viewer {
      id
      name
      priorSportsCategory {
        id
      }
      nationality
    }
  }
}
    `;

/**
 * __useModalTeamCreateViewerQuery__
 *
 * To run a query within a React component, call `useModalTeamCreateViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalTeamCreateViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalTeamCreateViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useModalTeamCreateViewerQuery(baseOptions?: Apollo.QueryHookOptions<ModalTeamCreateViewerQuery, ModalTeamCreateViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalTeamCreateViewerQuery, ModalTeamCreateViewerQueryVariables>(ModalTeamCreateViewerDocument, options);
      }
export function useModalTeamCreateViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalTeamCreateViewerQuery, ModalTeamCreateViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalTeamCreateViewerQuery, ModalTeamCreateViewerQueryVariables>(ModalTeamCreateViewerDocument, options);
        }
export type ModalTeamCreateViewerQueryHookResult = ReturnType<typeof useModalTeamCreateViewerQuery>;
export type ModalTeamCreateViewerLazyQueryHookResult = ReturnType<typeof useModalTeamCreateViewerLazyQuery>;
export type ModalTeamCreateViewerQueryResult = Apollo.QueryResult<ModalTeamCreateViewerQuery, ModalTeamCreateViewerQueryVariables>;
export function refetchModalTeamCreateViewerQuery(variables?: ModalTeamCreateViewerQueryVariables) {
      return { query: ModalTeamCreateViewerDocument, variables: variables }
    }
export const ModalTeamCreateTeamDataDocument = gql`
    query ModalTeamCreateTeamData($id: ID!) {
  sports {
    team(id: $id) {
      ...ModalTeamCreateTeam
      userMapsByPage {
        entries {
          teamAuthority
          user {
            id
          }
        }
      }
    }
    viewer {
      id
      teamMapsByPage(offset: 0, limit: 1, order: {field: createdAt, sort: ASC}) {
        total
        entries {
          team {
            ...ModalTeamCreateTeam
          }
          teamAuthority
        }
      }
    }
  }
}
    ${ModalTeamCreateTeamFragmentDoc}`;

/**
 * __useModalTeamCreateTeamDataQuery__
 *
 * To run a query within a React component, call `useModalTeamCreateTeamDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalTeamCreateTeamDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalTeamCreateTeamDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModalTeamCreateTeamDataQuery(baseOptions: Apollo.QueryHookOptions<ModalTeamCreateTeamDataQuery, ModalTeamCreateTeamDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalTeamCreateTeamDataQuery, ModalTeamCreateTeamDataQueryVariables>(ModalTeamCreateTeamDataDocument, options);
      }
export function useModalTeamCreateTeamDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalTeamCreateTeamDataQuery, ModalTeamCreateTeamDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalTeamCreateTeamDataQuery, ModalTeamCreateTeamDataQueryVariables>(ModalTeamCreateTeamDataDocument, options);
        }
export type ModalTeamCreateTeamDataQueryHookResult = ReturnType<typeof useModalTeamCreateTeamDataQuery>;
export type ModalTeamCreateTeamDataLazyQueryHookResult = ReturnType<typeof useModalTeamCreateTeamDataLazyQuery>;
export type ModalTeamCreateTeamDataQueryResult = Apollo.QueryResult<ModalTeamCreateTeamDataQuery, ModalTeamCreateTeamDataQueryVariables>;
export function refetchModalTeamCreateTeamDataQuery(variables?: ModalTeamCreateTeamDataQueryVariables) {
      return { query: ModalTeamCreateTeamDataDocument, variables: variables }
    }
export const ModalTeamJoinViewerDocument = gql`
    query ModalTeamJoinViewer {
  sports {
    viewer {
      id
      name
      priorSportsCategory {
        id
      }
      nationality
    }
  }
}
    `;

/**
 * __useModalTeamJoinViewerQuery__
 *
 * To run a query within a React component, call `useModalTeamJoinViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalTeamJoinViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalTeamJoinViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useModalTeamJoinViewerQuery(baseOptions?: Apollo.QueryHookOptions<ModalTeamJoinViewerQuery, ModalTeamJoinViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalTeamJoinViewerQuery, ModalTeamJoinViewerQueryVariables>(ModalTeamJoinViewerDocument, options);
      }
export function useModalTeamJoinViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalTeamJoinViewerQuery, ModalTeamJoinViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalTeamJoinViewerQuery, ModalTeamJoinViewerQueryVariables>(ModalTeamJoinViewerDocument, options);
        }
export type ModalTeamJoinViewerQueryHookResult = ReturnType<typeof useModalTeamJoinViewerQuery>;
export type ModalTeamJoinViewerLazyQueryHookResult = ReturnType<typeof useModalTeamJoinViewerLazyQuery>;
export type ModalTeamJoinViewerQueryResult = Apollo.QueryResult<ModalTeamJoinViewerQuery, ModalTeamJoinViewerQueryVariables>;
export function refetchModalTeamJoinViewerQuery(variables?: ModalTeamJoinViewerQueryVariables) {
      return { query: ModalTeamJoinViewerDocument, variables: variables }
    }
export const NavbarTeamsTeamDataDocument = gql`
    query NavbarTeamsTeamData($id: ID!) {
  sports {
    team(id: $id) {
      ...NavbarTeamsTeam
      userMapsByPage {
        total
        entries {
          teamAuthority
          user {
            id
          }
        }
      }
    }
    viewer {
      id
      teamMapsByPage(offset: 0, limit: 1, order: {field: createdAt, sort: ASC}) {
        total
        entries {
          team {
            ...NavbarTeamsTeam
          }
          teamAuthority
        }
      }
    }
  }
}
    ${NavbarTeamsTeamFragmentDoc}`;

/**
 * __useNavbarTeamsTeamDataQuery__
 *
 * To run a query within a React component, call `useNavbarTeamsTeamDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavbarTeamsTeamDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavbarTeamsTeamDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNavbarTeamsTeamDataQuery(baseOptions: Apollo.QueryHookOptions<NavbarTeamsTeamDataQuery, NavbarTeamsTeamDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavbarTeamsTeamDataQuery, NavbarTeamsTeamDataQueryVariables>(NavbarTeamsTeamDataDocument, options);
      }
export function useNavbarTeamsTeamDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavbarTeamsTeamDataQuery, NavbarTeamsTeamDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavbarTeamsTeamDataQuery, NavbarTeamsTeamDataQueryVariables>(NavbarTeamsTeamDataDocument, options);
        }
export type NavbarTeamsTeamDataQueryHookResult = ReturnType<typeof useNavbarTeamsTeamDataQuery>;
export type NavbarTeamsTeamDataLazyQueryHookResult = ReturnType<typeof useNavbarTeamsTeamDataLazyQuery>;
export type NavbarTeamsTeamDataQueryResult = Apollo.QueryResult<NavbarTeamsTeamDataQuery, NavbarTeamsTeamDataQueryVariables>;
export function refetchNavbarTeamsTeamDataQuery(variables?: NavbarTeamsTeamDataQueryVariables) {
      return { query: NavbarTeamsTeamDataDocument, variables: variables }
    }
export const SectionPaymentPlanOptionsTeamSubscriptionDocument = gql`
    query SectionPaymentPlanOptionsTeamSubscription($teamId: ID!) {
  teamSubscriptionItem(teamId: $teamId) {
    ...SectionPaymentPlanOptionsSubscription
  }
}
    ${SectionPaymentPlanOptionsSubscriptionFragmentDoc}`;

/**
 * __useSectionPaymentPlanOptionsTeamSubscriptionQuery__
 *
 * To run a query within a React component, call `useSectionPaymentPlanOptionsTeamSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionPaymentPlanOptionsTeamSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionPaymentPlanOptionsTeamSubscriptionQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSectionPaymentPlanOptionsTeamSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<SectionPaymentPlanOptionsTeamSubscriptionQuery, SectionPaymentPlanOptionsTeamSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SectionPaymentPlanOptionsTeamSubscriptionQuery, SectionPaymentPlanOptionsTeamSubscriptionQueryVariables>(SectionPaymentPlanOptionsTeamSubscriptionDocument, options);
      }
export function useSectionPaymentPlanOptionsTeamSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectionPaymentPlanOptionsTeamSubscriptionQuery, SectionPaymentPlanOptionsTeamSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SectionPaymentPlanOptionsTeamSubscriptionQuery, SectionPaymentPlanOptionsTeamSubscriptionQueryVariables>(SectionPaymentPlanOptionsTeamSubscriptionDocument, options);
        }
export type SectionPaymentPlanOptionsTeamSubscriptionQueryHookResult = ReturnType<typeof useSectionPaymentPlanOptionsTeamSubscriptionQuery>;
export type SectionPaymentPlanOptionsTeamSubscriptionLazyQueryHookResult = ReturnType<typeof useSectionPaymentPlanOptionsTeamSubscriptionLazyQuery>;
export type SectionPaymentPlanOptionsTeamSubscriptionQueryResult = Apollo.QueryResult<SectionPaymentPlanOptionsTeamSubscriptionQuery, SectionPaymentPlanOptionsTeamSubscriptionQueryVariables>;
export function refetchSectionPaymentPlanOptionsTeamSubscriptionQuery(variables?: SectionPaymentPlanOptionsTeamSubscriptionQueryVariables) {
      return { query: SectionPaymentPlanOptionsTeamSubscriptionDocument, variables: variables }
    }
export const TeamChartSorenessLevel5userListDocument = gql`
    query TeamChartSorenessLevel5userList($teamId: ID!, $date: DateTime!, $input: MultilingualTextInput!) {
  sports {
    team(id: $teamId) {
      id
      sportsData {
        sorenessLevel5UserList(date: $date) {
          ...WidgetSorenessUserList
        }
      }
    }
  }
}
    ${WidgetSorenessUserListFragmentDoc}`;

/**
 * __useTeamChartSorenessLevel5userListQuery__
 *
 * To run a query within a React component, call `useTeamChartSorenessLevel5userListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamChartSorenessLevel5userListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamChartSorenessLevel5userListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      date: // value for 'date'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamChartSorenessLevel5userListQuery(baseOptions: Apollo.QueryHookOptions<TeamChartSorenessLevel5userListQuery, TeamChartSorenessLevel5userListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamChartSorenessLevel5userListQuery, TeamChartSorenessLevel5userListQueryVariables>(TeamChartSorenessLevel5userListDocument, options);
      }
export function useTeamChartSorenessLevel5userListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamChartSorenessLevel5userListQuery, TeamChartSorenessLevel5userListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamChartSorenessLevel5userListQuery, TeamChartSorenessLevel5userListQueryVariables>(TeamChartSorenessLevel5userListDocument, options);
        }
export type TeamChartSorenessLevel5userListQueryHookResult = ReturnType<typeof useTeamChartSorenessLevel5userListQuery>;
export type TeamChartSorenessLevel5userListLazyQueryHookResult = ReturnType<typeof useTeamChartSorenessLevel5userListLazyQuery>;
export type TeamChartSorenessLevel5userListQueryResult = Apollo.QueryResult<TeamChartSorenessLevel5userListQuery, TeamChartSorenessLevel5userListQueryVariables>;
export function refetchTeamChartSorenessLevel5userListQuery(variables?: TeamChartSorenessLevel5userListQueryVariables) {
      return { query: TeamChartSorenessLevel5userListDocument, variables: variables }
    }
export const PlayerModalUserDocument = gql`
    query PlayerModalUser($playerId: ID!) {
  sports {
    user(id: $playerId) {
      id
      name
      pictureUrl
      priorSportsRole
      priorSportsPosition
      priorSportsCategory {
        group
        id
      }
      birthdate
    }
  }
}
    `;

/**
 * __usePlayerModalUserQuery__
 *
 * To run a query within a React component, call `usePlayerModalUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerModalUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerModalUserQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function usePlayerModalUserQuery(baseOptions: Apollo.QueryHookOptions<PlayerModalUserQuery, PlayerModalUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerModalUserQuery, PlayerModalUserQueryVariables>(PlayerModalUserDocument, options);
      }
export function usePlayerModalUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerModalUserQuery, PlayerModalUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerModalUserQuery, PlayerModalUserQueryVariables>(PlayerModalUserDocument, options);
        }
export type PlayerModalUserQueryHookResult = ReturnType<typeof usePlayerModalUserQuery>;
export type PlayerModalUserLazyQueryHookResult = ReturnType<typeof usePlayerModalUserLazyQuery>;
export type PlayerModalUserQueryResult = Apollo.QueryResult<PlayerModalUserQuery, PlayerModalUserQueryVariables>;
export function refetchPlayerModalUserQuery(variables?: PlayerModalUserQueryVariables) {
      return { query: PlayerModalUserDocument, variables: variables }
    }
export const PlayerModalLatestRawBodyDocument = gql`
    query PlayerModalLatestRawBody($playerId: ID!, $timezone: String!) {
  sports {
    user(id: $playerId) {
      id
      latestRawBody(timezone: $timezone) {
        id
        weight
        weightUnit
        height
        heightUnit
      }
    }
  }
}
    `;

/**
 * __usePlayerModalLatestRawBodyQuery__
 *
 * To run a query within a React component, call `usePlayerModalLatestRawBodyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerModalLatestRawBodyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerModalLatestRawBodyQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function usePlayerModalLatestRawBodyQuery(baseOptions: Apollo.QueryHookOptions<PlayerModalLatestRawBodyQuery, PlayerModalLatestRawBodyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerModalLatestRawBodyQuery, PlayerModalLatestRawBodyQueryVariables>(PlayerModalLatestRawBodyDocument, options);
      }
export function usePlayerModalLatestRawBodyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerModalLatestRawBodyQuery, PlayerModalLatestRawBodyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerModalLatestRawBodyQuery, PlayerModalLatestRawBodyQueryVariables>(PlayerModalLatestRawBodyDocument, options);
        }
export type PlayerModalLatestRawBodyQueryHookResult = ReturnType<typeof usePlayerModalLatestRawBodyQuery>;
export type PlayerModalLatestRawBodyLazyQueryHookResult = ReturnType<typeof usePlayerModalLatestRawBodyLazyQuery>;
export type PlayerModalLatestRawBodyQueryResult = Apollo.QueryResult<PlayerModalLatestRawBodyQuery, PlayerModalLatestRawBodyQueryVariables>;
export function refetchPlayerModalLatestRawBodyQuery(variables?: PlayerModalLatestRawBodyQueryVariables) {
      return { query: PlayerModalLatestRawBodyDocument, variables: variables }
    }
export const SportsUserInjuryHistoryTableDocument = gql`
    query SportsUserInjuryHistoryTable($id: ID!, $bodyPart: SportsBodyPartName!, $offset: Int!, $limit: Int!, $input: MultilingualTextInput!) {
  sports {
    user(id: $id) {
      sorenessHistory {
        recentData(bodyPart: $bodyPart, offset: $offset, limit: $limit) {
          bodyPart(input: $input) {
            id
            value
          }
          total
          hasNext
          values {
            time
            level
            memo
            isContact
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSportsUserInjuryHistoryTableQuery__
 *
 * To run a query within a React component, call `useSportsUserInjuryHistoryTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportsUserInjuryHistoryTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsUserInjuryHistoryTableQuery({
 *   variables: {
 *      id: // value for 'id'
 *      bodyPart: // value for 'bodyPart'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSportsUserInjuryHistoryTableQuery(baseOptions: Apollo.QueryHookOptions<SportsUserInjuryHistoryTableQuery, SportsUserInjuryHistoryTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SportsUserInjuryHistoryTableQuery, SportsUserInjuryHistoryTableQueryVariables>(SportsUserInjuryHistoryTableDocument, options);
      }
export function useSportsUserInjuryHistoryTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SportsUserInjuryHistoryTableQuery, SportsUserInjuryHistoryTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SportsUserInjuryHistoryTableQuery, SportsUserInjuryHistoryTableQueryVariables>(SportsUserInjuryHistoryTableDocument, options);
        }
export type SportsUserInjuryHistoryTableQueryHookResult = ReturnType<typeof useSportsUserInjuryHistoryTableQuery>;
export type SportsUserInjuryHistoryTableLazyQueryHookResult = ReturnType<typeof useSportsUserInjuryHistoryTableLazyQuery>;
export type SportsUserInjuryHistoryTableQueryResult = Apollo.QueryResult<SportsUserInjuryHistoryTableQuery, SportsUserInjuryHistoryTableQueryVariables>;
export function refetchSportsUserInjuryHistoryTableQuery(variables?: SportsUserInjuryHistoryTableQueryVariables) {
      return { query: SportsUserInjuryHistoryTableDocument, variables: variables }
    }
export const WeeklyInjuryHistoryDocument = gql`
    query WeeklyInjuryHistory($id: ID!, $date: DateTime!, $bodyPart: SportsBodyPartName!, $input: MultilingualTextInput!) {
  sports {
    user(id: $id) {
      id
      sorenessHistory {
        weeklyData(date: $date, bodyPart: $bodyPart) {
          from
          to
          bodyPart(input: $input) {
            id
            value
          }
          prev {
            time
            level
          }
          curr {
            time
            level
          }
          next {
            time
            level
          }
        }
      }
    }
  }
}
    `;

/**
 * __useWeeklyInjuryHistoryQuery__
 *
 * To run a query within a React component, call `useWeeklyInjuryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyInjuryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyInjuryHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      bodyPart: // value for 'bodyPart'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWeeklyInjuryHistoryQuery(baseOptions: Apollo.QueryHookOptions<WeeklyInjuryHistoryQuery, WeeklyInjuryHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeeklyInjuryHistoryQuery, WeeklyInjuryHistoryQueryVariables>(WeeklyInjuryHistoryDocument, options);
      }
export function useWeeklyInjuryHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeeklyInjuryHistoryQuery, WeeklyInjuryHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeeklyInjuryHistoryQuery, WeeklyInjuryHistoryQueryVariables>(WeeklyInjuryHistoryDocument, options);
        }
export type WeeklyInjuryHistoryQueryHookResult = ReturnType<typeof useWeeklyInjuryHistoryQuery>;
export type WeeklyInjuryHistoryLazyQueryHookResult = ReturnType<typeof useWeeklyInjuryHistoryLazyQuery>;
export type WeeklyInjuryHistoryQueryResult = Apollo.QueryResult<WeeklyInjuryHistoryQuery, WeeklyInjuryHistoryQueryVariables>;
export function refetchWeeklyInjuryHistoryQuery(variables?: WeeklyInjuryHistoryQueryVariables) {
      return { query: WeeklyInjuryHistoryDocument, variables: variables }
    }
export const MonthlyInjuryHistoryDocument = gql`
    query MonthlyInjuryHistory($id: ID!, $date: DateTime!, $bodyPart: SportsBodyPartName!, $input: MultilingualTextInput!) {
  sports {
    user(id: $id) {
      id
      sorenessHistory {
        monthlyData(date: $date, bodyPart: $bodyPart) {
          from
          to
          bodyPart(input: $input) {
            id
            value
          }
          prev {
            time
            level
          }
          curr {
            time
            level
          }
          next {
            time
            level
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMonthlyInjuryHistoryQuery__
 *
 * To run a query within a React component, call `useMonthlyInjuryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyInjuryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyInjuryHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      bodyPart: // value for 'bodyPart'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMonthlyInjuryHistoryQuery(baseOptions: Apollo.QueryHookOptions<MonthlyInjuryHistoryQuery, MonthlyInjuryHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonthlyInjuryHistoryQuery, MonthlyInjuryHistoryQueryVariables>(MonthlyInjuryHistoryDocument, options);
      }
export function useMonthlyInjuryHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyInjuryHistoryQuery, MonthlyInjuryHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonthlyInjuryHistoryQuery, MonthlyInjuryHistoryQueryVariables>(MonthlyInjuryHistoryDocument, options);
        }
export type MonthlyInjuryHistoryQueryHookResult = ReturnType<typeof useMonthlyInjuryHistoryQuery>;
export type MonthlyInjuryHistoryLazyQueryHookResult = ReturnType<typeof useMonthlyInjuryHistoryLazyQuery>;
export type MonthlyInjuryHistoryQueryResult = Apollo.QueryResult<MonthlyInjuryHistoryQuery, MonthlyInjuryHistoryQueryVariables>;
export function refetchMonthlyInjuryHistoryQuery(variables?: MonthlyInjuryHistoryQueryVariables) {
      return { query: MonthlyInjuryHistoryDocument, variables: variables }
    }
export const PlayerSummaryDocument = gql`
    query PlayerSummary($playerId: ID!, $starerId: ID!, $teamId: ID!, $from: DateTime!, $to: DateTime!, $timezone: String!, $hasPermissionViewStarredPlayers: Boolean!, $input: MultilingualTextInput!) {
  sports {
    user(id: $playerId) {
      id
      ...PlayerSummaryIdentity
      data(from: $from, to: $to, timezone: $timezone, stride: DAY, names: [SORENESS]) {
        name
        ranges(input: $input) {
          entries {
            time
            value
          }
        }
      }
      isStarredBy(starerId: $starerId, teamId: $teamId) @include(if: $hasPermissionViewStarredPlayers)
      injury {
        id
        occurDate
        historyStatus
      }
    }
  }
}
    ${PlayerSummaryIdentityFragmentDoc}`;

/**
 * __usePlayerSummaryQuery__
 *
 * To run a query within a React component, call `usePlayerSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerSummaryQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      starerId: // value for 'starerId'
 *      teamId: // value for 'teamId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      timezone: // value for 'timezone'
 *      hasPermissionViewStarredPlayers: // value for 'hasPermissionViewStarredPlayers'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlayerSummaryQuery(baseOptions: Apollo.QueryHookOptions<PlayerSummaryQuery, PlayerSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerSummaryQuery, PlayerSummaryQueryVariables>(PlayerSummaryDocument, options);
      }
export function usePlayerSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerSummaryQuery, PlayerSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerSummaryQuery, PlayerSummaryQueryVariables>(PlayerSummaryDocument, options);
        }
export type PlayerSummaryQueryHookResult = ReturnType<typeof usePlayerSummaryQuery>;
export type PlayerSummaryLazyQueryHookResult = ReturnType<typeof usePlayerSummaryLazyQuery>;
export type PlayerSummaryQueryResult = Apollo.QueryResult<PlayerSummaryQuery, PlayerSummaryQueryVariables>;
export function refetchPlayerSummaryQuery(variables?: PlayerSummaryQueryVariables) {
      return { query: PlayerSummaryDocument, variables: variables }
    }
export const CsvExportDocument = gql`
    mutation CsvExport($input: CsvExportInput!) {
  exportCsvFile(input: $input)
}
    `;
export type CsvExportMutationFn = Apollo.MutationFunction<CsvExportMutation, CsvExportMutationVariables>;

/**
 * __useCsvExportMutation__
 *
 * To run a mutation, you first call `useCsvExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCsvExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [csvExportMutation, { data, loading, error }] = useCsvExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCsvExportMutation(baseOptions?: Apollo.MutationHookOptions<CsvExportMutation, CsvExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CsvExportMutation, CsvExportMutationVariables>(CsvExportDocument, options);
      }
export type CsvExportMutationHookResult = ReturnType<typeof useCsvExportMutation>;
export type CsvExportMutationResult = Apollo.MutationResult<CsvExportMutation>;
export type CsvExportMutationOptions = Apollo.BaseMutationOptions<CsvExportMutation, CsvExportMutationVariables>;
export const CreateFeedbackDocument = gql`
    mutation CreateFeedback($input: CreateFeedbackInput!, $multilingualTextInput: MultilingualTextInput!) {
  createFeedback(input: $input) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const DeleteFeedbackDocument = gql`
    mutation DeleteFeedback($id: ID!) {
  deleteFeedback(id: $id)
}
    `;
export type DeleteFeedbackMutationFn = Apollo.MutationFunction<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>;

/**
 * __useDeleteFeedbackMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackMutation, { data, loading, error }] = useDeleteFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>(DeleteFeedbackDocument, options);
      }
export type DeleteFeedbackMutationHookResult = ReturnType<typeof useDeleteFeedbackMutation>;
export type DeleteFeedbackMutationResult = Apollo.MutationResult<DeleteFeedbackMutation>;
export type DeleteFeedbackMutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>;
export const UpdateFeedbackDocument = gql`
    mutation UpdateFeedback($input: UpdateFeedbackInput!, $multilingualTextInput: MultilingualTextInput!) {
  updateFeedback(input: $input) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type UpdateFeedbackMutationFn = Apollo.MutationFunction<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>;

/**
 * __useUpdateFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackMutation, { data, loading, error }] = useUpdateFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *   },
 * });
 */
export function useUpdateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>(UpdateFeedbackDocument, options);
      }
export type UpdateFeedbackMutationHookResult = ReturnType<typeof useUpdateFeedbackMutation>;
export type UpdateFeedbackMutationResult = Apollo.MutationResult<UpdateFeedbackMutation>;
export type UpdateFeedbackMutationOptions = Apollo.BaseMutationOptions<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>;
export const CreateFeedbackCommentsDocument = gql`
    mutation CreateFeedbackComments($input: CreateFeedbackCommentInput!, $multilingualTextInput: MultilingualTextInput!) {
  createFeedbackComment(input: $input) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type CreateFeedbackCommentsMutationFn = Apollo.MutationFunction<CreateFeedbackCommentsMutation, CreateFeedbackCommentsMutationVariables>;

/**
 * __useCreateFeedbackCommentsMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackCommentsMutation, { data, loading, error }] = useCreateFeedbackCommentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *   },
 * });
 */
export function useCreateFeedbackCommentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackCommentsMutation, CreateFeedbackCommentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackCommentsMutation, CreateFeedbackCommentsMutationVariables>(CreateFeedbackCommentsDocument, options);
      }
export type CreateFeedbackCommentsMutationHookResult = ReturnType<typeof useCreateFeedbackCommentsMutation>;
export type CreateFeedbackCommentsMutationResult = Apollo.MutationResult<CreateFeedbackCommentsMutation>;
export type CreateFeedbackCommentsMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackCommentsMutation, CreateFeedbackCommentsMutationVariables>;
export const UpdateFeedbackCommentsDocument = gql`
    mutation UpdateFeedbackComments($input: UpdateFeedbackCommentInput!, $multilingualTextInput: MultilingualTextInput!) {
  updateFeedbackComment(input: $input) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type UpdateFeedbackCommentsMutationFn = Apollo.MutationFunction<UpdateFeedbackCommentsMutation, UpdateFeedbackCommentsMutationVariables>;

/**
 * __useUpdateFeedbackCommentsMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackCommentsMutation, { data, loading, error }] = useUpdateFeedbackCommentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *   },
 * });
 */
export function useUpdateFeedbackCommentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedbackCommentsMutation, UpdateFeedbackCommentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedbackCommentsMutation, UpdateFeedbackCommentsMutationVariables>(UpdateFeedbackCommentsDocument, options);
      }
export type UpdateFeedbackCommentsMutationHookResult = ReturnType<typeof useUpdateFeedbackCommentsMutation>;
export type UpdateFeedbackCommentsMutationResult = Apollo.MutationResult<UpdateFeedbackCommentsMutation>;
export type UpdateFeedbackCommentsMutationOptions = Apollo.BaseMutationOptions<UpdateFeedbackCommentsMutation, UpdateFeedbackCommentsMutationVariables>;
export const DeleteFeedbackCommentsDocument = gql`
    mutation DeleteFeedbackComments($input: DeleteFeedbackCommentInput!, $multilingualTextInput: MultilingualTextInput!) {
  deleteFeedbackComment(input: $input) {
    ...FeedbackData
  }
}
    ${FeedbackDataFragmentDoc}`;
export type DeleteFeedbackCommentsMutationFn = Apollo.MutationFunction<DeleteFeedbackCommentsMutation, DeleteFeedbackCommentsMutationVariables>;

/**
 * __useDeleteFeedbackCommentsMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackCommentsMutation, { data, loading, error }] = useDeleteFeedbackCommentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *   },
 * });
 */
export function useDeleteFeedbackCommentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackCommentsMutation, DeleteFeedbackCommentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedbackCommentsMutation, DeleteFeedbackCommentsMutationVariables>(DeleteFeedbackCommentsDocument, options);
      }
export type DeleteFeedbackCommentsMutationHookResult = ReturnType<typeof useDeleteFeedbackCommentsMutation>;
export type DeleteFeedbackCommentsMutationResult = Apollo.MutationResult<DeleteFeedbackCommentsMutation>;
export type DeleteFeedbackCommentsMutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackCommentsMutation, DeleteFeedbackCommentsMutationVariables>;
export const CreateInjuryDocument = gql`
    mutation CreateInjury($input: CreateInjuryInput!, $files: [Upload!]!, $multilingualTextInput: MultilingualTextInput!) {
  createInjury(input: $input, files: $files) {
    id
    bodyPart(input: $multilingualTextInput) {
      id
      value
    }
    occurDate
    historyStatus
    reason
    diagnosis
    medicalRecord
    return {
      date
      status
      info
    }
    fileList {
      id
      name
      url
    }
    status
    user {
      id
    }
  }
}
    `;
export type CreateInjuryMutationFn = Apollo.MutationFunction<CreateInjuryMutation, CreateInjuryMutationVariables>;

/**
 * __useCreateInjuryMutation__
 *
 * To run a mutation, you first call `useCreateInjuryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInjuryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInjuryMutation, { data, loading, error }] = useCreateInjuryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *   },
 * });
 */
export function useCreateInjuryMutation(baseOptions?: Apollo.MutationHookOptions<CreateInjuryMutation, CreateInjuryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInjuryMutation, CreateInjuryMutationVariables>(CreateInjuryDocument, options);
      }
export type CreateInjuryMutationHookResult = ReturnType<typeof useCreateInjuryMutation>;
export type CreateInjuryMutationResult = Apollo.MutationResult<CreateInjuryMutation>;
export type CreateInjuryMutationOptions = Apollo.BaseMutationOptions<CreateInjuryMutation, CreateInjuryMutationVariables>;
export const UpdateInjuryDocument = gql`
    mutation UpdateInjury($input: UpdateInjuryInput!, $files: [Upload!], $multilingualTextInput: MultilingualTextInput!) {
  updateInjury(input: $input, files: $files) {
    id
    bodyPart(input: $multilingualTextInput) {
      id
      value
    }
    occurDate
    historyStatus
    reason
    diagnosis
    medicalRecord
    return {
      date
      info
      status
    }
    fileList {
      id
      name
      url
    }
    status
  }
}
    `;
export type UpdateInjuryMutationFn = Apollo.MutationFunction<UpdateInjuryMutation, UpdateInjuryMutationVariables>;

/**
 * __useUpdateInjuryMutation__
 *
 * To run a mutation, you first call `useUpdateInjuryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInjuryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInjuryMutation, { data, loading, error }] = useUpdateInjuryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *      multilingualTextInput: // value for 'multilingualTextInput'
 *   },
 * });
 */
export function useUpdateInjuryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInjuryMutation, UpdateInjuryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInjuryMutation, UpdateInjuryMutationVariables>(UpdateInjuryDocument, options);
      }
export type UpdateInjuryMutationHookResult = ReturnType<typeof useUpdateInjuryMutation>;
export type UpdateInjuryMutationResult = Apollo.MutationResult<UpdateInjuryMutation>;
export type UpdateInjuryMutationOptions = Apollo.BaseMutationOptions<UpdateInjuryMutation, UpdateInjuryMutationVariables>;
export const DeleteInjuryDocument = gql`
    mutation DeleteInjury($input: DeleteInjuryInput!) {
  deleteInjury(input: $input)
}
    `;
export type DeleteInjuryMutationFn = Apollo.MutationFunction<DeleteInjuryMutation, DeleteInjuryMutationVariables>;

/**
 * __useDeleteInjuryMutation__
 *
 * To run a mutation, you first call `useDeleteInjuryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInjuryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInjuryMutation, { data, loading, error }] = useDeleteInjuryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInjuryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInjuryMutation, DeleteInjuryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInjuryMutation, DeleteInjuryMutationVariables>(DeleteInjuryDocument, options);
      }
export type DeleteInjuryMutationHookResult = ReturnType<typeof useDeleteInjuryMutation>;
export type DeleteInjuryMutationResult = Apollo.MutationResult<DeleteInjuryMutation>;
export type DeleteInjuryMutationOptions = Apollo.BaseMutationOptions<DeleteInjuryMutation, DeleteInjuryMutationVariables>;
export const SendCheckConditionReminderPushDocument = gql`
    mutation SendCheckConditionReminderPush($input: CheckReminderPushInput!) {
  sendCheckConditionReminderPush(input: $input)
}
    `;
export type SendCheckConditionReminderPushMutationFn = Apollo.MutationFunction<SendCheckConditionReminderPushMutation, SendCheckConditionReminderPushMutationVariables>;

/**
 * __useSendCheckConditionReminderPushMutation__
 *
 * To run a mutation, you first call `useSendCheckConditionReminderPushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCheckConditionReminderPushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCheckConditionReminderPushMutation, { data, loading, error }] = useSendCheckConditionReminderPushMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCheckConditionReminderPushMutation(baseOptions?: Apollo.MutationHookOptions<SendCheckConditionReminderPushMutation, SendCheckConditionReminderPushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCheckConditionReminderPushMutation, SendCheckConditionReminderPushMutationVariables>(SendCheckConditionReminderPushDocument, options);
      }
export type SendCheckConditionReminderPushMutationHookResult = ReturnType<typeof useSendCheckConditionReminderPushMutation>;
export type SendCheckConditionReminderPushMutationResult = Apollo.MutationResult<SendCheckConditionReminderPushMutation>;
export type SendCheckConditionReminderPushMutationOptions = Apollo.BaseMutationOptions<SendCheckConditionReminderPushMutation, SendCheckConditionReminderPushMutationVariables>;
export const SendCheckWorkloadReminderPushDocument = gql`
    mutation SendCheckWorkloadReminderPush($input: CheckReminderPushInput!) {
  sendCheckWorkloadReminderPush(input: $input)
}
    `;
export type SendCheckWorkloadReminderPushMutationFn = Apollo.MutationFunction<SendCheckWorkloadReminderPushMutation, SendCheckWorkloadReminderPushMutationVariables>;

/**
 * __useSendCheckWorkloadReminderPushMutation__
 *
 * To run a mutation, you first call `useSendCheckWorkloadReminderPushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCheckWorkloadReminderPushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCheckWorkloadReminderPushMutation, { data, loading, error }] = useSendCheckWorkloadReminderPushMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCheckWorkloadReminderPushMutation(baseOptions?: Apollo.MutationHookOptions<SendCheckWorkloadReminderPushMutation, SendCheckWorkloadReminderPushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCheckWorkloadReminderPushMutation, SendCheckWorkloadReminderPushMutationVariables>(SendCheckWorkloadReminderPushDocument, options);
      }
export type SendCheckWorkloadReminderPushMutationHookResult = ReturnType<typeof useSendCheckWorkloadReminderPushMutation>;
export type SendCheckWorkloadReminderPushMutationResult = Apollo.MutationResult<SendCheckWorkloadReminderPushMutation>;
export type SendCheckWorkloadReminderPushMutationOptions = Apollo.BaseMutationOptions<SendCheckWorkloadReminderPushMutation, SendCheckWorkloadReminderPushMutationVariables>;
export const RequestBillingTokenDocument = gql`
    mutation RequestBillingToken($input: RequestBillingTokenInput!) {
  requestBillingToken(input: $input) {
    customerUid
    paymentToken
  }
}
    `;
export type RequestBillingTokenMutationFn = Apollo.MutationFunction<RequestBillingTokenMutation, RequestBillingTokenMutationVariables>;

/**
 * __useRequestBillingTokenMutation__
 *
 * To run a mutation, you first call `useRequestBillingTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBillingTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBillingTokenMutation, { data, loading, error }] = useRequestBillingTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestBillingTokenMutation(baseOptions?: Apollo.MutationHookOptions<RequestBillingTokenMutation, RequestBillingTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestBillingTokenMutation, RequestBillingTokenMutationVariables>(RequestBillingTokenDocument, options);
      }
export type RequestBillingTokenMutationHookResult = ReturnType<typeof useRequestBillingTokenMutation>;
export type RequestBillingTokenMutationResult = Apollo.MutationResult<RequestBillingTokenMutation>;
export type RequestBillingTokenMutationOptions = Apollo.BaseMutationOptions<RequestBillingTokenMutation, RequestBillingTokenMutationVariables>;
export const UpdateBillingKeyDocument = gql`
    mutation UpdateBillingKey($input: UpdateBillingTokenInput!) {
  updateBillingKey(input: $input) {
    id
    identityToken
    customerUid
    cardName
    cardNumber
    buyerName
    buyerTel
    buyerEmail
  }
}
    `;
export type UpdateBillingKeyMutationFn = Apollo.MutationFunction<UpdateBillingKeyMutation, UpdateBillingKeyMutationVariables>;

/**
 * __useUpdateBillingKeyMutation__
 *
 * To run a mutation, you first call `useUpdateBillingKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingKeyMutation, { data, loading, error }] = useUpdateBillingKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingKeyMutation, UpdateBillingKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingKeyMutation, UpdateBillingKeyMutationVariables>(UpdateBillingKeyDocument, options);
      }
export type UpdateBillingKeyMutationHookResult = ReturnType<typeof useUpdateBillingKeyMutation>;
export type UpdateBillingKeyMutationResult = Apollo.MutationResult<UpdateBillingKeyMutation>;
export type UpdateBillingKeyMutationOptions = Apollo.BaseMutationOptions<UpdateBillingKeyMutation, UpdateBillingKeyMutationVariables>;
export const UpdateBillingBuyerNameDocument = gql`
    mutation UpdateBillingBuyerName($input: UpdateBillingBuyerNameInput!) {
  updateBillingBuyerName(input: $input) {
    id
  }
}
    `;
export type UpdateBillingBuyerNameMutationFn = Apollo.MutationFunction<UpdateBillingBuyerNameMutation, UpdateBillingBuyerNameMutationVariables>;

/**
 * __useUpdateBillingBuyerNameMutation__
 *
 * To run a mutation, you first call `useUpdateBillingBuyerNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingBuyerNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingBuyerNameMutation, { data, loading, error }] = useUpdateBillingBuyerNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingBuyerNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingBuyerNameMutation, UpdateBillingBuyerNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingBuyerNameMutation, UpdateBillingBuyerNameMutationVariables>(UpdateBillingBuyerNameDocument, options);
      }
export type UpdateBillingBuyerNameMutationHookResult = ReturnType<typeof useUpdateBillingBuyerNameMutation>;
export type UpdateBillingBuyerNameMutationResult = Apollo.MutationResult<UpdateBillingBuyerNameMutation>;
export type UpdateBillingBuyerNameMutationOptions = Apollo.BaseMutationOptions<UpdateBillingBuyerNameMutation, UpdateBillingBuyerNameMutationVariables>;
export const UpdateBillingBuyerNumberDocument = gql`
    mutation UpdateBillingBuyerNumber($input: UpdateBillingBuyerNumberInput!) {
  updateBillingBuyerNumber(input: $input) {
    id
  }
}
    `;
export type UpdateBillingBuyerNumberMutationFn = Apollo.MutationFunction<UpdateBillingBuyerNumberMutation, UpdateBillingBuyerNumberMutationVariables>;

/**
 * __useUpdateBillingBuyerNumberMutation__
 *
 * To run a mutation, you first call `useUpdateBillingBuyerNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingBuyerNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingBuyerNumberMutation, { data, loading, error }] = useUpdateBillingBuyerNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingBuyerNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingBuyerNumberMutation, UpdateBillingBuyerNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingBuyerNumberMutation, UpdateBillingBuyerNumberMutationVariables>(UpdateBillingBuyerNumberDocument, options);
      }
export type UpdateBillingBuyerNumberMutationHookResult = ReturnType<typeof useUpdateBillingBuyerNumberMutation>;
export type UpdateBillingBuyerNumberMutationResult = Apollo.MutationResult<UpdateBillingBuyerNumberMutation>;
export type UpdateBillingBuyerNumberMutationOptions = Apollo.BaseMutationOptions<UpdateBillingBuyerNumberMutation, UpdateBillingBuyerNumberMutationVariables>;
export const UpdateBillingBuyerEmailDocument = gql`
    mutation UpdateBillingBuyerEmail($input: UpdateBillingBuyerEmailInput!) {
  updateBillingBuyerEmail(input: $input) {
    id
  }
}
    `;
export type UpdateBillingBuyerEmailMutationFn = Apollo.MutationFunction<UpdateBillingBuyerEmailMutation, UpdateBillingBuyerEmailMutationVariables>;

/**
 * __useUpdateBillingBuyerEmailMutation__
 *
 * To run a mutation, you first call `useUpdateBillingBuyerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingBuyerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingBuyerEmailMutation, { data, loading, error }] = useUpdateBillingBuyerEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingBuyerEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingBuyerEmailMutation, UpdateBillingBuyerEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingBuyerEmailMutation, UpdateBillingBuyerEmailMutationVariables>(UpdateBillingBuyerEmailDocument, options);
      }
export type UpdateBillingBuyerEmailMutationHookResult = ReturnType<typeof useUpdateBillingBuyerEmailMutation>;
export type UpdateBillingBuyerEmailMutationResult = Apollo.MutationResult<UpdateBillingBuyerEmailMutation>;
export type UpdateBillingBuyerEmailMutationOptions = Apollo.BaseMutationOptions<UpdateBillingBuyerEmailMutation, UpdateBillingBuyerEmailMutationVariables>;
export const StartSubscriptionDocument = gql`
    mutation StartSubscription($input: StartSubscriptionInput!) {
  startSubscription(input: $input) {
    subscription {
      ...PaymentSubscrption
    }
    paymentList {
      ... on PayInvoice {
        id
        paymentName
        paymentStatus
        cardName
        cardNumber
        billingUserName
        billingUserEmail
        billingUserPhone
        receiptUrl
        paidAt
        amount
      }
    }
  }
}
    ${PaymentSubscrptionFragmentDoc}`;
export type StartSubscriptionMutationFn = Apollo.MutationFunction<StartSubscriptionMutation, StartSubscriptionMutationVariables>;

/**
 * __useStartSubscriptionMutation__
 *
 * To run a mutation, you first call `useStartSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSubscriptionMutation, { data, loading, error }] = useStartSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<StartSubscriptionMutation, StartSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSubscriptionMutation, StartSubscriptionMutationVariables>(StartSubscriptionDocument, options);
      }
export type StartSubscriptionMutationHookResult = ReturnType<typeof useStartSubscriptionMutation>;
export type StartSubscriptionMutationResult = Apollo.MutationResult<StartSubscriptionMutation>;
export type StartSubscriptionMutationOptions = Apollo.BaseMutationOptions<StartSubscriptionMutation, StartSubscriptionMutationVariables>;
export const RestartSubscriptionDocument = gql`
    mutation RestartSubscription($input: RestartSubscriptionInput!) {
  restartSubscription(input: $input) {
    subscription {
      ...PaymentSubscrption
    }
    paymentList {
      ... on PayInvoice {
        id
        paymentName
        paymentStatus
        cardName
        cardNumber
        billingUserName
        billingUserEmail
        billingUserPhone
        receiptUrl
        paidAt
        amount
      }
    }
  }
}
    ${PaymentSubscrptionFragmentDoc}`;
export type RestartSubscriptionMutationFn = Apollo.MutationFunction<RestartSubscriptionMutation, RestartSubscriptionMutationVariables>;

/**
 * __useRestartSubscriptionMutation__
 *
 * To run a mutation, you first call `useRestartSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartSubscriptionMutation, { data, loading, error }] = useRestartSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestartSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<RestartSubscriptionMutation, RestartSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartSubscriptionMutation, RestartSubscriptionMutationVariables>(RestartSubscriptionDocument, options);
      }
export type RestartSubscriptionMutationHookResult = ReturnType<typeof useRestartSubscriptionMutation>;
export type RestartSubscriptionMutationResult = Apollo.MutationResult<RestartSubscriptionMutation>;
export type RestartSubscriptionMutationOptions = Apollo.BaseMutationOptions<RestartSubscriptionMutation, RestartSubscriptionMutationVariables>;
export const RefundSubscriptionDocument = gql`
    mutation RefundSubscription($input: RefundSubscriptionInput!) {
  refundSubscription(input: $input) {
    subscription {
      ...PaymentSubscrption
    }
    paymentList {
      ... on RefundInvoice {
        id
        paymentName
        paymentStatus
        cardName
        cardNumber
        billingUserName
        billingUserEmail
        billingUserPhone
        receiptUrl
        refundAt
        refundAmount
      }
    }
  }
}
    ${PaymentSubscrptionFragmentDoc}`;
export type RefundSubscriptionMutationFn = Apollo.MutationFunction<RefundSubscriptionMutation, RefundSubscriptionMutationVariables>;

/**
 * __useRefundSubscriptionMutation__
 *
 * To run a mutation, you first call `useRefundSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundSubscriptionMutation, { data, loading, error }] = useRefundSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<RefundSubscriptionMutation, RefundSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundSubscriptionMutation, RefundSubscriptionMutationVariables>(RefundSubscriptionDocument, options);
      }
export type RefundSubscriptionMutationHookResult = ReturnType<typeof useRefundSubscriptionMutation>;
export type RefundSubscriptionMutationResult = Apollo.MutationResult<RefundSubscriptionMutation>;
export type RefundSubscriptionMutationOptions = Apollo.BaseMutationOptions<RefundSubscriptionMutation, RefundSubscriptionMutationVariables>;
export const ResumeSubscriptionDocument = gql`
    mutation ResumeSubscription($input: ResumeSubscriptionInput!) {
  resumeSubscription(input: $input) {
    id
  }
}
    `;
export type ResumeSubscriptionMutationFn = Apollo.MutationFunction<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>;

/**
 * __useResumeSubscriptionMutation__
 *
 * To run a mutation, you first call `useResumeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeSubscriptionMutation, { data, loading, error }] = useResumeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>(ResumeSubscriptionDocument, options);
      }
export type ResumeSubscriptionMutationHookResult = ReturnType<typeof useResumeSubscriptionMutation>;
export type ResumeSubscriptionMutationResult = Apollo.MutationResult<ResumeSubscriptionMutation>;
export type ResumeSubscriptionMutationOptions = Apollo.BaseMutationOptions<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($input: CancelSubscriptionInput!) {
  cancelSubscription(input: $input) {
    id
  }
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const RefundDocument = gql`
    mutation Refund($input: Refund!) {
  refund(input: $input) {
    success
  }
}
    `;
export type RefundMutationFn = Apollo.MutationFunction<RefundMutation, RefundMutationVariables>;

/**
 * __useRefundMutation__
 *
 * To run a mutation, you first call `useRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundMutation, { data, loading, error }] = useRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundMutation(baseOptions?: Apollo.MutationHookOptions<RefundMutation, RefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundMutation, RefundMutationVariables>(RefundDocument, options);
      }
export type RefundMutationHookResult = ReturnType<typeof useRefundMutation>;
export type RefundMutationResult = Apollo.MutationResult<RefundMutation>;
export type RefundMutationOptions = Apollo.BaseMutationOptions<RefundMutation, RefundMutationVariables>;
export const RePaymentRequestDocument = gql`
    mutation RePaymentRequest($input: RetryPayment!) {
  retryPayment(input: $input) {
    subscription {
      ...PaymentSubscrption
    }
    paymentList {
      ... on PayInvoice {
        id
        paymentName
        paymentStatus
        cardName
        cardNumber
        billingUserName
        billingUserEmail
        billingUserPhone
        receiptUrl
        paidAt
        amount
      }
    }
  }
}
    ${PaymentSubscrptionFragmentDoc}`;
export type RePaymentRequestMutationFn = Apollo.MutationFunction<RePaymentRequestMutation, RePaymentRequestMutationVariables>;

/**
 * __useRePaymentRequestMutation__
 *
 * To run a mutation, you first call `useRePaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRePaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rePaymentRequestMutation, { data, loading, error }] = useRePaymentRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRePaymentRequestMutation(baseOptions?: Apollo.MutationHookOptions<RePaymentRequestMutation, RePaymentRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RePaymentRequestMutation, RePaymentRequestMutationVariables>(RePaymentRequestDocument, options);
      }
export type RePaymentRequestMutationHookResult = ReturnType<typeof useRePaymentRequestMutation>;
export type RePaymentRequestMutationResult = Apollo.MutationResult<RePaymentRequestMutation>;
export type RePaymentRequestMutationOptions = Apollo.BaseMutationOptions<RePaymentRequestMutation, RePaymentRequestMutationVariables>;
export const ExportTrainingReportDocument = gql`
    mutation ExportTrainingReport($input: ExportReport!) {
  exportTrainingReport(input: $input)
}
    `;
export type ExportTrainingReportMutationFn = Apollo.MutationFunction<ExportTrainingReportMutation, ExportTrainingReportMutationVariables>;

/**
 * __useExportTrainingReportMutation__
 *
 * To run a mutation, you first call `useExportTrainingReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTrainingReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTrainingReportMutation, { data, loading, error }] = useExportTrainingReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTrainingReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportTrainingReportMutation, ExportTrainingReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportTrainingReportMutation, ExportTrainingReportMutationVariables>(ExportTrainingReportDocument, options);
      }
export type ExportTrainingReportMutationHookResult = ReturnType<typeof useExportTrainingReportMutation>;
export type ExportTrainingReportMutationResult = Apollo.MutationResult<ExportTrainingReportMutation>;
export type ExportTrainingReportMutationOptions = Apollo.BaseMutationOptions<ExportTrainingReportMutation, ExportTrainingReportMutationVariables>;
export const ExportMatchReportDocument = gql`
    mutation ExportMatchReport($input: ExportReport!) {
  exportMatchReport(input: $input)
}
    `;
export type ExportMatchReportMutationFn = Apollo.MutationFunction<ExportMatchReportMutation, ExportMatchReportMutationVariables>;

/**
 * __useExportMatchReportMutation__
 *
 * To run a mutation, you first call `useExportMatchReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportMatchReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportMatchReportMutation, { data, loading, error }] = useExportMatchReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportMatchReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportMatchReportMutation, ExportMatchReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportMatchReportMutation, ExportMatchReportMutationVariables>(ExportMatchReportDocument, options);
      }
export type ExportMatchReportMutationHookResult = ReturnType<typeof useExportMatchReportMutation>;
export type ExportMatchReportMutationResult = Apollo.MutationResult<ExportMatchReportMutation>;
export type ExportMatchReportMutationOptions = Apollo.BaseMutationOptions<ExportMatchReportMutation, ExportMatchReportMutationVariables>;
export const LeaveTeamDocument = gql`
    mutation LeaveTeam($input: TeamUserInput!) {
  leaveTeam(input: $input)
}
    `;
export type LeaveTeamMutationFn = Apollo.MutationFunction<LeaveTeamMutation, LeaveTeamMutationVariables>;

/**
 * __useLeaveTeamMutation__
 *
 * To run a mutation, you first call `useLeaveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTeamMutation, { data, loading, error }] = useLeaveTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveTeamMutation(baseOptions?: Apollo.MutationHookOptions<LeaveTeamMutation, LeaveTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveTeamMutation, LeaveTeamMutationVariables>(LeaveTeamDocument, options);
      }
export type LeaveTeamMutationHookResult = ReturnType<typeof useLeaveTeamMutation>;
export type LeaveTeamMutationResult = Apollo.MutationResult<LeaveTeamMutation>;
export type LeaveTeamMutationOptions = Apollo.BaseMutationOptions<LeaveTeamMutation, LeaveTeamMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($teamId: ID!) {
  deleteTeam(teamId: $teamId) {
    id
  }
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const TransferTeamOwnershipDocument = gql`
    mutation TransferTeamOwnership($input: TeamUserInput!) {
  transferTeamOwnership(input: $input) {
    ownerTeamUserMap {
      id
    }
    adminTeamUserMap {
      id
    }
  }
}
    `;
export type TransferTeamOwnershipMutationFn = Apollo.MutationFunction<TransferTeamOwnershipMutation, TransferTeamOwnershipMutationVariables>;

/**
 * __useTransferTeamOwnershipMutation__
 *
 * To run a mutation, you first call `useTransferTeamOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferTeamOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferTeamOwnershipMutation, { data, loading, error }] = useTransferTeamOwnershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferTeamOwnershipMutation(baseOptions?: Apollo.MutationHookOptions<TransferTeamOwnershipMutation, TransferTeamOwnershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferTeamOwnershipMutation, TransferTeamOwnershipMutationVariables>(TransferTeamOwnershipDocument, options);
      }
export type TransferTeamOwnershipMutationHookResult = ReturnType<typeof useTransferTeamOwnershipMutation>;
export type TransferTeamOwnershipMutationResult = Apollo.MutationResult<TransferTeamOwnershipMutation>;
export type TransferTeamOwnershipMutationOptions = Apollo.BaseMutationOptions<TransferTeamOwnershipMutation, TransferTeamOwnershipMutationVariables>;
export const KickoutTeamUserListDocument = gql`
    mutation KickoutTeamUserList($input: TeamUserListInput!) {
  kickoutTeamUserList(input: $input)
}
    `;
export type KickoutTeamUserListMutationFn = Apollo.MutationFunction<KickoutTeamUserListMutation, KickoutTeamUserListMutationVariables>;

/**
 * __useKickoutTeamUserListMutation__
 *
 * To run a mutation, you first call `useKickoutTeamUserListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKickoutTeamUserListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kickoutTeamUserListMutation, { data, loading, error }] = useKickoutTeamUserListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKickoutTeamUserListMutation(baseOptions?: Apollo.MutationHookOptions<KickoutTeamUserListMutation, KickoutTeamUserListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KickoutTeamUserListMutation, KickoutTeamUserListMutationVariables>(KickoutTeamUserListDocument, options);
      }
export type KickoutTeamUserListMutationHookResult = ReturnType<typeof useKickoutTeamUserListMutation>;
export type KickoutTeamUserListMutationResult = Apollo.MutationResult<KickoutTeamUserListMutation>;
export type KickoutTeamUserListMutationOptions = Apollo.BaseMutationOptions<KickoutTeamUserListMutation, KickoutTeamUserListMutationVariables>;
export const CreateTeamGroupDocument = gql`
    mutation CreateTeamGroup($input: CreateTeamGroupInput!) {
  createTeamGroup(input: $input) {
    ...FTeamGroup
    groupMembers {
      user {
        ...FSportsUser
      }
    }
  }
}
    ${FTeamGroupFragmentDoc}
${FSportsUserFragmentDoc}`;
export type CreateTeamGroupMutationFn = Apollo.MutationFunction<CreateTeamGroupMutation, CreateTeamGroupMutationVariables>;

/**
 * __useCreateTeamGroupMutation__
 *
 * To run a mutation, you first call `useCreateTeamGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamGroupMutation, { data, loading, error }] = useCreateTeamGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamGroupMutation, CreateTeamGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamGroupMutation, CreateTeamGroupMutationVariables>(CreateTeamGroupDocument, options);
      }
export type CreateTeamGroupMutationHookResult = ReturnType<typeof useCreateTeamGroupMutation>;
export type CreateTeamGroupMutationResult = Apollo.MutationResult<CreateTeamGroupMutation>;
export type CreateTeamGroupMutationOptions = Apollo.BaseMutationOptions<CreateTeamGroupMutation, CreateTeamGroupMutationVariables>;
export const UpdateTeamGroupDocument = gql`
    mutation UpdateTeamGroup($input: UpdateTeamGroupInput!) {
  updateTeamGroup(input: $input) {
    ...FTeamGroup
    groupMembers {
      user {
        ...FSportsUser
      }
    }
  }
}
    ${FTeamGroupFragmentDoc}
${FSportsUserFragmentDoc}`;
export type UpdateTeamGroupMutationFn = Apollo.MutationFunction<UpdateTeamGroupMutation, UpdateTeamGroupMutationVariables>;

/**
 * __useUpdateTeamGroupMutation__
 *
 * To run a mutation, you first call `useUpdateTeamGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamGroupMutation, { data, loading, error }] = useUpdateTeamGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamGroupMutation, UpdateTeamGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamGroupMutation, UpdateTeamGroupMutationVariables>(UpdateTeamGroupDocument, options);
      }
export type UpdateTeamGroupMutationHookResult = ReturnType<typeof useUpdateTeamGroupMutation>;
export type UpdateTeamGroupMutationResult = Apollo.MutationResult<UpdateTeamGroupMutation>;
export type UpdateTeamGroupMutationOptions = Apollo.BaseMutationOptions<UpdateTeamGroupMutation, UpdateTeamGroupMutationVariables>;
export const DeleteTeamGroupDocument = gql`
    mutation DeleteTeamGroup($input: DeleteTeamGroupInput!) {
  deleteTeamGroup(input: $input) {
    ...FTeamGroup
  }
}
    ${FTeamGroupFragmentDoc}`;
export type DeleteTeamGroupMutationFn = Apollo.MutationFunction<DeleteTeamGroupMutation, DeleteTeamGroupMutationVariables>;

/**
 * __useDeleteTeamGroupMutation__
 *
 * To run a mutation, you first call `useDeleteTeamGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamGroupMutation, { data, loading, error }] = useDeleteTeamGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTeamGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamGroupMutation, DeleteTeamGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamGroupMutation, DeleteTeamGroupMutationVariables>(DeleteTeamGroupDocument, options);
      }
export type DeleteTeamGroupMutationHookResult = ReturnType<typeof useDeleteTeamGroupMutation>;
export type DeleteTeamGroupMutationResult = Apollo.MutationResult<DeleteTeamGroupMutation>;
export type DeleteTeamGroupMutationOptions = Apollo.BaseMutationOptions<DeleteTeamGroupMutation, DeleteTeamGroupMutationVariables>;
export const RequestTeamInvitationDocument = gql`
    mutation RequestTeamInvitation($input: SportsTeamInvitationRequestInput!) {
  requestTeamInvitation(input: $input) {
    id
    team {
      id
      code
      name
    }
    user {
      id
    }
  }
}
    `;
export type RequestTeamInvitationMutationFn = Apollo.MutationFunction<RequestTeamInvitationMutation, RequestTeamInvitationMutationVariables>;

/**
 * __useRequestTeamInvitationMutation__
 *
 * To run a mutation, you first call `useRequestTeamInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTeamInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTeamInvitationMutation, { data, loading, error }] = useRequestTeamInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTeamInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RequestTeamInvitationMutation, RequestTeamInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestTeamInvitationMutation, RequestTeamInvitationMutationVariables>(RequestTeamInvitationDocument, options);
      }
export type RequestTeamInvitationMutationHookResult = ReturnType<typeof useRequestTeamInvitationMutation>;
export type RequestTeamInvitationMutationResult = Apollo.MutationResult<RequestTeamInvitationMutation>;
export type RequestTeamInvitationMutationOptions = Apollo.BaseMutationOptions<RequestTeamInvitationMutation, RequestTeamInvitationMutationVariables>;
export const AcceptInvitationListDocument = gql`
    mutation AcceptInvitationList($input: SportsTeamInvitationAcceptListInput!) {
  acceptInvitationList(input: $input) {
    id
  }
}
    `;
export type AcceptInvitationListMutationFn = Apollo.MutationFunction<AcceptInvitationListMutation, AcceptInvitationListMutationVariables>;

/**
 * __useAcceptInvitationListMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationListMutation, { data, loading, error }] = useAcceptInvitationListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInvitationListMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationListMutation, AcceptInvitationListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationListMutation, AcceptInvitationListMutationVariables>(AcceptInvitationListDocument, options);
      }
export type AcceptInvitationListMutationHookResult = ReturnType<typeof useAcceptInvitationListMutation>;
export type AcceptInvitationListMutationResult = Apollo.MutationResult<AcceptInvitationListMutation>;
export type AcceptInvitationListMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationListMutation, AcceptInvitationListMutationVariables>;
export const RejectTeamInvitationListDocument = gql`
    mutation RejectTeamInvitationList($input: SportsTeamInvitationRejectListInput!) {
  rejectTeamInvitationList(input: $input) {
    id
  }
}
    `;
export type RejectTeamInvitationListMutationFn = Apollo.MutationFunction<RejectTeamInvitationListMutation, RejectTeamInvitationListMutationVariables>;

/**
 * __useRejectTeamInvitationListMutation__
 *
 * To run a mutation, you first call `useRejectTeamInvitationListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTeamInvitationListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTeamInvitationListMutation, { data, loading, error }] = useRejectTeamInvitationListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectTeamInvitationListMutation(baseOptions?: Apollo.MutationHookOptions<RejectTeamInvitationListMutation, RejectTeamInvitationListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectTeamInvitationListMutation, RejectTeamInvitationListMutationVariables>(RejectTeamInvitationListDocument, options);
      }
export type RejectTeamInvitationListMutationHookResult = ReturnType<typeof useRejectTeamInvitationListMutation>;
export type RejectTeamInvitationListMutationResult = Apollo.MutationResult<RejectTeamInvitationListMutation>;
export type RejectTeamInvitationListMutationOptions = Apollo.BaseMutationOptions<RejectTeamInvitationListMutation, RejectTeamInvitationListMutationVariables>;
export const ToggleTeamUserStarStatusDocument = gql`
    mutation ToggleTeamUserStarStatus($input: ToggleTeamUserStarredInput!) {
  toggleTeamUserStarStatus(input: $input) {
    status
  }
}
    `;
export type ToggleTeamUserStarStatusMutationFn = Apollo.MutationFunction<ToggleTeamUserStarStatusMutation, ToggleTeamUserStarStatusMutationVariables>;

/**
 * __useToggleTeamUserStarStatusMutation__
 *
 * To run a mutation, you first call `useToggleTeamUserStarStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTeamUserStarStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTeamUserStarStatusMutation, { data, loading, error }] = useToggleTeamUserStarStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleTeamUserStarStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleTeamUserStarStatusMutation, ToggleTeamUserStarStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleTeamUserStarStatusMutation, ToggleTeamUserStarStatusMutationVariables>(ToggleTeamUserStarStatusDocument, options);
      }
export type ToggleTeamUserStarStatusMutationHookResult = ReturnType<typeof useToggleTeamUserStarStatusMutation>;
export type ToggleTeamUserStarStatusMutationResult = Apollo.MutationResult<ToggleTeamUserStarStatusMutation>;
export type ToggleTeamUserStarStatusMutationOptions = Apollo.BaseMutationOptions<ToggleTeamUserStarStatusMutation, ToggleTeamUserStarStatusMutationVariables>;
export const UploadProfileDocument = gql`
    mutation UploadProfile($file: Upload!) {
  uploadProfile(file: $file) {
    user {
      id
    }
  }
}
    `;
export type UploadProfileMutationFn = Apollo.MutationFunction<UploadProfileMutation, UploadProfileMutationVariables>;

/**
 * __useUploadProfileMutation__
 *
 * To run a mutation, you first call `useUploadProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfileMutation, { data, loading, error }] = useUploadProfileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfileMutation(baseOptions?: Apollo.MutationHookOptions<UploadProfileMutation, UploadProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProfileMutation, UploadProfileMutationVariables>(UploadProfileDocument, options);
      }
export type UploadProfileMutationHookResult = ReturnType<typeof useUploadProfileMutation>;
export type UploadProfileMutationResult = Apollo.MutationResult<UploadProfileMutation>;
export type UploadProfileMutationOptions = Apollo.BaseMutationOptions<UploadProfileMutation, UploadProfileMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateSportsUserInput!) {
  updateUser(input: $input) {
    ...FSportsUser
  }
}
    ${FSportsUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AnalyticsViewerDocument = gql`
    query AnalyticsViewer {
  sports {
    viewer {
      ...AnalyticsViewer
    }
  }
}
    ${AnalyticsViewerFragmentDoc}`;

/**
 * __useAnalyticsViewerQuery__
 *
 * To run a query within a React component, call `useAnalyticsViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsViewerQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsViewerQuery, AnalyticsViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsViewerQuery, AnalyticsViewerQueryVariables>(AnalyticsViewerDocument, options);
      }
export function useAnalyticsViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsViewerQuery, AnalyticsViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsViewerQuery, AnalyticsViewerQueryVariables>(AnalyticsViewerDocument, options);
        }
export type AnalyticsViewerQueryHookResult = ReturnType<typeof useAnalyticsViewerQuery>;
export type AnalyticsViewerLazyQueryHookResult = ReturnType<typeof useAnalyticsViewerLazyQuery>;
export type AnalyticsViewerQueryResult = Apollo.QueryResult<AnalyticsViewerQuery, AnalyticsViewerQueryVariables>;
export function refetchAnalyticsViewerQuery(variables?: AnalyticsViewerQueryVariables) {
      return { query: AnalyticsViewerDocument, variables: variables }
    }
export const InvoiceBillingDocument = gql`
    query InvoiceBilling($identityToken: ID!) {
  billing(identityToken: $identityToken) {
    id
    ...InvoiceBillingItem
  }
}
    ${InvoiceBillingItemFragmentDoc}`;

/**
 * __useInvoiceBillingQuery__
 *
 * To run a query within a React component, call `useInvoiceBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceBillingQuery({
 *   variables: {
 *      identityToken: // value for 'identityToken'
 *   },
 * });
 */
export function useInvoiceBillingQuery(baseOptions: Apollo.QueryHookOptions<InvoiceBillingQuery, InvoiceBillingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceBillingQuery, InvoiceBillingQueryVariables>(InvoiceBillingDocument, options);
      }
export function useInvoiceBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceBillingQuery, InvoiceBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceBillingQuery, InvoiceBillingQueryVariables>(InvoiceBillingDocument, options);
        }
export type InvoiceBillingQueryHookResult = ReturnType<typeof useInvoiceBillingQuery>;
export type InvoiceBillingLazyQueryHookResult = ReturnType<typeof useInvoiceBillingLazyQuery>;
export type InvoiceBillingQueryResult = Apollo.QueryResult<InvoiceBillingQuery, InvoiceBillingQueryVariables>;
export function refetchInvoiceBillingQuery(variables?: InvoiceBillingQueryVariables) {
      return { query: InvoiceBillingDocument, variables: variables }
    }
export const MultilingualDataDocument = gql`
    query MultilingualData($input: MultilingualTextInput!) {
  multilingualText(input: $input) {
    categoryGroupList {
      id
      value
      categoryList {
        id
        value
        coachPositionList {
          id
          value
        }
        id
        value
        playerPositionList {
          id
          value
        }
      }
    }
    countryList {
      id
      value
    }
  }
}
    `;

/**
 * __useMultilingualDataQuery__
 *
 * To run a query within a React component, call `useMultilingualDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultilingualDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultilingualDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultilingualDataQuery(baseOptions: Apollo.QueryHookOptions<MultilingualDataQuery, MultilingualDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MultilingualDataQuery, MultilingualDataQueryVariables>(MultilingualDataDocument, options);
      }
export function useMultilingualDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MultilingualDataQuery, MultilingualDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MultilingualDataQuery, MultilingualDataQueryVariables>(MultilingualDataDocument, options);
        }
export type MultilingualDataQueryHookResult = ReturnType<typeof useMultilingualDataQuery>;
export type MultilingualDataLazyQueryHookResult = ReturnType<typeof useMultilingualDataLazyQuery>;
export type MultilingualDataQueryResult = Apollo.QueryResult<MultilingualDataQuery, MultilingualDataQueryVariables>;
export function refetchMultilingualDataQuery(variables?: MultilingualDataQueryVariables) {
      return { query: MultilingualDataDocument, variables: variables }
    }
export const TeamPlanListDocument = gql`
    query TeamPlanList($couponId: String) {
  teamPlanList(couponId: $couponId) {
    id
    planName
    planType
    limitCount
    discountStartAt
    discountEndAt
    originalPriceStartAt
    planOptionInfoList {
      ...PlanOptionInfo
    }
  }
}
    ${PlanOptionInfoFragmentDoc}`;

/**
 * __useTeamPlanListQuery__
 *
 * To run a query within a React component, call `useTeamPlanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPlanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPlanListQuery({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useTeamPlanListQuery(baseOptions?: Apollo.QueryHookOptions<TeamPlanListQuery, TeamPlanListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamPlanListQuery, TeamPlanListQueryVariables>(TeamPlanListDocument, options);
      }
export function useTeamPlanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamPlanListQuery, TeamPlanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamPlanListQuery, TeamPlanListQueryVariables>(TeamPlanListDocument, options);
        }
export type TeamPlanListQueryHookResult = ReturnType<typeof useTeamPlanListQuery>;
export type TeamPlanListLazyQueryHookResult = ReturnType<typeof useTeamPlanListLazyQuery>;
export type TeamPlanListQueryResult = Apollo.QueryResult<TeamPlanListQuery, TeamPlanListQueryVariables>;
export function refetchTeamPlanListQuery(variables?: TeamPlanListQueryVariables) {
      return { query: TeamPlanListDocument, variables: variables }
    }
export const PlayerInjuryDocument = gql`
    query PlayerInjury($playerId: ID!, $from: DateTime!, $to: DateTime!, $timezone: String!, $input: MultilingualTextInput!) {
  sports {
    user(id: $playerId) {
      id
      ...PlayerSummaryIdentity
      data(
        from: $from
        to: $to
        timezone: $timezone
        stride: DAY
        names: [INJURY, SORENESS]
      ) {
        name
        ranges(input: $input) {
          entries {
            time
            value
          }
        }
      }
    }
  }
}
    ${PlayerSummaryIdentityFragmentDoc}`;

/**
 * __usePlayerInjuryQuery__
 *
 * To run a query within a React component, call `usePlayerInjuryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerInjuryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerInjuryQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      timezone: // value for 'timezone'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlayerInjuryQuery(baseOptions: Apollo.QueryHookOptions<PlayerInjuryQuery, PlayerInjuryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerInjuryQuery, PlayerInjuryQueryVariables>(PlayerInjuryDocument, options);
      }
export function usePlayerInjuryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerInjuryQuery, PlayerInjuryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerInjuryQuery, PlayerInjuryQueryVariables>(PlayerInjuryDocument, options);
        }
export type PlayerInjuryQueryHookResult = ReturnType<typeof usePlayerInjuryQuery>;
export type PlayerInjuryLazyQueryHookResult = ReturnType<typeof usePlayerInjuryLazyQuery>;
export type PlayerInjuryQueryResult = Apollo.QueryResult<PlayerInjuryQuery, PlayerInjuryQueryVariables>;
export function refetchPlayerInjuryQuery(variables?: PlayerInjuryQueryVariables) {
      return { query: PlayerInjuryDocument, variables: variables }
    }
export const PlayerSelectAllUserMapsByGroupDocument = gql`
    query PlayerSelectAllUserMapsByGroup($id: ID!) {
  sports {
    team(id: $id) {
      id
      memberUserList {
        count
        userList {
          id
          name
          pictureUrl
          nationality
        }
      }
    }
  }
}
    `;

/**
 * __usePlayerSelectAllUserMapsByGroupQuery__
 *
 * To run a query within a React component, call `usePlayerSelectAllUserMapsByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerSelectAllUserMapsByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerSelectAllUserMapsByGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlayerSelectAllUserMapsByGroupQuery(baseOptions: Apollo.QueryHookOptions<PlayerSelectAllUserMapsByGroupQuery, PlayerSelectAllUserMapsByGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerSelectAllUserMapsByGroupQuery, PlayerSelectAllUserMapsByGroupQueryVariables>(PlayerSelectAllUserMapsByGroupDocument, options);
      }
export function usePlayerSelectAllUserMapsByGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerSelectAllUserMapsByGroupQuery, PlayerSelectAllUserMapsByGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerSelectAllUserMapsByGroupQuery, PlayerSelectAllUserMapsByGroupQueryVariables>(PlayerSelectAllUserMapsByGroupDocument, options);
        }
export type PlayerSelectAllUserMapsByGroupQueryHookResult = ReturnType<typeof usePlayerSelectAllUserMapsByGroupQuery>;
export type PlayerSelectAllUserMapsByGroupLazyQueryHookResult = ReturnType<typeof usePlayerSelectAllUserMapsByGroupLazyQuery>;
export type PlayerSelectAllUserMapsByGroupQueryResult = Apollo.QueryResult<PlayerSelectAllUserMapsByGroupQuery, PlayerSelectAllUserMapsByGroupQueryVariables>;
export function refetchPlayerSelectAllUserMapsByGroupQuery(variables?: PlayerSelectAllUserMapsByGroupQueryVariables) {
      return { query: PlayerSelectAllUserMapsByGroupDocument, variables: variables }
    }
export const GroupsMemberMapByTeamDocument = gql`
    query GroupsMemberMapByTeam($id: ID!) {
  sports {
    team(id: $id) {
      id
      groups {
        ...FTeamGroup
        groupMembers {
          user {
            ...FSportsUser
          }
        }
      }
    }
  }
}
    ${FTeamGroupFragmentDoc}
${FSportsUserFragmentDoc}`;

/**
 * __useGroupsMemberMapByTeamQuery__
 *
 * To run a query within a React component, call `useGroupsMemberMapByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsMemberMapByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsMemberMapByTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupsMemberMapByTeamQuery(baseOptions: Apollo.QueryHookOptions<GroupsMemberMapByTeamQuery, GroupsMemberMapByTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsMemberMapByTeamQuery, GroupsMemberMapByTeamQueryVariables>(GroupsMemberMapByTeamDocument, options);
      }
export function useGroupsMemberMapByTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsMemberMapByTeamQuery, GroupsMemberMapByTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsMemberMapByTeamQuery, GroupsMemberMapByTeamQueryVariables>(GroupsMemberMapByTeamDocument, options);
        }
export type GroupsMemberMapByTeamQueryHookResult = ReturnType<typeof useGroupsMemberMapByTeamQuery>;
export type GroupsMemberMapByTeamLazyQueryHookResult = ReturnType<typeof useGroupsMemberMapByTeamLazyQuery>;
export type GroupsMemberMapByTeamQueryResult = Apollo.QueryResult<GroupsMemberMapByTeamQuery, GroupsMemberMapByTeamQueryVariables>;
export function refetchGroupsMemberMapByTeamQuery(variables?: GroupsMemberMapByTeamQueryVariables) {
      return { query: GroupsMemberMapByTeamDocument, variables: variables }
    }
export const TeamChartVarianceTodayDataDocument = gql`
    query TeamChartVarianceTodayData($teamId: ID!, $cursor: DateTime!, $timezone: String!) {
  sports {
    team(id: $teamId) {
      id
      dailyData(cursor: $cursor, timezone: $timezone) {
        acwr {
          range {
            entries {
              meta
              time
              value
            }
            from
            to
          }
          value
          varianceFromYesterday
        }
        condition {
          range {
            entries {
              meta
              time
              value
            }
            from
            to
          }
          value
          varianceFromYesterday
        }
        readiness {
          value
          varianceFromYesterday
        }
        workload {
          value
          varianceFromYesterday
          range {
            entries {
              meta
              time
              value
            }
            from
            to
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTeamChartVarianceTodayDataQuery__
 *
 * To run a query within a React component, call `useTeamChartVarianceTodayDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamChartVarianceTodayDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamChartVarianceTodayDataQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      cursor: // value for 'cursor'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useTeamChartVarianceTodayDataQuery(baseOptions: Apollo.QueryHookOptions<TeamChartVarianceTodayDataQuery, TeamChartVarianceTodayDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamChartVarianceTodayDataQuery, TeamChartVarianceTodayDataQueryVariables>(TeamChartVarianceTodayDataDocument, options);
      }
export function useTeamChartVarianceTodayDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamChartVarianceTodayDataQuery, TeamChartVarianceTodayDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamChartVarianceTodayDataQuery, TeamChartVarianceTodayDataQueryVariables>(TeamChartVarianceTodayDataDocument, options);
        }
export type TeamChartVarianceTodayDataQueryHookResult = ReturnType<typeof useTeamChartVarianceTodayDataQuery>;
export type TeamChartVarianceTodayDataLazyQueryHookResult = ReturnType<typeof useTeamChartVarianceTodayDataLazyQuery>;
export type TeamChartVarianceTodayDataQueryResult = Apollo.QueryResult<TeamChartVarianceTodayDataQuery, TeamChartVarianceTodayDataQueryVariables>;
export function refetchTeamChartVarianceTodayDataQuery(variables?: TeamChartVarianceTodayDataQueryVariables) {
      return { query: TeamChartVarianceTodayDataDocument, variables: variables }
    }
export const TeamChartVarianceDataDocument = gql`
    query TeamChartVarianceData($teamId: ID!, $cursor: DateTime!, $timezone: String!, $filter: TeamUserMapForTeamDataFilterInput) {
  sports {
    team(id: $teamId) {
      id
      dailyData(cursor: $cursor, timezone: $timezone, filter: $filter) {
        acwr {
          range {
            entries {
              meta
              time
              value
            }
            from
            to
          }
          value
          varianceFromYesterday
        }
        condition {
          range {
            entries {
              meta
              time
              value
            }
            from
            to
          }
          value
          varianceFromYesterday
        }
        readiness {
          value
          varianceFromYesterday
        }
        workload {
          value
          varianceFromYesterday
          range {
            entries {
              meta
              time
              value
            }
            from
            to
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTeamChartVarianceDataQuery__
 *
 * To run a query within a React component, call `useTeamChartVarianceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamChartVarianceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamChartVarianceDataQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      cursor: // value for 'cursor'
 *      timezone: // value for 'timezone'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamChartVarianceDataQuery(baseOptions: Apollo.QueryHookOptions<TeamChartVarianceDataQuery, TeamChartVarianceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamChartVarianceDataQuery, TeamChartVarianceDataQueryVariables>(TeamChartVarianceDataDocument, options);
      }
export function useTeamChartVarianceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamChartVarianceDataQuery, TeamChartVarianceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamChartVarianceDataQuery, TeamChartVarianceDataQueryVariables>(TeamChartVarianceDataDocument, options);
        }
export type TeamChartVarianceDataQueryHookResult = ReturnType<typeof useTeamChartVarianceDataQuery>;
export type TeamChartVarianceDataLazyQueryHookResult = ReturnType<typeof useTeamChartVarianceDataLazyQuery>;
export type TeamChartVarianceDataQueryResult = Apollo.QueryResult<TeamChartVarianceDataQuery, TeamChartVarianceDataQueryVariables>;
export function refetchTeamChartVarianceDataQuery(variables?: TeamChartVarianceDataQueryVariables) {
      return { query: TeamChartVarianceDataDocument, variables: variables }
    }
export const ViewerDocument = gql`
    query Viewer {
  sports {
    viewer {
      id
      ...FSportsUser
      guideReopenStatus {
        alarm {
          web
        }
      }
      teamMaps {
        ...FSportsTeamUserMap
      }
    }
  }
}
    ${FSportsUserFragmentDoc}
${FSportsTeamUserMapFragmentDoc}`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
      }
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<ViewerQuery, ViewerQueryVariables>;
export function refetchViewerQuery(variables?: ViewerQueryVariables) {
      return { query: ViewerDocument, variables: variables }
    }
export const QuerySystemUserDataDocument = gql`
    query QuerySystemUserData($id: ID!, $from: DateTime!, $to: DateTime!, $groups: [SportsDataGroup!], $names: [SportsDataName!], $timezone: String!, $input: MultilingualTextInput!) {
  sports {
    user(id: $id) {
      id
      name
      pictureUrl
      data(
        from: $from
        to: $to
        stride: DAY
        groups: $groups
        names: $names
        timezone: $timezone
      ) {
        name
        ranges(input: $input) {
          from
          entries {
            value
            time
          }
        }
      }
      workload(from: $from, to: $to) {
        time
        count
        reasonForNoWorkout
        workload {
          sum
        }
        duration {
          sum
        }
        intensity {
          avg
        }
        satisfaction {
          avg
        }
        workloadList {
          id
          name
          time
          load
          duration
          intensity
          satisfaction
          type
          event {
            event {
              id
              title
              hideExpectedIntensityFromPlayers
              startAt
              endAt
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuerySystemUserDataQuery__
 *
 * To run a query within a React component, call `useQuerySystemUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySystemUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySystemUserDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      groups: // value for 'groups'
 *      names: // value for 'names'
 *      timezone: // value for 'timezone'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuerySystemUserDataQuery(baseOptions: Apollo.QueryHookOptions<QuerySystemUserDataQuery, QuerySystemUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySystemUserDataQuery, QuerySystemUserDataQueryVariables>(QuerySystemUserDataDocument, options);
      }
export function useQuerySystemUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySystemUserDataQuery, QuerySystemUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySystemUserDataQuery, QuerySystemUserDataQueryVariables>(QuerySystemUserDataDocument, options);
        }
export type QuerySystemUserDataQueryHookResult = ReturnType<typeof useQuerySystemUserDataQuery>;
export type QuerySystemUserDataLazyQueryHookResult = ReturnType<typeof useQuerySystemUserDataLazyQuery>;
export type QuerySystemUserDataQueryResult = Apollo.QueryResult<QuerySystemUserDataQuery, QuerySystemUserDataQueryVariables>;
export function refetchQuerySystemUserDataQuery(variables?: QuerySystemUserDataQueryVariables) {
      return { query: QuerySystemUserDataDocument, variables: variables }
    }
export const QuerySystemUserWorkloadDataDocument = gql`
    query QuerySystemUserWorkloadData($id: ID!, $from: DateTime!, $to: DateTime!, $timezone: String!, $teamId: ID) {
  sports {
    user(id: $id) {
      id
      name
      pictureUrl
      sportsDataList(from: $from, to: $to, timezone: $timezone) {
        id
        date
        workload {
          count
          reasonForNoWorkout
          isChecked
          workload {
            sum
          }
          duration {
            sum
          }
          intensity {
            avg
          }
          satisfaction {
            avg
          }
          workloadList {
            id
            time
            load
            duration
            intensity
            satisfaction
            type
            schedule {
              id
              schedule(teamId: $teamId) {
                id
                title
                startTime
                endTime
                team {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuerySystemUserWorkloadDataQuery__
 *
 * To run a query within a React component, call `useQuerySystemUserWorkloadDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySystemUserWorkloadDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySystemUserWorkloadDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      timezone: // value for 'timezone'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useQuerySystemUserWorkloadDataQuery(baseOptions: Apollo.QueryHookOptions<QuerySystemUserWorkloadDataQuery, QuerySystemUserWorkloadDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySystemUserWorkloadDataQuery, QuerySystemUserWorkloadDataQueryVariables>(QuerySystemUserWorkloadDataDocument, options);
      }
export function useQuerySystemUserWorkloadDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySystemUserWorkloadDataQuery, QuerySystemUserWorkloadDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySystemUserWorkloadDataQuery, QuerySystemUserWorkloadDataQueryVariables>(QuerySystemUserWorkloadDataDocument, options);
        }
export type QuerySystemUserWorkloadDataQueryHookResult = ReturnType<typeof useQuerySystemUserWorkloadDataQuery>;
export type QuerySystemUserWorkloadDataLazyQueryHookResult = ReturnType<typeof useQuerySystemUserWorkloadDataLazyQuery>;
export type QuerySystemUserWorkloadDataQueryResult = Apollo.QueryResult<QuerySystemUserWorkloadDataQuery, QuerySystemUserWorkloadDataQueryVariables>;
export function refetchQuerySystemUserWorkloadDataQuery(variables?: QuerySystemUserWorkloadDataQueryVariables) {
      return { query: QuerySystemUserWorkloadDataDocument, variables: variables }
    }
export const QuerySystemTeamUserMapsDataDocument = gql`
    query QuerySystemTeamUserMapsData($id: ID!, $cursor: DateTime!, $offset: Int, $limit: Int, $timezone: String!, $groups: [SportsDataGroup!], $names: [SportsDataName!], $filter: TeamUserMapFilterInput, $order: UserDataOrderByInput, $input: MultilingualTextInput!) {
  sports {
    team(id: $id) {
      id
      userMapsByPage(limit: $limit, offset: $offset, filter: $filter, order: $order) {
        offset
        limit
        total
        hasPrev
        hasNext
        entries {
          id
          teamAuthority
          user {
            id
            name
            pictureUrl
            data(
              from: $cursor
              to: $cursor
              stride: DAY
              groups: $groups
              names: $names
              timezone: $timezone
            ) {
              name
              stride
              ranges(input: $input) {
                from
                entries {
                  time
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuerySystemTeamUserMapsDataQuery__
 *
 * To run a query within a React component, call `useQuerySystemTeamUserMapsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySystemTeamUserMapsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySystemTeamUserMapsDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      timezone: // value for 'timezone'
 *      groups: // value for 'groups'
 *      names: // value for 'names'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuerySystemTeamUserMapsDataQuery(baseOptions: Apollo.QueryHookOptions<QuerySystemTeamUserMapsDataQuery, QuerySystemTeamUserMapsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySystemTeamUserMapsDataQuery, QuerySystemTeamUserMapsDataQueryVariables>(QuerySystemTeamUserMapsDataDocument, options);
      }
export function useQuerySystemTeamUserMapsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySystemTeamUserMapsDataQuery, QuerySystemTeamUserMapsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySystemTeamUserMapsDataQuery, QuerySystemTeamUserMapsDataQueryVariables>(QuerySystemTeamUserMapsDataDocument, options);
        }
export type QuerySystemTeamUserMapsDataQueryHookResult = ReturnType<typeof useQuerySystemTeamUserMapsDataQuery>;
export type QuerySystemTeamUserMapsDataLazyQueryHookResult = ReturnType<typeof useQuerySystemTeamUserMapsDataLazyQuery>;
export type QuerySystemTeamUserMapsDataQueryResult = Apollo.QueryResult<QuerySystemTeamUserMapsDataQuery, QuerySystemTeamUserMapsDataQueryVariables>;
export function refetchQuerySystemTeamUserMapsDataQuery(variables?: QuerySystemTeamUserMapsDataQueryVariables) {
      return { query: QuerySystemTeamUserMapsDataDocument, variables: variables }
    }
export const QuerySystemTeamAverageDataDocument = gql`
    query QuerySystemTeamAverageData($id: ID!, $from: DateTime!, $to: DateTime!, $timezone: String!, $names: [SportsTeamDataName!]!, $filter: TeamUserMapForTeamDataFilterInput) {
  sports {
    team(id: $id) {
      id
      data(from: $from, to: $to, timezone: $timezone, names: $names, filter: $filter) {
        stride
        name
        ranges {
          from
          to
          entries {
            time
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuerySystemTeamAverageDataQuery__
 *
 * To run a query within a React component, call `useQuerySystemTeamAverageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySystemTeamAverageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySystemTeamAverageDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      timezone: // value for 'timezone'
 *      names: // value for 'names'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQuerySystemTeamAverageDataQuery(baseOptions: Apollo.QueryHookOptions<QuerySystemTeamAverageDataQuery, QuerySystemTeamAverageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySystemTeamAverageDataQuery, QuerySystemTeamAverageDataQueryVariables>(QuerySystemTeamAverageDataDocument, options);
      }
export function useQuerySystemTeamAverageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySystemTeamAverageDataQuery, QuerySystemTeamAverageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySystemTeamAverageDataQuery, QuerySystemTeamAverageDataQueryVariables>(QuerySystemTeamAverageDataDocument, options);
        }
export type QuerySystemTeamAverageDataQueryHookResult = ReturnType<typeof useQuerySystemTeamAverageDataQuery>;
export type QuerySystemTeamAverageDataLazyQueryHookResult = ReturnType<typeof useQuerySystemTeamAverageDataLazyQuery>;
export type QuerySystemTeamAverageDataQueryResult = Apollo.QueryResult<QuerySystemTeamAverageDataQuery, QuerySystemTeamAverageDataQueryVariables>;
export function refetchQuerySystemTeamAverageDataQuery(variables?: QuerySystemTeamAverageDataQueryVariables) {
      return { query: QuerySystemTeamAverageDataDocument, variables: variables }
    }
export const MonitoringChartDataDocument = gql`
    query MonitoringChartData($teamId: ID!, $cursor: DateTime!, $timezone: String!, $dataName: ChartDataName!, $filter: TeamUserMapForTeamDataFilterInput, $input: MultilingualTextInput!) {
  sports {
    team(id: $teamId) {
      id
      chartData(
        cursor: $cursor
        timezone: $timezone
        dataName: $dataName
        filter: $filter
      ) {
        avg {
          ... on ChartCondition {
            mood
            muscle
            fatigue
            stress
            duration
            quality
            condition
          }
          ... on ChartWorkload {
            duration
            satisfaction
            workload
            intensity
          }
          ... on ChartWorkloadAcxrAxd {
            acxr
            axd
            ax
            cx
          }
          ... on ChartSoreness {
            level1 {
              count
              parts {
                bodyPart(input: $input) {
                  id
                  value
                }
                bodyPartGroup
              }
            }
            level2 {
              count
              parts {
                bodyPart(input: $input) {
                  id
                  value
                }
                bodyPartGroup
              }
            }
            level3 {
              count
              parts {
                bodyPart(input: $input) {
                  id
                  value
                }
                bodyPartGroup
              }
            }
            level4 {
              count
              parts {
                bodyPart(input: $input) {
                  id
                  value
                }
                bodyPartGroup
              }
            }
            level5 {
              count
              parts {
                bodyPart(input: $input) {
                  id
                  value
                }
                bodyPartGroup
              }
            }
          }
          ... on ChartBody {
            weight
            height
          }
        }
        userList {
          user {
            id
            name
            pictureUrl
          }
          value {
            ... on ChartUserCondition {
              mood
              muscle
              fatigue
              stress
              duration
              quality
              condition
            }
            ... on ChartUserWorkload {
              duration
              satisfaction
              workload
              intensity
              reasonForNoWorkout
              workloadList {
                id
                load
                duration
                time
                intensity
                satisfaction
                type
                memo
                schedule {
                  schedule(teamId: $teamId) {
                    id
                    title
                    team {
                      id
                    }
                  }
                }
              }
            }
            ... on ChartWorkloadAcxrAxd {
              acxr
              axd
              ax
              cx
            }
            ... on ChartSoreness {
              level1 {
                count
                parts {
                  bodyPart(input: $input) {
                    id
                    value
                  }
                  bodyPartGroup
                }
              }
              level2 {
                count
                parts {
                  bodyPart(input: $input) {
                    id
                    value
                  }
                  bodyPartGroup
                }
              }
              level3 {
                count
                parts {
                  bodyPart(input: $input) {
                    id
                    value
                  }
                  bodyPartGroup
                }
              }
              level4 {
                count
                parts {
                  bodyPart(input: $input) {
                    id
                    value
                  }
                  bodyPartGroup
                }
              }
              level5 {
                count
                parts {
                  bodyPart(input: $input) {
                    id
                    value
                  }
                  bodyPartGroup
                }
              }
            }
            ... on ChartBody {
              weight
              height
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMonitoringChartDataQuery__
 *
 * To run a query within a React component, call `useMonitoringChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringChartDataQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      cursor: // value for 'cursor'
 *      timezone: // value for 'timezone'
 *      dataName: // value for 'dataName'
 *      filter: // value for 'filter'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMonitoringChartDataQuery(baseOptions: Apollo.QueryHookOptions<MonitoringChartDataQuery, MonitoringChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonitoringChartDataQuery, MonitoringChartDataQueryVariables>(MonitoringChartDataDocument, options);
      }
export function useMonitoringChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonitoringChartDataQuery, MonitoringChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonitoringChartDataQuery, MonitoringChartDataQueryVariables>(MonitoringChartDataDocument, options);
        }
export type MonitoringChartDataQueryHookResult = ReturnType<typeof useMonitoringChartDataQuery>;
export type MonitoringChartDataLazyQueryHookResult = ReturnType<typeof useMonitoringChartDataLazyQuery>;
export type MonitoringChartDataQueryResult = Apollo.QueryResult<MonitoringChartDataQuery, MonitoringChartDataQueryVariables>;
export function refetchMonitoringChartDataQuery(variables?: MonitoringChartDataQueryVariables) {
      return { query: MonitoringChartDataDocument, variables: variables }
    }
export const PlanBillingTeamSubscriptionDocument = gql`
    query PlanBillingTeamSubscription($teamId: ID!, $limit: Int, $offset: Int) {
  teamSubscriptionItem(teamId: $teamId) {
    ...PlanBillingTeamSubscription
  }
}
    ${PlanBillingTeamSubscriptionFragmentDoc}`;

/**
 * __usePlanBillingTeamSubscriptionQuery__
 *
 * To run a query within a React component, call `usePlanBillingTeamSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanBillingTeamSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanBillingTeamSubscriptionQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePlanBillingTeamSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<PlanBillingTeamSubscriptionQuery, PlanBillingTeamSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanBillingTeamSubscriptionQuery, PlanBillingTeamSubscriptionQueryVariables>(PlanBillingTeamSubscriptionDocument, options);
      }
export function usePlanBillingTeamSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanBillingTeamSubscriptionQuery, PlanBillingTeamSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanBillingTeamSubscriptionQuery, PlanBillingTeamSubscriptionQueryVariables>(PlanBillingTeamSubscriptionDocument, options);
        }
export type PlanBillingTeamSubscriptionQueryHookResult = ReturnType<typeof usePlanBillingTeamSubscriptionQuery>;
export type PlanBillingTeamSubscriptionLazyQueryHookResult = ReturnType<typeof usePlanBillingTeamSubscriptionLazyQuery>;
export type PlanBillingTeamSubscriptionQueryResult = Apollo.QueryResult<PlanBillingTeamSubscriptionQuery, PlanBillingTeamSubscriptionQueryVariables>;
export function refetchPlanBillingTeamSubscriptionQuery(variables?: PlanBillingTeamSubscriptionQueryVariables) {
      return { query: PlanBillingTeamSubscriptionDocument, variables: variables }
    }
export const PlanBillingBillingDocument = gql`
    query PlanBillingBilling($identityToken: ID!) {
  billing(identityToken: $identityToken) {
    id
    identityToken
    customerUid
    cardName
    cardNumber
    buyerName
    buyerTel
    buyerEmail
  }
}
    `;

/**
 * __usePlanBillingBillingQuery__
 *
 * To run a query within a React component, call `usePlanBillingBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanBillingBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanBillingBillingQuery({
 *   variables: {
 *      identityToken: // value for 'identityToken'
 *   },
 * });
 */
export function usePlanBillingBillingQuery(baseOptions: Apollo.QueryHookOptions<PlanBillingBillingQuery, PlanBillingBillingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanBillingBillingQuery, PlanBillingBillingQueryVariables>(PlanBillingBillingDocument, options);
      }
export function usePlanBillingBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanBillingBillingQuery, PlanBillingBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanBillingBillingQuery, PlanBillingBillingQueryVariables>(PlanBillingBillingDocument, options);
        }
export type PlanBillingBillingQueryHookResult = ReturnType<typeof usePlanBillingBillingQuery>;
export type PlanBillingBillingLazyQueryHookResult = ReturnType<typeof usePlanBillingBillingLazyQuery>;
export type PlanBillingBillingQueryResult = Apollo.QueryResult<PlanBillingBillingQuery, PlanBillingBillingQueryVariables>;
export function refetchPlanBillingBillingQuery(variables?: PlanBillingBillingQueryVariables) {
      return { query: PlanBillingBillingDocument, variables: variables }
    }
export const PlanBillingInvoicesByPageDocument = gql`
    query PlanBillingInvoicesByPage($subscriptionId: ID!, $offset: Int, $limit: Int) {
  invoicesByPage(subscriptionId: $subscriptionId, offset: $offset, limit: $limit) {
    offset
    limit
    total
    hasNext
    hasPrev
    invoiceList {
      ...PlanBillingPayInvoiceInfo
      ... on RefundInvoice {
        id
        refundAt
        paymentName
        paymentStatus
        refundAmount
        cardName
        cardNumber
        receiptUrl
      }
    }
  }
}
    ${PlanBillingPayInvoiceInfoFragmentDoc}`;

/**
 * __usePlanBillingInvoicesByPageQuery__
 *
 * To run a query within a React component, call `usePlanBillingInvoicesByPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanBillingInvoicesByPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanBillingInvoicesByPageQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePlanBillingInvoicesByPageQuery(baseOptions: Apollo.QueryHookOptions<PlanBillingInvoicesByPageQuery, PlanBillingInvoicesByPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanBillingInvoicesByPageQuery, PlanBillingInvoicesByPageQueryVariables>(PlanBillingInvoicesByPageDocument, options);
      }
export function usePlanBillingInvoicesByPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanBillingInvoicesByPageQuery, PlanBillingInvoicesByPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanBillingInvoicesByPageQuery, PlanBillingInvoicesByPageQueryVariables>(PlanBillingInvoicesByPageDocument, options);
        }
export type PlanBillingInvoicesByPageQueryHookResult = ReturnType<typeof usePlanBillingInvoicesByPageQuery>;
export type PlanBillingInvoicesByPageLazyQueryHookResult = ReturnType<typeof usePlanBillingInvoicesByPageLazyQuery>;
export type PlanBillingInvoicesByPageQueryResult = Apollo.QueryResult<PlanBillingInvoicesByPageQuery, PlanBillingInvoicesByPageQueryVariables>;
export function refetchPlanBillingInvoicesByPageQuery(variables?: PlanBillingInvoicesByPageQueryVariables) {
      return { query: PlanBillingInvoicesByPageDocument, variables: variables }
    }
export const PlanBillingLicenseDocument = gql`
    query PlanBillingLicense($teamId: ID!) {
  sports {
    team(id: $teamId) {
      id
      license {
        id
        startAt
        endAt
        licenseName
        teamVolume
        teamIdList
      }
    }
  }
}
    `;

/**
 * __usePlanBillingLicenseQuery__
 *
 * To run a query within a React component, call `usePlanBillingLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanBillingLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanBillingLicenseQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function usePlanBillingLicenseQuery(baseOptions: Apollo.QueryHookOptions<PlanBillingLicenseQuery, PlanBillingLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanBillingLicenseQuery, PlanBillingLicenseQueryVariables>(PlanBillingLicenseDocument, options);
      }
export function usePlanBillingLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanBillingLicenseQuery, PlanBillingLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanBillingLicenseQuery, PlanBillingLicenseQueryVariables>(PlanBillingLicenseDocument, options);
        }
export type PlanBillingLicenseQueryHookResult = ReturnType<typeof usePlanBillingLicenseQuery>;
export type PlanBillingLicenseLazyQueryHookResult = ReturnType<typeof usePlanBillingLicenseLazyQuery>;
export type PlanBillingLicenseQueryResult = Apollo.QueryResult<PlanBillingLicenseQuery, PlanBillingLicenseQueryVariables>;
export function refetchPlanBillingLicenseQuery(variables?: PlanBillingLicenseQueryVariables) {
      return { query: PlanBillingLicenseDocument, variables: variables }
    }
export const PlanPayPricingBillingDocument = gql`
    query PlanPayPricingBilling($identityToken: ID!) {
  billing(identityToken: $identityToken) {
    ...PlanPayPricingBillingItem
  }
}
    ${PlanPayPricingBillingItemFragmentDoc}`;

/**
 * __usePlanPayPricingBillingQuery__
 *
 * To run a query within a React component, call `usePlanPayPricingBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanPayPricingBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanPayPricingBillingQuery({
 *   variables: {
 *      identityToken: // value for 'identityToken'
 *   },
 * });
 */
export function usePlanPayPricingBillingQuery(baseOptions: Apollo.QueryHookOptions<PlanPayPricingBillingQuery, PlanPayPricingBillingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanPayPricingBillingQuery, PlanPayPricingBillingQueryVariables>(PlanPayPricingBillingDocument, options);
      }
export function usePlanPayPricingBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanPayPricingBillingQuery, PlanPayPricingBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanPayPricingBillingQuery, PlanPayPricingBillingQueryVariables>(PlanPayPricingBillingDocument, options);
        }
export type PlanPayPricingBillingQueryHookResult = ReturnType<typeof usePlanPayPricingBillingQuery>;
export type PlanPayPricingBillingLazyQueryHookResult = ReturnType<typeof usePlanPayPricingBillingLazyQuery>;
export type PlanPayPricingBillingQueryResult = Apollo.QueryResult<PlanPayPricingBillingQuery, PlanPayPricingBillingQueryVariables>;
export function refetchPlanPayPricingBillingQuery(variables?: PlanPayPricingBillingQueryVariables) {
      return { query: PlanPayPricingBillingDocument, variables: variables }
    }
export const PlanPayPricingTeamSubscriptionDocument = gql`
    query PlanPayPricingTeamSubscription($teamId: ID!) {
  teamSubscriptionItem(teamId: $teamId) {
    ...PlanPayPricingSubscription
  }
}
    ${PlanPayPricingSubscriptionFragmentDoc}`;

/**
 * __usePlanPayPricingTeamSubscriptionQuery__
 *
 * To run a query within a React component, call `usePlanPayPricingTeamSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanPayPricingTeamSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanPayPricingTeamSubscriptionQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function usePlanPayPricingTeamSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<PlanPayPricingTeamSubscriptionQuery, PlanPayPricingTeamSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanPayPricingTeamSubscriptionQuery, PlanPayPricingTeamSubscriptionQueryVariables>(PlanPayPricingTeamSubscriptionDocument, options);
      }
export function usePlanPayPricingTeamSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanPayPricingTeamSubscriptionQuery, PlanPayPricingTeamSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanPayPricingTeamSubscriptionQuery, PlanPayPricingTeamSubscriptionQueryVariables>(PlanPayPricingTeamSubscriptionDocument, options);
        }
export type PlanPayPricingTeamSubscriptionQueryHookResult = ReturnType<typeof usePlanPayPricingTeamSubscriptionQuery>;
export type PlanPayPricingTeamSubscriptionLazyQueryHookResult = ReturnType<typeof usePlanPayPricingTeamSubscriptionLazyQuery>;
export type PlanPayPricingTeamSubscriptionQueryResult = Apollo.QueryResult<PlanPayPricingTeamSubscriptionQuery, PlanPayPricingTeamSubscriptionQueryVariables>;
export function refetchPlanPayPricingTeamSubscriptionQuery(variables?: PlanPayPricingTeamSubscriptionQueryVariables) {
      return { query: PlanPayPricingTeamSubscriptionDocument, variables: variables }
    }
export const PlanPayPricingTeamCouponsDocument = gql`
    query PlanPayPricingTeamCoupons($teamId: ID!) {
  couponListByTeam(teamId: $teamId) {
    content {
      couponId
      name
    }
  }
}
    `;

/**
 * __usePlanPayPricingTeamCouponsQuery__
 *
 * To run a query within a React component, call `usePlanPayPricingTeamCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanPayPricingTeamCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanPayPricingTeamCouponsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function usePlanPayPricingTeamCouponsQuery(baseOptions: Apollo.QueryHookOptions<PlanPayPricingTeamCouponsQuery, PlanPayPricingTeamCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanPayPricingTeamCouponsQuery, PlanPayPricingTeamCouponsQueryVariables>(PlanPayPricingTeamCouponsDocument, options);
      }
export function usePlanPayPricingTeamCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanPayPricingTeamCouponsQuery, PlanPayPricingTeamCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanPayPricingTeamCouponsQuery, PlanPayPricingTeamCouponsQueryVariables>(PlanPayPricingTeamCouponsDocument, options);
        }
export type PlanPayPricingTeamCouponsQueryHookResult = ReturnType<typeof usePlanPayPricingTeamCouponsQuery>;
export type PlanPayPricingTeamCouponsLazyQueryHookResult = ReturnType<typeof usePlanPayPricingTeamCouponsLazyQuery>;
export type PlanPayPricingTeamCouponsQueryResult = Apollo.QueryResult<PlanPayPricingTeamCouponsQuery, PlanPayPricingTeamCouponsQueryVariables>;
export function refetchPlanPayPricingTeamCouponsQuery(variables?: PlanPayPricingTeamCouponsQueryVariables) {
      return { query: PlanPayPricingTeamCouponsDocument, variables: variables }
    }
export const PlanPayPricingAppliedPriceDocument = gql`
    query PlanPayPricingAppliedPrice($input: GetCouponAppliedPriceInput!) {
  couponAppliedPrice(input: $input) {
    deductedPrice
    discountedPrice
    originalPrice
    discountInfo {
      type
      value
    }
  }
}
    `;

/**
 * __usePlanPayPricingAppliedPriceQuery__
 *
 * To run a query within a React component, call `usePlanPayPricingAppliedPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanPayPricingAppliedPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanPayPricingAppliedPriceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlanPayPricingAppliedPriceQuery(baseOptions: Apollo.QueryHookOptions<PlanPayPricingAppliedPriceQuery, PlanPayPricingAppliedPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanPayPricingAppliedPriceQuery, PlanPayPricingAppliedPriceQueryVariables>(PlanPayPricingAppliedPriceDocument, options);
      }
export function usePlanPayPricingAppliedPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanPayPricingAppliedPriceQuery, PlanPayPricingAppliedPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanPayPricingAppliedPriceQuery, PlanPayPricingAppliedPriceQueryVariables>(PlanPayPricingAppliedPriceDocument, options);
        }
export type PlanPayPricingAppliedPriceQueryHookResult = ReturnType<typeof usePlanPayPricingAppliedPriceQuery>;
export type PlanPayPricingAppliedPriceLazyQueryHookResult = ReturnType<typeof usePlanPayPricingAppliedPriceLazyQuery>;
export type PlanPayPricingAppliedPriceQueryResult = Apollo.QueryResult<PlanPayPricingAppliedPriceQuery, PlanPayPricingAppliedPriceQueryVariables>;
export function refetchPlanPayPricingAppliedPriceQuery(variables?: PlanPayPricingAppliedPriceQueryVariables) {
      return { query: PlanPayPricingAppliedPriceDocument, variables: variables }
    }
export const HighestBenefitCouponByTeamDocument = gql`
    query HighestBenefitCouponByTeam($originalPrice: Float!, $teamId: ID!) {
  highestBenefitCouponByTeam(originalPrice: $originalPrice, teamId: $teamId) {
    couponId
    endAt
    name
    remainDays
    startAt
  }
}
    `;

/**
 * __useHighestBenefitCouponByTeamQuery__
 *
 * To run a query within a React component, call `useHighestBenefitCouponByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighestBenefitCouponByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighestBenefitCouponByTeamQuery({
 *   variables: {
 *      originalPrice: // value for 'originalPrice'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useHighestBenefitCouponByTeamQuery(baseOptions: Apollo.QueryHookOptions<HighestBenefitCouponByTeamQuery, HighestBenefitCouponByTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HighestBenefitCouponByTeamQuery, HighestBenefitCouponByTeamQueryVariables>(HighestBenefitCouponByTeamDocument, options);
      }
export function useHighestBenefitCouponByTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HighestBenefitCouponByTeamQuery, HighestBenefitCouponByTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HighestBenefitCouponByTeamQuery, HighestBenefitCouponByTeamQueryVariables>(HighestBenefitCouponByTeamDocument, options);
        }
export type HighestBenefitCouponByTeamQueryHookResult = ReturnType<typeof useHighestBenefitCouponByTeamQuery>;
export type HighestBenefitCouponByTeamLazyQueryHookResult = ReturnType<typeof useHighestBenefitCouponByTeamLazyQuery>;
export type HighestBenefitCouponByTeamQueryResult = Apollo.QueryResult<HighestBenefitCouponByTeamQuery, HighestBenefitCouponByTeamQueryVariables>;
export function refetchHighestBenefitCouponByTeamQuery(variables?: HighestBenefitCouponByTeamQueryVariables) {
      return { query: HighestBenefitCouponByTeamDocument, variables: variables }
    }
export const PlayersUserMapDocument = gql`
    query PlayersUserMap($teamId: ID!, $limit: Int!, $filter: TeamUserMapFilterInput!, $timezone: String!) {
  sports {
    team(id: $teamId) {
      id
      userMapsByPage(
        limit: $limit
        filter: $filter
        order: {field: name, sort: ASC, timezone: $timezone, stride: DAY}
      ) {
        entries {
          id
          user {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePlayersUserMapQuery__
 *
 * To run a query within a React component, call `usePlayersUserMapQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayersUserMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayersUserMapQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function usePlayersUserMapQuery(baseOptions: Apollo.QueryHookOptions<PlayersUserMapQuery, PlayersUserMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayersUserMapQuery, PlayersUserMapQueryVariables>(PlayersUserMapDocument, options);
      }
export function usePlayersUserMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayersUserMapQuery, PlayersUserMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayersUserMapQuery, PlayersUserMapQueryVariables>(PlayersUserMapDocument, options);
        }
export type PlayersUserMapQueryHookResult = ReturnType<typeof usePlayersUserMapQuery>;
export type PlayersUserMapLazyQueryHookResult = ReturnType<typeof usePlayersUserMapLazyQuery>;
export type PlayersUserMapQueryResult = Apollo.QueryResult<PlayersUserMapQuery, PlayersUserMapQueryVariables>;
export function refetchPlayersUserMapQuery(variables?: PlayersUserMapQueryVariables) {
      return { query: PlayersUserMapDocument, variables: variables }
    }
export const RegisterPostionPositionsDocument = gql`
    query RegisterPostionPositions($categoryId: String!) {
  sports {
    category(id: $categoryId) {
      id
      coachPositions
    }
  }
}
    `;

/**
 * __useRegisterPostionPositionsQuery__
 *
 * To run a query within a React component, call `useRegisterPostionPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterPostionPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterPostionPositionsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useRegisterPostionPositionsQuery(baseOptions: Apollo.QueryHookOptions<RegisterPostionPositionsQuery, RegisterPostionPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisterPostionPositionsQuery, RegisterPostionPositionsQueryVariables>(RegisterPostionPositionsDocument, options);
      }
export function useRegisterPostionPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisterPostionPositionsQuery, RegisterPostionPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisterPostionPositionsQuery, RegisterPostionPositionsQueryVariables>(RegisterPostionPositionsDocument, options);
        }
export type RegisterPostionPositionsQueryHookResult = ReturnType<typeof useRegisterPostionPositionsQuery>;
export type RegisterPostionPositionsLazyQueryHookResult = ReturnType<typeof useRegisterPostionPositionsLazyQuery>;
export type RegisterPostionPositionsQueryResult = Apollo.QueryResult<RegisterPostionPositionsQuery, RegisterPostionPositionsQueryVariables>;
export function refetchRegisterPostionPositionsQuery(variables?: RegisterPostionPositionsQueryVariables) {
      return { query: RegisterPostionPositionsDocument, variables: variables }
    }
export const UploadUserProfileImageDocument = gql`
    mutation UploadUserProfileImage($file: Upload!) {
  uploadProfile(file: $file) {
    user {
      id
      pictureFileId
    }
  }
}
    `;
export type UploadUserProfileImageMutationFn = Apollo.MutationFunction<UploadUserProfileImageMutation, UploadUserProfileImageMutationVariables>;

/**
 * __useUploadUserProfileImageMutation__
 *
 * To run a mutation, you first call `useUploadUserProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserProfileImageMutation, { data, loading, error }] = useUploadUserProfileImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadUserProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadUserProfileImageMutation, UploadUserProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadUserProfileImageMutation, UploadUserProfileImageMutationVariables>(UploadUserProfileImageDocument, options);
      }
export type UploadUserProfileImageMutationHookResult = ReturnType<typeof useUploadUserProfileImageMutation>;
export type UploadUserProfileImageMutationResult = Apollo.MutationResult<UploadUserProfileImageMutation>;
export type UploadUserProfileImageMutationOptions = Apollo.BaseMutationOptions<UploadUserProfileImageMutation, UploadUserProfileImageMutationVariables>;
export const NavigationViewerDocument = gql`
    query NavigationViewer {
  sports {
    viewer {
      id
      ...FSportsUser
      teamMaps {
        ...FSportsTeamUserMap
      }
      guideReopenStatus {
        alarm {
          web
        }
      }
    }
  }
}
    ${FSportsUserFragmentDoc}
${FSportsTeamUserMapFragmentDoc}`;

/**
 * __useNavigationViewerQuery__
 *
 * To run a query within a React component, call `useNavigationViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationViewerQuery(baseOptions?: Apollo.QueryHookOptions<NavigationViewerQuery, NavigationViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationViewerQuery, NavigationViewerQueryVariables>(NavigationViewerDocument, options);
      }
export function useNavigationViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationViewerQuery, NavigationViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationViewerQuery, NavigationViewerQueryVariables>(NavigationViewerDocument, options);
        }
export type NavigationViewerQueryHookResult = ReturnType<typeof useNavigationViewerQuery>;
export type NavigationViewerLazyQueryHookResult = ReturnType<typeof useNavigationViewerLazyQuery>;
export type NavigationViewerQueryResult = Apollo.QueryResult<NavigationViewerQuery, NavigationViewerQueryVariables>;
export function refetchNavigationViewerQuery(variables?: NavigationViewerQueryVariables) {
      return { query: NavigationViewerDocument, variables: variables }
    }
export const NavigationByPlanDocument = gql`
    query NavigationByPlan($teamId: ID!, $includeSubscription: Boolean = false, $includeLicense: Boolean = false) {
  teamSubscriptionItem(teamId: $teamId) @include(if: $includeSubscription) {
    ...NavigationTeamSubscription
  }
  licenseByTeam(teamId: $teamId) @include(if: $includeLicense) {
    ...NavigationTeamLicense
  }
}
    ${NavigationTeamSubscriptionFragmentDoc}
${NavigationTeamLicenseFragmentDoc}`;

/**
 * __useNavigationByPlanQuery__
 *
 * To run a query within a React component, call `useNavigationByPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationByPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationByPlanQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      includeSubscription: // value for 'includeSubscription'
 *      includeLicense: // value for 'includeLicense'
 *   },
 * });
 */
export function useNavigationByPlanQuery(baseOptions: Apollo.QueryHookOptions<NavigationByPlanQuery, NavigationByPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationByPlanQuery, NavigationByPlanQueryVariables>(NavigationByPlanDocument, options);
      }
export function useNavigationByPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationByPlanQuery, NavigationByPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationByPlanQuery, NavigationByPlanQueryVariables>(NavigationByPlanDocument, options);
        }
export type NavigationByPlanQueryHookResult = ReturnType<typeof useNavigationByPlanQuery>;
export type NavigationByPlanLazyQueryHookResult = ReturnType<typeof useNavigationByPlanLazyQuery>;
export type NavigationByPlanQueryResult = Apollo.QueryResult<NavigationByPlanQuery, NavigationByPlanQueryVariables>;
export function refetchNavigationByPlanQuery(variables?: NavigationByPlanQueryVariables) {
      return { query: NavigationByPlanDocument, variables: variables }
    }
export const NavigationQualificationDocument = gql`
    query NavigationQualification($teamId: ID!) {
  qualification(teamId: $teamId) {
    id
    qualificationType
    isExpired
  }
}
    `;

/**
 * __useNavigationQualificationQuery__
 *
 * To run a query within a React component, call `useNavigationQualificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQualificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQualificationQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useNavigationQualificationQuery(baseOptions: Apollo.QueryHookOptions<NavigationQualificationQuery, NavigationQualificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationQualificationQuery, NavigationQualificationQueryVariables>(NavigationQualificationDocument, options);
      }
export function useNavigationQualificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationQualificationQuery, NavigationQualificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationQualificationQuery, NavigationQualificationQueryVariables>(NavigationQualificationDocument, options);
        }
export type NavigationQualificationQueryHookResult = ReturnType<typeof useNavigationQualificationQuery>;
export type NavigationQualificationLazyQueryHookResult = ReturnType<typeof useNavigationQualificationLazyQuery>;
export type NavigationQualificationQueryResult = Apollo.QueryResult<NavigationQualificationQuery, NavigationQualificationQueryVariables>;
export function refetchNavigationQualificationQuery(variables?: NavigationQualificationQueryVariables) {
      return { query: NavigationQualificationDocument, variables: variables }
    }
export type CardDiscountDetailsCouponContentsQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type CardDiscountDetailsCouponContentsQuery = (
  { __typename: 'Query' }
  & { couponListByTeam: (
    { __typename: 'CouponListByTeam' }
    & { content?: Maybe<Array<(
      { __typename: 'CouponSimple' }
      & Pick<CouponSimple, 'couponId' | 'name'>
    )>> }
  ) }
);

export type InvitationRequestBadgeRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type InvitationRequestBadgeRequestsQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { viewer?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { teamMaps: Array<(
        { __typename: 'SportsTeamUserMap' }
        & Pick<SportsTeamUserMap, 'id'>
        & { team: (
          { __typename: 'SportsTeam' }
          & Pick<SportsTeam, 'id'>
          & { pendingInvitationRequestList: Array<(
            { __typename: 'TeamInvitationRequest' }
            & Pick<TeamInvitationRequest, 'id' | 'status'>
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type CardGroupSummaryProgressBarDataQueryVariables = Exact<{
  teamId: Scalars['ID'];
  cursor: Scalars['DateTime'];
  timezone: Scalars['String'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
}>;


export type CardGroupSummaryProgressBarDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { dailyData: (
        { __typename: 'DailyTeamData' }
        & { readiness: (
          { __typename: 'DailyReadinessData' }
          & Pick<DailyReadinessData, 'value' | 'varianceFromYesterday'>
        ) }
      ) }
    )> }
  ) }
);

export type FeedbackListQueryVariables = Exact<{
  userId: Scalars['ID'];
  teamId: Scalars['ID'];
  multilingualTextInput: MultilingualTextInput;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Float']>;
}>;


export type FeedbackListQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { feedbackConnection: (
        { __typename: 'FeedbackConnection' }
        & { pageInfo: (
          { __typename: 'PageInfo' }
          & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
        ), edges: Array<(
          { __typename: 'FeedbackEdge' }
          & Pick<FeedbackEdge, 'cursor'>
          & { node: (
            { __typename: 'Feedback' }
            & FeedbackDataFragment
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type UserCurrentInjuryListQueryVariables = Exact<{
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  input: MultilingualTextInput;
}>;


export type UserCurrentInjuryListQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { currentInjuryList: Array<(
        { __typename: 'Injury' }
        & UserInjuryDataFragment
      )> }
    )> }
  ) }
);

export type UserPastInjuryListQueryVariables = Exact<{
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  input: MultilingualTextInput;
}>;


export type UserPastInjuryListQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { pastInjuryList: Array<(
        { __typename: 'Injury' }
        & UserInjuryDataFragment
      )> }
    )> }
  ) }
);

export type UserInjuryDataFragment = (
  { __typename: 'Injury' }
  & Pick<Injury, 'id' | 'occurDate' | 'historyStatus' | 'reason' | 'diagnosis' | 'medicalRecord' | 'status'>
  & { bodyPart?: Maybe<(
    { __typename: 'SportsBodyPart' }
    & Pick<SportsBodyPart, 'id' | 'value'>
  )>, return: (
    { __typename: 'Return' }
    & Pick<Return, 'date' | 'info' | 'status'>
  ), fileList: Array<(
    { __typename: 'InjuryFile' }
    & Pick<InjuryFile, 'id' | 'name' | 'url'>
  )> }
);

export type FeedbackDetailQueryVariables = Exact<{
  id: Scalars['ID'];
  multilingualTextInput: MultilingualTextInput;
}>;


export type FeedbackDetailQuery = (
  { __typename: 'Query' }
  & { feedback: (
    { __typename: 'Feedback' }
    & FeedbackDataFragment
  ) }
);

export type FeedbackDataFragment = (
  { __typename: 'Feedback' }
  & Pick<Feedback, 'id' | 'date' | 'type' | 'message' | 'isUpdated' | 'createdAt' | 'teamId'>
  & { contentsData?: Maybe<(
    { __typename: 'FeedbackConditionNotGoodData' }
    & Pick<FeedbackConditionNotGoodData, 'dataList' | 'mood' | 'fatigue' | 'stress' | 'muscle' | 'sleepDuration' | 'sleepDurationScore' | 'sleepQuality'>
  ) | (
    { __typename: 'FeedbackConditionPlummetData' }
    & Pick<FeedbackConditionPlummetData, 'dataList' | 'mood28dayList' | 'fatigue28dayList' | 'stress28dayList' | 'muscle28dayList' | 'sleepDuration28dayList' | 'sleepQuality28dayList'>
  ) | (
    { __typename: 'FeedbackSorenessSevereData' }
    & Pick<FeedbackSorenessSevereData, 'level' | 'memo' | 'isContact'>
    & { bodyPart: (
      { __typename: 'SportsBodyPart' }
      & Pick<SportsBodyPart, 'id' | 'value' | 'front' | 'back' | 'left' | 'right'>
    ) }
  ) | (
    { __typename: 'FeedbackWorkloadMemoData' }
    & Pick<FeedbackWorkloadMemoData, 'duration' | 'time' | 'timezone' | 'memo'>
    & { scheduleData?: Maybe<(
      { __typename: 'FeedbackWorkloadScheduleData' }
      & Pick<FeedbackWorkloadScheduleData, 'type' | 'detailType' | 'title' | 'location' | 'scheduleDate' | 'startTime' | 'endTime'>
    )> }
  ) | (
    { __typename: 'FeedbackWorkloadRiskAtInjuryData' }
    & Pick<FeedbackWorkloadRiskAtInjuryData, 'label' | 'analysisType' | 'reasonList'>
  )>, commentList: Array<(
    { __typename: 'FeedbackComment' }
    & Pick<FeedbackComment, 'id' | 'comment' | 'isUpdated' | 'createdAt'>
    & { user: (
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'pictureUrl' | 'name'>
    ) }
  )>, createUser: (
    { __typename: 'SportsUser' }
    & Pick<SportsUser, 'id' | 'name' | 'pictureUrl' | 'gender'>
  ), player: (
    { __typename: 'SportsUser' }
    & Pick<SportsUser, 'id' | 'name' | 'pictureUrl'>
  ), team?: Maybe<(
    { __typename: 'SportsTeam' }
    & Pick<SportsTeam, 'id'>
  )> }
);

export type FeedbackSuggestionListQueryVariables = Exact<{
  userId: Scalars['ID'];
  teamId: Scalars['ID'];
  timezone: Scalars['String'];
  input: MultilingualTextInput;
}>;


export type FeedbackSuggestionListQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'name' | 'pictureUrl'>
      & { feedbackSuggestionList: Array<(
        { __typename: 'FeedbackSuggestion' }
        & Pick<FeedbackSuggestion, 'date' | 'type'>
        & { contentsData?: Maybe<(
          { __typename: 'FeedbackConditionNotGoodData' }
          & Pick<FeedbackConditionNotGoodData, 'dataList' | 'mood' | 'fatigue' | 'stress' | 'muscle' | 'sleepDuration' | 'sleepDurationScore' | 'sleepQuality'>
        ) | (
          { __typename: 'FeedbackConditionPlummetData' }
          & Pick<FeedbackConditionPlummetData, 'dataList' | 'mood28dayList' | 'fatigue28dayList' | 'stress28dayList' | 'muscle28dayList' | 'sleepDuration28dayList' | 'sleepQuality28dayList'>
        ) | (
          { __typename: 'FeedbackSorenessSevereData' }
          & Pick<FeedbackSorenessSevereData, 'level' | 'memo' | 'isContact'>
          & { bodyPart: (
            { __typename: 'SportsBodyPart' }
            & Pick<SportsBodyPart, 'id' | 'value' | 'front' | 'back' | 'left' | 'right'>
            & { group: (
              { __typename: 'SportsBodyPartGroup' }
              & Pick<SportsBodyPartGroup, 'id'>
            ) }
          ) }
        ) | (
          { __typename: 'FeedbackWorkloadMemoData' }
          & Pick<FeedbackWorkloadMemoData, 'memo' | 'duration' | 'time' | 'timezone'>
          & { scheduleData?: Maybe<(
            { __typename: 'FeedbackWorkloadScheduleData' }
            & Pick<FeedbackWorkloadScheduleData, 'type' | 'detailType' | 'title' | 'location' | 'scheduleDate' | 'startTime' | 'endTime'>
          )> }
        ) | (
          { __typename: 'FeedbackWorkloadRiskAtInjuryData' }
          & Pick<FeedbackWorkloadRiskAtInjuryData, 'label' | 'analysisType' | 'reasonList'>
        )> }
      )> }
    )> }
  ) }
);

export type DialogTeamPlayerListQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type DialogTeamPlayerListQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { userMapsByPage: (
        { __typename: 'SportsTeamUserMapByPage' }
        & { entries: Array<(
          { __typename: 'SportsTeamUserMap' }
          & { user: (
            { __typename: 'SportsUser' }
            & Pick<SportsUser, 'id' | 'name' | 'pictureUrl' | 'gender'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type DrawerPlayersUserMapsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  timezone: Scalars['String'];
  filter?: Maybe<TeamUserMapFilterInput>;
}>;


export type DrawerPlayersUserMapsQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { userMapsByPage: (
        { __typename: 'SportsTeamUserMapByPage' }
        & Pick<SportsTeamUserMapByPage, 'offset' | 'limit' | 'total' | 'hasNext' | 'hasPrev'>
        & { entries: Array<(
          { __typename: 'SportsTeamUserMap' }
          & DrawerPlayersUserMapFragment
        )> }
      ) }
    )> }
  ) }
);

export type DrawerPlayersUserMapFragment = (
  { __typename: 'SportsTeamUserMap' }
  & Pick<SportsTeamUserMap, 'id' | 'isStarredByViewer'>
  & { user: (
    { __typename: 'SportsUser' }
    & Pick<SportsUser, 'id' | 'name' | 'pictureUrl' | 'priorSportsPosition' | 'priorSportsRole'>
    & { priorSportsCategory?: Maybe<(
      { __typename: 'SportsCategoryCollection' }
      & Pick<SportsCategoryCollection, 'id' | 'group'>
    )> }
  ) }
);

export type BodyPartsQueryVariables = Exact<{
  input: MultilingualTextInput;
}>;


export type BodyPartsQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { bodyParts: Array<(
      { __typename: 'SportsBodyPart' }
      & Pick<SportsBodyPart, 'back' | 'front' | 'id' | 'left' | 'right' | 'value'>
      & { group: (
        { __typename: 'SportsBodyPartGroup' }
        & Pick<SportsBodyPartGroup, 'id'>
      ) }
    )> }
  ) }
);

export type BodyPartMultilingualTextQueryVariables = Exact<{
  input: MultilingualTextInput;
  key?: Maybe<Scalars['String']>;
}>;


export type BodyPartMultilingualTextQuery = (
  { __typename: 'Query' }
  & { multilingualText: (
    { __typename: 'MultilingualTextQuery' }
    & { bodyPartList: Array<(
      { __typename: 'MultilingualBodyPart' }
      & Pick<MultilingualBodyPart, 'id' | 'value'>
    )> }
  ) }
);

export type ModalGroupGroupsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ModalGroupGroupsQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { groups: Array<(
        { __typename: 'TeamGroup' }
        & Pick<TeamGroup, 'id' | 'name' | 'orderRanking'>
        & { groupMembers: Array<(
          { __typename: 'TeamGroupUser' }
          & Pick<TeamGroupUser, 'id'>
          & { user: (
            { __typename: 'SportsUser' }
            & Pick<SportsUser, 'id'>
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type ModalTeamCreateViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ModalTeamCreateViewerQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { viewer?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'name' | 'nationality'>
      & { priorSportsCategory?: Maybe<(
        { __typename: 'SportsCategoryCollection' }
        & Pick<SportsCategoryCollection, 'id'>
      )> }
    )> }
  ) }
);

export type ModalTeamCreateTeamDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ModalTeamCreateTeamDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & { userMapsByPage: (
        { __typename: 'SportsTeamUserMapByPage' }
        & { entries: Array<(
          { __typename: 'SportsTeamUserMap' }
          & Pick<SportsTeamUserMap, 'teamAuthority'>
          & { user: (
            { __typename: 'SportsUser' }
            & Pick<SportsUser, 'id'>
          ) }
        )> }
      ) }
      & ModalTeamCreateTeamFragment
    )>, viewer?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { teamMapsByPage: (
        { __typename: 'SportsTeamUserMapByPage' }
        & Pick<SportsTeamUserMapByPage, 'total'>
        & { entries: Array<(
          { __typename: 'SportsTeamUserMap' }
          & Pick<SportsTeamUserMap, 'teamAuthority'>
          & { team: (
            { __typename: 'SportsTeam' }
            & ModalTeamCreateTeamFragment
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type ModalTeamCreateTeamFragment = (
  { __typename: 'SportsTeam' }
  & Pick<SportsTeam, 'id' | 'name' | 'pictureUrl' | 'createdAt'>
  & { sportsCategory: (
    { __typename: 'SportsCategoryCollection' }
    & Pick<SportsCategoryCollection, 'id' | 'group'>
  ), region: (
    { __typename: 'Region' }
    & Pick<Region, 'id'>
  ), allUsers: (
    { __typename: 'SportsTeamUserMapByPage' }
    & Pick<SportsTeamUserMapByPage, 'total'>
  ), players: (
    { __typename: 'SportsTeamUserMapByPage' }
    & Pick<SportsTeamUserMapByPage, 'total'>
  ) }
);

export type ModalTeamJoinViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ModalTeamJoinViewerQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { viewer?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'name' | 'nationality'>
      & { priorSportsCategory?: Maybe<(
        { __typename: 'SportsCategoryCollection' }
        & Pick<SportsCategoryCollection, 'id'>
      )> }
    )> }
  ) }
);

export type NavbarTeamsTeamDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NavbarTeamsTeamDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & { userMapsByPage: (
        { __typename: 'SportsTeamUserMapByPage' }
        & Pick<SportsTeamUserMapByPage, 'total'>
        & { entries: Array<(
          { __typename: 'SportsTeamUserMap' }
          & Pick<SportsTeamUserMap, 'teamAuthority'>
          & { user: (
            { __typename: 'SportsUser' }
            & Pick<SportsUser, 'id'>
          ) }
        )> }
      ) }
      & NavbarTeamsTeamFragment
    )>, viewer?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { teamMapsByPage: (
        { __typename: 'SportsTeamUserMapByPage' }
        & Pick<SportsTeamUserMapByPage, 'total'>
        & { entries: Array<(
          { __typename: 'SportsTeamUserMap' }
          & Pick<SportsTeamUserMap, 'teamAuthority'>
          & { team: (
            { __typename: 'SportsTeam' }
            & NavbarTeamsTeamFragment
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type NavbarTeamsTeamFragment = (
  { __typename: 'SportsTeam' }
  & Pick<SportsTeam, 'id' | 'name' | 'pictureUrl' | 'createdAt'>
  & { sportsCategory: (
    { __typename: 'SportsCategoryCollection' }
    & Pick<SportsCategoryCollection, 'id' | 'group'>
  ), region: (
    { __typename: 'Region' }
    & Pick<Region, 'id'>
  ), allUsers: (
    { __typename: 'SportsTeamUserMapByPage' }
    & Pick<SportsTeamUserMapByPage, 'total'>
  ), players: (
    { __typename: 'SportsTeamUserMapByPage' }
    & Pick<SportsTeamUserMapByPage, 'total'>
  ) }
);

export type SectionPaymentPlanOptionsTeamSubscriptionQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type SectionPaymentPlanOptionsTeamSubscriptionQuery = (
  { __typename: 'Query' }
  & { teamSubscriptionItem?: Maybe<(
    { __typename: 'SubscriptionItem' }
    & SectionPaymentPlanOptionsSubscriptionFragment
  )> }
);

export type SectionPaymentPlanOptionsSubscriptionFragment = (
  { __typename: 'SubscriptionItem' }
  & Pick<SubscriptionItem, 'id'>
  & { plan: (
    { __typename: 'PlanWithOption' }
    & Pick<PlanWithOption, 'id'>
    & { planOptionInfo: (
      { __typename: 'PlanOption' }
      & Pick<PlanOption, 'id'>
    ) }
  ) }
);

export type TeamChartSorenessLevel5userListQueryVariables = Exact<{
  teamId: Scalars['ID'];
  date: Scalars['DateTime'];
  input: MultilingualTextInput;
}>;


export type TeamChartSorenessLevel5userListQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { sportsData: (
        { __typename: 'TeamSportsData' }
        & { sorenessLevel5UserList: Array<(
          { __typename: 'SorenessUser' }
          & WidgetSorenessUserListFragment
        )> }
      ) }
    )> }
  ) }
);

export type WidgetSorenessUserListFragment = (
  { __typename: 'SorenessUser' }
  & { user: (
    { __typename: 'SportsUser' }
    & Pick<SportsUser, 'id' | 'name' | 'pictureUrl'>
  ), sorenessList: Array<(
    { __typename: 'Soreness' }
    & { bodyPart: (
      { __typename: 'SportsBodyPart' }
      & Pick<SportsBodyPart, 'value'>
    ) }
  )> }
);

export type PlayerModalUserQueryVariables = Exact<{
  playerId: Scalars['ID'];
}>;


export type PlayerModalUserQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'name' | 'pictureUrl' | 'priorSportsRole' | 'priorSportsPosition' | 'birthdate'>
      & { priorSportsCategory?: Maybe<(
        { __typename: 'SportsCategoryCollection' }
        & Pick<SportsCategoryCollection, 'group' | 'id'>
      )> }
    )> }
  ) }
);

export type PlayerModalLatestRawBodyQueryVariables = Exact<{
  playerId: Scalars['ID'];
  timezone: Scalars['String'];
}>;


export type PlayerModalLatestRawBodyQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { latestRawBody?: Maybe<(
        { __typename: 'SportsRawBody' }
        & Pick<SportsRawBody, 'id' | 'weight' | 'weightUnit' | 'height' | 'heightUnit'>
      )> }
    )> }
  ) }
);

export type SportsUserInjuryHistoryTableQueryVariables = Exact<{
  id: Scalars['ID'];
  bodyPart: SportsBodyPartName;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  input: MultilingualTextInput;
}>;


export type SportsUserInjuryHistoryTableQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & { sorenessHistory: (
        { __typename: 'SportsUserSorenessHistory' }
        & { recentData: (
          { __typename: 'SportsUserSorenessRecentData' }
          & Pick<SportsUserSorenessRecentData, 'total' | 'hasNext'>
          & { bodyPart: (
            { __typename: 'SportsBodyPart' }
            & Pick<SportsBodyPart, 'id' | 'value'>
          ), values: Array<(
            { __typename: 'SportsUserSorenessRecentDataValue' }
            & Pick<SportsUserSorenessRecentDataValue, 'time' | 'level' | 'memo' | 'isContact'>
          )> }
        ) }
      ) }
    )> }
  ) }
);

export type WeeklyInjuryHistoryQueryVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  bodyPart: SportsBodyPartName;
  input: MultilingualTextInput;
}>;


export type WeeklyInjuryHistoryQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { sorenessHistory: (
        { __typename: 'SportsUserSorenessHistory' }
        & { weeklyData: (
          { __typename: 'SportsUserSorenessHistoryWeekly' }
          & Pick<SportsUserSorenessHistoryWeekly, 'from' | 'to'>
          & { bodyPart: (
            { __typename: 'SportsBodyPart' }
            & Pick<SportsBodyPart, 'id' | 'value'>
          ), prev: (
            { __typename: 'SportsUserSorenessDataValue' }
            & Pick<SportsUserSorenessDataValue, 'time' | 'level'>
          ), curr: Array<(
            { __typename: 'SportsUserSorenessDataValue' }
            & Pick<SportsUserSorenessDataValue, 'time' | 'level'>
          )>, next: (
            { __typename: 'SportsUserSorenessDataValue' }
            & Pick<SportsUserSorenessDataValue, 'time' | 'level'>
          ) }
        ) }
      ) }
    )> }
  ) }
);

export type MonthlyInjuryHistoryQueryVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  bodyPart: SportsBodyPartName;
  input: MultilingualTextInput;
}>;


export type MonthlyInjuryHistoryQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { sorenessHistory: (
        { __typename: 'SportsUserSorenessHistory' }
        & { monthlyData: (
          { __typename: 'SportsUserSorenessHistoryMonthly' }
          & Pick<SportsUserSorenessHistoryMonthly, 'from' | 'to'>
          & { bodyPart: (
            { __typename: 'SportsBodyPart' }
            & Pick<SportsBodyPart, 'id' | 'value'>
          ), prev: (
            { __typename: 'SportsUserSorenessDataValue' }
            & Pick<SportsUserSorenessDataValue, 'time' | 'level'>
          ), curr: Array<(
            { __typename: 'SportsUserSorenessDataValue' }
            & Pick<SportsUserSorenessDataValue, 'time' | 'level'>
          )>, next: (
            { __typename: 'SportsUserSorenessDataValue' }
            & Pick<SportsUserSorenessDataValue, 'time' | 'level'>
          ) }
        ) }
      ) }
    )> }
  ) }
);

export type PlayerSummaryQueryVariables = Exact<{
  playerId: Scalars['ID'];
  starerId: Scalars['ID'];
  teamId: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  timezone: Scalars['String'];
  hasPermissionViewStarredPlayers: Scalars['Boolean'];
  input: MultilingualTextInput;
}>;


export type PlayerSummaryQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & MakeOptional<Pick<SportsUser, 'id' | 'isStarredBy'>, 'isStarredBy'>
      & { data: Array<(
        { __typename: 'SportsTimeSeriesData' }
        & Pick<SportsTimeSeriesData, 'name'>
        & { ranges: Array<(
          { __typename: 'SportsDataRange' }
          & { entries: Array<(
            { __typename: 'SportsDataEntry' }
            & Pick<SportsDataEntry, 'time' | 'value'>
          )> }
        )> }
      )>, injury?: Maybe<(
        { __typename: 'Injury' }
        & Pick<Injury, 'id' | 'occurDate' | 'historyStatus'>
      )> }
      & PlayerSummaryIdentityFragment
    )> }
  ) }
);

export type PlayerSummaryIdentityFragment = (
  { __typename: 'SportsUser' }
  & Pick<SportsUser, 'pictureUrl' | 'name' | 'gender' | 'birthdate' | 'priorSportsPosition' | 'priorSportsRole'>
  & { priorSportsCategory?: Maybe<(
    { __typename: 'SportsCategoryCollection' }
    & Pick<SportsCategoryCollection, 'id' | 'group'>
  )> }
);

export type FSportsTeamFragment = (
  { __typename: 'SportsTeam' }
  & Pick<SportsTeam, 'id' | 'type' | 'slug' | 'name' | 'code' | 'viewerAuthority' | 'pictureUrl'>
  & { region: (
    { __typename: 'Region' }
    & Pick<Region, 'id'>
  ), sportsCategory: (
    { __typename: 'SportsCategoryCollection' }
    & Pick<SportsCategoryCollection, 'id'>
  ), allUsers: (
    { __typename: 'SportsTeamUserMapByPage' }
    & Pick<SportsTeamUserMapByPage, 'total'>
  ), players: (
    { __typename: 'SportsTeamUserMapByPage' }
    & Pick<SportsTeamUserMapByPage, 'total'>
  ) }
);

export type FSportsTeamInvitationRequestFragment = (
  { __typename: 'SportsTeamInvitationRequest' }
  & Pick<SportsTeamInvitationRequest, 'id' | 'status' | 'teamAuthority'>
  & { user: (
    { __typename: 'SportsUser' }
    & Pick<SportsUser, 'id'>
  ), team: (
    { __typename: 'SportsTeam' }
    & FSportsTeamFragment
  ) }
);

export type FSportsTeamUserMapFragment = (
  { __typename: 'SportsTeamUserMap' }
  & Pick<SportsTeamUserMap, 'id' | 'teamAuthority'>
  & { team: (
    { __typename: 'SportsTeam' }
    & { subscriptionItem?: Maybe<(
      { __typename: 'SubscriptionItem' }
      & Pick<SubscriptionItem, 'id' | 'planId'>
    )> }
    & FSportsTeamFragment
  ) }
);

export type FSportsUserFragment = (
  { __typename: 'SportsUser' }
  & Pick<SportsUser, 'id' | 'slug' | 'name' | 'introduction' | 'pictureUrl' | 'priorSportsPosition' | 'priorSportsRole' | 'createdAt' | 'birthdate' | 'email' | 'federation' | 'gender' | 'nationality' | 'phoneNumber'>
  & { priorSportsCategory?: Maybe<(
    { __typename: 'SportsCategoryCollection' }
    & Pick<SportsCategoryCollection, 'id' | 'group'>
  )> }
);

export type FTeamGroupFragment = (
  { __typename: 'TeamGroup' }
  & Pick<TeamGroup, 'id' | 'name' | 'orderRanking' | 'teamId' | 'userId' | 'createdAt' | 'updatedAt'>
);

export type CsvExportMutationVariables = Exact<{
  input: CsvExportInput;
}>;


export type CsvExportMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'exportCsvFile'>
);

export type CreateFeedbackMutationVariables = Exact<{
  input: CreateFeedbackInput;
  multilingualTextInput: MultilingualTextInput;
}>;


export type CreateFeedbackMutation = (
  { __typename: 'Mutation' }
  & { createFeedback: (
    { __typename: 'Feedback' }
    & FeedbackDataFragment
  ) }
);

export type DeleteFeedbackMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFeedbackMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'deleteFeedback'>
);

export type UpdateFeedbackMutationVariables = Exact<{
  input: UpdateFeedbackInput;
  multilingualTextInput: MultilingualTextInput;
}>;


export type UpdateFeedbackMutation = (
  { __typename: 'Mutation' }
  & { updateFeedback: (
    { __typename: 'Feedback' }
    & FeedbackDataFragment
  ) }
);

export type CreateFeedbackCommentsMutationVariables = Exact<{
  input: CreateFeedbackCommentInput;
  multilingualTextInput: MultilingualTextInput;
}>;


export type CreateFeedbackCommentsMutation = (
  { __typename: 'Mutation' }
  & { createFeedbackComment: (
    { __typename: 'Feedback' }
    & FeedbackDataFragment
  ) }
);

export type UpdateFeedbackCommentsMutationVariables = Exact<{
  input: UpdateFeedbackCommentInput;
  multilingualTextInput: MultilingualTextInput;
}>;


export type UpdateFeedbackCommentsMutation = (
  { __typename: 'Mutation' }
  & { updateFeedbackComment: (
    { __typename: 'Feedback' }
    & FeedbackDataFragment
  ) }
);

export type DeleteFeedbackCommentsMutationVariables = Exact<{
  input: DeleteFeedbackCommentInput;
  multilingualTextInput: MultilingualTextInput;
}>;


export type DeleteFeedbackCommentsMutation = (
  { __typename: 'Mutation' }
  & { deleteFeedbackComment: (
    { __typename: 'Feedback' }
    & FeedbackDataFragment
  ) }
);

export type CreateInjuryMutationVariables = Exact<{
  input: CreateInjuryInput;
  files: Array<Scalars['Upload']> | Scalars['Upload'];
  multilingualTextInput: MultilingualTextInput;
}>;


export type CreateInjuryMutation = (
  { __typename: 'Mutation' }
  & { createInjury: (
    { __typename: 'Injury' }
    & Pick<Injury, 'id' | 'occurDate' | 'historyStatus' | 'reason' | 'diagnosis' | 'medicalRecord' | 'status'>
    & { bodyPart?: Maybe<(
      { __typename: 'SportsBodyPart' }
      & Pick<SportsBodyPart, 'id' | 'value'>
    )>, return: (
      { __typename: 'Return' }
      & Pick<Return, 'date' | 'status' | 'info'>
    ), fileList: Array<(
      { __typename: 'InjuryFile' }
      & Pick<InjuryFile, 'id' | 'name' | 'url'>
    )>, user: (
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
    ) }
  ) }
);

export type UpdateInjuryMutationVariables = Exact<{
  input: UpdateInjuryInput;
  files?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  multilingualTextInput: MultilingualTextInput;
}>;


export type UpdateInjuryMutation = (
  { __typename: 'Mutation' }
  & { updateInjury: (
    { __typename: 'Injury' }
    & Pick<Injury, 'id' | 'occurDate' | 'historyStatus' | 'reason' | 'diagnosis' | 'medicalRecord' | 'status'>
    & { bodyPart?: Maybe<(
      { __typename: 'SportsBodyPart' }
      & Pick<SportsBodyPart, 'id' | 'value'>
    )>, return: (
      { __typename: 'Return' }
      & Pick<Return, 'date' | 'info' | 'status'>
    ), fileList: Array<(
      { __typename: 'InjuryFile' }
      & Pick<InjuryFile, 'id' | 'name' | 'url'>
    )> }
  ) }
);

export type DeleteInjuryMutationVariables = Exact<{
  input: DeleteInjuryInput;
}>;


export type DeleteInjuryMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'deleteInjury'>
);

export type SendCheckConditionReminderPushMutationVariables = Exact<{
  input: CheckReminderPushInput;
}>;


export type SendCheckConditionReminderPushMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'sendCheckConditionReminderPush'>
);

export type SendCheckWorkloadReminderPushMutationVariables = Exact<{
  input: CheckReminderPushInput;
}>;


export type SendCheckWorkloadReminderPushMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'sendCheckWorkloadReminderPush'>
);

export type RequestBillingTokenMutationVariables = Exact<{
  input: RequestBillingTokenInput;
}>;


export type RequestBillingTokenMutation = (
  { __typename: 'Mutation' }
  & { requestBillingToken: (
    { __typename: 'BillingToken' }
    & Pick<BillingToken, 'customerUid' | 'paymentToken'>
  ) }
);

export type UpdateBillingKeyMutationVariables = Exact<{
  input: UpdateBillingTokenInput;
}>;


export type UpdateBillingKeyMutation = (
  { __typename: 'Mutation' }
  & { updateBillingKey: (
    { __typename: 'Billing' }
    & Pick<Billing, 'id' | 'identityToken' | 'customerUid' | 'cardName' | 'cardNumber' | 'buyerName' | 'buyerTel' | 'buyerEmail'>
  ) }
);

export type UpdateBillingBuyerNameMutationVariables = Exact<{
  input: UpdateBillingBuyerNameInput;
}>;


export type UpdateBillingBuyerNameMutation = (
  { __typename: 'Mutation' }
  & { updateBillingBuyerName: (
    { __typename: 'Billing' }
    & Pick<Billing, 'id'>
  ) }
);

export type UpdateBillingBuyerNumberMutationVariables = Exact<{
  input: UpdateBillingBuyerNumberInput;
}>;


export type UpdateBillingBuyerNumberMutation = (
  { __typename: 'Mutation' }
  & { updateBillingBuyerNumber: (
    { __typename: 'Billing' }
    & Pick<Billing, 'id'>
  ) }
);

export type UpdateBillingBuyerEmailMutationVariables = Exact<{
  input: UpdateBillingBuyerEmailInput;
}>;


export type UpdateBillingBuyerEmailMutation = (
  { __typename: 'Mutation' }
  & { updateBillingBuyerEmail: (
    { __typename: 'Billing' }
    & Pick<Billing, 'id'>
  ) }
);

export type StartSubscriptionMutationVariables = Exact<{
  input: StartSubscriptionInput;
}>;


export type StartSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { startSubscription: (
    { __typename: 'SubscriptionResponse' }
    & { subscription: (
      { __typename: 'SubscriptionItem' }
      & PaymentSubscrptionFragment
    ), paymentList: Array<(
      { __typename: 'PayInvoice' }
      & Pick<PayInvoice, 'id' | 'paymentName' | 'paymentStatus' | 'cardName' | 'cardNumber' | 'billingUserName' | 'billingUserEmail' | 'billingUserPhone' | 'receiptUrl' | 'paidAt' | 'amount'>
    ) | { __typename: 'RefundInvoice' }> }
  ) }
);

export type RestartSubscriptionMutationVariables = Exact<{
  input: RestartSubscriptionInput;
}>;


export type RestartSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { restartSubscription: (
    { __typename: 'SubscriptionResponse' }
    & { subscription: (
      { __typename: 'SubscriptionItem' }
      & PaymentSubscrptionFragment
    ), paymentList: Array<(
      { __typename: 'PayInvoice' }
      & Pick<PayInvoice, 'id' | 'paymentName' | 'paymentStatus' | 'cardName' | 'cardNumber' | 'billingUserName' | 'billingUserEmail' | 'billingUserPhone' | 'receiptUrl' | 'paidAt' | 'amount'>
    ) | { __typename: 'RefundInvoice' }> }
  ) }
);

export type RefundSubscriptionMutationVariables = Exact<{
  input: RefundSubscriptionInput;
}>;


export type RefundSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { refundSubscription: (
    { __typename: 'SubscriptionResponse' }
    & { subscription: (
      { __typename: 'SubscriptionItem' }
      & PaymentSubscrptionFragment
    ), paymentList: Array<{ __typename: 'PayInvoice' } | (
      { __typename: 'RefundInvoice' }
      & Pick<RefundInvoice, 'id' | 'paymentName' | 'paymentStatus' | 'cardName' | 'cardNumber' | 'billingUserName' | 'billingUserEmail' | 'billingUserPhone' | 'receiptUrl' | 'refundAt' | 'refundAmount'>
    )> }
  ) }
);

export type ResumeSubscriptionMutationVariables = Exact<{
  input: ResumeSubscriptionInput;
}>;


export type ResumeSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { resumeSubscription: (
    { __typename: 'SubscriptionItem' }
    & Pick<SubscriptionItem, 'id'>
  ) }
);

export type CancelSubscriptionMutationVariables = Exact<{
  input: CancelSubscriptionInput;
}>;


export type CancelSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { cancelSubscription: (
    { __typename: 'SubscriptionItem' }
    & Pick<SubscriptionItem, 'id'>
  ) }
);

export type RefundMutationVariables = Exact<{
  input: Refund;
}>;


export type RefundMutation = (
  { __typename: 'Mutation' }
  & { refund: (
    { __typename: 'RefundResponse' }
    & Pick<RefundResponse, 'success'>
  ) }
);

export type RePaymentRequestMutationVariables = Exact<{
  input: RetryPayment;
}>;


export type RePaymentRequestMutation = (
  { __typename: 'Mutation' }
  & { retryPayment: (
    { __typename: 'SubscriptionResponse' }
    & { subscription: (
      { __typename: 'SubscriptionItem' }
      & PaymentSubscrptionFragment
    ), paymentList: Array<(
      { __typename: 'PayInvoice' }
      & Pick<PayInvoice, 'id' | 'paymentName' | 'paymentStatus' | 'cardName' | 'cardNumber' | 'billingUserName' | 'billingUserEmail' | 'billingUserPhone' | 'receiptUrl' | 'paidAt' | 'amount'>
    ) | { __typename: 'RefundInvoice' }> }
  ) }
);

export type PaymentSubscrptionFragment = (
  { __typename: 'SubscriptionItem' }
  & Pick<SubscriptionItem, 'id' | 'status' | 'cycleAnchor' | 'startAt' | 'endAt' | 'teamId' | 'planId' | 'planOptionId' | 'isSubscripting' | 'isRefundable' | 'isFreeTrial' | 'isActivate'>
  & { plan: (
    { __typename: 'PlanWithOption' }
    & Pick<PlanWithOption, 'id' | 'planName' | 'planType'>
    & { planOptionInfo: (
      { __typename: 'PlanOption' }
      & Pick<PlanOption, 'id'>
    ) }
  ), coupon: (
    { __typename: 'UseCoupon' }
    & Pick<UseCoupon, 'id' | 'isUsed'>
  ) }
);

export type ExportTrainingReportMutationVariables = Exact<{
  input: ExportReport;
}>;


export type ExportTrainingReportMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'exportTrainingReport'>
);

export type ExportMatchReportMutationVariables = Exact<{
  input: ExportReport;
}>;


export type ExportMatchReportMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'exportMatchReport'>
);

export type LeaveTeamMutationVariables = Exact<{
  input: TeamUserInput;
}>;


export type LeaveTeamMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'leaveTeam'>
);

export type DeleteTeamMutationVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type DeleteTeamMutation = (
  { __typename: 'Mutation' }
  & { deleteTeam: (
    { __typename: 'SportsTeam' }
    & Pick<SportsTeam, 'id'>
  ) }
);

export type TransferTeamOwnershipMutationVariables = Exact<{
  input: TeamUserInput;
}>;


export type TransferTeamOwnershipMutation = (
  { __typename: 'Mutation' }
  & { transferTeamOwnership: (
    { __typename: 'TransferTeamOwnership' }
    & { ownerTeamUserMap: (
      { __typename: 'SportsTeamUserMap' }
      & Pick<SportsTeamUserMap, 'id'>
    ), adminTeamUserMap: (
      { __typename: 'SportsTeamUserMap' }
      & Pick<SportsTeamUserMap, 'id'>
    ) }
  ) }
);

export type KickoutTeamUserListMutationVariables = Exact<{
  input: TeamUserListInput;
}>;


export type KickoutTeamUserListMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'kickoutTeamUserList'>
);

export type CreateTeamGroupMutationVariables = Exact<{
  input: CreateTeamGroupInput;
}>;


export type CreateTeamGroupMutation = (
  { __typename: 'Mutation' }
  & { createTeamGroup: (
    { __typename: 'TeamGroup' }
    & { groupMembers: Array<(
      { __typename: 'TeamGroupUser' }
      & { user: (
        { __typename: 'SportsUser' }
        & FSportsUserFragment
      ) }
    )> }
    & FTeamGroupFragment
  ) }
);

export type UpdateTeamGroupMutationVariables = Exact<{
  input: UpdateTeamGroupInput;
}>;


export type UpdateTeamGroupMutation = (
  { __typename: 'Mutation' }
  & { updateTeamGroup: (
    { __typename: 'TeamGroup' }
    & { groupMembers: Array<(
      { __typename: 'TeamGroupUser' }
      & { user: (
        { __typename: 'SportsUser' }
        & FSportsUserFragment
      ) }
    )> }
    & FTeamGroupFragment
  ) }
);

export type DeleteTeamGroupMutationVariables = Exact<{
  input: DeleteTeamGroupInput;
}>;


export type DeleteTeamGroupMutation = (
  { __typename: 'Mutation' }
  & { deleteTeamGroup: (
    { __typename: 'TeamGroup' }
    & FTeamGroupFragment
  ) }
);

export type RequestTeamInvitationMutationVariables = Exact<{
  input: SportsTeamInvitationRequestInput;
}>;


export type RequestTeamInvitationMutation = (
  { __typename: 'Mutation' }
  & { requestTeamInvitation: (
    { __typename: 'SportsTeamInvitationRequest' }
    & Pick<SportsTeamInvitationRequest, 'id'>
    & { team: (
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id' | 'code' | 'name'>
    ), user: (
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
    ) }
  ) }
);

export type AcceptInvitationListMutationVariables = Exact<{
  input: SportsTeamInvitationAcceptListInput;
}>;


export type AcceptInvitationListMutation = (
  { __typename: 'Mutation' }
  & { acceptInvitationList: Array<(
    { __typename: 'SportsTeamInvitationRequest' }
    & Pick<SportsTeamInvitationRequest, 'id'>
  )> }
);

export type RejectTeamInvitationListMutationVariables = Exact<{
  input: SportsTeamInvitationRejectListInput;
}>;


export type RejectTeamInvitationListMutation = (
  { __typename: 'Mutation' }
  & { rejectTeamInvitationList: Array<(
    { __typename: 'SportsTeamInvitationRequest' }
    & Pick<SportsTeamInvitationRequest, 'id'>
  )> }
);

export type ToggleTeamUserStarStatusMutationVariables = Exact<{
  input: ToggleTeamUserStarredInput;
}>;


export type ToggleTeamUserStarStatusMutation = (
  { __typename: 'Mutation' }
  & { toggleTeamUserStarStatus: (
    { __typename: 'StarredToggleResult' }
    & Pick<StarredToggleResult, 'status'>
  ) }
);

export type UploadProfileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadProfileMutation = (
  { __typename: 'Mutation' }
  & { uploadProfile: (
    { __typename: 'UploadProfileResponse' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateSportsUserInput;
}>;


export type UpdateUserMutation = (
  { __typename: 'Mutation' }
  & { updateUser: (
    { __typename: 'SportsUser' }
    & FSportsUserFragment
  ) }
);

export type AnalyticsViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsViewerQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { viewer?: Maybe<(
      { __typename: 'SportsUser' }
      & AnalyticsViewerFragment
    )> }
  ) }
);

export type AnalyticsViewerFragment = (
  { __typename: 'SportsUser' }
  & Pick<SportsUser, 'id' | 'name' | 'pictureUrl' | 'priorSportsRole' | 'email' | 'birthdate' | 'phoneNumber' | 'gender' | 'nationality' | 'createdAt'>
  & { priorSportsCategory?: Maybe<(
    { __typename: 'SportsCategoryCollection' }
    & Pick<SportsCategoryCollection, 'id' | 'group'>
  )>, teamMaps: Array<(
    { __typename: 'SportsTeamUserMap' }
    & Pick<SportsTeamUserMap, 'id' | 'teamAuthority'>
    & { team: (
      { __typename: 'SportsTeam' }
      & AnalyticsTeamFragment
    ) }
  )> }
);

export type AnalyticsTeamFragment = (
  { __typename: 'SportsTeam' }
  & Pick<SportsTeam, 'id' | 'name' | 'pictureUrl' | 'code'>
  & { allUsers: (
    { __typename: 'SportsTeamUserMapByPage' }
    & Pick<SportsTeamUserMapByPage, 'total'>
  ), players: (
    { __typename: 'SportsTeamUserMapByPage' }
    & Pick<SportsTeamUserMapByPage, 'total'>
  ) }
);

export type InvoiceBillingQueryVariables = Exact<{
  identityToken: Scalars['ID'];
}>;


export type InvoiceBillingQuery = (
  { __typename: 'Query' }
  & { billing?: Maybe<(
    { __typename: 'Billing' }
    & Pick<Billing, 'id'>
    & InvoiceBillingItemFragment
  )> }
);

export type InvoiceBillingItemFragment = (
  { __typename: 'Billing' }
  & Pick<Billing, 'id' | 'identityToken' | 'customerUid' | 'cardName' | 'cardNumber' | 'buyerName' | 'buyerTel' | 'buyerEmail'>
);

export type MultilingualDataQueryVariables = Exact<{
  input: MultilingualTextInput;
}>;


export type MultilingualDataQuery = (
  { __typename: 'Query' }
  & { multilingualText: (
    { __typename: 'MultilingualTextQuery' }
    & { categoryGroupList: Array<(
      { __typename: 'MultilingualCategoryGroup' }
      & Pick<MultilingualCategoryGroup, 'id' | 'value'>
      & { categoryList: Array<(
        { __typename: 'MultilingualCategory' }
        & Pick<MultilingualCategory, 'id' | 'value'>
        & { coachPositionList: Array<(
          { __typename: 'MultilingualCoachPosition' }
          & Pick<MultilingualCoachPosition, 'id' | 'value'>
        )>, playerPositionList: Array<(
          { __typename: 'MultilingualPlayerPosition' }
          & Pick<MultilingualPlayerPosition, 'id' | 'value'>
        )> }
      )> }
    )>, countryList: Array<(
      { __typename: 'MultilingualCountry' }
      & Pick<MultilingualCountry, 'id' | 'value'>
    )> }
  ) }
);

export type TeamPlanListQueryVariables = Exact<{
  couponId?: Maybe<Scalars['String']>;
}>;


export type TeamPlanListQuery = (
  { __typename: 'Query' }
  & { teamPlanList: Array<(
    { __typename: 'Plan' }
    & Pick<Plan, 'id' | 'planName' | 'planType' | 'limitCount' | 'discountStartAt' | 'discountEndAt' | 'originalPriceStartAt'>
    & { planOptionInfoList?: Maybe<Array<Maybe<(
      { __typename: 'PlanOptionWithCoupon' }
      & PlanOptionInfoFragment
    )>>> }
  )> }
);

export type PlanOptionInfoFragment = (
  { __typename: 'PlanOptionWithCoupon' }
  & Pick<PlanOptionWithCoupon, 'id' | 'currency' | 'description' | 'originalPrice' | 'discountedPrice' | 'duration' | 'maxPlayerCount' | 'maxTeamCount' | 'nextPaymentDay' | 'period' | 'planOptionName'>
);

export type PlayerInjuryQueryVariables = Exact<{
  playerId: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  timezone: Scalars['String'];
  input: MultilingualTextInput;
}>;


export type PlayerInjuryQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { data: Array<(
        { __typename: 'SportsTimeSeriesData' }
        & Pick<SportsTimeSeriesData, 'name'>
        & { ranges: Array<(
          { __typename: 'SportsDataRange' }
          & { entries: Array<(
            { __typename: 'SportsDataEntry' }
            & Pick<SportsDataEntry, 'time' | 'value'>
          )> }
        )> }
      )> }
      & PlayerSummaryIdentityFragment
    )> }
  ) }
);

export type PlayerSelectAllUserMapsByGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PlayerSelectAllUserMapsByGroupQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { memberUserList: (
        { __typename: 'SportsUserList' }
        & Pick<SportsUserList, 'count'>
        & { userList: Array<(
          { __typename: 'SportsUser' }
          & Pick<SportsUser, 'id' | 'name' | 'pictureUrl' | 'nationality'>
        )> }
      ) }
    )> }
  ) }
);

export type GroupsMemberMapByTeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GroupsMemberMapByTeamQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { groups: Array<(
        { __typename: 'TeamGroup' }
        & { groupMembers: Array<(
          { __typename: 'TeamGroupUser' }
          & { user: (
            { __typename: 'SportsUser' }
            & FSportsUserFragment
          ) }
        )> }
        & FTeamGroupFragment
      )> }
    )> }
  ) }
);

export type TGroupMemberMapFragment = (
  { __typename: 'TeamGroup' }
  & Pick<TeamGroup, 'id' | 'name' | 'orderRanking'>
  & { groupMembers: Array<(
    { __typename: 'TeamGroupUser' }
    & { user: (
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'name' | 'pictureUrl' | 'nationality'>
    ) }
  )> }
);

export type TeamChartVarianceTodayDataQueryVariables = Exact<{
  teamId: Scalars['ID'];
  cursor: Scalars['DateTime'];
  timezone: Scalars['String'];
}>;


export type TeamChartVarianceTodayDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { dailyData: (
        { __typename: 'DailyTeamData' }
        & { acwr: (
          { __typename: 'DailyAcwrData' }
          & Pick<DailyAcwrData, 'value' | 'varianceFromYesterday'>
          & { range: Array<(
            { __typename: 'SportsTeamDataRange' }
            & Pick<SportsTeamDataRange, 'from' | 'to'>
            & { entries: Array<(
              { __typename: 'SportsTeamDataEntry' }
              & Pick<SportsTeamDataEntry, 'meta' | 'time' | 'value'>
            )> }
          )> }
        ), condition: (
          { __typename: 'DailyConditionData' }
          & Pick<DailyConditionData, 'value' | 'varianceFromYesterday'>
          & { range: Array<(
            { __typename: 'SportsTeamDataRange' }
            & Pick<SportsTeamDataRange, 'from' | 'to'>
            & { entries: Array<(
              { __typename: 'SportsTeamDataEntry' }
              & Pick<SportsTeamDataEntry, 'meta' | 'time' | 'value'>
            )> }
          )> }
        ), readiness: (
          { __typename: 'DailyReadinessData' }
          & Pick<DailyReadinessData, 'value' | 'varianceFromYesterday'>
        ), workload: (
          { __typename: 'DailyWorkloadData' }
          & Pick<DailyWorkloadData, 'value' | 'varianceFromYesterday'>
          & { range: Array<(
            { __typename: 'SportsTeamDataRange' }
            & Pick<SportsTeamDataRange, 'from' | 'to'>
            & { entries: Array<(
              { __typename: 'SportsTeamDataEntry' }
              & Pick<SportsTeamDataEntry, 'meta' | 'time' | 'value'>
            )> }
          )> }
        ) }
      ) }
    )> }
  ) }
);

export type TeamChartVarianceDataQueryVariables = Exact<{
  teamId: Scalars['ID'];
  cursor: Scalars['DateTime'];
  timezone: Scalars['String'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
}>;


export type TeamChartVarianceDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { dailyData: (
        { __typename: 'DailyTeamData' }
        & { acwr: (
          { __typename: 'DailyAcwrData' }
          & Pick<DailyAcwrData, 'value' | 'varianceFromYesterday'>
          & { range: Array<(
            { __typename: 'SportsTeamDataRange' }
            & Pick<SportsTeamDataRange, 'from' | 'to'>
            & { entries: Array<(
              { __typename: 'SportsTeamDataEntry' }
              & Pick<SportsTeamDataEntry, 'meta' | 'time' | 'value'>
            )> }
          )> }
        ), condition: (
          { __typename: 'DailyConditionData' }
          & Pick<DailyConditionData, 'value' | 'varianceFromYesterday'>
          & { range: Array<(
            { __typename: 'SportsTeamDataRange' }
            & Pick<SportsTeamDataRange, 'from' | 'to'>
            & { entries: Array<(
              { __typename: 'SportsTeamDataEntry' }
              & Pick<SportsTeamDataEntry, 'meta' | 'time' | 'value'>
            )> }
          )> }
        ), readiness: (
          { __typename: 'DailyReadinessData' }
          & Pick<DailyReadinessData, 'value' | 'varianceFromYesterday'>
        ), workload: (
          { __typename: 'DailyWorkloadData' }
          & Pick<DailyWorkloadData, 'value' | 'varianceFromYesterday'>
          & { range: Array<(
            { __typename: 'SportsTeamDataRange' }
            & Pick<SportsTeamDataRange, 'from' | 'to'>
            & { entries: Array<(
              { __typename: 'SportsTeamDataEntry' }
              & Pick<SportsTeamDataEntry, 'meta' | 'time' | 'value'>
            )> }
          )> }
        ) }
      ) }
    )> }
  ) }
);

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { viewer?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { guideReopenStatus: (
        { __typename: 'GuideReopenStatus' }
        & { alarm: (
          { __typename: 'GuideReopenStatusUnit' }
          & Pick<GuideReopenStatusUnit, 'web'>
        ) }
      ), teamMaps: Array<(
        { __typename: 'SportsTeamUserMap' }
        & FSportsTeamUserMapFragment
      )> }
      & FSportsUserFragment
    )> }
  ) }
);

export type QuerySystemUserDataQueryVariables = Exact<{
  id: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  groups?: Maybe<Array<SportsDataGroup> | SportsDataGroup>;
  names?: Maybe<Array<SportsDataName> | SportsDataName>;
  timezone: Scalars['String'];
  input: MultilingualTextInput;
}>;


export type QuerySystemUserDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'name' | 'pictureUrl'>
      & { data: Array<(
        { __typename: 'SportsTimeSeriesData' }
        & Pick<SportsTimeSeriesData, 'name'>
        & { ranges: Array<(
          { __typename: 'SportsDataRange' }
          & Pick<SportsDataRange, 'from'>
          & { entries: Array<(
            { __typename: 'SportsDataEntry' }
            & Pick<SportsDataEntry, 'value' | 'time'>
          )> }
        )> }
      )>, workload: Array<(
        { __typename: 'UserWorkloadData' }
        & Pick<UserWorkloadData, 'time' | 'count' | 'reasonForNoWorkout'>
        & { workload: (
          { __typename: 'Statistic' }
          & Pick<Statistic, 'sum'>
        ), duration: (
          { __typename: 'Statistic' }
          & Pick<Statistic, 'sum'>
        ), intensity: (
          { __typename: 'Statistic' }
          & Pick<Statistic, 'avg'>
        ), satisfaction: (
          { __typename: 'Statistic' }
          & Pick<Statistic, 'avg'>
        ), workloadList: Array<(
          { __typename: 'SportsRawWorkload' }
          & Pick<SportsRawWorkload, 'id' | 'name' | 'time' | 'load' | 'duration' | 'intensity' | 'satisfaction' | 'type'>
          & { event?: Maybe<(
            { __typename: 'BasicEventInvitee' }
            & { event: (
              { __typename: 'BasicEvent' }
              & Pick<BasicEvent, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) | (
              { __typename: 'Match' }
              & Pick<Match, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) | (
              { __typename: 'Training' }
              & Pick<Training, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) }
          ) | (
            { __typename: 'MatchInvitee' }
            & { event: (
              { __typename: 'BasicEvent' }
              & Pick<BasicEvent, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) | (
              { __typename: 'Match' }
              & Pick<Match, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) | (
              { __typename: 'Training' }
              & Pick<Training, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) }
          ) | (
            { __typename: 'TrainingInvitee' }
            & { event: (
              { __typename: 'BasicEvent' }
              & Pick<BasicEvent, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) | (
              { __typename: 'Match' }
              & Pick<Match, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) | (
              { __typename: 'Training' }
              & Pick<Training, 'id' | 'title' | 'hideExpectedIntensityFromPlayers' | 'startAt' | 'endAt'>
            ) }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type QuerySystemUserWorkloadDataQueryVariables = Exact<{
  id: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  timezone: Scalars['String'];
  teamId?: Maybe<Scalars['ID']>;
}>;


export type QuerySystemUserWorkloadDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'name' | 'pictureUrl'>
      & { sportsDataList: Array<(
        { __typename: 'SportsData' }
        & Pick<SportsData, 'id' | 'date'>
        & { workload?: Maybe<(
          { __typename: 'WorkloadData' }
          & Pick<WorkloadData, 'count' | 'reasonForNoWorkout' | 'isChecked'>
          & { workload: (
            { __typename: 'Statistic' }
            & Pick<Statistic, 'sum'>
          ), duration: (
            { __typename: 'Statistic' }
            & Pick<Statistic, 'sum'>
          ), intensity: (
            { __typename: 'Statistic' }
            & Pick<Statistic, 'avg'>
          ), satisfaction: (
            { __typename: 'Statistic' }
            & Pick<Statistic, 'avg'>
          ), workloadList: Array<(
            { __typename: 'Workload' }
            & Pick<Workload, 'id' | 'time' | 'load' | 'duration' | 'intensity' | 'satisfaction' | 'type'>
            & { schedule?: Maybe<(
              { __typename: 'BasicScheduleInvitee' }
              & Pick<BasicScheduleInvitee, 'id'>
              & { schedule?: Maybe<(
                { __typename: 'BasicSchedule' }
                & Pick<BasicSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              ) | (
                { __typename: 'MatchSchedule' }
                & Pick<MatchSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              ) | (
                { __typename: 'TrainingSchedule' }
                & Pick<TrainingSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              )> }
            ) | (
              { __typename: 'MatchScheduleInvitee' }
              & Pick<MatchScheduleInvitee, 'id'>
              & { schedule?: Maybe<(
                { __typename: 'BasicSchedule' }
                & Pick<BasicSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              ) | (
                { __typename: 'MatchSchedule' }
                & Pick<MatchSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              ) | (
                { __typename: 'TrainingSchedule' }
                & Pick<TrainingSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              )> }
            ) | (
              { __typename: 'TrainingInviteeSchedule' }
              & Pick<TrainingInviteeSchedule, 'id'>
              & { schedule?: Maybe<(
                { __typename: 'BasicSchedule' }
                & Pick<BasicSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              ) | (
                { __typename: 'MatchSchedule' }
                & Pick<MatchSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              ) | (
                { __typename: 'TrainingSchedule' }
                & Pick<TrainingSchedule, 'id' | 'title' | 'startTime' | 'endTime'>
                & { team?: Maybe<(
                  { __typename: 'SportsTeam' }
                  & Pick<SportsTeam, 'id'>
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type QuerySystemTeamUserMapsDataQueryVariables = Exact<{
  id: Scalars['ID'];
  cursor: Scalars['DateTime'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  timezone: Scalars['String'];
  groups?: Maybe<Array<SportsDataGroup> | SportsDataGroup>;
  names?: Maybe<Array<SportsDataName> | SportsDataName>;
  filter?: Maybe<TeamUserMapFilterInput>;
  order?: Maybe<UserDataOrderByInput>;
  input: MultilingualTextInput;
}>;


export type QuerySystemTeamUserMapsDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { userMapsByPage: (
        { __typename: 'SportsTeamUserMapByPage' }
        & Pick<SportsTeamUserMapByPage, 'offset' | 'limit' | 'total' | 'hasPrev' | 'hasNext'>
        & { entries: Array<(
          { __typename: 'SportsTeamUserMap' }
          & Pick<SportsTeamUserMap, 'id' | 'teamAuthority'>
          & { user: (
            { __typename: 'SportsUser' }
            & Pick<SportsUser, 'id' | 'name' | 'pictureUrl'>
            & { data: Array<(
              { __typename: 'SportsTimeSeriesData' }
              & Pick<SportsTimeSeriesData, 'name' | 'stride'>
              & { ranges: Array<(
                { __typename: 'SportsDataRange' }
                & Pick<SportsDataRange, 'from'>
                & { entries: Array<(
                  { __typename: 'SportsDataEntry' }
                  & Pick<SportsDataEntry, 'time' | 'value'>
                )> }
              )> }
            )> }
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type QuerySystemTeamAverageDataQueryVariables = Exact<{
  id: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  timezone: Scalars['String'];
  names: Array<SportsTeamDataName> | SportsTeamDataName;
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
}>;


export type QuerySystemTeamAverageDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { data: Array<(
        { __typename: 'SportsTimeSeriesTeamData' }
        & Pick<SportsTimeSeriesTeamData, 'stride' | 'name'>
        & { ranges: Array<(
          { __typename: 'SportsTeamDataRange' }
          & Pick<SportsTeamDataRange, 'from' | 'to'>
          & { entries: Array<(
            { __typename: 'SportsTeamDataEntry' }
            & Pick<SportsTeamDataEntry, 'time' | 'value'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type MonitoringChartDataQueryVariables = Exact<{
  teamId: Scalars['ID'];
  cursor: Scalars['DateTime'];
  timezone: Scalars['String'];
  dataName: ChartDataName;
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  input: MultilingualTextInput;
}>;


export type MonitoringChartDataQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { chartData: (
        { __typename: 'ChartData' }
        & { avg: (
          { __typename: 'ChartBody' }
          & Pick<ChartBody, 'weight' | 'height'>
        ) | (
          { __typename: 'ChartCondition' }
          & Pick<ChartCondition, 'mood' | 'muscle' | 'fatigue' | 'stress' | 'duration' | 'quality' | 'condition'>
        ) | (
          { __typename: 'ChartSoreness' }
          & { level1: (
            { __typename: 'SorenessLevelData' }
            & Pick<SorenessLevelData, 'count'>
            & { parts: Array<(
              { __typename: 'SorenessParts' }
              & Pick<SorenessParts, 'bodyPartGroup'>
              & { bodyPart: (
                { __typename: 'SportsBodyPart' }
                & Pick<SportsBodyPart, 'id' | 'value'>
              ) }
            )> }
          ), level2: (
            { __typename: 'SorenessLevelData' }
            & Pick<SorenessLevelData, 'count'>
            & { parts: Array<(
              { __typename: 'SorenessParts' }
              & Pick<SorenessParts, 'bodyPartGroup'>
              & { bodyPart: (
                { __typename: 'SportsBodyPart' }
                & Pick<SportsBodyPart, 'id' | 'value'>
              ) }
            )> }
          ), level3: (
            { __typename: 'SorenessLevelData' }
            & Pick<SorenessLevelData, 'count'>
            & { parts: Array<(
              { __typename: 'SorenessParts' }
              & Pick<SorenessParts, 'bodyPartGroup'>
              & { bodyPart: (
                { __typename: 'SportsBodyPart' }
                & Pick<SportsBodyPart, 'id' | 'value'>
              ) }
            )> }
          ), level4: (
            { __typename: 'SorenessLevelData' }
            & Pick<SorenessLevelData, 'count'>
            & { parts: Array<(
              { __typename: 'SorenessParts' }
              & Pick<SorenessParts, 'bodyPartGroup'>
              & { bodyPart: (
                { __typename: 'SportsBodyPart' }
                & Pick<SportsBodyPart, 'id' | 'value'>
              ) }
            )> }
          ), level5: (
            { __typename: 'SorenessLevelData' }
            & Pick<SorenessLevelData, 'count'>
            & { parts: Array<(
              { __typename: 'SorenessParts' }
              & Pick<SorenessParts, 'bodyPartGroup'>
              & { bodyPart: (
                { __typename: 'SportsBodyPart' }
                & Pick<SportsBodyPart, 'id' | 'value'>
              ) }
            )> }
          ) }
        ) | (
          { __typename: 'ChartWorkload' }
          & Pick<ChartWorkload, 'duration' | 'satisfaction' | 'workload' | 'intensity'>
        ) | (
          { __typename: 'ChartWorkloadAcxrAxd' }
          & Pick<ChartWorkloadAcxrAxd, 'acxr' | 'axd' | 'ax' | 'cx'>
        ), userList: Array<(
          { __typename: 'ChartUser' }
          & { user: (
            { __typename: 'SportsUser' }
            & Pick<SportsUser, 'id' | 'name' | 'pictureUrl'>
          ), value: (
            { __typename: 'ChartBody' }
            & Pick<ChartBody, 'weight' | 'height'>
          ) | (
            { __typename: 'ChartSoreness' }
            & { level1: (
              { __typename: 'SorenessLevelData' }
              & Pick<SorenessLevelData, 'count'>
              & { parts: Array<(
                { __typename: 'SorenessParts' }
                & Pick<SorenessParts, 'bodyPartGroup'>
                & { bodyPart: (
                  { __typename: 'SportsBodyPart' }
                  & Pick<SportsBodyPart, 'id' | 'value'>
                ) }
              )> }
            ), level2: (
              { __typename: 'SorenessLevelData' }
              & Pick<SorenessLevelData, 'count'>
              & { parts: Array<(
                { __typename: 'SorenessParts' }
                & Pick<SorenessParts, 'bodyPartGroup'>
                & { bodyPart: (
                  { __typename: 'SportsBodyPart' }
                  & Pick<SportsBodyPart, 'id' | 'value'>
                ) }
              )> }
            ), level3: (
              { __typename: 'SorenessLevelData' }
              & Pick<SorenessLevelData, 'count'>
              & { parts: Array<(
                { __typename: 'SorenessParts' }
                & Pick<SorenessParts, 'bodyPartGroup'>
                & { bodyPart: (
                  { __typename: 'SportsBodyPart' }
                  & Pick<SportsBodyPart, 'id' | 'value'>
                ) }
              )> }
            ), level4: (
              { __typename: 'SorenessLevelData' }
              & Pick<SorenessLevelData, 'count'>
              & { parts: Array<(
                { __typename: 'SorenessParts' }
                & Pick<SorenessParts, 'bodyPartGroup'>
                & { bodyPart: (
                  { __typename: 'SportsBodyPart' }
                  & Pick<SportsBodyPart, 'id' | 'value'>
                ) }
              )> }
            ), level5: (
              { __typename: 'SorenessLevelData' }
              & Pick<SorenessLevelData, 'count'>
              & { parts: Array<(
                { __typename: 'SorenessParts' }
                & Pick<SorenessParts, 'bodyPartGroup'>
                & { bodyPart: (
                  { __typename: 'SportsBodyPart' }
                  & Pick<SportsBodyPart, 'id' | 'value'>
                ) }
              )> }
            ) }
          ) | (
            { __typename: 'ChartUserCondition' }
            & Pick<ChartUserCondition, 'mood' | 'muscle' | 'fatigue' | 'stress' | 'duration' | 'quality' | 'condition'>
          ) | (
            { __typename: 'ChartUserWorkload' }
            & Pick<ChartUserWorkload, 'duration' | 'satisfaction' | 'workload' | 'intensity' | 'reasonForNoWorkout'>
            & { workloadList: Array<Maybe<(
              { __typename: 'Workload' }
              & Pick<Workload, 'id' | 'load' | 'duration' | 'time' | 'intensity' | 'satisfaction' | 'type' | 'memo'>
              & { schedule?: Maybe<(
                { __typename: 'BasicScheduleInvitee' }
                & { schedule?: Maybe<(
                  { __typename: 'BasicSchedule' }
                  & Pick<BasicSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                ) | (
                  { __typename: 'MatchSchedule' }
                  & Pick<MatchSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                ) | (
                  { __typename: 'TrainingSchedule' }
                  & Pick<TrainingSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                )> }
              ) | (
                { __typename: 'MatchScheduleInvitee' }
                & { schedule?: Maybe<(
                  { __typename: 'BasicSchedule' }
                  & Pick<BasicSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                ) | (
                  { __typename: 'MatchSchedule' }
                  & Pick<MatchSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                ) | (
                  { __typename: 'TrainingSchedule' }
                  & Pick<TrainingSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                )> }
              ) | (
                { __typename: 'TrainingInviteeSchedule' }
                & { schedule?: Maybe<(
                  { __typename: 'BasicSchedule' }
                  & Pick<BasicSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                ) | (
                  { __typename: 'MatchSchedule' }
                  & Pick<MatchSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                ) | (
                  { __typename: 'TrainingSchedule' }
                  & Pick<TrainingSchedule, 'id' | 'title'>
                  & { team?: Maybe<(
                    { __typename: 'SportsTeam' }
                    & Pick<SportsTeam, 'id'>
                  )> }
                )> }
              )> }
            )>> }
          ) | (
            { __typename: 'ChartWorkloadAcxrAxd' }
            & Pick<ChartWorkloadAcxrAxd, 'acxr' | 'axd' | 'ax' | 'cx'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type PlanBillingTeamSubscriptionQueryVariables = Exact<{
  teamId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type PlanBillingTeamSubscriptionQuery = (
  { __typename: 'Query' }
  & { teamSubscriptionItem?: Maybe<(
    { __typename: 'SubscriptionItem' }
    & PlanBillingTeamSubscriptionFragment
  )> }
);

export type PlanBillingTeamSubscriptionFragment = (
  { __typename: 'SubscriptionItem' }
  & Pick<SubscriptionItem, 'id' | 'status' | 'cycleAnchor' | 'startAt' | 'endAt' | 'teamId' | 'isSubscripting' | 'isRefundable' | 'isFreeTrial'>
  & { invoicesByPage: (
    { __typename: 'InvoiceList' }
    & Pick<InvoiceList, 'offset' | 'limit' | 'total' | 'hasNext' | 'hasPrev'>
    & { invoiceList?: Maybe<Array<Maybe<(
      { __typename: 'PayInvoice' }
      & PlanBillingPayInvoiceInfoFragment
    ) | (
      { __typename: 'RefundInvoice' }
      & Pick<RefundInvoice, 'id' | 'refundAt' | 'paymentName' | 'paymentStatus' | 'refundAmount' | 'cardName' | 'cardNumber' | 'receiptUrl'>
    )>>> }
  ), coupon: (
    { __typename: 'UseCoupon' }
    & Pick<UseCoupon, 'id' | 'isUsed'>
  ), plan: (
    { __typename: 'PlanWithOption' }
    & Pick<PlanWithOption, 'id' | 'planName' | 'planType'>
    & { planOptionInfo: (
      { __typename: 'PlanOption' }
      & Pick<PlanOption, 'id' | 'planOptionName' | 'description' | 'amount' | 'currency' | 'period' | 'duration' | 'maxPlayerCount' | 'maxTeamCount'>
    ) }
  ) }
);

export type PlanBillingBillingQueryVariables = Exact<{
  identityToken: Scalars['ID'];
}>;


export type PlanBillingBillingQuery = (
  { __typename: 'Query' }
  & { billing?: Maybe<(
    { __typename: 'Billing' }
    & Pick<Billing, 'id' | 'identityToken' | 'customerUid' | 'cardName' | 'cardNumber' | 'buyerName' | 'buyerTel' | 'buyerEmail'>
  )> }
);

export type PlanBillingInvoicesByPageQueryVariables = Exact<{
  subscriptionId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PlanBillingInvoicesByPageQuery = (
  { __typename: 'Query' }
  & { invoicesByPage: (
    { __typename: 'InvoiceList' }
    & Pick<InvoiceList, 'offset' | 'limit' | 'total' | 'hasNext' | 'hasPrev'>
    & { invoiceList?: Maybe<Array<Maybe<(
      { __typename: 'PayInvoice' }
      & PlanBillingPayInvoiceInfoFragment
    ) | (
      { __typename: 'RefundInvoice' }
      & Pick<RefundInvoice, 'id' | 'refundAt' | 'paymentName' | 'paymentStatus' | 'refundAmount' | 'cardName' | 'cardNumber' | 'receiptUrl'>
    )>>> }
  ) }
);

export type PlanBillingPayInvoiceInfoFragment = (
  { __typename: 'PayInvoice' }
  & Pick<PayInvoice, 'id' | 'paidAt' | 'paymentName' | 'paymentStatus' | 'amount' | 'cardName' | 'cardNumber' | 'receiptUrl'>
);

export type PlanBillingLicenseQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type PlanBillingLicenseQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { license?: Maybe<(
        { __typename: 'License' }
        & Pick<License, 'id' | 'startAt' | 'endAt' | 'licenseName' | 'teamVolume' | 'teamIdList'>
      )> }
    )> }
  ) }
);

export type PlanPayPricingBillingQueryVariables = Exact<{
  identityToken: Scalars['ID'];
}>;


export type PlanPayPricingBillingQuery = (
  { __typename: 'Query' }
  & { billing?: Maybe<(
    { __typename: 'Billing' }
    & PlanPayPricingBillingItemFragment
  )> }
);

export type PlanPayPricingTeamSubscriptionQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type PlanPayPricingTeamSubscriptionQuery = (
  { __typename: 'Query' }
  & { teamSubscriptionItem?: Maybe<(
    { __typename: 'SubscriptionItem' }
    & PlanPayPricingSubscriptionFragment
  )> }
);

export type PlanPayPricingTeamCouponsQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type PlanPayPricingTeamCouponsQuery = (
  { __typename: 'Query' }
  & { couponListByTeam: (
    { __typename: 'CouponListByTeam' }
    & { content?: Maybe<Array<(
      { __typename: 'CouponSimple' }
      & Pick<CouponSimple, 'couponId' | 'name'>
    )>> }
  ) }
);

export type PlanPayPricingAppliedPriceQueryVariables = Exact<{
  input: GetCouponAppliedPriceInput;
}>;


export type PlanPayPricingAppliedPriceQuery = (
  { __typename: 'Query' }
  & { couponAppliedPrice: (
    { __typename: 'CouponApplied' }
    & Pick<CouponApplied, 'deductedPrice' | 'discountedPrice' | 'originalPrice'>
    & { discountInfo: (
      { __typename: 'DiscountInfo' }
      & Pick<DiscountInfo, 'type' | 'value'>
    ) }
  ) }
);

export type HighestBenefitCouponByTeamQueryVariables = Exact<{
  originalPrice: Scalars['Float'];
  teamId: Scalars['ID'];
}>;


export type HighestBenefitCouponByTeamQuery = (
  { __typename: 'Query' }
  & { highestBenefitCouponByTeam?: Maybe<(
    { __typename: 'CouponSimple' }
    & Pick<CouponSimple, 'couponId' | 'endAt' | 'name' | 'remainDays' | 'startAt'>
  )> }
);

export type PlanPayPricingSubscriptionFragment = (
  { __typename: 'SubscriptionItem' }
  & Pick<SubscriptionItem, 'id' | 'status' | 'cycleAnchor' | 'startAt' | 'endAt' | 'teamId' | 'isSubscripting' | 'isRefundable' | 'isFreeTrial'>
  & { plan: (
    { __typename: 'PlanWithOption' }
    & Pick<PlanWithOption, 'id' | 'planName' | 'planType'>
    & { planOptionInfo: (
      { __typename: 'PlanOption' }
      & Pick<PlanOption, 'id' | 'planOptionName' | 'description' | 'currency' | 'period' | 'duration' | 'maxPlayerCount' | 'maxTeamCount' | 'amount'>
    ) }
  ) }
);

export type PlanPayPricingBillingItemFragment = (
  { __typename: 'Billing' }
  & Pick<Billing, 'id' | 'identityToken' | 'customerUid' | 'cardName' | 'cardNumber' | 'buyerName' | 'buyerTel' | 'buyerEmail'>
);

export type PlayersUserMapQueryVariables = Exact<{
  teamId: Scalars['ID'];
  limit: Scalars['Int'];
  filter: TeamUserMapFilterInput;
  timezone: Scalars['String'];
}>;


export type PlayersUserMapQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { team?: Maybe<(
      { __typename: 'SportsTeam' }
      & Pick<SportsTeam, 'id'>
      & { userMapsByPage: (
        { __typename: 'SportsTeamUserMapByPage' }
        & { entries: Array<(
          { __typename: 'SportsTeamUserMap' }
          & Pick<SportsTeamUserMap, 'id'>
          & { user: (
            { __typename: 'SportsUser' }
            & Pick<SportsUser, 'id'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type RegisterPostionPositionsQueryVariables = Exact<{
  categoryId: Scalars['String'];
}>;


export type RegisterPostionPositionsQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { category: (
      { __typename: 'SportsCategoryCollection' }
      & Pick<SportsCategoryCollection, 'id' | 'coachPositions'>
    ) }
  ) }
);

export type UploadUserProfileImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadUserProfileImageMutation = (
  { __typename: 'Mutation' }
  & { uploadProfile: (
    { __typename: 'UploadProfileResponse' }
    & { user?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id' | 'pictureFileId'>
    )> }
  ) }
);

export type NavigationViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type NavigationViewerQuery = (
  { __typename: 'Query' }
  & { sports: (
    { __typename: 'SportsQuery' }
    & { viewer?: Maybe<(
      { __typename: 'SportsUser' }
      & Pick<SportsUser, 'id'>
      & { teamMaps: Array<(
        { __typename: 'SportsTeamUserMap' }
        & FSportsTeamUserMapFragment
      )>, guideReopenStatus: (
        { __typename: 'GuideReopenStatus' }
        & { alarm: (
          { __typename: 'GuideReopenStatusUnit' }
          & Pick<GuideReopenStatusUnit, 'web'>
        ) }
      ) }
      & FSportsUserFragment
    )> }
  ) }
);

export type TRegisterViewerFragment = (
  { __typename: 'SportsUser' }
  & Pick<SportsUser, 'id' | 'priorSportsPosition' | 'pictureUrl' | 'name' | 'gender' | 'birthdate' | 'nationality' | 'phoneNumber'>
  & { priorSportsCategory?: Maybe<(
    { __typename: 'SportsCategoryCollection' }
    & Pick<SportsCategoryCollection, 'id' | 'group'>
  )> }
);

export type NavigationViewerFragment = (
  { __typename: 'SportsUser' }
  & Pick<SportsUser, 'id' | 'name' | 'slug' | 'pictureUrl' | 'priorSportsRole' | 'priorSportsPosition' | 'createdAt'>
  & { priorSportsCategory?: Maybe<(
    { __typename: 'SportsCategoryCollection' }
    & Pick<SportsCategoryCollection, 'id' | 'group'>
  )> }
);

export type NavigationByPlanQueryVariables = Exact<{
  teamId: Scalars['ID'];
  includeSubscription?: Maybe<Scalars['Boolean']>;
  includeLicense?: Maybe<Scalars['Boolean']>;
}>;


export type NavigationByPlanQuery = (
  { __typename: 'Query' }
  & { teamSubscriptionItem?: Maybe<(
    { __typename: 'SubscriptionItem' }
    & NavigationTeamSubscriptionFragment
  )>, licenseByTeam?: Maybe<(
    { __typename: 'License' }
    & NavigationTeamLicenseFragment
  )> }
);

export type NavigationTeamSubscriptionFragment = (
  { __typename: 'SubscriptionItem' }
  & Pick<SubscriptionItem, 'id' | 'status' | 'teamId' | 'isFreeTrial' | 'isSubscripting' | 'startAt' | 'endAt' | 'planId' | 'planOptionId'>
  & { plan: (
    { __typename: 'PlanWithOption' }
    & Pick<PlanWithOption, 'id' | 'planType'>
    & { planOptionInfo: (
      { __typename: 'PlanOption' }
      & Pick<PlanOption, 'id' | 'maxPlayerCount' | 'maxTeamCount' | 'amount' | 'currency' | 'period' | 'description' | 'planOptionName' | 'nextPaymentDay'>
    ) }
  ) }
);

export type NavigationTeamLicenseFragment = (
  { __typename: 'License' }
  & Pick<License, 'id' | 'isActivate'>
);

export type NavigationQualificationQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type NavigationQualificationQuery = (
  { __typename: 'Query' }
  & { qualification: (
    { __typename: 'Qualification' }
    & Pick<Qualification, 'id' | 'qualificationType' | 'isExpired'>
  ) }
);

export const namedOperations = {
  Query: {
    CardDiscountDetailsCouponContents: 'CardDiscountDetailsCouponContents',
    InvitationRequestBadgeRequests: 'InvitationRequestBadgeRequests',
    CardGroupSummaryProgressBarData: 'CardGroupSummaryProgressBarData',
    FeedbackList: 'FeedbackList',
    UserCurrentInjuryList: 'UserCurrentInjuryList',
    UserPastInjuryList: 'UserPastInjuryList',
    FeedbackDetail: 'FeedbackDetail',
    FeedbackSuggestionList: 'FeedbackSuggestionList',
    DialogTeamPlayerList: 'DialogTeamPlayerList',
    DrawerPlayersUserMaps: 'DrawerPlayersUserMaps',
    BodyParts: 'BodyParts',
    BodyPartMultilingualText: 'BodyPartMultilingualText',
    ModalGroupGroups: 'ModalGroupGroups',
    ModalTeamCreateViewer: 'ModalTeamCreateViewer',
    ModalTeamCreateTeamData: 'ModalTeamCreateTeamData',
    ModalTeamJoinViewer: 'ModalTeamJoinViewer',
    NavbarTeamsTeamData: 'NavbarTeamsTeamData',
    SectionPaymentPlanOptionsTeamSubscription: 'SectionPaymentPlanOptionsTeamSubscription',
    TeamChartSorenessLevel5userList: 'TeamChartSorenessLevel5userList',
    PlayerModalUser: 'PlayerModalUser',
    PlayerModalLatestRawBody: 'PlayerModalLatestRawBody',
    SportsUserInjuryHistoryTable: 'SportsUserInjuryHistoryTable',
    WeeklyInjuryHistory: 'WeeklyInjuryHistory',
    MonthlyInjuryHistory: 'MonthlyInjuryHistory',
    PlayerSummary: 'PlayerSummary',
    AnalyticsViewer: 'AnalyticsViewer',
    InvoiceBilling: 'InvoiceBilling',
    MultilingualData: 'MultilingualData',
    TeamPlanList: 'TeamPlanList',
    PlayerInjury: 'PlayerInjury',
    PlayerSelectAllUserMapsByGroup: 'PlayerSelectAllUserMapsByGroup',
    GroupsMemberMapByTeam: 'GroupsMemberMapByTeam',
    TeamChartVarianceTodayData: 'TeamChartVarianceTodayData',
    TeamChartVarianceData: 'TeamChartVarianceData',
    Viewer: 'Viewer',
    QuerySystemUserData: 'QuerySystemUserData',
    QuerySystemUserWorkloadData: 'QuerySystemUserWorkloadData',
    QuerySystemTeamUserMapsData: 'QuerySystemTeamUserMapsData',
    QuerySystemTeamAverageData: 'QuerySystemTeamAverageData',
    MonitoringChartData: 'MonitoringChartData',
    PlanBillingTeamSubscription: 'PlanBillingTeamSubscription',
    PlanBillingBilling: 'PlanBillingBilling',
    PlanBillingInvoicesByPage: 'PlanBillingInvoicesByPage',
    PlanBillingLicense: 'PlanBillingLicense',
    PlanPayPricingBilling: 'PlanPayPricingBilling',
    PlanPayPricingTeamSubscription: 'PlanPayPricingTeamSubscription',
    PlanPayPricingTeamCoupons: 'PlanPayPricingTeamCoupons',
    PlanPayPricingAppliedPrice: 'PlanPayPricingAppliedPrice',
    HighestBenefitCouponByTeam: 'HighestBenefitCouponByTeam',
    PlayersUserMap: 'PlayersUserMap',
    RegisterPostionPositions: 'RegisterPostionPositions',
    NavigationViewer: 'NavigationViewer',
    NavigationByPlan: 'NavigationByPlan',
    NavigationQualification: 'NavigationQualification'
  },
  Mutation: {
    CsvExport: 'CsvExport',
    CreateFeedback: 'CreateFeedback',
    DeleteFeedback: 'DeleteFeedback',
    UpdateFeedback: 'UpdateFeedback',
    CreateFeedbackComments: 'CreateFeedbackComments',
    UpdateFeedbackComments: 'UpdateFeedbackComments',
    DeleteFeedbackComments: 'DeleteFeedbackComments',
    CreateInjury: 'CreateInjury',
    UpdateInjury: 'UpdateInjury',
    DeleteInjury: 'DeleteInjury',
    SendCheckConditionReminderPush: 'SendCheckConditionReminderPush',
    SendCheckWorkloadReminderPush: 'SendCheckWorkloadReminderPush',
    RequestBillingToken: 'RequestBillingToken',
    UpdateBillingKey: 'UpdateBillingKey',
    UpdateBillingBuyerName: 'UpdateBillingBuyerName',
    UpdateBillingBuyerNumber: 'UpdateBillingBuyerNumber',
    UpdateBillingBuyerEmail: 'UpdateBillingBuyerEmail',
    StartSubscription: 'StartSubscription',
    RestartSubscription: 'RestartSubscription',
    RefundSubscription: 'RefundSubscription',
    ResumeSubscription: 'ResumeSubscription',
    CancelSubscription: 'CancelSubscription',
    Refund: 'Refund',
    RePaymentRequest: 'RePaymentRequest',
    ExportTrainingReport: 'ExportTrainingReport',
    ExportMatchReport: 'ExportMatchReport',
    LeaveTeam: 'LeaveTeam',
    DeleteTeam: 'DeleteTeam',
    TransferTeamOwnership: 'TransferTeamOwnership',
    KickoutTeamUserList: 'KickoutTeamUserList',
    CreateTeamGroup: 'CreateTeamGroup',
    UpdateTeamGroup: 'UpdateTeamGroup',
    DeleteTeamGroup: 'DeleteTeamGroup',
    RequestTeamInvitation: 'RequestTeamInvitation',
    AcceptInvitationList: 'AcceptInvitationList',
    RejectTeamInvitationList: 'RejectTeamInvitationList',
    ToggleTeamUserStarStatus: 'ToggleTeamUserStarStatus',
    UploadProfile: 'UploadProfile',
    UpdateUser: 'UpdateUser',
    UploadUserProfileImage: 'UploadUserProfileImage'
  },
  Fragment: {
    UserInjuryData: 'UserInjuryData',
    FeedbackData: 'FeedbackData',
    DrawerPlayersUserMap: 'DrawerPlayersUserMap',
    ModalTeamCreateTeam: 'ModalTeamCreateTeam',
    NavbarTeamsTeam: 'NavbarTeamsTeam',
    SectionPaymentPlanOptionsSubscription: 'SectionPaymentPlanOptionsSubscription',
    WidgetSorenessUserList: 'WidgetSorenessUserList',
    PlayerSummaryIdentity: 'PlayerSummaryIdentity',
    FSportsTeam: 'FSportsTeam',
    FSportsTeamInvitationRequest: 'FSportsTeamInvitationRequest',
    FSportsTeamUserMap: 'FSportsTeamUserMap',
    FSportsUser: 'FSportsUser',
    FTeamGroup: 'FTeamGroup',
    PaymentSubscrption: 'PaymentSubscrption',
    AnalyticsViewer: 'AnalyticsViewer',
    AnalyticsTeam: 'AnalyticsTeam',
    InvoiceBillingItem: 'InvoiceBillingItem',
    PlanOptionInfo: 'PlanOptionInfo',
    TGroupMemberMap: 'TGroupMemberMap',
    PlanBillingTeamSubscription: 'PlanBillingTeamSubscription',
    PlanBillingPayInvoiceInfo: 'PlanBillingPayInvoiceInfo',
    PlanPayPricingSubscription: 'PlanPayPricingSubscription',
    PlanPayPricingBillingItem: 'PlanPayPricingBillingItem',
    TRegisterViewer: 'TRegisterViewer',
    NavigationViewer: 'NavigationViewer',
    NavigationTeamSubscription: 'NavigationTeamSubscription',
    NavigationTeamLicense: 'NavigationTeamLicense'
  }
}