import { useI18n } from '@plco-pro/hooks'

import { Box, Flex, Text } from '../../atoms'
import { FeedbackContentsType } from '../helpers'

interface PlcoSuggestionProps {
  feedbackContentsType?: FeedbackContentsType
  suggestion: string
}

/**
 * 플코의 제안
 * "피드백 등록" 과 "피드백 상세보기" 두군데서 사용하며 스타일이 조금씩 다름
 */
export const PlcoSuggestion = ({
  feedbackContentsType = 'add',
  suggestion,
}: PlcoSuggestionProps) => {
  const { formatMessage: f } = useI18n()

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        ...{
          add: {
            py: 3,
            borderTop: '1px solid',
            borderTopColor: 'grey-100',
          },
          detail: {
            border: '1px solid',
            borderColor: 'grey-100',
            borderRadius: '8px',
            p: 2,
          },
        }[feedbackContentsType],
      }}
    >
      <Text variant="h5" sx={{ color: 'grey-600', fontWeight: 700, mb: '12px' }}>
        {f({ id: 'feedback.plco-suggestion' })}
      </Text>

      <Flex sx={{ alignItems: 'flex-start', gap: 2 }}>
        <Text variant="p1">👉</Text>

        <Text variant="p1" sx={{ color: 'grey-500-72' }}>
          {suggestion}
        </Text>
      </Flex>
    </Box>
  )
}
