import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import { variant, VariantArgs } from 'styled-system'
import { ResponsiveStyleValue } from 'theme-ui'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { useResponsive } from '@plco-pro/hooks/responsive'

export type ChipVariant =
  | 'white'
  | 'primary'
  | 'grey'
  | 'red'
  | 'soft-red'
  | 'orange'
  | 'green'
  | 'violet'
export type ChipAppearance = 'filled' | 'outlined' | 'tonal' | 'ghost'
export type ChipSize = 'xs' | 'sm' | 'md' | 'lg'
export type ChipShape = 'round' | 'square'

export type ChipProps = Omit<FlexProps, 'size' | 'color' | 'shape'> & {
  variant?: ChipVariant
  appearance?: ChipAppearance
  size?: ResponsiveStyleValue<ChipSize>
  shape?: ResponsiveStyleValue<ChipShape>
  interactive?: boolean
  disabled?: boolean
}

export type StyledFlexProps = Omit<FlexProps, 'size' | 'shape'> & {
  size?: ChipSize
  shape?: ChipShape
}

const size = variant({
  prop: 'size',
  variants: {
    xs: {
      height: 24,
      fontSize: 'p1',
      padding: '0px 16px 2px 16px',
      minWidth: '40px',
    },
    sm: {
      height: 32,
      fontSize: 'p1',
      padding: '4px 16px 6px 16px',
      minWidth: '40px',
    },
    md: {
      height: 36,
      fontSize: 's2',
      padding: '5px 16px 7px 16px',
      minWidth: '48px',
    },
    lg: {
      height: 48,
      fontSize: 's1',
      padding: '5px 16px 7px 16px',
      minWidth: '64px',
    },
  },
} as VariantArgs)

const shape = variant({
  prop: 'shape',
  variants: {
    round: {
      borderRadius: 18,
    },
    square: {
      borderRadius: 4,
    },
  },
} as VariantArgs)

const StyledFlex = styled(Flex)<StyledFlexProps>(size, shape)

export const Chip: React.FunctionComponent<ChipProps> = ({
  variant = 'white',
  appearance = 'filled',
  size = 'md',
  interactive = false,
  disabled = false,
  shape = 'round',
  ...rest
}) => {
  const getResponsiveProp = useResponsive()

  const responsiveSize = useMemo(() => getResponsiveProp(size), [size, getResponsiveProp])
  const responsiveShape = useMemo(() => getResponsiveProp(shape), [shape, getResponsiveProp])

  const computedVariant = useMemo(() => {
    const interactivePart = interactive ? '-interactive' : ''

    if (disabled) {
      return 'chip.disabled'
    } else if (variant === 'white') {
      return `chip.white-outlined${interactivePart}`
    } else {
      return `chip.${variant}-${appearance}${interactivePart}`
    }
  }, [appearance, disabled, interactive, variant])

  return (
    <StyledFlex
      size={responsiveSize}
      shape={responsiveShape}
      variant={computedVariant}
      {...rest}
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        ...rest.sx,
      }}
    />
  )
}
