import { ChartSystemPropsDatasetItem } from '@plco-pro/maps/chart-system/chart-system.types'

export const getCautionTrainingAreaDataset = (
  xField = 'name',
  yFormat = (value: number) => value,
): ChartSystemPropsDatasetItem => ({
  type: 'BACKGROUND_AREA',
  color: 'caution-training-bg',
  parser: {
    x: {
      field: xField,
    },
    y: {
      value: yFormat(0.8),
    },
    y0: {
      value: yFormat(0),
    },
    label: {
      value: 'CAUTION_TRAINING',
      interpretationFormat: 'TRANSLATE',
    },
  },
  label: {
    labelComponent: 'LABEL_AREA_CENTER',
    config: {
      color: 'caution-training',
    },
  },
})

export const getBestTrainingAreaDataset = (
  xField = 'name',
  yFormat = (value: number) => value,
): ChartSystemPropsDatasetItem => ({
  type: 'BACKGROUND_AREA',
  color: 'best-training-bg',
  parser: {
    x: {
      field: xField,
    },
    y: {
      value: yFormat(1.31),
    },
    y0: {
      value: yFormat(0.8),
    },
    label: {
      value: 'BEST_TRAINING',
      interpretationFormat: 'TRANSLATE',
    },
  },
  label: {
    labelComponent: 'LABEL_AREA_CENTER',
    config: {
      color: 'best-training',
    },
  },
})

export const getWarningTraningAreaDataset = (
  xField = 'name',
  yFormat = (value: number) => value,
): ChartSystemPropsDatasetItem => ({
  type: 'BACKGROUND_AREA',
  color: 'warning-training-bg',
  parser: {
    x: {
      field: xField,
    },
    y: {
      value: yFormat(1.5),
    },
    y0: {
      value: yFormat(1.31),
    },
    label: {
      value: 'WARNING_TRAINING',
      interpretationFormat: 'TRANSLATE',
    },
  },
  label: {
    labelComponent: 'LABEL_AREA_CENTER',
    config: {
      color: 'warning-training',
    },
  },
})

export const getDangerTrainingAreaDataset = (
  xField = 'name',
  yFormat = (value: number) => value,
): ChartSystemPropsDatasetItem => ({
  type: 'BACKGROUND_AREA',
  color: 'danger-training-bg',
  parser: {
    x: {
      field: xField,
    },
    y: {
      value: yFormat(2.5),
    },
    y0: {
      value: yFormat(1.5),
    },
    label: {
      value: 'DANGER_TRAINING',
      interpretationFormat: 'TRANSLATE',
    },
  },
  label: {
    labelComponent: 'LABEL_AREA_CENTER',
    config: {
      color: 'danger-training',
    },
  },
})
