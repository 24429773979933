import { useMemo } from 'react'

import { Flex, Icon, Text } from '@plco-pro/components/atoms'
import { Avatar } from '@plco-pro/components/molecules'
import { Feedback, FeedbackType } from '@plco-pro/graphqls/react.generated'
import { useMappingFeedback } from '@plco-pro/hooks/use-mapping-feedback'
import { useMomentFormat } from '@plco-pro/hooks/use-moment-format'
import { WITHDRAWN_USER } from '@plco-pro/utils/constant'

interface FeedbackCardProps {
  feedbackData: Feedback
  onClick: () => void
}

export const FeedbackCard = ({ feedbackData, onClick }: FeedbackCardProps) => {
  const { formatFeedbackDate } = useMomentFormat()
  const { getFeedbackListMessage, getFeedbackListType } = useMappingFeedback()

  const isWithdrawnUser = useMemo(() => {
    return feedbackData.createUser.id === WITHDRAWN_USER
  }, [feedbackData])

  const user = useMemo(() => {
    return feedbackData.createUser.name
  }, [feedbackData])

  const date = useMemo(() => {
    return formatFeedbackDate(feedbackData.createdAt)
  }, [feedbackData, formatFeedbackDate])

  const commentCount = useMemo(() => {
    return (feedbackData.commentList || []).length >= 100 ? '99+' : feedbackData.commentList.length
  }, [feedbackData])

  const message = getFeedbackListMessage(feedbackData)
  const type = getFeedbackListType({
    type: feedbackData.type,
    contentsData: feedbackData.contentsData,
  })

  const createUser = useMemo(() => {
    return {
      pictureUrl: feedbackData.createUser.pictureUrl,
      name: feedbackData.createUser.name,
    }
  }, [feedbackData])

  const isRequestFeedback = useMemo(() => {
    return feedbackData.type === FeedbackType.REQUEST
  }, [feedbackData])

  return (
    <Flex
      sx={{
        gap: 1,
        p: 2,
        borderBottom: '1px solid',
        borderBottomColor: 'grey-100',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'grey-100',
        },
        '&:active': {
          backgroundColor: 'grey-200',
        },
        '&:last-of-type': {
          borderBottom: '0',
        },
      }}
      onClick={onClick}
    >
      <Avatar
        src={createUser.pictureUrl}
        value={createUser.name}
        size={24}
        isWithdrawn={isWithdrawnUser}
      />

      <Flex sx={{ flexDirection: 'column', flex: 1 }}>
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mb: '2px', gap: 1 }}>
          <Text
            variant="p1"
            ellipsis={true}
            sx={{ color: 'text-primary', fontWeight: 700, flex: 1 }}
          >
            {user}
          </Text>

          <Text variant="p2" sx={{ color: 'text-secondary' }}>
            {date}
          </Text>
        </Flex>

        <Flex sx={{ alignItems: 'center', mb: 1 }}>
          <Text
            variant="p2"
            sx={{
              fontWeight: 700,
              color: isRequestFeedback ? 'sky-500' : 'text-secondary',
            }}
          >
            {type}
          </Text>

          <Text variant="p2" sx={{ color: 'text-secondary', fontWeight: 700, mx: '2px' }}>
            ·
          </Text>

          <Icon src="/images/ic_chat_bubble_made.svg" sx={{ mr: '2px' }} />

          <Text variant="p2" sx={{ color: 'text-secondary', fontWeight: 700 }}>
            {commentCount}
          </Text>
        </Flex>

        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Text variant="p1" sx={{ color: 'text-primary', flex: 1 }} ellipsis={true}>
            {message}
          </Text>

          <Icon src="/images/ic_keyboard_arrow_right.svg" size={16} color="text-secondary" />
        </Flex>
      </Flex>
    </Flex>
  )
}
