// server error messages
// path override order example
// 1. ERROR.CONFLICT.sports.createTeam
// 2. ERROR.CONFLICT

// validation error messages
// path override order
// 1. VALIDATION_ERROR.sports.createTeam.username.stringAlphadash
// 2. VALIDATION_ERROR.sports.createTeam.username
// 2. VALIDATION_ERROR

export const error = {
  en: {
    // BAD_REQUEST: 400
    'ERROR.BAD_REQUEST.sports.joinTeam': 'Team has already been joined. Please try again.',
    'ERROR.BAD_REQUEST.restartSubscription.PAYMENT_NO_BALANCE':
      'Payment failed due to insufficient funds. Please change your payment card and try again.',
    'ERROR.BAD_REQUEST.restartSubscription.PAYMENT_FAIL': 'Payment failed. Please try again.',
    'ERROR.BAD_REQUEST.startSubscription.PAYMENT_NO_BALANCE':
      'Payment failed due to insufficient funds. Please change your payment card and try again.',
    'ERROR.BAD_REQUEST.startSubscription.PAYMENT_FAIL': 'Payment failed. Please try again.',

    // UNAUTHORIZED: 401

    // PAYMENT_REQUIRED: 402

    // FORBIDDEN: 403
    'ERROR.FORBIDDEN.sports.joinTeam':
      'The team already exists. You can only have 1 team. Please use it after refreshing.',
    'ERROR.FORBIDDEN.sports.createTeam':
      'The team already exists. You can only have 1 team. Please use it after refreshing.',
    'ERROR.FORBIDDEN.sports.updateTeamUserMap': 'Forbidden authorization to update authorization.',

    // NOT_FOUND: 404
    'ERROR.NOT_FOUND.sports.joinTeam': 'The team code is invalid. try again.',
    'ERROR.NOT_FOUND.sports.updateTeamGroup': 'The group no longer exists.',
    'ERROR.NOT_FOUND.sports.deleteTeamGroup': 'The group no longer exists.',

    // METHOD_NOT_ALLOWED: 405

    // NOT_ACCEPTABLE: 406

    // PROXY_AUTHENTICATION_REQUIRED: 407

    // REQUEST_TIMEOUT: 408

    // CONFLICT: 409
    'ERROR.CONFLICT.sports.createTeam': 'Duplicated Resource.',
    'ERROR.CONFLICT.sports.createTeamGroup':
      'Group name already exists. Please use a different name.',
    'ERROR.CONFLICT.sports.updateTeamGroup':
      'Group name already exists. Please use a different name.',
    'ERROR.CONFLICT.sports.joinTeam': 'Waiting for acceptance. Please wait for approval.',
    'ERROR.CONFLICT.sports.joinTeam.max-invitation': 'Too many waiting to accept.',
    'ERROR.NO_PERMISSION': 'You do not have the authority',
    'ERROR.TEAM_USER_MAP_NOT_FOUND': 'No longer in this team',

    // GONE: 410

    // LENGTH_REQUIRED: 411

    // PRECONDITION_FAILED: 412

    // PAYLOAD_TOO_LARGE: 413

    // URI_TOO_LONG: 414

    // UNSUPPORTED_MEDIA_TYPE: 415

    // RANGE_NOT_SATISFIABLE: 416

    // EXPECTATION_FAILED: 417

    // IM_A_TEAPOT: 418

    // MISDIRECTED_REQUEST: 421

    // UNPROCESSABLE_ENTITY: 422

    // LOCKED: 423

    // FAILED_DEPENDENCY: 424

    // TOO_EARLY: 425

    // UPGRADE_REQUIRED: 426

    // PRECONDITION_REQUIRED: 428

    // TOO_MANY_REQUESTS: 429

    // REQUEST_HEADER_FIELDS_TOO_LARGE: 431

    // UNAVAILABLE_FOR_LEGAL_REASONS: 451

    // INTERNAL_SERVER_ERROR: 500
    'ERROR.INTERNAL_SERVER_ERROR.sports.createTeam': 'The team URL is a duplicate.',

    // NOT SERVER ERROR
    'ERROR.viewer.id': 'Failed to get user information. Please try again later',

    // TEAM REQUEST ERROR
    'ERROR.TEAM_INVALID_CODE': 'Team not found with given code.',
    'ERROR.TEAM_EXISTING_USER': 'You are already in the team.',
    'ERROR.TEAM_SERVER_ERROR': 'A temporary error has occurred. please try again.',
  },
  ko: {
    // BAD_REQUEST: 400
    'ERROR.BAD_REQUEST.sports.joinTeam': '이미 소속된 팀입니다. 다시 시도해 주세요.',
    'ERROR.BAD_REQUEST.restartSubscription.PAYMENT_NO_BALANCE':
      '카드 잔액부족으로 결제를 실패하였습니다. 결제 카드 변경후 다시 시도해주세요.',
    'ERROR.BAD_REQUEST.restartSubscription.PAYMENT_FAIL':
      '결제를 실패하였습니다. 다시 시도해주세요.',
    'ERROR.BAD_REQUEST.startSubscription.PAYMENT_NO_BALANCE':
      '카드 잔액부족으로 결제를 실패하였습니다. 결제 카드 변경후 다시 시도해주세요.',
    'ERROR.BAD_REQUEST.startSubscription.PAYMENT_FAIL': '결제를 실패하였습니다. 다시 시도해주세요.',

    // UNAUTHORIZED: 401

    // PAYMENT_REQUIRED: 402

    // FORBIDDEN: 403
    'ERROR.FORBIDDEN.sports.joinTeam':
      '이미 팀이 존재합니다. (1개의 팀만 가질수 있습니다 새로고침 후 이용해주세요).',
    'ERROR.FORBIDDEN.sports.createTeam':
      '이미 팀이 존재합니다. (1개의 팀만 가질수 있습니다 새로고침 후 이용해주세요).',
    'ERROR.FORBIDDEN.sports.updateTeamUserMap': '권한을 수정할 권한이 부족합니다.',

    // NOT_FOUND: 404
    'ERROR.NOT_FOUND.sports.joinTeam': '팀코드가 유효하지 않습니다. 다시 입력해 주세요.',
    'ERROR.NOT_FOUND.sports.updateTeamGroup': '해당 그룹이 더이상 존재하지 않습니다.',
    'ERROR.NOT_FOUND.sports.deleteTeamGroup': '해당 그룹이 더이상 존재하지 않습니다.',

    // METHOD_NOT_ALLOWED: 405

    // NOT_ACCEPTABLE: 406

    // PROXY_AUTHENTICATION_REQUIRED: 407

    // REQUEST_TIMEOUT: 408

    // CONFLICT: 409
    'ERROR.CONFLICT.sports.createTeam': '팀이 존재합니다?',
    'ERROR.CONFLICT.sports.createTeamGroup': '이미 존재하는 이름입니다. 다른 이름을 사용하세요.',
    'ERROR.CONFLICT.sports.updateTeamGroup': '이미 존재하는 이름입니다. 다른 이름을 사용하세요.',
    'ERROR.CONFLICT.sports.joinTeam': '수락 대기 중 상태입니다. 승인 여부를 기다려주세요.',
    'ERROR.CONFLICT.sports.joinTeam.max-invitation': '수락 대기 중이 너무 많습니다.',
    'ERROR.NO_PERMISSION': '권한이 없습니다',
    'ERROR.TEAM_USER_MAP_NOT_FOUND': '더 이상 이 팀에 소속되어 있지 않습니다',

    // GONE: 410

    // LENGTH_REQUIRED: 411

    // PRECONDITION_FAILED: 412

    // PAYLOAD_TOO_LARGE: 413

    // URI_TOO_LONG: 414

    // UNSUPPORTED_MEDIA_TYPE: 415

    // RANGE_NOT_SATISFIABLE: 416

    // EXPECTATION_FAILED: 417

    // IM_A_TEAPOT: 418

    // MISDIRECTED_REQUEST: 421

    // UNPROCESSABLE_ENTITY: 422

    // LOCKED: 423

    // FAILED_DEPENDENCY: 424

    // TOO_EARLY: 425

    // UPGRADE_REQUIRED: 426

    // PRECONDITION_REQUIRED: 428

    // TOO_MANY_REQUESTS: 429

    // REQUEST_HEADER_FIELDS_TOO_LARGE: 431

    // UNAVAILABLE_FOR_LEGAL_REASONS: 451

    // INTERNAL_SERVER_ERROR: 500
    'ERROR.INTERNAL_SERVER_ERROR.sports.createTeam': '팀 URL 이 중복됩니다.',

    // NOT SERVER ERROR
    'ERROR.viewer.id': '유저 정보를 가지고오는데 실패했습니다. 잠시후에 다시 시도해주세요',

    // TEAM REQUEST ERROR
    'ERROR.TEAM_INVALID_CODE': '팀코드를 찾을 수 없습니다. 다시 확인해 주세요.',
    'ERROR.TEAM_EXISTING_USER': '이미 팀에 가입되어 있습니다.',
    'ERROR.TEAM_SERVER_ERROR': '일시적 장애가 발생했습니다. 다시 시도해 주세요.',
  },
  ja: {
    // BAD_REQUEST: 400
    'ERROR.BAD_REQUEST.sports.joinTeam': 'すでに所属しているチームです。 もう一度お試しください', // "이미 소속된 팀입니다. 다시 시도해 주세요.",
    'ERROR.BAD_REQUEST.restartSubscription.PAYMENT_NO_BALANCE':
      'カード残高不足で支払いに失敗しました。 決済カードを変更した後、もう一度お試しください', // "카드 잔액부족으로 결제를 실패하였습니다. 결제 카드 변경후 다시 시도해주세요.",
    'ERROR.BAD_REQUEST.restartSubscription.PAYMENT_FAIL':
      '支払いに失敗しました.もう一度お試しください.', // "결제를 실패하였습니다. 다시 시도해주세요.",
    'ERROR.BAD_REQUEST.startSubscription.PAYMENT_NO_BALANCE':
      'カード残高不足で支払いに失敗しました.決済カードを変更した後、もう一度お試しください.', // "카드 잔액부족으로 결제를 실패하였습니다. 결제 카드 변경후 다시 시도해주세요.",
    'ERROR.BAD_REQUEST.startSubscription.PAYMENT_FAIL':
      '支払いに失敗しました.もう一度お試しくださ.', // "결제를 실패하였습니다. 다시 시도해주세요.",

    // UNAUTHORIZED: 401

    // PAYMENT_REQUIRED: 402

    // FORBIDDEN: 403
    'ERROR.FORBIDDEN.sports.joinTeam':
      'すでにチームが存在します.（1つのチームしか持てません. リロードしてからご利用ください.）', // "이미 팀이 존재합니다. (1개의 팀만 가질수 있습니다 새로고침 후 이용해주세요).",
    'ERROR.FORBIDDEN.sports.createTeam':
      'すでにチームが存在します.（1つのチームしか持てません。 リロードしてからご利用ください.）', // "이미 팀이 존재합니다. (1개의 팀만 가질수 있습니다 새로고침 후 이용해주세요).",
    'ERROR.FORBIDDEN.sports.updateTeamUserMap': '権限を修正する権限が不足しています.', // "권한을 수정할 권한이 부족합니다.",

    // NOT_FOUND: 404
    'ERROR.NOT_FOUND.sports.joinTeam': 'チームコードが無効です.もう一度入力してください.', // "팀코드가 유효하지 않습니다. 다시 입력해 주세요.",
    'ERROR.NOT_FOUND.sports.updateTeamGroup': 'そのグループはもう存在しません.', // "해당 그룹이 더이상 존재하지 않습니다.",
    'ERROR.NOT_FOUND.sports.deleteTeamGroup': 'そのグループはもう存在しません.', // "해당 그룹이 더이상 존재하지 않습니다.",

    // METHOD_NOT_ALLOWED: 405

    // NOT_ACCEPTABLE: 406

    // PROXY_AUTHENTICATION_REQUIRED: 407

    // REQUEST_TIMEOUT: 408

    // CONFLICT: 409
    'ERROR.CONFLICT.sports.createTeam': 'チームが存在します.', // "팀이 존재합니다.",
    'ERROR.CONFLICT.sports.createTeamGroup': '既に存在する名前です.別の名前を使用してください.', // "이미 존재하는 이름입니다. 다른 이름을 사용하세요.",
    'ERROR.CONFLICT.sports.updateTeamGroup': '既に存在する名前です.別の名前を使用してください.', // "이미 존재하는 이름입니다. 다른 이름을 사용하세요.",
    'ERROR.CONFLICT.sports.joinTeam': '受け入れ待機中の状態です.承認するかどうかをお待ちください.', // "수락 대기 중 상태입니다. 승인 여부를 기다려주세요.",
    'ERROR.CONFLICT.sports.joinTeam.max-invitation': '受諾待機中が多すぎます.', // "수락 대기 중이 너무 많습니다.",
    'ERROR.NO_PERMISSION': '権限がありません', // '권한이 없습니다',
    'ERROR.TEAM_USER_MAP_NOT_FOUND': 'もうこのチームに所属していません', // '더 이상 이 팀에 소속되어 있지 않습니다',

    // GONE: 410

    // LENGTH_REQUIRED: 411

    // PRECONDITION_FAILED: 412

    // PAYLOAD_TOO_LARGE: 413

    // URI_TOO_LONG: 414

    // UNSUPPORTED_MEDIA_TYPE: 415

    // RANGE_NOT_SATISFIABLE: 416

    // EXPECTATION_FAILED: 417

    // IM_A_TEAPOT: 418

    // MISDIRECTED_REQUEST: 421

    // UNPROCESSABLE_ENTITY: 422

    // LOCKED: 423

    // FAILED_DEPENDENCY: 424

    // TOO_EARLY: 425

    // UPGRADE_REQUIRED: 426

    // PRECONDITION_REQUIRED: 428

    // TOO_MANY_REQUESTS: 429

    // REQUEST_HEADER_FIELDS_TOO_LARGE: 431

    // UNAVAILABLE_FOR_LEGAL_REASONS: 451

    // INTERNAL_SERVER_ERROR: 500
    'ERROR.INTERNAL_SERVER_ERROR.sports.createTeam': 'チームURLが重複します.', // "팀 URL 이 중복됩니다.",

    // NOT SERVER ERROR
    'ERROR.viewer.id': 'ユーザー情報の取得に失敗しました。 後ほどもう一度お試しください.', // "유저 정보를 가지고오는데 실패했습니다. 잠시후에 다시 시도해주세요",

    // TEAM REQUEST ERROR
    'ERROR.TEAM_INVALID_CODE': 'チームコードが見つかりません.もう一度確認をお願いしま.', // "팀코드를 찾을 수 없습니다. 다시 확인해 주세요.",
    'ERROR.TEAM_EXISTING_USER': 'すでにチームに加入しています.', // "이미 팀에 가입되어 있습니다.",
    'ERROR.TEAM_SERVER_ERROR': '一時的な障害が発生しました.もう一度お試しください.', // "일시적 장애가 발생했습니다. 다시 시도해 주세요.",

    // CUSTOM ERROR (in front
    'ERROR.FEATURE.DISABLED': '今機能は利用できません。しばらくお待ちください！',
  },
}
