/* eslint-disable */
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  DateTimeString: any;
  JSON: { [key: string]: any };
  StringOrFloat: any;
  Upload: never;
};

export type ActionList = {
  __typename?: 'ActionList';
  clickTime: Scalars['Date'];
};

export type AddBannerLogInput = {
  bannerId: Scalars['ID'];
  timezone: Scalars['String'];
};

export type AddOrgLicenseTeamInput = {
  licenseId: Scalars['ID'];
  teamIdList: Array<Scalars['ID']>;
};

export type AddProLicenseTeamInput = {
  licenseId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type Alarm = {
  createUser: SportsUser;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  player: SportsUser;
  team?: Maybe<SportsTeam>;
  type: AlarmType;
};

export type AlarmConnection = {
  __typename?: 'AlarmConnection';
  edges: Array<AlarmEdge>;
  pageInfo: PageInfo;
};

export enum AlarmDataType {
  ACXR = 'ACXR',
  CONDITION = 'CONDITION',
  INJURY = 'INJURY',
  SORENESS = 'SORENESS',
  SORENESS_LEVEL5 = 'SORENESS_LEVEL5'
}

export type AlarmEdge = {
  __typename?: 'AlarmEdge';
  cursor: Scalars['String'];
  node: Alarm;
};

export enum AlarmType {
  COMMENT_CREATED = 'COMMENT_CREATED',
  FEEDBACK_CREATED = 'FEEDBACK_CREATED',
  FEEDBACK_REQUESTED = 'FEEDBACK_REQUESTED',
  SPORTS_DATA_ACXR = 'SPORTS_DATA_ACXR',
  SPORTS_DATA_CONDITION = 'SPORTS_DATA_CONDITION',
  SPORTS_DATA_EVENT_WORKLOAD_UPDATED = 'SPORTS_DATA_EVENT_WORKLOAD_UPDATED',
  SPORTS_DATA_INJURY = 'SPORTS_DATA_INJURY',
  SPORTS_DATA_SORENESS = 'SPORTS_DATA_SORENESS',
  SPORTS_DATA_SORENESS_LEVEL5 = 'SPORTS_DATA_SORENESS_LEVEL5'
}

export type AppendSorenessListInput = {
  sorenessList: Array<SorenessInput>;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type ApplyCouponInput = {
  couponId: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type AttendeeChartAvg = ChartCondition | ChartWorkload;

export type AttendeeChartData = {
  __typename?: 'AttendeeChartData';
  avg: AttendeeChartAvg;
  userList: Array<AttendeeChartUser>;
};

export enum AttendeeChartDataType {
  CONDITION = 'CONDITION',
  WORKLOAD = 'WORKLOAD'
}

export type AttendeeChartUser = {
  __typename?: 'AttendeeChartUser';
  user: SportsUser;
  value: AttendeeChartValue;
};

export type AttendeeChartValue = ChartUserCondition | ChartUserWorkload;

export type Banner = {
  __typename?: 'Banner';
  clickUserList: BannerLogResponse;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  landingUrl?: Maybe<Scalars['String']>;
  targetOptionList?: Maybe<Array<Maybe<TargetOption>>>;
  title: Scalars['String'];
};


export type BannerClickUserListArgs = {
  date: Scalars['Date'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
};

export type BannerLogResponse = {
  __typename?: 'BannerLogResponse';
  clickUserList: Array<Maybe<ClickUser>>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export enum BannerOption {
  AGE = 'AGE',
  GENDER = 'GENDER',
  LANGUAGE = 'LANGUAGE',
  TEAM = 'TEAM'
}

export enum BannerOptionOperator {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  NE = 'NE'
}

export type BasicEvent = Event & {
  __typename?: 'BasicEvent';
  endAt: Scalars['Date'];
  eventInvitees: Array<EventInvitee>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: EventType;
};

export type BasicEventInvitee = EventInvitee & {
  __typename?: 'BasicEventInvitee';
  event: Event;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  status?: Maybe<EventInviteeStatus>;
  /** the player who is invited to this event */
  user?: Maybe<SportsUser>;
  workoutType?: Maybe<WorkoutType>;
};


export type BasicEventInviteeEventArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export type Billing = {
  __typename?: 'Billing';
  buyerEmail?: Maybe<Scalars['String']>;
  buyerName?: Maybe<Scalars['String']>;
  buyerTel?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  customerUid: Scalars['String'];
  id: Scalars['ID'];
  identityToken: Scalars['String'];
};

export type BillingToken = {
  __typename?: 'BillingToken';
  customerUid: Scalars['String'];
  paymentToken: Scalars['String'];
};

export type BodyData = {
  __typename?: 'BodyData';
  BMI?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<HeightUnit>;
  id?: Maybe<Scalars['ID']>;
  recommendedHydration?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
};

export type CancelSubscriptionInput = {
  subscriptionId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type ChangeCouponInput = {
  couponId: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type ChangeSubscriptionDateInput = {
  endAt: Scalars['DateTime'];
  startAt?: Maybe<Scalars['DateTime']>;
  subscriptionId: Scalars['ID'];
};

export type ChartAvgData = ChartBody | ChartCondition | ChartSoreness | ChartWorkload | ChartWorkloadAcxrAxd;

export type ChartBody = {
  __typename?: 'ChartBody';
  height: Scalars['Float'];
  weight: Scalars['Float'];
};

export type ChartCondition = {
  __typename?: 'ChartCondition';
  condition: Scalars['Float'];
  duration: Scalars['Float'];
  fatigue: Scalars['Float'];
  mood: Scalars['Float'];
  muscle: Scalars['Float'];
  quality: Scalars['Float'];
  stress: Scalars['Float'];
};

export type ChartData = {
  __typename?: 'ChartData';
  avg: ChartAvgData;
  userList: Array<ChartUser>;
};

export enum ChartDataName {
  BODY = 'BODY',
  CONDITION = 'CONDITION',
  CONDITION_7DAY = 'CONDITION_7DAY',
  CONDITION_28DAY = 'CONDITION_28DAY',
  SORENESS = 'SORENESS',
  WORKLOAD = 'WORKLOAD',
  WORKLOAD_7DAY = 'WORKLOAD_7DAY',
  WORKLOAD_28DAY = 'WORKLOAD_28DAY',
  WORKLOAD_ACXR = 'WORKLOAD_ACXR'
}

export type ChartSoreness = {
  __typename?: 'ChartSoreness';
  level1: SorenessLevelData;
  level2: SorenessLevelData;
  level3: SorenessLevelData;
  level4: SorenessLevelData;
  level5: SorenessLevelData;
};

export type ChartUser = {
  __typename?: 'ChartUser';
  user: SportsUser;
  value: ChartUserData;
};

export type ChartUserCondition = {
  __typename?: 'ChartUserCondition';
  condition?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fatigue?: Maybe<Scalars['Float']>;
  mood?: Maybe<Scalars['Float']>;
  muscle?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
  stress?: Maybe<Scalars['Float']>;
};

export type ChartUserData = ChartBody | ChartSoreness | ChartUserCondition | ChartUserWorkload | ChartWorkloadAcxrAxd;

export type ChartUserWorkload = {
  __typename?: 'ChartUserWorkload';
  /** 당일 운동시간의 합 */
  duration?: Maybe<Scalars['Float']>;
  /** 첫번째 운동부하의 아이디 */
  id?: Maybe<Scalars['ID']>;
  /** 당일 운동강도의 평균 */
  intensity?: Maybe<Scalars['Float']>;
  reasonForNoWorkout?: Maybe<NoWorkoutType>;
  /** 당일 운동만족도의 평균 */
  satisfaction?: Maybe<Scalars['Float']>;
  /** 당일 운동부하의 합 */
  workload?: Maybe<Scalars['Float']>;
  workloadList: Array<Maybe<Workload>>;
};

export type ChartWorkload = {
  __typename?: 'ChartWorkload';
  duration: Scalars['Float'];
  intensity: Scalars['Float'];
  satisfaction: Scalars['Float'];
  workload: Scalars['Float'];
};

export type ChartWorkloadAcxrAxd = {
  __typename?: 'ChartWorkloadAcxrAxd';
  acxr: Scalars['Float'];
  ax: Scalars['Float'];
  axd: Scalars['Float'];
  cx: Scalars['Float'];
};

export type CheckEventInviteReminderPushInput = {
  coachName: Scalars['String'];
  eventId: Scalars['ID'];
  userIdList?: Maybe<Array<Scalars['ID']>>;
};

export type CheckIsGivenTeamsInput = {
  teamIdList: Array<Scalars['ID']>;
};

export type CheckReminderPushInput = {
  coachName: Scalars['String'];
  teamId: Scalars['String'];
  timezone: Scalars['String'];
  userIdList?: Maybe<Array<Scalars['ID']>>;
};

export type CheckedData = {
  __typename?: 'CheckedData';
  condition: CheckedOrNotData;
  date: Scalars['String'];
  workload: CheckedOrNotData;
};

export type CheckedDetailData = {
  __typename?: 'CheckedDetailData';
  playerList: Array<SportsUser>;
  total: Scalars['Float'];
};

export type CheckedOrNotData = {
  __typename?: 'CheckedOrNotData';
  checked: CheckedDetailData;
  unChecked: CheckedDetailData;
};

export type ClickUser = {
  __typename?: 'ClickUser';
  actionList: Array<ActionList>;
  user: SportsUser;
};

export type CoachAlarm = {
  __typename?: 'CoachAlarm';
  coach: SportsUser;
  createdAt: Scalars['DateTime'];
  dataType: AlarmDataType;
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  isSentToPlayer: Scalars['Boolean'];
  player: SportsUser;
  team: SportsTeam;
};

export type CoachAlarmConnection = {
  __typename?: 'CoachAlarmConnection';
  edges: Array<CoachAlarmEdge>;
  pageInfo: PageInfo;
};

export type CoachAlarmEdge = {
  __typename?: 'CoachAlarmEdge';
  cursor: Scalars['String'];
  node: CoachAlarm;
};

export type Condition = {
  __typename?: 'Condition';
  ac: Scalars['Float'];
  cc: Scalars['Float'];
  fatigue: Scalars['Float'];
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  mood: Scalars['Float'];
  muscle: Scalars['Float'];
  readiness: Scalars['Float'];
  score: Scalars['Float'];
  sleepDuration: Scalars['Float'];
  sleepQuality: Scalars['Float'];
  sleepTime: Scalars['DateTimeString'];
  stress: Scalars['Float'];
};

export type ConditionCareUser = {
  __typename?: 'ConditionCareUser';
  dataList: Array<UserConditionDataType>;
  user: SportsUser;
};

export type ConditionCareUserList = {
  __typename?: 'ConditionCareUserList';
  notGoodUserList: Array<ConditionCareUser>;
  plummetUserList: Array<ConditionCareUser>;
  total: Scalars['Int'];
};

export type ConditionReportData = {
  __typename?: 'ConditionReportData';
  recoverConditionUserList: Array<ConditionUser>;
  recoverSorenessUserList: Array<SorenessUser>;
  severeConditionUserList: Array<ConditionUser>;
  severeSorenessUserList: Array<SorenessUser>;
};

export type ConditionReportFatigueData = {
  __typename?: 'ConditionReportFatigueData';
  fatigue: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportMoodData = {
  __typename?: 'ConditionReportMoodData';
  mood: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportMuscleData = {
  __typename?: 'ConditionReportMuscleData';
  muscle: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportSleepDurationData = {
  __typename?: 'ConditionReportSleepDurationData';
  sleepDuration: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportSleepQualityData = {
  __typename?: 'ConditionReportSleepQualityData';
  sleepQuality: Scalars['Float'];
  streak?: Maybe<Scalars['Float']>;
};

export type ConditionReportStressData = {
  __typename?: 'ConditionReportStressData';
  streak?: Maybe<Scalars['Float']>;
  stress: Scalars['Float'];
};

export type ConditionStreakData = ConditionReportFatigueData | ConditionReportMoodData | ConditionReportMuscleData | ConditionReportSleepDurationData | ConditionReportSleepQualityData | ConditionReportStressData;

export type ConditionUser = {
  __typename?: 'ConditionUser';
  conditionList: Array<ConditionStreakData>;
  user: SportsUser;
};

export enum Contact {
  CONTACT = 'CONTACT',
  NON_CONTACT = 'NON_CONTACT',
  NO_SORENESS = 'NO_SORENESS',
  UNKNOWN = 'UNKNOWN'
}

export type Country = {
  __typename?: 'Country';
  defaultLanguage?: Maybe<Scalars['String']>;
  defaultUTCOffset?: Maybe<Scalars['Int']>;
  id?: Maybe<CountryCode>;
};

export enum CountryCode {
  all_country_code__ad = 'all_country_code__ad',
  all_country_code__ae = 'all_country_code__ae',
  all_country_code__af = 'all_country_code__af',
  all_country_code__ag = 'all_country_code__ag',
  all_country_code__ai = 'all_country_code__ai',
  all_country_code__al = 'all_country_code__al',
  all_country_code__am = 'all_country_code__am',
  all_country_code__ao = 'all_country_code__ao',
  all_country_code__aq = 'all_country_code__aq',
  all_country_code__ar = 'all_country_code__ar',
  all_country_code__as = 'all_country_code__as',
  all_country_code__at = 'all_country_code__at',
  all_country_code__au = 'all_country_code__au',
  all_country_code__aw = 'all_country_code__aw',
  all_country_code__ax = 'all_country_code__ax',
  all_country_code__az = 'all_country_code__az',
  all_country_code__ba = 'all_country_code__ba',
  all_country_code__bb = 'all_country_code__bb',
  all_country_code__bd = 'all_country_code__bd',
  all_country_code__be = 'all_country_code__be',
  all_country_code__bf = 'all_country_code__bf',
  all_country_code__bg = 'all_country_code__bg',
  all_country_code__bh = 'all_country_code__bh',
  all_country_code__bi = 'all_country_code__bi',
  all_country_code__bj = 'all_country_code__bj',
  all_country_code__bl = 'all_country_code__bl',
  all_country_code__bm = 'all_country_code__bm',
  all_country_code__bn = 'all_country_code__bn',
  all_country_code__bo = 'all_country_code__bo',
  all_country_code__bq = 'all_country_code__bq',
  all_country_code__br = 'all_country_code__br',
  all_country_code__bs = 'all_country_code__bs',
  all_country_code__bt = 'all_country_code__bt',
  all_country_code__bv = 'all_country_code__bv',
  all_country_code__bw = 'all_country_code__bw',
  all_country_code__by = 'all_country_code__by',
  all_country_code__bz = 'all_country_code__bz',
  all_country_code__ca = 'all_country_code__ca',
  all_country_code__cc = 'all_country_code__cc',
  all_country_code__cd = 'all_country_code__cd',
  all_country_code__cf = 'all_country_code__cf',
  all_country_code__cg = 'all_country_code__cg',
  all_country_code__ch = 'all_country_code__ch',
  all_country_code__ci = 'all_country_code__ci',
  all_country_code__ck = 'all_country_code__ck',
  all_country_code__cl = 'all_country_code__cl',
  all_country_code__cm = 'all_country_code__cm',
  all_country_code__cn = 'all_country_code__cn',
  all_country_code__co = 'all_country_code__co',
  all_country_code__cr = 'all_country_code__cr',
  all_country_code__cu = 'all_country_code__cu',
  all_country_code__cv = 'all_country_code__cv',
  all_country_code__cw = 'all_country_code__cw',
  all_country_code__cx = 'all_country_code__cx',
  all_country_code__cy = 'all_country_code__cy',
  all_country_code__cz = 'all_country_code__cz',
  all_country_code__de = 'all_country_code__de',
  all_country_code__dj = 'all_country_code__dj',
  all_country_code__dk = 'all_country_code__dk',
  all_country_code__dm = 'all_country_code__dm',
  all_country_code__do = 'all_country_code__do',
  all_country_code__dz = 'all_country_code__dz',
  all_country_code__ec = 'all_country_code__ec',
  all_country_code__ee = 'all_country_code__ee',
  all_country_code__eg = 'all_country_code__eg',
  all_country_code__eh = 'all_country_code__eh',
  all_country_code__er = 'all_country_code__er',
  all_country_code__es = 'all_country_code__es',
  all_country_code__et = 'all_country_code__et',
  all_country_code__fi = 'all_country_code__fi',
  all_country_code__fj = 'all_country_code__fj',
  all_country_code__fk = 'all_country_code__fk',
  all_country_code__fm = 'all_country_code__fm',
  all_country_code__fo = 'all_country_code__fo',
  all_country_code__fr = 'all_country_code__fr',
  all_country_code__ga = 'all_country_code__ga',
  all_country_code__gb = 'all_country_code__gb',
  all_country_code__gd = 'all_country_code__gd',
  all_country_code__ge = 'all_country_code__ge',
  all_country_code__gf = 'all_country_code__gf',
  all_country_code__gg = 'all_country_code__gg',
  all_country_code__gh = 'all_country_code__gh',
  all_country_code__gi = 'all_country_code__gi',
  all_country_code__gl = 'all_country_code__gl',
  all_country_code__gm = 'all_country_code__gm',
  all_country_code__gn = 'all_country_code__gn',
  all_country_code__gp = 'all_country_code__gp',
  all_country_code__gq = 'all_country_code__gq',
  all_country_code__gr = 'all_country_code__gr',
  all_country_code__gs = 'all_country_code__gs',
  all_country_code__gt = 'all_country_code__gt',
  all_country_code__gu = 'all_country_code__gu',
  all_country_code__gw = 'all_country_code__gw',
  all_country_code__gy = 'all_country_code__gy',
  all_country_code__hk = 'all_country_code__hk',
  all_country_code__hm = 'all_country_code__hm',
  all_country_code__hn = 'all_country_code__hn',
  all_country_code__hr = 'all_country_code__hr',
  all_country_code__ht = 'all_country_code__ht',
  all_country_code__hu = 'all_country_code__hu',
  all_country_code__id = 'all_country_code__id',
  all_country_code__ie = 'all_country_code__ie',
  all_country_code__il = 'all_country_code__il',
  all_country_code__im = 'all_country_code__im',
  all_country_code__in = 'all_country_code__in',
  all_country_code__io = 'all_country_code__io',
  all_country_code__iq = 'all_country_code__iq',
  all_country_code__ir = 'all_country_code__ir',
  all_country_code__is = 'all_country_code__is',
  all_country_code__it = 'all_country_code__it',
  all_country_code__je = 'all_country_code__je',
  all_country_code__jm = 'all_country_code__jm',
  all_country_code__jo = 'all_country_code__jo',
  all_country_code__jp = 'all_country_code__jp',
  all_country_code__ke = 'all_country_code__ke',
  all_country_code__kg = 'all_country_code__kg',
  all_country_code__kh = 'all_country_code__kh',
  all_country_code__ki = 'all_country_code__ki',
  all_country_code__km = 'all_country_code__km',
  all_country_code__kn = 'all_country_code__kn',
  all_country_code__kp = 'all_country_code__kp',
  all_country_code__kr = 'all_country_code__kr',
  all_country_code__kw = 'all_country_code__kw',
  all_country_code__ky = 'all_country_code__ky',
  all_country_code__kz = 'all_country_code__kz',
  all_country_code__la = 'all_country_code__la',
  all_country_code__lb = 'all_country_code__lb',
  all_country_code__lc = 'all_country_code__lc',
  all_country_code__li = 'all_country_code__li',
  all_country_code__lk = 'all_country_code__lk',
  all_country_code__lr = 'all_country_code__lr',
  all_country_code__ls = 'all_country_code__ls',
  all_country_code__lt = 'all_country_code__lt',
  all_country_code__lu = 'all_country_code__lu',
  all_country_code__lv = 'all_country_code__lv',
  all_country_code__ly = 'all_country_code__ly',
  all_country_code__ma = 'all_country_code__ma',
  all_country_code__mc = 'all_country_code__mc',
  all_country_code__md = 'all_country_code__md',
  all_country_code__me = 'all_country_code__me',
  all_country_code__mf = 'all_country_code__mf',
  all_country_code__mg = 'all_country_code__mg',
  all_country_code__mh = 'all_country_code__mh',
  all_country_code__mk = 'all_country_code__mk',
  all_country_code__ml = 'all_country_code__ml',
  all_country_code__mm = 'all_country_code__mm',
  all_country_code__mn = 'all_country_code__mn',
  all_country_code__mo = 'all_country_code__mo',
  all_country_code__mp = 'all_country_code__mp',
  all_country_code__mq = 'all_country_code__mq',
  all_country_code__mr = 'all_country_code__mr',
  all_country_code__ms = 'all_country_code__ms',
  all_country_code__mt = 'all_country_code__mt',
  all_country_code__mu = 'all_country_code__mu',
  all_country_code__mv = 'all_country_code__mv',
  all_country_code__mw = 'all_country_code__mw',
  all_country_code__mx = 'all_country_code__mx',
  all_country_code__my = 'all_country_code__my',
  all_country_code__mz = 'all_country_code__mz',
  all_country_code__na = 'all_country_code__na',
  all_country_code__nc = 'all_country_code__nc',
  all_country_code__ne = 'all_country_code__ne',
  all_country_code__nf = 'all_country_code__nf',
  all_country_code__ng = 'all_country_code__ng',
  all_country_code__ni = 'all_country_code__ni',
  all_country_code__nl = 'all_country_code__nl',
  all_country_code__no = 'all_country_code__no',
  all_country_code__np = 'all_country_code__np',
  all_country_code__nr = 'all_country_code__nr',
  all_country_code__nu = 'all_country_code__nu',
  all_country_code__nz = 'all_country_code__nz',
  all_country_code__om = 'all_country_code__om',
  all_country_code__pa = 'all_country_code__pa',
  all_country_code__pe = 'all_country_code__pe',
  all_country_code__pf = 'all_country_code__pf',
  all_country_code__pg = 'all_country_code__pg',
  all_country_code__ph = 'all_country_code__ph',
  all_country_code__pk = 'all_country_code__pk',
  all_country_code__pl = 'all_country_code__pl',
  all_country_code__pm = 'all_country_code__pm',
  all_country_code__pn = 'all_country_code__pn',
  all_country_code__pr = 'all_country_code__pr',
  all_country_code__ps = 'all_country_code__ps',
  all_country_code__pt = 'all_country_code__pt',
  all_country_code__pw = 'all_country_code__pw',
  all_country_code__py = 'all_country_code__py',
  all_country_code__qa = 'all_country_code__qa',
  all_country_code__re = 'all_country_code__re',
  all_country_code__ro = 'all_country_code__ro',
  all_country_code__rs = 'all_country_code__rs',
  all_country_code__ru = 'all_country_code__ru',
  all_country_code__rw = 'all_country_code__rw',
  all_country_code__sa = 'all_country_code__sa',
  all_country_code__sb = 'all_country_code__sb',
  all_country_code__sc = 'all_country_code__sc',
  all_country_code__sd = 'all_country_code__sd',
  all_country_code__se = 'all_country_code__se',
  all_country_code__sg = 'all_country_code__sg',
  all_country_code__sh = 'all_country_code__sh',
  all_country_code__si = 'all_country_code__si',
  all_country_code__sj = 'all_country_code__sj',
  all_country_code__sk = 'all_country_code__sk',
  all_country_code__sl = 'all_country_code__sl',
  all_country_code__sm = 'all_country_code__sm',
  all_country_code__sn = 'all_country_code__sn',
  all_country_code__so = 'all_country_code__so',
  all_country_code__sr = 'all_country_code__sr',
  all_country_code__ss = 'all_country_code__ss',
  all_country_code__st = 'all_country_code__st',
  all_country_code__sv = 'all_country_code__sv',
  all_country_code__sx = 'all_country_code__sx',
  all_country_code__sy = 'all_country_code__sy',
  all_country_code__sz = 'all_country_code__sz',
  all_country_code__tc = 'all_country_code__tc',
  all_country_code__td = 'all_country_code__td',
  all_country_code__tf = 'all_country_code__tf',
  all_country_code__tg = 'all_country_code__tg',
  all_country_code__th = 'all_country_code__th',
  all_country_code__tj = 'all_country_code__tj',
  all_country_code__tk = 'all_country_code__tk',
  all_country_code__tl = 'all_country_code__tl',
  all_country_code__tm = 'all_country_code__tm',
  all_country_code__tn = 'all_country_code__tn',
  all_country_code__to = 'all_country_code__to',
  all_country_code__tr = 'all_country_code__tr',
  all_country_code__tt = 'all_country_code__tt',
  all_country_code__tv = 'all_country_code__tv',
  all_country_code__tw = 'all_country_code__tw',
  all_country_code__tz = 'all_country_code__tz',
  all_country_code__ua = 'all_country_code__ua',
  all_country_code__ug = 'all_country_code__ug',
  all_country_code__um = 'all_country_code__um',
  all_country_code__us = 'all_country_code__us',
  all_country_code__uy = 'all_country_code__uy',
  all_country_code__uz = 'all_country_code__uz',
  all_country_code__va = 'all_country_code__va',
  all_country_code__vc = 'all_country_code__vc',
  all_country_code__ve = 'all_country_code__ve',
  all_country_code__vg = 'all_country_code__vg',
  all_country_code__vi = 'all_country_code__vi',
  all_country_code__vn = 'all_country_code__vn',
  all_country_code__vu = 'all_country_code__vu',
  all_country_code__wf = 'all_country_code__wf',
  all_country_code__ws = 'all_country_code__ws',
  all_country_code__ye = 'all_country_code__ye',
  all_country_code__yt = 'all_country_code__yt',
  all_country_code__za = 'all_country_code__za',
  all_country_code__zm = 'all_country_code__zm',
  all_country_code__zw = 'all_country_code__zw'
}

export type CouponApplied = {
  __typename?: 'CouponApplied';
  deductedPrice?: Maybe<Scalars['Int']>;
  discountInfo: DiscountInfo;
  discountedPrice?: Maybe<Scalars['Int']>;
  originalPrice: Scalars['Int'];
};

export type CouponDetail = {
  __typename?: 'CouponDetail';
  couponId: Scalars['ID'];
  couponItemListByPage: CouponItemListByPage;
  discountInfo: DiscountInfo;
  endAt: Scalars['Date'];
  limitCount: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  remainDays: Scalars['Int'];
  startAt: Scalars['Date'];
  totalTeamCnt: Scalars['Int'];
  useTeamCnt: Scalars['Int'];
};


export type CouponDetailCouponItemListByPageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum CouponDiscountType {
  FIXED_RATE_DISCOUNT = 'FIXED_RATE_DISCOUNT',
  FLAT_DISCOUNT = 'FLAT_DISCOUNT'
}

export type CouponIsGiven = {
  __typename?: 'CouponIsGiven';
  isGiven: Scalars['Boolean'];
  ownerToken: Scalars['String'];
};

export type CouponIsGivenList = {
  __typename?: 'CouponIsGivenList';
  content?: Maybe<Array<CouponIsGiven>>;
};

export type CouponItem = {
  __typename?: 'CouponItem';
  code: Scalars['String'];
  endAt?: Maybe<Scalars['Date']>;
  ownerToken: Scalars['String'];
  startAt?: Maybe<Scalars['Date']>;
  state: CouponItemStateType;
  team: SportsTeam;
  terminatedAt?: Maybe<Scalars['Date']>;
  useCount: Scalars['Int'];
};

export type CouponItemListByPage = Page & {
  __typename?: 'CouponItemListByPage';
  content?: Maybe<Array<CouponItem>>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum CouponItemStateType {
  TERMINATED = 'TERMINATED',
  UNUSED = 'UNUSED',
  USE_COMPLETE = 'USE_COMPLETE',
  USING = 'USING'
}

export type CouponListByPage = Page & {
  __typename?: 'CouponListByPage';
  content?: Maybe<Array<CouponSimple>>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type CouponListByTeam = {
  __typename?: 'CouponListByTeam';
  content?: Maybe<Array<CouponSimple>>;
};

export type CouponSimple = {
  __typename?: 'CouponSimple';
  couponId: Scalars['ID'];
  endAt: Scalars['Date'];
  name: Scalars['String'];
  remainDays: Scalars['Int'];
  startAt: Scalars['Date'];
};

export type CreateAttendanceStatusInput = {
  absenceReason?: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
  status: EventInviteeStatus;
};

export type CreateBodyInput = {
  height: Scalars['Float'];
  heightUnit?: Maybe<HeightUnit>;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
  weight: Scalars['Float'];
  weightUnit?: Maybe<WeightUnit>;
};

export type CreateCheckReminderPushSchedule = {
  pushMessageType: ReminderPushMessageType;
  scheduleTime: Scalars['String'];
  teamIdList: Array<Scalars['String']>;
  timezone: Scalars['String'];
};

export type CreateConditionInput = {
  fatigue: Scalars['Int'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  sleepDuration: Scalars['Int'];
  sleepQuality: Scalars['Int'];
  sleepTime: Scalars['DateTime'];
  stress: Scalars['Int'];
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type CreateCouponInput = {
  discountType: CouponDiscountType;
  discountValue: Scalars['Int'];
  endAt: Scalars['DateTime'];
  limitCount: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type CreateEventInput = {
  competition?: Maybe<Scalars['String']>;
  endAt: Scalars['DateTime'];
  eventInviteIdentityList: Array<EventInviteIdentity>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: EventType;
  venue?: Maybe<Venue>;
};

export type CreateFeedbackCommentInput = {
  comment: Scalars['String'];
  feedbackId: Scalars['ID'];
};

export type CreateFeedbackInput = {
  conditionNotGood?: Maybe<FeedbackConditionNotGoodInput>;
  conditionPlummet?: Maybe<FeedbackConditionPlummetInput>;
  /** YYYY-MM-DD 형식 */
  date: Scalars['String'];
  message: Scalars['String'];
  playerId: Scalars['String'];
  sorenessSevere?: Maybe<FeedbackSorenessSevereInput>;
  teamId: Scalars['String'];
  type: FeedbackType;
  workloadMemo?: Maybe<FeedbackWorkloadMemoInput>;
  workloadRiskAtInjury?: Maybe<FeedbackWorkloadRiskAtInjuryInput>;
};

export type CreateHyperClientInput = {
  birthdate?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isExistsUser: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CreateInjuryInput = {
  bodyPart?: Maybe<SportsBodyPartName>;
  /** 최대 100자 */
  diagnosis?: Maybe<Scalars['String']>;
  historyStatus: InjuryHistoryStatus;
  /** 최대 100자 */
  medicalRecord?: Maybe<Scalars['String']>;
  occurDate: Scalars['DateTime'];
  /** 최대 100자 */
  reason?: Maybe<Scalars['String']>;
  return: ReturnInput;
  status: InjuryStatus;
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateInput = {
  schedule?: Maybe<ScheduleInput>;
  title: Scalars['String'];
};

export type CreateLicenseInput = {
  endAt: Scalars['DateTime'];
  licenseName: Scalars['String'];
  memo: Scalars['String'];
  ownerToken?: Maybe<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  teamVolume: Scalars['Int'];
  type: LicenseType;
};

export type CreateNoWorkoutInput = {
  reasonForNoWorkout: NoWorkoutType;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type CreateOrgTeamInput = {
  introduction?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userId: Scalars['ID'];
};

export type CreateSpecialNoteInput = {
  eventId: Scalars['ID'];
  specialNote: Scalars['String'];
};

export type CreateSportsTeamInput = {
  introduction?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pictureFileId?: Maybe<Scalars['String']>;
  region: CountryCode;
  sportsCategory: SportsCategory;
};

export type CreateTeamGroupInput = {
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  teamId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type CreateTrainingPointInput = {
  eventId: Scalars['ID'];
  trainingPoint: Scalars['String'];
};

export type CreateWorkloadInput = {
  duration: Scalars['Int'];
  eventId?: Maybe<Scalars['ID']>;
  intensity: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  satisfaction: Scalars['Int'];
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
  type: WorkoutType;
};

export type CsvExportInput = {
  from: Scalars['DateTimeString'];
  group: Array<SportsDataGroup>;
  language: Language;
  to: Scalars['DateTimeString'];
  userIdList: Array<Scalars['ID']>;
};

export type CurrentSorenessUserList = {
  __typename?: 'CurrentSorenessUserList';
  multipleUserList: Array<SorenessUser>;
  severeUserList: Array<SorenessUser>;
  total: Scalars['Float'];
};

export type DailyAcwrData = DailyData & {
  __typename?: 'DailyAcwrData';
  range: Array<SportsTeamDataRange>;
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailyConditionData = DailyData & {
  __typename?: 'DailyConditionData';
  range: Array<SportsTeamDataRange>;
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailyData = {
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailyReadinessData = DailyData & {
  __typename?: 'DailyReadinessData';
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailySoreness = {
  __typename?: 'DailySoreness';
  /** 5단계 통증 정보 */
  high: DailySorenessData;
  /** 3, 4단계 통증 정보 */
  severe: DailySorenessData;
};

export type DailySorenessCountData = DailyData & {
  __typename?: 'DailySorenessCountData';
  range: Array<SportsTeamDataRange>;
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailySorenessData = DailyData & {
  __typename?: 'DailySorenessData';
  range: Array<SportsTeamDataRange>;
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};

export type DailyTeamData = {
  __typename?: 'DailyTeamData';
  acwr: DailyAcwrData;
  condition: DailyConditionData;
  readiness: DailyReadinessData;
  soreness: DailySoreness;
  sorenessCount: DailySorenessCountData;
  workload: DailyWorkloadData;
};

export type DailyWorkloadData = DailyData & {
  __typename?: 'DailyWorkloadData';
  range: Array<SportsTeamDataRange>;
  value: Scalars['Float'];
  varianceFromYesterday: Scalars['Float'];
};




export type DeleteFeedbackCommentInput = {
  commentId: Scalars['ID'];
  feedbackId: Scalars['ID'];
};

export type DeleteFileInput = {
  id: Scalars['ID'];
};

export type DeleteInjuryInput = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type DeleteInput = {
  routineId: Scalars['ID'];
};

export type DeleteLicenseInput = {
  licenseId: Scalars['ID'];
};

export type DeleteNoWorkoutInput = {
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type DeleteOrgTeamInput = {
  id: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
};

export type DeleteSportsDataInput = {
  date: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type DeleteSportsUserInput = {
  identityId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeleteTeamGroupInput = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type DiscountInfo = {
  __typename?: 'DiscountInfo';
  type?: Maybe<CouponDiscountType>;
  value?: Maybe<Scalars['Int']>;
};

export type EntryFilter = {
  __typename?: 'EntryFilter';
  dataType?: Maybe<Scalars['String']>;
  dataUnit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  func?: Maybe<Scalars['String']>;
  operator?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  stride?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EntryValue = {
  __typename?: 'EntryValue';
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type Event = {
  endAt: Scalars['Date'];
  eventInvitees: Array<EventInvitee>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: EventType;
};

export type EventInviteIdentity = {
  id: Scalars['ID'];
};

export type EventInvitee = {
  event: Event;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  status?: Maybe<EventInviteeStatus>;
  /** the player who is invited to this event */
  user?: Maybe<SportsUser>;
  workoutType?: Maybe<WorkoutType>;
};


export type EventInviteeEventArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export enum EventInviteeStatus {
  ABSENCE_ETC = 'ABSENCE_ETC',
  ABSENCE_INJURY = 'ABSENCE_INJURY',
  NOT_CHECK = 'NOT_CHECK',
  NO_PLAY = 'NO_PLAY',
  PARTICIPATION = 'PARTICIPATION',
  STARTER = 'STARTER',
  SUBSTITUTE = 'SUBSTITUTE'
}

export enum EventType {
  MATCH = 'MATCH',
  OTHER_EVENT = 'OTHER_EVENT',
  PRACTICE = 'PRACTICE',
  REST = 'REST',
  TRAINING = 'TRAINING'
}

export type Eventable = {
  event?: Maybe<EventInvitee>;
};

export type ExportReport = {
  eventId: Scalars['String'];
  fileType: ReportFileType;
  language: Language;
  timezone: Scalars['String'];
};

export type Feedback = {
  __typename?: 'Feedback';
  commentList: Array<FeedbackComment>;
  contentsData?: Maybe<FeedbackContentsData>;
  createUser: SportsUser;
  createdAt: Scalars['Date'];
  date: Scalars['String'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isUpdated: Scalars['Boolean'];
  message: Scalars['String'];
  player: SportsUser;
  team?: Maybe<SportsTeam>;
  teamId: Scalars['ID'];
  type: FeedbackType;
};

export type FeedbackAlarm = Alarm & {
  __typename?: 'FeedbackAlarm';
  commentId?: Maybe<Scalars['ID']>;
  createUser: SportsUser;
  createdAt: Scalars['DateTime'];
  feedback: Feedback;
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  player: SportsUser;
  team?: Maybe<SportsTeam>;
  type: AlarmType;
};

export type FeedbackAlarmConnection = {
  __typename?: 'FeedbackAlarmConnection';
  edges: Array<FeedbackAlarmEdge>;
  pageInfo: PageInfo;
};

export type FeedbackAlarmEdge = {
  __typename?: 'FeedbackAlarmEdge';
  cursor: Scalars['String'];
  node: FeedbackAlarm;
};

export type FeedbackComment = {
  __typename?: 'FeedbackComment';
  comment: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  isUpdated: Scalars['Boolean'];
  user: SportsUser;
};

export type FeedbackConditionNotGoodData = {
  __typename?: 'FeedbackConditionNotGoodData';
  dataList: Array<UserConditionDataType>;
  fatigue: Scalars['Int'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  sleepDuration: Scalars['Int'];
  sleepDurationScore: Scalars['Int'];
  sleepQuality: Scalars['Int'];
  stress: Scalars['Int'];
};

export type FeedbackConditionNotGoodInput = {
  dataList: Array<UserConditionDataType>;
  fatigue: Scalars['Int'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  sleepDuration: Scalars['Int'];
  sleepDurationScore: Scalars['Int'];
  sleepQuality: Scalars['Int'];
  stress: Scalars['Int'];
};

export type FeedbackConditionPlummetData = {
  __typename?: 'FeedbackConditionPlummetData';
  dataList: Array<UserConditionDataType>;
  fatigue28dayList: Array<Scalars['Int']>;
  mood28dayList: Array<Scalars['Int']>;
  muscle28dayList: Array<Scalars['Int']>;
  sleepDuration28dayList: Array<Scalars['Int']>;
  sleepQuality28dayList: Array<Scalars['Int']>;
  stress28dayList: Array<Scalars['Int']>;
};

export type FeedbackConditionPlummetInput = {
  dataList: Array<UserConditionDataType>;
  fatigue28dayList: Array<Scalars['Int']>;
  mood28dayList: Array<Scalars['Int']>;
  muscle28dayList: Array<Scalars['Int']>;
  sleepDuration28dayList: Array<Scalars['Int']>;
  sleepQuality28dayList: Array<Scalars['Int']>;
  stress28dayList: Array<Scalars['Int']>;
};

export type FeedbackConnection = {
  __typename?: 'FeedbackConnection';
  edges: Array<FeedbackEdge>;
  pageInfo: PageInfo;
};

export type FeedbackContentsData = FeedbackConditionNotGoodData | FeedbackConditionPlummetData | FeedbackSorenessSevereData | FeedbackWorkloadMemoData | FeedbackWorkloadRiskAtInjuryData;

export type FeedbackEdge = {
  __typename?: 'FeedbackEdge';
  cursor: Scalars['String'];
  node: Feedback;
};

export type FeedbackSorenessSevereData = {
  __typename?: 'FeedbackSorenessSevereData';
  bodyPart: SportsBodyPart;
  isContact?: Maybe<Contact>;
  level: Scalars['Int'];
  memo: Scalars['String'];
};


export type FeedbackSorenessSevereDataBodyPartArgs = {
  input: MultilingualTextInput;
};

export type FeedbackSorenessSevereInput = {
  bodyPart: SportsBodyPartName;
  isContact?: Maybe<Contact>;
  level: Scalars['Int'];
  memo: Scalars['String'];
};

export type FeedbackSuggestion = {
  __typename?: 'FeedbackSuggestion';
  contentsData?: Maybe<FeedbackContentsData>;
  date: Scalars['String'];
  type: FeedbackType;
};

export enum FeedbackType {
  CONDITION_NOT_GOOD = 'CONDITION_NOT_GOOD',
  CONDITION_PLUMMET = 'CONDITION_PLUMMET',
  NORMAL = 'NORMAL',
  REQUEST = 'REQUEST',
  SORENESS_SEVERE = 'SORENESS_SEVERE',
  WORKLOAD_MEMO = 'WORKLOAD_MEMO',
  WORKLOAD_RISK_AT_INJURY = 'WORKLOAD_RISK_AT_INJURY'
}

export type FeedbackWorkloadEventData = {
  __typename?: 'FeedbackWorkloadEventData';
  competition?: Maybe<Scalars['String']>;
  endAt: Scalars['Date'];
  location?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: EventType;
};

export type FeedbackWorkloadEventInput = {
  competition?: Maybe<Scalars['String']>;
  endAt: Scalars['Date'];
  location?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: EventType;
};

export type FeedbackWorkloadMemoData = {
  __typename?: 'FeedbackWorkloadMemoData';
  duration: Scalars['Int'];
  eventData?: Maybe<FeedbackWorkloadEventData>;
  memo: Scalars['String'];
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
};

export type FeedbackWorkloadMemoInput = {
  duration: Scalars['Int'];
  eventData?: Maybe<FeedbackWorkloadEventInput>;
  memo: Scalars['String'];
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
};

export type FeedbackWorkloadRiskAtInjuryData = {
  __typename?: 'FeedbackWorkloadRiskAtInjuryData';
  analysisType: UserWorkloadSuggestionDataType;
  label: UserWorkloadRiskAtInjuryLabelType;
  reasonList: Array<UserWorkloadRiskAtInjuryReasonType>;
};

export type FeedbackWorkloadRiskAtInjuryInput = {
  analysisType: UserWorkloadSuggestionDataType;
  label: UserWorkloadRiskAtInjuryLabelType;
  reasonList: Array<UserWorkloadRiskAtInjuryReasonType>;
};

export type FileEntity = {
  __typename?: 'FileEntity';
  byteSize: Scalars['Float'];
  contentType: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  private: Scalars['Boolean'];
  tags: Scalars['JSON'];
  updatedAt: Scalars['Date'];
  url: Scalars['String'];
};

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER'
}

export type GetCoachEventInput = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};

export type GetCouponAppliedPriceInput = {
  couponId?: Maybe<Scalars['String']>;
  originalPrice: Scalars['Int'];
};

export type GiveCouponToTeamsInput = {
  teamIdList: Array<Scalars['ID']>;
};

export type GuideReopenStatus = {
  __typename?: 'GuideReopenStatus';
  alarm: GuideReopenStatusUnit;
  readiness: GuideReopenStatusUnit;
};

export type GuideReopenStatusUnit = {
  __typename?: 'GuideReopenStatusUnit';
  app: ReopenStatus;
  web: ReopenStatus;
};

export enum HeightUnit {
  cm = 'cm',
  ft = 'ft',
  in = 'in'
}

export type Injury = {
  __typename?: 'Injury';
  bodyPart?: Maybe<SportsBodyPart>;
  diagnosis?: Maybe<Scalars['String']>;
  fileList: Array<InjuryFile>;
  historyStatus: InjuryHistoryStatus;
  id: Scalars['ID'];
  medicalRecord?: Maybe<Scalars['String']>;
  occurDate: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  return: Return;
  status: InjuryStatus;
  user: SportsUser;
};


export type InjuryBodyPartArgs = {
  input: MultilingualTextInput;
};

export type InjuryFile = {
  __typename?: 'InjuryFile';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export enum InjuryHistoryStatus {
  FIRST = 'FIRST',
  RELAPSE = 'RELAPSE'
}

export enum InjuryReturnStatus {
  DECIDED = 'DECIDED',
  UNDECIDED = 'UNDECIDED'
}

export enum InjuryStatus {
  END = 'END',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type InjuryUser = {
  __typename?: 'InjuryUser';
  injuryList: Array<Injury>;
  user: SportsUser;
};

export type InjuryUserList = {
  __typename?: 'InjuryUserList';
  total: Scalars['Float'];
  userList: Array<InjuryUser>;
};

export type InsightDataEntry = {
  __typename?: 'InsightDataEntry';
  user: SportsUser;
  value: Scalars['String'];
};

export enum InsightDataGroup {
  CONDITION = 'CONDITION',
  INJURY = 'INJURY',
  SUMMARY = 'SUMMARY',
  WORKLOAD = 'WORKLOAD'
}

export type InsightDataItem = {
  __typename?: 'InsightDataItem';
  entries: Array<InsightDataEntry>;
  from: Scalars['DateTimeString'];
  group?: Maybe<InsightDataGroup>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  meta: InsightDataItemMeta;
  name: InsightDataName;
  offset?: Maybe<Scalars['Int']>;
  stride: InsightDataStride;
  timezone: Scalars['String'];
  to: Scalars['DateTimeString'];
  total: Scalars['Int'];
};


export type InsightDataItemEntriesArgs = {
  input: MultilingualTextInput;
};

export type InsightDataItemMeta = {
  __typename?: 'InsightDataItemMeta';
  entryFilter: EntryFilter;
  entryValue: EntryValue;
};

export enum InsightDataName {
  CONDITION_CHECKED = 'CONDITION_CHECKED',
  CONDITION_CHECKED_NOT = 'CONDITION_CHECKED_NOT',
  RANK_ACWR_HIGH = 'RANK_ACWR_HIGH',
  RANK_CONDITION_BAD = 'RANK_CONDITION_BAD',
  RANK_CONDITION_BAD_7DAY = 'RANK_CONDITION_BAD_7DAY',
  RANK_CONDITION_BAD_28DAY = 'RANK_CONDITION_BAD_28DAY',
  RANK_FATIGUE_HIGH = 'RANK_FATIGUE_HIGH',
  RANK_FATIGUE_HIGH_7DAY = 'RANK_FATIGUE_HIGH_7DAY',
  RANK_FATIGUE_HIGH_28DAY = 'RANK_FATIGUE_HIGH_28DAY',
  RANK_MOOD_BAD = 'RANK_MOOD_BAD',
  RANK_MOOD_BAD_7DAY = 'RANK_MOOD_BAD_7DAY',
  RANK_MOOD_BAD_28DAY = 'RANK_MOOD_BAD_28DAY',
  RANK_MUSCLE_BAD = 'RANK_MUSCLE_BAD',
  RANK_MUSCLE_BAD_7DAY = 'RANK_MUSCLE_BAD_7DAY',
  RANK_MUSCLE_BAD_28DAY = 'RANK_MUSCLE_BAD_28DAY',
  RANK_SLEEP_DURATION_BAD = 'RANK_SLEEP_DURATION_BAD',
  RANK_SLEEP_DURATION_BAD_7DAY = 'RANK_SLEEP_DURATION_BAD_7DAY',
  RANK_SLEEP_DURATION_BAD_28DAY = 'RANK_SLEEP_DURATION_BAD_28DAY',
  RANK_SLEEP_QUALITY_BAD = 'RANK_SLEEP_QUALITY_BAD',
  RANK_SLEEP_QUALITY_BAD_7DAY = 'RANK_SLEEP_QUALITY_BAD_7DAY',
  RANK_SLEEP_QUALITY_BAD_28DAY = 'RANK_SLEEP_QUALITY_BAD_28DAY',
  RANK_SORENESS_INJURED = 'RANK_SORENESS_INJURED',
  RANK_SORENESS_INJURED_7DAY = 'RANK_SORENESS_INJURED_7DAY',
  RANK_SORENESS_INJURED_28DAY = 'RANK_SORENESS_INJURED_28DAY',
  RANK_SORENESS_SEVERE = 'RANK_SORENESS_SEVERE',
  RANK_SORENESS_SEVERE_7DAY = 'RANK_SORENESS_SEVERE_7DAY',
  RANK_SORENESS_SEVERE_28DAY = 'RANK_SORENESS_SEVERE_28DAY',
  RANK_STRESS_HIGH = 'RANK_STRESS_HIGH',
  RANK_STRESS_HIGH_7DAY = 'RANK_STRESS_HIGH_7DAY',
  RANK_STRESS_HIGH_28DAY = 'RANK_STRESS_HIGH_28DAY',
  RANK_WORKLOAD_AXD_7DAY = 'RANK_WORKLOAD_AXD_7DAY',
  RANK_WORKLOAD_HIGH = 'RANK_WORKLOAD_HIGH',
  RANK_WORKLOAD_HIGH_7DAY = 'RANK_WORKLOAD_HIGH_7DAY',
  RANK_WORKLOAD_HIGH_28DAY = 'RANK_WORKLOAD_HIGH_28DAY',
  RANK_WORKLOAD_INCREASED_RATE_7DAY = 'RANK_WORKLOAD_INCREASED_RATE_7DAY',
  WORKLOAD_CHECKED = 'WORKLOAD_CHECKED',
  WORKLOAD_CHECKED_NOT = 'WORKLOAD_CHECKED_NOT'
}

export enum InsightDataStride {
  DAY = 'DAY',
  RECENT_7DAY = 'RECENT_7DAY',
  RECENT_28DAY = 'RECENT_28DAY'
}

export type InvitationRequest = {
  id: Scalars['ID'];
  teamAuthority: SportsTeamAuthority;
  userId: Scalars['ID'];
};

export type Invoice = {
  billingUserEmail: Scalars['String'];
  billingUserName: Scalars['String'];
  billingUserPhone: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  id: Scalars['ID'];
  paymentName: Scalars['String'];
  paymentStatus: PaymentStatus;
  receiptUrl?: Maybe<Scalars['String']>;
};

export type InvoiceDetail = {
  __typename?: 'InvoiceDetail';
  amount: Scalars['Float'];
  billingUserEmail: Scalars['String'];
  billingUserName: Scalars['String'];
  billingUserPhone: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  id: Scalars['ID'];
  maxPlayerCount: Scalars['Float'];
  maxTeamCount: Scalars['Float'];
  paidAt: Scalars['String'];
  planName: Scalars['String'];
  planOptionName: Scalars['String'];
  receiptUrl?: Maybe<Scalars['String']>;
};

export type InvoiceList = {
  __typename?: 'InvoiceList';
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  invoiceList?: Maybe<Array<Maybe<Invoice>>>;
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  total: Scalars['Float'];
};


export type KickUserFromOrgTeamInput = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export enum Language {
  EN = 'EN',
  JA = 'JA',
  KO = 'KO',
  VN = 'VN'
}

export type License = {
  __typename?: 'License';
  createdAt: Scalars['String'];
  endAt: Scalars['String'];
  id: Scalars['ID'];
  isActivate: Scalars['Boolean'];
  licenseName: Scalars['String'];
  licensedTeams: Array<SportsTeam>;
  memo: Scalars['String'];
  ownerTeam?: Maybe<SportsClient>;
  ownerToken?: Maybe<Scalars['String']>;
  startAt: Scalars['String'];
  teamIdList: Array<Scalars['String']>;
  teamVolume: Scalars['Float'];
  type: LicenseType;
  updatedAt: Scalars['String'];
};

export enum LicenseType {
  ORG = 'ORG',
  PRO = 'PRO'
}

export type LicensesByPage = {
  __typename?: 'LicensesByPage';
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  licenses?: Maybe<Array<License>>;
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  total: Scalars['Float'];
};

export type LineupInvitee = {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineupPosition?: Maybe<Scalars['String']>;
  playTime?: Maybe<Scalars['String']>;
  status: EventInviteeStatus;
};

export type MakeDoNotReopenAlarmGuideStatusInput = {
  platform: Platform;
  userId: Scalars['ID'];
};

export type MakeDoNotReopenReadinessGuideStatusInput = {
  platform: Platform;
  untilToday: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type Match = Event & {
  __typename?: 'Match';
  attendeeChartData: AttendeeChartData;
  competition: Scalars['String'];
  endAt: Scalars['Date'];
  eventInvitees: Array<MatchInvitee>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  opponent: Scalars['String'];
  result: MatchResult;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: EventType;
  venue: Venue;
  workloadCheckedUserCount: Scalars['Float'];
};


export type MatchAttendeeChartDataArgs = {
  dataType?: Maybe<AttendeeChartDataType>;
};

export type MatchInvitee = EventInvitee & {
  __typename?: 'MatchInvitee';
  comment?: Maybe<Scalars['String']>;
  event: Event;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  lineupPosition?: Maybe<Scalars['String']>;
  playTime?: Maybe<Scalars['String']>;
  status?: Maybe<EventInviteeStatus>;
  /** the player who is invited to this event */
  user?: Maybe<SportsUser>;
  workoutType?: Maybe<WorkoutType>;
};


export type MatchInviteeEventArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export type MatchResult = {
  __typename?: 'MatchResult';
  memo?: Maybe<Scalars['String']>;
  opponentScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  status?: Maybe<MatchResultStatus>;
};

export enum MatchResultStatus {
  DRAW = 'DRAW',
  LOSE = 'LOSE',
  WIN = 'WIN'
}

export type MultilingualBodyPart = {
  __typename?: 'MultilingualBodyPart';
  back: Scalars['Boolean'];
  front: Scalars['Boolean'];
  group: MultilingualBodyPartGroup;
  id: SportsBodyPartName;
  left: Scalars['Boolean'];
  right: Scalars['Boolean'];
  value: Scalars['String'];
};

export type MultilingualBodyPartGroup = {
  __typename?: 'MultilingualBodyPartGroup';
  id: SportsBodyPartGroupName;
  value: Scalars['String'];
};

export type MultilingualCategory = {
  __typename?: 'MultilingualCategory';
  coachPositionList: Array<MultilingualCoachPosition>;
  id: SportsCategory;
  playerPositionList: Array<MultilingualPlayerPosition>;
  value: Scalars['String'];
};

export type MultilingualCategoryGroup = {
  __typename?: 'MultilingualCategoryGroup';
  categoryList: Array<MultilingualCategory>;
  id: SportsCategoryGroup;
  value: Scalars['String'];
};

export type MultilingualCoachPosition = {
  __typename?: 'MultilingualCoachPosition';
  id: SportsPosition;
  value: Scalars['String'];
};

export type MultilingualCountry = {
  __typename?: 'MultilingualCountry';
  id: CountryCode;
  value: Scalars['String'];
};

export type MultilingualPlayerPosition = {
  __typename?: 'MultilingualPlayerPosition';
  id: SportsPosition;
  value: Scalars['String'];
};

export type MultilingualTextInput = {
  nationality?: Maybe<Scalars['String']>;
  osType?: Maybe<OsType>;
};

export type MultilingualTextQuery = {
  __typename?: 'MultilingualTextQuery';
  bodyPartList: Array<MultilingualBodyPart>;
  categoryGroupList: Array<MultilingualCategoryGroup>;
  countryList: Array<MultilingualCountry>;
  timezoneList: Array<Timezone>;
};


export type MultilingualTextQueryBodyPartListArgs = {
  key?: Maybe<Scalars['String']>;
};


export type MultilingualTextQueryCategoryGroupListArgs = {
  key?: Maybe<Scalars['String']>;
};


export type MultilingualTextQueryCountryListArgs = {
  key?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitationList: Array<SportsTeamInvitationRequest>;
  addBannerLog: Scalars['Boolean'];
  addOrgLicenseTeam: License;
  addProLicenseTeam: License;
  appendSorenessList: SportsData;
  applyCoupon: SubscriptionItem;
  cancelSubscription: SubscriptionItem;
  changeCoupon: SubscriptionItem;
  changeSubscriptionDate: SubscriptionItem;
  clearDeviceTokens: Scalars['Boolean'];
  createAttendanceStatus: EventInvitee;
  createBody: SportsData;
  createCareer: SportsCareer;
  createCheckReminderPushSchedule: Array<TeamCheckReminderSchedule>;
  createCondition: SportsData;
  createCoupon: CouponDetail;
  createEvent: Event;
  createFeedback: Feedback;
  createFeedbackComment: Feedback;
  createHyperClient: Scalars['Boolean'];
  createInjury: Injury;
  createLicense: License;
  createNoWorkout: SportsData;
  createOrgTeam: OrgTeam;
  createRoutine: Routine;
  createSpecialNote: Event;
  createTeam: SportsTeam;
  createTeamGroup: TeamGroup;
  createTrainingPoint: Event;
  createWorkload: SportsData;
  deleteBody: SportsData;
  deleteCareer: Scalars['Boolean'];
  deleteCheckReminderPushSchedule: Scalars['Boolean'];
  deleteCondition: SportsData;
  deleteCoupon: CouponDetail;
  deleteEvent: Scalars['Boolean'];
  deleteFeedback: Scalars['Boolean'];
  deleteFeedbackComment: Feedback;
  /** @deprecated 기능별로 필요한 파일 삭제 기능을 개발할 계획으로 추후 삭제 예정 */
  deleteFile: Scalars['Boolean'];
  deleteInjury: Scalars['Boolean'];
  deleteLicense: License;
  deleteNoWorkout: SportsData;
  deleteOrgTeam: OrgTeam;
  deleteRoutine: Routine;
  deleteTeam: SportsTeam;
  deleteTeamGroup: TeamGroup;
  deleteUser: Scalars['Boolean'];
  deleteWorkload: SportsData;
  deregisterDeviceToken: Scalars['Boolean'];
  exportCsvFile: Scalars['String'];
  exportMatchReport: Scalars['String'];
  exportTrainingReport: Scalars['String'];
  giveCoupons: CouponDetail;
  kickUserFromOrgTeam: OrgTeamUserMap;
  kickoutTeamUserList: Scalars['Boolean'];
  leaveTeam: Scalars['Boolean'];
  makeAlarmGuideDoNotReopenStatus: GuideReopenStatusUnit;
  makeCoachAlarmListChecked: Array<CoachAlarm>;
  makeFeedbackAlarmListChecked: Array<FeedbackAlarm>;
  makePlayerAlarmListChecked: Array<Alarm>;
  makeReadinessGuideDoNotReopenStatus: GuideReopenStatusUnit;
  refund: RefundResponse;
  refundSubscription: SubscriptionResponse;
  registerDeviceToken: Scalars['Boolean'];
  rejectTeamInvitationList: Array<SportsTeamInvitationRequest>;
  removeCoupon: CouponDetail;
  removeOrgLicenseTeam: License;
  removeProLicenseTeam: License;
  removeUnusedCoupons: CouponDetail;
  requestBillingToken: BillingToken;
  requestTeamInvitation: SportsTeamInvitationRequest;
  restartSubscription: SubscriptionResponse;
  resumeSubscription: SubscriptionItem;
  retryPayment: SubscriptionResponse;
  sendCheckConditionReminderPush: Scalars['Boolean'];
  sendCheckEventEnviteeReminderPush: Scalars['Boolean'];
  sendCheckWorkloadReminderPush: Scalars['Boolean'];
  sendEventWorkloadReminderPush: Scalars['Boolean'];
  sendToPlayerAlarm: CoachAlarm;
  sendToPlayerAlarmList: Array<CoachAlarm>;
  setOrgTeamUsers: Array<OrgTeamUserMap>;
  startSubscription: SubscriptionResponse;
  toggleRoutine: RoutineToggle;
  toggleTeamUserStarStatus: StarredToggleResult;
  transferTeamOwnership: TransferTeamOwnership;
  updateAttendanceStatus: EventInvitee;
  updateBillingBuyerEmail: Billing;
  updateBillingBuyerName: Billing;
  updateBillingBuyerNumber: Billing;
  updateBillingKey: Billing;
  updateBody: SportsData;
  updateCareer: SportsCareer;
  updateCondition: SportsData;
  updateCoupon: CouponDetail;
  updateEvent: Event;
  updateEventWorkloadDuration: Event;
  updateFeedback: Feedback;
  updateFeedbackComment: Feedback;
  updateInjury: Injury;
  updateLicenseDate: License;
  updateLineup: Match;
  updateMatchResult: Match;
  updateNoWorkout: SportsData;
  updateRoutine: Routine;
  updateSpecialNote: Event;
  updateTeam: SportsTeam;
  updateTeamGroup: TeamGroup;
  updateTrainingPoint: Event;
  updateUser: SportsUser;
  updateWorkload: SportsData;
  uploadBanner: Banner;
  uploadCoordinatingReport: Scalars['Boolean'];
  /** @deprecated 기능별로 필요한 파일 업로드 기능을 개발할 계획으로 추후 삭제 예정 */
  uploadFile: FileEntity;
  uploadProfile: UploadProfileResponse;
};


export type MutationAcceptInvitationListArgs = {
  input: SportsTeamInvitationAcceptListInput;
};


export type MutationAddBannerLogArgs = {
  input: AddBannerLogInput;
};


export type MutationAddOrgLicenseTeamArgs = {
  input: AddOrgLicenseTeamInput;
};


export type MutationAddProLicenseTeamArgs = {
  input: AddProLicenseTeamInput;
};


export type MutationAppendSorenessListArgs = {
  input: AppendSorenessListInput;
};


export type MutationApplyCouponArgs = {
  input: ApplyCouponInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationChangeCouponArgs = {
  input: ChangeCouponInput;
};


export type MutationChangeSubscriptionDateArgs = {
  input: ChangeSubscriptionDateInput;
};


export type MutationCreateAttendanceStatusArgs = {
  input: CreateAttendanceStatusInput;
};


export type MutationCreateBodyArgs = {
  input: CreateBodyInput;
};


export type MutationCreateCareerArgs = {
  input: SportsCareerCreateInput;
};


export type MutationCreateCheckReminderPushScheduleArgs = {
  input: CreateCheckReminderPushSchedule;
};


export type MutationCreateConditionArgs = {
  input: CreateConditionInput;
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
  teamId: Scalars['ID'];
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


export type MutationCreateFeedbackCommentArgs = {
  input: CreateFeedbackCommentInput;
};


export type MutationCreateHyperClientArgs = {
  input: CreateHyperClientInput;
};


export type MutationCreateInjuryArgs = {
  files: Array<Scalars['Upload']>;
  input: CreateInjuryInput;
};


export type MutationCreateLicenseArgs = {
  input: CreateLicenseInput;
};


export type MutationCreateNoWorkoutArgs = {
  input: CreateNoWorkoutInput;
};


export type MutationCreateOrgTeamArgs = {
  input: CreateOrgTeamInput;
};


export type MutationCreateRoutineArgs = {
  input: CreateInput;
};


export type MutationCreateSpecialNoteArgs = {
  input: CreateSpecialNoteInput;
};


export type MutationCreateTeamArgs = {
  input: CreateSportsTeamInput;
};


export type MutationCreateTeamGroupArgs = {
  input: CreateTeamGroupInput;
};


export type MutationCreateTrainingPointArgs = {
  input: CreateTrainingPointInput;
};


export type MutationCreateWorkloadArgs = {
  input: CreateWorkloadInput;
};


export type MutationDeleteBodyArgs = {
  input: DeleteSportsDataInput;
};


export type MutationDeleteCareerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCheckReminderPushScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteConditionArgs = {
  input: DeleteSportsDataInput;
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationDeleteFeedbackArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFeedbackCommentArgs = {
  input: DeleteFeedbackCommentInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationDeleteInjuryArgs = {
  input: DeleteInjuryInput;
};


export type MutationDeleteLicenseArgs = {
  input: DeleteLicenseInput;
};


export type MutationDeleteNoWorkoutArgs = {
  input: DeleteNoWorkoutInput;
};


export type MutationDeleteOrgTeamArgs = {
  input: DeleteOrgTeamInput;
};


export type MutationDeleteRoutineArgs = {
  input: DeleteInput;
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['ID'];
};


export type MutationDeleteTeamGroupArgs = {
  input: DeleteTeamGroupInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteSportsUserInput;
};


export type MutationDeleteWorkloadArgs = {
  input: DeleteSportsDataInput;
};


export type MutationDeregisterDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationExportCsvFileArgs = {
  input: CsvExportInput;
};


export type MutationExportMatchReportArgs = {
  input: ExportReport;
};


export type MutationExportTrainingReportArgs = {
  input: ExportReport;
};


export type MutationGiveCouponsArgs = {
  id: Scalars['ID'];
  input: GiveCouponToTeamsInput;
};


export type MutationKickUserFromOrgTeamArgs = {
  input: KickUserFromOrgTeamInput;
};


export type MutationKickoutTeamUserListArgs = {
  input: TeamUserListInput;
};


export type MutationLeaveTeamArgs = {
  input: TeamUserInput;
};


export type MutationMakeAlarmGuideDoNotReopenStatusArgs = {
  input: MakeDoNotReopenAlarmGuideStatusInput;
};


export type MutationMakeCoachAlarmListCheckedArgs = {
  coachAlarmIdList: Array<Scalars['ID']>;
};


export type MutationMakeFeedbackAlarmListCheckedArgs = {
  date?: Maybe<Scalars['DateTime']>;
  feedbackAlarmId?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};


export type MutationMakePlayerAlarmListCheckedArgs = {
  alarmId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
};


export type MutationMakeReadinessGuideDoNotReopenStatusArgs = {
  input: MakeDoNotReopenReadinessGuideStatusInput;
};


export type MutationRefundArgs = {
  input: Refund;
};


export type MutationRefundSubscriptionArgs = {
  input: RefundSubscriptionInput;
};


export type MutationRegisterDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationRejectTeamInvitationListArgs = {
  input: SportsTeamInvitationRejectListInput;
};


export type MutationRemoveCouponArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationRemoveOrgLicenseTeamArgs = {
  input: RemoveOrgLicenseTeamInput;
};


export type MutationRemoveProLicenseTeamArgs = {
  input: RemoveProLicenseTeamInput;
};


export type MutationRemoveUnusedCouponsArgs = {
  id: Scalars['ID'];
};


export type MutationRequestBillingTokenArgs = {
  input: RequestBillingTokenInput;
};


export type MutationRequestTeamInvitationArgs = {
  input: SportsTeamInvitationRequestInput;
};


export type MutationRestartSubscriptionArgs = {
  input: RestartSubscriptionInput;
};


export type MutationResumeSubscriptionArgs = {
  input: ResumeSubscriptionInput;
};


export type MutationRetryPaymentArgs = {
  input: RetryPayment;
};


export type MutationSendCheckConditionReminderPushArgs = {
  input: CheckReminderPushInput;
};


export type MutationSendCheckEventEnviteeReminderPushArgs = {
  input: CheckEventInviteReminderPushInput;
};


export type MutationSendCheckWorkloadReminderPushArgs = {
  input: CheckReminderPushInput;
};


export type MutationSendEventWorkloadReminderPushArgs = {
  input: CheckEventInviteReminderPushInput;
};


export type MutationSendToPlayerAlarmArgs = {
  coachAlarmId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationSendToPlayerAlarmListArgs = {
  teamId: Scalars['ID'];
  timezone: Scalars['String'];
};


export type MutationSetOrgTeamUsersArgs = {
  input: SetOrgTeamUsersInput;
};


export type MutationStartSubscriptionArgs = {
  input: StartSubscriptionInput;
};


export type MutationToggleRoutineArgs = {
  input: ToggleInput;
};


export type MutationToggleTeamUserStarStatusArgs = {
  input: ToggleTeamUserStarredInput;
};


export type MutationTransferTeamOwnershipArgs = {
  input: TeamUserInput;
};


export type MutationUpdateAttendanceStatusArgs = {
  input: UpdateAttendanceStatusInput;
};


export type MutationUpdateBillingBuyerEmailArgs = {
  input: UpdateBillingBuyerEmailInput;
};


export type MutationUpdateBillingBuyerNameArgs = {
  input: UpdateBillingBuyerNameInput;
};


export type MutationUpdateBillingBuyerNumberArgs = {
  input: UpdateBillingBuyerNumberInput;
};


export type MutationUpdateBillingKeyArgs = {
  input: UpdateBillingTokenInput;
};


export type MutationUpdateBodyArgs = {
  input: UpdateBodyInput;
};


export type MutationUpdateCareerArgs = {
  input: SportsCareerUpdateInput;
};


export type MutationUpdateConditionArgs = {
  input: UpdateConditionInput;
};


export type MutationUpdateCouponArgs = {
  id: Scalars['ID'];
  input: UpdateCouponInput;
};


export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  input: UpdateEventInput;
  teamId: Scalars['ID'];
};


export type MutationUpdateEventWorkloadDurationArgs = {
  input: UpdateEventWorkloadDurationInput;
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};


export type MutationUpdateFeedbackCommentArgs = {
  input: UpdateFeedbackCommentInput;
};


export type MutationUpdateInjuryArgs = {
  files?: Maybe<Array<Scalars['Upload']>>;
  input: UpdateInjuryInput;
};


export type MutationUpdateLicenseDateArgs = {
  input: UpdateLicenseInput;
};


export type MutationUpdateLineupArgs = {
  input: UpdateLineup;
};


export type MutationUpdateMatchResultArgs = {
  input: UpdateMatchResultInput;
};


export type MutationUpdateNoWorkoutArgs = {
  input: UpdateNoWorkoutInput;
};


export type MutationUpdateRoutineArgs = {
  input: UpdateInput;
};


export type MutationUpdateSpecialNoteArgs = {
  input: UpdateSpecialNoteInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateSportsTeamInput;
};


export type MutationUpdateTeamGroupArgs = {
  input: UpdateTeamGroupInput;
};


export type MutationUpdateTrainingPointArgs = {
  input: UpdateTrainingPointInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateSportsUserInput;
};


export type MutationUpdateWorkloadArgs = {
  input: UpdateWorkloadInput;
};


export type MutationUploadBannerArgs = {
  file: Scalars['Upload'];
  input: UploadBannerInput;
};


export type MutationUploadCoordinatingReportArgs = {
  file: Scalars['Upload'];
  input: UploadCoordinatingReportInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadProfileArgs = {
  file: Scalars['Upload'];
  teamId?: Maybe<Scalars['ID']>;
};

export enum NoWorkoutType {
  INJURY = 'INJURY',
  REST = 'REST'
}

export enum OsType {
  android = 'android',
  ios = 'ios',
  web = 'web'
}

export type OrgTeam = {
  __typename?: 'OrgTeam';
  id: Scalars['ID'];
  introduction?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownedBy: SportsUser;
  slug?: Maybe<Scalars['String']>;
  type: TeamType;
  userMaps: Array<OrgTeamUserMap>;
};

export type OrgTeamByPage = {
  __typename?: 'OrgTeamByPage';
  entries: Array<OrgTeam>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type OrgTeamUserMap = {
  __typename?: 'OrgTeamUserMap';
  id: Scalars['ID'];
  team: OrgTeam;
  teamAuthority: SportsTeamAuthority;
  user: SportsUser;
};

export type Page = {
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PayInvoice = Invoice & {
  __typename?: 'PayInvoice';
  amount: Scalars['Float'];
  billingUserEmail: Scalars['String'];
  billingUserName: Scalars['String'];
  billingUserPhone: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  id: Scalars['ID'];
  paidAt?: Maybe<Scalars['String']>;
  paymentName: Scalars['String'];
  paymentStatus: PaymentStatus;
  receiptUrl?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  FAIL = 'FAIL',
  PAY = 'PAY',
  REFUND = 'REFUND',
  SCHEDULED_CANCEL = 'SCHEDULED_CANCEL',
  SCHEDULED_PAY = 'SCHEDULED_PAY'
}

export type Plan = {
  __typename?: 'Plan';
  discountEndAt?: Maybe<Scalars['Date']>;
  discountStartAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  limitCount?: Maybe<Scalars['Int']>;
  originalPriceStartAt?: Maybe<Scalars['Date']>;
  planName: Scalars['String'];
  planOptionInfoList?: Maybe<Array<Maybe<PlanOptionWithCoupon>>>;
  planType: Scalars['String'];
};

export type PlanOption = {
  __typename?: 'PlanOption';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  id: Scalars['ID'];
  maxPlayerCount: Scalars['Float'];
  maxTeamCount: Scalars['Float'];
  nextPaymentDay: Scalars['Date'];
  period: Scalars['String'];
  planOptionName: Scalars['String'];
};

export type PlanOptionWithCoupon = {
  __typename?: 'PlanOptionWithCoupon';
  currency: Scalars['String'];
  description: Scalars['String'];
  discountedPrice?: Maybe<Scalars['Int']>;
  duration: Scalars['Float'];
  id: Scalars['ID'];
  maxPlayerCount: Scalars['Float'];
  maxTeamCount: Scalars['Float'];
  nextPaymentDay: Scalars['Date'];
  originalPrice: Scalars['Int'];
  period: Scalars['String'];
  planOptionName: Scalars['String'];
};

export type PlanWithOption = {
  __typename?: 'PlanWithOption';
  id: Scalars['ID'];
  planName: Scalars['String'];
  planOptionInfo: PlanOption;
  planType: Scalars['String'];
};

export enum Platform {
  APP = 'APP',
  WEB = 'WEB'
}

export type PlayerEvent = {
  __typename?: 'PlayerEvent';
  eventList: Array<EventInvitee>;
  upcomingEventList: Array<EventInvitee>;
  user: SportsUser;
};


export type PlayerEventEventListArgs = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type PlayerEventUpcomingEventListArgs = {
  timezone: Scalars['String'];
};

export type Qualification = {
  __typename?: 'Qualification';
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  qualificationType: QualificationType;
};

export enum QualificationType {
  LICENSE = 'LICENSE',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export type Query = {
  __typename?: 'Query';
  banner: Banner;
  bannerList: Array<Banner>;
  billing?: Maybe<Billing>;
  coupon: CouponDetail;
  couponAppliedPrice: CouponApplied;
  couponIsGivenList: CouponIsGivenList;
  couponList: CouponListByPage;
  couponListByTeam: CouponListByTeam;
  eventForCoach: Event;
  eventForPlayer: EventInvitee;
  feedback: Feedback;
  findFile: FileEntity;
  highestBenefitCouponByTeam?: Maybe<CouponSimple>;
  invoice: InvoiceDetail;
  invoicesByPage: InvoiceList;
  licenseById: License;
  licenseByOwnerToken?: Maybe<License>;
  licenseByTeam?: Maybe<License>;
  licensesByPage: LicensesByPage;
  multilingualText: MultilingualTextQuery;
  qualification: Qualification;
  routine?: Maybe<Routine>;
  routineList?: Maybe<Array<Routine>>;
  serviceMeta: ServiceMeta;
  sports: SportsQuery;
  teamCheckReminderScheduleByPage: TeamCheckReminderScheduleByPage;
  teamPlanList: Array<Plan>;
  teamSubscriptionItem?: Maybe<SubscriptionItem>;
};


export type QueryBannerArgs = {
  id: Scalars['ID'];
};


export type QueryBannerListArgs = {
  language: Language;
};


export type QueryBillingArgs = {
  identityToken: Scalars['ID'];
};


export type QueryCouponArgs = {
  id: Scalars['ID'];
};


export type QueryCouponAppliedPriceArgs = {
  input: GetCouponAppliedPriceInput;
};


export type QueryCouponIsGivenListArgs = {
  id: Scalars['ID'];
  input: CheckIsGivenTeamsInput;
};


export type QueryCouponListArgs = {
  isActivated: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
};


export type QueryCouponListByTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryEventForCoachArgs = {
  id: Scalars['ID'];
};


export type QueryEventForPlayerArgs = {
  id: Scalars['ID'];
};


export type QueryFeedbackArgs = {
  id: Scalars['ID'];
};


export type QueryFindFileArgs = {
  id: Scalars['ID'];
};


export type QueryHighestBenefitCouponByTeamArgs = {
  originalPrice: Scalars['Float'];
  teamId: Scalars['ID'];
};


export type QueryInvoiceArgs = {
  paymentId: Scalars['ID'];
  subscriptionId: Scalars['ID'];
};


export type QueryInvoicesByPageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  subscriptionId: Scalars['ID'];
};


export type QueryLicenseByIdArgs = {
  licenseId: Scalars['ID'];
};


export type QueryLicenseByOwnerTokenArgs = {
  ownerToken: Scalars['ID'];
};


export type QueryLicenseByTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryLicensesByPageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryMultilingualTextArgs = {
  input: MultilingualTextInput;
};


export type QueryQualificationArgs = {
  teamId: Scalars['ID'];
};


export type QueryRoutineArgs = {
  routineId: Scalars['ID'];
};


export type QueryRoutineListArgs = {
  cursor?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
};


export type QueryServiceMetaArgs = {
  appId: Scalars['String'];
  os: OsType;
  since: Scalars['String'];
};


export type QueryTeamCheckReminderScheduleByPageArgs = {
  filter?: Maybe<TeamCheckReminderScheduleFilter>;
  limit?: Scalars['Float'];
  offset?: Scalars['Float'];
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryTeamPlanListArgs = {
  couponId?: Maybe<Scalars['String']>;
};


export type QueryTeamSubscriptionItemArgs = {
  teamId: Scalars['ID'];
};

export enum QueryOperation {
  eq = 'eq',
  ilike = 'ilike',
  in = 'in'
}

export type RawDataByCursor = {
  __typename?: 'RawDataByCursor';
  cursor: Scalars['Date'];
  entries: Array<RawDataEntry>;
  names: Array<SportsRawDataName>;
  nextCursor: Scalars['Date'];
  stride: SportsDataStride;
  timezone: Scalars['String'];
};

export type RawDataEntry = SportsRawBody | SportsRawCondition | SportsRawInjury | SportsRawSleep | SportsRawWellness | SportsRawWorkload;

export type Refund = {
  amount?: Maybe<Scalars['Float']>;
  impUid: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
};

export type RefundInvoice = Invoice & {
  __typename?: 'RefundInvoice';
  billingUserEmail: Scalars['String'];
  billingUserName: Scalars['String'];
  billingUserPhone: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  id: Scalars['ID'];
  paymentName: Scalars['String'];
  paymentStatus: PaymentStatus;
  receiptUrl?: Maybe<Scalars['String']>;
  refundAmount: Scalars['Float'];
  refundAt: Scalars['String'];
};

export type RefundResponse = {
  __typename?: 'RefundResponse';
  reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RefundSubscriptionInput = {
  subscriptionId: Scalars['ID'];
};

export type Region = {
  __typename?: 'Region';
  defaultLanguage?: Maybe<Scalars['String']>;
  defaultUTCOffset?: Maybe<Scalars['Int']>;
  id?: Maybe<CountryCode>;
};

export enum ReminderPushMessageType {
  CONDITION = 'CONDITION',
  WORKLOAD = 'WORKLOAD'
}

export type RemoveOrgLicenseTeamInput = {
  licenseId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type RemoveProLicenseTeamInput = {
  licenseId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export enum ReopenStatus {
  DO_NOT_REOPEN = 'DO_NOT_REOPEN',
  REOPEN = 'REOPEN'
}

export enum ReportFileType {
  IMAGE = 'IMAGE',
  PDF = 'PDF'
}

export enum ReportType {
  COORDINATING_DAILY = 'COORDINATING_DAILY',
  COORDINATING_WEEKLY = 'COORDINATING_WEEKLY',
  EVENT_MATCH = 'EVENT_MATCH',
  EVENT_TRAINING = 'EVENT_TRAINING'
}

export type RequestBillingTokenInput = {
  identityToken: Scalars['ID'];
};

export type RestartSubscriptionInput = {
  buyerEmail: Scalars['String'];
  buyerName: Scalars['String'];
  buyerTel: Scalars['String'];
  couponId?: Maybe<Scalars['ID']>;
  planId: Scalars['ID'];
  planOptionId: Scalars['ID'];
  subscriptionId?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type ResumeSubscriptionInput = {
  subscriptionId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type RetryPayment = {
  subscriptionId: Scalars['ID'];
};

export type Return = {
  __typename?: 'Return';
  date: Scalars['String'];
  info?: Maybe<Scalars['String']>;
  status: InjuryReturnStatus;
};

export type ReturnInput = {
  date: Scalars['DateTime'];
  /** 최대 100자 */
  info?: Maybe<Scalars['String']>;
  status: InjuryReturnStatus;
};

export type RiskAtInjuryUser = {
  __typename?: 'RiskAtInjuryUser';
  analysisType: UserWorkloadSuggestionDataType;
  label: UserWorkloadRiskAtInjuryLabelType;
  reasonList: Array<UserWorkloadRiskAtInjuryReasonType>;
  user: SportsUser;
};

export type RiskAtInjuryUserList = {
  __typename?: 'RiskAtInjuryUserList';
  total: Scalars['Int'];
  userList: Array<RiskAtInjuryUser>;
};

export type Routine = {
  __typename?: 'Routine';
  activity?: Maybe<RoutineActivity>;
  id: Scalars['ID'];
  schedule?: Maybe<RoutineSchedule>;
  title: Scalars['String'];
};

export type RoutineActivity = {
  __typename?: 'RoutineActivity';
  hasCompleted: Scalars['Boolean'];
  lastCompletedAt?: Maybe<Scalars['Date']>;
};

export enum RoutineActivityStatus {
  DONE = 'DONE',
  UNDONE = 'UNDONE'
}

export type RoutineSchedule = {
  __typename?: 'RoutineSchedule';
  alertAt: Scalars['String'];
  dayNums: Array<Scalars['Int']>;
  id: Scalars['ID'];
  isAlertEnabled: Scalars['Boolean'];
};

export type RoutineToggle = {
  __typename?: 'RoutineToggle';
  res: Scalars['Boolean'];
};

export type ScheduleInput = {
  alertAt: Scalars['String'];
  dayNums: Array<Scalars['Int']>;
  isAlertEnabled: Scalars['Boolean'];
};

export type ServiceMeta = {
  __typename?: 'ServiceMeta';
  forceUpdate: Scalars['Boolean'];
  higherVersionList: Array<VersionRevision>;
  latestVersion: Scalars['String'];
  versionRevisions: Array<VersionRevision>;
};


export type ServiceMetaVersionRevisionsArgs = {
  appId: Scalars['String'];
  os: OsType;
  since: Scalars['String'];
};

export type SetOrgTeamUsersInput = {
  teamId: Scalars['ID'];
  userIds?: Maybe<Array<Scalars['ID']>>;
};

export enum SimpleOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Soreness = {
  __typename?: 'Soreness';
  bodyPart: SportsBodyPart;
  isContact: Contact;
  level: Scalars['Float'];
  memo: Scalars['String'];
  streak: Scalars['Float'];
};


export type SorenessBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SorenessData = {
  __typename?: 'SorenessData';
  isChecked: Scalars['Boolean'];
  sorenessList: Array<Soreness>;
};

export type SorenessInput = {
  bodyPart: SportsBodyPartName;
  isContact: Contact;
  level: Scalars['Float'];
  /** 최대 50자 */
  memo: Scalars['String'];
};

export type SorenessLevelData = {
  __typename?: 'SorenessLevelData';
  count: Scalars['Float'];
  parts: Array<SorenessParts>;
};

export type SorenessParts = {
  __typename?: 'SorenessParts';
  bodyPart: SportsBodyPart;
  bodyPartGroup: SportsBodyPartGroupName;
};


export type SorenessPartsBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SorenessUser = {
  __typename?: 'SorenessUser';
  sorenessList: Array<Soreness>;
  user: SportsUser;
};

export type SportsBodyPart = {
  __typename?: 'SportsBodyPart';
  back: Scalars['Boolean'];
  front: Scalars['Boolean'];
  group: SportsBodyPartGroup;
  id: SportsBodyPartName;
  left: Scalars['Boolean'];
  right: Scalars['Boolean'];
  value: Scalars['String'];
};

export type SportsBodyPartGroup = {
  __typename?: 'SportsBodyPartGroup';
  id: SportsBodyPartGroupName;
  parts: Array<SportsBodyPart>;
};


export type SportsBodyPartGroupPartsArgs = {
  input: MultilingualTextInput;
};

export enum SportsBodyPartGroupName {
  all_body_part_group__arm = 'all_body_part_group__arm',
  all_body_part_group__head = 'all_body_part_group__head',
  all_body_part_group__leg = 'all_body_part_group__leg',
  all_body_part_group__torso = 'all_body_part_group__torso'
}

export enum SportsBodyPartName {
  all_body_part__back_head = 'all_body_part__back_head',
  all_body_part__back_neck = 'all_body_part__back_neck',
  all_body_part__face = 'all_body_part__face',
  all_body_part__forehead = 'all_body_part__forehead',
  all_body_part__front_neck = 'all_body_part__front_neck',
  all_body_part__left_achilles_tendon = 'all_body_part__left_achilles_tendon',
  all_body_part__left_ankle = 'all_body_part__left_ankle',
  all_body_part__left_back_leg = 'all_body_part__left_back_leg',
  all_body_part__left_back_of_hand = 'all_body_part__left_back_of_hand',
  all_body_part__left_back_of_knee = 'all_body_part__left_back_of_knee',
  all_body_part__left_back_thigh = 'all_body_part__left_back_thigh',
  all_body_part__left_chest = 'all_body_part__left_chest',
  all_body_part__left_elbow = 'all_body_part__left_elbow',
  all_body_part__left_finger = 'all_body_part__left_finger',
  all_body_part__left_forearm = 'all_body_part__left_forearm',
  all_body_part__left_front_knee = 'all_body_part__left_front_knee',
  all_body_part__left_front_thigh = 'all_body_part__left_front_thigh',
  all_body_part__left_groin = 'all_body_part__left_groin',
  all_body_part__left_hip = 'all_body_part__left_hip',
  all_body_part__left_hip_joint = 'all_body_part__left_hip_joint',
  all_body_part__left_instep = 'all_body_part__left_instep',
  all_body_part__left_palm = 'all_body_part__left_palm',
  all_body_part__left_rib = 'all_body_part__left_rib',
  all_body_part__left_shin = 'all_body_part__left_shin',
  all_body_part__left_shoulder = 'all_body_part__left_shoulder',
  all_body_part__left_sole = 'all_body_part__left_sole',
  all_body_part__left_toe = 'all_body_part__left_toe',
  all_body_part__left_upper_arm = 'all_body_part__left_upper_arm',
  all_body_part__left_wing = 'all_body_part__left_wing',
  all_body_part__left_wrist = 'all_body_part__left_wrist',
  all_body_part__lower_abdomen = 'all_body_part__lower_abdomen',
  all_body_part__lower_back = 'all_body_part__lower_back',
  all_body_part__middle_back = 'all_body_part__middle_back',
  all_body_part__reproductive_organs = 'all_body_part__reproductive_organs',
  all_body_part__right_achilles_tendon = 'all_body_part__right_achilles_tendon',
  all_body_part__right_ankle = 'all_body_part__right_ankle',
  all_body_part__right_back_leg = 'all_body_part__right_back_leg',
  all_body_part__right_back_of_hand = 'all_body_part__right_back_of_hand',
  all_body_part__right_back_of_knee = 'all_body_part__right_back_of_knee',
  all_body_part__right_back_thigh = 'all_body_part__right_back_thigh',
  all_body_part__right_chest = 'all_body_part__right_chest',
  all_body_part__right_elbow = 'all_body_part__right_elbow',
  all_body_part__right_finger = 'all_body_part__right_finger',
  all_body_part__right_forearm = 'all_body_part__right_forearm',
  all_body_part__right_front_knee = 'all_body_part__right_front_knee',
  all_body_part__right_front_thigh = 'all_body_part__right_front_thigh',
  all_body_part__right_groin = 'all_body_part__right_groin',
  all_body_part__right_hip = 'all_body_part__right_hip',
  all_body_part__right_hip_joint = 'all_body_part__right_hip_joint',
  all_body_part__right_instep = 'all_body_part__right_instep',
  all_body_part__right_palm = 'all_body_part__right_palm',
  all_body_part__right_rib = 'all_body_part__right_rib',
  all_body_part__right_shin = 'all_body_part__right_shin',
  all_body_part__right_shoulder = 'all_body_part__right_shoulder',
  all_body_part__right_sole = 'all_body_part__right_sole',
  all_body_part__right_toe = 'all_body_part__right_toe',
  all_body_part__right_upper_arm = 'all_body_part__right_upper_arm',
  all_body_part__right_wing = 'all_body_part__right_wing',
  all_body_part__right_wrist = 'all_body_part__right_wrist',
  all_body_part__tailbone = 'all_body_part__tailbone',
  all_body_part__upper_abdomen = 'all_body_part__upper_abdomen',
  all_body_part__upper_back = 'all_body_part__upper_back'
}

export type SportsCareer = {
  __typename?: 'SportsCareer';
  abstract?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  sportsCategory: SportsCategoryCollection;
  sportsPosition?: Maybe<SportsPosition>;
  sportsRole: SportsRole;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  teamName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type SportsCareerCreateInput = {
  abstract?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  sportsCategory: SportsCategory;
  sportsPosition?: Maybe<SportsPosition>;
  sportsRole: SportsRole;
  startAt: Scalars['DateTime'];
  teamId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type SportsCareerUpdateInput = {
  abstract?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  sportsCategory: SportsCategory;
  sportsPosition?: Maybe<SportsPosition>;
  sportsRole: SportsRole;
  startAt: Scalars['DateTime'];
  teamId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export enum SportsCategory {
  sports_category__3x3_basketball = 'sports_category__3x3_basketball',
  sports_category__aerobics = 'sports_category__aerobics',
  sports_category__alpine_skiing = 'sports_category__alpine_skiing',
  sports_category__american_football = 'sports_category__american_football',
  sports_category__archery = 'sports_category__archery',
  sports_category__arm_wrestling = 'sports_category__arm_wrestling',
  sports_category__artistic_gymnastics = 'sports_category__artistic_gymnastics',
  sports_category__artistic_swimming = 'sports_category__artistic_swimming',
  sports_category__badminton = 'sports_category__badminton',
  sports_category__baseball = 'sports_category__baseball',
  sports_category__basketball = 'sports_category__basketball',
  sports_category__biathlon = 'sports_category__biathlon',
  sports_category__billiards = 'sports_category__billiards',
  sports_category__bmx_freestyle = 'sports_category__bmx_freestyle',
  sports_category__bmx_racing = 'sports_category__bmx_racing',
  sports_category__bobsleigh = 'sports_category__bobsleigh',
  sports_category__boccia = 'sports_category__boccia',
  sports_category__bowling = 'sports_category__bowling',
  sports_category__boxing = 'sports_category__boxing',
  sports_category__breaking = 'sports_category__breaking',
  sports_category__canoe = 'sports_category__canoe',
  sports_category__cricket = 'sports_category__cricket',
  sports_category__cross_country_skiing = 'sports_category__cross_country_skiing',
  sports_category__curling = 'sports_category__curling',
  sports_category__cycling_mtb = 'sports_category__cycling_mtb',
  sports_category__cycling_road = 'sports_category__cycling_road',
  sports_category__cycling_track = 'sports_category__cycling_track',
  sports_category__decathlon = 'sports_category__decathlon',
  sports_category__discus_throw = 'sports_category__discus_throw',
  sports_category__diving = 'sports_category__diving',
  sports_category__dressage = 'sports_category__dressage',
  sports_category__etc = 'sports_category__etc',
  sports_category__eventing = 'sports_category__eventing',
  sports_category__fencing = 'sports_category__fencing',
  sports_category__field_hockey = 'sports_category__field_hockey',
  sports_category__fin_swimming = 'sports_category__fin_swimming',
  sports_category__foot_volleyball = 'sports_category__foot_volleyball',
  sports_category__freestyle_skiing = 'sports_category__freestyle_skiing',
  sports_category__futsal = 'sports_category__futsal',
  sports_category__golf = 'sports_category__golf',
  sports_category__hammer_throw = 'sports_category__hammer_throw',
  sports_category__handball = 'sports_category__handball',
  sports_category__heptathlon = 'sports_category__heptathlon',
  sports_category__high_jump = 'sports_category__high_jump',
  sports_category__hurdle = 'sports_category__hurdle',
  sports_category__ice_hockey = 'sports_category__ice_hockey',
  sports_category__ice_skating = 'sports_category__ice_skating',
  sports_category__javelin_throw = 'sports_category__javelin_throw',
  sports_category__judo = 'sports_category__judo',
  sports_category__karate = 'sports_category__karate',
  sports_category__kayak = 'sports_category__kayak',
  sports_category__kickboxing = 'sports_category__kickboxing',
  sports_category__kung_fu = 'sports_category__kung_fu',
  sports_category__lacrosse = 'sports_category__lacrosse',
  sports_category__long_distance_running = 'sports_category__long_distance_running',
  sports_category__long_jump = 'sports_category__long_jump',
  sports_category__luge = 'sports_category__luge',
  sports_category__marathon = 'sports_category__marathon',
  sports_category__marathon_swimming = 'sports_category__marathon_swimming',
  sports_category__middle_distance_running = 'sports_category__middle_distance_running',
  sports_category__modern_pentathlon = 'sports_category__modern_pentathlon',
  sports_category__nordic_skiing = 'sports_category__nordic_skiing',
  sports_category__pole_vault = 'sports_category__pole_vault',
  sports_category__race_walking = 'sports_category__race_walking',
  sports_category__racquetball = 'sports_category__racquetball',
  sports_category__rhythmic_gymnastics = 'sports_category__rhythmic_gymnastics',
  sports_category__roller = 'sports_category__roller',
  sports_category__rowing = 'sports_category__rowing',
  sports_category__rugby = 'sports_category__rugby',
  sports_category__rugby_sevens = 'sports_category__rugby_sevens',
  sports_category__sepak_takraw = 'sports_category__sepak_takraw',
  sports_category__shooting = 'sports_category__shooting',
  sports_category__shot_put = 'sports_category__shot_put',
  sports_category__show_jumping = 'sports_category__show_jumping',
  sports_category__skateboard = 'sports_category__skateboard',
  sports_category__skeleton = 'sports_category__skeleton',
  sports_category__ski_jumping = 'sports_category__ski_jumping',
  sports_category__ski_mountaineering = 'sports_category__ski_mountaineering',
  sports_category__snowboarding = 'sports_category__snowboarding',
  sports_category__soccer = 'sports_category__soccer',
  sports_category__soft_tennis = 'sports_category__soft_tennis',
  sports_category__softball = 'sports_category__softball',
  sports_category__sports_climbing = 'sports_category__sports_climbing',
  sports_category__sprint = 'sports_category__sprint',
  sports_category__squash = 'sports_category__squash',
  sports_category__ssireum = 'sports_category__ssireum',
  sports_category__surfing = 'sports_category__surfing',
  sports_category__swimming = 'sports_category__swimming',
  sports_category__table_tennis = 'sports_category__table_tennis',
  sports_category__taekwondo = 'sports_category__taekwondo',
  sports_category__takkyeon = 'sports_category__takkyeon',
  sports_category__tee_ball = 'sports_category__tee_ball',
  sports_category__tennis = 'sports_category__tennis',
  sports_category__trampoline = 'sports_category__trampoline',
  sports_category__triathlon = 'sports_category__triathlon',
  sports_category__triple_jump = 'sports_category__triple_jump',
  sports_category__volleyball = 'sports_category__volleyball',
  sports_category__water_polo = 'sports_category__water_polo',
  sports_category__water_skiing = 'sports_category__water_skiing',
  sports_category__weightlifting = 'sports_category__weightlifting',
  sports_category__wrestling = 'sports_category__wrestling',
  sports_category__yacht = 'sports_category__yacht'
}

export type SportsCategoryCollection = {
  __typename?: 'SportsCategoryCollection';
  coachPositions: Array<SportsPosition>;
  group?: Maybe<SportsCategoryGroup>;
  id?: Maybe<SportsCategory>;
  playerPositions: Array<SportsPosition>;
};

export enum SportsCategoryGroup {
  sports_category_group__archery = 'sports_category_group__archery',
  sports_category_group__athletics = 'sports_category_group__athletics',
  sports_category_group__ball = 'sports_category_group__ball',
  sports_category_group__combat = 'sports_category_group__combat',
  sports_category_group__cycling = 'sports_category_group__cycling',
  sports_category_group__equestrian = 'sports_category_group__equestrian',
  sports_category_group__etc = 'sports_category_group__etc',
  sports_category_group__fencing = 'sports_category_group__fencing',
  sports_category_group__gymnastics = 'sports_category_group__gymnastics',
  sports_category_group__modern_pentathlon = 'sports_category_group__modern_pentathlon',
  sports_category_group__racket = 'sports_category_group__racket',
  sports_category_group__roller = 'sports_category_group__roller',
  sports_category_group__shooting = 'sports_category_group__shooting',
  sports_category_group__triathlon = 'sports_category_group__triathlon',
  sports_category_group__water = 'sports_category_group__water',
  sports_category_group__weightlifting = 'sports_category_group__weightlifting',
  sports_category_group__winter = 'sports_category_group__winter'
}

export type SportsCategoryGroupCollection = {
  __typename?: 'SportsCategoryGroupCollection';
  categories: Array<SportsCategoryCollection>;
  id: SportsCategoryGroup;
};

export type SportsClient = OrgTeam | SportsTeam;

export type SportsData = {
  __typename?: 'SportsData';
  body?: Maybe<BodyData>;
  condition?: Maybe<Condition>;
  date: Scalars['DateTimeString'];
  id?: Maybe<Scalars['ID']>;
  soreness: SorenessData;
  user: SportsUser;
  workload?: Maybe<WorkloadData>;
};


export type SportsDataBodyArgs = {
  heightUnit?: HeightUnit;
  weightUnit?: WeightUnit;
};

export type SportsDataAlarm = Alarm & {
  __typename?: 'SportsDataAlarm';
  createUser: SportsUser;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  player: SportsUser;
  team?: Maybe<SportsTeam>;
  type: AlarmType;
};

export type SportsDataEntry = {
  __typename?: 'SportsDataEntry';
  time: Scalars['DateTimeString'];
  value?: Maybe<Scalars['JSON']>;
};

export enum SportsDataGroup {
  BODY = 'BODY',
  CONDITION = 'CONDITION',
  INJURY = 'INJURY',
  READINESS = 'READINESS',
  WORKLOAD = 'WORKLOAD'
}

export enum SportsDataName {
  BODY = 'BODY',
  BODY_MONTHLY_STAT = 'BODY_MONTHLY_STAT',
  BODY_WEEKLY_STAT = 'BODY_WEEKLY_STAT',
  CONDITION = 'CONDITION',
  CONDITION_AX = 'CONDITION_AX',
  CONDITION_CX = 'CONDITION_CX',
  CONDITION_MONTHLY_AVG = 'CONDITION_MONTHLY_AVG',
  CONDITION_ROLLING_AVG_7DAY = 'CONDITION_ROLLING_AVG_7DAY',
  CONDITION_ROLLING_AVG_28DAY = 'CONDITION_ROLLING_AVG_28DAY',
  CONDITION_WEEKLY_AVG = 'CONDITION_WEEKLY_AVG',
  INJURY = 'INJURY',
  RAW_BODY = 'RAW_BODY',
  RAW_INJURY = 'RAW_INJURY',
  RAW_WORKLOAD = 'RAW_WORKLOAD',
  READINESS = 'READINESS',
  SLEEP_MONTHLY_STAT = 'SLEEP_MONTHLY_STAT',
  SLEEP_ROLLING_AVG_7DAY = 'SLEEP_ROLLING_AVG_7DAY',
  SLEEP_ROLLING_AVG_28DAY = 'SLEEP_ROLLING_AVG_28DAY',
  SLEEP_WEEKLY_STAT = 'SLEEP_WEEKLY_STAT',
  SORENESS = 'SORENESS',
  WELLNESS_MONTHLY_STAT = 'WELLNESS_MONTHLY_STAT',
  WELLNESS_ROLLING_AVG_7DAY = 'WELLNESS_ROLLING_AVG_7DAY',
  WELLNESS_ROLLING_AVG_28DAY = 'WELLNESS_ROLLING_AVG_28DAY',
  WELLNESS_WEEKLY_STAT = 'WELLNESS_WEEKLY_STAT',
  WORKLOAD = 'WORKLOAD',
  WORKLOAD_ACXR = 'WORKLOAD_ACXR',
  WORKLOAD_ACXRAXD_ROLLING_AVG_7DAY = 'WORKLOAD_ACXRAXD_ROLLING_AVG_7DAY',
  WORKLOAD_ACXRAXD_ROLLING_AVG_28DAY = 'WORKLOAD_ACXRAXD_ROLLING_AVG_28DAY',
  WORKLOAD_AX = 'WORKLOAD_AX',
  WORKLOAD_AXD = 'WORKLOAD_AXD',
  WORKLOAD_AXI = 'WORKLOAD_AXI',
  WORKLOAD_CX = 'WORKLOAD_CX',
  WORKLOAD_MONTHLY_STAT = 'WORKLOAD_MONTHLY_STAT',
  WORKLOAD_ROLLING_AVG_7DAY = 'WORKLOAD_ROLLING_AVG_7DAY',
  WORKLOAD_ROLLING_AVG_28DAY = 'WORKLOAD_ROLLING_AVG_28DAY',
  WORKLOAD_WEEKLY_STAT = 'WORKLOAD_WEEKLY_STAT',
  WORKLOAD_X = 'WORKLOAD_X'
}

export type SportsDataRange = {
  __typename?: 'SportsDataRange';
  entries: Array<SportsDataEntry>;
  from: Scalars['DateTimeString'];
  to: Scalars['DateTimeString'];
};

export enum SportsDataStride {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}

export enum SportsInjuryQuality {
  BURNING = 'BURNING',
  COMES_AND_GOES = 'COMES_AND_GOES',
  CRAMPING = 'CRAMPING',
  CUTTING = 'CUTTING',
  DULL = 'DULL',
  NUMBING = 'NUMBING',
  PRESSING = 'PRESSING',
  RADIATING = 'RADIATING',
  SHARP = 'SHARP',
  TINGLING = 'TINGLING'
}

export enum SportsInjuryTiming {
  GRADUAL = 'GRADUAL',
  OCCASIONAL = 'OCCASIONAL',
  SUDDEN = 'SUDDEN',
  SUSTAINING = 'SUSTAINING'
}

export enum SportsPosition {
  coach_position__3x3_basketball__assistant_coach = 'coach_position__3x3_basketball__assistant_coach',
  coach_position__3x3_basketball__athletic_trainer = 'coach_position__3x3_basketball__athletic_trainer',
  coach_position__3x3_basketball__coach = 'coach_position__3x3_basketball__coach',
  coach_position__3x3_basketball__head_coach = 'coach_position__3x3_basketball__head_coach',
  coach_position__3x3_basketball__playing_coach = 'coach_position__3x3_basketball__playing_coach',
  coach_position__aerobics__athletic_trainer = 'coach_position__aerobics__athletic_trainer',
  coach_position__aerobics__coach = 'coach_position__aerobics__coach',
  coach_position__aerobics__first_coach = 'coach_position__aerobics__first_coach',
  coach_position__aerobics__head_coach = 'coach_position__aerobics__head_coach',
  coach_position__aerobics__playing_coach = 'coach_position__aerobics__playing_coach',
  coach_position__alpine_skiing__athletic_trainer = 'coach_position__alpine_skiing__athletic_trainer',
  coach_position__alpine_skiing__coach = 'coach_position__alpine_skiing__coach',
  coach_position__alpine_skiing__first_coach = 'coach_position__alpine_skiing__first_coach',
  coach_position__alpine_skiing__head_coach = 'coach_position__alpine_skiing__head_coach',
  coach_position__alpine_skiing__playing_coach = 'coach_position__alpine_skiing__playing_coach',
  coach_position__american_football__assistant_coach = 'coach_position__american_football__assistant_coach',
  coach_position__american_football__athletic_trainer = 'coach_position__american_football__athletic_trainer',
  coach_position__american_football__coach = 'coach_position__american_football__coach',
  coach_position__american_football__defensive_coach = 'coach_position__american_football__defensive_coach',
  coach_position__american_football__fitness_coach = 'coach_position__american_football__fitness_coach',
  coach_position__american_football__head_coach = 'coach_position__american_football__head_coach',
  coach_position__american_football__offensive_coach = 'coach_position__american_football__offensive_coach',
  coach_position__american_football__playing_coach = 'coach_position__american_football__playing_coach',
  coach_position__american_football__scrum_coach = 'coach_position__american_football__scrum_coach',
  coach_position__american_football__skill_coach = 'coach_position__american_football__skill_coach',
  coach_position__american_football__strength_and_conditioning_coach = 'coach_position__american_football__strength_and_conditioning_coach',
  coach_position__archery__assistant_coach = 'coach_position__archery__assistant_coach',
  coach_position__archery__athletic_trainer = 'coach_position__archery__athletic_trainer',
  coach_position__archery__coach = 'coach_position__archery__coach',
  coach_position__archery__head_coach = 'coach_position__archery__head_coach',
  coach_position__archery__playing_coach = 'coach_position__archery__playing_coach',
  coach_position__arm_wrestling__athletic_trainer = 'coach_position__arm_wrestling__athletic_trainer',
  coach_position__arm_wrestling__coach = 'coach_position__arm_wrestling__coach',
  coach_position__arm_wrestling__first_coach = 'coach_position__arm_wrestling__first_coach',
  coach_position__arm_wrestling__head_coach = 'coach_position__arm_wrestling__head_coach',
  coach_position__arm_wrestling__playing_coach = 'coach_position__arm_wrestling__playing_coach',
  coach_position__artistic_gymnastics__athletic_trainer = 'coach_position__artistic_gymnastics__athletic_trainer',
  coach_position__artistic_gymnastics__coach = 'coach_position__artistic_gymnastics__coach',
  coach_position__artistic_gymnastics__first_coach = 'coach_position__artistic_gymnastics__first_coach',
  coach_position__artistic_gymnastics__head_coach = 'coach_position__artistic_gymnastics__head_coach',
  coach_position__artistic_gymnastics__playing_coach = 'coach_position__artistic_gymnastics__playing_coach',
  coach_position__artistic_swimming__athletic_trainer = 'coach_position__artistic_swimming__athletic_trainer',
  coach_position__artistic_swimming__coach = 'coach_position__artistic_swimming__coach',
  coach_position__artistic_swimming__first_coach = 'coach_position__artistic_swimming__first_coach',
  coach_position__artistic_swimming__head_coach = 'coach_position__artistic_swimming__head_coach',
  coach_position__badminton__assistant_coach = 'coach_position__badminton__assistant_coach',
  coach_position__badminton__athletic_trainer = 'coach_position__badminton__athletic_trainer',
  coach_position__badminton__coach = 'coach_position__badminton__coach',
  coach_position__badminton__head_coach = 'coach_position__badminton__head_coach',
  coach_position__baseball__athletic_trainer = 'coach_position__baseball__athletic_trainer',
  coach_position__baseball__base_coach = 'coach_position__baseball__base_coach',
  coach_position__baseball__bench_coach = 'coach_position__baseball__bench_coach',
  coach_position__baseball__coach = 'coach_position__baseball__coach',
  coach_position__baseball__defensive_coach = 'coach_position__baseball__defensive_coach',
  coach_position__baseball__head_coach = 'coach_position__baseball__head_coach',
  coach_position__baseball__hitting_coach = 'coach_position__baseball__hitting_coach',
  coach_position__baseball__pitching_coach = 'coach_position__baseball__pitching_coach',
  coach_position__baseball__playing_coach = 'coach_position__baseball__playing_coach',
  coach_position__basketball__assistant_coach = 'coach_position__basketball__assistant_coach',
  coach_position__basketball__athletic_trainer = 'coach_position__basketball__athletic_trainer',
  coach_position__basketball__coach = 'coach_position__basketball__coach',
  coach_position__basketball__first_coach = 'coach_position__basketball__first_coach',
  coach_position__basketball__head_coach = 'coach_position__basketball__head_coach',
  coach_position__basketball__playing_coach = 'coach_position__basketball__playing_coach',
  coach_position__biathlon__athletic_trainer = 'coach_position__biathlon__athletic_trainer',
  coach_position__biathlon__coach = 'coach_position__biathlon__coach',
  coach_position__biathlon__first_coach = 'coach_position__biathlon__first_coach',
  coach_position__biathlon__head_coach = 'coach_position__biathlon__head_coach',
  coach_position__biathlon__playing_coach = 'coach_position__biathlon__playing_coach',
  coach_position__billiards__athletic_trainer = 'coach_position__billiards__athletic_trainer',
  coach_position__billiards__coach = 'coach_position__billiards__coach',
  coach_position__bmx_freestyle__assistant_coach = 'coach_position__bmx_freestyle__assistant_coach',
  coach_position__bmx_freestyle__athletic_trainer = 'coach_position__bmx_freestyle__athletic_trainer',
  coach_position__bmx_freestyle__coach = 'coach_position__bmx_freestyle__coach',
  coach_position__bmx_freestyle__head_coach = 'coach_position__bmx_freestyle__head_coach',
  coach_position__bmx_freestyle__playing_coach = 'coach_position__bmx_freestyle__playing_coach',
  coach_position__bmx_racing__assistant_coach = 'coach_position__bmx_racing__assistant_coach',
  coach_position__bmx_racing__athletic_trainer = 'coach_position__bmx_racing__athletic_trainer',
  coach_position__bmx_racing__coach = 'coach_position__bmx_racing__coach',
  coach_position__bmx_racing__head_coach = 'coach_position__bmx_racing__head_coach',
  coach_position__bmx_racing__playing_coach = 'coach_position__bmx_racing__playing_coach',
  coach_position__bobsleigh__athletic_trainer = 'coach_position__bobsleigh__athletic_trainer',
  coach_position__bobsleigh__coach = 'coach_position__bobsleigh__coach',
  coach_position__bobsleigh__first_coach = 'coach_position__bobsleigh__first_coach',
  coach_position__bobsleigh__head_coach = 'coach_position__bobsleigh__head_coach',
  coach_position__bobsleigh__playing_coach = 'coach_position__bobsleigh__playing_coach',
  coach_position__boccia__athletic_trainer = 'coach_position__boccia__athletic_trainer',
  coach_position__boccia__coach = 'coach_position__boccia__coach',
  coach_position__boccia__first_coach = 'coach_position__boccia__first_coach',
  coach_position__boccia__head_coach = 'coach_position__boccia__head_coach',
  coach_position__boccia__playing_coach = 'coach_position__boccia__playing_coach',
  coach_position__bowling__athletic_trainer = 'coach_position__bowling__athletic_trainer',
  coach_position__bowling__coach = 'coach_position__bowling__coach',
  coach_position__bowling__first_coach = 'coach_position__bowling__first_coach',
  coach_position__bowling__head_coach = 'coach_position__bowling__head_coach',
  coach_position__bowling__playing_coach = 'coach_position__bowling__playing_coach',
  coach_position__boxing__athletic_trainer = 'coach_position__boxing__athletic_trainer',
  coach_position__boxing__coach = 'coach_position__boxing__coach',
  coach_position__boxing__first_coach = 'coach_position__boxing__first_coach',
  coach_position__boxing__head_coach = 'coach_position__boxing__head_coach',
  coach_position__boxing__playing_coach = 'coach_position__boxing__playing_coach',
  coach_position__breaking__athletic_trainer = 'coach_position__breaking__athletic_trainer',
  coach_position__breaking__coach = 'coach_position__breaking__coach',
  coach_position__breaking__head_coach = 'coach_position__breaking__head_coach',
  coach_position__breaking__playing_coach = 'coach_position__breaking__playing_coach',
  coach_position__canoe__athletic_trainer = 'coach_position__canoe__athletic_trainer',
  coach_position__canoe__coach = 'coach_position__canoe__coach',
  coach_position__canoe__first_coach = 'coach_position__canoe__first_coach',
  coach_position__canoe__head_coach = 'coach_position__canoe__head_coach',
  coach_position__cricket__athletic_trainer = 'coach_position__cricket__athletic_trainer',
  coach_position__cricket__coach = 'coach_position__cricket__coach',
  coach_position__cricket__first_coach = 'coach_position__cricket__first_coach',
  coach_position__cricket__head_coach = 'coach_position__cricket__head_coach',
  coach_position__cricket__playing_coach = 'coach_position__cricket__playing_coach',
  coach_position__cross_country_skiing__athletic_trainer = 'coach_position__cross_country_skiing__athletic_trainer',
  coach_position__cross_country_skiing__coach = 'coach_position__cross_country_skiing__coach',
  coach_position__cross_country_skiing__first_coach = 'coach_position__cross_country_skiing__first_coach',
  coach_position__cross_country_skiing__head_coach = 'coach_position__cross_country_skiing__head_coach',
  coach_position__cross_country_skiing__playing_coach = 'coach_position__cross_country_skiing__playing_coach',
  coach_position__curling__athletic_trainer = 'coach_position__curling__athletic_trainer',
  coach_position__curling__coach = 'coach_position__curling__coach',
  coach_position__curling__first_coach = 'coach_position__curling__first_coach',
  coach_position__curling__head_coach = 'coach_position__curling__head_coach',
  coach_position__curling__playing_coach = 'coach_position__curling__playing_coach',
  coach_position__cycling_mtb__assistant_coach = 'coach_position__cycling_mtb__assistant_coach',
  coach_position__cycling_mtb__athletic_trainer = 'coach_position__cycling_mtb__athletic_trainer',
  coach_position__cycling_mtb__coach = 'coach_position__cycling_mtb__coach',
  coach_position__cycling_mtb__head_coach = 'coach_position__cycling_mtb__head_coach',
  coach_position__cycling_mtb__playing_coach = 'coach_position__cycling_mtb__playing_coach',
  coach_position__cycling_road__assistant_coach = 'coach_position__cycling_road__assistant_coach',
  coach_position__cycling_road__athletic_trainer = 'coach_position__cycling_road__athletic_trainer',
  coach_position__cycling_road__coach = 'coach_position__cycling_road__coach',
  coach_position__cycling_road__head_coach = 'coach_position__cycling_road__head_coach',
  coach_position__cycling_road__playing_coach = 'coach_position__cycling_road__playing_coach',
  coach_position__cycling_track__assistant_coach = 'coach_position__cycling_track__assistant_coach',
  coach_position__cycling_track__athletic_trainer = 'coach_position__cycling_track__athletic_trainer',
  coach_position__cycling_track__coach = 'coach_position__cycling_track__coach',
  coach_position__cycling_track__head_coach = 'coach_position__cycling_track__head_coach',
  coach_position__cycling_track__playing_coach = 'coach_position__cycling_track__playing_coach',
  coach_position__discus_throw__athletic_trainer = 'coach_position__discus_throw__athletic_trainer',
  coach_position__discus_throw__coach = 'coach_position__discus_throw__coach',
  coach_position__discus_throw__first_coach = 'coach_position__discus_throw__first_coach',
  coach_position__discus_throw__head_coach = 'coach_position__discus_throw__head_coach',
  coach_position__discus_throw__playing_coach = 'coach_position__discus_throw__playing_coach',
  coach_position__diving__athletic_trainer = 'coach_position__diving__athletic_trainer',
  coach_position__diving__coach = 'coach_position__diving__coach',
  coach_position__diving__first_coach = 'coach_position__diving__first_coach',
  coach_position__diving__head_coach = 'coach_position__diving__head_coach',
  coach_position__dressage__assistant_coach = 'coach_position__dressage__assistant_coach',
  coach_position__dressage__athletic_trainer = 'coach_position__dressage__athletic_trainer',
  coach_position__dressage__coach = 'coach_position__dressage__coach',
  coach_position__dressage__head_coach = 'coach_position__dressage__head_coach',
  coach_position__dressage__playing_coach = 'coach_position__dressage__playing_coach',
  coach_position__etc__athletic_trainer = 'coach_position__etc__athletic_trainer',
  coach_position__etc__coach = 'coach_position__etc__coach',
  coach_position__etc__head_coach = 'coach_position__etc__head_coach',
  coach_position__etc__playing_coach = 'coach_position__etc__playing_coach',
  coach_position__eventing__assistant_coach = 'coach_position__eventing__assistant_coach',
  coach_position__eventing__athletic_trainer = 'coach_position__eventing__athletic_trainer',
  coach_position__eventing__coach = 'coach_position__eventing__coach',
  coach_position__eventing__head_coach = 'coach_position__eventing__head_coach',
  coach_position__eventing__playing_coach = 'coach_position__eventing__playing_coach',
  coach_position__fencing__athletic_trainer = 'coach_position__fencing__athletic_trainer',
  coach_position__fencing__coach = 'coach_position__fencing__coach',
  coach_position__fencing__first_coach = 'coach_position__fencing__first_coach',
  coach_position__fencing__head_coach = 'coach_position__fencing__head_coach',
  coach_position__fencing__playing_coach = 'coach_position__fencing__playing_coach',
  coach_position__field_hockey__assistant_coach = 'coach_position__field_hockey__assistant_coach',
  coach_position__field_hockey__athletic_trainer = 'coach_position__field_hockey__athletic_trainer',
  coach_position__field_hockey__coach = 'coach_position__field_hockey__coach',
  coach_position__field_hockey__gk_coach = 'coach_position__field_hockey__gk_coach',
  coach_position__field_hockey__head_coach = 'coach_position__field_hockey__head_coach',
  coach_position__field_hockey__playing_coach = 'coach_position__field_hockey__playing_coach',
  coach_position__fin_swimming__athletic_trainer = 'coach_position__fin_swimming__athletic_trainer',
  coach_position__fin_swimming__coach = 'coach_position__fin_swimming__coach',
  coach_position__fin_swimming__first_coach = 'coach_position__fin_swimming__first_coach',
  coach_position__fin_swimming__head_coach = 'coach_position__fin_swimming__head_coach',
  coach_position__foot_volleyball__athletic_trainer = 'coach_position__foot_volleyball__athletic_trainer',
  coach_position__foot_volleyball__coach = 'coach_position__foot_volleyball__coach',
  coach_position__foot_volleyball__first_coach = 'coach_position__foot_volleyball__first_coach',
  coach_position__foot_volleyball__head_coach = 'coach_position__foot_volleyball__head_coach',
  coach_position__foot_volleyball__playing_coach = 'coach_position__foot_volleyball__playing_coach',
  coach_position__freestyle_skiing__athletic_trainer = 'coach_position__freestyle_skiing__athletic_trainer',
  coach_position__freestyle_skiing__coach = 'coach_position__freestyle_skiing__coach',
  coach_position__freestyle_skiing__first_coach = 'coach_position__freestyle_skiing__first_coach',
  coach_position__freestyle_skiing__head_coach = 'coach_position__freestyle_skiing__head_coach',
  coach_position__freestyle_skiing__playing_coach = 'coach_position__freestyle_skiing__playing_coach',
  coach_position__futsal__athletic_trainer = 'coach_position__futsal__athletic_trainer',
  coach_position__futsal__coach = 'coach_position__futsal__coach',
  coach_position__futsal__first_coach = 'coach_position__futsal__first_coach',
  coach_position__futsal__head_coach = 'coach_position__futsal__head_coach',
  coach_position__futsal__playing_coach = 'coach_position__futsal__playing_coach',
  coach_position__golf__athletic_trainer = 'coach_position__golf__athletic_trainer',
  coach_position__golf__coach = 'coach_position__golf__coach',
  coach_position__hammer_throw__athletic_trainer = 'coach_position__hammer_throw__athletic_trainer',
  coach_position__hammer_throw__coach = 'coach_position__hammer_throw__coach',
  coach_position__hammer_throw__first_coach = 'coach_position__hammer_throw__first_coach',
  coach_position__hammer_throw__head_coach = 'coach_position__hammer_throw__head_coach',
  coach_position__hammer_throw__playing_coach = 'coach_position__hammer_throw__playing_coach',
  coach_position__handball__assistant_coach = 'coach_position__handball__assistant_coach',
  coach_position__handball__athletic_trainer = 'coach_position__handball__athletic_trainer',
  coach_position__handball__coach = 'coach_position__handball__coach',
  coach_position__handball__first_coach = 'coach_position__handball__first_coach',
  coach_position__handball__head_coach = 'coach_position__handball__head_coach',
  coach_position__handball__playing_coach = 'coach_position__handball__playing_coach',
  coach_position__high_jump__athletic_trainer = 'coach_position__high_jump__athletic_trainer',
  coach_position__high_jump__coach = 'coach_position__high_jump__coach',
  coach_position__high_jump__first_coach = 'coach_position__high_jump__first_coach',
  coach_position__high_jump__head_coach = 'coach_position__high_jump__head_coach',
  coach_position__high_jump__playing_coach = 'coach_position__high_jump__playing_coach',
  coach_position__hurdle__athletic_trainer = 'coach_position__hurdle__athletic_trainer',
  coach_position__hurdle__coach = 'coach_position__hurdle__coach',
  coach_position__hurdle__first_coach = 'coach_position__hurdle__first_coach',
  coach_position__hurdle__head_coach = 'coach_position__hurdle__head_coach',
  coach_position__hurdle__playing_coach = 'coach_position__hurdle__playing_coach',
  coach_position__ice_hockey__assistant_coach = 'coach_position__ice_hockey__assistant_coach',
  coach_position__ice_hockey__athletic_trainer = 'coach_position__ice_hockey__athletic_trainer',
  coach_position__ice_hockey__coach = 'coach_position__ice_hockey__coach',
  coach_position__ice_hockey__gk_coach = 'coach_position__ice_hockey__gk_coach',
  coach_position__ice_hockey__head_coach = 'coach_position__ice_hockey__head_coach',
  coach_position__ice_hockey__playing_coach = 'coach_position__ice_hockey__playing_coach',
  coach_position__ice_skating__athletic_trainer = 'coach_position__ice_skating__athletic_trainer',
  coach_position__ice_skating__coach = 'coach_position__ice_skating__coach',
  coach_position__ice_skating__first_coach = 'coach_position__ice_skating__first_coach',
  coach_position__ice_skating__head_coach = 'coach_position__ice_skating__head_coach',
  coach_position__ice_skating__playing_coach = 'coach_position__ice_skating__playing_coach',
  coach_position__javelin_throw__athletic_trainer = 'coach_position__javelin_throw__athletic_trainer',
  coach_position__javelin_throw__coach = 'coach_position__javelin_throw__coach',
  coach_position__javelin_throw__first_coach = 'coach_position__javelin_throw__first_coach',
  coach_position__javelin_throw__head_coach = 'coach_position__javelin_throw__head_coach',
  coach_position__javelin_throw__playing_coach = 'coach_position__javelin_throw__playing_coach',
  coach_position__judo__athletic_trainer = 'coach_position__judo__athletic_trainer',
  coach_position__judo__coach = 'coach_position__judo__coach',
  coach_position__judo__first_coach = 'coach_position__judo__first_coach',
  coach_position__judo__head_coach = 'coach_position__judo__head_coach',
  coach_position__judo__playing_coach = 'coach_position__judo__playing_coach',
  coach_position__karate__athletic_trainer = 'coach_position__karate__athletic_trainer',
  coach_position__karate__coach = 'coach_position__karate__coach',
  coach_position__karate__first_coach = 'coach_position__karate__first_coach',
  coach_position__karate__head_coach = 'coach_position__karate__head_coach',
  coach_position__karate__playing_coach = 'coach_position__karate__playing_coach',
  coach_position__kayak__athletic_trainer = 'coach_position__kayak__athletic_trainer',
  coach_position__kayak__coach = 'coach_position__kayak__coach',
  coach_position__kayak__first_coach = 'coach_position__kayak__first_coach',
  coach_position__kayak__head_coach = 'coach_position__kayak__head_coach',
  coach_position__kickboxing__athletic_trainer = 'coach_position__kickboxing__athletic_trainer',
  coach_position__kickboxing__coach = 'coach_position__kickboxing__coach',
  coach_position__kickboxing__first_coach = 'coach_position__kickboxing__first_coach',
  coach_position__kickboxing__head_coach = 'coach_position__kickboxing__head_coach',
  coach_position__kickboxing__playing_coach = 'coach_position__kickboxing__playing_coach',
  coach_position__kung_fu__athletic_trainer = 'coach_position__kung_fu__athletic_trainer',
  coach_position__kung_fu__coach = 'coach_position__kung_fu__coach',
  coach_position__kung_fu__first_coach = 'coach_position__kung_fu__first_coach',
  coach_position__kung_fu__head_coach = 'coach_position__kung_fu__head_coach',
  coach_position__kung_fu__playing_coach = 'coach_position__kung_fu__playing_coach',
  coach_position__lacrosse__assistant_coach = 'coach_position__lacrosse__assistant_coach',
  coach_position__lacrosse__athletic_trainer = 'coach_position__lacrosse__athletic_trainer',
  coach_position__lacrosse__coach = 'coach_position__lacrosse__coach',
  coach_position__lacrosse__gk_coach = 'coach_position__lacrosse__gk_coach',
  coach_position__lacrosse__head_coach = 'coach_position__lacrosse__head_coach',
  coach_position__lacrosse__playing_coach = 'coach_position__lacrosse__playing_coach',
  coach_position__long_distance_running__athletic_trainer = 'coach_position__long_distance_running__athletic_trainer',
  coach_position__long_distance_running__coach = 'coach_position__long_distance_running__coach',
  coach_position__long_distance_running__first_coach = 'coach_position__long_distance_running__first_coach',
  coach_position__long_distance_running__head_coach = 'coach_position__long_distance_running__head_coach',
  coach_position__long_distance_running__playing_coach = 'coach_position__long_distance_running__playing_coach',
  coach_position__long_jump__athletic_trainer = 'coach_position__long_jump__athletic_trainer',
  coach_position__long_jump__coach = 'coach_position__long_jump__coach',
  coach_position__long_jump__first_coach = 'coach_position__long_jump__first_coach',
  coach_position__long_jump__head_coach = 'coach_position__long_jump__head_coach',
  coach_position__long_jump__playing_coach = 'coach_position__long_jump__playing_coach',
  coach_position__luge__athletic_trainer = 'coach_position__luge__athletic_trainer',
  coach_position__luge__coach = 'coach_position__luge__coach',
  coach_position__luge__first_coach = 'coach_position__luge__first_coach',
  coach_position__luge__head_coach = 'coach_position__luge__head_coach',
  coach_position__luge__playing_coach = 'coach_position__luge__playing_coach',
  coach_position__marathon__athletic_trainer = 'coach_position__marathon__athletic_trainer',
  coach_position__marathon__coach = 'coach_position__marathon__coach',
  coach_position__marathon__first_coach = 'coach_position__marathon__first_coach',
  coach_position__marathon__head_coach = 'coach_position__marathon__head_coach',
  coach_position__marathon__playing_coach = 'coach_position__marathon__playing_coach',
  coach_position__marathon_swimming__athletic_trainer = 'coach_position__marathon_swimming__athletic_trainer',
  coach_position__marathon_swimming__coach = 'coach_position__marathon_swimming__coach',
  coach_position__marathon_swimming__first_coach = 'coach_position__marathon_swimming__first_coach',
  coach_position__marathon_swimming__head_coach = 'coach_position__marathon_swimming__head_coach',
  coach_position__men_decathlon__athletic_trainer = 'coach_position__men_decathlon__athletic_trainer',
  coach_position__men_decathlon__coach = 'coach_position__men_decathlon__coach',
  coach_position__men_decathlon__first_coach = 'coach_position__men_decathlon__first_coach',
  coach_position__men_decathlon__head_coach = 'coach_position__men_decathlon__head_coach',
  coach_position__men_decathlon__playing_coach = 'coach_position__men_decathlon__playing_coach',
  coach_position__middle_distance_running__athletic_trainer = 'coach_position__middle_distance_running__athletic_trainer',
  coach_position__middle_distance_running__coach = 'coach_position__middle_distance_running__coach',
  coach_position__middle_distance_running__first_coach = 'coach_position__middle_distance_running__first_coach',
  coach_position__middle_distance_running__head_coach = 'coach_position__middle_distance_running__head_coach',
  coach_position__middle_distance_running__playing_coach = 'coach_position__middle_distance_running__playing_coach',
  coach_position__modern_pentathlon__athletic_trainer = 'coach_position__modern_pentathlon__athletic_trainer',
  coach_position__modern_pentathlon__coach = 'coach_position__modern_pentathlon__coach',
  coach_position__modern_pentathlon__first_coach = 'coach_position__modern_pentathlon__first_coach',
  coach_position__modern_pentathlon__head_coach = 'coach_position__modern_pentathlon__head_coach',
  coach_position__modern_pentathlon__playing_coach = 'coach_position__modern_pentathlon__playing_coach',
  coach_position__nordic_skiing__athletic_trainer = 'coach_position__nordic_skiing__athletic_trainer',
  coach_position__nordic_skiing__coach = 'coach_position__nordic_skiing__coach',
  coach_position__nordic_skiing__first_coach = 'coach_position__nordic_skiing__first_coach',
  coach_position__nordic_skiing__head_coach = 'coach_position__nordic_skiing__head_coach',
  coach_position__nordic_skiing__playing_coach = 'coach_position__nordic_skiing__playing_coach',
  coach_position__pole_vault__athletic_trainer = 'coach_position__pole_vault__athletic_trainer',
  coach_position__pole_vault__coach = 'coach_position__pole_vault__coach',
  coach_position__pole_vault__first_coach = 'coach_position__pole_vault__first_coach',
  coach_position__pole_vault__head_coach = 'coach_position__pole_vault__head_coach',
  coach_position__pole_vault__playing_coach = 'coach_position__pole_vault__playing_coach',
  coach_position__race_walking__athletic_trainer = 'coach_position__race_walking__athletic_trainer',
  coach_position__race_walking__coach = 'coach_position__race_walking__coach',
  coach_position__race_walking__first_coach = 'coach_position__race_walking__first_coach',
  coach_position__race_walking__head_coach = 'coach_position__race_walking__head_coach',
  coach_position__race_walking__playing_coach = 'coach_position__race_walking__playing_coach',
  coach_position__racquetball__assistant_coach = 'coach_position__racquetball__assistant_coach',
  coach_position__racquetball__athletic_trainer = 'coach_position__racquetball__athletic_trainer',
  coach_position__racquetball__coach = 'coach_position__racquetball__coach',
  coach_position__racquetball__head_coach = 'coach_position__racquetball__head_coach',
  coach_position__rhythmic_gymnastics__athletic_trainer = 'coach_position__rhythmic_gymnastics__athletic_trainer',
  coach_position__rhythmic_gymnastics__coach = 'coach_position__rhythmic_gymnastics__coach',
  coach_position__rhythmic_gymnastics__first_coach = 'coach_position__rhythmic_gymnastics__first_coach',
  coach_position__rhythmic_gymnastics__head_coach = 'coach_position__rhythmic_gymnastics__head_coach',
  coach_position__rhythmic_gymnastics__playing_coach = 'coach_position__rhythmic_gymnastics__playing_coach',
  coach_position__roller__athletic_trainer = 'coach_position__roller__athletic_trainer',
  coach_position__roller__coach = 'coach_position__roller__coach',
  coach_position__roller__first_coach = 'coach_position__roller__first_coach',
  coach_position__roller__head_coach = 'coach_position__roller__head_coach',
  coach_position__roller__playing_coach = 'coach_position__roller__playing_coach',
  coach_position__rowing__athletic_trainer = 'coach_position__rowing__athletic_trainer',
  coach_position__rowing__coach = 'coach_position__rowing__coach',
  coach_position__rowing__first_coach = 'coach_position__rowing__first_coach',
  coach_position__rowing__head_coach = 'coach_position__rowing__head_coach',
  coach_position__rugby__assistant_coach = 'coach_position__rugby__assistant_coach',
  coach_position__rugby__athletic_trainer = 'coach_position__rugby__athletic_trainer',
  coach_position__rugby__coach = 'coach_position__rugby__coach',
  coach_position__rugby__defensive_coach = 'coach_position__rugby__defensive_coach',
  coach_position__rugby__fitness_coach = 'coach_position__rugby__fitness_coach',
  coach_position__rugby__head_coach = 'coach_position__rugby__head_coach',
  coach_position__rugby__offensive_coach = 'coach_position__rugby__offensive_coach',
  coach_position__rugby__playing_coach = 'coach_position__rugby__playing_coach',
  coach_position__rugby__scrum_coach = 'coach_position__rugby__scrum_coach',
  coach_position__rugby__skill_coach = 'coach_position__rugby__skill_coach',
  coach_position__rugby__strength_and_conditioning_coach = 'coach_position__rugby__strength_and_conditioning_coach',
  coach_position__rugby_sevens__assistant_coach = 'coach_position__rugby_sevens__assistant_coach',
  coach_position__rugby_sevens__athletic_trainer = 'coach_position__rugby_sevens__athletic_trainer',
  coach_position__rugby_sevens__coach = 'coach_position__rugby_sevens__coach',
  coach_position__rugby_sevens__defensive_coach = 'coach_position__rugby_sevens__defensive_coach',
  coach_position__rugby_sevens__fitness_coach = 'coach_position__rugby_sevens__fitness_coach',
  coach_position__rugby_sevens__head_coach = 'coach_position__rugby_sevens__head_coach',
  coach_position__rugby_sevens__offensive_coach = 'coach_position__rugby_sevens__offensive_coach',
  coach_position__rugby_sevens__playing_coach = 'coach_position__rugby_sevens__playing_coach',
  coach_position__rugby_sevens__scrum_coach = 'coach_position__rugby_sevens__scrum_coach',
  coach_position__rugby_sevens__skill_coach = 'coach_position__rugby_sevens__skill_coach',
  coach_position__rugby_sevens__strength_and_conditioning_coach = 'coach_position__rugby_sevens__strength_and_conditioning_coach',
  coach_position__sepak_takraw__athletic_trainer = 'coach_position__sepak_takraw__athletic_trainer',
  coach_position__sepak_takraw__coach = 'coach_position__sepak_takraw__coach',
  coach_position__sepak_takraw__first_coach = 'coach_position__sepak_takraw__first_coach',
  coach_position__sepak_takraw__head_coach = 'coach_position__sepak_takraw__head_coach',
  coach_position__sepak_takraw__playing_coach = 'coach_position__sepak_takraw__playing_coach',
  coach_position__shooting__athletic_trainer = 'coach_position__shooting__athletic_trainer',
  coach_position__shooting__coach = 'coach_position__shooting__coach',
  coach_position__shooting__first_coach = 'coach_position__shooting__first_coach',
  coach_position__shooting__head_coach = 'coach_position__shooting__head_coach',
  coach_position__shot_put__athletic_trainer = 'coach_position__shot_put__athletic_trainer',
  coach_position__shot_put__coach = 'coach_position__shot_put__coach',
  coach_position__shot_put__first_coach = 'coach_position__shot_put__first_coach',
  coach_position__shot_put__head_coach = 'coach_position__shot_put__head_coach',
  coach_position__shot_put__playing_coach = 'coach_position__shot_put__playing_coach',
  coach_position__show_jumping__assistant_coach = 'coach_position__show_jumping__assistant_coach',
  coach_position__show_jumping__athletic_trainer = 'coach_position__show_jumping__athletic_trainer',
  coach_position__show_jumping__coach = 'coach_position__show_jumping__coach',
  coach_position__show_jumping__head_coach = 'coach_position__show_jumping__head_coach',
  coach_position__show_jumping__playing_coach = 'coach_position__show_jumping__playing_coach',
  coach_position__skateboard__athletic_trainer = 'coach_position__skateboard__athletic_trainer',
  coach_position__skateboard__coach = 'coach_position__skateboard__coach',
  coach_position__skateboard__head_coach = 'coach_position__skateboard__head_coach',
  coach_position__skateboard__playing_coach = 'coach_position__skateboard__playing_coach',
  coach_position__skeleton__athletic_trainer = 'coach_position__skeleton__athletic_trainer',
  coach_position__skeleton__coach = 'coach_position__skeleton__coach',
  coach_position__skeleton__first_coach = 'coach_position__skeleton__first_coach',
  coach_position__skeleton__head_coach = 'coach_position__skeleton__head_coach',
  coach_position__skeleton__playing_coach = 'coach_position__skeleton__playing_coach',
  coach_position__ski_jumping__athletic_trainer = 'coach_position__ski_jumping__athletic_trainer',
  coach_position__ski_jumping__coach = 'coach_position__ski_jumping__coach',
  coach_position__ski_jumping__first_coach = 'coach_position__ski_jumping__first_coach',
  coach_position__ski_jumping__head_coach = 'coach_position__ski_jumping__head_coach',
  coach_position__ski_jumping__playing_coach = 'coach_position__ski_jumping__playing_coach',
  coach_position__ski_mountaineering__athletic_trainer = 'coach_position__ski_mountaineering__athletic_trainer',
  coach_position__ski_mountaineering__coach = 'coach_position__ski_mountaineering__coach',
  coach_position__ski_mountaineering__first_coach = 'coach_position__ski_mountaineering__first_coach',
  coach_position__ski_mountaineering__head_coach = 'coach_position__ski_mountaineering__head_coach',
  coach_position__ski_mountaineering__playing_coach = 'coach_position__ski_mountaineering__playing_coach',
  coach_position__snowboarding__athletic_trainer = 'coach_position__snowboarding__athletic_trainer',
  coach_position__snowboarding__coach = 'coach_position__snowboarding__coach',
  coach_position__snowboarding__first_coach = 'coach_position__snowboarding__first_coach',
  coach_position__snowboarding__head_coach = 'coach_position__snowboarding__head_coach',
  coach_position__snowboarding__playing_coach = 'coach_position__snowboarding__playing_coach',
  coach_position__soccer__athletic_trainer = 'coach_position__soccer__athletic_trainer',
  coach_position__soccer__coach = 'coach_position__soccer__coach',
  coach_position__soccer__first_coach = 'coach_position__soccer__first_coach',
  coach_position__soccer__fitness_coach = 'coach_position__soccer__fitness_coach',
  coach_position__soccer__gk_coach = 'coach_position__soccer__gk_coach',
  coach_position__soccer__head_coach = 'coach_position__soccer__head_coach',
  coach_position__soccer__playing_coach = 'coach_position__soccer__playing_coach',
  coach_position__soccer__tactical_coach = 'coach_position__soccer__tactical_coach',
  coach_position__soft_tennis__assistant_coach = 'coach_position__soft_tennis__assistant_coach',
  coach_position__soft_tennis__athletic_trainer = 'coach_position__soft_tennis__athletic_trainer',
  coach_position__soft_tennis__coach = 'coach_position__soft_tennis__coach',
  coach_position__soft_tennis__head_coach = 'coach_position__soft_tennis__head_coach',
  coach_position__softball__athletic_trainer = 'coach_position__softball__athletic_trainer',
  coach_position__softball__base_coach = 'coach_position__softball__base_coach',
  coach_position__softball__bench_coach = 'coach_position__softball__bench_coach',
  coach_position__softball__coach = 'coach_position__softball__coach',
  coach_position__softball__defensive_coach = 'coach_position__softball__defensive_coach',
  coach_position__softball__head_coach = 'coach_position__softball__head_coach',
  coach_position__softball__hitting_coach = 'coach_position__softball__hitting_coach',
  coach_position__softball__pitching_coach = 'coach_position__softball__pitching_coach',
  coach_position__softball__playing_coach = 'coach_position__softball__playing_coach',
  coach_position__sports_climbing__athletic_trainer = 'coach_position__sports_climbing__athletic_trainer',
  coach_position__sports_climbing__coach = 'coach_position__sports_climbing__coach',
  coach_position__sports_climbing__head_coach = 'coach_position__sports_climbing__head_coach',
  coach_position__sports_climbing__playing_coach = 'coach_position__sports_climbing__playing_coach',
  coach_position__sprint__athletic_trainer = 'coach_position__sprint__athletic_trainer',
  coach_position__sprint__coach = 'coach_position__sprint__coach',
  coach_position__sprint__first_coach = 'coach_position__sprint__first_coach',
  coach_position__sprint__head_coach = 'coach_position__sprint__head_coach',
  coach_position__sprint__playing_coach = 'coach_position__sprint__playing_coach',
  coach_position__squash__assistant_coach = 'coach_position__squash__assistant_coach',
  coach_position__squash__athletic_trainer = 'coach_position__squash__athletic_trainer',
  coach_position__squash__coach = 'coach_position__squash__coach',
  coach_position__squash__head_coach = 'coach_position__squash__head_coach',
  coach_position__ssireum__athletic_trainer = 'coach_position__ssireum__athletic_trainer',
  coach_position__ssireum__coach = 'coach_position__ssireum__coach',
  coach_position__ssireum__first_coach = 'coach_position__ssireum__first_coach',
  coach_position__ssireum__head_coach = 'coach_position__ssireum__head_coach',
  coach_position__ssireum__playing_coach = 'coach_position__ssireum__playing_coach',
  coach_position__surfing__athletic_trainer = 'coach_position__surfing__athletic_trainer',
  coach_position__surfing__coach = 'coach_position__surfing__coach',
  coach_position__surfing__first_coach = 'coach_position__surfing__first_coach',
  coach_position__surfing__head_coach = 'coach_position__surfing__head_coach',
  coach_position__swimming__athletic_trainer = 'coach_position__swimming__athletic_trainer',
  coach_position__swimming__coach = 'coach_position__swimming__coach',
  coach_position__swimming__first_coach = 'coach_position__swimming__first_coach',
  coach_position__swimming__head_coach = 'coach_position__swimming__head_coach',
  coach_position__table_tennis__assistant_coach = 'coach_position__table_tennis__assistant_coach',
  coach_position__table_tennis__athletic_trainer = 'coach_position__table_tennis__athletic_trainer',
  coach_position__table_tennis__coach = 'coach_position__table_tennis__coach',
  coach_position__table_tennis__head_coach = 'coach_position__table_tennis__head_coach',
  coach_position__taekwondo__athletic_trainer = 'coach_position__taekwondo__athletic_trainer',
  coach_position__taekwondo__coach = 'coach_position__taekwondo__coach',
  coach_position__taekwondo__first_coach = 'coach_position__taekwondo__first_coach',
  coach_position__taekwondo__head_coach = 'coach_position__taekwondo__head_coach',
  coach_position__taekwondo__playing_coach = 'coach_position__taekwondo__playing_coach',
  coach_position__takkyeon__athletic_trainer = 'coach_position__takkyeon__athletic_trainer',
  coach_position__takkyeon__coach = 'coach_position__takkyeon__coach',
  coach_position__takkyeon__first_coach = 'coach_position__takkyeon__first_coach',
  coach_position__takkyeon__head_coach = 'coach_position__takkyeon__head_coach',
  coach_position__takkyeon__playing_coach = 'coach_position__takkyeon__playing_coach',
  coach_position__tee_ball__athletic_trainer = 'coach_position__tee_ball__athletic_trainer',
  coach_position__tee_ball__coach = 'coach_position__tee_ball__coach',
  coach_position__tee_ball__first_coach = 'coach_position__tee_ball__first_coach',
  coach_position__tee_ball__head_coach = 'coach_position__tee_ball__head_coach',
  coach_position__tee_ball__playing_coach = 'coach_position__tee_ball__playing_coach',
  coach_position__tennis__assistant_coach = 'coach_position__tennis__assistant_coach',
  coach_position__tennis__athletic_trainer = 'coach_position__tennis__athletic_trainer',
  coach_position__tennis__coach = 'coach_position__tennis__coach',
  coach_position__tennis__head_coach = 'coach_position__tennis__head_coach',
  coach_position__trampoline__athletic_trainer = 'coach_position__trampoline__athletic_trainer',
  coach_position__trampoline__coach = 'coach_position__trampoline__coach',
  coach_position__trampoline__first_coach = 'coach_position__trampoline__first_coach',
  coach_position__trampoline__head_coach = 'coach_position__trampoline__head_coach',
  coach_position__trampoline__playing_coach = 'coach_position__trampoline__playing_coach',
  coach_position__triathlon__athletic_trainer = 'coach_position__triathlon__athletic_trainer',
  coach_position__triathlon__bike_coach = 'coach_position__triathlon__bike_coach',
  coach_position__triathlon__coach = 'coach_position__triathlon__coach',
  coach_position__triathlon__first_coach = 'coach_position__triathlon__first_coach',
  coach_position__triathlon__head_coach = 'coach_position__triathlon__head_coach',
  coach_position__triathlon__playing_coach = 'coach_position__triathlon__playing_coach',
  coach_position__triathlon__running_coach = 'coach_position__triathlon__running_coach',
  coach_position__triathlon__swimming_coach = 'coach_position__triathlon__swimming_coach',
  coach_position__triple_jump__athletic_trainer = 'coach_position__triple_jump__athletic_trainer',
  coach_position__triple_jump__coach = 'coach_position__triple_jump__coach',
  coach_position__triple_jump__first_coach = 'coach_position__triple_jump__first_coach',
  coach_position__triple_jump__head_coach = 'coach_position__triple_jump__head_coach',
  coach_position__triple_jump__playing_coach = 'coach_position__triple_jump__playing_coach',
  coach_position__volleyball__assistant_coach = 'coach_position__volleyball__assistant_coach',
  coach_position__volleyball__athletic_trainer = 'coach_position__volleyball__athletic_trainer',
  coach_position__volleyball__coach = 'coach_position__volleyball__coach',
  coach_position__volleyball__first_coach = 'coach_position__volleyball__first_coach',
  coach_position__volleyball__head_coach = 'coach_position__volleyball__head_coach',
  coach_position__volleyball__playing_coach = 'coach_position__volleyball__playing_coach',
  coach_position__water_polo__athletic_trainer = 'coach_position__water_polo__athletic_trainer',
  coach_position__water_polo__coach = 'coach_position__water_polo__coach',
  coach_position__water_polo__first_coach = 'coach_position__water_polo__first_coach',
  coach_position__water_polo__head_coach = 'coach_position__water_polo__head_coach',
  coach_position__water_skiing__athletic_trainer = 'coach_position__water_skiing__athletic_trainer',
  coach_position__water_skiing__coach = 'coach_position__water_skiing__coach',
  coach_position__water_skiing__first_coach = 'coach_position__water_skiing__first_coach',
  coach_position__water_skiing__head_coach = 'coach_position__water_skiing__head_coach',
  coach_position__weightlifting__assistant_coach = 'coach_position__weightlifting__assistant_coach',
  coach_position__weightlifting__athletic_trainer = 'coach_position__weightlifting__athletic_trainer',
  coach_position__weightlifting__coach = 'coach_position__weightlifting__coach',
  coach_position__weightlifting__head_coach = 'coach_position__weightlifting__head_coach',
  coach_position__weightlifting__playing_coach = 'coach_position__weightlifting__playing_coach',
  coach_position__women_heptathlon__athletic_trainer = 'coach_position__women_heptathlon__athletic_trainer',
  coach_position__women_heptathlon__coach = 'coach_position__women_heptathlon__coach',
  coach_position__women_heptathlon__first_coach = 'coach_position__women_heptathlon__first_coach',
  coach_position__women_heptathlon__head_coach = 'coach_position__women_heptathlon__head_coach',
  coach_position__women_heptathlon__playing_coach = 'coach_position__women_heptathlon__playing_coach',
  coach_position__wrestling__athletic_trainer = 'coach_position__wrestling__athletic_trainer',
  coach_position__wrestling__coach = 'coach_position__wrestling__coach',
  coach_position__wrestling__first_coach = 'coach_position__wrestling__first_coach',
  coach_position__wrestling__head_coach = 'coach_position__wrestling__head_coach',
  coach_position__wrestling__playing_coach = 'coach_position__wrestling__playing_coach',
  coach_position__yacht__athletic_trainer = 'coach_position__yacht__athletic_trainer',
  coach_position__yacht__coach = 'coach_position__yacht__coach',
  coach_position__yacht__first_coach = 'coach_position__yacht__first_coach',
  coach_position__yacht__head_coach = 'coach_position__yacht__head_coach',
  player_position__american_football__center = 'player_position__american_football__center',
  player_position__american_football__conner_back = 'player_position__american_football__conner_back',
  player_position__american_football__defensive_end = 'player_position__american_football__defensive_end',
  player_position__american_football__defensive_tackle = 'player_position__american_football__defensive_tackle',
  player_position__american_football__dime_back = 'player_position__american_football__dime_back',
  player_position__american_football__free_safety = 'player_position__american_football__free_safety',
  player_position__american_football__full_back = 'player_position__american_football__full_back',
  player_position__american_football__half_back = 'player_position__american_football__half_back',
  player_position__american_football__middle_linebacker = 'player_position__american_football__middle_linebacker',
  player_position__american_football__nickle_back = 'player_position__american_football__nickle_back',
  player_position__american_football__offensive_guard = 'player_position__american_football__offensive_guard',
  player_position__american_football__offensive_tackle = 'player_position__american_football__offensive_tackle',
  player_position__american_football__outside_linebacker = 'player_position__american_football__outside_linebacker',
  player_position__american_football__quarter_back = 'player_position__american_football__quarter_back',
  player_position__american_football__running_back = 'player_position__american_football__running_back',
  player_position__american_football__strong_safety = 'player_position__american_football__strong_safety',
  player_position__american_football__tight_end = 'player_position__american_football__tight_end',
  player_position__american_football__wide_receiver = 'player_position__american_football__wide_receiver',
  player_position__baseball__catcher = 'player_position__baseball__catcher',
  player_position__baseball__center_fielder = 'player_position__baseball__center_fielder',
  player_position__baseball__designated_hitter = 'player_position__baseball__designated_hitter',
  player_position__baseball__first_baseman = 'player_position__baseball__first_baseman',
  player_position__baseball__left_fielder = 'player_position__baseball__left_fielder',
  player_position__baseball__pitcher = 'player_position__baseball__pitcher',
  player_position__baseball__right_fielder = 'player_position__baseball__right_fielder',
  player_position__baseball__second_baseman = 'player_position__baseball__second_baseman',
  player_position__baseball__short_stop = 'player_position__baseball__short_stop',
  player_position__baseball__third_baseman = 'player_position__baseball__third_baseman',
  player_position__basketball__center = 'player_position__basketball__center',
  player_position__basketball__point_guard = 'player_position__basketball__point_guard',
  player_position__basketball__power_forward = 'player_position__basketball__power_forward',
  player_position__basketball__shooting_guard = 'player_position__basketball__shooting_guard',
  player_position__basketball__small_forward = 'player_position__basketball__small_forward',
  player_position__cricket__batsman = 'player_position__cricket__batsman',
  player_position__cricket__bowler = 'player_position__cricket__bowler',
  player_position__cricket__wicket = 'player_position__cricket__wicket',
  player_position__curling__lead = 'player_position__curling__lead',
  player_position__curling__second = 'player_position__curling__second',
  player_position__curling__skip = 'player_position__curling__skip',
  player_position__curling__vice = 'player_position__curling__vice',
  player_position__empty = 'player_position__empty',
  player_position__field_hockey__defense = 'player_position__field_hockey__defense',
  player_position__field_hockey__forward = 'player_position__field_hockey__forward',
  player_position__field_hockey__goalkeeper = 'player_position__field_hockey__goalkeeper',
  player_position__field_hockey__midfielder = 'player_position__field_hockey__midfielder',
  player_position__foot_volleyball__attacker = 'player_position__foot_volleyball__attacker',
  player_position__foot_volleyball__defender = 'player_position__foot_volleyball__defender',
  player_position__foot_volleyball__setter = 'player_position__foot_volleyball__setter',
  player_position__futsal__ala = 'player_position__futsal__ala',
  player_position__futsal__fixo = 'player_position__futsal__fixo',
  player_position__futsal__goleiro = 'player_position__futsal__goleiro',
  player_position__futsal__pivo = 'player_position__futsal__pivo',
  player_position__handball__center_back = 'player_position__handball__center_back',
  player_position__handball__goalkeeper = 'player_position__handball__goalkeeper',
  player_position__handball__left_back = 'player_position__handball__left_back',
  player_position__handball__left_wing = 'player_position__handball__left_wing',
  player_position__handball__pivot = 'player_position__handball__pivot',
  player_position__handball__right_back = 'player_position__handball__right_back',
  player_position__handball__right_wing = 'player_position__handball__right_wing',
  player_position__ice_hockey__center = 'player_position__ice_hockey__center',
  player_position__ice_hockey__goaltender = 'player_position__ice_hockey__goaltender',
  player_position__ice_hockey__left_defense = 'player_position__ice_hockey__left_defense',
  player_position__ice_hockey__left_wing = 'player_position__ice_hockey__left_wing',
  player_position__ice_hockey__right_defense = 'player_position__ice_hockey__right_defense',
  player_position__ice_hockey__right_wing = 'player_position__ice_hockey__right_wing',
  player_position__rugby__centre = 'player_position__rugby__centre',
  player_position__rugby__flanker = 'player_position__rugby__flanker',
  player_position__rugby__full_back = 'player_position__rugby__full_back',
  player_position__rugby__hooker = 'player_position__rugby__hooker',
  player_position__rugby__lock = 'player_position__rugby__lock',
  player_position__rugby__number_eight = 'player_position__rugby__number_eight',
  player_position__rugby__prop = 'player_position__rugby__prop',
  player_position__rugby__scrum_half = 'player_position__rugby__scrum_half',
  player_position__rugby__stand_off = 'player_position__rugby__stand_off',
  player_position__rugby__wing = 'player_position__rugby__wing',
  player_position__rugby_sevens__center = 'player_position__rugby_sevens__center',
  player_position__rugby_sevens__hooker = 'player_position__rugby_sevens__hooker',
  player_position__rugby_sevens__prop = 'player_position__rugby_sevens__prop',
  player_position__rugby_sevens__scrum_half = 'player_position__rugby_sevens__scrum_half',
  player_position__rugby_sevens__stand_off = 'player_position__rugby_sevens__stand_off',
  player_position__rugby_sevens__wing = 'player_position__rugby_sevens__wing',
  player_position__sepak_takraw__feeder = 'player_position__sepak_takraw__feeder',
  player_position__sepak_takraw__killer = 'player_position__sepak_takraw__killer',
  player_position__sepak_takraw__tekong = 'player_position__sepak_takraw__tekong',
  player_position__soccer__defense = 'player_position__soccer__defense',
  player_position__soccer__forward = 'player_position__soccer__forward',
  player_position__soccer__goalkeeper = 'player_position__soccer__goalkeeper',
  player_position__soccer__midfielder = 'player_position__soccer__midfielder',
  player_position__tee_ball__catcher = 'player_position__tee_ball__catcher',
  player_position__tee_ball__first_baseman = 'player_position__tee_ball__first_baseman',
  player_position__tee_ball__first_center_fielder = 'player_position__tee_ball__first_center_fielder',
  player_position__tee_ball__first_short_stop = 'player_position__tee_ball__first_short_stop',
  player_position__tee_ball__left_fielder = 'player_position__tee_ball__left_fielder',
  player_position__tee_ball__right_fielder = 'player_position__tee_ball__right_fielder',
  player_position__tee_ball__second_baseman = 'player_position__tee_ball__second_baseman',
  player_position__tee_ball__second_center_fielder = 'player_position__tee_ball__second_center_fielder',
  player_position__tee_ball__second_short_stop = 'player_position__tee_ball__second_short_stop',
  player_position__tee_ball__third_baseman = 'player_position__tee_ball__third_baseman',
  player_position__volleyball__libero = 'player_position__volleyball__libero',
  player_position__volleyball__middle_blocker = 'player_position__volleyball__middle_blocker',
  player_position__volleyball__opposite_spiker = 'player_position__volleyball__opposite_spiker',
  player_position__volleyball__outside_hitter = 'player_position__volleyball__outside_hitter',
  player_position__volleyball__setter = 'player_position__volleyball__setter'
}

export type SportsQuery = {
  __typename?: 'SportsQuery';
  bodyPartGroups: Array<SportsBodyPartGroup>;
  bodyParts: Array<SportsBodyPart>;
  categories: Array<SportsCategoryCollection>;
  category: SportsCategoryCollection;
  categoryGroups: Array<SportsCategoryGroupCollection>;
  countryList: Array<Country>;
  orgTeam: OrgTeam;
  orgTeamsByPage: OrgTeamByPage;
  regions: Array<Region>;
  team?: Maybe<SportsTeam>;
  teamsByPage: SportsTeamByPage;
  user?: Maybe<SportsUser>;
  usersByPage: SportsUserByPage;
  viewer?: Maybe<SportsUser>;
};


export type SportsQueryBodyPartsArgs = {
  groups?: Maybe<Array<SportsBodyPartGroupName>>;
  input: MultilingualTextInput;
};


export type SportsQueryCategoriesArgs = {
  groups?: Maybe<Array<Scalars['String']>>;
};


export type SportsQueryCategoryArgs = {
  id: Scalars['String'];
};


export type SportsQueryOrgTeamArgs = {
  id: Scalars['ID'];
};


export type SportsQueryOrgTeamsByPageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['ID']>;
};


export type SportsQueryTeamArgs = {
  id: Scalars['ID'];
};


export type SportsQueryTeamsByPageArgs = {
  activeOnly?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TeamFilterInput>;
  filterBy?: Maybe<TeamFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SportsQueryUserArgs = {
  id: Scalars['ID'];
};


export type SportsQueryUsersByPageArgs = {
  activeOnly?: Maybe<Scalars['Boolean']>;
  filterBy?: Maybe<UserFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type SportsRawBody = SportsRawData & TimeSerializable & {
  __typename?: 'SportsRawBody';
  height: Scalars['Float'];
  heightUnit?: Maybe<HeightUnit>;
  id: Scalars['ID'];
  name: SportsRawDataName;
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  user: SportsUser;
  weight: Scalars['Float'];
  weightUnit?: Maybe<WeightUnit>;
};

export type SportsRawCondition = SportsRawData & TimeSerializable & {
  __typename?: 'SportsRawCondition';
  id: Scalars['ID'];
  name: SportsRawDataName;
  rawSleep: SportsRawSleep;
  rawWellness: SportsRawWellness;
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  user: SportsUser;
};

export type SportsRawData = {
  id: Scalars['ID'];
  name: SportsRawDataName;
  user: SportsUser;
};

export enum SportsRawDataName {
  RAW_BODY = 'RAW_BODY',
  RAW_CONDITION = 'RAW_CONDITION',
  RAW_INJURY = 'RAW_INJURY',
  RAW_SLEEP = 'RAW_SLEEP',
  RAW_WELLNESS = 'RAW_WELLNESS',
  RAW_WORKLOAD = 'RAW_WORKLOAD'
}

export type SportsRawInjury = Eventable & SportsRawData & TimeSerializable & {
  __typename?: 'SportsRawInjury';
  bodyPart: SportsBodyPart;
  bodyPartGroup: SportsBodyPartGroup;
  comment?: Maybe<Scalars['String']>;
  event?: Maybe<EventInvitee>;
  id: Scalars['ID'];
  name: SportsRawDataName;
  quality: Array<SportsInjuryQuality>;
  soreness: Scalars['Int'];
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  timing: Array<SportsInjuryTiming>;
  user: SportsUser;
};


export type SportsRawInjuryEventArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export type SportsRawSleep = SportsRawData & TimeSerializable & {
  __typename?: 'SportsRawSleep';
  duration: Scalars['Int'];
  id: Scalars['ID'];
  name: SportsRawDataName;
  quality: Scalars['Int'];
  time: Scalars['DateTimeString'];
  timeHourlyOffset: Scalars['Float'];
  timezone: Scalars['String'];
  user: SportsUser;
};

export type SportsRawWellness = SportsRawData & TimeSerializable & {
  __typename?: 'SportsRawWellness';
  fatigue: Scalars['Int'];
  id: Scalars['ID'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  name: SportsRawDataName;
  stress: Scalars['Int'];
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  user: SportsUser;
};

export type SportsRawWorkload = Eventable & SportsRawData & TimeSerializable & {
  __typename?: 'SportsRawWorkload';
  duration: Scalars['Int'];
  event?: Maybe<EventInvitee>;
  id: Scalars['ID'];
  intensity: Scalars['Int'];
  load: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  name: SportsRawDataName;
  satisfaction: Scalars['Int'];
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  type: WorkoutType;
  user: SportsUser;
};


export type SportsRawWorkloadEventArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export enum SportsRole {
  COACH = 'COACH',
  PLAYER = 'PLAYER'
}

export type SportsTeam = {
  __typename?: 'SportsTeam';
  chartData: ChartData;
  coachAlarmConnection: CoachAlarmConnection;
  code: Scalars['String'];
  createdAt: Scalars['Date'];
  dailyData: DailyTeamData;
  data: Array<SportsTimeSeriesTeamData>;
  eventList: Array<Event>;
  feedbackAlarmConnection: FeedbackAlarmConnection;
  group: TeamGroup;
  groups: Array<TeamGroup>;
  id: Scalars['ID'];
  injuryList: Array<Injury>;
  insightData: Array<InsightDataItem>;
  insightDataEntryByPage: InsightDataItem;
  introduction?: Maybe<Scalars['String']>;
  invitationRequests: Array<SportsTeamInvitationRequest>;
  invitationRequestsByPage: SportsTeamInvitationRequestByPage;
  isGivenWelcomeCoupon: Scalars['Boolean'];
  license?: Maybe<License>;
  memberUserList: SportsUserList;
  name: Scalars['String'];
  notCheckedAlarm: Scalars['Float'];
  notCheckedCoachAlarm: Scalars['Int'];
  notCheckedFeedbackAlarm: Scalars['Int'];
  ownedBy: SportsUser;
  pendingInvitationRequestList: Array<TeamInvitationRequest>;
  pictureFileId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  region: Region;
  slug?: Maybe<Scalars['String']>;
  sportsCategory: SportsCategoryCollection;
  sportsData: TeamSportsData;
  starredUserMaps: Array<SportsTeamUserStarred>;
  subscriptionItem?: Maybe<SubscriptionItem>;
  teamGroup: TeamGroup;
  teamGroups: Array<TeamGroup>;
  totalUserList: SportsUserList;
  type: TeamType;
  updatedAt: Scalars['Date'];
  userMapsByPage: SportsTeamUserMapByPage;
  viewerAuthority?: Maybe<SportsTeamAuthority>;
  viewerInvitationRequestStatus: SportsTeamInvitationRequestStatus;
};


export type SportsTeamChartDataArgs = {
  cursor: Scalars['DateTime'];
  dataName: ChartDataName;
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  timezone: Scalars['String'];
};


export type SportsTeamCoachAlarmConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  timezone: Scalars['String'];
};


export type SportsTeamDailyDataArgs = {
  cursor: Scalars['DateTime'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  timezone: Scalars['String'];
};


export type SportsTeamDataArgs = {
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  from: Scalars['DateTime'];
  names: Array<SportsTeamDataName>;
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type SportsTeamEventListArgs = {
  input: GetCoachEventInput;
};


export type SportsTeamFeedbackAlarmConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  timezone: Scalars['String'];
};


export type SportsTeamGroupArgs = {
  id: Scalars['ID'];
};


export type SportsTeamInsightDataArgs = {
  cursor: Scalars['DateTime'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  groups?: Maybe<Array<InsightDataGroup>>;
  names?: Maybe<Array<InsightDataName>>;
  stride?: Maybe<InsightDataStride>;
  timezone: Scalars['String'];
};


export type SportsTeamInsightDataEntryByPageArgs = {
  cursor: Scalars['DateTime'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  name: InsightDataName;
  offset?: Maybe<Scalars['Int']>;
  timezone: Scalars['String'];
};


export type SportsTeamInvitationRequestsByPageArgs = {
  filter?: Maybe<TeamInvitationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SportsTeamNotCheckedCoachAlarmArgs = {
  timezone: Scalars['String'];
};


export type SportsTeamNotCheckedFeedbackAlarmArgs = {
  timezone: Scalars['String'];
};


export type SportsTeamStarredUserMapsArgs = {
  order?: Maybe<UserDataOrderByInput>;
  starerId: Scalars['ID'];
};


export type SportsTeamTeamGroupArgs = {
  id: Scalars['ID'];
};


export type SportsTeamUserMapsByPageArgs = {
  filter?: Maybe<TeamUserMapFilterInput>;
  filterBy?: Maybe<TeamUserFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<UserDataOrderByInput>;
};

export enum SportsTeamAuthority {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OBSERVER = 'OBSERVER',
  OWNER = 'OWNER',
  PENDING_MEMBER = 'PENDING_MEMBER',
  VIEWER = 'VIEWER'
}

export type SportsTeamByPage = {
  __typename?: 'SportsTeamByPage';
  entries: Array<SportsTeam>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type SportsTeamDataEntry = {
  __typename?: 'SportsTeamDataEntry';
  meta?: Maybe<Scalars['JSON']>;
  time: Scalars['DateTimeString'];
  value?: Maybe<Scalars['JSON']>;
};

export enum SportsTeamDataName {
  TEAM_CONDITION_AVG = 'TEAM_CONDITION_AVG',
  TEAM_INJURY_BY_SORENESS = 'TEAM_INJURY_BY_SORENESS',
  TEAM_READINESS_AVG = 'TEAM_READINESS_AVG',
  TEAM_WORKLOAD_ACXR_AVG = 'TEAM_WORKLOAD_ACXR_AVG',
  TEAM_WORKLOAD_AVG = 'TEAM_WORKLOAD_AVG'
}

export type SportsTeamDataRange = {
  __typename?: 'SportsTeamDataRange';
  entries: Array<SportsTeamDataEntry>;
  from: Scalars['DateTimeString'];
  to: Scalars['DateTimeString'];
};

export type SportsTeamInvitationAcceptListInput = {
  invitationRequestList: Array<InvitationRequest>;
  team: SportsTeamInvitationRejectTeam;
};

export type SportsTeamInvitationRejectListInput = {
  requestIdList: Array<Scalars['ID']>;
  team: SportsTeamInvitationRejectTeam;
};

export type SportsTeamInvitationRejectTeam = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SportsTeamInvitationRequest = {
  __typename?: 'SportsTeamInvitationRequest';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  position?: Maybe<SportsPosition>;
  role?: Maybe<SportsRole>;
  status: SportsTeamInvitationRequestStatus;
  team: SportsTeam;
  teamAuthority?: Maybe<SportsTeamAuthority>;
  updatedAt: Scalars['Date'];
  user: SportsUser;
};

export type SportsTeamInvitationRequestByPage = {
  __typename?: 'SportsTeamInvitationRequestByPage';
  entries: Array<SportsTeamInvitationRequest>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type SportsTeamInvitationRequestInput = {
  code: Scalars['String'];
  position?: Maybe<SportsPosition>;
  role?: Maybe<SportsRole>;
  teamAuthority?: Maybe<SportsTeamAuthority>;
  userId: Scalars['ID'];
};

export enum SportsTeamInvitationRequestStatus {
  CONFIRMING = 'CONFIRMING',
  LEFT = 'LEFT',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type SportsTeamUserMap = {
  __typename?: 'SportsTeamUserMap';
  comment?: Maybe<Scalars['String']>;
  groups: Array<TeamGroup>;
  id: Scalars['ID'];
  isStarredByViewer: Scalars['Boolean'];
  position?: Maybe<SportsPosition>;
  role?: Maybe<SportsRole>;
  team: SportsTeam;
  teamAuthority: SportsTeamAuthority;
  user: SportsUser;
};

export type SportsTeamUserMapByPage = {
  __typename?: 'SportsTeamUserMapByPage';
  entries: Array<SportsTeamUserMap>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type SportsTeamUserStarred = {
  __typename?: 'SportsTeamUserStarred';
  isStarredByViewer: Scalars['Boolean'];
  starer: SportsUser;
  team: SportsTeam;
  user: SportsUser;
};

export type SportsTimeSeriesData = {
  __typename?: 'SportsTimeSeriesData';
  group: SportsDataGroup;
  name: SportsDataName;
  ranges: Array<SportsDataRange>;
  stride: SportsDataStride;
};


export type SportsTimeSeriesDataRangesArgs = {
  input: MultilingualTextInput;
};

export type SportsTimeSeriesTeamData = {
  __typename?: 'SportsTimeSeriesTeamData';
  name: SportsTeamDataName;
  ranges: Array<SportsTeamDataRange>;
  stride: SportsDataStride;
};

export type SportsUser = {
  __typename?: 'SportsUser';
  alarmConnection: AlarmConnection;
  birthdate?: Maybe<Scalars['String']>;
  body: UserBodyData;
  careers?: Maybe<Array<SportsCareer>>;
  createdAt: Scalars['Date'];
  currentInjuryList: Array<Injury>;
  data: Array<SportsTimeSeriesData>;
  deviceTokens?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  event: PlayerEvent;
  federation?: Maybe<Array<Scalars['String']>>;
  feedbackConnection: FeedbackConnection;
  feedbackSuggestionList: Array<FeedbackSuggestion>;
  gender?: Maybe<Gender>;
  guideReopenStatus: GuideReopenStatus;
  id: Scalars['ID'];
  identityId: Scalars['String'];
  injury?: Maybe<Injury>;
  introduction?: Maybe<Scalars['String']>;
  invitationRequests: Array<SportsTeamInvitationRequest>;
  invitationRequestsByPage: SportsTeamInvitationRequestByPage;
  isStarredBy: Scalars['Boolean'];
  latestRawBody?: Maybe<SportsRawBody>;
  name?: Maybe<Scalars['String']>;
  nationality?: Maybe<CountryCode>;
  notCheckedAlarm: Scalars['Float'];
  pastInjuryList: Array<Injury>;
  phoneNumber?: Maybe<Scalars['String']>;
  pictureFileId?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  priorCareer?: Maybe<SportsCareer>;
  priorSportsCategory?: Maybe<SportsCategoryCollection>;
  priorSportsPosition?: Maybe<SportsPosition>;
  priorSportsRole?: Maybe<SportsRole>;
  pushConditionCheckReminderSchedule?: Maybe<Scalars['String']>;
  pushConditionReminderAllowed: Scalars['Boolean'];
  pushMarketingAllowed: Scalars['Boolean'];
  pushReminderAllowed: Scalars['Boolean'];
  pushWorkloadCheckReminderSchedule?: Maybe<Scalars['String']>;
  pushWorkloadReminderAllowed: Scalars['Boolean'];
  rawBody: SportsRawBody;
  rawCondition: SportsRawCondition;
  rawDataByCursor: RawDataByCursor;
  rawInjury: SportsRawInjury;
  rawSleep: SportsRawSleep;
  rawWellness: SportsRawWellness;
  rawWorkload: SportsRawWorkload;
  routineList: Array<Routine>;
  slug?: Maybe<Scalars['String']>;
  sorenessHistory: SportsUserSorenessHistory;
  sportsData?: Maybe<SportsData>;
  sportsDataList: Array<SportsData>;
  teamList: Array<SportsTeam>;
  teamMaps: Array<SportsTeamUserMap>;
  teamMapsByPage: SportsTeamUserMapByPage;
  teamsByPage: SportsTeamByPage;
  totalFeedbackCount: Scalars['Int'];
  translatedNationality?: Maybe<Scalars['String']>;
  translatedSportsCategory?: Maybe<Scalars['String']>;
  translatedSportsPosition?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  workload: Array<UserWorkloadData>;
};


export type SportsUserAlarmConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  timezone: Scalars['String'];
};


export type SportsUserBodyArgs = {
  date: Scalars['DateTime'];
  heightUnit?: HeightUnit;
  weightUnit?: WeightUnit;
};


export type SportsUserCurrentInjuryListArgs = {
  teamId: Scalars['ID'];
};


export type SportsUserDataArgs = {
  from?: Maybe<Scalars['DateTime']>;
  groups?: Maybe<Array<SportsDataGroup>>;
  names?: Maybe<Array<SportsDataName>>;
  raw?: Maybe<Scalars['Boolean']>;
  stride?: Maybe<SportsDataStride>;
  timezone: Scalars['String'];
  to?: Maybe<Scalars['DateTime']>;
};


export type SportsUserFeedbackConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  teamId?: Maybe<Scalars['ID']>;
};


export type SportsUserFeedbackSuggestionListArgs = {
  teamId: Scalars['ID'];
  timezone: Scalars['String'];
};


export type SportsUserInvitationRequestsByPageArgs = {
  filter?: Maybe<TeamInvitationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SportsUserIsStarredByArgs = {
  starerId: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type SportsUserLatestRawBodyArgs = {
  cursor?: Maybe<Scalars['DateTime']>;
  heightUnit?: HeightUnit;
  timezone: Scalars['String'];
  weightUnit?: WeightUnit;
};


export type SportsUserNotCheckedAlarmArgs = {
  timezone: Scalars['String'];
};


export type SportsUserPastInjuryListArgs = {
  teamId: Scalars['ID'];
};


export type SportsUserRawBodyArgs = {
  heightUnit?: HeightUnit;
  id: Scalars['ID'];
  weightUnit?: WeightUnit;
};


export type SportsUserRawConditionArgs = {
  id: Scalars['ID'];
};


export type SportsUserRawSleepArgs = {
  id: Scalars['ID'];
};


export type SportsUserRawWellnessArgs = {
  id: Scalars['ID'];
};


export type SportsUserRawWorkloadArgs = {
  id: Scalars['ID'];
};


export type SportsUserRoutineListArgs = {
  cursor?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
};


export type SportsUserSportsDataArgs = {
  date: Scalars['String'];
  timezone: Scalars['String'];
};


export type SportsUserSportsDataListArgs = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type SportsUserTeamMapsByPageArgs = {
  filter?: Maybe<TeamUserMapFilterInput>;
  filterBy?: Maybe<TeamUserFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<TeamMapOrderByInput>;
};


export type SportsUserTeamsByPageArgs = {
  activeOnly?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TeamFilterInput>;
  filterBy?: Maybe<TeamFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SportsUserTranslatedNationalityArgs = {
  input: MultilingualTextInput;
};


export type SportsUserTranslatedSportsCategoryArgs = {
  input: MultilingualTextInput;
};


export type SportsUserTranslatedSportsPositionArgs = {
  input: MultilingualTextInput;
};


export type SportsUserWorkloadArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type SportsUserByPage = {
  __typename?: 'SportsUserByPage';
  entries: Array<SportsUser>;
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type SportsUserList = {
  __typename?: 'SportsUserList';
  count: Scalars['Int'];
  userList: Array<SportsUser>;
};

export type SportsUserSorenessDataValue = {
  __typename?: 'SportsUserSorenessDataValue';
  level?: Maybe<Scalars['Int']>;
  time: Scalars['DateTimeString'];
};

export type SportsUserSorenessHistory = {
  __typename?: 'SportsUserSorenessHistory';
  monthlyData: SportsUserSorenessHistoryMonthly;
  recentData: SportsUserSorenessRecentData;
  weeklyData: SportsUserSorenessHistoryWeekly;
};


export type SportsUserSorenessHistoryMonthlyDataArgs = {
  bodyPart: SportsBodyPartName;
  date: Scalars['DateTime'];
};


export type SportsUserSorenessHistoryRecentDataArgs = {
  bodyPart: SportsBodyPartName;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SportsUserSorenessHistoryWeeklyDataArgs = {
  bodyPart: SportsBodyPartName;
  date: Scalars['DateTime'];
};

export type SportsUserSorenessHistoryMonthly = {
  __typename?: 'SportsUserSorenessHistoryMonthly';
  bodyPart: SportsBodyPart;
  curr: Array<SportsUserSorenessDataValue>;
  from: Scalars['DateTimeString'];
  next: SportsUserSorenessDataValue;
  prev: SportsUserSorenessDataValue;
  to: Scalars['DateTimeString'];
};


export type SportsUserSorenessHistoryMonthlyBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SportsUserSorenessHistoryWeekly = {
  __typename?: 'SportsUserSorenessHistoryWeekly';
  bodyPart: SportsBodyPart;
  curr: Array<SportsUserSorenessDataValue>;
  from: Scalars['DateTimeString'];
  next: SportsUserSorenessDataValue;
  prev: SportsUserSorenessDataValue;
  to: Scalars['DateTimeString'];
};


export type SportsUserSorenessHistoryWeeklyBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SportsUserSorenessRecentData = {
  __typename?: 'SportsUserSorenessRecentData';
  bodyPart: SportsBodyPart;
  hasNext: Scalars['Boolean'];
  total: Scalars['Int'];
  values: Array<SportsUserSorenessRecentDataValue>;
};


export type SportsUserSorenessRecentDataBodyPartArgs = {
  input: MultilingualTextInput;
};

export type SportsUserSorenessRecentDataValue = {
  __typename?: 'SportsUserSorenessRecentDataValue';
  isContact?: Maybe<Contact>;
  level: Scalars['Int'];
  memo: Scalars['String'];
  time: Scalars['DateTimeString'];
};

export type StarredToggleResult = {
  __typename?: 'StarredToggleResult';
  status: Scalars['Boolean'];
};

export type StartSubscriptionInput = {
  buyerEmail: Scalars['String'];
  buyerName: Scalars['String'];
  buyerTel: Scalars['String'];
  couponId?: Maybe<Scalars['ID']>;
  planId: Scalars['ID'];
  planOptionId: Scalars['ID'];
  subscriptionId?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type Statistic = {
  __typename?: 'Statistic';
  avg: Scalars['Float'];
  max: Scalars['Float'];
  min: Scalars['Float'];
  sum: Scalars['Float'];
};


export type SubscriptionItem = {
  __typename?: 'SubscriptionItem';
  coupon: UseCoupon;
  cycleAnchor?: Maybe<Scalars['String']>;
  endAt: Scalars['Date'];
  id: Scalars['ID'];
  invoicesByPage: InvoiceList;
  isActivate: Scalars['Boolean'];
  isFreeTrial: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  isSubscripting: Scalars['Boolean'];
  plan: PlanWithOption;
  planId: Scalars['String'];
  planOptionId: Scalars['String'];
  startAt: Scalars['Date'];
  status: SubscriptionStatusEnum;
  teamId: Scalars['String'];
};


export type SubscriptionItemInvoicesByPageArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type SubscriptionResponse = {
  __typename?: 'SubscriptionResponse';
  paymentList: Array<Invoice>;
  subscription: SubscriptionItem;
};

export enum SubscriptionStatus {
  ACTIVATE = 'ACTIVATE',
  GRACE_PERIOD = 'GRACE_PERIOD',
  INACTIVATE = 'INACTIVATE'
}

export enum SubscriptionStatusEnum {
  ACTIVATE = 'ACTIVATE',
  GRACE_PERIOD = 'GRACE_PERIOD',
  INACTIVATE = 'INACTIVATE'
}

export type TargetOption = {
  __typename?: 'TargetOption';
  operator: BannerOptionOperator;
  option: BannerOption;
  value: Scalars['StringOrFloat'];
};

export type TargetOptionInput = {
  operator: BannerOptionOperator;
  option: BannerOption;
  value: Scalars['StringOrFloat'];
};

export type TeamCheckReminderSchedule = {
  __typename?: 'TeamCheckReminderSchedule';
  id: Scalars['ID'];
  pushMessageType: ReminderPushMessageType;
  scheduleTime: Scalars['String'];
  team: SportsTeam;
};

export type TeamCheckReminderScheduleByPage = {
  __typename?: 'TeamCheckReminderScheduleByPage';
  teamCheckReminderScheduleList: Array<TeamCheckReminderSchedule>;
  total: Scalars['Float'];
};

export type TeamCheckReminderScheduleFilter = {
  pushMessageTypeList?: Maybe<Array<ReminderPushMessageType>>;
  scheduleTimeList?: Maybe<Array<Scalars['String']>>;
};

export enum TeamField {
  name = 'name',
  region = 'region',
  slug = 'slug',
  sportsCategory = 'sportsCategory'
}

export type TeamFilter = {
  searchTerm?: Maybe<Scalars['String']>;
};

export type TeamFilterExpressionInput = {
  field: TeamField;
  op: QueryOperation;
  value: Scalars['String'];
};

export type TeamFilterInput = {
  and?: Maybe<Array<TeamFilterInput>>;
  expression?: Maybe<TeamFilterExpressionInput>;
  not?: Maybe<TeamFilterInput>;
  or?: Maybe<Array<TeamFilterInput>>;
};

export type TeamGroup = {
  __typename?: 'TeamGroup';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy: SportsUser;
  groupMembers: Array<TeamGroupUser>;
  id: Scalars['ID'];
  name: Scalars['String'];
  orderRanking?: Maybe<Scalars['String']>;
  team: SportsTeam;
  teamId: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  userId: Scalars['String'];
};

export type TeamGroupUser = {
  __typename?: 'TeamGroupUser';
  groups: Array<TeamGroup>;
  id: Scalars['ID'];
  team: SportsTeam;
  user: SportsUser;
};

export enum TeamInvitationField {
  position = 'position',
  role = 'role',
  status = 'status',
  teamAuthority = 'teamAuthority'
}

export type TeamInvitationFilterExpressionInput = {
  field: TeamInvitationField;
  op: QueryOperation;
  value: Scalars['String'];
};

export type TeamInvitationFilterInput = {
  and?: Maybe<Array<TeamInvitationFilterInput>>;
  expression?: Maybe<TeamInvitationFilterExpressionInput>;
  not?: Maybe<TeamInvitationFilterInput>;
  or?: Maybe<Array<TeamInvitationFilterInput>>;
};

export type TeamInvitationRequest = {
  __typename?: 'TeamInvitationRequest';
  id: Scalars['ID'];
  prevTeamAuthority?: Maybe<SportsTeamAuthority>;
  role?: Maybe<SportsRole>;
  status: SportsTeamInvitationRequestStatus;
  teamAuthority?: Maybe<SportsTeamAuthority>;
  user: SportsUser;
};

export enum TeamMapField {
  createdAt = 'createdAt'
}

export type TeamMapOrderByInput = {
  field: TeamMapField;
  sort: SimpleOrder;
};

export type TeamSportsData = {
  __typename?: 'TeamSportsData';
  chartData: ChartData;
  checkedList: Array<CheckedData>;
  conditionCareUserList: ConditionCareUserList;
  conditionReportData: ConditionReportData;
  currentInjuryUserList: InjuryUserList;
  currentSorenessUserList: CurrentSorenessUserList;
  dailyData: DailyTeamData;
  injuryList: Array<Injury>;
  insightData: Array<InsightDataItem>;
  insightDataEntryByPage: InsightDataItem;
  latestFeedbackList: Array<Feedback>;
  riskAtInjuryUserList: RiskAtInjuryUserList;
  weeklyWorkloadSum: WeeklyWorkloadSum;
};


export type TeamSportsDataChartDataArgs = {
  cursor: Scalars['DateTime'];
  dataName: ChartDataName;
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  timezone: Scalars['String'];
};


export type TeamSportsDataCheckedListArgs = {
  from: Scalars['DateTime'];
  timezone: Scalars['String'];
  to: Scalars['DateTime'];
};


export type TeamSportsDataConditionCareUserListArgs = {
  timezone: Scalars['String'];
};


export type TeamSportsDataConditionReportDataArgs = {
  date: Scalars['DateTime'];
  timezone: Scalars['String'];
};


export type TeamSportsDataCurrentSorenessUserListArgs = {
  timezone: Scalars['String'];
};


export type TeamSportsDataDailyDataArgs = {
  cursor: Scalars['DateTime'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  timezone: Scalars['String'];
};


export type TeamSportsDataInsightDataArgs = {
  cursor: Scalars['DateTime'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  groups?: Maybe<Array<InsightDataGroup>>;
  names?: Maybe<Array<InsightDataName>>;
  stride?: Maybe<InsightDataStride>;
  timezone: Scalars['String'];
};


export type TeamSportsDataInsightDataEntryByPageArgs = {
  cursor: Scalars['DateTime'];
  filter?: Maybe<TeamUserMapForTeamDataFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  name: InsightDataName;
  offset?: Maybe<Scalars['Int']>;
  timezone: Scalars['String'];
};


export type TeamSportsDataRiskAtInjuryUserListArgs = {
  timezone: Scalars['String'];
};


export type TeamSportsDataWeeklyWorkloadSumArgs = {
  eventOnly: Scalars['Boolean'];
  groupId?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type TeamSubscription = {
  __typename?: 'TeamSubscription';
  endAt: Scalars['Date'];
  id: Scalars['String'];
  maxPlayerCount: Scalars['Int'];
  planId: Scalars['String'];
  planOptionId: Scalars['String'];
  status: SubscriptionStatus;
};

export enum TeamType {
  ORG = 'ORG',
  TEAM = 'TEAM'
}

export type TeamUserFilter = {
  teamAuthority?: Maybe<SportsTeamAuthority>;
};

export type TeamUserInput = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type TeamUserListInput = {
  teamId: Scalars['ID'];
  userIdList?: Maybe<Array<Scalars['ID']>>;
};

export enum TeamUserMapField {
  groupId = 'groupId',
  position = 'position',
  role = 'role',
  teamAuthority = 'teamAuthority',
  userId = 'userId',
  userName = 'userName'
}

export type TeamUserMapFilterExpressionInput = {
  field: TeamUserMapField;
  op: QueryOperation;
  value: Scalars['String'];
};

export type TeamUserMapFilterInput = {
  and?: Maybe<Array<TeamUserMapFilterInput>>;
  expression?: Maybe<TeamUserMapFilterExpressionInput>;
  not?: Maybe<TeamUserMapFilterInput>;
  or?: Maybe<Array<TeamUserMapFilterInput>>;
};

export enum TeamUserMapForTeamDataField {
  groupId = 'groupId',
  position = 'position',
  role = 'role',
  teamAuthority = 'teamAuthority',
  userId = 'userId'
}

export type TeamUserMapForTeamDataFilterExpressionInput = {
  field: TeamUserMapForTeamDataField;
  op: QueryOperation;
  value: Scalars['String'];
};

export type TeamUserMapForTeamDataFilterInput = {
  and?: Maybe<Array<TeamUserMapForTeamDataFilterInput>>;
  expression?: Maybe<TeamUserMapForTeamDataFilterExpressionInput>;
  not?: Maybe<TeamUserMapForTeamDataFilterInput>;
  or?: Maybe<Array<TeamUserMapForTeamDataFilterInput>>;
};

export type TimeSerializable = {
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
};

export type Timezone = {
  __typename?: 'Timezone';
  value: Scalars['String'];
};

export type ToggleInput = {
  routineId: Scalars['ID'];
  status: RoutineActivityStatus;
  timezone: Scalars['String'];
  toggledAt: Scalars['Date'];
};

export type ToggleTeamUserStarredInput = {
  starerId: Scalars['ID'];
  status: Scalars['Boolean'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Training = Event & {
  __typename?: 'Training';
  attendeeChartData: AttendeeChartData;
  endAt: Scalars['Date'];
  eventInvitees: Array<TrainingInvitee>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  specialNote?: Maybe<Scalars['String']>;
  startAt: Scalars['Date'];
  team?: Maybe<SportsTeam>;
  timezone: Scalars['String'];
  title: Scalars['String'];
  trainingPoint?: Maybe<Scalars['String']>;
  type: EventType;
  workloadCheckedUserCount: Scalars['Float'];
};

export type TrainingInvitee = EventInvitee & {
  __typename?: 'TrainingInvitee';
  absenceReason?: Maybe<Scalars['String']>;
  event: Event;
  hasChecked: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  status?: Maybe<EventInviteeStatus>;
  /** the player who is invited to this event */
  user?: Maybe<SportsUser>;
  workoutType?: Maybe<WorkoutType>;
};


export type TrainingInviteeEventArgs = {
  teamId?: Maybe<Scalars['ID']>;
};

export type TransferTeamOwnership = {
  __typename?: 'TransferTeamOwnership';
  adminTeamUserMap: SportsTeamUserMap;
  ownerTeamUserMap: SportsTeamUserMap;
};

export type UpdateAttendanceStatusInput = {
  absenceReason?: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
  status: EventInviteeStatus;
};

export type UpdateBillingBuyerEmailInput = {
  billingId?: Maybe<Scalars['ID']>;
  buyerEmail: Scalars['String'];
  identityToken: Scalars['ID'];
};

export type UpdateBillingBuyerNameInput = {
  billingId?: Maybe<Scalars['ID']>;
  buyerName: Scalars['String'];
  identityToken: Scalars['ID'];
};

export type UpdateBillingBuyerNumberInput = {
  billingId?: Maybe<Scalars['ID']>;
  buyerTel: Scalars['String'];
  identityToken: Scalars['ID'];
};

export type UpdateBillingTokenInput = {
  identityToken: Scalars['ID'];
  impUid: Scalars['String'];
};

export type UpdateBodyInput = {
  height: Scalars['Float'];
  heightUnit?: Maybe<HeightUnit>;
  id: Scalars['ID'];
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
  weight: Scalars['Float'];
  weightUnit?: Maybe<WeightUnit>;
};

export type UpdateConditionInput = {
  fatigue: Scalars['Int'];
  id: Scalars['ID'];
  mood: Scalars['Int'];
  muscle: Scalars['Int'];
  sleepDuration: Scalars['Int'];
  sleepQuality: Scalars['Int'];
  sleepTime: Scalars['DateTime'];
  stress: Scalars['Int'];
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type UpdateCouponInput = {
  discountType: CouponDiscountType;
  discountValue: Scalars['Int'];
  limitCount: Scalars['Int'];
};

export type UpdateEventInput = {
  competition?: Maybe<Scalars['String']>;
  endAt: Scalars['DateTime'];
  eventInviteIdentityList: Array<EventInviteIdentity>;
  expectedIntensity?: Maybe<Scalars['Int']>;
  hideExpectedIntensityFromPlayers?: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: EventType;
  venue?: Maybe<Venue>;
};

export type UpdateEventWorkloadDurationInput = {
  endDate: Scalars['DateTime'];
  eventId: Scalars['String'];
  startDate: Scalars['DateTime'];
  timezone: Scalars['String'];
  userIdList: Array<Scalars['ID']>;
};

export type UpdateFeedbackCommentInput = {
  comment: Scalars['String'];
  commentId: Scalars['ID'];
  feedbackId: Scalars['ID'];
};

export type UpdateFeedbackInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type UpdateInjuryInput = {
  bodyPart?: Maybe<SportsBodyPartName>;
  /** 최대 100자 */
  diagnosis?: Maybe<Scalars['String']>;
  historyStatus: InjuryHistoryStatus;
  id: Scalars['ID'];
  maintainFileIdList: Array<Scalars['ID']>;
  /** 최대 100자 */
  medicalRecord?: Maybe<Scalars['String']>;
  occurDate: Scalars['DateTime'];
  /** 최대 100자 */
  reason?: Maybe<Scalars['String']>;
  return: ReturnInput;
  status: InjuryStatus;
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UpdateInput = {
  routineId: Scalars['ID'];
  schedule?: Maybe<ScheduleInput>;
  title: Scalars['String'];
};

export type UpdateLicenseInput = {
  endAt: Scalars['DateTime'];
  licenseId: Scalars['ID'];
  startAt: Scalars['DateTime'];
};

export type UpdateLineup = {
  eventId: Scalars['String'];
  eventInviteeList: Array<LineupInvitee>;
};

export type UpdateMatchResultInput = {
  eventId: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  result?: Maybe<MatchResultStatus>;
  score?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UpdateNoWorkoutInput = {
  reasonForNoWorkout: NoWorkoutType;
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
};

export type UpdateSpecialNoteInput = {
  eventId: Scalars['ID'];
  specialNote: Scalars['String'];
};

export type UpdateSportsTeamInput = {
  id: Scalars['ID'];
  introduction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pictureFileId?: Maybe<Scalars['String']>;
  region?: Maybe<CountryCode>;
  sportsCategory?: Maybe<SportsCategory>;
};

export type UpdateSportsUserInput = {
  birthdate?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['ID']>;
  introduction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nationality?: Maybe<CountryCode>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberVerified?: Maybe<Scalars['Boolean']>;
  pictureFileId?: Maybe<Scalars['String']>;
  priorSportsCategory?: Maybe<SportsCategory>;
  priorSportsPosition?: Maybe<SportsPosition>;
  priorSportsRole?: Maybe<SportsRole>;
  pushConditionCheckReminderSchedule?: Maybe<Scalars['String']>;
  pushConditionReminderAllowed?: Maybe<Scalars['Boolean']>;
  pushMarketingAllowed?: Maybe<Scalars['Boolean']>;
  pushReminderAllowed?: Maybe<Scalars['Boolean']>;
  pushWorkloadCheckReminderSchedule?: Maybe<Scalars['String']>;
  pushWorkloadReminderAllowed?: Maybe<Scalars['Boolean']>;
};

export type UpdateTeamGroupInput = {
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  teamId: Scalars['ID'];
  userIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateTrainingPointInput = {
  eventId: Scalars['ID'];
  trainingPoint: Scalars['String'];
};

export type UpdateWorkloadInput = {
  duration: Scalars['Int'];
  eventId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  intensity: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  satisfaction: Scalars['Int'];
  time: Scalars['DateTime'];
  timezone: Scalars['String'];
  type: WorkoutType;
};


export type UploadBannerInput = {
  /** 클라이언트에서 지정한 날짜의 UTC 시간값 */
  endAt?: Maybe<Scalars['Date']>;
  landingUrl?: Maybe<Scalars['String']>;
  /** 클라이언트에서 지정한 날짜의 UTC 시간값 */
  startAt?: Maybe<Scalars['Date']>;
  targetOptionList?: Maybe<Array<Maybe<TargetOptionInput>>>;
  title: Scalars['String'];
};

export type UploadCoordinatingReportInput = {
  endAt?: Maybe<Scalars['Date']>;
  reportType: ReportType;
  startAt: Scalars['Date'];
  teamIdList: Array<Scalars['ID']>;
};

export type UploadProfileResponse = {
  __typename?: 'UploadProfileResponse';
  team?: Maybe<SportsTeam>;
  user?: Maybe<SportsUser>;
};

export type UseCoupon = {
  __typename?: 'UseCoupon';
  id?: Maybe<Scalars['ID']>;
  isUsed: Scalars['Boolean'];
};

export type UserBodyData = {
  __typename?: 'UserBodyData';
  BMI?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<HeightUnit>;
  id?: Maybe<Scalars['ID']>;
  recommendedHydration?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
};

export enum UserConditionDataType {
  FATIGUE = 'FATIGUE',
  MOOD = 'MOOD',
  MUSCLE = 'MUSCLE',
  SLEEP_DURATION = 'SLEEP_DURATION',
  SLEEP_QUALITY = 'SLEEP_QUALITY',
  STRESS = 'STRESS'
}

export type UserDataOrderByInput = {
  cursor?: Maybe<Scalars['DateTime']>;
  field: UserDataType;
  sort: SimpleOrder;
  stride: UserDataStride;
  timezone: Scalars['String'];
};

export enum UserDataStride {
  DAY = 'DAY'
}

export enum UserDataType {
  condition = 'condition',
  createdAt = 'createdAt',
  fatigue = 'fatigue',
  height = 'height',
  mood = 'mood',
  muscle = 'muscle',
  name = 'name',
  sleepDuration = 'sleepDuration',
  sleepQuality = 'sleepQuality',
  stress = 'stress',
  weight = 'weight',
  worklaodACWR = 'worklaodACWR',
  worklaodAX = 'worklaodAX',
  worklaodCX = 'worklaodCX',
  workload = 'workload',
  workloadAXD = 'workloadAXD',
  workloadDuration = 'workloadDuration',
  workloadIntensity = 'workloadIntensity',
  workloadSatisfaction = 'workloadSatisfaction'
}

export type UserFilter = {
  searchTerm?: Maybe<Scalars['String']>;
};

export type UserWorkloadData = {
  __typename?: 'UserWorkloadData';
  acwr?: Maybe<Scalars['Float']>;
  aw?: Maybe<Scalars['Int']>;
  awd?: Maybe<Scalars['Float']>;
  awi?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  cw?: Maybe<Scalars['Int']>;
  duration: Statistic;
  id?: Maybe<Scalars['ID']>;
  intensity: Statistic;
  reasonForNoWorkout?: Maybe<NoWorkoutType>;
  satisfaction: Statistic;
  time: Scalars['Date'];
  workload: Statistic;
  workloadList: Array<SportsRawWorkload>;
};

export enum UserWorkloadRiskAtInjuryLabelType {
  RISK_CAUTION = 'RISK_CAUTION',
  RISK_DETECTED = 'RISK_DETECTED',
  RISK_INCREASED = 'RISK_INCREASED'
}

export enum UserWorkloadRiskAtInjuryReasonType {
  HIGH_ACWR = 'HIGH_ACWR',
  HIGH_WEEKLY_WORKLOAD = 'HIGH_WEEKLY_WORKLOAD',
  LOW_ACWR = 'LOW_ACWR',
  MONOTONY = 'MONOTONY',
  NON_ELASTIC_INTENSITY = 'NON_ELASTIC_INTENSITY',
  STRAIN = 'STRAIN',
  WEEKLY_WORKLOAD_SURGE = 'WEEKLY_WORKLOAD_SURGE'
}

export enum UserWorkloadSuggestionDataType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X'
}

export enum Venue {
  AWAY = 'AWAY',
  ETC = 'ETC',
  HOME = 'HOME'
}

export type VersionRevision = {
  __typename?: 'VersionRevision';
  appId: Scalars['String'];
  enableUserDelete: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  forceUpdate: Scalars['Boolean'];
  id: Scalars['ID'];
  optionalUpdate: Scalars['Boolean'];
  os?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type WeeklyWorkloadSum = {
  __typename?: 'WeeklyWorkloadSum';
  lastWeek: Array<Scalars['Int']>;
  thisWeek: Array<Scalars['Int']>;
};

export enum WeightUnit {
  kg = 'kg',
  lb = 'lb'
}

export type Workload = {
  __typename?: 'Workload';
  duration: Scalars['Int'];
  event?: Maybe<EventInvitee>;
  id: Scalars['ID'];
  intensity: Scalars['Int'];
  isUpdatedByCoach: Scalars['Boolean'];
  load: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  satisfaction: Scalars['Int'];
  time: Scalars['DateTimeString'];
  timezone: Scalars['String'];
  type: WorkoutType;
};

export type WorkloadData = {
  __typename?: 'WorkloadData';
  acwr?: Maybe<Scalars['Float']>;
  aw?: Maybe<Scalars['Int']>;
  awd?: Maybe<Scalars['Float']>;
  awi?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  cw?: Maybe<Scalars['Int']>;
  duration: Statistic;
  id?: Maybe<Scalars['ID']>;
  intensity: Statistic;
  isChecked: Scalars['Boolean'];
  reasonForNoWorkout?: Maybe<NoWorkoutType>;
  satisfaction: Statistic;
  workload: Statistic;
  workloadList: Array<Workload>;
};

export type WorkloadEventAlarm = Alarm & {
  __typename?: 'WorkloadEventAlarm';
  createUser: SportsUser;
  createdAt: Scalars['DateTime'];
  event?: Maybe<Event>;
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  player: SportsUser;
  team?: Maybe<SportsTeam>;
  type: AlarmType;
};

export enum WorkoutType {
  NORMAL = 'NORMAL',
  REHAB = 'REHAB'
}
