import { useState } from 'react'

import _cloneDeep from 'lodash/cloneDeep'

import { Box } from '@plco-pro/components/atoms/box'
import { Button } from '@plco-pro/components/atoms/button'
import { Text } from '@plco-pro/components/atoms/text'
import { CardPlayerInjuryHistory } from '@plco-pro/components/organisms/card-player-injury-history/card-player-injury-history'
import {
  SportsBodyPartName,
  SportsUserSorenessRecentData,
  SportsUserSorenessRecentDataValue,
  useSportsUserInjuryHistoryTableQuery,
} from '@plco-pro/graphqls/react.generated'
import { useNationality } from '@plco-pro/hooks'
import { useI18n } from '@plco-pro/hooks/i18n'

type Props = {
  playerId: string
  injuryPart: SportsBodyPartName
}

const LIMIT = 5

export const PlayerInjuryHistoryContainer = ({ playerId, injuryPart }: Props) => {
  const { formatMessage: f } = useI18n()
  const nationality = useNationality()
  const [userInjuryHistoryData, setUserInjuryHistoryData] = useState<
    SportsUserSorenessRecentData | undefined
  >()

  const { data, loading, fetchMore } = useSportsUserInjuryHistoryTableQuery({
    skip: !injuryPart,
    variables: {
      input: {
        nationality,
      },
      id: playerId,
      bodyPart: injuryPart,
      offset: 0,
      limit: LIMIT,
    },
    onCompleted: () =>
      setUserInjuryHistoryData(
        // FIXME:
        data?.sports.user?.sorenessHistory.recentData as SportsUserSorenessRecentData,
      ),
  })

  const onFetchMore = () => {
    const previousResultValues = userInjuryHistoryData?.values

    if (!userInjuryHistoryData?.hasNext || !previousResultValues) {
      return
    }

    fetchMore({
      variables: {
        offset: userInjuryHistoryData.values.length,
      },
    }).then((fetchMoreResult) => {
      if (!fetchMoreResult.data.sports.user?.sorenessHistory) {
        return
      }

      const fetchMoreValues = fetchMoreResult.data.sports.user.sorenessHistory.recentData.values

      const values = [...(previousResultValues || []), ...fetchMoreValues]

      const copyResult = _cloneDeep(fetchMoreResult)

      if (!copyResult.data.sports.user) {
        return
      }

      copyResult.data.sports.user.sorenessHistory.recentData.values = values

      setUserInjuryHistoryData(
        // FIXME:
        copyResult.data?.sports.user?.sorenessHistory.recentData as SportsUserSorenessRecentData,
      )
    })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Text variant={'h4'} sx={{ fontWeight: 'bold', mb: '16px' }}>
        {f({ id: 'RECENT_RECORDS' })}
      </Text>

      {userInjuryHistoryData?.values.map(
        (value: SportsUserSorenessRecentDataValue, index: number) => (
          <CardPlayerInjuryHistory key={index} injuryData={value} />
        ),
      )}

      {userInjuryHistoryData?.hasNext && (
        <Button
          variant={'secondary'}
          sx={{
            mb: '24px',
            width: '100%',
          }}
          color={'grey'}
          isLoading={loading}
          onClick={onFetchMore}
          size={'md'}
        >
          {f({ id: 'SEE_MORE' })}
        </Button>
      )}
    </Box>
  )
}
