import { VictoryTooltipProps } from 'victory'

import { Box } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { ChartTooltipPeriod } from '@plco-pro/components/molecules/chart-label-tooltip-multi-team-data'
import {
  DEFAULT_TOOLTIP_WIDTH,
  TOOLTIP_HEADER_HEIGHT,
} from '@plco-pro/components/molecules/chart-tooltip-workload-history'
import { WorkloadHistoryEventItem } from '@plco-pro/components/molecules/workload-history-event-item'
import { WorkloadHistoryUserInfo } from '@plco-pro/components/molecules/workload-history-user-info'
import { SportsRawWorkload } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useWorkloadParser } from '@plco-pro/hooks/workload-parser'
import { Color } from '@plco-pro/maps/chart-system/chart-system.types'
import theme from '@plco-pro/themes/main'

export const PERIOD_WHITE_LIST = ['1_DAY', 'WEEKLY', 'MONTHLY']

const TOOLTIP_MIN_HEIGHT = 148
const TOOLTIP_MAX_HEIGHT = 362

export type WorkloadKey =
  | 'WORKLOAD'
  | 'WORKLOAD_INTENSITY'
  | 'WORKLOAD_DURATION'
  | 'WORKLOAD_SATISFACTION'

export type ChartLabelWorkloadHistoryProps = VictoryTooltipProps & {
  date?: string
  pictureUrl?: string
  period: ChartTooltipPeriod
  name: string
  dataType: WorkloadKey
  workloadList: SportsRawWorkload[]
  colorList?: Color[]
  chipComponent: React.ReactNode
}

export const ChartLabelWorkloadHistory = (props: ChartLabelWorkloadHistoryProps) => {
  const { date, period, pictureUrl, name, dataType, workloadList, colorList, chipComponent } = props

  const { formatMessage: f } = useI18n()
  const { getWorkloadLabel } = useWorkloadParser()

  const showWorkloadList = PERIOD_WHITE_LIST.includes(period) && !!workloadList.length

  return (
    <Card
      sx={{
        zIndex: 1000,
        width: DEFAULT_TOOLTIP_WIDTH,
        minHeight: TOOLTIP_MIN_HEIGHT,
        maxHeight: TOOLTIP_MAX_HEIGHT,
        borderRadius: 15,
        overflow: 'hidden',
        position: 'absolute',
      }}
    >
      <Flex sx={{ flexDirection: 'column' }}>
        {/* header */}
        <Flex
          sx={{
            bg: 'grey-50',
            width: '100%',
            p: '10px 24px',
            height: TOOLTIP_HEADER_HEIGHT,
          }}
        >
          <Text sx={{ fontWeight: 'bold' }} variant={'p1'} appearance={'hint'}>
            {date}
          </Text>
        </Flex>
        <Box
          sx={{
            minHeight: TOOLTIP_MIN_HEIGHT - TOOLTIP_HEADER_HEIGHT,
            maxHeight: TOOLTIP_MAX_HEIGHT - TOOLTIP_HEADER_HEIGHT,
            overflowY: 'scroll',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              p: '16px 24px 0px 24px',
            }}
          >
            {/* user info */}
            <WorkloadHistoryUserInfo name={name} pictureUrl={pictureUrl} />
            {!PERIOD_WHITE_LIST.includes(period) && (
              <Text
                sx={{ fontWeight: 'bold', pb: 1 }}
                variant={'p2'}
                color={theme.colors['primary-400']}
              >
                {workloadList.length
                  ? f({ id: 'monitoring.chart.workload-count' }, { count: workloadList.length })
                  : ''}
              </Text>
            )}
            {/* workload info */}
            <Flex
              sx={{
                alignItems: 'center',
                gap: '6px',
                pb: 2,
              }}
            >
              {chipComponent}
            </Flex>
            {showWorkloadList && (
              <Flex sx={{ flexDirection: 'column', gap: 1, pb: 3 }}>
                {/* event list" */}
                <Text
                  sx={{ fontWeight: 'bold' }}
                  variant={'p2'}
                  color={theme.colors['primary-400']}
                >
                  {f({ id: 'monitoring.chart.workload-count' }, { count: workloadList.length })}
                </Text>
                <Flex sx={{ flexDirection: 'column', gap: '12px' }}>
                  {/* event item  */}
                  {workloadList.map((workloadItem, index) => {
                    const result = getWorkloadLabel(dataType, workloadItem)

                    if (!result) {
                      return
                    }

                    return (
                      <WorkloadHistoryEventItem
                        key={workloadItem.id}
                        color={colorList?.[index % 4]}
                        title={result.title}
                        type={workloadItem.type}
                        prevLabel={result.prevLabel}
                        afterLabel={result.afterLabel}
                      />
                    )
                  })}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
    </Card>
  )
}
