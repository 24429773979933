import { Flex, Spinner } from '@plco-pro/components/atoms'
import { Modal } from '@plco-pro/components/organisms/modal'

interface Props {
  open: boolean
}

export const ModalFileLoading = ({ open }: Props) => {
  return (
    <Modal width={'80px'} height={'80px'} borderRadius={'10px'} open={open} showCloseIcon={false}>
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Spinner />
      </Flex>
    </Modal>
  )
}
