import React from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { NavbarPlayerModal } from '@plco-pro/components/organisms/navbar-player-modal'
import { LAYOUT_MAIN_MAX_WIDTH } from '@plco-pro/providers/responsive'

export type LayoutPlayersProps = {}

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'stretch',
      minHeight: '100vh',
      bg: 'background',
    }}
    {...props}
  />
)

const HeaderBox = (props: BoxProps) => <Box sx={{ flex: '0 1 auto' }} {...props} />

const MainFlex = (props: FlexProps) => (
  <Flex
    sx={{
      flex: '1 0 auto',
      flexDirection: 'row',
      alignItems: 'stretch',
    }}
    {...props}
  />
)

const ContentFlex = (props: FlexProps) => (
  <Flex
    sx={{
      flex: '1 1 auto',
      maxWidth: LAYOUT_MAIN_MAX_WIDTH,
    }}
    {...props}
  />
)

export const LAYOUT_PLAYER_CONTENT_PADDING = [2, 4, 4, 4]

export const LayoutPlayerModal: React.FunctionComponent<LayoutPlayersProps> = ({ children }) => {
  return (
    <ContainerFlex>
      <HeaderBox>
        <NavbarPlayerModal />
      </HeaderBox>

      <MainFlex>
        <Flex sx={{ width: '100%', justifyContent: 'center', p: LAYOUT_PLAYER_CONTENT_PADDING }}>
          <ContentFlex>{children}</ContentFlex>
        </Flex>
      </MainFlex>
    </ContainerFlex>
  )
}
