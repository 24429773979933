import { Label } from '@plco-pro/components/atoms'

const commonStyle = {
  width: '18px',
  height: '18px',
  border: '1px solid',
  borderRadius: '4px',
  alignSelf: 'center',
  borderColor: 'grey-300',
  cursor: 'pointer',
}

const checkedStyle = {
  borderColor: 'primary-500',
  backgroundColor: 'primary-500',
  backgroundImage: 'url(/images/check.png)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '70% 70%',
}

interface Props {
  checked: boolean
  onClick: () => void
}

export const LabelInjury = ({ checked, onClick }: Props) => {
  return (
    <Label
      sx={
        !checked
          ? {
              ...commonStyle,
            }
          : {
              ...commonStyle,
              ...checkedStyle,
            }
      }
      onClick={onClick}
    />
  )
}
