export const messages = {
  'blog.header.title': 'Blog',
  'blog.header.search.placeholder': 'Tìm kiếm',
  'blog.posted-at': '{days} ngày trước',
  'blog.reading-time': '⏱ Thời lượng {minute}phút',
  'blog.onboarding-section.title': '👋 Hướng dẫn sử dụng PLCO 100%',
  'blog.onboarding-section.subtitle': '7 điều nhất định phải đọc trong 14 ngày dùng thử miễn phí',
  'blog.onboarding-section.view-all': 'Xem tất cả',
  'blog.featured-section.title': '📝 Nội dung PLCO đề xuất',
  'blog.featured-section.subtitle': 'Khám phá những câu chuyện thể thao thú vị\n được PLCO đề xuất',
  'blog.tag-section.title': '🔖 Gắn thẻ tất cả',
  'blog.article.featured.title': '📄 Bài viết nên đọc cùng',
  'blog.article.featured.subtitle': 'Giới thiệu bài viết có chủ đề liên quan đến bài viết đã đọc',
  'blog.article.featured.empty': 'Chà! Chưa có bài viết nào được đề xuất...',
  'blog.banner.onboarding.title': 'Hướng dẫn sử dụng PLCO 100%',
  'blog.banner.onboarding.subtitle': 'Bạn có bỏ lỡ nội dung giới thiệu nào không?',
  'blog.banner.FAQ.title': 'Cách sử dụng chức năng & FAQ',
  'blog.banner.FAQ.subtitle': 'Tìm hiểu thêm về PLCO',
  'blog.search.title': '🔍 Kết quả tìm kiếm cho ‘{q}’',
  'blog.search.count':
    '{count, plural, =0 {Không tìm thấy kết quả. Vui lòng kiểm tra lại!} other {{count} Bài viết}}',
  'blog.search.empty': 'Không tìm thấy kết quả nào. Vui lòng kiểm tra lại!',
  'blog.onboarding.title': 'Hướng dẫn sử dụng PLCO 100%',
  'blog.onboarding.article.next-article.mdAndDown': 'Bài tiếp theo',
  'blog.onboarding.article.next-article': 'Đọc bài tiếp theo',
  'blog.onboarding.article.prev-article.mdAndDown': 'Bài trước',
  'blog.onboarding.article.prev-article': 'Đọc bài trước',
  'blog.error.internal-server-error.title': 'Đã xảy ra lỗi khi xử lý trang.',
  'blog.error.internal-server-error.subtitle': 'Vui lòng nhấp vào nút ‘Tải lại’ để thử lại.',
  'dashboard.update.label': 'Cập nhật {date}',
  'dashboard.dday.chip': '🔥 Dùng thử miễn phí D-{leftDay}',
  'dashboard.calendar.today': 'Hôm nay',
  'dashboard.calendar.setting.title': 'Cài đặt',
  'dashboard.calendar.setting.general.subtitle': 'Cài đặt chung',
  'dashboard.calendar.setting.general.condition-check': 'Kiểm tra thể trạng',
  'dashboard.calendar.setting.check.subtitle': 'Kiểm tra',
  'dashboard.calendar.data.show-more': 'xem thêm···',
  'dashboard.condition-unchecked-players.title': 'Gửi thông báo chưa kiểm tra thể trạng',
  'dashboard.workout-unchecked-players.title': 'Gửi thông báo chưa kiểm tra tập luyện',
  'dashboard.data.condition.title': 'Cầu thủ cần kiểm tra thể trạng',
  'dashboard.data.condition.chip.in-bad': 'Cầu thủ không khỏe',
  'dashboard.data.condition.chip-guide.in-bad':
    'Hiển thị cầu thủ có thể trạng không khỏe khi kiểm tra thể trạng trong ngày.\n- Mức độ mệt mỏi, căng thẳng, cơ bắp, tâm trạng, chất lượng giấc ngủ: Dưới 4 điểm\n- Thời gian ngủ: Dưới 6 tiếng',
  'dashboard.data.condition.chip.plummeted': 'Cầu thủ sụt giảm mạnh',
  'dashboard.data.condition.chip-guide.plummeted':
    'Hiển thị cầu thủ có các mục thể trạng giảm đột ngột so với xu hướng gần đây.',
  'dashboard.data.condition.empty': 'Quản lý bản thân xuất sắc',
  'dashboard.data.risk-of-injury.title': 'Cầu thủ có nguy cơ chấn thương',
  'dashboard.data.risk-of-injury.empty': 'Không có nguy cơ chấn thương',
  'dashboard.data.risk-of-injury.increased': 'Tăng nguy cơ chấn thương',
  'dashboard.data.risk-of-injury.caution': 'Lưu ý nguy cơ chấn thương',
  'dashboard.data.risk-of-injury.detected': 'Nhận biết nguy cơ chấn thương',
  'dashboard.data.risk-of-injury.reason.high-acwr': 'Quá sức so với thể lực',
  'dashboard.data.risk-of-injury.reason.low-acwr': 'Lượng tập luyện ít so với thể lực',
  'dashboard.data.risk-of-injury.reason.weekly-workload-surge':
    'Tải tập luyện trong tuần tăng đột biến so với tuần trước',
  'dashboard.data.risk-of-injury.reason.strain': 'Áp lực tập luyện về thể chất và tinh thần',
  'dashboard.data.risk-of-injury.reason.high-weekly-workload': 'tải tập luyện cao trong tuần',
  'dashboard.data.risk-of-injury.reason.monotony': 'Tập luyện đơn giản',
  'dashboard.data.risk-of-injury.reason.non-elastic-intensity':
    'Cường độ tập luyện không linh hoạt',
  'dashboard.data.risk-of-injury.suggestion.A':
    'Kết hợp tập luyện cường độ cao và thấp để giảm lượng tập luyện, có thể giúp giảm nguy cơ chấn thương do mệt mỏi và áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.B':
    'Điều chỉnh cường độ và thời gian tập luyện để giảm lượng tập luyện, có thể giúp giảm nguy cơ chấn thương do mệt mỏi và áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.C':
    'Kết hợp tập luyện cường độ cao và thấp để giảm hoặc tăng dần lượng tập luyện, có thể giúp giảm nguy cơ chấn thương do mệt mỏi và áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.D':
    'Điều chỉnh cường độ và thời gian tập luyện để giảm hoặc tăng dần lượng tập luyện, có thể giúp giảm nguy cơ chấn thương do mệt mỏi và áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.E':
    'Kết hợp tập luyện cường độ cao và thấp để giảm hoặc tăng dần lượng tập luyện, có thể giúp giảm nguy cơ chấn thương do mệt mỏi!',
  'dashboard.data.risk-of-injury.suggestion.F':
    'Điều chỉnh cường độ và thời gian tập luyện để giảm hoặc tăng dần lượng tập luyện, có thể giúp giảm nguy cơ chấn thương do mệt mỏi!',
  'dashboard.data.risk-of-injury.suggestion.G':
    'Kết hợp tập luyện cường độ cao và thấp để giảm lượng tập luyện, có thể giúp giảm nguy cơ chấn thương do mệt mỏi!',
  'dashboard.data.risk-of-injury.suggestion.H':
    'Điều chỉnh cường độ và thời gian tập luyện để giảm lượng tập luyện, có thể giúp giảm nguy cơ chấn thương do mệt mỏi!',
  'dashboard.data.risk-of-injury.suggestion.I':
    'Kết hợp tập luyện cường độ cao và thấp để tăng lượng tập luyện, có thể giúp ngăn ngừa nguy cơ chấn thương do thể lực yếu và giảm áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.J':
    'Điều chỉnh cường độ và thời gian tập luyện để tăng lượng tập luyện, có thể giúp ngăn ngừa nguy cơ chấn thương do thể lực yếu và giảm áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.K':
    'Kết hợp tập luyện cường độ cao và thấp để tăng lượng tập luyện, có thể giúp ngăn ngừa nguy cơ chấn thương do thể lực yếu!',
  'dashboard.data.risk-of-injury.suggestion.L':
    'Điều chỉnh cường độ và thời gian tập luyện để tăng lượng tập luyện, có thể giúp ngăn ngừa nguy cơ chấn thương do thể lực yếu!',
  'dashboard.data.risk-of-injury.suggestion.M':
    'Kết hợp tập luyện cường độ cao và thấp để giảm lượng tập luyện, có thể giúp giảm áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.N':
    'Điều chỉnh cường độ và thời gian tập luyện để giảm lượng tập luyện, có thể giúp giảm áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.O':
    'Kết hợp tập luyện cường độ cao và thấp để giảm hoặc tăng dần lượng tập luyện, có thể giúp giảm áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.P':
    'Điều chỉnh cường độ và thời gian tập luyện để giảm hoặc tăng dần lượng tập luyện, có thể giúp giảm áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.Q':
    'Kết hợp tập luyện cường độ cao và thấp để giảm hoặc tăng dần lượng tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.R':
    'Điều chỉnh cường độ và thời gian tập luyện để giảm hoặc tăng dần lượng tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.S':
    'Kết hợp tập luyện cường độ cao và thấp để lập kế hoạch tập luyện với tải tập luyện linh hoạt, có thể giúp giảm áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.T':
    'Điều chỉnh cường độ và thời gian tập luyện để lập kế hoạch tập luyện với tải tập luyện linh hoạt, có thể giúp giảm áp lực tập luyện!',
  'dashboard.data.risk-of-injury.suggestion.U':
    'Kết hợp tập luyện cường độ cao và thấp để lập kế hoạch tập luyện với tải tập luyện linh hoạt!',
  'dashboard.data.risk-of-injury.suggestion.V':
    'Điều chỉnh cường độ và thời gian tập luyện để lập kế hoạch tập luyện với tải tập luyện linh hoạt!',
  'dashboard.data.risk-of-injury.suggestion.W':
    'Lập kế hoạch tập luyện kết hợp tập luyện cường độ cao và thấp!',
  'dashboard.data.risk-of-injury.suggestion.X': '-',
  'dashboard.data.current-injury.title': 'Cầu thủ bị thương hiện tại',
  'dashboard.data.current-injury.empty': 'Hiện không có cầu thủ nào bị chấn thương',
  'dashboard.data.soreness.title': 'Cầu thủ đang gặp phải cơn đau hiện tại',
  'dashboard.data.soreness.chip.severe': 'Cơn đau nghiêm trọng',
  'dashboard.data.soreness.chip-guide.severe':
    'Hiển thị cầu thủ có ít nhất 1 vị trí cơn đau ở mức độ 3 trở lên.',
  'dashboard.data.soreness.severe.empty': 'Hiện không có cầu thủ nào bị cơn đau nghiêm trọng',
  'dashboard.data.soreness.chip.multiple': 'Có nhiều cơn đau',
  'dashboard.data.soreness.chip-guide.multiple': 'Hiển thị cầu thủ có tổng cộng 4 cơn đau trở lên.',
  'dashboard.data.soreness.multiple.empty': 'Hiện không có cầu thủ nào có nhiều cơn đau',
  'dashboard.data.soreness.list': 'Danh sách các cơn đau',
  'dashboard.data.today-event.title': 'Lịch trình ngày hôm nay',
  'dashboard.data.today-event.empty-player': 'Không có cầu thủ nào được chọn để hiển thị',
  'dashboard.data.today-event-training.none':
    'Không có đối tượng tham gia.\nHãy thử thêm đối tượng tham gia.',
  'dashboard.data.today-event-match.none':
    'Không có cầu thủ thi đấu.\nHãy thử đăng ký cầu thủ thi đấu.',
  'dashboard.data.today-event.match-player.none': 'Hôm nay không có lịch trình tập luyện nào.',
  'dashboard.popup.reminder-condition-unchecked.title':
    'Gửi thông báo thể trạng\ncho {count} cầu thủ?',
  'dashboard.popup.reminder-workout-unchecked.title':
    'Gửi thông báo tập luyện\ncho {count} cầu thủ?',
  'dashboard.popup.reminder-participant-unchecked.title':
    'Gửi thông báo xác nhận tham gia\ntập luyện cho {count} cầu thủ?',
  'dashboard.popup.reminder-workload-unchecked.title':
    'Gửi thông báo tải tập luyện\ncho {count} cầu thủ?',
  'dashboard.popup.reminder.subtitle': 'Chỉ bao gồm những cầu thủ chưa kiểm tra.',
  'dashboard.popup.player-select.all': 'Chọn tất cả',
  'dashboard.popup.player-select.all-selected': 'Đã chọn tất cả ({count})',
  'dashboard.popup.player-select.none-selected': 'Chưa chọn',
  'dashboard.popup.player-select.search.player': 'Tìm kiếm cầu thủ',
  'dashboard.popup.player-select.search.no-input': 'Không có dữ liệu',
  'dashboard.popup.player-select.search.none': 'Không tìm thấy kết quả',
  'dashboard.create-injury.player-select.title': 'Vui lòng chọn cầu thủ',
  'dashboard.button.training-report': 'Xem báo cáo tập luyện',
  'dashboard.button.match-report': 'Xem báo cáo trận đấu',
  'dashboard.button.practice-report': 'Xem báo cáo trận giao hữu',
  'dashboard.button.send-participant-notification': 'Gửi thông báo tham gia',
  'dashboard.button.send-workload-notification': 'Gửi thông báo tải tập luyện',
  'dashboard.button.injury-registration': 'Đăng ký chấn thương',
  'dashboard.button.create-event': 'Thêm lịch trình',
  'dashboard.button.register-lineup': 'Đăng ký cầu thủ thi đấu',
  'dashboard.data-etc.name': 'Tên',
  'dashboard.data-etc.type': 'Loại hình',
  'dashboard.data-etc.part': 'Vị trí',
  'dashboard.data-etc.level': 'Mức độ',
  'dashboard.data-etc.lasting-days': 'Thời gian',
  'dashboard.data-etc.description': 'Mô tả',
  'dashboard.data-etc.position': 'Vị trí thi đấu',
  'dashboard.data-etc.criteria': '{count} mục',
  'dashboard.event.general': 'Thông tin chung',
  'dashboard.event.participant': 'Xác nhận tham gia',
  'dashboard.event.lineup': 'Vận động viên thi đấu',
  'dashboard.event.all': 'Tất cả',
  'dashboard.event.training-attend': 'Tham gia',
  'dashboard.event.training-absent': 'Vắng mặt',
  'dashboard.event.unchecked': 'Chưa kiểm tra',
  'dashboard.event.match-starting': 'Xuất phát',
  'dashboard.event.match-subs': 'Dự bị',
  'dashboard-event.match-vs': 'vs',
  'dashboard-event.match-result': 'Kết quả trận đấu',
  'dashboard.workout.normal': 'Tập thường',
  'dashboard.workout.rehab': 'Tập trị liệu',
  'dashboard.workout.absence-injury': 'Vắng mặt do chấn thương',
  'dashboard.workout.absence-other-reason': 'Vắng mặt do lý do khác',
  'dashboard.soreness.description.level-1': 'Cơn đau nhẹ',
  'dashboard.soreness.description.level-2': 'Cơn đau vừa',
  'dashboard.soreness.description.level-3': 'Cơn đau nặng',
  'dashboard.soreness.description.level-4': 'Cơn đau rất nặng',
  'dashboard.soreness.description.level-5': 'Cơn đau không thể chịu đựng',
  'dashboard.card-team-invite.title': 'Mời tham gia nhóm',
  'dashboard.card-team-invite.subtitle': 'Vui lòng mời thành viên\nbằng mã nhóm',
  'dashboard.filter.schedule.subtitle': 'Lịch trình',
  'dashboard.filter.intensity-check.subtitle': 'Kiểm tra tải tập luyện',
  'ERROR.BAD_REQUEST.sports.joinTeam': 'Đây là nhóm bạn đã gia nhập. Vui lòng thử lại.',
  'ERROR.BAD_REQUEST.restartSubscription.PAYMENT_NO_BALANCE':
    'Thanh toán thất bại do tài khoản không đủ số dư. Vui lòng thay đổi thẻ thanh toán và thử lại.',
  'ERROR.BAD_REQUEST.restartSubscription.PAYMENT_FAIL': 'Thanh toán thất bại. Vui lòng thử lại.',
  'ERROR.BAD_REQUEST.startSubscription.PAYMENT_NO_BALANCE':
    'Thanh toán thất bại do tài khoản không đủ số dư. Vui lòng thay đổi thẻ thanh toán và thử lại.',
  'ERROR.BAD_REQUEST.startSubscription.PAYMENT_FAIL': 'Thanh toán thất bại. Vui lòng thử lại.',
  'ERROR.FORBIDDEN.sports.joinTeam':
    'Nhóm đã tồn tại. (Bạn chỉ có thể sở hữu 1 nhóm. Vui lòng tải lại trang và thử lại.)',
  'ERROR.FORBIDDEN.sports.createTeam':
    'Nhóm đã tồn tại. (Bạn chỉ có thể sở hữu 1 nhóm. Vui lòng tải lại trang và thử lại.)',
  'ERROR.FORBIDDEN.sports.updateTeamUserMap': 'Không có quyền sửa đổi quyền hạn.',
  'ERROR.NOT_FOUND.sports.joinTeam': 'Mã nhóm không hợp lệ. Vui lòng nhập lại.',
  'ERROR.NOT_FOUND.sports.updateTeamGroup': 'Nhóm này không còn tồn tại.',
  'ERROR.NOT_FOUND.sports.deleteTeamGroup': 'Nhóm này không còn tồn tại.',
  'ERROR.CONFLICT.sports.createTeam': 'Nhóm đã tồn tại',
  'ERROR.CONFLICT.sports.createTeamGroup': 'Tên này đã được sử dụng. Vui lòng sử dụng tên khác.',
  'ERROR.CONFLICT.sports.updateTeamGroup': 'Tên này đã được sử dụng. Vui lòng sử dụng tên khác.',
  'ERROR.CONFLICT.sports.joinTeam': 'Đang chờ chấp nhận. Vui lòng đợi xác nhận.',
  'ERROR.CONFLICT.sports.joinTeam.max-invitation': 'Có quá nhiều yêu cầu chờ xử lý.',
  'ERROR.NO_PERMISSION': 'Bạn không có quyền hạn.',
  'ERROR.TEAM_USER_MAP_NOT_FOUND': 'Bạn không còn là thành viên của nhóm này.',
  'ERROR.INTERNAL_SERVER_ERROR.sports.createTeam': 'URL nhóm bị trùng lặp.',
  'ERROR.viewer.id': 'Không thể truy xuất thông tin người dùng. Vui lòng thử lại sau.',
  'ERROR.TEAM_INVALID_CODE': 'Không tìm thấy mã nhóm. Vui lòng kiểm tra lại.',
  'ERROR.TEAM_EXISTING_USER': 'Bạn đã tham gia nhóm này.',
  'ERROR.TEAM_SERVER_ERROR': 'Đã xảy ra lỗi tạm thời. Vui lòng thử lại sau.',
  language: 'Tiếng Hàn',
  'common.today': 'Hôm nay',
  UNCHECKED: 'Chưa kiểm tra',
  HIGH: 'Cao',
  BAD: 'Tệ',
  'index.title':
    'PLCO COACH là dịch vụ giúp theo dõi và quản lý dữ liệu về thể trạng, tải tập luyện, cơn đau và chấn thương của các cầu thủ.',
  '403.title': 'Đây là trang không thể truy cập.',
  '403.subtitle.first': 'Rất tiếc. Bạn không có quyền truy cập trang này.',
  '403.subtitle.second': 'Vui lòng nhấp vào nút ‘Quay lại trang trước’.',
  '403.button': 'Quay lại trang trước',
  '404.title': 'Trang này không tồn tại.',
  '404.subtitle': 'Vui lòng nhấp vào nút ‘Về trang chủ’ để bắt đầu lại.',
  '404.button': 'Về trang chủ',
  'internal-server-error.title': 'Dịch vụ đang bị gián đoạn tạm thời.',
  'internal-server-error.subtitle':
    'Chúng tôi đang nỗ lực khắc phục sự cố.\nVui lòng tham khảo thông tin khoa học thể thao trên blog hoặc nhấp vào nút thử lại sau 3-5 phút.',
  'internal-server-error.button': 'Thử lại',
  'team-new.title': 'Chào mừng bạn, {name}!',
  'team-new.subtitle':
    'PLCO COACH là dịch vụ giúp theo dõi và quản lý dữ liệu về thể trạng, tải tập luyện, cơn đau và chấn thương của các cầu thủ.',
  'team-new.create.title': 'Hãy tạo nhóm và mời các thành viên tham gia PLCO COACH!',
  'team-new.create.button': 'Tạo nhóm',
  'team-new.join.title': 'Bạn có mã nhóm được mời? Nhập mã tại đây.',
  'team-new.join.button': 'Nhập mã',
  'modal-team-create.header.title': 'Tạo nhóm',
  'modal-team-create.footer.left.button': 'Hủy',
  'modal-team-create.footer.right.button': 'Tạo nhóm',
  'modal-team-create.name.label': 'Tên nhóm (bắt buộc)',
  'modal-team-create.name.placeholder': 'Vui lòng nhập tên nhóm. (Ví dụ: PLCO FC)',
  'modal-team-create.category.label': 'Môn thể thao (bắt buộc)',
  'modal-team-create.nationality.label': 'Quốc tịch (bắt buộc)',
  'modal-team-create.region.label': 'Quốc gia (bắt buộc)',
  'modal-team-create.url.label': 'URL nhóm',
  'modal-team-create.url.placeholder': 'Chỉ được sử dụng chữ cái và số',
  'modal-team-create-loading.loading.title': 'Đang tạo nhóm tuyệt vời...',
  'modal-team-create-loading.loading.subtitle': 'Vui lòng đợi trong giây lát.',
  'modal-team-create-loading.success.title': 'Tạo nhóm thành công!',
  'modal-team-create-loading.error.title': 'Tạo nhóm thất bại',
  'modal-team-create-loading.error.subtitle': 'Vui lòng thử lại.',
  'modal-team-join.header.title': 'Nhập mã nhóm',
  'modal-team-join.footer.left.button': 'Hủy',
  'modal-team-join.footer.right.button': 'Xác nhận',
  'modal-team-join.title':
    'Vui lòng nhập mã nhóm gồm 6 ký tự chữ cái và số, sau đó nhấp vào Xác nhận.',
  'modal-team-join.code.placeholder': 'Nhập mã nhóm',
  'modal-team-join-success.footer.right.button': 'Xác nhận',
  'modal-team-join-success.title': 'Kết nối {name} thành công',
  'modal-team-join-success.subtitle': 'Kết nối nhóm sẽ hoàn tất khi người quản lý chấp nhận.',
  'modal-logout.title': 'Chắc chắn muốn đăng xuất?',
  'modal-leave.title': 'Chắc chắn muốn rời khỏi nhóm?',
  'modal-kickout.title': 'Chắc chắn xóa {name} ra khỏi nhóm?',
  'modal-transfer.title': 'Chuyển quyền sở hữu {teamName} cho {userName}?',
  'modal-check-condition-reminder.title.smOnly':
    'Gửi thông báo kiểm tra thể trạng\ncho {count} cầu thủ?',
  'modal-check-condition-reminder.title.mdAndUp':
    'Gửi thông báo kiểm tra thể trạng\ncho {count} cầu thủ?',
  'modal-check-workload-reminder.title.smOnly':
    'Gửi thông báo kiểm tra tập luyện\ncho {count} cầu thủ?',
  'modal-check-workload-reminder.title.mdAndUp':
    'Gửi thông báo kiểm\ntra tập luyện cho {count} cầu thủ?',
  'navbar-user-dropdown.edit-profile': 'Chỉnh sửa hồ sơ',
  'navbar-user-dropdown.logout': 'Đăng xuất',
  'navbar-team-dropdown.team': 'Tổng {membersCount} · Cầu thủ {playersCount}',
  'navbar-team-dropdown.edit-team-profile': 'Chỉnh sửa hồ sơ nhóm',
  'navbar-team-dropdown.team-management': 'Thành viên&quyền hạn',
  'navbar-team-dropdown.team-invite': 'Mời tham gia nhóm',
  'navbar-team-dropdown.team-list': 'Tất cả nhóm ({count})',
  'navbar-team-dropdown.create-team': 'Tạo nhóm',
  'navbar-team-dropdown.payment': 'Đăng ký & Thanh toán',
  'navbar-team-dropdown.team-code.title': 'Bạn có mã nhóm được mời?',
  'navbar-team-dropdown.enter-team-code': 'Nhập mã nhóm',
  'navbar-players.player-data': 'Dữ liệu cầu thủ',
  'navbar-players.back': 'Quay lại',
  'main-drawer.item.dashboard': 'Bảng điều khiển',
  'main-drawer.item.team-chart': 'Biểu đồ nhóm',
  'main-drawer.item.insight': 'Insight',
  'main-drawer.item.monitoring': 'Giám sát nhóm',
  'main-drawer.item.player': 'Đội hình',
  'main-drawer.item.scheduler': 'Lịch trình',
  'main-drawer.item.blog': 'Blog',
  'main-drawer.item.blog.chip': 'Dùng thử miễn phí D-{leftDay}',
  'sub-drawer.item.overview': 'Tổng quan',
  'drawer-language.language': 'Ngôn ngữ',
  'drawer-footer.copyright': '© plco for Coach',
  'drawer-players.search.placeholder': 'Tìm kiếm cầu thủ',
  'drawer-players.starred-players': 'Cầu thủ được yêu thích ({playersCount})',
  'drawer-players.players': 'Cầu thủ ({playersCount})',
  'team-member.back': 'Quay lại',
  'team-member.access.title': 'Thành viên và Quyền hạn',
  'dropdown-menu-team-member.leave': 'Rời khỏi nhóm',
  'dropdown-menu-team-member.kickout': 'Xóa khỏi nhóm',
  'dropdown-menu-team-member.transfer': 'Chuyển quyền sở hữu',
  'monitoring.invite.pending.title':
    'Chờ phê duyệt từ quản trị viên. Hãy đăng nhập lại sau khi được phê duyệt.',
  'monitoring-header.title': 'Giám sát nhóm',
  'monitoring-header.update': 'Cập nhật',
  'monitoring.modal.title': 'Mời vào nhóm',
  'monitoring.modal.team.code': 'Mã nhóm',
  'monitoring.modal.description.top': 'Bạn muốn gửi lời mời cho ai?',
  'monitoring.modal.description.bottom': 'Sao chép và chia sẻ liên kết',
  'monitoring.modal.coach': 'Huấn luyện viên',
  'monitoring.modal.player': 'Cầu thủ',
  'monitoring.modal.clipboard.toasts.success': 'Lời mời tham gia nhóm đã được sao chép',
  'monitoring.modal.clipboard-team-code.toasts.success': 'Mã nhóm đã được sao chép vào clipboard',
  'monitoring.chart.title': 'Biểu đồ nhóm',
  'monitoring.table.title': 'Dữ liệu nhóm',
  'monitoring.table.workout.memo-button': 'Ghi chú tập luyện',
  'monitoring.modal-workout-memo.title': 'Ghi chú tập luyện',
  'monitoring.modal-workout-memo.empty': 'Không có Ghi chú tập luyện nào hết.',
  'monitoring.table.noData': 'Chưa có thành viên nào…',
  'monitoring.table.soreness.degree': 'Cơn đau {degree, selectordinal, other {#Mứcđộ}}',
  'monitoring.table.data-sorting': 'Sắp xếp dữ liệu',
  'monitoring.table.clear-sorting': 'Đặt lại thứ tự sắp xếp',
  'monitoring.table.data': 'Dữ liệu',
  'monitoring.table.sorting': 'Sắp xếp',
  'monitoring.table.asc': 'Thứ tự tăng dần',
  'monitoring.table.desc': 'Thứ tự giảm dần',
  'monitoring.period-button.1_DAY': '1 ngày',
  'monitoring.period-button.7_DAYS': '7 ngày trước',
  'monitoring.period-button.28_DAYS': '28 ngày trước',
  'monitoring.chart.non-has.period': 'Chỉ có thể xem dữ liệu 1 ngày {dataType}',
  'monitoring.chart.tooltip.close': 'Đóng',
  'monitoring.chart.tooltip.another-team.title': 'Vận động/Huấn luyện',
  'monitoring.chart.workload-count': 'Tổng {count} lần',
  'unauthorized.title': 'Rất tiếc, bạn không thể xem nội dung này.',
  'unauthorized.subtitle.first': 'Vui lòng đợi cho đến khi quản trị viên phê duyệt.',
  'unauthorized.subtitle.second': 'Sau khi được duyệt, hãy làm mới trang (nhấn F5).',
  'calendar.navigationLabel.decade': 'Chọn năm',
  'calendar.year': 'Năm',
  'group-monitoring.dropdal-player-select.subtitle': 'Đã chọn {playersCount} người',
  'group-monitoring.dropdal-player-select.placeholder.groups-and-players': 'Tìm kiếm nhóm/cầu thủ',
  'group-monitoring.dropdal-player-select.placeholder.players': 'Tìm kiếm cầu thủ',
  'group-monitoring.dropdal-player-select.select-all': 'Chọn tất cả ({playersCount})',
  'group-monitoring.group-button.button': 'Thêm nhóm mới',
  'group-monitoring.modal-group.create.cancel': 'Xác nhận hủy thêm nhóm mới',
  'group-monitoring.modal-group.create.header': 'Thêm nhóm mới',
  'group-monitoring.modal-group.modify.header': 'Sửa nhóm',
  'group-monitoring.modal-group.modify.cancel': 'Xác nhận hủy chỉnh sửa nhóm?',
  'group-monitoring.modal-group.modify.delete': 'Xóa nhóm',
  'group-monitoring.modal-group.input.label': 'Tên nhóm',
  'group-monitoring.modal-group.input.placeholder': 'Vui lòng nhập tên nhóm. (Tối đa 10 ký tự)',
  'group-monitoring.modal-group.member-list.header.title': 'Chọn thành viên',
  'group-monitoring.modal-group.member-list.header.subtitle':
    'Hãy chọn thành viên để thêm vào nhóm',
  'group-monitoring.modal-group.member-list.placeholder': 'Tìm kiếm cầu thủ',
  'group-monitoring.modal-group.member-list.select-all': 'Chọn tất cả ({playersCount})',
  'group-monitoring.modal-group-delete.text': 'Chắc chắn muốn xóa nhóm?',
  'scheduler.header.title': 'Lịch trình',
  'scheduler.header.button.create-event': 'Thêm lịch trình',
  'scheduler.days.date.event.more': 'xem thêm···',
  'scheduler.days.date.event.count': 'Số lượng',
  'scheduler.modal-event.create.header': 'Thêm lịch trình',
  'scheduler.modal-event.duplicate.header': 'Sao chép lịch trình',
  'scheduler.modal-event.edit.header': 'Sửa lịch trình',
  'scheduler.modal-event.event-name.field-input.label': 'Tên lịch trình',
  'scheduler.modal-event.event-name.field-input.placeholder':
    'Đặt tên cho lịch trình. (Không quá 50 ký tự)',
  'scheduler.modal-event.event-location.field-input.label': 'Địa điểm',
  'scheduler.modal-event.event-location.field-input.placeholder': 'Nhập địa điểm',
  'scheduler.modal-event.event-date.field-input-date.start.label': 'Bắt đầu',
  'scheduler.modal-event.event-date.field-input-date.end.label': 'Kết thúc',
  'scheduler.modal-event.expected-intensity.label': 'Cường độ mục tiêu',
  'scheduler.modal-event.expected-intensity.1': 'Rất nhẹ nhàng',
  'scheduler.modal-event.expected-intensity.2': 'Nhẹ nhàng, có thể trò chuyện.',
  'scheduler.modal-event.expected-intensity.3': 'Nhẹ nhàng, có thể trò chuyện.',
  'scheduler.modal-event.expected-intensity.4': 'Trung bình, hơi khó thở',
  'scheduler.modal-event.expected-intensity.5': 'Trung bình, hơi khó thở',
  'scheduler.modal-event.expected-intensity.6': 'Trung bình, hơi khó thở',
  'scheduler.modal-event.expected-intensity.7': 'Cường độ cao, thở dốc',
  'scheduler.modal-event.expected-intensity.8': 'Cường độ cao, thở dốc',
  'scheduler.modal-event.expected-intensity.9': 'Cường độ cao, thở dốc',
  'scheduler.modal-event.expected-intensity.10': 'Cường độ rất cao, không thể trò chuyện',
  'scheduler.modal-event.participant.label': 'Đối tượng tham gia',
  'scheduler.modal-event.participant.count': 'Đã chọn {playersCount}',
  'scheduler.modal-event.training.textarea.label': 'Thông báo tập luyện',
  'scheduler.modal-event.training.textarea.placeholder':
    'Nhập chi tiết lịch trình hoặc thông báo bạn muốn chia sẻ với cầu thủ.\nNội dung sẽ được chia sẻ với mọi người.\nVí dụ) Chương trình tập luyện ngày hôm nay: Khởi động - 5:2 - Sút bóng - Chuyền 1:1',
  'scheduler.modal-event.textarea.placeholder': 'Nhập lịch trình chi tiết.',
  'scheduler.modal-event.caution.expected-intensity':
    'Cường độ mục tiêu, đối tượng tham gia và ghi chú chỉ có quản trị viên mới có thể xem.',
  'scheduler.modal-event.caution':
    'Đối tượng tham gia, ghi chú chỉ có quản trị viên mới có thể xem.',
  'scheduler.modal-event.more-items.duplicate': 'Sao chép lịch trình',
  'scheduler.modal-event.more-items.modify': 'Sửa lịch trình',
  'scheduler.modal-event.more-items.delete': 'Xóa lịch trình',
  'scheduler.modal-event.create.cancel.title': 'Bạn có chắc chắn muốn hủy tạo lịch trình không?',
  'scheduler.modal-event.duplicate.cancel.title':
    'Bạn có chắc chắn muốn hủy sao chép lịch trình không?',
  'scheduler.modal-event.modify.cancel.title': 'Bạn có chắc chắn muốn hủy sửa lịch trình không?',
  'scheduler.modal-event.delete.cancel.title': 'Bạn có chắc chắn muốn xóa lịch trình không?',
  'scheduler.overlay.participants': 'Đối tượng tham gia ({participants})',
  'scheduler.overlay.participants.more': '+ xem thêm...',
  'scheduler.overlay.notice-target': 'Đối tượng thông báo ({participants})',
  'scheduler.overlay.participants.total': 'Tổng số ({total})',
  'scheduler.overlay.participants.notification': 'Gửi thông báo tham gia',
  'scheduler.overlay.all': 'Tất cả',
  'scheduler.overlay.attend': 'Tham gia',
  'scheduler.overlay.absent': 'Vắng mặt',
  'scheduler.overlay.injured': 'Chấn thương',
  'scheduler.overlay.etc': 'Khác',
  'scheduler.overlay.unchecked': 'Chưa kiểm tra',
  'scheduler.button.training-report': 'Xem báo cáo tập luyện',
  'scheduler.modal-participant-notification.title':
    'Gửi thông báo xác nhận tham gia\ntập luyện cho {participants} người?',
  'scheduler.modal-event.memo.guide':
    'Các cầu thủ không thể xem được cường độ mục tiêu/đối tượng tham gia, nhưng thông báo tập luyện sẽ được chia sẻ với tất cả mọi người.',
  'scheduler.modal-event.match-opponent.field-input.label': 'Đối thủ',
  'scheduler.modal-event.match-opponent.field-input.placeholder':
    'Nhập tên nhóm hoặc cầu thủ nhóm đối phương',
  'scheduler.modal-event.match-competition.field-input.label': 'Giải đấu',
  'scheduler.modal-event.match-competition.field-input.placeholder': 'Nhập tên giải/giải đấu.',
  'scheduler.modal-event.match-location.field-input.label': 'Địa điểm',
  'scheduler.modal-event.match-location.field-input.placeholder': 'Nhập địa điểm thi đấu.',
  'scheduler.modal-event.match-event.field-input.label': 'Lịch trình',
  'scheduler.modal-event.match-venue.etc.button': 'Khác',
  'scheduler.modal-event.match-notice-target.field-input.label': 'Người nhận thông báo',
  'scheduler.modal-event.match-notice-target.field-input.guide':
    'Người nhận thông báo có thể được thêm vào danh sách thi đấu chính thức',
  'scheduler.modal-event.match-notice.field-input.label': 'Thông báo',
  'scheduler.modal-event.match-notice.textarea.label': 'Thông báo trận đấu',
  'scheduler.modal-event.match-notice.textarea.placeholder':
    'Nhập nội dung thông báo cần gửi cho các cầu thủ liên quan đến trận đấu. Nội dung sẽ được chia sẻ với tất cả cầu thủ.',
  'scheduler.modal-event.match-notice.textarea.guide':
    'Lưu ý: Ghi chú lịch trình của trận đấu lưu trữ trước ngày 10 tháng 10 năm 2023 sẽ không được công khai, vì vậy hãy yên tâm.',
  'scheduler.button.match-report': 'Xem báo cáo trận đấu',
  'scheduler.button.practice-match-report': 'Xem báo cáo trận giao hữu',
  'match-report.notice': 'Thông báo',
  'match-report.result': 'Kết quả trận đấu',
  'match-report.result-status.win': 'Thắng',
  'match-report.result-status.draw': 'Hòa',
  'match-report.result-status.lose': 'Thua',
  'match-report.result-textarea.placeholder':
    'Tự do ghi chú về điểm nổi bật của trận đấu, bình luận, chiến thuật chính,...',
  'match-report.lineup-and-record-card.title': 'Danh sách và kỷ lục của các cầu thủ thi đấu',
  'match-report.lineup-and-record-card-empty.title':
    'Hiện tại không có cầu thủ thi đấu nào được đăng ký',
  'match-report.lineup-and-record-card-empty.subtitle':
    'Vui lòng nhấn nút Đăng ký cầu thủ thi đấu để thêm vào danh sách.',
  'match-report.lineup-and-record-card.table-label.part': 'Thi đấu',
  'match-report.lineup-and-record-card.table-label.playing-time': 'Thời gian thi đấu',
  'match-report.lineup-and-record-card.table-label.record-and-comment':
    'Kỷ lục và đánh giá của cầu thủ',
  'match-report.lineup-and-record-card.modify-button.help':
    'Nhấn vào đây để chỉnh sửa danh sách cầu thủ thi đấu',
  'match-report.lineup-register-modal.title': 'Đăng ký cầu thủ thi đấu',
  'match-report.lineup-register-modal.search-input.placeholder': 'Tìm kiếm cầu thủ',
  'match-report.lineup-register-modal.guide':
    'Các cầu thủ bạn đã chọn làm đối tượng thông báo sẽ được hiển thị trong danh sách.',
  'match-report.lineup-register-modal.player-status.starting': 'Xuất phát',
  'match-report.lineup-register-modal.player-status.sub': 'Dự bị',
  'match-report.lineup-register-modal.player-status.no-play': 'Không tham gia',
  'match-report.lineup-register-modal.empty':
    'Không có cầu thủ nào được chọn để hiển thị.\nTrước tiên hãy chọn đối tượng thông báo trong chỉnh sửa lịch trình.',
  'match-report.lineup-register-modal.no-result': 'Không tìm thấy kết quả',
  'match-report.lineup-data-card.title': 'Dữ liệu cầu thủ thi đấu',
  'match-report.edit': 'Đang chỉnh sửa',
  'match-report.event-list.title': 'Đối tượng thông báo ({count})',
  'match-report.all.player-list.empty.title': 'Không có cầu thủ được chọn',
  'match-report.all.player-list.empty.subTitle':
    'Không có cầu thủ nào được chọn làm đối tượng thông báo',
  'training-report.location': 'Địa điểm',
  'training-report.date': 'Ngày',
  'training-report.time': 'Thời gian',
  'training-report.notice': 'Thông báo',
  'training-report.attendance.percent': 'Tỉ lệ tham gia',
  'training-report.player-list.help': 'Nhấn vào số và kiểm tra các danh sách',
  'training-report.attendance.workload.data.title': 'Dữ liệu tải tập luyện của cầu thủ',
  'training-report.attendance.workload.data.list': 'Danh sách',
  'training-report.attendance.workload.data.chart': 'Biểu đồ',
  'training-report.all': 'Toàn bộ',
  'training-report.attend': 'Tham gia',
  'training-report.injured': 'Chấn thương',
  'training-report.etc': 'Khác',
  'training-report.unchecked': 'Chưa kiểm tra',
  'training-report.all.player-list.empty.title': 'Không có cầu thủ được chọn',
  'training-report.all.player-list.empty.subTitle':
    'Không có cầu thủ nào được chọn làm đối tượng tham gia.',
  'training-report.attend.player-list.empty.title': 'Không có cầu thủ tham gia',
  'training-report.attend.player-list.empty.subTitle': "Chưa có cầu thủ nào kiểm tra 'Tham gia'",
  'training-report.injured.player-list.empty.title': 'Không có cầu thủ bị chấn thương (Vắng mặt).',
  'training-report.injured.player-list.empty.subTitle':
    'Không có cầu thủ nào vắng mặt do chấn thương 👍',
  'training-report.etc.player-list.empty.title': 'Không có cầu thủ vắng mặt nào khác',
  'training-report.etc.player-list.empty.subTitle':
    'Không có cầu thủ nào vắng mặt vì lý do cá nhân 👍',
  'training-report.unchecked.player-list.empty.title': 'Không có cầu thủ nào chưa kiểm tra',
  'training-report.unchecked.player-list.empty.subTitle':
    'Tất cả các cầu thủ đã kiểm tra tham gia/vắng mặt 👍',
  'training-report.attendance.workload.data.empty':
    'Hiện tại chưa có cầu thủ nào kiểm tra tham gia…',
  'banner-subscribe.title.first': 'Đăng ký gói',
  'banner-subscribe.title.second': 'tiếp tục theo dõi',
  'banner-subscribe.action': 'Đăng ký gói',
  'card-teams-invite.title': 'Mời vào nhóm',
  'card-teams-invite.content': 'Mời thành viên nhóm.',
  'card-teams-invite.instruction':
    'Có thể mời thành viên bất kỳ lúc nào từ {place} ở góc trên bên trái.',
  'card-teams-invite.instruction.place': "Menu nhóm > 'Mời vào nhóm'",
  'info-tooltip.player-detail': 'Bây giờ có thể xem dữ liệu cá nhân của cầu thủ!',
  'info-tooltip.transfer-ownership': 'Bạn đã trở thành chủ sở hữu của nhóm!',
  'field-input.label.name': 'Tên',
  'field-input.label.nationality': 'Quốc tịch',
  'field-input.label.gender': 'Giới tính',
  'field-input.label.birthdate': 'Ngày sinh',
  'field-input.label.phone-number': 'Số điện thoại',
  'field-input.label.role': 'Vai trò',
  'field-input.label.position': 'Chức vụ',
  'field-input.label.sportsCategory': 'Môn thể thao',
  'field-input.name.placeholder': 'Nhập tên',
  'field-input.birthdate.placeholder': 'Nhập ngày sinh (ví dụ: 1994-01-05)',
  'field-input.phone-number.placeholder': 'Nhập số điện thoại (ví dụ: 010-1234-5678)',
  'field-input.username.placeholder': 'Nhập ID',
  'chart.data.not.checked': 'Chưa kiểm tra',
  'chart.data.none': 'Không có',
  'report.exception.mobile': 'Vui lòng xem báo cáo\ntrên PC hoặc máy tính bảng',
  'report.exception.authority.title': 'Bạn không thể xem báo cáo này',
  'report.exception.authority.subtext':
    'Lịch thi đấu có chính xác chưa?\nHãy kiểm tra xem đây có phải là lịch thi đấu của đội khác hoặc lịch thi đấu đã bị xóa hay không.',
  'match-report.modal.header': 'Báo cáo trận đấu',
  'practice-match-report.modal.header': 'Báo cáo trận giao hữu',
  'training-report.modal.header': 'Báo cáo huấn luyện',
  'training-report.workload-intensity-compare.title': 'So sánh cường độ tập luyện',
  'training-report.workload-intensity-checked':
    'Trong {totalCount} cầu thủ tham gia có {checkedCount} người đã hoàn thành kiểm tra tải tập luyện',
  'training-report.aim-intesity-ballon': 'Mục tiêu',
  'training-report.aim-intensity.title': 'Mục tiêu',
  'training-report.real-intensity.title': 'Thực tế',
  'training-report.real-intensity.label': 'Trung bình đội*',
  'training-report.real-intensity.empty': 'Đang chờ kiểm tra tải tập luyện',
  'training-report.training-point.title': 'Điểm huấn luyện',
  'training-report.training-point.placeholder':
    'Ghi lại mục tiêu và mục đích và mục tiêu của buổi tập, nội dung và kết quả tập luyện thực tế,…\n\nVí dụ) Tiến hành giảm cường độ tập luyện trước trận đấu',
  'training-report.special-notes.title': 'Hạng mục đặc biệt',
  'training-report.special-notes.placeholder':
    'Ghi lại bất kỳ hạng mục đặc biệt nào xảy ra trong buổi tập và nhận xét,...\n\nVí dụ) Cầu thủ có vẻ mệt mỏi sau buổi tập cường độ cao hôm trước',
  'report.export.help': 'Có thể lưu báo cáo dưới dạng PDF/hình ảnh',
  'report.export-modal.title': 'Xuất báo cáo',
  'report.export-modal.save-pdf.button': 'PDF',
  'report.export-modal.save-img.button': 'Hình ảnh',
  'report.export-modal.save.loading.text': 'Đang lưu',
  'report.export-modal.save.success.text': 'Hoàn tất lưu',
  'report.export-modal.save.failed.text': 'Lưu thất bại',
  'player.modal-injury.register.header': 'Đăng ký chấn thương',
  'player.modal-injury.edit.header': 'Chỉnh sửa chấn thương',
  'player.modal-injury.detail.header': 'Xem chi tiết chấn thương',
  'player.modal-injury.injury-dummy.front': 'Trước',
  'player.modal-injury.injury-dummy.back': 'Sau',
  'player.modal-injury.selection.part.text': 'Chọn vị trí chấn thương',
  'player.modal-injury.selection.part.none.subtext': 'Không có vị trí được chọn',
  'player.modal-injury.selection.part.guide': 'Nhấn vào hình mô phỏng để chọn vùng chấn thương',
  'player.modal-injury.detail-selection.header': 'Nhập thông tin chi tiết',
  'player.modal-injury.detail-selection.date.title': 'Ngày chấn thương',
  'player.modal-injury.detail-selection.relapse.text': 'Tái phát',
  'player.modal-injury.detail-selection.cause.title': 'Nguyên nhân và triệu chứng',
  'player.modal-injury.detail-selection.cause.placeholder':
    'Nhập thông tin về bối cảnh, tình huống và triệu chứng khi xảy ra chấn thương.',
  'player.modal-injury.detail-selection.diagnosis.title': 'Tên chẩn đoán',
  'player.modal-injury.detail-selection.diagnosis.placeholder': 'Nhập tên chẩn đoán.',
  'player.modal-injury.detail-selection.medical-record.title': 'Lịch sử điều trị',
  'player.modal-injury.detail-selection.medical-record.placeholder':
    'Nhập ngày điều trị, bệnh viện, phương pháp chẩn đoán,…',
  'player.modal-injury.detail-selection.return-date.title': 'Ngày trở lại',
  'player.modal-injury.detail-selection.return-date.undecide': 'Chưa xác định',
  'player.modal-injury.detail-selection.return-info.title': 'Thông tin phục hồi',
  'player.modal-injury.detail-selection.return-info.placeholder':
    'Thời gian điều trị, thời gian dự kiến',
  'player.modal-injury.detail-selection.injury-ended.title': 'Kết thúc chấn thương',
  'player.modal-injury.detail-selection.injury-ended.subtitle':
    'Lưu chấn thương này vào lịch sử chấn thương',
  'player.modal-injury.detail-selection.file.button': 'Đính kèm tệp',
  'player.modal-injury.detail-selection.file.text':
    'Bạn có thể đính kèm ảnh hoặc tài liệu liên quan\n*Tối đa 3 tệp, mỗi tệp không quá 10MB',
  'player.modal-injury.register-cancel.title': 'Chắc chắn muốn hủy đăng ký chấn thương?',
  'player.modal-injury.update-cancel.title': 'Chắc chắn muốn hủy chỉnh sửa chấn thương?',
  'player.modal-injury.delete.title': 'Chắc chắn muốn xóa chấn thương?',
  'menu.chat-support': 'Trò chuyện',
  'menu.lanuage': 'Thay đổi ngôn ngữ',
  'menu.notification': 'Thông báo',
  'menu.my-profile': 'Hồ sơ của tôi',
  'fab.send-feedback': 'Đăng ký phản hồi',
  'fab.select-feedback-player': 'Chọn cầu thủ để phản hồi',
  'fab.search-players': 'Tìm kiếm cầu thủ',
  'fab.select': 'Chọn',
  'fab.no-results-found': 'Không có kết quả tìm kiếm.',
  'fab.cancel': 'Hủy',
  'fab.next': 'Tiếp',
  'notification.updates': 'Cập nhật',
  'notification.notable-players': 'Cầu thủ chính',
  'notification.make-all-as-read': 'Đánh dấu tất cả là đã đọc',
  'notification.COMMENT_CREATED': '{user} đã bình luận vào phản hồi {title} của {player}',
  'notification.COMMENT_CREATED_NO_FEEDBACK': '{user} đã bình luận vào {title} của {player}',
  'notification.FEEDBACK_CREATED': '{author} đã gửi phản hồi {title} cho {player}',
  'notification.FEEDBACK_CREATED_NO_FEEDBACK': '{author} đã gửi {title} đến {player}',
  'notification.FEEDBACK_REQUESTED': '{player} đã nhận được yêu cầu phản hồi mới',
  'notification.feedback-deleted': 'Phản hồi này đã bị xóa',
  'notification.feedback-request-deleted': 'Yêu cầu phản hồi này đã bị xóa',
  'notification.comment-deleted': 'Bình luận này đã bị xóa',
  'notification.no-update-notifications': 'Hiện tại không có thông báo cập nhật',
  'notification.notify-request-or-comments':
    'Sẽ có thông báo đến khi có nội dung mới cần kiểm tra, như yêu cầu phản hồi hoặc bình luận mới,…',
  'notification.general': 'Chung',
  'notification.format.a-week-ago': 'Một tuần trước',
  'notification.format.today': 'HH:mm',
  feedback: 'Phản hồi',
  'feedback.no-feedback-yet': 'Không có phản hồi nào',
  'feedback.send-feedback': 'Đăng ký phản hồi',
  'feedback.choose-feedback': 'Bạn muốn gửi phản hồi gì\nđến {player}?',
  'feedback.suggestion-description':
    'Viết tự do hoặc chọn từ gợi ý của PLCO.\nGợi ý luôn được cập nhật.',
  'feedback.general-feedback': 'Phản hồi chung',
  'feedback.emoji-general-feedback': '🙂 Phản hồi chung',
  'feedback.write-freely': 'Viết tự do',
  'feedback.plco-suggestions': '✨Gợi ý của PLCO',
  'feedback.tag.NORMAL': 'Phản hồi chung',
  'feedback.tag.REQUEST': 'Yêu cầu phản hồi',
  'feedback.tag.TRAINING': 'Ghi chú huấn luyện',
  'feedback.tag.MATCH': 'Ghi chú trận đấu',
  'feedback.tag.PRACTICE': 'Ghi chú trận giao hữu',
  'feedback.tag.WORKLOAD_MEMO': 'Ghi chú tập luyện',
  'feedback.tag.WORKLOAD_RISK_AT_INJURY': 'Tải tập luyện',
  'feedback.tag.CONDITION_NOT_GOOD': 'Thể trạng',
  'feedback.tag.CONDITION_PLUMMET': 'Thể trạng',
  'feedback.tag.SORENESS_SEVERE': 'Cơn đau',
  'feedback.type.normal-workout': 'Tập luyện chung',
  'feedback.type.RISK_INCREASED': 'Tăng nguy cơ chấn thương',
  'feedback.type.RISK_CAUTION': 'Cảnh báo nguy cơ chấn thương',
  'feedback.type.RISK_DETECTED': 'Phát hiện nguy cơ chấn thương',
  'feedback.type.severe-soreness': 'Cơn đau nghiêm trọng',
  'feedback.type.bad-condition': 'Thể trạng không tốt',
  'feedback.type.plummeted-condition': 'Thể trạng sụt giảm',
  'feedback.back': 'Trước',
  'feedback.next': 'Sau',
  'feedback.save': 'Lưu',
  'feedback.no-specific-suggestions': 'Hiện tại không có đề xuất nào',
  'feedback.enter-your-feedback': 'Hãy nhập phản hồi',
  'feedback.data-for-date': 'D/M/yyyy',
  'feedback.injury.reason.HIGH_ACWR': 'Mức độ mệt mỏi cao so với thể lực',
  'feedback.injury.reason.LOW_ACWR': 'Lượng tập luyện ít so với thể lực',
  'feedback.injury.reason.WEEKLY_WORKLOAD_SURGE':
    'Tải tập luyện trong tuần tăng đột biến so với tuần trước',
  'feedback.injury.reason.STRAIN': 'Áp lực tập luyện về thể chất và tinh thần',
  'feedback.injury.reason.HIGH_WEEKLY_WORKLOAD': 'Tải tập luyện trong tuần cao',
  'feedback.injury.reason.MONOTONY': 'Tập luyện đơn giản',
  'feedback.injury.reason.NON_ELASTIC_INTENSITY': 'Cường độ tập luyện không linh hoạt',
  'feedback.player-status-description':
    '{player} đang ở trạng thái {status} do {count} nguyên nhân, ngoại trừ {cause}',
  'feedback.plco-suggestion': 'Gợi ý của PLCO',
  'feedback.condition-type.FATIGUE': 'Mức độ mệt mỏi',
  'feedback.condition-type.STRESS': 'Căng thẳng',
  'feedback.condition-type.MUSCLE': 'Cơ bắp',
  'feedback.condition-type.MOOD': 'Tâm trạng',
  'feedback.condition-type.SLEEP_DURATION': 'Thời gian ngủ',
  'feedback.condition-type.SLEEP_QUALITY': 'Chất lượng giấc ngủ',
  'feedback.bad-condition-description':
    'Các hạng mục {conditionList} của {player} không tốt đều dưới 4 điểm.',
  'feedback.decline-condition':
    'Các hạng mục {conditionList} của {player} đã giảm mạnh so với 28 ngày gần đây.',
  'feedback.soreness-bodypart':
    '{player} xuất hiện triệu chứng đau nhức không {type, select, CONTACT {tiếp xúc} NON_CONTACT {không tiếp xúc} other {}} mức độ {level} ở {body_part}',
  'feedback.level': 'Mức độ {level}',
  'feedback.sure-cancel-sending-feedback': 'Chắc chắn hủy đăng ký phản hồi?',
  'feedback.no': 'Không',
  'feedback.yes': 'Có',
  'feedback.view-feedback-details': 'Xem chi tiết phản hồi',
  'feedback.comments': 'Bình luận',
  'feedback.add-comment-this-feedback': 'Bình luận về phản hồi này',
  'feedback.submit': 'Đăng ký',
  'feedback.editing': 'Đang sửa',
  'feedback.sure-delete-feedback': 'Chắc chắn xóa phản hồi này?',
  'feedback.player-no-longer-view': 'Cầu thủ cũng sẽ không thể xem phản hồi này nữa.',
  'feedback.want-delete-comment': 'Chắc chắn xóa bình luận này?',
  'feedback.edited': '(Đã chỉnh sửa)',
  'feedback.authors-feedback': 'Phản hồi của {author}',
  'feedback.no-comments-yet': 'Hiện tại chưa có bình luận nào.',
  'feedback.request-from-player': 'Yêu cầu được gửi từ {player}',
  'feedback.feedback-request': 'Yêu cầu phản hồi',
  'feedback.feedback-has-deleted': 'Đã xóa phản hổi',
  'feedback.feedback-has-edited': 'Đã chỉnh sửa phản hồi',
  'feedback.org-license-description':
    'Người quản lý giấy phép ORG không thể sử dụng chức năng phản hồi',
  'feedback.withdrew-user': 'Thành viên đã rời khỏi',
  'workout-duration.edit': 'Chỉnh sửa thời gian tập luyện',
  'workout-duration.helper-text':
    'Dữ liệu thời gian tập luyện được chỉnh sửa bởi huấn luyện viên sẽ được hiển thị bằng màu xanh lam.',
  'workout-duration.edit-description':
    'Có thể chỉnh sửa trong vòng 7 ngày và thông báo sẽ được gửi cầu thủ.\nVì dữ liệu gốc sẽ được chỉnh sửa nên hãy đặc biệt lưu ý.',
  'workout-duration.select-players': 'Chọn cầu thủ*',
  'workout-duration.starts-ends': 'Bắt đầu~Kết thúc tập luyện*',
  'workout-duration.time-format-hours-min': '⏱️ HH:mm ~ HH:mm ({hours}giờ {min}phút)',
  'workout-duration.time-format-hours': '⏱️ HH:mm ~ HH:mm ({hours}giờ)',
  'workout-duration.time-format-min': '⏱️ HH:mm ~ HH:mm ({min}phút)',
  'workout-duration.invalid-duration': '⏱️ Vui lòng nhập thời gian chính xác',
  'workout-duration.designated-time': 'Thời gian chỉ định',
  'workout-duration.cancel': 'Hủy',
  'workout-duration.save': 'Lưu',
  'workout-duration.select': 'Chọn',
  'workout-duration.select-all': 'Chọn tất cả ({count})',
  'workout-duration.hh': 'HH',
  'workout-duration.mm': 'mm',
  'workout-duration.edited': 'Đã chỉnh sửa thời gian tập luyện',
  GTE: 'Trở lên',
  GT: 'Vượt quá',
  LTE: 'Trở xuống',
  LT: 'Dưới',
  AVG: 'Trung bình',
  TIMES: 'Lần',
  STEP: 'Mức độ',
  SORENESS: 'Cơn đau',
  ACWR_AVG: 'Nguy cơ chấn thương (ACWR)',
  MONOTONY: 'Sự đơn điệu (monotony)',
  'insight.widget.footer.reminder-button': 'Thông báo kiểm tra',
  'insight.widget.empty-data': 'Hiện tại không có cầu thủ nào cần báo cáo.',
  'insight.widget.title.summary': 'Tóm tắt',
  'insight.widget.title.condition': 'Thể trạng',
  'insight.widget.title.workload': 'Tải tập luyện',
  'insight.widget.title.injury': 'Cơn đau',
  'insight.widget.item.title.CONDITION_CHECKED_NOT': 'Cầu thủ chưa kiểm tra thể trạng ({count})',
  'insight.widget.item.subtitle.CONDITION_CHECKED_NOT': 'Tiêu chuẩn ・ Chưa kiểm tra thể trạng',
  'insight.widget.item.title.RANK_ACWR_HIGH': '😱 Cầu thủ có nguy cơ chấn thương cao ({count})',
  'insight.widget.item.subtitle.RANK_ACWR_HIGH':
    'Tiêu chuẩn ・ Nguy cơ chấn thương (ACWR) 1.5 trở lên',
  'insight.widget.item.title.RANK_CONDITION_BAD': '🤔 Cầu thủ có thể trạng không tốt ({count})',
  'insight.widget.item.subtitle.RANK_CONDITION_BAD': 'Tiêu chuẩn ・ Thể trạng 3 điểm trở xuống',
  'insight.widget.item.title.RANK_CONDITION_BAD_7DAY':
    '🤔 Cầu thủ có thể trạng không tốt ({count})',
  'insight.widget.item.subtitle.RANK_CONDITION_BAD_7DAY':
    'Tiêu chuẩn ・ Nhiều lần 3 điểm trở xuống trong tuần về điểm thể trạng',
  'insight.widget.item.title.RANK_CONDITION_BAD_28DAY':
    '🤔 Cầu thủ có thể trạng không tốt ({count})',
  'insight.widget.item.subtitle.RANK_CONDITION_BAD_28DAY':
    'Tiêu chuẩn ・ Nhiều lần 3 điểm trở xuống trong tháng về điểm thể trạng',
  'insight.widget.item.title.RANK_MUSCLE_BAD':
    '💪 Cầu thủ có tình trạng cơ bắp không tốt ({count})',
  'insight.widget.item.subtitle.RANK_MUSCLE_BAD': 'Tiêu chuẩn ・Cơ bắp 4 điểm trở xuống',
  'insight.widget.item.title.RANK_MUSCLE_BAD_7DAY':
    '💪 Cầu thủ có tình trạng cơ bắp không tốt ({count})',
  'insight.widget.item.subtitle.RANK_MUSCLE_BAD_7DAY':
    'Tiêu chuẩn ・ Nhiều lần 4 điểm trở xuống trong tuần về điểm cơ bắp',
  'insight.widget.item.title.RANK_MUSCLE_BAD_28DAY':
    '💪 Cầu thủ có tình trạng cơ bắp không tốt ({count})',
  'insight.widget.item.subtitle.RANK_MUSCLE_BAD_28DAY':
    'Tiêu chuẩn ・ Nhiều lần 4 điểm trở xuống trong tháng về điểm cơ bắp',
  'insight.widget.item.title.RANK_STRESS_HIGH': '🤯 Cầu thủ có mức độ căng thẳng cao ({count})',
  'insight.widget.item.subtitle.RANK_STRESS_HIGH': 'Tiêu chuẩn ・ Căng thẳng 4 điểm trở xuống',
  'insight.widget.item.title.RANK_STRESS_HIGH_7DAY':
    '🤯 Cầu thủ có mức độ căng thẳng cao ({count})',
  'insight.widget.item.subtitle.RANK_STRESS_HIGH_7DAY':
    'Tiêu chuẩn ・ Nhiều lần 4 điểm trở xuống trong tuần về mức độ căng thẳng',
  'insight.widget.item.title.RANK_STRESS_HIGH_28DAY':
    '🤯 Cầu thủ có mức độ căng thẳng cao ({count})',
  'insight.widget.item.subtitle.RANK_STRESS_HIGH_28DAY':
    'Tiêu chuẩn ・ Nhiều lần 4 điểm trở xuống trong tháng về mức độ căng thẳng',
  'insight.widget.item.title.RANK_MOOD_BAD': '😩 Cầu thủ có tâm trạng không tốt ({count})',
  'insight.widget.item.subtitle.RANK_MOOD_BAD': 'Tiêu chuẩn ・ Tâm trạng 4 điểm trở xuống',
  'insight.widget.item.title.RANK_MOOD_BAD_7DAY': '😩 Cầu thủ có tâm trạng không tốt ({count})',
  'insight.widget.item.subtitle.RANK_MOOD_BAD_7DAY':
    'Tiêu chuẩn ・ Nhiều lần 4 điểm trở xuống trong tuần về điểm tâm trạng',
  'insight.widget.item.title.RANK_MOOD_BAD_28DAY': '😩 Cầu thủ có tâm trạng không tốt ({count})',
  'insight.widget.item.subtitle.RANK_MOOD_BAD_28DAY':
    'Tiêu chuẩn ・ Nhiều lần 4 điểm trở xuống trong tháng về điểm tâm trạng',
  'insight.widget.item.title.RANK_FATIGUE_HIGH': '😵 Cầu thủ có mức độ mệt mỏi cao ({count})',
  'insight.widget.item.subtitle.RANK_FATIGUE_HIGH': 'Tiêu chuẩn ・ Mức độ mệt mỏi 4 điểm trở xuống',
  'insight.widget.item.title.RANK_FATIGUE_HIGH_7DAY': '😵 Cầu thủ có mức độ mệt mỏi cao ({count})',
  'insight.widget.item.subtitle.RANK_FATIGUE_HIGH_7DAY':
    'Tiêu chuẩn ・ Nhiều lần 4 điểm trở xuống trong tuần về mức độ mệt mỏi',
  'insight.widget.item.title.RANK_FATIGUE_HIGH_28DAY': '😵 Cầu thủ có mức độ mệt mỏi cao ({count})',
  'insight.widget.item.subtitle.RANK_FATIGUE_HIGH_28DAY':
    'Tiêu chuẩn ・ Nhiều lần 4 điểm trở xuống trong tháng về mức độ mệt mỏi',
  'insight.widget.item.title.RANK_SLEEP_DURATION_BAD': '😴 Cầu thủ có thời gian ngủ ít ({count})',
  'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD':
    'Tiêu chuẩn ・ Thời gian ngủ dưới 360 phút (6 tiếng)',
  'insight.widget.item.title.RANK_SLEEP_DURATION_BAD_7DAY':
    '😴 Cầu thủ có thời gian ngủ ít ({count})',
  'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD_7DAY':
    'Tiêu chuẩn ・ Nhiều lần dưới 360 phút (6 tiếng) ngủ trong tuần',
  'insight.widget.item.title.RANK_SLEEP_DURATION_BAD_28DAY':
    '😴 Cầu thủ có thời gian ngủ ít ({count})',
  'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD_28DAY':
    'Tiêu chuẩn ・ Nhiều lần dưới 360 phút (6 tiếng) ngủ trong tháng',
  'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD': '😴 Cầu thủ có thời gian ngủ ít ({count})',
  'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD':
    'Tiêu chuẩn ・ Chất lượng giấc ngủ dưới 4 điểm',
  'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD_7DAY':
    '🥱 Cầu thủ có chất lượng giấc ngủ thấp ({count})',
  'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD_7DAY':
    'Tiêu chuẩn ・ Chất lượng giấc ngủ trong tuần dưới 4 điểm',
  'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD_28DAY':
    '🥱 Cầu thủ có chất lượng giấc ngủ thấp ({count})',
  'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD_28DAY':
    'Tiêu chuẩn ・ Chất lượng giấc ngủ trong tháng dưới 4 điểm',
  'insight.widget.item.title.RANK_WORKLOAD_HIGH': '🏋️ Cầu thủ có tải tập luyện cao ({count})',
  'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH':
    'Tiêu chuẩn ・ Tổng tải tập luyện vượt quá 800',
  'insight.widget.item.title.RANK_WORKLOAD_HIGH_7DAY': '🏋️ Cầu thủ có tải tập luyện cao ({count})',
  'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH_7DAY':
    'Tiêu chuẩn ・ Tổng tải tập luyện trong tuần vượt quá 4000',
  'insight.widget.item.title.RANK_WORKLOAD_HIGH_28DAY': '🏋️ Cầu thủ có tải tập luyện cao ({count})',
  'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH_28DAY':
    'Tiêu chuẩn ・ Tổng tải tập luyện trong tháng vượt quá 4000',
  'insight.widget.item.title.RANK_WORKLOAD_INCREASED_RATE_7DAY':
    '💥 Cầu thủ có tỷ lệ tăng tải tập luyện cao ({count})',
  'insight.widget.item.subtitle.RANK_WORKLOAD_INCREASED_RATE_7DAY':
    'Tiêu chuẩn ・ Tải tập luyện tăng hơn 15% so với tuần trước',
  'insight.widget.item.title.RANK_WORKLOAD_AXD_7DAY':
    '😑 Cầu thủ có tải tập luyện đơn điệu ({count})',
  'insight.widget.item.subtitle.RANK_WORKLOAD_AXD_7DAY':
    'Tiêu chuẩn ・ Sự đơn điệu(monotony) quá 2',
  'insight.widget.item.title.RANK_SORENESS_INJURED': '🤕 Cầu thủ cơn đau mức độ 5 ({count})',
  'insight.widget.item.subtitle.RANK_SORENESS_INJURED':
    'Tiêu chuẩn ・ Có từ 1 cơn đau trở lên ở mức độ 5',
  'insight.widget.item.title.RANK_SORENESS_INJURED_7DAY': '🤕 Cầu thủ cơn đau mức độ 5 ({count})',
  'insight.widget.item.subtitle.RANK_SORENESS_INJURED_7DAY':
    'Tiêu chuẩn ・ Có từ 1 cơn đau trở lên ở mức độ 5',
  'insight.widget.item.title.RANK_SORENESS_INJURED_28DAY': '🤕 Cầu thủ cơn đau mức độ 5 ({count})',
  'insight.widget.item.subtitle.RANK_SORENESS_INJURED_28DAY':
    'Tiêu chuẩn ・ Có từ 1 cơn đau lên ở mức độ 5',
  'insight.widget.item.title.RANK_SORENESS_SEVERE': '😣 Cầu thủ cơn đau mức độ 3, 4 ({count})',
  'insight.widget.item.subtitle.RANK_SORENESS_SEVERE':
    'Tiêu chuẩn ・ Có từ 1 cơn đau trở lên ở mức độ 3~4',
  'insight.widget.item.title.RANK_SORENESS_SEVERE_7DAY': '😣 Cầu thủ cơn đau mức độ 3, 4 ({count})',
  'insight.widget.item.subtitle.RANK_SORENESS_SEVERE_7DAY':
    'Tiêu chuẩn ・ Có từ 1 cơn đau trở lên ở mức độ 3~4',
  'insight.widget.item.title.RANK_SORENESS_SEVERE_28DAY':
    '😣 Cầu thủ cơn đau mức độ 3, 4 ({count})',
  'insight.widget.item.subtitle.RANK_SORENESS_SEVERE_28DAY':
    'Tiêu chuẩn ・ Có từ 1 cơn đau trở lên ở mức độ 3~4',
  'insight.header.DAY': 'Hôm nay',
  'insight.header.RECENT_7DAY': '7 ngày gần nhất',
  'insight.header.RECENT_28DAY': '28 ngày gần nhất',
  'notable.card.condition-text': 'Thể trạng của bạn không tốt.',
  'notable.card.workload_acxr-text':
    'Nguy cơ chấn thương của bạn thấp hơn mức tối ưu. Cần chú ý chấn thương.',
  'notable.card.soreness-text': 'Cơn đau của bạn ở mức độ 3~4 là mức độ đáng báo động.',
  'notable.card.soreness-max-text': 'Cơn đau của bạn ở mức độ 5 là mức độ nguy hiểm.',
  'notable.card.button.notify': 'Xác nhận và gửi thông báo',
  'notable.card.button.view-player': 'Xem dữ liệu của cầu thủ',
  'notable.dropdown.title': 'Thông báo cầu thủ chính',
  'notable.dropdown.help-content.title': 'Gửi thông báo đến các cầu thủ',
  'notable.dropdown.help-content.close-title': 'Không hiển thị lại',
  'notable.dropdown.help-content.list-1':
    "Giám sát sẽ cho biết về những cầu thủ chính cần theo dõi. Nhấn nút [Xác nhận] và gửi thông báo 'Đã xác nhận ghi chép này' đến các cầu thủ.",
  'notable.dropdown.help-content.list-2':
    'Có thể gửi thông báo mới mỗi ngày, theo tiêu chuẩn 1 ngày.',
  'notable.dropdown.button.notification-all': 'Xác nhận tất cả',
  'notable.dropdown.caption.notification-all':
    'Nhấn nút xác nhận tất cả và gửi thông báo tới các cầu thủ có trong danh sách.',
  'notable.dropdown.body.empty.title': 'Không có hạng mục đặc biệt',
  'notable.dropdown.body.empty.subtitle':
    'Không có cầu thủ chính nào cần được theo dõi👍\nNếu có hạng mục cần phải xác minh thì sẽ thông báo sau.',
  'plan.step': 'Mức độ {step}/{total}',
  'plan.team.amount': '{amount}won / tháng',
  'plan.contact': 'Giải đáp thắc mắc',
  'plan.make-payment': 'Thanh toán',
  'plan.plan-info': 'Thông tin đăng ký',
  'plan.plan-info.discount-count.first': 'Trận 1',
  'plan.plan-info.discount-count': 'Trận đấu 1~{limitCount}',
  'plan.plan-info.original-price-count': 'Từ trận {limitCount} ~',
  'plan.plan': 'Đăng ký',
  'plan.current.plan': 'Gói đăng ký hiện tại',
  'plan.new.plan': 'Gói đăng ký mới',
  'plan.team': 'Đội',
  'plan.payment.method': 'Cách thức thanh toán',
  'plan.register-card': 'Đăng ký thẻ',
  'plan.change-card.info.lgAndUp':
    '· Khi đăng ký thẻ thanh toán, hệ thống sẽ trừ 100won để kiểm tra, sau 10 phút sẽ được hoàn lại vào thẻ.',
  'plan.change-card.info.mdOnly':
    '· Khi đăng ký thẻ thanh toán, hệ thống sẽ trừ 100won để kiểm tra, sau 10 phút sẽ được hoàn lại vào thẻ.',
  'plan.change-card.info.smOnly':
    '· Khi đăng ký thẻ thanh toán, hệ thống sẽ trừ 100won để kiểm tra, sau 10 phút sẽ được hoàn lại vào thẻ.',
  'plan.change-card.error.info': 'Vui lòng đăng ký cách thức thanh toán.',
  'plan.change.card': 'Thay đổi thẻ',
  'plan.players-number': 'Số lượng cầu thủ',
  'plan.payment-date': 'Ngày thanh toán định kỳ',
  'plan.next.payment-date': 'Ngày thanh toán tiếp theo',
  'hyper.next.payment.date': 'Ngày thanh toán định kỳ tiếp theo',
  'plan.invoice.details': 'Thông tin hóa đơn',
  'plan.buyer.name': 'Tên đơn vị',
  'plan.buyer.email': 'Email',
  'plan.buyer.phone': 'Số điện thoại',
  'plan.discount.details': 'Thông tin giảm giá',
  'plan.discount.coupon.null': 'Không có mã giảm giá hiệu lực.',
  'plan.discount.coupon.none': 'Không dùng mã giảm giá',
  'plan.payment.amount': 'Số tiền thanh toán',
  'plan.current.payment.amount': 'Số tiền dự tính hiện tại',
  'plan.new.payment.amount': 'Số tiền dự tính mới',
  'plan.subtotal': 'Số tiền dự tính',
  'plan.discount': 'Số tiền giảm',
  'plan.invoice.id': 'Mã số thanh toán',
  'plan.payment.date': 'Ngày thanh toán',
  'plan.total': 'Số tiền thanh toán',
  'plan.total.amount': 'Tổng tiền',
  'plan.regular.payment.amount': 'Số tiền thanh toán định kỳ',
  'plan.hyper.regular.payment.date': 'Ngày thanh toán định kỳ',
  'plan.status': 'Trạng thái',
  'plan.view': 'Xem',
  'plan.download': 'Tải xuống',
  'plan.players': '{players}người',
  'plan.teams': '{teams}nhóm',
  'plan.amount.unit': '{amount}won',
  'plan.remaining.days': 'Số ngày còn lại',
  'plan.expected.refund.amount': 'Số tiền hoàn trả dự kiến',
  'plan.day': '{day}ngày',
  'plan.coupon': 'Mã giảm',
  'plan.hyper': 'PLCO Hyper',
  'plan.card-error':
    'Thanh toán thất bại vì lý do thẻ thanh toán bị lỗi. Vui lòng thay đổi thẻ thanh toán và thử lại.',
  'plan.server-error': 'Thanh toán thất bại vì lý do lỗi serve. Vui lòng thử lại.',
  'plan.incomplete-error': 'Đăng ký thẻ chưa thành công. Vui lòng thử lại.',
  'plan.team.icon': '🏃',
  'plan.pro.icon': '🏆',
  'plan.title': 'Vui lòng chọn gói đăng ký',
  'plan.subtitle.lgAndUp':
    'Thời gian dùng thử miễn phí đã kết thúc!\nVui lòng chọn gói đăng ký mong muốn và tiếp tục sử dụng dịch vụ.',
  'plan.subtitle.mdAndDown':
    'Thời gian dùng thử miễn phí đã kết thúc!\nVui lòng chọn gói đăng ký mong muốn và tiếp tục sử dụng dịch vụ.',
  'plan.pricing.title.smOnly': 'Vui lòng xác nhận gói đăng ký và số tiền cần thanh toán',
  'plan.pricing.title.mdAndUp': 'Vui lòng xác nhận gói đăng ký và số tiền cần thanh toán',
  'plan.pricing.subtitle.smOnly': 'Sau khi kiểm tra thông tin sẽ tiến hành thanh toán.',
  'plan.pricing.subtitle.mdAndUp': 'Sau khi kiểm tra thông tin sẽ tiến hành thanh toán.',
  'plan.pricing.payment-day': 'Hàng tháng {day}',
  'hyper.pricing.payment-day': 'Hàng {day}ngày',
  'plan.pricing.total': 'Tổng số tiền',
  'plan.pricing.amount.info.smOnly':
    'Không thể hủy đăng ký sau khi bắt đầu sử dụng dịch vụ trả phí.',
  'plan.pricing.amount.info.mdAndUp':
    'Không thể hủy đăng ký sau khi bắt đầu sử dụng dịch vụ trả phí.',
  'plan.pricing.terms': 'Lưu thông tin thanh toán và đồng ý thanh toán định kỳ hàng tháng.',
  'plan.pricing.current-plan.info': 'Không thể chọn gói đăng ký giống với gói hiện tại.',
  'plan.pricing.plan-info.notice':
    'Từ {day} thanh toán định kỳ sẽ được thực hiện với số tiền đã được thay đổi.',
  'plan.change-plan.notice': 'Lưu ý về thay đổi gói đăng ký',
  'plan.change-plan.notice.content':
    '· Ngay cả sau khi đổi thẻ, số tiền hoàn lại vẫn sẽ được xử lý vào thẻ thanh toán của gói trước khi thay đổi.\n    · Cách tính số tiền hủy một phần: {(Số tiền thanh toán hàng tháng trước khi thay đổi) / 30 ngày } X Số ngày còn lại\n    · Cách tính số tiền thanh toán : {(Số tiền thanh toán hàng tháng sau khi thay đổi) / 30 ngày } X Số ngày còn lại\n    · Số ngày còn lại: Ngày thay đổi ~ Ngày thanh toán định kỳ tiếp theo\n    · Quá trình xử lý hoàn tiền có thể mất ít nhất từ 2 đến tối đa 5 ngày làm việc tùy thuộc vào công ty phát hành thẻ.\n    · Nếu thay đổi gói trong vòng 24 giờ kể từ khi thanh toán, toàn bộ số tiền của gói hiện tại sẽ được hoàn trả và sau đó gói mới sẽ được thanh toán.',
  'plan.change-plan.amount.notice':
    'Nếu thay đổi gói trong vòng 24 giờ kể từ khi thanh toán, toàn bộ số tiền của gói hiện tại sẽ được hoàn trả và sau đó gói mới sẽ được thanh toán.',
  'plan.change-plan.impossible':
    'Không thể thay đổi gói đăng ký trong vòng 24 giờ trước ngày thanh toán tiếp theo.',
  'plan.coupon.notice': 'Lưu ý khi sử dụng mã giảm giá',
  'plan.coupon.notice.content':
    '· Nếu hủy giữa chừng khi vẫn đang sử dụng gói đăng ký, hiệu lực của phiếu giảm giá sẽ bị mất.\n    · Không thể sử dụng trùng mã giảm giá.\n    · Trường hợp có cùng mã giảm giá, mỗi nhóm chỉ được phép sử dụng 1 lần.\n    · Trường hợp các gói có áp dụng mã giảm giá, không thể thay đổi các tùy chọn của gói ( thay đổi số lượng người tối đa). Nếu cần thay đổi các tùy chọn, cần phải hủy gói và đăng ký lại, hiệu lực của mã giảm giá cũng sẽ bị mất.',
  'plan.processing.title': 'Đang tiến hành xử lý thanh toán.',
  'plan.processing.subtitle': 'Đang tiến hành thanh toán thẻ. Vui lòng đợi trong giây lát.',
  'plan.completed.title.smOnly': 'Đã hoàn thành thanh toán gói đăng ký!',
  'plan.completed.title.mdAndUp': 'Đã hoàn thành thanh toán gói đăng ký!',
  'hypler.completed.title': 'Đã hoàn thành thanh toán Hypher PLCO',
  'plan.completed.subtitle.part1.lgAndUp': 'Thông tin về gói cước và lịch sử thanh toán có',
  'plan.completed.subtitle.part1.mdAndDown': 'Thông tin về gói cước và lịch sử thanh toán có',
  'plan.completed.subtitle.part2': '\\u00A0',
  'plan.completed.subtitle.part3': 'kiểm tra được.',
  'plan.completed.go-home': 'Trở về trang chủ',
  'plan.billing.title': 'Đăng ký & Thanh toán',
  'plan.billing.plan-details': '🧢 Thông tin đăng ký',
  'plan.billing.plan-expired': 'Đăng ký đã hết hạn',
  'plan.billing.cancel.plan': 'Đăng ký đã hủy',
  'plan.billing.refund.plan': 'Đăng ký được hoàn tiền',
  'plan.billing.subscribe.plan': 'Đăng ký gói',
  'plan.billing.resume.plan': 'Đăng ký lại gói',
  'plan.billing.retry.payment': 'Thanh toán lại',
  'plan.billing.cancel': 'Hủy',
  'plan.billing.refund': 'Hoàn tiền',
  'plan.billing.payment.details': '💳 Thông tin thanh toán',
  'plan.billing.payment.date.expires': '{date} Dự kiến hết hạn',
  'plan.billing.invoice.details': '📩 Thông tin hóa đơn',
  'plan.billing.edit': 'Thay đổi',
  'plan.billing.payment.history': '🧾 Nội dung thanh toán',
  'plan.billing.license.period': 'Thời hạn cấp phép',
  'plan.billing.connected.teams': 'Số nhóm liên kết',
  'plan.billing.license.name': 'Tên giấy phép',
  'plan.billing.license.owner': 'Chủ sở hữu giấy phép',
  'plan.billing.available.team': 'Số nhóm có thể liên kết thêm',
  'hyper.billing.period': 'Thời hạn cấp phép',
  'plan.billing.payment-fail.help.text':
    'Thanh toán thất bại.\nThanh toán sẽ được thử lại tổng cộng 4 lần vào lúc 12:00 sáng và 12:00 trưa. Nếu thanh toán không thực hiện được trong 4 lần thử thì gói đăng ký sẽ không được gia hạn.\nVui lòng kiểm tra hoặc thay đổi thẻ trước ngày gia hạn.',
  'plan.billing.status.refund': 'Hoàn tiền',
  'plan.billing.status.pay': 'Thanh toán hoàn tất',
  'plan.billing.status.fail': 'Thanh toán thất bại',
  'plan.edit.invoice.title': 'Thay đổi thông tin hóa đơn',
  'plan.change.card.title': 'Bạn muốn thay đổi thông tin thẻ thanh toán?',
  'plan.change.card.subtitle.mdAndUp':
    'Khi thay đổi thẻ thanh toán hệ thống sẽ trừ 100won để kiểm tra,\nsau 10 phút sẽ được hoàn lại vào thẻ.',
  'plan.change.card.subtitle.smOnly':
    'Khi thay đổi thẻ thanh toán hệ thống sẽ trừ 100won để kiểm tra, sau 10 phút sẽ được hoàn lại vào thẻ.',
  'plan.change.card.button': 'Thay đổi thẻ thanh toán',
  'plan.change.card.product.name': 'Kiểm tra thẻ thanh toán',
  'plan.refund-plan.title': 'Bạn muốn hoàn tiền gói đăng ký không?',
  'plan.refund-plan.subtitle':
    'Sau khi thanh toán, có thể hoàn lại tiền gói đăng ký trong vòng 24 giờ.',
  'plan.refund-plan.toasts.success': 'Gói đăng ký đã được hoàn tiền thành công.',
  'plan.retry-payment.title': 'Bạn muốn thử thanh toán lại không?',
  'plan.retry-payment.subtitle':
    'Nếu muốn thay đổi thẻ thanh toán, vui lòng đổi thẻ ở mục Thông tin thanh toán > Phương thức thanh toán và thử lại.',
  'plan.retry-payment.toasts.success': 'Thanh toán thành công.',
  'plan.retry-payment.toasts.fail':
    'Thanh toán thất bại. Vui lòng kiểm tra lại phương thức thanh toán một lần nữa.',
  'plan.resume-plan.title': 'Bạn muốn gia hạn đăng ký lại gói này không?',
  'plan.resume-plan.button': 'Đăng ký lại gói',
  'plan.resume-plan.toasts.success': 'Gia hạn đăng ký đã hoàn tất.',
  'plan.cancel-plan.title': 'Bạn muốn hủy đăng ký gói không?',
  'plan.cancel-plan.subtitle':
    'Gói đăng ký sẽ được hủy vào ngày {day}.\nNếu đăng ký lại PLCO, bạn có thể giám sát bằng dữ liệu đã lưu.',
  'plan.cancel-plan.toasts.success': 'Gói đăng ký đã được hủy thành công.',
  'plan.expiration.title': 'Gói đăng ký đã hết hạn',
  'plan.free-trial.expiration.title': 'Thời gian dùng thử miễn phí đã kết thúc',
  'plan.expiration.subtitle.smOnly':
    'Đăng ký gói và tiếp tục sử dụng các dịch vụ đa dạng của PLCO 😊',
  'plan.expiration.subtitle.mdAndUp':
    'Đăng ký gói và tiếp tục sử dụng các dịch vụ đa dạng của PLCO 😊',
  'players.header.title.no-player': 'Vui lòng đợi trong giây lát!\nVẫn chưa có cầu thủ nào cả...',
  'players.header.subtitle.no-player':
    'Hãy mời vào nhóm và thử giám sát dữ liệu cá nhân của các cầu thủ!',
  'players.header.button.no-player': 'Mời thành viên',
  'players.readiness.previous-day': 'So sánh',
  'players.readiness.previous-day.no-change': 'Không có thay đổi',
  'players.card.player-info.title': 'Thông tin cầu thủ',
  'players.card.player-info.birthdate': 'Ngày tháng năm sinh',
  'players.card.player-info.height': 'Chiều cao',
  'players.card.player-info.weight': 'Cân nặng',
  'players.card.player-info.none': 'Không nhập',
  'players.card.readiness.title': 'Mức độ chuẩn bị',
  'players.card.readiness.past-7-days': '7 ngày trước',
  'players.card.soreness.title': 'Cơn đau',
  'players.card.soreness.footer':
    'Hãy thử nhấn vào một bộ phận! Có thể xem chi tiết các diễn biến và hồ sơ.',
  'players.card.injury.title': 'Chấn thương',
  'players.card.current-injury.tab': 'Chấn thương hiện tại',
  'players.card.past-injury.tab': 'Chấn thương trước đây',
  'players.card.current-injury-empty.text': 'Không phải chấn thương',
  'players.card.past-injury-empty.text': 'Không có ghi chép trước đây',
  'players.card.past-injury-empty.subtext':
    "Nếu bật 'Kết thúc chấn thương' khi Đăng ký/Chỉnh sửa,\nnó sẽ được lưu vào chấn thương trước đây.",
  'players.card.injury-register.button': 'Đăng ký chấn thương',
  'players.card.injury-list.return.label': 'Trở lại',
  'players.card.injury-list.return-unknown.label': 'Ngày trở lại chưa xác định',
  'players.card.injury-register.help':
    'Chấn thương là dữ liệu được người đứng đầu quản lý,\nsẽ không được hiển thị cho cầu thủ.',
  'players.tab-menu-item.CONDITION.title': 'Thể trạng',
  'players.tab-menu-item.WORKLOAD.title': 'Tải tập luyện',
  'players.tab-menu-item.WORKLOAD_ACXR.title': 'Nguy cơ chấn thương',
  'players.tab-menu-item.SORENESS.title': 'Cơn đau',
  'players.tab-menu-item.BODY.title': 'Cơ thể',
  'players.condition.chart-card.condition-by-items.title': 'Thể trạng của từng mục',
  'players.condition.chart-card.condition.title': 'Thể trạng',
  'players.condition.chart-card.fatigue.title': 'Mức độ mệt mỏi',
  'players.condition.chart-card.stress.title': 'Căng thẳng',
  'players.condition.chart-card.muscle.title': 'Cơ bắp',
  'players.condition.chart-card.mood.title': 'Tâm trạng',
  'players.condition.chart-card.sleep.title': 'Giấc ngủ',
  'players.workload.chart-card.workload.title': 'Tải tập luyện',
  'players.workload.chart-card.intensity.title': 'Cường độ luyện tập (RPE)',
  'players.workload.chart-card.duration.title': 'Thời gian luyện tập',
  'players.workload.chart-card.satisfaction.title': 'Mức độ hài lòng',
  'players.acwr.chart-card.acwr.title': 'Nguy cơ chấn thương (ACWR)',
  'players.injury.chart-card.soreness-injury.title': 'Cơn đau',
  'players.body.chart-card.weight.title': 'Thể trọng',
  'players.body.chart-card.height.title': 'Chiều cao',
  'players.injury.tooltip.button': 'Xem chi tiết',
  'edit-profile.layout.title': 'Hồ sơ của tôi',
  'edit-profile.title': 'Chỉnh sửa hồ sơ',
  'edit-profile.upload.button': 'Tải lên ảnh đại diện',
  'edit-profile.upload.under-line': 'Thay đổi thành ảnh mặc định',
  'edit-profile.toasts.success': 'Hồ sơ đã được lưu.',
  'edit-profile.toasts.error.file-size':
    'Kích thước file quá lớn. Vui lòng tải lên file có kích thước dưới 1MB',
  'register.welcome.title': '🎉 Chào mừng bạn!',
  'register.welcome.instruction':
    'PLCO là dịch vụ giúp đạt được hiệu suất tốt nhất bằng cách ghi lại và giám sát dữ liệu.',
  'register.welcome.hint':
    'Vui lòng trả lời một số câu hỏi để có thể sử dụng dịch vụ một cách tốt nhất!',
  'register.name.title': 'Vui lòng nhập tên của bạn',
  'register.name.instruction':
    'Nên nhập tên thật để mọi người có thể nhận ra bạn. Bạn có thể thay đổi tên bất cứ lúc nào.',
  'register.profile-picture.title': 'Thêm ảnh đại diện',
  'register.profile-picture.instruction':
    'Vui lòng thêm ảnh đại diện để người khác dễ dàng nhận ra bạn.',
  'register.profile-picture.upload': 'Tải lên ảnh hồ sơ',
  'register.profile-picture.reset': 'Thay đổi thành ảnh mặc định',
  'register.gender.title': 'Vui lòng chọn giới tính',
  'register.gender.man': 'Nam',
  'register.gender.woman': 'Nữ',
  'register.gender.others': 'Khác',
  'register.birthdate.title': 'Vui lòng nhập ngày tháng năm sinh',
  'register.nationality.title': 'Vui lòng nhập quốc tịch',
  'register.sports-category.title': 'Vui lòng nhập môn thể thao',
  'register.position.title': 'Vui lòng nhập chức vụ',
  'register.phone-number.title': 'Vui lòng nhập số điện thoại',
  'register.phone-number.instruction':
    'Vui lòng nhập số điện thoại di động để nhận thông báo và thông tin quan trọng.',
  'register.team-code.title.first': 'Bạn có thuộc nhóm nào không?',
  'register.team-code.title.second': 'Vui lòng nhập mã nhóm',
  'register.team-code.instruction':
    'Nếu nhóm bạn đã sử dụng PLCO, hãy liên hệ với người tạo nhóm để lấy mã nhóm.',
  'register.team-code.team-code.placeholder': 'Nhập mã nhóm gồm 6 chữ số',
  'register.team-code.connect': 'Liên kết',
  'team-chart.header.title': 'Biểu đồ đội',
  'team-chart.chart.condition.title': 'Thể trạng của đội',
  'team-chart.chart.workload.title': 'Tải tập luyện của đội',
  'team-chart.chart.injury.title': 'Số lượng cơn đau của đội',
  'team-chart.widget.soreness.title': 'Cầu thủ có cơn đau ở mức độ 5 ({count})',
  'team-chart.widget.injury.subtitle': 'Tiêu chuẩn ・ {date}',
  'team-code.coach.copy.messages':
    "Hãy tham gia vào nhóm {teamName}! 👍\n\nTên nhóm: {teamName}\nMã nhóm: {teamCode}\n\nCách liên kết nhóm:\n1) Đăng nhập vào https://plco.pro\n2) Nhấn vào 'Nhập mã code'\n3) Nhập 'Mã nhóm hiện tại'.\n4) Nếu quản trị viên chấp nhận thì thành công!",
  'team-code.player.copy.messages':
    "Vui lòng sát nhập vào nhóm {teamName}! 👍\n\nTên nhóm: {teamName}\nMã nhóm: {teamCode}\n\nCách liên kết nhóm:\n1) Nhấn chọn 'ảnh hồ sơ'.\n2) Nhấn chọn 'thẻ mã nhóm'.\n3) Nhập 'mã nhóm hiện tại'.\n4) Nếu quản trị viên chấp nhận thì thành công!\n\nBạn vẫn chưa tải ứng dụng sao?\nHãy tải xuống tại đường link https://plco.onelink.me/HcII/7eee538ff ngay!",
  'navbar-teams.back': 'Quay lại',
  'edit-team-profile.layout.title': 'Hồ sơ nhóm',
  'edit-team-profile.title': 'Chỉnh sửa hồ sơ nhóm',
  'edit-team-profile.upload.button': 'Tải lên ảnh đại diện',
  'edit-team-profile.upload.under-line': 'Thay đổi thành ảnh mặc định',
  'edit-team-profile.toasts.success': 'Đã lưu hồ sơ của nhóm.',
  'edit-team-profile.toasts.error.file-size':
    'Kích thước file quá lớn. Vui lòng tải lên file có kích thước dưới 1MB',
  'teams.team-list.layout.title': 'Nhóm',
  'teams.enter-team-code.title': 'Nhập mã nhóm',
  'teams.enter-team-code.content': 'Bạn có nhận được lời mời từ nhóm nào không?',
  'teams.create-team.title': 'Tạo nhóm',
  'teams.create-team.content.first': 'Tạo nhóm mới',
  'teams.create-team.content.second': 'Mời thành viên',
  'teams.team-list.title.all-teams': 'Tổng nhóm ({count})',
  'teams.team-card.team.count': 'Tổng {membersCount}· Cầu thủ {playersCount}',
  'teams.team-card.edit.team-profile': 'Chỉnh sửa hồ sơ nhóm',
  'teams.team-card.leave.team': 'Rời khỏi nhóm',
  'teams.team-card.leave.toasts.success': 'Rời khỏi nhóm thành công',
  'teams.team-card.team.waiting': 'Đang đợi chấp nhận',
  'teams.default.toast.error': 'Đã xảy ra lỗi. Vui lòng tải lại trang.',
  'teams.exceed.toast.error':
    'Vượt quá số lượng thành viên đã đăng ký nên không thể thay đổi quyền hạn. Vui lòng kiểm tra lại.',
  'teams.select-modal.selected-member': 'Đã chọn {membersCount}người',
  'member-pending-table.title': 'Yêu cầu',
  'member-pending-table.name': 'Tên',
  'member-pending-table.sportName': 'Môn thể thao',
  'member-pending-table.sportPosition': 'Vị trí thi đấu',
  'member-pending-table.sportRole': 'Vai trò',
  'member-pending-table.noData': 'Không có yêu cầu',
  'member-pending-table.actions.accept': 'Chấp nhận',
  'member-pending-table.actions.reject': 'Từ chối',
  'member-pending-table.accept.player.expired-plan.title':
    'Chỉ có thể chấp nhận các cầu thủ đã đăng ký.',
  'member-pending-table.accept.player.expired-plan.subtitle':
    'Đăng ký hiện tại đã hết hạn nên không thể chấp nhận làm thành viên.\nVui lòng thử lại sau khi đăng ký.',
  'member-pending-table.subscribe.plan': 'Đăng ký',
  'member-pending-table.accept.toasts.success':
    '{membersCount, plural,\n      =1 {Đã chấp nhận {name}.}\n      =1000 {Đã chấp nhận 999+ người.}\n      other {#Đã chấp nhận.}\n    }',
  'member-pending-table.reject.toasts.success':
    '{membersCount, plural,\n      =1 {Đã từ chối {name}.}\n      =1000 {Đã từ chối 999+ người.}\n      other {#Đã từ chối.}\n    }',
  'member-table.all': 'Tất cả',
  'member-table.member': 'Thành viên',
  'member-table.admin': 'Quản trị viên',
  'member-table.owner': 'Chủ sở hữu',
  'member-table.title': 'Thành viên',
  'member-table.name': 'Tên',
  'member-table.sportName': 'Môn thể thao',
  'member-table.sportPosition': 'Vị trí thi đấu',
  'member-table.sportRole': 'Vai trò',
  'member-table.teamAuthority': 'Quyền hạn',
  'member-table.change-teamAuthority': 'Thay đổi quyền hạn',
  'member-table.noData': 'Vui lòng mời cầu thủ và huẩn luyện viên',
  'member-table.player.noData': 'Vui lòng mời cầu thủ',
  'member-table.auth-help.owner.auth': 'Chủ sở hữu',
  'member-table.auth-help.owner.desc':
    'Là người thành lập nhóm. Có thể thiết lập các nhóm thành viên. Có thể tạo, chỉnh sửa và xóa lịch trình. Có thể xóa nhóm nếu cần thiết.',
  'member-table.auth-help.admin.auth': 'Quản trị viên',
  'member-table.auth-help.admin.desc':
    '(Huấn luyện viên hoặc giám sát viên) có thể giám sát dữ liệu của thành viên. Có thể tạo, chỉnh sửa và xóa lịch trình.',
  'member-table.auth-help.member.auth': 'Thành viên',
  'member-table.auth-help.member.desc': '(Cầu thủ) Là cầu thủ trực thuộc nhóm.',
  'member-table.kick.toasts.success': 'Đã xóa {name} ra khỏi nhóm.',
  'member-table.transfer.toasts.success': 'Quyền hạn của {name} đã được đổi thành {authority}.',
  'member-table.authority.toasts.success':
    '{membersCount, plural,\n      =1 {Quyền hạn của {name} đã được đổi thành {authority}.}\n      =1000 {Quyền hạn của 999+ người đã được đổi thành {authority}.}\n      other {Quyền hạn của #người đã được đổi thành {authority}.}\n    }',
  'button-delete-team.button': 'Xóa nhóm',
  'button-delete-team.modal.success': 'Đã xóa thành công nhóm {teamName}.',
  'button-delete-team.modal.error': 'Không thể xóa nhóm nếu có thành viên trong nhóm.',
  'button-delete-team.modal.error.teamId': 'Không tìm thấy nhóm. Vui lòng thử lại.',
  'button-delete-team.modal.confirm': 'Bạn chắc chắn muốn xóa nhóm không?',
  'button-delete-team.dialog.modal.title': 'Không thể xóa một nhóm khi đã đăng ký.',
  'button-delete-team.dialog.modal.subtitle':
    'Trong trường hợp muốn xóa nhóm, vui lòng hủy gói đang sử dụng và thử lại.',
  'toast.error-message': 'Đã có vấn đề xảy ra. Vui lòng thử lại sau.',
  'toast.empty-player': 'Không có cầu thủ.',
  'toast.unchecked-empty-player': 'Không có cầu thủ chưa kiểm tra.',
  'toast.modal-check-condition-reminder.success': 'Gửi thông báo đã kiểm tra thể trạng thành công.',
  'toast.modal-check-condition-reminder.error': 'Đã có vấn đề xảy ra. Vui lòng thử lại sau.',
  'toast.modal-check-condition-reminder.error.already-sent':
    'Đã gửi thông báo kiểm tra. Hãy thử lại sau 1 phút. ({seconds}giây)',
  'toast.modal-check-workout-reminder.success': 'Đã gửi thông báo kiểm tra tập luyện thành công.',
  'toast.modal-check-workout-reminder.error': 'Đã xảy ra lỗi. Vui lòng thử lại sau.',
  'toast.modal-check-workout-reminder.info.already-sent':
    'Đã gửi thông báo kiểm tra. Hãy thử lại sau 1 phút. ({seconds}giây)',
  'toast.modal-check-workload-reminder.success':
    'Đã gửi thành công thông báo tải tập luyện cho các cầu thủ chưa kiểm tra',
  'toast.modal-group-delete.success': 'Đã xóa nhóm {groupName} thành công.',
  'toast.modal-group-modify.success': 'Các thay đổi đã được lưu lại.',
  'toast-modal-check-participant-reminder-success':
    'Đã gửi thông báo kiểm tra luyện tập đến các cầu thủ chưa kiểm tra thành công.',
  'toast-modal-check-participant-reminder-error': 'Đã xảy ra lỗi. Vui lòng thử lại sau.',
  'toast.event.past-data.modify': 'Không thể chỉnh sửa sau 28 ngày.',
  'toast.event.past-data.delete': 'Không thể chỉnh sửa sau 28 ngày.',
  'toast.training-report.success': 'Lưu thành công',
  'toast.training-report.error': 'Lưu không thành công. Vui lòng thử lại sau.',
  'toast.injury-create.success': 'Đã đăng ký chân thương thành công.',
  'toast.injury-delete.success': 'Đã xóa chấn thương thành công.',
  'toast.injury-update.success': 'Đã chỉnh sửa chấn thương thành công.',
  'toast.injury-update.current.success': 'Đã thay đổi thành chấn thương hiện tại.',
  'toast.injury-update.past.success': 'Đã thay đổi thành chấn thương trước đây.',
  'toast.injury-file-upload.count.error': 'Không thể đính kèm quá 3 tệp.',
  'toast.injury-file-upload.size.error': 'Không thể đính kèm file vượt quá 10MB.',
  'toast.event-create.success': 'Hoàn tất thêm lịch trình {month} {day}',
  'toast.event-update.success': 'Hoàn tất chỉnh sửa lịch trình {month} {day}',
  'toast.event-delete.success': 'Hoàn tất xóa lịch trình {month} {day}',
  'toast.injury-create.select-player.error': 'Vui lòng chọn cầu thủ chấn thương',
  'toast.dropdown-select-player.error': 'Vui lòng chọn ít nhất 1 cầu thủ',
  'toast.feedback-has-been-sent': 'Đã đăng ký phản hồi',
  undefined: '',
  'plan.subtitle': 'Đăng ký gói thanh toán định kỳ hàng tháng bằng thẻ tín dụng hoặc thẻ ghi nợ.',
  'plan.elite': 'Xuất sắc',
  'plan.elite.description': 'Kế hoạch cho\nđội học sinh',
  'plan.pro': 'Chuyên nghiệp',
  'plan.pro.description': 'Kế hoạch cho\ncấp độ chuyên nghiệp',
  'plan.pay.contect-description':
    'Vui lòng liên hệ với chúng tôi nếu bạn cần thảo luận về số lượng đội/thời hạn sử dụng/phương thức thanh toán,... ngoài khoản thanh toán định kỳ hàng tháng. (Ví dụ: Các hiệp hội, liên đoàn, cơ quan giáo dục,...)',
  'dashboard.data.weekly-workload.title': 'Tổng tải trọng tập luyện đội',
  'dashboard.data.weekly-workload.toggle-button': 'Chỉ lên lịch',
  'dashboard.data.weekly-workload.heavy': 'Nhiều lượng bài tập 4000',
  'dashboard.data.last-week.label': 'Tuần trước',
  'dashboard.data.this-week.label': 'Tuần này',
  'dashboard.data.last-week-day.workload.sum': '{day} tuần trước\nTổng {sum}',
  'dashboard.condition-banner.title': '😎 Kiểm tra thể trạng',
  'dashboard.workout-banner.title': '💪 Kiểm tra tập luyện',
  'dashboard.banner-checked.players': 'Kiểm tra',
  'dashboard.banner-unchecked.players': 'Chưa kiểm tra',
  'dashboard.banner-unchecked.notification': 'Gửi thông báo chưa kiểm tra',
  'group-monitoring.group-button.create': 'Thêm nhóm ({count}/5)',
  'group-monitoring.modal-group.setting.header': 'Chỉnh sửa nhóm',
  'group-monitoring.group-button.setting': 'Chỉnh sửa nhóm',
  'group-monitoring.modal-group.guide':
    'Thông tin nhóm đã lưu được hiển thị đồng nhất cho tất cả trưởng nhóm.',
  'group-monitoring.modal-group.group-list.header.title': 'Chọn nhóm',
  'group-monitoring.modal-group.setting.delete-button': 'Xóa nhóm này',
  'dashboard.data.latest-feedback.title': 'Phản hồi mới nhất',
  'dashboard.data.latest-feedback.guide':
    'Bạn có thể xem tối đa 20 đăng ký gần đây nhất.\nVui lòng xác nhận toàn bộ phản hồi ở mục thông báo hoặc đội hình.',
  'plan.billing.status.loading': 'Đang xử lý thanh toán',
  'plan.billing.status.failed': 'Thanh toán thất bại',
  'plan.billing.status.success': 'Thanh toán thành công',
  'plan.regular.payment.date': 'Ngày thanh toán định kỳ',
  'toast.modal-group-create.success': 'Đã được thêm vào nhóm',
  'date.format.month-minute': 'MM. Do hh:mm',
  'date.format.month-day-time': 'MM. Do LT',
  'date.format.year-month-day': 'YYYY. MM. D',
  'date.format.year-month-day-suffix': 'YYYY. MM. Do',
  'date.format.year-to-minutes': 'YYYY. MM. Do · HH:mm',
  'date.format.year-to-minutes-a': 'YYYY. MM. D HH:mm',
  'date.format.month-day': 'MM. D',
  'date.format.period': 'YYYY. MM. D',
  'date.format.year-month': 'YYYY. MM',
  'date.format.year-month-day-week': 'YYYY. MM. Do (dd)',
  'date.format.hour-minutes': '{hour}giờ {minute}phút',
  'date.year-month': '{YYYY} {MMM}',
  'date.year-full-month': '{YYYY} {MMMM}',
  'date.weekdays': 'Thứ hai_thứ ba_thứ tư_thứ năm_thứ sáu_thứ bảy_chủ nhật',
  'date.left.day': 'Còn lại {days}ngày',
  'date.duration.time': 'Hgiờ Mphút',
  NO: 'Không',
  YES: 'Có',
  CANCEL: 'Hủy',
  CLOSE: 'Đóng',
  CONFIRM: 'Xác nhận',
  DONE: 'Hoàn tất',
  RETRY: 'Thử lại',
  LEARN_MORE: 'Xem thêm',
  APPLY: 'Áp dụng',
  SEND: 'Gửi',
  SELECT: 'Lựa chọn',
  SEARCH: 'Tìm kiếm',
  HOUR: '{hours, plural, =0 {#giờ} other {#giờ}}',
  H: 'Giờ',
  MINUTE: '{minutes, plural, =0 {# phút} other {# phút}}',
  M: 'Phút',
  DAY: 'Ngày',
  MONTH: 'Tháng',
  YEAR: 'Năm',
  COINT: 'Cái',
  POINT: 'Điểm',
  SCORE: 'Tỉ số chung cuộc',
  PEOPLE: 'Người',
  PEOPLE_COUNT: '{count}',
  CM: 'cm',
  KG: 'kg',
  NAME: 'Tên',
  TODAY: 'Hôm nay',
  YESTERDAY: 'Hôm qua',
  START: 'Bắt đầu',
  NEXT: 'Tiếp theo',
  PREV: 'Quay lại',
  NOT_NOW: 'Tiếp tục',
  OKAY: 'Xác nhận',
  LEAVE: 'Rời khỏi',
  SAVE: 'Lưu',
  SUM: 'Tích lũy',
  ISER: 'Người dùng',
  MAKE: 'Tạo mới',
  ADD: 'Thêm vào',
  CHANGE: 'Thay đổi',
  PART: 'Phần',
  GOAL: 'Mục tiêu',
  ALL: 'Tất cả',
  FILTER: 'Lọc',
  NO_SEARCH_RESULT: 'Không tìm thấy kết quả',
  SELECT_ALL: 'Chọn tất cả',
  DELETE: 'Xóa',
  REFRESH: 'Tải lại trang',
  'federation.kakao': 'Kakaotalk',
  'federation.facebook': 'Facebook',
  'federation.apple': 'Ứng dụng',
  'federation.google': 'Google',
  'ko-KR': '한국어',
  'en-KR': 'English',
  'ja-KR': '日本語',
  'vn-VN': 'Tiếng Việt',
  READINESS: 'Mức độ chuẩn bị',
  FATIGUE: 'Mức độ mệt mỏi',
  STRESS: 'Căng thẳng',
  MUSCLE: 'Cơ bắp',
  MOOD: 'Tâm trạng',
  SLEEP_DURATION: 'Thời gian ngủ',
  DURATION: 'Thời gian ngủ',
  SLEEP_QUALITY: 'Chất lượng giấc ngủ',
  QUALITY: 'Chất lượng giấc ngủ',
  WORKLOAD_ACX: 'Nguy cơ chấn thương (ACWR)',
  WORKLOAD_AXD: 'Sự đơn điệu',
  WORKLOAD_AX: 'Tải tập luyện tăng cấp tốc',
  WORKLOAD_CX: 'Tải tập luyện tăng tích lũy',
  WORKLOAD_AX_CX: 'Tải tập luyện tăng cấp tốc & tích lũy',
  WORKLOAD_DURATION: 'Thời gian tập luyện',
  WORKLOAD_INTENSITY: 'Cường độ tập luyện',
  WORKLOAD_INTENSITY_RPE: 'Cường độ tập luyện (RPE)',
  WORKLOAD_SATISFACTION: 'Mức độ hài lòng',
  SORENESS_1: 'Cơn đau mức độ 1',
  SORENESS_2: 'Cơn đau mức độ 2',
  SORENESS_3: 'Cơn đau mức độ 3',
  SORENESS_4: 'Cơn đau mức độ 4',
  SORENESS_5: 'Cơn đau mức độ 5',
  CONDITION: 'Thể trạng',
  WORKLOAD: 'Tải tập luyện',
  HEIGHT: 'Chiều cao',
  WEIGHT: 'Cân nặng',
  ACWR: 'Nguy cơ chấn thương',
  DANGER_TRAINING: 'Nguy hiểm',
  WARNING_TRAINING: 'Chú ý',
  BEST_TRAINING: 'Phù hợp nhất',
  CAUTION_TRAINING: 'Lưu ý',
  RECENT_RECORDS: 'Ghi chép gần nhất',
  SORENESS_NUMBER_1: 'Mức độ 1',
  SORENESS_NUMBER_2: 'Mức độ 2',
  SORENESS_NUMBER_3: 'Mức độ 3',
  SORENESS_NUMBER_4: 'Mức độ 4',
  SORENESS_NUMBER_5: 'Mức độ 5',
  TYPE: 'Loại',
  '1-day': 'Trong ngày',
  '1-week': 'Trong tuần',
  '1-month': 'Trong tháng',
  'event-type.TRAINING': 'Huấn luyện',
  'event-type.MATCH': 'Trận đấu',
  'event-type.PRACTICE': 'Trận đấu giao hữu',
  'event-type.REST': 'Nghỉ ngơi',
  'event-type.OTHER_EVENT': 'Khác',
  ATTEND: 'Tham gia',
  ABSENCE: 'Vắng mặt',
  SEE_MORE: 'Xem thêm',
  SORENESS_INTERPOLATED_STREAK:
    '{days, plural, =0 {Không có} =1 {Liên tục #ngày} =28 {Liên tục hơn #ngày} other {Liên tục #ngày}}',
  SORENESS_DEGREE: 'Mức độ {degree}',
  STREAK:
    '{days, plural, =0 {Không có dữ liệu} =9999 {Liên tục 9999+ ngày} other {Liên tục #ngày}}',
  STREAK_NEGATIVE:
    '{days, plural, =0 {Không có dữ liệu} =1 {Trải qua #ngày sau khi nhập} =9999 {Trải qua 9999+ ngày sau khi nhập} other {Trải qua #ngày sau khi nhập}}',
  'USER.NAME': 'Tên',
  'USER.NAME.placeholder': 'Vui lòng nhập tên thật',
  'USER.NATIONALITY': 'Quốc tịch',
  'USER.GENDER': 'Giới tính',
  'USER.BIRTHDATE': 'Ngày sinh',
  'USER.USERNAME': 'Tên người dùng',
  'USER.USERNAME.placeholder': 'Vui lòng nhập tên người dùng',
  'USER.PHONENUMBER': 'Số điện thoại',
  'USER.PHONENUMBER.title': 'Thay đổi số điện thoại',
  'USER.INTRODUCE': 'Giới thiệu bản thân',
  'USER.INTRODUCE.placeholder': 'Vui lòng giới thiệu bản thân',
  'USER.INTRODUCTION.empty': 'Không có giới thiệu',
  'GENDER.MALE': 'Nam',
  'GENDER.FEMALE': 'Nữ',
  'GENDER.OTHER': 'Khác',
  PLAYER: 'Cầu thủ',
  COACH: 'Người quản lý',
  ROLE: 'Vai trò',
  'ROLE.PLAYER': 'Cầu thủ',
  'ROLE.COACH': 'Người quản lý',
  CATEGORY: 'Môn thể thao',
  POSITION: 'Vị trí thi đấu',
  TEAM: 'Đội',
  GROUP: 'Nhóm',
  BIRTHDATE: 'Ngày tháng năm sinh',
  'TEAM_AUTHORITY.OWNER': 'Chủ sở hữu',
  'TEAM_AUTHORITY.ADMIN': 'Quản trị viên',
  'TEAM_AUTHORITY.MEMBER': 'Thành viên',
  'TEAM_AUTHORITY.VIEWER': 'Người xem',
  SORENESS_LEVEL_1: 'Mức độ 1',
  SORENESS_LEVEL_2: 'Mức độ 2',
  SORENESS_LEVEL_3: 'Mức độ 3',
  SORENESS_LEVEL_4: 'Mức độ 4',
  SORENESS_LEVEL_5: 'Mức độ 5',
  CONTACT: 'Tiếp xúc',
  NON_CONTACT: 'Không tiếp xúc',
  DATA: 'Dữ liệu',
  'DATA.NONE': 'Không có',
  'DATA.READINESS': 'Mức độ chuẩn bị',
  'DATA.WORKLOAD': 'Tải tập luyện',
  'DATA.WORKLOAD_TOTAL': 'Tổng tải tập luyện',
  'DATA.WORKLOAD_RAW': 'Tải tập luyện',
  'DATA.WORKLOAD_INTENSITY': 'Cường độ tập luyện',
  'DATA.WORKLOAD_DURATION': 'Thời gian tập luyện',
  'DATA.WORKLOAD_SATISFACTION': 'Mức độ hài lòng',
  'DATA.WORKLOAD_AX': 'Tải tập luyện tăng cấp tốc',
  'DATA.WORKLOAD_AXD': 'Sự đơn điệu',
  'DATA.WORKLOAD_CX': 'Tải tập luyện tăng tích lũy',
  'DATA.WORKLOAD_ACXR': 'Nguy cơ chấn thương (ACWR)',
  'DATA.CONDITION': 'Thể trạng',
  'DATA.CONDITION_AX': 'Thể trạng trong 1 tuần gần nhất',
  'DATA.CONDITION_CX': 'Thể trạng trong 4 tuần gần nhất',
  'DATA.WELLNESS': 'Sức khỏe',
  'DATA.WELLNESS_MUSCLE': 'Cơ bắp',
  'DATA.WELLNESS_MOOD': 'Tâm trạng',
  'DATA.WELLNESS_FATIGUE': 'Mức độ mệt mỏi',
  'DATA.WELLNESS_STRESS': 'Căng thẳng',
  'DATA.WELLNESS_RECOVERY': 'Hồi phục',
  'DATA.SLEEP': 'Giấc ngủ',
  'DATA.SLEEP_DURATION': 'Thời gian ngủ',
  'DATA.SLEEP_QUALITY': 'Chất lượng giấc ngủ',
  'DATA.RECOVERY': 'Hồi phục',
  'DATA.ACWR': 'Nguy cơ chấn thương',
  'DATA.SORENESS': 'Mức độ cơn đau',
  'DATA.SORENESS_COUNT': 'Số lượng cơn đau',
  'DATA.BODY': 'Cơ thể',
  'DATA.BODY_HEIGHT': 'Chiều cao',
  'DATA.BODY_WEIGHT': 'Cân nặng',
  'DATA.BODY_BMI': 'Chỉ số khối cơ thể (BMI)',
  'DATA.BODY_RECOMMENDED_HYDRATION': 'Lượng nước khuyến nghị',
  'DATA.RAW_CONDITION': 'Thể trạng',
  'DATA.RAW_WELLNESS': 'Sức khỏe',
  'DATA.CONDITION.BODY': 'Cơ bắp',
  'DATA.RAW_SLEEP': 'Giấc ngủ',
  'DATA.CONDITION.QUALITY': 'Chất lượng',
  'DATA.CONDITION.DURATION': 'Giờ',
  'DATA.WORKOUT': 'Vận động',
  'DATA.NORMAL.WORKOUT': 'Tập thường',
  'DATA.NORMAL': 'Tập thường',
  'DATA.REHAB': 'Tập trị liệu',
  'DATA.REHAB.WORKOUT': 'Tập trị liệu',
  'DATA.NO.WORKOUT': 'Không vận động',
  'DATA.REST': 'Nghỉ ngơi',
  'DATA.INJURY': 'Chấn thương',
  'DATA.RAW_WORKLOAD': 'Tải tập luyện',
  'DATA.WORKLOAD.INTENSITY': 'Cường độ',
  'DATA.WORKLOAD.SATISFACTION': 'Hài lòng',
  'DATA.WORKLOAD.DURATION': 'Thời gian tập luyện',
  'DATA.INJURY.SORENESS': 'Cơn đau',
  'DATA.RAW_BODY': 'Cơ thể',
  'DATA.BODY.WEIGHT': 'Cân nặng',
  'DATA.BODY.HEIGHT': 'Chiều cao',
  'DATA.STRIDE_DAY': 'Theo ngày',
  'DATA.STRIDE_WEEK': 'Theo tuần',
  'DATA.STRIDE_MONTH': 'Theo tháng',
  'DATA.RAW_BODY.HEIGHT': 'Chiều cao',
  'DATA.RAW_BODY.WEIGHT': 'Cân nặng',
  'DATA.NO.INPUT': 'Không có dữ liệu',
  'DATA.READINESS.[0,50).label': 'Rất tệ',
  'DATA.READINESS.[50,60).label': 'Tệ',
  'DATA.READINESS.[60,75).label': 'Bình thường',
  'DATA.READINESS.[75,85).label': 'Tốt',
  'DATA.READINESS.[85,100].label': 'Rất tốt',
  'DATA.CONDITION.[1,2).label': 'Rất thấp',
  'DATA.CONDITION.[2,4).label': 'Thấp',
  'DATA.CONDITION.[4,7).label': 'Bình thường',
  'DATA.CONDITION.[7,9).label': 'Cao',
  'DATA.CONDITION.[9,10].label': 'Rất cao',
  'DATA.WELLNESS_MUSCLE.[1,3).label': 'Rất tệ',
  'DATA.WELLNESS_MUSCLE.[3,5).label': 'Tệ',
  'DATA.WELLNESS_MUSCLE.[5,7).label': 'Bình thường',
  'DATA.WELLNESS_MUSCLE.[7,9).label': 'Tốt',
  'DATA.WELLNESS_MUSCLE.[9,10].label': 'Rất tốt',
  'DATA.WELLNESS_MOOD.[1,3).label': 'Rất tệ',
  'DATA.WELLNESS_MOOD.[3,5).label': 'Tệ',
  'DATA.WELLNESS_MOOD.[5,7).label': 'Bình thường',
  'DATA.WELLNESS_MOOD.[7,9).label': 'Tốt',
  'DATA.WELLNESS_MOOD.[9,10].label': 'Rất tốt',
  'DATA.WELLNESS_FATIGUE.[1,3).label': 'Rất tệ',
  'DATA.WELLNESS_FATIGUE.[3,5).label': 'Tệ',
  'DATA.WELLNESS_FATIGUE.[5,7).label': 'Bình thường',
  'DATA.WELLNESS_FATIGUE.[7,9).label': 'Tốt',
  'DATA.WELLNESS_FATIGUE.[9,10].label': 'Rất tốt',
  'DATA.WELLNESS_STRESS.[1,3).label': 'Rất tệ',
  'DATA.WELLNESS_STRESS.[3,5).label': 'Tệ',
  'DATA.WELLNESS_STRESS.[5,7).label': 'Bình thường',
  'DATA.WELLNESS_STRESS.[7,9).label': 'Tốt',
  'DATA.WELLNESS_STRESS.[9,10].label': 'Rất tốt',
  'DATA.SLEEP_DURATION.[0,300).label': 'Rất tệ',
  'DATA.SLEEP_DURATION.[300,360).label': 'Tệ',
  'DATA.SLEEP_DURATION.[360,420).label': 'Bình thường',
  'DATA.SLEEP_DURATION.[420,480).label': 'Tốt',
  'DATA.SLEEP_DURATION.[480,720].label': 'Rất tốt',
  'DATA.SLEEP_QUALITY.[1,3).label': 'Rất tệ',
  'DATA.SLEEP_QUALITY.[3,5).label': 'Tệ',
  'DATA.SLEEP_QUALITY.[5,7).label': 'Bình thường',
  'DATA.SLEEP_QUALITY.[7,9).label': 'Tốt',
  'DATA.SLEEP_QUALITY.[9,10].label': 'Rất tốt',
  'DATA.WORKLOAD.[0,201).label': 'Rất thấp',
  'DATA.WORKLOAD.[201,401).label': 'Thấp',
  'DATA.WORKLOAD.[401,801).label': 'Bình thường',
  'DATA.WORKLOAD.[801,1001).label': 'Cao',
  'DATA.WORKLOAD.[1001,Infinity].label': 'Rất cao',
  'DATA.WORKLOAD_INTENSITY.[1,2).label': 'Rất nhẹ',
  'DATA.WORKLOAD_INTENSITY.[2,4).label': 'Nhẹ',
  'DATA.WORKLOAD_INTENSITY.[4,7).label': 'Bình thường',
  'DATA.WORKLOAD_INTENSITY.[7,8).label': 'Hơi nặng',
  'DATA.WORKLOAD_INTENSITY.[8,10).label': 'Nặng',
  'DATA.WORKLOAD_INTENSITY.[10,10].label': 'Tối đa',
  'DATA.WORKLOAD_DURATION.[0,31).label': 'Rất thấp',
  'DATA.WORKLOAD_DURATION.[31,61).label': 'Thấp',
  'DATA.WORKLOAD_DURATION.[61,121).label': 'Bình thường',
  'DATA.WORKLOAD_DURATION.[121,241).label': 'Cao',
  'DATA.WORKLOAD_DURATION.[241,720].label': 'Rất cao',
  'DATA.WORKLOAD_AX.[0,201).label': 'Rất thấp',
  'DATA.WORKLOAD_AX.[201,401).label': 'Thấp',
  'DATA.WORKLOAD_AX.[401,801).label': 'Bình thường',
  'DATA.WORKLOAD_AX.[801,1001).label': 'Cao',
  'DATA.WORKLOAD_AX.[1001,7200].label': 'Rất cao',
  'DATA.WORKLOAD_AXD.[2,Infinity].label': 'Tệ',
  'DATA.WORKLOAD_AXD.[0,2).label': 'Tốt',
  'DATA.WORKLOAD_AXI.[0,0.8).label': 'Thấp',
  'DATA.WORKLOAD_AXI.[0.8,1.31).label': 'Bình thường',
  'DATA.WORKLOAD_AXI.[1.31,1.5).label': 'Cao',
  'DATA.WORKLOAD_AXI.[1.5,Infinity].label': 'Rất cao',
  'DATA.WORKLOAD_CX.[0,201).label': 'Rất thấp',
  'DATA.WORKLOAD_CX.[201,401).label': 'Thấp',
  'DATA.WORKLOAD_CX.[401,801).label': 'Bình thường',
  'DATA.WORKLOAD_CX.[801,1001).label': 'Cao',
  'DATA.WORKLOAD_CX.[1001,7200].label': 'Rất cao',
  'DATA.WORKLOAD_ACXR.[0,0.8).label': 'Lưu ý',
  'DATA.WORKLOAD_ACXR.[0.8,1.31).label': 'Phù hợp nhất',
  'DATA.WORKLOAD_ACXR.[1.31,1.5).label': 'Chú ý',
  'DATA.WORKLOAD_ACXR.[1.5,Infinity].label': 'Nguy hiểm',
  'DATA.SORENESS.[0,1).label': 'Không có cơn đau',
  'DATA.SORENESS.[1,2).label': 'Cơn đau nhức nhẹ',
  'DATA.SORENESS.[2,3).label': 'Cơn đau nhức vừa',
  'DATA.SORENESS.[3,4).label': 'Cơn đau nhức nặng',
  'DATA.SORENESS.[4,5).label': 'Cơn đau nhức nghiêm trọng',
  'DATA.SORENESS.[5,5].label': 'Cơn đau không chịu được',
  'DATA.BODY_BMI.[0,18.5).label': 'Thiếu cân',
  'DATA.BODY_BMI.[18.5,23).label': 'Cân nặng bình thường',
  'DATA.BODY_BMI.[23,25).label': 'Thừa cân',
  'DATA.BODY_BMI.[25,30).label': 'Béo phì nhẹ',
  'DATA.BODY_BMI.[30,40).label': 'Béo phì vừa phải',
  'DATA.BODY_BMI.[40,Infinity].label': 'Béo phì nặng',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).label': 'Cường độ rất nhẹ',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).label': 'Cường độ nhẹ',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).label': 'Cường độ bình thường',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).label': 'Cường độ hơi mạnh',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).label': 'Cường độ mạnh',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].label': 'Cường độ tối đa',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).description': 'Hầu như không cần dùng sức',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).description':
    'Ở mức có thể chịu đựng được trong vài giờ đồng hồ.\n Hô hấp dễ dàng và có thể trò chuyện bình thường',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).description':
    'Ở mức hơi khó thở. Có thể trò chuyện ngắn. Trạng thái khá thoải mái',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).description':
    'Ở mức hơi khó chịu. Thở dốc. Có thể nói từng câu.',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).description':
    'Khó có thể duy trì cường độ tập luyện. Khó thở. Chỉ có thể nói được vài từ.',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].description':
    'Khó có thể tiếp tục. Hoàn toàn không thở được. Không thể nói chuyện được',
  'ERROR.BAD_REQUEST': 'Yêu cầu đã bị lỗi.',
  'ERROR.UNAUTHORIZED': 'Xác minh của khách hàng bị thất bại.',
  'ERROR.FORBIDDEN': 'Bạn không có quyền hạn.',
  'ERROR.NOT_FOUND': 'Không thể tìm thấy.',
  'ERROR.NOT_FOUND.requestTeamInvitation': 'Không tìm thấy mã nhóm. Vui lòng kiểm tra lại.',
  'ERROR.REQUEST_TIMEOUT': 'Yêu cầu đã hết thời gian.',
  'ERROR.CONFLICT': 'Yêu cầu bị trùng lặp.',
  'ERROR.UNPROCESSABLE_ENTITY': 'Lỗi xác thực.',
  'ERROR.INTERNAL_SERVER_ERROR': 'Đã xảy ra lỗi tạm thời. Vui lòng thử lại sau.',
  'ERROR.NOT_IMPLEMENTED': 'Đang bảo trì.',
  'ERROR.NETWORK_ERROR':
    'Đã xảy ra sự cố mạng. Vui lòng kiểm tra trạng thái kết nối và thử lại sau.',
  'ERROR.SESSION_EXPIRED': 'Phiên đã hết hạn',
  'ERROR.TEAM_DUPLICATED_INVITATION': 'Đang chờ chấp nhận.',
  'plan.change.applied-coupon.subtitle': '-',
  'plan.change.applied-coupon.title': '-',
  'member-pending-table.accept.player.exceed.subtitle': '-',
  'member-pending-table.accept.player.exceed.title': '-',
  'member-pending-table.change.plan': '-',
  'banner-update.title': 'Đã nhận ra điều gì đã thay đổi chưa?',
  'banner-update.subtext': 'Hãy kiểm tra ngay tin tức cập nhật!',
  'team-chart.dropdown-dataset-players.condition.checked-players.title':
    'Kiểm tra thể trạng ({count})',
  'team-chart.dropdown-dataset-players.condition.unchecked-players.title':
    'Chưa kiểm tra thể trạng ({count})',
  'team-chart.dropdown-dataset-players.workout.checked-players.title':
    'Cầu thủ kiểm tra vận động ({count})',
  'team-chart.dropdown-dataset-players.workout.unchecked-players.title':
    'Cầu thủ chưa kiểm tra vận động ({count})',
  SORENESS_TYPE: 'Loại hình',
  SORENESS_SITUATION: 'Tình hình phát sinh cơn đau',
  'group-monitoring.modal-group.setting.cancel': 'Xác nhận hủy chỉnh sửa nhóm?',
  'date-range.7-days': '7 ngày trước',
  'date-range.30-days': '30 ngày trước',
  MON: 'T2',
  TUE: 'T3',
  WED: 'T4',
  THU: 'T5',
  FRI: 'T6',
  SAT: 'T7',
  SUN: 'CN',
  VALIDATION_ERROR: 'Lỗi xác nhận',
  'VALIDATION_ERROR.required': '{field} là bắt buộc',
  'VALIDATION_ERROR.string': '{field} phải là văn bản',
  'VALIDATION_ERROR.stringEmpty': '{field} không thể để trống',
  'VALIDATION_ERROR.stringMin': '{field} phải có ít nhất {expected}ký tự',
  'VALIDATION_ERROR.stringMax': '{field} phải có tối đa {expected}ký tự',
  'VALIDATION_ERROR.stringLength': '{field} phải có đúng {expected} ký tự',
  'VALIDATION_ERROR.stringPattern': '{field} không phù hợp với định dạng',
  'VALIDATION_ERROR.stringContains': '{field} phải có chứa ít nhất {expected} ký tự',
  'VALIDATION_ERROR.stringEnum': '\n{field} không tồn tại trong các giá trị cho phép',
  'VALIDATION_ERROR.stringNumeric': '{field} phải là một kết hợp số',
  'VALIDATION_ERROR.stringAlpha': '{field} phải là một kết hợp chữ cái tiếng Anh',
  'VALIDATION_ERROR.stringAlphanum': '{field} phải là một kết hợp chữ cái và số',
  'VALIDATION_ERROR.stringAlphadash':
    '{field} phải là một kết hợp của chữ cái tiếng Anh và ký tự đặc biệt',
  'VALIDATION_ERROR.stringHex': '{field} phải ở dạng hex ',
  'VALIDATION_ERROR.stringSingleLine': '{field} phải là một dòng',
  'VALIDATION_ERROR.number': '{field} phải là một số',
  'VALIDATION_ERROR.numberMin': '{field} phải {expected} trở lên',
  'VALIDATION_ERROR.numberMax': '{field} phải {expected} trở xuống',
  'VALIDATION_ERROR.numberEqual': '{field} phải là {expected}',
  'VALIDATION_ERROR.numberNotEqual': '{field} không thể là {expected}',
  'VALIDATION_ERROR.numberInteger': '{field} phải là một giá trị số nguyên',
  'VALIDATION_ERROR.numberPositive': '{field} phải là một số dương',
  'VALIDATION_ERROR.numberNegative': '{field} phải là một số âm',
  'VALIDATION_ERROR.array': '{field} phải là một mảng',
  'VALIDATION_ERROR.arrayEmpty': '{field} không thể là một mảng trống',
  'VALIDATION_ERROR.arrayMin': '{field}의 mảng phải có ít nhất {expected} giá trị',
  'VALIDATION_ERROR.arrayMax': 'Mảng của {field} phải có từ {expected} giá trị trở xuống',
  'VALIDATION_ERROR.arrayLength': '-',
  'VALIDATION_ERROR.arrayContains': '-',
  'VALIDATION_ERROR.arrayUnique': '-',
  'VALIDATION_ERROR.arrayEnum': '-',
  'VALIDATION_ERROR.tuple': '-',
  'VALIDATION_ERROR.tupleEmpty': '-',
  'VALIDATION_ERROR.tupleLength': '-',
  'VALIDATION_ERROR.boolean': '-',
  'VALIDATION_ERROR.function': '-',
  'VALIDATION_ERROR.date': '-',
  'VALIDATION_ERROR.dateMin': '{field} phải là {expected} hoặc lớn hơn.',
  'VALIDATION_ERROR.dateMax': '{field} phải là {expected} hoặc nhỏ hơn.',
  'VALIDATION_ERROR.forbidden': '{field} không được phép',
  'VALIDATION_ERROR.email': '{field} không phải là định dạng email hợp lệ',
  'VALIDATION_ERROR.url': '{field} không phải là định dạng url hợp lệ',
  'VALIDATION_ERROR.enumValue': '{field} phải chọn một',
  'VALIDATION_ERROR.equalValue': '-',
  'VALIDATION_ERROR.equalField': '-',
  'VALIDATION_ERROR.object': '-',
  'VALIDATION_ERROR.objectStrict': '-',
  'VALIDATION_ERROR.objectMinProps': '-',
  'VALIDATION_ERROR.objectMaxProps': '-',
  'VALIDATION_ERROR.uuid': '-',
  'VALIDATION_ERROR.uuidVersion': '-',
  'VALIDATION_ERROR.mac': '-',
  'VALIDATION_ERROR.luhn': '-',
  'VALIDATION_ERROR.duplicate': '{field} đã được sử dụng',
  'VALIDATION_ERROR.sports.createTeam.username':
    'Hãy nhập ít nhất 4 ký tự với kết hợp chữ cái, số và dấu gạch nối (-)',
  'VALIDATION_ERROR.sports.createTeam.username.duplicate': 'Đã sử dụng URL.',
  'VALIDATION_ERROR.sports.createTeam.name.stringPattern':
    'Không được sử dụng ký tự đặc biệt hoặc emoji.',
  'VALIDATION_ERROR.sports.joinTeam.code':
    'Hãy nhập ít nhất 4 ký tự với kết hợp chữ cái, số và dấu gạch nối (-)',
  'VALIDATION_ERROR.sports.updateUser.name.pattern':
    'Sử dụng chữ, số và dấu gạch ngang (-) duy nhất.',
  'VALIDATION_ERROR.sports.updateUser.birthdate.common':
    'Ngày sinh không hợp lệ. Vui lòng thử lại.',
  'VALIDATION_ERROR.sports.updateUser.phone-number.common':
    'Số điện thoại di động không hợp lệ. Vui lòng thử lại.',
  'VALIDATION_ERROR.sports.createEvent.title.required': 'Vui lòng nhập tên lịch trình.',
  'VALIDATION_ERROR.sports.createEvent.startAt.required': 'Vui lòng nhập ngày và giờ đúng cách.',
  'VALIDATION_ERROR.sports.createEvent.endAt.required': 'Vui lòng nhập ngày và giờ đúng cách.',
  'VALIDATION_ERROR.sports.createEvent.expectedIntensity.required':
    'Hãy chọn mức độ tập luyện mục tiêu.',
  'VALIDATION_ERROR.sports.createEvent.participants.required': 'Hãy chọn đối tượng tham gia.',
  'VALIDATION_ERROR.sports.updateEvent.title.required': 'Vui lòng nhập tên lịch trình.',
  'VALIDATION_ERROR.sports.updateEvent.startAt.required': 'Vui lòng nhập ngày và giờ đúng cách.',
  'VALIDATION_ERROR.sports.updateEvent.endAt.required': 'Vui lòng nhập ngày và giờ đúng cách.',
  'VALIDATION_ERROR.sports.updateEvent.expectedIntensity.required':
    'Hãy chọn mức độ tập luyện mục tiêu.',
  'VALIDATION_ERROR.sports.updateEvent.participants.required': 'Hãy chọn đối tượng tham gia.',
  'VALIDATION_ERROR.sports.updateTeam.name.pattern':
    'Sử dụng chữ, số và dấu gạch ngang (-) duy nhất.',
  'VALIDATION_ERROR.sports.TeamGroup.name': 'Hãy nhập ít nhất 1 và tối đa 10 ký tự.',
  'VALIDATION_ERROR.sports.TeamGroup.userIds': 'Hãy chọn ít nhất 1 thành viên.',
  'VALIDATION_ERROR.plan.buyer.name': '1 ký tự trở lên và không quá 50 ký tự.',
  'VALIDATION_ERROR.plan.buyer.email': 'Email không hợp lệ. Vui lòng thử lại.',
  'VALIDATION_ERROR.plan.buyer.phone': 'Số điện thoại di động không hợp lệ. Vui lòng thử lại.',
  'VALIDATION_ERROR.username.duplicate': 'Tên này đã được sử dụng bởi người dùng hoặc nhóm khác.',
  'VALIDATION_ERROR.username.stringMin': 'Tên người dùng phải có ít nhất 4 ký tự.',
  'VALIDATION_ERROR.username.stringAlphadash':
    'Tên người dùng phải được tạo bằng chữ cái và dấu gạch (-).',
  'VALIDATION_ERROR.sports.match.opponent.required': 'Vui lòng nhập tên đối thủ.',
  'VALIDATION_ERROR.sports.match.competition.required': 'Vui lòng nhập thông tin về cuộc thi.',
  'VALIDATION_ERROR.sports.match.location.required': 'Vui lòng nhập địa điểm của trận đấu.',
  'VALIDATION_ERROR.sports.match.date.required': 'Vui lòng nhập ngày và giờ đúng cách.',
  'VALIDATION_ERROR.sports.match.notice-target.required': 'Hãy chọn đối tượng thông báo.',
  'ERROR.FIELD.name': 'Tên',
  'ERROR.FIELD.team.name': 'Tên nhóm',
  'ERROR.FIELD.team.url': 'URL',
  'ERROR.FIELD.sportsCategory': 'Môn thể thao',
  'ERROR.FIELD.region': 'Quốc gia',
  'ERROR.FIELD.username': 'Tên người dùng',
  'ERROR.FIELD.sports.createTeam.username': 'Url nhóm',
  'ERROR.FIELD.code': 'Mã nhóm',
  'ERROR.FIELD.role': 'Vai trò',
  'ERROR.FIELD.position': 'Chức vụ',
  'ERROR.FIELD.sports_category': 'Môn thể thao',
  'ERROR.FIELD.nationality': 'Quốc tịch',
  'ERROR.FIELD.gender': 'Giới tính',
  'ERROR.FIELD.birthdate': 'Ngày sinh',
  'ERROR.FIELD.phoneNumber': 'Số điện thoại',
  'ERROR.EMPTY.select': 'Hãy chọn {field}',
  'ERROR.EMPTY.input': 'Hãy nhập {field} vào',
  WORKLOAD_ACXR: 'Nguy cơ chấn thương (ACWR)',
  'plan.elite.icon': '🏅',
  'DATA.WORKLOAD_SATISFACTION.[1,3).label': 'Rất thấp',
  'DATA.WORKLOAD_SATISFACTION.[3,5).label': 'Thấp',
  'DATA.WORKLOAD_SATISFACTION.[5,7).label': 'Bình thường',
  'DATA.WORKLOAD_SATISFACTION.[7,9).label': 'Cao',
  'DATA.WORKLOAD_SATISFACTION.[9,10].label': 'Rất cao',
  'date.format.year-month-day-week-suffix': 'YYYY. M. D · HH:mm',
  'plan.pay.contact-link':
    'https://docs.google.com/forms/d/e/1FAIpQLSc3OlUZgM8YRseHHo47kQ8VNbMFJvt38fFYDJGaEUUq5QB4_A/viewform?usp=sf_link',

  member__button__change_owner: 'Thay đổi chủ sở hữu',
  member__authority__owner: 'Chủ sở hữu',
  member__authority__coach: 'HLV',
  member__authority__player: 'Cầu thủ',
  member__toast__kickout_select_first: 'Vui lòng chọn thành viên để loại khỏi đội trước',
  member__tooltip__owner: 'Bạn là người tạo ra đội và có đầy đủ quyền quản trị.',
  member__popup__title_single_kickout: 'Bạn có muốn loại {name} ra khỏi đội không?',
  member__popup__title_multi_kickout: 'Bạn có muốn loại {count} thành viên ra khỏi đội không?',
  member__toast__kickout_successed: '{count} thành viên đã được loại ra',
  member__toast__request_select_first: 'Vui lòng chọn thành viên để chấp nhận/từ chối trước',
  member__tooltip__authority_disabled:
    'Bạn đã có lịch sử được đăng ký là {authority}. Vui lòng liên hệ nếu bạn muốn thay đổi.',
  member__popup__title_single_accept:
    'Bạn có muốn chấp nhận yêu cầu dưới danh xưng [{authority}] không?',
  member__popup__description_accept:
    'Sau đó, bạn sẽ không thể thay đổi cầu thủ-HLV, vì vậy hãy quyết định cẩn thận.',
  member__popup__title_multi_accept: 'Bạn có muốn chấp nhận {count} yêu cầu không?',
  member__popup__title_reject: 'Bạn có muốn từ chối yêu cầu không?',
  member__popup__descriptiom_reject: 'Tổng cộng từ chối {count} yêu cầu.',
  member__toast__accept_successed: 'Đã chấp nhận {count} yêu cầu',
  member__toast__reject_successed: '{count} yêu cầu đã bị từ chối',
  member__description__request_empty: 'Không có yêu cầu',
  member__dialog__title_change_owner: 'Vui lòng chọn\nchủ sở hữu mới',
  member__dialog__description_change_owner: 'Quyền sở hữu sẽ được chuyển giao cho HLV đã chọn.',
  member__dialog__text_1_empty_change_owner: 'Không có HLV',
  member__dialog__text_2_empty_change_owner:
    'Vui lòng mời HLV bạn muốn\nchuyển thành chủ sở hữu vào đội trước.',
  member__popup__title_change_owner_alert: 'Bạn có muốn chuyển quyền sở hữu cho [{name}] không?',
  member__toast__change_owner_successed: 'Đã thay đổi chủ sở hữu',
  member__tooltip__authority_owner:
    'Bạn là người tạo ra đội và có đầy đủ quyền quản trị. Bạn có thể sử dụng tất cả các chức năng bao gồm xóa đội và có thể chuyển quyền sở hữu cho các HLV khác.',
  member__tooltip__authority_coach:
    'Bạn là một giám đốc, huấn luyện viên hoặc người lãnh đạo khác. Bạn có thể sử dụng tất cả các chức năng ngoại trừ việc xóa đội.',
  member__tooltip__authority_player:
    'Bạn là một cầu thủ trong đội. Bạn không thể sử dụng các chức năng của PLCO COACH.',
  member__toast__plan_expired: 'Đăng ký đã hết hạn nên không thể chấp nhận/ từ chối thành viên',
}

export default messages
