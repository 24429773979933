import {
  AXIS_TICK_FORMAT,
  AXIS_X_ORIENTATION,
  AXIS_X_STYLE,
  AXIS_X_TICK_VALUE,
  AXIS_Y_ORIENTATION,
  AXIS_Y_TICK_VALUE,
  CHART_ANIMATE,
  CHART_TYPE,
  DATASET_CORNER_RADIUS,
  DOMAIN_SIZE,
  DOMAIN_Y,
  LABEL_CONFIG_PREFIX,
  LABEL_CONFIG_WIDTH,
  POLAR_AXIS_LABEL_PLACEMENT,
  POLAR_Y_FORMAT_DURATION,
  TICK_LABEL_COMPONENT,
  Y_FORMAT,
} from '@plco-pro/maps/chart-system/props'

import type { Context } from '@apollo/client'
import type { ChartTooltipPeriod } from '@plco-pro/components/molecules/chart-label-tooltip-multi-team-data'
import type { UserDataType } from '@plco-pro/graphqls/react.generated'
import type { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import type { AXIS_STYLE } from '@plco-pro/maps/chart-system/props'
import type { TrainingChartType } from '@plco-pro/maps/chart-training-report'
import type { QuerySystemMap } from '@plco-pro/maps/query-system'
import type { StoreSystemMapKey } from '@plco-pro/maps/store-system'
import type { Theme } from '@plco-pro/themes/main'

export type Color = keyof Theme['colors'] | string

export enum ChipType {
  'TEN' = 'ten',
  'TIME' = 'time',
  'NUMBER' = 'number',
  'CM' = 'cm',
  'KG' = 'kg',
}

export type ParserSystemKey =
  | 'USER_DATA'
  | 'USER_DATA_WITHOUT_USER'
  | 'TEAM_USER_MAPS_DATA'
  | 'TEAM_AVG_DATA'
  | 'DAILY_CONDITION'
  | 'PLAYER_DAILY_CONDITION'
  | 'TEAM_MONITORING'
  | 'TEAM_MONITORING_WORKLOAD'
  | 'PLAYER_WORKLOAD_DATA'
  | 'USER_WORKLOAD'
  | 'PARTICIPANT_WORKLOAD'
  | 'PARTICIPANT_CONDITION'

export type ParserSystemConfig = ParserSystemKey

export type ParserSystemInterpretationFormat =
  | 'SUM'
  | 'AVERAGE'
  | 'AVERAGE_ROUND_0'
  | 'AVERAGE_ROUND_1'
  | 'AVERAGE_ROUND_2'
  | 'IS_NUMERIC'
  | 'TRANSLATE'
export type ParserSystemFormat = 'FLOOR' | 'TIME_MM.D'

export type QuerySystemConfig = {
  name: keyof QuerySystemMap
  variables: {
    [variableKey: string]: any
  }
  period?: 'DAY' | 'WEEK' | 'MONTH' | 'LAST_7_DAYS' | 'LAST_28_DAYS'
  context?: Context
}

export enum PersonalDataTabId {
  CONDITION = 'CONDITION',
  WORKLOAD = 'WORKLOAD',
  ACWR = 'WORKLOAD_ACXR',
  SORENESS = 'SORENESS',
  BODY = 'BODY',
}

export type ChartSystemMapValue = {
  query?: QuerySystemConfig
  parser: ParserSystemConfig
  store: StoreSystemMapKey
  dataset: ChartSystemPropsDataset
  domain: ChartSystemPropsDomain
  label?: ChartSystemPropsLabel
}

export type AverageDatasetOptions = {
  field: string
  precision: number
  format?: AXIS_TICK_FORMAT
  prefix?: LABEL_CONFIG_PREFIX
  isAcwrLabel?: boolean
}

export type ChartSystemPropsDatasetItem = {
  type?: CHART_TYPE
  width?: number
  // line 차트에서 scatter 의 width, scatter width = 1 일때, scatter 의 width 는 약 2.25 (확인이 필요함)
  scatterWidth?: number
  hoverScatterWidth?: number
  minWidth?: number
  maxWidth?: number
  offset?: number
  color?: Color
  colorList?: Color[]
  noneDataColor?: Color
  colorHover?: Color
  noneDataColorHover?: Color
  colorScale?: (keyof Theme['colors'] | string)[]
  colorScaleHover?: (keyof Theme['colors'] | string)[]
  cornerRadius?: DATASET_CORNER_RADIUS
  animate?: CHART_ANIMATE
  parser: ChartSystemPropsDatasetParser
  axis?: ChartSystemPropsAxis
  polarAxis?: ChartSystemPropsMapPolarAxis
  polarYFormat?: ChartSystemPropsMapPolarYFormat
  yFormat?: Y_FORMAT
  legend?: ChartSystemPropsDatasetLegend[]
  label?: ChartSystemPropsDatasetLabel
  sortBy?: string
  orderBy?: 'asc' | 'desc'
  drawPrevision?: boolean
  period?: ChartTooltipPeriod
  chipType?: 'number' | 'ten' | 'time'
  dataType?: MonitoringDataType | TrainingChartType
  nullDataKey?: string
}

export type ChartSystemPropsDataset = ChartSystemPropsDatasetItem[]

export type ChartSystemPropsDomain = {
  size?: DOMAIN_SIZE
  shift?: number
  x?: ChartSystemPropsDomainX
  y?: ChartSystemPropsDomainY
  xPaddingRatio?: number
  dataIndex?: number | number[]
}

// label
export type ChartSystemPropsLabelConfigItem = {
  chartType?: CHART_TYPE
  dataType?: MonitoringDataType
  color?: Color
  colorList?: Color[]
  chipType?: ChipType
  interpretPath?: string
  prefix?: 'AVG' | 'SUM'
  field?: string
  format?: AXIS_TICK_FORMAT
  nullDataKey?: string
}

export type ChartSystemPropsLabelConfig = {
  width?: LABEL_CONFIG_WIDTH
  period?: ChartTooltipPeriod
  format?: AXIS_TICK_FORMAT
  prefix?: LABEL_CONFIG_PREFIX
  items?: Array<ChartSystemPropsLabelConfigItem>
  color?: Color
  isAcwrLabel?: boolean
}

export type ChartSystemPropsLabel = {
  labelComponent?: TICK_LABEL_COMPONENT
  config?: ChartSystemPropsLabelConfig
}

// axis
export type ChartSystemPropsAxisXConfig = {
  tabId?: PersonalDataTabId
  dataType?: UserDataType
}
export type ChartSystemPropsAxisX = {
  orientation?: AXIS_X_ORIENTATION
  tickCount?: number
  tickValues?: AXIS_X_TICK_VALUE
  tickFormat?: AXIS_TICK_FORMAT
  tickLabelComponent?: TICK_LABEL_COMPONENT
  config?: ChartSystemPropsAxisXConfig
  style?: AXIS_X_STYLE
}

export type ChartSystemPropsAxisY = {
  orientation?: AXIS_Y_ORIENTATION
  tickCount?: number
  tickValues?: AXIS_Y_TICK_VALUE
  tickFormat?: AXIS_TICK_FORMAT
  tickLabelComponent?: TICK_LABEL_COMPONENT
  style?: AXIS_STYLE
}
export type ChartSystemPropsAxis = {
  x?: ChartSystemPropsAxisX | ChartSystemPropsAxisX[]
  y?: ChartSystemPropsAxisY | ChartSystemPropsAxisY[]
}

// polar axis
export type ChartSystemPropsMapPolarAxisX = {
  labelPlacement?: POLAR_AXIS_LABEL_PLACEMENT
  tickCount?: number
  tickValues?: AXIS_X_TICK_VALUE
  tickFormat?: AXIS_TICK_FORMAT
  tickLabelComponent?: TICK_LABEL_COMPONENT
  style?: AXIS_STYLE
}

export type ChartSystemPropsMapPolarAxisY = {
  labelPlacement?: POLAR_AXIS_LABEL_PLACEMENT
  dataName?: string
  tickCount?: number
  tickValues?: AXIS_Y_TICK_VALUE
  tickFormat?: AXIS_TICK_FORMAT
  tickLabelComponent?: TICK_LABEL_COMPONENT
  style?: AXIS_STYLE
}
export type ChartSystemPropsMapPolarAxis = {
  x?: ChartSystemPropsMapPolarAxisX | ChartSystemPropsMapPolarAxisX[]
  y?: ChartSystemPropsMapPolarAxisY | ChartSystemPropsMapPolarAxisY[]
}

export type ChartSystemPropsMapPolarYFormat = {
  duration: POLAR_Y_FORMAT_DURATION
}
// dataset
export type ChartSystemPropsDatasetParserItem = {
  field?: string
  format?: ParserSystemFormat
  value?: number | string
  interpretPath?: string
  interpretationFormat?: ParserSystemInterpretationFormat
  index?: 'FIRST_ONLY' | 'LAST_ONLY'
  postOperation?: boolean
  nullDataKey?: string
}
export type ChartSystemPropsDatasetParser = {
  [key in string]?: ChartSystemPropsDatasetParserItem
}
export type ChartSystemPropsDatasetLegend = {
  label?: string
  toggle?: boolean
  visible?: boolean
  group?: number
  chartType?: CHART_TYPE
  chartColor?: Color
  animate?: CHART_ANIMATE
}
export type ChartSystemPropsDatasetLabel = {
  labelComponent?: TICK_LABEL_COMPONENT
  color?: Color
  config?: ChartSystemPropsLabelConfig
}

// domain
export type ChartSystemPropsDomainX = {
  start?: number
  end?: number
}
export type ChartSystemPropsDomainY = {
  start?: AXIS_Y_TICK_VALUE | DOMAIN_Y | number
  end?: AXIS_Y_TICK_VALUE | DOMAIN_Y | number
}

// config
export type ChartSystemPropsConfig =
  | ChartSystemMapValue
  | ChartSystemPropsDatasetItem
  | ChartSystemPropsDomain
  | ChartSystemPropsAxis
  | ChartSystemPropsLabel
  | ChartSystemPropsMapPolarAxis

// tree
export type ValueOrArray<T> = T | ValueOrArray<T>[]
// eslint-disable-next-line @typescript-eslint/ban-types
export type ChartSystemPropsTree = ValueOrArray<Function | number>
