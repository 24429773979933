import { useCallback, useState } from 'react'

import { useStore } from '@plco-pro/stores'

export const ChartCustomScatter = (props: any) => {
  const { monitoring } = useStore()

  const {
    x,
    y,
    datum,
    scale,
    index,
    style,
    dotSize,
    onClick = () => {},
    onHover = () => {},
    onClose = () => {},
  } = props

  const { stroke, strokeWidth, fill } = style

  const [isHover, setIsHover] = useState(false)

  const openBottomSheet = useCallback(() => {
    onClick(index)
    monitoring.setBottomSheetVisible(true)
  }, [monitoring, index, onClick])

  const openTooltip = useCallback(() => {
    if (!datum || !scale) return

    onHover({
      index,
      datum,
      scale,
    })

    setIsHover(true)
  }, [index, datum, scale, onHover])

  const closeTooltip = useCallback(() => {
    onClose()
    setIsHover(false)
  }, [onClose])

  if (!datum.y && !datum.reasonForNoWorkout) return null

  return (
    <circle
      cx={x}
      cy={y}
      r={!isHover ? dotSize : 6}
      sx={{
        stroke,
        strokeWidth,
        fill,
        pointerEvents: 'all',
      }}
      onClick={openBottomSheet}
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    />
  )
}
