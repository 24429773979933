import { keyframes } from '@emotion/react'

export const Keyframes = {
  blink: keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `,
  rotation: keyframes`
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
`,
} as const
