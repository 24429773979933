import { UserDataType } from '@plco-pro/graphqls/react.generated'
import { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import theme from '@plco-pro/themes/main'

import {
  AverageDatasetOptions,
  ChartSystemMapValue,
  ChartSystemPropsDatasetItem,
  ChipType,
  ParserSystemInterpretationFormat,
  PersonalDataTabId,
} from './chart-system.types'
import {
  getBestTrainingAreaDataset,
  getCautionTrainingAreaDataset,
  getDangerTrainingAreaDataset,
  getWarningTraningAreaDataset,
} from './datasets'

export const CHART_SYSTEM_MONITORING_MAP_KEY = [
  // monitoring/condition
  'TEAM:CONDITION:1_DAYS:USER_AVATAR:BAR',
  'TEAM:CONDITION:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:CONDITION:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:FATIGUE:1_DAYS:USER_AVATAR:BAR',
  'TEAM:FATIGUE:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:FATIGUE:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:STRESS:1_DAYS:USER_AVATAR:BAR',
  'TEAM:STRESS:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:STRESS:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:MUSCLE:1_DAYS:USER_AVATAR:BAR',
  'TEAM:MUSCLE:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:MUSCLE:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:MOOD:1_DAYS:USER_AVATAR:BAR',
  'TEAM:MOOD:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:MOOD:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:SLEEP_DURATION:1_DAYS:USER_AVATAR:BAR',
  'TEAM:SLEEP_DURATION:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:SLEEP_DURATION:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:SLEEP_QUALITY:1_DAYS:USER_AVATAR:BAR',
  'TEAM:SLEEP_QUALITY:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:SLEEP_QUALITY:4_WEEKS:USER_AVATAR:BAR',

  // monitoring/workload
  'TEAM:WORKLOAD:1_DAYS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_INTENSITY:1_DAYS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_INTENSITY:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_INTENSITY:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_DURATION:1_DAYS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_DURATION:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_DURATION:4_WEEKS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_SATISFACTION:1_DAYS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_SATISFACTION:1_WEEKS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_SATISFACTION:4_WEEKS:USER_AVATAR:BAR',

  // monitoring/acwr
  'TEAM:WORKLOAD_ACXR:1_DAYS:USER_AVATAR:BAR',
  'TEAM:WORKLOAD_AX_CX:1_DAYS:USER_AVATAR:BAR_BAR',
  'TEAM:WORKLOAD_AXD:1_DAYS:USER_AVATAR:BAR',

  // monitoring/injury
  'TEAM:INJURY_1:1_DAYS:USER_AVATAR:BAR',
  'TEAM:INJURY_2:1_DAYS:USER_AVATAR:BAR',
  'TEAM:INJURY_3:1_DAYS:USER_AVATAR:BAR',
  'TEAM:INJURY_4:1_DAYS:USER_AVATAR:BAR',
  'TEAM:INJURY_5:1_DAYS:USER_AVATAR:BAR',

  // monitoring/body
  'TEAM:HEIGHT:1_DAYS:USER_AVATAR:BAR',
  'TEAM:WEIGHT:1_DAYS:USER_AVATAR:BAR',
] as const

export const getAverageDataset = (options: AverageDatasetOptions): ChartSystemPropsDatasetItem => {
  const { field, precision, format, prefix, isAcwrLabel } = options

  let interpretationFormat: ParserSystemInterpretationFormat | undefined
  if (precision === 0) {
    interpretationFormat = 'AVERAGE_ROUND_0'
  } else if (precision === 1) {
    interpretationFormat = 'AVERAGE_ROUND_1'
  } else if (precision === 2) {
    interpretationFormat = 'AVERAGE_ROUND_2'
  }

  return {
    type: 'DOTTED_LINE',
    color: prefix === 'GOAL' ? 'primary-500' : 'teal-500',
    parser: {
      x: {
        field: 'id',
      },
      y: {
        field,
        interpretationFormat,
        postOperation: true,
      },
      label: {
        field,
        interpretationFormat,
        postOperation: true,
      },
    },
    label: {
      labelComponent: 'LABEL_DOTTED_LINE',
      config: {
        format,
        prefix,
        isAcwrLabel: isAcwrLabel || false,
      },
    },
  }
}

export const chartSystemMonitoringMap: Record<
  (typeof CHART_SYSTEM_MONITORING_MAP_KEY)[number],
  ChartSystemMapValue
> = {
  'TEAM:CONDITION:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'deep-purple-300',
        colorHover: 'deep-purple-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'condition',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.condition,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'condition',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.CONDITION,
            color: theme.colors['deep-purple-300'],
            chipType: ChipType.TEN,
            interpretPath: 'condition',
          },
        ],
      },
    },
  },
  'TEAM:CONDITION:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'deep-purple-300',
        colorHover: 'deep-purple-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'condition',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.condition,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'condition',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '7_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.CONDITION,
            color: theme.colors['deep-purple-300'],
            chipType: ChipType.TEN,
            interpretPath: 'condition',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:CONDITION:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'deep-purple-300',
        colorHover: 'deep-purple-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'condition',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.condition,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'condition',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '28_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.CONDITION,
            color: theme.colors['deep-purple-300'],
            chipType: ChipType.TEN,
            interpretPath: 'condition',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:FATIGUE:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'pink-300',
        colorHover: 'pink-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'fatigue',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.fatigue,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'fatigue',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.FATIGUE,
            color: theme.colors['pink-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_FATIGUE',
          },
        ],
      },
    },
  },
  'TEAM:FATIGUE:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'pink-300',
        colorHover: 'pink-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'fatigue',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.fatigue,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'fatigue.avg',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '7_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.FATIGUE,
            color: theme.colors['pink-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_FATIGUE',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:FATIGUE:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'pink-300',
        colorHover: 'pink-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'fatigue',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.fatigue,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'fatigue',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '28_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.FATIGUE,
            color: theme.colors['pink-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_FATIGUE',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:STRESS:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'violet-300',
        colorHover: 'violet-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'stress',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.stress,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'stress',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.STRESS,
            color: theme.colors['violet-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_STRESS',
          },
        ],
      },
    },
  },
  'TEAM:STRESS:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'violet-300',
        colorHover: 'violet-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'stress',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.stress,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'stress',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '7_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.STRESS,
            color: theme.colors['violet-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_STRESS',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:STRESS:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'violet-300',
        colorHover: 'violet-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'stress',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.stress,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'stress',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '28_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.STRESS,
            color: theme.colors['violet-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_STRESS',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:MUSCLE:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'teal-300',
        colorHover: 'teal-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'muscle',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.muscle,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'muscle',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MUSCLE,
            color: theme.colors['teal-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_MUSCLE',
          },
        ],
      },
    },
  },
  'TEAM:MUSCLE:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'teal-300',
        colorHover: 'teal-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'muscle',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.muscle,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'muscle',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '7_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MUSCLE,
            color: theme.colors['teal-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_MUSCLE',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:MUSCLE:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'teal-300',
        colorHover: 'teal-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'muscle',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.muscle,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'muscle',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '28_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MUSCLE,
            color: theme.colors['teal-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_MUSCLE',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:MOOD:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'amber-300',
        colorHover: 'amber-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'mood',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.mood,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'mood',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MOOD,
            color: theme.colors['amber-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_MOOD',
          },
        ],
      },
    },
  },
  'TEAM:MOOD:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'amber-300',
        colorHover: 'amber-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'mood',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.mood,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'mood',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '7_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MOOD,
            color: theme.colors['amber-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_MOOD',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:MOOD:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'amber-300',
        colorHover: 'amber-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'mood',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.mood,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'mood',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '28_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MOOD,
            color: theme.colors['amber-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_MOOD',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:SLEEP_DURATION:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'indigo-300',
        colorHover: 'indigo-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'duration',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.sleepDuration,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_720_TICK_VALUES_DIVIDE_5',
            tickFormat: 'HOUR_FORMAT',
          },
        },
      },
      getAverageDataset({
        field: 'duration',
        precision: 0,
        prefix: 'AVG_STRING',
        format: 'HOUR_MINUTE_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 720,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_DURATION,
            color: theme.colors['indigo-300'],
            chipType: ChipType.TIME,
            interpretPath: 'SLEEP_DURATION',
          },
        ],
      },
    },
  },
  'TEAM:SLEEP_DURATION:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'indigo-300',
        colorHover: 'indigo-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'duration',
            interpretationFormat: 'AVERAGE_ROUND_0',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.sleepDuration,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_720_TICK_VALUES_DIVIDE_5',
            tickFormat: 'HOUR_FORMAT',
          },
        },
      },
      getAverageDataset({
        field: 'duration',
        precision: 0,
        prefix: 'AVG_STRING',
        format: 'HOUR_MINUTE_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 720,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '7_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_DURATION,
            color: theme.colors['indigo-300'],
            chipType: ChipType.TIME,
            interpretPath: 'SLEEP_DURATION',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:SLEEP_DURATION:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'indigo-300',
        colorHover: 'indigo-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'duration',
            interpretationFormat: 'AVERAGE_ROUND_0',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.sleepDuration,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_720_TICK_VALUES_DIVIDE_5',
            tickFormat: 'HOUR_FORMAT',
          },
        },
      },
      getAverageDataset({
        field: 'duration',
        precision: 0,
        prefix: 'AVG_STRING',
        format: 'HOUR_MINUTE_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 720,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '28_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_DURATION,
            color: theme.colors['indigo-300'],
            chipType: ChipType.TIME,
            interpretPath: 'SLEEP_DURATION',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:SLEEP_QUALITY:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'yellow-400',
        colorHover: 'yellow-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'quality',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.sleepQuality,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'quality',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_QUALITY,
            color: theme.colors['yellow-400'],
            chipType: ChipType.TEN,
            interpretPath: 'SLEEP_QUALITY',
          },
        ],
      },
    },
  },
  'TEAM:SLEEP_QUALITY:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'yellow-400',
        colorHover: 'yellow-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'quality',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.sleepQuality,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'quality',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '7_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_QUALITY,
            color: theme.colors['yellow-400'],
            chipType: ChipType.TEN,
            interpretPath: 'SLEEP_QUALITY',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'TEAM:SLEEP_QUALITY:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'CONDITION_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'yellow-400',
        colorHover: 'yellow-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'quality',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.CONDITION,
              dataType: UserDataType.sleepQuality,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'quality',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '28_DAYS',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_QUALITY,
            color: theme.colors['yellow-400'],
            chipType: ChipType.TEN,
            interpretPath: 'SLEEP_QUALITY',
            prefix: 'AVG',
          },
        ],
      },
    },
  },

  // monitoring/workload
  'TEAM:WORKLOAD:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD',
      },
    },
    parser: 'TEAM_MONITORING_WORKLOAD',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_STACK',
        width: 16,
        noneDataColorHover: '#A9CEC4',
        noneDataColor: 'teal-100',
        colorScale: ['teal-300', 'teal-600', 'teal-800', 'teal-900'],
        colorScaleHover: ['#62CAB1', '#18A4A6', '#10657A', '#0E4F69'],
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.WORKLOAD,
        chipType: ChipType.NUMBER,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'workload',
          },
          domainY: {
            field: 'workloadTotal',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workload,
            },
          },
          y: {
            orientation: 'LEFT',
          },
        },
      },
      getAverageDataset({
        field: 'workloadTotal',
        precision: 0,
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'DOMAIN_Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'teal-300',
        noneDataColor: 'teal-100',
        colorHover: '#62CAB1',
        noneDataColorHover: '#A9CEC4',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '7_DAYS',
        dataType: MonitoringDataType.WORKLOAD,
        nullDataKey: 'chart.data.none',
        chipType: ChipType.NUMBER,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'workload',
            format: 'FLOOR',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workload,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_TICK_VALUES_1600_UP_DOWN',
          },
        },
      },
      getAverageDataset({
        field: 'workload',
        precision: 0,
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_1600_ROUNDED_UP',
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'teal-300',
        noneDataColor: 'teal-100',
        colorHover: '#62CAB1',
        noneDataColorHover: '#A9CEC4',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '28_DAYS',
        dataType: MonitoringDataType.WORKLOAD,
        nullDataKey: 'chart.data.none',
        chipType: ChipType.NUMBER,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'workload',
            format: 'FLOOR',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workload,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_TICK_VALUES_1600_UP_DOWN',
          },
        },
      },
      getAverageDataset({
        field: 'workload',
        precision: 0,
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_1600_ROUNDED_UP',
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_INTENSITY:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'amber-200',
        noneDataColor: 'amber-100',
        colorHover: '#CDB978',
        colorScale: ['amber-200', 'amber-500', 'amber-700', 'amber-900'],
        noneDataColorHover: '#CDC29D',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.WORKLOAD_INTENSITY,
        chipType: ChipType.TEN,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'intensity',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadIntensity,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'intensity',
        precision: 1,
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_INTENSITY:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'amber-200',
        noneDataColor: 'amber-100',
        colorHover: '#CDB978',
        noneDataColorHover: '#CDC29D',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '7_DAYS',
        dataType: MonitoringDataType.WORKLOAD_INTENSITY,
        nullDataKey: 'chart.data.none',
        chipType: ChipType.TEN,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'intensity',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadIntensity,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'intensity',
        precision: 1,
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_INTENSITY:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'amber-200',
        noneDataColor: 'amber-100',
        colorHover: '#CDB978',
        noneDataColorHover: '#CDC29D',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '28_DAYS',
        dataType: MonitoringDataType.WORKLOAD_INTENSITY,
        nullDataKey: 'chart.data.none',
        chipType: ChipType.TEN,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'intensity',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadIntensity,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'intensity',
        precision: 1,
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_DURATION:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD',
      },
    },
    parser: 'TEAM_MONITORING_WORKLOAD',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_STACK',
        width: 16,
        noneDataColorHover: '#CCAAB0',
        noneDataColor: 'pink-100',
        colorScale: ['pink-300', 'pink-600', 'pink-800', 'pink-900'],
        colorScaleHover: ['#CA5A74', '#AB0E5E', '#990E51', '#7E0E4E'],
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.WORKLOAD_DURATION,
        chipType: ChipType.TIME,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'duration',
          },
          domainY: {
            field: 'workloadTotal',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadDuration,
            },
          },
          y: {
            orientation: 'LEFT',
            tickFormat: 'HOUR_MINUTE_FORMAT',
            tickValues: 'Y_TICK_VALUES_180_UP_DOWN',
          },
        },
      },
      getAverageDataset({
        field: 'duration',
        precision: 0,
        prefix: 'AVG_STRING',
        format: 'HOUR_MINUTE_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'DOMAIN_Y_180_UP_DOWN',
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_DURATION:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'pink-300',
        noneDataColor: 'pink-100',
        colorHover: '#CA5A74',
        noneDataColorHover: '#CCAAB0',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '7_DAYS',
        dataType: MonitoringDataType.WORKLOAD_DURATION,
        nullDataKey: 'chart.data.none',
        chipType: ChipType.TIME,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'duration',
            interpretationFormat: 'AVERAGE_ROUND_0',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadDuration,
            },
          },
          y: {
            orientation: 'LEFT',
            tickFormat: 'HOUR_MINUTE_FORMAT',
            tickValues: 'Y_TICK_VALUES_180_UP_DOWN',
          },
        },
      },
      getAverageDataset({
        field: 'duration',
        precision: 0,
        prefix: 'AVG_STRING',
        format: 'HOUR_MINUTE_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'DOMAIN_Y_180_UP_DOWN',
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_DURATION:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'pink-300',
        noneDataColor: 'pink-100',
        colorHover: '#CA5A74',
        noneDataColorHover: '#CCAAB0',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '28_DAYS',
        dataType: MonitoringDataType.WORKLOAD_DURATION,
        nullDataKey: 'chart.data.none',
        chipType: ChipType.TIME,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'duration',
            interpretationFormat: 'AVERAGE_ROUND_0',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadDuration,
            },
          },
          y: {
            orientation: 'LEFT',
            tickFormat: 'HOUR_MINUTE_FORMAT',
            tickValues: 'Y_TICK_VALUES_180_UP_DOWN',
          },
        },
      },
      getAverageDataset({
        field: 'duration',
        precision: 0,
        prefix: 'AVG_STRING',
        format: 'HOUR_MINUTE_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'DOMAIN_Y_180_UP_DOWN',
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_SATISFACTION:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'purple-300',
        noneDataColor: 'purple-100',
        colorHover: '#CB78CD',
        colorScale: ['purple-300', 'purple-600', 'purple-700', 'purple-900'],
        noneDataColorHover: '#CCB2CF',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.WORKLOAD_SATISFACTION,
        chipType: ChipType.TEN,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'satisfaction',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
          reasonForNoWorkout: {
            field: 'reasonForNoWorkout',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadSatisfaction,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'satisfaction',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_SATISFACTION:1_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_7DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'purple-300',
        noneDataColor: 'purple-100',
        colorHover: '#CB78CD',
        noneDataColorHover: '#CCB2CF',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '7_DAYS',
        dataType: MonitoringDataType.WORKLOAD_SATISFACTION,
        nullDataKey: 'chart.data.none',
        chipType: ChipType.TEN,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'satisfaction',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadSatisfaction,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'satisfaction',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  'TEAM:WORKLOAD_SATISFACTION:4_WEEKS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_28DAY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'purple-300',
        noneDataColor: 'purple-100',
        colorHover: '#CB78CD',
        noneDataColorHover: '#CCB2CF',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '28_DAYS',
        dataType: MonitoringDataType.WORKLOAD_SATISFACTION,
        nullDataKey: 'chart.data.none',
        chipType: ChipType.TEN,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'satisfaction',
            interpretationFormat: 'AVERAGE_ROUND_1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.WORKLOAD,
              dataType: UserDataType.workloadSatisfaction,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'satisfaction',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  // monitoring/acwr
  'TEAM:WORKLOAD_ACXR:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_ACXR',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'pink-300',
        colorHover: 'pink-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'acxr',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.ACWR,
              dataType: UserDataType.worklaodACWR,
            },
          },
          y: {
            orientation: 'LEFT',
            tickCount: 5,
            tickFormat: '2_POINT_5_PLUS',
          },
        },
        yFormat: '2.5_MAX',
      },
      getCautionTrainingAreaDataset('id'),
      getBestTrainingAreaDataset('id'),
      getWarningTraningAreaDataset('id'),
      getDangerTrainingAreaDataset('id'),
      getAverageDataset({
        field: 'acxr',
        precision: 2,
        prefix: 'AVG_STRING',
        isAcwrLabel: true,
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 2.5,
      },
      xPaddingRatio: 0.7,
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.WORKLOAD_ACXR,
            color: theme.colors['pink-300'],
            chipType: ChipType.NUMBER,
            interpretPath: 'WORKLOAD_ACXR',
          },
        ],
      },
    },
  },
  'TEAM:WORKLOAD_AX_CX:1_DAYS:USER_AVATAR:BAR_BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_ACXR',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        offset: 20,
        color: 'orange-300',
        colorHover: 'orange-500',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'ax',
            format: 'FLOOR',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.ACWR,
              dataType: UserDataType.worklaodAX,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_MAX_PLUS_400_TICK_VALUES_5',
          },
        },
        legend: [
          {
            label: 'WORKLOAD_AX',
            chartType: 'BAR',
            chartColor: theme.colors['orange-300'],
            visible: true,
          },
        ],
      },
      {
        type: 'BAR',
        width: 14,
        color: 'indigo-300',
        colorHover: 'indigo-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'cx',
            format: 'FLOOR',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          workloadList: {
            field: 'workloadList',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            config: {
              tabId: PersonalDataTabId.ACWR,
              dataType: UserDataType.worklaodAX,
            },
          },
        },
        legend: [
          {
            label: 'WORKLOAD_CX',
            chartType: 'BAR',
            chartColor: theme.colors['indigo-300'],
            visible: true,
          },
        ],
      },
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_PLUS_400_ROUNDED_UP',
      },
      dataIndex: [0, 1],
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.WORKLOAD_AX,
            color: theme.colors['orange-300'],
            chipType: ChipType.NUMBER,
            interpretPath: 'WORKLOAD_AX',
          },
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.WORKLOAD_CX,
            color: theme.colors['indigo-300'],
            chipType: ChipType.NUMBER,
            interpretPath: 'WORKLOAD_CX',
          },
        ],
      },
    },
  },
  'TEAM:WORKLOAD_AXD:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'WORKLOAD_ACXR',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'yellow-400',
        colorHover: 'yellow-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'axd',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.ACWR,
              dataType: UserDataType.workloadAXD,
            },
          },
          y: {
            orientation: 'LEFT',
            tickCount: 5,
            tickFormat: '2_POINT_5_PLUS',
          },
        },
        yFormat: '2.5_MAX',
      },
      getAverageDataset({
        field: 'axd',
        precision: 2,
        prefix: 'AVG_STRING',
        isAcwrLabel: true,
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 2.5,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.WORKLOAD_AXD,
            color: theme.colors['yellow-400'],
            chipType: ChipType.NUMBER,
            interpretPath: 'WORKLOAD_AXD',
          },
        ],
      },
    },
  },
  // monitoring/injury
  'TEAM:INJURY_1:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'SORENESS',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'injury-level-1',
        colorHover: 'injury-level-1-hover',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'level1',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          domainY: {
            field: 'count',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.SORENESS,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_MAX_PLUS_4_TICK_VALUES_5',
          },
        },
      },
      getAverageDataset({
        field: 'level1',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'COUNT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SORENESS_1,
            color: theme.colors['injury-level-1'],
          },
        ],
      },
    },
  },
  'TEAM:INJURY_2:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'SORENESS',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'injury-level-2',
        colorHover: 'injury-level-2-hover',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'level2',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          domainY: {
            field: 'count',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.SORENESS,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_MAX_PLUS_4_TICK_VALUES_5',
          },
        },
      },
      getAverageDataset({
        field: 'level2',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'COUNT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SORENESS_2,
            color: theme.colors['injury-level-2-hover'],
          },
        ],
      },
    },
  },
  'TEAM:INJURY_3:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'SORENESS',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'injury-level-3',
        colorHover: 'injury-level-3-hover',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'level3',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          domainY: {
            field: 'count',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.SORENESS,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_MAX_PLUS_4_TICK_VALUES_5',
          },
        },
      },
      getAverageDataset({
        field: 'level3',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'COUNT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SORENESS_3,
            color: theme.colors['injury-level-3-hover'],
          },
        ],
      },
    },
  },
  'TEAM:INJURY_4:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'SORENESS',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'injury-level-4',
        colorHover: 'injury-level-4-hover',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'level4',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          domainY: {
            field: 'count',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.SORENESS,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_MAX_PLUS_4_TICK_VALUES_5',
          },
        },
      },
      getAverageDataset({
        field: 'level4',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'COUNT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SORENESS_4,
            color: theme.colors['injury-level-4-hover'],
          },
        ],
      },
    },
  },
  'TEAM:INJURY_5:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'SORENESS',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'injury-level-5',
        colorHover: 'injury-level-5-hover',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'level5',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
          domainY: {
            field: 'count',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              tabId: PersonalDataTabId.SORENESS,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_MAX_PLUS_4_TICK_VALUES_5',
          },
        },
      },
      getAverageDataset({
        field: 'level5',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'COUNT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_PLUS_4_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SORENESS_5,
            color: theme.colors['injury-level-5-hover'],
          },
        ],
      },
    },
  },
  // monitoring/body
  'TEAM:HEIGHT:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'BODY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'violet-300',
        colorHover: 'violet-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'height',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            config: {
              tabId: PersonalDataTabId.BODY,
              dataType: UserDataType.height,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_MAX_PLUS_5_OR_200_TICK_VALUES_6',
            tickFormat: 'CM_FORMAT',
          },
        },
      },
      getAverageDataset({
        field: 'height',
        precision: 1,
        format: 'CM_FORMAT',
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 'Y_MIN_MINUS_5_ROUNDED_DOWN',
        end: 'Y_MAX_PLUS_5_DEFAULT_200_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.HEIGHT,
            color: theme.colors['violet-300'],
            chipType: ChipType.CM,
          },
        ],
      },
    },
  },
  'TEAM:WEIGHT:1_DAYS:USER_AVATAR:BAR': {
    query: {
      name: 'TEAM_MONITORING',
      variables: {
        dataName: 'BODY',
      },
    },
    parser: 'TEAM_MONITORING',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'green-300',
        colorHover: 'green-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        parser: {
          x: {
            field: 'id',
          },
          y: {
            field: 'weight',
          },
          id: {
            field: 'id',
          },
          name: {
            field: 'name',
          },
          pictureUrl: {
            field: 'pictureUrl',
          },
          time: {
            field: 'time',
          },
        },
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            config: {
              tabId: PersonalDataTabId.BODY,
              dataType: UserDataType.weight,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_MAX_PLUS_5_OR_100_TICK_VALUES_6',
            tickFormat: 'KG_FORMAT',
          },
        },
      },
      getAverageDataset({
        field: 'weight',
        precision: 1,
        format: 'KG_FORMAT',
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 'Y_MIN_MINUS_5_ROUNDED_DOWN',
        end: 'Y_MAX_PLUS_5_DEFAULT_100_ROUNDED_UP',
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            dataType: MonitoringDataType.WEIGHT,
            color: theme.colors['green-500'],
            chipType: ChipType.KG,
          },
        ],
      },
    },
  },
}
