import { useEffect, useMemo, useState } from 'react'

import { LazyQueryHookOptions } from '@apollo/client'
import { ObservableQuery } from '@apollo/client/core/ObservableQuery'
import { Instance, types } from 'mobx-state-tree'

import { UserDataStride } from '@plco-pro/graphqls/react.generated'
import { CHART_SYSTEM_MAP_KEY } from '@plco-pro/maps/chart-system/chart-system'
import { get30DaysAgo } from '@plco-pro/utils/date-utils'

import type { QuerySystemReturnType } from '@plco-pro/hooks/query-system'
import type { StoreSystemMapKey } from '@plco-pro/maps/store-system'

export const DEFAULT_PAGE = 0
export const DEFAULT_PAGE_SIZE = 15
export const DEFAULT_HAS_PREV = false
export const DEFAULT_HAS_NEXT = true

// chart query store
export type DataItem = {
  [key in string]: any
}
export type Data = DataItem[]

export const ChartQueryStore = types
  .model('ChartQueryStore', {
    // query returns
    data: types.optional(types.frozen<Data>(), []),
    loading: types.optional(types.boolean, false),
    // query params
    variables: types.optional(types.frozen<any>(), {}),
    options: types.optional(
      types.frozen<Omit<LazyQueryHookOptions, 'nextFetchPolicy' | 'defaultOptions'>>(),
      {},
    ),
    date: types.optional(types.Date, new Date()),
    startDate: types.optional(types.Date, get30DaysAgo()),
    endDate: types.optional(types.Date, new Date()),
    stride: types.optional(types.enumeration(Object.values(UserDataStride)), UserDataStride.DAY),
  })
  .actions((self) => {
    let refetch: ObservableQuery['refetch'] | null | (() => void) = null
    return {
      // query returns
      setData(value?: QuerySystemReturnType['data']) {
        self.data = value || []
      },
      setLoading(value: boolean) {
        self.loading = value
      },
      setRefetch(value: ObservableQuery['refetch'] | (() => void)) {
        refetch = value
      },
      getRefetch() {
        return refetch
      },
      // query params
      setVariables(value: any) {
        self.variables = value
      },
      setOptions(value: Omit<LazyQueryHookOptions, 'variables'>) {
        self.options = value
      },
      setDate(value: Date) {
        self.date = value
      },
      setDateRange(value: [Date, Date]) {
        const [startDate, endDate] = value
        self.startDate = startDate
        self.endDate = endDate
      },
      setStride(value: UserDataStride) {
        self.stride = value
      },
    }
  })

// chart data store
export type ChartParsedDataItem = {
  [key in string]: any
}

export type ChartParsedData = ChartParsedDataItem[][]

const ChartDataStore = (() => {
  return types
    .model('ChartDataStore', {
      configKey: types.optional(
        types.enumeration([...CHART_SYSTEM_MAP_KEY]),
        CHART_SYSTEM_MAP_KEY[0],
      ),
      parsedData: types.optional(types.frozen<ChartParsedData>(), []),
    })
    .actions((self) => {
      return {
        setConfigKey(value: (typeof CHART_SYSTEM_MAP_KEY)[number]) {
          self.configKey = value
        },
        setParsedData(value?: ChartParsedData) {
          self.parsedData = value || []
        },
      }
    })
})()

// chart pagination store
export const ChartPaginationStore = types
  .model('ChartPaginationStore', {
    page: types.optional(types.number, DEFAULT_PAGE),
    pageSize: types.optional(types.number, DEFAULT_PAGE_SIZE),
    hasPrev: types.optional(types.boolean, DEFAULT_HAS_PREV),
    hasNext: types.optional(types.boolean, DEFAULT_HAS_NEXT),
  })
  .actions((self) => {
    return {
      setPage(value: number) {
        self.page = value
      },
      setPageSize(value: number) {
        self.pageSize = value
      },
      setHasPrev(value: boolean) {
        self.hasPrev = value
      },
      setHasNext(value: boolean) {
        self.hasNext = value
      },
      resetPagination() {
        self.page = DEFAULT_PAGE
        self.pageSize = DEFAULT_PAGE_SIZE
      },
    }
  })

// composed types
export const QueryChartPaginationStore = types.compose(
  'QueryChartPaginationStore',
  ChartQueryStore,
  ChartDataStore,
  ChartPaginationStore,
)
export type QueryChartPaginationStore = Instance<typeof QueryChartPaginationStore>

export const QueryChartStore = types.compose('QueryChartStore', ChartQueryStore, ChartDataStore)
export type QueryChartStore = Instance<typeof QueryChartStore>

// conditional type
export type StoreSystemTypeName = StoreSystemMapKey
export type StoreSystemObjectType<T> = T extends 'QUERY_CHART_PAGINATION'
  ? QueryChartPaginationStore
  : T extends 'QUERY_CHART'
  ? QueryChartStore
  : never

export const useStoreSystem = <T extends StoreSystemTypeName>(
  configKey: T,
): { initialized: boolean; store: StoreSystemObjectType<T> | undefined } => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [store, setStore] = useState<StoreSystemObjectType<T> | undefined>()

  useEffect(() => {
    if (configKey === 'QUERY_CHART_PAGINATION') {
      // flag as initialized
      setInitialized(true)
      setStore(QueryChartPaginationStore.create() as StoreSystemObjectType<T>)
    } else if (configKey === 'QUERY_CHART') {
      // flag as initialized
      setInitialized(true)
      setStore(QueryChartStore.create() as StoreSystemObjectType<T>)
    } else {
      throw new Error('store-system: invalid config')
    }
  }, [configKey])

  return useMemo(
    () => ({
      initialized,
      store,
    }),
    [initialized, store],
  )
}
