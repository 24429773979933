import { TRegisterViewerFragment } from '@plco-pro/graphqls/react.generated'

export const isRegister = (viewer: TRegisterViewerFragment): boolean => {
  if (!viewer) {
    return false
  }

  const { name, gender, birthdate, phoneNumber, nationality, priorSportsCategory } = viewer

  return Boolean(name && gender && birthdate && nationality && priorSportsCategory && phoneNumber)
}
