import React, {
  ChangeEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import styled from '@emotion/styled'
import { variant, VariantArgs } from 'styled-system'
import { InputProps as ThemeUIInputProps, ResponsiveStyleValue } from 'theme-ui'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Icon } from '@plco-pro/components/atoms/icon'
import { Input } from '@plco-pro/components/atoms/input'
import { useResponsive } from '@plco-pro/hooks/responsive'

const SEARCH_ICON_SIZE = 24

export type InputSearchSize = 'sm' | 'md'

export type InputSearchProps = Omit<ThemeUIInputProps, 'onChange' | 'value' | 'size'> & {
  value: string
  onChange?: (value: string) => void
  type?: string
  size?: ResponsiveStyleValue<InputSearchSize>
  placeholder?: string
  error?: boolean
  onKeyDownEnter?: VoidFunction
  onClickSearch?: VoidFunction
}

export type StyledThemeUIInputSearchProps = Omit<ThemeUIInputProps, 'size'> & {
  size?: InputSearchSize
}

const size = variant({
  prop: 'size',
  variants: {
    sm: {
      height: '40px',
      borderRadius: '20px',
      fontSize: '16px',
    },
    md: {
      height: '48px',
      borderRadius: '24px',
      fontSize: '16px',
    },
  },
} as VariantArgs)

const StyledThemeUIInputSearch = styled(Input)<StyledThemeUIInputSearchProps>(size)

export const InputSearch: React.FunctionComponent<InputSearchProps> = ({
  value,
  onChange = () => {},
  onKeyDownEnter,
  onClickSearch,
  type,
  placeholder,
  error,
  size = 'md',
  ...rest
}) => {
  const previousValue = useRef(value)

  const getResponsiveProp = useResponsive()

  const [isFocused, setIsFocused] = useState(false)

  const onMouseDownClear: MouseEventHandler<HTMLInputElement> = useCallback((event) => {
    event.preventDefault()
  }, [])

  const onClickClear: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.stopPropagation()
      onChange('')
    },
    [onChange],
  )

  const onKeyDown: InputSearchProps['onKeyDown'] = (e) => {
    if (e.key === 'Enter') {
      onKeyDownEnter?.()
    }
  }

  const responsiveSize = useMemo(() => getResponsiveProp(size), [size, getResponsiveProp])

  useEffect(() => {
    if (previousValue.current === value) {
      return
    }

    if (!value.length) {
      onClickSearch?.()
    }
  }, [value, onClickSearch])

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return (
    <Flex
      sx={{
        width: '100%',
        position: 'relative',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box sx={{ position: 'absolute', left: 2 }} onClick={onClickSearch}>
        <Icon src={'/images/search.svg'} sx={{ color: 'icon-subtle' }} size={SEARCH_ICON_SIZE} />
      </Box>
      <StyledThemeUIInputSearch
        size={responsiveSize as any}
        error={error}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={onKeyDown}
        type={type}
        placeholder={placeholder}
        sx={{
          width: '100%',
          pl: `${16 + SEARCH_ICON_SIZE + 8}px`,
        }}
        {...rest}
      />
      {isFocused && value && (
        <Box
          tabIndex={0}
          onMouseDown={onMouseDownClear}
          onClick={onClickClear}
          sx={{
            position: 'absolute',
            right: 2,
            borderRadius: 50,
            p: '4px',
            cursor: 'pointer',
            ':hover': {
              bg: 'grey-100',
            },
            ':active': {
              bg: 'grey-200',
            },
          }}
        >
          <Icon src={'/images/ic-close.svg'} size={16} color={'grey-400'} />
        </Box>
      )}
    </Flex>
  )
}
