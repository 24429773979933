import { dataInterpreter } from '@common/utils/libs'

import { WorkloadKey } from '@plco-pro/components/molecules/chart-label-workload-history'
import { SportsRawWorkload } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMappingByDataType } from '@plco-pro/hooks/mapping-by-data-type'
import { useMoment } from '@plco-pro/hooks/moment'

import { useViewer } from './viewer/viewer'

const WORKLOAD_KEY_MAP = {
  WORKLOAD: 'load',
  WORKLOAD_INTENSITY: 'intensity',
  WORKLOAD_DURATION: 'duration',
  WORKLOAD_SATISFACTION: 'satisfaction',
}

export const useWorkloadParser = () => {
  const { timeDurationMap: timeTranslate } = useMappingByDataType()
  const { formatMessage: f } = useI18n()
  const moment = useMoment()
  const { currentActiveTeam } = useViewer()

  const getWorkloadLabel = (dataType: WorkloadKey, workloadItem: SportsRawWorkload) => {
    const workloadKey = WORKLOAD_KEY_MAP[dataType] as keyof SportsRawWorkload
    const workloadValue = workloadItem[workloadKey]

    const interpretedDuration = dataInterpreter.get('WORKLOAD_DURATION', workloadItem.duration)
    const interpretedValue = dataInterpreter.get(dataType, workloadValue)
    const label = f({ id: `DATA.${interpretedValue.interpretation?.label}` })

    if (!interpretedDuration.value) return

    const workloadDuration = timeTranslate(interpretedDuration.value)

    const showTitle =
      workloadItem.event &&
      workloadItem.event.event.hideExpectedIntensityFromPlayers &&
      currentActiveTeam?.id === workloadItem.event.event.team?.id
    const workloadTitle = showTitle
      ? workloadItem.event?.event.title
      : f({ id: 'monitoring.chart.tooltip.another-team.title' })

    if (dataType === 'WORKLOAD_DURATION') {
      const startAt = moment(workloadItem.time).format('HH:mm')
      const endAt = moment(workloadItem.time).add(interpretedValue.value, 'minutes').format('HH:mm')

      return {
        title: workloadTitle,
        prevLabel: `${startAt}~${endAt}`,
        afterLabel: `${workloadDuration}`,
      }
    } else {
      return {
        title: workloadTitle,
        prevLabel: `${workloadDuration}`,
        afterLabel: `${label}(${interpretedValue.value})`,
      }
    }
  }

  return { getWorkloadLabel }
}
