import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'

export type ScalableSvgProps = Pick<FlexProps, 'className' | 'color' | 'opacity'> & {
  src: any // source of the svg file
  width?: number | string // desired width "50%", 0.5, or 300
  height?: number | string // desired height "50%", 0.5, or 300
  customColor?: {
    normal: string
    pressed: string
    hovered: string
  }
}

export function calculateAspectRatio({
  sourceLayout,
  parentLayout,
  width,
  height,
}: {
  sourceLayout: { w: number; h: number }
  parentLayout: { w: number; h: number }
  width?: number | string
  height?: number | string
}) {
  if (!sourceLayout.w) {
    return undefined
  }

  const regex = /(\d*)%/
  const sourceRatio = sourceLayout.h / Math.max(sourceLayout.w, 1)
  let w = Math.min(sourceLayout.w, parentLayout.w)
  let { h } = sourceLayout

  if (typeof width !== 'undefined') {
    // width is given
    if (typeof width === 'string') {
      // parse width value
      const match = width.match(regex)
      if (match === null) {
        throw new Error("Malformed width string, must be of the form 'value%'")
      }
      w = Math.round(parentLayout.w * (parseInt(match[1], 10) / 100))
    } else if (typeof width === 'number') {
      w = width <= 1 ? Math.round(parentLayout.w * width) : width
    }
    h = Math.round(w * sourceRatio)
  } else if (typeof height !== 'undefined') {
    // height is given
    if (typeof height === 'string') {
      // parse width value
      const match = height.match(regex)
      if (match === null) {
        throw new Error("Malformed width string, must be of the form 'value%'")
      }
      h = Math.round(parentLayout.h * (parseInt(match[1], 10) / 100))
    } else if (typeof height === 'number') {
      h = height <= 1 ? Math.round(parentLayout.h * height) : height
    }
    w = Math.round(h * Math.max(1, 1 / sourceRatio))
  }

  return { width: w, height: h }
}

export const ScalableSvg: React.FunctionComponent<ScalableSvgProps> = ({
  src,
  width,
  height,
  opacity,
  color,
  customColor,
  className,
}) => {
  const [parentLayout, setParentLayout] = useState({ w: 0, h: 0 })

  // update parentLayout on layout change
  const layoutRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const w = layoutRef?.current?.clientWidth || 0
    const h = layoutRef?.current?.clientHeight || 0

    setParentLayout({ w, h })
  }, [])

  const handleBeforeInjection = useCallback(
    (svg) => {
      if (!svg) return

      // read viewBox from svg TagElement
      const viewBox = svg.attributes.getNamedItem('viewBox')?.value
      if (typeof viewBox !== 'string') return

      const viewBoxStrings = viewBox.split(' ')
      const x = parseInt(viewBoxStrings[0], 10)
      const y = parseInt(viewBoxStrings[1], 10)
      const x2 = parseInt(viewBoxStrings[2], 10)
      const y2 = parseInt(viewBoxStrings[3], 10)
      const sourceLayout = {
        x,
        y,
        w: x2 - x,
        h: y2 - y,
      }

      // calculate layout that maintains aspect ratio
      const layout = calculateAspectRatio({
        parentLayout,
        sourceLayout,
        width,
        height,
      })

      // update svg style
      svg.setAttribute('width', layout?.width)
      svg.setAttribute('height', layout?.height)
    },
    [height, parentLayout, width],
  )

  return (
    <Flex
      ref={layoutRef}
      className={className}
      color={customColor?.normal || color || 'none'}
      sx={{
        '&:hover': {
          color: customColor?.hovered,
        },
        '&:active': {
          color: customColor?.pressed,
        },
      }}
      opacity={opacity != undefined ? opacity : 'none'}
    >
      <ReactSVG
        src={src}
        beforeInjection={handleBeforeInjection}
        style={{
          color: 'inherit',
        }}
      />
    </Flex>
  )
}
