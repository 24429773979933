export const blog = {
  en: {
    'blog.header.title': 'Blog',
    'blog.header.search.placeholder': 'Search',

    'blog.posted-at': '{days, plural, =1 {{days} day ago} other {{days} days ago}}',
    'blog.reading-time': '⏱ {minute}min read',

    'blog.onboarding-section.title': '👋 Plco 100% Utilization Guide',
    'blog.onboarding-section.subtitle': '7 things you must read during the 14 days of free trial',
    'blog.onboarding-section.view-all': 'View all',

    'blog.featured-section.title': '📝 Recommended articles',
    'blog.featured-section.subtitle': 'Check out fun sports stories recommended by Plco',

    'blog.tag-section.title': '🔖 All tags',

    'blog.article.featured.title': '📄 Related articles',
    'blog.article.featured.subtitle': 'Check out articles on the related topics',
    'blog.article.featured.empty': "Ooops! we don't have any recommendations yet...",

    'blog.banner.onboarding.title': 'Plco 100% Utilization Guide',
    'blog.banner.onboarding.subtitle': 'Missed any Onboarding content?',
    'blog.banner.FAQ.title': 'Feature Manual & FAQ',
    'blog.banner.FAQ.subtitle': 'Learn more about Plco',

    //  Search
    'blog.search.title': '🔍 Search results for ‘{q}’',
    'blog.search.count':
      '{count, plural, =0 {There are no results. Please try again!} =1 {{count} Article} other {{count} Articles}}',
    'blog.search.empty': 'There are no results. Please try again!',

    //  Onboarding
    'blog.onboarding.title': 'Plco 100% Utilization Guide',

    //  Onboarding article
    'blog.onboarding.article.next-article': 'Next article',
    'blog.onboarding.article.next-article.mdAndDown': 'Next article',
    'blog.onboarding.article.prev-article': 'Previous article',
    'blog.onboarding.article.prev-article.mdAndDown': 'Previous article',

    'blog.error.internal-server-error.title': 'A problem occurred while processing the request.',
    'blog.error.internal-server-error.subtitle': 'Press the ‘Refresh’ button to try again.',
  },
  ko: {
    'blog.header.title': '블로그',
    'blog.header.search.placeholder': '검색',

    'blog.posted-at': '{days}일전',
    'blog.reading-time': '⏱ {minute}분 분량',

    'blog.onboarding-section.title': '👋 플코 100% 활용 가이드',
    'blog.onboarding-section.subtitle': '무료체험 14일동안 꼭 읽어보아야할 7가지',
    'blog.onboarding-section.view-all': '전체보기',

    'blog.featured-section.title': '📝 플코 추천 콘텐츠',
    'blog.featured-section.subtitle': '플코가 추천하는 재밌는 스포츠 이야기를 만나보세요',

    'blog.tag-section.title': '🔖 전체 태그',

    'blog.article.featured.title': '📄 함께 읽어보면 좋은 글',
    'blog.article.featured.subtitle': '읽어보신 글과 연관된 주제를 가진 글을 소개해요',
    'blog.article.featured.empty': '앗! 아직 추천만한 글이 없어요...',

    'blog.banner.onboarding.title': '플코 100% 활용 가이드',
    'blog.banner.onboarding.subtitle': '놓친 온보딩 콘텐츠가 있나요?',
    'blog.banner.FAQ.title': '기능 사용법 & FAQ',
    'blog.banner.FAQ.subtitle': '플코 더 자세히 알아보기',

    //  Search
    'blog.search.title': '🔍 ‘{q}’에 대한 검색 결과',
    'blog.search.count':
      '{count, plural, =0 {검색 결과가 없어요. 다시 확인해주세요!} other {{count} 개의 글}}',
    'blog.search.empty': '검색 결과가 없어요. 다시 확인해주세요!',

    //  Onboarding
    'blog.onboarding.title': '플코 100% 활용 가이드',

    //  Onboarding article
    'blog.onboarding.article.next-article.mdAndDown': '다음글',
    'blog.onboarding.article.next-article': '다음글 읽어보기',

    'blog.onboarding.article.prev-article.mdAndDown': '이전글',
    'blog.onboarding.article.prev-article': '이전글 읽어보기',

    'blog.error.internal-server-error.title': '페이지를 처리하는 도중 문제가 발생했습니다.',
    'blog.error.internal-server-error.subtitle': '‘새로고침’ 버튼을 눌러 다시 시도해 주세요.',
  },
  ja: {
    'blog.header.title': 'ブログ', // "블로그",
    'blog.header.search.placeholder': '検索', // "검색",

    'blog.posted-at': '{days}日前', // "{days}일전",
    'blog.reading-time': '⏱ {minute}分 分量', // "⏱ {minute}분 분량",

    'blog.onboarding-section.title': '👋 プルコ100%活用ガイド', // "👋 플코 100% 활용 가이드",
    'blog.onboarding-section.subtitle': '無料体験14日間必ず読まなければならない7つのこと', // "무료체험 14일동안 꼭 읽어보아야할 7가지",
    'blog.onboarding-section.view-all': '全体を見る', // "전체보기",

    'blog.featured-section.title': '📝 プルコおすすめコンテンツ', // "📝 플코 추천 콘텐츠",
    'blog.featured-section.subtitle': 'プルコがおすすめする面白いスポーツの話を聞いてみてください', // "플코가 추천하는 재밌는 스포츠 이야기를 만나보세요",

    'blog.tag-section.title': '全体タグ', // "🔖 전체 태그",

    'blog.article.featured.title': '📄 一緒に読んでみると良い文', // "📄 함께 읽어보면 좋은 글",
    'blog.article.featured.subtitle': 'お読みになった文章と関連したテーマを持った文章を紹介します', // "읽어보신 글과 연관된 주제를 가진 글을 소개해요",
    'blog.article.featured.empty': 'あっ！まだおすすめの文章がありません...', // "앗! 아직 추천만한 글이 없어요...",

    'blog.banner.onboarding.title': 'プルコ100%活用ガイド', // "플코 100% 활용 가이드",
    'blog.banner.onboarding.subtitle': '見逃したオンボーディングコンテンツはありますか？', // "놓친 온보딩 콘텐츠가 있나요?",
    'blog.banner.FAQ.title': '機能の使い方 & FAQ', // "기능 사용법 & FAQ",
    'blog.banner.FAQ.subtitle': 'プルコをもっと詳しく調べる', // "플코 더 자세히 알아보기",

    //  Search
    'blog.search.title': "🔍 '{q}' に対する検索結果", // "🔍 ‘{q}’에 대한 검색 결과",
    'blog.search.count':
      '{count、plural、=0{検索結果がありません. もう一度確認してください！} other{{count} 個の文}}', // "{count, plural, =0 {검색 결과가 없어요. 다시 확인해주세요!} other {{count} 개의 글}}",
    'blog.search.empty': '検索結果がありません。 もう一度確認してください！', // "검색 결과가 없어요. 다시 확인해주세요!",

    //  Onboarding
    'blog.onboarding.title': 'プルコ100%活用ガイド', // "플코 100% 활용 가이드",

    //  Onboarding article
    'blog.onboarding.article.next-article.mdAndDown': '次の文', // "다음글",
    'blog.onboarding.article.next-article': '次の文章を読んでみる', // "다음글 읽어보기",

    'blog.onboarding.article.prev-article.mdAndDown': '以前の文', // "이전글",
    'blog.onboarding.article.prev-article': '前文を読む', // "이전글 읽어보기",

    'blog.error.internal-server-error.title': 'ページの処理中に問題が発生しました.', // "페이지를 처리하는 도중 문제가 발생했습니다.",
    'blog.error.internal-server-error.subtitle':
      "'最新の情報に更新' ボタンを押して、もう一度お試しください.", // "‘새로고침’ 버튼을 눌러 다시 시도해 주세요.",
  },
}
