import { __ENV__ } from '@plco-pro/env'

export default class Iamport {
  constructor() {
    this.loadScript()
  }

  loadScript() {
    const w = window
    if (w.Iamport) {
      return
    }
    var iap = function () {
      iap.c(arguments)
    }
    iap.q = []
    iap.c = function (args) {
      iap.q.push(args)
    }
    w.Iamport = iap
    function load() {
      if (w.IamportInitialized) {
        return
      }
      w.IamportInitialized = true
      const script = document.getElementsByTagName('script')[0]

      const iamport = document.createElement('script')
      iamport.type = 'text/javascript'
      iamport.src = 'https://cdn.iamport.kr/js/iamport.payment-1.1.8.js'
      script.parentNode.insertBefore(iamport, script)

      const jquery = document.createElement('script')
      jquery.type = 'text/javascript'
      jquery.src = 'https://code.jquery.com/jquery-1.12.4.min.js'
      script.parentNode.insertBefore(jquery, script)
    }
    if (document.readyState === 'complete') {
      load()
    } else if (window.attachEvent) {
      window.attachEvent('onload', load)
    } else {
      window.addEventListener('DOMContentLoaded', load, false)
      window.addEventListener('load', load, false)
    }
  }

  getMerchantId() {
    switch (__ENV__) {
      case 'production':
        return {
          merchantId: require('@plco-pro-config/production.iamport.json').merchantId,
        }
      case 'stage':
        return {
          merchantId: require('@plco-pro-config/stage.iamport.json').merchantId,
        }
      default:
        return {
          merchantId: require('@plco-pro-config/development.iamport.json').merchantId,
        }
    }
  }

  requestPay(data, callback) {
    const merchantId = this.getMerchantId().merchantId
    IMP.init(merchantId)
    IMP.request_pay(data, callback)
  }
}
