import React, { useCallback } from 'react'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Image } from '@plco-pro/components/atoms/image'
import { Text } from '@plco-pro/components/atoms/text'
import { SportsBodyPart } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'

export enum MappingDataType {
  NUMBER = 'number',
  DURATION = 'duration',
  ARRAY_STRING = 'arrayString',
  FREQUENCY = 'frequency',
  AVG = 'avg',
  PERCENTAGE = 'percentage',
}

export const useMappingByDataType = () => {
  const { formatMessage: f } = useI18n()

  const numberMap = useCallback(
    (unit?: string | null) => (value: string) => {
      const translateUnit = unit ? f({ id: unit.toUpperCase() }, { [unit]: value }) : ''
      return `${value}${translateUnit}`
    },
    [f],
  )

  const averageMap = useCallback(
    (unit?: string | null) => (value: string) => {
      const translateUnit = unit ? f({ id: unit.toUpperCase() }) : ''
      return `${translateUnit} ${value}`
    },
    [f],
  )

  const timeDurationMap = useCallback(
    (value: string | number) => {
      if (Number.isNaN(Number(value))) {
        return value
      }
      const durationMinutes = Number(value)
      let duration = ''
      const time = Math.floor(durationMinutes / 60)
      const h = time > 0 ? time : '0'
      const minutes = durationMinutes - Math.floor(durationMinutes / 60) * 60
      const m = minutes > 0 ? minutes : ''

      if (h > 0) {
        duration += `${h + f({ id: 'H' })} `
      }

      if (m > 0) {
        duration += m + f({ id: 'M' })
      }

      return duration
    },
    [f],
  )

  const injuryMap = useCallback((value: string) => {
    const bodyParts = JSON.parse(value) as SportsBodyPart[]

    if (bodyParts.length > 1) {
      return `${bodyParts[0].value} +${bodyParts.length - 1}`
    }
    return bodyParts[0].value
  }, [])

  const percentMap = useCallback(
    (type?: string) => (value: string) => {
      if (type === 'increase') {
        return (
          <Flex>
            <Text>
              {value}
              {'%'}
            </Text>
            <Image src={'/images/arrow-up.svg'} />
          </Flex>
        )
      }
      return `${value}%`
    },
    [],
  )

  const mappingByType = useCallback(
    (type?: MappingDataType | string | null, unit?: string | null) => {
      switch (type) {
        case MappingDataType.NUMBER:
          return numberMap(unit)
        case MappingDataType.DURATION:
          return timeDurationMap
        case MappingDataType.ARRAY_STRING:
          return injuryMap
        case MappingDataType.FREQUENCY:
          return numberMap(unit)
        case MappingDataType.AVG:
          return averageMap(unit)
        case MappingDataType.PERCENTAGE:
          return percentMap(type)
        default:
          return numberMap()
      }
    },
    [numberMap, timeDurationMap, injuryMap, averageMap, percentMap],
  )

  return {
    numberMap,
    timeDurationMap,
    injuryMap,
    mappingByType,
  }
}
