import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { ChipWorkoutStatus } from '@plco-pro/components/molecules'
import { WorkoutType } from '@plco-pro/graphqls/react.generated'
import { Color } from '@plco-pro/maps/chart-system/chart-system.types'

export type Props = {
  color?: Color
  title?: string
  type: WorkoutType
  prevLabel: string
  afterLabel: string
}

export const WorkloadHistoryEventItem = ({ color, title, type, prevLabel, afterLabel }: Props) => {
  return (
    <Flex sx={{ width: '100%', flexDirection: 'column', gap: '4px' }}>
      <Flex sx={{ alignItems: 'center', gap: '6px' }}>
        <Box
          sx={{
            width: '8px',
            height: '8px',
            borderRadius: 3,
            bg: color,
          }}
        />
        <Flex sx={{ justifyContent: 'space-between', gap: 1, flex: 1 }}>
          <Text sx={{ maxWidth: '192px', fontWeight: 'bold' }} ellipsis>
            {title}
          </Text>
          <ChipWorkoutStatus type={type} />
        </Flex>
      </Flex>
      <Flex sx={{ gap: '2px', pl: '15px' }}>
        <Text sx={{ fontWeight: '500' }} variant={'p2'} appearance={'hint'}>
          {prevLabel}
        </Text>
        <Text sx={{ fontWeight: '500' }} variant={'p2'} appearance={'hint'}>
          {'・'}
        </Text>
        <Text sx={{ fontWeight: '500' }} variant={'p2'} appearance={'hint'}>
          {afterLabel}
        </Text>
      </Flex>
    </Flex>
  )
}
