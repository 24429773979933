import { GraphQLErrors } from '@apollo/client/errors'

import { routeToRenewalCoach } from '@plco-pro/utils'

import { useI18n } from './i18n'
import { useToast } from './toast'

export const useFeedbackError = () => {
  const { showToast } = useToast()
  const { formatMessage } = useI18n()

  const showFeedbackError = (graphQLErrors: GraphQLErrors, redirect = false) => {
    const statusCode = graphQLErrors[0].extensions.statusCode
    const errorCode = graphQLErrors[0].extensions.errorCode

    if (statusCode !== 400) {
      showToast(formatMessage({ id: `ERROR.${errorCode}` }), { status: 'error' })

      if (redirect) {
        routeToRenewalCoach()
      }

      return
    }

    showToast(formatMessage({ id: 'toast.modal-check-condition-reminder.error' }), {
      status: 'error',
    })
  }

  return showFeedbackError
}
