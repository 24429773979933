import React from 'react'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'

export type DropdownMenuTitleItemProps = {
  title?: string
  subText?: string
}

export const DropdownMenuTitleItem: React.FunctionComponent<DropdownMenuTitleItemProps> = ({
  title,
  subText,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '4px',
        px: 2,
        pt: 2,
        pb: '12px',
      }}
    >
      <Text variant={'s2'} sx={{ fontWeight: 'medium' }} ellipsis>
        {title}
      </Text>
      <Text sx={{ fontSize: '10px' }} color={'indigo-500'}>
        {subText}
      </Text>
    </Flex>
  )
}
