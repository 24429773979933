import { toJS } from 'mobx'
import { types } from 'mobx-state-tree'

import { breakpoints } from '@plco-pro/themes/main'
import { SIDE_NAVIGATION_WIDTH } from '@plco-pro/utils/constant'
import { get30DaysAgo } from '@plco-pro/utils/date-utils'
import { remToPx } from '@plco-pro/utils/rem-to-px'

export enum PersonalDataTabId {
  CONDITION = 'CONDITION',
  WORKLOAD = 'WORKLOAD',
  ACWR = 'WORKLOAD_ACXR',
  SORENESS = 'SORENESS',
  BODY = 'BODY',
}

export const PlayersStore = types
  .model('Players', {
    tabIdState: types.optional(
      types.maybeNull(types.enumeration(Object.values(PersonalDataTabId))),
      null,
    ),
    playerId: types.optional(types.maybeNull(types.string), null),
    isPlayerStarred: types.optional(types.maybeNull(types.boolean), null),
    date: types.optional(types.Date, new Date()),
    startDate: types.optional(types.Date, get30DaysAgo()),
    endDate: types.optional(types.Date, new Date()),
    contentWidth: types.optional(types.number, 0),
  })
  .actions((self) => {
    return {
      setTabId(value: PersonalDataTabId | null) {
        self.tabIdState = value
      },
      setPlayerId(value: string | null) {
        self.playerId = value
      },
      setIsPlayerStarred(value: boolean | null) {
        self.isPlayerStarred = value
      },
      setDate(value: Date) {
        self.date = value
      },
      setDateRange(value: [Date, Date]) {
        const [startDate, endDate] = value
        self.startDate = startDate
        self.endDate = endDate
      },
      setContentWidth(width = 0) {
        self.contentWidth = width
      },
    }
  })
  .views((self) => ({
    get dateRange() {
      return toJS([self.startDate, self.endDate])
    },
    get tabId() {
      return self.tabIdState || PersonalDataTabId.CONDITION
    },
    get isContentXlAndUp() {
      return self.contentWidth > remToPx(breakpoints.slice(-1)[0]) - SIDE_NAVIGATION_WIDTH
    },
  }))
