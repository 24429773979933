import React from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { CardChartPlayers } from '@plco-pro/components/organisms/card-chart-players'
import { useI18n } from '@plco-pro/hooks/i18n'
import { observer } from '@plco-pro/stores'

const CardContainerBox = (props: BoxProps) => (
  <Box sx={{ px: [1, 2, 2, 2], mt: [2, 4, 4, 4] }} {...props} />
)

export const TabContentItemPlayersBody = observer(() => {
  const { formatMessage: f } = useI18n()

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        mx: [-1, -2, null, null],
        mt: [-2, -4, null, null],
      }}
    >
      <Box sx={{ width: 'col12.12' }}>
        <CardContainerBox>
          <CardChartPlayers
            configKey="USER:WEIGHT:LINE"
            title={f({ id: 'players.body.chart-card.weight.title' })}
            isDateRange={true}
          />
        </CardContainerBox>
      </Box>

      <Box sx={{ width: 'col12.12' }}>
        <CardContainerBox>
          <CardChartPlayers
            configKey="USER:HEIGHT:LINE"
            title={f({ id: 'players.body.chart-card.height.title' })}
            isDateRange={true}
          />
        </CardContainerBox>
      </Box>
    </Flex>
  )
})
