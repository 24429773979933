import { blog } from '@plco-pro/i18ns/blog'
import { dashboard } from '@plco-pro/i18ns/dashboard'
import { error } from '@plco-pro/i18ns/error'
import { insight } from '@plco-pro/i18ns/insight'
import { notable } from '@plco-pro/i18ns/notable'
import { plan } from '@plco-pro/i18ns/plan'
import { players } from '@plco-pro/i18ns/players'
import { profile } from '@plco-pro/i18ns/profile'
import { register } from '@plco-pro/i18ns/register'
import { teamChart } from '@plco-pro/i18ns/team-chart'
import { teamCode } from '@plco-pro/i18ns/team-code'
import { teams } from '@plco-pro/i18ns/teams'
import { toast } from '@plco-pro/i18ns/toast'

export type I18nMessagePack = typeof messages

const messages = {
  language: 'English',

  ...register.en,
  ...dashboard.en,
  ...teamChart.en,
  ...error.en,
  ...insight.en,
  ...plan.en,
  ...players.en,
  ...profile.en,
  ...teamCode.en,
  ...teams.en,
  ...toast.en,
  ...blog.en,
  ...notable.en,

  // widget data type
  UNCHECKED: 'Unchecked',
  HIGH: 'High',
  BAD: 'Bad',

  'common.today': 'Today',

  // index page
  'index.title':
    "Plco for Coach is a service that monitors and manages player's condition, workload, soreness and injury data.",

  // 403 page
  '403.title': 'Oops, Access Denied/Forbidden.',
  '403.subtitle.first': 'Sorry, but you don’t have permission to',
  '403.subtitle.second': 'access this page. You can go back to previous page.',
  '403.button': 'Back to previous page',

  // 404 page
  '404.title': 'The page you were looking for doesn’t exist.',
  '404.subtitle': 'If you want to start over, Go back to home.',
  '404.button': 'Back to home',

  // internal server error page
  'internal-server-error.title': 'The service is being delayed for a while.',
  'internal-server-error.subtitle':
    'We are trying to solve the problem. \n Explore the sports science information on out blog or press the ‘Try again’ button in 3-5 minutes to try again.',
  'internal-server-error.button': 'Try again',

  // team-new page
  'team-new.title': 'Welcome {name}!',
  'team-new.subtitle':
    "Plco for Coach is a service that monitors and manages player's condition, workload, soreness and injury data.",
  'team-new.create.title': 'Create a team on Plco for Coach then invite members to the team!',
  'team-new.create.button': 'Create team',
  'team-new.join.title': 'Do you want to join an existing team? Enter the team code you received.',
  'team-new.join.button': 'Enter code',

  // modal-team-create
  'modal-team-create.header.title': 'Create a team',
  'modal-team-create.footer.left.button': 'Cancel',
  'modal-team-create.footer.right.button': 'Create team',
  'modal-team-create.name.label': 'Team name(required)',
  'modal-team-create.name.placeholder': 'Please enter team name (e.g. Plco FC)',
  'modal-team-create.nationality.label': 'Nationality(required)',
  'modal-team-create.region.label': 'Region(required)',
  'modal-team-create.category.label': 'Sports(required)',
  'modal-team-create.url.label': 'Team URL',
  'modal-team-create.url.placeholder': 'Enter alphanumeric characters',

  // modal-team-create-loading
  'modal-team-create-loading.loading.title': 'We’re making a great team...',
  'modal-team-create-loading.loading.subtitle': 'Wait a minute',
  'modal-team-create-loading.success.title': 'Succeed to create a team!',
  'modal-team-create-loading.error.title': 'Failed to create a team',
  'modal-team-create-loading.error.subtitle': 'Try again',

  // modal-team-join
  'modal-team-join.header.title': 'Enter team code',
  'modal-team-join.footer.left.button': 'Cancel',
  'modal-team-join.footer.right.button': 'Confirm',
  'modal-team-join.title': 'Enter the 6 alphanumeric character team code, then click Confirm.',
  'modal-team-join.code.placeholder': 'Enter team code',

  // modal-team-join-success
  'modal-team-join-success.footer.right.button': 'Confirm',
  'modal-team-join-success.title': '{name} Connection success',
  'modal-team-join-success.subtitle': 'The connection is completed when coach accepts it.',

  // modal-logout
  'modal-logout.title': 'Are you sure you want to log out?',

  // modal-leave
  'modal-leave.title': 'Are you sure you want to leave the team?',

  // modal-kickout
  'modal-kickout.title': 'Are you sure you want to kick {name} out of the team?',

  // modal-transfer
  'modal-transfer.title': 'Transfer ownership of {teamName} to {userName}?',

  // modal-check-condition-reminder
  'modal-check-condition-reminder.title.smOnly':
    'Would you like to send a condition check notification to {count} players?',
  'modal-check-condition-reminder.title.mdAndUp':
    'Would you like to send a condition check notification to {count} players?',

  // modal-check-workload-reminder
  'modal-check-workload-reminder.title.smOnly':
    'Would you like to send a workout check notification to {count} players?',
  'modal-check-workload-reminder.title.mdAndUp':
    'Would you like to send a workout check notification to {count} players?',

  // navbar-user-dropdown
  'navbar-user-dropdown.edit-profile': 'Edit profile',
  'navbar-user-dropdown.logout': 'Log out',

  // navbar-team-dropdown
  'navbar-team-dropdown.team': '{membersCount} members · {playersCount} players',
  'navbar-team-dropdown.edit-team-profile': 'Edit team profile',
  'navbar-team-dropdown.team-management': 'Members & Authority',
  'navbar-team-dropdown.team-invite': 'Invite to team',
  'navbar-team-dropdown.team-list': 'All teams ({count})',
  'navbar-team-dropdown.create-team': 'Create a team',
  'navbar-team-dropdown.payment': 'Plan & Billing',
  'navbar-team-dropdown.team-code.title': 'Are there any teams invited?',
  'navbar-team-dropdown.enter-team-code': 'Enter team code',

  // navbar-players
  'navbar-players.player-data': 'Player data',
  'navbar-players.back': 'Go back',

  // main-drawer
  'main-drawer.item.dashboard': 'Dashboard',
  'main-drawer.item.team-chart': 'Team chart',
  'main-drawer.item.insight': 'Insight',
  'main-drawer.item.monitoring': 'Team monitoring',
  'main-drawer.item.player': 'Squad',
  'main-drawer.item.scheduler': 'Calendar',
  'main-drawer.item.blog': 'Blog',
  'main-drawer.item.blog.chip': 'Free trial D-{leftDay}',

  // sub-drawer
  'sub-drawer.item.overview': 'Overview',

  // drawer-language
  'drawer-language.language': 'Language',

  // drawer-footer
  'drawer-footer.copyright': '© plco for Coach',

  // drawer-players
  'drawer-players.search.placeholder': 'Player search',
  'drawer-players.starred-players': 'Starred players ({playersCount})',
  'drawer-players.players': 'Players ({playersCount})',

  // team-member
  'team-member.back': 'Go back',
  'team-member.access.title': 'Members & Authority',

  // dropdown-menu-team-member
  'dropdown-menu-team-member.leave': 'Leave team',
  'dropdown-menu-team-member.kickout': 'Kick out',
  'dropdown-menu-team-member.transfer': 'Transfer ownership',

  // monitoring
  'monitoring.invite.pending.title':
    "Waiting for admin's approval. Try logging in again when your request have been approved.",
  'monitoring-header.title': 'Team monitoring',
  'monitoring-header.update': 'last updated',
  'monitoring.modal.team.code': 'Team code',
  'monitoring.modal.description.top': 'Who do you want to send an invitation message?',
  'monitoring.modal.description.bottom': 'Copy and share it.',
  'monitoring.modal.coach': 'Coach',
  'monitoring.modal.player': 'Player',
  'monitoring.modal.clipboard.toasts.success': 'Your team invitation message has been copied.',
  'monitoring.modal.clipboard-team-code.toasts.success': 'Copied team code to clipboard',
  'monitoring.modal.title': 'Invite to team',
  'monitoring.chart.title': 'Team Chart',
  'monitoring.table.title': 'Team Data',
  'monitoring.table.workout.memo-button': 'Workout memo',
  'monitoring.modal-workout-memo.title': 'Workout memo',
  'monitoring.modal-workout-memo.empty': 'No workout memo entered.',
  'monitoring.table.noData': 'There are no members yet...',
  'monitoring.table.soreness.degree': 'Level {degree, selectordinal, other {#}}',
  'monitoring.table.data-sorting': 'Data sorting',
  'monitoring.table.clear-sorting': 'Clear sorting',
  'monitoring.table.data': 'Data',
  'monitoring.table.sorting': 'Sorting',
  'monitoring.table.asc': 'Ascending',
  'monitoring.table.desc': 'Descending',
  'monitoring.period-button.1_DAY': '1day',
  'monitoring.period-button.7_DAYS': 'Last 7days',
  'monitoring.period-button.28_DAYS': 'Last 28days',
  'monitoring.chart.tooltip.close': 'close',
  'monitoring.chart.tooltip.another-team.title': 'Workout/Training',
  'monitoring.chart.workload-count': 'Total {count}',

  // unauthorized
  'unauthorized.title': 'Oops, You can’t view this content.',
  'unauthorized.subtitle.first': 'Please wait a moment for the admin to accept it.',
  'unauthorized.subtitle.second': 'And refresh (F5) when the acceptance is completed.',

  // calendar
  'calendar.navigationLabel.decade': 'Select year',

  // group
  'group-monitoring.dropdal-player-select.subtitle': '{playersCount} selected',
  'group-monitoring.dropdal-player-select.placeholder.groups-and-players': 'Group/Player Search',
  'group-monitoring.dropdal-player-select.placeholder.players': 'Player Search',
  'group-monitoring.dropdal-player-select.select-all': 'Select all ({playersCount})',

  'group-monitoring.group-button.create': 'Add group ({count}/5)',
  'group-monitoring.modal-group.create.cancel': 'Are you sure you want to cancel group creation?',
  'group-monitoring.modal-group.create.header': 'Create group',

  'group-monitoring.group-button.setting': 'Settings',
  'group-monitoring.modal-group.setting.header': 'Settings',

  'group-monitoring.modal-group.guide':
    'Saved group information is uniformly reflected for all coaches.',

  'group-monitoring.modal-group.modify.header': 'Edit group',
  'group-monitoring.modal-group.setting.cancel':
    'Are you sure you want to cancel group modification',
  'group-monitoring.modal-group.setting.delete-button': 'Delete this group',

  'group-monitoring.modal-group.input.label': 'Group name',
  'group-monitoring.modal-group.input.placeholder':
    'Please create a group name. (Up to 10 characters)',

  'group-monitoring.modal-group.group-list.header.title': 'Select a group',

  'group-monitoring.modal-group.member-list.header.title': 'Member selection',
  'group-monitoring.modal-group.member-list.header.subtitle':
    'Please select a member to join the group.',
  'group-monitoring.modal-group.member-list.placeholder': 'Player Search',
  'group-monitoring.modal-group.member-list.select-all': 'Select All ({playersCount})',

  'group-monitoring.modal-group-delete.text': 'Are you sure you want to delete the group?',

  // scheduler
  'scheduler.header.title': 'Event',
  'scheduler.header.button.create-event': 'Create Event',
  'scheduler.days.date.event.more': 'more···',
  'scheduler.days.date.event.count': ' ',
  'scheduler.modal-event.create.header': 'Create Event',
  'scheduler.modal-event.duplicate.header': 'Duplicate Event',
  'scheduler.modal-event.edit.header': 'Edit Event',
  'scheduler.modal-event.event-name.field-input.label': 'Event title',
  'scheduler.modal-event.event-name.field-input.placeholder':
    'Please enter a event title (up to 50 characters)',
  'scheduler.modal-event.event-location.field-input.label': 'Location',
  'scheduler.modal-event.event-location.field-input.placeholder': 'Please enter a location',
  'scheduler.modal-event.event-date.field-input-date.start.label': 'Starts',
  'scheduler.modal-event.event-date.field-input-date.end.label': 'Ends',
  'scheduler.modal-event.expected-intensity.label': 'Expected Intensity',
  'scheduler.modal-event.expected-intensity.1': 'Very Light Activity',
  'scheduler.modal-event.expected-intensity.2': 'Light Activity',
  'scheduler.modal-event.expected-intensity.3': 'Light Activity',
  'scheduler.modal-event.expected-intensity.4': 'Moderate Activity',
  'scheduler.modal-event.expected-intensity.5': 'Moderate Activity',
  'scheduler.modal-event.expected-intensity.6': 'Moderate Activity',
  'scheduler.modal-event.expected-intensity.7': 'Very hard Activity',
  'scheduler.modal-event.expected-intensity.8': 'Very hard Activity',
  'scheduler.modal-event.expected-intensity.9': 'Very hard Activity',
  'scheduler.modal-event.expected-intensity.10': 'Max effort Activity',
  'scheduler.modal-event.participant.label': ' Participants',
  'scheduler.modal-event.participant.count': '{playersCount} selected',
  'scheduler.modal-event.training.textarea.label': 'Training Notice',
  'scheduler.modal-event.training.textarea.placeholder':
    "Provide a detailed schedule or jot down important information for the players.\nNotice will be shared with them.\neg) Today's Program: Warm-up, Rondo, and 1-on-1 Passing",
  'scheduler.modal-event.textarea.placeholder': 'Please enter the event in detail.',

  'scheduler.modal-event.caution.expected-intensity':
    'Expected intensity, participants and notes can only be viewed by admin',
  'scheduler.modal-event.caution': 'Participants and notes can only be viewed by the administrator',

  'scheduler.modal-event.more-items.duplicate': 'Duplicate Event',
  'scheduler.modal-event.more-items.modify': 'Edit Event',
  'scheduler.modal-event.more-items.delete': 'Delete Event',

  'scheduler.modal-event.create.cancel.title': "Are you sure you want to cancel 'Create Event'?",
  'scheduler.modal-event.duplicate.cancel.title':
    "Are you sure you want to cancel 'Duplicate Event'?",
  'scheduler.modal-event.modify.cancel.title': "Are you sure you want to cancel 'Edit Event'?",
  'scheduler.modal-event.delete.cancel.title': 'Are you sure you want to delete the Event?',

  'scheduler.overlay.participants': 'Participants ({participants})',
  'scheduler.overlay.participants.more': '+ more...',
  'scheduler.overlay.notice-target': 'Notice Target ({participants})',

  'scheduler.overlay.participants.total': 'Invited Players ({total})',
  'scheduler.overlay.participants.notification': 'Notify unchecked players',
  'scheduler.overlay.all': 'All',
  'scheduler.overlay.attend': 'Attend',
  'scheduler.overlay.absent': 'Absent',
  'scheduler.overlay.injured': 'Injured',
  'scheduler.overlay.etc': 'etc',
  'scheduler.overlay.unchecked': 'Unchecked',
  'scheduler.button.training-report': 'Training report',
  'scheduler.modal-participant-notification.title':
    'Would you like to send a Participation check notification to {participants} players?',
  'scheduler.modal-event.memo.guide':
    'The expected intensity/participants is not visible to players, but they receive the training notice.',

  'scheduler.modal-event.match-opponent.field-input.label': 'Opponent',
  'scheduler.modal-event.match-opponent.field-input.placeholder':
    'Please enter the opponent team or player.',
  'scheduler.modal-event.match-competition.field-input.label': 'Competition',
  'scheduler.modal-event.match-competition.field-input.placeholder':
    'Please enter the league/competition name, etc.',
  'scheduler.modal-event.match-location.field-input.label': 'Location',
  'scheduler.modal-event.match-location.field-input.placeholder':
    'Please enter the match location.',
  'scheduler.modal-event.match-event.field-input.label': 'Event',
  'scheduler.modal-event.match-venue.etc.button': 'ETC',
  'scheduler.modal-event.match-notice-target.field-input.label': 'Notice Target',
  'scheduler.modal-event.match-notice-target.field-input.guide':
    'Players in the notice target can be registered on the lineup.',
  'scheduler.modal-event.match-notice.field-input.label': 'Notice',
  'scheduler.modal-event.match-notice.textarea.label': 'Match Notice',
  'scheduler.modal-event.match-notice.textarea.placeholder':
    'Provide the information about the match that players need to be notified.\nThe content will be shared with the players.',
  'scheduler.modal-event.match-notice.textarea.guide':
    'Don’t worry, any match schedule notes saved prior to 10/10, 2023 will remain confidential.',
  'scheduler.button.match-report': 'Match report',
  'scheduler.button.practice-match-report': 'Practice Match Report',

  // match report
  'match-report.notice': 'Notice',
  'match-report.result': 'Match Result',
  'match-report.result-status.win': 'W',
  'match-report.result-status.draw': 'D',
  'match-report.result-status.lose': 'L',
  'match-report.result-textarea.placeholder':
    'Record key match statistics, comments, tactics, and more.',
  'match-report.lineup-and-record-card.title': 'Lineup and Records',
  'match-report.lineup-and-record-card-empty.title': 'No registered lineup',
  'match-report.lineup-and-record-card-empty.subtitle':
    "Please click the 'Register Lineup' button to submit the lineup",
  'match-report.lineup-and-record-card.table-label.part': 'Part.',
  'match-report.lineup-and-record-card.table-label.playing-time': 'Playing Time',
  'match-report.lineup-and-record-card.table-label.record-and-comment':
    'Players Record and Comment',
  'match-report.lineup-and-record-card.modify-button.help': 'Click here to modify the lineup',
  'match-report.lineup-register-modal.title': 'Register Lineup',
  'match-report.lineup-register-modal.search-input.placeholder': 'Player search',
  'match-report.lineup-register-modal.guide':
    'The players selected for the notice will be displayed in the list.',
  'match-report.lineup-register-modal.player-status.starting': 'Starting',
  'match-report.lineup-register-modal.player-status.sub': 'Subs',
  'match-report.lineup-register-modal.player-status.no-play': 'No play',
  'match-report.lineup-register-modal.empty':
    'No players to display.\nPlease select a notice target first in the schedule modification.',
  'match-report.lineup-register-modal.no-result': 'No results found.',
  'match-report.lineup-data-card.title': 'Lineup Player Data',
  'match-report.edit': 'Editing',
  'match-report.event-list.title': 'Notice Target ({count})',
  'match-report.all.player-list.empty.title': 'No players selected',
  'match-report.all.player-list.empty.subTitle':
    'There are no players selected for the notice target.',

  // training report
  'training-report.all': 'All',
  'training-report.attend': 'Attend',
  'training-report.injured': 'Injured',
  'training-report.etc': 'etc',
  'training-report.unchecked': 'Unchecked',

  'training-report.all.player-list.empty.title': 'No selected players',
  'training-report.all.player-list.empty.subTitle': 'No selected players for participation.',

  'training-report.attend.player-list.empty.title': 'No attendees',
  'training-report.attend.player-list.empty.subTitle': 'No players have checked ‘Attend’ yet.',

  'training-report.injured.player-list.empty.title': 'No injured (absent) players',
  'training-report.injured.player-list.empty.subTitle': 'No players absent for injury 👍',

  'training-report.etc.player-list.empty.title': 'No players absent for other reasons',
  'training-report.etc.player-list.empty.subTitle': 'No players absent for other reasons 👍',

  'training-report.unchecked.player-list.empty.title': 'No unchecked players',
  'training-report.unchecked.player-list.empty.subTitle':
    'Every player indicated their attendance/absence 👍',

  'training-report.location': 'Location',
  'training-report.date': 'Date',
  'training-report.time': 'Time',
  'training-report.notice': 'Notice',

  'training-report.attendance.percent': 'Attendance',

  'training-report.player-list.help': 'Select a number to validate the roster',

  'training-report.attendance.workload.data.title': 'Participants Workload Data',
  'training-report.attendance.workload.data.list': 'List',
  'training-report.attendance.workload.data.chart': 'Chart',

  'training-report.attendance.workload.data.empty': 'There are no participants checked yet...',

  // tutorial
  'tutorial.end': 'Got it',
  'tutorial.step.0.title': 'Invite the members. 👋',
  'tutorial.step.0.content':
    "Plco for Coach is a service that helps you understand the condition of your members and coach them properly through data monitoring. Invite the members by clicking 'Invite by team code'.",
  'tutorial.step.0.searchTerm': "'Invite by team code'.",
  'tutorial.step.1.title': 'Copy the team code and invitation message. 👍',
  'tutorial.step.1.content':
    'Copy the team code and invitation message and send the team code to the members you are inviting via messenger or text message.',
  'tutorial.step.1.searchTerm': 'Copy the team code and invitation message',
  'tutorial.step.2.title': ' ',
  'tutorial.step.2.content': ' ',
  'tutorial.step.2.searchTerm': ' ',
  'tutorial.step.3.title': 'Manage the invited members in the members & Authority. 🙋🏻‍♂️',
  'tutorial.step.3.content': "Click 'Members & Authority' to see what kind of members are there.",
  'tutorial.step.3.searchTerm': "'Members & Authority'",
  'tutorial.step.4.title': 'Manage the invited members in the members & Authority. 🙋🏻‍♂️',
  'tutorial.step.4.content': "Click 'Members & Authority' to see what kind of members are there.",
  'tutorial.step.4.searchTerm': "'Members & Authority'",
  'tutorial.step.5.title': 'Accept request and try to join the team. ✅',
  // eslint-disable-next-line max-len
  'tutorial.step.5.content':
    "we already added a virtual player. Because no one is here now. Accept the request. Sometimes a member that I didn't invite can come in. In that case, press 'Reject'. Then the player can't join the team.",
  'tutorial.step.5.searchTerm': 'already added a virtual player',
  'tutorial.step.6.title': 'Set authority. 🤝',
  // eslint-disable-next-line max-len
  'tutorial.step.6.content':
    "Once you accept the request, Everyone will have member(player) Authority at first. If the person who requested is a coach or a manager, you must change to 'Admin' authority. Please select the right permissions.",
  'tutorial.step.6.searchTerm': 'member(player) Authority at first',
  // eslint-disable-next-line max-len
  'tutorial.step.6.subContent':
    'Owner: The person who created the team. You can set up a group of members. You can create, modify, and delete schedules. You can delete a team if necessary\n\nAdmin (coach or manager): You can monitor data for members. You can create, modify, and delete events.\n\nMember(Player): This is a member of the team.',
  'tutorial.step.7.title': 'Get the members out of the team. ➡️',
  'tutorial.step.7.content':
    "Now, a virtual player has done his job. Click 'Kick out' to leave from the team.",
  'tutorial.step.7.searchTerm': "'Kick out'",
  'tutorial.step.8.title': ' ',
  'tutorial.step.8.content': ' ',
  'tutorial.step.8.searchTerm': ' ',
  'tutorial.step.9.title': 'Invitation to members complete 🙌',
  'tutorial.step.9.content':
    'Good job. Now invite real members. Please accept the request and change the authority. And monitoring the data.',
  'tutorial.step.9.searchTerm': 'Now invite real members.',
  'tutorial.restart.title': 'Want to know how to invite team members? 🙌',
  'tutorial.restart.subTitle': 'Learn how to invite members',
  'tutorial.virtualMember.name': '(virtual) Eric',
  'tutorial.virtualMember.sportName': 'Soccer',
  'tutorial.virtualMember.sportPosition': 'MidFielder',
  'tutorial.virtualMember.sportRole': 'Player',

  // banner-subscribe
  'banner-subscribe.title.first': 'Subscribe to a plan and',
  'banner-subscribe.title.second': 'continue monitoring',
  'banner-subscribe.action': 'Subscribe Plan',

  // banner-update
  'banner-update.title': 'Do you see the difference?',
  'banner-update.subtext': 'Check out the ‘Update News’\nright now!',

  // card
  'card-teams-invite.title': 'Invite to team',
  'card-teams-invite.content': 'Invite to your team members',
  'card-teams-invite.instruction':
    'You can invite members at any time from {place} on the top left.',
  'card-teams-invite.instruction.place': "Team menu > 'Invite to team'",

  // info tooltip
  'info-tooltip.player-detail': "Now you can check the player's personal data.",
  'info-tooltip.transfer-ownership': 'The authority has been changed\nto the owner of the team!',

  // field-input
  'field-input.label.name': 'Name',
  'field-input.label.nationality': 'Nationality',
  'field-input.label.gender': 'Gender',
  'field-input.label.birthdate': 'Birthdate',
  'field-input.label.phone-number': 'Phone Number',
  'field-input.label.role': 'Role',
  'field-input.label.position': 'Position',
  'field-input.label.sportsCategory': 'SportsCategory',
  'field-input.name.placeholder': 'enter your name',
  'field-input.birthdate.placeholder': 'Enter your birthdate (e.g. 1994-05-01)',
  'field-input.phone-number.placeholder': 'Enter your number (ex: 010-1234-5678)',
  'field-input.username.placeholder': 'Enter your ID',

  // chart data
  'chart.data.not.checked': 'Not checked',
  'chart.data.none': 'None',

  // report
  'report.exception.mobile': 'Please check the report on your PC or tablet.',
  'report.exception.authority.title': 'This report is not accessible to you',
  'report.exception.authority.subtext':
    "Ensure the accuracy of your team's schedule.\nConfirm that the accessed schedule belongs to your team and is not deleted.",

  // match report
  'match-report.modal.header': 'Match Report',

  // practice-match report
  'practice-match-report.modal.header': 'Practice Match Report',

  // training report
  'training-report.modal.header': 'Training Report',
  'training-report.workload-intensity-compare.title': 'Workload Intensity Comparison',
  'training-report.workload-intensity-checked':
    'Workload Check Completed: {checkedCount} out of {totalCount} Participants',
  'training-report.aim-intesity-ballon': 'EXPECTED',
  'training-report.aim-intensity.title': 'Expected Intensity',
  'training-report.real-intensity.title': 'Actual Intensity',
  'training-report.real-intensity.label': 'team avg.*',
  'training-report.real-intensity.empty': "Awaiting Players' Workload Check",
  'training-report.training-point.title': 'Training Point',
  'training-report.training-point.placeholder':
    'Record Training Goals, Objectives, Content, and Results.\n\nExample: Pre-game Training with Lower Intensity.',
  'training-report.special-notes.title': 'Special Notes',
  'training-report.special-notes.placeholder':
    'Record any notable observations or experiences during the training session.\n\nExample: Players demonstrated signs of fatigue, possibly due to the high-intensity training the previous day.',

  // export report
  'report.export.help': 'You can save the report as PDF/image',
  'report.export-modal.title': 'Export report',
  'report.export-modal.save-pdf.button': 'Save as PDF',
  'report.export-modal.save-img.button': 'Save as Image',
  'report.export-modal.save.loading.text': 'Saving',
  'report.export-modal.save.success.text': 'Save successed',
  'report.export-modal.save.failed.text': 'Save failed',

  // player injury modal
  'player.modal-injury.register.header': 'Register injury',
  'player.modal-injury.edit.header': 'Edit Injury',
  'player.modal-injury.detail.header': 'View injury details',
  'player.modal-injury.injury-dummy.front': 'Front',
  'player.modal-injury.injury-dummy.back': 'Back',
  'player.modal-injury.selection.part.text': 'Injury area selection',
  'player.modal-injury.selection.part.none.subtext': 'No selected area',
  'player.modal-injury.selection.part.guide': 'Click on the mannequin to select the injury area',
  'player.modal-injury.detail-selection.header': 'Enter the details',
  'player.modal-injury.detail-selection.date.title': 'Injury Date',
  'player.modal-injury.detail-selection.relapse.text': 'Relapse',
  'player.modal-injury.detail-selection.cause.title': 'Causes and symptoms',
  'player.modal-injury.detail-selection.cause.placeholder':
    'Enter the background, situation, symptoms, etc.',
  'player.modal-injury.detail-selection.diagnosis.title': 'Diagnosis',
  'player.modal-injury.detail-selection.diagnosis.placeholder': 'Enter the diagnosis.',
  'player.modal-injury.detail-selection.medical-record.title': 'Medical record',
  'player.modal-injury.detail-selection.medical-record.placeholder':
    'Enter the date of treatment, hospital, etc.',
  'player.modal-injury.detail-selection.return-date.title': 'Return date',
  'player.modal-injury.detail-selection.return-date.undecide': 'Undecided',
  'player.modal-injury.detail-selection.return-info.title': 'Return information',
  'player.modal-injury.detail-selection.return-info.placeholder':
    'Enter the rehab period, expected return date, etc.',
  'player.modal-injury.detail-selection.injury-ended.title': 'Injury ended',
  'player.modal-injury.detail-selection.injury-ended.subtitle':
    'Saving this injury as a past injury.',
  'player.modal-injury.detail-selection.file.button': 'Attach file',
  'player.modal-injury.detail-selection.file.text':
    'You can attach relevant photos or documents\n*Up to 3 files, each within 10MB',
  'player.modal-injury.register-cancel.title':
    'Are you sure you want to cancel the injury registration?',
  'player.modal-injury.update-cancel.title':
    'Are you sure you want to cancel the injury modification?',
  'player.modal-injury.delete.title': 'Are you sure you want to delete the injury?',

  'menu.chat-support': 'Chat support',
  'menu.lanuage': 'Language',
  'menu.notification': 'Notifications',
  'menu.my-profile': 'My profile',

  'fab.send-feedback': 'Send feedback',
  'fab.select-feedback-player': 'Please select a feedback player',
  'fab.search-players': 'Search players',
  'fab.select': 'Select',
  'fab.no-results-found': 'No results found',
  'fab.cancel': 'Cancel',
  'fab.next': 'Next',

  'notification.updates': 'Updates',
  'notification.notable-players': 'Notable players',
  'notification.make-all-as-read': 'Make all as read',
  'notification.COMMENT_CREATED': "{user} left a comment on {player}'s {title} <b>feedback</b>",
  'notification.COMMENT_CREATED_NO_FEEDBACK': "{user} left a comment on {player}'s {title}",
  'notification.FEEDBACK_CREATED': '{author} sent {title} <b>feedback</b> to {player}',
  'notification.FEEDBACK_CREATED_NO_FEEDBACK': '{author} sent {title} to {player}',
  'notification.FEEDBACK_REQUESTED': 'Received a new <b>feedback request</b> from {player}',
  'notification.feedback-deleted': 'This feedback has been deleted',
  'notification.feedback-request-deleted': 'This feedback request has been deleted',
  'notification.comment-deleted': 'This comment has been deleted',
  'notification.no-update-notifications': 'No update notifications',
  'notification.notify-request-or-comments':
    'Notify you if there are new\nfeedback requests or comments',
  'notification.general': 'General',
  'notification.format.a-week-ago': 'A week ago',
  'notification.format.today': 'HH:mm',

  feedback: 'Feedback',
  'feedback.no-feedback-yet': 'No feedback yet',
  'feedback.send-feedback': 'Send feedback',
  'feedback.choose-feedback': 'Choose feedback\nfor {player}',
  'feedback.suggestion-description':
    'Write freely or choose from suggestions provided by PLCO.\nSuggestions are always being updated.',
  'feedback.general-feedback': 'General feedback',
  'feedback.emoji-general-feedback': '🙂 General feedback',
  'feedback.write-freely': 'Write freely',
  'feedback.plco-suggestions': '✨PLCO Suggestions',
  'feedback.tag.NORMAL': 'General feedback',
  'feedback.tag.REQUEST': 'Request feedback',
  'feedback.tag.TRAINING': 'Training memo',
  'feedback.tag.MATCH': 'Match memo',
  'feedback.tag.PRACTICE': 'Practice match memo',
  'feedback.tag.WORKLOAD_MEMO': 'Workout memo',
  'feedback.tag.WORKLOAD_RISK_AT_INJURY': 'Workload',
  'feedback.tag.CONDITION_NOT_GOOD': 'Condition',
  'feedback.tag.CONDITION_PLUMMET': 'Condition',
  'feedback.tag.SORENESS_SEVERE': 'Soreness',
  'feedback.type.normal-workout': 'Normal workout',
  'feedback.type.RISK_INCREASED': 'Increased: Risk of injury',
  'feedback.type.RISK_CAUTION': 'Caution: Risk of injury',
  'feedback.type.RISK_DETECTED': 'Detected: Risk of injury',
  'feedback.type.severe-soreness': 'severe soreness',
  'feedback.type.bad-condition': 'Bad condition',
  'feedback.type.plummeted-condition': 'Plummeted condition',
  'feedback.back': 'Back',
  'feedback.next': 'Next',
  'feedback.save': 'Save',
  'feedback.no-specific-suggestions': 'No specific suggestions to offer',
  'feedback.enter-your-feedback': 'Please enter your feedback',
  'feedback.data-for-date': 'MMM Do, yyyy',
  'feedback.injury.reason.HIGH_ACWR': 'High ACWR',
  'feedback.injury.reason.LOW_ACWR': 'Low ACWR',
  'feedback.injury.reason.WEEKLY_WORKLOAD_SURGE': 'Weekly workload surge',
  'feedback.injury.reason.STRAIN': 'Strain',
  'feedback.injury.reason.HIGH_WEEKLY_WORKLOAD': 'High weekly workload',
  'feedback.injury.reason.MONOTONY': 'Monotony',
  'feedback.injury.reason.NON_ELASTIC_INTENSITY': 'Non-elastic intensity',
  'feedback.player-status-description':
    '{player} is in {status} status due to {cause} and {count} other factors.',
  'feedback.plco-suggestion': "PLCO's suggestion",
  'feedback.condition-type.FATIGUE': 'Fatigue',
  'feedback.condition-type.STRESS': 'Stress',
  'feedback.condition-type.MUSCLE': 'Muscle',
  'feedback.condition-type.MOOD': 'Mood',
  'feedback.condition-type.SLEEP_DURATION': 'Sleep time',
  'feedback.condition-type.SLEEP_QUALITY': 'Sleep quality',
  'feedback.bad-condition-description':
    "{player}'s {conditionList}: Below 4 points, which is not favorable.",
  'feedback.decline-condition':
    "{player}'s {conditionList}: Sharply declined compared to the last 28 days.",
  'feedback.soreness-bodypart':
    '{player} is experiencing lv{level} {type, select, CONTACT {Contact} NON_CONTACT {Non-contact} other {}} soreness in {body_part}.',
  'feedback.level': 'lv{level}',
  'feedback.sure-cancel-sending-feedback': 'Are you sure you want to cancel sending feedback?',
  'feedback.no': 'No',
  'feedback.yes': 'Yes',
  'feedback.view-feedback-details': 'View feedback details',
  'feedback.comments': 'Comments',
  'feedback.add-comment-this-feedback': 'Add a comment to this feedback',
  'feedback.submit': 'Submit',
  'feedback.editing': 'Editing',
  'feedback.sure-delete-feedback': 'Are you sure you want to delete the feedback?',
  'feedback.player-no-longer-view': 'The player can no longer view',
  'feedback.want-delete-comment': 'Do you want to delete the comment?',
  'feedback.edited': '(Edited)',
  'feedback.authors-feedback': "{author}'s feedback",
  'feedback.no-comments-yet': 'No comments yet',
  'feedback.request-from-player': 'Request from {player}',
  'feedback.feedback-request': 'Feedback request',
  'feedback.feedback-has-deleted': 'Feedback has been deleted',
  'feedback.feedback-has-edited': 'Feedback has been edited',
  'feedback.org-license-description': 'ORG license administrators\ncannot use feedback',
  'feedback.withdrew-user': 'Withdrawn user',

  'workout-duration.edit': 'Edit workout duration',
  'workout-duration.helper-text': 'Duration edited by the coach will be displayed in blue',
  'workout-duration.edit-description':
    'You can edit for 7 days based on the schedule date, and a notification will be sent to the player. Please be especially careful as the original data will be edited.',
  'workout-duration.select-players': 'Select players*',
  'workout-duration.starts-ends': 'Starts~Ends*',
  'workout-duration.time-format-hours-min': '⏱️ HH:mm ~ HH:mm ({hours}h {min}m)',
  'workout-duration.time-format-hours': '⏱️ HH:mm ~ HH:mm ({hours}h)',
  'workout-duration.time-format-min': '⏱️ HH:mm ~ HH:mm ({min}m)',
  'workout-duration.invalid-duration': 'Enter the time correctly',
  'workout-duration.designated-time': 'Designated time',
  'workout-duration.cancel': 'Cancel',
  'workout-duration.save': 'Save',
  'workout-duration.select': 'Select',
  'workout-duration.select-all': 'Select all ({count})',
  'workout-duration.hh': 'HH',
  'workout-duration.mm': 'mm',
  'workout-duration.edited': 'Workout duration edited',
}

export default messages
