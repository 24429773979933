export const teamChart = {
  en: {
    'team-chart.header.title': 'Team chart',
    'team-chart.card-team-status-condition.prefix': '😎',
    'team-chart.card-team-status-condition.title': 'Condition check',
    'team-chart.card-team-status-condition.check-complete': 'Completed',
    'team-chart.card-team-status-condition.checked-players': 'Checked player',
    'team-chart.card-team-status-condition.unchecked-players': 'Unchecked player',
    'team-chart.card-team-status-condition.check-reminder.button': 'Send a check notification',
    'team-chart.card-team-status-workout.prefix': '💪',
    'team-chart.card-team-status-workout.title': 'Workout Check',
    'team-chart.card-team-status-workout.check-complete': 'Completed',
    'team-chart.card-team-status-workout.checked-players': 'Checked player',
    'team-chart.card-team-status-workout.unchecked-players': 'Unchecked player',
    'team-chart.card-team-status-workout.check-reminder.button': 'Send a check notification',
    'team-chart.card-team-status-soreness.prefix': '🤕',
    'team-chart.card-team-status-soreness.title': 'Soreness',
    'team-chart.card-team-status-soreness-players.title': 'Level 5 player',
    'team-chart.card-team-status-soreness.severe-players.title': 'Level 3 and 4 player',
    'team-chart.card-team-status-injury.prefix': '🚑',
    'team-chart.card-team-status-injury.title': 'Injury',
    'team-chart.card-team-status-injury-players.title':
      'Player data - Players with current injuries',
    'team-chart.dropdown-dataset-players.condition.checked-players.title':
      'Condition checked player ({count})',
    'team-chart.dropdown-dataset-players.condition.unchecked-players.title':
      'Condition unchecked player ({count})',
    'team-chart.dropdown-dataset-players.workout.checked-players.title':
      'Workout checked player ({count})',
    'team-chart.dropdown-dataset-players.workout.checked-players.guide':
      "Injuries/Rest day are who checked 'No Workout'",
    'team-chart.dropdown-dataset-players.workout.unchecked-players.title':
      'Workout unchecked player ({count})',
    'team-chart.dropdown-dataset-players.soreness-players.title': 'Level 5 player ({count})',
    'team-chart.dropdown-dataset-players.soreness.severe-players.title':
      'Level 3 and 4 player ({count})',
    'team-chart.dropdown-dataset-players.injury-players.title': 'Injured player ({count})',
    'team-chart.chart.condition.title': 'Team Condition',
    'team-chart.chart.workload.title': 'Team Workload',
    'team-chart.chart.injury.title': 'Team Soreness count',
    'team-chart.chart.button.period.7_days': 'Last 7days',
    'team-chart.chart.button.period.28_days': 'Last 28days',
    'team-chart.widget.soreness.title': 'Player with level 5 soreness ({count})',
    'team-chart.widget.injury.subtitle': 'criteria ・ {date}',
  },
  ko: {
    'team-chart.header.title': '팀 차트',
    'team-chart.card-team-status-condition.prefix': '😎',
    'team-chart.card-team-status-condition.title': '컨디션 체크',
    'team-chart.card-team-status-condition.check-complete': '체크완료',
    'team-chart.card-team-status-condition.checked-players': '체크 선수',
    'team-chart.card-team-status-condition.unchecked-players': '미체크 선수',
    'team-chart.card-team-status-condition.check-reminder.button': '미체크 선수 알림 보내기',
    'team-chart.card-team-status-workout.prefix': '💪',
    'team-chart.card-team-status-workout.title': '운동 체크',
    'team-chart.card-team-status-workout.check-complete': '체크완료',
    'team-chart.card-team-status-workout.checked-players': '체크 선수',
    'team-chart.card-team-status-workout.unchecked-players': '미체크 선수',
    'team-chart.card-team-status-workout.check-reminder.button': '미체크 선수 알림 보내기',
    'team-chart.card-team-status-soreness.prefix': '😖',
    'team-chart.card-team-status-soreness.title': '통증',
    'team-chart.card-team-status-soreness-players.title': '통증 5단계 선수',
    'team-chart.card-team-status-soreness.severe-players.title': '통증 3, 4단계 선수',
    'team-chart.card-team-status-injury.prefix': '🚑',
    'team-chart.card-team-status-injury.title': '부상',
    'team-chart.card-team-status-injury-players.title': '선수 데이터 - 현재 부상이 등록된 선수',
    'team-chart.dropdown-dataset-players.condition.checked-players.title':
      '컨디션 체크 선수 ({count}명)',
    'team-chart.dropdown-dataset-players.condition.unchecked-players.title':
      '컨디션 미체크 선수 ({count}명)',
    'team-chart.dropdown-dataset-players.workout.checked-players.title':
      '운동 체크 선수 ({count}명)',
    'team-chart.dropdown-dataset-players.workout.checked-players.guide':
      "부상/휴식은 '운동 안 함'을 체크한 선수입니다",
    'team-chart.dropdown-dataset-players.workout.unchecked-players.title':
      '운동 미체크 선수 ({count}명)',
    'team-chart.dropdown-dataset-players.soreness-players.title': '통증 5단계 선수 ({count}명)',
    'team-chart.dropdown-dataset-players.soreness.severe-players.title':
      '통증 3, 4단계 선수 ({count}명)',
    'team-chart.dropdown-dataset-players.injury-players.title': '부상 선수 ({count}명)',
    'team-chart.chart.condition.title': '팀 컨디션',
    'team-chart.chart.workload.title': '팀 운동부하',
    'team-chart.chart.injury.title': '팀 통증 개수',
    'team-chart.chart.button.period.7_days': '지난 7일',
    'team-chart.chart.button.period.28_days': '지난 28일',
    'team-chart.widget.soreness.title': '통증 5단계 선수 ({count}명)',
    'team-chart.widget.injury.subtitle': '기준 ・ {date}',
    'team-chart.onboarding.hyper.title': '최고의 경기력, \n 하이퍼로 만들어보세요',
    'team-chart.onboarding.hyper.description':
      '진정한 프로선수로의 첫 걸음, \n 엘리트 선수가 빅리그로 가기까지 \n 하이퍼와 함께라면 가능합니다.',
    'team-chart.onboarding.hyper.payment.button': '하이퍼 결제 시작하기',
  },
  ja: {
    'team-chart.header.title': 'チームチャート', // "팀 차트",
    'team-chart.card-team-status-condition.prefix': '😎',
    'team-chart.card-team-status-condition.title': 'コンディション·チェック', // "컨디션 체크",
    'team-chart.card-team-status-condition.check-complete': '完了', // "체크완료",
    'team-chart.card-team-status-condition.checked-players': 'チェック選手', // "체크 선수",
    'team-chart.card-team-status-condition.unchecked-players': '未チェック選手', // "미체크 선수",
    'team-chart.card-team-status-condition.check-reminder.button': '未チェック選手に通知を送信', // "미체크 선수 알림 보내기",
    'team-chart.card-team-status-workout.prefix': '💪',
    'team-chart.card-team-status-workout.title': '運動チェック', // "운동 체크",
    'team-chart.card-team-status-workout.check-complete': '完了', // "체크완료",
    'team-chart.card-team-status-workout.checked-players': 'チェック選手', // "체크 선수",
    'team-chart.card-team-status-workout.unchecked-players': '未チェック選手', // "미체크 선수",
    'team-chart.card-team-status-workout.check-reminder.button': '未チェック選手に通知を送信', // "미체크 선수 알림 보내기",
    'team-chart.card-team-status-soreness.prefix': '🤕',
    'team-chart.card-team-status-soreness.title': '痛み', // "통증",
    'team-chart.card-team-status-soreness-players.title': '痛み5段階の選手', // "통증 5단계 선수",
    'team-chart.card-team-status-soreness.severe-players.title': '痛み3、4段階の選手', // "통증 3, 4단계 선수",
    'team-chart.card-team-status-injury.prefix': '🚑',
    'team-chart.card-team-status-injury.title': '負傷', // '부상',
    'team-chart.card-team-status-injury-players.title': '選手データ - 現在負傷が登録されている選手', // '선수 데이터 - 현재 부상이 등록된 선수',
    'team-chart.dropdown-dataset-players.condition.checked-players.title':
      'コンディションチェック選手({count}名)', // "컨디션 체크 선수 ({count}명)",
    'team-chart.dropdown-dataset-players.condition.unchecked-players.title':
      'コンディション未チェック選手({count}名)', // "컨디션 미체크 선수 ({count}명)",
    'team-chart.dropdown-dataset-players.workout.checked-players.title':
      '運動負荷チェック選手({count}名)', // "운동 체크 선수 ({count}명)",
    'team-chart.dropdown-dataset-players.workout.checked-players.guide':
      "負傷/休憩は'運動しなかった'とチェックした選手です", // "부상/휴식은 ‘운동 안 함'을 체크한 선수입니다",
    'team-chart.dropdown-dataset-players.workout.unchecked-players.title':
      '運動負荷未チェック選手({count}名)', // "운동 미체크 선수 ({count}명)",
    'team-chart.dropdown-dataset-players.soreness-players.title': '痛み5段階の選手 ({count}名)', // "통증 5단계 선수 ({count}명)",
    'team-chart.dropdown-dataset-players.soreness.severe-players.title':
      '痛み3、4段階の選手 ({count}名)', // "통증 3, 4단계 선수 ({count}명)",
    'team-chart.dropdown-dataset-players.injury-players.title': '負傷選手 ({count}名)', // '부상 선수 ({count}명)',
    'team-chart.chart.condition.title': 'チーム·コンディション', // "팀 컨디션",
    'team-chart.chart.workload.title': 'チーム運動負荷', // "팀 운동부하",
    'team-chart.chart.injury.title': 'チームの痛みの個数', // "팀 통증 개수",
    'team-chart.chart.button.period.7_days': '今月の7日', // "지난 7일",
    'team-chart.chart.button.period.28_days': '今月28日', // "지난 28일",
    'team-chart.widget.soreness.title': '5段階の痛みを持つ選手 ({count})', // "통증 5단계 선수 ({count}명)",
    'team-chart.widget.injury.subtitle': '基準 · {date}', // "기준 ・{date}",
  },
}
