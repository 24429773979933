import {
  CHART_SYSTEM_PARTICIPANT_WORKLOAD_MAP_KEY,
  chartSystemParticipantWorkloadMap,
} from './event'
import { CHART_SYSTEM_MONITORING_MAP_KEY, chartSystemMonitoringMap } from './monitoring'
import { CHART_SYSTEM_PLAYER_MAP_KEY, chartSystemPlayersMap } from './players'
import { CHART_SYSTEM_TEAM_CHART_MAP_KEY, chartSystemTeamChartMap } from './teamChart'

import type { ChartSystemMapValue } from './chart-system.types'

// DATA_TYPE:DATA_NAME:PERIOD:FREQUENCY:CHART_TYPE
export const CHART_SYSTEM_MAP_KEY = [
  // USER
  ...CHART_SYSTEM_PLAYER_MAP_KEY,
  ...CHART_SYSTEM_PARTICIPANT_WORKLOAD_MAP_KEY,
  // TEAM
  ...CHART_SYSTEM_MONITORING_MAP_KEY,
  ...CHART_SYSTEM_TEAM_CHART_MAP_KEY,
] as const

export type ChartSystemMapKey = (typeof CHART_SYSTEM_MAP_KEY)[number]

type ChartSystemMap = Record<ChartSystemMapKey, ChartSystemMapValue>

export const chartSystemMap: ChartSystemMap = {
  // USER
  // players/summary/readiness
  ...chartSystemPlayersMap,
  // TEAM
  // monitoring/condition
  ...chartSystemMonitoringMap,
  // teamChart/condition
  ...chartSystemTeamChartMap,
  // event/workload
  ...chartSystemParticipantWorkloadMap,
}
