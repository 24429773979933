import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMoment } from '@plco-pro/hooks/moment'
import { injuryColors } from '@plco-pro/themes/main'

type Props = {
  soreness: number
  time: Date
}

export const InjuryHistoryCardTitle = ({ soreness, time }: Props) => {
  const { formatMessage: f } = useI18n()
  const moment = useMoment()

  const injuryColor = injuryColors[`injury-level-${soreness}` as keyof typeof injuryColors]
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Box
          sx={{
            width: 16,
            height: 16,
            bg: injuryColor,
            borderRadius: 16,
            mr: '8px',
          }}
        />
        <Text variant={'h5'}>{f({ id: `INJURY_NUMBER_${soreness}` })}</Text>
      </Flex>
      <Text appearance={'hint'}>{`${moment(time).format('YYYY.M.D (ddd)')}`}</Text>
    </Flex>
  )
}
