import React from 'react'

import { Box, Text } from '@plco-pro/components/atoms'
import { TextAreaAutoResize } from '@plco-pro/components/molecules'

interface Props {
  isEditable: boolean
  value: string
  setValue: (string: string) => void
  width: string
  textareaRef: React.RefObject<HTMLTextAreaElement>
  placeholder: string
  resizeAction?: () => void
}

export const TextEditable = ({
  isEditable,
  width,
  value,
  setValue,
  textareaRef,
  placeholder,
  resizeAction,
}: Props) => {
  if (isEditable) {
    return (
      <TextAreaAutoResize
        resizeAction={resizeAction}
        textareaRef={textareaRef}
        width={width}
        value={value}
        placeholder={placeholder}
        setValue={setValue}
      />
    )
  }

  return (
    <Box sx={{ width }}>
      {value.split('\n').map((el, index) => (
        <Text sx={{ wordBreak: 'break-all' }} key={index}>
          {el}
        </Text>
      ))}
    </Box>
  )
}
