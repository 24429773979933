import React, { useRef, useState } from 'react'

import { Offset, Placement } from 'react-overlays/esm/usePopper'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Image } from '@plco-pro/components/atoms/image'
import { Text } from '@plco-pro/components/atoms/text'
import { OverlayWrapper } from '@plco-pro/components/molecules/overlay-wrapper'
import { Calendar } from '@plco-pro/components/organisms/calendar'
import { useMoment } from '@plco-pro/hooks/moment'
import { getScreenSize } from '@plco-pro/providers/responsive'

const CALENDAR_HEIGHT = 320

export type OverlayCalendarProps = {
  value: Date
  onChange: (date: Date) => void
  minDate?: Date
  maxDate?: Date
  buttonProps?: FlexProps
  titleColor?: string
  disabled?: boolean
  placement?: Placement
  offset?: Offset
}

export const OverlayCalendar: React.FunctionComponent<OverlayCalendarProps> = React.memo(
  ({ value, onChange, minDate, maxDate, buttonProps, titleColor, disabled, placement, offset }) => {
    const moment = useMoment()
    const { mdAndDown } = getScreenSize(false)

    const containerRef = useRef<HTMLDivElement>(null)
    const targetRef = useRef<any>(null)

    const [show, setShow] = useState<boolean>(false)
    const [caculatedPlacement, setCaculatedPlacement] = useState<Placement>()

    const showCalendar = () => {
      if (disabled || !containerRef.current) {
        return
      }

      const scrollHeight = containerRef.current.offsetParent?.scrollHeight
      const scrollDistance = containerRef.current.offsetParent?.scrollTop || 0
      const modalHeight = containerRef.current.offsetParent?.clientHeight
      const offsetTop = containerRef.current.offsetTop

      if (!scrollHeight || !offsetTop) {
        return
      }

      if (!placement) {
        setShow(true)

        return
      }

      if (scrollHeight - scrollDistance === modalHeight) {
        setCaculatedPlacement('bottom-start')
      } else if (placement === 'bottom-start') {
        if (scrollDistance < CALENDAR_HEIGHT) {
          setCaculatedPlacement(mdAndDown ? 'top-start' : 'bottom-start')
        } else {
          setCaculatedPlacement(mdAndDown ? 'bottom-start' : 'top-start')
        }
      } else if (placement === 'top-start') {
        setCaculatedPlacement(placement)
      }

      setShow(true)
    }

    return (
      <Box ref={containerRef}>
        <Flex>
          <Flex
            ref={targetRef}
            onClick={showCalendar}
            {...buttonProps}
            sx={{
              alignItems: 'center',
              padding: '8px 11px 8px 14px',
              cursor: 'pointer',
              ...buttonProps?.sx,
            }}
          >
            <Image src={'/images/calendar.svg'} />
            <Text sx={{ mx: 1, color: titleColor }}>{moment(value).format('YYYY.MM.DD')}</Text>
            <Image
              src={'/images/dropdown-arrow.svg'}
              sx={{
                width: '24px',
                minWidth: '24px',
                height: '24px',
                minHeight: '24px',
              }}
            />
          </Flex>
        </Flex>

        <OverlayWrapper
          offset={offset || [10, 4]}
          placement={caculatedPlacement || placement}
          target={targetRef}
          container={containerRef}
          show={show}
          onHide={() => setShow(false)}
          rootClose
        >
          {({ props }) => (
            <Box {...props}>
              <Calendar
                value={value}
                minDate={minDate}
                maxDate={maxDate || moment(new Date()).add(10, 'y').toDate()}
                minDetail={'month'}
                onChange={(date: Date) => {
                  if (!Array.isArray(date)) {
                    onChange(
                      moment(date)
                        .set({ hour: value.getHours(), minute: value.getMinutes() })
                        .toDate(),
                    )
                  }
                  setShow(false)
                }}
              />
            </Box>
          )}
        </OverlayWrapper>
      </Box>
    )
  },
)
