import React, { useCallback } from 'react'

import { useRouter } from 'next/router'

import { Box } from '@plco-pro/components/atoms/box'
import { Button } from '@plco-pro/components/atoms/button'
import { Responsive } from '@plco-pro/components/atoms/responsive'
import { Text } from '@plco-pro/components/atoms/text'
import { Navbar } from '@plco-pro/components/organisms/navbar'
import { useI18n } from '@plco-pro/hooks/i18n'
import { observer } from '@plco-pro/stores'

export type NavbarPlayersProps = {}

export const NavbarPlayerModal: React.FunctionComponent<NavbarPlayersProps> = observer(() => {
  const router = useRouter()
  const { formatMessage: f } = useI18n()

  const handleBackClick = useCallback(() => {
    const { playerId, ...restRouterQuery } = router.query

    router.push({
      query: {
        ...restRouterQuery,
      },
    })
  }, [router])

  return (
    <Navbar sx={{ px: [2, 4, null, null] }}>
      <Responsive smOnly>
        <Text variant={'s2'}>{f({ id: 'navbar-players.player-data' })}</Text>
        <Box sx={{ flex: '1 0 auto' }} />
        <Button variant={'secondary'} color={'primary'} onClick={handleBackClick}>
          {f({ id: 'navbar-players.back' })}
        </Button>
      </Responsive>

      <Responsive mdAndUp>
        <Text variant={'h5'}>{f({ id: 'navbar-players.player-data' })}</Text>
        <Box sx={{ flex: '1 0 auto' }} />
        <Button variant={'secondary'} color={'primary'} onClick={handleBackClick}>
          {f({ id: 'navbar-players.back' })}
        </Button>
      </Responsive>
    </Navbar>
  )
})
