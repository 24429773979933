import React from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { Card, CardProps } from '@plco-pro/components/atoms/card'

export type GhostCardProps = CardProps

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const BlinkCard = styled(Card)`
  border-radius: 8px;
  animation: ${blink} 1s ease-in-out infinite;
`

export const GhostCard: React.FunctionComponent<GhostCardProps> = (props) => {
  return <BlinkCard data-testid={'ghost-card'} {...props} />
}
