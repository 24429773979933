import { useMemo } from 'react'

import { Flex, Text } from '@plco-pro/components/atoms'
import { InjuryStatus } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'

interface Props {
  injuryStatus?: InjuryStatus
}

export const ChipInjury = ({ injuryStatus }: Props) => {
  const { formatMessage } = useI18n()

  const injuryChipMap = useMemo(() => {
    if (injuryStatus === InjuryStatus.IN_PROGRESS) {
      return {
        color: 'primary-500',
        bg: 'primary-100',
        text: formatMessage({ id: 'players.card.current-injury.tab' }),
      }
    }

    return {
      color: 'grey-500-80',
      bg: 'grey-500-8',
      text: formatMessage({ id: 'players.card.past-injury.tab' }),
    }
  }, [injuryStatus, formatMessage])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '23px',
        padding: '4px 8px',
        flexShrink: 0,
        borderRadius: '10px',
        bg: injuryChipMap.bg,
      }}
    >
      <Text sx={{ fontWeight: 700 }} variant={'p2'} color={injuryChipMap.color}>
        {injuryChipMap.text}
      </Text>
    </Flex>
  )
}
