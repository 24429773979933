import { moment } from '@common/utils/libs'
import { SnapshotOrInstance, types } from 'mobx-state-tree'

export type WeightUnit = 'kg' | 'lb'
export const WEIGHT_UNITS: WeightUnit[] = ['kg', 'lb']
export type HeightUnit = 'cm' | 'ft' | 'in'
export const HEIGHT_UNITS: HeightUnit[] = ['cm', 'ft', 'in']
export type Language = 'en-KR' | 'ko-KR' | 'ja-KR' | 'vn-VN'
export const LANGUAGES: Language[] = ['ko-KR', 'en-KR', 'ja-KR', 'vn-VN'] // 언어 순서 > drawer 언어 순서
export type Region = 'KR' | 'JP' | 'ID' | 'VN'
export const REGIONS: Region[] = ['KR', 'JP', 'ID', 'VN']

const getClosestSupportedLanguage = (language: string | null, supportedLanguages: Language[]) => {
  if (!language) {
    return supportedLanguages[0]
  }

  let languageCode = language
  if (languageCode.indexOf('-') !== -1) {
    languageCode = languageCode.split('-')[0]
  }

  const foundSupportedLanguage = supportedLanguages.find((supportedLanguage) => {
    const supportedLanguageCode = supportedLanguage.split('-')[0]

    return supportedLanguageCode === languageCode
  })

  return foundSupportedLanguage || supportedLanguages[0]
}

const PreferenceSystemModel = types
  .model('PreferenceSystem', {
    language: types.optional(types.maybeNull(types.string), null),
  })
  .actions((self) => ({
    setLanguage(value: SnapshotOrInstance<typeof self.language>) {
      self.language = value
    },
    afterCreate() {
      const browserLanguage = window.navigator.language
      this.setLanguage(browserLanguage)
    },
  }))

const PreferenceThemeConfigModel = types
  .model('PreferenceThemeConfig', {
    theme: types.optional(types.enumeration(['main']), 'main'),
  })
  .actions((self) => ({
    setTheme(value: SnapshotOrInstance<typeof self.theme>) {
      self.theme = value
    },
  }))

const PreferenceLocaleConfigModel = types
  .model('PreferenceLocaleConfig', {
    language: types.optional(
      types.maybeNull(types.enumeration<Language>(Object.values(LANGUAGES))),
      null,
    ),
    region: types.optional(types.enumeration<Region>(Object.values(REGIONS)), 'KR'),
    timezone: types.optional(types.maybeNull(types.string), null),
  })
  .actions((self) => ({
    setLanguage(value: SnapshotOrInstance<typeof self.language>) {
      self.language = value
    },
    setRegion(value: SnapshotOrInstance<typeof self.region>) {
      self.region = value
    },
    setTimezone(value: SnapshotOrInstance<typeof self.timezone>) {
      self.timezone = value
    },
  }))

const PreferenceUnitConfigModel = types
  .model('PreferenceUnitConfig', {
    weight: types.optional(types.enumeration<WeightUnit>(Object.values(WEIGHT_UNITS)), 'kg'),
    height: types.optional(types.enumeration<HeightUnit>(Object.values(HEIGHT_UNITS)), 'cm'),
  })
  .actions((self) => ({
    setWeight(value: SnapshotOrInstance<typeof self.weight>) {
      self.weight = value
    },
    setHeight(value: SnapshotOrInstance<typeof self.height>) {
      self.height = value
    },
  }))

const PreferenceConfigModel = types.model('PreferenceConfig', {
  theme: types.optional(PreferenceThemeConfigModel, {}),
  locale: types.optional(PreferenceLocaleConfigModel, {}),
  unit: types.optional(PreferenceUnitConfigModel, {}),
})

export const PreferenceStore = types
  .model('Preference', {
    system: types.optional(PreferenceSystemModel, {}),
    config: types.optional(PreferenceConfigModel, {}),
  })
  .views((self) => {
    return {
      get themes() {
        return ['main']
      },
      get theme() {
        const { config } = self

        return config.theme
      },
      get locale() {
        const { system, config } = self

        const computedLocale = {
          get supportedLanguages(): Language[] {
            // order matters, first item will be used as default system language
            return ['ko-KR', 'en-KR', 'ja-KR', 'vn-VN']
          },
          get language() {
            return (
              config.locale.language ||
              getClosestSupportedLanguage(system.language, this.supportedLanguages)
            )
          },
          get regions() {
            return moment.tz.countries()
          },
          get supportedRegion() {
            return ['KR', 'JP', 'ID', 'VN']
          },
          get region() {
            return config.locale.region
          },
          get timezones() {
            return moment.tz.names()
          },
          get regionTimezones() {
            return moment.tz.zonesForCountry(computedLocale.region)
          },
          get timezone() {
            // TODO: 추후에, 자신의 timezone 을 설정할수있는 기능이 나오면, config.timezone 을 우선으로 설정한다.
            // return config.locale.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
            return Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Seoul'
          },
          get utcOffset() {
            return (
              (moment.tz.zone(computedLocale.timezone)?.utcOffset(new Date().getTime()) || 0) / -60
            )
          },
          get code() {
            const languageCode = computedLocale.language.split('-')[0]

            return `${languageCode}-${computedLocale.region}`
          },
        }
        return computedLocale
      },
      get unit() {
        const { config } = self
        return {
          get weights() {
            return WEIGHT_UNITS
          },
          get heights() {
            return HEIGHT_UNITS
          },
          get weight() {
            return config.unit.weight
          },
          get height() {
            return config.unit.weight
          },
        }
      },
    }
  })
