import { type NavigationViewerQuery } from '@plco-pro/graphqls/react.generated'

export const getTeamId = (data: NavigationViewerQuery) => {
  const viewer = data?.sports?.viewer

  return viewer?.teamMaps[0]?.team?.id
}

export const getTeams = (data: NavigationViewerQuery) => {
  const viewer = data?.sports?.viewer

  const teamMapsTeams = viewer?.teamMaps.map((teamMap) => teamMap.team)

  // use data from either teamMaps
  return teamMapsTeams
}
