import { useCallback, useEffect, useMemo } from 'react'

import {
  QueryOperation,
  SportsTeamAuthority,
  TeamUserMapField,
  TeamUserMapFilterInput,
  TeamUserMapForTeamDataField,
  TeamUserMapForTeamDataFilterInput,
} from '@plco-pro/graphqls/react.generated'
import { useMoment } from '@plco-pro/hooks/moment'
import { StoreSystemObjectType, StoreSystemTypeName } from '@plco-pro/hooks/store-system'
import { QuerySystemConfig } from '@plco-pro/maps/chart-system/chart-system.types'
import {
  QuerySystemMap,
  querySystemMap,
  QuerySystemVariablesTypeMap,
} from '@plco-pro/maps/query-system'
import { useStore } from '@plco-pro/stores'

import { useNationality, useViewer } from '.'

import type { LazyQueryResult } from '@apollo/client'

export type QuerySystemReturnType = Omit<LazyQueryResult<any, any>, 'refetch'> & {
  refetch: LazyQueryResult<any, any>['refetch'] | (() => void)
}

export const useQuerySystem: (
  config?: QuerySystemConfig,
  store?: StoreSystemObjectType<StoreSystemTypeName>,
  isDateRange?: boolean,
) => QuerySystemReturnType = (config, store, isDateRange) => {
  const { preference } = useStore()
  const moment = useMoment()
  const nationality = useNationality()
  const { currentActiveTeam } = useViewer()

  const variableMap: Record<keyof QuerySystemMap, () => any> | undefined = useMemo(() => {
    if (!config?.variables) {
      return
    }

    const { groups, names, period, dataName } = config?.variables

    const getUnitOfTime = (period: QuerySystemConfig['period']) => {
      switch (period) {
        case 'DAY':
          return 'days'
        case 'WEEK':
          return 'isoWeek'
        case 'MONTH':
          return 'month'
        default:
          return 'days'
      }
    }

    const getPeriodOfTimeShift = (period: QuerySystemConfig['period']) => {
      switch (period) {
        case 'LAST_7_DAYS':
          return [-6, 'days']
        case 'LAST_28_DAYS':
          return [-27, 'days']
        default:
          return [0]
      }
    }

    const unitOfTime = getUnitOfTime(period)

    return {
      // not call query when variableMap return undefined
      USER_DATA: () => {
        if (!store?.variables?.id) {
          return
        }

        const date = store.variables.date || store.date

        const from = isDateRange
          ? moment(store.startDate).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
          : moment(date)
              .startOf(unitOfTime)
              .add(...getPeriodOfTimeShift(period))
              .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)

        const to = isDateRange
          ? moment(store.endDate).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
          : moment(date).endOf(unitOfTime).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)

        const variables: QuerySystemVariablesTypeMap['USER_DATA'] = {
          input: {
            nationality,
          },
          id: store.variables.id,
          from,
          to,
          groups,
          names,
          timezone: preference.locale.timezone,
        }
        return variables
      },
      USER_WORKLOAD_DATA: () => {
        const date = store?.variables.date || store?.date

        const from = isDateRange
          ? moment(store?.startDate).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
          : moment(date)
              .startOf(unitOfTime)
              .add(...getPeriodOfTimeShift(period))
              .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)

        const to = isDateRange
          ? moment(store?.endDate).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
          : moment(date).endOf(unitOfTime).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)

        const invalidDate = moment(from).isAfter(moment(to))

        if (!store?.variables?.id || !currentActiveTeam?.id || invalidDate) {
          return
        }

        const variables: QuerySystemVariablesTypeMap['USER_WORKLOAD_DATA'] = {
          id: store.variables.id,
          from,
          to,
          timezone: preference.locale.timezone,
          teamId: currentActiveTeam.id,
        }
        return variables
      },
      TEAM_USER_MAPS_DATA: () => {
        if (!store?.variables?.id) {
          return
        }

        const filterInputs: TeamUserMapFilterInput[] = []

        if (store?.variables?.groupId) {
          filterInputs.push({
            expression: {
              field: TeamUserMapField.groupId,
              op: QueryOperation.eq,
              value: store?.variables?.groupId,
            },
          })
        }

        if (store?.variables?.userIds) {
          filterInputs.push({
            expression: {
              field: TeamUserMapField.userId,
              op: QueryOperation.in,
              value: JSON.stringify(store?.variables?.userIds),
            },
          })
        }

        filterInputs.push({
          expression: {
            field: TeamUserMapField.teamAuthority,
            op: QueryOperation.eq,
            value: `PLCO:${SportsTeamAuthority.MEMBER}`,
          },
        })

        const variables: QuerySystemVariablesTypeMap['TEAM_USER_MAPS_DATA'] = {
          input: {
            nationality,
          },
          id: store.variables.id,
          cursor: store.variables.cursor,
          offset: store.variables.offset,
          limit: store.variables.limit,
          timezone: preference.locale.timezone,
          groups,
          names,
          filter: {
            and: filterInputs,
          },
          order: store.variables.order,
        }

        return variables
      },
      TEAM_AVG_DATA: () => {
        if (!store?.variables?.id) {
          return
        }

        const filterInputs: TeamUserMapForTeamDataFilterInput[] = []

        if (store?.variables?.groupId) {
          filterInputs.push({
            expression: {
              field: TeamUserMapForTeamDataField.groupId,
              op: QueryOperation.eq,
              value: store?.variables?.groupId,
            },
          })
        }

        if (store?.variables?.userIds) {
          filterInputs.push({
            expression: {
              field: TeamUserMapForTeamDataField.userId,
              op: QueryOperation.in,
              value: JSON.stringify(store?.variables?.userIds),
            },
          })
        }

        const date = store.variables?.date || store.date

        const from = isDateRange
          ? moment(store.startDate).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
          : moment(date)
              .startOf(unitOfTime)
              .add(...getPeriodOfTimeShift(period))
              .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
        const to = isDateRange
          ? moment(store.endDate).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
          : moment(date).endOf(unitOfTime).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)

        const variables: QuerySystemVariablesTypeMap['TEAM_AVG_DATA'] = {
          id: store.variables.id,
          from,
          to,
          names,
          timezone: preference.locale.timezone,
          filter: {
            and: filterInputs,
          },
        }

        return variables
      },
      TEAM_MONITORING: () => {
        if (!store?.variables?.id) {
          return
        }

        const filterInputs: TeamUserMapForTeamDataFilterInput[] = []

        if (store?.variables?.groupId) {
          filterInputs.push({
            expression: {
              field: TeamUserMapForTeamDataField.groupId,
              op: QueryOperation.eq,
              value: store?.variables?.groupId,
            },
          })
        }
        if (store?.variables?.userIds) {
          filterInputs.push({
            expression: {
              field: TeamUserMapForTeamDataField.userId,
              op: QueryOperation.in,
              value: JSON.stringify(store?.variables?.userIds),
            },
          })
        }
        filterInputs.push({
          expression: {
            field: TeamUserMapForTeamDataField.teamAuthority,
            op: QueryOperation.eq,
            value: `PLCO:${SportsTeamAuthority.MEMBER}`,
          },
        })

        const variables: QuerySystemVariablesTypeMap['TEAM_MONITORING'] = {
          input: {
            nationality,
          },
          teamId: store.variables.id,
          cursor: store.variables.cursor,
          timezone: preference.locale.timezone,
          dataName,
          filter: {
            and: filterInputs,
          },
        }

        return variables
      },
    }
    // eslint-disable-next-line max-len
  }, [
    config?.variables,
    store?.variables.id,
    store?.variables.date,
    store?.variables?.groupId,
    store?.variables?.userIds,
    store?.variables.cursor,
    store?.variables.offset,
    store?.variables.limit,
    store?.variables.order,
    store?.date,
    store?.startDate,
    store?.endDate,
    isDateRange,
    moment,
    nationality,
    preference.locale.timezone,
    currentActiveTeam?.id,
  ])

  const { query, variables } = useMemo(() => {
    const configName = (config?.name as keyof typeof querySystemMap) || 'USER_DATA' // config.name 값이 없어도 hook 순서의 변화는 없어야 한다.

    return {
      query: querySystemMap[configName](),
      variables: variableMap?.[configName](),
    }
  }, [config, variableMap])

  const [lazyQueryHooks, { called, refetch, ...lazyQueryResult }] = query({
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    // config name 이 없을경우 lazy query 를 호출하지 않는다.
    if (config?.name && variables) {
      const options = {
        ...store?.options,
        variables,
        context: {
          ...config?.context,
          ...store?.options.context,
        },
      }
      lazyQueryHooks(options)
    }
  }, [variables, lazyQueryHooks, store?.options, config?.context, config?.name])

  const refetchQuery = useCallback(() => {
    if (called) {
      refetch()
    }
  }, [called, refetch])

  return { ...lazyQueryResult, called, refetch: refetchQuery }
}
