import React, { Fragment } from 'react'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Image } from '@plco-pro/components/atoms/image'
import { Text } from '@plco-pro/components/atoms/text'
import { SportsTeamAuthority } from '@plco-pro/graphqls/react.generated'
import { useViewer } from '@plco-pro/hooks'
import { useI18n } from '@plco-pro/hooks/i18n'

export type UnauthorizedProps = {}

export const Unauthorized: React.FunctionComponent<UnauthorizedProps> = () => {
  const { formatMessage: f } = useI18n()
  const { currentActiveTeam } = useViewer()

  return (
    <Flex sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ width: ['100%', 360, 360, 360], mx: [2, 0, 0, 0] }}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Image src={'/images/403.svg'} sx={{ width: '100%', mb: 4 }} />

          <Text variant={'h4'} sx={{ textAlign: 'center', mb: 1 }}>
            {f({ id: 'unauthorized.title' })}
          </Text>

          {currentActiveTeam?.viewerAuthority === SportsTeamAuthority.PENDING_MEMBER && (
            <Fragment>
              <Text sx={{ textAlign: 'center' }}>{f({ id: 'unauthorized.subtitle.first' })}</Text>

              <Text sx={{ textAlign: 'center' }}>{f({ id: 'unauthorized.subtitle.second' })}</Text>
            </Fragment>
          )}
        </Flex>
      </Box>
    </Flex>
  )
}
