import { Fragment, useMemo } from 'react'

import { FeedbackWorkloadRiskAtInjuryData } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'

import { Box, Divider, Flex } from '../../atoms'
import { DescriptionBox } from '../components'
import { FeedbackContentsType } from '../helpers'
import { PlcoSuggestion } from './plco-suggestion'
import { WorkloadReasonChip } from './workload-reason-chip'

interface InjuryRiskProps {
  // 이름
  name: string
  feedbackContentsType: FeedbackContentsType
  workloadRiskAtInjuryData: FeedbackWorkloadRiskAtInjuryData
}

export const InjuryRisk = ({
  name: player,
  feedbackContentsType,
  workloadRiskAtInjuryData,
}: InjuryRiskProps) => {
  const { formatMessage: f } = useI18n()
  const { analysisType, reasonList, label } = workloadRiskAtInjuryData

  const cause = useMemo(() => {
    return f({ id: `feedback.injury.reason.${reasonList[0]}` })
  }, [f, reasonList])

  const count = useMemo(() => {
    return reasonList.length - 1
  }, [reasonList])

  const status = f({ id: `feedback.type.${label}` })

  return (
    <Fragment>
      <Box sx={{ mx: [2, 3] }}>
        <Flex sx={{ flexWrap: 'wrap', gap: '4px', mb: '12px' }}>
          {reasonList.map((reason) => (
            <WorkloadReasonChip key={reason} type={reason} />
          ))}
        </Flex>

        <DescriptionBox feedbackContentsType={feedbackContentsType} sx={{ mb: 2 }}>
          {f({ id: 'feedback.player-status-description' }, { player, cause, count, status })}
        </DescriptionBox>

        <PlcoSuggestion
          feedbackContentsType={feedbackContentsType}
          suggestion={f({ id: `dashboard.data.risk-of-injury.suggestion.${analysisType}` })}
        />
      </Box>
      {feedbackContentsType === 'add' && <Divider sx={{ height: '16px', mt: 1, mb: 2 }} />}
    </Fragment>
  )
}
