import { DataInterpretation, DataInterpretationRanges } from './data-interpreter.types'

// DO NOT MANUALLY EDIT BELLOW DATA
// see "data-interpreter-mapper.js" file for details on how to update data
// bellow data is generated by data-interpreter-mapper.ts
export const dataInterpretations: DataInterpretation[] = [
  {
    paths: ['READINESS', 'READINESS.total'],
    ranges: {
      '[0,50)': {
        priority: 1,
        degree: 'DANGER',
        label: 'READINESS.[0,50).label',
        description: 'READINESS.[0,50).description',
      },
      '[50,60)': {
        priority: 1,
        degree: 'DANGER',
        label: 'READINESS.[50,60).label',
        description: 'READINESS.[50,60).description',
      },
      '[60,75)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'READINESS.[60,75).label',
        description: 'READINESS.[60,75).description',
      },
      '[75,85)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'READINESS.[75,85).label',
        description: 'READINESS.[75,85).description',
      },
      '[85,100]': {
        priority: 2,
        degree: 'GOOD',
        label: 'READINESS.[85,100].label',
        description: 'READINESS.[85,100].description',
      },
    },
  },
  {
    paths: ['CONDITION', 'CONDITION_WEEKLY_AVG', 'CONDITION_MONTHLY_AVG'],
    ranges: {
      '[1,2)': {
        priority: 2,
        degree: 'DANGER',
        label: 'CONDITION.[1,2).label',
        description: 'CONDITION.[1,2).description',
      },
      '[2,4)': {
        priority: 2,
        degree: 'DANGER',
        label: 'CONDITION.[2,4).label',
        description: 'CONDITION.[2,4).description',
      },
      '[4,7)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'CONDITION.[4,7).label',
        description: 'CONDITION.[4,7).description',
      },
      '[7,9)': {
        priority: 8,
        degree: 'GOOD',
        label: 'CONDITION.[7,9).label',
        description: 'CONDITION.[7,9).description',
      },
      '[9,10]': {
        priority: 8,
        degree: 'GOOD',
        label: 'CONDITION.[9,10].label',
        description: 'CONDITION.[9,10].description',
      },
    },
  },
  {
    paths: [
      'WELLNESS_MUSCLE',
      'CONDITION.muscle',
      'WELLNESS_WEEKLY_STAT.muscle.min',
      'WELLNESS_WEEKLY_STAT.muscle.max',
      'WELLNESS_WEEKLY_STAT.muscle.avg',
      'WELLNESS_MONTHLY_STAT.muscle.min',
      'WELLNESS_MONTHLY_STAT.muscle.max',
      'WELLNESS_MONTHLY_STAT.muscle.avg',
    ],
    ranges: {
      '[1,3)': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'WELLNESS_MUSCLE.[1,3).label',
        description: 'WELLNESS_MUSCLE.[1,3).description',
      },
      '[3,5)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'WELLNESS_MUSCLE.[3,5).label',
        description: 'WELLNESS_MUSCLE.[3,5).description',
      },
      '[5,7)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WELLNESS_MUSCLE.[5,7).label',
        description: 'WELLNESS_MUSCLE.[5,7).description',
      },
      '[7,9)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WELLNESS_MUSCLE.[7,9).label',
        description: 'WELLNESS_MUSCLE.[7,9).description',
      },
      '[9,10]': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WELLNESS_MUSCLE.[9,10].label',
        description: 'WELLNESS_MUSCLE.[9,10].description',
      },
    },
  },
  {
    paths: [
      'WELLNESS_MOOD',
      'CONDITION.mood',
      'WELLNESS_WEEKLY_STAT.mood.min',
      'WELLNESS_WEEKLY_STAT.mood.max',
      'WELLNESS_WEEKLY_STAT.mood.avg',
      'WELLNESS_MONTHLY_STAT.mood.min',
      'WELLNESS_MONTHLY_STAT.mood.max',
      'WELLNESS_MONTHLY_STAT.mood.avg',
    ],
    ranges: {
      '[1,3)': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'WELLNESS_MOOD.[1,3).label',
        description: 'WELLNESS_MOOD.[1,3).description',
      },
      '[3,5)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'WELLNESS_MOOD.[3,5).label',
        description: 'WELLNESS_MOOD.[3,5).description',
      },
      '[5,7)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WELLNESS_MOOD.[5,7).label',
        description: 'WELLNESS_MOOD.[5,7).description',
      },
      '[7,9)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WELLNESS_MOOD.[7,9).label',
        description: 'WELLNESS_MOOD.[7,9).description',
      },
      '[9,10]': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WELLNESS_MOOD.[9,10].label',
        description: 'WELLNESS_MOOD.[9,10].description',
      },
    },
  },
  {
    paths: [
      'WELLNESS_FATIGUE',
      'CONDITION.fatigue',
      'WELLNESS_WEEKLY_STAT.fatigue.min',
      'WELLNESS_WEEKLY_STAT.fatigue.max',
      'WELLNESS_WEEKLY_STAT.fatigue.avg',
      'WELLNESS_MONTHLY_STAT.fatigue.min',
      'WELLNESS_MONTHLY_STAT.fatigue.max',
      'WELLNESS_MONTHLY_STAT.fatigue.avg',
    ],
    ranges: {
      '[1,3)': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'WELLNESS_FATIGUE.[1,3).label',
        description: 'WELLNESS_FATIGUE.[1,3).description',
      },
      '[3,5)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'WELLNESS_FATIGUE.[3,5).label',
        description: 'WELLNESS_FATIGUE.[3,5).description',
      },
      '[5,7)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WELLNESS_FATIGUE.[5,7).label',
        description: 'WELLNESS_FATIGUE.[5,7).description',
      },
      '[7,9)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WELLNESS_FATIGUE.[7,9).label',
        description: 'WELLNESS_FATIGUE.[7,9).description',
      },
      '[9,10]': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WELLNESS_FATIGUE.[9,10].label',
        description: 'WELLNESS_FATIGUE.[9,10].description',
      },
    },
  },
  {
    paths: [
      'WELLNESS_STRESS',
      'CONDITION.stress',
      'WELLNESS_WEEKLY_STAT.stress.min',
      'WELLNESS_WEEKLY_STAT.stress.max',
      'WELLNESS_WEEKLY_STAT.stress.avg',
      'WELLNESS_MONTHLY_STAT.stress.min',
      'WELLNESS_MONTHLY_STAT.stress.max',
      'WELLNESS_MONTHLY_STAT.stress.avg',
    ],
    ranges: {
      '[1,3)': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'WELLNESS_STRESS.[1,3).label',
        description: 'WELLNESS_STRESS.[1,3).description',
      },
      '[3,5)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'WELLNESS_STRESS.[3,5).label',
        description: 'WELLNESS_STRESS.[3,5).description',
      },
      '[5,7)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WELLNESS_STRESS.[5,7).label',
        description: 'WELLNESS_STRESS.[5,7).description',
      },
      '[7,9)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WELLNESS_STRESS.[7,9).label',
        description: 'WELLNESS_STRESS.[7,9).description',
      },
      '[9,10]': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WELLNESS_STRESS.[9,10].label',
        description: 'WELLNESS_STRESS.[9,10].description',
      },
    },
  },
  {
    paths: [
      'SLEEP_DURATION',
      'CONDITION.sleepDuration',
      'SLEEP.duration',
      'SLEEP_WEEKLY_STAT.duration.total',
      'SLEEP_WEEKLY_STAT.duration.avg',
      'SLEEP_WEEKLY_STAT.duration.min',
      'SLEEP_WEEKLY_STAT.duration.max',
      'SLEEP_MONTHLY_STAT.duration.total',
      'SLEEP_MONTHLY_STAT.duration.avg',
      'SLEEP_MONTHLY_STAT.duration.min',
      'SLEEP_MONTHLY_STAT.duration.max',
    ],
    ranges: {
      '[0,300)': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'SLEEP_DURATION.[0,300).label',
        description: 'SLEEP_DURATION.[0,300).description',
      },
      '[300,360)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'SLEEP_DURATION.[300,360).label',
        description: 'SLEEP_DURATION.[300,360).description',
      },
      '[360,420)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'SLEEP_DURATION.[360,420).label',
        description: 'SLEEP_DURATION.[360,420).description',
      },
      '[420,480)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'SLEEP_DURATION.[420,480).label',
        description: 'SLEEP_DURATION.[420,480).description',
      },
      '[480,720]': {
        priority: undefined,
        degree: 'GOOD',
        label: 'SLEEP_DURATION.[480,720].label',
        description: 'SLEEP_DURATION.[480,720].description',
      },
    },
  },
  {
    paths: [
      'SLEEP_QUALITY',
      'CONDITION.sleepQuality',
      'SLEEP.quality',
      'SLEEP_WEEKLY_STAT.quality.avg',
      'SLEEP_WEEKLY_STAT.quality.min',
      'SLEEP_WEEKLY_STAT.quality.max',
      'SLEEP_MONTHLY_STAT.quality.avg',
      'SLEEP_MONTHLY_STAT.quality.min',
      'SLEEP_MONTHLY_STAT.quality.max',
    ],
    ranges: {
      '[1,3)': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'SLEEP_QUALITY.[1,3).label',
        description: 'SLEEP_QUALITY.[1,3).description',
      },
      '[3,5)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'SLEEP_QUALITY.[3,5).label',
        description: 'SLEEP_QUALITY.[3,5).description',
      },
      '[5,7)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'SLEEP_QUALITY.[5,7).label',
        description: 'SLEEP_QUALITY.[5,7).description',
      },
      '[7,9)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'SLEEP_QUALITY.[7,9).label',
        description: 'SLEEP_QUALITY.[7,9).description',
      },
      '[9,10]': {
        priority: undefined,
        degree: 'GOOD',
        label: 'SLEEP_QUALITY.[9,10].label',
        description: 'SLEEP_QUALITY.[9,10].description',
      },
    },
  },
  {
    paths: ['CONDITION_AX'],
    ranges: {
      '[1,2)': {
        priority: 5,
        degree: 'DANGER',
        label: 'CONDITION_AX.[1,2).label',
        description: 'CONDITION_AX.[1,2).description',
      },
      '[2,4)': {
        priority: 5,
        degree: 'DANGER',
        label: 'CONDITION_AX.[2,4).label',
        description: 'CONDITION_AX.[2,4).description',
      },
      '[4,7)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'CONDITION_AX.[4,7).label',
        description: 'CONDITION_AX.[4,7).description',
      },
      '[7,9)': {
        priority: 9,
        degree: 'GOOD',
        label: 'CONDITION_AX.[7,9).label',
        description: 'CONDITION_AX.[7,9).description',
      },
      '[9,10]': {
        priority: 9,
        degree: 'GOOD',
        label: 'CONDITION_AX.[9,10].label',
        description: 'CONDITION_AX.[9,10].description',
      },
    },
  },
  {
    paths: ['CONDITION_CX'],
    ranges: {
      '[1,2)': {
        priority: 6,
        degree: 'DANGER',
        label: 'CONDITION_CX.[1,2).label',
        description: 'CONDITION_CX.[1,2).description',
      },
      '[2,4)': {
        priority: 6,
        degree: 'DANGER',
        label: 'CONDITION_CX.[2,4).label',
        description: 'CONDITION_CX.[2,4).description',
      },
      '[4,7)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'CONDITION_CX.[4,7).label',
        description: 'CONDITION_CX.[4,7).description',
      },
      '[7,9)': {
        priority: 10,
        degree: 'GOOD',
        label: 'CONDITION_CX.[7,9).label',
        description: 'CONDITION_CX.[7,9).description',
      },
      '[9,10]': {
        priority: 10,
        degree: 'GOOD',
        label: 'CONDITION_CX.[9,10].label',
        description: 'CONDITION_CX.[9,10].description',
      },
    },
  },
  {
    paths: [
      'WORKLOAD',
      'WORKLOAD_X',
      'WORKLOAD_WEEKLY_STAT.workload.total',
      'WORKLOAD_WEEKLY_STAT.workload.avg',
      'WORKLOAD_WEEKLY_STAT.workload.min',
      'WORKLOAD_WEEKLY_STAT.workload.max',
      'WORKLOAD_MONTHLY_STAT.workload.total',
      'WORKLOAD_MONTHLY_STAT.workload.avg',
      'WORKLOAD_MONTHLY_STAT.workload.min',
      'WORKLOAD_MONTHLY_STAT.workload.max',
    ],
    ranges: {
      '[0,201)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD.[0,201).label',
        description: 'WORKLOAD.[0,201).description',
      },
      '[201,401)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD.[201,401).label',
        description: 'WORKLOAD.[201,401).description',
      },
      '[401,801)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD.[401,801).label',
        description: 'WORKLOAD.[401,801).description',
      },
      '[801,1001)': {
        priority: 2,
        degree: 'WARNING',
        label: 'WORKLOAD.[801,1001).label',
        description: 'WORKLOAD.[801,1001).description',
      },
      '[1001,Infinity]': {
        priority: 2,
        degree: 'DANGER',
        label: 'WORKLOAD.[1001,Infinity].label',
        description: 'WORKLOAD.[1001,Infinity].description',
      },
    },
  },
  {
    paths: [
      'WORKLOAD_INTENSITY',
      'RAW_WORKLOAD.intensity',
      'WORKLOAD.intensity',
      'WORKLOAD_WEEKLY_STAT.intensity.total',
      'WORKLOAD_WEEKLY_STAT.intensity.avg',
      'WORKLOAD_WEEKLY_STAT.intensity.min',
      'WORKLOAD_WEEKLY_STAT.intensity.max',
      'WORKLOAD_MONTHLY_STAT.intensity.total',
      'WORKLOAD_MONTHLY_STAT.intensity.avg',
      'WORKLOAD_MONTHLY_STAT.intensity.min',
      'WORKLOAD_MONTHLY_STAT.intensity.max',
    ],
    ranges: {
      '[1,2)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_INTENSITY.[1,2).label',
        description: 'WORKLOAD_INTENSITY.[1,2).description',
      },
      '[2,4)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_INTENSITY.[2,4).label',
        description: 'WORKLOAD_INTENSITY.[2,4).description',
      },
      '[4,7)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_INTENSITY.[4,7).label',
        description: 'WORKLOAD_INTENSITY.[4,7).description',
      },
      '[7,8)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'WORKLOAD_INTENSITY.[7,8).label',
        description: 'WORKLOAD_INTENSITY.[7,8).description',
      },
      '[8,10)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'WORKLOAD_INTENSITY.[8,10).label',
        description: 'WORKLOAD_INTENSITY.[8,10).description',
      },
      '[10,10]': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'WORKLOAD_INTENSITY.[10,10].label',
        description: 'WORKLOAD_INTENSITY.[10,10].description',
      },
    },
  },
  {
    paths: ['TRAINING_REPORT_WORKLOAD_INTENSITY'],
    ranges: {
      '[1,2)': {
        priority: 3.1,
        degree: 'NEUTRAL',
        label: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).label',
        description: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).description',
      },
      '[2,4)': {
        priority: 3.1,
        degree: 'NEUTRAL',
        label: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).label',
        description: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).description',
      },
      '[4,7)': {
        priority: 3.1,
        degree: 'NEUTRAL',
        label: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).label',
        description: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).description',
      },
      '[7,8)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).label',
        description: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).description',
      },
      '[8,10)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).label',
        description: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).description',
      },
      '[10,10]': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].label',
        description: 'TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].description',
      },
    },
  },
  {
    paths: [
      'WORKLOAD_DURATION',
      'RAW_WORKLOAD.duration',
      'WORKLOAD.duration',
      'WORKLOAD_WEEKLY_STAT.duration.total',
      'WORKLOAD_WEEKLY_STAT.duration.avg',
      'WORKLOAD_WEEKLY_STAT.duration.min',
      'WORKLOAD_WEEKLY_STAT.duration.max',
      'WORKLOAD_MONTHLY_STAT.duration.total',
      'WORKLOAD_MONTHLY_STAT.duration.avg',
      'WORKLOAD_MONTHLY_STAT.duration.min',
      'WORKLOAD_MONTHLY_STAT.duration.max',
    ],
    ranges: {
      '[0,31)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_DURATION.[0,31).label',
        description: 'WORKLOAD_DURATION.[0,31).description',
      },
      '[31,61)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_DURATION.[31,61).label',
        description: 'WORKLOAD_DURATION.[31,61).description',
      },
      '[61,121)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_DURATION.[61,121).label',
        description: 'WORKLOAD_DURATION.[61,121).description',
      },
      '[121,241)': {
        priority: 3.1,
        degree: 'WARNING',
        label: 'WORKLOAD_DURATION.[121,241).label',
        description: 'WORKLOAD_DURATION.[121,241).description',
      },
      '[241,720]': {
        priority: 3.1,
        degree: 'DANGER',
        label: 'WORKLOAD_DURATION.[241,720].label',
        description: 'WORKLOAD_DURATION.[241,720].description',
      },
    },
  },
  {
    paths: [
      'WORKLOAD_SATISFACTION',
      'RAW_WORKLOAD.satisfaction',
      'WORKLOAD.satisfaction',
      'WORKLOAD_WEEKLY_STAT.satisfaction.total',
      'WORKLOAD_WEEKLY_STAT.satisfaction.avg',
      'WORKLOAD_WEEKLY_STAT.satisfaction.min',
      'WORKLOAD_WEEKLY_STAT.satisfaction.max',
      'WORKLOAD_MONTHLY_STAT.satisfaction.total',
      'WORKLOAD_MONTHLY_STAT.satisfaction.avg',
      'WORKLOAD_MONTHLY_STAT.satisfaction.min',
      'WORKLOAD_MONTHLY_STAT.satisfaction.max',
    ],
    ranges: {
      '[1,3)': {
        priority: 7.1,
        degree: 'DANGER',
        label: 'WORKLOAD_SATISFACTION.[1,3).label',
        description: 'WORKLOAD_SATISFACTION.[1,3).description',
      },
      '[3,5)': {
        priority: 7.1,
        degree: 'WARNING',
        label: 'WORKLOAD_SATISFACTION.[3,5).label',
        description: 'WORKLOAD_SATISFACTION.[3,5).description',
      },
      '[5,7)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_SATISFACTION.[5,7).label',
        description: 'WORKLOAD_SATISFACTION.[5,7).description',
      },
      '[7,9)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WORKLOAD_SATISFACTION.[7,9).label',
        description: 'WORKLOAD_SATISFACTION.[7,9).description',
      },
      '[9,10]': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WORKLOAD_SATISFACTION.[9,10].label',
        description: 'WORKLOAD_SATISFACTION.[9,10].description',
      },
    },
  },
  {
    paths: ['WORKLOAD_AX'],
    ranges: {
      '[0,201)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_AX.[0,201).label',
        description: 'WORKLOAD_AX.[0,201).description',
      },
      '[201,401)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_AX.[201,401).label',
        description: 'WORKLOAD_AX.[201,401).description',
      },
      '[401,801)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_AX.[401,801).label',
        description: 'WORKLOAD_AX.[401,801).description',
      },
      '[801,1001)': {
        priority: 5.1,
        degree: 'WARNING',
        label: 'WORKLOAD_AX.[801,1001).label',
        description: 'WORKLOAD_AX.[801,1001).description',
      },
      '[1001,7200]': {
        priority: 5.1,
        degree: 'DANGER',
        label: 'WORKLOAD_AX.[1001,7200].label',
        description: 'WORKLOAD_AX.[1001,7200].description',
      },
    },
  },
  {
    paths: ['WORKLOAD_AXD'],
    ranges: {
      '[2,Infinity]': {
        priority: 5.1,
        degree: 'DANGER',
        label: 'WORKLOAD_AXD.[2,Infinity].label',
        description: 'WORKLOAD_AXD.[2,Infinity].description',
      },
      '[0,2)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WORKLOAD_AXD.[0,2).label',
        description: 'WORKLOAD_AXD.[0,2).description',
      },
    },
  },
  {
    paths: ['WORKLOAD_AXI'],
    ranges: {
      '[0,0.8)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_AXI.[0,0.8).label',
        description: 'WORKLOAD_AXI.[0,0.8).description',
      },
      '[0.8,1.31)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_AXI.[0.8,1.31).label',
        description: 'WORKLOAD_AXI.[0.8,1.31).description',
      },
      '[1.31,1.5)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'WORKLOAD_AXI.[1.31,1.5).label',
        description: 'WORKLOAD_AXI.[1.31,1.5).description',
      },
      '[1.5,Infinity]': {
        priority: 4.1,
        degree: 'DANGER',
        label: 'WORKLOAD_AXI.[1.5,Infinity].label',
        description: 'WORKLOAD_AXI.[1.5,Infinity].description',
      },
    },
  },
  {
    paths: ['WORKLOAD_CX'],
    ranges: {
      '[0,201)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_CX.[0,201).label',
        description: 'WORKLOAD_CX.[0,201).description',
      },
      '[201,401)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_CX.[201,401).label',
        description: 'WORKLOAD_CX.[201,401).description',
      },
      '[401,801)': {
        priority: undefined,
        degree: 'NEUTRAL',
        label: 'WORKLOAD_CX.[401,801).label',
        description: 'WORKLOAD_CX.[401,801).description',
      },
      '[801,1001)': {
        priority: 6,
        degree: 'WARNING',
        label: 'WORKLOAD_CX.[801,1001).label',
        description: 'WORKLOAD_CX.[801,1001).description',
      },
      '[1001,7200]': {
        priority: 6,
        degree: 'DANGER',
        label: 'WORKLOAD_CX.[1001,7200].label',
        description: 'WORKLOAD_CX.[1001,7200].description',
      },
    },
  },
  {
    paths: ['WORKLOAD_ACXR'],
    ranges: {
      '[0,0.8)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'WORKLOAD_ACXR.[0,0.8).label',
        description: 'WORKLOAD_ACXR.[0,0.8).description',
      },
      '[0.8,1.31)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'WORKLOAD_ACXR.[0.8,1.31).label',
        description: 'WORKLOAD_ACXR.[0.8,1.31).description',
      },
      '[1.31,1.5)': {
        priority: 4.2,
        degree: 'WARNING',
        label: 'WORKLOAD_ACXR.[1.31,1.5).label',
        description: 'WORKLOAD_ACXR.[1.31,1.5).description',
      },
      '[1.5,Infinity]': {
        priority: 4.1,
        degree: 'DANGER',
        label: 'WORKLOAD_ACXR.[1.5,Infinity].label',
        description: 'WORKLOAD_ACXR.[1.5,Infinity].description',
      },
    },
  },
  {
    paths: [
      'PARTIAL_INJURY_SORENESS',
      'RAW_INJURY.soreness',
      'INJURY_WEEKLY_STAT.total.soreness.avg',
      'INJURY_WEEKLY_STAT.total.soreness.min',
      'INJURY_WEEKLY_STAT.total.soreness.max',
      'INJURY_MONTHLY_STAT.total.soreness.avg',
      'INJURY_MONTHLY_STAT.total.soreness.min',
      'INJURY_MONTHLY_STAT.total.soreness.max',
      'SORENESS.total',
      'SORENESS.FORE_HEAD',
      'SORENESS.FACE',
      'SORENESS.FRONT_NECK',
      'SORENESS.BACK_HEAD',
      'SORENESS.BACK_NECK',
      'SORENESS.RIGHT_CHEST',
      'SORENESS.LEFT_CHEST',
      'SORENESS.UPPER_ABDOMEN',
      'SORENESS.LOWER_ABDOMEN',
      'SORENESS.RIGHT_RIB',
      'SORENESS.LEFT_RIB',
      'SORENESS.REPRODUCTIVE_ORGANS',
      'SORENESS.RIGHT_WING',
      'SORENESS.LEFT_WING',
      'SORENESS.UPPER_BACK',
      'SORENESS.MIDDLE_BACK',
      'SORENESS.LOWER_BACK',
      'SORENESS.TAILBONE',
      'SORENESS.RIGHT_SHOULDER',
      'SORENESS.RIGHT_UPPER_ARM',
      'SORENESS.RIGHT_FORE_ARM',
      'SORENESS.RIGHT_WRIST',
      'SORENESS.RIGHT_PALM',
      'SORENESS.RIGHT_FINGER',
      'SORENESS.RIGHT_ELBOW',
      'SORENESS.RIGHT_BACK_HAND',
      'SORENESS.LEFT_SHOULDER',
      'SORENESS.LEFT_UPPER_ARM',
      'SORENESS.LEFT_FORE_ARM',
      'SORENESS.LEFT_WRIST',
      'SORENESS.LEFT_PALM',
      'SORENESS.LEFT_FINGER',
      'SORENESS.LEFT_ELBOW',
      'SORENESS.LEFT_BACK_HAND',
      'SORENESS.RIGHT_HIP_JOINT',
      'SORENESS.RIGHT_GROIN',
      'SORENESS.RIGHT_FRONT_THIGH',
      'SORENESS.RIGHT_FRONT_KNEE',
      'SORENESS.RIGHT_FRONT_LEG',
      'SORENESS.RIGHT_ANKLE',
      'SORENESS.RIGHT_INSTEP',
      'SORENESS.RIGHT_TOE',
      'SORENESS.RIGHT_HIP',
      'SORENESS.RIGHT_BACK_THIGH',
      'SORENESS.RIGHT_BACK_KNEE',
      'SORENESS.RIGHT_BACK_LEG',
      'SORENESS.RIGHT_ACHILLES_TENDON',
      'SORENESS.RIGHT_SOLE',
      'SORENESS.LEFT_HIP_JOINT',
      'SORENESS.LEFT_GROIN',
      'SORENESS.LEFT_FRONT_THIGH',
      'SORENESS.LEFT_FRONT_KNEE',
      'SORENESS.LEFT_FRONT_LEG',
      'SORENESS.LEFT_ANKLE',
      'SORENESS.LEFT_INSTEP',
      'SORENESS.LEFT_TOE',
      'SORENESS.LEFT_HIP',
      'SORENESS.LEFT_BACK_THIGH',
      'SORENESS.LEFT_BACK_KNEE',
      'SORENESS.LEFT_BACK_LEG',
      'SORENESS.LEFT_ACHILLES_TENDON',
      'SORENESS.LEFT_SOLE',
      'INJURY.parts.FORE_HEAD.soreness',
      'INJURY.parts.FACE.soreness',
      'INJURY.parts.FRONT_NECK.soreness',
      'INJURY.parts.BACK_HEAD.soreness',
      'INJURY.parts.BACK_NECK.soreness',
      'INJURY.parts.RIGHT_CHEST.soreness',
      'INJURY.parts.LEFT_CHEST.soreness',
      'INJURY.parts.UPPER_ABDOMEN.soreness',
      'INJURY.parts.LOWER_ABDOMEN.soreness',
      'INJURY.parts.RIGHT_RIB.soreness',
      'INJURY.parts.LEFT_RIB.soreness',
      'INJURY.parts.REPRODUCTIVE_ORGANS.soreness',
      'INJURY.parts.RIGHT_WING.soreness',
      'INJURY.parts.LEFT_WING.soreness',
      'INJURY.parts.UPPER_BACK.soreness',
      'INJURY.parts.MIDDLE_BACK.soreness',
      'INJURY.parts.LOWER_BACK.soreness',
      'INJURY.parts.TAILBONE.soreness',
      'INJURY.parts.RIGHT_SHOULDER.soreness',
      'INJURY.parts.RIGHT_UPPER_ARM.soreness',
      'INJURY.parts.RIGHT_FORE_ARM.soreness',
      'INJURY.parts.RIGHT_WRIST.soreness',
      'INJURY.parts.RIGHT_PALM.soreness',
      'INJURY.parts.RIGHT_FINGER.soreness',
      'INJURY.parts.RIGHT_ELBOW.soreness',
      'INJURY.parts.RIGHT_BACK_HAND.soreness',
      'INJURY.parts.LEFT_SHOULDER.soreness',
      'INJURY.parts.LEFT_UPPER_ARM.soreness',
      'INJURY.parts.LEFT_FORE_ARM.soreness',
      'INJURY.parts.LEFT_WRIST.soreness',
      'INJURY.parts.LEFT_PALM.soreness',
      'INJURY.parts.LEFT_FINGER.soreness',
      'INJURY.parts.LEFT_ELBOW.soreness',
      'INJURY.parts.LEFT_BACK_HAND.soreness',
      'INJURY.parts.RIGHT_HIP_JOINT.soreness',
      'INJURY.parts.RIGHT_GROIN.soreness',
      'INJURY.parts.RIGHT_FRONT_THIGH.soreness',
      'INJURY.parts.RIGHT_FRONT_KNEE.soreness',
      'INJURY.parts.RIGHT_FRONT_LEG.soreness',
      'INJURY.parts.RIGHT_ANKLE.soreness',
      'INJURY.parts.RIGHT_INSTEP.soreness',
      'INJURY.parts.RIGHT_TOE.soreness',
      'INJURY.parts.RIGHT_HIP.soreness',
      'INJURY.parts.RIGHT_BACK_THIGH.soreness',
      'INJURY.parts.RIGHT_BACK_KNEE.soreness',
      'INJURY.parts.RIGHT_BACK_LEG.soreness',
      'INJURY.parts.RIGHT_ACHILLES_TENDON.soreness',
      'INJURY.parts.RIGHT_SOLE.soreness',
      'INJURY.parts.LEFT_HIP_JOINT.soreness',
      'INJURY.parts.LEFT_GROIN.soreness',
      'INJURY.parts.LEFT_FRONT_THIGH.soreness',
      'INJURY.parts.LEFT_FRONT_KNEE.soreness',
      'INJURY.parts.LEFT_FRONT_LEG.soreness',
      'INJURY.parts.LEFT_ANKLE.soreness',
      'INJURY.parts.LEFT_INSTEP.soreness',
      'INJURY.parts.LEFT_TOE.soreness',
      'INJURY.parts.LEFT_HIP.soreness',
      'INJURY.parts.LEFT_BACK_THIGH.soreness',
      'INJURY.parts.LEFT_BACK_KNEE.soreness',
      'INJURY.parts.LEFT_BACK_LEG.soreness',
      'INJURY.parts.LEFT_ACHILLES_TENDON.soreness',
      'INJURY.parts.LEFT_SOLE.soreness',
    ],
    ranges: {
      '[0,1)': {
        priority: undefined,
        degree: 'NONE',
        label: 'PARTIAL_INJURY_SORENESS.[0,1).label',
        description: 'PARTIAL_INJURY_SORENESS.[0,1).description',
      },
      '[1,2)': {
        priority: undefined,
        degree: 'INJURY1',
        label: 'PARTIAL_INJURY_SORENESS.[1,2).label',
        description: 'PARTIAL_INJURY_SORENESS.[1,2).description',
      },
      '[2,3)': {
        priority: undefined,
        degree: 'INJURY2',
        label: 'PARTIAL_INJURY_SORENESS.[2,3).label',
        description: 'PARTIAL_INJURY_SORENESS.[2,3).description',
      },
      '[3,4)': {
        priority: 2.3,
        degree: 'INJURY3',
        label: 'PARTIAL_INJURY_SORENESS.[3,4).label',
        description: 'PARTIAL_INJURY_SORENESS.[3,4).description',
      },
      '[4,5)': {
        priority: 2.2,
        degree: 'INJURY4',
        label: 'PARTIAL_INJURY_SORENESS.[4,5).label',
        description: 'PARTIAL_INJURY_SORENESS.[4,5).description',
      },
      '[5,5]': {
        priority: 2.1,
        degree: 'INJURY5',
        label: 'PARTIAL_INJURY_SORENESS.[5,5].label',
        description: 'PARTIAL_INJURY_SORENESS.[5,5].description',
      },
    },
  },
  {
    paths: ['BODY_BMI', 'RAW_BODY.BMI', 'BODY_WEEKLY_STAT.BMI', 'BODY_MONTHLY_STAT.BMI'],
    ranges: {
      '[0,18.5)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'BODY_BMI.[0,18.5).label',
        description: 'BODY_BMI.[0,18.5).description',
      },
      '[18.5,23)': {
        priority: undefined,
        degree: 'GOOD',
        label: 'BODY_BMI.[18.5,23).label',
        description: 'BODY_BMI.[18.5,23).description',
      },
      '[23,25)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'BODY_BMI.[23,25).label',
        description: 'BODY_BMI.[23,25).description',
      },
      '[25,30)': {
        priority: undefined,
        degree: 'WARNING',
        label: 'BODY_BMI.[25,30).label',
        description: 'BODY_BMI.[25,30).description',
      },
      '[30,40)': {
        priority: undefined,
        degree: 'DANGER',
        label: 'BODY_BMI.[30,40).label',
        description: 'BODY_BMI.[30,40).description',
      },
      '[40,Infinity]': {
        priority: undefined,
        degree: 'DANGER',
        label: 'BODY_BMI.[40,Infinity].label',
        description: 'BODY_BMI.[40,Infinity].description',
      },
    },
  },
]

// patch data not present in the Google Sheet but manually added here
dataInterpretations.push({
  paths: [
    'BODY_RECOMMENDED_HYDRATION',
    'BODY.recommendedHydration',
    'RAW_BODY.recommendedHydration',
    'BODY_WEEKLY_STAT.recommendedHydration',
    'BODY_MONTHLY_STAT.recommendedHydration',
  ],
  ranges: {
    '[0,Infinity]': {
      label: undefined,
      description: undefined,
      degree: 'NONE',
      priority: undefined,
    },
  },
})

// map each path to its corresponding ranges (denormalize)
export const dataInterpretationMap = new Map<
  string,
  { ranges: DataInterpretationRanges; name: string }
>()
for (const { paths, ranges } of dataInterpretations) {
  for (const path of paths) {
    if (dataInterpretationMap.has(path)) {
      dataInterpretationMap.set(path, {
        // use first path of interpretation items as a representative name of data
        name: paths[0],
        // merge ranges when path is used more than once
        ranges: {
          ...dataInterpretationMap.get(path)?.ranges,
          ...ranges,
        },
      })
    } else {
      dataInterpretationMap.set(path, {
        // use first path of interpretation items as a representative name of data
        name: paths[0],
        ranges,
      })
    }
  }
}
