import { useMemo } from 'react'

import { FlexProps } from 'theme-ui'

import { Flex, Text } from '@plco-pro/components/atoms'
import { NoWorkoutType, WorkoutType } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import theme from '@plco-pro/themes/main'

export type WorkoutLabelType = NoWorkoutType | WorkoutType | undefined

type Props = FlexProps & {
  type?: WorkoutLabelType
}

export const ChipWorkoutStatus = ({ type }: Props) => {
  const { formatMessage } = useI18n()

  const chipInfo = useMemo(() => {
    switch (type) {
      case NoWorkoutType.REST:
        return {
          color: theme.colors['green-600'],
          text: formatMessage({ id: 'DATA.REST' }),
        }
      case NoWorkoutType.INJURY:
        return {
          color: theme.colors['soft-red-400'],
          text: formatMessage({ id: 'DATA.INJURY' }),
        }
      case WorkoutType.NORMAL:
        return {
          color: theme.colors['grey-500-64'],
          text: formatMessage({ id: 'DATA.NORMAL' }),
        }
      case WorkoutType.REHAB:
        return {
          color: theme.colors['primary-300'],
          text: formatMessage({ id: 'DATA.REHAB' }),
        }
    }
  }, [type, formatMessage])

  return (
    <Flex
      sx={{
        alignItems: 'center',
        py: '2px',
        px: '8px',
        borderRadius: '8px',
        bg: 'grey-50',
        height: '100%',
      }}
    >
      <Text sx={{ fontSize: '11px', fontWeight: 'bold' }} color={chipInfo?.color}>
        {chipInfo?.text}
      </Text>
    </Flex>
  )
}
