import { Fragment, ReactElement, ReactNode, useMemo } from 'react'

import cookie from 'cookie'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Head from 'next/head'

import { FeedbackFab } from '@plco-pro/components/molecules/feedback-fab'
import { ModalInjuryHistory } from '@plco-pro/components/pages/players/[id]/modal-injury-history'
import { ModalPlayer } from '@plco-pro/components/pages/players/[id]/modal-player'
import { config } from '@plco-pro/config'
import { AnalyticsProvider } from '@plco-pro/providers/analytics'
import { AuthenticationProvider } from '@plco-pro/providers/authentication'
import AuthorizationProvider from '@plco-pro/providers/authorization'
import ChannelTalkProvider from '@plco-pro/providers/channel-talk'
import { DrawerProvider } from '@plco-pro/providers/drawer'
import { EmotionProvider } from '@plco-pro/providers/emotion'
import I18nProvider from '@plco-pro/providers/i18n'
import { KeycloakProvider } from '@plco-pro/providers/keycloak'
import NavigationProvider from '@plco-pro/providers/navigation'
import QueryParamsProvider from '@plco-pro/providers/query-params'
import ResponsiveProvider from '@plco-pro/providers/responsive'
import RouterProvider from '@plco-pro/providers/router'
import ThemeProvider from '@plco-pro/providers/theme'
import globalStyle from '@plco-pro/styles/global'
import resetStyle from '@plco-pro/styles/reset'

import type { AppContext, AppProps as NextAppProps } from 'next/app'

import 'rsuite/dist/rsuite-no-reset.min.css'

const StoreProvider = dynamic(() => import('@plco-pro/stores/store'), { ssr: false })

const GraphQLProvider = dynamic(() => import('@plco-pro/graphqls/provider'), { ssr: false })

const ToastProvider = dynamic(() => import('@plco-pro/providers/toast'), { ssr: false })

const SystemProvider = dynamic(() => import('@plco-pro/providers/system'), { ssr: false })

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppProps = NextAppProps & {
  cookies: unknown
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps, cookies }: AppPropsWithLayout) => {
  const { store, i18n } = config

  const title = useMemo(() => {
    if (config.env === 'development') {
      return 'PLCO COACH - DEV'
    } else if (config.env === 'stage') {
      return 'PLCO COACH - STAGE'
    } else if (config.env === 'production') {
      return 'PLCO COACH'
    } else {
      return 'PLCO COACH'
    }
  }, [])

  const getLayout = Component.getLayout || ((page) => page)

  return (
    <Fragment>
      <EmotionProvider styles={`${resetStyle}${globalStyle}`} />

      <Head>
        {/* metadata */}
        <title>{title}</title>

        <meta
          name={'viewport'}
          content={'width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0'}
        />

        <meta name={'robots'} content={'noindex, nofollow'} />
      </Head>

      <KeycloakProvider cookies={cookies}>
        <StoreProvider {...store}>
          <I18nProvider {...i18n}>
            <ThemeProvider>
              <GraphQLProvider>
                <AnalyticsProvider>
                  <ChannelTalkProvider>
                    <RouterProvider>
                      <QueryParamsProvider>
                        <AuthenticationProvider>
                          <AuthorizationProvider>
                            <SystemProvider>
                              <NavigationProvider>
                                <ResponsiveProvider>
                                  <DrawerProvider>
                                    {getLayout(<Component {...pageProps} />)}
                                    <FeedbackFab />
                                  </DrawerProvider>
                                </ResponsiveProvider>
                                <ThemeProvider isPageModal={true}>
                                  <ResponsiveProvider isPageModal={true}>
                                    <ModalPlayer />
                                    <ModalInjuryHistory />
                                  </ResponsiveProvider>
                                </ThemeProvider>
                              </NavigationProvider>
                            </SystemProvider>
                          </AuthorizationProvider>
                        </AuthenticationProvider>
                      </QueryParamsProvider>
                    </RouterProvider>
                  </ChannelTalkProvider>
                </AnalyticsProvider>
              </GraphQLProvider>
              <ToastProvider />
            </ThemeProvider>
          </I18nProvider>
        </StoreProvider>
      </KeycloakProvider>
    </Fragment>
  )
}

App.getInitialProps = async (context: AppContext) => {
  const req = context?.ctx?.req
  const cookies = !req || !req.headers ? {} : cookie.parse(req.headers.cookie || '')

  return {
    cookies,
  }
}

export default App
