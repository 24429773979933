import React, { createContext, FunctionComponent, useContext, useState } from 'react'

export type AnalyticsContext = {
  sessionId: string | null
  setSessionId: (value: string | null) => void
  isIdentified: boolean
  setIsIdentified: (value: boolean) => void
}

export const AnalyticsContext = createContext<AnalyticsContext>({} as any)

export const useAnalyticsContext = () => {
  return useContext(AnalyticsContext)
}

export const AnalyticsProvider: FunctionComponent = (props) => {
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [isIdentified, setIsIdentified] = useState(false)

  return (
    <AnalyticsContext.Provider
      value={{
        sessionId,
        setSessionId,
        isIdentified,
        setIsIdentified,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  )
}
