import React, { ReactNode, useCallback, useMemo } from 'react'

import { ApolloLink, ApolloProvider, NextLink, Operation } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ServerParseError } from '@apollo/client/link/http'
import { ServerError } from '@apollo/client/link/utils'
import { useKeycloak } from '@react-keycloak/ssr'
import { GraphQLError } from 'graphql'

import { createApolloClient } from '@plco-pro/graphqls/client'
import { useLinkError } from '@plco-pro/graphqls/hook'
import { observer, useStore } from '@plco-pro/stores/store'
import { logGql, logTimeout } from '@plco-pro/utils/libs/log'

export {
  ApolloError,
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'

export type GraphQLErrorHandler = (
  error: GraphQLError,
  operation: Operation,
  forward: NextLink,
) => void
export type NetworkErrorHandler = (
  error: Error | ServerError | ServerParseError,
  operation: Operation,
) => void

interface Props {
  children: ReactNode
}

const httpURI = process.env.NEXT_PUBLIC_SERVER_URL || ''

export const GraphQLProvider = observer(({ children }: Props) => {
  const { apiClient, login } = useStore()
  const { keycloak } = useKeycloak()

  const getContext = useCallback(async () => {
    if (keycloak?.authenticated) {
      try {
        await keycloak.updateToken(10)
        return {
          ...Object.fromEntries(apiClient.headers),
          ...(keycloak.token ? { Authorization: `${'Bearer'} ${keycloak.token}` } : {}),
        }
      } catch (error) {
        console.error(error)
        await login()
      }
    } else {
      return Object.fromEntries(apiClient.headers)
    }
  }, [apiClient.headers, keycloak, login])

  // error link
  const {
    handleGraphQLParserFailed,
    handleGraphQLValidationFailed,
    handleBadUserInput,
    handleUnauthenticated,
    handleForbidden,
    handlePersistedQueryNotFound,
    handlePersistedQueryNotSupported,
    handleInternalServerError,
    handleNetworkError,
  } = useLinkError()

  const graphQLErrorHandler: GraphQLErrorHandler = useCallback(
    (error, operation, forward) => {
      if (error.message === '_INVALID_VERSION_') {
        window.location.reload()
        return
      }

      // map error handler to each graphQL error code
      // cf: https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
      switch (error?.extensions?.errorCode || '') {
        case 'GRAPHQL_PARSE_FAILED':
          handleGraphQLParserFailed(error, operation)
          break
        case 'GRAPHQL_VALIDATION_FAILED':
          handleGraphQLValidationFailed(error, operation)
          break
        case 'BAD_USER_INPUT':
          handleBadUserInput(error, operation)
          break
        case 'UNAUTHENTICATED':
          handleUnauthenticated(error, operation, forward)
          break
        case 'FORBIDDEN':
          handleForbidden(error, operation)
          break
        case 'PERSISTED_QUERY_NOT_FOUND':
          handlePersistedQueryNotFound(error, operation)
          break
        case 'PERSISTED_QUERY_NOT_SUPPORTED':
          handlePersistedQueryNotSupported(error, operation)
          break
        case 'INTERNAL_SERVER_ERROR':
          handleInternalServerError(error, operation)
          break
        default:
          break
      }
    },
    [
      handleBadUserInput,
      handleForbidden,
      handleGraphQLParserFailed,
      handleGraphQLValidationFailed,
      handleInternalServerError,
      handlePersistedQueryNotFound,
      handlePersistedQueryNotSupported,
      handleUnauthenticated,
    ],
  )

  const networkErrorHandler: NetworkErrorHandler = useCallback(
    (error, operation) => {
      handleNetworkError(error, operation)
    },
    [handleNetworkError],
  )

  const withErrorLink = (
    graphQLErrorHandler: GraphQLErrorHandler,
    networkErrorHandler: NetworkErrorHandler,
  ) =>
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      // handle GraphQL errors
      if (graphQLErrors) {
        graphQLErrors.forEach((graphQLError) => {
          graphQLErrorHandler(graphQLError, operation, forward)
        })
      }

      // handle Network errors
      if (networkError) {
        networkErrorHandler(networkError, operation)
      }
    })
  const errorLink = useMemo(
    () => withErrorLink(graphQLErrorHandler, networkErrorHandler),
    [graphQLErrorHandler, networkErrorHandler],
  )

  const loggerLink = useMemo(
    () =>
      new ApolloLink((operation, forward) => {
        const timeout = logTimeout(
          {
            operationName: operation.operationName,
            variables: operation.variables,
            query: operation.query.loc?.source.body,
          },
          60 * 1000,
        )

        operation.setContext((context: Record<string, any>) => ({
          headers: {
            ...context?.headers,
            'logger-timeout': timeout,
          },
        }))

        return forward(operation).map((result) => {
          clearTimeout(timeout)

          result.errors?.forEach((error) => {
            logGql(operation, error)
          })

          return result
        })
      }),
    [],
  )

  const client = useMemo(() => {
    return createApolloClient({
      httpURI,
      errorLink,
      getContext,
      loggerLink,
    })
  }, [getContext, errorLink, loggerLink])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
})

export default GraphQLProvider
