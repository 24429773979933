import React from 'react'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Icon } from '@plco-pro/components/atoms/icon'
import { Text } from '@plco-pro/components/atoms/text'
import { DonutData } from '@plco-pro/components/organisms/donut-data'
import { useI18n } from '@plco-pro/hooks/i18n'
import theme from '@plco-pro/themes/main'

export type DonutReadinessProps = {
  readinessValue?: number
  increaseValue?: number
}

export const DonutReadiness: React.FunctionComponent<DonutReadinessProps> = ({
  readinessValue,
  increaseValue = 0,
}) => {
  const { formatMessage: f } = useI18n()

  const arrowColor = increaseValue
    ? increaseValue > 0
      ? theme.colors['red-500']
      : theme.colors['primary-500']
    : theme.colors['grey-300']
  const arrowValue = increaseValue
    ? `${Math.abs(increaseValue)}${f({ id: 'POINT' })}`
    : f({ id: 'players.readiness.previous-day.no-change' })
  const arrowIcon = increaseValue > 0 ? '/images/arrow-drop-up.svg' : '/images/arrow-drop-down.svg'

  return (
    <Box>
      <Flex sx={{ justifyContent: 'center' }}>
        <DonutData value={readinessValue} width={140} path={'READINESS'} />
      </Flex>
      <Flex sx={{ mt: 2, justifyContent: 'center' }}>
        <Text variant={'p1'} appearance={'hint'} sx={{ fontWeight: 'medium' }}>
          {f({ id: 'players.readiness.previous-day' })}
        </Text>
        <Text variant={'p1'} sx={{ color: arrowColor, fontWeight: 'bold', ml: 2 }}>
          {arrowValue}
        </Text>
        {increaseValue !== 0 && <Icon src={arrowIcon} sx={{ color: arrowColor }} size={23} />}
      </Flex>
    </Box>
  )
}
