import { UserDataType } from '@plco-pro/graphqls/react.generated'
import { TrainingChartType } from '@plco-pro/maps/chart-training-report'
import theme from '@plco-pro/themes/main'

import { MonitoringDataType } from '../chart-monitoring'
import { ChartSystemMapValue, ChipType } from './chart-system.types'
import { getAverageDataset } from './monitoring'

export const CHART_SYSTEM_PARTICIPANT_WORKLOAD_MAP_KEY = [
  'USER:PARTICIPANT_WORKLOAD:WORKLOAD:BAR',
  'USER:PARTICIPANT_WORKLOAD:INTENSITY:BAR',
  'USER:PARTICIPANT_WORKLOAD:DURATION:BAR',
  'USER:PARTICIPANT_WORKLOAD:SATISFACTION:BAR',
  'USER:PARTICIPANT_CONDITION:CONDITION:BAR',
  'USER:PARTICIPANT_CONDITION:FATIGUE:BAR',
  'USER:PARTICIPANT_CONDITION:STRESS:BAR',
  'USER:PARTICIPANT_CONDITION:MUSCLE:BAR',
  'USER:PARTICIPANT_CONDITION:MOOD:BAR',
  'USER:PARTICIPANT_CONDITION:DURATION:BAR',
  'USER:PARTICIPANT_CONDITION:QUALITY:BAR',
]

export const chartSystemParticipantWorkloadMap: Record<
  (typeof CHART_SYSTEM_PARTICIPANT_WORKLOAD_MAP_KEY)[number],
  ChartSystemMapValue
> = {
  'USER:PARTICIPANT_WORKLOAD:WORKLOAD:BAR': {
    parser: 'PARTICIPANT_WORKLOAD',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'teal-300',
        colorHover: '#62CAB1',
        noneDataColor: 'teal-100',
        noneDataColorHover: '#A9CEC4',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: TrainingChartType.WORKLOAD,
        chipType: ChipType.NUMBER,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              dataType: UserDataType.workload,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_TICK_VALUES_1600_UP_DOWN',
          },
        },
      },
      getAverageDataset({
        field: 'workload',
        precision: 0,
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'Y_MAX_1600_ROUNDED_UP',
      },
      dataIndex: 0,
      size: 9,
      shift: 9,
    },
  },
  'USER:PARTICIPANT_WORKLOAD:INTENSITY:BAR': {
    parser: 'PARTICIPANT_WORKLOAD',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'amber-200',
        colorHover: '#CDB978',
        noneDataColor: 'amber-100',
        noneDataColorHover: '#CDC29D',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: TrainingChartType.WORKLOAD_INTENSITY,
        chipType: ChipType.TEN,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              dataType: UserDataType.workloadIntensity,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'intensity',
        precision: 1,
        prefix: 'GOAL',
      }),
      getAverageDataset({
        field: 'intensity',
        precision: 1,
        prefix: 'AVG_STRING',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },
  'USER:PARTICIPANT_WORKLOAD:DURATION:BAR': {
    parser: 'PARTICIPANT_WORKLOAD',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'pink-300',
        colorHover: '#CA5A74',
        noneDataColor: 'pink-100',
        noneDataColorHover: '#CCAAB0',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: TrainingChartType.WORKLOAD_DURATION,
        chipType: ChipType.TIME,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_DESC_TICK_VALUES',
            config: {
              dataType: UserDataType.workloadDuration,
            },
          },
          y: {
            orientation: 'LEFT',
            tickFormat: 'HOUR_MINUTE_FORMAT',
            tickValues: 'Y_TICK_VALUES_180_UP_DOWN',
          },
        },
      },
      getAverageDataset({
        field: 'duration',
        precision: 0,
        prefix: 'AVG_STRING',
        format: 'HOUR_MINUTE_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 'DOMAIN_Y_180_UP_DOWN',
      },
      dataIndex: 0,
    },
  },
  'USER:PARTICIPANT_WORKLOAD:SATISFACTION:BAR': {
    parser: 'PARTICIPANT_WORKLOAD',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'WORKLOAD_BAR',
        width: 14,
        color: 'purple-300',
        colorHover: '#CB78CD',
        noneDataColor: 'purple-100',
        noneDataColorHover: '#CCB2CF',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: TrainingChartType.WORKLOAD_SATISFACTION,
        chipType: ChipType.TEN,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              dataType: UserDataType.workloadSatisfaction,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'satisfaction',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
  },

  'USER:PARTICIPANT_CONDITION:CONDITION:BAR': {
    parser: 'PARTICIPANT_CONDITION',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'deep-purple-300',
        colorHover: 'deep-purple-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.CONDITION,
        chipType: ChipType.TEN,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              dataType: UserDataType.condition,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'condition',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.CONDITION,
            color: theme.colors['deep-purple-300'],
            chipType: ChipType.TEN,
            interpretPath: 'condition',
          },
        ],
      },
    },
  },
  'USER:PARTICIPANT_CONDITION:FATIGUE:BAR': {
    parser: 'PARTICIPANT_CONDITION',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'pink-300',
        colorHover: 'pink-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.FATIGUE,
        chipType: ChipType.TEN,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              dataType: UserDataType.fatigue,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'fatigue',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.FATIGUE,
            color: theme.colors['pink-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_FATIGUE',
          },
        ],
      },
    },
  },
  'USER:PARTICIPANT_CONDITION:STRESS:BAR': {
    parser: 'PARTICIPANT_CONDITION',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'violet-300',
        colorHover: 'violet-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.STRESS,
        chipType: ChipType.TEN,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              dataType: UserDataType.stress,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'stress',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.STRESS,
            color: theme.colors['violet-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_STRESS',
            prefix: 'AVG',
          },
        ],
      },
    },
  },
  'USER:PARTICIPANT_CONDITION:MUSCLE:BAR': {
    parser: 'PARTICIPANT_CONDITION',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'teal-300',
        colorHover: 'teal-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.MUSCLE,
        chipType: ChipType.TEN,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              dataType: UserDataType.muscle,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'muscle',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MUSCLE,
            color: theme.colors['teal-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_MUSCLE',
          },
        ],
      },
    },
  },
  'USER:PARTICIPANT_CONDITION:MOOD:BAR': {
    parser: 'PARTICIPANT_CONDITION',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'amber-300',
        colorHover: 'amber-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.MOOD,
        chipType: ChipType.TEN,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              dataType: UserDataType.mood,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'mood',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.MOOD,
            color: theme.colors['amber-300'],
            chipType: ChipType.TEN,
            interpretPath: 'WELLNESS_MOOD',
          },
        ],
      },
    },
  },
  'USER:PARTICIPANT_CONDITION:DURATION:BAR': {
    parser: 'PARTICIPANT_CONDITION',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'indigo-300',
        colorHover: 'indigo-500',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.SLEEP_DURATION,
        chipType: ChipType.TIME,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              dataType: UserDataType.sleepDuration,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_720_TICK_VALUES_DIVIDE_5',
            tickFormat: 'HOUR_FORMAT',
          },
        },
      },
      getAverageDataset({
        field: 'duration',
        precision: 0,
        prefix: 'AVG_STRING',
        format: 'HOUR_MINUTE_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 720,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_DURATION,
            color: theme.colors['indigo-300'],
            chipType: ChipType.TIME,
            interpretPath: 'SLEEP_DURATION',
          },
        ],
      },
    },
  },
  'USER:PARTICIPANT_CONDITION:QUALITY:BAR': {
    parser: 'PARTICIPANT_CONDITION',
    store: 'QUERY_CHART',
    dataset: [
      {
        type: 'BAR',
        width: 14,
        color: 'yellow-400',
        colorHover: 'yellow-600',
        cornerRadius: 'MEDIUM_CORNER_RADIUS',
        animate: 'FAST_CUBIC_IN_OUT',
        drawPrevision: true,
        period: '1_DAY',
        dataType: MonitoringDataType.SLEEP_QUALITY,
        chipType: ChipType.TEN,
        parser: {},
        axis: {
          x: {
            orientation: 'BOTTOM',
            tickLabelComponent: 'LABEL_AVATAR',
            tickValues: 'SORT_ASC_TICK_VALUES',
            config: {
              dataType: UserDataType.sleepQuality,
            },
          },
          y: {
            orientation: 'LEFT',
            tickValues: 'Y_10_TICK_VALUES',
          },
        },
      },
      getAverageDataset({
        field: 'quality',
        precision: 1,
        prefix: 'AVG_STRING',
        format: 'POINT_FORMAT',
      }),
    ],
    domain: {
      y: {
        start: 0,
        end: 10,
      },
      dataIndex: 0,
    },
    label: {
      labelComponent: 'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      config: {
        width: 'BIG_LABEL_TOOLTIP_WIDTH',
        period: '1_DAY',
        items: [
          {
            chartType: 'BAR',
            dataType: MonitoringDataType.SLEEP_QUALITY,
            color: theme.colors['yellow-400'],
            chipType: ChipType.TEN,
            interpretPath: 'SLEEP_QUALITY',
          },
        ],
      },
    },
  },
}
