import { moment } from '@common/utils/libs'
import { toJS } from 'mobx'
import { cast, types } from 'mobx-state-tree'

import {
  QueryOperation,
  TeamUserMapForTeamDataField,
  TeamUserMapForTeamDataFilterInput,
} from '@plco-pro/graphqls/react.generated'
import { TeamChartDataType, chartTeamChartMap } from '@plco-pro/maps/chart-teamChart'
import { get30DaysAgo } from '@plco-pro/utils/date-utils'

export const SEND_CHECK_REMINDER_LOCK_SECONDS = 60 // how many seconds before allowing sending check reminder

export const TeamChartStore = types
  .model('TeamChart', {
    // SHARED
    // query
    reloadedAt: types.optional(types.Date, new Date()),
    // filtering
    groupId: types.optional(types.maybeNull(types.string), null),
    date: types.optional(types.Date, new Date()),
    startDate: types.optional(types.Date, get30DaysAgo()),
    endDate: types.optional(types.Date, new Date()),
    playerIds: types.optional(types.maybeNull(types.array(types.string)), null),
    // CHART
    // query
    chartLoading: types.optional(types.boolean, false),
    chartReload: types.optional(types.boolean, false),
    // CARD TEAM STATUS
    checkConditionReminderSentAt: types.optional(types.maybeNull(types.Date), null),
    checkWorkloadReminderSentAt: types.optional(types.maybeNull(types.Date), null),
  })
  .actions((self) => {
    return {
      // SHARED
      // query
      setReloadedAt(value: Date) {
        self.reloadedAt = value
      },
      // filtering
      setGroupId(value: string | null) {
        self.groupId = value
      },
      onClearFilterGroup() {
        self.groupId = null
      },
      setDate(value: Date) {
        self.date = value
      },
      setDateRange(value: [Date, Date]) {
        const [startDate, endDate] = value
        self.startDate = startDate
        self.endDate = endDate
      },
      setPlayerIds(value: string[] | null) {
        self.playerIds = cast(value)
      },
      // CHART
      // query
      setChartLoading(value: boolean) {
        self.chartLoading = value
      },
      setChartReload(value: boolean) {
        self.chartReload = value
      },

      // CARD TEAM STATUS
      setCheckConditionReminderSentAt(value: Date) {
        self.checkConditionReminderSentAt = value
      },
      setCheckWorkloadReminderSentAt(value: Date) {
        self.checkWorkloadReminderSentAt = value
      },
    }
  })
  .views((self) => {
    return {
      // SHARED
      get cursor() {
        return moment(self.date).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
      },
      get deserializedPlayerIds() {
        return toJS(self.playerIds)
      },
      get dateRange() {
        return [self.startDate, self.endDate]
      },
      get filter() {
        const { groupId, playerIds } = self

        const groupIdFilterInput: TeamUserMapForTeamDataFilterInput | null = groupId
          ? {
              expression: {
                field: TeamUserMapForTeamDataField.groupId,
                op: QueryOperation.eq,
                value: groupId,
              },
            }
          : null

        const userIdFilterInput: TeamUserMapForTeamDataFilterInput | null = playerIds
          ? {
              expression: {
                field: TeamUserMapForTeamDataField.userId,
                op: QueryOperation.in,
                value: JSON.stringify(playerIds),
              },
            }
          : null

        if (!groupIdFilterInput && !userIdFilterInput) return null

        return {
          and: [
            ...(groupIdFilterInput ? [groupIdFilterInput] : []),
            ...(userIdFilterInput ? [userIdFilterInput] : []),
          ],
        }
      },
      // CHART
      chartConfig(dataType: TeamChartDataType) {
        const config = chartTeamChartMap[dataType]

        if (config) {
          return config
        }

        throw new Error('dashboard-store: failed to get chart config')
      },

      // CARD TEAM STATUS
      canSendCheckConditionReminder() {
        if (!self.checkConditionReminderSentAt) {
          return true
        }

        const differenceInSeconds = moment().diff(
          moment(self.checkConditionReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
      canSendCheckWorkloadReminder() {
        if (!self.checkWorkloadReminderSentAt) return true

        const differenceInSeconds = moment().diff(
          moment(self.checkWorkloadReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
    }
  })
