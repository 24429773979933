import { useCallback } from 'react'
import { toast, ToastContent, ToastOptions as ReactToastifyToastOptions } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'

export type ToastOptions = ReactToastifyToastOptions & {
  status: 'info' | 'success' | 'warning' | 'error'
}

export const useToast = () => {
  const showToast = useCallback((text: ToastContent, options?: ToastOptions) => {
    const { status } = options || {}

    if (status === 'success') {
      return toast.success(text, options)
    } else if (status === 'info') {
      return toast.info(text, options)
    } else if (status === 'warning') {
      return toast.warn(text, options)
    } else if (status === 'error') {
      return toast.error(text, options)
    } else {
      return toast.info(text, options)
    }
  }, [])

  const hideToast = useCallback((toastId: string) => {
    toast.dismiss(toastId)
  }, [])

  return {
    showToast,
    hideToast,
  }
}
