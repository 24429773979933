import { useCallback, useMemo } from 'react'

import { SportsTeamAuthority, SubscriptionStatusEnum } from '@plco-pro/graphqls/react.generated'
import { useViewer } from '@plco-pro/hooks/viewer/viewer'
import { PermissionMapKey, roleMap } from '@plco-pro/maps/authorization'
import { useStore } from '@plco-pro/stores'

export type TeamSubscription = {
  status: SubscriptionStatusEnum
  maxPlayerCount: number
  maxTeamCount: number
  isFreeTrial: boolean
  isHyper: boolean
}
export type TeamLicense = {
  id: string
  isActivate: boolean
}

export const useAuthorization = () => {
  const store = useStore()

  const { teamAuthority } = useViewer()

  const userRole: SportsTeamAuthority | null = useMemo(() => {
    return teamAuthority || null
  }, [teamAuthority])

  const {
    teamSubscriptionStatus: status,
    teamSubscriptionMaxTeamCount: maxTeamCount,
    teamSubscriptionMaxPlayerCount: maxPlayerCount,
    teamSubscriptionIsFreeTrial: isFreeTrial,
    isHyper,
  } = store.navigation

  const teamSubscription = useMemo(() => {
    return status === null ||
      maxPlayerCount === null ||
      maxTeamCount === null ||
      isFreeTrial === null
      ? null
      : {
          status,
          maxPlayerCount,
          maxTeamCount,
          isFreeTrial,
          isHyper,
        }
  }, [isFreeTrial, isHyper, maxPlayerCount, maxTeamCount, status])

  const teamLicense: TeamLicense | null = useMemo(() => {
    const id = store.navigation.teamLicenseId
    const isActivate = store.navigation.teamLicenseIsActivate

    if (id === null || isActivate === null) return null

    return {
      id,
      isActivate,
    }
  }, [store.navigation.teamLicenseId, store.navigation.teamLicenseIsActivate])

  const hasRole = useCallback(
    (role: SportsTeamAuthority): boolean => {
      if (!userRole) return false

      return userRole === role
    },
    [userRole],
  )

  const hasPermission = useCallback(
    (permission: PermissionMapKey): boolean => {
      if (!userRole) {
        return false
      }

      return roleMap[permission].includes(userRole)
    },
    [userRole],
  )

  const hasSomePermissions = useCallback(
    (permissions: PermissionMapKey[]): boolean => {
      if (!userRole) {
        return false
      }

      return permissions.some((permission) => roleMap[permission].includes(userRole))
    },
    [userRole],
  )

  const hasEveryPermissions = useCallback(
    (permissions: PermissionMapKey[]): boolean => {
      if (!userRole) {
        return false
      }

      return permissions.every((permission) => roleMap[permission].includes(userRole))
    },
    [userRole],
  )

  const hasValidSubscription = useMemo(() => {
    if (!teamSubscription) {
      return null
    }

    // skip subscription check for roles without requirement
    if (!hasPermission('REQUIRE_VALID_SUBSCRIPTION')) {
      return true
    }

    const { status } = teamSubscription

    return status === 'ACTIVATE' || status === 'GRACE_PERIOD'
  }, [hasPermission, teamSubscription])

  const hasValidLicense = useMemo(() => {
    if (!teamLicense) {
      return null
    }

    const { id, isActivate } = teamLicense

    return id !== null && isActivate
  }, [teamLicense])

  const hasValidPlan = useMemo(() => {
    if (hasValidSubscription === null && hasValidLicense === null) return null

    return (
      (hasValidSubscription === true && hasValidLicense === null) ||
      (hasValidSubscription === null && hasValidLicense === true)
    )
  }, [hasValidLicense, hasValidSubscription])

  const isSubscripting = store.navigation.isSubscripting

  const isAdmin = hasRole(SportsTeamAuthority.ADMIN)

  const isOwner = hasRole(SportsTeamAuthority.OWNER)

  const isObserver = hasRole(SportsTeamAuthority.OBSERVER)

  return {
    hasRole,
    hasPermission,
    hasSomePermissions,
    hasEveryPermissions,
    hasValidSubscription,
    hasValidLicense,
    hasValidPlan,
    isSubscripting,
    isHyper,
    isAdmin,
    isOwner,
    isObserver,
  }
}
