export const teams = {
  en: {
    'navbar-teams.back': 'Go back',

    'edit-team-profile.layout.title': 'Team Profile',
    'edit-team-profile.title': 'Edit Team Profile',
    'edit-team-profile.upload.button': 'Upload profile image',
    'edit-team-profile.upload.under-line': 'Change to default image',
    'edit-team-profile.toasts.success': 'team profile has been saved.',
    'edit-team-profile.toasts.error.file-size':
      'The file size is too large. Please upload a file of less than 1MB',

    'teams.team-list.layout.title': 'Team',
    'teams.enter-team-code.title': 'Enter team code',
    'teams.enter-team-code.content': 'Are there any teams invited?',
    'teams.create-team.title': 'Create a team',
    'teams.create-team.content.first': 'Create a new team',
    'teams.create-team.content.second': 'and invite members!',
    'teams.team-list.title.all-teams': 'All teams ({count})',
    'teams.team-card.team.count': '{membersCount} members · {playersCount} players',
    'teams.team-card.edit.team-profile': 'Edit Team Profile',
    'teams.team-card.leave.team': 'Leave Team',
    'teams.team-card.leave.toasts.success': 'You left the team successfully.',
    'teams.team-card.team.waiting': 'AWAITING',
    'teams.default.toast.error': 'Error occured. Refresh please.',
    'teams.exceed.toast.error': "Plan is exceed. you can't change permissions. Please check again.",
    'teams.select-modal.selected-member': '{membersCount} users selected',

    // member-pending-table
    'member-pending-table.title': 'Request',
    'member-pending-table.name': 'Name',
    'member-pending-table.sportName': 'Sport',
    'member-pending-table.sportPosition': 'Position',
    'member-pending-table.sportRole': 'Role',
    'member-pending-table.noData': 'No request',
    'member-pending-table.actions.accept': 'Accept',
    'member-pending-table.actions.reject': 'Reject',
    'member-pending-table.accept.player.expired-plan.title':
      'You can accept the players only during the plan subscription period.',
    'member-pending-table.accept.player.expired-plan.subtitle':
      'The plan has expired so player acceptance is \n impossible. Subscribe the plan and try again.',
    'member-pending-table.subscribe.plan': 'Subscribe Plan',
    'member-pending-table.accept.player.exceed.title': 'Plan is exceed',
    'member-pending-table.accept.player.exceed.subtitle':
      'Please check the number of player again or change \n the plan to accept more players',
    'member-pending-table.change.plan': 'Change Plan',
    'member-pending-table.accept.toasts.success': `{membersCount, plural,
      =1 {{name} has been accepted}
      =1000 {999+ Users has been accepted}
      other {# Users has been accepted}
    }`,
    'member-pending-table.reject.toasts.success': `{membersCount, plural,
      =1 {{name} has been rejected}
      =1000 {999+ Users has been rejected}
      other {# Users has been rejected}
    }`,

    // member-table
    'member-table.all': 'All',
    'member-table.member': 'Member',
    'member-table.admin': 'Admin',
    'member-table.owner': 'Owner',
    'member-table.title': 'Member',
    'member-table.name': 'Name',
    'member-table.sportName': 'Sport',
    'member-table.sportPosition': 'Position',
    'member-table.sportRole': 'Role',
    'member-table.teamAuthority': 'Authority',
    'member-table.change-teamAuthority': 'Change Authority',
    'member-table.noData': 'Invite your team member',
    'member-table.player.noData': 'Invite your player member',
    'member-table.auth-help.owner.auth': 'Owner',
    'member-table.auth-help.owner.desc':
      'The person who created the team. You can create a group of members. You can create, modify, and delete events. You can delete a team if necessary.',
    'member-table.auth-help.admin.auth': 'Admin',
    'member-table.auth-help.admin.desc':
      '(Coach) You can monitor data for members. You can also create, modify, and delete events.',
    'member-table.auth-help.member.auth': 'Member',
    'member-table.auth-help.member.desc': '(Player) You are a member of the team.',
    'member-table.kick.toasts.success': '{name} kicked out successfully.',
    'member-table.transfer.toasts.success': '{name}’s Authority has been changed to {authority}',
    'member-table.authority.toasts.success': `{membersCount, plural,
      =1 {{name}'s Authority has been changed to {authority}}
      =1000 {999+ User’s Authority has been changed to {authority}}
      other {# User’s Authority has been changed to {authority}}
    }`,

    // member-delete-team
    'button-delete-team.button': 'Delete team',
    'button-delete-team.modal.success': 'Team {teamName} has been deleted successfully.',
    'button-delete-team.modal.error': 'If there are team members, you cannot delete a team.',
    'button-delete-team.modal.error.teamId':
      'Team could not be found. Please refresh and try again.',
    'button-delete-team.modal.confirm': 'Are you sure you want to delete the team?',
    'button-delete-team.dialog.modal.title':
      'You can’t delete the team while \n subscribing to the plan.',
    'button-delete-team.dialog.modal.subtitle':
      'If you want to delete the team, \n Please cancel the plan and try again.',

    member__button__change_owner: 'Change owner',
    member__authority__owner: 'Owner',
    member__authority__coach: 'Coach',
    member__authority__player: 'Player',
    member__toast__kickout_select_first: 'Please select members to remove from the team first',
    member__tooltip__owner: 'The creator of the team and have full administrative rights.',
    member__popup__title_single_kickout: 'Are you sure you want to remove {name} from the team?',
    member__popup__title_multi_kickout:
      'Are you sure you want to remove {count} members from the team?',
    member__toast__kickout_successed: '{count} members have been removed',
    member__toast__request_select_first: 'Please select members to accept/reject first.',
    member__tooltip__authority_disabled:
      'Already have a history registered as {authority}. Please contact us if you wish to change it.',
    member__popup__title_single_accept: 'Accept request as [{authority}]?',
    member__popup__description_accept:
      'After this, you cannot change player-coach, so please decide carefully.',
    member__popup__title_multi_accept: 'Accept {count} requests?',
    member__popup__title_reject: 'Reject the request?',
    member__popup__descriptiom_reject: 'Reject a total of {count} requests.',
    member__toast__accept_successed: 'Accepted {count} requests',
    member__toast__reject_successed: 'Rejected {count} requests',
    member__description__request_empty: 'No requests',
    member__dialog__title_change_owner: 'Please select\na new owner.',
    member__dialog__description_change_owner:
      'Owner authority will be transferred to the selected coach.',
    member__dialog__text_1_empty_change_owner: 'No coaches',
    member__dialog__text_2_empty_change_owner:
      'Please invite a coach\nto change to the owner first.',
    member__popup__title_change_owner_alert: 'Change ownership to [{name}]?',
    member__toast__change_owner_successed: 'Owner has been changed successfully',
    member__tooltip__authority_owner:
      'A creator and admin of the team. Has all permissions including team deletion and can transfer owner authority to other coaches.',
    member__tooltip__authority_coach:
      'A coach, manager and so on. You can perform all functions except deleting the team.',
    member__tooltip__authority_player: 'A player in the team. Cannot perform PLCO COACH functions.',
    member__toast__plan_expired: 'Unable to accept/reject members because the plan has expired',
  },
  ko: {
    'navbar-teams.back': '돌아가기',

    'edit-team-profile.layout.title': '팀 프로필',
    'edit-team-profile.title': '팀 프로필 수정',
    'edit-team-profile.upload.button': '프로필 사진 업로드',
    'edit-team-profile.upload.under-line': '기본 이미지로 변경',
    'edit-team-profile.toasts.success': '팀 프로필이 저장되었습니다.',
    'edit-team-profile.toasts.error.file-size':
      '파일 사이즈가 너무 큽니다. 1MB 이하의 파일로 올려주세요',

    'teams.team-list.layout.title': '팀',
    'teams.enter-team-code.title': '팀 코드 입력',
    'teams.enter-team-code.content': '초대 받은 팀이 있으신가요?',
    'teams.create-team.title': '팀 만들기',
    'teams.create-team.content.first': '새로운 팀을 만들고',
    'teams.create-team.content.second': '멤버를 초대하세요!',
    'teams.team-list.title.all-teams': '전체 팀 ({count})',
    'teams.team-card.team.count': '전체 멤버 {membersCount}명 · 선수 {playersCount}명',
    'teams.team-card.edit.team-profile': '팀 프로필 수정',
    'teams.team-card.leave.team': '팀 나가기',
    'teams.team-card.leave.toasts.success': '팀을 성공적으로 나갔습니다.',
    'teams.team-card.team.waiting': '수락 대기중',
    'teams.default.toast.error': '에러가 발생했습니다. 새로고침 해주세요.',
    'teams.exceed.toast.error': '플랜 인원이 초과해서 권한 변경이 불가합니다. 다시 확인해 주세요.',
    'teams.select-modal.selected-member': '{membersCount}명 선택됨',

    // member-pending-table
    'member-pending-table.title': '요청',
    'member-pending-table.name': '이름',
    'member-pending-table.sportName': '종목',
    'member-pending-table.sportPosition': '포지션',
    'member-pending-table.sportRole': '역할',
    'member-pending-table.noData': '요청이 없습니다',
    'member-pending-table.actions.accept': '수락',
    'member-pending-table.actions.reject': '거절',
    'member-pending-table.accept.player.expired-plan.title':
      '플랜 구독 중에만 선수 수락이 가능합니다.',
    'member-pending-table.accept.player.expired-plan.subtitle':
      '현재 플랜이 만료되어 멤버 수락이 불가능합니다. \n 플랜 구독후 다시 시도하세요.',
    'member-pending-table.subscribe.plan': '플랜 구독',
    'member-pending-table.accept.player.exceed.title': '플랜 인원이 초과했습니다.',
    'member-pending-table.accept.player.exceed.subtitle':
      '플랜 인원을 다시 확인하시거나 더 많은 인원을 수락할 수 \n 있는 플랜으로 변경해 주세요.',
    'member-pending-table.change.plan': '플랜 변경',
    'member-pending-table.accept.toasts.success': `{membersCount, plural,
      =1 {{name}님을 수락했습니다.}
      =1000 {999+명을 수락했습니다.}
      other {#명을 수락했습니다.}
    }`,
    'member-pending-table.reject.toasts.success': `{membersCount, plural,
      =1 {{name}님을 거절했습니다.}
      =1000 {999+명을 거절했습니다.}
      other {#명을 거절했습니다.}
    }`,

    // member-table
    'member-table.all': '전체',
    'member-table.member': '멤버',
    'member-table.admin': '관리자',
    'member-table.owner': '소유자',
    'member-table.title': '멤버',
    'member-table.name': '이름',
    'member-table.sportName': '종목',
    'member-table.sportPosition': '포지션',
    'member-table.sportRole': '역할',
    'member-table.teamAuthority': '권한',
    'member-table.change-teamAuthority': '권한 변경',
    'member-table.noData': '선수와 코치를 초대해 보세요',
    'member-table.player.noData': '선수를 초대해 보세요',
    'member-table.auth-help.owner.auth': '소유자',
    'member-table.auth-help.admin.auth': '지도자',
    'member-table.auth-help.member.auth': '선수',
    'member-table.kick.toasts.success': '{name} 님을 성공적으로 내보냈습니다.',
    'member-table.transfer.toasts.success': '{name}님의 권한을 {authority}로 변경 완료했습니다.',
    'member-table.authority.toasts.success': `{membersCount, plural,
      =1 {{name}님의 권한을 {authority}로 변경 완료했습니다.}
      =1000 {999+명의 권한을 {authority}로 변경 완료했습니다.}
      other {#명의 권한을 {authority}로 변경 완료했습니다.}
    }`,

    // member-delete-team
    'button-delete-team.button': '팀삭제',
    'button-delete-team.modal.success': '{teamName} 팀을 성공적으로 삭제했습니다.',
    'button-delete-team.modal.error': '팀에 멤버가 있는 경우, 팀을 삭제할 수 없습니다.',
    'button-delete-team.modal.error.teamId': '팀을 찾을 수 없습니다. 다시 시도해 주세요.',
    'button-delete-team.modal.confirm': '정말로 팀을 삭제 하시겠습니까?',
    'button-delete-team.dialog.modal.title': '플랜 구독 중에는 팀 삭제가 불가능합니다.',
    'button-delete-team.dialog.modal.subtitle':
      '팀 삭제를 원하시는 경우 현재 이용중인 \n 플랜 해지후 다시 시도해 주세요.',

    member__button__change_owner: '소유자 변경',
    member__authority__owner: '소유자',
    member__authority__coach: '지도자',
    member__authority__player: '선수',
    member__toast__kickout_select_first: '팀 내보내기 할 멤버를 먼저 선택해주세요',
    member__tooltip__owner: '팀을 만든 사람으로 팀의 최고 관리자입니다.',
    member__popup__title_single_kickout: '{name}님을 팀에서 내보내시겠습니까?',
    member__popup__title_multi_kickout: '{count}명을 팀에서 내보내시겠습니까?',
    member__toast__kickout_successed: '{count}명을 내보냈습니다',
    member__toast__request_select_first: '수락/거절할 멤버를 먼저 선택해주세요',
    member__tooltip__authority_disabled:
      '이미 {authority}로 등록된 이력이 있습니다. 변경을 원하시면 문의해주세요.',
    member__popup__title_single_accept: '[{authority}]로 요청을 수락할까요?',
    member__popup__description_accept: '이후 선수-지도자를 변경할 수 없으니 신중히 결정해주세요.',
    member__popup__title_multi_accept: '{count}명의 요청을 수락할까요?',
    member__popup__title_reject: '요청을 거절할까요?',
    member__popup__descriptiom_reject: '총 {count}명의 요청을 거절합니다.',
    member__toast__accept_successed: '{count}명의 요청을 수락했습니다',
    member__toast__reject_successed: '{count}명의 요청을 거절했습니다',
    member__description__request_empty: '요청이 없습니다',
    member__dialog__title_change_owner: '새로운 소유자를\n선택해주세요',
    member__dialog__description_change_owner: '선택한 지도자에게 소유자 권한이 넘어갑니다.',
    member__dialog__text_1_empty_change_owner: '지도자 없음',
    member__dialog__text_2_empty_change_owner: '소유자로 변경할 지도자를\n먼저 팀에 초대해주세요.',
    member__popup__title_change_owner_alert: '[{name}]님으로\n소유자를 변경할까요?',
    member__toast__change_owner_successed: '소유자를 변경했습니다',
    member__tooltip__authority_owner:
      '팀을 만든 최고 관리자입니다. 팀 삭제를 포함한 모든 기능을 이용할 수 있습니다. 다른 지도자에게 소유권을 이전할 수 있습니다.',
    member__tooltip__authority_coach:
      '감독, 코치 등 지도자입니다. 팀 삭제를 제외한 모든 기능을 이용할 수 있습니다.',
    member__tooltip__authority_player:
      '팀에 속하는 선수입니다. 플코 코치의 기능을 이용할 수 없습니다.',
    member__toast__plan_expired: '플랜이 만료되어 멤버를 수락/거절할 수 없습니다',
  },
  ja: {
    'navbar-teams.back': '戻る', // "돌아가기",

    'edit-team-profile.layout.title': 'チームプロフィール', // "팀 프로필",
    'edit-team-profile.title': 'チームプロフィール修正', // "팀 프로필 수정",
    'edit-team-profile.upload.button': 'プロフィール写真のアップロード', // "프로필 사진 업로드",
    'edit-team-profile.upload.under-line': '基本イメージに変更', // "기본 이미지로 변경",
    'edit-team-profile.toasts.success': 'チーム プロファイルが保存されました.', // "팀 프로필이 저장되었습니다.",
    'edit-team-profile.toasts.error.file-size':
      'ファイルサイズが大きすぎます。 1MB以下のファイルでアップロードしてください', // "파일 사이즈가 너무 큽니다. 1MB 이하의 파일로 올려주세요",

    'teams.team-list.layout.title': 'チーム', // "팀",
    'teams.enter-team-code.title': 'チームコード入力', // "팀 코드 입력",
    'teams.enter-team-code.content': '招待されたチームはありますか？', // "초대 받은 팀이 있으신가요?",
    'teams.create-team.title': 'チーム作り', // "팀 만들기",
    'teams.create-team.content.first': '新しいチームを作って', // "새로운 팀을 만들고",
    'teams.create-team.content.second': 'メンバーを招待してください！', // "멤버를 초대하세요!",
    'teams.team-list.title.all-teams': '全体チーム ({count})', // "전체 팀 ({count})",
    'teams.team-card.team.count': '全メンバー{membersCount}名·選手{playersCount}名', // "전체 멤버 {membersCount}명 · 선수 {playersCount}명",
    'teams.team-card.edit.team-profile': 'チームプロフィール修正', // "팀 프로필 수정",
    'teams.team-card.leave.team': 'チームを出る', // "팀 나가기",
    'teams.team-card.leave.toasts.success': 'チームを成功的に出ました.', // "팀을 성공적으로 나갔습니다.",
    'teams.team-card.team.waiting': '受諾待機中', // "수락 대기중",
    'teams.default.toast.error': 'エラーが発生しました. リロードしてください.', // "에러가 발생했습니다. 새로고침 해주세요.",
    'teams.exceed.toast.error':
      'プランの人数を超えて権限の変更はできません。もう一度確認してください。', // 플랜 인원이 초과해서 권한 변경이 불가합니다. 다시 확인해 주세요.
    'teams.select-modal.selected-member': '{membersCount}名 選択された', // "{membersCount}명 선택됨",

    // member-pending-table
    'member-pending-table.title': '要請', // "요청",
    'member-pending-table.name': '名前', // "이름",
    'member-pending-table.sportName': '種目', // "종목",
    'member-pending-table.sportPosition': 'ポジション', // "포지션",
    'member-pending-table.sportRole': '役割', // "역할",
    'member-pending-table.noData': '要請がありません', // "요청이 없습니다",
    'member-pending-table.actions.accept': '受諾', // "수락",
    'member-pending-table.actions.reject': '断る', // "거절",
    'member-pending-table.accept.player.expired-plan.title':
      'プラン購読中のみ選手の受け入れが可能です.', // "플랜 구독 중에만 선수 수락이 가능합니다.",
    'member-pending-table.accept.player.expired-plan.subtitle':
      '現在のプランが満了しているため メンバーの受け入れができません. \nプラン購読後 もう一度お試しください.', // "현재 플랜이 만료되어 멤버 수락이 불가능합니다. \n 플랜 구독후 다시 시도하세요.",
    'member-pending-table.subscribe.plan': 'プラン購読', // "플랜 구독",
    'member-pending-table.accept.player.exceed.title': 'プラン変更後 選手の受け入れが可能です.', // "플랜 변경 후 선수 수락 가능합니다.",
    'member-pending-table.accept.player.exceed.subtitle':
      '現在、追加可能な人数がないため メンバーの受け入れが不可能です. \nプラン人数変更後、もう一度お試しください.', // "현재 추가 가능한 인원이 없어 멤버 수락이 불가능 합니다. \n 플랜 인원 변경후 다시 시도하세요.",
    'member-pending-table.change.plan': 'プラン変更', // "플랜 변경",
    'member-pending-table.accept.toasts.success': `{membersCount, plural,
      =1 {{name} さんを承認しました}
      =1000 {999+人を承認しました}
      other {#人を承認しました}
    }`,
    'member-pending-table.reject.toasts.success': `{membersCount, plural,
      =1 {{name}さんを拒否しました}
      =1000 {999+人を拒否しました}
      other {#人を拒否しました}
    }`,

    // member-table
    'member-table.all': '全体', // "전체",
    'member-table.member': 'メンバー', // "멤버",
    'member-table.admin': '管理者', // "관리자",
    'member-table.owner': '所有者', // "소유자",
    'member-table.title': 'メンバー', // "멤버",
    'member-table.name': '名前', // "이름",
    'member-table.sportName': '種目', // "종목",
    'member-table.sportPosition': 'ポジション', // "포지션",
    'member-table.sportRole': '役割', // "역할",
    'member-table.teamAuthority': '権限', // "권한",
    'member-table.change-teamAuthority': '権限変更', // "권한 변경",
    'member-table.noData': '選手とコーチを招待してみてください', // "선수와 코치를 초대해 보세요",
    'member-table.player.noData': '選手を招待してみてください', // "선수를 초대해 보세요",
    'member-table.auth-help.owner.auth': '所有者', // "소유자",
    'member-table.auth-help.owner.desc':
      'チームを作った人です. メンバーのグループを設定できます. スケジュールを作成して、修正、削除できます. 必要に応じてチームを削除できます.', // "팀을 만든 사람입니다. 멤버들의 그룹을 설정할 수 있습니다. 일정을 생성하고, 수정, 삭제 할 수 있습니다. 필요하면 팀을 삭제 할 수 있습니다.",
    'member-table.auth-help.admin.auth': '管理者', // "관리자",
    'member-table.auth-help.admin.desc':
      '（コーチ または 監督）メンバーのデータを監視できます. スケジュールを作成し、修正、削除することができます.', // "(코치 또는 감독) 멤버의 데이터를 모니터링 할 수 있습니다. 일정을 생성하고 수정, 삭제할 수 있습니다.",
    'member-table.auth-help.member.auth': 'メンバー', // "멤버",
    'member-table.auth-help.member.desc': '（選手）チームに属する選手です。', // "(선수) 팀에 속하는 선수입니다.",
    'member-table.kick.toasts.success': '{name}様を正常にエクスポートしました.', // "{name} 님을 성공적으로 내보냈습니다.",
    'member-table.transfer.toasts.success': 'name}様の権限を{authority}に変更完了しました.', // "{name}님의 권한을 {authority}로 변경 완료했습니다.",
    'member-table.authority.toasts.success':
      '{membersCount, plural, ' + // `{membersCount, plural,
      '=1{{name}様の権限を{authority}に変更完了しました。}' + // =1 {{name}님의 권한을 {authority}로 변경 완료했습니다.}
      '=1000{999+名の権限を{authority}に変更完了しました。}' + // =1000 {999+명의 권한을 {authority}로 변경 완료했습니다.}
      'other{#名の権限を{authority}に変更完了しました。}}', // other {#명의 권한을 {authority}로 변경 완료했습니다.}

    // member-delete-team
    'button-delete-team.button': 'チーム削除', // "팀삭제",
    'button-delete-team.modal.success': '{teamName}チームの削除に成功しました。', // "{teamName} 팀을 성공적으로 삭제했습니다.",
    'button-delete-team.modal.error':
      'チームにメンバーがいる場合、チームを削除することはできません。', // "팀에 멤버가 있는 경우, 팀을 삭제할 수 없습니다.",
    'button-delete-team.modal.error.teamId': 'チームが見つかりません。 もう一度お試しください。', // "팀을 찾을 수 없습니다. 다시 시도해 주세요.",
    'button-delete-team.modal.confirm': '本当にチームを削除しますか？', // "정말로 팀을 삭제 하시겠습니까?",
    'button-delete-team.dialog.modal.title': 'プラン購読中はチームの削除ができません。', // "플랜 구독 중에는 팀 삭제가 불가능합니다.",
    'button-delete-team.dialog.modal.subtitle':
      'チームの削除をご希望の場合は、現在ご利用中の\nプラン解約後、再度お試しください.', // "팀 삭제를 원하시는 경우 현재 이용중인 \n 플랜 해지후 다시 시도해 주세요.",

    member__button__change_owner: 'オーナー変更',
    member__authority__owner: 'オーナー',
    member__authority__coach: 'コーチ',
    member__authority__player: 'プレイヤー',
    member__toast__kickout_select_first: 'チームから削除するメンバーを最初に選択してください',
    member__tooltip__owner: 'あなたはチームの作成者であり、全管理権を持っています。',
    member__popup__title_single_kickout: '{name}をチームから削除しますか？',
    member__popup__title_multi_kickout: '{count}人をチームから削除しますか？',
    member__toast__kickout_successed: '{count}人が削除されました',
    member__toast__request_select_first: '承認/拒否するメンバーを最初に選択してください',
    member__tooltip__authority_disabled:
      '{authority}として登録済みの履歴があります。 変更をご希望でしたらお問い合わせください。',
    member__popup__title_single_accept: '[{authority}]でリクエストを承諾しますか？',
    member__popup__description_accept:
      'その後、プレーヤー-コーチを変更することはできませんので、慎重にお選びください。',
    member__popup__title_multi_accept: '{count}件のリクエストを承諾しますか？',
    member__popup__title_reject: 'リクエストを拒否しますか？',
    member__popup__descriptiom_reject: '合計{count}人のリクエストを拒否します。',
    member__toast__accept_successed: '{count}人のリクエストを承諾しました',
    member__toast__reject_successed: '{count}人のリクエストを拒否しました',
    member__description__request_empty: 'リクエストはありません',
    member__dialog__title_change_owner: '新しい所有者を\n選択してください',
    member__dialog__description_change_owner: '選択したコーチに所有権が移ります。',
    member__dialog__text_1_empty_change_owner: 'コーチがいません',
    member__dialog__text_2_empty_change_owner:
      '所有者に変更するコーチを\n先にチームに招待してください。',
    member__popup__title_change_owner_alert: '[{name}]に所有者を\n変更しますか？',
    member__toast__change_owner_successed: '所有者が変更されました',
    member__tooltip__authority_owner:
      'あなたはチームの作成者であり、チームの削除を含むすべての機能を利用できます。他のコーチに所有権を移行できます。',
    member__tooltip__authority_coach:
      '監督、コーチなどです。チームの削除を除くすべての機能を使用できます。',
    member__tooltip__authority_player: 'チームのメンバーです。コーチの機能は使用できません。',
    member__toast__plan_expired: 'プランが満了したため、メンバーを受諾/拒絶することはできません',
  },
}
