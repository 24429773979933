import { useMemo } from 'react'

import 'moment/locale/vi'

import { Moment, moment } from '@common/utils/libs'
import _clone from 'lodash/clone'

import { useI18n } from '@plco-pro/hooks/i18n'

export type { Moment }

const momentProps = _clone(moment)
const cache = new Map<string, typeof moment>()

export const useMoment = () => {
  const { locale: language, timeZone: timezone = 'Asia/Seoul' } = useI18n()

  return useMemo(() => {
    // ref: https://github.com/moment/moment/issues/3624#issuecomment-366524783
    const languageCode = language.split('-')[0] === 'vn' ? 'vi' : language.split('-')[0]

    const momentMock = Object.assign((...args: any[]) => {
      moment.tz.setDefault(timezone)
      moment.locale(languageCode)
      return moment(...args)
    }, momentProps) as typeof moment

    const cacheKey = `${timezone}-${language}`
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey)!
    }
    cache.set(cacheKey, momentMock)

    return momentMock
  }, [language, timezone])
}
