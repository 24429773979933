import { useEffect, useRef } from 'react'

import { Moment } from '@common/utils/libs'
import { teamIndividual } from '@common/utils/sports-category'
import _uniq from 'lodash/uniq'

import { useApolloClient } from '@plco-pro/graphqls/provider'
import {
  AnalyticsViewerDocument,
  AnalyticsViewerQuery,
  AnalyticsViewerQueryVariables,
  CountryCode,
  Gender,
  SportsRole,
} from '@plco-pro/graphqls/react.generated'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useMoment } from '@plco-pro/hooks/moment'
import { useAnalyticsContext } from '@plco-pro/providers/analytics'

export interface IdentifyData {
  // segment reserved traits
  id: string | undefined
  birthday: string | null
  age: number | null
  gender: Gender | null
  createdAt: string | null
  // custom traits
  sport: string
  nationality: CountryCode | null
  role: SportsRole | null
  authority: string[]
  team_individual: 'team' | 'individual'
  teamid_name: string[]
  teamid_authority: string[]
  connected_team_count: number
  test_account_exclusion: boolean
}

const parseIdentifyData = (
  analyticsViewerData: AnalyticsViewerQuery,
  moment: Moment,
): IdentifyData => {
  const { viewer } = analyticsViewerData.sports

  return {
    // segment reserved traits
    id: viewer?.id,
    birthday: moment(viewer?.birthdate).toISOString(),
    age: moment().diff(viewer?.birthdate, 'years'),
    gender: viewer?.gender || null,
    createdAt: moment(viewer?.createdAt).toISOString(),
    // custom traits
    nationality: viewer?.nationality || null,
    role: viewer?.priorSportsRole || null,
    sport: viewer?.priorSportsCategory?.id || '',
    team_individual: teamIndividual(viewer?.priorSportsCategory?.id || ''),
    teamid_authority: (viewer?.teamMaps || []).map(
      (teamMap) => `${teamMap.team.id}:${teamMap.teamAuthority}`,
    ),
    authority: _uniq((viewer?.teamMaps || []).map((teamMap) => teamMap.teamAuthority || '')),
    teamid_name: (viewer?.teamMaps || []).map(
      (teamMap) => `${teamMap.team.id}:${teamMap.team.name}`,
    ),
    connected_team_count: (viewer?.teamMaps || []).length,
    test_account_exclusion: !!viewer?.email?.endsWith('@qmit.kr'),
  }
}

export const useIdentify = () => {
  const moment = useMoment()
  const { identifyUser } = useAnalytics()
  const { setIsIdentified } = useAnalyticsContext()

  const analyticsViewerData = useApolloClient().readQuery<
    AnalyticsViewerQuery,
    AnalyticsViewerQueryVariables
  >({ query: AnalyticsViewerDocument })
  const parsedDataRef = useRef<IdentifyData>()

  useEffect(() => {
    if (analyticsViewerData && analyticsViewerData.sports.viewer) {
      const newParseIdentifyData = parseIdentifyData(analyticsViewerData, moment)

      if (JSON.stringify(newParseIdentifyData) !== JSON.stringify(parsedDataRef.current)) {
        parsedDataRef.current = newParseIdentifyData
        if (parsedDataRef.current.id) {
          identifyUser(parsedDataRef.current.id, parsedDataRef.current as any)
        }
      }
    } else {
      setIsIdentified(false)
    }
  }, [analyticsViewerData, moment, identifyUser, setIsIdentified])

  return {
    data: parsedDataRef.current,
  }
}
