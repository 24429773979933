import { useMemo } from 'react'

import { Box, Flex, Icon } from '@plco-pro/components/atoms'

export interface SlideToggleButtonProps {
  onToggle: () => void
  active: boolean
  disabled?: boolean
  size?: 'sm' | 'md'
}

export const ButtonSlideToggle = ({
  onToggle,
  active,
  disabled,
  size = 'md',
  ...rest
}: SlideToggleButtonProps) => {
  const buttonSize = useMemo(() => {
    if (size === 'sm') {
      return {
        width: '40px',
        height: '24px',
        right: '18px',
        iconSize: '20px',
      }
    }

    return {
      width: '64px',
      height: '32px',
      right: '34px',
      iconSize: '28px',
    }
  }, [size])

  const onClick = () => {
    if (disabled) {
      return
    }

    onToggle()
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        position: 'relative',
        width: buttonSize.width,
        cursor: 'pointer',
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={onClick}
      {...rest}
    >
      <Box
        sx={{
          alignSelf: 'stretch',
          height: buttonSize.height,
          bg: active ? 'primary-500' : '#edeff3',
          border: !active && '1px solid #c6cddf',
          borderRadius: '100px',
        }}
      />
      <Flex
        sx={{
          position: 'absolute',
          transition: '0.3s',
          top: '2px',
          right: active ? '2px' : buttonSize.right,
          width: buttonSize.iconSize,
          height: buttonSize.iconSize,
          bg: '#fff',
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {active && <Icon src={'/images/ic-check.svg'} color={'primary-500'} />}
      </Flex>
    </Flex>
  )
}
