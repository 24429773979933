export const error = {
  en: {
    'ERROR.BAD_REQUEST': 'Oops! Bad request.',
    'ERROR.UNAUTHORIZED': 'Unauthorized request.',
    'ERROR.FORBIDDEN': 'Not allowed to perform this action.',
    'ERROR.NOT_FOUND': 'Oops! resource not found.',
    'ERROR.NOT_FOUND.requestTeamInvitation': 'No team is found with the code.',
    'ERROR.REQUEST_TIMEOUT': 'Request timed out.',
    'ERROR.CONFLICT': 'Oops! Duplicate request.',
    'ERROR.UNPROCESSABLE_ENTITY': 'Validation error.',
    'ERROR.INTERNAL_SERVER_ERROR': 'A temporary error has occurred. Please try again.',
    'ERROR.NOT_IMPLEMENTED': 'Under construction.',
    'ERROR.NETWORK_ERROR':
      'A network issue has occurred. Please check your connection and try again.',
    'ERROR.SESSION_EXPIRED': 'Session has expired',

    // TEAM REQUEST ERROR
    'ERROR.TEAM_INVALID_CODE': 'Team not found with given code.',
    'ERROR.TEAM_EXISTING_USER': 'You are already in the team.',
    'ERROR.TEAM_SERVER_ERROR': 'A temporary error has occurred. please try again.',
    'ERROR.TEAM_DUPLICATED_INVITATION': 'Awaiting acceptance.',
  },
  ko: {
    'ERROR.BAD_REQUEST': '이런! 잘못된 요청입니다.',
    'ERROR.UNAUTHORIZED': '클라이언트의 인증 실패.',
    'ERROR.FORBIDDEN': '권한이 없습니다.',
    'ERROR.NOT_FOUND': '이런! 리소스를 찾을 수 없습니다.',
    'ERROR.NOT_FOUND.requestTeamInvitation': '팀 코드를 찾을 수 없습니다. 다시 확인해 주세요.',
    'ERROR.REQUEST_TIMEOUT': '요청 시간이 초과되었습니다.',
    'ERROR.CONFLICT': '이런! 중복된 요청입니다.',
    'ERROR.UNPROCESSABLE_ENTITY': '유효성 검사 오류입니다.',
    'ERROR.INTERNAL_SERVER_ERROR': '일시적인 장애가 발생했습니다. 잠시후 다시 시도해 주세요.',
    'ERROR.NOT_IMPLEMENTED': '공사중.',
    'ERROR.NETWORK_ERROR': '네트워크 이슈가 발생했습니다. 연결상태를 확인하고 다시 시도해 주세요.',
    'ERROR.SESSION_EXPIRED': '세션이 만료되었습니다',

    // TEAM REQUEST ERROR
    'ERROR.TEAM_INVALID_CODE': '팀코드를 찾을 수 없습니다. 다시 확인해 주세요.',
    'ERROR.TEAM_EXISTING_USER': '이미 팀에 가입되어 있습니다.',
    'ERROR.TEAM_SERVER_ERROR': '일시적 장애가 발생했습니다. 다시 시도해 주세요.',
    'ERROR.TEAM_DUPLICATED_INVITATION': '수락 대기 중입니다.',
  },
  jp: {
    'ERROR.BAD_REQUEST': 'おっと！間違ったリクエストです。', // "이런! 잘못된 요청입니다."
    'ERROR.UNAUTHORIZED': 'クライアントの認証に失敗しました。', // "클라이언트의 인증 실패."
    'ERROR.FORBIDDEN': '権限がありません。', // "권한이 없습니다."
    'ERROR.NOT_FOUND': 'おっと！リソースが見つかりません。', // "이런! 리소스를 찾을 수 없습니다."
    'ERROR.NOT_FOUND.requestTeamInvitation':
      'チームコードが見つかりません。もう一度確認してください。', // "팀 코드를 찾을 수 없습니다. 다시 확인해 주세요."
    'ERROR.REQUEST_TIMEOUT': 'リクエスト時間が超過しました。', // "요청 시간이 초과되었습니다."
    'ERROR.CONFLICT': 'おっと！重複したリクエストです。', // "이런! 중복된 요청입니다."
    'ERROR.UNPROCESSABLE_ENTITY': '妥当性検査エラーです。', // "유효성 검사 오류입니다."
    'ERROR.INTERNAL_SERVER_ERROR':
      '一時的な障害が発生しました。しばらくしてからもう一度お試しください。', // "일시적인 장애가 발생했습니다. 잠시후 다시 시도해 주세요."
    'ERROR.NOT_IMPLEMENTED': '工事中。', // "공사중."
    'ERROR.NETWORK_ERROR':
      'ネットワークの問題が発生しました。接続状態を確認してからもう一度お試しください。', // "네트워크 이슈가 발생했습니다. 연결상태를 확인하고 다시 시도해 주세요."
    'ERROR.SESSION_EXPIRED': 'セッションが期限切れです', // "세션이 만료되었습니다"

    // TEAM REQUEST ERROR
    'ERROR.TEAM_INVALID_CODE': 'チームコードが見つかりません。もう一度確認してください。', // "팀코드를 찾을 수 없습니다. 다시 확인해 주세요."
    'ERROR.TEAM_EXISTING_USER': 'すでにチームに参加しています。', // "이미 팀에 가입되어 있습니다."
    'ERROR.TEAM_SERVER_ERROR': '一時的な障害が発生しました。もう一度お試しください。', // "일시적 장애가 발생했습니다. 다시 시도해 주세요."
    'ERROR.TEAM_DUPLICATED_INVITATION': '承認待ちです。', // "수락 대기 중입니다."
  },
}
