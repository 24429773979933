import React, { useEffect } from 'react'

import { useKeycloak } from '@react-keycloak/ssr'
import { useRouter } from 'next/router'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Spinner } from '@plco-pro/components/atoms/spinner'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useStore } from '@plco-pro/stores'
import { AnalyticsEventType, storage } from '@plco-pro/utils/libs'

import { useAnalyticsContext } from './analytics'

export const LOGGED_ANALYTICS_SESSION_ID_KEY = 'logged-analytics-session-id'

export const AuthenticationProvider: React.FunctionComponent = ({ children }) => {
  const { login } = useStore()
  const router = useRouter()
  const { keycloak, initialized: keycloakInitialized } = useKeycloak()
  const { trackEvent, restartSession } = useAnalytics()
  const { sessionId, isIdentified } = useAnalyticsContext()

  useEffect(() => {
    if (keycloakInitialized && !keycloak?.authenticated) {
      restartSession()
      storage.remove(LOGGED_ANALYTICS_SESSION_ID_KEY)

      login()
    }
  }, [keycloak?.authenticated, keycloakInitialized, login, restartSession, router.route])

  useEffect(() => {
    if (!keycloak?.authenticated || !sessionId || !isIdentified) return

    const loggedAnalyticsSessionId = storage.get(LOGGED_ANALYTICS_SESSION_ID_KEY)

    if (!loggedAnalyticsSessionId || loggedAnalyticsSessionId !== sessionId) {
      storage.set(LOGGED_ANALYTICS_SESSION_ID_KEY, sessionId)

      trackEvent(AnalyticsEventType.LOGIN, {})
    }
  }, [isIdentified, keycloak?.authenticated, sessionId, trackEvent])

  return (
    <>
      {!keycloak?.authenticated ? (
        <Flex sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <Spinner />
        </Flex>
      ) : (
        children
      )}
    </>
  )
}
