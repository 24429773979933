import { useCallback, useMemo } from 'react'

import { average, isNumeric, sum } from '@common/utils'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import has from 'lodash/has'
import round from 'lodash/round'

import {
  ChartUserWorkload,
  ChartWorkload,
  Maybe,
  MonitoringChartDataQuery,
  NoWorkoutType,
  QuerySystemTeamAverageDataQuery,
  QuerySystemTeamUserMapsDataQuery,
  QuerySystemUserDataQuery,
  QuerySystemUserWorkloadDataQuery,
  SorenessData,
  SportsData,
  SportsRawWorkload,
  SportsUser,
} from '@plco-pro/graphqls/react.generated'
import { useDataInterpreter } from '@plco-pro/hooks/data-interpreter'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMoment } from '@plco-pro/hooks/moment'
import { getPostProcess } from '@plco-pro/hooks/parser-system/utils/get-post-process'
import { parserUserData } from '@plco-pro/hooks/parser-system/utils/parser-user-data'
import { QuerySystemReturnType } from '@plco-pro/hooks/query-system'
import {
  ChartSystemMapValue,
  ParserSystemConfig,
  ParserSystemFormat,
  ParserSystemInterpretationFormat,
} from '@plco-pro/maps/chart-system/chart-system.types'

type MonitoringChartDataType = {
  [key in string]: any
}

type QueryParser = {
  queryParserData: {
    [property: string]: any
  }[][]
  postProcessData: {
    [field: string]: number[]
  }
}

type WorkloadData = 'workload' | 'duration'

type WorkloadSortingProps = {
  [x: string]: any
  id: string
  name: Maybe<string> | undefined
  pictureUrl: Maybe<string> | undefined
  time?: string
  workloadList: Maybe<SportsRawWorkload>[]
  reasonForNoWorkout?: Maybe<NoWorkoutType>
  workloadTotal?: Maybe<number> | undefined
}[]

export const useParserSystem = (config: ChartSystemMapValue) => {
  const interpret = useDataInterpreter()
  const { formatMessage: f } = useI18n()
  const moment = useMoment()

  const { dataset } = config

  const { postProcessData } = useMemo(() => getPostProcess(dataset), [dataset])

  const defaultUserDataKeys: (keyof SportsUser)[] = useMemo(
    () => (config.parser?.includes('WITHOUT_USER') ? [] : ['id', 'name', 'pictureUrl']),
    [config.parser],
  )

  const interpreter = useCallback(
    (format?: ParserSystemInterpretationFormat) => {
      const getOnlyNumeric = (data: unknown[]) => data.filter(isNumeric) as number[]

      switch (format) {
        case 'SUM':
          return (data: unknown[]) => sum(getOnlyNumeric(data))
        case 'AVERAGE':
          return (data: unknown[]) => average(getOnlyNumeric(data))
        case 'AVERAGE_ROUND_0':
          return (data: unknown[]) => round(average(getOnlyNumeric(data)), 0) || undefined
        case 'AVERAGE_ROUND_1':
          return (data: unknown[]) => round(average(getOnlyNumeric(data)), 1) || undefined
        case 'AVERAGE_ROUND_2':
          return (data: unknown[]) => round(average(getOnlyNumeric(data)), 2) || undefined
        case 'IS_NUMERIC':
          return isNumeric
        case 'TRANSLATE':
          return (data: unknown[]) => {
            const lastElement = data[data.length - 1]
            if (typeof lastElement === 'string') {
              return f({ id: lastElement })
            }
            return '-'
          }
        default:
          return (data: unknown[]) => data[data.length - 1]
      }
    },
    [f],
  )

  const format = useCallback(
    (format?: ParserSystemFormat) => {
      switch (format) {
        case 'FLOOR':
          return Math.floor
        case 'TIME_MM.D':
          return (time: any) => (typeof time === 'string' ? moment(time).format('MM.D') : time)
        default:
          return (data: any) => data
      }
    },
    [moment],
  )

  const workloadDataSort = (a: WorkloadSortingProps, b: WorkloadSortingProps) => {
    const orderA = a[0]
    const orderB = b[0]

    if (!orderA.workloadTotal && !orderB.workloadTotal) {
      if (!!orderA.reasonForNoWorkout === !!orderB.reasonForNoWorkout) {
        return 0
      } else if (orderB.reasonForNoWorkout) {
        return 1
      }

      return -1
    } else if (!orderA.workloadTotal) return 1
    else if (!orderB.workloadTotal) return -1

    return orderB.workloadTotal - orderA.workloadTotal
  }

  const queryParser = useCallback(
    (parser?: ParserSystemConfig, data?: QuerySystemReturnType['data']): QueryParser => {
      let queryParserData: ReturnType<typeof parserUserData>

      if (!data) {
        return { queryParserData: [], postProcessData }
      }

      if (
        parser === 'USER_DATA' ||
        parser === 'USER_DATA_WITHOUT_USER' ||
        parser === 'PLAYER_DAILY_CONDITION'
      ) {
        const dataWithType = data as QuerySystemUserDataQuery | undefined

        const userData = defaultUserDataKeys.reduce(
          (userMetaData, userDataProperty) => {
            const user = dataWithType?.sports.user
            if (!user) {
              return userMetaData
            }

            userMetaData[userDataProperty] = null

            if (userDataProperty === 'id' && user.id) {
              userMetaData[userDataProperty] = user.id

              return userMetaData
            }

            if (userDataProperty in user) {
              const userDefaultInformation = user[userDataProperty as keyof typeof user]
              userMetaData[userDataProperty] =
                typeof userDefaultInformation === 'number' ||
                typeof userDefaultInformation === 'string'
                  ? userDefaultInformation
                  : null
            }

            return userMetaData
          },
          {} as Record<string, string | number | null>,
        )

        if (!dataWithType?.sports.user?.data) {
          return { queryParserData: [], postProcessData }
        }

        const userWorkload = dataWithType.sports.user.workload
        const result = parserUserData(dataWithType.sports.user.data, dataset, userData)

        result.parser.map((workloadDataList, index) => {
          workloadDataList[0]['workloadList'] = userWorkload[index].workloadList
          workloadDataList[workloadDataList.length - 1]['reasonForNoWorkout'] =
            userWorkload[index].reasonForNoWorkout
          return workloadDataList
        })

        queryParserData = { ...result }

        if (parser === 'PLAYER_DAILY_CONDITION') {
          const todayData = queryParserData.parser.map((item) => {
            if (
              moment(item[0].time).startOf('day').format('YYYY-MM-DD') ===
              moment(item[0].insertTime).startOf('day').format('YYYY-MM-DD')
            ) {
              return item
            }
            return [{ ...item[0], condition: null }]
          })
          queryParserData.parser = todayData
        }
      }
      // 선수단 > 운동부하 > 운동강도, 운동만족 차트
      else if (parser === 'USER_WORKLOAD') {
        const dataWithType = data as QuerySystemUserWorkloadDataQuery | undefined

        const userData = defaultUserDataKeys.reduce(
          (userMetaData, userDataProperty) => {
            const user = dataWithType?.sports.user
            if (!user) {
              return userMetaData
            }

            userMetaData[userDataProperty] = null

            if (userDataProperty === 'id' && user.id) {
              userMetaData[userDataProperty] = user.id

              return userMetaData
            }

            if (userDataProperty in user) {
              const userDefaultInformation = user[userDataProperty as keyof typeof user]
              userMetaData[userDataProperty] =
                typeof userDefaultInformation === 'number' ||
                typeof userDefaultInformation === 'string'
                  ? userDefaultInformation
                  : null
            }

            return userMetaData
          },
          {} as Record<string, string | number | null>,
        )

        if (!dataWithType?.sports.user?.sportsDataList) {
          return { queryParserData: [], postProcessData }
        }

        const result = dataWithType?.sports.user?.sportsDataList.map((sportsData) => {
          if (!sportsData.workload?.isChecked) {
            return [
              {
                ...userData,
                time: sportsData.date,
              },
            ]
          }

          return [
            {
              ...userData,
              time: sportsData.date,
              insertTime: sportsData.date,
              parsedWorkload: {
                time: sportsData.date,
                ...sportsData.workload,
              },
              ...sportsData.workload,
            },
          ]
        })

        return { queryParserData: result, postProcessData }
      } else if (parser === 'TEAM_MONITORING') {
        const teamMonitoringData = data as MonitoringChartDataQuery | undefined
        const userData = teamMonitoringData?.sports.team?.chartData?.userList
        if (!userData) {
          return { queryParserData: [], postProcessData }
        }

        const dataField = config.dataset[0].parser.y?.field as string

        const isInjuryData = dataField.startsWith('level')
        const isAxCxData = config.dataset.length === 2

        const userDataType = dataField.split('_')[0].toLowerCase() as string
        const today = moment().format('YYYY-MM-DD')

        const parsedData = userData.map(({ user, value }) => {
          const userValue = value as MonitoringChartDataType

          const userInjuryData = userValue[userDataType] as unknown as SorenessData
          const userData = isInjuryData ? [userInjuryData][0]?.count : userValue[userDataType]
          // 단조로움 데이터는 null 이면 Infinity로 치환
          const axdData = userValue[userDataType] === null ? Infinity : userValue[userDataType]

          const result = {
            [userDataType]: userDataType === 'axd' ? axdData : userData,
            id: user.id,
            name: user.name,
            pictureUrl: user.pictureUrl,
            time: today,
            workloadList: userValue.workloadList,
            reasonForNoWorkout: userValue.reasonForNoWorkout,
          }

          if (isAxCxData) {
            return [Object.assign(result, { cx: userValue['cx'] })]
          }

          return [result]
        })

        const paredDataWorkloadSorted = parsedData.sort(workloadDataSort)

        const chartDataAvg = teamMonitoringData.sports.team?.chartData
          .avg as MonitoringChartDataType

        const injuryCountData = chartDataAvg[userDataType] as unknown as SorenessData

        const ACWR_DATASET = ['acxr', 'axd']

        const yLabel = isInjuryData ? injuryCountData?.count : chartDataAvg[userDataType]
        const acwrLabel = () => {
          const isAcwrData = ACWR_DATASET.includes(userDataType)

          if (isAcwrData) {
            return chartDataAvg[userDataType] >= '2.5' ? 2.5 : chartDataAvg[userDataType]
          }
          return null
        }

        const newPostProcessData: any = {
          y: acwrLabel() || yLabel,
          label: yLabel,
        }

        queryParserData = { parser: paredDataWorkloadSorted, postProcessData: newPostProcessData }
      } else if (parser === 'TEAM_MONITORING_WORKLOAD') {
        const workloadData = data as MonitoringChartDataQuery | undefined
        const userData = workloadData?.sports.team?.chartData.userList

        if (!userData) {
          return { queryParserData: [], postProcessData }
        }

        const dataField = config.dataset[0].parser.y?.field as WorkloadData
        const workloadMappingDataField = dataField === 'workload' ? 'load' : dataField

        const parsedData = userData?.map(({ user, value }) => {
          const userWorkloadValue = value as ChartUserWorkload
          const { workloadList } = userWorkloadValue

          const workloadListValue = {} as any
          workloadList?.map(
            (workload, index) => (workloadListValue[index] = workload![workloadMappingDataField]),
          )

          return [
            {
              id: user.id,
              name: user.name,
              pictureUrl: user.pictureUrl,
              [dataField]: workloadListValue,
              workloadTotal: userWorkloadValue[dataField],
              workloadList: userWorkloadValue.workloadList,
              reasonForNoWorkout: userWorkloadValue.reasonForNoWorkout,
            },
          ]
        })

        const paredDataSorted = parsedData.sort(workloadDataSort)

        const chartData = workloadData.sports.team?.chartData.avg as ChartWorkload
        const yLabel = chartData[dataField]

        const newPostProcessData: any = {
          y: yLabel,
          label: yLabel,
        }

        queryParserData = { parser: paredDataSorted, postProcessData: newPostProcessData }
      } else if (parser === 'PLAYER_WORKLOAD_DATA') {
        const workloadData = data
        const userData = workloadData?.sports.user as SportsUser

        const { id, name, pictureUrl } = userData

        const dataField = config.dataset[0].parser.y?.field as WorkloadData
        const getDataField = dataField === 'workload' ? 'load' : dataField

        const parsedData = userData.sportsDataList.map((sportsData: SportsData) => {
          const workloadListValue = {} as any

          const userWorkloadList = sportsData?.workload?.workloadList

          if (!userWorkloadList?.length || !sportsData.workload?.isChecked) {
            workloadListValue[0] = 0
          } else {
            userWorkloadList?.map((workload, index) => {
              return (workloadListValue[index] = workload[getDataField])
            })
          }

          const workloadTotal = sportsData?.workload?.[dataField].sum

          return [
            {
              id,
              name,
              pictureUrl,
              [dataField]: workloadListValue,
              workloadTotal,
              time: moment(sportsData?.date).format('YYYY-MM-DD'),
              workloadList: sportsData.workload?.isChecked && userWorkloadList,
              reasonForNoWorkout: sportsData?.workload?.reasonForNoWorkout,
            },
          ]
        })

        return { queryParserData: parsedData, postProcessData }
      } else if (parser === 'TEAM_USER_MAPS_DATA' || parser === 'DAILY_CONDITION') {
        const dataWithType = data as QuerySystemTeamUserMapsDataQuery | undefined

        const todayData = dataWithType?.sports.team?.userMapsByPage.entries.map((entry) => {
          const { time, value } = entry.user.data[0].ranges[0].entries[0]

          const entryDate = moment(time).format('YYYY-MM-DD')
          const today = moment(entry.user.data[0].ranges[0].from).format('YYYY-MM-DD')

          if (entryDate === today && value) {
            return entry
          }

          const previousEntry = cloneDeep(entry)
          previousEntry.user.data[0].ranges[0].entries[0].value = null

          return previousEntry
        }) as []

        const userMapEntries =
          parser === 'DAILY_CONDITION'
            ? todayData
            : dataWithType?.sports.team?.userMapsByPage?.entries

        if (!userMapEntries) {
          return { queryParserData: [], postProcessData }
        }

        const clonePostProcessData = cloneDeep(postProcessData)
        const parsedData = userMapEntries
          .map(({ user }) => {
            // TODO: refactoring if need data by period in team data;
            const { queryParserData, postProcessData } = queryParser('USER_DATA', {
              sports: { user },
            })
            return {
              queryParserData: queryParserData[0],
              postProcessData,
            }
          })
          .reduce<QueryParser['queryParserData']>((acc, item) => {
            if (item.queryParserData) {
              acc.push(item.queryParserData)
            }
            if (item.postProcessData) {
              Object.entries(item.postProcessData).forEach(([key, value]) => {
                clonePostProcessData[key].push(...value)
              })
            }
            return acc
          }, [])
        queryParserData = { parser: parsedData, postProcessData: clonePostProcessData }
      } else if (parser === 'TEAM_AVG_DATA') {
        const dataWithType = data as QuerySystemTeamAverageDataQuery | undefined

        if (!dataWithType?.sports.team?.data) {
          return { queryParserData: [], postProcessData: {} }
        }

        queryParserData = parserUserData(dataWithType.sports.team.data, dataset)
      } else {
        return { queryParserData: [], postProcessData }
      }
      return {
        queryParserData: queryParserData.parser,
        postProcessData: queryParserData.postProcessData,
      }
    },
    [postProcessData, defaultUserDataKeys, dataset, moment, config.dataset],
  )

  const parserMap = useCallback(
    (data: QuerySystemReturnType['data']) => {
      // [multiDataByDays][dataByFromTo] / [dataByDataName];
      const { queryParserData, postProcessData } = queryParser(config.parser, data)

      const parserByDataset =
        dataset.map((chartItemMap) => {
          const { parser: datasetParser } = chartItemMap

          return (
            queryParserData?.map((dataByDate, index) => {
              const hasDataByDate = dataByDate.length > 0
              const interpretedData =
                (hasDataByDate ? dataByDate : [{}]).reduce(
                  (interpretDataByDay, multiRecordDataByDay) => {
                    if (chartItemMap.type !== 'POLAR') {
                      interpretDataByDay.x = index + 1
                    }

                    Object.entries(datasetParser).forEach(
                      ([datasetParserKey, datasetParserItem]) => {
                        const yInterpreter = interpreter(datasetParserItem?.interpretationFormat)
                        const mappingParserItemValue = datasetParserItem?.field
                          ? datasetParserItem.postOperation
                            ? postProcessData[datasetParserKey]
                            : has(multiRecordDataByDay, datasetParserItem?.field || '')
                            ? get(multiRecordDataByDay, datasetParserItem?.field || '')
                            : get(
                                multiRecordDataByDay,
                                datasetParserItem?.field?.toUpperCase() || '',
                              )
                          : datasetParserItem?.value

                        const interpreted = Array.isArray(mappingParserItemValue)
                          ? yInterpreter([mappingParserItemValue])
                          : yInterpreter([
                              interpretDataByDay[datasetParserKey],
                              mappingParserItemValue,
                            ])

                        interpretDataByDay[datasetParserKey] =
                          interpreted === null || interpreted === undefined
                            ? interpretDataByDay[datasetParserKey] || 0
                            : interpreted

                        if (datasetParserItem?.interpretPath) {
                          const interpretedLabelDegree = interpret(
                            datasetParserItem.interpretPath,
                            multiRecordDataByDay,
                          )
                          if (interpretedLabelDegree) {
                            interpretDataByDay.interpretation = interpretedLabelDegree
                          }
                        }
                      },
                    )

                    defaultUserDataKeys.forEach((userProperty) => {
                      interpretDataByDay[userProperty] = multiRecordDataByDay[userProperty]
                    })

                    return interpretDataByDay
                  },
                  {} as Record<string, number | null>,
                ) || {}

              Object.entries(datasetParser).forEach(([datasetParserKey, datasetParserItem]) => {
                if (datasetParserItem?.format) {
                  interpretedData[datasetParserKey] = format(datasetParserItem.format)(
                    interpretedData[datasetParserKey],
                  )
                }
                if (
                  (datasetParserItem?.index === 'LAST_ONLY' &&
                    queryParserData?.length - 1 !== index) ||
                  (datasetParserItem?.index === 'FIRST_ONLY' && index !== 0)
                ) {
                  delete interpretedData[datasetParserKey]
                }
              })

              return interpretedData
            }) || []
          )
        }) || []

      return parserByDataset
    },
    [config.parser, dataset, defaultUserDataKeys, format, interpret, interpreter, queryParser],
  )

  return useMemo(
    () => ({
      parser: (data?: QuerySystemReturnType['data']) => parserMap(data),
    }),
    [parserMap],
  )
}
