import React, { useMemo } from 'react'
import {
  Modal as ReactResponsiveDialogModal,
  ModalProps as ReactResponsiveDialogModalProps,
} from 'react-responsive-modal'

import styled from '@emotion/styled'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { ButtonProps } from '@plco-pro/components/atoms/button'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { DialogModalFooter } from '@plco-pro/components/molecules/dialog-modal-footer'
import { useResponsive } from '@plco-pro/hooks/responsive'

import 'react-responsive-modal/styles.css'

export type DialogModalProps = {
  width?: string
  open: boolean
  onClose?: () => void
  title?: string
  subtitle?: string | React.ReactNode
  closeOnEsc?: boolean
  footer?: React.ReactNode
  footerProps?: { leftButtonProps?: ButtonProps; rightButtonProps?: ButtonProps }
  children?: React.ReactNode
  isHyper?: boolean
}

export type StyledReactResponsiveDDialogModalProps = ReactResponsiveDialogModalProps & {
  width?: string
  height?: string
  className?: string
}

// react portal workaround for styled-components
// cf: https://material-ui.com/guides/interoperability/#portals
const StyledReactResponsiveDialogModal = styled(
  ({ className, ...rest }: StyledReactResponsiveDDialogModalProps) => (
    <ReactResponsiveDialogModal
      {...rest}
      onClose={rest.onClose ? rest.onClose : () => {}}
      classNames={{
        root: className,
      }}
      styles={{
        // modal root z-index 1000, toasts z-index 1000, so modal hide toasts
        root: {
          zIndex: 999,
        },
      }}
    />
  ),
)`
  .react-responsive-modal-modal {
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
    padding: 0;
    margin: 0;
    border-radius: 8px;
  }

  .react-responsive-modal-modal:focus {
    outline: none;
  }

  .react-responsive-modal-overlay {
    background: rgba(19, 24, 32, 0.6);
  }
`

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{
      width: '100%',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}
    {...props}
  />
)

const ContentBox = (props: BoxProps) => (
  <Box
    sx={{
      flex: '1 1 auto',
      px: 3,
      pt: 4,
    }}
    {...props}
  />
)

export const DialogModal: React.FunctionComponent<DialogModalProps> = ({
  width,
  open,
  onClose = () => {},
  title,
  subtitle,
  closeOnEsc = true,
  footer,
  footerProps,
  children,
  isHyper,
}) => {
  const getResponsiveProp = useResponsive()

  const responsiveContainerWidth = useMemo(() => ['100%', width || null], [width])
  const responsiveWidth = useMemo(
    () => getResponsiveProp(['calc(100% - 48px)', width || null]),
    [getResponsiveProp, width],
  )

  const hasSubtitle = useMemo(() => children || subtitle, [children, subtitle])

  return (
    <StyledReactResponsiveDialogModal
      open={open}
      onClose={onClose}
      closeOnEsc={closeOnEsc}
      onOverlayClick={onClose}
      center
      showCloseIcon={false}
      animationDuration={300}
      focusTrapped={false}
      width={responsiveWidth}
    >
      <ContainerFlex
        sx={{
          width: responsiveContainerWidth,
          maxHeight: '100vh',
        }}
      >
        <ContentBox sx={{ pb: isHyper ? 3 : null }}>
          <Text
            variant={['s1', 'h5']}
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              whiteSpace: 'pre-line',
              mt: hasSubtitle ? 0 : 2,
              mb: hasSubtitle ? 0 : 5,
            }}
          >
            {title}
          </Text>

          {children ? (
            <Box sx={{ mt: [1, 2], mb: 1 }}>{children}</Box>
          ) : (
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                mt: [1, 2],
                mb: 1,
              }}
            >
              <Box>
                <Text
                  variant={['p1', 's2']}
                  sx={{ textAlign: 'center', whiteSpace: 'pre-line', fontWeight: 'regular' }}
                >
                  {subtitle}
                </Text>
              </Box>
            </Flex>
          )}
        </ContentBox>
        <DialogModalFooter
          isHyper={isHyper}
          leftButtonProps={footerProps?.leftButtonProps}
          rightButtonProps={footerProps?.rightButtonProps}
        >
          {footer}
        </DialogModalFooter>
      </ContainerFlex>
    </StyledReactResponsiveDialogModal>
  )
}
