import m, { Moment as MomentOrigin } from 'moment'
import { extendMoment } from 'moment-range'
import momentTZ from 'moment-timezone'

export const moment = extendMoment(momentTZ as any)
export type Moment = ReturnType<typeof extendMoment>

// fix bug: https://github.com/moment/moment-timezone/issues/647#issuecomment-439600573
const momentLocale: any = moment.locale
moment.locale = (...args: any[]) => {
  const mLocales = m.locales()
  const momentLocales = moment.locales()
  if (mLocales.length !== momentLocales.length) {
    for (const lng of mLocales) {
      if (!momentLocales.includes(lng)) {
        // @ts-ignore
        moment.defineLocale(lng, m.localeData(lng)._config)
      }
    }
  }

  return momentLocale(...args)
}

export type { MomentOrigin }
