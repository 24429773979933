import { pathToRegexp } from 'path-to-regexp'

import { __ENV__ } from '@plco-pro/env'
import { SportsTeamAuthority } from '@plco-pro/graphqls/react.generated'

// permissions
export type PermissionMapKey =
  // access pages
  | 'ACCESS_INDEX'
  | 'ACCESS_UNAUTHORIZED'
  | 'ACCESS_NOT_FOUND'
  | 'ACCESS_AUTH_CALLBACK'
  | 'ACCESS_DASHBOARD'
  | 'ACCESS_TEAMCHART'
  | 'ACCESS_MONITORING'
  | 'ACCESS_EVENT'
  | 'ACCESS_INSIGHT'
  | 'ACCESS_PLAYERS'
  | 'ACCESS_PROFILE'
  | 'ACCESS_REGISTER'
  | 'ACCESS_TEAMS'
  | 'ACCESS_TEAMS_MEMBERS'
  | 'ACCESS_TEAMS_NEW'
  | 'ACCESS_TEAMS_PROFILE'
  | 'ACCESS_PLAN'
  | 'ACCESS_DEEP_LINK'
  | 'ACCESS_BLOG'
  | 'ACCESS_POST'
  | 'ACCESS_WORKLOAD_AI'
  // navbar
  | 'ACCESS_NOTABLE_ALARM'
  // debug
  | 'ACCESS_DEBUG'
  // authority
  | 'SET_AUTHORITY_OWNER'
  | 'SET_AUTHORITY_ADMIN'
  | 'SET_AUTHORITY_MEMBER'
  // invitation
  | 'ACCEPT_MEMBER'
  | 'REJECT_MEMBER'
  // kickout
  | 'KICKOUT_ADMIN'
  | 'KICKOUT_PLAYER'
  // team
  | 'CREATE_TEAM'
  | 'EDIT_TEAM'
  | 'LEAVE_TEAM'
  | 'DELETE_TEAM'
  // team code
  | 'VIEW_TEAM_CODE'
  | 'JOIN_TEAM_CODE'
  // players
  | 'VIEW_STARRED_PLAYERS'
  | 'EDIT_STARRED_PLAYERS'
  // dashboard
  | 'VIEW_DASHBOARD'
  | 'VIEW_DASHBOARD_BANNER'
  // teamchart
  | 'VIEW_TEAMCHART'
  // monitoring
  | 'VIEW_MONITORING'
  // group
  | 'VIEW_GROUP'
  | 'CREATE_GROUP'
  | 'EDIT_GROUP'
  | 'DELETE_GROUP'
  // event
  | 'VIEW_EVENT'
  | 'CREATE_EVENT'
  | 'EDIT_EVENT'
  | 'DUPLICATE_EVENT'
  | 'DELETE_EVENT'
  // notification
  | 'SEND_CHECK_NOTIFICATION'
  // subscription
  | 'REQUIRE_VALID_SUBSCRIPTION'
  // injury
  | 'CREATE_INJURY'
  // report
  | 'EXPORT_REPORT'
  | 'ACCESS_TRAINING_POINT'
  | 'ACCESS_MATCH_RESULT'
  | 'ACCESS_MATCH_LINEUP'

type RoleMap = Record<PermissionMapKey, Array<`${SportsTeamAuthority}`>>

const publicAccessPermissions: PermissionMapKey[] = [
  'ACCESS_INDEX',
  'ACCESS_UNAUTHORIZED',
  'ACCESS_NOT_FOUND',
  'ACCESS_AUTH_CALLBACK',
  'ACCESS_REGISTER',
  'ACCESS_TEAMS_NEW',
  'ACCESS_WORKLOAD_AI',
]
const debugAccessPermissions: PermissionMapKey[] =
  __ENV__ === 'development' || __ENV__ === 'stage' ? ['ACCESS_DEBUG'] : []
const AllPermission: SportsTeamAuthority[] = Object.values(SportsTeamAuthority)

const publicAccessRoleMap = [...publicAccessPermissions, ...debugAccessPermissions].reduce(
  (acc, cur) => {
    acc[cur] = AllPermission
    return acc
  },
  {} as RoleMap,
)

export const roleMap: RoleMap = {
  // access pages
  ...publicAccessRoleMap,
  ACCESS_DASHBOARD: ['OWNER', 'ADMIN', 'MEMBER', 'PENDING_MEMBER', 'OBSERVER'],
  ACCESS_MONITORING: ['OWNER', 'ADMIN', 'MEMBER', 'PENDING_MEMBER', 'OBSERVER'],
  ACCESS_TEAMCHART: ['OWNER', 'ADMIN', 'OBSERVER'],
  ACCESS_EVENT: ['OWNER', 'ADMIN', 'OBSERVER'],
  ACCESS_INSIGHT: ['OWNER', 'ADMIN', 'OBSERVER'],
  ACCESS_PLAN: ['OWNER', 'ADMIN', 'OBSERVER'],
  ACCESS_PLAYERS: ['OWNER', 'ADMIN', 'OBSERVER'],
  ACCESS_PROFILE: ['OWNER', 'ADMIN', 'MEMBER', 'PENDING_MEMBER', 'OBSERVER'],
  ACCESS_TEAMS: ['OWNER', 'ADMIN', 'MEMBER', 'PENDING_MEMBER', 'OBSERVER'],
  ACCESS_TEAMS_MEMBERS: ['OWNER', 'ADMIN'],
  ACCESS_TEAMS_PROFILE: ['OWNER', 'ADMIN'],
  ACCESS_BLOG: ['OWNER', 'ADMIN'],
  ACCESS_POST: ['OWNER', 'ADMIN'],
  // navbar
  ACCESS_NOTABLE_ALARM: ['OWNER', 'ADMIN'],
  // authority
  SET_AUTHORITY_OWNER: ['OWNER'],
  SET_AUTHORITY_ADMIN: ['OWNER', 'ADMIN'],
  SET_AUTHORITY_MEMBER: ['OWNER', 'ADMIN'],
  // invitation
  ACCEPT_MEMBER: ['OWNER', 'ADMIN'],
  REJECT_MEMBER: ['OWNER', 'ADMIN'],
  // kickout
  KICKOUT_ADMIN: ['OWNER', 'ADMIN'],
  KICKOUT_PLAYER: ['OWNER', 'ADMIN'],
  // team
  CREATE_TEAM: ['OWNER', 'ADMIN', 'MEMBER', 'PENDING_MEMBER'],
  EDIT_TEAM: ['OWNER', 'ADMIN'],
  DELETE_TEAM: ['OWNER'],
  LEAVE_TEAM: ['ADMIN', 'MEMBER', 'PENDING_MEMBER'],
  // team code
  VIEW_TEAM_CODE: ['OWNER', 'ADMIN'],
  JOIN_TEAM_CODE: ['OWNER', 'ADMIN', 'MEMBER', 'PENDING_MEMBER'],
  // players
  VIEW_STARRED_PLAYERS: ['OWNER', 'ADMIN'],
  EDIT_STARRED_PLAYERS: ['OWNER', 'ADMIN'],
  // dashboard
  VIEW_DASHBOARD: ['OWNER', 'ADMIN', 'OBSERVER'],
  VIEW_DASHBOARD_BANNER: ['OWNER', 'ADMIN'],
  // teamchart
  VIEW_TEAMCHART: ['OWNER', 'ADMIN', 'OBSERVER'],
  // monitoring
  VIEW_MONITORING: ['OWNER', 'ADMIN', 'OBSERVER'],
  // group
  VIEW_GROUP: ['OWNER', 'ADMIN', 'OBSERVER'],
  CREATE_GROUP: ['OWNER', 'ADMIN'],
  EDIT_GROUP: ['OWNER', 'ADMIN'],
  DELETE_GROUP: ['OWNER', 'ADMIN'],
  // event
  VIEW_EVENT: ['OWNER', 'ADMIN', 'OBSERVER'],
  CREATE_EVENT: ['OWNER', 'ADMIN'],
  EDIT_EVENT: ['OWNER', 'ADMIN'],
  DUPLICATE_EVENT: ['OWNER', 'ADMIN'],
  DELETE_EVENT: ['OWNER', 'ADMIN'],
  // notification
  SEND_CHECK_NOTIFICATION: ['OWNER', 'ADMIN'],
  // subscription
  REQUIRE_VALID_SUBSCRIPTION: ['OWNER', 'ADMIN'],
  // injury
  CREATE_INJURY: ['OWNER', 'ADMIN'],
  // report
  EXPORT_REPORT: ['OWNER', 'ADMIN'],
  ACCESS_TRAINING_POINT: ['OWNER', 'ADMIN'],
  ACCESS_MATCH_RESULT: ['OWNER', 'ADMIN'],
  ACCESS_MATCH_LINEUP: ['OWNER', 'ADMIN'],
}

// access
export const accessMap = new Map<PermissionMapKey, RegExp>([
  ['ACCESS_INDEX', pathToRegexp('/')],
  ['ACCESS_UNAUTHORIZED', pathToRegexp('/unauthorized')],
  ['ACCESS_NOT_FOUND', pathToRegexp('/404')],
  ['ACCESS_AUTH_CALLBACK', pathToRegexp('/auth-callback/(.*)')],
  ['ACCESS_DASHBOARD', pathToRegexp('/dashboard(.*)')],
  ['ACCESS_TEAMCHART', pathToRegexp('/teamchart(.*)')],
  ['ACCESS_MONITORING', pathToRegexp('/monitoring')],
  ['ACCESS_EVENT', pathToRegexp('/event')],
  ['ACCESS_INSIGHT', pathToRegexp('/insight')],
  ['ACCESS_PLAN', pathToRegexp('/plan/(.*)')],
  ['ACCESS_PLAYERS', pathToRegexp('/players/(.*)')],
  ['ACCESS_PROFILE', pathToRegexp('/profile')],
  ['ACCESS_REGISTER', pathToRegexp('/register/(.*)')],
  ['ACCESS_TEAMS', pathToRegexp('/teams')],
  ['ACCESS_TEAMS_MEMBERS', pathToRegexp('/teams/:id/members')],
  ['ACCESS_TEAMS_NEW', pathToRegexp('/teams/new')],
  ['ACCESS_TEAMS_PROFILE', pathToRegexp('/teams/:id/profile')],
  ['ACCESS_BLOG', pathToRegexp('/blog(.*)')],
  ['ACCESS_POST', pathToRegexp('/blog/:id')],
  ['ACCESS_DEBUG', pathToRegexp('/debug(.*)')],
])
