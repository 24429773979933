import { useMemo } from 'react'

import {
  Maybe,
  SportsCategory,
  SportsCategoryGroup,
  SportsPosition,
  SportsRole,
  useMultilingualDataQuery,
} from '@plco-pro/graphqls/react.generated'
import { useStore } from '@plco-pro/stores'

export const LANGUAGE_MAP = {
  ko: 'kr',
  en: 'us',
  ja: 'jp',
  vn: 'vn',
}

export interface MultilingualProps {
  defaultLanguage?: keyof typeof LANGUAGE_MAP
  group?: Maybe<SportsCategoryGroup | undefined>
  category?: Maybe<SportsCategory | undefined>
  role?: Maybe<SportsRole | undefined>
  position?: Maybe<SportsPosition | undefined>
}

export const useMultilingual = ({
  defaultLanguage,
  group,
  category,
  role,
  position,
}: MultilingualProps) => {
  const { preference } = useStore()

  const language =
    defaultLanguage || (preference.locale.language.split('-')[0] as keyof typeof LANGUAGE_MAP)

  const { data, loading } = useMultilingualDataQuery({
    variables: {
      input: {
        nationality: `all_country_code__${LANGUAGE_MAP[language]}`,
      },
    },
    fetchPolicy: 'cache-first',
  })

  const categoryGroupList = data?.multilingualText.categoryGroupList?.find(
    (groupItem) => groupItem.id === group,
  )

  const categoryList = categoryGroupList?.categoryList.find(
    (categoryItem) => categoryItem.id === category,
  )

  const categoryById = data?.multilingualText.categoryGroupList
    ?.map(({ categoryList }) => categoryList.find((categoryItem) => categoryItem.id === category))
    .find((data) => data)

  const positionList = data?.multilingualText.categoryGroupList.flatMap((categoryGroup) => {
    return categoryGroup.categoryList.flatMap((category) => {
      return [...category.coachPositionList, ...category.playerPositionList]
    })
  })

  const currentPosition = useMemo(() => {
    if (role === SportsRole.COACH) {
      return categoryList?.coachPositionList.find((positionItem) => positionItem.id === position)
    }

    return categoryList?.playerPositionList.find((positionItem) => positionItem.id === position)
  }, [categoryList, position, role])

  return {
    multilingualData: data,
    loading,
    categoryGroupList,
    categoryList,
    categoryById,
    positionList,
    currentPosition,
    countryList: data?.multilingualText.countryList,
  }
}
