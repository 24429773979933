/* eslint-disable max-len */
import { error } from './error'
import { validationError } from './validation-error'

export type I18nCommonMessagePack = typeof messages

export const messages = {
  ...error.en,
  ...validationError.en,
  // date format
  'date.format.month-minute': 'MMM D, HH:mm',
  'date.format.month-day-time': 'MMM D, LT',
  'date.format.year-month-day': 'MMM D, YYYY',
  'date.format.year-month-day-suffix': 'MMM D, YYYY',
  'date.format.year-to-minutes': 'MMM D, YYYY · HH:mm',
  'date.format.year-to-minutes-a': 'MMM D, YYYY h:mm A',
  'date.format.month-day': 'MMM D',
  'date.format.period': 'MMM D, YYYY',
  'date.format.year-month': 'YYYY. M',
  'date.format.year-month-day-week': 'dddd MMM D, YYYY',
  'date.format.year-month-day-week-suffix': 'dddd MMM D, YYYY · HH:mm',
  'date.format.hour-minutes': '{hour}h {minute}m',

  // date (for scheduler)
  'date.year-month': '{MMM} {YYYY}',
  'date.year-full-month': '{MMMM}, {YYYY}',
  'date.weekdays': 'MON_TUE_WED_THU_FRI_SAT_SUN',

  'date.left.day': 'left {days}',
  'date.duration.time': 'Hh Mm',

  'date-range.title-format': 'MMMM yyyy',
  'date-range.7-days': 'Last 7 days',
  'date-range.30-days': 'Last 30 days',

  NO: 'No',
  YES: 'Yes',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  CONFIRM: 'Confirm',
  DONE: 'Done',
  RETRY: 'Retry',
  LEARN_MORE: 'Learn more',
  APPLY: 'Apply',
  SEND: 'Send',
  SELECT: 'Select',
  SEARCH: 'Search',
  HOUR: '{hours, plural, =0 {# hour} other {# hours}}',
  H: 'h',
  MINUTE: '{minutes, plural, =0 {# minute} other {# minutes}}',
  M: 'm',
  DAY: 'day',
  MONTH: 'Month',
  YEAR: 'Year',
  COUNT: 'unit',
  POINT: 'pt',
  SCORE: 'pt',
  PEOPLE: ' ',
  PEOPLE_COUNT: '{count}',
  CM: 'cm',
  KG: 'kg',
  NAME: 'name',
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  START: 'Start',
  NEXT: 'Next',
  BACK: 'Back',
  NOT_NOW: 'Not now',
  OKAY: 'Okay',
  LEAVE: 'Leave',
  SAVE: 'Save',
  AVG: 'Avg',
  SUM: 'Sum',
  USER: 'User',
  MAKE: 'Make',
  ADD: 'Add',
  CHANGE: 'Change',
  PART: '{count, plural, =1 {part} other {parts}}',
  GOAL: 'goal',

  ALL: 'All',
  FILTER: 'Filter',
  NO_SEARCH_RESULT: 'No results found.',

  SELECT_ALL: 'SELECT ALL',

  DELETE: 'Delete',
  REFRESH: 'Refresh',

  // auth federation
  'federation.kakao': 'KakaoTalk',
  'federation.facebook': 'Facebook',
  'federation.apple': 'Apple',
  'federation.google': 'Google',

  // languages
  'ko-KR': '한국어',
  'en-KR': 'English',
  'ja-KR': '日本語',
  'vn-VN': 'Tiếng Việt',

  // data
  READINESS: 'Readiness',
  FATIGUE: 'Fatigue',
  STRESS: 'Stress',
  MUSCLE: 'Muscle',
  MOOD: 'Mood',
  SLEEP_DURATION: 'Sleep duration',
  DURATION: 'Sleep duration',
  SLEEP_QUALITY: 'Sleep quality',
  QUALITY: 'Sleep quality',
  WORKLOAD_ACXR: 'Risk of injury (ACWR)',
  WORKLOAD_AXD: 'Monotony',
  WORKLOAD_DURATION: 'Duration',
  WORKLOAD_INTENSITY: 'Intensity',
  WORKLOAD_INTENSITY_RPE: 'Intensity (RPE)',
  WORKLOAD_SATISFACTION: 'Satisfaction',
  WORKLOAD_AX: 'Acute Workload',
  WORKLOAD_CX: 'Chronic Workload',
  WORKLOAD_AX_CX: 'Acute & Chronic Workload',
  CONDITION: 'Condition',
  SORENESS_COUNT: 'Soreness count',
  SORENESS_1: 'Level 1',
  SORENESS_2: 'Level 2',
  SORENESS_3: 'Level 3',
  SORENESS_4: 'Level 4',
  SORENESS_5: 'Level 5 ',
  WORKLOAD: 'Workload',
  HEIGHT: 'Height',
  WEIGHT: 'Weight',
  ACWR: 'Risk of injury',
  DANGER_TRAINING: 'danger', // 위험
  WARNING_TRAINING: 'warning', // 주의
  BEST_TRAINING: 'best', // 최적
  CAUTION_TRAINING: 'caution', // 유의
  RECENT_RECORDS: 'Recent records',

  INJURY_NUMBER_0: 'No pain',
  INJURY_NUMBER_1: 'Level 1',
  INJURY_NUMBER_2: 'Level 2',
  INJURY_NUMBER_3: 'Level 3',
  INJURY_NUMBER_4: 'Level 4',
  INJURY_NUMBER_5: 'Level 5',
  TYPE: 'Type',

  SORENESS_TYPE: 'Type',
  SORENESS_MEMO: 'Memo',
  SORENESS_SITUATION: 'Situation of occ.',

  CONTACT: 'Contact',
  NON_CONTACT: 'Non-contact',

  // period
  '1-day': 'Daily',
  '1-week': 'Weekly',
  '1-month': 'Monthly',

  // scheduler type
  'event-type.TRAINING': 'Training',
  'event-type.MATCH': 'Match',
  'event-type.PRACTICE': 'Practice Match',
  'event-type.REST': 'Rest',
  'event-type.OTHER_EVENT': 'Etc.',

  CHECK_RATE: 'Check rate',
  CHECK: 'Check',
  UNCHECKED: 'Unchecked',

  ATTEND: 'Attend',
  ABSENCE: 'Absence',

  // constants
  SEE_MORE: 'See more',
  SORENESS_INTERPOLATED_STREAK:
    '{days, plural, =0 {None} =1 {since # day ago} =28 {since more than # days ago} other {since # days ago}}',
  SORENESS_DEGREE: 'Level {degree}',
  SORENESS_DEGREE_5: 'Level {degree} (Injury)',
  STREAK:
    '{days, plural, =0 {Not checked yet} =1 {# day in a row} =9999 {9999+ days in a row} other {# days in a row}}',
  STREAK_NEGATIVE:
    '{days, plural, =0 {Not checked yet} =9999 {9999+ days since last check} =1 {# day since last check} other {# days since last check}}',
  'USER.NAME': 'Name',
  'USER.NAME.placeholder': 'Enter your real name',
  'USER.NATIONALITY': 'Nationality',
  'USER.GENDER': 'Gender',
  'USER.BIRTHDATE': 'Birthdate',
  'USER.USERNAME': 'Username',
  'USER.USERNAME.placeholder': 'Enter your username.',
  'USER.PHONENUMBER': 'Phone number',
  'USER.PHONENUMBER.title': 'Change phone number',
  'USER.INTRODUCE': 'Introduction',
  'USER.INTRODUCE.placeholder': 'Introduce yourself',
  'USER.INTRODUCTION.empty': 'No introduction yet.',
  'GENDER.MALE': 'male',
  'GENDER.FEMALE': 'female',
  'GENDER.OTHER': 'other',
  PLAYER: 'PLAYER',
  COACH: 'COACH',
  ROLE: 'Role',
  'ROLE.PLAYER': 'Player',
  'ROLE.COACH': 'Coach',
  CATEGORY: 'Category',
  POSITION: 'Position',
  TEAM: 'Team',
  GROUP: 'Group',
  BIRTHDATE: 'Birthdate',

  MON: 'Mo', // 월
  TUE: 'Tu', // 화
  WED: 'We', // 수
  THU: 'Th', // 목
  FRI: 'Fr', // 금
  SAT: 'Sa', // 토
  SUN: 'Su', // 일

  // player positions
  'PLAYER_POSITION.EMPTY': ' ',
  'PLAYER_POSITION.SOCCER.FW': 'FW',
  'PLAYER_POSITION.SOCCER.MF': 'MF',
  'PLAYER_POSITION.SOCCER.DF': 'DF',
  'PLAYER_POSITION.SOCCER.GK': 'GK',
  'PLAYER_POSITION.BASKETBALL.PG': 'PG',
  'PLAYER_POSITION.BASKETBALL.SG': 'SG',
  'PLAYER_POSITION.BASKETBALL.SF': 'SF',
  'PLAYER_POSITION.BASKETBALL.PF': 'PF',
  'PLAYER_POSITION.BASKETBALL.C': 'C',
  'PLAYER_POSITION.VOLLEYBALL.LI': 'LI',
  'PLAYER_POSITION.VOLLEYBALL.S': 'S',
  'PLAYER_POSITION.VOLLEYBALL.C': 'C',
  'PLAYER_POSITION.VOLLEYBALL.L': 'L',
  'PLAYER_POSITION.VOLLEYBALL.R': 'R',
  'PLAYER_POSITION.HANDBALL.GK': 'GK',
  'PLAYER_POSITION.HANDBALL.LW': 'LW',
  'PLAYER_POSITION.HANDBALL.RW': 'RW',
  'PLAYER_POSITION.HANDBALL.LB': 'LB',
  'PLAYER_POSITION.HANDBALL.RB': 'RB',
  'PLAYER_POSITION.HANDBALL.CB': 'CB',
  'PLAYER_POSITION.HANDBALL.PV': 'PV',
  'PLAYER_POSITION.BASEBALL.P': 'P',
  'PLAYER_POSITION.BASEBALL.C': 'C',
  'PLAYER_POSITION.BASEBALL._1B': '1B',
  'PLAYER_POSITION.BASEBALL._2B': '2B',
  'PLAYER_POSITION.BASEBALL._3B': '3B',
  'PLAYER_POSITION.BASEBALL.SS': 'SS',
  'PLAYER_POSITION.BASEBALL.LF': 'LF',
  'PLAYER_POSITION.BASEBALL.CF': 'CF',
  'PLAYER_POSITION.BASEBALL.RF': 'RF',
  'PLAYER_POSITION.BASEBALL.DH': 'DH',
  'PLAYER_POSITION.FIELD_HOCKEY.FW': 'FW',
  'PLAYER_POSITION.FIELD_HOCKEY.MF': 'MF',
  'PLAYER_POSITION.FIELD_HOCKEY.DF': 'DF',
  'PLAYER_POSITION.FIELD_HOCKEY.GK': 'GK',
  'PLAYER_POSITION.RUGBY.HK': 'HK',
  'PLAYER_POSITION.RUGBY.PR': 'PR',
  'PLAYER_POSITION.RUGBY.SR': 'SR',
  'PLAYER_POSITION.RUGBY.FL': 'FL',
  'PLAYER_POSITION.RUGBY.NE': 'NE',
  'PLAYER_POSITION.RUGBY.SH': 'SH',
  'PLAYER_POSITION.RUGBY.FH': 'FH',
  'PLAYER_POSITION.RUGBY.CT': 'CT',
  'PLAYER_POSITION.RUGBY.WG': 'WG',
  'PLAYER_POSITION.RUGBY.FB': 'FB',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.C': 'C',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OG': 'OG',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OT': 'OT',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.QB': 'QB',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.RB': 'RB',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.WR': 'WR',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.TE': 'TE',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DT': 'DT',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DE': 'DE',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.MLB': 'MLB',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.OLB': 'OLB',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.CB': 'CB',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.S': 'S',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.NB': 'NB',
  'PLAYER_POSITION.AMERICAN_FOOTBALL.DB': 'DB',
  'PLAYER_POSITION.BILLIARDS.S': 'S',
  'PLAYER_POSITION.BILLIARDS.P': 'P',
  'PLAYER_POSITION.BILLIARDS.C': 'C',
  'PLAYER_POSITION.CRICKET.BO': 'BO',
  'PLAYER_POSITION.CRICKET.BA': 'BA',
  'PLAYER_POSITION.CRICKET.WI': 'WI',
  'PLAYER_POSITION.SEPAK_TAKRAW.K': 'K',
  'PLAYER_POSITION.SEPAK_TAKRAW.F': 'F',
  'PLAYER_POSITION.SEPAK_TAKRAW.T': 'T',
  'PLAYER_POSITION.TEE_BALL.C': 'C',
  'PLAYER_POSITION.TEE_BALL._1B': '1B',
  'PLAYER_POSITION.TEE_BALL._2B': '2B',
  'PLAYER_POSITION.TEE_BALL._3B': '3B',
  'PLAYER_POSITION.TEE_BALL._1SS': '1SS',
  'PLAYER_POSITION.TEE_BALL._2SS': '2SS',
  'PLAYER_POSITION.TEE_BALL.LF': 'LF',
  'PLAYER_POSITION.TEE_BALL._1CF': '1CF',
  'PLAYER_POSITION.TEE_BALL._2CF': '2CF',
  'PLAYER_POSITION.TEE_BALL.RF': 'RF',
  'PLAYER_POSITION.FOOT_VOLLEYBALL.A': 'A',
  'PLAYER_POSITION.FOOT_VOLLEYBALL.S': 'S',
  'PLAYER_POSITION.FOOT_VOLLEYBALL.D': 'D',
  'PLAYER_POSITION.FUTSAL.PIVO': 'Pivo',
  'PLAYER_POSITION.FUTSAL.ALA': 'Ala',
  'PLAYER_POSITION.FUTSAL.FIXO': 'Fixo',
  'PLAYER_POSITION.FUTSAL.GOLEIRO': 'Goleiro',
  'PLAYER_POSITION.SWIMMING.FREESTYLE': 'Freestyle',
  'PLAYER_POSITION.SWIMMING.BACKSTROKE': 'Backstroke',
  'PLAYER_POSITION.SWIMMING.BREASTSTROKE': 'Breaststroke',
  'PLAYER_POSITION.SWIMMING.BUTTERFLY': 'Butterfly',
  'PLAYER_POSITION.SWIMMING.MEDLEY': 'Medley',
  'PLAYER_POSITION.CANOE.SLALOM': 'Slalom',
  'PLAYER_POSITION.CANOE.SPRINT': 'Sprint',
  'PLAYER_POSITION.KAYAK.SLALOM': 'Slalom',
  'PLAYER_POSITION.KAYAK.SPRINT': 'Sprint',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.VAULT': 'Vault',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.FLOOR_EXERCISES': 'Floor exercises',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.RINGS': 'Rings',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.HORIZONTAL_BAR': 'Horizontal bar',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.PARALLEL_BAR': 'Parallel bar',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.POMMEL_HORSE': 'Pommel horse',
  'PLAYER_POSITION.ARTISTIC_GYMNASTICS.BALANCE_BEAM': 'Balance beam',
  'PLAYER_POSITION.ICE_SKATING.SPEED_SKATING': 'Speed skating',
  'PLAYER_POSITION.ICE_SKATING.FIGURE_SKATING': 'Figure skating',
  'PLAYER_POSITION.ICE_SKATING.SHORT_TRACK': 'Short track',
  'PLAYER_POSITION.ICE_HOCKEY.G': 'G',
  'PLAYER_POSITION.ICE_HOCKEY.RD': 'RD',
  'PLAYER_POSITION.ICE_HOCKEY.LD': 'LD',
  'PLAYER_POSITION.ICE_HOCKEY.RW': 'RW',
  'PLAYER_POSITION.ICE_HOCKEY.CF': 'CF',
  'PLAYER_POSITION.ICE_HOCKEY.LW': 'LW',
  'PLAYER_POSITION.CURLING.LEAD': 'Lead',
  'PLAYER_POSITION.CURLING.SECOND': 'Second',
  'PLAYER_POSITION.CURLING.THIRD': 'Third',
  'PLAYER_POSITION.CURLING.SKIP': 'Skip',
  'PLAYER_POSITION.FENCING.FLEURET': 'Fleuret',
  'PLAYER_POSITION.FENCING.SABRE': 'Sabre',
  'PLAYER_POSITION.FENCING.EPEE': 'Epee',

  // coach positions
  'COACH_POSITION.HEAD_COACH': 'Head coach',
  'COACH_POSITION.FIRST_COACH': 'First coach',
  'COACH_POSITION.COACH': 'Coach',
  'COACH_POSITION.PLAYING_COACH': 'Playing coach',
  'COACH_POSITION.ATHLETIC_TRAINER': 'Athletic trainer',
  'COACH_POSITION.GK_COACH': 'GK coach',
  'COACH_POSITION.TACTICAL_COACH': 'Tactical coach',
  'COACH_POSITION.FITNESS_COACH': 'Fitness coach',
  'COACH_POSITION.ASSISTANT_COACH': 'Assistant coach',
  'COACH_POSITION.MANAGER': 'Manager',
  'COACH_POSITION.BENCH_COACH': 'Bench coach',
  'COACH_POSITION.HITTING_COACH': 'Hitting coach',
  'COACH_POSITION.PITCHING_COACH': 'Pitching coach',
  'COACH_POSITION.DEFENSIVE_COACH': 'Defensive coach',
  'COACH_POSITION.RUNNING_COACH': 'Running coach',
  'COACH_POSITION.SnC_COACH': 'S&C coach',
  'COACH_POSITION.SWIMMING_COACH': 'Swimming coach',
  'COACH_POSITION.BIKE_COACH': 'Bike coach',

  // sports categories
  'SPORTS_CATEGORY_GROUP.ATHLETICS': 'Athletics',
  'SPORTS_CATEGORY.SPRINT': 'Sprint',
  'SPORTS_CATEGORY.MIDDLE_DISTANCE_RUNNING': 'Middle distance running',
  'SPORTS_CATEGORY.LONG_DISTANCE_RUNNING': 'Long distance running',
  'SPORTS_CATEGORY.HURDLE': 'Hurdle',
  'SPORTS_CATEGORY.RACE_WALKING': 'Race walking',
  'SPORTS_CATEGORY.MARATHON': 'Marathon',
  'SPORTS_CATEGORY.LONG_JUMP': 'Long jump',
  'SPORTS_CATEGORY.TRIPLE_JUMP': 'Triple jump',
  'SPORTS_CATEGORY.HIGH_JUMP': 'High jump',
  'SPORTS_CATEGORY.POLE_VAULT': 'Pole vault',
  'SPORTS_CATEGORY.SHOT_PUT': 'Shot put',
  'SPORTS_CATEGORY.DISCUS_THROW': 'Discus throw',
  'SPORTS_CATEGORY.JAVELIN_THROW': 'Javelin throw',
  'SPORTS_CATEGORY.HAMMER_THROW': 'Hammer throw',
  'SPORTS_CATEGORY.MEN_DECATHLON': 'Men decathlon',
  'SPORTS_CATEGORY.WOMEN_HEPTATHLON': 'Women heptathlon',
  'SPORTS_CATEGORY_GROUP.BALL': 'Ball',
  'SPORTS_CATEGORY.SOCCER': 'Soccer',
  'SPORTS_CATEGORY.BASKETBALL': 'Basketball',
  'SPORTS_CATEGORY.VOLLEYBALL': 'Volleyball',
  'SPORTS_CATEGORY.HANDBALL': 'Handball',
  'SPORTS_CATEGORY.BASEBALL': 'Baseball',
  'SPORTS_CATEGORY.FIELD_HOCKEY': 'Field hockey',
  'SPORTS_CATEGORY.RUGBY': 'Rugby',
  'SPORTS_CATEGORY.AMERICAN_FOOTBALL': 'American football',
  'SPORTS_CATEGORY.BILLIARDS': 'Billiards',
  'SPORTS_CATEGORY.GOLF': 'Golf',
  'SPORTS_CATEGORY.CRICKET': 'Cricket',
  'SPORTS_CATEGORY.BOCCIA': 'Boccia',
  'SPORTS_CATEGORY.SEPAK_TAKRAW': 'Sepak takraw',
  'SPORTS_CATEGORY.TEE_BALL': 'Tee ball',
  'SPORTS_CATEGORY.FOOT_VOLLEYBALL': 'Foot volleyball',
  'SPORTS_CATEGORY.FUTSAL': 'Futsal',
  'SPORTS_CATEGORY.BOWLING': 'Bowling',
  'SPORTS_CATEGORY_GROUP.RACKET': 'Racket',
  'SPORTS_CATEGORY.RACQUETBALL': 'Racquetball',
  'SPORTS_CATEGORY.BADMINTON': 'Badminton',
  'SPORTS_CATEGORY.SQUASH': 'Squash',
  'SPORTS_CATEGORY.SOFT_TENNIS': 'Soft tennis',
  'SPORTS_CATEGORY.TABLE_TENNIS': 'Table tennis',
  'SPORTS_CATEGORY.TENNIS': 'Tennis',
  'SPORTS_CATEGORY_GROUP.WATER': 'Water',
  'SPORTS_CATEGORY.SWIMMING': 'Swimming',
  'SPORTS_CATEGORY.DIVING': 'Diving',
  'SPORTS_CATEGORY.WATER_POLO': 'Water polo',
  'SPORTS_CATEGORY.SYNCHRONIZE': 'Synchronize',
  'SPORTS_CATEGORY.ROWING': 'Rowing',
  'SPORTS_CATEGORY.CANOE': 'Canoe',
  'SPORTS_CATEGORY.KAYAK': 'Kayak',
  'SPORTS_CATEGORY.FINSWIMMING': 'Finswimming',
  'SPORTS_CATEGORY_GROUP.COMBAT': 'Combat',
  'SPORTS_CATEGORY.TAEKWONDO': 'Taekwondo',
  'SPORTS_CATEGORY.TAEKKYEON': 'Taekkyeon',
  'SPORTS_CATEGORY.KUNG_FU': 'Kung fu',
  'SPORTS_CATEGORY.SSIREUM': 'Ssireum',
  'SPORTS_CATEGORY.KARATE': 'Karate',
  'SPORTS_CATEGORY.JUDO': 'Judo',
  'SPORTS_CATEGORY.BOXING': 'Boxing',
  'SPORTS_CATEGORY.KICKBOXING': 'Kickboxing',
  'SPORTS_CATEGORY.WRESTLING': 'Wrestling',
  'SPORTS_CATEGORY.ARM_WRESTLING': 'Arm wrestling',
  'SPORTS_CATEGORY_GROUP.GYMNASTICS': 'Gymnastics',
  'SPORTS_CATEGORY.ARTISTIC_GYMNASTICS': 'Artistic gymnastics',
  'SPORTS_CATEGORY.RHYTHMIC_GYMNASTICS': 'Rhythmic gymnastics',
  'SPORTS_CATEGORY.TRAMPOLINE': 'Trampoline',
  'SPORTS_CATEGORY.AEROBICS': 'Aerobics',
  'SPORTS_CATEGORY_GROUP.WEIGHTLIFTING': 'Weightlifting',
  'SPORTS_CATEGORY.WEIGHTLIFTING': 'Weightlifting',
  'SPORTS_CATEGORY_GROUP.EQUESTRIAN': 'Equestrian',
  'SPORTS_CATEGORY.EQUESTRIAN': 'Equestrian',
  'SPORTS_CATEGORY_GROUP.CYCLING': 'Cycling',
  'SPORTS_CATEGORY.CYCLING': 'Cycling',
  'SPORTS_CATEGORY_GROUP.WINTER': 'Winter',
  'SPORTS_CATEGORY.ICE_SKATING': 'Ice skating',
  'SPORTS_CATEGORY.ICE_HOCKEY': 'Ice hockey',
  'SPORTS_CATEGORY.CURLING': 'Curling',
  'SPORTS_CATEGORY.SKIING': 'Skiing',
  'SPORTS_CATEGORY.ALPINE_SKIING': 'Alpine skiing',
  'SPORTS_CATEGORY.NORDIC_SKIING': 'Nordic skiing',
  'SPORTS_CATEGORY.FREESTYLE_SKIING': 'Freestyle skiing',
  'SPORTS_CATEGORY.CROSS_COUNTRY_SKIING': 'Cross country skiing',
  'SPORTS_CATEGORY.SKI_JUMPING': 'Ski jumping',
  'SPORTS_CATEGORY.BIATHLON': 'Biathlon',
  'SPORTS_CATEGORY.SNOWBOARDING': 'Snowboarding',
  'SPORTS_CATEGORY.BOBSLEIGH': 'Bobsleigh',
  'SPORTS_CATEGORY.LUGE': 'Luge',
  'SPORTS_CATEGORY.SKELETON': 'Skeleton',
  'SPORTS_CATEGORY_GROUP.ARCHERY': 'Archery',
  'SPORTS_CATEGORY.ARCHERY': 'Archery',
  'SPORTS_CATEGORY_GROUP.FENCING': 'Fencing',
  'SPORTS_CATEGORY.FENCING': 'Fencing',
  'SPORTS_CATEGORY_GROUP.SHOOTING': 'Shooting',
  'SPORTS_CATEGORY.SHOOTING': 'Shooting',
  'SPORTS_CATEGORY_GROUP.ROLLER': 'Roller',
  'SPORTS_CATEGORY.ROLLER': 'Roller',
  'SPORTS_CATEGORY_GROUP.MODERN_PENTATHLON': 'Modern pentathlon',
  'SPORTS_CATEGORY.MODERN_PENTATHLON': 'Modern pentathlon',
  'SPORTS_CATEGORY_GROUP.ETC': 'etc',
  'SPORTS_CATEGORY.ETC': 'etc',
  'SPORTS_CATEGORY_GROUP.TRIATHLON': 'Triathlon',
  'SPORTS_CATEGORY.TRIATHLON': 'Triathlon',

  // team authorities
  'TEAM_AUTHORITY.OWNER': 'Owner',
  'TEAM_AUTHORITY.ADMIN': 'Admin',
  'TEAM_AUTHORITY.MEMBER': 'Member',
  'TEAM_AUTHORITY.VIEWER': 'Viewer',

  // country codes
  'COUNTRY_CODE.AD': 'Andorra',
  'COUNTRY_CODE.AE': 'United Arab Emirates (the)',
  'COUNTRY_CODE.AF': 'Afghanistan',
  'COUNTRY_CODE.AG': 'Antigua and Barbuda',
  'COUNTRY_CODE.AI': 'Anguilla',
  'COUNTRY_CODE.AL': 'Albania',
  'COUNTRY_CODE.AM': 'Armenia',
  'COUNTRY_CODE.AO': 'Angola',
  'COUNTRY_CODE.AQ': 'Antarctica',
  'COUNTRY_CODE.AR': 'Argentina',
  'COUNTRY_CODE.AS': 'American Samoa',
  'COUNTRY_CODE.AT': 'Austria',
  'COUNTRY_CODE.AU': 'Australia',
  'COUNTRY_CODE.AW': 'Aruba',
  'COUNTRY_CODE.AX': 'Åland Islands',
  'COUNTRY_CODE.AZ': 'Azerbaijan',
  'COUNTRY_CODE.BA': 'Bosnia and Herzegovina',
  'COUNTRY_CODE.BB': 'Barbados',
  'COUNTRY_CODE.BD': 'Bangladesh',
  'COUNTRY_CODE.BE': 'Belgium',
  'COUNTRY_CODE.BF': 'Burkina Faso',
  'COUNTRY_CODE.BG': 'Bulgaria',
  'COUNTRY_CODE.BH': 'Bahrain',
  'COUNTRY_CODE.BI': 'Burundi',
  'COUNTRY_CODE.BJ': 'Benin',
  'COUNTRY_CODE.BL': 'Saint Barthélemy',
  'COUNTRY_CODE.BM': 'Bermuda',
  'COUNTRY_CODE.BN': 'Brunei Darussalam',
  'COUNTRY_CODE.BO': 'Bolivia (Plurinational State of)',
  'COUNTRY_CODE.BQ': 'Bonaire, Sint Eustatius and Saba',
  'COUNTRY_CODE.BR': 'Brazil',
  'COUNTRY_CODE.BS': 'Bahamas (the)',
  'COUNTRY_CODE.BT': 'Bhutan',
  'COUNTRY_CODE.BV': 'Bouvet Island',
  'COUNTRY_CODE.BW': 'Botswana',
  'COUNTRY_CODE.BY': 'Belarus',
  'COUNTRY_CODE.BZ': 'Belize',
  'COUNTRY_CODE.CA': 'Canada',
  'COUNTRY_CODE.CC': 'Cocos (Keeling) Islands (the)',
  'COUNTRY_CODE.CD': 'Congo (the Democratic Republic of the)',
  'COUNTRY_CODE.CF': 'Central African Republic (the)',
  'COUNTRY_CODE.CG': 'Congo (the)',
  'COUNTRY_CODE.CH': 'Switzerland',
  'COUNTRY_CODE.CI': "Côte d'Ivoire",
  'COUNTRY_CODE.CK': 'Cook Islands (the)',
  'COUNTRY_CODE.CL': 'Chile',
  'COUNTRY_CODE.CM': 'Cameroon',
  'COUNTRY_CODE.CN': 'China',
  'COUNTRY_CODE.CO': 'Colombia',
  'COUNTRY_CODE.CR': 'Costa Rica',
  'COUNTRY_CODE.CU': 'Cuba',
  'COUNTRY_CODE.CV': 'Cabo Verde',
  'COUNTRY_CODE.CW': 'Curaçao',
  'COUNTRY_CODE.CX': 'Christmas Island',
  'COUNTRY_CODE.CY': 'Cyprus',
  'COUNTRY_CODE.CZ': 'Czechia',
  'COUNTRY_CODE.DE': 'Germany',
  'COUNTRY_CODE.DJ': 'Djibouti',
  'COUNTRY_CODE.DK': 'Denmark',
  'COUNTRY_CODE.DM': 'Dominica',
  'COUNTRY_CODE.DO': 'Dominican Republic (the)',
  'COUNTRY_CODE.DZ': 'Algeria',
  'COUNTRY_CODE.EC': 'Ecuador',
  'COUNTRY_CODE.EE': 'Estonia',
  'COUNTRY_CODE.EG': 'Egypt',
  'COUNTRY_CODE.EH': 'Western Sahara',
  'COUNTRY_CODE.ER': 'Eritrea',
  'COUNTRY_CODE.ES': 'Spain',
  'COUNTRY_CODE.ET': 'Ethiopia',
  'COUNTRY_CODE.FI': 'Finland',
  'COUNTRY_CODE.FJ': 'Fiji',
  'COUNTRY_CODE.FK': 'Falkland Islands (the) [Malvinas]',
  'COUNTRY_CODE.FM': 'Micronesia (Federated States of)',
  'COUNTRY_CODE.FO': 'Faroe Islands (the)',
  'COUNTRY_CODE.FR': 'France',
  'COUNTRY_CODE.GA': 'Gabon',
  'COUNTRY_CODE.GB': 'United Kingdom of Great Britain and Northern Ireland (the)',
  'COUNTRY_CODE.GD': 'Grenada',
  'COUNTRY_CODE.GE': 'Georgia',
  'COUNTRY_CODE.GF': 'French Guiana',
  'COUNTRY_CODE.GG': 'Guernsey',
  'COUNTRY_CODE.GH': 'Ghana',
  'COUNTRY_CODE.GI': 'Gibraltar',
  'COUNTRY_CODE.GL': 'Greenland',
  'COUNTRY_CODE.GM': 'Gambia (the)',
  'COUNTRY_CODE.GN': 'Guinea',
  'COUNTRY_CODE.GP': 'Guadeloupe',
  'COUNTRY_CODE.GQ': 'Equatorial Guinea',
  'COUNTRY_CODE.GR': 'Greece',
  'COUNTRY_CODE.GS': 'South Georgia and the South Sandwich Islands',
  'COUNTRY_CODE.GT': 'Guatemala',
  'COUNTRY_CODE.GU': 'Guam',
  'COUNTRY_CODE.GW': 'Guinea-Bissau',
  'COUNTRY_CODE.GY': 'Guyana',
  'COUNTRY_CODE.HK': 'Hong Kong',
  'COUNTRY_CODE.HM': 'Heard Island and McDonald Islands',
  'COUNTRY_CODE.HN': 'Honduras',
  'COUNTRY_CODE.HR': 'Croatia',
  'COUNTRY_CODE.HT': 'Haiti',
  'COUNTRY_CODE.HU': 'Hungary',
  'COUNTRY_CODE.ID': 'Indonesia',
  'COUNTRY_CODE.IE': 'Ireland',
  'COUNTRY_CODE.IL': 'Israel',
  'COUNTRY_CODE.IM': 'Isle of Man',
  'COUNTRY_CODE.IN': 'India',
  'COUNTRY_CODE.IO': 'British Indian Ocean Territory (the)',
  'COUNTRY_CODE.IQ': 'Iraq',
  'COUNTRY_CODE.IR': 'Iran (Islamic Republic of)',
  'COUNTRY_CODE.IS': 'Iceland',
  'COUNTRY_CODE.IT': 'Italy',
  'COUNTRY_CODE.JE': 'Jersey',
  'COUNTRY_CODE.JM': 'Jamaica',
  'COUNTRY_CODE.JO': 'Jordan',
  'COUNTRY_CODE.JP': 'Japan',
  'COUNTRY_CODE.KE': 'Kenya',
  'COUNTRY_CODE.KG': 'Kyrgyzstan',
  'COUNTRY_CODE.KH': 'Cambodia',
  'COUNTRY_CODE.KI': 'Kiribati',
  'COUNTRY_CODE.KM': 'Comoros (the)',
  'COUNTRY_CODE.KN': 'Saint Kitts and Nevis',
  'COUNTRY_CODE.KP': "Korea (the Democratic People's Republic of)",
  'COUNTRY_CODE.KR': 'Korea (the Republic of)',
  'COUNTRY_CODE.KW': 'Kuwait',
  'COUNTRY_CODE.KY': 'Cayman Islands (the)',
  'COUNTRY_CODE.KZ': 'Kazakhstan',
  'COUNTRY_CODE.LA': "Lao People's Democratic Republic (the)",
  'COUNTRY_CODE.LB': 'Lebanon',
  'COUNTRY_CODE.LC': 'Saint Lucia',
  'COUNTRY_CODE.LI': 'Liechtenstein',
  'COUNTRY_CODE.LK': 'Sri Lanka',
  'COUNTRY_CODE.LR': 'Liberia',
  'COUNTRY_CODE.LS': 'Lesotho',
  'COUNTRY_CODE.LT': 'Lithuania',
  'COUNTRY_CODE.LU': 'Luxembourg',
  'COUNTRY_CODE.LV': 'Latvia',
  'COUNTRY_CODE.LY': 'Libya',
  'COUNTRY_CODE.MA': 'Morocco',
  'COUNTRY_CODE.MC': 'Monaco',
  'COUNTRY_CODE.MD': 'Moldova (the Republic of)',
  'COUNTRY_CODE.ME': 'Montenegro',
  'COUNTRY_CODE.MF': 'Saint Martin (French part)',
  'COUNTRY_CODE.MG': 'Madagascar',
  'COUNTRY_CODE.MH': 'Marshall Islands (the)',
  'COUNTRY_CODE.MK': 'Republic of North Macedonia',
  'COUNTRY_CODE.ML': 'Mali',
  'COUNTRY_CODE.MM': 'Myanmar',
  'COUNTRY_CODE.MN': 'Mongolia',
  'COUNTRY_CODE.MO': 'Macao',
  'COUNTRY_CODE.MP': 'Northern Mariana Islands (the)',
  'COUNTRY_CODE.MQ': 'Martinique',
  'COUNTRY_CODE.MR': 'Mauritania',
  'COUNTRY_CODE.MS': 'Montserrat',
  'COUNTRY_CODE.MT': 'Malta',
  'COUNTRY_CODE.MU': 'Mauritius',
  'COUNTRY_CODE.MV': 'Maldives',
  'COUNTRY_CODE.MW': 'Malawi',
  'COUNTRY_CODE.MX': 'Mexico',
  'COUNTRY_CODE.MY': 'Malaysia',
  'COUNTRY_CODE.MZ': 'Mozambique',
  'COUNTRY_CODE.NA': 'Namibia',
  'COUNTRY_CODE.NC': 'New Caledonia',
  'COUNTRY_CODE.NE': 'Niger (the)',
  'COUNTRY_CODE.NF': 'Norfolk Island',
  'COUNTRY_CODE.NG': 'Nigeria',
  'COUNTRY_CODE.NI': 'Nicaragua',
  'COUNTRY_CODE.NL': 'Netherlands (the)',
  'COUNTRY_CODE.NO': 'Norway',
  'COUNTRY_CODE.NP': 'Nepal',
  'COUNTRY_CODE.NR': 'Nauru',
  'COUNTRY_CODE.NU': 'Niue',
  'COUNTRY_CODE.NZ': 'New Zealand',
  'COUNTRY_CODE.OM': 'Oman',
  'COUNTRY_CODE.PA': 'Panama',
  'COUNTRY_CODE.PE': 'Peru',
  'COUNTRY_CODE.PF': 'French Polynesia',
  'COUNTRY_CODE.PG': 'Papua New Guinea',
  'COUNTRY_CODE.PH': 'Philippines (the)',
  'COUNTRY_CODE.PK': 'Pakistan',
  'COUNTRY_CODE.PL': 'Poland',
  'COUNTRY_CODE.PM': 'Saint Pierre and Miquelon',
  'COUNTRY_CODE.PN': 'Pitcairn',
  'COUNTRY_CODE.PR': 'Puerto Rico',
  'COUNTRY_CODE.PS': 'Palestine, State of',
  'COUNTRY_CODE.PT': 'Portugal',
  'COUNTRY_CODE.PW': 'Palau',
  'COUNTRY_CODE.PY': 'Paraguay',
  'COUNTRY_CODE.QA': 'Qatar',
  'COUNTRY_CODE.RE': 'Réunion',
  'COUNTRY_CODE.RO': 'Romania',
  'COUNTRY_CODE.RS': 'Serbia',
  'COUNTRY_CODE.RU': 'Russian Federation (the)',
  'COUNTRY_CODE.RW': 'Rwanda',
  'COUNTRY_CODE.SA': 'Saudi Arabia',
  'COUNTRY_CODE.SB': 'Solomon Islands',
  'COUNTRY_CODE.SC': 'Seychelles',
  'COUNTRY_CODE.SD': 'Sudan (the)',
  'COUNTRY_CODE.SE': 'Sweden',
  'COUNTRY_CODE.SG': 'Singapore',
  'COUNTRY_CODE.SH': 'Saint Helena, Ascension and Tristan da Cunha',
  'COUNTRY_CODE.SI': 'Slovenia',
  'COUNTRY_CODE.SJ': 'Svalbard and Jan Mayen',
  'COUNTRY_CODE.SK': 'Slovakia',
  'COUNTRY_CODE.SL': 'Sierra Leone',
  'COUNTRY_CODE.SM': 'San Marino',
  'COUNTRY_CODE.SN': 'Senegal',
  'COUNTRY_CODE.SO': 'Somalia',
  'COUNTRY_CODE.SR': 'Suriname',
  'COUNTRY_CODE.SS': 'South Sudan',
  'COUNTRY_CODE.ST': 'Sao Tome and Principe',
  'COUNTRY_CODE.SV': 'El Salvador',
  'COUNTRY_CODE.SX': 'Sint Maarten (Dutch part)',
  'COUNTRY_CODE.SY': 'Syrian Arab Republic',
  'COUNTRY_CODE.SZ': 'Eswatini',
  'COUNTRY_CODE.TC': 'Turks and Caicos Islands (the)',
  'COUNTRY_CODE.TD': 'Chad',
  'COUNTRY_CODE.TF': 'French Southern Territories (the)',
  'COUNTRY_CODE.TG': 'Togo',
  'COUNTRY_CODE.TH': 'Thailand',
  'COUNTRY_CODE.TJ': 'Tajikistan',
  'COUNTRY_CODE.TK': 'Tokelau',
  'COUNTRY_CODE.TL': 'Timor-Leste',
  'COUNTRY_CODE.TM': 'Turkmenistan',
  'COUNTRY_CODE.TN': 'Tunisia',
  'COUNTRY_CODE.TO': 'Tonga',
  'COUNTRY_CODE.TR': 'Turkey',
  'COUNTRY_CODE.TT': 'Trinidad and Tobago',
  'COUNTRY_CODE.TV': 'Tuvalu',
  'COUNTRY_CODE.TW': 'Taiwan (Province of China)',
  'COUNTRY_CODE.TZ': 'Tanzania, United Republic of',
  'COUNTRY_CODE.UA': 'Ukraine',
  'COUNTRY_CODE.UG': 'Uganda',
  'COUNTRY_CODE.UM': 'United States Minor Outlying Islands (the)',
  'COUNTRY_CODE.US': 'United States of America (the)',
  'COUNTRY_CODE.UY': 'Uruguay',
  'COUNTRY_CODE.UZ': 'Uzbekistan',
  'COUNTRY_CODE.VA': 'Holy See (the)',
  'COUNTRY_CODE.VC': 'Saint Vincent and the Grenadines',
  'COUNTRY_CODE.VE': 'Venezuela (Bolivarian Republic of)',
  'COUNTRY_CODE.VG': 'Virgin Islands (British)',
  'COUNTRY_CODE.VI': 'Virgin Islands (U.S.)',
  'COUNTRY_CODE.VN': 'Viet Nam',
  'COUNTRY_CODE.VU': 'Vanuatu',
  'COUNTRY_CODE.WF': 'Wallis and Futuna',
  'COUNTRY_CODE.WS': 'Samoa',
  'COUNTRY_CODE.YE': 'Yemen',
  'COUNTRY_CODE.YT': 'Mayotte',
  'COUNTRY_CODE.ZA': 'South Africa',
  'COUNTRY_CODE.ZM': 'Zambia',
  'COUNTRY_CODE.ZW': 'Zimbabwe',

  // body parts
  'BODY_PART.HEAD': 'Head parts',
  'BODY_PART.TORSO': 'Torso parts',
  'BODY_PART.RIGHT_ARM': 'Right arm parts',
  'BODY_PART.LEFT_ARM': 'Left arm parts',
  'BODY_PART.RIGHT_LEG': 'Right leg parts',
  'BODY_PART.LEFT_LEG': 'left leg parts',
  'BODY_PART.FORE_HEAD': 'Fore head',
  'BODY_PART.FACE': 'Face',
  'BODY_PART.FRONT_NECK': 'Front neck',
  'BODY_PART.BACK_HEAD': 'Back head',
  'BODY_PART.BACK_NECK': 'Back neck',
  'BODY_PART.RIGHT_CHEST': 'Right chest',
  'BODY_PART.LEFT_CHEST': 'Left chest',
  'BODY_PART.UPPER_ABDOMEN': 'Upper abdomen',
  'BODY_PART.LOWER_ABDOMEN': 'Lower abdomen',
  'BODY_PART.RIGHT_RIB': 'Right rib',
  'BODY_PART.LEFT_RIB': 'Left rib',
  'BODY_PART.REPRODUCTIVE_ORGANS': 'Reproductive organs',
  'BODY_PART.RIGHT_WING': 'Right wing',
  'BODY_PART.LEFT_WING': 'Left wing',
  'BODY_PART.UPPER_BACK': 'Upper back',
  'BODY_PART.MIDDLE_BACK': 'Middle back',
  'BODY_PART.LOWER_BACK': 'Lower back',
  'BODY_PART.TAILBONE': 'Tailbone',
  'BODY_PART.RIGHT_SHOULDER': 'Right shoulder',
  'BODY_PART.RIGHT_UPPER_ARM': 'Right upper arm',
  'BODY_PART.RIGHT_FORE_ARM': 'Right fore arm',
  'BODY_PART.RIGHT_WRIST': 'Right wrist',
  'BODY_PART.RIGHT_PALM': 'Right palm',
  'BODY_PART.RIGHT_FINGER': 'Right finger',
  'BODY_PART.RIGHT_ELBOW': 'Right elbow',
  'BODY_PART.RIGHT_BACK_HAND': 'Right back hand',
  'BODY_PART.LEFT_SHOULDER': 'Left shoulder',
  'BODY_PART.LEFT_UPPER_ARM': 'Left upper arm',
  'BODY_PART.LEFT_FORE_ARM': 'Left fore arm',
  'BODY_PART.LEFT_WRIST': 'Left wrist',
  'BODY_PART.LEFT_PALM': 'Left palm',
  'BODY_PART.LEFT_FINGER': 'Left finger',
  'BODY_PART.LEFT_ELBOW': 'Left elbow',
  'BODY_PART.LEFT_BACK_HAND': 'Left back hand',
  'BODY_PART.RIGHT_HIP_JOINT': 'Right hip joint',
  'BODY_PART.RIGHT_GROIN': 'Right groin',
  'BODY_PART.RIGHT_FRONT_THIGH': 'Right front thigh',
  'BODY_PART.RIGHT_FRONT_KNEE': 'Right front knee',
  'BODY_PART.RIGHT_FRONT_LEG': 'Right front leg',
  'BODY_PART.RIGHT_ANKLE': 'Right ankle',
  'BODY_PART.RIGHT_INSTEP': 'Right instep',
  'BODY_PART.RIGHT_TOE': 'Right toe',
  'BODY_PART.RIGHT_HIP': 'Right hip',
  'BODY_PART.RIGHT_BACK_THIGH': 'Right back thigh',
  'BODY_PART.RIGHT_BACK_KNEE': 'Right back knee',
  'BODY_PART.RIGHT_BACK_LEG': 'Right back leg',
  'BODY_PART.RIGHT_ACHILLES_TENDON': 'Right achilles tendon',
  'BODY_PART.RIGHT_SOLE': 'Right sole',
  'BODY_PART.LEFT_HIP_JOINT': 'Left hip joint',
  'BODY_PART.LEFT_GROIN': 'Left groin',
  'BODY_PART.LEFT_FRONT_THIGH': 'Left front thigh',
  'BODY_PART.LEFT_FRONT_KNEE': 'Left front knee',
  'BODY_PART.LEFT_FRONT_LEG': 'Left front leg',
  'BODY_PART.LEFT_ANKLE': 'Left ankle',
  'BODY_PART.LEFT_INSTEP': 'Left instep',
  'BODY_PART.LEFT_TOE': 'Left toe',
  'BODY_PART.LEFT_HIP': 'Left hip',
  'BODY_PART.LEFT_BACK_THIGH': 'Left back thigh',
  'BODY_PART.LEFT_BACK_KNEE': 'Left back knee',
  'BODY_PART.LEFT_BACK_LEG': 'Left back leg',
  'BODY_PART.LEFT_ACHILLES_TENDON': 'Left achilles tendon',
  'BODY_PART.LEFT_SOLE': 'Left sole',

  // soreness level
  SORENESS_LEVEL_0: 'No pain',
  SORENESS_LEVEL_1: 'Level 1',
  SORENESS_LEVEL_2: 'Level 2',
  SORENESS_LEVEL_3: 'Level 3',
  SORENESS_LEVEL_4: 'Level 4',
  SORENESS_LEVEL_5: 'Level 5',

  // injury quality
  SHARP: 'Sharp',
  BURNING: 'Burning',
  CUTTING: 'Cutting',
  TINGLING: 'Tingling',
  COMES_AND_GOES: 'Come and goes',
  DULL: 'Dull',
  PRESSING: 'Pressing',
  NUMBING: 'Numbing',
  CRAMPING: 'Cramping',
  RADIATING: 'Radiating',

  // injury timing
  SUSTAINING: 'Sustaining',
  OCCASIONAL: 'Occasional',
  GRADUAL: 'Gradual',
  SUDDEN: 'Sudden',

  // custom data labels
  DATA: 'Data',
  'DATA.NONE': 'None',
  'DATA.READINESS': 'Readiness',
  'DATA.WORKLOAD': 'Workload',
  'DATA.DONUT.WORKLOAD_READINESS': 'Overall Readiness',
  'DATA.WORKLOAD_READINESS': 'Overall Workload Readiness',
  'DATA.WORKLOAD_TOTAL': 'Total workload',
  'DATA.WORKLOAD_RAW': 'Workload',
  'DATA.WORKLOAD_INTENSITY': 'Intensity',
  'DATA.WORKLOAD_DURATION': 'Duration',
  'DATA.WORKLOAD_SATISFACTION': 'Satisfaction',
  'DATA.WORKLOAD_AX': 'Acute Workload',
  'DATA.WORKLOAD_AXI': 'Fatigue level',
  'DATA.WORKLOAD_AXD': 'Monotony',
  'DATA.WORKLOAD_CX': 'Chronic Workload',
  'DATA.WORKLOAD_ACXR': 'Risk of injury (ACWR)',
  'DATA.CONDITION': 'Condition',
  'DATA.DONUT.CONDITION_READINESS': 'Overall Readiness',
  'DATA.CONDITION_READINESS': 'Overall Condition Readiness',
  'DATA.CONDITION_AX': 'Condition in a recent week',
  'DATA.CONDITION_CX': 'Condition in recent 4 weeks',
  'DATA.WELLNESS': 'Wellness',
  'DATA.WELLNESS_MUSCLE': 'Muscle',
  'DATA.WELLNESS_MOOD': 'Mood',
  'DATA.WELLNESS_FATIGUE': 'Fatigue',
  'DATA.WELLNESS_STRESS': 'Stress',
  'DATA.WELLNESS_RECOVERY': 'Recovery',
  'DATA.SLEEP': 'Sleep',
  'DATA.SLEEP_DURATION': 'Sleep duration',
  'DATA.SLEEP_QUALITY': 'Sleep quality',
  'DATA.RECOVERY': 'Recovery',
  'DATA.ACWR': 'Risk of injury',
  'DATA.INJURY': 'Injury',
  'DATA.DONUT.INJURY_READINESS': 'Overall Readiness',
  'DATA.INJURY_READINESS': 'Overall Injury Readiness',
  'DATA.SORENESS_AND_INJURY': 'Soreness & Injury',
  'DATA.SORENESS': 'Soreness',
  'DATA.SORENESS_COUNT': 'Soreness count',
  'DATA.SORENESS_AX': 'Soreness in a recent week',
  'DATA.SORENESS_AXI': 'Soreness level in a recent week',
  'DATA.SORENESS_AXD': 'Soreness rhythm in a recent week',
  'DATA.SORENESS_CX': 'Soreness in recent 3 months',
  'DATA.SORENESS_ACXR': 'Soreness level in recent 3 months',
  'DATA.SORENESS_CXD': 'Soreness rhythm in recent 3 months',
  'DATA.BODY': 'Body',
  'DATA.BODY_HEIGHT': 'Height',
  'DATA.BODY_WEIGHT': 'Weight',
  'DATA.BODY_BMI': 'Body Mass Index (BMI)',
  'DATA.BODY_RECOMMENDED_HYDRATION': 'Recommended Hydration',
  'DATA.RAW_CONDITION': 'Condition',
  'DATA.RAW_WELLNESS': 'Wellness',
  'DATA.CONDITION.BODY': 'Muscle',
  'DATA.RAW_SLEEP': 'Sleep',
  'DATA.CONDITION.QUALITY': 'Quality',
  'DATA.CONDITION.DURATION': 'Duration',
  'DATA.RAW_WORKLOAD': 'Workload',
  'DATA.WORKLOAD.INTENSITY': 'Intensity',
  'DATA.WORKLOAD.SATISFACTION': 'Satisfaction',
  'DATA.WORKLOAD.DURATION': 'Duration',
  'DATA.WORKOUT': 'Workout',
  'DATA.NORMAL': 'Norm',
  'DATA.NORMAL.WORKOUT': 'Normal Workout',
  'DATA.REHAB': 'Rehab',
  'DATA.REHAB.WORKOUT': 'Rehabilitation',
  'DATA.NO.WORKOUT': 'No Workout',
  'DATA.INJURIES': 'Injuries',
  'DATA.REST': 'Rest day',
  'DATA.RAW_INJURY': 'Injury',
  'DATA.INJURY.SORENESS': 'Soreness',
  'DATA.INJURY.TIMING': 'Timing',
  'DATA.INJURY.QUALITY': 'Quality',
  'DATA.INJURY.MEMO': 'Memo',
  'DATA.INJURY.PLAYER.MEMO': 'Note',
  'DATA.RAW_BODY': 'Body',
  'DATA.BODY.WEIGHT': 'Weight',
  'DATA.BODY.HEIGHT': 'Height',
  'DATA.STRIDE_DAY': 'Daily',
  'DATA.STRIDE_WEEK': 'Weekly',
  'DATA.STRIDE_MONTH': 'Monthly',
  'DATA.RAW_BODY.HEIGHT': 'Height',
  'DATA.RAW_BODY.WEIGHT': 'Weight',
  'DATA.NO.INPUT': 'No Input',

  // data-interpreter generated labels and descriptions
  'DATA.READINESS.[0,50).label': 'Very bad',
  'DATA.READINESS.[50,60).label': 'Bad',
  'DATA.READINESS.[60,75).label': 'Normal',
  'DATA.READINESS.[75,85).label': 'Good',
  'DATA.READINESS.[85,100].label': 'Very good',
  'DATA.CONDITION.[1,2).label': 'Very low',
  'DATA.CONDITION.[2,4).label': 'Low',
  'DATA.CONDITION.[4,7).label': 'Normal',
  'DATA.CONDITION.[7,9).label': 'High',
  'DATA.CONDITION.[9,10].label': 'Very high',
  'DATA.WELLNESS_MUSCLE.[1,3).label': 'Very bad',
  'DATA.WELLNESS_MUSCLE.[3,5).label': 'Bad',
  'DATA.WELLNESS_MUSCLE.[5,7).label': 'Normal',
  'DATA.WELLNESS_MUSCLE.[7,9).label': 'Good',
  'DATA.WELLNESS_MUSCLE.[9,10].label': 'Very good',
  'DATA.WELLNESS_MOOD.[1,3).label': 'Very bad',
  'DATA.WELLNESS_MOOD.[3,5).label': 'Bad',
  'DATA.WELLNESS_MOOD.[5,7).label': 'Normal',
  'DATA.WELLNESS_MOOD.[7,9).label': 'Good',
  'DATA.WELLNESS_MOOD.[9,10].label': 'Very good',
  'DATA.WELLNESS_FATIGUE.[1,3).label': 'Very bad',
  'DATA.WELLNESS_FATIGUE.[3,5).label': 'Bad',
  'DATA.WELLNESS_FATIGUE.[5,7).label': 'Normal',
  'DATA.WELLNESS_FATIGUE.[7,9).label': 'Good',
  'DATA.WELLNESS_FATIGUE.[9,10].label': 'Very good',
  'DATA.WELLNESS_STRESS.[1,3).label': 'Very bad',
  'DATA.WELLNESS_STRESS.[3,5).label': 'Bad',
  'DATA.WELLNESS_STRESS.[5,7).label': 'Normal',
  'DATA.WELLNESS_STRESS.[7,9).label': 'Good',
  'DATA.WELLNESS_STRESS.[9,10].label': 'Very good',
  'DATA.SLEEP_DURATION.[0,300).label': 'Very bad',
  'DATA.SLEEP_DURATION.[300,360).label': 'Bad',
  'DATA.SLEEP_DURATION.[360,420).label': 'Normal',
  'DATA.SLEEP_DURATION.[420,480).label': 'Good',
  'DATA.SLEEP_DURATION.[480,720].label': 'Very good',
  'DATA.SLEEP_QUALITY.[1,3).label': 'Very bad',
  'DATA.SLEEP_QUALITY.[3,5).label': 'Bad',
  'DATA.SLEEP_QUALITY.[5,7).label': 'Normal',
  'DATA.SLEEP_QUALITY.[7,9).label': 'Good',
  'DATA.SLEEP_QUALITY.[9,10].label': 'Very good',
  'DATA.CONDITION_AX.[1,2).label': 'Very low',
  'DATA.CONDITION_AX.[2,4).label': 'Low',
  'DATA.CONDITION_AX.[4,7).label': 'Normal',
  'DATA.CONDITION_AX.[7,9).label': 'High',
  'DATA.CONDITION_AX.[9,10].label': 'Very high',
  'DATA.CONDITION_CX.[1,2).label': 'Very low',
  'DATA.CONDITION_CX.[2,4).label': 'Low',
  'DATA.CONDITION_CX.[4,7).label': 'Normal',
  'DATA.CONDITION_CX.[7,9).label': 'High',
  'DATA.CONDITION_CX.[9,10].label': 'Very high',
  'DATA.WORKLOAD.[0,201).label': 'Very low',
  'DATA.WORKLOAD.[201,401).label': 'Low',
  'DATA.WORKLOAD.[401,801).label': 'Normal',
  'DATA.WORKLOAD.[801,1001).label': 'High',
  'DATA.WORKLOAD.[1001,Infinity].label': 'Very high',
  'DATA.WORKLOAD_INTENSITY.[1,2).label': 'Very light',
  'DATA.WORKLOAD_INTENSITY.[2,4).label': 'Light',
  'DATA.WORKLOAD_INTENSITY.[4,7).label': 'Moderate',
  'DATA.WORKLOAD_INTENSITY.[7,8).label': 'Vigorous',
  'DATA.WORKLOAD_INTENSITY.[8,10).label': 'Very hard',
  'DATA.WORKLOAD_INTENSITY.[10,10].label': 'Max effort',
  'DATA.WORKLOAD_DURATION.[0,31).label': 'Very low',
  'DATA.WORKLOAD_DURATION.[31,61).label': 'Low',
  'DATA.WORKLOAD_DURATION.[61,121).label': 'Normal',
  'DATA.WORKLOAD_DURATION.[121,241).label': 'High',
  'DATA.WORKLOAD_DURATION.[241,720].label': 'Very high',
  'DATA.WORKLOAD_SATISFACTION.[1,3).label': 'Very low',
  'DATA.WORKLOAD_SATISFACTION.[3,5).label': 'Low',
  'DATA.WORKLOAD_SATISFACTION.[5,7).label': 'Normal',
  'DATA.WORKLOAD_SATISFACTION.[7,9).label': 'High',
  'DATA.WORKLOAD_SATISFACTION.[9,10].label': 'Very high',
  'DATA.WORKLOAD_AX.[0,201).label': 'Very low',
  'DATA.WORKLOAD_AX.[201,401).label': 'Low',
  'DATA.WORKLOAD_AX.[401,801).label': 'Normal',
  'DATA.WORKLOAD_AX.[801,1001).label': 'High',
  'DATA.WORKLOAD_AX.[1001,7200].label': 'Very high',
  'DATA.WORKLOAD_AXD.[2,Infinity].label': 'Bad',
  'DATA.WORKLOAD_AXD.[0,2).label': 'good',
  'DATA.WORKLOAD_AXI.[0,0.8).label': 'Low',
  'DATA.WORKLOAD_AXI.[0.8,1.31).label': 'Normal',
  'DATA.WORKLOAD_AXI.[1.31,1.5).label': 'High',
  'DATA.WORKLOAD_AXI.[1.5,Infinity].label': 'Very high',
  'DATA.WORKLOAD_CX.[0,201).label': 'Very low',
  'DATA.WORKLOAD_CX.[201,401).label': 'Low',
  'DATA.WORKLOAD_CX.[401,801).label': 'Normal',
  'DATA.WORKLOAD_CX.[801,1001).label': 'High',
  'DATA.WORKLOAD_CX.[1001,7200].label': 'Very high',
  'DATA.WORKLOAD_ACXR.[0,0.8).label': 'Caution',
  'DATA.WORKLOAD_ACXR.[0.8,1.31).label': 'Best',
  'DATA.WORKLOAD_ACXR.[1.31,1.5).label': 'Warning',
  'DATA.WORKLOAD_ACXR.[1.5,Infinity].label': 'Danger',
  'DATA.PARTIAL_INJURY_SORENESS.[0,1).label': 'No pain',
  'DATA.PARTIAL_INJURY_SORENESS.[1,2).label': 'Mild',
  'DATA.PARTIAL_INJURY_SORENESS.[2,3).label': 'Moderate',
  'DATA.PARTIAL_INJURY_SORENESS.[3,4).label': 'Severe',
  'DATA.PARTIAL_INJURY_SORENESS.[4,5).label': 'Very severe',
  'DATA.PARTIAL_INJURY_SORENESS.[5,5].label': 'Injury',
  'DATA.BODY_BMI.[0,18.5).label': 'Underweight',
  'DATA.BODY_BMI.[18.5,23).label': 'Normal weight',
  'DATA.BODY_BMI.[23,25).label': 'Overweight',
  'DATA.BODY_BMI.[25,30).label': 'Mild obesity',
  'DATA.BODY_BMI.[30,40).label': 'Moderate obesity',
  'DATA.BODY_BMI.[40,Infinity].label': 'High obesity',

  'DATA.READINESS.[0,50).description':
    'Your readiness is bad. Your condition is not fully prepared for the training or match. You need the overall check of your condition such as sleep, fatigue and so on.',
  'DATA.READINESS.[50,60).description':
    'Your readiness is bad. Your condition is not fully prepared for the training or match. You need the overall check of your condition such as sleep, fatigue and so on.',
  'DATA.READINESS.[60,75).description': undefined,
  'DATA.READINESS.[75,85).description':
    'Your readiness is good. Your condition state is appropriate to the training or match. Keep checking your readiness.',
  'DATA.READINESS.[85,100].description':
    'Your readiness is good. Your condition state is appropriate to the training or match. Keep checking your readiness.',
  'DATA.CONDITION.[1,2).description':
    'Your condition is low. Rest is encouraged, and prioritize muscle/psychological recovery.',
  'DATA.CONDITION.[2,4).description':
    'Your condition is low. Rest is encouraged, and prioritize muscle/psychological recovery.',
  'DATA.CONDITION.[4,7).description': undefined,
  'DATA.CONDITION.[7,9).description':
    'Your condition is good. Muscle, mood, and recovery are well-balanced.',
  'DATA.CONDITION.[9,10].description':
    'Your condition is good. Muscle, mood, and recovery are well-balanced.',
  'DATA.WELLNESS_MUSCLE.[1,3).description':
    'Your state of muscle is bad. There is a risk of injury if you work out in the current state. Lower your muscle fatigue by resting or running lightly.',
  'DATA.WELLNESS_MUSCLE.[3,5).description':
    'Your state of muscle is bad. There is a risk of injury if you work out in the current state. Lower your muscle fatigue by resting or running lightly.',
  'DATA.WELLNESS_MUSCLE.[5,7).description': undefined,
  'DATA.WELLNESS_MUSCLE.[7,9).description': undefined,
  'DATA.WELLNESS_MUSCLE.[9,10].description': undefined,
  'DATA.WELLNESS_MOOD.[1,3).description':
    'Your state of mood is bad. It should not last for a long. If your mood is not good, the muscles are more tense than usual and feel tired quickly.',
  'DATA.WELLNESS_MOOD.[3,5).description':
    'Your state of mood is bad. It should not last for a long. If your mood is not good, the muscles are more tense than usual and feel tired quickly.',
  'DATA.WELLNESS_MOOD.[5,7).description': undefined,
  'DATA.WELLNESS_MOOD.[7,9).description': undefined,
  'DATA.WELLNESS_MOOD.[9,10].description': undefined,
  'DATA.WELLNESS_FATIGUE.[1,3).description':
    'Long-term high fatigue affects poor performance. Fatigue management is required.',
  'DATA.WELLNESS_FATIGUE.[3,5).description':
    'Long-term high fatigue affects poor performance. Fatigue management is required.',
  'DATA.WELLNESS_FATIGUE.[5,7).description': undefined,
  'DATA.WELLNESS_FATIGUE.[7,9).description': undefined,
  'DATA.WELLNESS_FATIGUE.[9,10].description': undefined,
  'DATA.WELLNESS_STRESS.[1,3).description':
    'Stress increases the risk of injury and hinders rehabilitation Think about the cause and how to deal with it.',
  'DATA.WELLNESS_STRESS.[3,5).description':
    'Stress increases the risk of injury and hinders rehabilitation Think about the cause and how to deal with it.',
  'DATA.WELLNESS_STRESS.[5,7).description': undefined,
  'DATA.WELLNESS_STRESS.[7,9).description': undefined,
  'DATA.WELLNESS_STRESS.[9,10].description': undefined,
  'DATA.SLEEP_DURATION.[0,300).description':
    "You don't get enough sleep. When you don't get enough sleep, your muscle recovery slows down and your stamina decreases. It also increases the risk of injury and can reduce your concentration.",
  'DATA.SLEEP_DURATION.[300,360).description':
    "You don't get enough sleep. When you don't get enough sleep, your muscle recovery slows down and your stamina decreases. It also increases the risk of injury and can reduce your concentration.",
  'DATA.SLEEP_DURATION.[360,420).description': undefined,
  'DATA.SLEEP_DURATION.[420,480).description': undefined,
  'DATA.SLEEP_DURATION.[480,720].description': undefined,
  'DATA.SLEEP_QUALITY.[1,3).description':
    "Sleep quality control is required. Even if you sleep long enough, low quality sleep slows your body's recovery. Also, it adversely affects stress and mood, so it is necessary to manage sleep quality along with sleep time",
  'DATA.SLEEP_QUALITY.[3,5).description':
    "Sleep quality control is required. Even if you sleep long enough, low quality sleep slows your body's recovery. Also, it adversely affects stress and mood, so it is necessary to manage sleep quality along with sleep time",
  'DATA.SLEEP_QUALITY.[5,7).description': undefined,
  'DATA.SLEEP_QUALITY.[7,9).description': undefined,
  'DATA.SLEEP_QUALITY.[9,10].description': undefined,
  'DATA.CONDITION_AX.[1,2).description':
    'Your 1 week condition average is low. Rest is recommended, and prioritize muscle/psychological recovery.',
  'DATA.CONDITION_AX.[2,4).description':
    'Your 1 week condition average is low. Rest is recommended, and prioritize muscle/psychological recovery.',
  'DATA.CONDITION_AX.[4,7).description': undefined,
  'DATA.CONDITION_AX.[7,9).description':
    'Your 1 week condtion average is good. Good condition is helpful for the improvement in performance. Maintain your current conditioning routine.',
  'DATA.CONDITION_AX.[9,10].description':
    'Your 1 week condtion average is good. Good condition is helpful for the improvement in performance. Maintain your current conditioning routine.',
  'DATA.CONDITION_CX.[1,2).description':
    'Your 4 weeks condition average is low. Rest is recommended, and prioritize muscle/psychological recovery.',
  'DATA.CONDITION_CX.[2,4).description':
    'Your 4 weeks condition average is low. Rest is recommended, and prioritize muscle/psychological recovery.',
  'DATA.CONDITION_CX.[4,7).description': undefined,
  'DATA.CONDITION_CX.[7,9).description':
    'Your 4 weeks condition average is good. Good condition is helpful for the improvement in performance. Maintain your current conditioning routine.',
  'DATA.CONDITION_CX.[9,10].description':
    'Your 4 weeks condition average is good. Good condition is helpful for the improvement in performance. Maintain your current conditioning routine.',
  'DATA.WORKLOAD.[0,201).description': undefined,
  'DATA.WORKLOAD.[201,401).description': undefined,
  'DATA.WORKLOAD.[401,801).description': undefined,
  'DATA.WORKLOAD.[801,1001).description':
    'As your workload is high, you need proper recovery. Planning to control the workout intensity or the amount of workout is recommended.',
  'DATA.WORKLOAD.[1001,Infinity].description':
    'As your workload is high, you need proper recovery. Planning to control the workout intensity or the amount of workout is recommended.',
  'DATA.WORKLOAD_INTENSITY.[1,2).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[2,4).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[4,7).description': undefined,
  'DATA.WORKLOAD_INTENSITY.[7,8).description':
    'Successive workout of high intensity affects injuries and condition.',
  'DATA.WORKLOAD_INTENSITY.[8,10).description':
    'Successive workout of high intensity affects injuries and condition.',
  'DATA.WORKLOAD_INTENSITY.[10,10].description':
    'Successive workout of high intensity affects injuries and condition.',
  'DATA.WORKLOAD_DURATION.[0,31).description': undefined,
  'DATA.WORKLOAD_DURATION.[31,61).description': undefined,
  'DATA.WORKLOAD_DURATION.[61,121).description': undefined,
  'DATA.WORKLOAD_DURATION.[121,241).description':
    'It is important to improve the quality of workout according to the purpose rather than the large amount of workout.',
  'DATA.WORKLOAD_DURATION.[241,720].description':
    'It is important to improve the quality of workout according to the purpose rather than the large amount of workout.',
  'DATA.WORKLOAD_SATISFACTION.[1,3).description':
    'Your workout satisfaction is low. You need motivations for the sense of accomplishment. Large and small goal set is recommended.',
  'DATA.WORKLOAD_SATISFACTION.[3,5).description':
    'Your workout satisfaction is low. You need motivations for the sense of accomplishment. Large and small goal set is recommended.',
  'DATA.WORKLOAD_SATISFACTION.[5,7).description': undefined,
  'DATA.WORKLOAD_SATISFACTION.[7,9).description': undefined,
  'DATA.WORKLOAD_SATISFACTION.[9,10].description': undefined,
  'DATA.WORKLOAD_AX.[0,201).description': undefined,
  'DATA.WORKLOAD_AX.[201,401).description': undefined,
  'DATA.WORKLOAD_AX.[401,801).description': undefined,
  'DATA.WORKLOAD_AX.[801,1001).description':
    'Your recent week average workload is high. The rapid increase in the amount of workout compared to your fitness can cause injury. Proper recovery is needed for the muscle growth and performance improvement.',
  'DATA.WORKLOAD_AX.[1001,7200].description':
    'Your recent week average workload is high. The rapid increase in the amount of workout compared to your fitness can cause injury. Proper recovery is needed for the muscle growth and performance improvement.',
  'DATA.WORKLOAD_AXD.[2,Infinity].description':
    'Your recent week workload rhythm is not good. You need the variation of workout intensity and workout duration. Monotonous training negatively affects athletes.',
  'DATA.WORKLOAD_AXD.[0,2).description': undefined,
  'DATA.WORKLOAD_AXI.[0,0.8).description': undefined,
  'DATA.WORKLOAD_AXI.[0.8,1.31).description': undefined,
  'DATA.WORKLOAD_AXI.[1.31,1.5).description': undefined,
  'DATA.WORKLOAD_AXI.[1.5,Infinity].description':
    'The amount of workout has rapidly increased compared to recent week average. Proper stretching, sufficient nutrition and hydration, sufficient sleep for recovery and so on are recommended for injury prevention.',
  'DATA.WORKLOAD_CX.[0,201).description': undefined,
  'DATA.WORKLOAD_CX.[201,401).description': undefined,
  'DATA.WORKLOAD_CX.[401,801).description': undefined,
  'DATA.WORKLOAD_CX.[801,1001).description':
    'Your recent 4 weeks average workload is high. Your fitness improves as much as recent 4 weeks average workload, and your adaptation to the high workload improves. Progressive increase of the amount of workout is recommended. ',
  'DATA.WORKLOAD_CX.[1001,7200].description':
    'Your recent 4 weeks average workload is high. Your fitness improves as much as recent 4 weeks average workload, and your adaptation to the high workload improves. Progressive increase of the amount of workout is recommended. ',
  'DATA.WORKLOAD_ACXR.[0,0.8).description': undefined,
  'DATA.WORKLOAD_ACXR.[0.8,1.31).description': undefined,
  'DATA.WORKLOAD_ACXR.[1.31,1.5).description':
    "You are in the range of 'caution in injury.' You need to reduce the fatigue and control the amount of workout.",
  'DATA.WORKLOAD_ACXR.[1.5,Infinity].description':
    "You are in the range of 'risk of injury.' Your fatigue compared to fitness is high. Recovery is recommended rather than workout, and the proper rest and recovery are required. ",
  'DATA.PARTIAL_INJURY_SORENESS.[0,1).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[1,2).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[2,3).description': undefined,
  'DATA.PARTIAL_INJURY_SORENESS.[3,4).description':
    'Soreness degree 3 has occurred. An effort to ease the pain in the short term is needed. Actions for recovery including rest, massage, cold/hot pack, nutrition are needed. ',
  'DATA.PARTIAL_INJURY_SORENESS.[4,5).description':
    "Soreness degree 4 has occurred. The exact cause and prescription through experts' diagnosis are required.",
  'DATA.PARTIAL_INJURY_SORENESS.[5,5].description':
    'Injury has occurred. Stop all workout and focus on physical/psychological recovery. You need a deliberate recovery strategy.',
  'DATA.BODY_BMI.[0,18.5).description': undefined,
  'DATA.BODY_BMI.[18.5,23).description': undefined,
  'DATA.BODY_BMI.[23,25).description': undefined,
  'DATA.BODY_BMI.[25,30).description': undefined,
  'DATA.BODY_BMI.[30,40).description': undefined,
  'DATA.BODY_BMI.[40,Infinity].description': undefined,
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).label': 'Very light',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).label': 'Light',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).label': 'Moderate',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).label': 'Vigorous',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).label': 'Very hard',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].label': 'Max effort',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[1,2).description':
    'Hardly any exertion but more than sleeping, watching TV, etc.',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[2,4).description':
    'Feels like you can maintain for hours. Easy to breath and carry a conversation.',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[4,7).description':
    'Breathing heavily, can hold a short conversation. Still somewhat comfortable, but becoming noticeably more challenging.',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[7,8).description':
    'Borderline uncomfortable. Short of breath, can speak a sentence.',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[8,10).description':
    'Very difficult to maintain workout intensity. speak only a few words.',
  'DATA.TRAINING_REPORT_WORKLOAD_INTENSITY.[10,10].description':
    'Feels almost impossible to keep going. Completely out of breath, unable to talk.',
}

export default messages
