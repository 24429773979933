import { useRef } from 'react'

import { Button } from '@plco-pro/components/atoms/button'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { InjuryTooltipContents } from '@plco-pro/components/molecules/injury-tooltip-contents'
import { InjuryTooltipTitle } from '@plco-pro/components/molecules/injury-tooltip-title'
import { Contact } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import theme from '@plco-pro/themes/main'
import { isMobile } from '@plco-pro/utils/isMobile'

const TOOLTIP_WIDTH = 300

export interface PartsData {
  name: string
  bodyPart: string
  soreness: number
  quality: string[]
  timing: string[]
  comment: string
  memo: string
  isContact: Contact
}

interface Props {
  pageY: number
  name: string
  offset?: number
  partsData?: PartsData
  offsetLeft: number
  parentWidth: number
  onClickDetail?: () => void
  layoutHeight: number
}

// TODO : 툴팁이 스크린 상단 기준 40px을 넘기지 않도록 표시
export const InjuryTooltip = ({
  pageY,
  name,
  offset = 0,
  partsData,
  offsetLeft,
  parentWidth,
  onClickDetail,
  layoutHeight,
}: Props) => {
  const tooltipRef = useRef<HTMLDivElement>(null)

  const { formatMessage: f } = useI18n()

  const placement = (offsetLeft: number, pageY: number, tooltipHeight = 0) => {
    if (offsetLeft > TOOLTIP_WIDTH) {
      return 'left'
    }

    if (parentWidth - offsetLeft > TOOLTIP_WIDTH) {
      return 'right'
    }

    if (pageY > tooltipHeight + 8 + 40) {
      return 'top'
    }

    return 'bottom'
  }

  const positionStyle = (offsetLeft: number): FlexProps['sx'] => {
    const tooltipHeight = tooltipRef.current?.clientHeight ?? 0
    const tooltipPlacement = placement(offsetLeft, pageY, tooltipHeight)
    const translateY = Math.abs(Math.min(pageY - 70, tooltipHeight / 2))

    if (tooltipPlacement === 'right') {
      return {
        top: `max(70px, ${pageY}px)`,
        transform: `translateY(-${translateY}px) translateX(${Math.floor(offset + 8)}px)`,
      }
    }

    if (tooltipPlacement === 'left') {
      return {
        top: `max(70px, ${pageY}px)`,
        transform: `translateY(-${translateY}px) translateX(-100%) translateX(-8px)`,
      }
    }

    if (tooltipPlacement === 'top') {
      return {
        top: pageY,
        right: '16px',
        transform: `translateY(-100%) translateY(-8px) translateY(-${layoutHeight}px)`,
      }
    }

    return {
      top: `${pageY + 16}px`,
      right: '16px',
    }
  }

  const handleClickDetail = () => {
    onClickDetail?.()
  }

  return (
    <Flex
      ref={tooltipRef}
      className={'tooltip'}
      sx={{
        visibility: 'hidden',
        width: `${TOOLTIP_WIDTH}px`,
        padding: '16px',
        bg: theme.colors['text-primary'],
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px',
        zIndex: 200,
        position: 'fixed',
        ...positionStyle(offsetLeft),
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          flexGrow: 1,
        }}
      >
        <InjuryTooltipTitle name={name} partsData={partsData} />

        {partsData && <InjuryTooltipContents partsData={partsData} />}

        {isMobile() ? (
          <Flex sx={{ width: '100%' }}>
            <Button
              size={'xs'}
              shape={'round'}
              sx={{ width: '100%' }}
              onTouchStart={(e) => {
                e.stopPropagation()
              }}
              onTouchEnd={(e) => {
                e.stopPropagation()
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleClickDetail?.()
              }}
            >
              {f({ id: 'players.injury.tooltip.button' })}
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  )
}
