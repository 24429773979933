import React, { forwardRef } from 'react'

import styled from '@emotion/styled'
import { Label as ThemeUILabel, LabelProps as ThemeUILabelProps } from 'theme-ui'

import { appearance } from '@plco-pro/components/atoms/text'

export type LabelProps = ThemeUILabelProps & {
  appearance?: 'hint' | 'red'
}

const StyledThemeUILabel = styled(ThemeUILabel)<LabelProps>(appearance)

export const Label: React.FunctionComponent<LabelProps> = forwardRef((props, ref) => (
  <StyledThemeUILabel ref={ref} variant={'l1'} {...props} />
))
