import { Flex } from '@plco-pro/components/atoms/flex'
import { Text, TextProps } from '@plco-pro/components/atoms/text'
import { PartsData } from '@plco-pro/components/molecules/injury-tooltip/injury-tooltip'
import { Contact } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'

const ContentsLabel = (props: { children: React.ReactNode }) => (
  <Text sx={{ color: 'white' }} variant={'p2'} {...props} />
)

const ContentsValue = (props: TextProps) => <Text variant={'p2'} {...props} />

interface Props {
  partsData: PartsData
}

export const InjuryTooltipContents = ({ partsData }: Props) => {
  const { formatMessage } = useI18n()

  const getContactTypeText = (isContact?: Contact) => {
    if (isContact === Contact.CONTACT) {
      return formatMessage({ id: 'CONTACT' })
    }

    if (isContact === Contact.NON_CONTACT) {
      return formatMessage({ id: 'NON_CONTACT' })
    }

    return '-'
  }

  return (
    <>
      <Flex sx={{ flexDirection: 'column', gap: '2px' }}>
        <ContentsLabel>{formatMessage({ id: 'SORENESS_TYPE' })}</ContentsLabel>
        <ContentsValue appearance={'hint'}>{getContactTypeText(partsData.isContact)}</ContentsValue>
      </Flex>
      <Flex sx={{ flexDirection: 'column', gap: '2px' }}>
        <ContentsLabel>{formatMessage({ id: 'SORENESS_SITUATION' })}</ContentsLabel>
        <ContentsValue sx={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }} appearance={'hint'}>
          {partsData.memo}
        </ContentsValue>
      </Flex>
    </>
  )
}
