import { observable } from 'mobx'
import { types } from 'mobx-state-tree'

export const APIClientStore = types
  .model('APIClient', {
    headers: types.optional(types.map(types.string), {}),
    restHTTPEndpoint: types.optional(types.string, ''),
  })
  .actions((self) => {
    return {
      setHeader(key: string, value: string | null) {
        if (value === null) {
          self.headers.delete(key)
        } else {
          self.headers.set(key, value)
        }
      },
      setRestHTTPEndpoint(endpoint: string) {
        self.restHTTPEndpoint = endpoint
      },
    }
  })
  .extend((self) => {
    // volatile state
    const initialized = observable.box(false)

    return {
      views: {
        get initialized() {
          return initialized.get()
        },
      },
      actions: {
        async initialize() {
          initialized.set(true)
        },
      },
    }
  })
