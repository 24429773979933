import { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import { ChartSystemPropsLabel } from '@plco-pro/maps/chart-system/chart-system.types'
import theme from '@plco-pro/themes/main'

export const DATASET_LABEL_INJURY: ChartSystemPropsLabel = {
  labelComponent: 'LABEL_TOOLTIP_MULTI_USER_DATA',
  config: {
    width: 'SORENESS_LABEL_TOOLTIP_WIDTH',
    items: [
      {
        chartType: 'STACK',
        dataType: MonitoringDataType.SORENESS_1,
        field: 'level1',
        color: theme.colors['injury-level-1'],
        format: 'COUNT_FORMAT',
      },
      {
        chartType: 'STACK',
        dataType: MonitoringDataType.SORENESS_2,
        field: 'level2',
        color: theme.colors['injury-level-2'],
        format: 'COUNT_FORMAT',
      },
      {
        chartType: 'STACK',
        dataType: MonitoringDataType.SORENESS_3,
        field: 'level3',
        color: theme.colors['injury-level-3'],
        format: 'COUNT_FORMAT',
      },
      {
        chartType: 'STACK',
        dataType: MonitoringDataType.SORENESS_4,
        field: 'level4',
        color: theme.colors['injury-level-4'],
        format: 'COUNT_FORMAT',
      },
      {
        chartType: 'STACK',
        dataType: MonitoringDataType.SORENESS_5,
        field: 'level5',
        color: theme.colors['injury-level-5'],
        format: 'COUNT_FORMAT',
      },
    ],
  },
}
