import React, { useCallback, useEffect, useMemo, useState } from 'react'

import _orderBy from 'lodash/orderBy'
import { VictoryLabel } from 'victory'
import { EventPropTypeInterface, VictoryStyleInterface } from 'victory-core'

import { ChartLabelAreaCenter } from '@plco-pro/components/molecules/chart-label-area-center'
import { ChartLabelAvatar } from '@plco-pro/components/molecules/chart-label-avatar'
import { ChartLabelDottedLine } from '@plco-pro/components/molecules/chart-label-dotted-line'
import { ChartLabelTooltipMultiTeamData } from '@plco-pro/components/molecules/chart-label-tooltip-multi-team-data'
import { ChartLabelTooltipMultiUserData } from '@plco-pro/components/molecules/chart-label-tooltip-multi-user-data'
import {
  ChartPropsDatasetItem,
  DOMAIN_DEFAULT_Y_END,
  DOMAIN_DEFAULT_Y_START,
} from '@plco-pro/components/organisms/chart'
import { useChartSystemHelper } from '@plco-pro/hooks/chart-system-helper'
import { useDataInterpreter } from '@plco-pro/hooks/data-interpreter'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMoment } from '@plco-pro/hooks/moment'
import {
  ChartParsedData,
  ChartParsedDataItem,
  StoreSystemObjectType,
  StoreSystemTypeName,
} from '@plco-pro/hooks/store-system'
import { chartSystemPropsMap as m, ChartSystemPropsMapKey } from '@plco-pro/maps/chart-system/props'
import theme, { ThemeColor } from '@plco-pro/themes/main'
import { getColor } from '@plco-pro/utils'

import type {
  ChartSystemMapValue,
  ChartSystemPropsConfig,
  ChartSystemPropsDatasetItem,
  ChartSystemPropsTree,
} from '@plco-pro/maps/chart-system/chart-system.types'

function hasKey<K extends Map<unknown, unknown>, T extends string | number>(
  map: K,
  key?: T,
): key is T & Map<T, () => unknown> {
  return key !== undefined && map.has(key)
}

function mappingColor(item: string) {
  return getColor(item)
}

const generateFunctionTreeRecursively = (config: ChartSystemPropsConfig) => {
  const res: any = {}

  for (const [key, value] of Object.entries(config)) {
    if (key === 'parser') {
      res[key] = (data: ChartParsedDataItem[]) => data
    } else if (key === 'color' || key === 'width') {
      res.style = m.get('GET_STYLE')
    } else if (key === 'colorHover') {
      res.events = m.get('GET_EVENTS')
      res.colorHover = value
    } else if (key === 'colorScale') {
      res.colorScale = m.get('GET_COLOR_SCALE')
    } else if (key === 'colorScaleHover') {
      res.colorScaleHover = m.get('GET_COLOR_SCALE_HOVER')
    } else if (key === 'config') {
      // ignore config objects
    } else if (Array.isArray(value)) {
      res[key] = value?.map((v) => generateFunctionTreeRecursively(v))
    } else if (typeof value === 'object') {
      res[key] = generateFunctionTreeRecursively(value)
    } else {
      if (typeof value === 'string') {
        // string => function in map or raw string
        res[key] = m.has(value as ChartSystemPropsMapKey)
          ? m.get(value as ChartSystemPropsMapKey)
          : value
      } else {
        // non-string => raw value
        res[key] = value
      }
    }
  }

  return res
}

const UNRESOLVED_FUNCTION_KEYS = ['tickFormat']

const resolveFunctionTreeRecursively = (
  tree: ChartSystemPropsTree,
  data: ChartParsedDataItem[],
  config: ChartSystemPropsConfig,
) => {
  const res: any = {}

  for (const [key, value] of Object.entries(tree)) {
    if (key === 'parser') {
      res.data = value(data, config)
    } else if (Array.isArray(value)) {
      res[key] = value?.map((v) => resolveFunctionTreeRecursively(v, data, config))
    } else if (typeof value === 'object') {
      res[key] = resolveFunctionTreeRecursively(value, data, config)
    } else {
      if (typeof value === 'function') {
        // function => function in map
        res[key] = UNRESOLVED_FUNCTION_KEYS.includes(key) ? value : value(data, config)
      } else {
        // non-function => raw value
        res[key] = value
      }
    }
  }

  return res
}

export const useChartSystemProps = (
  config: ChartSystemMapValue,
  store?: StoreSystemObjectType<StoreSystemTypeName>,
) => {
  const moment = useMoment()
  const interpret = useDataInterpreter()
  const { formatMessage: f } = useI18n()
  const { getDataIndex, getDataEachYFormat, getBarWidth } = useChartSystemHelper()

  const [initialized, setInitialized] = useState<boolean>(false)
  const [datasetTrees, setDatasetTrees] = useState<ChartSystemPropsTree[]>([])
  const [domainTree, setDomainTree] = useState<ChartSystemPropsTree[]>([])
  const [labelTree, setLabelTree] = useState<ChartSystemPropsTree[]>([])

  const getDataYMax = useCallback((data: ChartParsedDataItem[]) => {
    return Math.max(...data.map((item) => (isNaN(item.y) ? item.domainY || 0 : item.y)), 0)
  }, [])

  useEffect(() => {
    const unit = {
      point: f({ id: 'POINT' }),
      count: (value: number | string) => f({ id: 'PART' }, { count: value }),
      cm: f({ id: 'CM' }),
      kg: f({ id: 'KG' }),
      hour: f({ id: 'H' }, { hours: 0 }),
      minute: f({ id: 'M' }, { minutes: 0 }),
    }
    // chart type functions
    m.set('BAR', () => 'BAR')
    m.set('LINE', () => 'LINE')
    m.set('BACKGROUND_AREA', () => 'BACKGROUND_AREA')

    // translate string functions
    m.set('AVG_STRING', () => `${f({ id: 'AVG' })}: `)
    m.set('GOAL', () => `${f({ id: 'GOAL' })}: `)

    // translate counter functions
    m.set('NULL_STRING', () => '')

    // tick format functions
    m.set('POINT_FORMAT', (value: number | string) => `${value}${unit.point}`)
    m.set('COUNT_FORMAT', (value: number | string) => `${value}${unit.count(value)}`)
    m.set('CM_FORMAT', (value: number | string) => `${value}${unit.cm}`)
    m.set('KG_FORMAT', (value: number | string) => `${value}${unit.kg}`)
    m.set('HOUR_FORMAT', (value: number | string) => {
      const hours = Math.floor(typeof value === 'string' ? parseInt(value, 10) / 60 : value / 60)

      return `${hours}${unit.hour}`
    })
    m.set('HOUR_MINUTE_FORMAT', (value: number | string) => {
      const hours = Math.floor(typeof value === 'string' ? parseInt(value, 10) / 60 : value / 60)
      const minutes = Math.floor(typeof value === 'string' ? parseInt(value, 10) % 60 : value % 60)
      const hoursFormat = hours !== 0 ? `${hours}${unit.hour}` : ''
      const minutesFormat = minutes !== 0 ? `${minutes}${unit.minute}` : ''

      return hoursFormat === minutesFormat ? `0${unit.minute}` : `${hoursFormat}${minutesFormat}`
    })
    m.set('HOUR_MINUTE_FORMAT_12_PLUS', (value: number | string) => {
      const hours = Math.floor(typeof value === 'string' ? parseInt(value, 10) / 60 : value / 60)
      const minutes = Math.floor(typeof value === 'string' ? parseInt(value, 10) % 60 : value % 60)
      const hoursFormat = hours !== 0 ? `${hours}${unit.hour}` : ''
      const minutesFormat = minutes !== 0 ? `${minutes}${unit.minute}` : ''

      return hoursFormat === minutesFormat
        ? `0${unit.minute}`
        : `${hours === 12 ? `${hoursFormat}+` : hoursFormat}${minutesFormat}`
    })
    m.set('TRANSLATE_UPPERCASE', (value: number | string) => {
      return typeof value === 'string' ? f({ id: value.toUpperCase() }) : value
    })
    m.set('TRANSLATE_INJURY', (value: number) => {
      const injury = new Array(6).fill(0).map((_, index) => {
        return f({ id: `SORENESS_LEVEL_${index}` })
      })

      return injury[value]
    })
    m.set('DAY_OF_WEEK', (value: number) => {
      return moment(value).format('ddd')
    })
    m.set('DAY_OF_MONTH', (value: number) => {
      return moment(value).format('M.D')
    })
    m.set('2_POINT_5_PLUS', () => {
      return ({ datum }: any) => {
        return datum < 2.5 ? datum : '2.5+'
      }
    })
    m.set('4_PLUS', () => {
      return ({ datum }: any) => {
        return datum < 4 ? datum : '4+'
      }
    })
    m.set('1_RE-SCALE_2.5+', () => {
      return ({ datum }: any) => {
        return datum < 1 ? datum * 2.5 : '2.5+'
      }
    })
    m.set('1_RE-SCALE_10', () => {
      return ({ datum }: any) => {
        return datum * 10
      }
    })
    m.set('1_RE-SCALE_720_HOUR_FORMAT', (value: number) => {
      return () => {
        const hours = Math.floor((value * 720) / 60)

        return `${hours}${unit.hour}`
      }
    })
    m.set('1_RE-SCALE_1500_MAX', () => {
      const values = getDataEachYFormat('1500_MAX_RE-SCALE_1', config, store)
      const maxValue = Math.max(...values, 1500)

      const multipleBy500 = maxValue % 500 === 0 ? maxValue : 500 * Math.ceil(maxValue / 500)

      return ({ datum }: any) => {
        return datum * multipleBy500
      }
    })

    // min and max functions
    m.set('Y_MIN', (data: ChartParsedDataItem[]) =>
      Math.min(
        ...data.filter((item) => item.y !== null).map((item) => item.y),
        DOMAIN_DEFAULT_Y_START,
      ),
    )
    m.set('Y_MAX_2', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 2)
    })
    m.set('Y_MAX_2_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax2 = m.get('Y_MAX_2')?.(data)
      const yMax2RoundedUp = yMax2 - (yMax2 % 1) + 1

      return yMax2 === 2 ? yMax2 : yMax2RoundedUp
    })
    m.set('Y_MAX_10', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 10)
    })
    m.set('Y_MAX_10_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax10 = m.get('Y_MAX_10')?.(data)
      const yMax10RoundedUp = yMax10 - (yMax10 % 2) + 2

      return yMax10 === 10 ? yMax10 : yMax10RoundedUp
    })
    m.set('Y_MAX_100', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 100)
    })
    m.set('Y_MAX_100_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax100 = m.get('Y_MAX_100')?.(data)
      const yMax100RoundedUp = yMax100 - (yMax100 % 25) + 25

      return yMax100 === 100 ? yMax100 : yMax100RoundedUp
    })
    m.set('Y_MAX_180', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 180)
    })
    m.set('Y_MAX_180_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax180 = m.get('Y_MAX_180')?.(data)
      const yMax180RoundedUp = yMax180 - (yMax180 % 60) + 60

      return yMax180 === 180 ? yMax180 : yMax180RoundedUp
    })
    m.set('Y_MAX_200', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 200)
    })
    m.set('Y_MAX_200_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax200 = m.get('Y_MAX_200')?.(data)
      const yMax200RoundedUp = yMax200 - (yMax200 % 50) + 50

      return yMax200 === 200 ? yMax200 : yMax200RoundedUp
    })
    m.set('Y_MAX_600', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 600)
    })
    m.set('Y_MAX_600_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax600 = m.get('Y_MAX_600')?.(data)
      const yMax600RoundedUp = yMax600 - (yMax600 % 120) + 120

      return yMax600 === 600 ? yMax600 : yMax600RoundedUp
    })
    m.set('Y_MAX_1200', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 1200)
    })
    m.set('Y_MAX_1200_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax1200 = m.get('Y_MAX_1200')?.(data)
      const yMax1200RoundedUp = yMax1200 - (yMax1200 % 200) + 200

      return yMax1200 === 1200 ? yMax1200 : yMax1200RoundedUp
    })
    m.set('Y_MAX_1500', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 1500)
    })
    m.set('Y_MAX_1500_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax1500 = m.get('Y_MAX_1500')?.(data)
      const yMax1500RoundedUp = yMax1500 - (yMax1500 % 250) + 250

      return yMax1500 === 1500 ? yMax1500 : yMax1500RoundedUp
    })
    m.set('Y_MAX_1600', (data: ChartParsedDataItem[]) => {
      return Math.max(...data.map((item) => item.y), DOMAIN_DEFAULT_Y_END, 1600)
    })
    m.set('Y_MAX_1600_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax1600 = m.get('Y_MAX_1600')?.(data)
      const yMax1600RoundedUp = yMax1600 - (yMax1600 % 400) + 400

      return yMax1600 === 1600 ? yMax1600 : yMax1600RoundedUp
    })
    m.set('Y_MAX_PLUS_4_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const max = getDataYMax(data)
      const roundedMax = Math.ceil(max / 4) * 4

      return roundedMax === 0 ? 4 : roundedMax
    })
    m.set('Y_MAX_PLUS_5_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const max = getDataYMax(data)
      const roundedMax = Math.ceil(max / 5) * 5

      return roundedMax === 0 ? 5 : roundedMax
    })
    m.set('Y_MAX_PLUS_400_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const max = getDataYMax(data)
      const roundedMax = Math.ceil(max / 400) * 400

      return roundedMax === 0 ? 400 : roundedMax
    })
    m.set('Y_MAX_PLUS_5_DEFAULT_100_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      if (data.every((element) => element.y === null)) return 100

      const max = getDataYMax(data)
      const roundedMax = Math.ceil(max / 5) * 5

      return roundedMax === 0 ? 5 : roundedMax
    })
    m.set('Y_MAX_PLUS_5_DEFAULT_200_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      if (data.every((element) => element.y === null)) return 200

      const max = getDataYMax(data)
      const roundedMax = Math.ceil(max / 5) * 5

      return roundedMax === 0 ? 5 : roundedMax
    })
    m.set('Y_MAX_PLUS_60_DEFAULT_180_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      if (data.every((element) => element.y === null)) return 180

      const max = getDataYMax(data)
      const roundedMax = Math.ceil(max / 60) * 60

      return roundedMax === 0 ? 60 : roundedMax
    })
    m.set('Y_MAX_PLUS_400_DEFAULT_1600_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      if (data.every((element) => element.y === null)) return 1600

      const max = getDataYMax(data)
      const roundedMax = Math.ceil(max / 400) * 400

      return roundedMax === 0 ? 400 : roundedMax
    })
    m.set('Y_MIN_MINUS_5_ROUNDED_DOWN', (data: ChartParsedDataItem[]) => {
      if (data.every((element) => element.y === null)) return 0

      const max = m.get('Y_MAX_PLUS_5_ROUNDED_UP')?.(data)
      const min = Math.min(...data.filter((item) => item.y !== null).map((item) => item.y))
      const roundedMin = Math.floor(min / 5) * 5

      return roundedMin === max ? roundedMin - 5 : roundedMin
    })

    // tick values functions
    m.set('Y_MAX_2_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_2_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += 0.5) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_10_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_10_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += 2) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_180_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_180_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += 60) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_200_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_200_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += 50) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_600_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_600_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += 120) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_1200_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_1200_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += 200) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_1600_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_1600_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += 400) {
        values.push(i)
      }

      return values
    })
    m.set('Y_1_TICK_VALUES_DIVIDE_6', () => {
      return [0, 0.2, 0.4, 0.6, 0.8, 1]
    })
    m.set('Y_1_TICK_VALUES_DIVIDE_5', () => {
      return [0, 0.25, 0.5, 0.75, 1]
    })
    m.set('Y_10_TICK_VALUES', () => {
      return [0, 2, 4, 6, 8, 10]
    })
    m.set('Y_INJURY_LEVEL', () => {
      return [0, 1, 2, 3, 4, 5]
    })
    m.set('Y_20_TICK_VALUES', () => {
      return [0, 5, 10, 15, 20]
    })
    m.set('Y_180_TICK_VALUES', () => {
      return [0, 30, 60, 90, 120, 150, 180]
    })
    m.set('Y_720_TICK_VALUES', () => {
      return [0, 120, 240, 360, 480, 600, 720]
    })
    m.set('Y_720_TICK_VALUES_DIVIDE_5', () => {
      return [0, 180, 360, 540, 720]
    })
    m.set('Y_1600_TICK_VALUES', () => {
      return [0, 400, 800, 1200, 1600]
    })
    m.set('Y_MAX_PLUS_4_TICK_VALUES_5', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_PLUS_4_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += (max - min) / 4) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_PLUS_400_TICK_VALUES_5', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN')?.(data)
      const max = m.get('Y_MAX_PLUS_400_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += (max - min) / 4) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_PLUS_5_OR_100_TICK_VALUES_6', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN_MINUS_5_ROUNDED_DOWN')?.(data)
      const max = m.get('Y_MAX_PLUS_5_DEFAULT_100_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += (max - min) / 5) {
        values.push(i)
      }
      return values
    })
    m.set('Y_MAX_PLUS_5_OR_200_TICK_VALUES_6', (data: ChartParsedDataItem[]) => {
      const values = []
      const min = m.get('Y_MIN_MINUS_5_ROUNDED_DOWN')?.(data)
      const max = m.get('Y_MAX_PLUS_5_DEFAULT_200_ROUNDED_UP')?.(data)

      for (let i = min; i <= max; i += (max - min) / 5) {
        values.push(i)
      }

      return values
    })
    m.set('Y_MAX_PLUS_400_OR_1600_TICK_VALUES_5', (data: ChartParsedDataItem[]) => {
      const values = []
      const max = m.get('Y_MAX_PLUS_400_DEFAULT_1600_ROUNDED_UP')?.(data)

      for (let i = 0; i <= max; i += max / 4) {
        values.push(i)
      }
      return values
    })
    m.set('Y_MAX_PLUS_60_OR_180_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const max = m.get('Y_MAX_PLUS_60_DEFAULT_180_ROUNDED_UP')?.(data)

      for (let i = 0; i <= max; i += 60) {
        values.push(i)
      }
      return values
    })
    m.set('Y_TICK_VALUES_180_UP_DOWN', (data: ChartParsedDataItem[]) => {
      const max = m.get('DOMAIN_Y_180_UP_DOWN')?.(data)
      const values = []

      if (max <= 180) {
        return m.get('Y_180_TICK_VALUES')?.(data)
      }

      if (max <= 360) {
        return m.get('Y_MAX_PLUS_60_OR_180_TICK_VALUES')?.(data)
      }

      const yMaxFormatHour = Math.ceil(Math.ceil(max / 60) / 6)

      for (let i = 0; i <= 6; i++) {
        values.push(60 * yMaxFormatHour * i)
      }

      return values
    })
    m.set('Y_TICK_VALUES_1600_UP_DOWN', (data: ChartParsedDataItem[]) => {
      if (Math.max(...data.map((item) => item.y)) > 1600) {
        return m.get('Y_MAX_PLUS_400_OR_1600_TICK_VALUES_5')?.(data)
      }

      return m.get('Y_1600_TICK_VALUES')?.(data)
    })

    // sorting functions
    m.set('SORT_ASC_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const sortingData = _orderBy(data, (item) => item.y || undefined, 'asc')
      return sortingData.map((data: any) => data.x)
    })
    m.set('SORT_DESC_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const sortingData = _orderBy(data, (item) => item.y || '', 'desc')
      return sortingData.map((data: any) => data.x)
    })

    // domain size functions
    m.set('DAYS_IN_MONTH', () => {
      return moment(store?.date).daysInMonth()
    })

    // y domain functions
    m.set('DOMAIN_Y_MAX_PLUS_4_ROUNDED_UP', (data: ChartParsedDataItem[]) => {
      const yMax = Math.max(...data.map((item) => item.domainY))

      return yMax === 0 ? 4 : yMax % 4 === 0 ? yMax : yMax - (yMax % 4) + 4
    })
    m.set('DOMAIN_Y_4_ROUNDED_TICK_VALUES', (data: ChartParsedDataItem[]) => {
      const values = []
      const max = m.get('DOMAIN_Y_MAX_PLUS_4_ROUNDED_UP')?.(data)

      for (let i = 0; i <= max; i += max / 4) {
        values.push(i)
      }

      return values
    })
    m.set('DOMAIN_Y_180_UP_DOWN', (data: ChartParsedDataItem[]) => {
      const yMax = Math.max(...data.map((item) => (isNaN(item.y) ? item.domainY || 0 : item.y)))

      if (yMax <= 180) {
        return 180
      }

      if (yMax <= 360) {
        return m.get('Y_MAX_PLUS_60_DEFAULT_180_ROUNDED_UP')?.(data)
      }

      const yMaxFormatTime = yMax === 0 ? 180 : yMax % 6 === 0 ? yMax : yMax - (yMax % 6) + 6
      return yMaxFormatTime
    })

    // y format functions
    m.set('2.5_RE-SCALE_MAX_1', () => {
      return (data: any) => (data.y != undefined ? Math.min(...[data.y / 2.5, 1]) : null)
    })
    m.set('10_RE-SCALE_1', () => {
      return (data: any) => (data.y != undefined ? data.y / 10 : null)
    })
    m.set('72_RE-SCALE_1', () => {
      return (data: any) => {
        if (!data.y) return null

        const THRESHOLDS = [
          { threshold: 300, value: 2 },
          { threshold: 360, value: 4 },
          { threshold: 420, value: 6 },
          { threshold: 480, value: 8 },
          { threshold: Infinity, value: 10 },
        ]

        return THRESHOLDS.find(({ threshold }) => data.y < threshold)?.value
      }
    })
    m.set('720_RE-SCALE_1', () => {
      return (data: any) => (data.y != undefined ? data.y / 720 : null)
    })
    m.set('1500_RE-SCALE_1', () => {
      return (data: any) => (data.y != undefined ? data.y / 1500 : null)
    })
    m.set('2.5_MAX', () => {
      return (data: any) => (data.y != undefined ? Math.min(...[data.y, 2.5]) : null)
    })
    m.set('4_MAX', () => {
      return (data: any) => (data.y != undefined ? Math.min(...[data.y, 4]) : null)
    })
    m.set('720_MAX', () => {
      return (data: any) => (data.y != undefined ? Math.min(...[data.y, 720]) : null)
    })
    m.set('1500_MAX_RE-SCALE_1', () => {
      const values = getDataEachYFormat('1500_MAX_RE-SCALE_1', config, store)
      const maxValue = Math.max(...values, 1500)

      const multipleBy500 = maxValue % 500 === 0 ? maxValue : 500 * Math.ceil(maxValue / 500)

      return (data: any) => (data.y != undefined ? data.y / multipleBy500 : null)
    })

    // orientation functions
    m.set('LEFT', () => 'left')
    m.set('RIGHT', () => 'right')
    m.set('TOP', () => 'top')
    m.set('BOTTOM', () => 'bottom')

    // label placement functions
    m.set('PARALLEL', () => 'parallel')
    m.set('PERPENDICULAR', () => 'perpendicular')
    m.set('VERTICAL', () => 'vertical')

    // label size functions
    m.set('SORENESS_LABEL_TOOLTIP_WIDTH', () => 220)
    m.set('SMALL_LABEL_TOOLTIP_WIDTH', () => 140)
    m.set('MEDIUM_LABEL_TOOLTIP_WIDTH', () => 204)
    m.set('BIG_LABEL_TOOLTIP_WIDTH', () => 260)
    m.set('XBIG_LABEL_TOOLTIP_WIDTH', () => 300)

    // corner radius functions
    m.set('SMALL_CORNER_RADIUS', () => 2)
    m.set('MEDIUM_CORNER_RADIUS', () => 4)

    // label component functions
    m.set('LABEL_AVATAR', (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem) => {
      const { tabId, dataType } = Array.isArray(config?.axis?.x)
        ? config?.axis?.x?.[0].config || {}
        : config?.axis?.x?.config || {}

      return <ChartLabelAvatar data={data} tabId={tabId} dataType={dataType} />
    })
    m.set(
      'LABEL_TOOLTIP_MULTI_TEAM_DATA',
      (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem) => {
        const { width, period, items } = config?.label?.config || {}

        // parsed props
        const parsedWidth = hasKey(m, width) ? m.get(width)?.() : undefined
        const parsedDate = store?.date
        const parsedItems = items?.map((item: any, index: number) => {
          const parsedData = data[index]
          const parsedFormat = m.has(item.format)
            ? m.get(item.format)
            : (value: number | string) => value

          return {
            ...item,
            data: parsedData,
            format: parsedFormat,
          }
        })

        return (
          <ChartLabelTooltipMultiTeamData
            width={parsedWidth}
            date={parsedDate}
            items={parsedItems}
            period={period}
          />
        )
      },
    )
    m.set(
      'LABEL_TOOLTIP_MULTI_USER_DATA',
      (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem) => {
        const { width, items } = config?.label?.config || {}

        // type conditionals
        const isPolar = items?.findIndex((item: any) => item.chartType === 'POLAR') !== -1
        const isStack = items?.findIndex((item: any) => item.chartType === 'STACK') !== -1

        // parsed props
        const parsedWidth = !!width && m.has(width) ? m.get(width)?.() : undefined
        const parsedDate = isPolar ? store?.date : undefined
        const parsedItems = items?.map((item, index: number) => {
          let parsedData
          const { format, interpretPath } = item
          const parsedFormat = hasKey(m, format) ? m.get(format) : (value: number | string) => value
          const parsedInterpret = interpretPath
            ? (value: number | string) => interpret(interpretPath, value)
            : () => {}

          if (isPolar) {
            parsedData = data[0]
          } else if (isStack) {
            parsedData = data[0]
          } else {
            parsedData = data[index]
          }

          return {
            ...item,
            data: parsedData,
            format: parsedFormat as (value: number | string) => number | string,
            interpret: parsedInterpret,
          }
        })

        return (
          <ChartLabelTooltipMultiUserData
            width={parsedWidth}
            date={parsedDate}
            items={parsedItems}
          />
        )
      },
    )
    m.set(
      'LABEL_RENDER_IN_PORTAL_ANCHOR_END',
      (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem) => {
        const { color } = config?.label?.config || {}
        const style = color ? { fill: theme.colors[color as ThemeColor] } : {}

        return <VictoryLabel renderInPortal textAnchor={'end'} style={style} />
      },
    )
    m.set(
      'LABEL_DOTTED_LINE',
      (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem) => {
        const { format, prefix, isAcwrLabel } = config?.label?.config || {}

        const parsedFormat = hasKey(m, format)
          ? (m.get(format) as (value: number | string) => number | string)
          : (value: number | string) => value
        const parsedPrefix = hasKey(m, prefix) ? m.get(prefix)?.() : undefined

        return (
          <ChartLabelDottedLine
            format={parsedFormat}
            prefix={parsedPrefix}
            defaultPrefix={prefix}
            isAcwrLabel={isAcwrLabel}
          />
        )
      },
    )

    m.set(
      'LABEL_AREA_CENTER',
      (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem) => {
        const { color } = config?.label?.config || {}

        return <ChartLabelAreaCenter color={color} />
      },
    )

    // style functions
    m.set(
      'GET_STYLE',
      (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem): VictoryStyleInterface => {
        const {
          color = theme.colors['deep-purple-500'],
          type,
          width,
          minWidth,
          maxWidth,
        } = config || {}
        const chartColor = getColor(color)
        const isBar = type === 'BAR' || type === 'WORKLOAD_BAR'
        const isLine = type === 'LINE' || type === 'WORKLOAD_LINE'
        const isStack = type === 'STACK' || type === 'WORKLOAD_STACK'
        const isPolar = type === 'POLAR'
        const isArea = type === 'AREA'
        const isDottedLine = type === 'DOTTED_LINE'

        if (isDottedLine) {
          return {
            data: {
              stroke: chartColor,
              strokeWidth: 2,
              strokeDasharray: '4,4',
            },
          }
        } else if (isStack) {
          return {
            data: {
              width,
            },
          }
        } else if (isLine) {
          return {
            data: {
              stroke: chartColor,
              strokeWidth: width,
              strokeLinecap: 'round',
            },
          }
        } else if (isPolar) {
          return {
            data: {
              fill: chartColor,
              fillOpacity: 0.2,
              strokeWidth: 2,
            },
          }
        } else if (isArea) {
          return {
            data: {
              fill: chartColor,
              fillOpacity: 0.2,
              stroke: chartColor,
              strokeWidth: 2,
            },
          }
        } else if (isBar) {
          return {
            data: {
              fill: chartColor,
              width: (props) => {
                // width has priority over minWidth and maxWidth
                if (width) {
                  return width
                }

                const barWidth = getBarWidth(props)
                if (minWidth != undefined && maxWidth == undefined) {
                  return Math.max(minWidth, barWidth)
                } else if (maxWidth != undefined && minWidth == undefined) {
                  return Math.min(maxWidth, barWidth)
                } else if (minWidth != undefined && maxWidth != undefined) {
                  // maxWidth has priority over minWidth
                  return Math.min(maxWidth, Math.max(minWidth, barWidth))
                } else {
                  return barWidth
                }
              },
            },
          }
        } else {
          return {
            data: {
              fill: chartColor,
              width,
            },
          }
        }
      },
    )

    // color scale functions
    m.set('GET_COLOR_SCALE', (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem) => {
      const { colorScale } = config || {}

      return colorScale ? colorScale?.map(mappingColor) : [theme.colors['deep-purple-500']]
    })
    m.set(
      'GET_COLOR_SCALE_HOVER',
      (data: ChartParsedDataItem[], config: ChartSystemPropsDatasetItem) => {
        const { colorScaleHover } = config || {}

        return colorScaleHover
          ? colorScaleHover?.map(mappingColor)
          : theme.colors['deep-purple-500']
      },
    )

    // axis x style functions
    m.set('AXIS_X_STYLE_INVISIBLE', () => {
      return {
        axis: {
          stroke: 'none',
        },
      }
    })
    // axis style functions
    m.set('AXIS_STYLE_INVISIBLE', () => {
      return {
        grid: {
          stroke: 'none',
        },
      }
    })

    m.set('AXIS_TEXT_ANCHOR_MIDDLE', () => {
      return {
        axisLabel: {
          textAnchor: 'middle',
        },
      }
    })
    m.set('AXIS_TEXT_ANCHOR_CONDITION', () => {
      return {
        grid: {
          stroke: theme.colors['grey-100'],
        },
        tickLabels: {
          ...{ padding: 18 },
          ...{
            textAnchor: (data: { text: string }) => {
              return data.text === 'Fatigue' ||
                data.text === '피로도' ||
                data.text === 'Mức độ mệt mỏi' ||
                data.text === '疲労度'
                ? 'middle'
                : null
            },
          },
        },
      }
    })

    // events functions
    m.set(
      'GET_EVENTS',
      (
        data: ChartParsedDataItem[],
        config: ChartSystemPropsDatasetItem,
      ): EventPropTypeInterface<'data', string | number | number[] | string[]>[] => {
        const { colorHover = theme.colors['deep-purple-500'], type } = config || {}

        const isLine = type === 'LINE' || type === 'WORKLOAD_LINE'
        const isBar = type === 'BAR' || type === 'WORKLOAD_BAR'
        const isStack = type === 'STACK' || type === 'WORKLOAD_STACK'
        const isSingleItem = (data || []).filter((item) => item.y != undefined).length === 1

        if (isBar || isStack) return []

        return [
          {
            target: 'data',
            eventHandlers: {
              onMouseEnter: () => {
                return [
                  {
                    target: 'data',
                    mutation: (props) => {
                      return {
                        style: {
                          ...props.style,
                          [isLine && !isSingleItem ? 'stroke' : 'fill']: getColor(colorHover),
                        },
                      }
                    },
                  },
                ]
              },
              onMouseLeave: () => {
                return [
                  {
                    target: 'data',
                    mutation: () => null,
                  },
                ]
              },
            },
          },
        ]
      },
    )

    // animate functions
    m.set('FAST_CUBIC_IN_OUT', () => {
      return {
        onLoad: {
          delay: 0,
          // duration on chart new render
          duration: 600,
        },
        // duration on chart cached render
        duration: 450,
        easing: 'cubicInOut',
      }
    })
    m.set('FAST_CUBIC_IN_OUT_OPACITY', () => {
      return {
        onLoad: {
          // duration on chart new render
          duration: 200,
          before: () => ({ opacity: 0 }),
          after: () => ({ opacity: 1 }),
        },
        // duration on chart cached render
        duration: 200,
        easing: 'cubicInOut',
      }
    })

    // generate function trees for dataset config
    if (!config.dataset) {
      throw new Error('chart-system: invalid dataset config')
    }
    const newDatasetTrees = config.dataset.reduce((acc, config) => {
      const newDatasetTree = generateFunctionTreeRecursively(config)

      acc.push(newDatasetTree)

      return acc
    }, [] as ChartSystemPropsTree[])
    setDatasetTrees(newDatasetTrees)

    // generate function tree for domain config
    if (!config.domain) {
      throw new Error('chart-system: invalid domain config')
    }
    const newDomainTree = generateFunctionTreeRecursively(config.domain)
    setDomainTree(newDomainTree)

    // generate function tree for label config
    const newLabelTree = generateFunctionTreeRecursively(config.label || {})
    setLabelTree(newLabelTree)

    // flag as initialized
    setInitialized(true)
  }, [
    config,
    config.dataset,
    config.domain,
    config.label,
    f,
    getBarWidth,
    getDataEachYFormat,
    getDataYMax,
    interpret,
    moment,
    store,
  ])

  const getChartSystemPropsDataset = useCallback(
    (data: ChartParsedData) => {
      if (datasetTrees?.length === 0 || data?.length === 0 || m.size === 0) {
        return []
      }

      // resolve trees with data and config
      return datasetTrees.reduce((acc, tree, index) => {
        const newResolvedTree = resolveFunctionTreeRecursively(
          tree,
          data[index],
          config.dataset[index],
        )

        acc.push(newResolvedTree)

        return acc
      }, [] as ChartPropsDatasetItem[])
    },
    [config.dataset, datasetTrees],
  )

  const getChartSystemPropsDomain = useCallback(
    (data: ChartParsedData) => {
      if (!domainTree || data?.length === 0 || m.size === 0) {
        return {}
      }

      // get domain data
      const configKey = store?.configKey
      const dataIndex = getDataIndex(configKey)
      let domainData
      if (Array.isArray(dataIndex)) {
        // if dataIndex is an array, merge all data arrays into one
        domainData =
          dataIndex.reduce((acc, cur) => {
            const currentdomainData = dataIndex != undefined ? data[cur] : []

            return [...acc, ...currentdomainData]
          }, [] as ChartParsedDataItem[]) || []
      } else {
        domainData = dataIndex != undefined ? data[dataIndex] : []
      }

      // resolve tree with data and config
      return resolveFunctionTreeRecursively(domainTree, domainData, config)
    },
    [config, domainTree, getDataIndex, store?.configKey],
  )

  const getChartSystemPropsLabel = useCallback(
    (data: ChartParsedData) => {
      if (!labelTree || data?.length === 0 || m.size === 0) {
        return {}
      }

      // resolve tree with data and config
      return resolveFunctionTreeRecursively(labelTree, data, config)
    },
    [config, labelTree],
  )

  const chartSystemProps = useMemo(() => {
    const data = store?.parsedData || []

    return {
      dataset: getChartSystemPropsDataset(data),
      domain: getChartSystemPropsDomain(data),
      label: getChartSystemPropsLabel(data),
    }
  }, [
    store?.parsedData,
    getChartSystemPropsDataset,
    getChartSystemPropsDomain,
    getChartSystemPropsLabel,
  ])

  return useMemo(
    () => ({
      initialized,
      chartSystemProps,
    }),
    [initialized, chartSystemProps],
  )
}
