import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { dataInterpreter, moment } from '@common/utils/libs'
import _sum from 'lodash/sum'
import { useRouter } from 'next/router'
import { VictoryTooltipProps } from 'victory'

import { Box } from '@plco-pro/components/atoms'
import { ChipWorkoutStatus } from '@plco-pro/components/molecules'
import { ChartTooltipPeriod } from '@plco-pro/components/molecules/chart-label-tooltip-multi-team-data'
import {
  ChartLabelWorkloadHistory,
  WorkloadKey,
} from '@plco-pro/components/molecules/chart-label-workload-history'
import { ChipMonitoringNumber } from '@plco-pro/components/molecules/chip-monitoring-number'
import { ChipMonitoringTen } from '@plco-pro/components/molecules/chip-monitoring-ten'
import { ChipMonitoringTime } from '@plco-pro/components/molecules/chip-monitoring-time'
import { SportsRawWorkload } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMomentUiFormat } from '@plco-pro/hooks/moment-ui-format'
import { ChartParsedDataItem } from '@plco-pro/hooks/store-system'
import { ChartSystemPropsLabelConfigItem } from '@plco-pro/maps/chart-system/chart-system.types'
import { getScreenSize } from '@plco-pro/providers/responsive'
import { observer, useStore } from '@plco-pro/stores'
import theme from '@plco-pro/themes/main'

export const DEFAULT_TOOLTIP_WIDTH = 318
export const TOOLTIP_HEADER_HEIGHT = 41

export type Item = ChartSystemPropsLabelConfigItem & {
  data: ChartParsedDataItem
}

export type ChartTooltipWorkloadHistoryProps = VictoryTooltipProps & {
  period: ChartTooltipPeriod
  items?: Item[]
  datum?: { [key in string]?: any }
  scale?: { x?: any; y?: any }
  x?: number
  y?: number
  onToggleTooltipActive: (isActive: boolean) => void
}

export const ChartTooltipWorkloadHistory = observer((props: ChartTooltipWorkloadHistoryProps) => {
  const { smAndDown } = getScreenSize(false)
  const { formatMessage } = useI18n()
  const { formatDate, formatDateWithInput } = useMomentUiFormat()

  const { monitoring, players } = useStore()
  const { route } = useRouter()

  const { date: monitoringDate } = monitoring
  const { date: playersDate } = players
  const currentDate = route === '/monitoring' ? monitoringDate : playersDate

  const { onToggleTooltipActive } = props

  const items = useMemo(() => {
    return (props.items || []).map((item) => {
      const { data, chartType, dataType, color, colorList, chipType, nullDataKey } = item

      if (!data) return null

      const parsedValue = chartType === 'WORKLOAD_STACK' ? _sum(Object.values(data.y)) : data.y
      const parsedChartType = chartType && chartType?.toLocaleLowerCase()
      const parsedDataType = dataType ? dataType.toLocaleUpperCase() : ''
      const parsedColor = color || theme.colors['deep-purple-500']

      const interpretedValue = dataInterpreter.get(parsedDataType, parsedValue)

      const getStackWorkloadLabel = () => {
        if (interpretedValue.value) {
          return {
            text: formatMessage({ id: `DATA.${interpretedValue.interpretation?.label}` }),
            disabled: false,
          }
        } else if (nullDataKey) {
          return {
            text: formatMessage({ id: `${nullDataKey}` }),
            disabled: true,
          }
        } else if (data.reasonForNoWorkout) {
          return {
            text: formatMessage({ id: 'DATA.NO.WORKOUT' }),
            disabled: false,
          }
        }

        return {
          text: formatMessage({ id: 'DATA.NO.INPUT' }),
          disabled: true,
        }
      }

      const { text, disabled } = getStackWorkloadLabel()
      const workloadSatisfactionColor =
        interpretedValue.name === 'WORKLOAD_SATISFACTION'
          ? interpretedValue.interpretation?.degree
          : ''

      const chipComponent = (() => {
        if (chipType === 'ten') {
          return (
            <>
              <ChipMonitoringTen
                value={parsedValue}
                label={text}
                disabled={disabled}
                color={workloadSatisfactionColor}
              />
              {data.reasonForNoWorkout ? (
                <ChipWorkoutStatus type={data.reasonForNoWorkout} />
              ) : null}
            </>
          )
        } else if (chipType === 'time') {
          return (
            <>
              <ChipMonitoringTime value={parsedValue} label={text} disabled={disabled} />
              {data.reasonForNoWorkout ? (
                <ChipWorkoutStatus type={data.reasonForNoWorkout} />
              ) : null}
            </>
          )
        } else if (chipType === 'number') {
          return (
            <>
              <ChipMonitoringNumber value={parsedValue} label={text} disabled={disabled} />
              {data.reasonForNoWorkout ? (
                <ChipWorkoutStatus type={data.reasonForNoWorkout} />
              ) : null}
            </>
          )
        }
      })()

      return {
        name: data.name,
        domainX: data.x,
        date: data.date,
        pictureUrl: data.pictureUrl,
        workloadList: (data.workloadList as SportsRawWorkload[]) || [],
        chartType: parsedChartType,
        dataType: parsedDataType as WorkloadKey,
        color: parsedColor,
        colorList: colorList,
        chipComponent,
      }
    })
  }, [formatMessage, props.items])

  const getAmount = useCallback((period: ChartTooltipPeriod) => {
    if (period === '7_DAYS') {
      return -6
    } else if (period === '28_DAYS') {
      return -27
    }

    return 0
  }, [])

  const date = useMemo(() => {
    if (!props.period) return

    if (props.period === 'WEEKLY' || props.period === 'MONTHLY') {
      return formatDate(items[0]?.domainX)
    } else if (props.period === '1_DAY') {
      return formatDate(items[0]?.date || currentDate)
    } else {
      const amount = getAmount(props.period)
      const unit = 'day'

      return formatDateWithInput(currentDate, { amount, unit })
    }
  }, [props.period, formatDate, items, currentDate, getAmount, formatDateWithInput])

  if (items.every((item) => !item)) return null

  const enterTooltip = () => {
    onToggleTooltipActive(true)
  }

  const leaveTooltip = () => {
    onToggleTooltipActive(false)
  }

  const [tooltipHeight, setTooltipHeight] = useState(0)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const node = ref?.current

    if (node) {
      setTooltipHeight(node.lastElementChild?.clientHeight || 0)
    }
  }, [props.x, tooltipHeight, ref.current?.clientHeight])

  const isFutureData =
    moment(props.datum?.x).isValid() && moment(props?.datum?.x).isAfter(moment(), 'day')

  if (isFutureData) {
    return null
  }

  return (
    <Fragment>
      {!smAndDown &&
        items.map((item, index) => {
          if (!item) {
            return null
          }

          return (
            <Box
              ref={ref}
              onMouseEnter={enterTooltip}
              onMouseLeave={leaveTooltip}
              key={index}
              sx={{
                position: 'absolute',
                top: props.y || 0 - tooltipHeight,
                left: props.x,
                zIndex: 1000,
              }}
            >
              <ChartLabelWorkloadHistory
                period={props.period}
                date={date}
                name={item.name}
                dataType={item.dataType}
                pictureUrl={item.pictureUrl}
                workloadList={item.workloadList}
                colorList={item.colorList}
                chipComponent={item.chipComponent}
              />
            </Box>
          )
        })}
    </Fragment>
  )
})
