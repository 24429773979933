import React, { useCallback, useMemo } from 'react'

import { moment } from '@common/utils/libs'
import { VictoryPortal, VictoryTooltipProps } from 'victory'

import { Box } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Icon } from '@plco-pro/components/atoms/icon'
import { Text } from '@plco-pro/components/atoms/text'
import { ChartLabelTooltipBackgroundLine } from '@plco-pro/components/molecules/chart-label-tooltip-backgroud-line'
import { useDataInterpreter } from '@plco-pro/hooks/data-interpreter'
import { useI18n } from '@plco-pro/hooks/i18n'
import { ChartParsedDataItem } from '@plco-pro/hooks/store-system'
import { MonitoringDataType } from '@plco-pro/maps/chart-monitoring'
import { ChartSystemPropsLabelConfigItem } from '@plco-pro/maps/chart-system/chart-system.types'
import theme from '@plco-pro/themes/main'
import { getDegreeColor } from '@plco-pro/utils/color'

const DEFAULT_TOOLTIP_WIDTH = 200
const HEADER_HEIGHT = 32
const CONTENT_HEIGHT = 24
const CONTENT_PADDING = 16

type Item = Omit<ChartSystemPropsLabelConfigItem, 'format'> & {
  data: ChartParsedDataItem
  format?: (value: number | string) => number | string
  interpretPath?: string
}

export type ChartLabelTooltipMultiUserDataProps = VictoryTooltipProps & {
  width?: number
  date?: Date
  items?: Array<Item>
  datum?: { [key in string]?: any }
  scale?: { x?: any; y?: any }
}

export const ChartLabelTooltipMultiUserData: React.FunctionComponent<
  ChartLabelTooltipMultiUserDataProps
> = (props) => {
  const interpret = useDataInterpreter()
  const { formatMessage } = useI18n()

  // header props
  const dateString = useMemo(() => {
    const date = props?.date || props?.datum?.x || new Date()

    const datePartString = `${moment(date).format(formatMessage({ id: 'date.format.period' }))}`
    const dayPartString = `${moment(date).format('(ddd)')}`

    return `${datePartString} ${dayPartString}`
  }, [formatMessage, props?.date, props?.datum?.x])

  // items props
  const formatRawData = useCallback(
    (rawData: MonitoringDataType) => {
      return formatMessage({ id: rawData.toUpperCase() }).replace(/\(.*?\)/g, '')
    },
    [formatMessage],
  )

  const items = useMemo(() => {
    return (props.items || []).map((item) => {
      const { data, chartType, dataType, field, color, interpretPath, nullDataKey, format } = item

      // type conditionals
      const isPolar = chartType === 'POLAR'
      const isStack = chartType === 'STACK'

      const isInjuryData =
        !!dataType &&
        [
          MonitoringDataType.SORENESS_1,
          MonitoringDataType.SORENESS_2,
          MonitoringDataType.SORENESS_3,
          MonitoringDataType.SORENESS_4,
          MonitoringDataType.SORENESS_5,
        ].includes(dataType)

      // parsed props
      let parsedValue: string | number
      if (isPolar && field) {
        parsedValue = data?.[0]?.[field]
      } else if (isStack && field) {
        parsedValue = data?.find((item: any) => item.x === props.datum?.x)?.y?.[field]
      } else {
        parsedValue = data?.find((item: any) => item.x === props.datum?.x)?.y
      }

      const acwrData = ['workload_acxr', 'workload_ax', 'workload_cx']

      const getParsedText = () => {
        if (parsedValue) {
          return format?.(parsedValue)
        } else if (isInjuryData) {
          return '-'
        } else if (dataType?.includes('workload')) {
          const dataParsingkeyCheck = acwrData.includes(dataType)
            ? nullDataKey && parsedValue === null
            : nullDataKey

          return dataParsingkeyCheck ? formatMessage({ id: `${nullDataKey}` }) : '0'
        }

        return formatMessage({ id: 'chart.data.not.checked' })
      }

      const parsedText = getParsedText()
      const parsedChartType =
        chartType && !isPolar && !isStack ? chartType?.toLocaleLowerCase() : 'bar'
      const parsedDataType = dataType ? formatRawData(dataType) : ''
      const parsedColor = color || theme.colors['deep-purple-500']

      // label props
      const getWorkloadLabel = () => {
        if (interpretPath && parsedValue) {
          return interpret(interpretPath, parsedValue).label
        } else if (nullDataKey === 'chart.data.none') {
          return ''
        } else {
          const isWorkOutData = data.find((item: any) => item.x === props.datum?.x)
            ?.reasonForNoWorkout

          return isWorkOutData
            ? formatMessage({ id: 'DATA.NO.WORKOUT' })
            : formatMessage({ id: 'DATA.NO.INPUT' })
        }
      }

      const { label, degree } =
        interpretPath && parsedValue
          ? interpret(interpretPath, parsedValue) || {}
          : { label: undefined, degree: undefined }
      const degreeColor = getDegreeColor(degree, 'MULTI_DATA_DEGREE_COLOR_WHITELIST')
      const parsedLabel = dataType === 'workload' ? getWorkloadLabel() : label

      return {
        value: parsedValue,
        text: parsedText,
        chartType: parsedChartType,
        dataType: parsedDataType,
        color: parsedColor,
        label: parsedLabel,
        degreeColor,
      }
    })
  }, [formatMessage, formatRawData, interpret, props.datum?.x, props.items])

  // size props
  const width = props.width || DEFAULT_TOOLTIP_WIDTH
  const height = HEADER_HEIGHT + items.length * 24 + 24

  const x = useMemo(() => {
    const centerX = Math.round(props?.scale?.x.domain()[1] / 2)

    if (centerX === props?.datum?._x) {
      return (props?.x || 0) - width / 2
    }

    if (centerX > props?.datum?._x) {
      return props?.x || 0
    }

    return (props?.x || 0) - 2 * (width / 2)
  }, [props?.datum?._x, props?.scale?.x, props?.x, width])

  const y = (props?.y || 0) - height - 8

  const hasItems = useMemo(
    () =>
      moment(props?.datum?.x).isValid()
        ? moment(props?.datum?.x).isSameOrBefore(moment(), 'day')
        : items.filter((item) => item.value).length > 0,
    [items, props?.datum?.x],
  )

  if (!hasItems) return null

  // background line props
  const hasBackgroundLine =
    items?.findIndex((item: any) => item.chartType === 'line' || item.chartType === 'area') !== -1

  if ((props?.date || props?.datum?.x) === undefined) {
    return null
  }

  return (
    <>
      <VictoryPortal>
        <foreignObject x={x} y={y} width={width} height={height}>
          <Card
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'grey-50',
            }}
          >
            <Flex sx={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {/* header */}
              <Flex
                sx={{
                  width: '100%',
                  height: `${HEADER_HEIGHT}px`,
                  px: 1,
                  alignItems: 'center',
                  bg: 'grey-50',
                }}
              >
                <Text variant={'p2'} appearance={'hint'} sx={{ fontWeight: 'medium' }}>
                  {dateString}
                </Text>
              </Flex>
              {/* content */}
              <Flex
                sx={{
                  width: '100%',
                  px: 1,
                  py: `${CONTENT_PADDING / 2}px`,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                }}
              >
                {items.map((item, index) => (
                  <Box
                    key={`chart-label-tooltip-multi-data-${index}`}
                    sx={{ width: '100%', height: `${CONTENT_HEIGHT}px` }}
                  >
                    <Flex sx={{ height: `${CONTENT_HEIGHT}px`, alignItems: 'center' }}>
                      <Flex sx={{ flex: '0 0 auto', mr: 1, pt: '2px' }}>
                        <Icon
                          color={item.color}
                          src={`/images/chart-tooltip-${item.chartType}.svg`}
                        />
                      </Flex>
                      <Flex sx={{ flex: '0 1 auto' }}>
                        <Text
                          variant={'p2'}
                          appearance={'hint'}
                          ellipsis
                          sx={{ fontWeight: 'medium' }}
                        >
                          {item.dataType}
                        </Text>
                      </Flex>
                      <Flex sx={{ flex: '0 0 auto', mx: '4px' }}>
                        <Text variant={'p2'} appearance={'hint'} sx={{ fontWeight: 'medium' }}>
                          {':'}
                        </Text>
                      </Flex>
                      <Flex sx={{ flex: '1 0 auto' }}>
                        <Text variant={'p2'} appearance={'hint'} sx={{ fontWeight: 'medium' }}>
                          {item.text}
                        </Text>
                      </Flex>
                      <Flex sx={{ flex: '0 0 auto' }}>
                        <Text
                          variant={'p2'}
                          appearance={'hint'}
                          sx={{ fontWeight: 'medium' }}
                          style={{ color: item.degreeColor }}
                        >
                          {item.label}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                ))}
              </Flex>
            </Flex>
          </Card>
        </foreignObject>
      </VictoryPortal>
      {hasBackgroundLine && <ChartLabelTooltipBackgroundLine {...props} />}
    </>
  )
}
