import { useMemo } from 'react'

import { Flex, Icon, Text } from '@plco-pro/components/atoms'
import {
  Injury,
  InjuryReturnStatus,
  InjuryStatus,
  SportsTeamAuthority,
} from '@plco-pro/graphqls/react.generated'
import { useI18n, useViewer } from '@plco-pro/hooks'
import { useMoment } from '@plco-pro/hooks/moment'

interface Props {
  injury?: Injury
  onClick: () => void
}

export const ListItemPlayerInjury = ({ injury, onClick }: Props) => {
  const { formatMessage } = useI18n()
  const moment = useMoment()
  const { teamAuthority } = useViewer()

  const isObserver = teamAuthority === SportsTeamAuthority.OBSERVER
  const isUndecidedInjury = injury?.return.status === InjuryReturnStatus.UNDECIDED

  const daysDiff = useMemo(() => {
    const today = moment().startOf('day')
    const diff = today.diff(moment(injury?.return.date), 'days')
    const returnText = formatMessage({ id: 'players.card.injury-list.return.label' })

    if (injury?.status === InjuryStatus.END) {
      return ''
    }

    if (isUndecidedInjury) {
      return formatMessage({ id: 'players.card.injury-list.return-unknown.label' })
    }

    if (diff === 0) {
      return `${returnText} D-day`
    }
    if (diff > 0) {
      return `${returnText} D+${diff}`
    }

    return `${returnText} D${diff}`
  }, [formatMessage, injury?.return.date, injury?.status, isUndecidedInjury, moment])

  const listActiveStyle = isObserver
    ? {}
    : {
        cursor: 'pointer',
        '&:hover': {
          bg: 'grey-100',
        },
        '&:active': {
          bg: 'grey-200',
        },
      }

  return (
    <Flex
      sx={{
        borderRadius: '8px',
        px: 2,
        py: '12px',
        bg: 'grey-50',
        justifyContent: 'space-between',
        ...listActiveStyle,
      }}
      onClick={onClick}
    >
      <Flex sx={{ gap: 1 }}>
        <Text sx={{ fontWeight: '500', flexShrink: 0 }} color={'grey-500-80'}>
          {moment(injury?.occurDate).format('YYYY.MM.DD')}
        </Text>
        <Text sx={{ fontWeight: '500' }} color={'grey-500-80'} ellipsis>
          {injury?.bodyPart ? injury?.bodyPart.value : injury?.reason}
        </Text>
      </Flex>
      <Flex sx={{ alignItems: 'center', flexShrink: 0 }}>
        <Text
          variant={'p2'}
          color={'primary-400'}
          appearance={isUndecidedInjury ? 'disabled' : undefined}
          sx={{ mx: 1, fontWeight: '700', whiteSpace: 'nowrap' }}
        >
          {daysDiff}
        </Text>
        <Icon
          src={'/images/arrow-right.svg'}
          sx={{ width: '20px', height: '20px' }}
          color={'#8F9BB3'}
        />
      </Flex>
    </Flex>
  )
}
