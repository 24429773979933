export type Nullable<T> = T | undefined | null

export const isNotNull = <T extends unknown>(value: T | null): value is T => {
  return value !== null
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never

export type EmptyObject = {
  [K in string]: never
}

// TODO: remove ts-ignore after fix typing issue
export function inOperator<K, T extends string>(
  k: K,
  o: T,
  // @ts-ignore
): o is keyof K {
  return o in k
}
