import { forwardRef, ReactNode, useMemo } from 'react'

import styled from '@emotion/styled'
import { variant, VariantArgs } from 'styled-system'
import {
  Button as ThemeUIButton,
  ButtonProps as ThemeUIButtonProps,
  ResponsiveStyleValue,
} from 'theme-ui'

import { Flex } from '@plco-pro/components/atoms/flex'
import { Icon, IconProps } from '@plco-pro/components/atoms/icon'
import { Spinner } from '@plco-pro/components/atoms/spinner'
import { useResponsive } from '@plco-pro/hooks/responsive'

type Variant = 'primary' | 'secondary' | 'tertiary' | 'danger'
type Color = 'primary' | 'grey' | 'red'
type Shape = 'round' | 'square'
type Size = 'xs' | 'sm' | 'md' | 'lg'

export type ButtonProps = Omit<
  ThemeUIButtonProps,
  'variant' | 'shape' | 'size' | 'color' | 'children'
> & {
  variant?: Variant
  color?: Color
  shape?: Shape
  size?: ResponsiveStyleValue<Size>
  active?: boolean
  disabled?: boolean
  icon?: boolean
  src?: string
  iconOnly?: boolean
  children?: ReactNode
  isLoading?: boolean
  'data-testid'?: string
}

type ColorVariant = Color | 'disabled'

export type StyledThemeUIButtonProps = Omit<ThemeUIButtonProps, 'size' | 'shape' | 'color'> & {
  size?: Size | `iconOnly-${Size}`
  shape?: Shape
  colorVariant: ColorVariant
}

const size = variant({
  prop: 'size',
  variants: {
    xs: {
      py: '0px',
      px: '8px',
      fontSize: 'p2',
      lineHeight: 'paragraph',
      fontWeight: 'bold',
      fontFamily: 'subtitle',
      minWidth: '57px',
      height: '24px',
    },
    sm: {
      py: '0px',
      px: '8px',
      fontSize: 'p1',
      lineHeight: 'paragraph',
      fontWeight: 'bold',
      fontFamily: 'subtitle',
      minWidth: '64px',
      height: '32px',
    },
    md: {
      py: '0px',
      px: '16px',
      fontSize: 'p1',
      lineHeight: 'paragraph',
      fontWeight: 'bold',
      fontFamily: 'subtitle',
      minWidth: '80px',
      height: '40px',
    },
    lg: {
      py: '0px',
      px: '16px',
      fontSize: 's2',
      lineHeight: 'subtitle',
      fontWeight: 'bold',
      fontFamily: 'subtitle',
      minWidth: '87px',
      height: '48px',
    },
    'iconOnly-sm': {
      px: '8px',
      py: '5px',
      width: '30px',
      height: '32px',
    },
    'iconOnly-md': {
      px: '0px',
      py: '0px',
      width: '40px',
      height: '40px',
    },
    'iconOnly-lg': {
      px: '0px',
      py: '0px',
      width: '48px',
      height: '48px',
    },
  },
} as VariantArgs)

const shape = variant({
  prop: 'shape',
  variants: {
    round: {
      borderRadius: 100,
    },
    square: {
      borderRadius: 4,
    },
  },
} as VariantArgs)

const colorVariant = variant({
  prop: 'colorVariant',
  variants: {
    disabled: {},
    primary: {},
    red: {
      color: 'text-error',
      outlineColor: 'grey-200',
      ':hover': {
        backgroundColor: 'grey-500-8',
      },
      ':active': {
        backgroundColor: 'grey-500-16',
      },
    },
    grey: {
      color: 'text-primary',
      outlineColor: 'grey-200',
      ':hover': {
        backgroundColor: 'grey-500-8',
      },
      ':active': {
        backgroundColor: 'grey-500-16',
      },
    },
  },
})

const StyledThemeUIButton = styled(ThemeUIButton)<StyledThemeUIButtonProps>(
  size,
  shape,
  colorVariant,
)

const StyledIcon = styled(Icon)()

export const Button = forwardRef<HTMLButtonElement, ButtonProps & Pick<IconProps, 'disabledColor'>>(
  (
    {
      variant = 'primary',
      size = 'md',
      color = 'primary',
      children,
      icon,
      src = '/images/add.svg',
      iconOnly,
      disabledColor,
      disabled,
      isLoading = false,
      ...rest
    },
    ref,
  ) => {
    const getResponsiveProp = useResponsive()

    const responsiveSize = useMemo(() => getResponsiveProp(size) || 'md', [size, getResponsiveProp])

    const colorVariant: ColorVariant = (() => {
      if (disabled) {
        return 'disabled'
      }

      if (variant === 'primary' || variant === 'danger') {
        return 'primary'
      }

      return color
    })()
    const renderIconOnly = icon && iconOnly

    return (
      <StyledThemeUIButton
        ref={ref}
        size={renderIconOnly ? `iconOnly-${responsiveSize}` : responsiveSize}
        variant={variant}
        colorVariant={colorVariant}
        type={'button'}
        disabled={disabled}
        sx={{
          cursor: 'pointer',
          pointerEvents: isLoading ? 'none' : 'auto',
          opacity: isLoading ? 0.5 : 1,
        }}
        {...rest}
      >
        <Flex
          sx={{
            gap: 1,
            justifyContent: 'center',
            alignItems: 'center',
            color: 'inherit',
            height: '100%',
            whiteSpace: 'nowrap',
          }}
        >
          {icon && (
            <StyledIcon
              className={'btn-icon-container'}
              size={iconSize(responsiveSize).width}
              src={src}
              color={disabledColor || 'inherit'}
              sx={{ marginBottom: '2px', ...iconSize(responsiveSize) }}
            />
          )}
          {!renderIconOnly && !isLoading ? children : null}
          {!renderIconOnly && isLoading ? <Spinner size={'sm'} color={'alternative'} /> : null}
        </Flex>
      </StyledThemeUIButton>
    )
  },
)

const iconSize = (size: 'xs' | 'sm' | 'md' | 'lg') => {
  const iconSizes = {
    xs: {
      width: '14px',
      height: '14px',
    },
    sm: {
      width: '14px',
      height: '14px',
    },
    md: {
      width: '24px',
      height: '24px',
    },
    lg: {
      width: '24px',
      height: '24px',
    },
  }

  return iconSizes[size] || {}
}
