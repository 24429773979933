import React from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Divider } from '@plco-pro/components/atoms/divider'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { GhostCard } from '@plco-pro/components/atoms/ghost-card'
import { Icon } from '@plco-pro/components/atoms/icon'
import { Text, TextProps } from '@plco-pro/components/atoms/text'
import { Avatar } from '@plco-pro/components/molecules/avatar'
import {
  PlayerSummaryIdentityFragment,
  SportsCategoryCollection,
} from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMultilingual } from '@plco-pro/hooks/multilingual'
import { palette } from '@plco-pro/themes/main'

type Props = {
  loading: boolean
  user?: {
    name?: PlayerSummaryIdentityFragment['name']
    pictureUrl?: PlayerSummaryIdentityFragment['pictureUrl']
    birthdate?: PlayerSummaryIdentityFragment['birthdate']
    priorSportsCategory?: Pick<SportsCategoryCollection, 'id' | 'group'> | null
    priorSportsPosition?: PlayerSummaryIdentityFragment['priorSportsPosition']
    priorSportsRole?: PlayerSummaryIdentityFragment['priorSportsRole']
  } | null
  height?: string
  weight?: string
  isPlayerStarred?: boolean
}

const CardFlex = (props: FlexProps) => (
  <Flex sx={{ flexDirection: 'column', height: '100%' }} {...props} />
)

const TitleText = (props: TextProps) => (
  <Text variant={['s1', 'h4']} sx={{ fontWeight: 'bold', mx: 3, mt: 3 }} {...props} />
)

const ContentBox = (props: BoxProps) => <Box sx={{ mx: 3 }} {...props} />

const DividerContainerBox = (props: BoxProps) => <Box sx={{ px: 3, my: 1 }} {...props} />

const InfoTextFlex = (props: FlexProps) => <Flex sx={{ width: '100%' }} {...props} />

const InfoFieldText = (props: TextProps) => (
  <Text variant={'p1'} appearance={'hint'} sx={{ width: '100px' }} {...props} />
)

const InfoValueText = (props: TextProps) => <Text variant={'p1'} sx={{ ml: '14px' }} {...props} />

export const CardPlayerInfo = ({ loading, user, height, weight, isPlayerStarred }: Props) => {
  const { formatMessage: f } = useI18n()

  const sportsCategoryGroup = user?.priorSportsCategory?.group
  const sportsPosition = user?.priorSportsPosition
  const sportsCategory = user?.priorSportsCategory?.id
  const sportsRole = user?.priorSportsRole

  const { currentPosition } = useMultilingual({
    group: sportsCategoryGroup,
    category: sportsCategory,
    role: sportsRole,
    position: sportsPosition,
  })

  const avatarSrc = user?.pictureUrl
  const username = user?.name
  const birthdate = user?.birthdate

  if (loading) {
    return (
      <GhostCard sx={{ width: '100%', height: '100%' }}>
        <CardFlex>
          <TitleText>{f({ id: 'players.card.player-info.title' })}</TitleText>
        </CardFlex>
      </GhostCard>
    )
  }

  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <CardFlex>
        <TitleText>{f({ id: 'players.card.player-info.title' })}</TitleText>

        <ContentBox sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ mt: '5px' }}>
              <Avatar src={avatarSrc} value={username} size={96} />
            </Box>
            <Flex
              sx={{
                mt: 1,
                mb: 1,
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '100%',
              }}
            >
              <Flex sx={{ alignItems: 'center', maxWidth: '100%' }}>
                <Flex sx={{ flex: 1 }}>
                  <Text
                    variant={'h3'}
                    sx={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                    multiLineEllipsis={1}
                  >
                    {username}
                  </Text>
                </Flex>
                {isPlayerStarred && (
                  <Flex>
                    <Icon src={'/images/star.svg'} color={palette['orange-400']} />
                  </Flex>
                )}
              </Flex>
              <Text variant={'s2'} appearance={'hint'}>
                {currentPosition?.value}
              </Text>
            </Flex>
          </Flex>
        </ContentBox>

        <DividerContainerBox>
          <Divider />
        </DividerContainerBox>

        <ContentBox sx={{ mb: 3 }}>
          <InfoTextFlex>
            <InfoFieldText>{f({ id: 'players.card.player-info.birthdate' })}</InfoFieldText>
            <InfoValueText>
              {birthdate === 'Invalid date'
                ? f({ id: 'players.card.player-info.none' })
                : birthdate}
            </InfoValueText>
          </InfoTextFlex>
          <InfoTextFlex sx={{ mt: 2 }}>
            <InfoFieldText>{f({ id: 'players.card.player-info.height' })}</InfoFieldText>
            <InfoValueText>{height || f({ id: 'players.card.player-info.none' })}</InfoValueText>
          </InfoTextFlex>
          <InfoTextFlex sx={{ mt: 2 }}>
            <InfoFieldText>{f({ id: 'players.card.player-info.weight' })}</InfoFieldText>
            <InfoValueText>{weight || f({ id: 'players.card.player-info.none' })}</InfoValueText>
          </InfoTextFlex>
        </ContentBox>
      </CardFlex>
    </Card>
  )
}
