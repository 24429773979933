import _debounce from 'lodash/debounce'
import { observable, runInAction } from 'mobx'
import {
  addDisposer,
  applySnapshot,
  getEnv,
  getSnapshot,
  getType,
  Instance,
  onSnapshot,
  SnapshotOut,
  types,
} from 'mobx-state-tree'

import { getKeycloakInstanceWithConfig } from '@plco-pro/providers/keycloak'
import { BlogStore } from '@plco-pro/stores/blog'
import { MonitoringStore } from '@plco-pro/stores/monitoring'
import { IStorage } from '@plco-pro/utils/libs'

import { APIClientStore } from './api'
import { EventStore } from './event'
import { NavigationStore } from './navigation'
import { NotificationStore } from './notification'
import { PlayersStore } from './players'
import { PreferenceStore } from './preference'
import { RegisterStore } from './register'
import { TeamChartStore } from './team-chart-store'
import { TeamsStore } from './teams'

export type RootStoreEnv = {
  apiClientHeaders?: { [key: string]: string }
  storage: IStorage
  storageKey?: string
}

// sync store state from async storage
export const RootStore = types
  .model('RootStore', {
    notification: types.optional(NotificationStore, {}),
    apiClient: types.optional(APIClientStore, {}),
    preference: types.optional(PreferenceStore, {}),
    navigation: types.optional(NavigationStore, {}),
    teamChart: types.optional(TeamChartStore, {}),
    monitoring: types.optional(MonitoringStore, {}),
    event: types.optional(EventStore, {}),
    players: types.optional(PlayersStore, {}),
    teams: types.optional(TeamsStore, {}),
    blog: types.optional(BlogStore, {}),
    register: types.optional(RegisterStore, {}),
  })
  .extend((selfWrongType) => {
    const self = selfWrongType as RootStoreType
    const { storage, storageKey = 'default-storage-2.0.0' } = getEnv<RootStoreEnv>(self)
    const type = getType(self)

    const keycloakInstance = getKeycloakInstanceWithConfig()

    // volatile states
    const restored = observable.box(false)
    const storageLoaded = observable.box(false)

    // store sync handlers
    const save: (
      snapshot?: SnapshotOut<RootStoreType>,
      debounced?: boolean,
    ) => Promise<void> = async (snapshot?: SnapshotOut<RootStoreType>, debounced = false) => {
      if (!debounced) {
        // cancel duplicate saving
        debouncedSave.cancel()
      }

      if (!snapshot) {
        // eslint-disable-next-line no-param-reassign
        snapshot = getSnapshot(self)
      }

      if (!type.is(snapshot)) {
        console.warn('snapshot type is unmatched with store, ignore state saving')
        return
      }
      await storage.set(storageKey, JSON.stringify(snapshot))
    }
    const debouncedSave = _debounce(
      (snapshot: SnapshotOut<RootStoreType>) => self.save(snapshot, true),
      1000,
      { maxWait: 2000 },
    )

    const restore = async () => {
      try {
        const serializedSnapshot = await storage.get(storageKey)
        const snapshot = serializedSnapshot ? JSON.parse(serializedSnapshot) : undefined

        if (!snapshot) {
          return
        }

        if (!type.is(snapshot)) {
          if (snapshot) {
            console.warn('snapshot type is unmatched with store, ignore state restoring')
          }
          return
        }

        applySnapshot(self, snapshot)

        restored.set(true)
      } catch (error) {
        console.error('failed to restore RootStore', error)
      }
    }

    // clear except local preference
    const clear = async () => {
      try {
        const { preference } = getSnapshot(self)

        const persistent = {
          preference,
        }

        applySnapshot(self, persistent)
        await save()
        await self.afterCreate()
      } catch (err) {
        console.error('failed to clear store', err)
      }
    }

    const login = async () => {
      try {
        const { language } = self.preference.locale

        await keycloakInstance.login?.({
          locale: language.split('-')[0] || 'en',
          redirectUri: `${window.location.origin}/auth-callback/login`,
        })
      } catch (error) {
        console.error('login failed', error)
      }
    }

    const logout = async () => {
      try {
        await keycloakInstance.logout?.({
          redirectUri: `${window.location.origin}/auth-callback/logout`,
        })
      } catch (error) {
        console.error('logout failed', error)
      }
    }

    const applyTokenToHeader = () => {
      const { token } = keycloakInstance

      self.apiClient.setHeader('Authorization', token ? `${'Bearer'} ${token}` : null)
    }

    return {
      actions: {
        async afterCreate() {
          // restore state from storage
          await restore()

          // sync storage with state for any updates
          addDisposer(self, onSnapshot(self, debouncedSave))
          runInAction(() => {
            storageLoaded.set(true)
          })

          // preference locale update -> API Client header and OIDC Client UI locale update
          const updateLocaleHeaderAndParam = () => {
            const localeCode = self.preference.locale.code
            if (self.apiClient.headers.get('Accept-Language') !== localeCode) {
              self.apiClient.setHeader('Accept-Language', localeCode)
            }
          }
          updateLocaleHeaderAndParam()
          addDisposer(self, onSnapshot(self.preference, updateLocaleHeaderAndParam))

          // config -> API Client rest http endpoint

          const restHTTPEndpoint = process.env.NEXT_PUBLIC_GRAPHQL_URL || ''
          self.apiClient.setRestHTTPEndpoint(restHTTPEndpoint)

          // init API Client
          await self.apiClient.initialize()
        },
        save,
        restore,
        clear,
        login,
        logout,
        applyTokenToHeader,
      },
      views: {
        get initialized() {
          return storageLoaded.get() && self.apiClient.initialized
        },
        get restored() {
          return restored.get()
        },
      },
    }
  })

export type RootStoreModel = typeof RootStore
export type RootStoreType = Instance<typeof RootStore.Type>
