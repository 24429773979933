export type Env = 'development' | 'stage' | 'production'
export type ClientEnv = 'debug' | 'release'
export type ServerEnv = 'dev' | 'stage' | 'prod'

// current environment
export const __ENV__: Env = (process.env.ENV as Env) || 'development'

// client environment
export const __CLIENT_ENV__: ClientEnv = process.env.APP_ENV === 'prod' ? 'release' : 'debug'

// is client debug environment
export const __CLIENT_DEBUG__: boolean = __CLIENT_ENV__ === 'debug'

// server api environment
export const __SERVER_ENV__: ServerEnv = process.env.APP_ENV as ServerEnv

// is server api dev environment
export const __SERVER_DEV__: boolean = __SERVER_ENV__ === 'dev'
