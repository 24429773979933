import { Flex, Icon, Text } from '@plco-pro/components/atoms'

interface Props {
  id: number
  fileName: string
  onDelete: (id: number) => void
}

export const InjuryFileItem = ({ id, fileName, onDelete }: Props) => {
  return (
    <Flex sx={{ gap: 1, px: 2, py: 1, bg: '#fff', borderRadius: '8px' }}>
      <Text variant={'p1'}>{'📎'}</Text>
      <Text sx={{ flex: 1 }} variant={'p1'} ellipsis>
        {fileName}
      </Text>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '24px',
          height: '24px',
          p: '2px',
          borderRadius: '50%',
          cursor: 'pointer',
          ':hover': {
            bg: 'grey-500-8',
          },
          ':active': {
            bg: 'grey-500-16',
          },
        }}
      >
        <Icon
          onClick={() => onDelete(id)}
          sx={{ flexShrink: 0 }}
          src={'/images/ic-delete-outline.svg'}
          color={'carmine-300'}
        />
      </Flex>
    </Flex>
  )
}
