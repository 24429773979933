import React from 'react'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { Avatar } from '@plco-pro/components/molecules/avatar'
import {
  Maybe,
  PlayerModalUserQuery,
  SportsRawBody,
  useBodyPartMultilingualTextQuery,
} from '@plco-pro/graphqls/react.generated'
import { useNationality } from '@plco-pro/hooks'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMomentUiFormat } from '@plco-pro/hooks/moment-ui-format'
import { useMultilingual } from '@plco-pro/hooks/multilingual'

type Props = {
  user?: PlayerModalUserQuery['sports']['user']
  latestRawBody?: Maybe<Pick<SportsRawBody, 'weight' | 'height'>>
  showInjuryPart?: boolean
  part: string
}

export const InjuryHistoryModalHeader = ({ showInjuryPart, user, latestRawBody, part }: Props) => {
  const { formatDate } = useMomentUiFormat()
  const { formatMessage: f } = useI18n()
  const nationality = useNationality()

  const { data } = useBodyPartMultilingualTextQuery({
    variables: {
      input: {
        nationality,
      },
      key: part,
    },
  })

  const { currentPosition } = useMultilingual({
    group: user?.priorSportsCategory?.group,
    category: user?.priorSportsCategory?.id,
    role: user?.priorSportsRole,
    position: user?.priorSportsPosition,
  })

  const name = user?.name
  const birthdate = user?.birthdate ? formatDate(user.birthdate) : '-'

  const weight = latestRawBody?.weight ? `${latestRawBody.weight}kg` : '-'
  const height = latestRawBody?.height ? `${latestRawBody.height}cm` : '-'

  if (!user) {
    return <Text variant={'s2'}>{f({ id: 'navbar-players.player-data' })}</Text>
  }

  if (!showInjuryPart) {
    return (
      <Flex sx={{ gap: 1 }}>
        <Avatar src={user?.pictureUrl} value={name} size={[40, 48]} />
        <Box>
          <Text variant={['s2', 's1']} sx={{ fontWeight: 'medium' }}>
            {name}
          </Text>
          <Flex>
            <Text variant={['p2', 'p1']} appearance={'hint'}>
              {[currentPosition?.value, birthdate, weight, height].join(' / ')}
            </Text>
          </Flex>
        </Box>
      </Flex>
    )
  }

  return <Text variant={'s1'}>{data?.multilingualText.bodyPartList[0].value}</Text>
}
