export const teamCode = {
  en: {
    'team-code.coach.copy.messages':
      'Join {teamName}! 👍\n\n' +
      'Team Name: {teamName}\n' +
      'Team Code: {teamCode}\n\n' +
      'How to connect the team:\n' +
      '1) Login to https://plco.pro\n' +
      "2) Click 'Enter team code'\n" +
      '3) Enter the Team code\n' +
      '4) Successful connection if owner or admin accepts!',

    'team-code.player.copy.messages':
      'Join {teamName}! 👍\n\n' +
      'Team Name: {teamName}\n' +
      'Team Code: {teamCode}\n\n' +
      'How to connect the team:\n' +
      "1) Tap 'Profile Image'.\n" +
      "2) Tap 'team code card'\n" +
      "3) Enter 'the team code'.\n" +
      '4) Successful connection if owner or admin accepts!\n\n' +
      "Don't you have an app yet?\n" +
      'here is the link: https://plco.onelink.me/HcII/7eee538f',
  },
  ko: {
    'team-code.coach.copy.messages':
      '{teamName}팀에 합류하세요! 👍\n\n' +
      '팀이름: {teamName}\n' +
      '팀코드: {teamCode}\n\n' +
      '팀 연결 방법:\n' +
      '1) https://plco.pro 로그인\n' +
      "2) '코드 입력'을 누르세요.\n" +
      "3) '해당 팀 코드'를 입력하세요.\n" +
      '4) 관리자가 수락하면 연결 성공!',

    'team-code.player.copy.messages':
      '{teamName}팀에 합류하세요! 👍\n\n' +
      '팀이름: {teamName}\n' +
      '팀코드: {teamCode}\n\n' +
      '팀 연결 방법:\n' +
      "1) '프로필 이미지’를 탭하세요.\n" +
      "2) '팀 코드 카드'를 탭 하세요.\n" +
      "3) '해당 팀 코드'를 입력하세요.\n" +
      '4) 관리자가 수락하면 연결 성공!\n\n' +
      '앱이 아직 없으신가요?\n' +
      'https://plco.onelink.me/HcII/7eee538f 에서 다운로드하세요!',
  },
  ja: {
    'team-code.coach.copy.messages':
      '{teamName}チームに合流してください！ 👍\n\n' + // "{teamName}팀에 합류하세요! 👍\n\n" +
      'チーム名:{teamName}\n' + // "팀이름: {teamName}\n" +
      'チームコード:{teamCode}\n\n' + // "팀코드: {teamCode}\n\n" +
      'チーム接続方法:\n' + // "팀 연결 방법:\n" +
      '1) https://plco.pro ログイン\n' + // "1) https://plco.pro 로그인\n" +
      "2) 'コード入力'をクリックします。\n" + // "2) '코드 입력'을 누르세요.\n" +
      "3) '該当チームコード' を入力してください.\n" + // "3) '해당 팀 코드'를 입력하세요.\n" +
      '4) 管理者が受諾すれば接続成功！', // "4) 관리자가 수락하면 연결 성공!",

    'team-code.player.copy.messages':
      '{teamName}チームに合流してください！ 👍\n\n' + // "{teamName}팀에 합류하세요! 👍\n\n" +
      'チーム名:{teamName}\n' + // "팀이름: {teamName}\n" +
      'チームコード:{teamCode}\n\n' + // "팀코드: {teamCode}\n\n" +
      'チーム接続方法:\n' + // "팀 연결 방법:\n" +
      "1) 'プロフィール画像' をタップしてください。\n" + // "1) '프로필 이미지’를 탭하세요.\n" +
      "2) 'チームコードカード'をタップしてください。\n" + // "2) '팀 코드 카드'를 탭 하세요.\n" +
      "3) '該当チームコード' を入力してください。\n" + // "3) '해당 팀 코드'를 입력하세요.\n" +
      '4) 管理者が受諾すれば接続成功！\n\n' + // "4) 관리자가 수락하면 연결 성공!\n\n" +
      'アプリがまだありませんか？\n' + // "앱이 아직 없으신가요?\n" +
      'https://plco.onelink.me/HcII/7eee538fからダウンロードしてください！', // "https://plco.onelink.me/HcII/7eee538f 에서 다운로드하세요!",
  },
}
