import { ChangeEvent, useEffect } from 'react'

import { Textarea } from '@plco-pro/components/atoms'

interface Props {
  width: string
  value?: string
  setValue: (value: string) => void
  textareaRef: React.RefObject<HTMLTextAreaElement>
  placeholder?: string
  resizeAction?: () => void
}

export const TextAreaAutoResize = ({
  width,
  value,
  setValue,
  resizeAction,
  textareaRef,
  placeholder,
}: Props) => {
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target

    setValue(value)
  }

  useEffect(() => {
    resizeAction?.()
  }, [resizeAction, value])

  return (
    <Textarea
      sx={{
        width,
        height: 'auto',
        overflowY: 'hidden',
        resize: 'none',
        py: 1,
        px: 2,
      }}
      value={value}
      rows={1}
      onChange={onChange}
      placeholder={placeholder}
      ref={textareaRef}
    />
  )
}
