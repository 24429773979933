import React, { forwardRef } from 'react'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'

export type TouchableOpacityProps = FlexProps & {
  disabled?: boolean
}

export const TouchableOpacity = forwardRef<HTMLDivElement, TouchableOpacityProps>((props, ref) => {
  return (
    <Flex
      ref={ref}
      {...props}
      sx={{
        alignItems: 'center',
        cursor: 'pointer',
      }}
    />
  )
})
