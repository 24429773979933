import { useEffect, useMemo, useState } from 'react'

import { Flex, Spinner, Text } from '@plco-pro/components/atoms'
import { CardGeneralFeedback } from '@plco-pro/components/organisms/dialog-feedback/card-general-feedback'
import { CardSuggestionFeedback } from '@plco-pro/components/organisms/dialog-feedback/card-suggestion-feedback'
import { EmptySuggestionFeedback } from '@plco-pro/components/organisms/dialog-feedback/empty-suggestion-feedback'
import { PlayerSelectedType } from '@plco-pro/components/organisms/dialog-player-select'
import { Modal } from '@plco-pro/components/organisms/modal'
import { FeedbackSuggestion } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'
import { useResponsiveContext } from '@plco-pro/providers/responsive'

interface Props {
  loading: boolean
  player: PlayerSelectedType
  feedbackSuggestionList: FeedbackSuggestion[]
  isOpen: boolean
  entry: 'squad' | 'fab' | 'newdashboard'
  onClose: () => void
  onPrev: () => void
  onNext: () => void
  onSelectFeedback: (feedback?: FeedbackSuggestion | null) => void
}

export const DialogSelectFeedback = ({
  loading,
  player,
  feedbackSuggestionList,
  isOpen,
  entry,
  onClose,
  onPrev,
  onNext,
  onSelectFeedback,
}: Props) => {
  const { formatMessage } = useI18n()
  const { smAndDown, xlOnly } = useResponsiveContext()

  const [selectedFeedbackIndex, setSelectedFeedbackIndex] = useState<number | null>()

  useEffect(() => {
    setSelectedFeedbackIndex(undefined)
    onSelectFeedback(undefined)
  }, [player, onSelectFeedback])

  const onChangeFeedback = (index: number | null) => {
    const selectedFeedback = index === null ? null : feedbackSuggestionList[index]

    setSelectedFeedbackIndex(index)
    onSelectFeedback(selectedFeedback)
  }

  const modalMaxHeight = useMemo(() => {
    const viewportHeight = window.innerHeight

    if (smAndDown) {
      return '100%'
    }

    if (xlOnly) {
      return viewportHeight > 800 ? '800px' : `${viewportHeight - 40}px`
    }

    return viewportHeight > 590 ? '590px' : `${viewportHeight - 40}px`
  }, [smAndDown, xlOnly])

  return (
    <Modal
      isFullScreen={smAndDown}
      open={isOpen}
      width={'560px'}
      height={modalMaxHeight}
      contentPaddingDisable={true}
      onClose={onClose}
      headerProps={{
        title: formatMessage({ id: 'fab.send-feedback' }),
        sx: { fontSize: 's1' },
      }}
      footerProps={{
        leftButtonProps: {
          children:
            entry === 'squad'
              ? formatMessage({ id: 'CANCEL' })
              : formatMessage({ id: 'feedback.back' }),
          onClick: entry === 'squad' ? onClose : onPrev,
        },
        rightButtonProps: {
          disabled: selectedFeedbackIndex === undefined,
          children: formatMessage({ id: 'feedback.next' }),
          onClick: onNext,
        },
      }}
    >
      {loading ? (
        <Flex
          sx={{ flex: 1, height: '100%', py: 4, justifyContent: 'center', alignItems: 'center' }}
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex sx={{ flexDirection: 'column', gap: '42px', my: 3, px: [2, 3] }}>
          <Flex sx={{ flexDirection: 'column', gap: 4 }}>
            <Flex sx={{ flexDirection: 'column', gap: 1 }}>
              <Text
                sx={{
                  fontSize: ['h5', 'h3'],
                  fontWeight: 'bold',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                }}
              >
                {formatMessage({ id: 'feedback.choose-feedback' }, { player: player.name })}
              </Text>
              <Text sx={{ whiteSpace: 'pre-wrap' }} variant={'p1'} color={'grey-500'}>
                {formatMessage({ id: 'feedback.suggestion-description' })}
              </Text>
            </Flex>
            <CardGeneralFeedback
              isSelected={selectedFeedbackIndex === null}
              onChange={() => onChangeFeedback(null)}
            />
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ fontSize: 'h5', fontWeight: 'bold' }}>
              {formatMessage({ id: 'feedback.plco-suggestions' })}
            </Text>
            <Flex sx={{ flexDirection: 'column', gap: 2, mt: [3, 2] }}>
              {feedbackSuggestionList.length ? (
                feedbackSuggestionList.map((item, index) => (
                  <CardSuggestionFeedback
                    player={player}
                    feedbackSuggestion={item}
                    isSelected={selectedFeedbackIndex === index}
                    key={index}
                    onChange={() => onChangeFeedback(index)}
                  />
                ))
              ) : (
                <EmptySuggestionFeedback />
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Modal>
  )
}
