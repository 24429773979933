import React, { useMemo } from 'react'

import { BlockProps } from 'victory-core'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Icon } from '@plco-pro/components/atoms/icon'
import { Text } from '@plco-pro/components/atoms/text'
import { ChartPropsDataset, ChartPropsLegend } from '@plco-pro/components/organisms/chart'
import { useI18n } from '@plco-pro/hooks/i18n'

export type ChartLegendProps = {
  padding?: BlockProps
  dataset: ChartPropsDataset
}

export const ChartLegend: React.FunctionComponent<ChartLegendProps> = ({ padding, dataset }) => {
  const { formatMessage: f } = useI18n()

  const visibleAllDataLegendGroups = useMemo(() => {
    return (dataset || []).map((item) => {
      const visibleAllDataLegendItems = (item.legend || []).filter(
        (data) => data.visible && data.label,
      )

      const legendGroupsMap = visibleAllDataLegendItems.reduce((acc, legend) => {
        const groupId = legend.group || 0

        const groupLegendItems = acc.get(groupId) || []
        acc.set(groupId, [...groupLegendItems, legend])
        return acc
      }, new Map<number, ChartPropsLegend[]>())

      return Array.from(legendGroupsMap.values())
    })
  }, [dataset])

  return (
    <Box
      mt={`${padding?.top || 0}px`}
      mb={`${padding?.bottom || 0}px`}
      ml={`${padding?.left || 0}px`}
      mr={`${padding?.right || 0}px`}
    >
      <Flex
        sx={{
          width: '100%',
          height: '22px',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        {visibleAllDataLegendGroups.map((dataLegendGroups) =>
          dataLegendGroups.map((group, index) => (
            <Flex
              key={`group-${index}`}
              sx={{ justifyContent: 'center', flexWrap: 'nowrap', flexShrink: 0 }}
            >
              {group.map((data) => {
                const type = data?.chartType
                const chartColor = data?.chartColor
                const label = data?.label ? f({ id: data.label, defaultMessage: ' ' }) : ' '

                return (
                  <Flex key={`${type}-${chartColor}-${label}`} sx={{ mr: 2, alignItems: 'center' }}>
                    <Icon
                      src={`/images/chart-legend-${type?.toLocaleLowerCase()}.svg`}
                      sx={{ color: chartColor, mr: 1 }}
                    />
                    <Text variant={'p1'} sx={{ fontWeight: 'medium' }}>
                      {label}
                    </Text>
                  </Flex>
                )
              })}
            </Flex>
          )),
        )}
      </Flex>
    </Box>
  )
}
