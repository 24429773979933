import React, { memo } from 'react'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'

type TabAnchorProps = FlexProps & {
  name: string
  onClick: () => void
  isActive?: boolean
  activeColor?: string
}

export const Anchor = memo(
  ({ name, onClick, isActive, activeColor = 'primary', ...rest }: TabAnchorProps) => {
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'stretch',
          flexShrink: '0',
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={onClick}
        {...rest}
      >
        <Text
          variant={['s2', 's2', 'h5', null]}
          sx={{ mt: ['12px', null, '14px', null], mx: [1, 2, 4, null] }}
          appearance={isActive ? undefined : 'hint'}
        >
          {name}
        </Text>

        <Box
          sx={{
            mt: ['12px', null, '14px', null],
            height: '3px',
            borderRadius: '2px',
            zIndex: 1,
            bg: isActive ? activeColor : undefined,
          }}
        />
      </Flex>
    )
  },
)
