import React, { useCallback, useEffect } from 'react'

import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { pathToRegexp } from 'path-to-regexp'
import { Flex } from 'theme-ui'

import { Spinner } from '@plco-pro/components/atoms'
import { isRegister } from '@plco-pro/domain/register'
import { NavigationViewerQuery, useNavigationViewerQuery } from '@plco-pro/graphqls/react.generated'
import { CUSTOM_TIMEOUT_MESSAGE } from '@plco-pro/graphqls/utils'
import { useIdentify } from '@plco-pro/hooks/identify'
import { useNavigation } from '@plco-pro/hooks/navigation'
import { useStore } from '@plco-pro/stores'
import { routeToRenewalCoach } from '@plco-pro/utils'
import { setUser } from '@plco-pro/utils/libs/log'
import { getTeams } from '@plco-pro/utils/navigation'

const WHITE_LIST = [
  { path: '/auth-callback/logout' },
  { path: '/blog', queryParams: ['errorCode'] },
  { path: '/blog/*', queryParams: ['errorCode'] },
]

const NavigationProvider: React.FunctionComponent = observer(({ children }) => {
  const router = useRouter()
  const { navigation, register } = useStore()
  const { isLoading, isAdmin, isOwner, isObserver, isActivate, isHyper } = useNavigation()

  // analytics
  useIdentify()

  const setActiveTeam = useCallback(
    (data: NavigationViewerQuery) => {
      const teams = getTeams(data)
      const currentActiveTeam =
        teams?.find((team) => team?.id === navigation.activeTeamId) || teams?.[0]
      const viewer = data?.sports?.viewer

      // find total count properties
      const totalTeamCount = viewer?.teamMaps?.map((teamMap) => teamMap.team).length || 0

      navigation.setActiveTeamId(currentActiveTeam?.id || '')

      // update totals in store
      navigation.setTotalTeamCount(totalTeamCount)
    },
    [navigation],
  )

  const handleCompletedNavigationViewer = (data: NavigationViewerQuery) => {
    const viewer = data?.sports?.viewer
    if (!viewer) return

    if (isRegister(viewer)) {
      setActiveTeam(data)
      navigation.setInitialized(true)
    } else {
      register.viewerIdentity(viewer)

      if (!router.route.includes('/register')) {
        router.replace('/register/welcome')
      }
    }
  }

  // query to fetch viewer data
  const skipNavigationViewer = !!WHITE_LIST.find(({ path, queryParams }) => {
    return (
      new RegExp(path).test(router.pathname) &&
      (queryParams ?? []).every((query) => router.asPath.includes(`${query}=`))
    )
  })
  const { data } = useNavigationViewerQuery({
    skip: skipNavigationViewer,
    onCompleted: (data) => {
      if (!data) {
        return
      }

      handleCompletedNavigationViewer(data)
    },
    onError: (error) => {
      navigation.changeSubscription(null)
      navigation.changeLicense(null)
      if (error?.message === CUSTOM_TIMEOUT_MESSAGE) {
        return router.push({
          pathname: '/blog',
          query: {
            errorCode: 'timeout',
          },
        })
      }
    },
    context: {
      skipGlobalHandling: true,
    },
  })

  useEffect(() => {
    if (!data) {
      return
    }

    const { viewer } = data.sports

    setUser({
      id: viewer?.id,
      email: viewer?.email,
      name: viewer?.name,
    })
  }, [data])

  // GA tracking
  useEffect(() => {
    if (typeof window === 'undefined' || !data?.sports.viewer?.id) {
      return
    }

    window.dataLayer?.push(function () {
      this.set('userId', data.sports.viewer?.id)
    })
  }, [data?.sports.viewer?.id])

  useEffect(() => {
    if (isLoading || router.pathname.match(pathToRegexp('/register/(.*)'))) {
      return
    }

    const isCoach = isAdmin || isOwner || isObserver

    if (!isCoach) {
      routeToRenewalCoach()

      return
    }

    if (isHyper) {
      return
    }

    if (new RegExp('/plan/(.*)').test(router.pathname)) {
      return
    }

    if (!isActivate) {
      routeToRenewalCoach()
    }
  }, [isActivate, isAdmin, isHyper, isLoading, isObserver, isOwner, router])

  return (
    <>
      {isLoading ? (
        <Flex sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <Spinner />
        </Flex>
      ) : (
        children
      )}
    </>
  )
})

export default NavigationProvider
