import { Flex, Text } from '@plco-pro/components/atoms'
import { ButtonRadio } from '@plco-pro/components/molecules/button-radio'
import { useI18n } from '@plco-pro/hooks'

interface Props {
  isSelected: boolean
  onChange: () => void
}

export const CardGeneralFeedback = ({ isSelected, onChange }: Props) => {
  const { formatMessage } = useI18n()

  return (
    <Flex
      onClick={onChange}
      sx={{
        alignItems: 'center',
        gap: '12px',
        px: 2,
        py: '20px',
        cursor: 'pointer',
        border: '1px solid',
        borderRadius: '6px',
        borderColor: isSelected ? 'primary-500' : 'grey-200',
        ':hover': {
          bg: 'grey-100',
        },
        ':active': {
          bg: 'grey-200',
        },
      }}
    >
      <ButtonRadio isChecked={isSelected} size={20} sx={{ width: '20px', height: '20px', p: 0 }} />
      <Flex sx={{ flexDirection: 'column', gap: '2px' }}>
        <Text sx={{ fontSize: 's2', fontWeight: 'bold' }}>
          {formatMessage({ id: 'feedback.emoji-general-feedback' })}
        </Text>
        <Text sx={{ fontWeight: 500, color: 'grey-400' }}>
          {formatMessage({ id: 'feedback.write-freely' })}
        </Text>
      </Flex>
    </Flex>
  )
}
