import { blog } from '@plco-pro/i18ns/blog'
import { dashboard } from '@plco-pro/i18ns/dashboard'
import { error } from '@plco-pro/i18ns/error'
import { insight } from '@plco-pro/i18ns/insight'
import { notable } from '@plco-pro/i18ns/notable'
import { plan } from '@plco-pro/i18ns/plan'
import { players } from '@plco-pro/i18ns/players'
import { profile } from '@plco-pro/i18ns/profile'
import { register } from '@plco-pro/i18ns/register'
import { teamChart } from '@plco-pro/i18ns/team-chart'
import { teamCode } from '@plco-pro/i18ns/team-code'
import { teams } from '@plco-pro/i18ns/teams'
import { toast } from '@plco-pro/i18ns/toast'

import { I18nMessagePack } from './i18n.en'

export const messages: I18nMessagePack = {
  language: '한국어',

  ...register.ko,
  ...teamChart.ko,
  ...dashboard.ko,
  ...error.ko,
  ...insight.ko,
  ...plan.ko,
  ...players.ko,
  ...profile.ko,
  ...teamCode.ko,
  ...teams.ko,
  ...toast.ko,
  ...blog.ko,
  ...notable.ko,

  'common.today': '오늘',

  // widget data type
  UNCHECKED: '미체크',
  HIGH: '높은',
  BAD: '나쁜',

  // index page
  'index.title':
    '플코 for Coach는 선수들의 컨디션, 운동 부하, 통증 및 부상 데이터를 모니터링 하고 관리하는 서비스 입니다.',

  // 403 page
  '403.title': '이런, 접근할 수 없는 페이지 입니다.',
  '403.subtitle.first': '죄송합니다. 이 페이지에 접근할 수 있는 권한이 없습니다.',
  '403.subtitle.second': '‘이전 페이지로 돌아가기’ 버튼을 누르세요.',
  '403.button': '이전 페이지로 돌아가기',

  // 404 page
  '404.title': '페이지가 존재하지 않습니다.',
  '404.subtitle': '다시 시작하려면 ‘홈으로 돌아가기’버튼을 눌러주세요.',
  '404.button': '홈으로 돌아가기',

  // internal server error page
  'internal-server-error.title': '서비스가 잠시 지연되고 있습니다.',
  'internal-server-error.subtitle':
    '문제를 해결하기 위해 열심히 노력하고 있습니다.\n블로그를 통해 스포츠 과학 정보를 둘러보거나 3-5분 뒤에 버튼을 눌러 다시 시도해 보세요.',
  'internal-server-error.button': '다시 시도해 보기',

  // team-new page
  'team-new.title': '환영합니다. {name}님!',
  'team-new.subtitle':
    '플코 for Coach는 선수들의 컨디션, 운동 부하, 통증 및 부상 데이터를 모니터링하고 관리하는 서비스입니다.',
  'team-new.create.title': '플코 for Coach에서 팀을 만들고 멤버들을 초대해 보세요!',
  'team-new.create.button': '팀 만들기',
  'team-new.join.title': '초대받은 팀이 있나요? 내가 받은 코드 입력하기.',
  'team-new.join.button': '코드 입력',

  // modal-team-create
  'modal-team-create.header.title': '팀 만들기',
  'modal-team-create.footer.left.button': '취소',
  'modal-team-create.footer.right.button': '팀 만들기',
  'modal-team-create.name.label': '팀 이름(필수)',
  'modal-team-create.name.placeholder': '팀 이름을 입력해주세요. (예: 플코 FC)',
  'modal-team-create.category.label': '종목(필수)',
  'modal-team-create.nationality.label': '국적(필수)',
  'modal-team-create.region.label': '국가(필수)',
  'modal-team-create.url.label': '팀 URL',
  'modal-team-create.url.placeholder': '영문, 숫자 가능',

  // modal-team-create-loading
  'modal-team-create-loading.loading.title': '멋진 팀을 만들고 있습니다...',
  'modal-team-create-loading.loading.subtitle': '잠시만 기다려 주세요.',
  'modal-team-create-loading.success.title': '팀만들기 성공!',
  'modal-team-create-loading.error.title': '팀만들기 실패',
  'modal-team-create-loading.error.subtitle': '다시 시도해 주세요.',

  // modal-team-join
  'modal-team-join.header.title': '팀 코드 입력',
  'modal-team-join.footer.left.button': '취소',
  'modal-team-join.footer.right.button': '확인',
  'modal-team-join.title': '영문, 숫자 6자리 조합으로 된 팀 코드를 입력한 후 확인을 클릭하세요.',
  'modal-team-join.code.placeholder': '팀 코드 입력',

  // modal-team-join-success
  'modal-team-join-success.footer.right.button': '확인',
  'modal-team-join-success.title': '{name} 연결 성공',
  'modal-team-join-success.subtitle': '지도자가 수락하면 팀연결이 완료돼요.',

  // modal-logout
  'modal-logout.title': '정말로 로그아웃 하시겠습니까?',

  // modal-leave
  'modal-leave.title': '정말로 팀에서 나가시겠습니까?',

  // modal-kickout
  'modal-kickout.title': '{name}님을 팀에서 내보내시겠습니까?',

  // modal-transfer
  'modal-transfer.title': '{userName}님에게 {teamName}에 대한 소유권 변경을 하시겠습니까?',

  // modal-check-condition-reminder
  'modal-check-condition-reminder.title.smOnly':
    '{count}명 선수들에게 컨디션 체크 알림을 \n 보내시겠습니까?',
  'modal-check-condition-reminder.title.mdAndUp':
    '{count}명 선수들에게 \n 컨디션 체크 알림을 보내시겠습니까?',

  // modal-check-workload-reminder
  'modal-check-workload-reminder.title.smOnly':
    '{count}명 선수들에게 운동 체크 알림을 \n 보내시겠습니까?',
  'modal-check-workload-reminder.title.mdAndUp':
    '{count}명 선수들에게 \n 운동 체크 알림을 보내시겠습니까?',

  // navbar-user-dropdown
  'navbar-user-dropdown.edit-profile': '프로필 수정',
  'navbar-user-dropdown.logout': '로그아웃',

  // navbar-team-dropdown
  'navbar-team-dropdown.team': '전체 멤버 {membersCount}명 · 선수 {playersCount}명',
  'navbar-team-dropdown.edit-team-profile': '팀 프로필 수정',
  'navbar-team-dropdown.team-management': '멤버 및 권한',
  'navbar-team-dropdown.team-invite': '팀으로 초대',
  'navbar-team-dropdown.team-list': '전체 팀 ({count})',
  'navbar-team-dropdown.create-team': '팀 만들기',
  'navbar-team-dropdown.payment': '플랜 & 결제',
  'navbar-team-dropdown.team-code.title': '초대 받은 팀이 있으신가요?',
  'navbar-team-dropdown.enter-team-code': '팀 코드 입력',

  // navbar-players
  'navbar-players.player-data': '선수 데이터',
  'navbar-players.back': '돌아가기',

  // main-drawer
  'main-drawer.item.dashboard': '대시보드',
  'main-drawer.item.team-chart': '팀 차트',
  'main-drawer.item.insight': '인사이트',
  'main-drawer.item.monitoring': '팀 모니터링',
  'main-drawer.item.player': '선수단',
  'main-drawer.item.scheduler': '일정',
  'main-drawer.item.blog': '블로그',
  'main-drawer.item.blog.chip': '무료체험 D-{leftDay}',

  // sub-drawer
  'sub-drawer.item.overview': '개요',

  // drawer-language
  'drawer-language.language': '언어',

  // drawer-footer
  'drawer-footer.copyright': '© plco for Coach',

  // drawer-players
  'drawer-players.search.placeholder': '선수 검색',
  'drawer-players.starred-players': '즐겨 찾는 선수 ({playersCount}명)',
  'drawer-players.players': '선수 ({playersCount}명)',

  // team-member
  'team-member.back': '돌아가기',
  'team-member.access.title': '멤버 및 권한',

  // dropdown-menu-team-member
  'dropdown-menu-team-member.leave': '팀 나가기',
  'dropdown-menu-team-member.kickout': '팀 내보내기',
  'dropdown-menu-team-member.transfer': '소유권 변경',

  // monitoring
  'monitoring.invite.pending.title':
    '관리자의 승인을 기다리는 중입니다. 승인을 마치면 다시 로그인 해보세요.',
  'monitoring-header.title': '팀 모니터링',
  'monitoring-header.update': '업데이트',
  'monitoring.modal.title': '팀으로 초대',
  'monitoring.modal.team.code': '팀 코드',
  'monitoring.modal.description.top': '누구에게 초대 메시지를 보내고 싶나요?',
  'monitoring.modal.description.bottom': '링크를 복사해서 전달해 보세요.',
  'monitoring.modal.coach': '지도자',
  'monitoring.modal.player': '선수',
  'monitoring.modal.clipboard.toasts.success': '팀 초대 메시지가 복사되었습니다.',
  'monitoring.modal.clipboard-team-code.toasts.success': '팀코드가 클립보드에 복사 되었습니다.',
  'monitoring.chart.title': '팀 차트',
  'monitoring.table.title': '팀 데이터',
  'monitoring.table.workout.memo-button': '운동 메모 목록',
  'monitoring.modal-workout-memo.title': '운동 메모 목록',
  'monitoring.modal-workout-memo.empty': '입력된 운동 메모가 없습니다.',
  'monitoring.table.noData': '아직 멤버가 없네요...',
  'monitoring.table.soreness.degree': '통증 {degree, selectordinal, other {#단계}}',
  'monitoring.table.data-sorting': '데이터 정렬',
  'monitoring.table.clear-sorting': '정렬 초기화',
  'monitoring.table.data': '데이터',
  'monitoring.table.sorting': '정렬',
  'monitoring.table.asc': '오름차순',
  'monitoring.table.desc': '내림차순',
  'monitoring.period-button.1_DAY': '1일',
  'monitoring.period-button.7_DAYS': '지난 7일',
  'monitoring.period-button.28_DAYS': '지난 28일',
  'monitoring.chart.tooltip.close': '닫기',
  'monitoring.chart.tooltip.another-team.title': '운동/훈련',
  'monitoring.chart.workload-count': '총 {count}회',

  // unauthorized
  'unauthorized.title': '이런, 아직 컨텐츠를 볼 수 없습니다.',
  'unauthorized.subtitle.first': '관리자가 승인할 때까지 잠시만 기다려주세요.',
  'unauthorized.subtitle.second': '승인이 완료 되면 새로고침(F5) 해보세요.',

  // calendar
  'calendar.navigationLabel.decade': '년도 선택',

  // group
  'group-monitoring.dropdal-player-select.subtitle': '{playersCount}명 선택됨',
  'group-monitoring.dropdal-player-select.placeholder.groups-and-players': '그룹/선수 검색',
  'group-monitoring.dropdal-player-select.placeholder.players': '선수 검색',
  'group-monitoring.dropdal-player-select.select-all': '전체 선택 ({playersCount}명)',

  'group-monitoring.group-button.create': '그룹 추가 ({count}/5)',
  'group-monitoring.modal-group.create.cancel': '그룹 추가를 취소하겠습니까?',
  'group-monitoring.modal-group.create.header': '그룹 추가',

  'group-monitoring.group-button.setting': '그룹 설정',
  'group-monitoring.modal-group.setting.header': '그룹 설정',

  'group-monitoring.modal-group.guide': '저장한 그룹 정보는 모든 지도자에게 동일하게 반영됩니다.',

  'group-monitoring.modal-group.modify.header': '그룹 수정',
  'group-monitoring.modal-group.setting.cancel': '그룹 수정을 취소하시겠습니까?',
  'group-monitoring.modal-group.setting.delete-button': '이 그룹 삭제하기',

  'group-monitoring.modal-group.input.label': '그룹 이름',
  'group-monitoring.modal-group.input.placeholder': '그룹 이름을 만들어 주세요.(최대 10자)',

  'group-monitoring.modal-group.group-list.header.title': '그룹 선택',

  'group-monitoring.modal-group.member-list.header.title': '멤버 선택',
  'group-monitoring.modal-group.member-list.header.subtitle': '그룹에 들어갈 멤버를 선택해 주세요',
  'group-monitoring.modal-group.member-list.placeholder': '선수 검색',
  'group-monitoring.modal-group.member-list.select-all': '전체 선택 ({playersCount}명)',

  'group-monitoring.modal-group-delete.text': '정말로 그룹을 삭제하시겠습니까?',

  // scheduler
  'scheduler.header.title': '일정',
  'scheduler.header.button.create-event': '일정 추가',
  'scheduler.days.date.event.more': '개 더보기···',
  'scheduler.days.date.event.count': '개',
  'scheduler.modal-event.create.header': '일정 추가',
  'scheduler.modal-event.duplicate.header': '일정 복제',
  'scheduler.modal-event.edit.header': '일정 수정',
  'scheduler.modal-event.event-name.field-input.label': '일정 이름',
  'scheduler.modal-event.event-name.field-input.placeholder': '일정 이름을 지어주세요. (50자 이내)',
  'scheduler.modal-event.event-location.field-input.label': '위치',
  'scheduler.modal-event.event-location.field-input.placeholder': '위치를 입력하세요.',
  'scheduler.modal-event.event-date.field-input-date.start.label': '시작',
  'scheduler.modal-event.event-date.field-input-date.end.label': '종료',
  'scheduler.modal-event.expected-intensity.label': '목표 강도',
  'scheduler.modal-event.expected-intensity.1': '아주 가벼운 정도',
  'scheduler.modal-event.expected-intensity.2': '대화가 가능한 정도의 저강도',
  'scheduler.modal-event.expected-intensity.3': '대화가 가능한 정도의 저강도',
  'scheduler.modal-event.expected-intensity.4': '호흡이 적당히 차는 중강도',
  'scheduler.modal-event.expected-intensity.5': '호흡이 적당히 차는 중강도',
  'scheduler.modal-event.expected-intensity.6': '호흡이 적당히 차는 중강도',
  'scheduler.modal-event.expected-intensity.7': '호흡이 비교적 어려운 고강도',
  'scheduler.modal-event.expected-intensity.8': '호흡이 비교적 어려운 고강도',
  'scheduler.modal-event.expected-intensity.9': '호흡이 비교적 어려운 고강도',
  'scheduler.modal-event.expected-intensity.10': '대화가 불가능한 상태의 최대 강도',
  'scheduler.modal-event.participant.label': '참가 대상',
  'scheduler.modal-event.participant.count': '{playersCount}명 선택됨',
  'scheduler.modal-event.training.textarea.label': '훈련 공지',
  'scheduler.modal-event.training.textarea.placeholder':
    '일정을 자세하게 입력하거나 선수들에게 공지하고 싶은 내용을 적어보세요.\n선수들에게 내용이 공유됩니다.\n예시) 오늘의 훈련 프로그램: 웜업 - 5:2 - 슛팅 - 1:1 패스',
  'scheduler.modal-event.textarea.placeholder': '일정을 자세하게 입력하세요.',

  'scheduler.modal-event.caution.expected-intensity':
    '목표 강도, 참가 대상, 메모는 관리자만 볼 수 있습니다.',
  'scheduler.modal-event.caution': '참가 대상, 메모는 관리자만 볼 수 있습니다.',

  'scheduler.modal-event.more-items.duplicate': '일정 복제',
  'scheduler.modal-event.more-items.modify': '일정 수정',
  'scheduler.modal-event.more-items.delete': '일정 삭제',

  'scheduler.modal-event.create.cancel.title': '정말로 일정 만들기를 취소하시겠습니까?',
  'scheduler.modal-event.duplicate.cancel.title': '정말로 일정 복제를 취소하시겠습니까?',
  'scheduler.modal-event.modify.cancel.title': '정말로 일정 수정을 취소하시겠습니까?',
  'scheduler.modal-event.delete.cancel.title': '정말로 일정을 삭제하시겠습니까?',

  'scheduler.overlay.participants': '참가대상 ({participants}명)',
  'scheduler.overlay.participants.more': '+ 더보기...',
  'scheduler.overlay.notice-target': '공지 대상 ({participants})',

  'scheduler.overlay.participants.total': '전체인원 ({total})',
  'scheduler.overlay.participants.notification': '참가여부 미체크 알림 보내기',
  'scheduler.overlay.all': '전체',
  'scheduler.overlay.attend': '참가',
  'scheduler.overlay.absent': '불참',
  'scheduler.overlay.injured': '부상',
  'scheduler.overlay.etc': '기타',
  'scheduler.overlay.unchecked': '미체크',
  'scheduler.button.training-report': '훈련 리포트 보기',
  'scheduler.modal-participant-notification.title':
    '{participants}명의 선수에게\n훈련 참가여부 알림을 보낼까요?',
  'scheduler.modal-event.memo.guide':
    '목표강도/참가대상은 선수들이 볼 수 없지만, 훈련 공지는 선수들에게 공유됩니다.',

  'scheduler.modal-event.match-opponent.field-input.label': '경기 상대',
  'scheduler.modal-event.match-opponent.field-input.placeholder': '상대팀 또는 선수를 입력하세요.',
  'scheduler.modal-event.match-competition.field-input.label': '대회',
  'scheduler.modal-event.match-competition.field-input.placeholder': '리그/대회명 등을 입력하세요.',
  'scheduler.modal-event.match-location.field-input.label': '장소',
  'scheduler.modal-event.match-location.field-input.placeholder': '경기 장소를 입력하세요.',
  'scheduler.modal-event.match-event.field-input.label': '일정',
  'scheduler.modal-event.match-venue.etc.button': '기타',
  'scheduler.modal-event.match-notice-target.field-input.label': '공지 대상',
  'scheduler.modal-event.match-notice-target.field-input.guide':
    '공지 대상에 포함된 선수를 출전 선수 명단에 등록할 수 있습니다.',
  'scheduler.modal-event.match-notice.field-input.label': '공지',
  'scheduler.modal-event.match-notice.textarea.label': '경기 공지',
  'scheduler.modal-event.match-notice.textarea.placeholder':
    '경기와 관련하여 선수들에게 공지가 필요한 내용을 적어보세요.\n선수들에게 내용이 공유됩니다.',
  'scheduler.modal-event.match-notice.textarea.guide':
    '2023년 10월 10일 이전에 저장한 경기 일정 메모는 공개되지 않으니 걱정마세요.',
  'scheduler.button.match-report': '경기 리포트 보기',
  'scheduler.button.practice-match-report': '연습경기 리포트 보기',

  // match report
  'match-report.notice': '공지',
  'match-report.result': '경기 결과',
  'match-report.result-status.win': '승',
  'match-report.result-status.draw': '무',
  'match-report.result-status.lose': '패',
  'match-report.result-textarea.placeholder':
    '경기 주요 기록, 코멘트, 전술 등을 자유롭게 기록해보세요.',
  'match-report.lineup-and-record-card.title': '출전 선수 명단 및 기록',
  'match-report.lineup-and-record-card-empty.title': '등록된 출전 선수 없음',
  'match-report.lineup-and-record-card-empty.subtitle':
    '출전 선수 등록 버튼을 눌러 명단을 등록해주세요',
  'match-report.lineup-and-record-card.table-label.part': '출전',
  'match-report.lineup-and-record-card.table-label.playing-time': '출전 시간',
  'match-report.lineup-and-record-card.table-label.record-and-comment': '선수 기록 및 평가',
  'match-report.lineup-and-record-card.modify-button.help':
    '여기를 눌러 출전 선수 명단을 수정할 수 있어요',
  'match-report.lineup-register-modal.title': '출전 선수 등록',
  'match-report.lineup-register-modal.search-input.placeholder': '선수 검색',
  'match-report.lineup-register-modal.guide': '공지 대상으로 선택한 선수가 리스트에 표시됩니다.',
  'match-report.lineup-register-modal.player-status.starting': '선발',
  'match-report.lineup-register-modal.player-status.sub': '교체',
  'match-report.lineup-register-modal.player-status.no-play': '미출전',
  'match-report.lineup-register-modal.empty':
    '표시할 선수가 없습니다.\n일정 수정에서 공지 대상을 먼저 선택해주세요.',
  'match-report.lineup-register-modal.no-result': '검색 결과가 없습니다.',
  'match-report.lineup-data-card.title': '출전 선수 데이터',
  'match-report.edit': '편집 중',
  'match-report.event-list.title': '공지 대상 ({count}명)',
  'match-report.all.player-list.empty.title': '선택된 선수 없음',
  'match-report.all.player-list.empty.subTitle': '공지 대상으로 선택된 선수들이 없어요.',

  // training report
  'training-report.location': '위치',
  'training-report.date': '날짜',
  'training-report.time': '시간',
  'training-report.notice': '공지',

  'training-report.attendance.percent': '참가율',

  'training-report.player-list.help': '숫자를 누르고 각 명단을 확인해보세요',

  'training-report.attendance.workload.data.title': '참가선수 운동부하 데이터',
  'training-report.attendance.workload.data.list': '리스트',
  'training-report.attendance.workload.data.chart': '차트',

  'training-report.all': '전체',
  'training-report.attend': '참가',
  'training-report.injured': '부상',
  'training-report.etc': '기타',
  'training-report.unchecked': '미체크',

  'training-report.all.player-list.empty.title': '선택된 선수 없음',
  'training-report.all.player-list.empty.subTitle': '참가 대상으로 선택된 선수들이 없어요.',

  'training-report.attend.player-list.empty.title': '참가 선수 없음',
  'training-report.attend.player-list.empty.subTitle': "아직 ‘참가'에 체크한 선수들이 없어요.",

  'training-report.injured.player-list.empty.title': '부상(불참) 선수 없음',
  'training-report.injured.player-list.empty.subTitle': '부상으로 불참하는 선수가 없어요 👍',

  'training-report.etc.player-list.empty.title': '기타(불참) 선수 없음',
  'training-report.etc.player-list.empty.subTitle': '개인사정으로 불참하는 선수가 없어요 👍',

  'training-report.unchecked.player-list.empty.title': '미체크 선수 없음',
  'training-report.unchecked.player-list.empty.subTitle': '모든 선수들이 참가/불참을 체크했어요 👍',

  'training-report.attendance.workload.data.empty': '아직 참가를 체크한 선수가 없네요...',

  // tutorial
  'tutorial.end': '알겠어요',
  'tutorial.step.0.title': '멤버들을 초대하세요. 👋',
  'tutorial.step.0.content':
    '플코 for Coach는 데이터 모니터링을 통해 멤버들의 상태를 파악하고 올바른 코칭을 할 수 있는 서비스입니다. ‘팀 코드로 초대’를 눌러 멤버들을 초대해 보세요.',
  'tutorial.step.0.searchTerm': '‘팀 코드로 초대’',
  'tutorial.step.1.title': '팀코드와 초대 메시지를 복사하세요. 👍',
  'tutorial.step.1.content':
    '팀코드와 초대 메시지를 복사하고 초대할 멤버들에게 메신저나 문자 메시지를 통해 팀 코드를 전달하세요.',
  'tutorial.step.1.searchTerm': '팀코드와 초대 메시지를 복사',
  'tutorial.step.2.title': ' ',
  'tutorial.step.2.content': ' ',
  'tutorial.step.2.searchTerm': ' ',
  'tutorial.step.3.title': '초대한 멤버들을 멤버 및 권한에서 확인하세요. 🙋🏻‍♂️',
  'tutorial.step.3.content': '‘멤버 및 권한’를 눌러서 어떤 멤버들이 있는지 확인해 보세요.',
  'tutorial.step.3.searchTerm': '‘멤버 및 권한’',
  'tutorial.step.4.title': '초대한 멤버들을 멤버 및 권한에서 확인하세요. 🙋🏻‍♂️',
  'tutorial.step.4.content': '‘멤버 및 권한’를 눌러서 어떤 멤버들이 있는지 확인해 보세요.',
  'tutorial.step.4.searchTerm': '‘멤버 및 권한’',
  'tutorial.step.5.title': '선수 요청을 수락해서 팀에 합류시켜 보세요. ✅',
  'tutorial.step.5.content':
    '지금은 아무도 없기 때문에 가상의 선수를 미리 추가해놨어요. 간혹 내가 초대하지 않은 멤버가 들어올 수 있어요. 그럴 때는 거절을 누르세요. 그러면 해당 선수는 팀에 합류할 수 없어요.',
  'tutorial.step.5.searchTerm': '가상의 선수를 미리 추가',
  'tutorial.step.6.title': '권한을 설정하세요. 🤝',
  'tutorial.step.6.content':
    "요청을 수락하게 되면 처음은 모두 멤버(선수) 권한을 가지게 됩니다. 요청한 사람이 코치 또는 감독이라면 꼭 '관리자'로 변경하셔야 합니다. 권한을 올바르게 선택해 보세요.",
  'tutorial.step.6.searchTerm': '처음은 모두 멤버(선수) 권한',
  'tutorial.step.6.subContent':
    '소유자: 팀을 만든 사람입니다. 멤버들의 그룹을 설정할 수 있습니다. 일정을 생성하고, 수정, 삭제 할 수 있습니다. 필요하면 팀을 삭제 할 수 있습니다.\n\n관리자(코치 또는 감독): 멤버의 데이터를 모니터링 할 수 있습니다. 일정을 생성하고 수정, 삭제할 수 있습니다.\n\n멤버(선수): 팀에 속하는 선수입니다.',
  'tutorial.step.7.title': '멤버를 팀에서 내보내 보세요. ➡️',
  'tutorial.step.7.content':
    '이제 가상의 선수는 할 일을 다했습니다. ‘팀 내보내기’를 눌러 팀에서 내보내 보세요.',
  'tutorial.step.7.searchTerm': '‘팀 내보내기’',
  'tutorial.step.8.title': ' ',
  'tutorial.step.8.content': ' ',
  'tutorial.step.8.searchTerm': ' ',
  'tutorial.step.9.title': '멤버 초대 안내 완료 🙌',
  'tutorial.step.9.content':
    '잘하셨습니다. 이제 실제 멤버들을 초대하세요. 요청을 수락하고 권한을 변경하세요. 그리고 데이터를 모니터링하세요.',
  'tutorial.step.9.searchTerm': '이제 실제 멤버들을 초대하세요.',
  'tutorial.restart.title': '팀 멤버 초대 방법을 알고 싶나요? 🙌',
  'tutorial.restart.subTitle': '튜토리얼을 통해 멤버 초대 방법에 대해서 알아보세요.',
  'tutorial.virtualMember.name': '(가상) 김선수',
  'tutorial.virtualMember.sportName': '축구',
  'tutorial.virtualMember.sportPosition': '미드필더',
  'tutorial.virtualMember.sportRole': '선수',

  // banner-subscribe
  'banner-subscribe.title.first': '플랜 구독하고',
  'banner-subscribe.title.second': '모니터링을 계속해 보세요',
  'banner-subscribe.action': '플랜 구독하기',

  // banner-update
  'banner-update.title': '혹시 달라진 점을 눈치채셨나요?',
  'banner-update.subtext': '지금 바로 ‘업데이트 소식’을\n확인하세요!',

  // card
  'card-teams-invite.title': '팀으로 초대',
  'card-teams-invite.content': '팀 멤버를 초대하세요',
  'card-teams-invite.instruction': '상단 왼쪽에 {place}에서 언제든 멤버를 초대할 수 있어요.',
  'card-teams-invite.instruction.place': "팀 메뉴>'팀으로 초대'",

  // info tooltip
  'info-tooltip.player-detail': '이제 선수의 개인 데이터를 확인할 수 있어요!',
  'info-tooltip.transfer-ownership': '팀에 소유자로 권한이 변경 됐습니다!',

  // field-input
  'field-input.label.name': '이름',
  'field-input.label.nationality': '국적',
  'field-input.label.gender': '성별',
  'field-input.label.birthdate': '생년월일',
  'field-input.label.phone-number': '휴대폰 번호',
  'field-input.label.role': '역할',
  'field-input.label.position': '직책',
  'field-input.label.sportsCategory': '종목',
  'field-input.name.placeholder': '이름 입력',
  'field-input.birthdate.placeholder': '생년월일 입력(예:1994-05-01)',
  'field-input.phone-number.placeholder': '휴대폰 번호 입력 (예: 010-1234-5678)',
  'field-input.username.placeholder': 'ID를 입력',

  // chart data
  'chart.data.not.checked': '체크안함',
  'chart.data.none': '없음',

  // report
  'report.exception.mobile': '리포트는\nPC 또는 태블릿에서 확인해주세요.',
  'report.exception.authority.title': '이 리포트를 볼 수 없어요',
  'report.exception.authority.subtext':
    '우리 팀의 일정이 맞나요?\n다른 팀의 일정이거나, 이미 삭제된 일정은 아닌지 확인해보세요.',

  // match report
  'match-report.modal.header': '경기 리포트',

  // practice-match report
  'practice-match-report.modal.header': '연습경기 리포트', // '연습경기 리포트',

  // training report
  'training-report.modal.header': '훈련 리포트',
  'training-report.workload-intensity-compare.title': '운동강도 비교',
  'training-report.workload-intensity-checked':
    '참가선수 {totalCount}명 중 {checkedCount}명 운동부하 체크 완료',
  'training-report.aim-intesity-ballon': '목표강도',
  'training-report.aim-intensity.title': '목표강도',
  'training-report.real-intensity.title': '실제강도',
  'training-report.real-intensity.label': '팀 평균*',
  'training-report.real-intensity.empty': '운동부하 체크 대기 중',
  'training-report.training-point.title': '훈련 포인트',
  'training-report.training-point.placeholder':
    '훈련의 목표와 목적, 실제 훈련 내용과 결과 등을 기록해보세요.\n\n예시) 경기 전 훈련이기에 강도 낮게 진행함',
  'training-report.special-notes.title': '특이사항',
  'training-report.special-notes.placeholder':
    '훈련 중 있던 특이사항, 느낀점 등을 기록해보세요.\n\n예시) 전날 고강도 훈련 때문인지 선수들이 쳐지는 경향을 보임',

  // export report
  'report.export.help': '리포트를 PDF/이미지로 저장할 수 있어요',
  'report.export-modal.title': '리포트 내보내기',
  'report.export-modal.save-pdf.button': 'PDF로 저장',
  'report.export-modal.save-img.button': '이미지로 저장',
  'report.export-modal.save.loading.text': '저장 중',
  'report.export-modal.save.success.text': '저장 성공',
  'report.export-modal.save.failed.text': '저장 실패',

  // player injury modal
  'player.modal-injury.register.header': '부상 등록',
  'player.modal-injury.edit.header': '부상 수정',
  'player.modal-injury.detail.header': '부상 상세보기',
  'player.modal-injury.injury-dummy.front': '앞',
  'player.modal-injury.injury-dummy.back': '뒤',
  'player.modal-injury.selection.part.text': '부상 부위 선택',
  'player.modal-injury.selection.part.none.subtext': '선택된 부위 없음',
  'player.modal-injury.selection.part.guide': '마네킹을 눌러 부상 부위를 선택해주세요',
  'player.modal-injury.detail-selection.header': '상세 정보 입력',
  'player.modal-injury.detail-selection.date.title': '부상 날짜',
  'player.modal-injury.detail-selection.relapse.text': '재발',
  'player.modal-injury.detail-selection.cause.title': '원인 및 증상',
  'player.modal-injury.detail-selection.cause.placeholder':
    '부상이 발생한 배경, 상황, 증상 등을 입력하세요.',
  'player.modal-injury.detail-selection.diagnosis.title': '진단명',
  'player.modal-injury.detail-selection.diagnosis.placeholder': '진단명을 입력하세요.',
  'player.modal-injury.detail-selection.medical-record.title': '진료 기록',
  'player.modal-injury.detail-selection.medical-record.placeholder':
    '진료일, 병원, 진단 방법 등을 입력하세요.',
  'player.modal-injury.detail-selection.return-date.title': '복귀 날짜',
  'player.modal-injury.detail-selection.return-date.undecide': '미정',
  'player.modal-injury.detail-selection.return-info.title': '복귀 정보',
  'player.modal-injury.detail-selection.return-info.placeholder':
    '재활 기간, 복귀 예상 시기 등을 입력하세요.',
  'player.modal-injury.detail-selection.injury-ended.title': '부상 종료',
  'player.modal-injury.detail-selection.injury-ended.subtitle':
    '이 부상을 과거 부상으로 저장합니다',
  'player.modal-injury.detail-selection.file.button': '파일 첨부',
  'player.modal-injury.detail-selection.file.text':
    '관련 사진이나 문서를 첨부할 수 있어요\n*10MB 이내의 파일 최대 3개',
  'player.modal-injury.register-cancel.title': '정말로 부상 등록을 취소하시겠습니까?',
  'player.modal-injury.update-cancel.title': '정말로 부상 수정을 취소하시겠습니까?',
  'player.modal-injury.delete.title': '정말로 부상을 삭제하시겠습니까?',

  'menu.chat-support': '채팅 문의',
  'menu.lanuage': '언어 변경',
  'menu.notification': '알림',
  'menu.my-profile': '내 프로필',

  'fab.send-feedback': '피드백 등록',
  'fab.select-feedback-player': '피드백 선수를 선택하세요',
  'fab.search-players': '선수 검색',
  'fab.select': '선택',
  'fab.no-results-found': '검색 결과가 없습니다.',
  'fab.cancel': '취소',
  'fab.next': '다음',

  'notification.updates': '업데이트',
  'notification.notable-players': '주요 선수',
  'notification.make-all-as-read': '모두 읽음으로 처리하기',
  'notification.COMMENT_CREATED': '{user}님이 {player}님의 {title} <b>피드백</b>에 댓글을 남겼어요',
  'notification.COMMENT_CREATED_NO_FEEDBACK': '{user}님이 {player}님의 {title}에 댓글을 남겼어요',
  'notification.FEEDBACK_CREATED': '{author}님이 {player}님에게 {title} <b>피드백</b>을 보냈어요',
  'notification.FEEDBACK_CREATED_NO_FEEDBACK': '{author}님이 {player}님에게 {title}을 보냈어요',
  'notification.FEEDBACK_REQUESTED': '{player}님에게 새로운 <b>피드백 요청</b>을 받았어요',
  'notification.feedback-deleted': '이 피드백은 삭제되었습니다',
  'notification.feedback-request-deleted': '이 피드백 요청은 삭제되었습니다',
  'notification.comment-deleted': '이 댓글은 삭제되었습니다',
  'notification.no-update-notifications': '업데이트 알림이 없어요',
  'notification.notify-request-or-comments':
    '새로운 피드백 요청이나 댓글 등\n확인해야할 내용이 생기면 알려드릴게요',
  'notification.general': '일반',
  'notification.format.a-week-ago': '일주일 전',
  'notification.format.today': 'HH:mm',

  feedback: '피드백',
  'feedback.no-feedback-yet': '아직 피드백이 없어요',
  'feedback.send-feedback': '피드백 등록',
  'feedback.choose-feedback': '{player}님에게\n어떤 피드백을 보낼까요?',
  'feedback.suggestion-description':
    '자유롭게 작성하거나 플코가 드리는 제안에서 골라보세요.\n제안은 항상 최신화되고 있어요.',
  'feedback.general-feedback': '일반 피드백',
  'feedback.emoji-general-feedback': '🙂 일반 피드백',
  'feedback.write-freely': '자유롭게 작성하기',
  'feedback.plco-suggestions': '✨플코 제안',
  'feedback.tag.NORMAL': '일반 피드백',
  'feedback.tag.REQUEST': '피드백 요청',
  'feedback.tag.TRAINING': '훈련 메모',
  'feedback.tag.MATCH': '경기 메모',
  'feedback.tag.PRACTICE': '연습경기 메모',
  'feedback.tag.WORKLOAD_MEMO': '운동 메모',
  'feedback.tag.WORKLOAD_RISK_AT_INJURY': '운동부하',
  'feedback.tag.CONDITION_NOT_GOOD': '컨디션',
  'feedback.tag.CONDITION_PLUMMET': '컨디션',
  'feedback.tag.SORENESS_SEVERE': '통증',
  'feedback.type.normal-workout': '일반 운동',
  'feedback.type.RISK_INCREASED': '부상 위험 증가',
  'feedback.type.RISK_CAUTION': '부상 위험 유의',
  'feedback.type.RISK_DETECTED': '부상 위험 감지',
  'feedback.type.severe-soreness': '심각한 통증',
  'feedback.type.bad-condition': '안 좋은 컨디션',
  'feedback.type.plummeted-condition': '급락한 컨디션',
  'feedback.back': '이전',
  'feedback.next': '다음',
  'feedback.save': '저장',
  'feedback.no-specific-suggestions': '제안드릴 특이사항이 없어요',
  'feedback.enter-your-feedback': '피드백을 입력하세요',
  'feedback.data-for-date': 'yyyy년 M월 D일 데이터',
  'feedback.injury.reason.HIGH_ACWR': '체력 대비 피로도 높음',
  'feedback.injury.reason.LOW_ACWR': '체력 대비 훈련량 부족',
  'feedback.injury.reason.WEEKLY_WORKLOAD_SURGE': '전주 대비 주간 운동부하 급증',
  'feedback.injury.reason.STRAIN': '신체적, 심리적인 훈련 부담감',
  'feedback.injury.reason.HIGH_WEEKLY_WORKLOAD': '높은 주간 운동부하',
  'feedback.injury.reason.MONOTONY': '단조로운 운동',
  'feedback.injury.reason.NON_ELASTIC_INTENSITY': '비탄력적 운동강도',
  'feedback.player-status-description':
    '{player}님은 {cause} 외 {count}개의 원인으로 인해 {status} 상태입니다.',
  'feedback.plco-suggestion': '플코의 제안',
  'feedback.condition-type.FATIGUE': '피로도',
  'feedback.condition-type.STRESS': '스트레스',
  'feedback.condition-type.MUSCLE': '근육',
  'feedback.condition-type.MOOD': '기분',
  'feedback.condition-type.SLEEP_DURATION': '수면시간',
  'feedback.condition-type.SLEEP_QUALITY': '수면품질',
  'feedback.bad-condition-description':
    '{player}님의 {conditionList} 항목이 4점 이하로 좋지 않습니다.',
  'feedback.decline-condition':
    '{player}님의 {conditionList} 항목이 최근 28일 추이 대비 급락했습니다.',
  'feedback.soreness-bodypart':
    '{player}님의 {body_part}에 {level}단계 {type, select, CONTACT {접촉성} NON_CONTACT {비접촉성} other {}} 통증이 발생했습니다.',
  'feedback.level': '{level}단계',
  'feedback.sure-cancel-sending-feedback': '정말로 피드백 등록을 취소하시겠습니까?',
  'feedback.no': '아니요',
  'feedback.yes': '예',
  'feedback.view-feedback-details': '피드백 상세보기',
  'feedback.comments': '댓글',
  'feedback.add-comment-this-feedback': '이 피드백에 댓글 달기',
  'feedback.submit': '등록',
  'feedback.editing': '수정 중',
  'feedback.sure-delete-feedback': '정말로 피드백을 삭제하시겠습니까?',
  'feedback.player-no-longer-view': '선수도 더 이상 피드백을 볼 수 없어요',
  'feedback.want-delete-comment': '댓글을 삭제하시겠습니까?',
  'feedback.edited': '(수정됨)',
  'feedback.authors-feedback': '{author}님의 피드백',
  'feedback.no-comments-yet': '아직 댓글이 없습니다',
  'feedback.request-from-player': '{player}님이 보낸 요청입니다',
  'feedback.feedback-request': '피드백 요청',
  'feedback.feedback-has-deleted': '피드백을 삭제했습니다',
  'feedback.feedback-has-edited': '피드백을 수정했습니다',
  'feedback.org-license-description': 'ORG 라이센스 관계자는\n피드백 기능을 이용할 수 없습니다',
  'feedback.withdrew-user': '탈퇴한 유저',

  'workout-duration.edit': '운동시간 편집',
  'workout-duration.helper-text': '코치가 편집한 운동시간 데이터는 파란색으로 표시됩니다',
  'workout-duration.edit-description':
    '일정 날짜 기준 7일 동안 편집할 수 있고, 선수에게 알림이 전송됩니다.\n데이터 원본이 편집되므로 각별히 유의해 주세요.',
  'workout-duration.select-players': '선수 선택*',
  'workout-duration.starts-ends': '운동 시작~종료*',
  'workout-duration.time-format-hours-min': '⏱️ HH:mm ~ HH:mm ({hours}시간 {min}분)',
  'workout-duration.time-format-hours': '⏱️ HH:mm ~ HH:mm ({hours}시간)',
  'workout-duration.time-format-min': '⏱️ HH:mm ~ HH:mm ({min}분)',
  'workout-duration.invalid-duration': '⏱️ 시간을 올바르게 입력해 주세요',
  'workout-duration.designated-time': '지정한 시간',
  'workout-duration.cancel': '취소',
  'workout-duration.save': '저장',
  'workout-duration.select': '선택',
  'workout-duration.select-all': '전체 선택 ({count}명)',
  'workout-duration.hh': 'HH시',
  'workout-duration.mm': 'mm분',
  'workout-duration.edited': '운동시간을 편집했습니다',
}

export default messages
