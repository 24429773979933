import { theme } from '@plco-pro/themes/main'

// colors
export const colorScale = Object.values(theme.colors)

// layout
export const baseProps = {
  width: 800,
  height: 300,
  padding: 64,
  portalZIndex: 1,
  colorScale,
}

// label
export const baseLabelStyle = {
  fontFamily: theme.fonts.label,
  fontWeight: theme.fontWeights.regular,
  letterSpacing: theme.letterSpacings.label,
  fontSize: 12,
  fill: theme.colors['text-secondary'],
  stroke: 'transparent',
  padding: 8,
}

// stroke
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

export const chartTheme = {
  area: {
    ...{
      style: {
        data: {
          fill: theme.colors['primary'],
        },
        labels: baseLabelStyle,
      },
    },
    ...baseProps,
  },
  dependentAxis: {
    ...{
      style: {
        axis: {
          fill: 'none',
          stroke: 'none',
        },
        axisLabel: {
          ...baseLabelStyle,
          ...{ padding: 12 },
        },
        grid: {
          fill: 'none',
          stroke: theme.colors['grey-100'],
          strokeLinecap,
          strokeLinejoin,
          pointerEvents: 'painted',
        },
        ticks: {
          size: 4,
          fill: 'none',
          stroke: 'none',
        },
        tickLabels: baseLabelStyle,
      },
    },
  },
  independentAxis: {
    ...{
      style: {
        axis: {
          fill: 'transparent',
          stroke: theme.colors['grey-100'],
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin,
        },
        axisLabel: {
          ...baseLabelStyle,
          ...{ padding: 12 },
        },
        grid: {
          fill: 'none',
          stroke: 'none',
          pointerEvents: 'painted',
        },
        ticks: {
          size: 4,
          fill: 'none',
          stroke: 'none',
        },
        tickLabels: baseLabelStyle,
      },
    },
  },
  bar: {
    ...{
      style: {
        data: {
          fill: theme.colors['primary'],
          strokeWidth: 0,
          strokeLinecap,
          strokeLinejoin,
        },
        labels: baseLabelStyle,
      },
    },
    ...baseProps,
  },
  boxplot: {
    ...{
      style: {
        max: { padding: 8, stroke: theme.colors['text-primary'], strokeWidth: 1 },
        maxLabels: { ...baseLabelStyle, ...{ padding: 3 } },
        median: { padding: 8, stroke: theme.colors['text-primary'], strokeWidth: 1 },
        medianLabels: { ...baseLabelStyle, ...{ padding: 3 } },
        min: { padding: 8, stroke: theme.colors['text-primary'], strokeWidth: 1 },
        minLabels: { ...baseLabelStyle, ...{ padding: 3 } },
        q1: { padding: 8, fill: theme.colors['text-secondary'] },
        q1Labels: { ...baseLabelStyle, ...{ padding: 3 } },
        q3: { padding: 8, fill: theme.colors['text-secondary'] },
        q3Labels: { ...baseLabelStyle, ...{ padding: 3 } },
      },
      boxWidth: 20,
    },
    ...baseProps,
  },
  candlestick: {
    ...{
      style: {
        data: {
          stroke: theme.colors['text-primary'],
          strokeWidth: 1,
        },
        labels: { ...baseLabelStyle, ...{ padding: 5 } },
      },
      candleColors: {
        positive: '#ffffff',
        negative: theme.colors['text-primary'],
      },
    },
    ...baseProps,
  },
  chart: baseProps,
  errorbar: {
    ...{
      borderWidth: 8,
      style: {
        data: {
          fill: 'transparent',
          stroke: theme.colors['text-primary'],
          strokeWidth: 2,
        },
        labels: baseLabelStyle,
      },
    },
    ...baseProps,
  },
  group: {
    ...{
      colorScale,
    },
    ...baseProps,
  },
  histogram: {
    ...{
      style: {
        data: {
          fill: theme.colors['text-secondary'],
          stroke: theme.colors['text-primary'],
          strokeWidth: 2,
        },
        labels: baseLabelStyle,
      },
    },
    ...baseProps,
  },
  legend: {
    colorScale,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: baseLabelStyle,
      title: { ...baseLabelStyle, ...{ padding: 5 } },
    },
  },
  line: {
    ...{
      style: {
        data: {
          fill: 'transparent',
          stroke: theme.colors['text-primary'],
          strokeWidth: 2,
        },
        labels: baseLabelStyle,
      },
    },
    ...baseProps,
  },
  lineAverage: {
    ...{
      style: {
        data: {
          fill: 'transparent',
          stroke: theme.colors['text-secondary'],
          strokeWidth: 2,
          strokeDasharray: '4,4',
        },
        labels: baseLabelStyle,
      },
    },
    ...baseProps,
  },
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: 'transparent',
        strokeWidth: 1,
      },
      labels: { ...baseLabelStyle, ...{ padding: 20 } },
    },
    colorScale,
    width: 400,
    height: 400,
    padding: 50,
  },
  scatter: {
    ...{
      style: {
        data: {
          fill: theme.colors['text-primary'],
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: baseLabelStyle,
      },
    },
    ...baseProps,
  },
  stack: {
    ...{
      colorScale,
    },
    ...baseProps,
  },
  tooltip: {
    style: { ...baseLabelStyle, ...{ padding: 0, pointerEvents: 'none' } },
    flyoutStyle: {
      stroke: theme.colors['text-primary'],
      strokeWidth: 1,
      fill: '#f0f0f0',
      pointerEvents: 'none',
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: {
    ...{
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: { ...baseLabelStyle, ...{ padding: 5, pointerEvents: 'none' } },
        flyout: {
          stroke: theme.colors['text-primary'],
          strokeWidth: 1,
          fill: '#f0f0f0',
          pointerEvents: 'none',
        },
      },
    },
    baseProps,
  },
}
