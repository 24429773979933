import { moment } from '@common/utils/libs'
import { types } from 'mobx-state-tree'

import { SEND_CHECK_REMINDER_LOCK_SECONDS } from './team-chart-store'

// for nav header hide tooltip in event page
export const EventStore = types
  .model('Event', {
    //  update time
    updatedAt: types.optional(types.Date, new Date()),

    // training participants
    checkParticipantsReminderSentAt: types.optional(types.maybeNull(types.Date), null),
  })
  .actions((self) => {
    return {
      setUpdatedAt(value: Date) {
        self.updatedAt = value
      },

      // training participants
      setCheckParticipantsReminderSentAt(value: Date) {
        self.checkParticipantsReminderSentAt = value
      },
    }
  })
  .views((self) => {
    return {
      get updatedDate() {
        return self.updatedAt
      },

      canSendCheckParticipantsReminder() {
        if (!self.checkParticipantsReminderSentAt) return true

        const differenceInSeconds = moment().diff(
          moment(self.checkParticipantsReminderSentAt),
          'seconds',
        )

        return differenceInSeconds >= SEND_CHECK_REMINDER_LOCK_SECONDS
      },
    }
  })
