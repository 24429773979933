import React, { useMemo } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Button, ButtonProps } from '@plco-pro/components/atoms/button'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useResponsive } from '@plco-pro/hooks/responsive'

export type ModalFooterProps = {
  leftButtonProps?: ButtonProps
  rightButtonProps?: ButtonProps
  isFullScreen?: boolean
  children?: React.ReactNode
} & FlexProps

const FooterBox = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      flex: '0 1 auto',
      px: [2, 3],
      py: 2,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: 'grey-100',
    }}
  />
)

const FooterFlex = (props: FlexProps) => (
  <Flex sx={{ height: '100%', alignItems: 'center', gap: 2 }} {...props} />
)

export const ModalFooter: React.FunctionComponent<ModalFooterProps> = ({
  leftButtonProps,
  rightButtonProps,
  isFullScreen = false,
  children,
  ...rest
}) => {
  const getResponsiveProp = useResponsive()
  const { formatMessage: f } = useI18n()

  const responsiveFooterHeight = useMemo(
    () => (isFullScreen ? getResponsiveProp(['62px', '70px', null, null]) : '70px'),
    [isFullScreen, getResponsiveProp],
  )

  if (children) {
    return (
      <FooterBox {...rest}>
        <FooterFlex>{children}</FooterFlex>
      </FooterBox>
    )
  } else if (leftButtonProps && rightButtonProps) {
    return (
      <FooterBox sx={{ height: responsiveFooterHeight }} {...rest}>
        <FooterFlex>
          <Button
            variant={leftButtonProps.variant || 'secondary'}
            color={leftButtonProps.color || 'grey'}
            sx={{ minWidth: '83px' }}
            {...leftButtonProps}
          >
            {leftButtonProps.children || f({ id: 'CANCEL' })}
          </Button>
          <Box sx={{ mx: 'auto' }} />
          <Button
            variant={rightButtonProps.variant || 'primary'}
            sx={{ minWidth: '83px' }}
            {...rightButtonProps}
          >
            {rightButtonProps.children || f({ id: 'CONFIRM' })}
          </Button>
        </FooterFlex>
      </FooterBox>
    )
  } else if (rightButtonProps && !leftButtonProps) {
    return (
      <FooterBox sx={{ height: responsiveFooterHeight }} {...rest}>
        <FooterFlex>
          <Box sx={{ mx: 'auto' }} />
          <Button
            variant={rightButtonProps.variant || 'primary'}
            disabled={rightButtonProps.disabled}
            onClick={rightButtonProps.onClick}
            {...rightButtonProps}
          >
            {rightButtonProps.title || f({ id: 'CONFIRM' })}
          </Button>
        </FooterFlex>
      </FooterBox>
    )
  } else if (!rightButtonProps && leftButtonProps) {
    return (
      <FooterBox sx={{ height: responsiveFooterHeight }} {...rest}>
        <FooterFlex>
          <Button
            variant={leftButtonProps.variant || 'secondary'}
            color={leftButtonProps.color || 'grey'}
            {...leftButtonProps}
          >
            {leftButtonProps.title || f({ id: 'CANCEL' })}
          </Button>
          <Box sx={{ mx: 'auto' }} />
        </FooterFlex>
      </FooterBox>
    )
  } else {
    return null
  }
}
