import React, { forwardRef, useCallback, useMemo } from 'react'

import { useRouter } from 'next/router'
import { VictoryLabelProps } from 'victory'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { Avatar } from '@plco-pro/components/molecules/avatar'
import { UserDataType } from '@plco-pro/graphqls/react.generated'
import { useMoment } from '@plco-pro/hooks/moment'
import { ChartParsedDataItem } from '@plco-pro/hooks/store-system'
import { useStore } from '@plco-pro/stores'
import { PersonalDataTabId } from '@plco-pro/stores/players'

export const WIDTH = 48
export const HEIGHT = 68

export type ChartLabelAvatarProps = VictoryLabelProps & {
  data?: ChartParsedDataItem
  tabId?: PersonalDataTabId
  dataType?: UserDataType
  index?: number
  onClick?: (id?: number) => void
}

const ContainerFlex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
  <Flex
    ref={ref}
    sx={{
      py: '4px',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'bg-hover',
      },
      '&:active': {
        backgroundColor: 'bg-pressed',
      },
    }}
    {...props}
  />
))

export const ChartLabelAvatar: React.FunctionComponent<ChartLabelAvatarProps> = ({
  x = 0,
  y = 0,
  text,
  data,
  tabId,
  dataType,
}) => {
  const moment = useMoment()
  const store = useStore()
  const router = useRouter()

  const realX = x - WIDTH / 2
  const realY = y

  // get current label's data
  // cannot rely on index as index is updated on panning (drawn index)
  const currentLabelData: Record<string, any> | undefined = useMemo(() => {
    return data?.find((item) => item.x === text)
  }, [data, text])

  const { id, pictureUrl, name, time } = currentLabelData || {}
  const playerDate =
    typeof time === 'string' && moment(time).isValid()
      ? moment(time).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')

  const handleClick = useCallback(() => {
    if (!id) return

    store.navigation.setPageViewProperty({ conversion: 'team_monitoring_chart' })

    router.push(
      {
        query: {
          playerId: id,
          playerDate,
          dataType,
        },
        hash: tabId,
      },
      undefined,
      {
        scroll: false,
      },
    )
  }, [id, dataType, router, store.navigation, tabId, playerDate])

  return (
    <foreignObject x={realX} y={realY} width={WIDTH} height={HEIGHT}>
      <ContainerFlex onClick={handleClick}>
        <Box sx={{ width: 38, height: 38 }}>
          <Avatar size={38} src={pictureUrl} value={name?.toString() || ''} />
        </Box>
        <Box
          sx={{
            mt: '4px',
            width: WIDTH,
            textAlign: 'center',
          }}
        >
          <Text variant={'p2'} appearance={'hint'} ellipsis>
            {name?.toString() || text}
          </Text>
        </Box>
      </ContainerFlex>
    </foreignObject>
  )
}
