import React from 'react'

import _round from 'lodash/round'

import { Box } from '@plco-pro/components/atoms/box'
import { Chip, ChipProps } from '@plco-pro/components/atoms/chip'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'

export type ChipMonitoringNumberProps = ChipProps & {
  value: number
  label?: string
}

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-start',
    }}
    {...props}
  />
)

export const ChipMonitoringNumber: React.FunctionComponent<ChipMonitoringNumberProps> = ({
  value,
  label,
  disabled,
  ...rest
}) => {
  if (label) {
    return (
      <Flex sx={{ alignItems: 'center', gap: '6px', flex: '1 0 0' }}>
        <Text sx={{ fontWeight: 'bold' }} variant={'s1'} appearance={disabled && 'disabled'}>
          {label}
        </Text>
        <Box sx={{ padding: '4px 8px', bg: 'grey-50', borderRadius: 5 }}>
          <Text sx={{ fontWeight: 'bold', color: 'grey-500' }} variant={'p2'}>
            {value}
          </Text>
        </Box>
      </Flex>
    )
  } else if (rest.backgroundColor) {
    return (
      <Chip size={'xs'} {...rest}>
        <Text
          variant={'p1'}
          appearance={'alternative'}
          sx={{ fontWeight: 'medium', marginTop: '-1px' }}
        >
          {_round(value, 2)}
        </Text>
      </Chip>
    )
  } else {
    return (
      <ContainerFlex>
        <Text variant={'p1'}>{_round(value, 2)}</Text>
      </ContainerFlex>
    )
  }
}
