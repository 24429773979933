import { useCallback, useEffect, useState } from 'react'

export const useModal = () => {
  const [open, setOpen] = useState(false)
  const [isRenderModal, setIsRenderModal] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const onAnimationEnd = useCallback(() => {
    if (!open) {
      setIsRenderModal(false)
    }
  }, [open])

  useEffect(() => {
    if (open) {
      setIsRenderModal(true)
    }
  }, [open])

  return {
    open,
    isRenderModal,
    setOpen,
    onOpen,
    onClose,
    onToggle,
    onAnimationEnd,
  }
}
