import React from 'react'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { ChipWorkoutStatus, WorkoutLabelType } from '@plco-pro/components/molecules'
import { Avatar } from '@plco-pro/components/molecules/avatar'

export type DropdownMenuDatasetItemProps = {
  src?: string
  value?: string
  title?: string
  label?: WorkoutLabelType
  isShowChip?: boolean
  onClick?: () => void
}

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={
      props.onClick
        ? {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'bg-hover',
            },
            '&:active': {
              backgroundColor: 'bg-pressed',
            },
          }
        : {}
    }
    {...props}
  />
)

export const DropdownMenuDatasetItem: React.FunctionComponent<DropdownMenuDatasetItemProps> = ({
  src,
  title,
  value,
  label,
  isShowChip,
  onClick,
}) => {
  return (
    <ContainerFlex sx={{ px: 2, py: 1, alignItems: 'center' }} onClick={() => onClick?.()}>
      <Avatar sx={{ mr: 1 }} src={src} value={value} size={32} />
      <Text variant={'p1'} ellipsis sx={{ flex: 1, marginRight: 2 }}>
        {title}
      </Text>
      {isShowChip && <ChipWorkoutStatus type={label} />}
    </ContainerFlex>
  )
}
