export const insight = {
  en: {
    GTE: 'or more',
    GT: 'above',
    LTE: 'or less',
    LT: 'under',
    AVG: 'avg',
    TIMES: '{times, plural, =1 {time} other {times}}',
    STEP: 'step',
    SORENESS: 'soreness',
    ACWR_AVG: 'ACWR avg',
    MONOTONY: 'monotony',

    'insight.widget.footer.reminder-button': 'check notification',
    'insight.widget.empty-data': "There's no player to tell you.",
    'insight.widget.title.summary': 'Summary',
    'insight.widget.title.condition': 'Condition',
    'insight.widget.title.workload': 'Workload',
    'insight.widget.title.injury': 'Soreness',

    // SUMMARY
    'insight.widget.item.title.CONDITION_CHECKED_NOT': 'Unchecked condition ({count})',
    'insight.widget.item.subtitle.CONDITION_CHECKED_NOT': 'criteria ・ unchecked',

    'insight.widget.item.title.RANK_ACWR_HIGH': '😱 High ACWR ({count})',
    'insight.widget.item.subtitle.RANK_ACWR_HIGH': 'criteria ・ ACWR {value} {operator}',
    'insight.widget.item.title.RANK_ACWR_HIGH_7DAY': '😱 High ACWR ({count})',
    'insight.widget.item.subtitle.RANK_ACWR_HIGH_7DAY': 'criteria ・ ACWR avg {value} {operator}',
    'insight.widget.item.title.RANK_ACWR_HIGH_28DAY': '😱 High ACWR ({count})',
    'insight.widget.item.subtitle.RANK_ACWR_HIGH_28DAY': 'criteria ・ ACWR avg {value} {operator}',

    // CONDITION
    'insight.widget.item.title.RANK_CONDITION_BAD': '🤔 Bad condition ({count})',
    'insight.widget.item.subtitle.RANK_CONDITION_BAD': 'criteria ・ condition {value} {operator}',
    'insight.widget.item.title.RANK_CONDITION_BAD_7DAY': '🤔 Bad condition ({count})',
    'insight.widget.item.subtitle.RANK_CONDITION_BAD_7DAY':
      'criteria ・ number of condition 1~{value}{unit}',
    'insight.widget.item.title.RANK_CONDITION_BAD_28DAY': '🤔 Bad condition ({count})',
    'insight.widget.item.subtitle.RANK_CONDITION_BAD_28DAY':
      'criteria ・ number of condition 1~{value}{unit}',

    'insight.widget.item.title.RANK_MUSCLE_BAD': '💪 Bad muscle ({count})',
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD': 'criteria ・ muscle {value} {operator}',
    'insight.widget.item.title.RANK_MUSCLE_BAD_7DAY': '💪 Bad muscle ({count})',
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD_7DAY':
      'criteria ・ number of muscle 1~{value}{unit}',
    'insight.widget.item.title.RANK_MUSCLE_BAD_28DAY': '💪 Bad muscle ({count})',
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD_28DAY':
      'criteria ・ number of muscle 1~{value}{unit}',

    'insight.widget.item.title.RANK_STRESS_HIGH': '🤯 High stress ({count})',
    'insight.widget.item.subtitle.RANK_STRESS_HIGH': 'criteria ・ stress {value} {operator}',
    'insight.widget.item.title.RANK_STRESS_HIGH_7DAY': '🤯 High stress ({count})',
    'insight.widget.item.subtitle.RANK_STRESS_HIGH_7DAY':
      'criteria ・ number of stress 1~{value}{unit}',
    'insight.widget.item.title.RANK_STRESS_HIGH_28DAY': '🤯 High stress ({count})',
    'insight.widget.item.subtitle.RANK_STRESS_HIGH_28DAY':
      'criteria ・ number of stress 1~{value}{unit}',

    'insight.widget.item.title.RANK_MOOD_BAD': '😩 Bad mood ({count})',
    'insight.widget.item.subtitle.RANK_MOOD_BAD': 'criteria ・ mood {value} {operator}',
    'insight.widget.item.title.RANK_MOOD_BAD_7DAY': '😩 Bad mood ({count})',
    'insight.widget.item.subtitle.RANK_MOOD_BAD_7DAY': 'criteria ・ number of mood 1~{value}{unit}',
    'insight.widget.item.title.RANK_MOOD_BAD_28DAY': '😩 Bad mood ({count})',
    'insight.widget.item.subtitle.RANK_MOOD_BAD_28DAY':
      'criteria ・ number of mood 1~{value}{unit}',

    'insight.widget.item.title.RANK_FATIGUE_HIGH': '😵 High fatigue ({count})',
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH': 'criteria ・ fatigue {value} {operator}',
    'insight.widget.item.title.RANK_FATIGUE_HIGH_7DAY': '😵 High fatigue ({count})',
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH_7DAY':
      'criteria ・ number of fatigue 1~{value}{unit}',
    'insight.widget.item.title.RANK_FATIGUE_HIGH_28DAY': '😵 High fatigue ({count})',
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH_28DAY':
      'criteria ・ number of fatigue 1~{value}{unit}',

    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD': '😴 Bad sleep time ({count})',
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD':
      'criteria ・ duration under {value}m({hours}h)',
    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD_7DAY': '😴 Bad sleep time ({count})',
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD_7DAY':
      'criteria ・ number of duration under {value}m({hours}h)',
    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD_28DAY': '😴 Bad sleep time ({count})',
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD_28DAY':
      'criteria ・ number of duration under {value}m({hours}h)',

    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD': '🥱 Bad sleep quality ({count})',
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD': 'criteria ・ quality {value} {operator}',
    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD_7DAY': '🥱 Bad sleep quality ({count})',
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD_7DAY':
      'criteria ・ number of sleep quality 1~{value}{unit}',
    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD_28DAY': '🥱 Bad sleep quality ({count})',
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD_28DAY':
      'criteria ・ number of sleep quality 1~{value}{unit}',

    // WORKLOAD
    'insight.widget.item.title.RANK_WORKLOAD_HIGH': '🏋️‍ High workload ({count})',
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH': 'criteria ・ workload {operator} {value}',
    'insight.widget.item.title.RANK_WORKLOAD_HIGH_7DAY': '🏋️‍ High workload ({count})',
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH_7DAY':
      'criteria ・ sum workload {operator} {value}',
    'insight.widget.item.title.RANK_WORKLOAD_HIGH_28DAY': '🏋️‍ High workload ({count})',
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH_28DAY':
      'criteria ・ sum workload {operator} {value}',

    'insight.widget.item.title.RANK_WORKLOAD_INCREASED_RATE_7DAY':
      '💥 Increased workload rate ({count})',
    'insight.widget.item.subtitle.RANK_WORKLOAD_INCREASED_RATE_7DAY':
      'criteria ・ {value}% more than the previous week',

    'insight.widget.item.title.RANK_WORKLOAD_AXD_7DAY': '😑 Monotony ({count})',
    'insight.widget.item.subtitle.RANK_WORKLOAD_AXD_7DAY':
      'criteria ・ monotony {operator} {value}',

    // SORENESS
    'insight.widget.item.title.RANK_SORENESS_INJURED': '🤕 Player with level 5 soreness ({count})',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED':
      'criteria ・ more than one level {value} soreness',
    'insight.widget.item.title.RANK_SORENESS_INJURED_7DAY':
      '🤕 Player with level 5 soreness ({count})',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED_7DAY':
      'criteria ・ more than one level {value} soreness',
    'insight.widget.item.title.RANK_SORENESS_INJURED_28DAY':
      '🤕 Player with level 5 soreness ({count})',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED_28DAY':
      'criteria ・ more than one level {value} soreness',

    'insight.widget.item.title.RANK_SORENESS_SEVERE':
      '😣 Player with level 3, 4 soreness ({count})',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE':
      'criteria ・ more than one level {from}, {to} soreness',
    'insight.widget.item.title.RANK_SORENESS_SEVERE_7DAY':
      '😣 Player with level 3, 4 soreness ({count})',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE_7DAY':
      'criteria ・ more than one level {from}, {to} soreness',
    'insight.widget.item.title.RANK_SORENESS_SEVERE_28DAY':
      '😣 Player with level 3, 4 soreness ({count})',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE_28DAY':
      'criteria ・ more than one level {from}, {to} soreness',

    // HEADER
    'insight.header.DAY': 'Today',
    'insight.header.RECENT_7DAY': 'Recent 7days',
    'insight.header.RECENT_28DAY': 'Recent 28days',
  },
  ko: {
    GTE: '이상',
    GT: '초과',
    LTE: '이하',
    LT: '미만',
    AVG: '평균',
    TIMES: '회',
    STEP: '단계',
    SORENESS: '통증',
    ACWR_AVG: '부상위험도(ACWR)',
    MONOTONY: '모노토니(monotony)',

    'insight.widget.footer.reminder-button': '체크 알림',
    'insight.widget.empty-data': '알려 드릴 만한 선수가 없어요.',
    'insight.widget.title.summary': '요약',
    'insight.widget.title.condition': '컨디션',
    'insight.widget.title.workload': '운동부하',
    'insight.widget.title.injury': '통증',

    // SUMMARY
    'insight.widget.item.title.CONDITION_CHECKED_NOT': '컨디션 미체크 선수 ({count}명)',
    'insight.widget.item.subtitle.CONDITION_CHECKED_NOT': '기준 ・ 컨디션 미체크',

    'insight.widget.item.title.RANK_ACWR_HIGH': '😱 부상위험도 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_ACWR_HIGH': '기준 ・ 부상위험도(ACWR) {value} {operator}',
    'insight.widget.item.title.RANK_ACWR_HIGH_7DAY': '😱 부상위험도 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_ACWR_HIGH_7DAY':
      '기준 ・ 평균 부상위험도(ACWR) {value} {operator}',
    'insight.widget.item.title.RANK_ACWR_HIGH_28DAY': '😱 부상위험도 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_ACWR_HIGH_28DAY':
      '기준 ・ 평균 부상위험도(ACWR) {value} {operator}',

    // CONDITION
    'insight.widget.item.title.RANK_CONDITION_BAD': '🤔 컨디션 나쁜 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_CONDITION_BAD': '기준 ・ 컨디션 {value}{unit} {operator}',
    'insight.widget.item.title.RANK_CONDITION_BAD_7DAY': '🤔 컨디션 나쁜 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_CONDITION_BAD_7DAY':
      '기준 ・ 주간 컨디션 {value}{unit} {operator} 많은 순',
    'insight.widget.item.title.RANK_CONDITION_BAD_28DAY': '🤔 컨디션 나쁜 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_CONDITION_BAD_28DAY':
      '기준 ・ 월간 컨디션 {value}{unit} {operator} 많은 순',

    'insight.widget.item.title.RANK_MUSCLE_BAD': '💪 근육 상태 나쁜 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD': '기준 ・ 근육 {value}{unit} {operator}',
    'insight.widget.item.title.RANK_MUSCLE_BAD_7DAY': '💪 근육 상태 나쁜 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD_7DAY':
      '기준 ・ 주간 근육 {value}{unit} {operator} 많은 순',
    'insight.widget.item.title.RANK_MUSCLE_BAD_28DAY': '💪 근육 상태 나쁜 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD_28DAY':
      '기준 ・ 월간 근육 {value}{unit} {operator} 많은 순',

    'insight.widget.item.title.RANK_STRESS_HIGH': '🤯 스트레스 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_STRESS_HIGH': '기준 ・ 스트레스 {value}{unit} {operator}',
    'insight.widget.item.title.RANK_STRESS_HIGH_7DAY': '🤯 스트레스 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_STRESS_HIGH_7DAY':
      '기준 ・ 주간 스트레스 {value}{unit} {operator} 많은 순',
    'insight.widget.item.title.RANK_STRESS_HIGH_28DAY': '🤯 스트레스 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_STRESS_HIGH_28DAY':
      '기준 ・ 월간 스트레스 {value}{unit} {operator} 많은 순',

    'insight.widget.item.title.RANK_MOOD_BAD': '😩 기분 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_MOOD_BAD': '기준 ・ 기분 {value}{unit} {operator}',
    'insight.widget.item.title.RANK_MOOD_BAD_7DAY': '😩 기분 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_MOOD_BAD_7DAY':
      '기준 ・ 주간 기분 {value}{unit} {operator} 많은 순',
    'insight.widget.item.title.RANK_MOOD_BAD_28DAY': '😩 기분 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_MOOD_BAD_28DAY':
      '기준 ・ 월간 기분 {value}{unit} {operator} 많은 순',

    'insight.widget.item.title.RANK_FATIGUE_HIGH': '😵 피로도 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH': '기준 ・ 피로도 {value}{unit} {operator}',
    'insight.widget.item.title.RANK_FATIGUE_HIGH_7DAY': '😵 피로도 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH_7DAY':
      '기준 ・ 주간 피로도 {value}{unit} {operator} 많은 순',
    'insight.widget.item.title.RANK_FATIGUE_HIGH_28DAY': '😵 피로도 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH_28DAY':
      '기준 ・ 월간 피로도 {value}{unit} {operator} 많은 순',

    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD': '😴 수면시간 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD':
      '기준 ・ 수면시간 {value}분({hours}시간) {operator}',
    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD_7DAY': '😴 수면시간 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD_7DAY':
      '기준 ・ 주간 수면시간 {value}분({hours}시간) {operator} 많은 순',
    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD_28DAY': '😴 수면시간 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD_28DAY':
      '기준 ・ 월간 수면시간 {value}분({hours}시간) {operator} 많은 순',

    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD': '🥱 수면품질 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD':
      '기준 ・ 수면품질 {value}{unit} {operator}',
    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD_7DAY': '🥱 수면품질 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD_7DAY':
      '기준 ・ 주간 수면품질 {value}{unit} {operator} 많은 순',
    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD_28DAY': '🥱 수면품질 낮은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD_28DAY':
      '기준 ・ 월간 수면품질 {value}{unit} {operator} 많은 순',

    // WORKLOAD
    'insight.widget.item.title.RANK_WORKLOAD_HIGH': '🏋️‍ 부하가 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH': '기준 ・ 부하 합계 {value} {operator}',
    'insight.widget.item.title.RANK_WORKLOAD_HIGH_7DAY': '🏋️‍ 부하가 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH_7DAY':
      '기준 ・ 주간 부하 합계 {value} {operator}',
    'insight.widget.item.title.RANK_WORKLOAD_HIGH_28DAY': '🏋️‍ 부하가 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH_28DAY':
      '기준 ・ 월간 부하 평균 {value} {operator}',

    'insight.widget.item.title.RANK_WORKLOAD_INCREASED_RATE_7DAY':
      '💥 부하 증가율이 높은 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_WORKLOAD_INCREASED_RATE_7DAY':
      '기준 ・ 전주 대비 부하 {value}% {operator} 증가',

    'insight.widget.item.title.RANK_WORKLOAD_AXD_7DAY': '😑 부하가 단조로운 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_WORKLOAD_AXD_7DAY':
      '기준 ・ 모노토니(monotony) {value} {operator}',

    // SORENESS
    'insight.widget.item.title.RANK_SORENESS_INJURED': '🤕 통증 5단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED': '기준 ・ 통증 {value}단계 1개 이상',
    'insight.widget.item.title.RANK_SORENESS_INJURED_7DAY': '🤕 통증 5단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED_7DAY': '기준 ・ 통증 {value}단계 1개 이상',
    'insight.widget.item.title.RANK_SORENESS_INJURED_28DAY': '🤕 통증 5단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED_28DAY': '기준 ・ 통증 {value}단계 1개 이상',

    'insight.widget.item.title.RANK_SORENESS_SEVERE': '😣‍ 통증 3, 4단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE': '기준 ・ 통증 {from}~{to}단계 1개 이상',
    'insight.widget.item.title.RANK_SORENESS_SEVERE_7DAY': '😣‍ 통증 3, 4단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE_7DAY':
      '기준 ・ 통증 {from}~{to}단계 1개 이상',
    'insight.widget.item.title.RANK_SORENESS_SEVERE_28DAY': '😣‍ 통증 3, 4단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE_28DAY':
      '기준 ・ 통증 {from}~{to}단계 1개 이상',

    // HEADER
    'insight.header.DAY': '오늘',
    'insight.header.RECENT_7DAY': '최근 7일',
    'insight.header.RECENT_28DAY': '최근 28일',
  },
  ja: {
    GTE: '以上', // "이상",
    GT: '超過', // "초과",
    LTE: '以下', // "이하",
    LT: '未満', // "미만",
    AVG: '平均', // "평균",
    TIMES: '回', // "회",
    STEP: '段階', // "단계",
    SORENESS: '痛み', // "통증",
    ACWR_AVG: '負傷危険度(ACWR)', // "부상위험도(ACWR)",
    MONOTONY: 'モノトニー(monotony)', // "모노토니(monotony)",

    'insight.widget.footer.reminder-button': 'チェック通知', // "체크 알림",
    'insight.widget.empty-data': '教えるに値する選手がいません.', // "알려 드릴 만한 선수가 없어요.",
    'insight.widget.title.summary': '要約', // "요약",
    'insight.widget.title.condition': 'コンディション', // "컨디션",
    'insight.widget.title.workload': '運動負荷', // "운동부하",
    'insight.widget.title.injury': '痛み', // "통증",

    // SUMMARY
    'insight.widget.item.title.CONDITION_CHECKED_NOT': 'コンディション未チェック選手({count}名)', // "컨디션 미체크 선수 ({count}명)",
    'insight.widget.item.subtitle.CONDITION_CHECKED_NOT': '.基準·体調未チェック', // "기준 ・ 컨디션 미체크",

    'insight.widget.item.title.RANK_ACWR_HIGH': '😱 負傷の危険度が高い選手 ({count}名)', // "😱 부상위험도 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_ACWR_HIGH': '基準·負傷リスク（ACWR）{value}{operator}', // "기준 ・ 부상위험도(ACWR) {value} {operator}",
    'insight.widget.item.title.RANK_ACWR_HIGH_7DAY': '😱 負傷の危険度が高い選手 ({count}名)', // "😱 부상위험도 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_ACWR_HIGH_7DAY':
      '基準·平均負傷危険度(ACWR){value}{operator}', // "기준 ・ 평균 부상위험도(ACWR) {value} {operator}",
    'insight.widget.item.title.RANK_ACWR_HIGH_28DAY': '😱 負傷の危険度が高い選手 ({count}名)', // "😱 부상위험도 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_ACWR_HIGH_28DAY':
      '基準·平均負傷危険度(ACWR){value}{operator}', // "기준 ・ 평균 부상위험도(ACWR) {value} {operator}",

    // CONDITION
    'insight.widget.item.title.RANK_CONDITION_BAD': '🤔 コンディションの悪い選手({count}名)', // "🤔 컨디션 나쁜 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_CONDITION_BAD': '基準·コンディション{value}{unit}{operator}', // "기준 ・ 컨디션 {value}{unit} {operator}",
    'insight.widget.item.title.RANK_CONDITION_BAD_7DAY': '🤔 コンディションの悪い選手({count}名)', // "🤔 컨디션 나쁜 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_CONDITION_BAD_7DAY':
      '基準·週間コンディション{value}{unit}{operator}多い順', // "기준 ・ 주간 컨디션 {value}{unit} {operator} 많은 순",
    'insight.widget.item.title.RANK_CONDITION_BAD_28DAY': '🤔 コンディションの悪い選手({count}名)', // "🤔 컨디션 나쁜 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_CONDITION_BAD_28DAY':
      '基準·月間コンディション{value}{unit}{operator}多い順', // "기준 ・ 월간 컨디션 {value}{unit} {operator} 많은 순",

    'insight.widget.item.title.RANK_MUSCLE_BAD': '💪筋肉状態の悪い選手({count}名)', // "💪 근육 상태 나쁜 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD': '基準·筋肉{value}{unit}{operator}', // "기준 ・ 근육 {value}{unit} {operator}",
    'insight.widget.item.title.RANK_MUSCLE_BAD_7DAY': '💪筋肉状態の悪い選手({count}名)', // "💪 근육 상태 나쁜 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD_7DAY':
      '基準·週間筋肉{value}{unit}{operator}多い順', // "기준 ・ 주간 근육 {value}{unit} {operator} 많은 순",
    'insight.widget.item.title.RANK_MUSCLE_BAD_28DAY': '💪筋肉状態の悪い選手({count}名)', // "💪 근육 상태 나쁜 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_MUSCLE_BAD_28DAY':
      '基準·月間筋肉{value}{unit}{operator}多い順', // "기준 ・ 월간 근육 {value}{unit} {operator} 많은 순",

    'insight.widget.item.title.RANK_STRESS_HIGH': '🤯 ストレスの高い選手({count}名)', // "🤯 스트레스 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_STRESS_HIGH': '基準·ストレス{value}{unit}{operator}', // "기준 ・ 스트레스 {value}{unit} {operator}",
    'insight.widget.item.title.RANK_STRESS_HIGH_7DAY': '🤯 ストレスの高い選手({count}名)', // "🤯 스트레스 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_STRESS_HIGH_7DAY':
      '基準·週間ストレス{value}{unit}{operator}多い順', // "기준 ・ 주간 스트레스 {value}{unit} {operator} 많은 순",
    'insight.widget.item.title.RANK_STRESS_HIGH_28DAY': '🤯 ストレスの高い選手({count}名)', // "🤯 스트레스 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_STRESS_HIGH_28DAY':
      '基準·月間ストレス{value}{unit}{operator}多い順', // "기준 ・ 월간 스트레스 {value}{unit} {operator} 많은 순",

    'insight.widget.item.title.RANK_MOOD_BAD': '😩 気分の低い選手 ({count}名)', // "😩 기분 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_MOOD_BAD': '基準·気分{value}{unit}{operator}', // "기준 ・ 기분 {value}{unit} {operator}",
    'insight.widget.item.title.RANK_MOOD_BAD_7DAY': '😩 気分の低い選手 ({count}名)', // "😩 기분 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_MOOD_BAD_7DAY': '基準·週間気分{value}{unit}{operator}多い順', // "기준 ・ 주간 기분 {value}{unit} {operator} 많은 순",
    'insight.widget.item.title.RANK_MOOD_BAD_28DAY': '😩気持ち低い選手({count}名)', // "😩 기분 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_MOOD_BAD_28DAY':
      '基準·月間気分{value}{unit}{operator}多い順', // "기준 ・ 월간 기분 {value}{unit} {operator} 많은 순",

    'insight.widget.item.title.RANK_FATIGUE_HIGH': '😵 疲労度の高い選手 ({count}名)', // "😵 피로도 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH': '基準·疲労度{value}{unit}{operator}', // "기준 ・ 피로도 {value}{unit} {operator}",
    'insight.widget.item.title.RANK_FATIGUE_HIGH_7DAY': '😵 疲労度の高い選手 ({count}名)', // "😵 피로도 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH_7DAY':
      '基準·週間疲労度{value}{unit}{operator}多い順', // "기준 ・ 주간 피로도 {value}{unit} {operator} 많은 순",
    'insight.widget.item.title.RANK_FATIGUE_HIGH_28DAY': '😵 疲労度の高い選手 ({count}名)', // "😵 피로도 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_FATIGUE_HIGH_28DAY':
      '基準·月間疲労度{value}{unit}{operator}多い順', // "기준 ・ 월간 피로도 {value}{unit} {operator} 많은 순",

    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD': '😴睡眠時間の低い選手({count}名)', // "😴 수면시간 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD':
      '基準·睡眠時間{value}分({hours}時間){operator}', // "기준 ・ 수면시간 {value}분({hours}시간) {operator}",
    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD_7DAY': '😴睡眠時間の低い選手({count}名)', // "😴 수면시간 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD_7DAY':
      '基準·週間睡眠時間{value}分({hours}時間){operator}多い順', // "기준 ・ 주간 수면시간 {value}분({hours}시간) {operator} 많은 순",
    'insight.widget.item.title.RANK_SLEEP_DURATION_BAD_28DAY': '😴睡眠時間の低い選手({count}名)', // "😴 수면시간 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_SLEEP_DURATION_BAD_28DAY':
      '基準·月間睡眠時間{value}分({hours}時間){operator}多い順', // "기준 ・ 월간 수면시간 {value}분({hours}시간) {operator} 많은 순",

    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD': '🥱 睡眠品質の低い選手({count}名)', // "🥱 수면품질 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD': '基準·睡眠品質{value}{unit}{operator}', // "기준 ・ 수면품질 {value}{unit} {operator}",
    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD_7DAY': '🥱 睡眠品質の低い選手({count}名)', // "🥱 수면품질 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD_7DAY':
      '基準・週間睡眠品質{value}{unit}{operator}多くの順', // "기준 ・ 주간 수면품질 {value}{unit} {operator} 많은 순",
    'insight.widget.item.title.RANK_SLEEP_QUALITY_BAD_28DAY': '🥱 睡眠品質の低い選手({count}名)', // "🥱 수면품질 낮은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_SLEEP_QUALITY_BAD_28DAY':
      '基準·月間睡眠品質{value}{unit}{operator}多い順', // "기준 ・ 월간 수면품질 {value}{unit} {operator} 많은 순",

    // WORKLOAD
    'insight.widget.item.title.RANK_WORKLOAD_HIGH': '🏋️ 負荷が高い選手({count}名)', // "🏋️‍ 부하가 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH': '基準·負荷合計{value}{operator}', // "기준 ・ 부하 합계 {value} {operator}",
    'insight.widget.item.title.RANK_WORKLOAD_HIGH_7DAY': '🏋️ 負荷が高い選手({count}名)', // "🏋️‍ 부하가 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH_7DAY': '基準·週間負荷合計{value}{operator}', // "기준 ・ 주간 부하 합계 {value} {operator}",
    'insight.widget.item.title.RANK_WORKLOAD_HIGH_28DAY': '🏋️ 負荷が高い選手({count}名)', // "🏋️‍ 부하가 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_WORKLOAD_HIGH_28DAY': '基準·月間負荷平均{value}{operator}', // "기준 ・ 월간 부하 평균 {value} {operator}",

    'insight.widget.item.title.RANK_WORKLOAD_INCREASED_RATE_7DAY':
      '💥 負荷増加率が高い選手 ({count}名)', // "💥 부하 증가율이 높은 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_WORKLOAD_INCREASED_RATE_7DAY':
      '基準·前週比負荷{value}%{operator}増加', // "기준 ・ 전주 대비 부하 {value}% {operator} 증가",

    'insight.widget.item.title.RANK_WORKLOAD_AXD_7DAY': '😑 負荷が単調な選手({count}名)', // "😑 부하가 단조로운 선수 ({count}명)",
    'insight.widget.item.subtitle.RANK_WORKLOAD_AXD_7DAY':
      '基準·モノトニー(monotony){value}{operator}', // "기준 ・ 모노토니(monotony) {value} {operator}",

    // SORENESS
    'insight.widget.item.title.RANK_SORENESS_INJURED': '🤕 5段階の痛みを持つ選手 ({count}名)', // '🤕 통증 5단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED': '基準·{value}段階の痛みが1つ以上', // '기준 ・ 통증 {value}단계 1개 이상',
    'insight.widget.item.title.RANK_SORENESS_INJURED_7DAY': '🤕 5段階の痛みを持つ選手 ({count}名)', // '🤕 통증 5단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED_7DAY': '基準·{value}段階の痛みが1つ以上', // '기준 ・ 통증 {value}단계 1개 이상',
    'insight.widget.item.title.RANK_SORENESS_INJURED_28DAY': '🤕 5段階の痛みを持つ選手 ({count}名)', // '🤕 통증 5단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_INJURED_28DAY': '基準·{value}段階の痛みが1つ以上', // '기준 ・ 통증 {value}단계 1개 이상',

    'insight.widget.item.title.RANK_SORENESS_SEVERE': '😣 3, 4段階の痛みを持つ選手 ({count}名)', // '😣‍ 통증 3, 4단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE': '基準·{from}, {to}段階の痛みが1つ以上', // '기준 ・ 통증 {from}~{to}단계 1개 이상',
    'insight.widget.item.title.RANK_SORENESS_SEVERE_7DAY':
      '😣 3, 4段階の痛みを持つ選手 ({count}名)', // 😣‍ 통증 3, 4단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE_7DAY':
      '基準·{from}, {to}段階の痛みが1つ以上', // '기준 ・ 통증 {from}~{to}단계 1개 이상',
    'insight.widget.item.title.RANK_SORENESS_SEVERE_28DAY':
      '😣 3, 4段階の痛みを持つ選手 ({count}名)', // 😣‍ 통증 3, 4단계 선수 ({count}명)',
    'insight.widget.item.subtitle.RANK_SORENESS_SEVERE_28DAY':
      '基準·{from}, {to}段階の痛みが1つ以上', // '기준 ・ 통증 {from}~{to}단계 1개 이상',

    // HEADER
    'insight.header.DAY': '今日', // "오늘",
    'insight.header.RECENT_7DAY': '最近7日', // "최근 7일",
    'insight.header.RECENT_28DAY': '最近28日', // "최근 28일",
  },
}
