import { Fragment, useMemo } from 'react'

import {
  FeedbackConditionNotGoodData,
  UserConditionDataType,
} from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks'
import { useResponsiveContext } from '@plco-pro/providers/responsive'

import { Box, Divider } from '../../atoms'
import { DescriptionBox } from '../components'
import { FeedbackContentsType } from '../helpers'
import { BadConditionItem } from './bad-condition-item'

interface BadConditionProps {
  name: string
  conditionNotGoodData: FeedbackConditionNotGoodData
  feedbackContentsType?: FeedbackContentsType
}

export const BadCondition = ({
  name: player,
  conditionNotGoodData,
  feedbackContentsType,
}: BadConditionProps) => {
  const { formatMessage: f } = useI18n()
  const { smAndDown } = useResponsiveContext()

  const {
    fatigue,
    stress,
    muscle,
    mood,
    sleepDuration,
    sleepQuality,
    sleepDurationScore,
    dataList,
  } = conditionNotGoodData

  const conditionList = useMemo(() => {
    return dataList.map((data) => f({ id: `feedback.condition-type.${data}` })).join(', ')
  }, [dataList, f])

  // 모바일, 피드백 등록 시에만 디바이더 노출
  const showDivider = feedbackContentsType === 'add' && smAndDown

  return (
    <Fragment>
      <Box sx={{ mx: [2, 3] }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1, mb: 2 }}>
          <BadConditionItem conditionType={UserConditionDataType.FATIGUE} score={fatigue} />
          <BadConditionItem conditionType={UserConditionDataType.STRESS} score={stress} />
          <BadConditionItem conditionType={UserConditionDataType.MUSCLE} score={muscle} />
          <BadConditionItem conditionType={UserConditionDataType.MOOD} score={mood} />
          <BadConditionItem
            conditionType={UserConditionDataType.SLEEP_DURATION}
            score={sleepDurationScore}
            sleepDuration={sleepDuration}
          />
          <BadConditionItem
            conditionType={UserConditionDataType.SLEEP_QUALITY}
            score={sleepQuality}
          />
        </Box>

        <DescriptionBox feedbackContentsType={feedbackContentsType}>
          {f({ id: 'feedback.bad-condition-description' }, { player, conditionList })}
        </DescriptionBox>
      </Box>
      {showDivider && <Divider sx={{ height: '16px', mt: 3, mb: 1 }} />}
    </Fragment>
  )
}
