import { useCallback, useContext, useEffect, useState } from 'react'

import { ReactChannelIOContext } from '@plco-pro/providers/channel-talk'

export const useChannelTalk = () => {
  const { isInitialized, channelTalk } = useContext(ReactChannelIOContext)
  const [isOpenedMessenger, setIsOpenedMessenger] = useState(false)

  const stop = useCallback(() => {
    if (!isInitialized) {
      return
    }

    channelTalk?.shutdown()
  }, [channelTalk, isInitialized])

  const show = useCallback(() => {
    if (!isInitialized) {
      return
    }

    channelTalk?.showChannelButton()
  }, [channelTalk, isInitialized])

  const hide = useCallback(() => {
    if (!isInitialized) {
      return
    }

    channelTalk?.hideChannelButton()
  }, [channelTalk, isInitialized])

  const track = useCallback(
    (eventName: string, eventProperty?: Record<string, unknown>) => {
      if (!isInitialized) {
        return
      }

      return channelTalk?.track(eventName, eventProperty)
    },
    [channelTalk, isInitialized],
  )

  const setPage = useCallback(
    (url: string) => {
      if (!isInitialized) {
        return
      }

      return channelTalk?.setPage(url)
    },
    [channelTalk, isInitialized],
  )

  // viewer data 를 불러오지 못했을때 강제로 boot 한다.
  const boot = useCallback(() => {
    return channelTalk?.boot()
  }, [channelTalk])

  const showMessenger = () => {
    channelTalk?.showMessenger()
  }

  const hideMessenger = () => {
    channelTalk?.hideMessenger()
  }

  // 메신저 on, off 정보를 isOpenMessenger 상태로 관리한다.
  useEffect(() => {
    const onShowMessenger = () => {
      channelTalk?.onShowMessenger(() => setIsOpenedMessenger(true))
    }
    const onHideMessenger = () => {
      channelTalk?.onHideMessenger(() => setIsOpenedMessenger(false))
    }

    if (isInitialized) {
      onShowMessenger()
      onHideMessenger()
    }
  }, [isInitialized, channelTalk])

  return {
    stop,
    show,
    hide,
    track,
    setPage,
    boot,
    showMessenger,
    hideMessenger,
    isOpenedMessenger,
  }
}
