export const notable = {
  en: {
    'notable.card.condition-text': "'s condition is not good.",
    'notable.card.workload_acxr-text':
      "'s injury risk is out of optimal. Please monitor closely as injuries may occur.",
    'notable.card.soreness-text': "'s soreness is 3~4 which is severe.",
    'notable.card.soreness-max-text': "'s soreness is 5 which is very severe.",

    'notable.card.button.notify': 'Confirm & Notify',
    'notable.card.button.view-player': 'View player data',

    'notable.dropdown.title': 'Notable Players',
    'notable.dropdown.help-content.title': 'Send notifications to the players!',
    'notable.dropdown.help-content.close-title': 'Don’t show again',
    'notable.dropdown.help-content.list-1':
      'Monitor your notable players easily. Just press [Confirm] and notify your players that their records have been confirmed.',
    'notable.dropdown.help-content.list-2': 'You can choose to send this notification daily.',

    'notable.dropdown.button.notification-all': 'Confirm and notify all',
    'notable.dropdown.caption.notification-all':
      'Send a notification to players on the list by tapping the button above.',

    'notable.dropdown.body.empty.title': 'No notifications yet',
    'notable.dropdown.body.empty.subtitle':
      'No players need monitoring at the moment 👍 \n' +
      "We'll let you know if there's anything you need to check.",
  },
  ko: {
    'notable.card.condition-text': '님의 컨디션이 좋지 않아요.',
    'notable.card.workload_acxr-text':
      '님의 부상위험도가 최적에서 벗어났어요. 부상을 주의해야합니다.',
    'notable.card.soreness-text': '님의 통증이 3~4단계로 심한 상태입니다.',
    'notable.card.soreness-max-text': '님의 통증이 5단계로 매우 심한 상태입니다.',

    'notable.card.button.notify': '확인 및 알림 보내기',
    'notable.card.button.view-player': '선수 데이터 보기',

    'notable.dropdown.title': '주요 선수 알림',
    'notable.dropdown.help-content.title': '선수들에게 알림 보내기',
    'notable.dropdown.help-content.close-title': '다시보지않기',
    'notable.dropdown.help-content.list-1':
      "모니터링이 꼭 필요한 주요 선수들을 알려드려요. [확인]을 누르고 선수들에게 ‘이 기록을 확인했다'는 알림을 보내보세요.",
    'notable.dropdown.help-content.list-2': '1일을 기준으로, 매일 새로운 알림을 보낼 수 있어요.',

    'notable.dropdown.button.notification-all': '모두 확인 누르기',
    'notable.dropdown.caption.notification-all':
      '모두 확인을 누르고 리스트에 있는 선수들에게 알림을 보냅니다.',

    'notable.dropdown.body.empty.title': '아직 특이사항이 없어요',
    'notable.dropdown.body.empty.subtitle':
      '모니터링이 꼭 필요한 주요 선수들이 아직 없어요 👍\n' +
      '확인해야 할 사항이 있다면 그때 알려드릴게요.',
  },
  ja: {
    'notable.card.condition-text': ' の状態は良くありません。', // '님의 컨디션이 좋지 않아요.',
    'notable.card.workload_acxr-text':
      ' の怪我のリスクは最適ではありません。怪我をする恐れがありますので、よく観察してください。', // '님의 부상위험도가 최적에서 벗어났어요. 부상을 주의해야합니다.',
    'notable.card.soreness-text': '様の痛みが3~4段階とひどい状態です。', // '님의 통증이 3~4단계로 심한 상태입니다.',
    'notable.card.soreness-max-text': 'の痛みは5段階で非常に深刻です。', // '님의 통증이 5단계로 매우 심한 상태입니다.',

    'notable.card.button.notify': '確認して通知する', // '확인 및 알림 보내기',
    'notable.card.button.view-player': '選手データを見る', // '선수 데이터 보기',

    'notable.dropdown.title': '注目選手', // '주요 선수 알림',
    'notable.dropdown.help-content.title': '選手に通知を送ろう！', // '선수들에게 알림 보내기',
    'notable.dropdown.help-content.close-title': '今後表示しない', // '다시보지않기',
    'notable.dropdown.help-content.list-1':
      '注目のプレーヤーを簡単に監視します。 [確認] を押して、記録が確認されたことをプレイヤーに通知するだけです。', // "모니터링이 꼭 필요한 주요 선수들을 알려드려요. [확인]을 누르고 선수들에게 ‘이 기록을 확인했다'는 알림을 보내보세요.",
    'notable.dropdown.help-content.list-2': 'この通知を毎日送信することを選択できます。', // '1일을 기준으로, 매일 새로운 알림을 보낼 수 있어요.',

    'notable.dropdown.button.notification-all': '確認し、全員に通知する', // '모두 확인 누르기',
    'notable.dropdown.caption.notification-all':
      '上のボタンをタップして、リストのプレイヤーに通知を送信します。', // '모두 확인을 누르고 리스트에 있는 선수들에게 알림을 보냅니다.',

    'notable.dropdown.body.empty.title': 'まだ通知はありません', // '아직 특이사항이 없어요',
    'notable.dropdown.body.empty.subtitle':
      '現在、監視が必要なプレイヤーはいません👍 \n' + '確認すべき事項があればお知らせします。', // '모니터링이 꼭 필요한 주요 선수들이 아직 없어요 👍\n' + '확인해야 할 사항이 있다면 그때 알려드릴게요.'
  },
}
