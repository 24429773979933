import {
  FSportsTeamFragment,
  FSportsTeamInvitationRequestFragment,
  FSportsTeamUserMapFragment,
} from '@plco-pro/graphqls/react.generated'

class ViewerTeam {
  private createTeamMaps = (teamUserMap: FSportsTeamUserMapFragment[]): FSportsTeamFragment[] => {
    const teamMapsTeams = teamUserMap.map((teamMap) => teamMap.team)

    return [...(teamMapsTeams || [])]
  }

  private getCurrentActiveTeam = (activeTeamId: string, teamMaps: FSportsTeamFragment[]) => {
    const currentActiveTeam = teamMaps.find((team) => team?.id === activeTeamId) || teamMaps[0]

    return currentActiveTeam
  }

  private getTeamAuthority = (
    currentActiveTeam: FSportsTeamFragment,
    teamUserMap: FSportsTeamUserMapFragment[],
    activeInvitationRequestsTeam?: FSportsTeamInvitationRequestFragment,
  ) => {
    const activeTeamMapsTeamAuthority = teamUserMap.find(
      (teamMap) => teamMap.team.id === currentActiveTeam.id,
    )?.teamAuthority

    const activeInvitationRequestsTeamAuthority = activeInvitationRequestsTeam?.teamAuthority
    const activeTeamAuthority = activeTeamMapsTeamAuthority || activeInvitationRequestsTeamAuthority

    return activeTeamAuthority
  }

  public teamMaps = (teamUserMap?: FSportsTeamUserMapFragment[]) => {
    if (!teamUserMap) return []

    return this.createTeamMaps(teamUserMap)
  }

  public currentActiveTeam = (
    activeTeamId?: string | null,
    teamUserMap?: FSportsTeamUserMapFragment[],
  ) => {
    if (!activeTeamId || !teamUserMap) {
      return null
    }

    const teamMaps = this.createTeamMaps(teamUserMap)

    return this.getCurrentActiveTeam(activeTeamId, teamMaps)
  }

  public teamAuthority = (
    activeTeamId?: string | null,
    teamUserMap?: FSportsTeamUserMapFragment[],
  ) => {
    if (!activeTeamId || !teamUserMap) return null

    const teamMaps = this.createTeamMaps(teamUserMap)
    const currentActiveTeam = this.getCurrentActiveTeam(activeTeamId, teamMaps)

    return this.getTeamAuthority(currentActiveTeam, teamUserMap)
  }

  public teamInvitationStatus = (
    activeTeamId?: string | null,
    teamUserMap?: FSportsTeamUserMapFragment[],
  ) => {
    if (!activeTeamId || !teamUserMap) return null

    return
  }

  public isInvitationPending = (
    activeTeamId?: string | null,
    teamUserMap?: FSportsTeamUserMapFragment[],
  ) => {
    if (!activeTeamId || !teamUserMap) return false

    return false
  }
}

export const viewerTeam = new ViewerTeam()
