import { FeedbackDataFragment, FeedbackType } from '@plco-pro/graphqls/react.generated'
import { useMappingFeedback } from '@plco-pro/hooks/use-mapping-feedback'

import { Flex, FlexProps, Text } from '../../atoms'

const FeedbackTypeColorset = {
  [FeedbackType.WORKLOAD_MEMO]: {
    color: 'violet-500',
    backgroundColor: 'violet-100',
  },
  [FeedbackType.WORKLOAD_RISK_AT_INJURY]: {
    color: 'pink-500',
    backgroundColor: 'pink-100',
  },
  [FeedbackType.CONDITION_NOT_GOOD]: {
    color: 'teal-600',
    backgroundColor: 'teal-100',
  },
  [FeedbackType.CONDITION_PLUMMET]: {
    color: 'teal-600',
    backgroundColor: 'teal-100',
  },
  [FeedbackType.SORENESS_SEVERE]: {
    color: 'amber-700',
    backgroundColor: 'amber-100',
  },
} as const

interface FeedbackTypeChipProps extends FlexProps {
  feedback: Pick<FeedbackDataFragment, 'type' | 'contentsData'>
}

export const FeedbackTypeChip = ({ feedback, ...props }: FeedbackTypeChipProps) => {
  const { getFeedbackListType } = useMappingFeedback()

  const type = feedback.type as Exclude<FeedbackType, FeedbackType.NORMAL | FeedbackType.REQUEST>
  const tagName = getFeedbackListType(feedback)

  return (
    <Flex
      sx={{
        backgroundColor: FeedbackTypeColorset[type].backgroundColor,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        px: '8px',
        height: '26px',
      }}
      {...props}
    >
      <Text sx={{ color: FeedbackTypeColorset[type].color, fontSize: '11px', fontWeight: 700 }}>
        {tagName}
      </Text>
    </Flex>
  )
}
