export type FeedbackContentsType = 'add' | 'detail'

export enum ConditionState {
  MOOD,
  SLEEP_TIME,
  SLEEP_QUALITY,
}

export interface ConditionChartData {
  x: number
  y: number
}
