import { Flex, FlexProps, Icon } from '@plco-pro/components/atoms'

interface Props extends FlexProps {
  isChecked: boolean
  size: number
}

export const ButtonRadio = ({ isChecked, size, ...rest }: Props) => {
  return (
    <Flex
      sx={{
        width: '40px',
        height: '40px',
        p: 1,
        borderRadius: '20px',
        flexShrink: 0,
      }}
      {...rest}
    >
      <Icon
        src={isChecked ? '/images/ic-radio-checked.svg' : '/images/ic-radio-unchecked.svg'}
        size={size}
      />
    </Flex>
  )
}
