import { Global } from '@emotion/react'
import { startOfDay, endOfDay, subDays } from 'date-fns'
import { MdCalendarToday } from 'react-icons/md'
import { DateRangePicker as RSuiteDateRangePicker } from 'rsuite'
import { DateRange } from 'rsuite/esm/DateRangePicker'

import { DateFormats } from '@plco-pro/constants'
import { useI18n } from '@plco-pro/hooks'
import { useMoment } from '@plco-pro/hooks/moment'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { palette } from '@plco-pro/themes/main'

import { Flex } from '../atoms'

const { combine, allowedMaxDays, afterToday } = RSuiteDateRangePicker

interface DateRangePickerProps {
  /**
   * @example
   * const [value, setValue] = useState<[Date, Date]>(() => {
    const monthAgo = moment().subtract({ days: 31 }).toDate()
    return [monthAgo, new Date()]
   })
  })
   */
  value: [Date, Date]
  /**
   * value 변경 이벤트
   */
  onChange: (value: DateRange) => void
  /**
   * DatePicker 열릴 때 이벤트
   */
  onOpen?: () => void
  /**
   * DatePicker 닫힐 때 이벤트
   */
  onClose?: () => void
}

export const DateRangePicker = ({ value, onChange, onOpen, onClose }: DateRangePickerProps) => {
  const moment = useMoment()
  const { formatMessage: f } = useI18n()
  const { mdAndDown } = useResponsiveContext()

  return (
    <Flex sx={{ display: 'inline-flex' }}>
      {/* Global CSS */}
      <Global
        styles={{
          // 팝업의 위치 수정
          '.rs-picker-popup': {
            marginTop: '4px',
            zIndex: 801,
          },
          // 폰트 추가
          '.rs-picker-daterange-panel': {
            fontFamily: 'Noto Sans KR,sans-serif',
          },
          // Input 커스텀 CSS
          '.rs-date-range-input': {
            fontFamily: 'Noto Sans KR,sans-serif',
            fontWeight: 700,
            fontSize: '14px !important',
            color: `${palette['grey-600']} !important`,
          },
          // Input Hover 커스텀
          '.rs-input-group:not(.rs-input-group-disabled):hover': {
            borderColor: palette['primary-300'],
          },
          // Input Focus-Within 커스텀
          '.rs-input-group:focus-within, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus':
            {
              outline: 'none',
              // outline: '2px solid',
              // outlineColor: palette['primary-400'],
              borderWidth: '1px',
              borderColor: palette['primary-400'],
            },
          // Input > 오른쪽 캘린더 아이콘을 감싸는 span 태그 커스텀
          '.rs-input-group-addon': {
            color: `${palette['grey-600']} !important`,
            backgroundColor: 'white !important',
            borderTopRightRadius: '6px !important',
            borderBottomRightRadius: '6px !important',
          },
          '.rs-calendar-header-title-date': {
            fontFamily: 'Noto Sans KR,sans-serif',
          },
          '.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges':
            {
              width: 'auto',
            },
          // 푸터 > 프리셋 영역의 버튼
          '.rs-picker-toolbar-ranges .rs-btn': {
            fontFamily: 'Noto Sans KR,sans-serif',
            fontSize: '14px',
            fontWeight: 700,
            textDecoration: 'none',
            color: palette['primary-500'],
          },
          '.rs-picker-toolbar-ranges .rs-btn:hover': {
            backgroundColor: palette['primary-500-16'],
          },
          '.rs-picker-toolbar-ranges .rs-btn:active': {
            backgroundColor: palette['primary-200'],
          },

          // 푸터 > 우측 OK 버튼
          '.rs-picker-toolbar-right .rs-btn': {
            fontFamily: 'Noto Sans KR,sans-serif',
            minWidth: '80px',
            height: '32px',
            fontSize: '14px',
            fontWeight: 700,
            backgroundColor: palette['primary-500'],
          },
          '.rs-picker-toolbar-right .rs-btn:hover': {
            backgroundColor: palette['primary-600'],
          },
          '.rs-picker-toolbar-right .rs-btn:active': {
            backgroundColor: palette['primary-700'],
          },
          // 푸터 > 우측 OK 버튼(비활성화)
          '.rs-picker-toolbar-right .rs-btn.rs-btn-disabled': {
            opacity: 1,
            backgroundColor: palette['primary-500'],
            color: palette['grey-300'],
          },
          //
          '.rs-calendar-table-cell-selected .rs-calendar-table-cell-content': {
            backgroundColor: '#3498FF',
          },
          '.rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content':
            {
              backgroundColor: '#1775DF',
            },
          '.rs-calendar-table-cell-in-range:before': {
            backgroundColor: '#E5F3FE',
          },
          '.rs-input-group': {
            cursor: 'pointer',
          },
          '.rs-input-group.rs-input-group-inside .rs-input': {
            pointerEvents: 'none',
          },
        }}
      />

      <RSuiteDateRangePicker
        value={value}
        showOneCalendar={mdAndDown}
        cleanable={false}
        editable={false}
        locale={{
          ok: f({ id: 'APPLY' }),
          monday: f({ id: 'MON' }),
          tuesday: f({ id: 'TUE' }),
          wednesday: f({ id: 'WED' }),
          thursday: f({ id: 'THU' }),
          friday: f({ id: 'FRI' }),
          saturday: f({ id: 'SAT' }),
          sunday: f({ id: 'SUN' }),
        }}
        placement={mdAndDown ? 'bottomStart' : 'bottomEnd'}
        style={{ width: '240px' }}
        character=" - "
        limitStartYear={10}
        limitEndYear={1}
        size="lg"
        caretAs={MdCalendarToday}
        format={DateFormats['yyyy.MM.dd']}
        onOpen={onOpen}
        onClose={onClose}
        contentEditable={false}
        css={{ cursor: 'pointer' }}
        isoWeek={true}
        className="rs-calendar-container"
        renderTitle={(date) => {
          return moment(date).format(f({ id: 'date-range.title-format' }))
        }}
        ranges={[
          {
            label: f({ id: 'date-range.7-days' }),
            value: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
          },
          {
            label: f({ id: 'date-range.30-days' }),
            value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
          },
        ]}
        shouldDisableDate={combine?.(allowedMaxDays?.(31), afterToday?.())}
        onChange={(value) => {
          if (value) {
            onChange(value)
          }
        }}
      />
    </Flex>
  )
}
