import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { Avatar } from '@plco-pro/components/molecules/avatar'

export type Props = {
  name?: string
  pictureUrl?: string
}

export const WorkloadHistoryUserInfo = ({ name, pictureUrl }: Props) => {
  return (
    <Flex sx={{ alignItems: 'center', gap: 1, pb: 2 }}>
      <Box sx={{ width: 24, height: 24, flex: 'none' }}>
        <Avatar size={24} src={pictureUrl} value={pictureUrl ? '' : name} />
      </Box>
      <Text sx={{ fontWeight: '500' }} variant={'p1'} appearance={'hint'} ellipsis>
        {name}
      </Text>
    </Flex>
  )
}
