import { Fragment, useCallback } from 'react'

import { CardPlayerFeedback } from '@plco-pro/components/organisms/card-player-feedback/card-player-feedback'
import { CardPlayerInfo } from '@plco-pro/components/organisms/card-player-info/card-player-info'
import { CardPlayerInjury } from '@plco-pro/components/organisms/card-player-injury/card-player-injury'
import { CardPlayerReadiness } from '@plco-pro/components/organisms/card-player-readiness/card-player-readiness'
import { CardPlayerSoreness } from '@plco-pro/components/organisms/card-player-soreness/card-player-soreness'
import { PlayerSelectedType } from '@plco-pro/components/organisms/dialog-player-select'
import { LayoutPlayerSummary } from '@plco-pro/components/templates/layout-player-summary/layout-player-summary'
import {
  PlayerSummaryQuery,
  SportsBodyPart,
  usePlayerModalLatestRawBodyQuery,
  usePlayerSummaryQuery,
} from '@plco-pro/graphqls/react.generated'
import { useNationality } from '@plco-pro/hooks'
import { useAuthorization } from '@plco-pro/hooks/authorization'
import { useMoment } from '@plco-pro/hooks/moment'
import { usePlayerInjuryData } from '@plco-pro/hooks/player-injury-data'
import { useStore } from '@plco-pro/stores'

export enum InjuryModalType {
  EDIT = 'edit',
  REGISTER = 'register',
}

type Props = {
  teamId: string
  playerId: string
  summaryUserRef?: (element: HTMLDivElement | null) => void
}

export const PlayerSummaryContainer = ({ teamId, playerId, summaryUserRef }: Props) => {
  const moment = useMoment()
  const { hasPermission } = useAuthorization()
  const { preference, players } = useStore()
  const nationality = useNationality()

  const { setPlayerId, setIsPlayerStarred } = players

  const { timezone } = preference.locale

  const from = moment().startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
  const to = moment().endOf('day').second(0).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)

  // summary query
  const onCompleted = useCallback(
    (data: PlayerSummaryQuery) => {
      if (!data.sports.user?.id) {
        setPlayerId(null)
      }

      setIsPlayerStarred(data.sports.user?.isStarredBy || null)
    },
    [setIsPlayerStarred, setPlayerId],
  )

  const onError = useCallback(() => {
    setPlayerId(null)
  }, [setPlayerId])

  const { data, loading } = usePlayerSummaryQuery({
    variables: {
      input: {
        nationality,
      },
      teamId,
      playerId,
      starerId: playerId,
      from,
      to,
      timezone,
      hasPermissionViewStarredPlayers: hasPermission('VIEW_STARRED_PLAYERS'),
    },
    onCompleted,
    onError,
  })

  const { data: latestRawBodyData } = usePlayerModalLatestRawBodyQuery({
    variables: {
      playerId,
      timezone,
    },
  })

  const {
    injuryDummyGender,
    injuryDummyData,
    loading: injuryLoading,
    lastInjuredAt,
    navigateInjuryHistoryModal,
  } = usePlayerInjuryData({
    playerId,
  })

  const user = data?.sports.user
  const isPlayerStarred = user?.isStarredBy

  const latestRawBody = latestRawBodyData?.sports.user?.latestRawBody
  const weight = latestRawBody ? `${latestRawBody?.weight}${latestRawBody?.weightUnit}` : ''
  const height = latestRawBody ? `${latestRawBody?.height}${latestRawBody.heightUnit}` : ''

  const onClickPart = (part: SportsBodyPart) => {
    navigateInjuryHistoryModal(part.id)
  }

  return (
    <Fragment>
      <LayoutPlayerSummary
        info={
          <CardPlayerInfo
            loading={loading}
            user={user}
            height={height}
            weight={weight}
            isPlayerStarred={!!isPlayerStarred}
          />
        }
        infoRef={summaryUserRef}
        readiness={<CardPlayerReadiness loading={loading} playerId={playerId} />}
        soreness={
          <CardPlayerSoreness
            loading={injuryLoading}
            injuryData={injuryDummyData}
            lastInjuredAt={lastInjuredAt}
            gender={injuryDummyGender}
            onClickPart={onClickPart}
          />
        }
        injury={<CardPlayerInjury teamId={teamId} playerId={playerId} gender={injuryDummyGender} />}
        feedback={<CardPlayerFeedback player={user as PlayerSelectedType} />}
      />
    </Fragment>
  )
}
