import { Flex, Text } from '@plco-pro/components/atoms'
import { useI18n } from '@plco-pro/hooks'

export const EmptySuggestionFeedback = () => {
  const { formatMessage } = useI18n()

  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center', py: 5 }}>
      <Text sx={{ fontSize: 'h1' }}>{'👋'}</Text>
      <Text sx={{ fontSize: 's2', fontWeight: 500, color: 'grey-400' }}>
        {formatMessage({ id: 'feedback.no-specific-suggestions' })}
      </Text>
    </Flex>
  )
}
