import React, { MouseEventHandler, useCallback, useMemo } from 'react'

import styled from '@emotion/styled'
import { variant, VariantArgs } from 'styled-system'
import { ResponsiveStyleValue } from 'theme-ui'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Icon, IconProps } from '@plco-pro/components/atoms/icon'
import { useResponsive } from '@plco-pro/hooks/responsive'

export type IconButtonSize = 'xs' | 'sm' | 'md' | 'lg'
export type IconButtonShape = 'circle' | 'square'
export type IconButtonGap = 'none' | 'sm' | 'md' | 'lg'

export type IconButtonProps = FlexProps & {
  src: string
  disabled?: boolean
  size?: ResponsiveStyleValue<IconButtonSize>
  gap?: ResponsiveStyleValue<IconButtonGap>
  iconSize?: IconProps['size']
  shape?: ResponsiveStyleValue<IconButtonShape>
  variantDisabled?: ResponsiveStyleValue<string>
  disabledColor?: FlexProps['color']
  interactionAreaProps?: BoxProps
}

export type IconContainerFlexProps = Omit<FlexProps, 'size' | 'shape'> & {
  size?: IconButtonSize
  shape?: IconButtonShape
  gap?: IconButtonGap
  disabled?: boolean
}

const size = variant({
  prop: 'size',
  variants: {
    xs: {
      width: '24px',
      height: '24px',
    },
    sm: {
      width: '32px',
      height: '32px',
    },
    md: {
      width: '36px',
      height: '36px',
    },
    lg: {
      width: '40px',
      height: '40px',
    },
  },
} as VariantArgs)

const gap = variant({
  prop: 'gap',
  variants: {
    none: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    sm: {
      marginTop: '-4px',
      marginBottom: '-4px',
      marginLeft: '-4px',
      marginRight: '-4px',
    },
    md: {
      marginTop: '-6px',
      marginBottom: '-6px',
      marginLeft: '-6px',
      marginRight: '-6px',
    },
    lg: {
      marginTop: '-8px',
      marginBottom: '-8px',
      marginLeft: '-8px',
      marginRight: '-8px',
    },
  },
} as VariantArgs)

const shape = variant({
  prop: 'shape',
  variants: {
    circle: {
      borderRadius: '50%',
    },
    square: {
      borderRadius: 4,
    },
  },
} as VariantArgs)

const IconContainerFlex = styled(Flex)<IconContainerFlexProps>(size, shape, gap)

export const IconButton: React.FunctionComponent<IconButtonProps> = ({
  src,
  disabled = false,
  variant = 'default',
  variantDisabled = 'disabled',
  size = 'md',
  gap = 'none',
  shape = 'circle',
  color,
  disabledColor,
  iconSize,
  interactionAreaProps,
  onClick,
  ...rest
}) => {
  const getResponsiveProp = useResponsive()

  const responsiveVariant = useMemo(() => getResponsiveProp(variant), [variant, getResponsiveProp])
  const responsiveVariantDisabled = useMemo(
    () => getResponsiveProp(variantDisabled),
    [variantDisabled, getResponsiveProp],
  )
  const responsiveShape = useMemo(() => getResponsiveProp(shape), [shape, getResponsiveProp])
  const responsiveSize = useMemo(() => getResponsiveProp(size), [size, getResponsiveProp])
  const responsiveGap = useMemo(() => getResponsiveProp(gap), [gap, getResponsiveProp])

  const computedVariant = useMemo(() => {
    const variantPart = disabled ? '' : responsiveVariant
    const disabledPart = disabled ? responsiveVariantDisabled : ''

    return `iconButton.${variantPart}${disabledPart}`
  }, [disabled, responsiveVariant, responsiveVariantDisabled])

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (disabled || !onClick) {
        return
      }

      onClick(event)
    },
    [disabled, onClick],
  )

  return (
    <Box
      {...interactionAreaProps}
      sx={{
        ...(disabled ? {} : { cursor: 'pointer' }),
        ...interactionAreaProps?.sx,
      }}
      onClick={disabled ? () => {} : handleClick}
    >
      <IconContainerFlex
        disabled={disabled}
        {...rest}
        variant={computedVariant}
        shape={responsiveShape}
        size={responsiveSize}
        gap={responsiveGap}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          ...rest.sx,
          userSelect: 'none', // prevent text selection
        }}
      >
        <Icon
          src={src}
          disabled={disabled}
          sx={{ color: color }}
          disabledColor={disabledColor}
          size={iconSize}
        />
      </IconContainerFlex>
    </Box>
  )
}
