import { useMemo } from 'react'

import { messages } from '@common/i18ns/i18n.en'
import { DataInterpretationItem, dataInterpreter, DataValueDegree } from '@common/utils/libs'

import { SportsDataName } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'

export type TranslatedData<T = number | null> = {
  value: T
  name: string | undefined
  _name: SportsDataName | undefined
} & Partial<Omit<DataInterpretationItem, 'degree'>> & {
    degree?: DataValueDegree
  }

const MISSING = '_'

const i18nHasValue = (key: string) => {
  // check for falsy values (undefined, null, etc...)
  // undefined, null or "" are treated as error by formatMessage
  return (messages as any)[`DATA.${key}`]
}

export function useDataInterpreter() {
  const { formatMessage: f } = useI18n()

  return useMemo(() => {
    return function <T = number>(
      path: string,
      value: any,
      map?: (value: number | null) => T | null,
    ) {
      const result = dataInterpreter.get(path, value)

      const translated: TranslatedData<T> = {
        value: result.value,
        name: result.name,
        _name: result.name,
        ...(result.interpretation as any),
      }

      if (translated.name) {
        if (!i18nHasValue(translated.name)) {
          translated.name = undefined
        } else {
          translated.name = f({
            id: `DATA.${translated.name}`,
            defaultMessage: translated.name,
          })
        }
      }

      if (translated.description) {
        if (!i18nHasValue(translated.description)) {
          translated.description = undefined
        } else {
          const v = f({
            id: `DATA.${translated.description}`,
            defaultMessage: MISSING,
          })
          translated.description = v === MISSING ? undefined : v
        }
      }

      if (translated.label) {
        if (!i18nHasValue(translated.label)) {
          translated.label = undefined
        } else {
          const v = f({
            id: `DATA.${translated.label}`,
            defaultMessage: MISSING,
          })
          translated.label = v === MISSING ? undefined : v
        }
      }

      if (map) {
        const mappingValue = map(translated.value as any)
        if (mappingValue) {
          translated.value = mappingValue
        }
      }

      return translated
    }
  }, [f])
}
