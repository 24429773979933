import { Fragment, useMemo } from 'react'

import { Moment } from 'moment'

import { FeedbackDataFragment, FeedbackType, Gender } from '@plco-pro/graphqls/react.generated'
import { useMappingFeedback } from '@plco-pro/hooks/use-mapping-feedback'

import { Box, Flex, Text } from '../atoms'
import { PlayerSelectedType } from '../organisms/dialog-player-select'
import { DateChip, FeedbackTypeChip } from './components'
import { BadCondition, PlummedCondition } from './condition'
import { FeedbackContentsType } from './helpers'
import { InjuryRisk } from './injury-risk'
import { Soreness } from './soreness'
import { WorkloadMemo } from './workload-memo'

interface FeedbackContentsProps {
  player: PlayerSelectedType
  date: Moment
  feedback: Pick<FeedbackDataFragment, 'type' | 'contentsData'>
  feedbackContentsType?: FeedbackContentsType
}

/**
 * "피드백 등록" 또는 "피드백 상세보기" 모달에 나타나는 컨텐츠 컴포넌트
 */
export const FeedbackContents = ({
  player,
  date,
  feedback,
  feedbackContentsType = 'add',
}: FeedbackContentsProps) => {
  const { getFeedbackSuggestionContents, getFeedbackListTitle } = useMappingFeedback()

  const { type } = feedback

  const contentsData = getFeedbackSuggestionContents(feedback)

  const contents = useMemo(() => {
    // 훈련 메모, 경기 메모, 연습 경기 메모, 운동 메모
    if (type === FeedbackType.WORKLOAD_MEMO && contentsData?.workloadMemoData) {
      return (
        <WorkloadMemo
          name={player.name || ''}
          pictureUrl={player.pictureUrl || ''}
          workloadMemoData={contentsData.workloadMemoData}
        />
      )
    }

    // 운동부하(부상위험)
    if (type === FeedbackType.WORKLOAD_RISK_AT_INJURY && contentsData?.workloadRiskAtInjuryData) {
      return (
        <InjuryRisk
          name={player.name || ''}
          workloadRiskAtInjuryData={contentsData.workloadRiskAtInjuryData}
          feedbackContentsType={feedbackContentsType}
        />
      )
    }

    // 안 좋은 컨디션
    if (type === FeedbackType.CONDITION_NOT_GOOD && contentsData?.conditionNotGoodData) {
      return (
        <BadCondition
          name={player.name || ''}
          conditionNotGoodData={contentsData.conditionNotGoodData}
          feedbackContentsType={feedbackContentsType}
        />
      )
    }

    // 급락한 컨디션
    if (type === FeedbackType.CONDITION_PLUMMET && contentsData?.conditionPlummetData) {
      return (
        <PlummedCondition
          name={player.name || ''}
          conditionPlummetData={contentsData.conditionPlummetData}
          feedbackContentsType={feedbackContentsType}
        />
      )
    }

    // 통증
    if (contentsData?.sorenessSevereData) {
      return (
        <Soreness
          name={player.name || ''}
          gender={player.gender as Gender}
          sorenessSevereData={contentsData.sorenessSevereData}
          feedbackContentsType={feedbackContentsType}
        />
      )
    }

    return <Fragment />
  }, [type, contentsData, feedbackContentsType, player])

  const title = getFeedbackListTitle(feedback)

  const showDateChip = useMemo(() => {
    return type !== FeedbackType.WORKLOAD_MEMO
  }, [type])

  return (
    <Box sx={{ flex: 1, backgroundColor: feedbackContentsType === 'add' ? 'white' : '#F7F9FC' }}>
      {/* 공통 영역 */}
      <Flex sx={{ alignItems: 'center', gap: 1, mb: 1, mx: [2, 3] }}>
        <FeedbackTypeChip feedback={feedback} />

        {showDateChip && <DateChip date={date} feedbackContentsType={feedbackContentsType} />}
      </Flex>

      {/* 제목 */}
      <Text variant="h5" sx={{ mx: [2, 3], mb: 2, fontWeight: 700 }}>
        {title}
      </Text>

      {contents}
    </Box>
  )
}
