// DATASET
export type CHART_TYPE =
  | 'BAR'
  | 'LINE'
  | 'AREA'
  | 'POLAR'
  | 'DOTTED_LINE'
  | 'STACK'
  | 'BACKGROUND_AREA'
  | 'WORKLOAD_BAR'
  | 'WORKLOAD_LINE'
  | 'WORKLOAD_STACK'

export type CHART_ANIMATE = 'FAST_CUBIC_IN_OUT' | 'FAST_CUBIC_IN_OUT_OPACITY'

export type DATASET_CORNER_RADIUS = 'SMALL_CORNER_RADIUS' | 'MEDIUM_CORNER_RADIUS'

export type Y_FORMAT =
  | '2.5_MAX'
  | '4_MAX'
  | '720_MAX'
  | '720_RE-SCALE_1'
  | '10_RE-SCALE_1'
  | '2.5_RE-SCALE_MAX_1'
  | '1500_MAX_RE-SCALE_1'
  | '1500_RE-SCALE_1'

export type POLAR_Y_FORMAT_DURATION = '72_RE-SCALE_1'

// DATASET - AXIS
export type AXIS_X_TICK_VALUE = 'SORT_ASC_TICK_VALUES' | 'SORT_DESC_TICK_VALUES'

export type AXIS_X_ORIENTATION = 'TOP' | 'BOTTOM'

export type AXIS_Y_TICK_VALUE =
  | 'Y_MIN'
  | 'Y_MIN_MINUS_5_ROUNDED_DOWN'
  | 'Y_MAX_2'
  | 'Y_MAX_2_ROUNDED_UP'
  | 'Y_MAX_10'
  | 'Y_MAX_10_ROUNDED_UP'
  | 'Y_MAX_100'
  | 'Y_MAX_100_ROUNDED_UP'
  | 'Y_MAX_180'
  | 'Y_MAX_180_ROUNDED_UP'
  | 'Y_MAX_200'
  | 'Y_MAX_200_ROUNDED_UP'
  | 'Y_MAX_600'
  | 'Y_MAX_600_ROUNDED_UP'
  | 'Y_MAX_1200'
  | 'Y_MAX_1200_ROUNDED_UP'
  | 'Y_MAX_1500'
  | 'Y_MAX_1500_ROUNDED_UP'
  | 'Y_MAX_1600'
  | 'Y_MAX_1600_ROUNDED_UP'
  | 'Y_MAX_PLUS_4_ROUNDED_UP'
  | 'Y_MAX_PLUS_400_ROUNDED_UP'
  | 'Y_MAX_PLUS_5_ROUNDED_UP'
  | 'Y_MAX_PLUS_5_DEFAULT_100_ROUNDED_UP'
  | 'Y_MAX_PLUS_5_DEFAULT_200_ROUNDED_UP'
  | 'Y_MAX_PLUS_60_DEFAULT_180_ROUNDED_UP'
  | 'Y_MAX_PLUS_400_DEFAULT_1600_ROUNDED_UP'
  | 'Y_MAX_2_TICK_VALUES'
  | 'Y_MAX_10_TICK_VALUES'
  | 'Y_MAX_180_TICK_VALUES'
  | 'Y_MAX_200_TICK_VALUES'
  | 'Y_MAX_600_TICK_VALUES'
  | 'Y_MAX_1200_TICK_VALUES'
  | 'Y_MAX_1600_TICK_VALUES'
  | 'Y_1_TICK_VALUES_DIVIDE_6'
  | 'Y_1_TICK_VALUES_DIVIDE_5'
  | 'Y_INJURY_LEVEL'
  | 'Y_10_TICK_VALUES'
  | 'Y_20_TICK_VALUES'
  | 'Y_180_TICK_VALUES'
  | 'Y_720_TICK_VALUES'
  | 'Y_720_TICK_VALUES_DIVIDE_5'
  | 'Y_1600_TICK_VALUES'
  | 'Y_MAX_PLUS_4_TICK_VALUES_5'
  | 'Y_MAX_PLUS_400_TICK_VALUES_5'
  | 'Y_MAX_PLUS_5_OR_100_TICK_VALUES_6'
  | 'Y_MAX_PLUS_5_OR_200_TICK_VALUES_6'
  | 'Y_MAX_PLUS_400_OR_1600_TICK_VALUES_5'
  | 'Y_MAX_PLUS_60_OR_180_TICK_VALUES'
  | 'Y_TICK_VALUES_180_UP_DOWN'
  | 'Y_TICK_VALUES_1600_UP_DOWN'
  | 'DOMAIN_Y_4_ROUNDED_TICK_VALUES'

export type AXIS_Y_ORIENTATION = 'LEFT' | 'RIGHT'

export type AXIS_TICK_FORMAT =
  | 'POINT_FORMAT'
  | 'COUNT_FORMAT'
  | 'CM_FORMAT'
  | 'KG_FORMAT'
  | 'HOUR_FORMAT'
  | 'HOUR_MINUTE_FORMAT'
  | 'HOUR_MINUTE_FORMAT_12_PLUS'
  | '2_POINT_5_PLUS'
  | '4_PLUS'
  | 'NULL_STRING'
  | 'DAY_OF_WEEK'
  | 'DAY_OF_MONTH'
  | '1_RE-SCALE_720_HOUR_FORMAT'
  | '1_RE-SCALE_10'
  | '1_RE-SCALE_2.5+'
  | '1_RE-SCALE_1500_MAX'
  | 'TRANSLATE_UPPERCASE'
  | 'TRANSLATE_INJURY'

export type POLAR_AXIS_LABEL_PLACEMENT = 'PARALLEL' | 'PERPENDICULAR' | 'VERTICAL'

export type AXIS_STYLE =
  | 'AXIS_STYLE_INVISIBLE'
  | 'AXIS_TEXT_ANCHOR_MIDDLE'
  | 'AXIS_TEXT_ANCHOR_CONDITION'
export type AXIS_X_STYLE = 'AXIS_X_STYLE_INVISIBLE'

// DOMAIN
export type DOMAIN_Y = 'DOMAIN_Y_MAX_PLUS_4_ROUNDED_UP' | 'DOMAIN_Y_180_UP_DOWN'

export type DOMAIN_SIZE = 'DAYS_IN_MONTH' | number

// LABEL
export type TICK_LABEL_COMPONENT =
  | 'LABEL_AVATAR'
  | 'LABEL_TOOLTIP_MULTI_TEAM_DATA'
  | 'LABEL_TOOLTIP_WORKLOAD_HISTORY'
  | 'LABEL_TOOLTIP_MULTI_USER_DATA'
  | 'LABEL_RENDER_IN_PORTAL_ANCHOR_END'
  | 'LABEL_DOTTED_LINE'
  | 'LABEL_AREA_CENTER'

export type LABEL_CONFIG_PREFIX = 'AVG_STRING' | 'GOAL'

export type LABEL_CONFIG_WIDTH =
  | 'SORENESS_LABEL_TOOLTIP_WIDTH'
  | 'SMALL_LABEL_TOOLTIP_WIDTH'
  | 'MEDIUM_LABEL_TOOLTIP_WIDTH'
  | 'BIG_LABEL_TOOLTIP_WIDTH'
  | 'XBIG_LABEL_TOOLTIP_WIDTH'
  | 'WORKLOAD_TOOLTIP_WIDTH'

export const ChartSystemPropsMapKeys = [
  'GET_STYLE',
  'GET_EVENTS',
  'GET_COLOR_SCALE',
  'GET_COLOR_SCALE_HOVER',
] as const

export type ChartSystemPropsMapKey = (typeof ChartSystemPropsMapKeys)[number]

// TODO: Rename
type Key =
  | ChartSystemPropsMapKey
  | CHART_TYPE
  | CHART_ANIMATE
  | AXIS_X_TICK_VALUE
  | AXIS_Y_TICK_VALUE
  | AXIS_TICK_FORMAT
  | TICK_LABEL_COMPONENT
  | DOMAIN_Y
  | Y_FORMAT
  | POLAR_Y_FORMAT_DURATION
  | POLAR_AXIS_LABEL_PLACEMENT
  | LABEL_CONFIG_PREFIX
  | AXIS_STYLE
  | AXIS_Y_ORIENTATION
  | AXIS_X_ORIENTATION
  | DOMAIN_SIZE
  | LABEL_CONFIG_WIDTH
  | DATASET_CORNER_RADIUS
  | AXIS_X_STYLE

export type ChartSystemPropsMap = Map<Key, Function>
export const chartSystemPropsMap: ChartSystemPropsMap = new Map()
