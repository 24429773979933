import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react'

import { __CLIENT_ENV__, __ENV__ } from '@plco-pro/env'
import { useNavigationViewerQuery } from '@plco-pro/graphqls/react.generated'
import ChannelTalk from '@plco-pro/utils/libs/channel-talk'
import { IChannelTalk } from '@plco-pro/utils/libs/channel-talk.types'

interface ReactChannelIOContextValue {
  isInitialized: boolean
  setIsInitialized: Dispatch<SetStateAction<boolean>>
  channelTalk: IChannelTalk | undefined
}

export const ReactChannelIOContext = createContext(null as unknown as ReactChannelIOContextValue)

const ChannelTalkProvider = ({ children }: { children?: ReactNode }) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [channelTalk, setChannelTalk] = useState<IChannelTalk>()

  // query to fetch viewer
  const { data } = useNavigationViewerQuery({
    fetchPolicy: 'cache-only',
  })

  const memberId = data?.sports.viewer?.id

  useEffect(() => {
    const dynamicallyImportPackage = () => {
      if (isInitialized) {
        return
      }

      if (__ENV__ === 'development') {
        return
      }

      setChannelTalk(new ChannelTalk())
    }

    dynamicallyImportPackage()
  }, [isInitialized])

  useEffect(() => {
    if (!memberId) {
      return
    }

    const settings = {
      zIndex: 1000,
    }

    channelTalk?.boot(
      {
        memberId: __CLIENT_ENV__ === 'release' ? memberId : `${__CLIENT_ENV__}-${memberId}`,
        /**
         * 기본 채널톡 버튼을 보이지 않게
         * https://developers.channel.io/reference/web-customization-kr
         */
        hideChannelButtonOnBoot: true,
        ...settings,
      },
      () => {
        setIsInitialized(true)
      },
    )
  }, [channelTalk, memberId, setIsInitialized])

  useEffect(() => {
    const viewer = data?.sports.viewer

    const profile = {
      slug: viewer?.slug,
      name: viewer?.name,
      email: viewer?.email,
      federation: viewer?.federation,
      mobileNumber: viewer?.phoneNumber,
      birthdate: viewer?.birthdate,
      teams: viewer?.teamMaps.map(({ team }) => `${team.name} : ${team.viewerAuthority}`),
      sportsRole: viewer?.priorSportsRole,
    }

    channelTalk?.updateUser({ profile })
  }, [data, channelTalk])

  return (
    <ReactChannelIOContext.Provider value={{ isInitialized, setIsInitialized, channelTalk }}>
      {children}
    </ReactChannelIOContext.Provider>
  )
}

export default ChannelTalkProvider
