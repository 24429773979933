import { Fragment, useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import { Button, Card, Flex, Text, TextProps } from '@plco-pro/components/atoms'
import { GhostCard } from '@plco-pro/components/atoms/ghost-card'
import { AnchorSmIcon } from '@plco-pro/components/molecules'
import { TooltipActionHelp } from '@plco-pro/components/molecules/tooltip-action-help'
import { CardPlayerInjuryEmpty } from '@plco-pro/components/organisms/card-player-injury-empty/card-player-injury-empty'
import { ListItemPlayerInjury } from '@plco-pro/components/organisms/card-player-injury/list-item-player-injury'
import { DialogInjuryDetail } from '@plco-pro/components/organisms/dialog-injury-detail'
import { ModalInjury } from '@plco-pro/components/organisms/modal-injury'
import {
  Injury,
  InjuryStatus,
  SportsTeamAuthority,
  useUserCurrentInjuryListQuery,
  useUserPastInjuryListLazyQuery,
} from '@plco-pro/graphqls/react.generated'
import { useNationality, useViewer } from '@plco-pro/hooks'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useStore } from '@plco-pro/stores'
import { PLAYER_INJURY_REGISTER_HELP_STORAGE_KEY } from '@plco-pro/utils/libs'

const TitleText = (props: TextProps) => (
  <Text variant={['s1', 'h4']} sx={{ fontWeight: 'bold' }} {...props} />
)

interface Props {
  teamId: string
  playerId: string
  gender: 'man' | 'woman'
}

export const CardPlayerInjury = ({ teamId, playerId, gender }: Props) => {
  const { formatMessage } = useI18n()
  const router = useRouter()
  const { navigation } = useStore()

  const nationality = useNationality()
  const { teamAuthority } = useViewer()
  const isObserver = teamAuthority === SportsTeamAuthority.OBSERVER

  const [anchor, setAnchor] = useState<InjuryStatus>(InjuryStatus.IN_PROGRESS)
  const [injuryData, setInjuryData] = useState<Injury>()
  const [isOpenInjuryDetail, setIsOpenInjuryDetail] = useState(false)
  const [isOpenInjuryModal, setIsOpenInjuryModal] = useState(false)

  useEffect(() => {
    setAnchor(InjuryStatus.IN_PROGRESS)
  }, [playerId])

  const { data: currentInjuryList, loading: currentInjuryListLoading } =
    useUserCurrentInjuryListQuery({
      skip: !teamId || !playerId || !anchor || anchor === InjuryStatus.END,
      variables: {
        input: {
          nationality,
        },
        teamId,
        userId: playerId,
      },
    })

  const [pastInjuryListQuery, { data: pastInjuryList, loading: pastInjuryListLoading }] =
    useUserPastInjuryListLazyQuery()

  const injuryList =
    anchor === InjuryStatus.IN_PROGRESS
      ? currentInjuryList?.sports.user?.currentInjuryList
      : pastInjuryList?.sports.user?.pastInjuryList

  useEffect(() => {
    const handleHashScroll = () => {
      const hash = router.asPath.split('#')[1]
      if (hash) {
        const targetElement = document.getElementById(hash)
        if (targetElement) {
          targetElement.scrollIntoView()
        }
      }
    }

    handleHashScroll()
  }, [router.asPath])

  const loading = currentInjuryListLoading || pastInjuryListLoading

  const onClickPastInjuryAnchor = () => {
    if (!teamId || !playerId) {
      return
    }

    pastInjuryListQuery({
      variables: {
        input: {
          nationality,
        },
        teamId,
        userId: playerId,
      },
    })

    setAnchor(InjuryStatus.END)
  }

  const openInjuryDialog = () => {
    setIsOpenInjuryModal(true)
    navigation.setPageViewProperty({ conversion: 'squad' })
  }

  const onRegister = () => {
    setIsOpenInjuryModal(true)
    navigation.setPageViewProperty({ conversion: 'squad' })
  }

  const closeInjuryDialog = () => {
    setIsOpenInjuryModal(false)
  }

  const openInjuryDetailDialog = (injury: Injury) => {
    if (isObserver) {
      return
    }

    setIsOpenInjuryDetail(true)
    navigation.setPageViewProperty({ conversion: 'squad' })
    setInjuryData(injury)
  }

  const closeInjuryDetailDialog = () => {
    setInjuryData(undefined)
    setIsOpenInjuryDetail(false)
  }

  if (loading) {
    return (
      <GhostCard sx={{ width: '100%', height: '100%' }}>
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mt: 3, mx: 3 }}>
          <TitleText>{formatMessage({ id: 'players.card.injury.title' })}</TitleText>
          <Button onClick={onRegister} variant={'secondary'} disabled={isObserver} color={'grey'}>
            {formatMessage({ id: 'players.card.injury-register.button' })}
          </Button>
        </Flex>

        <Flex sx={{ mx: 3, mt: 1 }}>
          <AnchorSmIcon
            onClick={() => setAnchor(InjuryStatus.IN_PROGRESS)}
            name={formatMessage({ id: 'players.card.current-injury.tab' })}
            isActive={anchor === InjuryStatus.IN_PROGRESS}
          />
          <AnchorSmIcon
            onClick={onClickPastInjuryAnchor}
            name={formatMessage({ id: 'players.card.past-injury.tab' })}
            isActive={anchor === InjuryStatus.END}
          />
        </Flex>
      </GhostCard>
    )
  }

  return (
    <Fragment>
      <Card
        sx={{
          width: '100%',
          height: ['100%', '100%', '480px'],
          pb: [3, 3, 0],
          position: 'relative',
        }}
      >
        <Flex
          sx={{
            position: 'relative',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mt: 3, mx: 3 }}>
            <TitleText>{formatMessage({ id: 'players.card.injury.title' })}</TitleText>

            <Button
              onClick={openInjuryDialog}
              variant={'secondary'}
              disabled={isObserver}
              color={'grey'}
            >
              {formatMessage({ id: 'players.card.injury-register.button' })}
            </Button>
          </Flex>

          <Flex sx={{ mx: 3, mt: 1 }}>
            <AnchorSmIcon
              onClick={() => setAnchor(InjuryStatus.IN_PROGRESS)}
              name={formatMessage({ id: 'players.card.current-injury.tab' })}
              isActive={anchor === InjuryStatus.IN_PROGRESS}
              textStyle={{ '&:hover': { bg: 'grey-50' } }}
            />

            <AnchorSmIcon
              onClick={onClickPastInjuryAnchor}
              name={formatMessage({ id: 'players.card.past-injury.tab' })}
              isActive={anchor === InjuryStatus.END}
              textStyle={{ '&:hover': { bg: 'grey-50' } }}
            />
          </Flex>

          <Flex sx={{ flexDirection: 'column', overflowY: 'auto', mb: 3 }}>
            {injuryList?.length ? (
              <Flex sx={{ mx: 3, mt: 3, flexDirection: 'column', gap: '12px' }}>
                {injuryList.map((injury, index) => {
                  const injuryData = injury as Injury

                  return (
                    <ListItemPlayerInjury
                      key={index}
                      injury={injuryData}
                      onClick={() => openInjuryDetailDialog(injuryData)}
                    />
                  )
                })}
              </Flex>
            ) : (
              <CardPlayerInjuryEmpty status={anchor} />
            )}
          </Flex>
        </Flex>
        <TooltipActionHelp
          placement="top-right"
          onCloseAction={{
            storageKey: PLAYER_INJURY_REGISTER_HELP_STORAGE_KEY,
          }}
          text={formatMessage({ id: 'players.card.injury-register.help' })}
          containerStyle={{ right: '24px', top: '84px', width: '258px' }}
        />
      </Card>

      <DialogInjuryDetail
        open={isOpenInjuryDetail}
        onClose={closeInjuryDetailDialog}
        injury={injuryData}
        setInjury={setInjuryData}
        onModify={openInjuryDialog}
        teamId={teamId}
        userId={playerId}
      />

      <ModalInjury
        teamId={teamId}
        userId={playerId}
        open={isOpenInjuryModal}
        injuryData={injuryData}
        setInjuryData={setInjuryData}
        gender={gender}
        onClose={closeInjuryDialog}
      />
    </Fragment>
  )
}
