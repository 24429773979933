import React, { useCallback } from 'react'

import { Bar } from 'victory'

import { HoverUserData } from '@plco-pro/components/organisms/chart'
import { useMoment } from '@plco-pro/hooks/moment'
import { ChartParsedDataItem } from '@plco-pro/hooks/store-system'
import { Color } from '@plco-pro/maps/chart-system/chart-system.types'
import { CHART_TYPE } from '@plco-pro/maps/chart-system/props'
import { useStore } from '@plco-pro/stores'
import theme from '@plco-pro/themes/main'
import { getColor } from '@plco-pro/utils'

export type ChartCustomStackProps = {
  active?: boolean
  y?: number
  y0?: number
  style?: any
  color?: string
  colorHover?: string
  drawPrevision?: boolean
  datum?: { [key in string]?: any }
  scale?: { x?: any; y?: any }
  data?: ChartParsedDataItem[] | undefined
  type?: CHART_TYPE
  isWorkloadChart?: boolean
  noneDataColorHover?: Color
  noneDataColor?: Color
  index: number
  onClick?: (index: number) => void
  onHover?: ({ index, datum, scale }: HoverUserData) => void
  onCloseChart?: () => void
}

export const ChartCustomStack: React.FunctionComponent<ChartCustomStackProps> = (props) => {
  const {
    active,
    y,
    y0,
    style,
    colorHover,
    drawPrevision,
    datum,
    scale,
    data,
    type,
    isWorkloadChart = false,
    noneDataColorHover,
    noneDataColor,
    index,
    onClick = () => {},
    onHover = () => {},
    onCloseChart = () => {},
  } = props
  const moment = useMoment()
  const { monitoring } = useStore()

  const openBottomSheet = useCallback(() => {
    onClick(index)
    monitoring.setBottomSheetVisible(true)
  }, [monitoring, index, onClick])

  const openTooltip = useCallback(() => {
    if (!datum || !scale) return

    onHover({
      index,
      datum,
      scale,
    })
  }, [index, datum, scale, onHover])

  if (!data) return null

  const datumFormattingDate = moment(new Date(datum?.xName?.split('.')[0])).format('YYYY-MM-DD')

  const xLableData =
    data.filter((item) => item.x === datumFormattingDate || item.x === datum?.xName) || []
  const isStackLastData = xLableData.length && Object.keys(xLableData[0].y).length === datum?._stack

  const getIsAfterData = moment(datum?.x, 'YYYY-MM-DDTHH:mm:ssZ').isAfter(moment())

  const previsionStyle = {
    ...style,
    fill: () =>
      getIsAfterData ? theme.colors['grey-300'] : getColor(noneDataColor) || style?.fill,
  }

  const previsionHoverStyle = {
    ...style,
    fill: () =>
      getIsAfterData ? theme.colors['grey-300'] : getColor(noneDataColorHover) || colorHover,
  }

  const hoverStyle = {
    ...style,
    fill: colorHover ? colorHover : theme.colors['deep-purple-500'],
  }

  if (drawPrevision && y && y === y0) {
    if (!isWorkloadChart || datum?._stack !== 1) {
      if (getIsAfterData)
        return (
          <Bar
            events={{
              onClick: type === 'WORKLOAD_STACK' ? openBottomSheet : null,
              onMouseEnter: type === 'WORKLOAD_STACK' ? openTooltip : null,
              onMouseLeave: type === 'WORKLOAD_STACK' ? onCloseChart : null,
            }}
            {...props}
            y={y - 3}
            y0={y}
            style={previsionStyle}
            cornerRadius={2}
          />
        )

      return null
    }

    return (
      <Bar
        {...props}
        events={{
          onClick: type === 'WORKLOAD_STACK' ? openBottomSheet : null,
          onMouseEnter: type === 'WORKLOAD_STACK' ? openTooltip : null,
          onMouseLeave: type === 'WORKLOAD_STACK' ? onCloseChart : null,
        }}
        y={y - 3}
        y0={y}
        style={active ? previsionHoverStyle : previsionStyle}
        cornerRadius={2}
      />
    )
  } else {
    return (
      <Bar
        {...props}
        events={{
          onClick: type === 'WORKLOAD_STACK' ? openBottomSheet : null,
          onMouseEnter: type === 'WORKLOAD_STACK' ? openTooltip : null,
          onMouseLeave: type === 'WORKLOAD_STACK' ? onCloseChart : null,
        }}
        style={active ? hoverStyle : style}
        cornerRadius={isWorkloadChart && isStackLastData ? 2 : 0}
      />
    )
  }
}
