export const toast = {
  en: {
    'toast.error-message': 'There was an error. Please try again',
    'toast.empty-player': 'There are no players',
    'toast.unchecked-empty-player': 'There are no unchecked players',
    'toast.modal-check-condition-reminder.success':
      'Condition check reminder has been sent successfully.',
    'toast.modal-check-condition-reminder.error': 'There was an error. Please try again',
    'toast.modal-check-condition-reminder.error.already-sent':
      'Check notification has already been sent. Please try again in 1minute. ({seconds}s)',
    'toast.modal-check-workout-reminder.success':
      'Workout check reminder has been sent successfully.',
    'toast.modal-check-workout-reminder.error': 'There was an error. Please try again',
    'toast.modal-check-workout-reminder.info.already-sent':
      'Check notification has already been sent. Please try again in 1minute. ({seconds}s)',
    'toast.modal-check-workload-reminder.success':
      'Workload check reminder has been sent successfully',
    'toast.modal-group-create.success': 'Group has been added',
    'toast.modal-group-delete.success': '{groupName} has been deleted successfully.',
    'toast.modal-group-modify.success': 'Your changes have been saved.',
    'toast-modal-check-participant-reminder-success':
      'Participation check reminder has been sent successfully.',
    'toast-modal-check-participant-reminder-error': 'There was an error. Please try again',
    'toast.event.past-data.modify': "Can't edit. Content is over 28 days old.",
    'toast.event.past-data.delete': "Can't delete. Content is over 28 days old.",
    'toast.training-report.success': 'Save success',
    'toast.training-report.error': 'Save failed. Please try again.',
    'toast.injury-create.success': 'Injury has been registered successfully.',
    'toast.injury-delete.success': 'Injury has been deleted successfully.',
    'toast.injury-update.success': 'Injury has been edited successfully.',
    'toast.injury-update.current.success': 'Changed to current injury.',
    'toast.injury-update.past.success': 'Changed to past injury.',
    'toast.injury-file-upload.count.error': "You can't attach more than 3 files.",
    'toast.injury-file-upload.size.error': 'Files larger than 10MB cannot be attached.',
    'toast.event-create.success': '{month} {day} schedule added.',
    'toast.event-update.success': '{month} {day} schedule updated.',
    'toast.event-delete.success': '{month} {day} schedule deleted.',
    'toast.injury-create.select-player.error': 'Please select an injured player',
    'toast.dropdown-select-player.error': 'Please select at least one player',
    'toast.feedback-has-been-sent': 'Feedback has been sent',
  },
  ko: {
    'toast.error-message': '문제가 발생했습니다. 다시 시도해주세요.',
    'toast.empty-player': '선수가 없습니다',
    'toast.unchecked-empty-player': '미체크 선수가 없습니다',
    'toast.modal-check-condition-reminder.success': '컨디션 체크 알림을 성공적으로 보냈습니다.',
    'toast.modal-check-condition-reminder.error': '문제가 발생했습니다. 다시 시도해주세요.',
    'toast.modal-check-condition-reminder.error.already-sent':
      '이미 체크알림이 전송되었습니다. 1분 후에 다시 시도하세요. ({seconds}초)',
    'toast.modal-check-workout-reminder.success': '운동 체크 알림을 성공적으로 보냈습니다.',
    'toast.modal-check-workout-reminder.error': '문제가 발생했습니다. 다시 시도해주세요.',
    'toast.modal-check-workout-reminder.info.already-sent':
      '이미 체크알림이 전송되었습니다. 1분 후에 다시 시도하세요. ({seconds}초)',
    'toast.modal-check-workload-reminder.success':
      '미체크 선수들에게 운동부하 알림을 성공적으로 보냈습니다',
    'toast.modal-group-create.success': '그룹이 추가되었습니다',
    'toast.modal-group-delete.success': '{groupName} 그룹을 성공적으로 삭제했습니다.',
    'toast.modal-group-modify.success': '변경사항이 저장되었습니다.',
    'toast-modal-check-participant-reminder-success':
      '미체크 선수들에게 훈련 참가여부 알림을 성공적으로 보냈습니다.',
    'toast-modal-check-participant-reminder-error': '문제가 발생했습니다. 다시 시도해주세요.',
    'toast.event.past-data.modify': '28일이 지나서 수정할 수 없어요.',
    'toast.event.past-data.delete': '28일이 지나서 삭제할 수 없어요.',
    'toast.training-report.success': '저장 성공',
    'toast.training-report.error': '저장 실패. 다시 시도해보세요.',
    'toast.injury-create.success': '부상을 성공적으로 등록했습니다.',
    'toast.injury-delete.success': '부상을 성공적으로 삭제했습니다.',
    'toast.injury-update.success': '부상을 성공적으로 수정했습니다.',
    'toast.injury-update.current.success': '현재 부상으로 변경했습니다.',
    'toast.injury-update.past.success': '과거 부상으로 변경했습니다.',
    'toast.injury-file-upload.count.error': '3개가 넘는 파일은 첨부할 수 없습니다.',
    'toast.injury-file-upload.size.error': '10MB가 넘는 파일은 첨부할 수 없습니다.',
    'toast.event-create.success': '{month} {day} 일정 추가 완료',
    'toast.event-update.success': '{month} {day} 일정 수정 완료',
    'toast.event-delete.success': '{month} {day} 일정 삭제 완료',
    'toast.injury-create.select-player.error': '부상 선수를 선택해 주세요',
    'toast.dropdown-select-player.error': '적어도 1명 이상 선택해 주세요',
    'toast.feedback-has-been-sent': '피드백을 등록했습니다',
  },
  ja: {
    'toast.error-message': '問題が発生しました. もう一度お試しください.',
    'toast.empty-player': '選手がいません', // '선수가 없습니다',
    'toast.unchecked-empty-player': '未チェックの選手がいません', // '미체크 선수가 없습니다',
    'toast.modal-check-condition-reminder.success':
      'コンディション チェック アラームを正常に送信しました.', // "컨디션 체크 알림을 성공적으로 보냈습니다.",
    'toast.modal-check-condition-reminder.error': '問題が発生しました. もう一度お試しください.', // "문제가 발생했습니다. 다시 시도해주세요.",
    'toast.modal-check-condition-reminder.error.already-sent':
      'すでにチェック通知が送信されました. 1分後にもう一度お試しください。 ({seconds}秒)', // "이미 체크알림이 전송되었습니다. 1분 후에 다시 시도하세요. ({seconds}초)",
    'toast.modal-check-workout-reminder.success': '運動負荷チェックアラームを正常に送信しました.', // "운동 체크 알림을 성공적으로 보냈습니다.",
    'toast.modal-check-workout-reminder.error': '問題が発生しました. もう一度お試しください.', // "문제가 발생했습니다. 다시 시도해주세요.",
    'toast.modal-check-workout-reminder.info.already-sent':
      'すでにチェック通知が送信されました. 1分後にもう一度お試しください。 ({seconds}秒)', // "이미 체크알림이 전송되었습니다. 1분 후에 다시 시도하세요. ({seconds}초)",
    'toast.modal-check-workload-reminder.success': '運動負荷チェックを正常に送信しました', // '미체크 선수들에게 운동부하 알림을 성공적으로 보냈습니다',
    'toast.modal-group-create.success': 'グループが追加されました', // '그룹이 추가되었습니다',
    'toast.modal-group-delete.success': '{groupName}グループの削除に成功しました.', // "{groupName} 그룹을 성공적으로 삭제했습니다.",
    'toast.modal-group-modify.success': '変更が保存されました.', // "변경사항이 저장되었습니다.",
    'toast-modal-check-participant-reminder-success':
      'ミチェック選手に練習参加可否通知を正常に送信しました。', // "미체크 선수들에게 훈련 참가여부 알림을 성공적으로 보냈습니다.",
    'toast-modal-check-participant-reminder-error': '問題が発生しました. もう一度お試しください.', // "문제가 발생했습니다. 다시 시도해주세요.",
    'toast.event.past-data.modify': '28日が過ぎたため変更できません。', // 28일이 지나서 수정할 수 없어요.
    'toast.event.past-data.delete': '28日が過ぎたため削除できません。', // 28일이 지나서 삭제할 수 없어요.
    'toast.training-report.success': '保存成功', // "저장 성공",
    'toast.training-report.error': '保存失敗。 もう一度お試しください。', // "저장 실패. 다시 시도해보세요.",
    'toast.injury-create.success': '負傷の登録に成功しました。', // '부상을 성공적으로 등록했습니다.',
    'toast.injury-delete.success': '怪我の削除に成功しました。', // '부상을 성공적으로 삭제했습니다.',
    'toast.injury-update.success': '負傷を成功的に修正しました。', // '부상을 성공적으로 수정했습니다.',
    'toast.injury-update.current.success': '現在の負傷に変更しました。', // '현재 부상으로 변경했습니다.',
    'toast.injury-update.past.success': '過去の負傷に変更しました。', // '과거 부상으로 변경했습니다.',
    'toast.injury-file-upload.count.error': '3つ以上のファイルは添付できません。', // '3개가 넘는 파일은 첨부할 수 없습니다.',
    'toast.injury-file-upload.size.error': '10MBを超えるファイルは添付できません。', // '10MB가 넘는 파일은 첨부할 수 없습니다.',
    'toast.event-create.success': '{month}{day}の日程追加完了', //  '{month} {day} 일정 추가 완료',
    'toast.event-update.success': '{month}{day}の日程修正完了', //  '{month} {day} 일정 수정 완료',
    'toast.event-delete.success': '{month}{day}の日程削除完了', //  '{month} {day} 일정 삭제 완료',
    'toast.injury-create.select-player.error': '負傷選手を選択してください', // '부상 선수를 선택해 주세요',
    'toast.dropdown-select-player.error': '少なくとも1人以上の選手を選択してください', // '적어도 1명 이상 선택해 주세요',
    'toast.feedback-has-been-sent': 'フィードバックが登録されました', // '피드백을 등록했습니다',
  },
}
