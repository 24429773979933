export type InjuryDummyGender = 'man' | 'woman'
export type InjuryDummyOrientation = 'front' | 'back'

export type InjuryDummyBackgroundMap = {
  [key in InjuryDummyGender]: {
    [key in InjuryDummyOrientation]: string
  }
}

export const injuryDummyBackgroundMap: InjuryDummyBackgroundMap = {
  man: {
    front: '/images/injury-dummy/man-front.svg',
    back: '/images/injury-dummy/man-back.svg',
  },
  woman: {
    front: '/images/injury-dummy/woman-front.svg',
    back: '/images/injury-dummy/woman-back.svg',
  },
}
