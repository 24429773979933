import React, { useMemo } from 'react'
import {
  Modal as ReactResponsiveModal,
  ModalProps as ReactResponsiveModalProps,
} from 'react-responsive-modal'

import styled from '@emotion/styled'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { ButtonProps } from '@plco-pro/components/atoms/button'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Icon } from '@plco-pro/components/atoms/icon'
import { ModalFooter } from '@plco-pro/components/molecules/modal-footer'
import { ModalHeader } from '@plco-pro/components/molecules/modal-header'
import { useResponsive } from '@plco-pro/hooks/responsive'
import { isMobile } from '@plco-pro/utils/isMobile'

import 'react-responsive-modal/styles.css'

export const MODAL_PADDING_X = '24px'

export type ModalProps = {
  responsiveWidth?: string
  width?: string
  height?: string
  maxHeight?: string
  maxWidth?: string
  borderRadius?: string | number
  open: boolean
  onClose?: () => void
  onAnimationEnd?: () => void
  closeOnEsc?: boolean
  showCloseIcon?: boolean
  isFullScreen?: boolean
  focusTrapped?: boolean
  header?: React.ReactNode
  headerProps?: BoxProps
  footer?: React.ReactNode
  footerProps?: { leftButtonProps?: ButtonProps; rightButtonProps?: ButtonProps } & FlexProps
  children?: React.ReactNode
  responsive?: boolean
  contentPaddingDisable?: boolean
}

export type StyledReactResponsiveModalProps = ReactResponsiveModalProps & {
  width?: string
  maxWidth?: string
  height?: string
  borderRadius?: string | number
  className?: string
  isHeaderVisible?: boolean
  responsiveHeaderTop?: string
  isFullScreen?: boolean
}

// react portal workaround for styled-components
// cf: https://material-ui.com/guides/interoperability/#portals
export const StyledReactResponsiveModal = styled(
  ({ className, ...rest }: StyledReactResponsiveModalProps) => (
    <ReactResponsiveModal
      {...rest}
      blockScroll={!isMobile()?.find((el) => el.includes('iPhone') || el.includes('iPad'))}
      onClose={rest.onClose ? rest.onClose : () => {}}
      classNames={{
        root: className,
      }}
      styles={{
        // modal root z-index 1000, toasts z-index 1000, so modal hide toasts
        ...rest.styles,
        root: {
          zIndex: 999,
          ...rest.styles?.root,
        },
      }}
    />
  ),
)`
  .react-responsive-modal-container {
    overflow-y: hidden;
  }

  .react-responsive-modal-modal {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding: 0;
    margin: 0;
    border-radius: ${(props) => props.borderRadius || `${props.theme.radii.default}px`};
    max-width: ${(props) => (props.isFullScreen ? '100vw' : props.maxWidth)};
  }

  .react-responsive-modal-modal:focus {
    outline: none;
  }

  .react-responsive-modal-overlay {
    background: rgba(19, 24, 32, 0.6);
  }

  .react-responsive-modal-closeButton {
    top: ${(props) => (props.isHeaderVisible ? props.responsiveHeaderTop : '16px')};
    right: ${(props) => (props.isFullScreen ? '16px' : '24px')};

    svg {
      fill: ${(props) => props.theme.colors['grey-600']};
    }
  }
`

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{
      height: '100%',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}
    {...props}
  />
)

const ContentBox = (props: BoxProps & { responsive?: boolean }) => (
  <Box
    sx={{
      flex: '1 1 auto',
      px: props.responsive ? [MODAL_PADDING_X, '80px'] : 2,
      py: 2,
    }}
    {...props}
  />
)

export const Modal: React.FunctionComponent<ModalProps> = ({
  responsiveWidth,
  width,
  height,
  maxHeight = '100vh',
  maxWidth,
  borderRadius,
  open,
  onClose = () => {},
  onAnimationEnd,
  showCloseIcon,
  closeOnEsc = true,
  isFullScreen = false,
  focusTrapped = true,
  header,
  headerProps,
  footer,
  footerProps,
  children,
  responsive,
  contentPaddingDisable = false,
}) => {
  const getResponsiveProp = useResponsive()

  const responsiveContainerWidth = useMemo(() => {
    return isFullScreen ? '100vw' : responsiveWidth || ['100%', width || null]
  }, [isFullScreen, width, responsiveWidth])
  const responsiveModalWidth = useMemo(
    () =>
      isFullScreen
        ? '100vw'
        : responsiveWidth || getResponsiveProp(['calc(100% - 32px)', width || null]),
    [isFullScreen, getResponsiveProp, width, responsiveWidth],
  )
  const responsiveHeight = useMemo(() => (isFullScreen ? '100vh' : height), [height, isFullScreen])
  const responsiveHeaderTop = useMemo(
    () => (isFullScreen ? getResponsiveProp(['14px', '21px', null, null]) : '21px'),
    [isFullScreen, getResponsiveProp],
  )

  const animationDuration = useMemo(() => {
    if (isFullScreen) {
      return 0
    }

    return 300
  }, [isFullScreen])

  return (
    <StyledReactResponsiveModal
      open={open}
      onAnimationEnd={onAnimationEnd}
      onClose={onClose}
      closeOnEsc={closeOnEsc}
      onOverlayClick={onClose}
      center
      showCloseIcon={showCloseIcon}
      closeIcon={<Icon src={'/images/ic-close.svg'} />}
      isHeaderVisible={!!header || !!headerProps}
      responsiveHeaderTop={responsiveHeaderTop}
      animationDuration={animationDuration}
      width={responsiveModalWidth}
      maxWidth={maxWidth || responsiveModalWidth}
      borderRadius={borderRadius}
      isFullScreen={isFullScreen}
      focusTrapped={focusTrapped}
    >
      <ContainerFlex
        sx={{
          width: responsiveContainerWidth,
          height: responsiveHeight,
          maxHeight,
        }}
      >
        <ModalHeader headerProps={headerProps} isFullScreen={isFullScreen}>
          {header}
        </ModalHeader>

        {children && (
          <ContentBox
            sx={{ position: 'relative', overflowY: 'auto', p: contentPaddingDisable ? 0 : 'auto' }}
            responsive={responsive}
          >
            {children}
          </ContentBox>
        )}

        <ModalFooter
          leftButtonProps={footerProps?.leftButtonProps}
          rightButtonProps={footerProps?.rightButtonProps}
          isFullScreen={isFullScreen}
          {...footerProps}
        >
          {footer}
        </ModalFooter>
      </ContainerFlex>
    </StyledReactResponsiveModal>
  )
}
