import React, { useMemo } from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import theme from '@plco-pro/themes/main'

export type ArcProps = {
  percent?: number
  width?: number
  strokeWidth?: number
  startColor?: string
  endColor?: string
  trackColor?: string
}

const dash = (offset: number) => keyframes`
  0% {
    stroke-dashoffset: ${offset}
  }
  100% {
    stroke-dashoffset: 0;
  }
`

const Circle = styled('path')`
  stroke-dashoffset: 0;
  animation: ${(props) => dash(props.offset ? Number(props.offset) : 0)} 1s ease-in-out;
`

export const Arc: React.FunctionComponent<ArcProps> = ({
  percent = 77,
  width = 100,
  strokeWidth = 10,
  startColor,
  endColor,
  trackColor = theme.colors['grey-100'],
}) => {
  const R = (width - strokeWidth) / 2
  const CF = 2 * Math.PI * R
  const gradientId = `arc.gradient.${startColor}.${endColor}`
  const offset = useMemo(() => CF * (percent / 100) || 0, [CF, percent])

  return (
    <svg width={width} height={width}>
      <defs>
        <linearGradient
          id={gradientId}
          gradientUnits={'userSpaceOnUse'}
          x1={'0%'}
          y1={'0%'}
          x2={'100%'}
          y2={'0%'}
        >
          <stop offset={'0%'} stopColor={startColor} stopOpacity={'1'} />
          <stop offset={'100%'} stopColor={endColor} stopOpacity={'1'} />
        </linearGradient>
      </defs>
      <path
        d={[
          ['M', R + strokeWidth / 2, strokeWidth / 2].join(' '),
          ['a', R, R, 0, 0, 1, 0, 2 * R].join(' '),
          ['a', R, R, 0, 0, 1, 0, -2 * R].join(' '),
        ].join('\n')}
        fill={'none'}
        stroke={trackColor}
        strokeWidth={strokeWidth}
      />
      <Circle
        d={[
          ['M', R + strokeWidth / 2, strokeWidth / 2].join(' '),
          ['a', R, R, 0, 0, 1, 0, 2 * R].join(' '),
          ['a', R, R, 0, 0, 1, 0, -2 * R].join(' '),
        ].join('\n')}
        fill={'none'}
        stroke={`url(#${gradientId})`}
        strokeLinecap={'round'}
        strokeWidth={strokeWidth}
        strokeDasharray={`${(CF * (percent / 100)).toString()} ${CF.toString()}`}
        transform={`rotate(180, ${width / 2}, ${width / 2})`}
        offset={offset}
      />
    </svg>
  )
}
