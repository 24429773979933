import { getParent, types } from 'mobx-state-tree'

import { RootStoreType } from '@plco-pro/stores/root'

import type { Posts } from '@plco-pro/hooks/ghost-api'

/**
 * Couldn't snapshot blog posts because ghost api doesn't notice delete post and update tags in post
 * if we have a lot of posts in ghost, we should find other way instead of always call post with no limit
 */
export const BlogStore = types
  .model('Blog', {
    posts: types.frozen<Posts>({}),
    lastUpdatedAt: types.maybeNull(types.Date),
  })
  .actions((self) => ({
    setPosts(newPosts: Posts) {
      self.posts = newPosts
      self.lastUpdatedAt = new Date()
    },
    setLastUpdatedAt(date: Date | null) {
      self.lastUpdatedAt = date
    },
    async clear() {
      self.posts = {}
      self.lastUpdatedAt = null
      await getParent<RootStoreType>(self).save()
    },
  }))
