import { useStore } from '@plco-pro/stores'

import { LANGUAGE_MAP } from './multilingual'

export const useNationality = () => {
  const { preference } = useStore()

  const language = preference.locale.language.split('-')[0] as keyof typeof LANGUAGE_MAP

  const nationality = `all_country_code__${LANGUAGE_MAP[language]}`

  return nationality
}
