import { useEffect, useRef, useState } from 'react'

import { ThemeUIStyleObject } from 'theme-ui'

import { Flex, Text, Box, Icon } from '@plco-pro/components/atoms'
import { storage } from '@plco-pro/utils/libs'
export type TooltipActionHelpPlacement =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'

interface Props {
  text: string
  onCloseAction?: {
    storageKey: string
    action?: () => void
  }
  containerStyle?: ThemeUIStyleObject
  placement: TooltipActionHelpPlacement
}

interface TrianglePositionType {
  top?: string
  left?: string
  right?: string
  bottom?: string
  transform?: string
}

export const TooltipActionHelp = ({ text, onCloseAction, containerStyle, placement }: Props) => {
  const [isDisableHelp, setIsDisableHelp] = useState(false)
  const [trianglePosition, setTrianglePosition] = useState<TrianglePositionType>()

  const tooltipRef = useRef<HTMLDivElement | null>(null)

  const { storageKey, action } = onCloseAction || {}

  const onClickCloseButton = () => {
    if (!storageKey) {
      return
    }

    setIsDisableHelp(true)
    storage.set(storageKey, 'true')

    action?.()
  }

  useEffect(() => {
    const { width } = tooltipRef.current?.getBoundingClientRect() || { width: 0 }

    const centerPosition = width / 2 - 6

    const position = () => {
      switch (placement) {
        case 'top-left':
          return {
            top: '-12px',
            left: '20px',
          }
        case 'top-center':
          return {
            top: '-12px',
            left: `${centerPosition}px`,
          }
        case 'top-right':
          return {
            top: '-12px',
            right: '20px',
          }
        case 'bottom-left':
          return {
            bottom: '-12px',
            left: '20px',
            transform: 'rotate(180deg)',
          }
        case 'bottom-center':
          return {
            bottom: '-12px',
            left: `${centerPosition}px`,
            transform: 'rotate(180deg)',
          }
        case 'bottom-right':
          return {
            bottom: '-12px',
            right: '20px',
            transform: 'rotate(180deg)',
          }
      }
    }

    setTrianglePosition(position())
  }, [placement])

  if (isDisableHelp || (storageKey && storage.get(storageKey)) || !trianglePosition) {
    return null
  }

  return (
    <Box ref={tooltipRef} sx={{ position: 'absolute', zIndex: 99, ...containerStyle }}>
      <Flex
        sx={{
          py: 1,
          px: 2,
          bg: 'grey-500',
          borderRadius: '8px',
          alignItems: 'center',
          gap: '4px',
          maxWidth: '360px',
        }}
      >
        <Text color="white-500" sx={{ whiteSpace: 'pre-line', textAlign: 'start' }}>
          {text}
        </Text>
        {onCloseAction && (
          <Icon
            sx={{
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                bg: 'grey-400',
              },
              flexShrink: 0,
            }}
            onClick={onClickCloseButton}
            src={'/images/close.svg'}
            color="white-500"
            size={'16px'}
          />
        )}
      </Flex>
      <Box
        sx={{
          width: 0,
          height: 0,
          borderBottom: '12px solid',
          borderBottomColor: 'grey-500',
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          position: 'absolute',
          ...trianglePosition,
        }}
      />
    </Box>
  )
}
