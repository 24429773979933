export const profile = {
  en: {
    'edit-profile.layout.title': 'My Profile',
    'edit-profile.title': 'Edit Profile',
    'edit-profile.upload.button': 'Upload profile image',
    'edit-profile.upload.under-line': 'Change to default image',
    'edit-profile.toasts.success': 'Your profile has been saved.',
    'edit-profile.toasts.error.file-size':
      'The file size is too large. Please upload a file of less than 1MB',
  },
  ko: {
    'edit-profile.layout.title': '내 프로필',
    'edit-profile.title': '프로필 수정',
    'edit-profile.upload.button': '프로필 사진 업로드',
    'edit-profile.upload.under-line': '기본 이미지로 변경',
    'edit-profile.toasts.success': '프로필이 저장되었습니다.',
    'edit-profile.toasts.error.file-size':
      '파일 사이즈가 너무 큽니다. 1MB 이하의 파일로 올려주세요',
  },
  ja: {
    'edit-profile.layout.title': '私のプロフィール', // "내 프로필",
    'edit-profile.title': 'プロフィール修正', // "프로필 수정",
    'edit-profile.upload.button': 'プロフィール写真のアップロード', // "프로필 사진 업로드",
    'edit-profile.upload.under-line': '基本イメージに変更', // "기본 이미지로 변경",
    'edit-profile.toasts.success': 'プロフィールが保存されました.', // "프로필이 저장되었습니다.",
    'edit-profile.toasts.error.file-size':
      'ファイルサイズが大きすぎます. 1MB以下のファイルでアップロードしてください.', // "파일 사이즈가 너무 큽니다. 1MB 이하의 파일로 올려주세요",
  },
}
