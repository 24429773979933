import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Card } from '@plco-pro/components/atoms/card'
import { Divider } from '@plco-pro/components/atoms/divider'
import { Flex } from '@plco-pro/components/atoms/flex'
import { GhostCard } from '@plco-pro/components/atoms/ghost-card'
import { Text, TextProps } from '@plco-pro/components/atoms/text'
import { Chart } from '@plco-pro/components/organisms/chart'
import { DonutReadiness } from '@plco-pro/components/organisms/donut-readiness'
import { useChartSystem } from '@plco-pro/hooks/chart-system'
import { useI18n } from '@plco-pro/hooks/i18n'
import { observer, useStore } from '@plco-pro/stores'
import { DRAWER_ANIMATION_DURATION } from '@plco-pro/utils/constant'

const TitleText = (props: TextProps) => (
  <Text variant={['s1', 'h4']} sx={{ fontWeight: 'bold', mx: 3, mt: 3 }} {...props} />
)

const ContentBox = (props: BoxProps) => <Box sx={{ mx: 3 }} {...props} />

const DividerContainerBox = (props: BoxProps) => <Box sx={{ px: 3, my: 1 }} {...props} />

type Props = {
  loading: boolean
  playerId: string
}

export const CardPlayerReadiness = observer(({ loading, playerId }: Props) => {
  const { formatMessage: f } = useI18n()
  const {
    navigation: { isOpenDrawer, isOpenPlayersDrawer },
  } = useStore()

  const readinessBarChartBoxRef = useRef<HTMLDivElement>(null)

  const [chartWidth, setChartWidth] = useState<number | 'auto'>()

  const {
    initialized: readinessBarChartInitialized,
    store: readinessBarChartStore,
    chartSystemProps: readinessBarChartSystemProps,
    loading: readinessBarChartLoading,
  } = useChartSystem('USER:READINESS:1_WEEKS:DAILY:BAR')

  const readinessData = useMemo(() => {
    const parsedData = readinessBarChartStore?.parsedData?.[0]
    if (!parsedData) return

    const { length } = parsedData
    const currentReadiness = parsedData[length - 1]?.y
    const previousReadiness = parsedData[length - 2]?.y
    const increaseValue =
      currentReadiness && previousReadiness && currentReadiness - previousReadiness
    return {
      readinessValue: currentReadiness,
      increaseValue,
    }
  }, [readinessBarChartStore?.parsedData])

  useEffect(() => {
    if (readinessBarChartInitialized) {
      readinessBarChartStore?.setVariables({
        id: playerId,
      })
    }
  }, [playerId, readinessBarChartInitialized, readinessBarChartStore])

  useEffect(() => {
    setTimeout(() => {
      setChartWidth(readinessBarChartBoxRef.current?.getBoundingClientRect().width || 'auto')
    }, DRAWER_ANIMATION_DURATION + 100)
  }, [isOpenPlayersDrawer, isOpenDrawer])

  if (loading) {
    return (
      <GhostCard sx={{ width: '100%', height: '100%' }}>
        <Flex sx={{ flexDirection: 'column', height: '100%' }}>
          <TitleText>{f({ id: 'players.card.readiness.title' })}</TitleText>
        </Flex>
      </GhostCard>
    )
  }

  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <Flex sx={{ flexDirection: 'column', height: '100%' }}>
        <TitleText>{f({ id: 'players.card.readiness.title' })}</TitleText>
        <ContentBox sx={{ height: '184px', mt: 3 }}>
          <DonutReadiness
            readinessValue={readinessData?.readinessValue}
            increaseValue={readinessData?.increaseValue}
          />
        </ContentBox>
        <DividerContainerBox>
          <Divider />
        </DividerContainerBox>
        <ContentBox sx={{ flex: 1, mt: 2, mb: 0 }}>
          <Flex sx={{ flexDirection: 'column', width: '100%' }}>
            <Text variant={'p2'} appearance={'hint'}>
              {f({ id: 'players.card.readiness.past-7-days' })}
            </Text>
            <Box ref={readinessBarChartBoxRef}>
              {readinessBarChartLoading ? (
                <Box sx={{ height: 110 }} />
              ) : (
                <Chart
                  name={'PLAYERS_READINESS'}
                  width={chartWidth}
                  height={110}
                  padding={{
                    left: 16,
                    right: 16,
                  }}
                  {...readinessBarChartSystemProps}
                />
              )}
            </Box>
          </Flex>
        </ContentBox>
      </Flex>
    </Card>
  )
})
