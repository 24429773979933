import type { ChartSystemMapKey } from '@plco-pro/maps/chart-system/chart-system'

export enum TeamChartDataType {
  CONDITION = 'condition',
  WORKLOAD = 'workload',
  INJURY = 'injury',
}

export type ChartTeamChartMap = Record<TeamChartDataType, ChartSystemMapKey>

export const chartTeamChartMap = {
  [TeamChartDataType.CONDITION]: 'TEAM_AVG:CONDITION:LINE',
  [TeamChartDataType.WORKLOAD]: 'TEAM_AVG:WORKLOAD_INTENSITY:BAR_LINE',
  [TeamChartDataType.INJURY]: 'TEAM_AVG:INJURY:STACK',
}
