import { useMemo } from 'react'

import { FeedbackWorkloadMemoData } from '@plco-pro/graphqls/react.generated'
import { useMoment } from '@plco-pro/hooks/moment'
import { useMomentFormat } from '@plco-pro/hooks/use-moment-format'

import { Box, Flex, Icon, Text } from '../../atoms'
import { MemoCard } from './memo-card'

interface WorkloadMemoProps {
  pictureUrl?: string
  name: string
  workloadMemoData: FeedbackWorkloadMemoData
}

export const WorkloadMemo = ({ pictureUrl, name, workloadMemoData }: WorkloadMemoProps) => {
  const moment = useMoment()
  const { formatMatchDate } = useMomentFormat()

  const date = useMemo(() => {
    const utcOffset = moment().tz(workloadMemoData.timezone).utcOffset()

    const parsedTime = moment(workloadMemoData.time).add(utcOffset, 'minutes')

    const startAt = moment(parsedTime)
    const endAt = startAt.clone().add({ minutes: workloadMemoData.duration })

    return formatMatchDate(startAt, endAt)
  }, [
    workloadMemoData.time,
    workloadMemoData.timezone,
    workloadMemoData.duration,
    moment,
    formatMatchDate,
  ])

  const location = useMemo(() => {
    return workloadMemoData.scheduleData?.location
  }, [workloadMemoData])

  return (
    <Box sx={{ mx: [2, 3] }}>
      <Flex sx={{ flexDirection: 'column', gap: '4px', mb: 2 }}>
        {/* 장소 */}
        {workloadMemoData.scheduleData && (
          <Flex sx={{ gap: 1, alignItems: 'flex-start' }}>
            <Icon src="/images/ic_location_on.svg" size={16} sx={{ pt: '2px' }} />

            <Text variant="p1" sx={{ color: 'grey-400', fontWeight: 500, flex: 1 }}>
              {location || '-'}
            </Text>
          </Flex>
        )}

        {/* 날짜, 시간 */}
        <Flex sx={{ gap: 1, alignItems: 'center' }}>
          <Icon src="/images/ic_date_range.svg" size={16} />
          <Text variant="p1" sx={{ color: 'grey-400', fontWeight: 500 }}>
            {date}
          </Text>
        </Flex>
      </Flex>

      <MemoCard pictureUrl={pictureUrl} name={name} contents={workloadMemoData.memo} />
    </Box>
  )
}
