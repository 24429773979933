import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import {
  ChartInjuryHistory,
  InjuryHistoryChartConfigKeyMap,
  InjuryHistoryChartPeriod,
} from '@plco-pro/components/organisms/chart-injury-history'
import {
  SportsBodyPartName,
  useBodyPartMultilingualTextQuery,
} from '@plco-pro/graphqls/react.generated'
import { useNationality } from '@plco-pro/hooks'

type Props = {
  playerId: string
  injuryPart: SportsBodyPartName
}

export const PlayerInjuryHistoryChartContainer = ({ playerId, injuryPart }: Props) => {
  const nationality = useNationality()

  const { data } = useBodyPartMultilingualTextQuery({
    variables: {
      key: injuryPart,
      input: {
        nationality,
      },
    },
  })

  const injuryHistoryChartPeriodConfigKeyMap: InjuryHistoryChartConfigKeyMap = new Map([
    [InjuryHistoryChartPeriod.WEEK, 'USER:INJURY_HISTORY:1_WEEKS:DAILY_TEXT:LINE'],
    [InjuryHistoryChartPeriod.MONTH, 'USER:INJURY_HISTORY:1_MONTHS:WEEKLY_DAY:LINE'],
  ])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: 3,
        pb: 5,
        width: '100%',
      }}
    >
      <Text variant={'h3'} sx={{ fontWeight: 'bold' }}>
        {'🩹'} {data?.multilingualText.bodyPartList?.[0].value}
      </Text>

      <ChartInjuryHistory
        periodConfigKeyMap={injuryHistoryChartPeriodConfigKeyMap}
        playerId={playerId}
        injuryPart={injuryPart}
      />
    </Flex>
  )
}
