import { Operation } from '@apollo/client'
import { SeverityLevel } from '@sentry/nextjs'
import * as Sentry from '@sentry/nextjs'
import { Primitive, User } from '@sentry/types'
import { GraphQLError } from 'graphql'

import { __ENV__ } from '@plco-pro/env'

/**
 * about sentry: https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/
 * sentry init 은 plco-pro/sentry.[client|server].config.js 파일에 지정
 * */

export const setUser = (user: User | null) => {
  Sentry.setUser(user)
}

export const setTag = (key: string, value: Primitive) => {
  Sentry.setTag(key, value)
}

export const logWithExtras = (extras: Record<string, unknown>, errorMessage: string) => {
  Sentry.withScope((scope) => {
    scope.setExtras(extras)

    logError(errorMessage)
  })
}

export const logGql = (operation: Operation, error: GraphQLError) => {
  Sentry.withScope((scope) => {
    scope.setExtras({
      name: operation.operationName,
      query: operation.query.loc?.source.body || null,
      variables: operation.variables,
      scope: 'global-link',
      error: error,
    })

    logError(operation.operationName)
  })
}

export const logError = (e: Error | string) => {
  if (e instanceof Error) {
    return Sentry.captureException(e)
  }

  Sentry.captureMessage(e, 'error')
}

export const logMessage = (e: string, level: SeverityLevel = 'log') => {
  Sentry.captureMessage(e, level)
}

export const logTimeout = (e: unknown, timeout: number) => {
  return setTimeout(() => {
    const payload = {
      text: `PLCO_PRO_NOTI: API response 가 ${timeout / 1000}초 이상 지났습니다!
      ${JSON.stringify(e, null, '\t')}
    `,
    }
    if (__ENV__ === 'production') {
      fetch('https://hooks.slack.com/services/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', {
        method: 'POST',
        body: JSON.stringify(payload),
      })
    }
  }, timeout)
}
