import React, { forwardRef } from 'react'

import styled from '@emotion/styled'
import { Input as ThemeUIInput, InputProps as ThemeUIInputProps } from 'theme-ui'

export type InputProps = ThemeUIInputProps & {
  error?: boolean
}

const StyledThemeUIInput = styled(ThemeUIInput)<InputProps>`
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.colors['text-tertiary']
    } else {
      return undefined
    }
  }};
  border-color: ${(props) => {
    if (props.disabled) {
      return props.theme.colors.disabled
    } else if (props.error) {
      return props.theme.colors['soft-red-600']
    } else {
      return undefined
    }
  }};
  ::placeholder {
    color: ${(props) => {
      if (props.disabled) {
        return props.theme.colors['text-tertiary']
      } else {
        return undefined
      }
    }};
  }
`

export const Input: React.FunctionComponent<InputProps> = forwardRef((props, ref) => (
  <StyledThemeUIInput ref={ref} {...props} />
))
