import React, { useCallback, useEffect } from 'react'

import { useRouter } from 'next/router'

import { Flex } from '@plco-pro/components/atoms/flex'
import { PlayerModalHeader } from '@plco-pro/components/molecules/player-modal-header'
import { ModalPage } from '@plco-pro/components/organisms/modal-page'
import { PlayerPage } from '@plco-pro/components/pages/players/[id]/index'
import {
  usePlayerModalLatestRawBodyQuery,
  usePlayerModalUserQuery,
} from '@plco-pro/graphqls/react.generated'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useIntersectionObserver } from '@plco-pro/hooks/intersection-observer'
import { useMoment } from '@plco-pro/hooks/moment'
import { useStore } from '@plco-pro/stores'
import { get30DaysAgo } from '@plco-pro/utils/date-utils'
import { AnalyticsEventType } from '@plco-pro/utils/libs'

export const ModalPlayer = () => {
  const moment = useMoment()
  const router = useRouter()
  const { trackEvent } = useAnalytics()

  const {
    preference: {
      locale: { timezone },
    },
    players: { setDate, setDateRange },
  } = useStore()
  const { ref: summaryUserRef, visible: isSummaryUserVisible } = useIntersectionObserver()

  const onClose = useCallback(() => {
    const { playerId, dataType, playerDate, ...restQuery } = router.query

    router.push(
      {
        query: {
          ...restQuery,
        },
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      },
    )
  }, [router])

  const { playerId: playerIdQuery, playerDate: playerDateQuery } = router.query

  const playerId = typeof playerIdQuery === 'string' ? playerIdQuery : ''
  const date =
    typeof playerDateQuery === 'string' && moment(playerDateQuery).isValid()
      ? playerDateQuery
      : moment().format('YYYY-MM-DD')

  useEffect(() => {
    setDate(moment(date).toDate())
    setDateRange([get30DaysAgo(moment(date).toDate()), moment(date).toDate()])
  }, [date, moment, setDate, setDateRange])

  const { data } = usePlayerModalUserQuery({
    skip: !playerId,
    variables: {
      playerId,
    },
  })

  const { data: latestRawBodyData } = usePlayerModalLatestRawBodyQuery({
    skip: !playerId,
    variables: {
      playerId,
      timezone,
    },
  })

  const user = data?.sports.user
  const latestRawBody = latestRawBodyData?.sports.user?.latestRawBody

  const openModal = !!playerId

  useEffect(() => {
    if (openModal) {
      trackEvent(AnalyticsEventType.PLAYER_PAGE_CLICKED, {})
    }
  }, [openModal, trackEvent])

  return (
    <ModalPage
      open={openModal}
      onClose={onClose}
      header={
        <PlayerModalHeader
          showHeader={!isSummaryUserVisible}
          user={user}
          latestRawBody={latestRawBody}
        />
      }
    >
      <Flex p={2} sx={{ width: '100%', height: '100%' }}>
        <PlayerPage playerId={playerId ?? ''} summaryUserRef={summaryUserRef} />
      </Flex>
    </ModalPage>
  )
}
