import { SportsBodyPartName } from '@plco-pro/graphqls/react.generated'
import { InjuryDummyGender } from '@plco-pro/maps/injury-dummy-background'

export type InjuryDummyBodyPartName = SportsBodyPartName

export const INJURY_DUMMY_BACKGROUND_WIDTH = 311
export const INJURY_DUMMY_BACKGROUND_HEIGHT = 662

export type InjuryDummyBodyPartMapValue = {
  src: string
  widthRatio: number
  heightRatio: number
  top: string
  left: string
}

export type InjuryDummyBodyPartMap = {
  [key in InjuryDummyBodyPartName]: {
    [key in InjuryDummyGender]: InjuryDummyBodyPartMapValue
  }
}

export const injuryDummyBodyPartMap: InjuryDummyBodyPartMap = {
  [SportsBodyPartName.all_body_part__forehead]: {
    man: {
      src: '/images/injury-dummy/man-fore-head.svg',
      widthRatio: 48 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 27 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(3 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(131 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-fore-head.svg',
      widthRatio: 44 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 21 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(4 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(132 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__face]: {
    man: {
      src: '/images/injury-dummy/man-face.svg',
      widthRatio: 52 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 55 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(29 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(130 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-face.svg',
      widthRatio: 50 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 54 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(27 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(129 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__front_neck]: {
    man: {
      src: '/images/injury-dummy/man-front-neck.svg',
      widthRatio: 39 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 26 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(85 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(136 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-front-neck.svg',
      widthRatio: 46 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 27 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(82 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(130 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__back_head]: {
    man: {
      src: '/images/injury-dummy/man-back-head.svg',
      widthRatio: 53 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 72 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(5 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(130 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-back-head.svg',
      widthRatio: 51 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 68 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(8 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(128 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__back_neck]: {
    man: {
      src: '/images/injury-dummy/man-back-neck.svg',
      widthRatio: 39 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 31 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(78 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(136 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-back-neck.svg',
      widthRatio: 33 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 24 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(77 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(137 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_chest]: {
    man: {
      src: '/images/injury-dummy/man-right-chest.svg',
      widthRatio: 53 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 65 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(129 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(98 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-chest.svg',
      widthRatio: 46 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 57 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(125 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(107 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_chest]: {
    man: {
      src: '/images/injury-dummy/man-left-chest.svg',
      widthRatio: 53 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 65 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(129 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(162 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-chest.svg',
      widthRatio: 45 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 56 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(125 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(155 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__upper_abdomen]: {
    man: {
      src: '/images/injury-dummy/man-upper-abdomen.svg',
      widthRatio: 105 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 39 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(195 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(103 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-upper-abdomen.svg',
      widthRatio: 86 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 33 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(181 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(112 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__lower_abdomen]: {
    man: {
      src: '/images/injury-dummy/man-lower-abdomen.svg',
      widthRatio: 62 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 80 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(229 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(125 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-lower-abdomen.svg',
      widthRatio: 50 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 68 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(215 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(129 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_rib]: {
    man: {
      src: '/images/injury-dummy/man-right-rib.svg',
      widthRatio: 18 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 70 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(228 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(107 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-rib.svg',
      widthRatio: 23 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 70 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(206 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(107 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_rib]: {
    man: {
      src: '/images/injury-dummy/man-left-rib.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 66 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(224 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(185 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-rib.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 69 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(205 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(178 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__reproductive_organs]: {
    man: {
      src: '/images/injury-dummy/man-reproductive-organs.svg',
      widthRatio: 68 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 43 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(304 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(121 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-reproductive-organs.svg',
      widthRatio: 59 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 32 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(283 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(124 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_wing]: {
    man: {
      src: '/images/injury-dummy/man-right-wing.svg',
      widthRatio: 54 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 71 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(115 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(178 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-wing.svg',
      widthRatio: 52 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 57 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(115 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(174 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_wing]: {
    man: {
      src: '/images/injury-dummy/man-left-wing.svg',
      widthRatio: 55 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 72 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(115 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(81 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-wing.svg',
      widthRatio: 52 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 56 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(114 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(81 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__upper_back]: {
    man: {
      src: '/images/injury-dummy/man-upper-back.svg',
      widthRatio: 63 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 87 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(107 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(125 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-upper-back.svg',
      widthRatio: 52 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 72 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(102 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(128 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__middle_back]: {
    man: {
      src: '/images/injury-dummy/man-middle-back.svg',
      widthRatio: 105 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 57 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(182 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(104 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-middle-back.svg',
      widthRatio: 90 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 56 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(172 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(109 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__lower_back]: {
    man: {
      src: '/images/injury-dummy/man-lower-back.svg',
      widthRatio: 85 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 53 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(238 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(112 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-lower-back.svg',
      widthRatio: 94 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 46 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(222 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(106 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__tailbone]: {
    man: {
      src: '/images/injury-dummy/man-tailbone.svg',
      widthRatio: 47 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 31 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(286 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(133 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-tailbone.svg',
      widthRatio: 44 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 26 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(266 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(131 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_shoulder]: {
    man: {
      src: '/images/injury-dummy/man-right-shoulder.svg',
      widthRatio: 59 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 43 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(109 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(77 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-shoulder.svg',
      widthRatio: 63 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 43 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(110 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(82 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_upper_arm]: {
    man: {
      src: '/images/injury-dummy/man-right-upper-arm.svg',
      widthRatio: 32 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 86 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(154 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(64 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-upper-arm.svg',
      widthRatio: 29 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 75 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(148 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(71 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_forearm]: {
    man: {
      src: '/images/injury-dummy/man-right-fore-arm.svg',
      widthRatio: 34 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 55 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(247 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(47 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-fore-arm.svg',
      widthRatio: 29 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 58 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(228 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(59 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_wrist]: {
    man: {
      src: '/images/injury-dummy/man-right-wrist.svg',
      widthRatio: 18 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 17 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(298 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(40 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-wrist.svg',
      widthRatio: 20 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 28 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(283 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(54 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_palm]: {
    man: {
      src: '/images/injury-dummy/man-right-palm.svg',
      widthRatio: 25 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 28 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(312 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(26 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-palm.svg',
      widthRatio: 22 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 22 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(308 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(40 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_finger]: {
    man: {
      src: '/images/injury-dummy/man-right-finger.svg',
      widthRatio: 46 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 53 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(322 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${100 / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-finger.svg',
      widthRatio: 39 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 52 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(320 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(23 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_elbow]: {
    man: {
      src: '/images/injury-dummy/man-right-elbow.svg',
      widthRatio: 29 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 47 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(215 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(225 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-elbow.svg',
      widthRatio: 22 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 38 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(205 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(217 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_back_of_hand]: {
    man: {
      src: '/images/injury-dummy/man-right-back-hand.svg',
      widthRatio: 25 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 29 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(313 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(261 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-back-hand.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 23 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(308 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(251 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_shoulder]: {
    man: {
      src: '/images/injury-dummy/man-left-shoulder.svg',
      widthRatio: 58 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 43 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(112 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(177 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-shoulder.svg',
      widthRatio: 62 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 40 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(110 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(164 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_upper_arm]: {
    man: {
      src: '/images/injury-dummy/man-left-upper-arm.svg',
      widthRatio: 34 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 84 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(154 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(217 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-upper-arm.svg',
      widthRatio: 29 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 76 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(147 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(208 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_forearm]: {
    man: {
      src: '/images/injury-dummy/man-left-fore-arm.svg',
      widthRatio: 37 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 54 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(246 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(229 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-fore-arm.svg',
      widthRatio: 35 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 63 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(226 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(218 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },

  [SportsBodyPartName.all_body_part__left_wrist]: {
    man: {
      src: '/images/injury-dummy/man-left-wrist.svg',
      widthRatio: 20 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 19 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(299 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(255 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-wrist.svg',
      widthRatio: 18 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 24 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(286 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(240 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_palm]: {
    man: {
      src: '/images/injury-dummy/man-left-palm.svg',
      widthRatio: 25 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 29 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(312 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(261 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-palm.svg',
      widthRatio: 23 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 22 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(309 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(250 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_finger]: {
    man: {
      src: '/images/injury-dummy/man-left-finger.svg',
      widthRatio: 42 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 53 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(323 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(268 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-finger.svg',
      widthRatio: 38 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 50 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(321 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(250 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_elbow]: {
    man: {
      src: '/images/injury-dummy/man-left-elbow.svg',
      widthRatio: 29 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 47 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(216 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(60 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-elbow.svg',
      widthRatio: 22 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 42 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(202 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(68 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_back_of_hand]: {
    man: {
      src: '/images/injury-dummy/man-left-back-hand.svg',
      widthRatio: 25 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 29 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(314 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(24 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-back-hand.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 23 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(308 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(40 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_hip_joint]: {
    man: {
      src: '/images/injury-dummy/man-right-hip-joint.svg',
      widthRatio: 41 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 47 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(295 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(99 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-hip-joint.svg',
      widthRatio: 41 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 44 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(269 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(100 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_groin]: {
    man: {
      src: '/images/injury-dummy/man-right-groin.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 51 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(340 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(125 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-groin.svg',
      widthRatio: 22 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 51 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(316 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(127 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_front_thigh]: {
    man: {
      src: '/images/injury-dummy/man-right-front-thigh.svg',
      widthRatio: 41 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 108 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(334 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(96 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-front-thigh.svg',
      widthRatio: 42 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 115 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(304 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(99 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_front_knee]: {
    man: {
      src: '/images/injury-dummy/man-right-front-knee.svg',
      widthRatio: 32 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 52 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(441 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(101 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-front-knee.svg',
      widthRatio: 31 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 45 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(421 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(107 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  /**
   * RIGHT_FRONT_LEG -> RIGHT_SHIN 변경됨
   */
  [SportsBodyPartName.all_body_part__right_shin]: {
    man: {
      src: '/images/injury-dummy/man-right-front-leg.svg',
      widthRatio: 34 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 92 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(487 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(97 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-front-leg.svg',
      widthRatio: 31 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 121 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(462 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(105 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_ankle]: {
    man: {
      src: '/images/injury-dummy/man-right-ankle.svg',
      widthRatio: 20 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 30 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(582 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(111 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-ankle.svg',
      widthRatio: 16 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 27 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(584 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(118 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_instep]: {
    man: {
      src: '/images/injury-dummy/man-right-instep.svg',
      widthRatio: 28 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 34 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(610 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(106 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-instep.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 36 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(610 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(117 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_toe]: {
    man: {
      src: '/images/injury-dummy/man-right-toe.svg',
      widthRatio: 34 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 19 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(642 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(100 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-toe.svg',
      widthRatio: 25 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 18 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(642 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(111 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_hip]: {
    man: {
      src: '/images/injury-dummy/man-right-hip.svg',
      widthRatio: 54 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 67 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(286 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(159 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-hip.svg',
      widthRatio: 51 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 59 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(275 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(157 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_back_thigh]: {
    man: {
      src: '/images/injury-dummy/man-right-back-thigh.svg',
      widthRatio: 48 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 114 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(350 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(166 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-back-thigh.svg',
      widthRatio: 50 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 98 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(328 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(160 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_back_of_knee]: {
    man: {
      src: '/images/injury-dummy/man-right-back-knee.svg',
      widthRatio: 32 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 30 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(465 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(182 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-back-knee.svg',
      widthRatio: 29 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 35 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(425 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(172 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_back_leg]: {
    man: {
      src: '/images/injury-dummy/man-right-back-leg.svg',
      widthRatio: 32 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 75 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(493 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(183 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-back-leg.svg',
      widthRatio: 30 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 130 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(459 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(174 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_achilles_tendon]: {
    man: {
      src: '/images/injury-dummy/man-right-achilles-tendon.svg',
      widthRatio: 18 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 31 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(572 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(184 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-achilles-tendon.svg',
      widthRatio: 16 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 27 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(589 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(173 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__right_sole]: {
    man: {
      src: '/images/injury-dummy/man-right-sole.svg',
      widthRatio: 27 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 48 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(602 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(179 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-right-sole.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 39 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(615 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(171 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_hip_joint]: {
    man: {
      src: '/images/injury-dummy/man-left-hip-joint.svg',
      widthRatio: 36 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 45 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(290 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(176 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-hip-joint.svg',
      widthRatio: 41 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 50 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(265 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(167 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_groin]: {
    man: {
      src: '/images/injury-dummy/man-left-groin.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 51 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(338 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(166 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-groin.svg',
      widthRatio: 22 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 51 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(316 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(160 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_front_thigh]: {
    man: {
      src: '/images/injury-dummy/man-left-front-thigh.svg',
      widthRatio: 42 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 108 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(326 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(175 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-front-thigh.svg',
      widthRatio: 43 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 116 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(305 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(167 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_front_knee]: {
    man: {
      src: '/images/injury-dummy/man-left-front-knee.svg',
      widthRatio: 32 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 52 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(434 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(178 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-front-knee.svg',
      widthRatio: 30 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 46 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(421 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(171 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  /**
   * LEFT_FRONT_LEG -> LEFT_SHIN으로 변경
   */
  [SportsBodyPartName.all_body_part__left_shin]: {
    man: {
      src: '/images/injury-dummy/man-left-front-leg.svg',
      widthRatio: 34 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 98 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(482 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(182 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-front-leg.svg',
      widthRatio: 32 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 121 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(461 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(173 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_ankle]: {
    man: {
      src: '/images/injury-dummy/man-left-ankle.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 31 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(581 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(182 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-ankle.svg',
      widthRatio: 17 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 27 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(584 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(173 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_instep]: {
    man: {
      src: '/images/injury-dummy/man-left-instep.svg',
      widthRatio: 27 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 33 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(612 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(179 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-instep.svg',
      widthRatio: 20 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 36 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(610 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(172 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_toe]: {
    man: {
      src: '/images/injury-dummy/man-left-toe.svg',
      widthRatio: 34 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 17 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(642 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(178 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-toe.svg',
      widthRatio: 26 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 19 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(641 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(172 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_hip]: {
    man: {
      src: '/images/injury-dummy/man-left-hip.svg',
      widthRatio: 55 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 68 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(288 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(98 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-hip.svg',
      widthRatio: 51 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 59 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(275 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(100 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_back_thigh]: {
    man: {
      src: '/images/injury-dummy/man-left-back-thigh.svg',
      widthRatio: 48 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 112 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(352 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(97 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-back-thigh.svg',
      widthRatio: 49 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 98 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(328 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(99 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_back_of_knee]: {
    man: {
      src: '/images/injury-dummy/man-left-back-knee.svg',
      widthRatio: 32 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 29 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(463 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(97 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-back-knee.svg',
      widthRatio: 30 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 35 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(424 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(108 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_back_leg]: {
    man: {
      src: '/images/injury-dummy/man-left-back-leg.svg',
      widthRatio: 34 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 82 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(489 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(96 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-back-leg.svg',
      widthRatio: 30 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 124 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(456 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(104 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_achilles_tendon]: {
    man: {
      src: '/images/injury-dummy/man-left-achilles-tendon.svg',
      widthRatio: 21 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 31 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(572 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(108 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-achilles-tendon.svg',
      widthRatio: 14 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 28 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(581 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(120 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
  [SportsBodyPartName.all_body_part__left_sole]: {
    man: {
      src: '/images/injury-dummy/man-left-sole.svg',
      widthRatio: 29 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 48 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(603 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(105 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
    woman: {
      src: '/images/injury-dummy/woman-left-sole.svg',
      widthRatio: 19 / INJURY_DUMMY_BACKGROUND_WIDTH,
      heightRatio: 45 / INJURY_DUMMY_BACKGROUND_HEIGHT,
      top: `${(608 * 100) / INJURY_DUMMY_BACKGROUND_HEIGHT}%`,
      left: `${(116 * 100) / INJURY_DUMMY_BACKGROUND_WIDTH}%`,
    },
  },
}
