import React, { useCallback } from 'react'

import { AuthClientError, AuthClientEvent } from '@react-keycloak/core'
import { getKeycloakInstance, SSRCookies, SSRKeycloakProvider } from '@react-keycloak/ssr'
import { KeycloakInstance } from 'keycloak-js'

import { __ENV__ } from '@plco-pro/env'

export type KeycloakProviderProps = {
  cookies: any
}

const config =
  __ENV__ === 'production'
    ? require(`@plco-pro-config/production.keycloak.json`)
    : __ENV__ === 'stage'
    ? require('@plco-pro-config/stage.keycloak.json')
    : require('@plco-pro-config/development.keycloak.json')

const initOptions = {
  checkLoginIframe: false,
  onLoad: 'check-sso',
}

export const getKeycloakInstanceWithConfig = () =>
  getKeycloakInstance(config) as Partial<KeycloakInstance>

export const KeycloakProvider: React.FunctionComponent<KeycloakProviderProps> = ({
  children,
  cookies,
}) => {
  const onEvent = useCallback((eventType: AuthClientEvent, error?: AuthClientError) => {
    console.info(eventType, error)
  }, [])

  return (
    <SSRKeycloakProvider
      persistor={SSRCookies(cookies)}
      keycloakConfig={config}
      initOptions={initOptions}
      onEvent={onEvent}
    >
      {children}
    </SSRKeycloakProvider>
  )
}
