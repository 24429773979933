import React, { useCallback, useEffect, useState } from 'react'
import {
  Modal as ReactResponsiveModal,
  ModalProps as ReactResponsiveModalProps,
} from 'react-responsive-modal'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { Box, BoxProps } from '@plco-pro/components/atoms/box'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Spinner } from '@plco-pro/components/atoms/spinner'
import { ModalPageHeader } from '@plco-pro/components/molecules/modal-page-header'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { breakpoints } from '@plco-pro/themes/main'
import { isMobile } from '@plco-pro/utils/isMobile'

import 'react-responsive-modal/styles.css'

type Props = ReactResponsiveModalProps & {
  maxHeight?: string
  open: boolean
  onClose: () => void
  header: React.ReactNode
  children?: React.ReactNode
  showHeaderBackIcon?: boolean
  handleClickBackIcon?: () => void
}

export type StyledReactResponsiveModalProps = ReactResponsiveModalProps & {
  width?: string
  height?: string
  className?: string
  isHeaderVisible?: boolean
  responsiveHeaderTop?: string
  isFullScreen?: boolean
}

// react portal workaround for styled-components
// cf: https://material-ui.com/guides/interoperability/#portals
const enterOverlayAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const ModalOverlayContainer = styled(Flex)`
  animation: ${enterOverlayAnimation} 300ms ease infinite;
} 
`
export const StyledReactResponsiveModal = styled(
  ({ className, ...rest }: StyledReactResponsiveModalProps) => (
    <ReactResponsiveModal
      {...rest}
      // ios 의 경우 scroll 에 이슈가있어서, 임시적으로 blockScroll 을 false 처리한다.
      // https://github.com/pradel/react-responsive-modal/issues/468
      blockScroll={!isMobile()?.find((el) => el.includes('iPhone') || el.includes('iPad'))}
      classNames={{
        ...rest.classNames,
        root: className,
      }}
      styles={{
        // modal root z-index 1000, toasts z-index 1000, so modal hide toasts
        ...rest.styles,
        root: {
          zIndex: 800,
          ...rest.styles?.root,
        },
        modal: {},
      }}
      onOverlayClick={(e) => {
        e.stopPropagation()
      }}
      onEscKeyDown={(e) => {
        e.stopPropagation()
      }}
    />
  ),
)`
  .react-responsive-modal-container {
    text-align: unset;
    position: absolute;
    right: 0;
    left: 0;
  }

  .react-responsive-modal-modal {
    position: fixed;
    right: 0;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding: 0;
    margin: 0;
    max-width: 100%;

    @media (max-width: ${breakpoints[0]}) {
      width: 100vw;
    }
  }

  .react-responsive-modal-modal:focus {
    outline: none;
  }

  .react-responsive-modal-overlay {
    background: rgba(19, 24, 32, 0.6);
  }

  .react-responsive-modal-closeButton {
    top: ${(props) => (props.isHeaderVisible ? props.responsiveHeaderTop : '16px')};
    right: 24px;

    svg {
      fill: ${(props) => props.theme.colors['grey-400']};
    }
  }

  @media (max-width: ${breakpoints[0]}) {
    @keyframes customEnterModalAnimation {
      0% {
        transform: translateY(100vh);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  @media (min-width: ${breakpoints[0]}) {
    @keyframes customEnterModalAnimation {
      0% {
        transform: translateX(${(props) => props.width});
      }
      100% {
        transform: translateX(0px);
      }
    }
  }
  @media (max-width: ${breakpoints[0]}) {
    @keyframes customLeaveModalAnimation {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(100vh);
      }
    }
  }
  @media (min-width: ${breakpoints[0]}) {
    @keyframes customLeaveModalAnimation {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(${(props) => props.width});
      }
    }
  }
`

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{
      height: '100vh',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}
    {...props}
  />
)

const ContentBox = (props: BoxProps) => <Box sx={{ flex: '1 1 auto' }} {...props} />

export const ModalPage = ({
  maxHeight = '100vh',
  open,
  onClose,
  header,
  children,
  showHeaderBackIcon,
  handleClickBackIcon,
  ...modalProps
}: Props) => {
  const { handleResize } = useResponsiveContext()
  const [didMount, setDidMount] = useState(false)

  const onAnimationEnd = useCallback(() => {
    setDidMount(open)
    const root = document.querySelector('.modalPageRoot')
    const stopPropagation = (e: Event) => {
      e.stopPropagation()
    }

    if (!root || !root.parentElement) {
      return
    }

    if (open) {
      root.parentElement.addEventListener('click', stopPropagation)
      return
    }

    root.parentElement.removeEventListener('click', stopPropagation)
    return
  }, [open])

  useEffect(() => {
    handleResize?.()
  }, [handleResize, didMount])

  return (
    <StyledReactResponsiveModal
      open={open}
      onClose={onClose}
      width={'50vw'}
      center={false}
      classNames={{
        modalAnimationIn: 'customEnterModalAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      animationDuration={150}
      onAnimationEnd={onAnimationEnd}
      showCloseIcon={false}
      className={'modalPageRoot'}
      {...modalProps}
    >
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <ContainerFlex sx={{ maxHeight }}>
          <ModalPageHeader
            showBackIcon={showHeaderBackIcon}
            handleClickBackIcon={handleClickBackIcon}
            onClose={onClose}
          >
            {header}
          </ModalPageHeader>

          {didMount ? (
            <ContentBox id="modal-page-content-box" sx={{ overflowY: 'auto' }}>
              {children}
            </ContentBox>
          ) : (
            <Flex sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Spinner />
            </Flex>
          )}
        </ContainerFlex>
      </Flex>
    </StyledReactResponsiveModal>
  )
}
