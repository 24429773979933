import React, { Fragment, memo, MouseEvent, TouchEvent, useMemo, useRef, useState } from 'react'

import { Flex } from '@plco-pro/components/atoms/flex'
import { ScalableSvg } from '@plco-pro/components/atoms/scalable-svg'
import { TouchableOpacity } from '@plco-pro/components/atoms/touchable-opacity'
import {
  InjuryTooltip,
  PartsData,
} from '@plco-pro/components/molecules/injury-tooltip/injury-tooltip'
import {
  InjuryDummyBodyPartDataEntry,
  InjuryDummyProps,
} from '@plco-pro/components/organisms/injury-dummy'
import { SportsBodyPart } from '@plco-pro/graphqls/react.generated'
import { InjuryDummyGender } from '@plco-pro/maps/injury-dummy-background'
import { injuryDummyBodyPartMap } from '@plco-pro/maps/injury-dummy-body-part'
import { theme } from '@plco-pro/themes/main'
import { isMobile } from '@plco-pro/utils/isMobile'

export type InjuryDummyBodyPartProps = {
  bodyPart: SportsBodyPart
  gender: InjuryDummyGender
  data?: InjuryDummyBodyPartDataEntry
  onClick?: InjuryDummyProps['onClick']
  parentLayout?: {
    width?: number
    height?: number
    offsetLeft?: number
    offsetParentWidth?: number
  }
  partsData?: PartsData
  selected?: boolean
  disabledTooltip?: boolean
}

export const getBodyPartColor = (selected?: boolean, data?: InjuryDummyBodyPartDataEntry) => {
  const color = {
    normal: theme.colors['injury-level-0-normal'],
    pressed: theme.colors['injury-level-0-pressed'],
    hovered: theme.colors['injury-level-0-hovered'],
  }

  const selectedColor = {
    normal: theme.colors['primary-500-72'],
    pressed: theme.colors['primary-500'],
    hovered: theme.colors['primary-500-48'],
  }

  if (selected) {
    return selectedColor
  }

  if (data && data.SORENESS !== 0) {
    color.normal = theme.colors[`injury-level-${data.SORENESS}-normal` as keyof typeof theme.colors]
    color.pressed =
      theme.colors[`injury-level-${data.SORENESS}-pressed` as keyof typeof theme.colors]
    color.hovered =
      theme.colors[`injury-level-${data.SORENESS}-hovered` as keyof typeof theme.colors]

    return color
  }

  return color
}

export const InjuryDummyBodyPart = ({
  bodyPart: part,
  gender,
  data = {},
  onClick,
  parentLayout = {},
  partsData,
  selected,
  disabledTooltip,
}: InjuryDummyBodyPartProps) => {
  const [visible, setVisible] = useState<'hidden' | 'visible'>('hidden')
  const [pageY, setPageY] = useState<number>(0)

  const ref = useRef<HTMLDivElement>(null)
  // get color from data
  const color = useMemo(() => getBodyPartColor(selected, data), [selected, data])

  // get mapItem from map

  const mapItem = useMemo(() => injuryDummyBodyPartMap[part.id][gender], [gender, part.id])

  // calculate svg size
  const layout = useMemo(() => {
    if (!parentLayout?.width || !parentLayout?.height) {
      return { opacity: 0 }
    }

    return {
      width: mapItem.widthRatio * parentLayout.width,
      height: mapItem.heightRatio * parentLayout.height,
    }
  }, [mapItem, parentLayout])

  const offsetLeft = (parentLayout?.offsetLeft ?? 0) + (ref.current?.offsetLeft ?? 0)
  const parentWidth = parentLayout?.offsetParentWidth || 0

  const handleClickPart = () => {
    onClick?.(part)
  }

  const handleClickTooltip = () => {
    if (!isMobile()) {
      return
    }

    setVisible('hidden')
    onClick?.(part)
  }

  const mouseEnter = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    if ('clientY' in e) {
      setPageY(e.clientY)
    } else if ('targetTouches' in e) {
      setPageY(e.targetTouches.item(0)?.clientY ?? 0)
    }
  }

  return (
    <Fragment>
      <Flex
        ref={ref}
        sx={{
          position: 'absolute',
          left: mapItem.left,
          top: mapItem.top,
          width: layout.width,
          height: layout.height,
          '&:hover, &:active, &:focus': {
            '& .tooltip': {
              visibility: visible,
            },
          },
          '@media (hover: hover) and (pointer: fine)': {
            '&:hover, &:active, &:focus': {
              '& .tooltip': {
                visibility: !disabledTooltip && 'visible',
              },
            },
          },
        }}
        onClick={() => {
          handleClickPart()
        }}
        onTouchStart={(e) => {
          if (!isMobile() || disabledTooltip) {
            return
          }
          setVisible('visible')
          mouseEnter(e)
        }}
        onMouseEnter={(e) => {
          if (isMobile()) {
            return
          }
          mouseEnter(e)
        }}
      >
        <TouchableOpacity disabled={!onClick}>
          <ScalableSvg
            src={mapItem.src}
            width={layout.width}
            height={layout.height}
            opacity={layout.opacity}
            customColor={color}
          />
        </TouchableOpacity>

        <InjuryTooltip
          pageY={pageY}
          offset={layout.width && layout.width + 8}
          layoutHeight={layout.height ?? 0}
          name={part.value}
          partsData={partsData}
          offsetLeft={offsetLeft}
          parentWidth={parentWidth}
          onClickDetail={handleClickTooltip}
        />
      </Flex>
    </Fragment>
  )
}

/*
  Function that determines if component needs to be re-rendered
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
*/
const arePropsEqual = (
  prevProps: InjuryDummyBodyPartProps,
  nextProps: InjuryDummyBodyPartProps,
) => {
  const isNameEqual = prevProps.bodyPart === nextProps.bodyPart
  const isGenderEqual = prevProps.gender === nextProps.gender
  const isOnClickEqual = !!prevProps.onClick === !!nextProps.onClick // shallow comparaison (only check for undefined => defined)
  const isParentLayoutEqual =
    prevProps.parentLayout?.width === nextProps.parentLayout?.width &&
    prevProps.parentLayout?.height === nextProps.parentLayout?.height &&
    prevProps.parentLayout?.offsetLeft === nextProps.parentLayout?.offsetLeft &&
    prevProps.parentLayout?.offsetParentWidth === nextProps.parentLayout?.offsetParentWidth

  const isDataEqual =
    prevProps.data?.SORENESS === nextProps.data?.SORENESS &&
    prevProps.data?.SORENESS_STREAK === nextProps.data?.SORENESS_STREAK

  const isSelectedEqual = prevProps.selected === nextProps.selected

  return (
    isNameEqual &&
    isGenderEqual &&
    isOnClickEqual &&
    isParentLayoutEqual &&
    isDataEqual &&
    isSelectedEqual
  )
}
export const MemoizedInjuryDummyBodyPart = memo(InjuryDummyBodyPart, arePropsEqual)
