import React, { useMemo } from 'react'

import _round from 'lodash/round'

import { Box } from '@plco-pro/components/atoms/box'
import { Chip, ChipProps } from '@plco-pro/components/atoms/chip'
import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import theme from '@plco-pro/themes/main'

export type ChipMonitoringTenProps = ChipProps & {
  value: any
  label?: string
  color?: string
}

const ContainerFlex = (props: FlexProps) => (
  <Flex
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-start',
    }}
    {...props}
  />
)

export const ChipMonitoringTen: React.FunctionComponent<ChipMonitoringTenProps> = ({
  value,
  label,
  disabled,
  color,
  ...rest
}) => {
  const workloadSatisfactionTextColor = useMemo(() => {
    switch (color) {
      case 'WARNING' || 'DANGER':
        return theme.colors['danger-training']
      case 'NEUTRAL':
        return theme.colors['caution-training']
      case 'GOOD':
        return theme.colors['best-training']
      default:
        return color
    }
  }, [color])

  if (label) {
    return (
      <Flex sx={{ alignItems: 'center', gap: '6px', flex: '1 0 0' }}>
        <Text
          sx={{ fontWeight: 'bold' }}
          variant={'s1'}
          appearance={disabled && 'disabled'}
          color={workloadSatisfactionTextColor}
        >
          {label}
        </Text>

        <Box sx={{ padding: '4px 8px', bg: 'grey-50', borderRadius: 5 }}>
          <Text sx={{ fontWeight: 'bold', color: 'grey-500' }} variant={'p2'}>
            {value}
            {value ? '/10' : ''}
          </Text>
        </Box>
      </Flex>
    )
  } else if (rest.backgroundColor) {
    return (
      <Chip size={'xs'} {...rest}>
        <Text
          variant={'p1'}
          appearance={'alternative'}
          sx={{ fontWeight: 'medium', marginTop: '-1px' }}
        >
          {_round(value, 1)}
        </Text>
        <Text
          variant={'p1'}
          appearance={'alternative'}
          sx={{ fontWeight: 'medium', marginTop: '-1px' }}
        >
          {'/10'}
        </Text>
      </Chip>
    )
  } else {
    return (
      <ContainerFlex>
        <Text variant={'p1'}>{_round(value, 1)}</Text>
        <Text variant={'p1'} opacity={0.6}>
          {'/10'}
        </Text>
      </ContainerFlex>
    )
  }
}
