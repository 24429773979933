import React from 'react'

import { Box } from '@plco-pro/components/atoms/box'
import { Flex } from '@plco-pro/components/atoms/flex'
import { Text } from '@plco-pro/components/atoms/text'
import { Avatar } from '@plco-pro/components/molecules/avatar'
import { Maybe, PlayerModalUserQuery, SportsRawBody } from '@plco-pro/graphqls/react.generated'
import { useI18n } from '@plco-pro/hooks/i18n'
import { useMomentUiFormat } from '@plco-pro/hooks/moment-ui-format'
import { useMultilingual } from '@plco-pro/hooks/multilingual'

type Props = {
  showHeader?: boolean
  user?: PlayerModalUserQuery['sports']['user']
  latestRawBody?: Maybe<Pick<SportsRawBody, 'weight' | 'height' | 'weightUnit' | 'heightUnit'>>
}

export const PlayerModalHeader = ({ showHeader, user, latestRawBody }: Props) => {
  const { formatDate } = useMomentUiFormat()

  const { formatMessage: f } = useI18n()

  const name = user?.name
  const birthdate = user?.birthdate ? formatDate(user.birthdate) : '-'

  const { currentPosition } = useMultilingual({
    group: user?.priorSportsCategory?.group,
    category: user?.priorSportsCategory?.id,
    role: user?.priorSportsRole,
    position: user?.priorSportsPosition,
  })

  const weight = latestRawBody ? `${latestRawBody?.weight}${latestRawBody.weightUnit}` : '-'
  const height = latestRawBody ? `${latestRawBody?.height}${latestRawBody.heightUnit}` : '-'

  if (!user || !showHeader) {
    return <Text variant={'s2'}>{f({ id: 'navbar-players.player-data' })}</Text>
  }

  return (
    <Flex sx={{ gap: 1 }}>
      <Avatar src={user?.pictureUrl} value={name} size={[40, 48]} />
      <Box>
        <Text variant={['s2', 's1']} sx={{ fontWeight: 'medium', pr: 3 }} ellipsis={true}>
          {name}
        </Text>

        <Flex>
          <Text variant={['p2', 'p1']} appearance={'hint'}>
            {[currentPosition?.value, birthdate, weight, height].join(' / ')}
          </Text>
        </Flex>
      </Box>
    </Flex>
  )
}
