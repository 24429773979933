export const trimText = (currentValue: string, maxLength: number) => {
  if (currentValue.length > maxLength + 1) {
    return currentValue.slice(0, maxLength)
  }

  if (currentValue.length <= maxLength) {
    return currentValue
  }

  const lastChar = currentValue.charAt(currentValue.length - 1)

  return `${currentValue.slice(0, maxLength - 1)}${lastChar}`
}
