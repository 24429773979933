import { ChangeEvent, Fragment, useState } from 'react'

import { HyperLink, Button, Flex, Icon, Spinner, Text, Textarea } from '@plco-pro/components/atoms'
import { Avatar, IconButton } from '@plco-pro/components/molecules'
import {
  Feedback,
  FeedbackType,
  useUpdateFeedbackMutation,
} from '@plco-pro/graphqls/react.generated'
import { useI18n, useNationality, useToast, useViewer } from '@plco-pro/hooks'
import { useAnalytics } from '@plco-pro/hooks/analytics'
import { useFeedbackError } from '@plco-pro/hooks/feedback-error'
import { useMomentFormat } from '@plco-pro/hooks/use-moment-format'
import { useResponsiveContext } from '@plco-pro/providers/responsive'
import { WITHDRAWN_USER } from '@plco-pro/utils/constant'
import { AnalyticsEventType } from '@plco-pro/utils/libs'

import { getFeedbackAmplitudeType } from './helper'

interface Props {
  editId: string
  feedback: Feedback
  isEditing: boolean
  onEdit: (id?: string) => void
  onDelete: () => void
}

export const CardCommonFeedbackDetail = ({
  editId,
  feedback,
  isEditing,
  onEdit,
  onDelete,
}: Props) => {
  const { formatMessage } = useI18n()
  const { formatFeedbackCreateDate } = useMomentFormat()
  const { viewer } = useViewer()
  const { showToast } = useToast()
  const nationality = useNationality()
  const { smAndDown } = useResponsiveContext()
  const showFeedbackError = useFeedbackError()
  const { trackEvent } = useAnalytics()

  const { id, createUser, createdAt, type, message, isUpdated } = feedback

  const [editedMessage, setEditedMessage] = useState(message)
  const [textareaHeight, setTextareaHeight] = useState(0)

  const [updateFeedbackMutation, { loading }] = useUpdateFeedbackMutation({
    onCompleted: ({ updateFeedback }) => {
      const feedbackTypeText = getFeedbackAmplitudeType(updateFeedback)

      trackEvent(AnalyticsEventType.EDIT_FEEDBACK, {
        type: feedbackTypeText,
      })
      showToast(formatMessage({ id: 'feedback.feedback-has-edited' }), { status: 'success' })
      onEdit()
    },
    onError: ({ graphQLErrors }) => {
      showFeedbackError(graphQLErrors)
    },
  })

  const onCancel = () => {
    onEdit()
    setEditedMessage(message)
  }

  const feedbackType = formatMessage({
    id: type === FeedbackType.NORMAL ? 'feedback.general-feedback' : 'feedback.feedback-request',
  })
  const feedbackCreateDate = formatFeedbackCreateDate(createdAt)
  const canEdit = viewer?.id === createUser?.id && !isEditing
  const isWithdrawnUser = createUser.id === WITHDRAWN_USER

  const onChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 500) {
      const lastChar = e.target.value.charAt(e.target.value.length - 1)

      setEditedMessage(`${editedMessage.slice(0, -1)}${lastChar}`)

      return
    }

    setEditedMessage(e.target.value)
  }

  const updateFeedback = () => {
    if (message === editedMessage) {
      const feedbackTypeText = getFeedbackAmplitudeType(feedback)

      trackEvent(AnalyticsEventType.EDIT_FEEDBACK, {
        type: feedbackTypeText,
      })
      showToast(formatMessage({ id: 'feedback.feedback-has-edited' }), { status: 'success' })
      onEdit()

      return
    }

    if (loading) {
      return
    }

    updateFeedbackMutation({
      variables: {
        input: {
          id,
          message: editedMessage,
        },
        multilingualTextInput: {
          nationality,
        },
      },
    })
  }

  return (
    <Fragment>
      <Flex sx={{ flexDirection: 'column', gap: '20px', pt: 3, pb: 4, px: [2, 3] }}>
        <Flex>
          <Flex sx={{ gap: '12px', alignItems: 'flex-start', width: '100%' }}>
            <Avatar
              src={createUser.pictureUrl}
              value={createUser.name}
              size={40}
              isWithdrawn={isWithdrawnUser}
            />
            <Flex sx={{ flexDirection: 'column', gap: '2px', flex: 1 }}>
              <Text sx={{ fontSize: 'p1', fontWeight: 700 }}>
                {isWithdrawnUser
                  ? formatMessage({ id: 'feedback.withdrew-user' })
                  : createUser.name}
              </Text>
              <Flex sx={{ gap: '4px' }}>
                <Text
                  sx={{
                    fontSize: 'p2',
                    fontWeight: 700,
                    color: type === FeedbackType.NORMAL ? 'grey-400' : 'sky-500',
                  }}
                >
                  {feedbackType}
                </Text>
                <Text sx={{ fontSize: 'p2', color: 'grey-400' }}>{'·'}</Text>
                <Text sx={{ fontSize: 'p2', color: 'grey-400' }}>{feedbackCreateDate}</Text>
              </Flex>
            </Flex>
            {canEdit && (
              <Flex>
                <IconButton
                  onClick={() => onEdit(feedback.id)}
                  src={'/images/ic_edit.svg'}
                  color={'grey-400'}
                  size={smAndDown ? 'lg' : 'sm'}
                  iconSize={smAndDown && 24}
                />
                <IconButton
                  onClick={onDelete}
                  src={'/images/ic_delete_outline.svg'}
                  color={'grey-400'}
                  size={smAndDown ? 'lg' : 'sm'}
                  iconSize={smAndDown && 24}
                />
              </Flex>
            )}
            {isEditing && editId === feedback.id && (
              <Flex sx={{ alignItems: 'center' }}>
                <Text sx={{ fontSize: 'p2', fontWeight: 700, color: 'grey-300' }}>
                  {formatMessage({ id: 'feedback.editing' })}
                </Text>
                <Flex
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '32px',
                    height: '32px',
                  }}
                >
                  <Icon src={'/images/ic_edit.svg'} color={'grey-300'} />
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        {isEditing && editId === feedback.id ? (
          <Flex sx={{ flexDirection: 'column', gap: '12px' }}>
            <Flex
              sx={{
                position: 'relative',
                minHeight: '105px',
                pt: 2,
                pb: 3,
                bg: 'white-500',
                border: '1px solid',
                borderColor: 'primary-500',
                borderRadius: 10,
              }}
            >
              <Textarea
                ref={(node) => {
                  if (!node) {
                    return
                  }
                  node.style.height = `${node.scrollHeight}px`
                }}
                value={editedMessage}
                onChange={onChangeMessage}
                placeholder={formatMessage({ id: 'feedback.enter-your-feedback' })}
                sx={{
                  p: 0,
                  px: 2,
                  maxHeight: '200px',
                  height: textareaHeight,
                  border: 'none',
                  resize: 'none',
                  fontSize: 's2',
                  fontWeight: 400,
                }}
              />
            </Flex>
            <Flex sx={{ justifyContent: 'flex-end', gap: 1 }}>
              <Button onClick={onCancel} variant={'secondary'} color={'grey'}>
                {formatMessage({ id: 'CANCEL' })}
              </Button>
              <Button onClick={updateFeedback} disabled={!editedMessage.length}>
                {loading ? (
                  <Spinner size={'sm'} color={'alternative'} />
                ) : (
                  formatMessage({ id: 'SAVE' })
                )}
              </Button>
            </Flex>
          </Flex>
        ) : (
          <HyperLink>
            <Flex
              ref={(node) => {
                if (!node) {
                  return
                }
                setTextareaHeight(node.scrollHeight)
              }}
              sx={{ alignItems: 'center', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
            >
              <Text sx={{ fontSize: 's2', fontWeight: 400 }}>
                {message}
                {isUpdated && (
                  <Text sx={{ display: 'inline-block', fontWeight: 500, color: 'grey-400' }}>
                    {formatMessage({ id: 'feedback.edited' })}
                  </Text>
                )}
              </Text>
            </Flex>
          </HyperLink>
        )}
      </Flex>
      <Flex sx={{ height: '16px', bg: 'grey-100' }} />
    </Fragment>
  )
}
